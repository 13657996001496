import { memo, useMemo } from 'react';
import { useReportBlockCtx } from './report-block-ctx';

export const ReportBlockDataComponent = memo(() => {
  const { visualization, visualizationComponents } = useReportBlockCtx();

  const Component = useMemo(() => {
    if (!visualization?.selectedView || !visualizationComponents) return undefined;
    return visualizationComponents[visualization.selectedView];
  }, [visualization?.selectedView, visualizationComponents]);

  return Component ? <Component /> : null;
});
