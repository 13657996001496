import { apiStatus, CONSENT_FLAG } from '@va/constants';
import { setUserFlag, updateWebsiteFlags } from '@va/dashboard/actions/api';
import { setConsentInSession } from '@va/dashboard/actions/ui';
import { getRequestStatus } from '@va/dashboard/selectors/api';
import { hasConsentFlag } from '@va/dashboard/selectors/app';
import ToggleSwitch from '@va/deprecated/components/ToggleSwitch';
import { useTranslate } from '@va/localization';
import { getApiRequestStatus } from '@va/standalone/shared/selectors';
import { isWixApp } from '@va/util/helpers';
import { useDispatch, useSelector } from 'react-redux';

export default function ConsentSettings({ priorConsent }: { priorConsent: string }) {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const consentFlag = useSelector((state) => hasConsentFlag(state, priorConsent));
  const apiRequestStatus = useSelector((state) =>
    isWixApp() ? getRequestStatus(state, 'updateWebsiteFlags') : getApiRequestStatus(state, 'setUserFlag'),
  );
  const inProgress = apiRequestStatus === apiStatus.IN_PROGRESS;

  const onSwitchChange = (val: boolean) => {
    isWixApp() ? dispatch(updateWebsiteFlags({ [priorConsent]: val })) : dispatch(setUserFlag(priorConsent, val));

    if (val === false) {
      dispatch(setConsentInSession(CONSENT_FLAG.YOUTUBE, false));
    }
  };

  return (
    <div className='flex justify-between py-10 xs:flex-col'>
      <div className='w-1/4 sm:w-1/2 xs:w-full text-3xl font-medium mb-2 mr-2'>
        {translate(`consent.${priorConsent}.title`)}
      </div>
      <div className='w-3/4 sm:w-1/2 xs:w-full flex flex-col'>
        <div className='flex mb-2'>
          <ToggleSwitch
            checked={consentFlag || false}
            handleChange={onSwitchChange}
            height={24}
            width={40}
            withoutBorder
            disabled={inProgress}
            onColor={window.PRIMARY_COLOR}
            onHandleColor={window.PRIMARY_COLOR}
          />
          <div className='ml-2 text-lg leading-24'>
            {translate(`option.btn.${consentFlag ? 'enabled' : 'disabled'}`)}
          </div>
        </div>
        <div className='text-sm leading-5 font-light'>{translate(`consent.${priorConsent}.description`)}</div>
      </div>
    </div>
  );
}
