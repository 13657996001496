import React from 'react';
import { IconProps } from '.';

export const MouseScrollIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={className}
      width='19'
      height='22'
      viewBox='0 0 19 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_11468_137758'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='19'
        height='22'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.5 2C12.8137 2 15.5 4.68629 15.5 8V14C15.5 17.3137 12.8137 20 9.5 20C6.18629 20 3.5 17.3137 3.5 14V8C3.5 4.68629 6.18629 2 9.5 2ZM2 8C2 3.85786 5.35786 0.5 9.5 0.5C13.6421 0.5 17 3.85786 17 8V14C17 18.1421 13.6421 21.5 9.5 21.5C5.35786 21.5 2 18.1421 2 14V8ZM9.5 5C8.67157 5 8 5.67157 8 6.5V9.5C8 10.3284 8.67157 11 9.5 11C10.3284 11 11 10.3284 11 9.5V6.5C11 5.67157 10.3284 5 9.5 5Z'
          fill='black'
        />
      </mask>
      <g mask='url(#mask0_11468_137758)'>
        <rect x='-2.5' y='-1' width='24' height='24' fill={color ?? 'var(--color-primary)'} />
      </g>
    </svg>
  );
};


