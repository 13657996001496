import { AnnouncementCategoriesType, AnnouncementContentType } from '@va/types/announcements';
import { merge } from 'lodash';

export class Announcement {
  public categories;
  public id;
  public content;

  constructor(id: string, categories: AnnouncementCategoriesType, content: AnnouncementContentType) {
    this.id = id;
    this.categories = categories;
    this.content = content;
  }
}

export class EmptyAnnouncement extends Announcement {
  constructor() {
    const id = '';
    const categories = {
      platform: '',
      subscriptionType: '',
      userType: '',
      installDate: '',
      numberOfMonthlyVisits: '',
      hadFirstVisit: null,
      hadFirstLogin: null,
      geolocation: '',
      messageType: '',
    };
    const content: AnnouncementContentType = {
      image: '',
      headline: '',
      text: '',
      confirmation_text: '',
      button_ok_text: '',
      button_cancel_text: '',
      confirmation_needed: false,
      target_url: '',
      target_url_raw: '',
      target_url_internal: '',
      internal_t3_link: null,
      internal_t3_link_utm: '',
      voucher_code: null,
      video: null,
      show_newsletter_toggle: false,
      show_email_reports_toggle: false,
    };
    super(id, categories, content);
  }
}

export const getDummyAnnouncement = (data?: {
  id?: string;
  categories?: Partial<AnnouncementCategoriesType>;
  content?: Partial<AnnouncementContentType>;
}) => {
  return merge(new EmptyAnnouncement(), data);
};

export const getDummyCategories = (data?: Record<any, any>) => {
  return {
    platform: 's',
    subscriptionType: '',
    userType: '',
    installDate: '',
    numberOfMonthlyVisits: '',
    hadFirstVisit: null,
    hadFirstLogin: null,
    geolocation: '',
    messageType: '',
    ...data,
  };
};

const accountBasedCategories = {
  platform: 'all',
  subscriptionType: 'all',
  userType: 'all',
};

const websiteBasedCategories = {
  platform: 'standalone, wix',
  subscriptionType: 'free, premium',
  userType: 'owner',
};

const firstLoginCategories = {
  messageType: 'firstlogin',
  userType: 'all',
};

export const dummyAnnouncements = [
  getDummyAnnouncement({
    categories: accountBasedCategories,
    id: String(Math.random()),
    content: { text: 'Account based 1' },
  }),
  getDummyAnnouncement({
    categories: accountBasedCategories,
    id: String(Math.random()),
    content: { text: 'Account based 2' },
  }),
  getDummyAnnouncement({
    categories: accountBasedCategories,
    id: 'id-3333333',
    content: { text: 'Account based 3' },
  }),
  getDummyAnnouncement({
    categories: accountBasedCategories,
    id: 'id-44444',
    content: { text: 'Account based 4' },
  }),

  getDummyAnnouncement({
    categories: websiteBasedCategories,
    id: String(Math.random()),
    content: { text: 'Website based 1' },
  }),
  getDummyAnnouncement({
    categories: websiteBasedCategories,
    id: String(Math.random()),
    content: { text: 'Website based 2' },
  }),
  getDummyAnnouncement({
    categories: websiteBasedCategories,
    id: 'id-777777777',
    content: { text: 'Website based 3' },
  }),
  getDummyAnnouncement({
    categories: firstLoginCategories,
    id: String(Math.random()),
    content: { text: 'First login' },
  }),
];
