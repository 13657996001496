import { Question } from '@va/dashboard/modules/feedback/shared';
import { isMobile } from '@va/dashboard/selectors/ui';
import FunnelGraph from '@va/deprecated/components/FunnelGraph';
import { useTranslate } from '@va/localization';
import { LoadingLogo, PercentageCell, TimeCell } from '@va/ui/design-system';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

export const FeedbackStatsPage = ({ questions, dismissible, stats, isLoading }) => {
  const translate = useTranslate();
  const isMobileDevice = useSelector(isMobile);

  if (isLoading)
    return (
      <div className='relative min-h-400'>
        <LoadingLogo />
      </div>
    );
  const { starts, responses, completionRate, dismissals, avgCompletionTime } = stats;

  let answersPerQuestion = stats.answersPerQuestion;

  if (!answersPerQuestion.length) answersPerQuestion = new Array(questions.length).fill(0);

  function renderAvgCompletionRate() {
    return (
      <SimpleStat
        additionalCss={isMobileDevice && 'flex items-center justify-center py-10'}
        label={translate('feedback.stats.averageCompletionTime')}
      >
        <TimeCell value={avgCompletionTime} />
      </SimpleStat>
    );
  }

  return (
    <div>
      <div className='flex flex-column border-b-2 border-gray-concrete'>
        <div className='flex pt-10 md:py-10 justify-around '>
          <SimpleStat label={translate('feedback.stats.starts')}>{starts}</SimpleStat>
          <SimpleStat label={translate('feedback.stats.responses')}>{responses}</SimpleStat>
          {dismissible && <SimpleStat label={translate('feedback.stats.dismissals')}>{dismissals}</SimpleStat>}
          <SimpleStat label={translate('feedback.stats.completionRate')}>
            <PercentageCell value={completionRate} />
          </SimpleStat>
          {!isMobileDevice && renderAvgCompletionRate()}
        </div>
        {isMobileDevice && renderAvgCompletionRate()}
      </div>
      <Content questions={questions} answersPerQuestion={answersPerQuestion} />
    </div>
  );
};

FeedbackStatsPage.propTypes = {
  questions: PropTypes.array,
  isLoading: PropTypes.bool,
  dismissible: PropTypes.bool,
  stats: PropTypes.shape({
    answersPerQuestion: PropTypes.array,
    starts: PropTypes.number,
    responses: PropTypes.number,
    completionRate: PropTypes.number,
    dismissals: PropTypes.number,
    avgCompletionTime: PropTypes.number,
  }),
};

const SimpleStat = ({ label, children, additionalCss }) => {
  return (
    <div className={classNames('font-medium text-gray-dusty md:w-1/4 text-center leading-normal', additionalCss)}>
      <div>{label}</div>
      <div className='text-mine-shaft font-semibold text-xl ml-2 md:ml-0'>{children}</div>
    </div>
  );
};
SimpleStat.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  additionalCss: PropTypes.string,
};

const Content = ({ questions, answersPerQuestion }) => {
  const [questionStyle, setQuestionStyle] = useState(0);
  const isMobileDevice = useSelector(isMobile);
  const translate = useTranslate();

  const getGraphLevelHeight = useCallback(
    (val) => {
      setQuestionStyle(val);
    },
    [setQuestionStyle],
  );

  return (
    <div className='flex flex-col lg:flex-row lg:pl-6 pt-6'>
      <div className='lg:w-1/3'>
        {questions.map((question, index) => {
          return (
            <div style={{ height: isMobileDevice ? 'auto' : questionStyle }} className='pb-6' key={question.id}>
              <Question
                className='h-full'
                type={question.type}
                number={index + 1}
                overrideChildrenStyle={'px-6 py-4 overflow-y-auto lg:max-h-2/3 answered-question-graph'}
              >
                <div>{question.body}</div>
                {question.description && (
                  <div className='py-2 text-sm leading-normal text-devil-gray'>{question.description}</div>
                )}
              </Question>
            </div>
          );
        })}
      </div>

      <div className='lg:w-2/3 px-6'>
        <FunnelGraph
          levels={answersPerQuestion}
          levelText={translate('feedback.stats.participants')}
          dropoutText={translate('feedback.stats.dropoutText')}
          getGraphLevelHeight={getGraphLevelHeight}
        />
      </div>
    </div>
  );
};

Content.propTypes = {
  questions: PropTypes.array,
  answersPerQuestion: PropTypes.array,
};
