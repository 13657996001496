import { BLACK_COLOR, GREEN_COLOR, WHITE_COLOR } from './../constants';

export function Export(props) {
  const { isActive, ...otherProps } = props;

  return isActive ? (
    <svg width='19' height='25' viewBox='0 0 19 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...otherProps}>
      <path d='M0 0H19V25H0V0Z' fill={WHITE_COLOR} />
      <path d='M0 0V24.4286H14.5667H19V6.10714L12.2143 0H0Z' fill={GREEN_COLOR} />
      <path d='M11.875 0V7.125H19' stroke={WHITE_COLOR} />
      <path d='M5.42969 17.6429L9.50112 20.3572L13.5725 17.6429' stroke={WHITE_COLOR} />
      <path d='M9.5 8.14294L9.5 20.3572' stroke={WHITE_COLOR} />
    </svg>
  ) : (
    <svg width='19' height='25' viewBox='0 0 19 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...otherProps}>
      <path d='M0 0H19V25H0V0Z' fill={WHITE_COLOR} />
      <path d='M14.5667 23.9286H0.5V0.5H12.0224L18.5 6.32982V23.9286H14.5667Z' stroke={BLACK_COLOR} />
      <path d='M12 1V7H19' stroke={BLACK_COLOR} />
      <path d='M5.42969 17.6429L9.50112 20.3572L13.5725 17.6429' stroke={BLACK_COLOR} />
      <path d='M9.5 8.14294L9.5 20.3572' stroke={BLACK_COLOR} />
    </svg>
  );
}
