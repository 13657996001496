import { makeAction } from '@va/store';

export * from './recordings';
export * from './upgrade';

export const GET_SETTINGS = 'APP_GET_SETTINGS';

export const IGNORE_VISITS = 'APP_IGNORE_VISITS';
export const SET_IGNORE_VISITS = 'APP_SET_IGNORE_VISITS';
export const SET_IGNORE_VISITS_LINK = 'APP_SET_IGNORE_VISITS_LINK';
export const SET_IGNORE_VISITS_IFRAME_LOADED = 'APP_SET_IGNORE_VISITS_IFRAME_LOADED';
export const REQUEST_IGNORE_VISITS = 'APP_REQUEST_IGNORE_VISITS';

export const INITIALIZE = 'APP_INITIALIZE';
export const INITIALIZE_SUCCEEDED = 'APP_INITIALIZE_SUCCEEDED';
export const REGISTER = 'APP_REGISTER';

export const SET_WEBSITE_PROPERTY = 'APP_SET_WEBSITE_PROPERTY';

export const SET = 'USER_SET';
export const SET_DEBUG_VALUE = 'DEBUG_SET';

export const SET_FORM_FIELD = 'SET_FORM_FIELD';

export const SET_INSTANCE_ID = 'APP_SET_INSTANCE_ID';

export const HANDLE_ANNOUNCEMENT_MODAL = 'APP_HANDLE_ANNOUNCEMENT_MODAL';
export const HANDLE_COMPANY_DETAILS_MODAL = 'APP_HANDLE_COMPANY_DETAILS_MODAL';
/** @deprecated */
export const PROMPT_SSR_FEATURE_FLOW = 'APP_PROMPT_SSR_FEATURE_FLOW';
export const SET_SSR_SESSION_FOR_RECORDINGS = 'APP_SET_SSR_SESSION_FOR_RECORDINGS';
export const SET_ALL_RECORDING_EVENTS_LOADED = 'APP_SET_ALL_RECORDING_EVENTS_LOADED';

export const PUSH_REMOVING_DYNAMIC_PAGE = 'PUSH_REMOVING_DYNAMIC_PAGE';
export const POP_REMOVING_DYNAMIC_PAGE = 'POP_REMOVING_DYNAMIC_PAGE';
export const PUSH_REMOVING_CONVERSION_TYPE = 'PUSH_REMOVING_CONVERSION_TYPE';
export const POP_REMOVING_CONVERSION_TYPE = 'POP_REMOVING_CONVERSION_TYPE';

export const GET_MODULE_PERMISSIONS = 'GET_MODULE_PERMISSIONS';
export const GET_MODULE_PERMISSIONS_SUCCEEDED = 'GET_MODULE_PERMISSIONS_SUCCEEDED';
export const GET_MODULE_PERMISSIONS_FAILED = 'GET_MODULE_PERMISSIONS_FAILED';

export const setWebsiteProperty = makeAction(SET_WEBSITE_PROPERTY, 'propertyName', 'propertyValue');
export const getSettings = makeAction(GET_SETTINGS);

export const pushRemovingDynamicPage = makeAction(PUSH_REMOVING_DYNAMIC_PAGE, 'id');
export const popRemovingDynamicPage = makeAction(POP_REMOVING_DYNAMIC_PAGE, 'id');
export const pushRemovingConversionType = makeAction(PUSH_REMOVING_CONVERSION_TYPE, 'id');
export const popRemovingConversionType = makeAction(POP_REMOVING_CONVERSION_TYPE, 'id');

export const ignoreVisits = makeAction(IGNORE_VISITS, 'value');
export const setIgnoreVisits = makeAction(SET_IGNORE_VISITS, 'value', 'isAllIgnored');
export const setIgnoreVisitsLink = makeAction(SET_IGNORE_VISITS_LINK, 'link');
export const requestIgnoreVisits = makeAction(REQUEST_IGNORE_VISITS);
export const setIgnoreVisitsIframeLoaded = makeAction(SET_IGNORE_VISITS_IFRAME_LOADED);

export const initialize = makeAction(INITIALIZE);
export const initializeSucceeded = makeAction(INITIALIZE_SUCCEEDED);

export const register = makeAction(REGISTER, 'data');

// User
export const set = makeAction(SET, 'state');
export const setDebugValue = makeAction(SET_DEBUG_VALUE, 'value');

export const setFormField = makeAction(SET_FORM_FIELD, 'formName', 'field', 'value');

export const setInstanceId = makeAction(SET_INSTANCE_ID, 'instanceId');

export const handleAnnouncementModal = makeAction(HANDLE_ANNOUNCEMENT_MODAL, 'locale');
export const handleUpdateCompanyDetailsModal = makeAction(HANDLE_COMPANY_DETAILS_MODAL);
/** @deprecated */
export const promptSsrFeatureFlow = makeAction(PROMPT_SSR_FEATURE_FLOW, 'value');
export const setSsrSessionForRecordings = makeAction(SET_SSR_SESSION_FOR_RECORDINGS, 'sessionId');
export const setAllRecordingEventsLoaded = makeAction(SET_ALL_RECORDING_EVENTS_LOADED, 'value');

export const getModulePermissions = makeAction(GET_MODULE_PERMISSIONS);
export const getModulePermissionsSucceeded = makeAction(GET_MODULE_PERMISSIONS_SUCCEEDED, 'data');
export const getModulePermissionsFailed = makeAction(GET_MODULE_PERMISSIONS_FAILED, 'error');
