import { facebookGroup, standalonePresentationLinks } from '@va/constants';
import { Footer } from '@va/dashboard/shared/ui-layout';
import { isSumoUser } from '@va/standalone/shared/selectors';
import { isAppsumoPageSelected } from '@va/util/helpers';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

const FooterWrapper: FC<{}> = () => {
  const sumoUser = useSelector(isSumoUser);

  const presentationLinks = useMemo(() => {
    if (!sumoUser || !isAppsumoPageSelected()) return standalonePresentationLinks;
    return { ...standalonePresentationLinks, facebookGroup: facebookGroup.sumo };
  }, [sumoUser]);

  return <Footer presentationLinks={presentationLinks} />;
};

export default FooterWrapper;
