import { Platform, TEST_IDS } from '@va/constants';
import { useTranslate } from '@va/localization';
import { useConfirmationDialogContext } from '@va/shared/util-confirmation';
import { UserRole } from '@va/types/website';
import { Paragraph, fontWeights } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { useCallback, useMemo } from 'react';
import Switch from 'react-switch';
import useAgencyWhiteLabelContext from '../AgencyWhiteLabelContext';
import WhiteLabelConfirmationModal from '../WhiteLabelConfirmationModal';

const WhiteLabelStatusCell = ({
  whiteLabelEnabled,
  websiteId,
  platform,
  userRole,
}: {
  whiteLabelEnabled: boolean;
  websiteId: string;
  platform: Platform;
  userRole: UserRole;
}) => {
  const translate = useTranslate();
  const { confirm } = useConfirmationDialogContext();
  const isWixWebsite = platform === Platform.WIX;
  const isContributor = userRole !== UserRole.OWNER;

  const { changeWebsiteWhiteLabelStatus, changeWebsiteWhiteLabelStatusLoading } = useAgencyWhiteLabelContext();

  const handleWhiteLabelStatus = useCallback(
    (status: boolean) => {
      changeWebsiteWhiteLabelStatus(websiteId, status);
    },

    [websiteId, changeWebsiteWhiteLabelStatus],
  );

  const handleWhiteLabelStatusChange = useCallback(
    (value: boolean) => {
      if (!value) {
        return confirm(() => {
          handleWhiteLabelStatus(value);
        }, WhiteLabelConfirmationModal);
      }

      return handleWhiteLabelStatus(value);
    },
    [confirm, handleWhiteLabelStatus],
  );

  const tooltipContent = useMemo(() => {
    if (!isContributor && !isWixWebsite) return;
    if (isWixWebsite) return translate('table.manageWebsites.actions.wixWhiteLabelNotAllowed');
    if (isContributor) return translate('tooltips.contributorRole');
  }, [isContributor, isWixWebsite, translate]);

  if (isContributor)
    return (
      <TooltipWrapper content={translate('standalone.contributors.accessInformationNotice')}>
        <Paragraph weight={fontWeights.medium}>{translate('standalone.contributors.noAccessRights')}</Paragraph>
      </TooltipWrapper>
    );

  return (
    <div className='flex items-center'>
      <TooltipWrapper content={tooltipContent} disabled={!tooltipContent}>
        <div>
          <Switch
            handleDiameter={24}
            offHandleColor='#FFFFFF'
            onHandleColor={'#FFFFFF'}
            offColor='#C3C3C3'
            onColor={'#06A25A'}
            onChange={handleWhiteLabelStatusChange}
            checked={whiteLabelEnabled}
            checkedIcon={false}
            uncheckedIcon={false}
            width={48}
            height={30}
            disabled={isWixWebsite || isContributor || changeWebsiteWhiteLabelStatusLoading}
            data-testid={TEST_IDS.generic.whiteLabel.toggleWhiteLabelSwitch}
          />
        </div>
      </TooltipWrapper>
      <span className='ml-4 font-medium text-15 leading-normal text-gray-devil'>
        {translate(whiteLabelEnabled ? 'option.btn.enabled' : 'option.btn.disabled')}
      </span>
    </div>
  );
};

export default WhiteLabelStatusCell;
