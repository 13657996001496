import { useTranslate } from '@va/localization';
import { TabbedButton } from '@va/ui/design-system';
import { useMemo, useState } from 'react';
import AutomaticTrackingCodeInstallation from './AutomaticTrackingCodeInstallation';
import ManualTrackingCodeInstallationView from './ManualTrackingCodeInstallationView';
import { useInstallTrackingCodeContext } from './useTrackingCodeInstallationView';

enum TrackingCodeType {
  manual = 'manual',
  automatic = 'automatic',
}

const TrackingCodeStep = () => {
  const translate = useTranslate();
  const { onSuccess, onError } = useInstallTrackingCodeContext();
  const [installationType, setInstallationType] = useState(TrackingCodeType.automatic);

  const domainTabs = useMemo(
    () => [
      {
        label: translate('onboarding.tabs.trackingCodeInstallation.automatic'),
        value: TrackingCodeType.automatic,
        className: '!h-12',
      },
      {
        label: translate('onboarding.tabs.trackingCodeInstallation.manual'),
        value: TrackingCodeType.manual,
        className: '!h-12',
      },
    ],
    [translate],
  );

  return (
    <div className='flex flex-col items-center px-18px sm-initial:mx-60px my-6 sm-intial:my-90px'>
      <div className='flex flex-col w-full items-center'>
        <TabbedButton
          buttonOptions={domainTabs}
          onChange={(value: TrackingCodeType) => setInstallationType(value)}
          selectedValue={installationType}
          className='max-w-[540px] flex-col sm-initial:flex-row !h-full'
        />
        {installationType === TrackingCodeType.manual && (
          <ManualTrackingCodeInstallationView onError={onError} onSuccess={onSuccess} />
        )}
      </div>
      {installationType === TrackingCodeType.automatic && <AutomaticTrackingCodeInstallation />}
    </div>
  );
};

export default TrackingCodeStep;
