import { themes, validationTranslationKeys } from '@va/constants';
import { CLOSE_MODAL, SET_CARD_VIEW, addNotification } from '@va/dashboard/actions/ui';
import { getCardView, isModalOpen as modalOpen } from '@va/dashboard/selectors/ui';
import ModalWrapper from '@va/dashboard/shared/modals/ModalWrapper';
import PopUpCard from '@va/deprecated/components/PopUpCard';
import LoadingCard from '@va/deprecated/components/PopUpCard/card-types/LoadingCard';
import { useTranslate } from '@va/localization';
import { GET_AGENCY_CUSTOMER_REQUEST } from '@va/standalone/shared/actions';
import { createAgencyCustomer, updateAgencyCustomer } from '@va/standalone/shared/api-client/billing';
import { modalName } from '@va/standalone/shared/constants';
import { getUserId } from '@va/standalone/shared/helpers';
import { getApiRequest } from '@va/standalone/shared/selectors';
import { SelectDropdown, SelectDropdownOption, TextInput } from '@va/ui/design-system';
import {
  getBusinessSectorOptions,
  getBusinessSizeOptions,
  getBusinessTypeOptions,
  getCountryOptions,
  renderIf,
} from '@va/util/helpers';
import { Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MessageCard from 'standalone/components/cards/PopUpCard/card-types/MessageCard';
import { object, string } from 'yup';
import ModalFooter from '../common/components/modal-footer';
import './BusinessInfo.scss';

const formFieldNames = {
  firstName: 'firstName',
  lastName: 'lastName',
  companyName: 'companyName',
  country: 'country',
  vat: 'vat',
  businessType: 'businessType',
  businessSize: 'businessSize',
  businessSector: 'businessSector',
  companyWebsite: 'companyWebsite',
  state: 'state',
  street: 'street',
  zipcode: 'zipcode',
  city: 'city',
};

enum businessInfoModalView {
  initial = 'initial',
  messageView = 'messageView',
}

const BusinessInfo = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state) => modalOpen(state, modalName.BUSINESS_INFO));
  const agencyCustomerInfo = useSelector((state) => getApiRequest(state, 'getAgencyCustomer'));

  const formInitialValues = {
    firstName: agencyCustomerInfo.get(formFieldNames.firstName) || '',
    lastName: agencyCustomerInfo.get(formFieldNames.lastName) || '',
    companyName: agencyCustomerInfo.get(formFieldNames.companyName) || '',
    country: agencyCustomerInfo.get(formFieldNames.country) || '',
    vat: agencyCustomerInfo.get(formFieldNames.vat) || '',
    state: agencyCustomerInfo.get(formFieldNames.state) || '',
    city: agencyCustomerInfo.get(formFieldNames.city) || '',
    zipcode: agencyCustomerInfo.get(formFieldNames.zipcode) || '',
    street: agencyCustomerInfo.get(formFieldNames.street) || '',
    companyWebsite: agencyCustomerInfo.get(formFieldNames.companyWebsite) || '',
    businessSector: parseInt(agencyCustomerInfo.get(formFieldNames.businessSector)) || '',
    businessType: parseInt(agencyCustomerInfo.get(formFieldNames.businessType)) || '',
    businessSize: parseInt(agencyCustomerInfo.get(formFieldNames.businessSize)) || '',
  };

  const modalView = useSelector((state) => getCardView(state, modalName.BUSINESS_INFO));

  const renderInitialView = renderIf(businessInfoModalView.initial === modalView);
  const renderMessageView = renderIf(businessInfoModalView.messageView === modalView);

  function handleModaViewSwitch(view: businessInfoModalView, previousView?: businessInfoModalView) {
    dispatch({
      type: SET_CARD_VIEW,
      cardName: modalName.BUSINESS_INFO,
      view,
      previousView,
    });
  }

  function handleCancelClick() {
    dispatch({ type: CLOSE_MODAL, modalName: modalName.BUSINESS_INFO });
    handleModaViewSwitch(businessInfoModalView.initial);
  }

  function handleFormSubmission(values: Object, { setSubmitting }: { setSubmitting: (bool: boolean) => void }) {
    const userId = getUserId();
    const agencyId = agencyCustomerInfo.get('id');
    const agencyInfoCall = agencyId
      ? updateAgencyCustomer(userId, agencyCustomerInfo.get('id'), values)
      : createAgencyCustomer(userId, values);
    agencyInfoCall
      .then(() => {
        setSubmitting(false);
        dispatch({ type: GET_AGENCY_CUSTOMER_REQUEST });
        handleModaViewSwitch(businessInfoModalView.messageView, businessInfoModalView.initial);
      })
      .catch(() => {
        setSubmitting(false);
        dispatch(
          addNotification({
            title: translate('notifications.generalError'),
            level: 'error',
            autoDismiss: 10,
          }),
        );
      });
  }

  const businessSectorOptions: SelectDropdownOption<number>[] = useMemo(
    () => getBusinessSectorOptions(translate),
    [translate],
  );

  const getSelectedBusinessSector = useCallback(
    (businessSectorId: number | string) => {
      return businessSectorOptions.find(
        (option) =>
          Number(option.value) ===
          (typeof businessSectorId === 'string' ? Number.parseInt(businessSectorId) : businessSectorId),
      );
    },
    [businessSectorOptions],
  );

  const businessTypeOptions = useMemo(() => getBusinessTypeOptions(translate), [translate]);
  const getSelectedBusinessType = useCallback(
    (businessTypeId: number | string) => {
      return businessTypeOptions.find((option) => Number(option.value) === Number(businessTypeId));
    },
    [businessTypeOptions],
  );

  const businessSizeOptions = useMemo(() => getBusinessSizeOptions(translate), [translate]);
  const getSelectedBusinessSize = useCallback(
    (businessSizeId: number | string) => {
      return businessSizeOptions.find((option) => Number(option.value) === Number(businessSizeId));
    },
    [businessSizeOptions],
  );

  const countryOptions: SelectDropdownOption<string>[] = getCountryOptions(translate);
  const getSelectedCountry = useCallback(
    (countryCode: string) => {
      return countryOptions.find((option) => option.value === countryCode);
    },
    [countryOptions],
  );

  return (
    <ModalWrapper
      closeModal={handleCancelClick}
      isOpen={isModalOpen}
      showCloseButton={false}
      className={'business-info-wrapper'}
      showCloseIconWithBorder
    >
      <React.Fragment>
        {renderInitialView(
          <Formik
            enableReinitialize
            initialValues={formInitialValues}
            validationSchema={getBusinessInfoSchema(translate)}
            onSubmit={handleFormSubmission}
          >
            {({ handleSubmit, handleChange, isSubmitting, values, errors, setFieldValue, isValid, dirty }) => {
              return isSubmitting ? (
                <LoadingCard />
              ) : (
                <PopUpCard
                  type={'card-grey'}
                  title={translate('modal.businessInfo.title')}
                  colorTheme={themes.NO_THEME}
                  smallerBottomSpace
                  noPadding
                  footerButtons={
                    <ModalFooter
                      isSubmitDisabled={!isValid || !dirty}
                      onSubmitClick={handleSubmit}
                      onCancelClick={handleCancelClick}
                      submitBtnText={translate('button.save')}
                    />
                  }
                >
                  <div className='space-y-3 py-3 px-5'>
                    <TextInput
                      required
                      error={errors.companyName as string}
                      name={formFieldNames.companyName}
                      label={translate('form.placeholders.companyName')}
                      onChange={handleChange}
                      value={values.companyName}
                      clearField={() => {
                        setFieldValue(formFieldNames.companyName, '');
                      }}
                    />
                    <TextInput
                      required
                      error={errors.firstName as string}
                      name={formFieldNames.firstName}
                      label={translate('form.placeholders.firstName')}
                      onChange={handleChange}
                      value={values.firstName}
                      clearField={() => {
                        setFieldValue(formFieldNames.firstName, '');
                      }}
                    />
                    <TextInput
                      required
                      error={errors.lastName as string}
                      name={formFieldNames.lastName}
                      label={translate('form.placeholders.lastName')}
                      onChange={handleChange}
                      value={values.lastName}
                      clearField={() => {
                        setFieldValue(formFieldNames.lastName, '');
                      }}
                    />
                    <TextInput
                      error={errors.companyWebsite as string}
                      name={formFieldNames.companyWebsite}
                      label={translate('form.placeholders.companyWebsite')}
                      onChange={handleChange}
                      value={values.companyWebsite}
                      clearField={() => {
                        setFieldValue(formFieldNames.companyWebsite, '');
                      }}
                    />
                    <TextInput
                      error={errors.vat as string}
                      name={formFieldNames.vat}
                      label={translate('form.placeholders.vatIdentificationNumber')}
                      onChange={handleChange}
                      value={values.vat}
                      clearField={() => {
                        setFieldValue(formFieldNames.vat, '');
                      }}
                    />

                    <SelectDropdown
                      label={translate('dropdown.businessData.businessType.placeholder')}
                      selectedOption={getSelectedBusinessType(values.businessType)}
                      dropdownOptions={businessTypeOptions}
                      onChange={(option) => {
                        setFieldValue(formFieldNames.businessType, option.value);
                      }}
                      error={errors.businessType}
                    />

                    <SelectDropdown
                      label={translate('dropdown.businessData.businessSize.placeholder')}
                      selectedOption={getSelectedBusinessSize(values.businessSize)}
                      dropdownOptions={businessSizeOptions}
                      onChange={(option) => {
                        setFieldValue(formFieldNames.businessSize, option.value);
                      }}
                      error={errors.businessSize}
                    />

                    <SelectDropdown
                      label={translate('dropdown.businessData.businessSector.placeholder')}
                      selectedOption={getSelectedBusinessSector(values.businessSector)}
                      dropdownOptions={businessSectorOptions}
                      onChange={(option) => {
                        setFieldValue(formFieldNames.businessSector, option.value);
                      }}
                      error={errors.businessSector}
                    />
                    <div className='grid grid-cols-2 gap-3'>
                      <TextInput
                        error={errors.street as string}
                        name={formFieldNames.street}
                        label={translate('form.placeholders.streetAndNumber')}
                        onChange={handleChange}
                        value={values.street}
                        clearField={() => {
                          setFieldValue(formFieldNames.street, '');
                        }}
                      />
                      <TextInput
                        error={errors.zipcode as string}
                        name={formFieldNames.zipcode}
                        label={translate('form.placeholders.zipCode')}
                        onChange={handleChange}
                        value={values.zipcode}
                        clearField={() => {
                          setFieldValue(formFieldNames.zipcode, '');
                        }}
                      />
                      <TextInput
                        error={errors.city as string}
                        name={formFieldNames.city}
                        label={translate('form.placeholders.city')}
                        onChange={handleChange}
                        value={values.city}
                        clearField={() => {
                          setFieldValue(formFieldNames.city, '');
                        }}
                      />
                      <TextInput
                        error={errors.state as string}
                        name={formFieldNames.state}
                        label={translate('form.placeholders.state')}
                        onChange={handleChange}
                        value={values.state}
                        clearField={() => {
                          setFieldValue(formFieldNames.state, '');
                        }}
                      />
                    </div>
                    <SelectDropdown
                      label={translate('form.placeholders.country')}
                      selectedOption={getSelectedCountry(values.country)}
                      dropdownOptions={countryOptions}
                      onChange={(option) => {
                        setFieldValue(formFieldNames.country, option.value);
                      }}
                      error={errors.country as string}
                      required
                      comparisonType='match'
                    />
                  </div>
                </PopUpCard>
              );
            }}
          </Formik>,
        )}
        {renderMessageView(
          <MessageCard
            modalName={modalName.BUSINESS_INFO}
            translate={translate}
            onClose={handleCancelClick}
            confirmationView
          />,
        )}
      </React.Fragment>
    </ModalWrapper>
  );
};

function getBusinessInfoSchema(translate: Function) {
  const requiredText = translate(validationTranslationKeys.required);

  return object().shape({
    firstName: string().required(requiredText),
    lastName: string().required(requiredText),
    companyName: string().required(requiredText),
    country: string().required(requiredText),
    vat: string(),
    companyWebsite: string(),
    state: string(),
    city: string(),
    zipcode: string(),
    street: string(),
  });
}

export default BusinessInfo;
