import { openModal } from '@va/dashboard/actions/ui';
import { useTranslate } from '@va/localization';
import { acceptContributorRole, rejectContributorRole } from '@va/standalone/shared/actions';
import { modalName } from '@va/standalone/shared/constants';
import { Button, Heading3, Paragraph } from '@va/ui/design-system';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

const PendingContributorRequest = ({ requestId, userRole }) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const onAcceptContributorRole = () => {
    dispatch(openModal(modalName.ACCEPT_CONTRIBUTOR_REQUEST));
    dispatch(acceptContributorRole(requestId, userRole));
  };

  const onRejectContributorRole = () => {
    dispatch(openModal(modalName.REJECT_CONTRIBUTOR_REQUEST));
    dispatch(rejectContributorRole(requestId, userRole));
  };

  return (
    <div className='p-6 w-full h-full max-w-3xl mx-auto text-center'>
      <Heading3>{translate('table.manageWebsites.pendingContributor.title')}</Heading3>
      <Paragraph>{translate('table.manageWebsites.pendingContributor.subTitle')}</Paragraph>
      <div className='flex justify-evenly flex-wrap mt-4'>
        <Button
          onClick={onAcceptContributorRole}
          text={translate('table.manageWebsites.pendingContributor.button.accept')}
        />
        <div onClick={onRejectContributorRole} className='cursor-pointer flex-center xxs:mt-2'>
          {translate('table.manageWebsites.pendingContributor.button.decline')}
        </div>
      </div>
    </div>
  );
};

export default PendingContributorRequest;

PendingContributorRequest.propTypes = {
  requestId: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
};
