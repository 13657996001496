import { LoaderIcon } from '@va/icons';
import { useTranslate } from '@va/localization';

type LoadingLogoProps = {
  color?: string;
  titleTransKey?: string;
  subTitleTransKey?: string;
};

export function LoadingLogo(props: LoadingLogoProps) {
  const { color, titleTransKey, subTitleTransKey } = props;
  const translate = useTranslate();
  return (
    <div className='flex flex-col justify-center items-center absolute inset-t-1/2 inset-r-1/2 w-full h-full'>
      <LoaderIcon color={color || 'var(--color-primary)'} />
      <div className='flex text-center font-semibold text-lg leading-6 text-gray-charcoal mt-4'>
        {titleTransKey ? translate(titleTransKey) : translate('loadingLogo.title')}
      </div>
      <div className='font-medium text-15 text-gray-devil leading-6 px-4 text-center'>
        {subTitleTransKey ? translate(subTitleTransKey) : translate('loadingLogo.subTitle')}
      </div>
    </div>
  );
}
