import { IconProps } from '.';

export const CollectingDataIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M14.25 3C14.25 4.24264 13.2426 5.25 12 5.25C10.7574 5.25 9.75 4.24264 9.75 3C9.75 1.75736 10.7574 0.75 12 0.75C13.2426 0.75 14.25 1.75736 14.25 3ZM23.25 12C23.25 13.2426 22.2426 14.25 21 14.25C19.7574 14.25 18.75 13.2426 18.75 12C18.75 10.7574 19.7574 9.75 21 9.75C22.2426 9.75 23.25 10.7574 23.25 12ZM14.25 21C14.25 22.2426 13.2426 23.25 12 23.25C10.7574 23.25 9.75 22.2426 9.75 21C9.75 19.7574 10.7574 18.75 12 18.75C13.2426 18.75 14.25 19.7574 14.25 21ZM5.25 12C5.25 13.2426 4.24264 14.25 3 14.25C1.75736 14.25 0.75 13.2426 0.75 12C0.75 10.7574 1.75736 9.75 3 9.75C4.24264 9.75 5.25 10.7574 5.25 12Z'
        stroke={color}
        strokeWidth='1.5'
      />
    </svg>
  );
};
