import { IconProps } from '.';

export const RightArrowIcon: React.FC<IconProps> = ({ color = 'black', className }) => {
  return (
    <svg
      className={className}
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17 9.25C17 5.10786 13.6421 1.75 9.5 1.75C5.35786 1.75 2 5.10786 2 9.25C2 13.3921 5.35786 16.75 9.5 16.75C13.6421 16.75 17 13.3921 17 9.25ZM18.5 9.25C18.5 4.27944 14.4706 0.249999 9.5 0.25C4.52944 0.25 0.499999 4.27944 0.5 9.25C0.5 14.2206 4.52944 18.25 9.5 18.25C14.4706 18.25 18.5 14.2206 18.5 9.25ZM5.59998 10L11.2893 10L9.56964 11.7197L10.6303 12.7803L13.6303 9.78033L14.1606 9.25L13.6303 8.71967L10.6303 5.71967L9.56964 6.78033L11.2893 8.5L5.59998 8.5L5.59998 10Z'
        fill={color}
      />
    </svg>
  );
};
