import { validationTranslationKeys } from '@va/constants';
import PopUpCard from '@va/deprecated/components/PopUpCard';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { object, string } from 'yup';

import { TextInput } from '@va/ui/design-system';
import ModalFooter from 'standalone/components/modals/common/components/modal-footer';

export default class EnterNewEmail extends PureComponent {
  constructor(props) {
    super(props);

    this.formInitialValues = {
      email: null,
    };

    this.formFieldNames = {
      email: 'email',
    };

    this.schema = object().shape({
      email: string()
        .nullable()
        .email(validationTranslationKeys.invalidEmail)
        .required(validationTranslationKeys.required),
    });
  }

  handleFormSubmission = (values) => {
    const { onSubmit } = this.props;
    onSubmit(values);
  };

  render() {
    const { translate, closeModal } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={this.formInitialValues}
        onSubmit={(values) => this.handleFormSubmission(values)}
        validationSchema={this.schema}
      >
        {({ handleSubmit, isValid, handleChange, values, errors, touched, setFieldValue, handleBlur }) => {
          return (
            <form name='change-email' onSubmit={handleSubmit}>
              <PopUpCard
                type='card-grey'
                title={translate('modal.changeEmail.newEmail.title')}
                invalid={!isValid}
                colorTheme={'no-theme'}
                smallerBottomSpace
                noPadding
                footerButtons={
                  <ModalFooter
                    onSubmitClick={handleSubmit}
                    onCancelClick={closeModal}
                    submitBtnText={translate('button.changeEmail')}
                    isSubmitDisabled={!isValid}
                  />
                }
              >
                <div className='change-email-content p-4 md:p-8'>
                  <TextInput
                    name={this.formFieldNames.email}
                    placeholder={translate('register.form.email.placeholder')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    clearField={() => setFieldValue(this.formFieldNames.email, '')}
                    value={values[this.formFieldNames.email]}
                    error={errors.email && touched.email ? translate(errors.email) : undefined}
                    autoFocus
                  />
                </div>
              </PopUpCard>
            </form>
          );
        }}
      </Formik>
    );
  }
}

EnterNewEmail.propTypes = {
  translate: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
