import {
  GET_ACCOUNT_INFORMATION_REQUEST,
  SET_USER_FLAG_REQUEST,
  getAccountInformationFailed,
  getAccountInformationSucceeded,
  setUserFlagFailed,
  setUserFlagsSucceeded,
} from '@va/dashboard/actions/api';
import Api from '@va/dashboard/api-client/index';
import { getVouchers, getVouchersInfo } from '@va/standalone/shared/actions';
import { accountType, storageItems } from '@va/standalone/shared/constants';
import { LocalStorage } from '@va/util/helpers';
import { all, call, put, takeLatest } from 'redux-saga/effects';

export function* watchers() {
  yield all([
    takeLatest(SET_USER_FLAG_REQUEST, setUserFlag),
    takeLatest(GET_ACCOUNT_INFORMATION_REQUEST, getAccountInformationSaga),
  ]);
}

function* setUserFlag(action) {
  try {
    const response = yield call(Api.setUserFlag, {
      label: action.label,
      value: action.value,
    });
    yield put(setUserFlagsSucceeded(response));
    const data = yield call(Api.getAccountInformation, {});
    yield put(getAccountInformationSucceeded(data));
  } catch (error) {
    yield put(setUserFlagFailed(error));
  }
}

export function* getAccountInformationSaga() {
  try {
    const data = yield call(Api.getAccountInformation, {});
    yield put(getAccountInformationSucceeded(data));
    const userEmail = data.email;
    LocalStorage.setItem(storageItems.userEmail, userEmail);

    if (data.type === accountType.SUMO) {
      yield put(getVouchers());
      yield put(getVouchersInfo());
    }
  } catch (error) {
    yield put(getAccountInformationFailed(error));
  }
}
