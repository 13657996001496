import { TEST_IDS } from '@va/constants';
import { LeftCircleArrowIcon, RightArrowIcon } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { Button, HorizontalSeparator, Paragraph, TextInput, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { addNumberSeparator } from '@va/util/helpers';
import classNames from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { SelectItemsPerPageDropdown } from './SelectItemsPerPageDropdown';

type DataTablePaginationProps = {
  total?: number;
  totalPages?: number;
  currentPage: number;
  pageSize: number;
  setCurrentPage: (newPage: number) => void;
  setPageSize: (newPageSize: number) => void;
  canNextPage?: boolean;
  canPreviousPage?: boolean;
  options?: Array<number>;
  showCountInfo?: boolean;
  showPageSizeSelector?: boolean;
  showPaginationBtns?: boolean;
  className?: string;
  size?: 'small' | 'large';
};

export const DataTablePaginationV2 = ({
  total = 0,
  totalPages = 0,
  currentPage,
  pageSize,
  setCurrentPage,
  setPageSize,
  canNextPage,
  canPreviousPage,
  options,
  showCountInfo = true,
  showPageSizeSelector = true,
  showPaginationBtns = true,
  className,
  size = 'large',
}: DataTablePaginationProps) => {
  const translate = useTranslate();
  const { locale } = useLocale();
  const currentPageBtnRef = useRef(null);
  const [isCustomPageSelectorVisible, setCustomPageSelectorVisible] = useState(false);
  const startOffset = total > 0 ? pageSize * currentPage + 1 : total;
  const endOffset = Math.min(pageSize * (currentPage + 1), total);

  const tableHasData = useMemo(() => total > 0, [total]);
  const isPaginationDisabled = useMemo(() => !tableHasData, [tableHasData]);
  const canGoToPreviousPage = useMemo(() => canPreviousPage || currentPage > 0, [canPreviousPage, currentPage]);
  const canGoToNextPage = useMemo(
    () => canNextPage || (currentPage + 1) * pageSize < total,
    [canNextPage, total, pageSize, currentPage],
  );

  const startItemValue = addNumberSeparator(startOffset, locale);
  const endItemValue = addNumberSeparator(endOffset, locale);
  const totalValue = addNumberSeparator(total, locale);
  const countInfoStartEnd = startItemValue !== endItemValue ? `${startItemValue}-${endItemValue}` : startItemValue;
  const countInfo = `${countInfoStartEnd}/${totalValue}`;

  return (
    <div
      className={classNames('flex max-h-12', className, {
        'gap-1.5': size === 'small',
        'gap-18px': size === 'large',
      })}
    >
      {showCountInfo && (
        <div className='flex flex-col justify-center'>
          <Paragraph
            size={size === 'large' ? paragraphSizes.tiny : paragraphSizes.extraTiny}
            className='whitespace-nowrap text-right text-gray-dustySolid !font-525'
          >
            {translate('table.pagination.shownItems')}
          </Paragraph>
          <Paragraph
            size={size === 'large' ? paragraphSizes.tiny : paragraphSizes.extraTiny}
            className='text-right text-gray-devilSolid !font-525'
          >{`${countInfo}`}</Paragraph>
        </div>
      )}
      {showPageSizeSelector && (
        <SelectItemsPerPageDropdown
          pageSize={pageSize}
          setPageSize={setPageSize}
          total={total}
          options={options}
          disabled={!tableHasData}
          size={size}
        />
      )}
      {showPaginationBtns && (
        <div className='flex rounded-12 w-auto shrink-0'>
          <Button
            icon={(_, color) => <LeftCircleArrowIcon color={color} />}
            className='rounded-r-none'
            disabled={!canGoToPreviousPage}
            color='tertiary'
            onClick={() => {
              setCurrentPage(currentPage - 1);
            }}
            tooltip={translate('button.previousPage')}
            size={size}
            data-testid={TEST_IDS.generic.table.prevPageBtn}
          />
          <TooltipWrapper content={translate('button.pageOverview')}>
            <div>
              <Button
                text={addNumberSeparator(currentPage + 1, locale)}
                className={classNames('rounded-none !bg-gray-mercury text-xs', {
                  'w-9 h-9 px-6px': size === 'small',
                })}
                color='tertiary'
                tooltip={
                  <PageSelectorDropdown
                    currentPage={currentPage}
                    setCurrentPage={(page) => setCurrentPage(page - 1)}
                    setCustomPageSelectorVisible={setCustomPageSelectorVisible}
                    totalPages={totalPages}
                  />
                }
                tooltipProps={{
                  tooltipClassNames: '!bg-white !rounded-18 w-[240px] p-2 border-2 border-gray-concrete',
                  arrow: false,
                  open: isCustomPageSelectorVisible,
                  onOpenChange: (open) => setCustomPageSelectorVisible(open),
                  useDefaultStyle: false,
                  trigger: 'click',
                  anchor: currentPageBtnRef?.current ?? undefined,
                  placement: 'bottom',
                  disabled: isPaginationDisabled,
                }}
                disabled={isPaginationDisabled}
                ref={currentPageBtnRef}
                size={size}
                data-testid={TEST_IDS.generic.table.pageSelectorDropdown}
              />
            </div>
          </TooltipWrapper>
          <Button
            icon={(_, color) => <RightArrowIcon color={color} />}
            className='rounded-l-none'
            color='tertiary'
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
            disabled={!canGoToNextPage}
            tooltip={translate('button.nextPage')}
            size={size}
            data-testid={TEST_IDS.generic.table.nextPageBtn}
          />
        </div>
      )}
    </div>
  );
};

const PageSelectorDropdown = ({
  currentPage,
  setCurrentPage,
  setCustomPageSelectorVisible,
  totalPages = 0,
}: {
  currentPage: number;
  setCurrentPage: (value: number) => void;
  setCustomPageSelectorVisible: (value: boolean) => void;
  totalPages?: number;
}) => {
  const translate = useTranslate();
  const { locale } = useLocale();
  const [isFormActive, setFormActive] = useState(false);
  const [pageNumber, setPageNumber] = useState<number | null>(null);

  const isPageValid = useMemo(
    () => Number(pageNumber) >= 1 && Number(pageNumber) <= totalPages && Number(pageNumber?.toString().length) <= 5,
    [pageNumber, totalPages],
  );

  return (
    <div className='bg-white flex flex-col' data-testid={TEST_IDS.generic.table.pageSelectorTooltip}>
      <Paragraph
        size={paragraphSizes.tiny}
        className='text-center !font-525 mb-1.5'
        colorClassName='text-gray-charcoal'
      >
        {translate('table.pagination.totalPages', { count: addNumberSeparator(totalPages, locale) })}
      </Paragraph>
      <TextInput
        type='number'
        label={translate('table.pagination.enterPage')}
        placeholder={translate('table.pagination.pageNumberPlaceholder')}
        value={pageNumber ?? ''}
        clearField={() => setPageNumber(null)}
        onChange={(e) => setPageNumber(Number(e.target.value))}
        onFocusCapture={() => setFormActive(true)}
        dataTestId={TEST_IDS.generic.table.pageSelectorInputField}
      />
      <HorizontalSeparator className={'my-[11.5px] -translate-x-2'} />
      {!isFormActive && (
        <>
          <Button
            text={translate('table.pagination.goToFirstPage')}
            color='tertiary'
            disabled={currentPage === 0}
            onClick={() => {
              setCurrentPage(1);
              setCustomPageSelectorVisible(false);
            }}
            data-testid={TEST_IDS.generic.table.goToFirstPageBtn}
          />
          <Button
            text={translate('table.pagination.goToLastPage')}
            color='tertiary'
            className='mt-6px'
            disabled={currentPage + 1 === totalPages}
            onClick={() => {
              setCurrentPage(totalPages);
              setCustomPageSelectorVisible(false);
            }}
            data-testid={TEST_IDS.generic.table.goToLastPageBtn}
          />
        </>
      )}
      {isFormActive && (
        <>
          <Button
            text={translate('button.submit')}
            disabled={pageNumber === 0 || !isPageValid}
            onClick={() => [setCurrentPage(pageNumber ?? 0), setPageNumber(null), setCustomPageSelectorVisible(false)]}
            tooltip={!isPageValid && translate('tablePagination.page.notExist')}
            data-testid={TEST_IDS.generic.buttons.submit}
          />
          <Button
            text={translate('button.cancel')}
            color='tertiary'
            className='mt-6px'
            onClick={() => [setPageNumber(null), setFormActive(false)]}
            data-testid={TEST_IDS.generic.buttons.cancel}
          />
        </>
      )}
    </div>
  );
};
