import { updateWebsiteFlags } from '@va/dashboard/actions/api';
import { startUpgradeProcess } from '@va/dashboard/actions/app';
import { getApiRequestImmutable } from '@va/dashboard/selectors/api';
import { getCurrentPlanName } from '@va/dashboard/selectors/app';
import Translation from '@va/deprecated/components/Translation';
import { useLocale } from '@va/localization';
import { subscriptionStatus } from '@va/standalone/shared/constants';
import { Close } from '@va/svg-visa-icons';
import { addNumberSeparator } from '@va/util/helpers';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import './UpgradeToHigherPackageBadge.scss';

const UpgradeToHigherPackageBadge = ({ textContent }) => {
  const dispatch = useDispatch();
  const { locale } = useLocale();
  const currentPlanName = useSelector((state) => getCurrentPlanName(state, subscriptionStatus.ACTIVE));
  const permissions = useSelector((state) => getApiRequestImmutable(state, 'getFeaturePermissions'));

  const onCloseClick = () =>
    dispatch(
      updateWebsiteFlags({
        dontShowUpgradeToHigherPackage: true,
      }),
    );

  return (
    <div className='upgrade-to-higher-plan-badge'>
      <div className='text flex-center'>
        {textContent || (
          <Translation
            translationKey={'topNav.badge.upgradeBehaviourFeature'}
            onClick={() => dispatch(startUpgradeProcess())}
            parameters={{
              currentPlanName: currentPlanName,
              currentRecordingsCount: addNumberSeparator(permissions.getIn(['recordings', 'limit'], 0), locale),
              currentHeatmapsCount: addNumberSeparator(permissions.getIn(['heatmaps', 'limit'], 0), locale),
              currentFunnelsCount: addNumberSeparator(permissions.getIn(['funnels', 'limit'], 0), locale),
              currentPollsCount: addNumberSeparator(permissions.getIn(['polls', 'limit'], 0), locale),
              currentSurveysCount: addNumberSeparator(permissions.getIn(['surveys', 'limit'], 0), locale),
            }}
          />
        )}
      </div>
      <div className='close-icon-wrapper' onClick={onCloseClick}>
        <Close className='close-icon' />
      </div>
    </div>
  );
};

UpgradeToHigherPackageBadge.propTypes = {
  textContent: PropTypes.element,
};

export default UpgradeToHigherPackageBadge;
