import { TEST_IDS } from '@va/constants';
import { useTranslate } from '@va/localization';
import { FilterActionButtons, useFiltersContext } from '@va/shared/feature-filters';
import { Paragraph, paragraphSizes } from '@va/ui/design-system';
import { getResponsiveTooltipHeight } from '@va/util/helpers';
import { useSubmitOnEnter, useWindowDimensions } from '@va/util/hooks';
import { useCallback, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { EditFilterTemplateTab } from './EditFilterTemplateTab';
import { TemplateListItem } from './TemplateListItem';
import { useGetFilterTemplates } from './api';

export const FilterTemplatesTab = ({ closeDropdown }: { closeDropdown: () => void }) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);
  const { reportBlockId, applyConditions } = useFiltersContext();

  const { data = [], mutate, isLoading } = useGetFilterTemplates(reportBlockId);

  const translate = useTranslate();

  const selectedTemplate = useMemo(
    () => data?.find((template) => template.id === selectedTemplateId),
    [data, selectedTemplateId],
  );

  const applyTemplate = useCallback(() => {
    if (!selectedTemplate) return;
    applyConditions(selectedTemplate.conditions);
  }, [applyConditions, selectedTemplate]);

  const handleSubmit = useCallback(() => {
    applyTemplate();
    closeDropdown();
  }, [applyTemplate, closeDropdown]);

  useSubmitOnEnter(handleSubmit);

  const { height } = useWindowDimensions();

  if (isEditing && selectedTemplate) {
    return (
      <EditFilterTemplateTab
        onClose={() => closeDropdown()}
        onBackClick={() => {
          setIsEditing(false);
        }}
        refreshList={mutate}
        template={selectedTemplate}
      />
    );
  }

  return (
    <div>
      <ul
        data-testid={TEST_IDS.generic.filters.filterTemplatesList}
        style={{ height: getResponsiveTooltipHeight(height) }}
        className='min-h-[100px] max-h-[250px] overflow-auto scrollbar scrollbar-thumb'
      >
        {isLoading && <Skeleton className='h-72px' count={3} />}
        {!isLoading && data?.length === 0 && (
          <Paragraph className='p-5 text-center' size={paragraphSizes.tiny}>
            {translate('all.filterTemplates.noTemplateCreated')}
          </Paragraph>
        )}
        {!isLoading &&
          data?.map((template) => {
            return (
              <li
                data-testid={TEST_IDS.helpers.createListItemId(template.id)}
                className='overflow-hidden'
                onClick={() => {
                  setSelectedTemplateId(template.id);
                }}
                key={template.id}
              >
                <TemplateListItem
                  onClickEdit={() => {
                    setIsEditing(true);
                    setSelectedTemplateId(template.id);
                  }}
                  selectedTemplateId={selectedTemplateId}
                  template={template}
                />
              </li>
            );
          })}
      </ul>
      <FilterActionButtons onClose={closeDropdown} onSubmit={handleSubmit} />
    </div>
  );
};
