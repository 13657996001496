export type ActiveSubscriptionType = {
  createdAtTs: number;
  id: string;
  planId: string;
  planName: string;
  planType: 'monthly' | 'yearly';
  type: 'paddle' | 'braintree';
  status: string;
  oldSubscriptionPlanId?: string;
  isActiveUntil?: number;
  nextBillingDate?: number;
};

export enum InvoiceIssuer {
  PADDLE = 'paddle',
  BRAINTREE = 'braintree',
}
