export const Analytics = () => {
  return (
    <svg height='24' width='20'>
      <line x1='0' y1='50%' x2='30%' y2='50%' stroke='#fff' strokeWidth='1.5' />
      <line x1='30%' y1='50%' x2='40%' y2='80%' stroke='#fff' strokeWidth='1.5' />
      <line x1='40%' y1='80%' x2='50%' y2='20%' stroke='#fff' strokeWidth='1.5' />
      <line x1='50%' y1='20%' x2='60%' y2='50%' stroke='#fff' strokeWidth='1.5' />
      <line x1='60%' y1='50%' x2='100%' y2='50%' stroke='#fff' strokeWidth='1.5' />
    </svg>
  );
};
