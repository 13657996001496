import { mapChartLabels, mapTooltipLabels } from '@va/dashboard/util-helpers/line-chart';
import { TimePeriod } from '@va/types/time';
import { addTransparencyToHexColor, getPercentageChange } from '@va/util/helpers';
import moment from 'moment';

type DataKeyType = 'current' | 'previous';

export type MappedDataType = ReturnType<typeof mapperFunction>;

export type StatsResponseType = {
  current: {
    results: { [timePeriod: string]: number };
    total: number;
  };
  previous?: {
    results: { [timePeriod: string]: number };
    total: number;
  };
};

export function mapperFunction(data: StatsResponseType, datasetTitle: string, timeUnit: TimePeriod) {
  const labels = getChartLabels(data, timeUnit);
  const total = data.current?.total;
  const previousTotal = data.previous?.total;
  const percentageChange = total && previousTotal && getPercentageChange(previousTotal, total);
  const datasets = data && mapDatasets(data, datasetTitle);
  const currentDataset = datasets[0] ? [datasets[0]] : [];
  const previousDataset = datasets[1] ? [datasets[1]] : [];

  return {
    total,
    previousTotal,
    percentageChange,
    currentLabels: labels.chartLabels.current,
    previousLabels: labels.chartLabels.previous,
    dates: {
      current: labels.tooltipLabels.current,
      previous: labels.tooltipLabels.previous,
    },
    datasets,
    currentDataset,
    previousDataset,
    tooltipDatasets: {
      current: currentDataset ?? [],
      previous: previousDataset ?? [],
    },
  };
}

function getChartLabels(data: StatsResponseType, timeUnit: TimePeriod) {
  const keys = Object.keys(data ?? {});
  const [currentLabels, previousLabels] = keys.map((key) => {
    if (!data) return null;
    const results = data[key as 'current' | 'previous']?.results ?? {};
    const resultsEntries = Object.entries(results);

    const dates = resultsEntries.map(([timeStampString]) => {
      const [from, until] = timeStampString.split('-');
      return {
        from: moment(Number(from) * 1000).format('L'),
        until: moment(Number(until) * 1000).format('L'),
      };
    });
    return dates;
  });

  return {
    tooltipLabels: {
      current: mapTooltipLabels(currentLabels ? currentLabels : [], timeUnit),
      previous: mapTooltipLabels(previousLabels ? previousLabels : [], timeUnit),
    },
    chartLabels: {
      current: mapChartLabels(currentLabels ? currentLabels : []),
      previous: mapChartLabels(previousLabels ? previousLabels : []),
    },
  };
}

function mapDatasets(data: StatsResponseType, cardTitle: string) {
  return Object.keys(data).map((key) => {
    const results = data[key as DataKeyType]?.results;

    const entries = Object.entries(results ?? {});
    const datasetData = entries.map((item) => {
      return item[1];
    }) as number[];

    const color = key === 'previous' ? addTransparencyToHexColor(window.PRIMARY_COLOR, 0.3) : window.PRIMARY_COLOR;

    return {
      label: cardTitle,
      data: datasetData,
      backgroundColor: color,
      borderColor: color,
      isCurrent: key === 'previous' ? false : true,
    };
  });
}
