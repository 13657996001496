import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSendDummyEmail } from '../apiClient';

const useDummyEmail = () => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const [dummyEmail, setDummyEmail] = useState('');
  const { execute, isLoading, isSucceeded, error } = useSendDummyEmail();
  const { showErrorNotification } = useAddNotification();
  const { showSuccessNotification } = useAddNotification();

  useEffect(() => {
    if (isSucceeded) {
      showSuccessNotification(2500, translate('whiteLabel.email.sendDummy.emailSent'));
    }
  }, [dispatch, isSucceeded, showSuccessNotification, translate]);

  useEffect(() => {
    if (error) {
      showErrorNotification();
    }
  }, [error, showErrorNotification]);

  return {
    dummyEmail,
    setDummyEmail,
    sendDummyEmail: execute,
    isSendDummyApiLoading: isLoading,
  };
};

export default useDummyEmail;
