import { transactionStatus } from '@va/standalone/shared/constants';
import moment from 'moment';

export function getTransactionStatus(status: string, amount: number) {
  if (status === transactionStatus.REFUND || amount < 0) return 'refunded';
  if (status === transactionStatus.VOIDED) return 'voided';

  return 'paid';
}

export function getFormattedDate(date: string) {
  return moment(date).format('DD MMM. YYYY');
}
