import { SearchIcon } from '@va/icons';
import { ButtonLoader } from '@va/svg-visa-icons';
import classNames from 'classnames';

type TrackingCodeStatusCardType = {
  codeTrackerIconClass: string;
  codeTrackerIcon: JSX.Element;
  codeTrackerStatus: string;
  handleCodeTrackerClick: () => void;
  isLoading: boolean;
  statusBtnText: string;
  title: string;
};
export const TrackingCodeStatusCard = (props: TrackingCodeStatusCardType) => {
  const {
    codeTrackerIconClass,
    codeTrackerIcon,
    codeTrackerStatus,
    handleCodeTrackerClick,
    isLoading,
    statusBtnText,
    title,
  } = props;
  return (
    <div className='rounded-18 w-full p-3 bg-white-snow flex items-center justify-between'>
      <div className='flex items-center w-1/2 xs:w-10/12	'>
        <div className={classNames('rounded-1/2 w-12 h-12 flex justify-center items-center', codeTrackerIconClass)}>
          {codeTrackerIcon}
        </div>
        <div className='pl-15px w-4/5 xs:w-9/12'>
          <div className='text-12 text-gray-charcoal font-medium overflow-ellipsis overflow-hidden w-full leading-18 '>
            {title}
          </div>
          <div className='text-15 text-gray-charcoal font-medium overflow-ellipsis overflow-hidden w-full leading-6'>
            {codeTrackerStatus}
          </div>
        </div>
      </div>
      <div
        className='w-1/2 h-12 xs:w-12 xs:h-12 bg-white flex items-center justify-center font-medium leading-6 text-15 text-gray-charcoal rounded-xl cursor-pointer hover:bg-white-smoke'
        onClick={handleCodeTrackerClick}
      >
        {isLoading ? (
          <ButtonLoader color={'var(--color-primary)'} />
        ) : (
          <>
            <SearchIcon />
            <span className='xs:hidden ml-3'>{statusBtnText}</span>
          </>
        )}
      </div>
    </div>
  );
};
