import {
  REMOVE_HIGHLIGHTED_RECORDING_ID,
  SET_HIGHLIGHTED_RECORDING_ID,
  SHOW_ALL_TIME_UNSEEN_RECORDINGS,
} from '@va/dashboard/actions/app';
import Immutable from 'immutable';

const initialState: { highlightedRecordingId: string | undefined; showAllUnseenRecordings: boolean } = {
  highlightedRecordingId: undefined,
  showAllUnseenRecordings: false,
};

export const recordingsReducer = (state = Immutable.Map(initialState), action: { payload: any; type: string }) => {
  switch (action.type) {
    case SET_HIGHLIGHTED_RECORDING_ID:
      return state.set('highlightedRecordingId', action.payload);
    case REMOVE_HIGHLIGHTED_RECORDING_ID:
      return state.set('highlightedRecordingId', undefined);
    case SHOW_ALL_TIME_UNSEEN_RECORDINGS:
      return state.set('showAllUnseenRecordings', action.payload);
    default:
      return state;
  }
};
