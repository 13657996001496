import classNames from 'classnames';
import { IconProps } from '.';

export const NotesIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.5 12H14.25C15.4926 12 16.5 10.9926 16.5 9.75V4.5C16.5 2.84315 15.1569 1.5 13.5 1.5H4.5C2.84315 1.5 1.5 2.84315 1.5 4.5V13.5C1.5 15.1569 2.84315 16.5 4.5 16.5H9.75C10.9926 16.5 12 15.4926 12 14.25V13.5V12H13.5ZM13.5 14.25V13.5H14.25C16.3211 13.5 18 11.8211 18 9.75V4.5C18 2.01472 15.9853 0 13.5 0H4.5C2.01472 0 0 2.01472 0 4.5V13.5C0 15.9853 2.01472 18 4.5 18H9.75C11.8211 18 13.5 16.3211 13.5 14.25ZM4.5 5.25C4.5 4.83579 4.83579 4.5 5.25 4.5H12.75C13.1642 4.5 13.5 4.83579 13.5 5.25C13.5 5.66421 13.1642 6 12.75 6H5.25C4.83579 6 4.5 5.66421 4.5 5.25ZM5.25 7.5C4.83579 7.5 4.5 7.83579 4.5 8.25C4.5 8.66421 4.83579 9 5.25 9H9.75C10.1642 9 10.5 8.66421 10.5 8.25C10.5 7.83579 10.1642 7.5 9.75 7.5H5.25Z'
        fill={color ?? 'var(--color-primary)'}
      />
    </svg>
  );
};


