import { LocalEventBus } from '@va/util/misc';
import { PropsWithChildren, createContext, useContext, useMemo } from 'react';

type EventsContext = {
  localEventBus?: LocalEventBus;
};

const EventsContext = createContext<EventsContext>({} as EventsContext);

/**
 * Stores a local EventBus instance
 * Provides the functionality of listening/sending events in a context area,
 * eliminating the necessity to identify and validate global events
 */
export const EventsContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const localEventBus = useMemo(() => new LocalEventBus(), []);

  return <EventsContext.Provider value={{ localEventBus }}>{children}</EventsContext.Provider>;
};

export const useEventsContext = () => useContext(EventsContext);
