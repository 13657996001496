import { storageItems } from '@va/standalone/shared/constants';
import { InvoiceIssuer } from '@va/standalone/shared/types';
import { LocalStorage } from '@va/util/helpers';
import { useFetchData } from '@va/util/hooks';
import { useCallback } from 'react';
import { RawInvoiceType, TransactionStatus } from '@va/dashboard/feature/subscription-settings';

/**
 * @deprecated
 * @see RawInvoiceType in src/website-viewer/components/cards/subscriptionSettings/types.ts
 */
export type InvoicesTypes = {
  planId: string;
  planName: string | null;
  amount: number;
  date: Date;
  dateTime: string;
  receiptUrl: string;
  token: string | null;
  transactionStatus: TransactionStatus;
  type: InvoiceIssuer;
};

export type WebsiteInvoicesTypes = { websiteUrl: string } & InvoicesTypes;

export const useGetWebsiteInvoices = () => {
  const userId = LocalStorage.getItem(storageItems.userId);

  const mapper = useCallback((r: RawInvoiceType[]) => r.map((item) => ({ ...item, date: new Date(item.date) })), []);

  return useFetchData<WebsiteInvoicesTypes[]>(`/users/${userId}/invoices`, {}, mapper);
};
