import { ensureStartBeforeEnd, getLocalizedDate, getTimestampFromIso } from '@va/util/helpers';
import { Dayjs } from 'dayjs';
import moment, { Moment } from 'moment';

export const dateObjToString = (dateArr: [Moment, Moment], locale = 'en') => {
  if (dateArr.length === 2) {
    const [start, end] = ensureStartBeforeEnd(dateArr);
    const from = getLocalizedDate(getTimestampFromIso(start), locale);
    const to = getLocalizedDate(getTimestampFromIso(end), locale);

    return [from, to];
  }

  const [start] = dateArr;
  return getLocalizedDate(getTimestampFromIso(start), locale);
};

export const fromDayjsToMoment = (date: Dayjs): Moment => moment(date.tz(window.timezone, true).toISOString());
