import { CommonFilterParams, Filter, FilterOperators } from '@va/types/filters';
import { IntervalFilterApplied } from './IntervalFilterApplied';
import { IntervalFilterInput } from './IntervalFilterInput';

export type IntervalFilterValue = { min: number; max: number };

export type IntervalFilterInputProps = {
  minLabel: string;
  maxLabel: string;
  placeholder?: string;
  valuesValidator?: (min: number | undefined, max: number | undefined) => string | undefined;
};

type IntervalFilterParams = CommonFilterParams<IntervalFilterValue, IntervalFilterInputProps>;

export class IntervalFilter extends Filter<IntervalFilterValue, IntervalFilterInputProps> {
  constructor(params: IntervalFilterParams) {
    super({
      ...params,
      operator: params.operator || FilterOperators.between,
      input: IntervalFilterInput,
      appliedInput: IntervalFilterApplied,
    });
  }
}
