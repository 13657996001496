import { modalName } from '@va/constants';
import { CLOSE_MODAL } from '@va/dashboard/actions/ui';
import { getProviderName } from '@va/dashboard/selectors/customization';
import { getActiveTab, getModalExtraProps, isModalOpen } from '@va/dashboard/selectors/ui';
import Translation from '@va/deprecated/components/Translation';
import { useTranslate } from '@va/localization';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalWrapper from '../ModalWrapper';
import './TabInformation.scss';

const TabInformation = () => {
  const isTabInfoModalOpen = useSelector((state) => isModalOpen(state, modalName.tabInformation));
  const modalExtraProps = useSelector((state) => getModalExtraProps(state, modalName.tabInformation));
  const tabTooltipTransKey = modalExtraProps && modalExtraProps.get('tabTooltipTransKey');
  const tabSubtitle = modalExtraProps && modalExtraProps.get('tabSubtitle');

  const translate = useTranslate();
  const dispatch = useDispatch();
  const activeTab = useSelector(getActiveTab);
  const providerName = useSelector(getProviderName);

  const tabSubtitleInfo = translate(`tabSubtitle.${activeTab}`, {
    providerName,
  });

  return (
    <ModalWrapper isOpen={isTabInfoModalOpen} showCloseButton={false} className={'tab-information-wrapper'}>
      <React.Fragment>
        <div className='p-2px'>
          <div className='tab-info-header flex justify-center font-black text-lg'>{translate(`tab.${activeTab}`)}</div>
        </div>
        <div className='extra-line'></div>
        <div className='tab-info-body'>
          {(tabSubtitleInfo || tabSubtitle) && (
            <div>
              {tabSubtitle ||
                translate(`tabSubtitle.${activeTab}`, {
                  providerName,
                })}
            </div>
          )}
          <div className='mt-4 p-4 bg-gray-concrete rounded-12'>
            {tabTooltipTransKey ? (
              translate(tabTooltipTransKey)
            ) : (
              <Translation
                translationKey={`tooltipTab.${activeTab}`}
                parameters={Object.assign({ providerName: providerName })}
              />
            )}
          </div>
        </div>
        <div className='extra-line'></div>
        <div className='p-2px'>
          <div
            onClick={() => dispatch({ type: CLOSE_MODAL, modalName: modalName.tabInformation })}
            className='tab-info-footer flex justify-center cursor-pointer text-mine-shaft font-semibold'
          >
            {translate(`button.close`)}
          </div>
        </div>
      </React.Fragment>
    </ModalWrapper>
  );
};

export default TabInformation;
