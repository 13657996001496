import { gridLayout as defaultGridLayout, gridLayoutPredefinedSize } from '@va/constants';
import Config from 'Config';

export const notAuthRoutes = ['/visit-settings', '/generate-impersonate', '/generate-impersonate/'];

export const subscriptionCycleType = {
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
};

export const subscriptionPlanType = {
  FREE: 'free',
  BASIC: 'basic',
  ADVANCED: 'advanced',
  PRO: 'pro',
};

export const packageId = {
  FREE: '',
  BASIC: 'lmozwcvuly',
  ADVANCED: 'premiumPackage2',
  PRO: 'pro',
};

export const permissionPack = {
  FREE: 0,
  BASIC: 1,
  ADVANCED: 2,
  PRO: 3,
};

export const wixDefaultSubscriptionPricePerMonth = {
  [packageId.BASIC]: {
    MONTHLY: 6.99,
    YEARLY: 5.83,
  },
  [packageId.ADVANCED]: {
    MONTHLY: 10.99,
    YEARLY: 9.17,
  },
  [packageId.PRO]: {
    MONTHLY: 22.99,
    YEARLY: 19.17,
  },
};

export const WIX_YEARLY_PACK_PERCENTAGE_FROM_MONTHLY = 83.40486;
export const WIX_ADVANCED_PACK_INCREASE_PERCENTAGE = 157.2246;

export const cycleType = {
  UNKNOWN: 0,
  FREE: 1,
  MONTHLY: 2,
  YEARLY: 3,
};

export const migrationStatus = {
  NONE: 0,
  REQUESTED: 1,
  MIGRATED: 2,
};

export const gridLayout = Object.assign({}, defaultGridLayout, {
  UpgradeToHigherPackageCard: gridLayoutPredefinedSize.settingsNormalCard,
  DirectAccessCard: gridLayoutPredefinedSize.overviewWiderCard,
  MigrationPendingCard: gridLayoutPredefinedSize.settingsDoubleCard,
  MigrationSucceededCard: gridLayoutPredefinedSize.settingsNormalCard,
  SsrSettingsCard: gridLayoutPredefinedSize.settingsNormalCard,
});

export const overviewPremiumCardIds = [
  'conversions',
  'topStartingPages',
  'topVisitedPages',
  'operatingSystems',
  'browsers',
  'resolutions',
  'deviceType',
];

export const WIX_EMBEDDED_SCRIPT_UNIX = Config.embeddedScriptReleaseUnix;
