import { IconProps } from './index';

export const HtmlElementIcon = ({ className = '', color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_11478_114878'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='18'
        height='18'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0 2.25C0 1.83579 0.335786 1.5 0.75 1.5H17.25C17.6642 1.5 18 1.83579 18 2.25C18 2.66421 17.6642 3 17.25 3H0.75C0.335787 3 0 2.66421 0 2.25ZM2.25 6H15.75C16.1642 6 16.5 6.33579 16.5 6.75V11.25C16.5 11.6642 16.1642 12 15.75 12H2.25C1.83579 12 1.5 11.6642 1.5 11.25V6.75C1.5 6.33579 1.83579 6 2.25 6ZM0 6.75C0 5.50736 1.00736 4.5 2.25 4.5H15.75C16.9926 4.5 18 5.50736 18 6.75V11.25C18 12.4926 16.9926 13.5 15.75 13.5H2.25C1.00736 13.5 0 12.4926 0 11.25V6.75ZM4.5 10.5C5.32843 10.5 6 9.82843 6 9C6 8.17157 5.32843 7.5 4.5 7.5C3.67157 7.5 3 8.17157 3 9C3 9.82843 3.67157 10.5 4.5 10.5ZM0.75 15C0.335786 15 0 15.3358 0 15.75C0 16.1642 0.335787 16.5 0.75 16.5H17.25C17.6642 16.5 18 16.1642 18 15.75C18 15.3358 17.6642 15 17.25 15H0.75Z'
          fill='black'
        />
      </mask>
      <g mask='url(#mask0_11478_114878)'>
        <rect x='-3' y='-3' width='24' height='24' fill={color ?? 'var(--color-primary)'} />
      </g>
    </svg>
  );
};


