import { LocalStorage } from '@va/util/helpers';
import { isArray } from 'lodash';
import { useCallback, useState } from 'react';

/** @deprecated use useHiddenKPIs instead, is more flexible in some case */
export const useVisibleKPIs = ({
  reportBlockId,
  allAvailableKPIs,
}: {
  reportBlockId: string;
  allAvailableKPIs: string[];
}) => {
  const getKPIsLocalstorageKey = useCallback(() => `${reportBlockId}-visible-kpis`, [reportBlockId]);

  const getStoredKPIs: () => string[] | undefined = useCallback(() => {
    const storedItems = LocalStorage.getItem(getKPIsLocalstorageKey());
    if (!storedItems || storedItems === 'null') return undefined;
    try {
      const kpis = JSON.parse(storedItems);
      if (isArray(kpis)) {
        return kpis;
      }
      return undefined;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }, [getKPIsLocalstorageKey]);

  const [visibleKPIs, setVisibleKPIs] = useState(getStoredKPIs() ?? allAvailableKPIs);

  const onKPIClick = useCallback(
    (kpi: string) => {
      setVisibleKPIs((prev) => {
        const newItems = prev.includes(kpi) ? prev.filter((element) => element !== kpi) : [...prev, kpi];
        LocalStorage.setItem(getKPIsLocalstorageKey(), JSON.stringify(newItems));
        return newItems;
      });
    },
    [getKPIsLocalstorageKey],
  );

  return { visibleKPIs, onKPIClick };
};

export const useHiddenKPIs = ({ reportBlockId }: { reportBlockId: string }) => {
  const getKPIsLocalstorageKey = useCallback(() => `${reportBlockId}-hidden-kpis`, [reportBlockId]);

  const getStoredKPIs: () => string[] | undefined = useCallback(() => {
    const storedItems = LocalStorage.getItem(getKPIsLocalstorageKey());
    if (!storedItems || storedItems === 'null') return undefined;
    try {
      const kpis = JSON.parse(storedItems);
      if (isArray(kpis)) {
        return kpis;
      }
      return undefined;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }, [getKPIsLocalstorageKey]);

  const [hiddenKPIs, setHiddenKPIs] = useState(getStoredKPIs() ?? []);

  const toggleKpiVisibility = useCallback(
    (kpi: string) => {
      setHiddenKPIs((prev) => {
        const newItems = prev.includes(kpi) ? prev.filter((element) => element !== kpi) : [...prev, kpi];
        LocalStorage.setItem(getKPIsLocalstorageKey(), JSON.stringify(newItems));
        return newItems;
      });
    },
    [getKPIsLocalstorageKey],
  );

  return { hiddenKPIs, toggleKpiVisibility };
};
