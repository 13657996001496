import { makeAction } from '@va/store';

export const GET_DYNAMIC_PAGES = 'GET_DYNAMIC_PAGES';
export const GET_DYNAMIC_PAGES_SUCCEEDED = 'GET_DYNAMIC_PAGES_SUCCEEDED';
export const GET_DYNAMIC_PAGES_FAILED = 'GET_DYNAMIC_PAGES_FAILED';

export const ADD_DYNAMIC_PAGE = 'ADD_DYNAMIC_PAGE';
export const ADD_DYNAMIC_PAGE_SUCCEEDED = 'ADD_DYNAMIC_PAGE_SUCCEEDED';
export const ADD_DYNAMIC_PAGE_FAILED = 'ADD_DYNAMIC_PAGE_FAILED';

export const EDIT_DYNAMIC_PAGE = 'EDIT_DYNAMIC_PAGE';
export const EDIT_DYNAMIC_PAGE_SUCCEEDED = 'EDIT_DYNAMIC_PAGE_SUCCEEDED';
export const EDIT_DYNAMIC_PAGE_FAILED = 'EDIT_DYNAMIC_PAGE_FAILED';

export const REMOVE_DYNAMIC_PAGE = 'REMOVE_DYNAMIC_PAGE';
export const REMOVE_DYNAMIC_PAGE_SUCCEEDED = 'REMOVE_DYNAMIC_PAGE_SUCCEEDED';
export const REMOVE_DYNAMIC_PAGE_FAILED = 'REMOVE_DYNAMIC_PAGE_FAILED';

export const getDynamicPages = makeAction(GET_DYNAMIC_PAGES);
export const getDynamicPagesSucceeded = makeAction(GET_DYNAMIC_PAGES_SUCCEEDED, 'data');
export const getDynamicPagesFailed = makeAction(GET_DYNAMIC_PAGES_FAILED, 'error');

export const addDynamicPage = makeAction(ADD_DYNAMIC_PAGE, 'data');
export const addDynamicPageSucceeded = makeAction(ADD_DYNAMIC_PAGE_SUCCEEDED);
export const addDynamicPageFailed = makeAction(ADD_DYNAMIC_PAGE_FAILED, 'error');

export const editDynamicPage = makeAction(EDIT_DYNAMIC_PAGE, 'id', 'data');
export const editDynamicPageSucceeded = makeAction(EDIT_DYNAMIC_PAGE_SUCCEEDED);
export const editDynamicPageFailed = makeAction(EDIT_DYNAMIC_PAGE_FAILED, 'error');

export const removeDynamicPage = makeAction(REMOVE_DYNAMIC_PAGE, 'id');
export const removeDynamicPageSucceeded = makeAction(REMOVE_DYNAMIC_PAGE_SUCCEEDED);
export const removeDynamicPageFailed = makeAction(REMOVE_DYNAMIC_PAGE_FAILED, 'error');
