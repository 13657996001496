export function AddPage(props) {
  const { color, ...restProps } = props;
  const colorValue = color ? color : '#04B5A6';
  return (
    <svg {...restProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='1' y='1' width='16' height='16' rx='3' stroke={colorValue} strokeWidth='2' />
      <rect x='4' y='12' width='6' height='2' rx='1' fill={colorValue} />
      <rect x='4' y='8' width='10' height='2' rx='1' fill={colorValue} />
      <rect x='4' y='4' width='10' height='2' rx='1' fill={colorValue} />
    </svg>
  );
}

export function CheckMarkWithCircle(props) {
  const { color, ...restProps } = props;
  const colorValue = color ? color : '#04B5A6';
  return (
    <svg {...restProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='9' cy='9' r='8' stroke={colorValue} strokeWidth='2' />
      <path d='M5 8.5L8 11.5L13 6.5' stroke={colorValue} strokeWidth='2' />
    </svg>
  );
}
