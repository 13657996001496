import { generateId } from '@va/util/helpers';
import { FormContext } from '@va/util/misc';
import { FieldArray, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import ChoiceOption from './ChoiceOption';

// Determines the fixed number of choices available
// Could become a prop in the future
const NUMBER_OF_CHOICES = 3;

const FixedChoiceEditor = ({ name }) => {
  const { getFieldProps, setFieldValue } = useFormikContext();
  const { value: choices = [] } = getFieldProps(name);

  const formConfig = useContext(FormContext);
  const { isFormDisabled } = formConfig;

  useEffect(() => {
    if (choices.length !== NUMBER_OF_CHOICES) {
      setFieldValue(name, initializeChoices(NUMBER_OF_CHOICES));
    }
  }, [choices.length, name, setFieldValue]);

  return (
    <FieldArray name={name}>
      {() => (
        <div>
          <div className='rounded-lg border-2 border-gray-gallery'>
            {choices.map((item, index) => (
              <ChoiceOption
                isFormDisabled={isFormDisabled}
                key={item.id}
                name={`${name}.${index}.value`}
                className='last:border-0'
              />
            ))}
          </div>
        </div>
      )}
    </FieldArray>
  );
};

FixedChoiceEditor.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FixedChoiceEditor;

const initializeChoices = (amount) => {
  const choices = [];
  for (let i = 0; i < amount; i++) {
    choices.push({
      id: generateId(),
      value: '',
    });
  }
  return choices;
};
