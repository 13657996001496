import { useIsHovering } from '@va/util/hooks';
import React, { useEffect, useState } from 'react';

export const useIsHoveringOnTableRow = (cellRef: React.RefObject<HTMLElement>) => {
  const [tableRowElement, setTableRowElement] = useState<HTMLElement | null | undefined>(null);

  const isHovering = useIsHovering({ current: tableRowElement });

  useEffect(() => {
    if (!cellRef.current) return;
    setTableRowElement(cellRef.current.parentElement?.parentElement);
  }, [cellRef]);

  return isHovering;
};
