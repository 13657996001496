import { UNIDENTIFIED_COUNTRY_CODE } from '@va/constants';
import { isNil } from 'lodash';
import { isDefaultWebsiteId } from '../dashboard';

export const passwordStrengthTransKeys = {
  veryWeak: 'password.strength.veryWeak',
  weak: 'password.strength.weak',
  medium: 'password.strength.medium',
  strong: 'password.strength.strong',
};

export function validateEmail(email: string, allowEmpty = false) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return allowEmpty ? email === '' || re.test(email) : !!(email && re.test(email.trim()));
}

// TODO Double check
export function isWebsiteUrlValid(isWebsitePublished: boolean, websiteUrl: string) {
  // @ts-ignore
  if (isWebsitePublished === 'false') {
    return false;
  }

  return (
    // @ts-ignore
    isWebsitePublished === 'true' &&
    websiteUrl &&
    !(
      websiteUrl === '#' ||
      websiteUrl === '//#' ||
      websiteUrl === 'null' ||
      websiteUrl === '//null' ||
      websiteUrl === null
    )
  );
}

export const getPasswordStrength = (password: string) => {
  if (!password) return 0;
  let strength = 0;

  if (password.match(/[a-z]+/)) {
    strength += 1;
  }
  if (password.match(/[A-Z]+/)) {
    strength += 1;
  }
  if (password.match(/[0-9]+/)) {
    strength += 1;
  }
  if (password.length >= 8 && password.match(/[$@#&!]+/)) {
    strength += 1;
  }

  return strength;
};

export const getPasswordStrengthKey = (password: string) => {
  const passwordStrength = getPasswordStrength(password) * 25;

  let key = '';
  switch (passwordStrength) {
    case 25:
    case 50:
      key = passwordStrengthTransKeys.weak;
      break;
    case 75:
      key = passwordStrengthTransKeys.medium;
      break;
    case 100:
      key = passwordStrengthTransKeys.strong;
      break;
    default:
      key = passwordStrengthTransKeys.veryWeak;
      break;
  }

  return key;
};

export function isValidCountryCode(countryCode: string) {
  if (!countryCode) return false;
  if (countryCode === UNIDENTIFIED_COUNTRY_CODE) return false;
  countryCode = countryCode.toLocaleLowerCase();
  if (['unknown', 'private', 'PRIVATE', 'untr'].includes(countryCode)) return false;
  return true;
}

export const isValidWebsiteId = (websiteId: string) =>
  !isDefaultWebsiteId(websiteId) && !isNil(websiteId) && websiteId !== 'null' && websiteId !== 'undefined';
