import { IconProps } from '.';

export const StackIcon = ({ className, color = '#000000' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.5 0C3.67157 0 3 0.671573 3 1.5H15C15 0.671573 14.3284 0 13.5 0H4.5ZM1.5 4.5C1.5 3.67157 2.17157 3 3 3H15C15.8284 3 16.5 3.67157 16.5 4.5H1.5ZM15 7.5H3C2.17157 7.5 1.5 8.17157 1.5 9V15C1.5 15.8284 2.17157 16.5 3 16.5H15C15.8284 16.5 16.5 15.8284 16.5 15V9C16.5 8.17157 15.8284 7.5 15 7.5ZM3 6C1.34315 6 0 7.34315 0 9V15C0 16.6569 1.34315 18 3 18H15C16.6569 18 18 16.6569 18 15V9C18 7.34315 16.6569 6 15 6H3Z'
        fill={color}
      />
    </svg>
  );
};
