import { TooltipOptions } from '@va/types/tooltip';
import { Button, ButtonColors } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { DropdownArrow } from '@va/util/components';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { SelectActionDropdownContent, SelectActionDropdownOption } from './SelectActionDropdownContent';

type SelectActionDropdownProps = {
  options: SelectActionDropdownOption[];
  icon?: (iconClasses: string, color?: string) => React.ReactNode;
  text?: string;
  showRightSideIcon?: boolean;
  wrapperClassName?: string;
  buttonColor?: ButtonColors;
  tooltipOptions?: TooltipOptions;
  selectedOption?: number | null;
  size?: 'small' | 'large';
  'data-testid-btn'?: string;
};

export const SelectActionDropdown: React.FC<SelectActionDropdownProps> = ({
  options,
  icon,
  text,
  wrapperClassName,
  showRightSideIcon = true,
  buttonColor = 'tertiary',
  tooltipOptions,
  selectedOption,
  size = 'large',
  'data-testid-btn': dataTestIdBtn,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const hideDropdown = useCallback(() => {
    setIsDropdownVisible(false);
  }, []);

  return (
    <TooltipWrapper
      useDefaultStyle={false}
      arrow={false}
      trigger='click'
      interactive
      open={isDropdownVisible}
      onOpenChange={(open) => setIsDropdownVisible(open)}
      content={
        <SelectActionDropdownContent hideDropdown={hideDropdown} options={options} selectedOption={selectedOption} />
      }
      {...tooltipOptions}
    >
      <div onClick={(event) => event.stopPropagation()}>
        <Button
          className={classNames('text-xs', wrapperClassName)}
          color={buttonColor}
          icon={icon}
          text={text}
          data-testid={dataTestIdBtn}
          rightSideIconWrapperCss='!border-0'
          rightSideIcon={
            showRightSideIcon && (
              <DropdownArrow
                iconClassName={classNames('ml-[2px]', {
                  'w-6px': size === 'small',
                  'w-8px': size === 'large',
                })}
                open={isDropdownVisible}
                color='#696969'
              />
            )
          }
          size={size}
        />
      </div>
    </TooltipWrapper>
  );
};
