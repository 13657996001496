import { IconProps } from './index';

export const GlobeIcon = ({ className = '', color }: IconProps) => {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12' cy='12' r='8.25' stroke={color ?? 'var(--color-primary)'} strokeWidth='1.5' />
      <path
        d='M17.625 12C17.625 14.4216 16.97 16.5969 15.9306 18.1559C14.8895 19.7176 13.4925 20.625 12 20.625C10.5075 20.625 9.11047 19.7176 8.06938 18.1559C7.03004 16.5969 6.375 14.4216 6.375 12C6.375 9.57843 7.03004 7.40307 8.06938 5.84405C9.11047 4.28242 10.5075 3.375 12 3.375C13.4925 3.375 14.8895 4.28242 15.9306 5.84405C16.97 7.40307 17.625 9.57843 17.625 12Z'
        stroke={color ?? 'var(--color-primary)'}
        strokeWidth='0.75'
      />
      <path
        d='M14.625 12C14.625 14.4562 14.2926 16.6643 13.7656 18.2454C13.5014 19.0379 13.1951 19.6522 12.8732 20.0606C12.548 20.4733 12.2501 20.625 12 20.625C11.7499 20.625 11.452 20.4733 11.1268 20.0606C10.8049 19.6522 10.4986 19.0379 10.2344 18.2454C9.70741 16.6643 9.375 14.4562 9.375 12C9.375 9.54381 9.70741 7.33571 10.2344 5.75462C10.4986 4.96212 10.8049 4.34784 11.1268 3.93938C11.452 3.52669 11.7499 3.375 12 3.375C12.2501 3.375 12.548 3.52669 12.8732 3.93938C13.1951 4.34784 13.5014 4.96212 13.7656 5.75462C14.2926 7.33571 14.625 9.54381 14.625 12Z'
        stroke={color ?? 'var(--color-primary)'}
        strokeWidth='0.75'
      />
      <path
        d='M12 17.625C9.57843 17.625 7.40307 16.97 5.84405 15.9306C4.28242 14.8895 3.375 13.4925 3.375 12C3.375 10.5075 4.28242 9.11047 5.84405 8.06938C7.40307 7.03003 9.57843 6.375 12 6.375C14.4216 6.375 16.5969 7.03003 18.1559 8.06938C19.7176 9.11047 20.625 10.5075 20.625 12C20.625 13.4925 19.7176 14.8895 18.1559 15.9306C16.5969 16.97 14.4216 17.625 12 17.625Z'
        stroke={color ?? 'var(--color-primary)'}
        strokeWidth='0.75'
      />
      <path
        d='M12 14.625C9.54381 14.625 7.33571 14.2926 5.75462 13.7656C4.96212 13.5014 4.34784 13.1951 3.93938 12.8732C3.5267 12.548 3.375 12.2501 3.375 12C3.375 11.7499 3.5267 11.452 3.93938 11.1268C4.34784 10.8049 4.96212 10.4986 5.75462 10.2344C7.33571 9.70741 9.54381 9.375 12 9.375C14.4562 9.375 16.6643 9.70741 18.2454 10.2344C19.0379 10.4986 19.6522 10.8049 20.0606 11.1268C20.4733 11.452 20.625 11.7499 20.625 12C20.625 12.2501 20.4733 12.548 20.0606 12.8732C19.6522 13.1951 19.0379 13.5014 18.2454 13.7656C16.6643 14.2926 14.4562 14.625 12 14.625Z'
        stroke={color ?? 'var(--color-primary)'}
        strokeWidth='0.75'
      />
    </svg>
  );
};


