import { getTypeOfVisitor } from '@va/util/helpers';
import { useTranslate } from '@va/localization';
import PropTypes from 'prop-types';
import { memo } from 'react';

export const ParticipantCell = memo(function ParticipantCell({ value }) {
  const translate = useTranslate();
  if ([0, 1].includes(value)) return translate(`visitorType.${getTypeOfVisitor(value)}`);
  return value;
});

ParticipantCell.propTypes = {
  value: PropTypes.oneOf([0, 1]),
};
