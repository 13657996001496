import { PropsWithChildren, createContext, useContext } from 'react';

type CtxValues = {
  onKpiDataViewChange: (kpi: string, value: string) => void;
  options: Record<string, string>;
  displayContextMenu?: boolean;
};

const ChartTilesContext = createContext<CtxValues>({ onKpiDataViewChange: () => {}, options: {} });

export const ChartTilesContextProvider = ({
  children,
  options,
  onKpiDataViewChange,
  displayContextMenu,
}: PropsWithChildren<CtxValues>) => {
  return (
    <ChartTilesContext.Provider value={{ onKpiDataViewChange, options, displayContextMenu }}>
      {children}
    </ChartTilesContext.Provider>
  );
};

export const useChartTilesContext = () => {
  return useContext(ChartTilesContext);
};
