import { PropsWithChildren, createContext, useContext, useMemo } from 'react';

type Permissions = {
  canEditFilters: boolean;
  canEditSegments: boolean;
  canEditTitle: boolean;
};

const defaultPermissions: Permissions = { canEditFilters: true, canEditSegments: true, canEditTitle: false };

/**
 * Make sure default values are always added here, this is for the case when the component is rendered without the permissions context on top
 */
const PermissionsContext = createContext<Permissions>(defaultPermissions);

/**
 * Should be an abstract layer over components,
 * in order to eliminate the necessity of accessing redux store directly inside them
 */
export const PermissionsProvider = ({ children, ...rest }: PropsWithChildren<Partial<Permissions>>) => {
  const permissions = useMemo(
    () =>
      Object.keys(defaultPermissions).reduce((acc, key) => {
        const k = key as keyof Permissions;
        acc[k] = rest[k] === undefined ? (defaultPermissions[k] as boolean) : (rest[k] as boolean);
        return acc;
      }, {} as Permissions),
    [rest],
  );

  return <PermissionsContext.Provider value={permissions}>{children}</PermissionsContext.Provider>;
};

export const usePermissionsProvider = () => useContext(PermissionsContext);
