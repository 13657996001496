import { getFullNameFromUserInfo } from '@va/util/helpers';
import { ContributorType } from '../agency-white-label-apiClient';

export function getFormattedContributorLists(contributors: ContributorType[]) {
  return contributors.map((contributor) => ({
    fullName: getFullNameFromUserInfo({
      firstName: contributor.user.firstName,
      lastName: contributor.user.lastName,
      email: contributor.user.email || 'N/A',
    }),
  }));
}
