export const OptOutIcon = ({ color = 'currentColor', ...props }: JSX.IntrinsicElements['svg']) => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 12.75C16.5 13.9926 15.4926 15 14.25 15V3C15.4926 3 16.5 4.00736 16.5 5.25V12.75ZM12.75 16.5H14.25C16.3211 16.5 18 14.8211 18 12.75V5.25C18 3.17893 16.3211 1.5 14.25 1.5H12.75H3.75C1.67893 1.5 0 3.17893 0 5.25V12.75C0 14.8211 1.67893 16.5 3.75 16.5H12.75ZM3.75 3H12.75V8.25H7.81066L9.53033 6.53033L8.46967 5.46967L5.46967 8.46967L4.93934 9L5.46967 9.53033L8.46967 12.5303L9.53033 11.4697L7.81066 9.75H12.75V15H3.75C2.50736 15 1.5 13.9926 1.5 12.75V5.25C1.5 4.00736 2.50736 3 3.75 3Z'
        fill={color}
      />
    </svg>
  );
};
