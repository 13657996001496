import { get, patch, post, remove } from '@va/http-client';
import { storageItems } from '@va/standalone/shared/constants';
import { LocalStorage } from '@va/util/helpers';

export const getAgencyThemes = (userIdValue: string) => {
  const url = `/users/${userIdValue}/agencyui/theme/all`;
  return get(url, {});
};

export const uploadAgencyLogo = (data: Record<string, unknown>) => {
  const userId = LocalStorage.getItem(storageItems['userId']);
  const url = `/users/${userId}/agencyui/logo`;
  return post(url, {}, data);
};

export const deleteAgencyLogo = (agencyId: string) => {
  const userId = LocalStorage.getItem(storageItems['userId']);
  const url = `/users/${userId}/agencyui/${agencyId}/logo`;
  return remove(url, {});
};

export const createAgencyUi = (data: Record<string, unknown>) => {
  const userId = LocalStorage.getItem(storageItems['userId']);
  const url = `/users/${userId}/agencyui`;
  return post(url, {}, data);
};

export const editAgencyUi = (userId: string, data: Record<string, unknown>, id: string) => {
  const url = `/users/${userId}/agencyui/${id}`;
  return patch(url, {}, data);
};

export const getAgencyUi = () => {
  const userId = LocalStorage.getItem(storageItems['userId']);
  const url = `/users/${userId}/agencyui`;
  return get(url, {});
};

export const getWebsiteAgencyUi = (websiteId: string) => {
  const url = `/websites/${websiteId}/agencyui`;
  return get(url, {});
};

export const getCustomUrlAgencyUi = (customUrl: string) => {
  const url = `/agencyui?url=${customUrl}`;
  return get(url, {});
};

export const createAgencyTheme = (userId: string, data: Record<string, unknown>) => {
  const url = `/users/${userId}/agencyui/theme`;
  return post(url, {}, data);
};

export const editAgencyTheme = (userId: string, data: Record<string, unknown>, id: string) => {
  const url = `/users/${userId}/agencyui/theme/${id}`;
  return patch(url, {}, data);
};

export const changeWebsiteWhiteLabelStatus = (websiteId: string, data: Record<string, unknown>) => {
  const url = `/websites/${websiteId}/white-label/status/change`;
  return patch(url, {}, data);
};

type YearlyCommissionResponse = { yearlyCommission: number };
export const getAgencyYearlyCommission: () => Promise<YearlyCommissionResponse> = () => {
  const userId = LocalStorage.getItem(storageItems['userId']);
  const url = `/users/${userId}/agencyui/commission-yearly`;
  return get(url, {}) as Promise<YearlyCommissionResponse>;
};
export const getAgencyQuaterlyCommission = () => {
  const userId = LocalStorage.getItem(storageItems['userId']);
  const url = `/users/${userId}/agencyui/commission-quarterly`;
  return get(url, {});
};
