import { getInstanceId } from '@va/dashboard/selectors/app';
import { isWixWebsite } from '@va/dashboard/selectors/core';
import { patch, post, remove } from '@va/http-client';
import { useAsyncFunction, useFetchData } from '@va/util/hooks';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ReportTypeEnum } from './notification-preferences/useNotificationPreferences';

export const useUpdateUrlView = () => {
  const websiteId = useSelector(getInstanceId);
  const fetcher = useCallback(
    (urlView: string) => patch(`/websites/${websiteId}/frontend-flags`, {}, { urlView }),
    [websiteId],
  );
  return useAsyncFunction<typeof fetcher>(fetcher);
};

export const addWhitelistedDomain = (websiteId: string, domain: string) => {
  return post(`/websites/${websiteId}/whitelisted-domains`, {}, { domain });
};

export const removeWhitelistedDomain = (websiteId: string, domain: string) => {
  return remove(`/websites/${websiteId}/whitelisted-domains/${domain}`);
};

export const useUpdateWhitelistedDomains = () => {
  const websiteId = useSelector(getInstanceId);

  const updateWhitelistedDomains = async (addedDomains: string[] = [], removedDomains: string[] = []) => {
    for (const domain of addedDomains) {
      await addWhitelistedDomain(websiteId, domain);
    }

    for (const domain of removedDomains) {
      await removeWhitelistedDomain(websiteId, domain);
    }
  };

  return useAsyncFunction<typeof updateWhitelistedDomains>(updateWhitelistedDomains);
};

export const addBlacklistedDomain = (websiteId: string, domain: string) => {
  return post(`/v2/websites/${websiteId}/blacklisted-domains`, {}, { domain });
};

export const removeBlacklistedDomain = (websiteId: string, domain: string) => {
  return remove(`/v2/websites/${websiteId}/blacklisted-domains/${domain}`);
};

export const useUpdateBlacklistedDomains = () => {
  const websiteId = useSelector(getInstanceId);

  const updateBlacklistedDomains = async (addedDomains: string[] = [], removedDomains: string[] = []) => {
    for (const domain of addedDomains) {
      await addBlacklistedDomain(websiteId, domain);
    }

    for (const domain of removedDomains) {
      await removeBlacklistedDomain(websiteId, domain);
    }
  };

  return useAsyncFunction<typeof updateBlacklistedDomains>(updateBlacklistedDomains);
};

export const useChangeTimezone = () => {
  const websiteId = useSelector(getInstanceId);
  const isWix = useSelector(isWixWebsite);
  const url = isWix ? `/wix/websites/${websiteId}` : `/websites/${websiteId}`;

  const changeTimezone = useCallback(
    (timezone: string) => {
      return patch(url, {}, { timezone });
    },
    [url],
  );

  return useAsyncFunction<typeof changeTimezone>(changeTimezone);
};

export const useChangeFirstWeekDay = () => {
  const websiteId = useSelector(getInstanceId);
  const isWix = useSelector(isWixWebsite);
  const url = isWix ? `/wix/websites/${websiteId}` : `/websites/${websiteId}`;

  const fetcher = useCallback(
    (firstWeekDay: number) => {
      return patch(url, {}, { firstWeekDay });
    },
    [url],
  );

  return useAsyncFunction<typeof fetcher>(fetcher);
};

export const useUnclaimWebsite = () => {
  const websiteId = useSelector(getInstanceId);

  const fetcher = useCallback(() => {
    return post(`/migrations/websites/${websiteId}/unclaim`, {}, {});
  }, [websiteId]);

  return useAsyncFunction<typeof fetcher>(fetcher);
};

export type NotificationSettingsResponse = {
  payload: NotificationSettings;
};

export type NotificationSettings = {
  reportType: ReportTypeEnum;
  websiteKey: string;
  emailList: EmailItem[];
};

export type EmailItem = {
  value: string;
  verified: boolean;
};

export const useGetNotificationSettings = () => {
  const websiteId = useSelector(getInstanceId);
  const mapper = (data: NotificationSettingsResponse) => data.payload;
  return useFetchData<NotificationSettings, Error>(
    `/v2/websites/${websiteId}/notification-settings`,
    undefined,
    mapper,
  );
};

export const useUpdateNotificationSettings = () => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = (data: { reportType?: ReportTypeEnum; emailList?: EmailItem[] }) =>
    patch(`/v2/websites/${websiteId}/notification-settings`, {}, data);
  return useAsyncFunction(asyncFunc);
};

export const useAddNotificationSettingsEmail = () => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = (data: { email: string }) =>
    post(`/v2/websites/${websiteId}/notification-settings/email`, {}, data);
  return useAsyncFunction(asyncFunc, { throwErrorBack: true });
};

export const useRemoveNotificationSettingsEmail = () => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = (data: { email: string }) =>
    remove(`/v2/websites/${websiteId}/notification-settings/email`, {}, data);
  return useAsyncFunction(asyncFunc);
};

export const useConfirmNotificationSettingsEmail = () => {
  const asyncFunc = (data: { email: string; token: string }, websiteId: string) =>
    post(`/v2/websites/${websiteId}/notification-settings/confirm`, {}, data);
  return useAsyncFunction(asyncFunc);
};

export const useResendNotificationSettingsEmail = () => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = (data: { email: string }) =>
    post(`/v2/websites/${websiteId}/notification-settings/resend-confirmation`, {}, data);
  return useAsyncFunction(asyncFunc);
};

export const useUpdateWebsiteSettings = () => {
  const websiteId = useSelector(getInstanceId);
  const isWix = useSelector(isWixWebsite);
  const asyncFunc = useMemo(
    () => (data: Record<string, unknown>) =>
      patch(isWix ? `/wix/websites/${websiteId}` : `/websites/${websiteId}`, {}, data),
    [isWix, websiteId],
  );
  return useAsyncFunction(asyncFunc);
};

// ------------ Alarming Behavior Settings ------------

type AlarmingBehaviorSettings = {
  payload: {
    websiteKey: string;
    abeEmailList: {
      value: string;
      verified: boolean;
    }[];
    abeActivated: boolean;
    abeWebsiteErrorsThreshold: {
      limit: number;
      interval: number;
    } | null;
    abeNegativeEventsThreshold: {
      limit: number;
      interval: number;
    } | null;
  };
};

type UpdateAlarmingBehaviorSettingsPayload = {
  abeActivated: boolean;
  abeWebsiteErrorsThreshold: {
    limit: number;
    interval: number;
  };
  abeNegativeEventsThreshold: {
    limit: number;
    interval: number;
  };
};

export const useGetAlarmingBehaviorSettings = () => {
  const websiteId = useSelector(getInstanceId);
  return useFetchData<AlarmingBehaviorSettings, Error>(
    `/v2/websites/${websiteId}/abe-notification-settings`,
    undefined,
  );
};

export const useUpdateAlarmingBehaviorSettings = () => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = (data: Partial<UpdateAlarmingBehaviorSettingsPayload>) =>
    patch<AlarmingBehaviorSettings>(`/v2/websites/${websiteId}/abe-notification-settings`, {}, data);
  return useAsyncFunction(asyncFunc);
};

export const useAddAlarmingBehaviorSettingsEmail = () => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = (data: { email: string }) =>
    post<AlarmingBehaviorSettings>(`/v2/websites/${websiteId}/abe-notification-settings/email`, {}, data);
  return useAsyncFunction(asyncFunc, { throwErrorBack: true });
};

export const useRemoveAlarmingBehaviorSettingsEmail = () => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = (data: { email: string }) =>
    remove(`/v2/websites/${websiteId}/abe-notification-settings/email`, {}, data);
  return useAsyncFunction(asyncFunc);
};

export const useConfirmAlarmingBehaviorSettingsEmail = () => {
  const asyncFunc = (data: { email: string; token: string }, websiteId: string) =>
    post<AlarmingBehaviorSettings>(`/v2/websites/${websiteId}/abe-notification-settings/confirm`, {}, data);
  return useAsyncFunction(asyncFunc);
};

export const useResendAlarmingBehaviorSettingsEmail = () => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = (data: { email: string }) =>
    post<AlarmingBehaviorSettings>(`/v2/websites/${websiteId}/abe-notification-settings/resend-confirmation`, {}, data);
  return useAsyncFunction(asyncFunc);
};
