import { TEST_IDS } from '@va/constants';
import { ChartTooltip, LineChartTooltipDataset } from '@va/types/charts';
import { Tooltip, TooltipContent, useTooltipContext } from '@va/ui/tooltips';
import { useWindowDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import { isObject, partition } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { LineChartTooltip, RawTooltipRow } from './LineChartTooltip';
import './external-tooltip.scss';
import { TooltipRow } from './tooltip/TooltipGridRow';

function processDataPoint(initial: { value: string | number; unit: string } | number) {
  if (isObject(initial)) return { ...initial };
  return { value: initial };
}

function partitionDatasets(datasets: Array<LineChartTooltipDataset>, dataIndex: number) {
  return partition(datasets, (dataset) => dataset.isCurrent).map((valuesArray, index) => {
    return valuesArray.reduce((previous, current) => {
      const currentValue: { value: string | number; unit: string; linkTo?: string } | number = current.data[
        dataIndex
      ] as number;
      const returnObject: {
        color?: string;
        value: string | number;
        unit?: string;
        linkTo?: string;
      } = processDataPoint(currentValue);

      if (index === 0) {
        returnObject.color = current.borderColor as string;
        returnObject.linkTo = current.linkTo;
      }
      return { ...previous, [current.label ?? '']: returnObject };
    }, {});
  });
}

export type TooltipTransformerFunction = (datasetsObject: { [key: string]: RawTooltipRow }) => {
  [key: string]: TooltipRow;
};

type ParamTypes = {
  dates: {
    current: ChartTooltip[];
    previous: ChartTooltip[];
  };
  datasets: { current: LineChartTooltipDataset[]; previous: LineChartTooltipDataset[] };
  showPrevious: boolean;
  title?: string;
  subtitle?: string;
  datasetsTransformer?: TooltipTransformerFunction;
  reversePercentageColors?: boolean;
};

/** @deprecated */
export const externalTooltip = (context: any, params: ParamTypes) => {
  return <ExternalTooltip context={context} params={params} />;
};

type TooltipProps = {
  context: any;
  params: {
    dates: {
      current: ChartTooltip[];
      previous: ChartTooltip[];
    };
    datasets: { current: LineChartTooltipDataset[]; previous: LineChartTooltipDataset[] };
    showPrevious: boolean;
    title?: string;
    subtitle?: string;
    datasetsTransformer?: TooltipTransformerFunction;
    reversePercentageColors?: boolean;
  };
};

/** @deprecated */
export const ExternalTooltip = ({ context, params }: TooltipProps) => {
  const { isMobile } = useWindowDimensions();
  const [isTouchMoving, setIsTouchMoving] = useState(false);
  const [touchEnded, setTouchEnded] = useState(true);
  const [isTooltipInteractive, setTooltipInteractive] = useState(false);
  const { dates, datasets, showPrevious, title, subtitle, datasetsTransformer, reversePercentageColors } = params;
  const { tooltip, chart } = context;
  const { height, top } = chart.chartArea;

  const onTouchMove = useCallback(() => {
    tooltip.opacity = 0;
    setIsTouchMoving(true);
  }, [tooltip]);

  const onTouchEnd = useCallback(() => {
    if (isTouchMoving) {
      tooltip.opacity = 0;
    }

    setTouchEnded(true);
    setIsTouchMoving(false);
  }, [isTouchMoving, tooltip]);

  const closeTooltip = useCallback(() => {
    tooltip.opacity = 0;
    setTouchEnded(false);
  }, [tooltip]);

  const handleOnScroll = useCallback(() => {
    if (tooltip.opacity === 0) return;
    chart.config.options.events = ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'];
    chart.update();
    tooltip.opacity = 0;

    setTooltipInteractive(false);
  }, [chart, tooltip]);

  const handleOnClick = useCallback(() => {
    if (tooltip.opacity === 0) return;
    setTooltipInteractive((prev) => {
      chart.config.options.events = prev ? ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'] : ['click'];
      chart.update();
      return !prev;
    });
  }, [chart, tooltip]);

  useEffect(() => {
    window.addEventListener('touchend', onTouchEnd);
    window.addEventListener('touchmove', onTouchMove);

    window.addEventListener('scroll', handleOnScroll, { capture: true });
    window.addEventListener('click', handleOnClick, { capture: true });

    return () => {
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('touchend', onTouchEnd);

      window.removeEventListener('scroll', handleOnScroll);
      window.removeEventListener('click', handleOnClick);
    };
  }, [onTouchEnd, onTouchMove, handleOnScroll, handleOnClick]);

  if (!datasets?.current || !datasets?.previous) return null;
  if (!tooltip || !tooltip.dataPoints) return null;

  const { dataIndex } = tooltip.dataPoints[0];
  const [currentValues, previousValues] = partitionDatasets([...datasets.current, ...datasets.previous], dataIndex);
  const { offsetLeft } = chart.canvas;

  const placeholderSize = 2;
  const left = offsetLeft + tooltip.caretX - placeholderSize / 2;

  return (
    <div className='chart-tooltip-wrapper'>
      <Tooltip
        placement={isMobile ? 'top' : undefined}
        interactive
        open={tooltip.opacity === 1 && !isTouchMoving && touchEnded}
      >
        <TooltipTrigger
          opacity={tooltip.opacity}
          left={left}
          height={height}
          top={top}
          placeholderSize={placeholderSize}
        />
        <TooltipContent
          tooltipClassNames={classNames('!rounded-xl p-4 !max-w-[540px]', {
            'pointer-events-none': !isTooltipInteractive,
          })}
          zIndex={1700}
        >
          <LineChartTooltip
            currentValues={currentValues}
            previousValues={previousValues}
            currentPeriod={dates?.current?.[dataIndex]?.tooltip}
            previousPeriod={dates?.previous?.[dataIndex]?.tooltip}
            title={title}
            subtitle={subtitle}
            showPrevious={showPrevious}
            datasetsTransformer={datasetsTransformer}
            reversePercentageColors={reversePercentageColors}
            closeTooltip={closeTooltip}
          />
        </TooltipContent>
      </Tooltip>
    </div>
  );
};

const TooltipTrigger = ({
  opacity,
  top,
  left,
  placeholderSize,
  height,
}: {
  opacity: number;
  top: number;
  left: number;
  placeholderSize: number;
  height: number;
}) => {
  const { refs, context } = useTooltipContext();

  useEffect(() => {
    context.update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [top, left, height]);

  // Overlaps the datapoint in the graph
  return (
    <div
      ref={refs.setReference}
      data-testid={TEST_IDS.generic.chart.datapoint}
      className={classNames('chart-datapoint absolute', {
        invisible: opacity === 0,
      })}
      style={{
        bottom: 0,
        top: `${top}px`,
        left: `${left}px`,
        width: `${placeholderSize}px`,
        maxHeight: `${height}px`,
      }}
    />
  );
};
