import { isWixWebsite } from '@va/dashboard/selectors/core';
import { GlobeIcon, RadioInputSelected } from '@va/icons';
import { isAccountSettingsPageSelected } from '@va/standalone/shared/helpers';
import { hasTrackingCodeError } from '@va/standalone/shared/selectors';
import { TooltipWrapper } from '@va/ui/tooltips';
import React from 'react';
import { useSelector } from 'react-redux';
import TrackingCodeStatusTooltip from './TrackingCodeStatusTooltip';

const CheckTrackingCodeStatusV2 = () => {
  const trackingCodeError = useSelector(hasTrackingCodeError);
  const isWix = useSelector(isWixWebsite);
  const showStatus = !isWix && !isAccountSettingsPageSelected();

  return (
    <TooltipWrapper
      interactive={true}
      disabled={!showStatus}
      tooltipClassNames='!rounded-18 max-w-[380px]'
      content={<TrackingCodeStatusTooltip />}
    >
      <div className='relative flex items-center justify-center ml-2'>
        <GlobeIcon />
        {showStatus && (
          <div className='absolute left-[-3px] flex items-center justify-center bg-white rounded-full'>
            <RadioInputSelected color={trackingCodeError ? '#EA2A0C' : undefined} className='w-3 h-3' />
            {!trackingCodeError && <div className='absolute animate-circle w-10 h-10 bg-[#06a25a]'></div>}
          </div>
        )}
      </div>
    </TooltipWrapper>
  );
};

export default React.memo(CheckTrackingCodeStatusV2);
