import { getInstanceId } from '@va/dashboard/selectors/app';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { GlobalFiltersConfigProvider } from '@va/shared/feature-filters';
import { PropsWithChildren } from 'react';
import { usePrepareAsyncAssets } from './filter-options';
import { useSelector } from 'react-redux';
import { FilterTemplatesTab, SaveFilterTemplateButton } from './filter-templates';

export const DashboardGlobalFiltersConfigProvider = ({ children }: PropsWithChildren<{}>) => {
  const { showErrorNotification } = useAddNotification();
  usePrepareAsyncAssets();
  const websiteId = useSelector(getInstanceId);
  return (
    <GlobalFiltersConfigProvider
      TemplatesTab={FilterTemplatesTab}
      SaveTemplateButton={SaveFilterTemplateButton}
      reportErrorFunc={(err) => {
        showErrorNotification(3000, err);
      }}
      websiteId={websiteId}
    >
      {children}
    </GlobalFiltersConfigProvider>
  );
};
