import { getInstanceId } from '@va/dashboard/selectors/app';
import { getWebsite } from '@va/dashboard/selectors/core';
import { get, patch, post } from '@va/http-client';
import { isWixApp } from '@va/util/helpers';
import { useAsyncFunction } from '@va/util/hooks';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const getSettings = (websiteId) => {
  const url = `/websites/${websiteId}/settings`;

  return get(url, {});
};

export const getNotificationSettings = (websiteId) => {
  const url = `/websites/${websiteId}/notification-settings`;

  return get(url, {});
};

export const updateSettings = (websiteId, data) => {
  const url = isWixApp() ? `/wix/websites/${websiteId}` : `/websites/${websiteId}`;

  return patch(url, {}, data);
};

export const updateWebsiteFrontendFlags = (websiteId, data) => {
  const url = `/websites/${websiteId}/frontend-flags`;

  return patch(url, {}, data);
};

export const ignoreIp = (websiteId, data) => {
  const url = `/websites/${websiteId}/ignored-ips`;
  return post(url, {}, data);
};

export const whitelistDomain = (websiteId, data) => {
  const url = `/websites/${websiteId}/whitelisted-domains`;
  return post(url, {}, data);
};

export const patchWebsite = (websiteId, body) => {
  const url = isWixApp() ? `/wix/websites/${websiteId}` : `/websites/${websiteId}`;

  return patch(url, {}, body);
};

export const useResetData = () => {
  const websiteId = useSelector(getInstanceId);
  const website = useSelector(getWebsite);
  const asyncFn = useCallback(
    (sendDataSelected) => {
      return post(`/v2/websites/${websiteId}/reset`, {}, { sendDataSelected: sendDataSelected, email: website.email });
    },
    [websiteId, website.email],
  );
  return useAsyncFunction(asyncFn);
};
