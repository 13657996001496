import { createEventTagModalView, modalName } from '@va/constants';
import { closeModal, setCardView } from '@va/dashboard/actions/ui';
import ModalWrapper from '@va/dashboard/shared/modals/ModalWrapper';
import { getCardView, isModalOpen as modalOpen } from '@va/dashboard/selectors/ui';
import { CloseIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, fontWeights, Heading5, HorizontalSeparator } from '@va/ui/design-system';
import { renderIf } from '@va/util/helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CopyCreatedEventTagSnippet from './CopyCreatedEventTagSnippet';
import CreateEventTagForm from './CreateEventTagForm';

const CreateEventTag: React.FC = () => {
  const translate = useTranslate();
  const [codeSnippet, setCodeSnippet] = useState('');
  const isModalOpen = useSelector((state) => modalOpen(state, modalName.createEventTag));
  const dispatch = useDispatch();
  const modalView = useSelector((state) => getCardView(state, modalName.createEventTag));

  useEffect(() => {
    handleModalViewSwitch(createEventTagModalView.CREATE_EVENT_TAG_VIEW, null);
  }, []);

  const handleCancelClick = useCallback(() => {
    handleModalViewSwitch(createEventTagModalView.CREATE_EVENT_TAG_VIEW, null);
    dispatch(closeModal(modalName.createEventTag));
  }, [dispatch]);

  function handleModalViewSwitch(view: string, previousView: string | null) {
    dispatch(setCardView(modalName.createEventTag, view, previousView));
  }

  const showCreateEventTagForm = renderIf(modalView === createEventTagModalView.CREATE_EVENT_TAG_VIEW);
  const showCopyCreatedEventTagSnippet = renderIf(modalView === createEventTagModalView.COPY_CREATED_EVENT_TAG_SNIPPET);

  return (
    <ModalWrapper
      isOpen={isModalOpen}
      roundedCorners
      closeModal={handleCancelClick}
      closeIcon={
        <Button
          color='tertiary'
          className='absolute top-6 right-6'
          icon={(classes) => <CloseIcon className={classes} />}
        />
      }
    >
      <div className='p-30 sm:p-4'>
        <Heading5 className={'pr-16'} weight={fontWeights.semibold}>
          {translate('modal.createEventTag.title')}
        </Heading5>
        <HorizontalSeparator className='my-30 -mx-30 sm:mx-[-15px]' />
        {showCreateEventTagForm(
          <CreateEventTagForm handleModalViewSwitch={handleModalViewSwitch} setCodeSnippet={setCodeSnippet} />,
        )}
        {showCopyCreatedEventTagSnippet(
          <CopyCreatedEventTagSnippet
            handleCancelClick={handleCancelClick}
            handleCreateNewEventTag={() => handleModalViewSwitch(createEventTagModalView.CREATE_EVENT_TAG_VIEW, null)}
            codeSnippet={codeSnippet}
          />,
        )}
      </div>
    </ModalWrapper>
  );
};

export default CreateEventTag;
