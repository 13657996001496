import classNames from 'classnames';
import { IconProps } from '.';

export const LocationMarkerIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.5 5.5C10.5 6.32843 9.82843 7 9 7C8.17157 7 7.5 6.32843 7.5 5.5C7.5 4.67157 8.17157 4 9 4C9.82843 4 10.5 4.67157 10.5 5.5Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 18L13.9751 8.04984C15.8243 4.35146 13.1349 0 9 0C4.86509 0 2.17573 4.35146 4.02492 8.04984L9 18ZM9 14.6459L12.6334 7.37902C13.984 4.67799 12.0198 1.5 9 1.5C5.98016 1.5 4.01605 4.67799 5.36656 7.37902L9 14.6459Z'
        fill={color ?? 'var(--color-primary)'}
      />
    </svg>
  );
};


