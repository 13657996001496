import { FlagsmithFeatureFlags } from '@va/util/helpers';
import { useFlags } from 'flagsmith/react';

export const useIsFeatureEnabled = (featureFlag: FlagsmithFeatureFlags) => {
  const {
    [featureFlag]: { enabled },
  } = useFlags([featureFlag]);

  return enabled;
};
