import { useCurrentPeriodFilter } from '@va/dashboard/util-hooks';
import { post } from '@va/http-client';
import {
  AlarmingBehaviorEventsFetchParams,
  AlarmingBehaviorEventsGraphFetchParams,
  UseAlarmingBehaviorEventsFetchParams,
} from '@va/types/events';
import { RawTimeseriesResponse } from '@va/types/time-series';
import { useFetchData } from '@va/util/hooks';
import { useCallback, useMemo } from 'react';
import { SWRResponse } from 'swr';

export async function fetchAlarmingBehaviorEventsGraph({
  websiteId,
  query,
  body,
}: AlarmingBehaviorEventsGraphFetchParams) {
  const graphResponse = await post<RawTimeseriesResponse>(
    `/v2/websites/${websiteId}/alarming-events/graph`,
    query,
    body,
  );
  return graphResponse;
}

export const useAlarmingBehaviorEvents = <T>({
  query,
  body,
  mapper,
}: UseAlarmingBehaviorEventsFetchParams<T>): SWRResponse<T, Error> => {
  const { from, until, websiteId } = useCurrentPeriodFilter();
  const url = useMemo(() => `/v2/websites/${websiteId}/alarming-events`, [websiteId]);
  const queryData = useMemo(
    () => ({
      from,
      until,
      ...query,
    }),
    [from, query, until],
  );

  const fetcher = useCallback(async ({ url, queryData, body }: AlarmingBehaviorEventsFetchParams) => {
    const eventsResponse = await post(url, queryData, body);
    return eventsResponse;
  }, []);

  return useFetchData<T, Error>(
    [{ url, queryData, body }],
    {
      revalidateOnFocus: false,
    },
    mapper,
    fetcher,
  );
};
