import { LIMIT } from '@va/dashboard/modules/feedback/shared';
import { BinIcon, DuplicateIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button } from '@va/ui/design-system';
import { generateId } from '@va/util/helpers';
import { FormContext } from '@va/util/misc';
import { FieldArray, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import Action from '../dropdowns/options-dropdown/Action';
import OptionsDropdown from '../dropdowns/options-dropdown/OptionsDropdown';
import ChoiceOption from './ChoiceOption';

const ChoiceEditor = ({ name }) => {
  const { getFieldProps, setFieldValue } = useFormikContext();
  const translate = useTranslate();
  const { value: choices = [] } = getFieldProps(name);
  const choicesMaxLimitReached = choices.length >= LIMIT.NUMBER.MULTIPLE_CHOICE.MAX;
  const choicesUnderMinLimit = choices.length <= LIMIT.NUMBER.MULTIPLE_CHOICE.MIN;
  const formConfig = useContext(FormContext);
  const { isFormDisabled } = formConfig;

  useEffect(() => {
    if (choices.length === 0) {
      setFieldValue(name, [
        { id: generateId(), value: '' },
        { id: generateId(), value: '' },
      ]);
    }
  }, [choices.length, name, setFieldValue]);

  const findChoiceIndexesByIds = (firstId, secondId) => {
    const firstIndex = choices.findIndex((item) => item.id === firstId);
    const secondIndex = choices.findIndex((item) => item.id === secondId);
    return [firstIndex, secondIndex];
  };

  return (
    <FieldArray name={name}>
      {({ push, remove, insert, move }) => (
        <div>
          <div className='rounded-lg border-2 border-gray-gallery'>
            {choices.map((item, index) => (
              <ChoiceOption
                key={item.id}
                id={item.id}
                name={`${name}.${index}.value`}
                className='last:border-0'
                isFormDisabled={isFormDisabled}
                handleChoiceOrder={(sourceId, targetId) => {
                  const indexes = findChoiceIndexesByIds(sourceId, targetId);
                  move(indexes[0], indexes[1]);
                }}
                optionsMenu={
                  <OptionsDropdown>
                    <Action
                      onClick={() => {
                        insert(index + 1, { ...item, id: generateId() });
                      }}
                      icon={<DuplicateIcon />}
                    >
                      {translate('feedback.question.choices.duplicate')}
                    </Action>
                    {!choicesUnderMinLimit && (
                      <Action
                        onClick={() => {
                          !choicesUnderMinLimit && remove(index);
                        }}
                        icon={<BinIcon />}
                      >
                        {translate('feedback.question.choices.delete')}
                      </Action>
                    )}
                  </OptionsDropdown>
                }
              />
            ))}
          </div>

          {!isFormDisabled && (
            <Button
              className={'w-full mt-4'}
              text={translate('feedback.question.addNewOption')}
              disabled={choicesMaxLimitReached}
              tooltip={choicesMaxLimitReached && translate('tooltips.polls.limit.reached.nrOfMultipleChoices')}
              onClick={() => !choicesMaxLimitReached && push({ id: generateId(), value: '' })}
            />
          )}
        </div>
      )}
    </FieldArray>
  );
};

ChoiceEditor.propTypes = {
  name: PropTypes.string,
};

export default ChoiceEditor;
