import { makeAction } from '@va/store';

/** @deprecated */
export const PROMPT_SSR_FEATURE = 'PROMPT_SSR_FEATURE';
export const PROMPT_SSR_FEATURE_SUCCEEDED = 'PROMPT_SSR_FEATURE_SUCCEEDED';
export const PROMPT_SSR_FEATURE_FAILED = 'PROMPT_SSR_FEATURE_FAILED';

export const GET_VIDEO_TRACKING_CODE_REQUEST = 'API_GET_VIDEO_TRACKING_CODE_REQUEST';
export const GET_VIDEO_TRACKING_CODE_SUCCEEDED = 'API_GET_VIDEO_TRACKING_CODE_SUCCEEDED';
export const GET_VIDEO_TRACKING_CODE_FAILED = 'API_GET_VIDEO_TRACKING_CODE_FAILED';

export const GET_RECORDINGS_COUNT = 'API_GET_RECORDINGS_COUNT';
export const GET_RECORDINGS_COUNT_SUCCEEDED = 'API_GET_RECORDINGS_COUNT_SUCCEEDED';
export const GET_RECORDINGS_COUNT_FAILED = 'API_GET_RECORDINGS_COUNT_FAILED';

export const CREATE_SSR_SETTINGS = 'API_CREATE_SSR_SETTINGS';
export const CREATE_SSR_SETTINGS_SUCCEEDED = 'API_CREATE_SSR_SETTINGS_SUCCEEDED';
export const CREATE_SSR_SETTINGS_FAILED = 'API_CREATE_SSR_SETTINGS_FAILED';

export const GET_SSR_SETTINGS = 'API_GET_SSR_SETTINGS';
export const GET_SSR_SETTINGS_SUCCEEDED = 'API_GET_SSR_SETTINGS_SUCCEEDED';
export const GET_SSR_SETTINGS_FAILED = 'API_GET_SSR_SETTINGS_FAILED';

export const UPDATE_SSR_SETTINGS = 'API_UPDATE_SSR_SETTINGS';
export const UPDATE_SSR_SETTINGS_SUCCEEDED = 'API_UPDATE_SSR_SETTINGS_SUCCEEDED';
export const UPDATE_SSR_SETTINGS_FAILED = 'API_UPDATE_SSR_SETTINGS_FAILED';

/** @deprecated */
export const promptSsrFeature = makeAction(PROMPT_SSR_FEATURE, 'value');
export const promptSsrFeatureSucceeded = makeAction(PROMPT_SSR_FEATURE_SUCCEEDED);
export const promptSsrFeatureFailed = makeAction(PROMPT_SSR_FEATURE_FAILED, 'error');

export const getVideoTrackingCode = makeAction(GET_VIDEO_TRACKING_CODE_REQUEST);
export const getVideoTrackingCodeSucceeded = makeAction(GET_VIDEO_TRACKING_CODE_SUCCEEDED, 'data');
export const getVideoTrackingCodeFailed = makeAction(GET_VIDEO_TRACKING_CODE_FAILED, 'error');

export const getRecordingsCount = makeAction(GET_RECORDINGS_COUNT);
export const getRecordingsCountSucceeded = makeAction(GET_RECORDINGS_COUNT_SUCCEEDED, 'data');
export const getRecordingsCountFailed = makeAction(GET_RECORDINGS_COUNT_FAILED, 'error');

// TODO Delete after the new module setings tab is released
export const createSsrSettings = makeAction(CREATE_SSR_SETTINGS, 'data');
export const createSsrSettingsSucceeded = makeAction(CREATE_SSR_SETTINGS_SUCCEEDED, 'data');
export const createSsrSettingsFailed = makeAction(CREATE_SSR_SETTINGS_FAILED, 'error');

export const getSsrSettings = makeAction(GET_SSR_SETTINGS);
export const getSsrSettingsSucceeded = makeAction(GET_SSR_SETTINGS_SUCCEEDED, 'data');
export const getSsrSettingsFailed = makeAction(GET_SSR_SETTINGS_FAILED, 'error');

export const updateSsrSettings = makeAction(UPDATE_SSR_SETTINGS, 'data');
export const updateSsrSettingsSucceeded = makeAction(UPDATE_SSR_SETTINGS_SUCCEEDED, 'data');
export const updateSsrSettingsFailed = makeAction(UPDATE_SSR_SETTINGS_FAILED, 'error');
