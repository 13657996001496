import { validationTranslationKeys } from '@va/constants';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { BlockIcon, RightArrowIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { FilterActionButtons, SubFilterTabHeader } from '@va/shared/feature-filters';
import { hasPermission } from '@va/standalone/shared/selectors';
import { FilterTemplate } from '@va/types/filter-templates';
import { Button, TextInput } from '@va/ui/design-system';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { DeleteFilterTemplateConfirmationDialog } from './DeleteFilterTemplateConfirmationDialog';
import { useDeleteFilterTemplate, useUpdateFilterTemplate } from './api';

export const EditFilterTemplateTab = ({
  template,
  onBackClick,
  refreshList,
  onClose,
}: {
  template: FilterTemplate;
  onBackClick: () => void;
  refreshList: () => void;
  onClose: () => void;
}) => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const websiteId = useSelector(getInstanceId);
  const templateUpdate = useUpdateFilterTemplate();
  const templateDelete = useDeleteFilterTemplate();

  const hasDeletePermission = useSelector(hasPermission);

  const { values, handleChange, errors, setFieldValue, submitForm } = useFormik({
    initialValues: { name: template.name },
    onSubmit: (values) => {
      templateUpdate.execute(template.id, { name: values.name });
    },
    validationSchema: yup.object().shape({ name: yup.string().required(validationTranslationKeys.required) }),
  });

  const translate = useTranslate();

  useEffect(() => {
    if (templateUpdate.isSucceeded || templateDelete.isSucceeded) {
      onBackClick();
      refreshList();
    }
  });

  return (
    <div>
      {isConfirmationDialogOpen && (
        <DeleteFilterTemplateConfirmationDialog
          onClose={() => {
            setIsConfirmationDialogOpen(false);
          }}
          onConfirm={() => {
            templateDelete.execute(websiteId, [template.id]);
          }}
        />
      )}
      <SubFilterTabHeader title={template.name} onBackClick={onBackClick} />
      <TextInput
        clearField={() => {
          setFieldValue('name', '');
        }}
        label={translate('all.filterTemplates.labels.name')}
        name='name'
        value={values.name}
        onChange={handleChange}
        error={translate(errors.name as string)}
        autoFocus
      />
      <Button
        className='mt-2 w-full !text-xs'
        type='button'
        color='destructive'
        icon={(_, color) => <BlockIcon className='w-4 h-4' color={color} />}
        text={translate('button.deleteTemplate')}
        disabled={templateDelete.isLoading || !hasDeletePermission}
        onClick={() => {
          setIsConfirmationDialogOpen(true);
        }}
        rightSideIcon={<RightArrowIcon color='#969696' />}
        tooltip={!hasDeletePermission && translate('tooltips.contributorRole')}
      />
      <FilterActionButtons
        onClose={onClose}
        submitButtonDisabled={templateUpdate.isLoading}
        onSubmit={() => {
          submitForm();
        }}
      />
    </div>
  );
};
