import { UNTRANSLATED_LOCALE } from '@va/constants';
import moment, { Moment } from 'moment-timezone';
import { getBrowserLanguage } from '../browser';

export function getFilterDateWithTimezone(date: Moment, timezone: string) {
  const changedDate = date.clone();

  //add timezone for date
  changedDate.tz(timezone);

  changedDate.add(date.utcOffset() - changedDate.utcOffset(), 'minutes');

  return changedDate;
}

const DEFAULT_DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

const LOCALIZED_DATE_TIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
};

export function getLocalizedDate(timestamp: number, locale = 'en', options = DEFAULT_DATE_FORMAT_OPTIONS) {
  const date = new Date(timestamp * 1000);
  const formatOptions = { ...DEFAULT_DATE_FORMAT_OPTIONS, ...options };
  if (locale === 'en' || locale === UNTRANSLATED_LOCALE) {
    const browserLocale = getBrowserLanguage();
    if (browserLocale.indexOf('en') === 0) {
      return date.toLocaleDateString(browserLocale, formatOptions);
    }

    return date.toLocaleDateString('en-US', formatOptions);
  }

  return date.toLocaleDateString(locale, formatOptions);
}

export const getFormattedDateAndTime = (unixTime: number, locale: string = 'en') => {
  return unixTime ? getLocalizedDate(unixTime, locale, LOCALIZED_DATE_TIME_FORMAT_OPTIONS) : '-';
};

export const getTimestampFromIso = (iso: string | moment.Moment) => {
  return moment(iso).unix();
};

export const ensureStartBeforeEnd = ([start, end]: [Moment, Moment]) => {
  return moment(end).isBefore(start) ? [end, start] : [start, end];
};
