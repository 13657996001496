import { getSupportCenterDomain } from '@va/dashboard/selectors/customization';
import { useTranslate } from '@va/localization';
import { ParagraphWithTooltip } from '@va/ui/design-system';
import { FC, PropsWithChildren, ReactElement, ReactNode, createContext, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

export type PageHeaderTooltipProps = {
  baseKey: string;
  render: (shouldShow: boolean) => ReactElement;
  title?: string | React.ReactNode;
  trigger?: 'click' | 'hover' | 'default';
  disabled?: boolean;
};

type TooltipSubInfoType = {
  text: string;
  defaultText: string;
  url: string;
};

type PageHeaderTooltipContextType = {
  getButtonLabel: (value: { text: string; defaultText: string }) => string;
  titleText: string | ReactNode;
  shouldRenderTooltip: boolean;
  baseKey: string;
  shouldRender: (val: string | null) => boolean;
  tooltipKeys: {
    title: string;
    description: string;
    video: TooltipSubInfoType;
    glossary: TooltipSubInfoType;
    supportCenter: TooltipSubInfoType;
    faq: TooltipSubInfoType;
  };
  render: (shouldShow: boolean) => ReactElement;
  trigger?: 'click' | 'hover' | 'default';
  disabled?: boolean;
};
const PageHeaderTooltipContext = createContext({} as PageHeaderTooltipContextType);

const PageHeaderTooltipContextProvider: FC<PropsWithChildren<PageHeaderTooltipProps>> = ({
  children,
  baseKey,
  title,
  render,
  trigger = 'default',
  disabled = false,
}) => {
  const translate = useTranslate();
  const supportCenterDomain = useSelector(getSupportCenterDomain);

  const tooltipKeys = useMemo(() => {
    return {
      title: translate(`${baseKey}_title`),
      description: translate(`${baseKey}_description`),
      video: {
        text: translate(`${baseKey}_videoText`),
        defaultText: translate('tooltip_videoText.default.label'),
        url: translate(`${baseKey}_videoUrl`, { supportCenterDomain }),
      },
      glossary: {
        text: translate(`${baseKey}_glossaryText`),
        defaultText: translate('tooltip_glossaryText.default.label'),
        url: translate(`${baseKey}_glossaryUrl`, { supportCenterDomain }),
      },
      supportCenter: {
        text: translate(`${baseKey}_supportCenterText`),
        defaultText: translate('tooltip_supportCenterText.default.label'),
        url: translate(`${baseKey}_supportCenterUrl`, { supportCenterDomain }),
      },
      faq: {
        text: translate(`${baseKey}_faq`),
        defaultText: translate('tooltip_faqText.default.label'),
        url: translate(`${baseKey}_faqUrl`, { supportCenterDomain }),
      },
    };
  }, [baseKey, translate, supportCenterDomain]);

  const getButtonLabel = useCallback(
    (buttonSection: { text: string; defaultText: string }) => {
      if (buttonSection.text?.includes(baseKey) || buttonSection.text === null) {
        return buttonSection.defaultText;
      }
      return buttonSection.text;
    },
    [baseKey],
  );

  const shouldRender = useCallback(
    (str: string | null) => {
      if (!str) return false;
      try {
        return !str?.includes(baseKey);
      } catch (e) {
        console.log(`Failed for ${baseKey} -> ${str}`);
        return false;
      }
    },
    [baseKey],
  );

  const shouldRenderTooltip = useMemo(() => {
    if (disabled) return false;
    return (
      shouldRender(tooltipKeys.title) ||
      shouldRender(tooltipKeys.description) ||
      shouldRender(tooltipKeys.video.url) ||
      shouldRender(tooltipKeys.glossary.url) ||
      shouldRender(tooltipKeys.supportCenter.url) ||
      shouldRender(tooltipKeys.faq.url)
    );
  }, [
    disabled,
    shouldRender,
    tooltipKeys.title,
    tooltipKeys.description,
    tooltipKeys.video.url,
    tooltipKeys.glossary.url,
    tooltipKeys.supportCenter.url,
    tooltipKeys.faq.url,
  ]);

  const titleText = useMemo(() => {
    if (typeof title !== 'string') {
      return title;
    }

    return (
      <ParagraphWithTooltip className='truncate font-525 '>
        {shouldRender(tooltipKeys.title) ? tooltipKeys.title : title}
      </ParagraphWithTooltip>
    );
  }, [title, shouldRender, tooltipKeys.title]);

  return (
    <PageHeaderTooltipContext.Provider
      value={{ getButtonLabel, titleText, shouldRenderTooltip, baseKey, shouldRender, tooltipKeys, trigger, render }}
    >
      {children}
    </PageHeaderTooltipContext.Provider>
  );
};

export default PageHeaderTooltipContextProvider;

export const usePageHeaderTooltipContext = () => {
  return useContext(PageHeaderTooltipContext);
};
