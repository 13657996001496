import classNames from 'classnames';
import { IconProps } from '.';

export const CheckmarkIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9ZM18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM8.78033 11.7803L13.2803 7.28033L12.2197 6.21967L8.25 10.1893L5.78033 7.71967L4.71967 8.78033L7.71967 11.7803L8.25 12.3107L8.78033 11.7803Z'
        fill={color ?? 'var(--color-primary)'}
      />
    </svg>
  );
};


