import { EXPORT_FORMAT } from '@va/constants';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { getGlobalFilter, getTrafficFilter } from '@va/dashboard/selectors/ui';
import { downloadResponse } from '@va/http-client';
import { call, put, select } from 'redux-saga/effects';
import zipcelx from 'zipcelx';

export function* fetch(apiCall, successCallback, failureCallback, action) {
  try {
    const websiteId = yield select(getInstanceId);
    const filter = yield select(getGlobalFilter);
    const trafficFilter = yield select(getTrafficFilter);

    const data = yield call(
      apiCall,
      websiteId,
      action.filter || filter,
      action.handleSpecificYear ? trafficFilter.specificYear : null,
    );
    yield put(successCallback(data));
  } catch (error) {
    yield put(failureCallback(error));
  }
}

export function* downloadExportResponse(data, fileName, format) {
  return format === EXPORT_FORMAT.CSV
    ? yield call(downloadResponse, data, fileName + '.csv')
    : yield call(zipcelx, {
        filename: fileName,
        sheet: {
          data: data,
        },
      });
}
