import { IconProps } from '.';

export const BarGraphIcon = ({ color = 'black', className }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.5 1.5H4.5C2.84315 1.5 1.5 2.84315 1.5 4.5V13.5C1.5 15.1569 2.84315 16.5 4.5 16.5H13.5C15.1569 16.5 16.5 15.1569 16.5 13.5V4.5C16.5 2.84315 15.1569 1.5 13.5 1.5ZM4.5 0C2.01472 0 0 2.01472 0 4.5V13.5C0 15.9853 2.01472 18 4.5 18H13.5C15.9853 18 18 15.9853 18 13.5V4.5C18 2.01472 15.9853 0 13.5 0H4.5ZM6.75 9.75C6.75 9.33579 7.08579 9 7.5 9C7.91421 9 8.25 9.33579 8.25 9.75V14.25C8.25 14.6642 7.91421 15 7.5 15C7.08579 15 6.75 14.6642 6.75 14.25V9.75ZM10.5 6C10.0858 6 9.75 6.33579 9.75 6.75V14.25C9.75 14.6642 10.0858 15 10.5 15C10.9142 15 11.25 14.6642 11.25 14.25V6.75C11.25 6.33579 10.9142 6 10.5 6ZM12.75 3.75C12.75 3.33579 13.0858 3 13.5 3C13.9142 3 14.25 3.33579 14.25 3.75V14.25C14.25 14.6642 13.9142 15 13.5 15C13.0858 15 12.75 14.6642 12.75 14.25V3.75ZM4.5 12C4.08579 12 3.75 12.3358 3.75 12.75V14.25C3.75 14.6642 4.08579 15 4.5 15C4.91421 15 5.25 14.6642 5.25 14.25V12.75C5.25 12.3358 4.91421 12 4.5 12Z'
        fill={color}
      />
    </svg>
  );
};
