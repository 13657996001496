import { BubbleDataPoint, Chart, ChartType, ChartTypeRegistry, Point, TooltipModel } from 'chart.js';
import { ReactNode } from 'react';
import { SWRResponse } from 'swr';
import { BaseTimeseriesDataset, BaseTimeseriesResponse } from '../time-series';

export type ChartTile = {
  id: string;
  label: string;
  headingValue: string | number;
  valueDetails?: string;
  colorHEX: string;
  selected?: boolean;
  percentageChange?: number;
  reversePercentageColors?: boolean;
  onClick?: () => void;
  onKpiDataViewChange?: (value: string) => void;
  renderContextMenu?: () => React.ReactNode;
};

export type ChartContext<T extends ChartType = 'line'> = {
  chart: Chart<keyof ChartTypeRegistry, (number | Point | [number, number] | BubbleDataPoint | null)[], unknown>;
  tooltip: TooltipModel<T>;
};

declare module 'chart.js' {
  export interface ControllerDatasetOptions {
    isCurrent?: boolean;
    id?: string;
    linkTo?: string;
    baseColor?: string;
  }
}

export enum LineDiagramOptions {
  STACKED = 'stacked',
  STEPPED = 'stepped',
  DEFAULT = 'default',
}

export enum LineStyling {
  UNFILLED = 'unfilled',
  FILLED = 'filled',
  DASHED = 'dashed',
}

export type ChartTooltipMode = 'fixed' | 'follow-point';

export type CommonVisualizationComponentProps = {
  useCurrentData: () => SWRResponse<BaseTimeseriesResponse<any>, Error>;
  usePreviousData: () => SWRResponse<BaseTimeseriesResponse<any>, Error>;
  datasetKeys: string[];
  renderErrorComponent?: (error: Error | undefined, isLoading: boolean) => React.ReactNode;
};

export type ChartVisualizationComponentProps = CommonVisualizationComponentProps & {
  lineDiagramType?: LineDiagramOptions;
  getHeadingValueFunc?: (total: number, locale: string, dataset: BaseTimeseriesDataset<number>) => any;
  getValueDetailsFunc?: (dataset: BaseTimeseriesDataset, total: number) => any;
  getTooltipValueFunc?: (value: any) => any;
  nodes?: {
    compareTo?: ReactNode;
  };
  tooltipTitle?: string;
  tooltipSubtitle?: string;
  tooltipMode?: ChartTooltipMode;
  reversePercentageColors?: boolean;
  loadingPlaceholdersCount?: number;
};
