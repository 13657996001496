import classNames from 'classnames';
import PropTypes from 'prop-types';

const InfoField = (props) => {
  const { label, value, additionalClass, onClick, icon } = props;

  return (
    <div className={classNames('flex flex-column mr-4', additionalClass)}>
      <div className='flex items-center'>
        <div className='font-medium text-base md:text-lg leading-normal tracking-default text-gray-devil'>{label}</div>
        {icon && <div className='ml-2 cursor-pointer'>{icon}</div>}
      </div>
      <div
        onClick={onClick && onClick}
        className='font-medium text-lg md:text-21 leading-30 tracking-normalTight text-mine-shaft pr-6 break-words'
      >
        {value ? value : 'n/a'}
      </div>
    </div>
  );
};

InfoField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  additionalClass: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.node,
};

export default InfoField;
