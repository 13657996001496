import {
  PAGE_CONTENT_STICKY_ITEMS_WRAPPER_ID,
  PAGE_CONTENT_TABLE_HEADER_WRAPPER_ID,
  PAGE_HEADER_RESIZE_EVENT,
} from '@va/constants';
import { isMobileDevice, isReportBlockPage } from '@va/dashboard/selectors/ui';
import { Portal } from '@va/util/components';
import { EventBus } from '@va/util/misc';
import classNames from 'classnames';
import { PropsWithChildren, forwardRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

type PageContentProps = {
  id?: string;
  className?: string;
  renderTopItems?: () => React.ReactNode;
};

export const PageContent = forwardRef<HTMLDivElement, PropsWithChildren<PageContentProps>>(
  ({ id, className, children, renderTopItems }, ref) => {
    const shouldApplyBg = useSelector(isReportBlockPage);

    return (
      <div
        id={id}
        ref={ref}
        className={classNames(
          'p-2 lg:p-30 lg:pt-auto h-full',
          { 'bg-[#F2F2F2]': shouldApplyBg },
          { 'bg-transparent': !shouldApplyBg },
          className,
        )}
      >
        {renderTopItems?.()}
        <PageContentStickyItems />
        {children}
      </div>
    );
  },
);

export const PageContentStickyItems = () => {
  const [pageHeaderHeight, setPageHeaderHeight] = useState(0);

  useEffect(() => {
    const listener = EventBus.addListener(PAGE_HEADER_RESIZE_EVENT, ({ height }: { height: number }) => {
      setPageHeaderHeight(height);
    });
    return () => {
      listener.removeListener();
    };
  }, []);

  return (
    <div style={{ top: `calc(${pageHeaderHeight}px + var(--top-bar-height))` }} className='sticky bg-[#F2F2F2] z-[800]'>
      <div id={PAGE_CONTENT_STICKY_ITEMS_WRAPPER_ID}></div>
      <div id={PAGE_CONTENT_TABLE_HEADER_WRAPPER_ID}></div>
    </div>
  );
};

export const StickyPageContentItemsWrapper: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const isMobile = useSelector(isMobileDevice);

  if (isMobile) return <>{children}</>;

  return (
    <Portal elementSelector={`#${PAGE_CONTENT_STICKY_ITEMS_WRAPPER_ID}`}>
      <div className='pt-3'>{children}</div>
    </Portal>
  );
};
