import moment from 'moment-timezone';
import { createSelector } from 'reselect';

export const getWebsiteInstallDate = createSelector(
  [(state) => parseInt(state.getIn(['core', 'website', 'installDate'], moment().unix()))],
  (installDate) => moment(installDate * 1000),
);

export const getNotificationsCardOptions = () => {
  return {
    data: [
      {
        premium: true,
        value: 0,
        type: 'daily',
      },
      {
        premium: true,
        value: 1,
        type: 'weekly',
      },
      {
        premium: false,
        value: 2,
        type: 'monthly',
      },
      {
        premium: false,
        value: 3,
        type: 'never',
      },
    ],
  };
};
