import { getIcon, getLabelFromValue } from '@va/dashboard/modules/feedback/shared';
import DividerIcon from '@va/images/divider.svg?react';
import { useTranslate } from '@va/localization';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './AnsweredQuestion.scss';
import VerticalDivider from './VerticalDivider';

export const Question = ({
  number = 0,
  header,
  text,
  icon,
  children,
  className,
  overrideChildrenStyle,
  type,
  rightHeaderSlot,
}) => {
  const Icon = type && !icon && getIcon(type);
  const translate = useTranslate();

  return (
    <div className={classNames('flex text-mine-shaft', className)}>
      <Number value={number} />

      <div className='relative min-w-0 grow'>
        <ExternalBorder />

        {header || (
          <div className='flex border-2 border-gray-gallery rounded-lg min-h-[3.5rem]'>
            <div className='border-2 border-white flex justify-center rounded-l-lg items-center w-12 bg-alabaster'>
              {icon || <Icon />}
            </div>

            <VerticalDivider className='h-[inherit]' />

            <div className='flex grow items-center pl-2'>
              <div>{type && !text && translate(`feedback.question.${getLabelFromValue(type).toLowerCase()}`)}</div>

              {rightHeaderSlot && <div className='ml-auto pl-2 pr-6'>{rightHeaderSlot}</div>}
            </div>
          </div>
        )}

        <div className={overrideChildrenStyle ? overrideChildrenStyle : 'px-6 py-4'}>{children}</div>
      </div>
    </div>
  );
};

Question.defaultProps = {
  type: null,
  header: null,
  icon: null,
  text: null,
  className: null,
  rightHeaderSlot: null,
};

Question.propTypes = {
  type: PropTypes.number,
  number: PropTypes.number.isRequired,
  header: PropTypes.node,
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
  text: PropTypes.string,
  className: PropTypes.string,
  rightHeaderSlot: PropTypes.node,
  overrideChildrenStyle: PropTypes.string,
};

export default Question;

const Number = ({ value }) => {
  return (
    <div>
      <div className='flex items-center'>
        <div className='rounded-lg border-2 border-gray-gallery font-medium leading-normal text-lg w-14 h-14 flex items-center justify-center'>
          {value.toString().padStart(2, '0')}
        </div>

        <DividerIcon />
      </div>
    </div>
  );
};

Number.propTypes = {
  value: PropTypes.number.isRequired,
};

const ExternalBorder = () => (
  <div className='absolute inset-0 border-2 border-gray-gallery rounded-lg pointer-events-none' />
);
