import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export default function SsrReachedLimitFooterButtons(props) {
  return (
    <Fragment>
      <button onClick={props.closeModal} className={'footer-button'}>
        {props.translate('button.understand')}
      </button>
      <button onClick={props.goToWebsiteSettings} className={'footer-button colored'}>
        {props.translate('button.goToSettings')}
      </button>
    </Fragment>
  );
}

SsrReachedLimitFooterButtons.propTypes = {
  closeModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  goToWebsiteSettings: PropTypes.func.isRequired,
};
