import { useCallback, useEffect } from 'react';

export const useSubmitOnEnter = (handleSubmit: () => void) => {
  const keyPressHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  useEffect(() => {
    document.addEventListener('keypress', keyPressHandler);
    return () => {
      document.removeEventListener('keypress', keyPressHandler);
    };
  }, [keyPressHandler]);
};
