import { TEST_IDS } from '@va/constants';
import { NavSubscriptionSettings } from '@va/icons';
import { useTranslate } from '@va/localization';
import { getAgencyYearlyCommission } from '@va/standalone/shared/api-client/Agency';
import { Paragraph } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import React, { useCallback, useEffect, useRef, useState } from 'react';

type TotalCommissionPayoutProps = {
  icon: React.ReactNode;
  onClick: () => void;
};

const TotalCommissionPayout: React.FC<TotalCommissionPayoutProps> = React.memo((props) => {
  const { icon, onClick } = props;
  const translate = useTranslate();
  const textRef = useRef(null);
  const [yearlyCommission, setYearlyCommission] = useState(0);

  useEffect(() => {
    getYearlyCommission();
  }, []);

  async function getYearlyCommission() {
    try {
      const response = await getAgencyYearlyCommission();
      setYearlyCommission(response.yearlyCommission);
    } catch (err) {
      //Yearly commission will be displayed 0.
    }
  }

  const onClickHandler = useCallback(
    (event: any) => {
      if (event.target === textRef.current) return;
      onClick();
    },
    [onClick],
  );

  return (
    <div
      onClick={onClickHandler}
      className='flex cursor-pointer justify-between items-center h-12 bg-gray-alabaster px-4 rounded-xl w-auto sm:w-full'
      data-testid={TEST_IDS.generic.whiteLabel.commissionPayoutsBtn}
    >
      <div className='flex items-center mr-4 md:mr-10'>
        <NavSubscriptionSettings className='min-h-18 min-w-18' color='#969696' />
        <div className='ml-2 font-medium text-15 leading-6 text-gray-devil flex items-center flex-wrap'>
          <TooltipWrapper trigger='click' content={translate('commission.payouts.totalCommission.info')} ref={textRef}>
            <Paragraph className='underline-dashed cursor-default'>
              {translate('commission.payouts.totalCommission.label')} :{' '}
            </Paragraph>
          </TooltipWrapper>
          <div>
            <span className='text-mine-shaft mx-1'>{yearlyCommission}</span> <span>USD</span>
          </div>
        </div>
      </div>
      {icon}
    </div>
  );
});

export default TotalCommissionPayout;
