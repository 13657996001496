import { getInstanceId } from '@va/dashboard/selectors/app';
import { DefaultFilterOptionType } from '@va/types/filters';
import { isDefaultWebsiteId } from '@va/util/helpers';
import { useFetchData } from '@va/util/hooks';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useTrafficChannelValues = (channel: string) => {
  const websiteId = useSelector(getInstanceId);
  const mapper = useCallback((data: string[]) => {
    return data.filter((item) => item.trim() !== '').map((item) => ({ label: item, value: item }));
  }, []);
  return useFetchData<DefaultFilterOptionType[]>(
    isDefaultWebsiteId(websiteId) ? null : `/websites/${websiteId}/traffic-api/values/${channel}`,
    { revalidateIfStale: false, revalidateOnFocus: false },
    mapper,
  );
};
