export const UPDATE_WEBSITE_LOCALE_REQUEST = 'API_UPDATE_WEBSITE_LOCALE_REQUEST';
export const UPDATE_WEBSITE_LOCALE_SUCCEEDED = 'API_UPDATE_WEBSITE_LOCALE_SUCCEEDED';
export const UPDATE_WEBSITE_LOCALE_FAILED = 'API_UPDATE_WEBSITE_LOCALE_FAILED';

export const CONFIRM_UPDATE_EMAIL_REQUEST = 'API_CONFIRM_UPDATE_EMAIL_REQUEST';
export const CONFIRM_UPDATE_EMAIL_SUCCEEDED = 'API_CONFIRM_UPDATE_EMAIL_SUCCEEDED';
export const CONFIRM_UPDATE_EMAIL_FAILED = 'API_CONFIRM_UPDATE_EMAIL_FAILED';

export const RESET_EMAIL_REQUEST = 'API_RESET_EMAIL_REQUEST';
export const RESET_EMAIL_SUCCEEDED = 'API_RESET_EMAIL_SUCCEEDED';
export const RESET_EMAIL_FAILED = 'API_RESET_EMAIL_FAILED';

export const CHANGE_EMAIL_REQUEST = 'API_CHANGE_EMAIL_REQUEST';
export const CHANGE_EMAIL_SUCCEEDED = 'API_CHANGE_EMAIL_SUCCEEDED';
export const CHANGE_EMAIL_FAILED = 'API_CHANGE_EMAIL_FAILED';

export const UPDATE_USER_INFO_REQUEST = 'API_UPDATE_USER_INFO';
export const UPDATE_USER_INFO_SUCCEEDED = 'API_UPDATE_USER_SUCCESS';
export const UPDATE_USER_INFO_FAILED = 'API_UPDATE_USER_INFO_FAILED';

export const CHANGE_AGENCY_STATUS_REQUEST = 'API_CHANGE_AGENCY_STATUS_REQUEST';
export const CHANGE_AGENCY_STATUS_SUCCEEDED = 'API_CHANGE_AGENCY_STATUS_SUCCEEDED';
export const CHANGE_AGENCY_STATUS_FAILED = 'API_CHANGE_AGENCY_STATUS_FAILED';

export const CHANGE_WEBSITE_WHITE_LABEL_STATUS_REQUEST = 'API_CHANGE_WEBSITE_WHITE_LABEL_STATUS_REQUEST';
export const CHANGE_WEBSITE_WHITE_LABEL_STATUS_SUCCEEDED = 'API_CHANGE_WEBSITE_WHITE_LABEL_STATUS_SUCCEEDED';
export const CHANGE_WEBSITE_WHITE_LABEL_STATUS_FAILED = 'API_CHANGE_WEBSITE_WHITE_LABEL_STATUS_FAILED';

export const CHANGE_ACCOUNT_DETAIL_REQUEST = 'API_CHANGE_ACCOUNT_DETAIL_REQUEST';
export const CHANGE_ACCOUNT_DETAIL_SUCCEEDED = 'API_CHANGE_ACCOUNT_DETAIL_SUCCEEDED';
export const CHANGE_ACCOUNT_DETAIL_FAILED = 'API_CHANGE_ACCOUNT_DETAIL_FAILED';

export const REFRESH_TOKEN = 'API_REFRESH_TOKEN';
export const REFRESH_TOKEN_ENDED = 'API_REFRESH_TOKEN_ENDED';

export const GET_WEBSITE_OVERVIEW = 'API_GET_WEBSITE_OVERVIEW';
export const INITIATE_WEBSITE_OVERVIEW = 'API_INITIATE_WEBSITE_OVERVIEW';
export const RESET_WEBSITE_OVERVIEW = 'API_RESET_WEBSITE_OVERVIEW';
export const GET_WEBSITE_OVERVIEW_SUCCEEDED = 'API_GET_WEBSITE_OVERVIEW_SUCCEEDED';
export const GET_WEBSITE_OVERVIEW_FAILED = 'API_GET_WEBSITE_OVERVIEW_FAILED';

export const DELETE_ACCOUNT_REQUEST = 'API_DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_SUCCEEDED = 'API_DELETE_ACCOUNT_SUCCEEDED';
export const DELETE_ACCOUNT_FAILED = 'API_DELETE_ACCOUNT_FAILED';

export const GET_TRACKING_CODE_REQUEST = 'API_GET_TRACKING_CODE_REQUEST';
export const GET_TRACKING_CODE_SUCCEEDED = 'API_GET_TRACKING_CODE_SUCCEEDED';
export const GET_TRACKING_CODE_FAILED = 'API_GET_TRACKING_CODE_FAILED';

export const REMOVE_WEBSITE_REQUEST = 'API_REMOVE_WEBSITE_REQUEST';
export const REMOVE_WEBSITE_SUCCEEDED = 'API_REMOVE_WEBSITE_SUCCEEDED';
export const REMOVE_WEBSITE_FAILED = 'API_REMOVE_WEBSITE_FAILED';

export const MUTATE_WEBSITES = 'MUTATE_WEBSITES';

export const GET_WEBSITES_REQUEST = 'API_GET_WEBSITES_REQUEST';
export const GET_WEBSITES_SUCCEEDED = 'API_GET_WEBSITES_SUCCEEDED';
export const GET_WEBSITES_FAILED = 'API_GET_WEBSITES_FAILED';

export const ACCEPT_CONTRIBUTOR_ROLE_REQUEST = 'API_ACCEPT_CONTRIBUTOR_ROLE_REQUEST';
export const ACCEPT_CONTRIBUTOR_ROLE_SUCCEEDED = 'API_ACCEPT_CONTRIBUTOR_ROLE_SUCCEEDED';
export const ACCEPT_CONTRIBUTOR_ROLE_FAILED = 'API_ACCEPT_CONTRIBUTOR_ROLE_FAILED';

export const REJECT_CONTRIBUTOR_ROLE_REQUEST = 'API_REJECT_CONTRIBUTOR_ROLE_REQUEST';
export const REJECT_CONTRIBUTOR_ROLE_SUCCEEDED = 'API_REJECT_CONTRIBUTOR_ROLE_SUCCEEDED';
export const REJECT_CONTRIBUTOR_ROLE_FAILED = 'API_REJECT_CONTRIBUTOR_ROLE_FAILED';

export const REGISTER_CONTRIBUTOR_REQUEST = 'API_REGISTER_CONTRIBUTOR_REQUEST';
export const REGISTER_CONTRIBUTOR_SUCCEEDED = 'API_REGISTER_CONTRIBUTOR_SUCCEEDED';
export const REGISTER_CONTRIBUTOR_FAILED = 'API_REGISTER_CONTRIBUTOR_FAILED';

export const LEAVE_WEBSITE_REQUEST = 'API_LEAVE_WEBSITE_REQUEST';
export const LEAVE_WEBSITE_SUCCEEDED = 'API_LEAVE_WEBSITE_SUCCEEDED';
export const LEAVE_WEBSITE_FAILED = 'API_LEAVE_WEBSITE_FAILED';

export const CREATE_USER_REQUEST = 'API_CREATE_USER_REQUEST';
export const CREATE_USER_SUCCEEDED = 'API_CREATE_USER_SUCCEEDED';
export const CREATE_USER_FAILED = 'API_CREATE_USER_FAILED';

export const ACTIVATE_USER_REQUEST = 'API_ACTIVATE_USER_REQUEST';
export const ACTIVATE_USER_SUCCEEDED = 'API_ACTIVATE_USER_SUCCEEDED';
export const ACTIVATE_USER_FAILED = 'API_ACTIVATE_USER_FAILED';

export const LOGIN_REQUEST = 'API_LOGIN_REQUEST';
export const LOGIN_SUCCEEDED = 'API_LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'API_LOGIN_FAILED';

export const CHECK_LOGIN_REQUEST = 'API_CHECK_LOGIN_REQUEST';
export const CHECK_LOGIN_SUCCEEDED = 'API_CHECK_LOGIN_SUCCEEDED';
export const CHECK_LOGIN_FAILED = 'API_CHECK_LOGIN_FAILED';

export const BACKGROUND_LOGIN_REQUEST = 'API_BACKGROUND_LOGIN_REQUEST';
export const BACKGROUND_LOGIN_SUCCEEDED = 'API_BACKGROUND_LOGIN_SUCCEEDED';
export const BACKGROUND_LOGIN_FAILED = 'API_BACKGROUND_LOGIN_FAILED';

export const FORGOT_PASSWORD_REQUEST = 'API_FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCEEDED = 'API_FORGOT_PASSWORD_SUCCEEDED';
export const FORGOT_PASSWORD_FAILED = 'API_FORGOT_PASSWORD_FAILED';

export const RESET_PASSWORD_REQUEST = 'API_RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCEEDED = 'API_RESET_PASSWORD_SUCCEEDED';
export const RESET_PASSWORD_FAILED = 'API_RESET_PASSWORD_FAILED';

export const RESEND_REGISTRATION_CONFIRMATION_REQUEST = 'API_RESEND_REGISTRATION_CONFIRMATION_REQUEST';
export const RESEND_REGISTRATION_CONFIRMATION_SUCCEEDED = 'API_RESEND_REGISTRATION_CONFIRMATION_SUCCEEDED';
export const RESEND_REGISTRATION_CONFIRMATION_FAILED = 'API_RESEND_REGISTRATION_CONFIRMATION_FAILED';

export const GENERATE_IMPERSONATE_TOKEN_REQUEST = 'API_GENERATE_IMPERSONATE_TOKEN_REQUEST';
export const GENERATE_IMPERSONATE_TOKEN_SUCCEEDED = 'API_GENERATE_IMPERSONATE_TOKEN_SUCCEEDED';
export const GENERATE_IMPERSONATE_TOKEN_FAILED = 'API_GENERATE_IMPERSONATE_TOKEN_FAILED';

export const LOGOUT = 'LOGOUT';

export const FIND_MIGRATIONS_USER_REQUEST = 'FIND_MIGRATIONS_USER_REQUEST';
export const FIND_MIGRATIONS_USER_SUCCEEDED = 'FIND_MIGRATIONS_USER_SUCCEEDED';
export const FIND_MIGRATIONS_USER_FAILED = 'FIND_MIGRATIONS_USER_FAILED';

export const CREATE_WEBSITE_FOR_MIGRATION_REQUEST = 'CREATE_WEBSITE_FOR_MIGRATION_REQUEST';
export const CREATE_WEBSITE_FOR_MIGRATION_SUCCEEDED = 'CREATE_WEBSITE_FOR_MIGRATION_SUCCEEDED';
export const CREATE_WEBSITE_FOR_MIGRATION_FAILED = 'CREATE_WEBSITE_FOR_MIGRATION_FAILED';

export const CREATE_NEW_USER_FOR_MIGRATION_REQUEST = 'CREATE_NEW_USER_FOR_MIGRATION_REQUEST';
export const CREATE_NEW_USER_FOR_MIGRATION_SUCCEEDED = 'CREATE_NEW_USER_FOR_MIGRATION_SUCCEEDED';
export const CREATE_NEW_USER_FOR_MIGRATION_FAILED = 'CREATE_NEW_USER_FOR_MIGRATION_FAILED';

export const CREATE_NEW_SUBSCRIBER = 'API_CREATE_NEW_SUBSCRIBER';
export const CREATE_NEW_SUBSCRIBER_SUCCEEDED = 'API_CREATE_NEW_SUBSCRIBER_SUCCEEDED';
export const CREATE_NEW_SUBSCRIBER_FAILED = 'API_CREATE_NEW_SUBSCRIBER_FAILED';

export const ADD_VOUCHER = 'ADD_VOUCHER';
export const ADD_VOUCHER_SUCCEEDED = 'ADD_VOUCHER_SUCCEEDED';
export const ADD_VOUCHER_FAILED = 'ADD_VOUCHER_FAILED';

export const CREATE_APPSUMO_USER_REQUEST = 'CREATE_APPSUMO_USER_REQUEST';
export const CREATE_APPSUMO_USER_SUCCEEDED = 'CREATE_APPSUMO_USER_SUCCEEDED';
export const CREATE_APPSUMO_USER_FAILED = 'CREATE_APPSUMO_USER_FAILED';

export const GET_VOUCHERS = 'GET_VOUCHERS_REQUEST';
export const GET_VOUCHERS_SUCCEEDED = 'GET_VOUCHERS_SUCCEEDED';
export const GET_VOUCHERS_FAILED = 'GET_VOUCHERS_FAILED';

export const GET_VOUCHERS_INFO = 'GET_VOUCHERS_INFO_REQUEST';
export const GET_VOUCHERS_INFO_SUCCEEDED = 'GET_VOUCHERS_INFO_SUCCEEDED';
export const GET_VOUCHERS_INFO_FAILED = 'GET_VOUCHERS_INFO_FAILED';

export const APPLY_LIFETIME_DEAL_REQUEST = 'APPLY_LIFETIME_DEAL_REQUEST';
export const APPLY_LIFETIME_DEAL_SUCCEEDED = 'APPLY_LIFETIME_DEAL_SUCCEEDED';
export const APPLY_LIFETIME_DEAL_FAILED = 'APPLY_LIFETIME_DEAL_FAILED';

export const GET_GOOGLE_IMPORTER_AUTH_URL_REQUEST = 'GET_GOOGLE_IMPORTER_AUTH_URL_REQUEST';
export const GET_GOOGLE_IMPORTER_AUTH_URL_SUCCEEDED = 'GET_GOOGLE_IMPORTER_AUTH_URL_SUCCEEDED';
export const GET_GOOGLE_IMPORTER_AUTH_URL_FAILED = 'GET_GOOGLE_IMPORTER_AUTH_URL_FAILED';

export const GET_GOOGLE_IMPORTER_VIEWS_REQUEST = 'GET_GOOGLE_IMPORTER_VIEWS_REQUEST';
export const GET_GOOGLE_IMPORTER_VIEWS_SUCCEEDED = 'GET_GOOGLE_IMPORTER_VIEWS_SUCCEEDED';
export const GET_GOOGLE_IMPORTER_VIEWS_FAILED = 'GET_GOOGLE_IMPORTER_VIEWS_FAILED';

export const START_GOOGLE_IMPORTER_REQUEST = 'START_GOOGLE_IMPORTER_REQUEST';
export const START_GOOGLE_IMPORTER_SUCCEEDED = 'START_GOOGLE_IMPORTER_SUCCEEDED';
export const START_GOOGLE_IMPORTER_FAILED = 'START_GOOGLE_IMPORTER_FAILED';

export const GET_GOOGLE_IMPORTER_PROGRESS_REQUEST = 'GET_GOOGLE_IMPORTER_PROGRESS_REQUEST';
export const GET_GOOGLE_IMPORTER_PROGRESS_SUCCEEDED = 'GET_GOOGLE_IMPORTER_PROGRESS_SUCCEEDED';
export const GET_GOOGLE_IMPORTER_PROGRESS_FAILED = 'GET_GOOGLE_IMPORTER_PROGRESS_FAILED';
export const UPDATE_GOOGLE_IMPORTER_PROGRESS = 'UPDATE_GOOGLE_IMPORTER_PROGRESS';

export const GET_AGENCY_THEMES_REQUEST = 'API_GET_AGENCY_THEMES_REQUEST';
export const GET_AGENCY_THEMES_SUCCEEDED = 'API_GET_AGENCY_THEMES_SUCCEEDED';
export const GET_AGENCY_THEMES_FAILED = 'API_GET_AGENCY_THEMES_FAILED';

export const GET_AGENCY_UI_REQUEST = 'API_GET_AGENCY_UI_REQUEST';
export const GET_AGENCY_UI_SUCCEEDED = 'API_GET_AGENCY_UI_SUCCEEDED';
export const GET_AGENCY_UI_FAILED = 'API_GET_AGENCY_UI_FAILED';

export const GET_WEBSITE_AGENCY_UI_REQUEST = 'API_GET_WEBSITE_AGENCY_UI_REQUEST';
export const GET_CUSTOM_URL_AGENCY_UI_REQUEST = 'API_GET_CUSTOM_URL_AGENCY_UI_REQUEST';

export const GET_AGENCY_QUATERLY_COMMISSION_REQUEST = 'API_GET_AGENCY_QUATERLY_COMMISSION_REQUEST';
export const GET_AGENCY_QUATERLY_COMMISSION_SUCCEEDED = 'API_GET_AGENCY_QUATERLY_COMMISSION_SUCCEEDED';
export const GET_AGENCY_QUATERLY_COMMISSION_FAILED = 'API_GET_AGENCY_QUATERLY_COMMISSION_FAILED';
