import { IconProps } from '.';

export const VerticalThreeDotIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='9' cy='3.75' r='1.5' transform='rotate(90 9 3.75)' fill={color} />
      <circle cx='9' cy='14.25' r='1.5' transform='rotate(90 9 14.25)' fill={color} />
      <circle cx='9' cy='9' r='1.5' transform='rotate(90 9 9)' fill={color} />
    </svg>
  );
};
