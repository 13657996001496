import { SubscriptionList } from '@va/dashboard/api-client/newsletter-preferences';
import { NavChatInbox } from '@va/icons';
import { useTranslate } from '@va/localization';
import { ToggleActionCard } from '@va/ui/design-system';
import Skeleton from 'react-loading-skeleton';

type NotificationSettingsProps = {
  isLoading: boolean;
  newsletterKey: keyof typeof SubscriptionList;
  promotionsKey: keyof typeof SubscriptionList;
  onNewsletterChange: (value: keyof typeof SubscriptionList) => void;
  isSubscribedByType: (value: keyof typeof SubscriptionList) => boolean | undefined;
};

export function NotificationSettings({
  isLoading,
  newsletterKey,
  promotionsKey,
  onNewsletterChange,
  isSubscribedByType,
}: NotificationSettingsProps) {
  const translate = useTranslate();

  if (isLoading) {
    return <Skeleton className='h-48 rounded-18' />;
  }

  return (
    <>
      <ToggleActionCard
        heightClass='h-12'
        name={newsletterKey}
        title={translate('notifications.options.newsletter')}
        icon={() => <NavChatInbox color='#696969' />}
        handleChange={() => {
          onNewsletterChange(newsletterKey);
        }}
        value={!!isSubscribedByType(newsletterKey)}
      />
      <ToggleActionCard
        heightClass='h-12'
        name={promotionsKey}
        title={translate('notifications.options.promotions')}
        icon={() => <NavChatInbox color='#696969' />}
        handleChange={() => {
          onNewsletterChange(promotionsKey);
        }}
        value={!!isSubscribedByType(promotionsKey)}
      />
    </>
  );
}
