import {
  CONSENT_FLAG,
  EMBEDDED_VIDEO_TRANS_KEY_PREFIX,
  FEATURE_VIDEO_TRANS_KEY_PREFIX,
  modalName,
} from '@va/constants';
import { YouTubeConsent } from '@va/dashboard/feature/consent';
import { hasConsentFlag } from '@va/dashboard/selectors/app';
import { getProviderName } from '@va/dashboard/selectors/customization';
import { getActiveTab, hasAgreedConsentInSession, isMobile } from '@va/dashboard/selectors/ui';
import { withModal } from '@va/deprecated/components/withModal';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import { getFeatureVideoTransKey } from '@va/util/helpers';
import { VideoPlayer } from 'common/components/misc/VideoPlayer';
import YoutubeSubscribeBtn from 'common/components/misc/YoutubeSubscribeBtn';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import ModalWrapper from '../ModalWrapper';
import './VideoOverlay.scss';

class VideoOverlayContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.onYoutubeSubscribeClick = this.onYoutubeSubscribeClick.bind(this);
  }

  onYoutubeSubscribeClick() {
    this.props.closeModal(modalName.featureVideo);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isMobile && prevProps.isModalOpen !== this.props.isModalOpen) {
      if (this.props.isModalOpen) {
        window.addEventListener('beforeunload', this.onYoutubeSubscribeClick);
      } else {
        window.removeEventListener('beforeunload', this.onYoutubeSubscribeClick);
      }
    }
  }

  render() {
    const { translate, activeTabName, providerName } = this.props;
    const videoEmbedTransKey = getFeatureVideoTransKey(activeTabName, translate),
      directVideoTransKey = FEATURE_VIDEO_TRANS_KEY_PREFIX + `.${activeTabName}.directUrl`,
      prefix = `${FEATURE_VIDEO_TRANS_KEY_PREFIX}.${activeTabName}`,
      videoTitle = translate(`${prefix}.title`),
      videoCategory = translate(`${prefix}.category`),
      subscriptionMessage = translate(`${EMBEDDED_VIDEO_TRANS_KEY_PREFIX}.general.subscriptionMessage`, {
        providerName,
      }),
      directVideoUrl = translate(directVideoTransKey);

    return (
      <ModalWrapper
        animation='none'
        blackCloseButton
        className={'video-overlay-wrapper theme-white full-screen'}
        isOpen={this.props.isModalOpen}
        closeModal={this.props.closeModal}
        showCloseIconWithBorder
      >
        <React.Fragment>
          <div className='flex video-details flex-col md:flex-row md:items-center gap-1'>
            <div className='video-title'>{videoTitle}</div>
            <div className='video-category'>({videoCategory})</div>
          </div>
          <div>
            {this.props.youTubeConsentFlag ? (
              <Fragment>
                <div className='circular-box-shadow' />
                <VideoPlayer videoEmbedTransKey={videoEmbedTransKey} />
              </Fragment>
            ) : (
              <div className='bg-white rounded-12 border-solid border-2 border-gray-gallery'>
                <YouTubeConsent youTubeUrl={directVideoUrl} modalNameToBeClosed={modalName.featureVideo} />
              </div>
            )}
          </div>
          <div className='flex video-details subscribe flex-row justify-between'>
            <div className={'subscription-message'}>{subscriptionMessage}</div>
            <YoutubeSubscribeBtn />
          </div>
        </React.Fragment>
      </ModalWrapper>
    );
  }
}

VideoOverlayContainer.propTypes = {
  activeTabName: PropTypes.string,
  translate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  youTubeConsentFlag: PropTypes.bool,
  // withModal
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
};

const mapStateToProps = (state) => ({
  providerName: getProviderName(state),
  activeTabName: getActiveTab(state),
  isMobile: isMobile(state),
  youTubeConsentFlag:
    hasConsentFlag(state, CONSENT_FLAG.YOUTUBE) || hasAgreedConsentInSession(state, CONSENT_FLAG.YOUTUBE),
});

export default connect(mapStateToProps)(withModal(withTranslate(VideoOverlayContainer), modalName.featureVideo));
