import { IconProps } from '.';

export function VideoCameraIcon({ color = 'var(--color-primary)', className = '' }: IconProps) {
  return (
    <svg
      className={className}
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 1.5H10.5C11.3284 1.5 12 2.17157 12 3V5.28926V6.71074V9C12 9.82843 11.3284 10.5 10.5 10.5H3C2.17157 10.5 1.5 9.82843 1.5 9V3C1.5 2.17157 2.17157 1.5 3 1.5ZM13.4798 9.34978C13.3066 10.8418 12.0385 12 10.5 12H3C1.34315 12 0 10.6569 0 9V3C0 1.34315 1.34315 0 3 0H10.5C12.0385 0 13.3066 1.15816 13.4798 2.65022L13.993 2.00869C15.3217 0.347883 18 1.28738 18 3.41426V8.58574C18 10.7126 15.3217 11.6521 13.993 9.99131L13.4798 9.34978ZM13.5 6.71074C13.5 6.88105 13.558 7.04628 13.6643 7.17927L15.1643 9.05427C15.6072 9.60787 16.5 9.2947 16.5 8.58574V3.41426C16.5 2.7053 15.6072 2.39213 15.1643 2.94574L13.6643 4.82074C13.558 4.95372 13.5 5.11895 13.5 5.28926V6.71074Z'
        fill={color}
      />
    </svg>
  );
}
