import { PageHeaderProps } from '@va/types/page-header';
import { usePageHeaderContext } from '@va/util/components';
import { useEffect } from 'react';

export const usePageHeaderProps = (headerProps: PageHeaderProps) => {
  const { setPageHeaderProps } = usePageHeaderContext();

  useEffect(() => {
    setPageHeaderProps(headerProps);
    return () => {
      setPageHeaderProps({});
    };
  }, [headerProps, setPageHeaderProps]);
};
