import classNames from 'classnames';
import { IconProps } from '.';

export const LayersIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.5 4.5L2.17705 5.33853L9.5 9L16.8229 5.33853L18.5 4.5L16.8229 3.66147L9.5 0L2.17705 3.66147L0.5 4.5ZM3.8541 4.5L9.5 7.32295L15.1459 4.5L9.5 1.67705L3.8541 4.5ZM3.5 7.5L2.17705 8.16147L0.5 9L2.17705 9.83853L9.5 13.5L16.8229 9.83853L18.5 9L16.8229 8.16147L15.5 7.5L13.8229 8.33853L15.1459 9L9.5 11.8229L3.8541 9L5.17705 8.33853L3.5 7.5ZM2.17705 12.6615L3.5 12L5.17705 12.8385L3.8541 13.5L9.5 16.3229L15.1459 13.5L13.8229 12.8385L15.5 12L16.8229 12.6615L18.5 13.5L16.8229 14.3385L9.5 18L2.17705 14.3385L0.5 13.5L2.17705 12.6615Z'
        fill={color}
      />
    </svg>
  );
};


