import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';

/**
 * Simple tooltip that wraps an icon
 * Uses new tooltip implementation that does not rely on redux
 */
const IconTooltip = ({ children, icon, className }) => {
  // Used for the active state of the info icon
  const [isActive, setIsActive] = useState(false);

  const tooltipContent = (children) => {
    return <div className='font-inter'>{children}</div>;
  };

  return (
    <TooltipWrapper
      interactive
      content={tooltipContent(children)}
      open={isActive}
      onOpenChange={(open) => setIsActive(open)}
    >
      <div className={classNames('flex cursor-pointer', className)}>{icon({ isActive })}</div>
    </TooltipWrapper>
  );
};

IconTooltip.propTypes = {
  icon: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default IconTooltip;
