import { apiRequestResponseMsg, apiStatus, modalName } from '@va/constants';
import { closeModal } from '@va/dashboard/actions/ui';
import { getCheckLoginModal, isModalOpen } from '@va/dashboard/selectors/ui';
import { useTranslate } from '@va/localization';
import { checkLogin, logout } from '@va/standalone/shared/actions';
import { getApiRequest } from '@va/standalone/shared/selectors';
import { ModalFormWrapper } from '@va/ui/components/modals';
import { Paragraph, TextInput, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';

enum FormFieldsNames {
  password = 'password',
}

const CheckPasswordModal = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const checkLoginApiResponse = useSelector((state) => getApiRequest(state, 'checkLogin'))?.toJS();
  const isOpen = useSelector((state) => isModalOpen(state, modalName.checkLogin));
  const checkLoginModal = useSelector(getCheckLoginModal);
  const { pendingModalName } = checkLoginModal;

  const checkLoginApiStatus = useMemo(() => checkLoginApiResponse.status, [checkLoginApiResponse]);

  const { values, errors, handleChange, setFieldValue, touched, handleSubmit, resetForm } = useFormik({
    initialValues: {
      [FormFieldsNames.password]: '',
    },
    validationSchema: object().shape({
      [FormFieldsNames.password]: string().required(translate('form.requiredField')),
    }),
    onSubmit: (values) => {
      dispatch(checkLogin({ password: values.password }));
    },
  });

  const handleClose = useCallback(() => {
    dispatch(closeModal(modalName.checkLogin));
  }, [dispatch]);

  useEffect(() => {
    if (checkLoginApiStatus === apiStatus.SUCCEEDED) {
      handleClose();
    }
  }, [checkLoginApiStatus, handleClose]);

  const errorMessage = useMemo(() => {
    if (checkLoginApiResponse?.error?.message === apiRequestResponseMsg.BAD_CREDENTIALS) {
      return translate('form.errors.incorrectUserPassword');
    }
    return translate('form.generalError');
  }, [translate, checkLoginApiResponse?.error?.message]);

  const checkLoginApiFailed = useMemo(() => checkLoginApiStatus === apiStatus.FAILED, [checkLoginApiStatus]);

  return (
    <ModalFormWrapper
      title={translate(`modal.${pendingModalName}.title`)}
      isOpen={isOpen}
      onClose={() => {
        handleClose();
        resetForm();
      }}
      onSubmit={handleSubmit}
      saveButtonProps={{
        text:
          checkLoginApiStatus === apiStatus.IN_PROGRESS
            ? translate('button.isLoading')
            : translate(`button.${pendingModalName}`),
      }}
    >
      <div className='flex flex-col p-3 gap-3'>
        <Paragraph>{translate('modal.checkPassword.mainText')}</Paragraph>
        <TextInput
          id='password'
          label={translate('form.labels.password')}
          name={FormFieldsNames.password}
          type='password'
          required
          onChange={handleChange}
          clearField={() => {
            setFieldValue(FormFieldsNames.password, '');
          }}
          value={values.password}
          autoComplete='new-password'
          error={errors.password && touched.password ? translate(errors.password) : undefined}
          showTogglePasswordVisibilityButton
          autoFocus
        />
        {checkLoginApiFailed && (
          <Paragraph weight={fontWeights.medium} className='ml-2 text-negative'>
            {errorMessage}
          </Paragraph>
        )}
        {checkLoginApiFailed && (
          <div className='flex flex-col text-center'>
            <Paragraph weight={fontWeights.semibold}>{translate('modal.checkPassword.forgotPassword')}</Paragraph>
            <Paragraph
              className='ml-2 text-negative cursor-pointer'
              size={paragraphSizes.tiny}
              onClick={() => dispatch(logout())}
            >
              {translate('modal.checkPassword.logoutText')}
            </Paragraph>
          </div>
        )}
      </div>
    </ModalFormWrapper>
  );
};

export default CheckPasswordModal;
