import { FastField } from 'formik';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import SingleValueDropdown from '../SingleValueDropdown';
import Setting from './Setting';

/** @deprecated */
const DropdownSetting = ({ children, description, name, options, initialValue }) => {
  const fieldFn = useCallback(
    ({ form }) => (
      <SingleValueDropdown
        options={options}
        onChange={(option) => form.setFieldValue(name, option.value)}
        initialValue={initialValue}
      />
    ),
    [],
  );

  return (
    <Setting description={description} field={<FastField name={name}>{fieldFn}</FastField>}>
      {children}
    </Setting>
  );
};

DropdownSetting.defaultProps = {
  description: null,
};

DropdownSetting.propTypes = {
  children: PropTypes.string.isRequired,
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default DropdownSetting;
