import { PageHeaderProps } from '@va/types/page-header';
import { createContext, PropsWithChildren, useContext, useState } from 'react';

type PageHeaderContextType = {
  pageHeaderProps: PageHeaderProps | undefined;
  setPageHeaderProps: (v: PageHeaderProps) => void;
};

const PageHeaderContext = createContext<PageHeaderContextType>({} as PageHeaderContextType);

const PageHeaderContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [pageHeaderProps, setPageHeaderProps] = useState<PageHeaderProps>();

  return (
    <PageHeaderContext.Provider value={{ pageHeaderProps, setPageHeaderProps }}>{children}</PageHeaderContext.Provider>
  );
};

const usePageHeaderContext = () => {
  return useContext(PageHeaderContext);
};

export { PageHeaderContextProvider, usePageHeaderContext };
