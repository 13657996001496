import { getWebsite } from '@va/dashboard/selectors/core';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import { hasPermission } from '@va/standalone/shared/selectors';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import NotificationLimitChangeSubscriptionButton from './NotificationLimitChangeSubscriptionButton';
import NotificationLimitProgressBar from './NotificationLimitProgressBar';

function NotificationLimit(props) {
  const { hasChangeSubscriptionPermission, translate, numberOfMonthlyVisits, limit, subTitleText } = props;
  return (
    <React.Fragment>
      <div className='limit-notification-title-row'>{translate(`notifications.visitsLimit.congratulation`)}</div>
      <div className='limit-notification-subtitle-row'>
        {subTitleText?.trim()?.length ? translate(subTitleText) : translate('notifications.visitsLimit.subtitle.l1')}
      </div>

      <NotificationLimitProgressBar consumed={numberOfMonthlyVisits} limit={limit} />
      <div className={`upgrade-btn-container ${!hasChangeSubscriptionPermission ? 'btn-disable' : ''}`}>
        <NotificationLimitChangeSubscriptionButton
          isDisabled={!hasChangeSubscriptionPermission}
          tooltipData={'tooltips.contributorRole'}
        />
      </div>
    </React.Fragment>
  );
}

NotificationLimit.propTypes = {
  translate: PropTypes.func.isRequired,
  numberOfMonthlyVisits: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  locale: PropTypes.string.isRequired,
  hasChangeSubscriptionPermission: PropTypes.bool.isRequired,
  subTitleText: PropTypes.string,
};

const mapStateToProps = (state) => {
  const website = getWebsite(state);
  return {
    numberOfMonthlyVisits: website.numberOfMonthlyVisits,
    hasChangeSubscriptionPermission: hasPermission(state),
  };
};

export default connect(mapStateToProps)(withTranslate(NotificationLimit));
