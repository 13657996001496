export const SquareBox: React.FC<{ color?: string; className?: string }> = ({ color = '#696969', className }) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 0L18 2.98654V15.0135L9 18L0 15.0135V2.98654L9 0ZM7.24671 2.15585L13.125 4.10649L14.8783 3.52468L9 1.57404L7.24671 2.15585ZM16.5 4.56058L9.75 6.80048V16.1771L16.5 13.9372V4.56058ZM8.25 16.1771V6.80048L1.5 4.56058V13.9372L8.25 16.1771ZM3.12171 3.52468L9 5.47532L10.7533 4.89351L4.875 2.94287L3.12171 3.52468Z'
        fill={color}
      />
    </svg>
  );
};
