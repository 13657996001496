import { apiStatus, userActions } from '@va/constants';
import { updateSsrSettings } from '@va/dashboard/actions/api';
import { getApiRequest } from '@va/dashboard/selectors/api';
import { useTranslate } from '@va/localization';
import { hasPermission } from '@va/standalone/shared/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

export const useToggleRecordings = () => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const history = useHistory();

  const ssrSettingsResponse: any = useSelector((state) => getApiRequest(state, 'getSsrSettings'));
  const updateSsrSettingsResponse: any = useSelector((state) => getApiRequest(state, 'updateSsrSettings'));
  const hasPermissionToChangeRecordingStatus = useSelector((state) => hasPermission(state, userActions.ChangeStatus));
  const isToggleRecordingsPaused =
    ssrSettingsResponse && typeof ssrSettingsResponse.paused === 'boolean' ? ssrSettingsResponse.paused : true;

  const getToggleDisable = () => {
    const disabled =
      updateSsrSettingsResponse.status === apiStatus.IN_PROGRESS || !hasPermissionToChangeRecordingStatus; // when to not allow the user be able to interact with the toggler

    return disabled;
  };

  const toggleDisabled = getToggleDisable();

  const toggle = () => {
    if (ssrSettingsResponse.status !== apiStatus.SUCCEEDED) {
      handleDisabledButton();
      return;
    }
    dispatch(updateSsrSettings({ paused: !isToggleRecordingsPaused }));
  };

  const handleDisabledButton = () => {
    if (!hasPermissionToChangeRecordingStatus) return;
    history.push('/behaviour/recordings');
  };

  const tooltip = () => {
    if (!hasPermissionToChangeRecordingStatus) return translate('tooltips.contributorRole');
    if (isToggleRecordingsPaused) return translate('panels.recordings.toggle.on');
    return translate('panels.recordings.toggle.off');
  };

  return {
    toggle,
    handleDisabledButton,
    isToggleRecordingsPaused,
    toggleDisabled,
    hasPermissionToChangeRecordingStatus,
    ssrSettingsResponse,
    tooltipText: tooltip(),
  };
};
