// === Paddle ===

export const GET_UPDATE_PAYMENT_METHOD_URL = 'API_GET_UPDATE_PAYMENT_METHOD_URL';
export const GET_UPDATE_PAYMENT_METHOD_URL_SUCCEEDED = 'API_GET_UPDATE_PAYMENT_METHOD_URL_SUCCEEDED';
export const GET_UPDATE_PAYMENT_METHOD_URL_FAILED = 'API_GET_UPDATE_PAYMENT_METHOD_URL_FAILED';

export const UPDATE_SUBSCRIPTION_REQUEST = 'API_UPDATE_SUBSCRIPTION_REQUEST';
export const UPDATE_SUBSCRIPTION_SUCCEEDED = 'API_UPDATE_SUBSCRIPTION_SUCCEEDED';
export const UPDATE_SUBSCRIPTION_FAILED = 'API_UPDATE_SUBSCRIPTION_FAILED';

export const GET_AGENCY_CUSTOMER_REQUEST = 'API_GET_AGENCY_CUSTOMER_REQUEST';
export const GET_AGENCY_CUSTOMER_SUCCEEDED = 'API_GET_AGENCY_CUSTOMER_SUCCEEDED';
export const GET_AGENCY_CUSTOMER_FAILED = 'API_GET_AGENCY_CUSTOMER_FAILED';

// === Common ===

export const GET_PAYMENT_METHOD_REQUEST = 'API_GET_PAYMENT_METHOD_REQUEST';
export const GET_PAYMENT_METHOD_SUCCEEDED = 'API_GET_PAYMENT_METHOD_SUCCEEDED';
export const GET_PAYMENT_METHOD_FAILED = 'API_GET_PAYMENT_METHOD_FAILED';

export const GET_PAYMENT_PLANS_REQUEST = 'API_GET_PAYMENT_PLANS_REQUEST';
export const GET_PAYMENT_PLANS_SUCCEEDED = 'API_GET_PAYMENT_PLANS_SUCCEEDED';
export const GET_PAYMENT_PLANS_FAILED = 'API_GET_PAYMENT_PLANS_FAILED';

export const GET_ALL_SUBSCRIPTIONS_REQUEST = 'API_GET_ALL_SUBSCRIPTIONS_REQUEST';
export const GET_ALL_SUBSCRIPTIONS_SUCCEEDED = 'API_GET_ALL_SUBSCRIPTIONS_SUCCEEDED';
export const GET_ALL_SUBSCRIPTIONS_FAILED = 'API_GET_ALL_SUBSCRIPTIONS_FAILED';

export const CANCEL_SUBSCRIPTION_REQUEST = 'API_CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCEEDED = 'API_CANCEL_SUBSCRIPTION_SUCCEEDED';
export const CANCEL_SUBSCRIPTION_FAILED = 'API_CANCEL_SUBSCRIPTION_FAILED';

// === Braintree ===

export const GET_CUSTOMER_AUTH_TOKEN_REQUEST = 'API_GET_CUSTOMER_AUTH_TOKEN_REQUEST';
export const GET_CUSTOMER_AUTH_TOKEN_SUCCEEDED = 'API_GET_CUSTOMER_AUTH_TOKEN_SUCCEEDED';
export const GET_CUSTOMER_AUTH_TOKEN_FAILED = 'API_GET_CUSTOMER_AUTH_TOKEN_FAILED';

export const GET_BRAINTREE_AUTH_TOKEN_REQUEST = 'API_GET_BRAINTREE_AUTH_TOKEN_REQUEST';
export const GET_BRAINTREE_AUTH_TOKEN_SUCCEEDED = 'API_GET_BRAINTREE_AUTH_TOKEN_SUCCEEDED';
export const GET_BRAINTREE_AUTH_TOKEN_FAILED = 'API_GET_BRAINTREE_AUTH_TOKEN_FAILED';

export const GET_CUSTOMER_REQUEST = 'API_GET_CUSTOMER_REQUEST';
export const GET_CUSTOMER_SUCCEEDED = 'API_GET_CUSTOMER_SUCCEEDED';
export const GET_CUSTOMER_FAILED = 'API_GET_CUSTOMER_FAILED';

export const GET_COMPUTED_SUBSCRIPTION_PRICE_REQUEST = 'API_GET_COMPUTED_SUBSCRIPTION_PRICE_REQUEST';
export const GET_COMPUTED_SUBSCRIPTION_PRICE_SUCCEEDED = 'API_GET_COMPUTED_SUBSCRIPTION_PRICE_SUCCEEDED';
export const GET_COMPUTED_SUBSCRIPTION_PRICE_FAILED = 'API_GET_COMPUTED_SUBSCRIPTION_PRICE_FAILED';

export const VALIDATE_VAT_REQUEST = 'API_VALIDATE_VAT_REQUEST';
export const VALIDATE_VAT_SUCCEEDED = 'API_VALIDATE_VAT_SUCCEEDED';
export const VALIDATE_VAT_FAILED = 'API_VALIDATE_VAT_FAILED';

export const SUBMIT_PAYMENT_REQUEST = 'API_SUBMIT_PAYMENT_REQUEST';
export const SUBMIT_PAYMENT_SUCCEEDED = 'API_SUBMIT_PAYMENT_SUCCEEDED';
export const SUBMIT_PAYMENT_FAILED = 'API_SUBMIT_PAYMENT_FAILED';

export const CREATE_CUSTOMER_REQUEST = 'API_CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCEEDED = 'API_CREATE_CUSTOMER_SUCCEEDED';
export const CREATE_CUSTOMER_FAILED = 'API_CREATE_CUSTOMER_FAILED';

export const CREATE_PAYMENT_METHOD_REQUEST = 'API_CREATE_PAYMENT_METHOD_REQUEST';
export const CREATE_PAYMENT_METHOD_SUCCEEDED = 'API_CREATE_PAYMENT_METHOD_SUCCEEDED';
export const CREATE_PAYMENT_METHOD_FAILED = 'API_CREATE_PAYMENT_METHOD_FAILED';

export const CREATE_SUBSCRIPTION_REQUEST = 'API_CREATE_SUBSCRIPTION_REQUEST';
export const CREATE_SUBSCRIPTION_SUCCEEDED = 'API_CREATE_SUBSCRIPTION_SUCCEEDED';
export const CREATE_SUBSCRIPTION_FAILED = 'API_CREATE_SUBSCRIPTION_FAILED';

export const CANCEL_SUBSCRIPTION_IMMEDIATE_REQUEST = 'API_CANCEL_SUBSCRIPTION_IMMEDIATE_REQUEST';
export const CANCEL_SUBSCRIPTION_IMMEDIATE_SUCCEEDED = 'API_CANCEL_SUBSCRIPTION_IMMEDIATE_SUCCEEDED';
export const CANCEL_SUBSCRIPTION_IMMEDIATE_FAILED = 'API_CANCEL_SUBSCRIPTION_IMMEDIATE_FAILED';

export const REACTIVATE_SUBSCRIPTION_REQUEST = 'API_REACTIVATE_SUBSCRIPTION_REQUEST';
export const REACTIVATE_SUBSCRIPTION_SUCCEEDED = 'API_REACTIVATE_SUBSCRIPTION_SUCCEEDED';
export const REACTIVATE_SUBSCRIPTION_FAILED = 'API_REACTIVATE_SUBSCRIPTION_FAILED';

export const UPDATE_CUSTOMER_REQUEST = 'API_UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCEEDED = 'API_UPDATE_CUSTOMER_SUCCEEDED';
export const UPDATE_CUSTOMER_FAILED = 'API_UPDATE_CUSTOMER_FAILED';

export const GENERATE_INVOICE = 'API_GENERATE_INVOICE';
export const GENERATE_INVOICE_SUCCEEDED = 'API_GENERATE_INVOICE_SUCCEEDED';
export const GENERATE_INVOICE_FAILED = 'API_GENERATE_INVOICE_FAILED';

export const VALIDATE_PROMO_CODE = 'API_VALIDATE_PROMO_CODE';
export const VALIDATE_PROMO_CODE_SUCCEEDED = 'API_VALIDATE_PROMO_CODE_SUCCEEDED';
export const VALIDATE_PROMO_CODE_FAILED = 'API_VALIDATE_PROMO_CODE_FAILED';
