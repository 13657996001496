import { DeviceTypesEnum } from '@va/types/device';
import { DesktopDeviceIcon } from './DesktopDeviceIcon';
import { MobileDeviceIcon } from './MobileDeviceIcon';
import { OtherDevicesIcon } from './OtherDevicesIcon';
import { TabletDeviceIcon } from './TabletDeviceIcon';

export function DeviceIcon({
  deviceType,
  color,
  className,
}: {
  deviceType: DeviceTypesEnum;
  color?: string;
  className?: string;
}) {
  switch (deviceType) {
    case DeviceTypesEnum.desktop:
      return <DesktopDeviceIcon className={className} color={color} />;
    case DeviceTypesEnum.mobile:
    case DeviceTypesEnum.tablet:
      return <TabletDeviceIcon className={className} color={color} />;
    case DeviceTypesEnum.phone:
      return <MobileDeviceIcon className={className} color={color} />;
    default:
      return <OtherDevicesIcon className={className} color={color} />;
  }
}
