export const GoogleAppIcon: React.FC<JSX.IntrinsicElements['svg']> = (props) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 24 24'
      xmlSpace='preserve'
      {...props}
    >
      <path
        d='M18.9,20.7l-3.6-2.9c-1,0.6-2.1,0.9-3.3,0.9c-2.8,0-5.2-1.8-6.1-4.2l-3.6,2.9c1.9,3.4,5.5,5.8,9.7,5.8
	C14.6,23.1,17,22.2,18.9,20.7L18.9,20.7z M12,22.1c-3.4,0-6.6-1.7-8.4-4.6l1.9-1.6c1.3,2.2,3.8,3.7,6.5,3.7c1.1,0,2.2-0.2,3.2-0.7
	l2.1,1.7C15.7,21.6,13.9,22.1,12,22.1z'
      />
      <path
        d='M22.8,10h-4.6h-6v4.4h5.9c-0.5,1.4-1.5,2.6-2.8,3.3l0,0l0,0l3.6,2.9c1.9-1.6,3.3-3.8,3.9-6.3
	c0.1-0.7,0.2-1.4,0.2-2.2C23,11.4,22.9,10.7,22.8,10z'
      />
      <path
        d='M12,1.1C7.7,1.1,4.1,3.5,2.2,7l0,0l3.6,2.9C6.7,7.3,9.1,5.5,12,5.5c1.3,0,2.5,0.4,3.5,1l3.6-2.9
	C17.1,2,14.7,1.1,12,1.1z M15.4,5.3c-1-0.5-2.2-0.8-3.4-0.8C9.3,4.5,6.8,6,5.4,8.3L3.5,6.7C5.3,3.8,8.5,2,12,2
	c1.9,0,3.8,0.6,5.4,1.6L15.4,5.3z'
      />
      <path
        d='M5.8,10L5.8,10L2.2,7.1l0,0C1.4,8.6,1,10.3,1,12.2s0.5,3.7,1.3,5.2l0,0l3.5-2.9l0,0c-0.3-0.7-0.4-1.5-0.4-2.3
	C5.5,11.3,5.6,10.6,5.8,10z'
      />
    </svg>
  );
};
