import { CheckmarkIconFilled, EmptyCheckmarkIcon, LockIconFilled, SearchIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { TooltipWrapper, TooltipWrapperProps } from '@va/ui/tooltips';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { AccordionSection } from '../../accordions';
import { TextInput } from '../../inputs';
import { Paragraph, ParagraphWithTooltip, fontWeights } from '../../typography';

export enum OptionStatusEnum {
  inactive = 0,
  active = 1,
  locked = 2,
}

export type SelectDropdownWithGroupsOptionType = {
  value: string | number;
  label: string;
  icon: React.ReactNode;
  status: OptionStatusEnum;
  className?: string;
};

type SelectDropdownWithGroupsProps = {
  options: SelectDropdownWithGroupsOptionType[];
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  titleClassName?: string;
  subtitleClassName?: string;
  maxHeightClass?: string;
  onOptionClick: (option: SelectDropdownWithGroupsOptionType) => void;
  isInitiallyOpen?: boolean;
  disabled?: boolean;
  infoIcon?: React.ReactElement;
  accordionChildTooltipProps?: TooltipWrapperProps;
  bgColorClass?: string;
  inputColor?: string;
  checkedIconColor?: string;
  'data-testid'?: string;
};

export const SelectDropdownWithGroups: React.FC<SelectDropdownWithGroupsProps> = ({
  options,
  icon,
  subtitle,
  title,
  titleClassName,
  subtitleClassName,
  maxHeightClass = 'max-h-[300px]',
  onOptionClick,
  isInitiallyOpen,
  disabled = false,
  accordionChildTooltipProps,
  infoIcon,
  bgColorClass = 'bg-white',
  inputColor,
  checkedIconColor,
  'data-testid': dataTestId,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const filteredOptions = useMemo(
    () => options.filter((option) => option.label.toLowerCase().includes(searchValue.toLowerCase())),
    [options, searchValue],
  );

  const lockedOptions = useMemo(
    () => filteredOptions.filter((option) => option.status === OptionStatusEnum.locked),
    [filteredOptions],
  );
  const activeOptions = useMemo(
    () => filteredOptions.filter((option) => option.status === OptionStatusEnum.active),
    [filteredOptions],
  );
  const inactiveOptions = useMemo(
    () => filteredOptions.filter((option) => option.status === OptionStatusEnum.inactive),
    [filteredOptions],
  );

  const translate = useTranslate();

  const handleOptionClick = useCallback(
    (option: SelectDropdownWithGroupsOptionType) => {
      !disabled && onOptionClick(option);
    },
    [disabled, onOptionClick],
  );

  return (
    <AccordionSection
      className='hover:!bg-white'
      heightClass='h-60px'
      bgColorClass={bgColorClass}
      icon={icon}
      title={title}
      subtitle={subtitle}
      isInitiallyOpen={isInitiallyOpen}
      infoIcon={infoIcon}
      titleClassName={titleClassName}
      subtitleClassName={subtitleClassName}
      data-testid={dataTestId}
    >
      <div className='p-2'>
        <TextInput
          value={searchValue}
          placeholder={translate('labels.search')}
          heightClass='h-12'
          clearField={() => {
            setSearchValue('');
          }}
          icon={<SearchIcon color='#696969' />}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          wrapperClassName='mb-6px'
          bgColor={inputColor}
        />
        <TooltipWrapper {...accordionChildTooltipProps!} disabled={!accordionChildTooltipProps}>
          <ul
            className={classNames(maxHeightClass, 'overflow-auto scrollbar scrollbar-thumb', {
              'opacity-60': disabled,
            })}
          >
            {filteredOptions.length === 0 && (
              <Paragraph className='p-5'>{translate('select.search.noMatch')}</Paragraph>
            )}

            <Group
              onOptionClick={handleOptionClick}
              options={sortOptions(lockedOptions)}
              title={translate('labels.selectOptions.lockedOptions')}
              disabled={disabled}
              checkedIconColor={checkedIconColor}
            />
            <Group
              onOptionClick={handleOptionClick}
              options={sortOptions(activeOptions)}
              title={translate('labels.selectOptions.activeOptions')}
              disabled={disabled}
              checkedIconColor={checkedIconColor}
            />
            <Group
              onOptionClick={handleOptionClick}
              options={sortOptions(inactiveOptions)}
              title={translate('labels.selectOptions.inactiveOptions')}
              disabled={disabled}
              checkedIconColor={checkedIconColor}
            />
          </ul>
        </TooltipWrapper>
      </div>
    </AccordionSection>
  );
};

const Group = ({
  options,
  title,
  onOptionClick,
  disabled = false,
  checkedIconColor,
}: {
  options: SelectDropdownWithGroupsOptionType[];
  title: string;
  onOptionClick: (option: SelectDropdownWithGroupsOptionType) => void;
  disabled?: boolean;
  checkedIconColor?: string;
}) => {
  return (
    <>
      {options.length > 0 && (
        <Paragraph className='text-center' weight={fontWeights.semibold}>
          {title}
        </Paragraph>
      )}
      {options.map((option, index) => (
        <OptionListItem
          onClick={onOptionClick}
          key={index}
          option={option}
          disabled={disabled}
          checkedIconColor={checkedIconColor}
        />
      ))}
    </>
  );
};

const OptionListItem = ({
  option,
  onClick,
  disabled = false,
  checkedIconColor,
}: {
  option: SelectDropdownWithGroupsOptionType;
  onClick: (option: SelectDropdownWithGroupsOptionType) => void;
  disabled?: boolean;
  checkedIconColor?: string;
}) => {
  const getStatusIcon = useCallback((status: OptionStatusEnum) => {
    if (status === OptionStatusEnum.active) {
      return <CheckmarkIconFilled color={checkedIconColor} />;
    }
    if (status === OptionStatusEnum.inactive) {
      return <EmptyCheckmarkIcon />;
    }
    if (status === OptionStatusEnum.locked) {
      return <LockIconFilled />;
    }
  }, []);
  return (
    <li
      onClick={() => {
        onClick(option);
      }}
      className={classNames('flex items-center gap-3 h-12 px-4 rounded-12', option?.className, {
        'cursor-not-allowed': disabled,
        'cursor-pointer hover:bg-white-snow': !disabled,
        'bg-white': option?.status === OptionStatusEnum.active,
      })}
    >
      {option.icon}
      <ParagraphWithTooltip weight={fontWeights.medium}>{option.label}</ParagraphWithTooltip>
      <div className='ml-auto'>{getStatusIcon(option.status)}</div>
    </li>
  );
};

function sortOptions(options: SelectDropdownWithGroupsOptionType[]) {
  return options.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });
}

export function getNextStatus(oldStatus: OptionStatusEnum) {
  if (oldStatus === OptionStatusEnum.active) return OptionStatusEnum.locked;
  if (oldStatus === OptionStatusEnum.inactive) return OptionStatusEnum.active;
  return OptionStatusEnum.inactive;
}

export function updateOptionsOnClick(
  options: SelectDropdownWithGroupsOptionType[],
  selectedOptionValue: number | string,
) {
  const selectedOptionIndex = options.findIndex((option) => option.value === selectedOptionValue)!;
  const selectedOption = options[selectedOptionIndex];

  const newStatus = getNextStatus(selectedOption.status);
  const updatedOptions = [...options];
  updatedOptions[selectedOptionIndex] = {
    ...selectedOption,
    status: newStatus,
  };
  return updatedOptions;
}
