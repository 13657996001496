import Translation from '@va/deprecated/components/Translation';
import PropTypes from 'prop-types';

export const VideoPlayer = (props) => (
  <div>
    <div className='ratio-16by9 video-embed-wrapper'>
      <div className='ratio-content'>
        <Translation translationKey={props.videoEmbedTransKey} />
      </div>
    </div>
    {props.note && <div className={'text-small'}>{props.note}</div>}
  </div>
);
VideoPlayer.propTypes = {
  videoEmbedTransKey: PropTypes.string.isRequired,
  note: PropTypes.string,
};
