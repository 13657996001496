import { PAGE_HEADER_NESTED_TABS_WRAPPER_ID } from '@va/constants';
import { TestAttributes } from '@va/types/component';
import { NestedRoute, NestedTab } from '@va/types/navigation';
import { ContextMenuProps, HorizontalScroll, NestedNavigationLink } from '@va/ui/design-system';
import { Portal } from '@va/util/components';
import classNames from 'classnames';
import React, { ElementType, Fragment, Suspense, useCallback, useEffect, useMemo, useRef } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router';
import { NestedPageNavigationTab } from './NestedPageNavigationTab';

enum elementType {
  tab = 'tab',
  route = 'route',
}

export type NestedPageNavigationProps = {
  routes: Array<NestedRoute | NestedTab>;
  componentProps?: Record<string, any>;
  onRouteChange?: () => void;
  containerElementId?: string;
  activeColor?: string;
  tabsWrapperClassName?: string;
  renderCustomLinkComponent?: (
    props: TestAttributes & {
      url: string;
      onRouteChange?: () => void;
      isActive: boolean;
      className?: string;
      activeColor?: string;
      icon?: ElementType;
      label: string;
      tooltip?: string;
      contextMenu?: ContextMenuProps;
      contextMenuElement?: React.ReactNode;
    },
  ) => React.ReactNode;
};

type NavigationItem = (NestedTab | NestedRoute) & { type: elementType };

// TODO Add story file
export const NestedPageNavigation: React.FC<NestedPageNavigationProps> = ({
  routes,
  componentProps,
  onRouteChange,
  containerElementId,
  renderCustomLinkComponent,
  activeColor,
  tabsWrapperClassName,
}) => {
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const headerNavigationRef = useRef<HTMLDivElement>(null);

  const scrollToSelectedTab = useCallback(() => {
    const headerNavigationContent = headerNavigationRef?.current;
    if (!headerNavigationContent) return;
    const containerData = headerNavigationContent.children[0];
    const activeElement = containerData.getElementsByClassName('active')[0];
    if (!(activeElement instanceof HTMLElement)) return;
    const activeElementOffsetLeftValue = activeElement.offsetLeft;
    containerData.scrollLeft = activeElementOffsetLeftValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerNavigationRef?.current]);

  const elements: NavigationItem[] = useMemo(
    () =>
      routes.map((r) => ({
        ...r,
        type: 'link' in r ? elementType.route : elementType.tab,
      })),
    [routes],
  );

  useEffect(() => {
    scrollToSelectedTab();
    window.addEventListener('resize', scrollToSelectedTab);
    return () => window.removeEventListener('resize', scrollToSelectedTab);
  }, [pathname, scrollToSelectedTab]);

  return (
    <Fragment>
      <Portal elementSelector={`#${containerElementId}`}>
        <HorizontalScroll
          ref={headerNavigationRef}
          className='z-[var(--zIndex-layout)] order-first lg:order-last h-full'
          containerClassName='h-full'
        >
          <div
            id={PAGE_HEADER_NESTED_TABS_WRAPPER_ID}
            className={classNames(
              'min-w-full inline-flex flex-row flex-nowrap justify-start border-b-2 border-gray-gallery bg-white backdrop-filter backdrop-blur-md px-18px h-full',
              tabsWrapperClassName,
            )}
          >
            {elements
              .filter((r) => r.shouldDisplay !== false)
              .map((element) => {
                let label, tooltip, icon, className, content, link, contextMenu, contextMenuElement, rest;
                let completeUrl, isTabActive;

                switch (element.type) {
                  case elementType.tab:
                    ({ label, tooltip, icon, className, content, ...rest } = element as NestedTab);

                    return (
                      <NestedPageNavigationTab
                        key={label}
                        label={label}
                        tooltip={tooltip}
                        icon={icon}
                        className={className}
                        isActive={false}
                        hasTooltip={!!tooltip}
                        content={content}
                        data-testid={rest['data-testid']}
                        activeColor={activeColor}
                      />
                    );
                  case elementType.route:
                    ({ label, tooltip, icon, className, contextMenu, contextMenuElement, link, ...rest } =
                      element as NestedRoute);

                    completeUrl = getCompletePath(url, link);
                    isTabActive = pathname === completeUrl;

                    if (renderCustomLinkComponent) {
                      return renderCustomLinkComponent({
                        url: getCompletePath(url, link),
                        isActive: getCompletePath(url, link) === pathname,
                        className,
                        label,
                        icon,
                        tooltip,
                        contextMenu,
                        contextMenuElement,
                        activeColor,
                        'data-testid': rest['data-testid'],
                      });
                    }
                    return (
                      <NestedNavigationLink
                        key={completeUrl}
                        onRouteChange={onRouteChange}
                        label={label}
                        isActive={isTabActive}
                        url={completeUrl}
                        icon={icon}
                        className={className}
                        contextMenu={contextMenu}
                        contextMenuElement={contextMenuElement}
                        data-testid={rest['data-testid']}
                        activeColor={activeColor}
                      />
                    );
                  default:
                    ({ label, tooltip, icon, className, contextMenu, contextMenuElement, link, ...rest } =
                      element as NestedRoute);
                    completeUrl = getCompletePath(url, link);
                    isTabActive = pathname === completeUrl;
                    return (
                      <NestedNavigationLink
                        key={completeUrl}
                        onRouteChange={onRouteChange}
                        label={label}
                        isActive={isTabActive}
                        url={completeUrl}
                        icon={icon}
                        className={className}
                        contextMenu={contextMenu}
                        contextMenuElement={contextMenuElement}
                        data-testid={rest['data-testid']}
                        activeColor={activeColor}
                      />
                    );
                }
              })}
            <div className='nav-border border-t-2 border-devil-gray absolute left-0 bottom-0 transition ease-out duration-200 -mb-0.5' />
          </div>
        </HorizontalScroll>
      </Portal>

      <Suspense fallback={null}>
        <Switch>
          {elements
            .filter(
              (r) =>
                r.type === elementType.route &&
                r.shouldDisplay !== false &&
                (r as NestedRoute).shouldRenderRoute !== false,
            )
            .map((r) => {
              const { link, component: Component, exact = false } = r as NestedRoute;
              return (
                <Route exact={exact} key={path + link} path={getCompletePath(path, link)}>
                  <Component {...componentProps} />
                </Route>
              );
            })}
          <Redirect
            exact
            push={false}
            from={path}
            to={getCompletePath(path, (elements.find((x) => x.type === elementType.route) as NestedRoute)?.link)}
          />
        </Switch>
      </Suspense>
    </Fragment>
  );
};

function getCompletePath(path: string, routePath: string) {
  if (path === '/') return routePath;
  return path + routePath;
}
