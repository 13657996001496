import classNames from 'classnames';
import React from 'react';
import { IconProps } from '.';

export const PendingIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 17C13.1421 17 16.5 13.6421 16.5 9.5C16.5 5.35786 13.1421 2 9 2C4.85786 2 1.5 5.35786 1.5 9.5C1.5 13.6421 4.85786 17 9 17ZM9 18.5C13.9706 18.5 18 14.4706 18 9.5C18 4.52944 13.9706 0.5 9 0.5C4.02944 0.5 0 4.52944 0 9.5C0 14.4706 4.02944 18.5 9 18.5ZM11.25 9.5C11.25 10.7426 10.2426 11.75 9 11.75C7.75736 11.75 6.75 10.7426 6.75 9.5C6.75 8.25736 7.75736 7.25 9 7.25C10.2426 7.25 11.25 8.25736 11.25 9.5ZM13.5 9.5C13.5 11.9853 11.4853 14 9 14C6.51472 14 4.5 11.9853 4.5 9.5C4.5 7.01472 6.51472 5 9 5C11.4853 5 13.5 7.01472 13.5 9.5ZM15 9.5C15 12.8137 12.3137 15.5 9 15.5C5.68629 15.5 3 12.8137 3 9.5C3 6.18629 5.68629 3.5 9 3.5C12.3137 3.5 15 6.18629 15 9.5Z'
        fill={color}
      />
    </svg>
  );
};


