import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { apiStatus } from '@va/constants';
import ModalWrapper from '@va/dashboard/shared/modals/ModalWrapper';

import { changeEmail } from '@va/standalone/shared/actions';
import './ChangeEmail.scss';
import ChangeEmailCard from './ChangeEmailComponent';

import { resetRequestStatus } from '@va/dashboard/actions/api';
import { closeModal } from '@va/dashboard/actions/ui';
import { isModalOpen as isModalOpenSelector } from '@va/dashboard/selectors/ui';
import { useTranslate } from '@va/localization';
import { getApiRequestStatus } from '@va/standalone/shared/selectors';

const ChangeEmailContainer = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state) => isModalOpenSelector(state, 'changeEmail'));
  const changeEmailStatus = useSelector((state) => getApiRequestStatus(state, 'changeEmail'));

  function onCloseModal() {
    if (changeEmailStatus === apiStatus.IN_PROGRESS) {
      return;
    }

    dispatch(resetRequestStatus('changeEmail'));
    dispatch(closeModal('changeEmail'));
  }

  const onSubmit = useCallback((data: { email: string }) => dispatch(changeEmail(data)), [dispatch]);

  return (
    <ModalWrapper
      className='change-email-wrapper'
      closeModal={onCloseModal}
      isOpen={isModalOpen}
      showCloseIconWithBorder={!(changeEmailStatus === apiStatus.IN_PROGRESS)}
      showCloseButton={false}
    >
      <ChangeEmailCard
        translate={translate}
        closeModal={onCloseModal}
        changeEmailStatus={changeEmailStatus}
        onSubmit={onSubmit}
      />
    </ModalWrapper>
  );
};

export default ChangeEmailContainer;
