import { TEST_IDS } from '@va/constants';
import { VisitDetails } from '@va/dashboard/components';
import { BrowserIcon } from '@va/icons';
import { useTranslate } from '@va/localization';

// TODO Update types
export function BrowserCell({ value }: { value: string }) {
  const translate = useTranslate();
  return (
    <VisitDetails
      data-testid={TEST_IDS.generic.visitInfo.browser}
      tooltipText={translate(`panels.latestVisitors.browser`)}
      tooltipSubtext={value}
      icon={<BrowserIcon browserName={value} className={'w-18 h-18'} />}
    />
  );
}
