import { useLocale } from '@va/localization';
import { addNumberSeparator } from '@va/util/helpers';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**@deprecated */
export const PercentageCell = ({ value }) => {
  const { locale } = useLocale();
  return (
    <span
      className={classNames({
        'text-pomegranate': value < 100 / 3, //value < 33.3%
        'text-fuel-yellow': value >= 100 / 3 && value < (100 * 2) / 3, //33.3% <= value < 66.6%
        'text-salem': value >= (100 * 2) / 3, //value >=66.6%
      })}
    >
      {addNumberSeparator(value, locale) + '%'}
    </span>
  );
};
PercentageCell.propTypes = {
  value: PropTypes.number,
};
