import classnames from 'classnames';
import { IconProps } from '.';

export const FilledInformationIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={classnames(className)}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM10.0838 8.22511C9.90804 7.05358 10.8154 6 12 6C13.1846 6 14.092 7.05358 13.9162 8.22511L13.2919 12.3874C13.196 13.0269 12.6466 13.5 12 13.5C11.3534 13.5 10.804 13.0269 10.7081 12.3874L10.0838 8.22511ZM10.5 16.5C10.5 15.6716 11.1716 15 12 15C12.8284 15 13.5 15.6716 13.5 16.5C13.5 17.3284 12.8284 18 12 18C11.1716 18 10.5 17.3284 10.5 16.5Z'
        fill={color ?? 'var(--color-primary)'}
      />
    </svg>
  );
};


