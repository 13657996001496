import { UNTRANSLATED_LOCALE } from '@va/constants';
import { useLocalizationContext } from '../context';

export const useLocale = () => {
  const { locale, setLocale } = useLocalizationContext();

  return {
    // used for number localization throughout the app
    locale: locale === UNTRANSLATED_LOCALE ? 'en' : locale,
    setLocale,
    // used in language selector
    rawLocale: locale,
  };
};
