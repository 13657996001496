import { LocalizationContext } from '@va/localization';
import { addNumberSeparator, renderIf } from '@va/util/helpers';
import classNames from 'classnames';
import { isNumber } from 'lodash';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import React, { Component } from 'react';
import 'react-rangeslider/lib/index.css';
import CurrentPlanInfo from '../../common/components/current-plan-info';
import UpgradeSlider from '../../common/components/upgrade-slider';
import CustomUpgradeSection from './CustomUpgradeSection';
import PlanInfo from './PlanInfo';
import './style.scss';

export default class UpgradeSliderComponent extends Component {
  getProcessedPlanName = () => {
    const { planName, unlimitedVoucherCode, translate } = this.props;
    if (unlimitedVoucherCode) {
      return translate('plan.voucherWithCode', {
        voucherCode: unlimitedVoucherCode,
      });
    }
    if (!planName) {
      return translate('plan.free');
    }
    return planName;
  };

  upgradeSliderSection() {
    const { locale } = this.context;
    const {
      uiSubscriptionPlan,
      selectedPaymentPlans,
      translate,
      plansCount,
      previousModalView,
      uiSliderVal,
      activePlanIndex,
      setSubscriptionPlan,
      planIndex,
    } = this.props;

    const isCustomSubscriptionOptionSelected = uiSubscriptionPlan === plansCount + 1;
    const checkSubscriptionDetailVisibility = uiSubscriptionPlan > 0 && uiSubscriptionPlan !== plansCount + 1;
    const shouldShowSubscriptionDetailsBtn = renderIf(checkSubscriptionDetailVisibility);

    return (
      <div
        className={classNames({
          'upgrade-slider-section-wrapper': !checkSubscriptionDetailVisibility,
        })}
      >
        <div className='upgrade-slider-section flex flex-column md:flex-row justify-between items-center w-full select-none'>
          <div
            className={classNames('upgrade-slider-container md:pr-0 w-full', {
              'md:w-2/3': isCustomSubscriptionOptionSelected,
            })}
          >
            <UpgradeSlider
              plansCount={plansCount}
              previousModalView={previousModalView}
              uiSliderVal={uiSliderVal}
              activePlanIndex={isNumber(planIndex) ? planIndex : activePlanIndex}
              setSubscriptionPlan={setSubscriptionPlan}
              showEndRailLine={false}
            />
          </div>
          {shouldShowSubscriptionDetailsBtn(
            <div className='upgrade-slider-monthly-visits flex flex-column w-full md:w-1/3 bg-gray-alabaster md:ml-25 md:rounded-l-24'>
              <span className='font-inter font-semibold text-15 leading-24 text-gray-devil flex justify-center md:justify-end text-right'>
                {translate('upgradeCard.counter.monthlyVisits')}
              </span>
              <span className='font-inter font-medium text-3xl leading-9 flex justify-center md:justify-end text-right'>
                {addNumberSeparator(selectedPaymentPlans[uiSubscriptionPlan]?.visits, locale)}
              </span>
            </div>,
          )}
        </div>
      </div>
    );
  }

  currentPlanInfo() {
    const { translate } = this.props;

    return (
      <CurrentPlanInfo title={translate('upgradeModal.currentWebsitePlan')} planName={this.getProcessedPlanName()} />
    );
  }

  render() {
    const { uiSubscriptionPlan, plansCount, translate } = this.props;
    const shouldShowPriceCalculation = renderIf(uiSubscriptionPlan > 0 && uiSubscriptionPlan < plansCount);
    const shouldShowCustomUpgradeSection = renderIf(uiSubscriptionPlan === plansCount);
    return (
      <React.Fragment>
        {this.currentPlanInfo()}
        {this.upgradeSliderSection()}
        {shouldShowPriceCalculation(<PlanInfo {...this.props} />)}
        {shouldShowCustomUpgradeSection(<CustomUpgradeSection translate={translate} />)}
      </React.Fragment>
    );
  }
}

UpgradeSliderComponent.contextType = LocalizationContext;

UpgradeSliderComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  setSubscriptionType: PropTypes.func.isRequired,
  setSubscriptionPlan: PropTypes.func.isRequired,
  uiSubscriptionPlan: PropTypes.number.isRequired,
  uiSliderVal: PropTypes.number.isRequired,
  selectedPaymentPlans: PropTypes.array.isRequired,
  activePlanIndex: PropTypes.number.isRequired,
  plansCount: PropTypes.number.isRequired,
  hasSubscriptionExpired: PropTypes.bool.isRequired,
  previousModalView: PropTypes.string,
  locale: PropTypes.string,
  unlimitedVoucherCode: PropTypes.string,
};
