import { FunctionComponent, memo } from 'react';
import SidebarListComponent from './SidebarListComponent';
import { SidebarItemProps, SidebarListProps } from './propDefinitions';

import classNames from 'classnames';
import './Sidebar.scss';

const SidebarList: FunctionComponent<SidebarListProps> = (props) => {
  const { sidebarItems, isExpanded } = props;

  return (
    <nav
      aria-label='Main'
      className={classNames('flex flex-col w-full', {
        'items-center px-[6px]': !isExpanded,
        'px-2 lg:px-18px': isExpanded,
      })}
    >
      {sidebarItems
        .filter((item) => item.displayInLeftNav)
        .map((item: SidebarItemProps, index: number) => {
          return <SidebarListComponent key={index} item={item} {...props} />;
        })}
    </nav>
  );
};

export default memo(SidebarList);
