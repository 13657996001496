import PropTypes from 'prop-types';
import { BLACK_COLOR, BLUE_COLOR, GREEN_COLOR, PURE_BLACK_COLOR } from '../constants';

const subMenu = (isActive, color) => ({
  colorValue: isActive ? (color ? color : BLUE_COLOR) : PURE_BLACK_COLOR,
});

export function NavPages(props) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 4.5V13.5C1.5 14.3284 2.17157 15 3 15H9C9.82843 15 10.5 14.3284 10.5 13.5V9V4.5C10.5 3.67157 9.82843 3 9 3H6H3C2.17157 3 1.5 3.67157 1.5 4.5ZM3 1.5C1.34315 1.5 0 2.84315 0 4.5V13.5C0 15.1569 1.34315 16.5 3 16.5H9C10.6569 16.5 12 15.1569 12 13.5V9V4.5C12 2.84315 10.6569 1.5 9 1.5H6H3ZM13.5 3.75C13.5 3.33579 13.8358 3 14.25 3C14.6642 3 15 3.33579 15 3.75V14.25C15 14.6642 14.6642 15 14.25 15C13.8358 15 13.5 14.6642 13.5 14.25V3.75ZM17.25 4.5C16.8358 4.5 16.5 4.83579 16.5 5.25V12.75C16.5 13.1642 16.8358 13.5 17.25 13.5C17.6642 13.5 18 13.1642 18 12.75V5.25C18 4.83579 17.6642 4.5 17.25 4.5Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavManageBilling(props) {
  const { isActive, ...otherProps } = props;
  return isActive ? (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 30 30'
      xmlSpace='preserve'
      {...otherProps}
    >
      <g>
        <path
          fill={GREEN_COLOR}
          d='M19.75,4.52l5.41,5.41v16.59h-21v-22H19.75 M20.16,3.52h-17v24h23v-18L20.16,3.52L20.16,3.52z'
        />
      </g>
      <g>
        <g>
          <polygon fill={GREEN_COLOR} points='20.16,6.35 20.16,9.52 23.33,9.52 		' />
        </g>
        <g>
          <path
            fill={GREEN_COLOR}
            d='M19.16,10.52v-5h-14v20h19v-15H19.16z M8.16,11.52h4v1h-4V11.52z M17.16,22.52h-9v-1h9V22.52z M21.16,19.52
          h-13v-1h13V19.52z M21.16,16.52h-13v-1h13V16.52z'
          />
        </g>
      </g>
      <g>
        <polygon fill={GREEN_COLOR} points='19.33,5.52 20.11,6.29 20.11,3.48 19.11,3.48 19.11,5.52 	' />
        <polygon fill={GREEN_COLOR} points='23.3,9.48 24.16,10.35 24.16,10.48 26.11,10.48 26.11,9.48 	' />
      </g>
    </svg>
  ) : (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 30 30'
      xmlSpace='preserve'
      {...otherProps}
    >
      <g>
        <path
          fill={BLACK_COLOR}
          d='M19.75,4.52l5.41,5.41v16.59h-21v-22H19.75 M20.16,3.52h-17v24h23v-18L20.16,3.52L20.16,3.52z'
        />
        <g>
          <polygon fill={BLACK_COLOR} points='26.16,10.52 19.16,10.52 19.16,3.52 20.16,3.52 20.16,9.52 26.16,9.52 		' />
        </g>
        <g>
          <rect x='8.16' y='15.52' fill={BLACK_COLOR} width='13' height='1' />
        </g>
        <g>
          <rect x='8.16' y='11.52' fill={BLACK_COLOR} width='4' height='1' />
        </g>
        <g>
          <rect x='8.16' y='18.52' fill={BLACK_COLOR} width='13' height='1' />
        </g>
        <g>
          <rect x='8.16' y='21.52' fill={BLACK_COLOR} width='9' height='1' />
        </g>
      </g>
    </svg>
  );
}

export function NavAccountSettings(props) {
  const { isActive, ...otherProps } = props;
  return isActive ? (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 30 30'
      xmlSpace='preserve'
      {...otherProps}
    >
      <g>
        <path
          fill={GREEN_COLOR}
          d='M26.51,19.64c-2.56-0.47-5.1-1.03-7.66-1.52c-0.71,1.05-2.45,1.58-4.18,1.58c-1.71,0-3.41-0.52-4.09-1.58
      c-2.58,0.5-5.14,1.02-7.7,1.55c-0.01,3.35-0.01,6.7-0.01,10.06h23.65C26.53,26.36,26.53,23,26.51,19.64z'
        />
        <path
          fill={GREEN_COLOR}
          d='M14.64,14.92c0.31,0,0.63-0.02,0.94-0.07c2.88-0.33,5.39-2.68,5.93-5.53c0.48-2.25-0.26-4.71-1.89-6.31
      c-1.29-1.33-3.14-2.07-4.99-2.07c-0.21,0-0.42,0.01-0.64,0.03c-2.66,0.25-5.09,2.11-5.93,4.67c-0.85,2.35-0.29,5.15,1.41,6.97
      C10.76,14.07,12.7,14.92,14.64,14.92z'
        />
      </g>
    </svg>
  ) : (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 30 30'
      xmlSpace='preserve'
      {...otherProps}
    >
      <path
        fill={BLACK_COLOR}
        d='M19.33,19.56c0.92,0.18,1.85,0.37,2.77,0.56c1.03,0.21,2.06,0.42,3.1,0.62c0.01,2.56,0.01,5.12,0.01,7.67H4.2
    c0-2.55,0-5.11,0.01-7.66c2-0.41,3.98-0.81,5.89-1.18c1.02,0.93,2.66,1.46,4.58,1.46C16.62,21.02,18.28,20.49,19.33,19.56
    M18.85,18.12c-0.71,1.05-2.45,1.58-4.18,1.58c-1.71,0-3.41-0.52-4.09-1.58c-2.58,0.5-5.14,1.02-7.7,1.55
    c-0.01,3.35-0.01,6.7-0.01,10.06h23.65c0-3.36,0-6.72-0.02-10.08C23.95,19.17,21.42,18.61,18.85,18.12L18.85,18.12z'
      />
      <path
        fill={BLACK_COLOR}
        d='M14.62,2.25c1.51,0,3.03,0.63,4.07,1.7C20,5.24,20.6,7.24,20.21,9.08c-0.43,2.28-2.48,4.2-4.82,4.47
    c-0.24,0.04-0.5,0.05-0.75,0.05c-1.58,0-3.14-0.7-4.2-1.9c-1.37-1.47-1.83-3.73-1.13-5.66c0.66-2.01,2.63-3.56,4.8-3.76
    C14.28,2.26,14.45,2.25,14.62,2.25 M14.62,0.94c-0.21,0-0.42,0.01-0.64,0.03c-2.66,0.25-5.09,2.11-5.93,4.67
    c-0.85,2.35-0.29,5.15,1.41,6.97c1.29,1.46,3.22,2.32,5.16,2.32c0.31,0,0.63-0.02,0.94-0.07c2.88-0.33,5.39-2.68,5.93-5.53
    c0.48-2.25-0.26-4.71-1.89-6.31C18.32,1.68,16.47,0.94,14.62,0.94L14.62,0.94z'
      />
    </svg>
  );
}

NavPages.propTypes = {
  isActive: PropTypes.bool,
};

NavManageBilling.propTypes = {
  isActive: PropTypes.bool,
};

NavAccountSettings.propTypes = {
  isActive: PropTypes.bool,
};
