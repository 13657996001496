import { ColumnFiltersState, GroupingState, SortingState } from '@tanstack/react-table';

export type TablePagination = {
  itemsCount?: number;
  pagesCount?: number;
  pageSize: number;
  pageIndex: number;
  pageNumber?: number;
  manualPagination?: boolean;
  options?: number[];
  headerPaginationOptions?: {
    showCountInfo?: boolean;
    showPageSizeSelector?: boolean;
    showPaginationBtns?: boolean;
    elementContainerId?: string;
  };
  footerPaginationOptions?: { showCountInfo?: boolean; showPageSizeSelector?: boolean; showPaginationBtns?: boolean };
};

export type OnTableQueryChangeFunc<T extends {}> = (options: TableQuery<T>) => Promise<any>;

export type TableQuery<T extends {}> = {
  pagination?: TablePagination;
  sortBy?: SortingState;
  filters?: ColumnFiltersState;
  groupBy?: GroupingState;
  manualSortBy?: boolean;
  manualFilters?: boolean;
  manualGroupBy?: boolean;
  disableMultiSort?: boolean;
  detachedHeader?: boolean;
};

export enum Direction {
  DESC = 'desc',
  ASC = 'asc',
}

export type Pagination = {
  pageSize: number;
  page: number;
};
