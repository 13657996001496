import classNames from 'classnames';

export type Progress = {
  color: string;
  percentage: number;
};

type ProgressBarTypes = {
  progress: Progress[];
  className?: string;
};

export const ProgressBar = ({ progress, className }: ProgressBarTypes) => {
  return (
    <div
      className={classNames(
        'flex w-full my-auto rounded-full bg-gray-concrete group-hover:bg-white/70 h-3 overflow-hidden',
        className,
      )}
    >
      {progress.map(({ color, percentage }, index) => (
        <div
          key={index}
          style={{
            width: `${percentage}%`,
            backgroundColor: `${color}`,
          }}
          className='flex flex-col whitespace-nowrap first:rounded-l-10px last:rounded-r-10px !mr-[1px]'
        />
      ))}
    </div>
  );
};
