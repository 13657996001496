import { Chart, ChartConfiguration, ChartDataset, registerables, Tick } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { memo, useEffect, useRef, useState } from 'react';

import { useLocale } from '@va/localization';
import { addNumberSeparator } from '@va/util/helpers';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { limitTicksCallback } from '../line-chart/utils';

// TODO MOVE TO A DIFFERENT PLACE, THIS IS IMPACTING ALL THE OHER CHARTS
// Removing ...registerables from here will break all the charts
const reg = registerables ? registerables : []; // Fix for jest
Chart.register(...reg, ChartDataLabels);

export const HIDDEN_DATASET_ID = 'hiddenDaset';

export type StackedBarChartDataType = {
  labels: string[];
  datasets: ChartDataset<'bar'>[];
};

/** @deprecated */
export type DatasetType = {
  label: string;
  data: number[];
  backgroundColor?: string | string[];
  stack?: string;
  color?: string;
};

type labelValueType = 'percent' | 'total';

export type StackedBarChartPropTypes = {
  chartData: StackedBarChartDataType;
  wrapperClasses?: string;
  canvasId: string;
  labelType?: labelValueType;
  tooltipFn?: (context: any) => React.ReactNode;
  configuration?: {
    maintainAspectRatio?: boolean;
    aspectRatio?: number;
    limitTicks?: boolean;
    yAxisCallback?: (value: number | string, index: number, values: Tick[]) => number | string;
    xAxisMaxRotation?: number;
    displayRightSideAxis?: boolean;
    displayDataLabels?: boolean;
  };
  isLoading?: boolean;
};

export const StackedBarChart: React.FC<StackedBarChartPropTypes> = memo(
  ({ chartData, canvasId, tooltipFn, labelType, wrapperClasses, configuration, isLoading }) => {
    const [tooltipContext, setTooltipContext] = useState<any>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const chartRef = useRef<Chart<'bar'>>();
    const { locale } = useLocale();

    useEffect(() => {
      if (!canvasRef.current) return;

      const canvasContext = canvasRef.current.getContext('2d');

      if (!canvasContext) return;

      const config: ChartConfiguration<'bar'> = {
        type: 'bar',
        data: { ...chartData }, // Fix for variable reference issue that can occur when the chart is updating

        options: {
          maintainAspectRatio: configuration?.maintainAspectRatio,
          aspectRatio: configuration?.aspectRatio,
          layout: {
            padding: {
              top: 20,
            },
          },
          animation: false,
          plugins: {
            datalabels: {
              align: 'top',
              anchor: 'end',
              display: configuration?.displayDataLabels ?? false,
              offset: -4,
              font: {
                weight: 600,
              },
              formatter: (value) => addNumberSeparator(value, locale),
            },
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
            tooltip: {
              mode: 'index',
              position: 'average',
              enabled: tooltipFn ? false : true,
              external: (context) => {
                setTooltipContext(context);
              },
            },
          },
          responsive: true,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
              },
              border: { display: false },
              afterBuildTicks: (axis) => limitTicksCallback(axis, configuration?.limitTicks),
              ticks: {
                ...buildTicks(),
                maxRotation: configuration?.xAxisMaxRotation,
              },
            },
            y: {
              type: 'linear',
              stacked: true,
              position: 'left',
              grid: {
                color: 'transparent',
              },
              border: { color: 'transparent' },
              ticks: {
                ...buildTicks('#969696'),
                [configuration?.yAxisCallback ? 'callback' : '']: configuration?.yAxisCallback,
              },
            },
            y3: {
              type: 'linear',
              position: 'right',
              grid: {
                color: 'transparent',
              },
              border: { color: 'transparent' },
              afterDataLimits: (axis) => {
                const yScale = axis.chart.scales['y'];
                if (!yScale) return;
                axis.min = yScale.min;
                axis.max = yScale.max;
              },
              ticks: {
                ...buildTicks('#969696'),
                [configuration?.yAxisCallback ? 'callback' : '']: configuration?.yAxisCallback,
              },
              display: configuration?.displayRightSideAxis,
            },
          },
          elements: {
            bar: {
              borderRadius: 7,
            },
          },
        },
      };

      if (!chartRef.current) {
        chartRef.current = new Chart<'bar'>(canvasContext, config);
        return;
      }

      // If chart exists, update it
      updateChart(chartRef.current, config);
    }, [chartData, tooltipFn, labelType, configuration, locale]);

    return (
      <div className={classNames('relative', wrapperClasses)}>
        {isLoading && <Skeleton className='h-full rounded-24' />}
        <canvas className={classNames({ 'invisible absolute': isLoading })} id={canvasId} ref={canvasRef}></canvas>
        {tooltipContext && !isLoading && tooltipFn && tooltipFn(tooltipContext)}
      </div>
    );
  },
);

export default StackedBarChart;

function updateChart(chart: Chart<'bar'>, config: ChartConfiguration<'bar'>) {
  // Update chart options
  if (config.options) {
    chart.options = config.options;
  }

  // Update chart datasets
  chart.data.datasets = config.data.datasets;

  // Update chart labels
  if (chart.data.labels) {
    chart.data.labels = config.data.labels;
  }

  // Re-draw chart
  chart.update();
}

function buildTicks(color = '#696969', size = 12) {
  return {
    font: {
      family: window.fontFamily,
      size,
      weight: 500,
      lineHeight: '150%',
    },
    color,
  };
}

export function getHiddenDataset(data: number[], bgColor = '#F0F0F0') {
  const max = Math.max(...data);

  return {
    label: HIDDEN_DATASET_ID,
    data: data.map((value) => max - value),
    backgroundColor: bgColor,
  };
}
