import { Platform } from '@va/constants';
import { PlusIconFilled } from '@va/icons';
import { useTranslate } from '@va/localization';
import { APP_SUMO_PACKAGE_NAME, subscriptionStatus } from '@va/standalone/shared/constants';
import { isSumoUser } from '@va/standalone/shared/selectors';
import { Paragraph } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import SubscriptionTooltip from 'standalone/components/misc/SubscriptionTooltip';
import { WebsiteType } from '../agency-white-label-apiClient';

const BillingPlan = ({ website }: { website: WebsiteType }) => {
  const translate = useTranslate();
  const sumoAccountType = useSelector(isSumoUser);
  const websiteSubscription = website?.subscription;
  const hasActiveSubscription = [subscriptionStatus.ACTIVE, subscriptionStatus.PAUSED].includes(
    websiteSubscription?.status,
  );

  const planName = websiteSubscription?.planName;
  const isWix = website.platform.toLowerCase() === Platform.WIX.toLowerCase();

  const activeUntil = hasActiveSubscription && (websiteSubscription?.isActiveUntil || websiteSubscription?.activeUntil);

  if (isWix) return <p>{translate('plan.viewOnWix')}</p>;

  if (website.unlimitedPlanActive) {
    return <Paragraph colorClassName='text-green-green'>{translate('plan.unlimited')}</Paragraph>;
  }

  if (sumoAccountType && website.sumoActive && !hasActiveSubscription)
    return <div className='plan-green cursor-pointer'>{APP_SUMO_PACKAGE_NAME}</div>;

  const planNameWithSubscription = (
    <div
      className={classNames('width-fit-content table-cell cursor-pointer', {
        'text-red-negative': activeUntil || !hasActiveSubscription,
      })}
    >
      {planName}
    </div>
  );

  if (websiteSubscription && (activeUntil || !hasActiveSubscription))
    return (
      <div>
        <TooltipWrapper
          interactive
          content={<SubscriptionTooltip activeSubscription={websiteSubscription} timezone={website.timezone} />}
        >
          {planNameWithSubscription}
        </TooltipWrapper>
      </div>
    );

  if (websiteSubscription && !activeUntil) return planNameWithSubscription;

  if (website.sumoActive) return null;

  return (
    <div className='flex cursor-pointer'>
      <div className='text-orange-dark mr-4'>{translate('plan.free')}</div>
      <PlusIconFilled className='my-auto' />
    </div>
  );
};

export default BillingPlan;
