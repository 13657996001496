import { platform } from '@va/constants';
import { RadioButtonIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { WebsiteManagementCircle } from '@va/svg-visa-icons';
import { WebsiteType } from '@va/types/website';
import { ListCardItemWithIcon, ParagraphWithTooltip } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { getUrlWithoutProtocolAndTrailingSlash } from '@va/util/helpers';
import classNames from 'classnames';
import { forwardRef, useCallback, useMemo } from 'react';

type WebsiteListItemProps = {
  website: WebsiteType;
  onWebsiteSelect: (id: string) => void;
  selectedWebsite: string | null;
  isWixWebsiteAllowed: boolean;
};

const WebsiteListItem = forwardRef<HTMLLIElement, WebsiteListItemProps>(
  ({ onWebsiteSelect, website, selectedWebsite, isWixWebsiteAllowed }, ref) => {
    const translate = useTranslate();
    const planName = useMemo(() => {
      if (website.unlimitedPlanActive) return translate('plan.unlimited');
      return website.subscription?.planName ?? translate('plan.free');
    }, [translate, website.subscription?.planName, website.unlimitedPlanActive]);
    const url = useMemo(() => getUrlWithoutProtocolAndTrailingSlash(website.url), [website.url]);
    const websiteId = useMemo(() => website.id, [website.id]);
    const isWix = useMemo(() => website.platform === platform.WIX, [website.platform]);
    const websiteDisabled = useMemo(() => !isWixWebsiteAllowed && isWix, [isWix, isWixWebsiteAllowed]);
    const onWebsiteClickHandler = useCallback(() => {
      if (websiteDisabled) {
        return;
      }
      onWebsiteSelect(websiteId);
    }, [onWebsiteSelect, websiteDisabled, websiteId]);

    return (
      <TooltipWrapper
        interactive
        content={translate('table.manageWebsites.actions.wixWhiteLabelNotAllowed')}
        disabled={isWixWebsiteAllowed || !isWix}
        key={`website-tooltip-${websiteId}`}
      >
        <li
          onClick={onWebsiteClickHandler}
          className={classNames(
            'mt-2 flex justify-between bg-white-smoke max-h-72 h-72 rounded-18 py-1.5 pl-3 pr-3 w-full items-center',
            { 'cursor-not-allowed': websiteDisabled, 'cursor-pointer': !websiteDisabled },
          )}
          ref={ref}
        >
          <>
            <ListCardItemWithIcon
              key={websiteId}
              icon={<WebsiteManagementCircle />}
              topText={url}
              bottomNode={
                <ParagraphWithTooltip>
                  <>
                    {translate('website.currentPricingPlan')}: <span className='font-525'>{planName}</span>
                  </>
                </ParagraphWithTooltip>
              }
            />
            <RadioButtonIcon selected={websiteId === selectedWebsite} className='shrink-0' />
          </>
        </li>
      </TooltipWrapper>
    );
  },
);

export default WebsiteListItem;
