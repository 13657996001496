import { DataViewOption } from '@va/constants';
import { useTranslate } from '@va/localization';
import { Button, ReportBlock } from '@va/ui/design-system';
import { PropsWithChildren, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';

type LocationsCardProps = {
  showChangeLoadedDataButton: boolean;
  title: string;
  isLoading: boolean;
  hasNoData: boolean;
  rightSideElement?: ReactNode;
  onChangeLoadedData: () => void;
  showExtendedData: boolean;
};

const LocationsCard: React.FC<PropsWithChildren<LocationsCardProps>> = ({
  children,
  showChangeLoadedDataButton,
  title,
  isLoading,
  hasNoData,
  rightSideElement,
  onChangeLoadedData,
  showExtendedData,
}) => {
  const translate = useTranslate();

  return (
    <ReportBlock
      visualization={{ selectedView: DataViewOption.horizontalBarChart }}
      title={title}
      nodes={{ rightSide: rightSideElement }}
    >
      {isLoading ? (
        <div className='grow overflow-hidden'>
          <Skeleton className='h-60px mb-1 rounded-15' count={12} />
        </div>
      ) : (
        <>
          {children}
          {showChangeLoadedDataButton && (
            <Button
              color='tertiary'
              className='w-full'
              text={showExtendedData ? translate('button.showLess') : translate('button.loadMore')}
              onClick={() => {
                onChangeLoadedData();
              }}
            />
          )}
        </>
      )}
      {!isLoading && hasNoData && (
        <div className={`flex items-center justify-center min-h-[300px]`}>{translate('table.noData')}</div>
      )}
    </ReportBlock>
  );
};
export default LocationsCard;
