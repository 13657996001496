import { defaultWebsiteId, MANUALLY_TRANSLATED_LANGUAGES } from '@va/constants';
import { StorageKeysManager } from '@va/util/misc';
import { debounce as lodashDebounce } from 'lodash';
import moment from 'moment';
import { RefObject } from 'react';
import { SessionStorage } from '../storage';
import { capitalizeFirstLetter } from '../string';

export const renderIf = (predicate: boolean) => (component: React.ReactNode) => predicate ? component : null;

export function getCountryCodeByLocale(locale: string) {
  switch (locale.toLowerCase()) {
    case 'en':
      return 'US';
    case 'hi':
      return 'IN';
    case 'ja':
      return 'JP';
    case 'cs':
      return 'CZ';
    case 'ko':
      return 'KR';
    case 'da':
      return 'DK';
    case 'sv':
      return 'SE';
    case 'zh':
      return 'CN';
    case 'uk':
      return 'UA';
    case 'vi':
      return 'VN';
    case 'he':
      return 'IL';
    case 'el':
      return 'GR';
    case 'et':
      return 'EE';
    case 'ar':
      return 'SA';
    default:
      return locale.toUpperCase();
  }
}

export function getTypeOfVisitor(visitorType: number) {
  switch (visitorType) {
    case 1:
      return 'returningVisitor';
    case 2:
      return 'conversionVisitor';
    default:
      return 'newVisitor';
  }
}

export function debounce(callback: () => any, delay: number) {
  return lodashDebounce(() => callback(), delay);
}

export function getMaxExportLengthPerPage(total: number) {
  return [4000, 3000, 2000, 1000, 500, 100, 10].reduce((acc, val) => (total / val <= 10 ? val : acc), 5000);
}

export function decodeMalformedURIComponent(input: string) {
  try {
    return decodeURIComponent(input);
  } catch (err) {
    const fixedEncoding = encodeURI(input).replace(/%5B/g, '[').replace(/%5D/g, ']');
    return decodeURIComponent(fixedEncoding);
  }
}

/** @deprecated Will only be used in one place, move it there */
export const getAdCampaignLabel = (value: string, translate: (k: string) => string) => {
  return value ? capitalizeFirstLetter(value) : '[' + translate('ads.utm.notProvided.text') + ']';
};

export const generateId = () => {
  return Math.random().toString(36).substr(2, 9);
};

export const isTextEllipsed = (contentRef: RefObject<HTMLElement>) => {
  if (!contentRef.current) return false;
  const { scrollWidth, offsetWidth } = contentRef.current;
  return scrollWidth - offsetWidth > 2;
};

export const setResizeObserver = (elementRef: RefObject<HTMLElement>, onResize: () => void) => {
  if (!elementRef?.current) return;
  const baseTabContent = elementRef && elementRef.current;
  const resizeObserver = new ResizeObserver(() => {
    onResize();
  });
  resizeObserver.observe(baseTabContent);
  return () => {
    resizeObserver.unobserve(baseTabContent);
  };
};

export const isManuallyTranslatedLanguage = (locale: string) => {
  return MANUALLY_TRANSLATED_LANGUAGES.includes(locale);
};

export function getHumanReadableFilesize(size: number) {
  const sizes = [' Bytes', ' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB'];

  for (let i = 1; i < sizes.length; i++) {
    if (size < Math.pow(1024, i)) return Math.round((size / Math.pow(1024, i - 1)) * 100) / 100 + sizes[i - 1];
  }
  return size;
}

const setCurrentDateToSessionStorage = () => {
  SessionStorage.setItem(StorageKeysManager.getTodayDateKey(), JSON.stringify(moment()));
};

export const storeTimePeriodToSessionStorage = ({
  from,
  until,
  websiteId,
}: {
  websiteId: string;
  from: moment.Moment;
  until: moment.Moment;
}) => {
  if (!from || !until || websiteId === defaultWebsiteId) return;
  setCurrentDateToSessionStorage();
  SessionStorage.setItem(
    StorageKeysManager.getTimePeriodKey(websiteId),
    JSON.stringify({ from: from.toISOString(), until: until.toISOString() }),
  );
};

export const getTimePeriodFromSessionStorage = (
  websiteId: string,
  websiteTimezone: string,
  websiteInstallDate: number,
): { from: moment.Moment; until: moment.Moment } | undefined => {
  const storedTimePeriod = SessionStorage.getItem(StorageKeysManager.getTimePeriodKey(websiteId));

  if (!storedTimePeriod || storedTimePeriod === 'null') return;

  try {
    const parsedTimePeriod = JSON.parse(storedTimePeriod);
    if (typeof parsedTimePeriod != 'object') {
      throw new Error('invalid time period');
    }

    const from = moment(parsedTimePeriod.from).tz(window.timezone);
    const until = moment(parsedTimePeriod.until).tz(window.timezone);

    const todaysSessionDate = SessionStorage.getItem(StorageKeysManager.getTodayDateKey());

    if (!todaysSessionDate) {
      return { from, until };
    }

    const currentSessionMomentDate = moment(JSON.parse(todaysSessionDate)).tz(websiteTimezone);

    if (!currentSessionMomentDate.isSame(moment().tz(websiteTimezone), 'day')) {
      return updateSelectedPeriod(
        moment(parsedTimePeriod.from).tz(websiteTimezone),
        moment(parsedTimePeriod.until).tz(websiteTimezone),
        currentSessionMomentDate,
        websiteId,
        websiteTimezone,
        websiteInstallDate,
      );
    }

    return { from, until };
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export function updateSelectedPeriod(
  from: moment.Moment,
  until: moment.Moment,
  currentSessionDate: moment.Moment,
  websiteId: string,
  websiteTimezone: string,
  websiteInstallDate: number,
) {
  const momentWithTimezone = () => moment().tz(websiteTimezone);
  const yesterday = momentWithTimezone().subtract(1, 'days');
  const last7DaysStart = momentWithTimezone().subtract(7, 'days');
  const last30DaysStart = momentWithTimezone().subtract(30, 'days');
  const thisMonthStart = momentWithTimezone().startOf('month');
  const last6MonthsStart = momentWithTimezone().date(moment().get('date')).subtract(6, 'months');
  const last12MonthsStart = moment().tz(websiteTimezone).date(moment().get('date')).subtract(12, 'months');
  const thisYearStart = momentWithTimezone().startOf('year');

  let updatedDate = { from, until };

  const isToday = from.isSame(currentSessionDate, 'day') && until.isSame(currentSessionDate, 'day');
  const isYesterday = from.isSame(yesterday, 'day') && until.isSame(yesterday, 'day');
  const isLast7Days = from.isSame(last7DaysStart, 'day') && until.isSame(currentSessionDate, 'day');
  const isLast30Days = from.isSame(last30DaysStart, 'day') && until.isSame(currentSessionDate, 'day');
  const isThisMonth = from.isSame(thisMonthStart, 'day') && until.isSame(currentSessionDate, 'day');
  const isLast6Months = from.isSame(last6MonthsStart, 'day') && until.isSame(currentSessionDate, 'day');
  const isLast12Months = from.isSame(last12MonthsStart, 'day') && until.isSame(currentSessionDate, 'day');
  const isThisYear = from.isSame(thisYearStart, 'day') && until.isSame(currentSessionDate, 'day');
  const isInstallPeriodSelected =
    from.isSame(moment.unix(websiteInstallDate).tz(websiteTimezone), 'day') && until.isSame(currentSessionDate, 'day');

  if (isToday || isYesterday || isLast7Days || isLast30Days || isLast6Months || isLast12Months) {
    updatedDate = {
      from: from.add(1, 'day').startOf('day'),
      until: until.add(1, 'day').endOf('day'),
    };
  } else if (isThisMonth || isThisYear || isInstallPeriodSelected) {
    updatedDate = {
      from,
      until: until.add(1, 'day').endOf('day'),
    };
  }
  storeTimePeriodToSessionStorage({
    websiteId,
    ...updatedDate,
  });
  return updatedDate;
}
