import { apiStatus } from '@va/constants';
import {
  GET_MODULE_PERMISSIONS,
  GET_MODULE_PERMISSIONS_FAILED,
  GET_MODULE_PERMISSIONS_SUCCEEDED,
} from '@va/dashboard/actions/app';
import { Map } from 'immutable';
import { newApiRequest } from './core/util';

export const getModulePermissions = newApiRequest(
  GET_MODULE_PERMISSIONS,
  GET_MODULE_PERMISSIONS_SUCCEEDED,
  GET_MODULE_PERMISSIONS_FAILED,
  // @ts-ignore
  Map({ status: apiStatus.NONE, data: [] }),
);
