import { IconProps } from '.';

export const TableListIcon = ({ className, color = '#3C3C3C' }: IconProps) => {
  return (
    <svg
      className={className}
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.75 2.5H13.25C14.9069 2.5 16.25 3.84315 16.25 5.5V8.5H2.75V5.5C2.75 3.84315 4.09315 2.5 5.75 2.5ZM2.75 10V13C2.75 14.6569 4.09315 16 5.75 16H13.25C14.9069 16 16.25 14.6569 16.25 13V10H2.75ZM1.25 5.5C1.25 3.01472 3.26472 1 5.75 1H13.25C15.7353 1 17.75 3.01472 17.75 5.5V13C17.75 15.4853 15.7353 17.5 13.25 17.5H5.75C3.26472 17.5 1.25 15.4853 1.25 13V5.5Z'
        fill={color}
      />
      <path
        opacity='0.5'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.75 13C8.75 12.5858 9.08579 12.25 9.5 12.25H14C14.4142 12.25 14.75 12.5858 14.75 13C14.75 13.4142 14.4142 13.75 14 13.75H9.5C9.08579 13.75 8.75 13.4142 8.75 13Z'
        fill={color}
      />
      <path
        opacity='0.5'
        d='M7.25 13C7.25 13.8284 6.57843 14.5 5.75 14.5C4.92157 14.5 4.25 13.8284 4.25 13C4.25 12.1716 4.92157 11.5 5.75 11.5C6.57843 11.5 7.25 12.1716 7.25 13Z'
        fill={color}
      />
      <path
        opacity='0.5'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.75 5.5C8.75 5.08579 9.08579 4.75 9.5 4.75H14C14.4142 4.75 14.75 5.08579 14.75 5.5C14.75 5.91421 14.4142 6.25 14 6.25H9.5C9.08579 6.25 8.75 5.91421 8.75 5.5Z'
        fill={color}
      />
      <path
        opacity='0.5'
        d='M7.25 5.5C7.25 6.32843 6.57843 7 5.75 7C4.92157 7 4.25 6.32843 4.25 5.5C4.25 4.67157 4.92157 4 5.75 4C6.57843 4 7.25 4.67157 7.25 5.5Z'
        fill={color}
      />
    </svg>
  );
};
