import { isWhiteLabelEnabled } from '@va/dashboard/selectors/core';
import { CommunityIcon, ExternalLinkIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, fontWeights, paragraphSizes, ParagraphWithTooltip } from '@va/ui/design-system';
import { isCommunityButtonEnabled, isStandaloneApp, isWixApp } from '@va/util/helpers';
import { useWindowDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const CommunityButton = ({ className, isInHeader = false }: { className?: string; isInHeader?: boolean }) => {
  const translate = useTranslate();
  const { width } = useWindowDimensions();
  const whiteLabelEnabled = useSelector(isWhiteLabelEnabled);
  const communityLink = translate('communityButton.link');

  const shouldShow = useMemo(() => {
    if (!isCommunityButtonEnabled()) return false;
    if (isWixApp()) return true;
    if (isStandaloneApp() && (whiteLabelEnabled || whiteLabelEnabled === undefined)) return false;
    if (!whiteLabelEnabled) return true;
  }, [whiteLabelEnabled]);

  if (!shouldShow) {
    return null;
  }

  if (width < 1480 && isInHeader) {
    return (
      <Button
        icon={(_, color) => <CommunityIcon color={color} />}
        color='primary'
        onClick={() => window.open(communityLink, '_blank')}
        tooltip={translate('communityButton.tooltip')}
      />
    );
  }

  return (
    <button
      className={classNames(
        {
          'max-w-[270px]': isInHeader,
        },
        'flex rounded-15 bg-primary h-60px px-12px gap-15px items-center justify-between cursor-pointer',
        className,
      )}
      onClick={() => window.open(communityLink, '_blank')}
      type='button'
    >
      <div className='flex gap-15px items-center overflow-hidden'>
        <CommunityIcon color='#FFFFFF' className='shrink-0' />
        <div className='flex flex-col text-left truncate'>
          <ParagraphWithTooltip size={paragraphSizes.tiny} weight={fontWeights.medium} colorClassName='text-white'>
            {translate('communityButton.topText')}
          </ParagraphWithTooltip>
          <ParagraphWithTooltip weight={fontWeights.medium} colorClassName='text-white'>
            {translate('communityButton.bottomText')}
          </ParagraphWithTooltip>
        </div>
      </div>
      <ExternalLinkIcon color='#FFFFFF' className='shrink-0' />
    </button>
  );
};
