import { IconProps } from '.';

export const CoinsIcon = ({ color = 'black', className }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.6931 6.34034C16.1624 5.60573 16.5 4.8684 16.5 4.5C16.5 4.1316 16.1624 3.39427 14.6931 2.65966C13.3136 1.96991 11.3029 1.5 9 1.5C6.69709 1.5 4.68636 1.96991 3.30686 2.65966C1.83764 3.39427 1.5 4.1316 1.5 4.5C1.5 4.8684 1.83764 5.60573 3.30686 6.34034C4.68636 7.03009 6.69709 7.5 9 7.5C11.3029 7.5 13.3136 7.03009 14.6931 6.34034ZM18 9V4.5C18 2.01472 13.9706 0 9 0C4.02944 0 0 2.01472 0 4.5V9V13.5C0 15.9853 4.02944 18 9 18C13.9706 18 18 15.9853 18 13.5V9ZM16.5 9C16.5 8.70949 16.29 8.18957 15.4808 7.62247C13.8438 8.47171 11.5453 9 9 9C6.45473 9 4.15623 8.47171 2.51919 7.62247L2.51919 7.62247C1.70996 8.18957 1.5 8.70949 1.5 9C1.5 9.3684 1.83764 10.1057 3.30686 10.8403C4.68636 11.5301 6.69709 12 9 12C11.3029 12 13.3136 11.5301 14.6931 10.8403C16.1624 10.1057 16.5 9.3684 16.5 9ZM3.30686 15.3403C1.83764 14.6057 1.5 13.8684 1.5 13.5C1.5 13.2095 1.70996 12.6896 2.51919 12.1225C4.15624 12.9717 6.45473 13.5 9 13.5C11.5453 13.5 13.8438 12.9717 15.4808 12.1225C16.29 12.6896 16.5 13.2095 16.5 13.5C16.5 13.8684 16.1624 14.6057 14.6931 15.3403C13.3136 16.0301 11.3029 16.5 9 16.5C6.69709 16.5 4.68636 16.0301 3.30686 15.3403Z'
        fill={color}
      />
    </svg>
  );
};
