import { get } from '@va/http-client';
import { storageItems } from '@va/standalone/shared/constants';
import { WebsiteType } from '@va/types/website';
import { LocalStorage } from '@va/util/helpers';
import useSWR, { SWRConfiguration } from 'swr';

export type WebsitesDataType = {
  hasContributor: boolean;
  length: number;
  start: number;
  total: number;
  totalWhiteLabeled: number;
  websites: WebsiteType[];
};

export const useGetWebsites = (controller: AbortController, queryData: Object, config: SWRConfiguration) => {
  const userId = LocalStorage.getItem(storageItems.userId);

  const fetcher = (url: string, queryData: any, controller: AbortController): any => {
    return get(url, queryData, { signal: controller.signal });
  };

  const swr = useSWR<WebsitesDataType>([`/users/${userId}/websites`, queryData, controller], fetcher, config);

  return { ...swr, controller };
};
