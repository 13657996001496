import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { Email } from '@va/svg-visa-icons';
import './style.scss';

export default class MessageAnimated extends PureComponent {
  render() {
    return (
      <div className={`message_icon_wrapper ${this.props.className}`}>
        <Email />
        <div className='notification_circle'>
          <div className='notification_num'>{this.props.emailNumber}</div>
        </div>
      </div>
    );
  }
}

MessageAnimated.propTypes = {
  className: PropTypes.string,
  emailNumber: PropTypes.number,
};

MessageAnimated.defaultProps = {
  className: '',
  emailNumber: 1,
};
