import { TEST_IDS, emailRegex } from '@va/constants';
import { CheckmarkIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { RouterWarningModal } from '@va/shared/util-confirmation';
import {
  CustomSenderEmailComponent,
  SectionHeader,
  SubSectionHeader,
} from '@va/ui/components/white-label-customisation';
import { ActionInput, Button, TabbedButton } from '@va/ui/design-system';
import { useMemo, useState } from 'react';
import useDummyEmail from './useDummyEmail';
import useReplyToEmailAddress, { fieldName } from './useReplyToEmailAddress';

enum TabsEnum {
  'replyToEmailAddress' = 0,
  'customSenderEmail' = 1,
}

const CustomEmailAddresses = () => {
  const [selectedTab, setSelectedTab] = useState(TabsEnum.replyToEmailAddress);

  const translate = useTranslate();
  const { dummyEmail, setDummyEmail, sendDummyEmail, isSendDummyApiLoading } = useDummyEmail();

  const tabButtons = [
    {
      label: translate('whiteLabel.email.tabs.replyTo'),
      value: TabsEnum.replyToEmailAddress,
      className: '!w-full !h-54 sm-initial:flex-1',
      tooltip: translate('whiteLabel.email.tabs.tooltips.replyTo'),
    },
    {
      label: translate('whiteLabel.email.tabs.customSenderEmail'),
      value: TabsEnum.customSenderEmail,
      className: '!w-full !h-54 sm-initial:flex-1',
      tooltip: translate('whiteLabel.email.tabs.tooltip.customSenderEmail'),
      disabled: true,
    },
  ];

  const { values, handleChange, clearField, handleSubmit, formErrors, isLoading, agencyUiId, hasMadeChanges } =
    useReplyToEmailAddress();

  const isSaveButtonDisabled = isLoading || !agencyUiId || !hasMadeChanges;

  const isDummyEmailFieldHasError = useMemo(() => !!dummyEmail && !dummyEmail.match(emailRegex), [dummyEmail]);

  return (
    <>
      <RouterWarningModal when={hasMadeChanges} />
      <div>
        <SectionHeader
          title={translate('whiteLabel.customEmail.title')}
          description={translate('whiteLabel.customEmail.info')}
        />
        <TabbedButton
          className='my-5 flex-col sm-initial:!flex-row !h-auto'
          buttonOptions={tabButtons}
          onChange={(value: TabsEnum) => {
            setSelectedTab(value);
          }}
          selectedValue={selectedTab}
        />
        <SubSectionHeader
          tooltip={translate('whiteLabel.email.replyTo.tooltip')}
          text={translate('whiteLabel.email.replyTo.title')}
        />
        <form onSubmit={handleSubmit}>
          <ActionInput
            buttonProps={{
              icon: () => <CheckmarkIcon color='white' />,
              text: translate('button.confirm'),
            }}
            textInputProps={{
              error: formErrors.replyToEmailAddress,
              name: fieldName,
              value: values.replyToEmailAddress ?? '',
              onChange: handleChange,
              clearField: clearField,
              label: translate('form.labels.addReplyToEmail'),
              placeholder: translate('card.notifications.placeholders.email'),
            }}
            info={translate('whiteLabel.email.replyTo.info')}
          />

          {selectedTab === TabsEnum.customSenderEmail && <CustomSenderEmailComponent />}

          <Button
            type={isSaveButtonDisabled ? 'button' : 'submit'}
            color='secondary'
            disabled={isSaveButtonDisabled}
            text={translate('button.confirmReplyToEmail')}
            className='w-full my-5'
            tooltip={!agencyUiId ? translate('whiteLabelCustomization.info.firstFillCustomUrl') : undefined}
            data-testid={TEST_IDS.generic.whiteLabelTheme.confirmReplyToEmailBtn}
          />
        </form>

        <SubSectionHeader
          tooltip={
            <div className='flex flex-col'>
              {translate('standalone.accountSettings.whiteLabelTheme.customEmailAddress.dummyEmailStepsTooltip.title')}
            </div>
          }
          text={translate('whiteLabel.email.sendDummy.title')}
        />
        <ActionInput
          info={translate('standalone.accountSettings.whiteLabelTheme.customEmailAddress.testEmailInputFieldInfo')}
          buttonProps={{
            onClick: () => !isDummyEmailFieldHasError && sendDummyEmail(dummyEmail),
            text: isSendDummyApiLoading ? translate('button.loading') : translate('button.send'),
            disabled: isSendDummyApiLoading || !agencyUiId,
            tooltip: !agencyUiId ? translate('whiteLabelCustomization.info.firstFillCustomUrl') : undefined,
            'data-testid': TEST_IDS.generic.whiteLabelTheme.sendTestEmailBtn,
          }}
          textInputProps={{
            error: isDummyEmailFieldHasError ? translate('register.form.email.invalidMessage') : undefined,
            value: dummyEmail,
            name: 'newEmailAddress',
            onChange: (e) => setDummyEmail(e.target.value),
            clearField: () => setDummyEmail(''),
            label: translate('form.labels.addTestEmail'),
            placeholder: translate('card.notifications.placeholders.email'),
          }}
        />
      </div>
    </>
  );
};

export default CustomEmailAddresses;
