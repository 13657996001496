import { TEST_IDS } from '@va/constants';
import { CheckmarkIcon, CopyIcon, CustomerSupport, SearchIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, Paragraph, ParagraphWithTooltip, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { useCopyToClipboard } from '@va/util/hooks';
import classNames from 'classnames';

export const CustomSenderEmailComponent = () => {
  const translate = useTranslate();

  return (
    <div className='w-full'>
      <TooltipWrapper content={translate('whiteLabel.email.customSender.tooltip')}>
        <Paragraph
          className=' border-gray-silver border-b border-dashed inline-block'
          weight={fontWeights.medium}
          size={paragraphSizes.large}
        >
          <span className='text-gray-dusty'>{translate('labels.optional')}:</span>
          {translate('whiteLabel.email.customSender.info')}
        </Paragraph>
      </TooltipWrapper>
      <div className='space-y-2'>
        <CustomEmailField domain='DOMAIN' ipAddress='v=spf1 mx a ip4:85.13.138.168 ~all' />
        <CustomEmailField domain='DOMAIN' ipAddress='v=spf1 mx a ip4:85.13.138.168 ~all' />
        <CustomEmailField domain='DOMAIN' ipAddress='v=spf1 mx a ip4:85.13.138.168 ~all' />
        <CustomEmailField domain='DOMAIN' ipAddress='v=spf1 mx a ip4:85.13.138.168 ~all' />
      </div>
    </div>
  );
};

type CustomEmailFieldProps = {
  domain: string;
  ipAddress: string;
};

const CustomEmailField: React.FC<CustomEmailFieldProps> = ({ domain, ipAddress }) => {
  const translate = useTranslate();
  const { onClickCopy, success } = useCopyToClipboard(1000);

  return (
    <div className='p-2 rounded-18 bg-white-snow flex items-center gap-2 overflow-auto scrollbar scrollbar-thumb'>
      <CustomEmailFieldItem className='uppercase' text='text' />
      <CustomEmailFieldItem text='@' />

      <Paragraph weight={fontWeights.medium} className='text-gray-devil px-4'>
        {domain}
      </Paragraph>
      <CustomEmailFieldItem
        className='!px-2 overflow-hidden min-w-150'
        text={ipAddress}
        rightNode={
          <Button
            onClick={() => {
              onClickCopy(ipAddress);
            }}
            className={success ? '!bg-green-pure' : undefined}
            tooltip={success ? translate('button.copiedToClipboard') : translate('button.copyToClipboardText')}
            color='quaternary'
            icon={() => (success ? <CheckmarkIcon color='white' /> : <CopyIcon color='#3C3C3C' />)}
            data-testid={TEST_IDS.generic.buttons.copyToClipboard}
          />
        }
      />
      <Button color='quaternary' icon={() => <SearchIcon />} onClick={() => {}} text={translate('button.verifyDNS')} />
      <Button color='quaternary' icon={() => <CustomerSupport />} onClick={() => {}} />
    </div>
  );
};

const CustomEmailFieldItem = ({
  text,
  className,
  rightNode,
}: {
  text: string;
  className?: string;
  rightNode?: React.ReactNode;
}) => {
  return (
    <div
      className={classNames(
        'rounded-15 flex items-center justify-center h-60 bg-gray-concrete px-6 text-gray-devil',
        className,
      )}
    >
      <ParagraphWithTooltip weight={fontWeights.medium}>{text}</ParagraphWithTooltip>
      {rightNode}
    </div>
  );
};
