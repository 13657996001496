import * as Api from './api';
import * as App from './app';
import * as Ui from './ui';

// TODO Should be removed
const standalone = {
  Api: Api,
  Ui: Ui,
  App: App,
  getFormState: (state: any) => state.getIn(['form']),
  getFormValue: (state: any, formName: string, valueName: string) =>
    state.getIn(['form', formName, 'values', valueName], ''),
  getFormSyncErrors: (state: any, formName: string) => state.getIn(['form', formName, 'syncErrors'], null),
  isFormValueActive: (state: any, formName: string, valueName: string) =>
    state.getIn(['form', formName, 'fields', valueName, 'active'], false),
};

export * from './api';
export * from './app';
export * from './appTs';
export * from './ui';
export { standalone };
