import { PageContent } from '@va/dashboard/shared/ui-layout';
import WebsiteInvoices from './WebsiteInvoices';

const AccountInvoices = () => {
  return (
    <PageContent>
      <WebsiteInvoices />
    </PageContent>
  );
};

export default AccountInvoices;
