import { useCallback, useEffect, useState } from 'react';

type Config = {
  immediate?: boolean;
  autoResetSuccessState?: boolean;
  throwErrorBack?: boolean;
};

export function useAsyncFunction<F extends (...args: any[]) => Promise<T>, T = Awaited<ReturnType<F>>, D = Error>(
  asyncFunction: F,
  config?: Config,
) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSucceeded, setSucceeded] = useState<boolean>(false);
  const [data, setData] = useState<T | undefined>(undefined);
  const [error, setError] = useState<D | null>(null);

  const { immediate = false, autoResetSuccessState = true, throwErrorBack = false } = config ?? {};

  const resetSuccessState = useCallback(() => {
    setSucceeded(false);
  }, []);

  const execute = useCallback(
    (...args: any) => {
      return new Promise((resolve, reject) => {
        setIsLoading(true);
        asyncFunction(...args)
          .then((res) => {
            resolve(res);
            setData(res);
            setError(null);
            setSucceeded(true);
          })
          .catch((error) => {
            setError(error);
            setSucceeded(false);
            if (throwErrorBack) {
              // Will throw and uncaught promise error, when throwErrorBack option is provided the function should be executed inside a try catch block.
              // Uncaught promises will make cypress tests fail
              reject(error);
            }
          })
          .finally(() => {
            setIsLoading(false);
            if (autoResetSuccessState) {
              setTimeout(() => {
                resetSuccessState();
              }, 1);
            }
          });
      });
    },
    [asyncFunction, autoResetSuccessState, resetSuccessState, throwErrorBack],
  );

  const clearError: () => void = useCallback(() => {
    setError(null);
  }, []);

  const reset = useCallback(() => {
    setSucceeded(false);
    setError(null);
    setData(undefined);
    setIsLoading(false);
  }, []);

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return {
    execute: execute as unknown as F,
    isLoading,
    data,
    error,
    isSucceeded,
    clearError,
    resetSuccessState,
    reset,
  };
}
