import { BLACK_COLOR, BLUE_COLOR, GREEN_COLOR, GREY_COLOR, PURE_BLACK_COLOR, WHITE_COLOR } from '@va/constants';
import { IconProps } from '.';

const subMenu = (isActive = false, color?: string) => ({
  colorValue: isActive ? (color ? color : BLUE_COLOR) : PURE_BLACK_COLOR,
});

const mainMenu = (isActive = false) => ({
  color: isActive ? WHITE_COLOR : PURE_BLACK_COLOR,
});

type NavIconProps = {
  color?: string;
  isActive?: boolean;
  className?: string;
  [key: string]: unknown;
};

export const NavEcommerce = (props: NavIconProps) => {
  const { color, isActive, ...otherProps } = props;
  const { color: fill } = mainMenu(isActive);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.09277 7.05224C1.288 5.09994 2.71176 3.54276 4.55133 3.11618C4.85251 1.34695 6.39295 0 8.248 0H9.748C11.603 0 13.1435 1.34695 13.4447 3.11618C15.2842 3.54276 16.708 5.09994 16.9032 7.05223L17.5032 13.0522C17.7681 15.7013 15.6879 18 13.0256 18H4.97044C2.30814 18 0.227864 15.7013 0.492772 13.0522L1.09277 7.05224ZM5.57044 4.5H5.998H11.998H12.4256C13.9668 4.5 15.2573 5.66788 15.4107 7.20149L16.0107 13.2015C16.1873 14.9675 14.8004 16.5 13.0256 16.5H4.97044C3.19557 16.5 1.80872 14.9675 1.98533 13.2015L2.58533 7.20149C2.73869 5.66789 4.02919 4.5 5.57044 4.5ZM6.12602 3H11.87C11.5611 2.12611 10.7277 1.5 9.748 1.5H8.248C7.26833 1.5 6.4349 2.12611 6.12602 3ZM4.498 7V7.75C4.498 9.40685 5.84114 10.75 7.498 10.75H10.498C12.1549 10.75 13.498 9.40685 13.498 7.75V7H11.998V7.75C11.998 8.57843 11.3264 9.25 10.498 9.25H7.498C6.66957 9.25 5.998 8.57843 5.998 7.75V7H4.498Z'
        fill={color || fill}
      />
    </svg>
  );
};
export const NavEcommerceOverview = (props: NavIconProps) => {
  const { color, isActive, ...otherProps } = props;
  const { color: fill } = mainMenu(isActive);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM11.25 9C11.25 10.2426 10.2426 11.25 9 11.25C7.75736 11.25 6.75 10.2426 6.75 9C6.75 7.75736 7.75736 6.75 9 6.75C10.2426 6.75 11.25 7.75736 11.25 9ZM13.5 9C13.5 11.4853 11.4853 13.5 9 13.5C6.51472 13.5 4.5 11.4853 4.5 9C4.5 6.51472 6.51472 4.5 9 4.5C11.4853 4.5 13.5 6.51472 13.5 9ZM15 9C15 12.3137 12.3137 15 9 15C5.68629 15 3 12.3137 3 9C3 5.68629 5.68629 3 9 3C12.3137 3 15 5.68629 15 9Z'
        fill={color || fill}
      />
    </svg>
  );
};

export const NavEcommerceCustomers = (props: NavIconProps) => {
  const { color, isActive, ...otherProps } = props;
  const { color: fill } = mainMenu(isActive);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.3561 14.25C15.6823 12.8972 16.5 11.0441 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 11.0441 2.31774 12.8972 3.64394 14.25C4.36649 13.513 5.23996 12.9244 6.21435 12.5344C5.1702 11.7103 4.5 10.4334 4.5 9C4.5 6.51472 6.51472 4.5 9 4.5C11.4853 4.5 13.5 6.51472 13.5 9C13.5 10.4334 12.8298 11.7103 11.7857 12.5344C12.7601 12.9244 13.6335 13.5129 14.3561 14.25ZM15.2994 15.4279C16.966 13.7944 18 11.518 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 11.8348 1.3106 14.3634 3.35893 16.0131C3.52332 16.1455 3.69248 16.2723 3.86608 16.393C5.32217 17.4061 7.09173 18 9 18C10.9083 18 12.6779 17.4061 14.134 16.393C14.134 16.393 14.134 16.393 14.134 16.393C14.2779 16.2929 14.4188 16.1886 14.5565 16.0804C14.59 16.0541 14.6233 16.0275 14.6564 16.0007C14.8798 15.82 15.0944 15.6289 15.2994 15.4279C15.2994 15.4279 15.2994 15.4279 15.2994 15.4279ZM13.1997 15.2148C12.1175 14.1541 10.6351 13.5 9.00002 13.5C7.36491 13.5 5.88257 14.1541 4.80034 15.2148C5.99859 16.0261 7.44396 16.5 9 16.5C10.5561 16.5 12.0014 16.0261 13.1997 15.2148ZM9 12C10.6569 12 12 10.6569 12 9C12 7.34315 10.6569 6 9 6C7.34315 6 6 7.34315 6 9C6 10.6569 7.34315 12 9 12Z'
        fill={color || fill}
      />
    </svg>
  );
};

export const NavEcommerceSalesCharts = (props: NavIconProps) => {
  const { color, isActive, ...otherProps } = props;
  const { color: fill } = mainMenu(isActive);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.50701 17.6364V1.27273H9.55531V17.6364H8.50701ZM11.7861 6.34801C11.718 5.7429 11.4367 5.27415 10.9424 4.94176C10.4481 4.60511 9.82591 4.43679 9.07591 4.43679C8.53897 4.43679 8.07449 4.52202 7.68244 4.69247C7.29039 4.85866 6.98571 5.08878 6.76838 5.38281C6.55531 5.67258 6.44878 6.00284 6.44878 6.37358C6.44878 6.68466 6.52122 6.95312 6.6661 7.17898C6.81525 7.40483 7.00914 7.59446 7.24778 7.74787C7.49068 7.89702 7.75062 8.02273 8.02761 8.125C8.3046 8.22301 8.57093 8.30398 8.82662 8.3679L10.105 8.70028C10.5226 8.80256 10.9509 8.94105 11.3898 9.11577C11.8287 9.29048 12.2357 9.5206 12.6107 9.80611C12.9857 10.0916 13.2883 10.4453 13.5184 10.8672C13.7528 11.2891 13.8699 11.794 13.8699 12.3821C13.8699 13.1236 13.6782 13.782 13.2947 14.3572C12.9154 14.9325 12.3635 15.3864 11.6391 15.7188C10.9189 16.0511 10.0475 16.2173 9.02477 16.2173C8.04466 16.2173 7.19664 16.0618 6.48074 15.7507C5.76483 15.4396 5.20446 14.9986 4.79963 14.4276C4.3948 13.8523 4.17108 13.1705 4.12846 12.3821H6.11C6.14835 12.8551 6.30176 13.2493 6.57022 13.5646C6.84295 13.8757 7.19025 14.108 7.61213 14.2614C8.03826 14.4105 8.50488 14.4851 9.01199 14.4851C9.57022 14.4851 10.0667 14.3977 10.5013 14.223C10.9403 14.044 11.2854 13.7969 11.5368 13.4815C11.7883 13.1619 11.914 12.7891 11.914 12.3629C11.914 11.9751 11.8032 11.6577 11.5816 11.4105C11.3643 11.1634 11.0681 10.9588 10.6931 10.7969C10.3224 10.6349 9.90261 10.4922 9.43386 10.3686L7.88699 9.94673C6.83869 9.66122 6.00772 9.24148 5.39409 8.6875C4.78471 8.13352 4.48002 7.40057 4.48002 6.48864C4.48002 5.73437 4.68457 5.07599 5.09366 4.51349C5.50275 3.95099 6.05673 3.5142 6.75559 3.20312C7.45446 2.88778 8.24281 2.73011 9.12065 2.73011C10.007 2.73011 10.789 2.88565 11.4665 3.19673C12.1483 3.50781 12.6853 3.93608 13.0773 4.48153C13.4694 5.02273 13.6739 5.64489 13.691 6.34801H11.7861Z'
        fill={color || fill}
      />
    </svg>
  );
};

export const NavEcommerceOrders = (props: NavIconProps) => {
  const { color, isActive, ...otherProps } = props;
  const { color: fill } = mainMenu(isActive);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.5 0C3.67157 0 3 0.671573 3 1.5H15C15 0.671573 14.3284 0 13.5 0H4.5ZM1.5 4.5C1.5 3.67157 2.17157 3 3 3H15C15.8284 3 16.5 3.67157 16.5 4.5H1.5ZM15 7.5H3C2.17157 7.5 1.5 8.17157 1.5 9V15C1.5 15.8284 2.17157 16.5 3 16.5H15C15.8284 16.5 16.5 15.8284 16.5 15V9C16.5 8.17157 15.8284 7.5 15 7.5ZM3 6C1.34315 6 0 7.34315 0 9V15C0 16.6569 1.34315 18 3 18H15C16.6569 18 18 16.6569 18 15V9C18 7.34315 16.6569 6 15 6H3Z'
        fill={color || fill}
      />
    </svg>
  );
};

export function NavDashboard(props: NavIconProps) {
  const { color, isActive, ...otherProps } = props;
  const { color: fill } = mainMenu(isActive);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 9C1.5 5.11099 4.46001 1.91332 8.25 1.53703V16.463C4.46001 16.0867 1.5 12.889 1.5 9ZM16.463 9.75H9.75V16.463C13.2933 16.1112 16.1112 13.2933 16.463 9.75ZM16.463 8.25H9.75V1.53703C13.2933 1.88883 16.1112 4.70669 16.463 8.25ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z'
        fill={color || fill}
      />
    </svg>
  );
}

/**
 * @deprecated
 * @see NavWebStatsOverview
 */
export function NavStatistic(props: NavIconProps) {
  return <NavWebStatsOverview {...props} />;
}

export function NavWebStatsOverview(props: NavIconProps) {
  const { color, isActive, ...otherProps } = props;
  const { color: fill } = mainMenu(isActive);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9H0C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0V1.5C13.1421 1.5 16.5 4.85786 16.5 9ZM9 14.25C11.8995 14.25 14.25 11.8995 14.25 9C14.25 6.1005 11.8995 3.75 9 3.75V5.25C11.0711 5.25 12.75 6.92893 12.75 9C12.75 11.0711 11.0711 12.75 9 12.75V14.25ZM10.5 9C10.5 9.82843 9.82843 10.5 9 10.5C8.17157 10.5 7.5 9.82843 7.5 9C7.5 8.17157 8.17157 7.5 9 7.5C9.82843 7.5 10.5 8.17157 10.5 9Z'
        fill={color || fill}
      />
    </svg>
  );
}

export function NavLatest(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.3002 13.1C14.4002 12.2 13.4002 11.6 12.2002 11.1C13.9002 10.1 15.0002 8.2 15.0002 6C15.0002 5.4 14.9002 4.7 14.7002 4.1C13.9002 1.7 11.7002 0 9.0002 0C6.3002 0 4.1002 1.7 3.3002 4.1C3.1002 4.7 3.0002 5.4 3.0002 6C3.0002 8.1 4.1002 10 5.8002 11.1C4.6002 11.5 3.6002 12.2 2.7002 13.1C2.3002 13.5 2.0002 13.9 1.7002 14.3C2.0002 14.7 2.3002 15.1 2.7002 15.5C4.3002 17 6.6002 18 9.0002 18C11.4002 18 13.7002 17 15.3002 15.4C15.7002 15 16.0002 14.6 16.3002 14.2C16.0002 13.8 15.7002 13.4 15.3002 13.1ZM4.5002 6C4.5002 3.7 6.3002 1.8 8.5002 1.5C8.7002 1.5 8.8002 1.5 9.0002 1.5C9.2002 1.5 9.3002 1.5 9.5002 1.5C11.7002 1.7 13.5002 3.7 13.5002 6C13.5002 8.5 11.5002 10.5 9.0002 10.5C6.5002 10.5 4.5002 8.5 4.5002 6ZM9.0002 16.5C6.9002 16.5 5.0002 15.6 3.6002 14.3C5.0002 12.9 6.9002 12 9.0002 12C11.1002 12 13.0002 12.9 14.3002 14.2C13.0002 15.6 11.1002 16.5 9.0002 16.5Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavPageVisits(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 4.5V13.5C1.5 14.3284 2.17157 15 3 15H9C9.82843 15 10.5 14.3284 10.5 13.5V9V4.5C10.5 3.67157 9.82843 3 9 3H6H3C2.17157 3 1.5 3.67157 1.5 4.5ZM3 1.5C1.34315 1.5 0 2.84315 0 4.5V13.5C0 15.1569 1.34315 16.5 3 16.5H9C10.6569 16.5 12 15.1569 12 13.5V9V4.5C12 2.84315 10.6569 1.5 9 1.5H6H3ZM13.5 3.75C13.5 3.33579 13.8358 3 14.25 3C14.6642 3 15 3.33579 15 3.75V14.25C15 14.6642 14.6642 15 14.25 15C13.8358 15 13.5 14.6642 13.5 14.25V3.75ZM17.25 4.5C16.8358 4.5 16.5 4.83579 16.5 5.25V12.75C16.5 13.1642 16.8358 13.5 17.25 13.5C17.6642 13.5 18 13.1642 18 12.75V5.25C18 4.83579 17.6642 4.5 17.25 4.5Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavVisitorsOverview(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM11.25 9C11.25 10.2426 10.2426 11.25 9 11.25C7.75736 11.25 6.75 10.2426 6.75 9C6.75 7.75736 7.75736 6.75 9 6.75C10.2426 6.75 11.25 7.75736 11.25 9ZM13.5 9C13.5 11.4853 11.4853 13.5 9 13.5C6.51472 13.5 4.5 11.4853 4.5 9C4.5 6.51472 6.51472 4.5 9 4.5C11.4853 4.5 13.5 6.51472 13.5 9ZM15 9C15 12.3137 12.3137 15 9 15C5.68629 15 3 12.3137 3 9C3 5.68629 5.68629 3 9 3C12.3137 3 15 5.68629 15 9Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavTools(props: NavIconProps) {
  const { color, isActive, ...otherProps } = props;
  const { color: fill } = mainMenu(isActive);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.75 2.625C5.75 2.00368 6.25368 1.5 6.875 1.5H11.125C11.7463 1.5 12.25 2.00368 12.25 2.625V3.75H5.75V2.625ZM4.25 3.75V2.625C4.25 1.17525 5.42525 0 6.875 0H11.125C12.5747 0 13.75 1.17525 13.75 2.625V3.75H15.5C16.8807 3.75 18 4.86929 18 6.25V15.5C18 16.8807 16.8807 18 15.5 18H2.5C1.11929 18 0 16.8807 0 15.5V6.25C0 4.86929 1.11929 3.75 2.5 3.75H4.25ZM2.5 5.25H15.5C16.0523 5.25 16.5 5.69772 16.5 6.25V8H1.5V6.25C1.5 5.69772 1.94772 5.25 2.5 5.25ZM1.5 9.5V12.25H16.5V9.5H1.5ZM1.5 13.75H16.5V15.5C16.5 16.0523 16.0523 16.5 15.5 16.5H2.5C1.94772 16.5 1.5 16.0523 1.5 15.5V13.75Z'
        fill={color || fill}
      />
    </svg>
  );
}

export function NavModulesSettings(props: NavIconProps) {
  const { color, isActive, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...otherProps}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.75 1.5C2.50736 1.5 1.5 2.50736 1.5 3.75C1.5 4.99264 2.50736 6 3.75 6H6V3.75C6 2.50736 4.99264 1.5 3.75 1.5ZM7.5 6V3.75C7.5 1.67893 5.82107 0 3.75 0C1.67893 0 0 1.67893 0 3.75C0 5.82107 1.67893 7.5 3.75 7.5H6V10.5H3.75C1.67893 10.5 0 12.1789 0 14.25C0 16.3211 1.67893 18 3.75 18C5.82107 18 7.5 16.3211 7.5 14.25V12H10.5V14.25C10.5 16.3211 12.1789 18 14.25 18C16.3211 18 18 16.3211 18 14.25C18 12.1789 16.3211 10.5 14.25 10.5H12V7.5H14.25C16.3211 7.5 18 5.82107 18 3.75C18 1.67893 16.3211 0 14.25 0C12.1789 0 10.5 1.67893 10.5 3.75V6H7.5ZM7.5 7.5V10.5H10.5V7.5H7.5ZM12 6H14.25C15.4926 6 16.5 4.99264 16.5 3.75C16.5 2.50736 15.4926 1.5 14.25 1.5C13.0074 1.5 12 2.50736 12 3.75V6ZM12 12V14.25C12 15.4926 13.0074 16.5 14.25 16.5C15.4926 16.5 16.5 15.4926 16.5 14.25C16.5 13.0074 15.4926 12 14.25 12H12ZM6 12H3.75C2.50736 12 1.5 13.0074 1.5 14.25C1.5 15.4926 2.50736 16.5 3.75 16.5C4.99264 16.5 6 15.4926 6 14.25V12Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavTrackingCode(props: NavIconProps) {
  const { color, isActive, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} height='24' width='20'>
      <line x1='0' y1='50%' x2='30%' y2='50%' stroke={color || colorValue} strokeWidth='1.5' />
      <line x1='30%' y1='50%' x2='40%' y2='80%' stroke={color || colorValue} strokeWidth='1.5' />
      <line x1='40%' y1='80%' x2='50%' y2='20%' stroke={color || colorValue} strokeWidth='1.5' />
      <line x1='50%' y1='20%' x2='60%' y2='50%' stroke={color || colorValue} strokeWidth='1.5' />
      <line x1='60%' y1='50%' x2='100%' y2='50%' stroke={color || colorValue} strokeWidth='1.5' />
    </svg>
  );
}

export const NavManageContributors = (props: NavIconProps) => {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);
  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.3 13.1C14.4 12.2 13.4 11.6 12.2 11.1C13.9 10.1 15 8.2 15 6C15 5.4 14.9 4.7 14.7 4.1C13.9 1.7 11.7 0 9 0C6.3 0 4.1 1.7 3.3 4.1C3.1 4.7 3 5.4 3 6C3 8.1 4.1 10 5.8 11.1C4.6 11.5 3.6 12.2 2.7 13.1C2.3 13.5 2 13.9 1.7 14.3C2 14.7 2.3 15.1 2.7 15.5C4.3 17 6.6 18 9 18C11.4 18 13.7 17 15.3 15.4C15.7 15 16 14.6 16.3 14.2C16 13.8 15.7 13.4 15.3 13.1ZM4.5 6C4.5 3.7 6.3 1.8 8.5 1.5C8.7 1.5 8.8 1.5 9 1.5C9.2 1.5 9.3 1.5 9.5 1.5C11.7 1.7 13.5 3.7 13.5 6C13.5 8.5 11.5 10.5 9 10.5C6.5 10.5 4.5 8.5 4.5 6ZM9 16.5C6.9 16.5 5 15.6 3.6 14.3C5 12.9 6.9 12 9 12C11.1 12 13 12.9 14.3 14.2C13 15.6 11.1 16.5 9 16.5Z'
        fill={color || colorValue}
      />
    </svg>
  );
};

export function NavCompetition(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 9C16.5 12.889 13.54 16.0867 9.75 16.463V1.53703C13.54 1.91332 16.5 5.11099 16.5 9ZM8.25 1.53703V16.463C4.46001 16.0867 1.5 12.889 1.5 9C1.5 5.11099 4.46001 1.91332 8.25 1.53703ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM15 9C15 11.6124 13.3304 13.8349 11 14.6586V3.34141C13.3304 4.16508 15 6.38756 15 9Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavDevices(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 3H15C15.8284 3 16.5 3.67157 16.5 4.5V10.5C16.5 11.3284 15.8284 12 15 12H3C2.17157 12 1.5 11.3284 1.5 10.5V4.5C1.5 3.67157 2.17157 3 3 3ZM0 4.5C0 2.84315 1.34315 1.5 3 1.5H15C16.6569 1.5 18 2.84315 18 4.5V10.5C18 12.1569 16.6569 13.5 15 13.5H3C1.34315 13.5 0 12.1569 0 10.5V4.5ZM3.75 15C3.33579 15 3 15.3358 3 15.75C3 16.1642 3.33579 16.5 3.75 16.5H14.25C14.6642 16.5 15 16.1642 15 15.75C15 15.3358 14.6642 15 14.25 15H3.75Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavBehaviour(props: NavIconProps) {
  const { color, isActive, ...otherProps } = props;
  const { color: fill } = mainMenu(isActive);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 1.5H13.5C15.1569 1.5 16.5 2.84315 16.5 4.5V6H18V4.5C18 2.01472 15.9853 0 13.5 0H12V1.5ZM6 1.5V0H4.5C2.01472 0 0 2.01472 0 4.5V6H1.5V4.5C1.5 2.84315 2.84315 1.5 4.5 1.5H6ZM1.5 12H0V13.5C0 15.9853 2.01472 18 4.5 18H6V16.5H4.5C2.84315 16.5 1.5 15.1569 1.5 13.5V12ZM12 16.5V18H13.5C15.9853 18 18 15.9853 18 13.5V12H16.5V13.5C16.5 15.1569 15.1569 16.5 13.5 16.5H12ZM9 12.75C11.0711 12.75 12.75 11.0711 12.75 9C12.75 6.92893 11.0711 5.25 9 5.25C6.92893 5.25 5.25 6.92893 5.25 9C5.25 11.0711 6.92893 12.75 9 12.75ZM9 14.25C11.8995 14.25 14.25 11.8995 14.25 9C14.25 6.1005 11.8995 3.75 9 3.75C6.1005 3.75 3.75 6.1005 3.75 9C3.75 11.8995 6.1005 14.25 9 14.25ZM11.25 9C11.25 9.62132 10.9982 10.1838 10.591 10.591L9.53033 9.53033C9.66605 9.39461 9.75 9.20711 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25C8.79289 8.25 8.60539 8.33395 8.46967 8.46967L7.40901 7.40901C7.81618 7.00184 8.37868 6.75 9 6.75C10.2426 6.75 11.25 7.75736 11.25 9Z'
        fill={color || fill}
      />
    </svg>
  );
}

export function NavRecordings(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 4.5H10.5C11.3284 4.5 12 5.17157 12 6V8.28926V9.71074V12C12 12.8284 11.3284 13.5 10.5 13.5H3C2.17157 13.5 1.5 12.8284 1.5 12V6C1.5 5.17157 2.17157 4.5 3 4.5ZM13.4798 12.3498C13.3066 13.8418 12.0385 15 10.5 15H3C1.34315 15 0 13.6569 0 12V6C0 4.34315 1.34315 3 3 3H10.5C12.0385 3 13.3066 4.15816 13.4798 5.65022L13.993 5.00869C15.3217 3.34788 18 4.28738 18 6.41426V11.5857C18 13.7126 15.3217 14.6521 13.993 12.9913L13.4798 12.3498ZM13.5 9.71074C13.5 9.88105 13.558 10.0463 13.6643 10.1793L15.1643 12.0543C15.6072 12.6079 16.5 12.2947 16.5 11.5857V6.41426C16.5 5.7053 15.6072 5.39213 15.1643 5.94574L13.6643 7.82074C13.558 7.95372 13.5 8.11895 13.5 8.28926V9.71074Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavHeatmaps(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.5541 4.94657C14.7649 5.52868 14.9056 6.14431 14.9662 6.78329C14.4672 6.49898 13.9235 6.28418 13.3483 6.15209C12.9149 6.05258 12.4636 6 12 6C8.68629 6 6 8.68629 6 12C6 12.4636 6.05258 12.9149 6.15209 13.3483C6.28418 13.9235 6.49898 14.4672 6.78329 14.9662C6.14431 14.9056 5.52868 14.7649 4.94657 14.5541C2.06153 13.5096 0 10.7455 0 7.5C0 3.35786 3.35786 0 7.5 0C10.7455 0 13.5096 2.06153 14.5541 4.94657ZM7.5 1.5C9.7345 1.5 11.6845 2.72207 12.7171 4.53393C12.4809 4.51148 12.2417 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 12.2417 4.51148 12.4809 4.53393 12.7171C2.72207 11.6845 1.5 9.7345 1.5 7.5C1.5 4.18629 4.18629 1.5 7.5 1.5ZM7.50004 15.9687C7.22842 15.661 6.98788 15.3252 6.78333 14.9662C7.01922 14.9886 7.25829 15 7.50004 15C7.86619 15 8.22621 14.9738 8.57832 14.9231C11.8575 14.4509 14.451 11.8574 14.9231 8.57829C14.9738 8.22617 15 7.86615 15 7.5C15 7.25825 14.9886 7.01918 14.9662 6.78329C15.3252 6.98784 15.661 7.22838 15.9688 7.5C16.1512 7.66105 16.3238 7.83303 16.4856 8.01491C17.4277 9.07463 18 10.4705 18 12C18 15.3137 15.3137 18 12 18C10.4705 18 9.07466 17.4277 8.01495 16.4855C7.83307 16.3238 7.66109 16.1512 7.50004 15.9687ZM12 16.5C11.3409 16.5 10.7146 16.3584 10.15 16.1035C12.9894 15.23 15.23 12.9894 16.1036 10.15C16.3584 10.7145 16.5 11.3409 16.5 12C16.5 14.4853 14.4853 16.5 12 16.5Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavFunnels(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.75 2.25H2.25C1.83579 2.25 1.5 2.58579 1.5 3C1.5 3.41421 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 3.41421 16.5 3C16.5 2.58579 16.1642 2.25 15.75 2.25ZM2.25 0.75C1.00736 0.75 0 1.75736 0 3C0 4.24264 1.00736 5.25 2.25 5.25H15.75C16.9926 5.25 18 4.24264 18 3C18 1.75736 16.9926 0.75 15.75 0.75H2.25ZM13.5 8.25H4.5C4.08579 8.25 3.75 8.58579 3.75 9C3.75 9.41421 4.08579 9.75 4.5 9.75H13.5C13.9142 9.75 14.25 9.41421 14.25 9C14.25 8.58579 13.9142 8.25 13.5 8.25ZM4.5 6.75C3.25736 6.75 2.25 7.75736 2.25 9C2.25 10.2426 3.25736 11.25 4.5 11.25H13.5C14.7426 11.25 15.75 10.2426 15.75 9C15.75 7.75736 14.7426 6.75 13.5 6.75H4.5ZM6.75 14.25H11.25C11.6642 14.25 12 14.5858 12 15C12 15.4142 11.6642 15.75 11.25 15.75H6.75C6.33579 15.75 6 15.4142 6 15C6 14.5858 6.33579 14.25 6.75 14.25ZM4.5 15C4.5 13.7574 5.50736 12.75 6.75 12.75H11.25C12.4926 12.75 13.5 13.7574 13.5 15C13.5 16.2426 12.4926 17.25 11.25 17.25H6.75C5.50736 17.25 4.5 16.2426 4.5 15Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavChat(props: NavIconProps) {
  const { color, isActive, ...otherProps } = props;
  const { color: fill } = mainMenu(isActive);
  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.25 6C15.4926 6 16.5 4.99264 16.5 3.75C16.5 2.50736 15.4926 1.5 14.25 1.5C13.0074 1.5 12 2.50736 12 3.75C12 4.99264 13.0074 6 14.25 6ZM14.25 7.5C16.3211 7.5 18 5.82107 18 3.75C18 1.67893 16.3211 0 14.25 0C12.1789 0 10.5 1.67893 10.5 3.75C10.5 5.82107 12.1789 7.5 14.25 7.5ZM9 1.5H4.5C2.01472 1.5 0 3.51472 0 6V13.5C0 15.9853 2.01472 18 4.5 18H12C14.4853 18 16.5 15.9853 16.5 13.5V9H15V13.5C15 15.1569 13.6569 16.5 12 16.5H4.5C2.84315 16.5 1.5 15.1569 1.5 13.5V6C1.5 4.34315 2.84315 3 4.5 3H9V1.5ZM4.5 12.75C4.08579 12.75 3.75 13.0858 3.75 13.5C3.75 13.9142 4.08579 14.25 4.5 14.25H12C12.4142 14.25 12.75 13.9142 12.75 13.5C12.75 13.0858 12.4142 12.75 12 12.75H4.5ZM3.75 10.75C3.75 10.3358 4.08579 10 4.5 10H12C12.4142 10 12.75 10.3358 12.75 10.75C12.75 11.1642 12.4142 11.5 12 11.5H4.5C4.08579 11.5 3.75 11.1642 3.75 10.75ZM4.5 5.25C4.08579 5.25 3.75 5.58579 3.75 6C3.75 6.41421 4.08579 6.75 4.5 6.75H7.5C7.91421 6.75 8.25 6.41421 8.25 6C8.25 5.58579 7.91421 5.25 7.5 5.25H4.5Z'
        fill={color || fill}
      />
    </svg>
  );
}

export function NavChatInbox(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.42705 9L5.01246 3.82918C5.26655 3.321 5.78594 3 6.3541 3H11.6459C12.2141 3 12.7335 3.321 12.9875 3.82918L15.5729 9H12.8284C12.0991 9 11.3996 9.28973 10.8839 9.80546L9.96967 10.7197L9.88388 10.8055C9.39573 11.2936 8.60427 11.2936 8.11612 10.8055L7.11612 9.80546C6.60039 9.28973 5.90092 9 5.17157 9H2.42705ZM1.67705 10.5L1.65836 10.5374C1.55422 10.7457 1.5 10.9753 1.5 11.2082V13.5C1.5 14.3284 2.17157 15 3 15H15C15.8284 15 16.5 14.3284 16.5 13.5V11.2082C16.5 10.9753 16.4458 10.7457 16.3416 10.5374L16.3229 10.5H12.8284C12.4969 10.5 12.179 10.6317 11.9445 10.8661L11.0303 11.7803L10.9445 11.8661C9.8706 12.9401 8.1294 12.9401 7.05546 11.8661L6.05546 10.8661C5.82104 10.6317 5.50309 10.5 5.17157 10.5H1.67705ZM0.316718 9.86656L3.67082 3.15836C4.179 2.14201 5.21779 1.5 6.3541 1.5H11.6459C12.7822 1.5 13.821 2.14201 14.3292 3.15836L17.6833 9.86656C17.8916 10.2831 18 10.7425 18 11.2082V13.5C18 15.1569 16.6569 16.5 15 16.5H3C1.34315 16.5 0 15.1569 0 13.5V11.2082C0 10.7425 0.108435 10.2831 0.316718 9.86656Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavChatAutoMessages(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.5 1.5H13.5C15.1569 1.5 16.5 2.84315 16.5 4.5V10.5C16.5 12.1569 15.1569 13.5 13.5 13.5H13.2426C12.0492 13.5 10.9046 13.9741 10.0607 14.818L9 15.8787L7.93934 14.818C7.09543 13.9741 5.95083 13.5 4.75736 13.5H4.5C2.84315 13.5 1.5 12.1569 1.5 10.5V4.5C1.5 2.84315 2.84315 1.5 4.5 1.5ZM9 18L10.0607 16.9393L11.1213 15.8787C11.6839 15.3161 12.447 15 13.2426 15H13.5C15.9853 15 18 12.9853 18 10.5V4.5C18 2.01472 15.9853 0 13.5 0H4.5C2.01472 0 0 2.01472 0 4.5V10.5C0 12.9853 2.01472 15 4.5 15H4.75736C5.55301 15 6.31607 15.3161 6.87868 15.8787L7.93934 16.9393L9 18ZM6.75 3.75C6.33579 3.75 6 4.08579 6 4.5C6 4.91421 6.33579 5.25 6.75 5.25H11.25C11.6642 5.25 12 4.91421 12 4.5C12 4.08579 11.6642 3.75 11.25 3.75H6.75ZM4.5 7.5C4.5 7.08579 4.83579 6.75 5.25 6.75H12.75C13.1642 6.75 13.5 7.08579 13.5 7.5C13.5 7.91421 13.1642 8.25 12.75 8.25H5.25C4.83579 8.25 4.5 7.91421 4.5 7.5ZM6.75 9.75C6.33579 9.75 6 10.0858 6 10.5C6 10.9142 6.33579 11.25 6.75 11.25H11.25C11.6642 11.25 12 10.9142 12 10.5C12 10.0858 11.6642 9.75 11.25 9.75H6.75Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavExport(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.25 13.5V2.87132L5.03033 6.09099L3.96967 5.03033L8.46967 0.530331L9 0L9.53033 0.530331L14.0303 5.03033L12.9697 6.09099L9.75 2.87132V13.5H8.25ZM1.5 10.5V13.5C1.5 15.1569 2.84315 16.5 4.5 16.5H13.5C15.1569 16.5 16.5 15.1569 16.5 13.5V10.5H18V13.5C18 15.9853 15.9853 18 13.5 18H4.5C2.01472 18 0 15.9853 0 13.5V10.5H1.5Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavWebsiteSettings(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { color: fill } = mainMenu(isActive);
  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.25 1.5H9.75V1.75734C9.75 3.76188 12.1736 4.76575 13.591 3.34833L13.7729 3.16637L14.8336 4.22703L14.6516 4.40901C13.2342 5.82642 14.2381 8.25 16.2426 8.25H16.5V9.75H16.2426C14.2381 9.75 13.2342 12.1736 14.6517 13.591L14.8336 13.773L13.773 14.8336L13.591 14.6516C12.1736 13.2342 9.75 14.2381 9.75 16.2426V16.5H8.25V16.2426C8.25 14.2381 5.82644 13.2342 4.40901 14.6516L4.22701 14.8336L3.16635 13.773L3.34833 13.591C4.76576 12.1736 3.76187 9.75 1.75734 9.75H1.5V8.25H1.75736C3.7619 8.25 4.76578 5.82643 3.34835 4.40901L3.16637 4.22703L4.22704 3.16637L4.40901 3.34834C5.82643 4.76576 8.25 3.7619 8.25 1.75735V1.5ZM13.7729 1.04505L12.7123 2.10571L12.5303 2.28767C12.0579 2.76014 11.25 2.42552 11.25 1.75734V1.5V0H9.75H8.25H6.75V1.5V1.75735C6.75 2.42553 5.94214 2.76016 5.46967 2.28768L5.2877 2.10571L4.22704 1.04505L3.16638 2.10571L2.10571 3.16637L1.04505 4.22703L2.10571 5.28769L2.28769 5.46967C2.76017 5.94214 2.42554 6.75 1.75736 6.75H1.5H0V8.25V9.75V11.25H1.5H1.75734C2.42552 11.25 2.76014 12.0579 2.28767 12.5303L2.10569 12.7123L1.04503 13.773L2.10569 14.8336L3.16635 15.8943L4.22701 16.955L5.28767 15.8943L5.46967 15.7123C5.94214 15.2398 6.75 15.5744 6.75 16.2426V16.5V18H8.25H9.75H11.25V16.5V16.2426C11.25 15.5745 12.0579 15.2398 12.5303 15.7123L12.7123 15.8943L13.773 16.955L14.8336 15.8943L15.8943 14.8336L16.955 13.773L15.8943 12.7123L15.7123 12.5303C15.2398 12.0579 15.5745 11.25 16.2426 11.25H16.5H18V9.75V8.25V6.75H16.5H16.2426C15.5744 6.75 15.2398 5.94214 15.7123 5.46967L15.8943 5.28769L16.9549 4.22703L15.8943 3.16637L14.8336 2.10571L13.7729 1.04505ZM11.25 9C11.25 10.2426 10.2426 11.25 9 11.25C7.75736 11.25 6.75 10.2426 6.75 9C6.75 7.75736 7.75736 6.75 9 6.75C10.2426 6.75 11.25 7.75736 11.25 9ZM12.75 9C12.75 11.0711 11.0711 12.75 9 12.75C6.92893 12.75 5.25 11.0711 5.25 9C5.25 6.92893 6.92893 5.25 9 5.25C11.0711 5.25 12.75 6.92893 12.75 9Z'
        fill={color || fill}
      />
    </svg>
  );
}

export function NavGeneralSettings(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);
  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.575 3.75H2.25C1.83579 3.75 1.5 4.08579 1.5 4.5C1.5 4.91421 1.83579 5.25 2.25 5.25H10.575C10.5258 5.00767 10.5 4.75685 10.5 4.5C10.5 4.24315 10.5258 3.99233 10.575 3.75ZM16.5 4.5C16.5 5.74264 15.4926 6.75 14.25 6.75C13.0074 6.75 12 5.74264 12 4.5C12 3.25736 13.0074 2.25 14.25 2.25C15.4926 2.25 16.5 3.25736 16.5 4.5ZM14.25 8.25C13.0231 8.25 11.9339 7.66083 11.2497 6.75H2.25C1.00736 6.75 0 5.74264 0 4.5C0 3.25736 1.00736 2.25 2.25 2.25H11.2497C11.9339 1.33916 13.0231 0.75 14.25 0.75C16.3211 0.75 18 2.42893 18 4.5C18 6.57107 16.3211 8.25 14.25 8.25ZM3.75 11.25C2.50736 11.25 1.5 12.2574 1.5 13.5C1.5 14.7426 2.50736 15.75 3.75 15.75C4.99264 15.75 6 14.7426 6 13.5C6 12.2574 4.99264 11.25 3.75 11.25ZM6.64368 15.8853C6.68022 15.8411 6.71576 15.7959 6.75027 15.75L15.75 15.75C16.9926 15.75 18 14.7426 18 13.5C18 12.2574 16.9926 11.25 15.75 11.25L6.75028 11.25C6.06612 10.3392 4.97686 9.75 3.75 9.75C1.67893 9.75 0 11.4289 0 13.5C0 15.5711 1.67893 17.25 3.75 17.25C4.91498 17.25 5.95588 16.7188 6.64368 15.8853ZM15.75 12.75L7.42499 12.75C7.47418 12.9923 7.5 13.2432 7.5 13.5C7.5 13.7568 7.47418 14.0077 7.42499 14.25L15.75 14.25C16.1642 14.25 16.5 13.9142 16.5 13.5C16.5 13.0858 16.1642 12.75 15.75 12.75Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavCampaigns(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C9.42002 1.5 9.83198 1.53453 10.2332 1.6009L10.4798 0.121076C9.99837 0.041432 9.50402 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 8.49598 17.9586 8.00163 17.8789 7.52018L16.3991 7.76682C16.4655 8.16802 16.5 8.57998 16.5 9ZM9 3C9.33602 3 9.66558 3.02762 9.98655 3.08072L9.73991 4.56054C9.49919 4.52072 9.25201 4.5 9 4.5C6.51472 4.5 4.5 6.51472 4.5 9C4.5 11.4853 6.51472 13.5 9 13.5C11.4853 13.5 13.5 11.4853 13.5 9C13.5 8.74799 13.4793 8.50081 13.4395 8.26009L14.9193 8.01345C14.9724 8.33442 15 8.66398 15 9C15 12.3137 12.3137 15 9 15C5.68629 15 3 12.3137 3 9C3 5.68629 5.68629 3 9 3ZM9 6C9.168 6 9.33278 6.01381 9.49326 6.04036L9.24663 7.52018C9.16639 7.50691 9.084 7.5 9 7.5C8.17157 7.5 7.5 8.17157 7.5 9C7.5 9.82843 8.17157 10.5 9 10.5C9.82843 10.5 10.5 9.82843 10.5 9C10.5 8.916 10.4931 8.8336 10.4798 8.75336L11.9596 8.50672C11.9862 8.66721 12 8.83199 12 9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9C6 7.34315 7.34315 6 9 6ZM12.0934 7.18131L10.5 7.5L10.8187 5.90656L11.4699 2.65045C11.7781 1.10933 13.1313 0 14.7029 0C16.5239 0 18 1.47614 18 3.29706C18 4.8687 16.8907 6.22187 15.3495 6.53009L12.0934 7.18131ZM15.0554 5.05922L12.4121 5.58787L12.9408 2.94463C13.1088 2.10464 13.8463 1.5 14.7029 1.5C15.6954 1.5 16.5 2.30457 16.5 3.29706C16.5 4.15368 15.8954 4.89122 15.0554 5.05922Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavSubscriptionSettings(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);
  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='1' y='2' width='18' height='14' rx='3' stroke={color || colorValue} strokeWidth='2' />
      <path d='M1.5 2.5L10 9L18.5 2.5' stroke={color || colorValue} strokeWidth='2' />
    </svg>
  );
}

export function NavPageSettings(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);
  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.25 0H12.75V1.5H13.5C15.9853 1.5 18 3.51472 18 6V12C18 14.4853 15.9853 16.5 13.5 16.5H12.75V18H11.25V16.5V15V3V1.5V0ZM12.75 3H13.5C15.1569 3 16.5 4.34315 16.5 6V12C16.5 13.6569 15.1569 15 13.5 15H12.75V3ZM9.75 1.5H4.5C2.01472 1.5 0 3.51472 0 6V12C0 14.4853 2.01472 16.5 4.5 16.5H9.75V15H4.5C2.84315 15 1.5 13.6569 1.5 12V6C1.5 4.34315 2.84315 3 4.5 3H9.75V1.5ZM4.5 5.25C4.08579 5.25 3.75 5.58579 3.75 6C3.75 6.41421 4.08579 6.75 4.5 6.75H8.25C8.66421 6.75 9 6.41421 9 6C9 5.58579 8.66421 5.25 8.25 5.25H4.5ZM3.75 9C3.75 8.58579 4.08579 8.25 4.5 8.25H8.25C8.66421 8.25 9 8.58579 9 9C9 9.41421 8.66421 9.75 8.25 9.75H4.5C4.08579 9.75 3.75 9.41421 3.75 9ZM4.5 11.25C4.08579 11.25 3.75 11.5858 3.75 12C3.75 12.4142 4.08579 12.75 4.5 12.75H6C6.41421 12.75 6.75 12.4142 6.75 12C6.75 11.5858 6.41421 11.25 6 11.25H4.5Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

/**
 * @deprecated Renamed to NavPrivacyCenter
 * @param {*} props
 * @returns
 */
export function NavNoCookieConsent(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.65379 11.1733L1.66477 5.34467L9.00001 1.67705L16.3044 5.32927L15.1363 11.17L8.98614 16.0902L2.65379 11.1733ZM0 4.5L9.00001 0L18 4.5L16.5 12L9.00001 18L1.27262 12L0 4.5ZM8.78033 11.0303L13.2803 6.53033L12.2197 5.46967L8.25 9.43934L5.78033 6.96967L4.71967 8.03033L7.71967 11.0303L8.25 11.5607L8.78033 11.0303Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavPrivacyCenter(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { color: colorValue } = mainMenu(isActive);
  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.65379 11.1733L1.66477 5.34467L9.00001 1.67705L16.3044 5.32927L15.1363 11.17L8.98614 16.0902L2.65379 11.1733ZM0 4.5L9.00001 0L18 4.5L16.5 12L9.00001 18L1.27262 12L0 4.5ZM8.78033 11.0303L13.2803 6.53033L12.2197 5.46967L8.25 9.43934L5.78033 6.96967L4.71967 8.03033L7.71967 11.0303L8.25 11.5607L8.78033 11.0303Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavVisitorSegments(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.5'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 9C18 9.53332 17.9536 10.0558 17.8646 10.5636L16.3871 10.3048C16.4612 9.88207 16.5 9.44618 16.5 9C16.5 8.55382 16.4612 8.11793 16.3871 7.69518L17.8646 7.43635C17.9536 7.94419 18 8.46668 18 9ZM0 9C0 9.53332 0.0463888 10.0558 0.135352 10.5636L1.61285 10.3048C1.5388 9.88207 1.5 9.44618 1.5 9C1.5 8.55382 1.5388 8.11793 1.61285 7.69518L0.135351 7.43635C0.0463888 7.94419 0 8.46668 0 9ZM1.94997 6.43411L0.540469 5.92097C0.903057 4.92501 1.43652 4.01115 2.10539 3.21484L3.25397 4.1796C2.69588 4.84401 2.25163 5.60551 1.94997 6.43411ZM4.1796 3.25397L3.21484 2.10539C4.01115 1.43652 4.92501 0.903057 5.92097 0.540469L6.43411 1.94997C5.60551 2.25163 4.84401 2.69588 4.1796 3.25397ZM7.69518 1.61285L7.43635 0.135352C7.94419 0.0463888 8.46668 0 9 0C9.53332 0 10.0558 0.0463888 10.5636 0.135351L10.3048 1.61285C9.88207 1.5388 9.44618 1.5 9 1.5C8.55382 1.5 8.11793 1.5388 7.69518 1.61285ZM11.5659 1.94997L12.079 0.540469C13.075 0.903057 13.9889 1.43652 14.7852 2.10539L13.8204 3.25397C13.156 2.69588 12.3945 2.25163 11.5659 1.94997ZM14.746 4.1796L15.8946 3.21484C16.5635 4.01115 17.0969 4.92501 17.4595 5.92097L16.05 6.43411C15.7484 5.60551 15.3041 4.84401 14.746 4.1796Z'
        fill={color || colorValue}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9C6 7.34315 7.34315 6 9 6C10.6569 6 12 7.34315 12 9ZM11.7323 12.5759C12.8069 11.7535 13.5 10.4577 13.5 9C13.5 6.51472 11.4853 4.5 9 4.5C6.51472 4.5 4.5 6.51472 4.5 9C4.5 10.4577 5.19309 11.7535 6.26771 12.5758C4.84666 13.2056 3.68363 14.3116 2.98108 15.6914C4.57559 17.1266 6.68577 18 8.99995 18C11.3141 18 13.4243 17.1266 15.0188 15.6914C14.697 15.0593 14.2784 14.4846 13.7827 13.9868C13.1965 13.3982 12.5022 12.9171 11.7323 12.5759ZM8.99995 16.5C7.5303 16.5 6.15938 16.0773 5.00214 15.3468C5.96511 14.2167 7.39882 13.5 8.99992 13.5C10.601 13.5 12.0348 14.2167 12.9977 15.3468C11.8405 16.0773 10.4696 16.5 8.99995 16.5Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavImport(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.25 0V10.6293L5.03033 7.40967L3.96967 8.47033L8.46967 12.9703L9 13.5007L9.53033 12.9703L14.0303 8.47033L12.9697 7.40967L9.75 10.6293V0H8.25ZM1.5 10.5V13.5C1.5 15.1569 2.84315 16.5 4.5 16.5H13.5C15.1569 16.5 16.5 15.1569 16.5 13.5V10.5H18V13.5C18 15.9853 15.9853 18 13.5 18H4.5C2.01472 18 0 15.9853 0 13.5V10.5H1.5Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavSettings(props: NavIconProps) {
  return NavWebsiteSettings(props);
}

export function NavConsent(props: NavIconProps) {
  return NavNoCookieConsent(props);
}

export function NavUpgradeAccount(props: NavIconProps) {
  const { ...otherProps } = props;

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 6C10.6569 6 12 4.65685 12 3C12 1.34315 10.6569 0 9 0C7.34315 0 6 1.34315 6 3C6 4.65685 7.34315 6 9 6ZM16.5 9C16.5 9.82843 15.8284 10.5 15 10.5C14.1716 10.5 13.5 9.82843 13.5 9C13.5 8.17157 14.1716 7.5 15 7.5C15.8284 7.5 16.5 8.17157 16.5 9ZM18 9C18 10.6569 16.6569 12 15 12C13.3431 12 12 10.6569 12 9C12 7.34315 13.3431 6 15 6C16.6569 6 18 7.34315 18 9ZM9 16.5C9.82843 16.5 10.5 15.8284 10.5 15C10.5 14.1716 9.82843 13.5 9 13.5C8.17157 13.5 7.5 14.1716 7.5 15C7.5 15.8284 8.17157 16.5 9 16.5ZM9 18C10.6569 18 12 16.6569 12 15C12 13.3431 10.6569 12 9 12C7.34315 12 6 13.3431 6 15C6 16.6569 7.34315 18 9 18ZM4.5 9C4.5 9.82843 3.82843 10.5 3 10.5C2.17157 10.5 1.5 9.82843 1.5 9C1.5 8.17157 2.17157 7.5 3 7.5C3.82843 7.5 4.5 8.17157 4.5 9ZM6 9C6 10.6569 4.65685 12 3 12C1.34315 12 0 10.6569 0 9C0 7.34315 1.34315 6 3 6C4.65685 6 6 7.34315 6 9Z'
        fill={PURE_BLACK_COLOR}
      />
    </svg>
  );
}

// TODO Delete- after the cleanup of sidebar and topbar
/** @deprecated */
export function NavArrowUp(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;

  return (
    <svg {...otherProps} width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 9L7 3L13 9' stroke={isActive ? (color ? color : BLUE_COLOR) : GREY_COLOR} strokeWidth='1.5' />
    </svg>
  );
}

// TODO Delete- after the cleanup of sidebar and topbar
/** @deprecated */
export function NavArrowDown(props: NavIconProps) {
  return (
    <svg {...props} width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 3L7 9L13 3' stroke={GREY_COLOR} strokeWidth='1.5' />
    </svg>
  );
}

export function NavToggleArrowExpanded(props: NavIconProps) {
  return (
    <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12' cy='12' r='11' stroke='#999999' strokeWidth='2' />
      <path d='M14 8L10 12L14 16' stroke='#999999' strokeWidth='2' />
    </svg>
  );
}

export function NavToggleArrowCollapsed(props: NavIconProps) {
  return (
    <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle r='11' transform='matrix(-1 0 0 1 12 12)' stroke='#999999' strokeWidth='2' />
      <path d='M10 8L14 12L10 16' stroke='#999999' strokeWidth='2' />
    </svg>
  );
}

export function NavManageWebsites(props: NavIconProps) {
  const { isActive, ...otherProps } = props;
  return isActive ? (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 30 30'
      xmlSpace='preserve'
      {...otherProps}
    >
      <g>
        <polygon
          fill={GREEN_COLOR}
          points='25.66,13.5 25.66,26.5 3.66,26.5 3.66,4.5 16.66,4.5 17.66,3.5 2.66,3.5 2.66,27.5 26.66,27.5
        26.66,12.5 	'
        />
        <g>
          <path
            fill={GREEN_COLOR}
            d='M23.66,3.91l2.59,2.59l-15,15H8.66v-2.59L23.66,3.91 M23.66,2.5l-16,16v4h4l16-16L23.66,2.5L23.66,2.5z'
          />
        </g>

        <rect
          x='23.01'
          y='5.6'
          transform='matrix(0.7071 -0.7071 0.7071 0.7071 2.4069 18.6642)'
          fill={GREEN_COLOR}
          width='1.45'
          height='1.66'
        />
        <polygon fill={GREEN_COLOR} points='9.66,20.5 9.66,19.33 20.51,8.47 21.69,9.65 10.83,20.5 	' />
        <polygon
          fill={GREEN_COLOR}
          points='12.07,23.5 6.66,23.5 6.66,18.09 16.73,8.02 16.73,5.5 4.66,5.5 4.66,25.5 24.66,25.5 24.66,13.48
        22.09,13.48 	'
        />
      </g>
    </svg>
  ) : (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 30 30'
      xmlSpace='preserve'
      {...otherProps}
    >
      <g>
        <polygon
          fill={BLACK_COLOR}
          points='25.82,26.5 3.82,26.5 3.82,4.5 16.83,4.5 17.83,3.5 2.82,3.5 2.82,27.5 26.82,27.5 26.82,12.5
        25.82,13.5 	'
        />
        <path
          fill={BLACK_COLOR}
          d='M7.82,22.5h4l16-16l-4-4l-16,16C7.82,18.5,7.82,22.5,7.82,22.5z M26.41,6.5l-2.44,2.44l-2.59-2.59l2.44-2.44
        L26.41,6.5z M8.82,18.91L20.68,7.06l2.59,2.59L11.41,21.5H8.82V18.91z'
        />
      </g>
    </svg>
  );
}

export function NavFeedback(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { color: fill } = mainMenu(isActive);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 18L7.5 17.25L12 15H13.5C15.9853 15 18 12.9853 18 10.5V4.5C18 2.01472 15.9853 0 13.5 0H4.5C2.01472 0 0 2.01472 0 4.5V10.5C0 12.9853 2.01472 15 4.5 15H6V16.3229V16.5V18ZM11.3292 13.6584L7.5 15.5729V15V13.5H6H4.5C2.84315 13.5 1.5 12.1569 1.5 10.5V4.5C1.5 2.84315 2.84315 1.5 4.5 1.5H13.5C15.1569 1.5 16.5 2.84315 16.5 4.5V10.5C16.5 12.1569 15.1569 13.5 13.5 13.5H12H11.6459L11.3292 13.6584ZM4.875 8.625C5.49632 8.625 6 8.12132 6 7.5C6 6.87868 5.49632 6.375 4.875 6.375C4.25368 6.375 3.75 6.87868 3.75 7.5C3.75 8.12132 4.25368 8.625 4.875 8.625ZM10.5 7.5C10.5 8.12132 9.99632 8.625 9.375 8.625C8.75368 8.625 8.25 8.12132 8.25 7.5C8.25 6.87868 8.75368 6.375 9.375 6.375C9.99632 6.375 10.5 6.87868 10.5 7.5ZM13.5 3.75C13.0858 3.75 12.75 4.08579 12.75 4.5V10.5C12.75 10.9142 13.0858 11.25 13.5 11.25C13.9142 11.25 14.25 10.9142 14.25 10.5V4.5C14.25 4.08579 13.9142 3.75 13.5 3.75Z'
        fill={color || fill}
      />
    </svg>
  );
}

export function NavPolls(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);
  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.5 4.5H6V6V9H5.75H1.5C1.5 4.85786 4.85786 1.5 9 1.5C12.6284 1.5 15.6551 4.07664 16.35 7.5H12V6V4.5H10.5H7.5ZM16.5 9H13.5H12V10.5V15.8759C14.6489 14.7186 16.5 12.0755 16.5 9ZM12 17.4879C11.5176 17.6584 11.0162 17.789 10.5 17.8756C10.0122 17.9574 9.51107 18 9 18C8.48893 18 7.98781 17.9574 7.5 17.8756C6.98377 17.789 6.48245 17.6584 6 17.4879C5.91609 17.4582 5.83275 17.4274 5.75 17.3953C2.8352 16.2661 0.655045 13.6627 0.124438 10.5C0.0425982 10.0122 0 9.51107 0 9C0 4.02944 4.02944 0 9 0C13.4595 0 17.1614 3.24343 17.8756 7.5C17.9574 7.98781 18 8.48893 18 9C18 12.9187 15.4956 16.2524 12 17.4879ZM1.65003 10.5C2.12194 12.8248 3.66919 14.7591 5.75 15.7612V12V10.5H4.25H1.65003ZM7.5 16.35C7.98466 16.4484 8.4863 16.5 9 16.5C9.5137 16.5 10.0153 16.4484 10.5 16.35V9V7.5V6H9H7.5V7.5V16.35Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavSurveys(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);
  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 2.25C0 1.83579 0.335786 1.5 0.75 1.5H17.25C17.6642 1.5 18 1.83579 18 2.25C18 2.66421 17.6642 3 17.25 3H0.75C0.335787 3 0 2.66421 0 2.25ZM2.25 6H15.75C16.1642 6 16.5 6.33579 16.5 6.75V11.25C16.5 11.6642 16.1642 12 15.75 12H2.25C1.83579 12 1.5 11.6642 1.5 11.25V6.75C1.5 6.33579 1.83579 6 2.25 6ZM0 6.75C0 5.50736 1.00736 4.5 2.25 4.5H15.75C16.9926 4.5 18 5.50736 18 6.75V11.25C18 12.4926 16.9926 13.5 15.75 13.5H2.25C1.00736 13.5 0 12.4926 0 11.25V6.75ZM4.5 10.5C5.32843 10.5 6 9.82843 6 9C6 8.17157 5.32843 7.5 4.5 7.5C3.67157 7.5 3 8.17157 3 9C3 9.82843 3.67157 10.5 4.5 10.5ZM0.75 15C0.335786 15 0 15.3358 0 15.75C0 16.1642 0.335787 16.5 0.75 16.5H17.25C17.6642 16.5 18 16.1642 18 15.75C18 15.3358 17.6642 15 17.25 15H0.75Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavTrafficStructure(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5 3C10.5 3.82843 9.82843 4.5 9 4.5C8.17157 4.5 7.5 3.82843 7.5 3C7.5 2.17157 8.17157 1.5 9 1.5C9.82843 1.5 10.5 2.17157 10.5 3ZM9.75 5.90549C11.0439 5.57246 12 4.39788 12 3C12 1.34315 10.6569 0 9 0C7.34315 0 6 1.34315 6 3C6 4.39788 6.95608 5.57245 8.25 5.90549V8.25H5.90549C5.57245 6.95608 4.39788 6 3 6C1.34315 6 0 7.34315 0 9C0 10.6569 1.34315 12 3 12C4.39788 12 5.57245 11.0439 5.90549 9.75H8.25V12.0945C6.95608 12.4275 6 13.6021 6 15C6 16.6569 7.34315 18 9 18C10.6569 18 12 16.6569 12 15C12 13.6021 11.0439 12.4275 9.75 12.0945V9.75H12.0945C12.4275 11.0439 13.6021 12 15 12C16.6569 12 18 10.6569 18 9C18 7.34315 16.6569 6 15 6C13.6021 6 12.4275 6.95608 12.0945 8.25H9.75V5.90549ZM10.5 15C10.5 15.8284 9.82843 16.5 9 16.5C8.17157 16.5 7.5 15.8284 7.5 15C7.5 14.1716 8.17157 13.5 9 13.5C9.82843 13.5 10.5 14.1716 10.5 15ZM13.5 9C13.5 9.82843 14.1716 10.5 15 10.5C15.8284 10.5 16.5 9.82843 16.5 9C16.5 8.17157 15.8284 7.5 15 7.5C14.1716 7.5 13.5 8.17157 13.5 9ZM3 10.5C2.17157 10.5 1.5 9.82843 1.5 9C1.5 8.17157 2.17157 7.5 3 7.5C3.82843 7.5 4.5 8.17157 4.5 9C4.5 9.82843 3.82843 10.5 3 10.5Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavSearchIconLeft(props: NavIconProps) {
  return (
    <svg {...props} width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 31.5C25.3661 31.5 26.6468 31.1348 27.75 30.4966V17.5034C26.6468 16.8652 25.3661 16.5 24 16.5C19.8579 16.5 16.5 19.8579 16.5 24C16.5 28.1421 19.8579 31.5 24 31.5ZM31.5 24C31.5 26.098 30.6385 27.9949 29.25 29.3561V18.6439C30.6385 20.0051 31.5 21.902 31.5 24ZM24 33C28.9706 33 33 28.9706 33 24C33 19.0294 28.9706 15 24 15C19.0294 15 15 19.0294 15 24C15 28.9706 19.0294 33 24 33ZM23.0303 26.4697L21.3107 24.75H25.5V23.25H21.3107L23.0303 21.5303L21.9697 20.4697L18.4393 24L21.9697 27.5303L23.0303 26.4697Z'
        fill={GREY_COLOR}
      />
    </svg>
  );
}

export function NavSearchIconRight(props: NavIconProps) {
  return (
    <svg {...props} width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 31.5C28.1421 31.5 31.5 28.1421 31.5 24C31.5 19.8579 28.1421 16.5 24 16.5C22.6339 16.5 21.3531 16.8652 20.25 17.5034V30.4966C21.3531 31.1348 22.6339 31.5 24 31.5ZM16.5 24C16.5 21.902 17.3615 20.0051 18.75 18.6439V29.3561C17.3615 27.9949 16.5 26.098 16.5 24ZM24 33C28.9706 33 33 28.9706 33 24C33 19.0294 28.9706 15 24 15C19.0294 15 15 19.0294 15 24C15 28.9706 19.0294 33 24 33ZM24.9697 26.4697L26.6893 24.75H22.5V23.25H26.6893L24.9697 21.5303L26.0303 20.4697L29.0303 23.4697L29.5607 24L29.0303 24.5303L26.0303 27.5303L24.9697 26.4697Z'
        fill={GREY_COLOR}
      />
    </svg>
  );
}

export function NavAddCircle({ color = PURE_BLACK_COLOR, className }: IconProps) {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='9' cy='9' r='8.25' stroke={color} strokeWidth='1.5' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.75 4.5H8.25V8.25L4.5 8.25V9.75H8.25V13.5H9.75V9.75H13.5V8.25L9.75 8.25V4.5Z'
        fill={color}
      />
    </svg>
  );
}

export function NavMilestone(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { color: fill } = mainMenu(isActive);
  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.99995 15L13.3695 7.71744C15.4073 4.32102 12.9608 0 8.99995 0C5.03908 0 2.59256 4.32102 4.63041 7.71744L8.99995 15ZM8.99995 12.0845L12.0832 6.94569C13.5212 4.54906 11.7949 1.5 8.99995 1.5C6.20502 1.5 4.47867 4.54906 5.91665 6.94569L8.99995 12.0845ZM3.59983 9.89972C1.41392 10.7207 0 12.0278 0 13.5C0 15.9853 4.02944 18 9 18C13.9706 18 18 15.9853 18 13.5C18 12.0278 16.5861 10.7207 14.4002 9.89972L13.5424 11.1864C13.962 11.3277 14.3474 11.4868 14.6931 11.6596C16.1624 12.3943 16.5 13.1316 16.5 13.5C16.5 13.8684 16.1624 14.6057 14.6931 15.3403C13.3136 16.0301 11.3029 16.5 9 16.5C6.69709 16.5 4.68636 16.0301 3.30686 15.3403C1.83764 14.6057 1.5 13.8684 1.5 13.5C1.5 13.1316 1.83764 12.3943 3.30686 11.6596C3.65258 11.4868 4.03795 11.3277 4.45763 11.1864L3.59983 9.89972ZM9 6.5C9.82843 6.5 10.5 5.82843 10.5 5C10.5 4.17157 9.82843 3.5 9 3.5C8.17157 3.5 7.5 4.17157 7.5 5C7.5 5.82843 8.17157 6.5 9 6.5Z'
        fill={color || fill}
      />
    </svg>
  );
}

export function NavEventTracking(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);
  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.55933 10.0593L10.4987 2.12H15.8774V7.49868L7.93801 15.438C7.35223 16.0238 6.40248 16.0238 5.81669 15.438L2.55933 12.1807C1.97355 11.5949 1.97355 10.6451 2.55933 10.0593ZM9.87735 0.619995H15.8774H17.3774V2.12V8.12L8.99868 16.4987C7.8271 17.6702 5.92761 17.6702 4.75603 16.4987L1.49867 13.2413C0.327102 12.0697 0.327102 10.1702 1.49867 8.99868L9.87735 0.619995ZM12.8774 6.62C13.7058 6.62 14.3774 5.94842 14.3774 5.12C14.3774 4.29157 13.7058 3.62 12.8774 3.62C12.0489 3.62 11.3774 4.29157 11.3774 5.12C11.3774 5.94842 12.0489 6.62 12.8774 6.62Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavVisitors(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.3002 13.1C14.4002 12.2 13.4002 11.6 12.2002 11.1C13.9002 10.1 15.0002 8.2 15.0002 6C15.0002 5.4 14.9002 4.7 14.7002 4.1C13.9002 1.7 11.7002 0 9.0002 0C6.3002 0 4.1002 1.7 3.3002 4.1C3.1002 4.7 3.0002 5.4 3.0002 6C3.0002 8.1 4.1002 10 5.8002 11.1C4.6002 11.5 3.6002 12.2 2.7002 13.1C2.3002 13.5 2.0002 13.9 1.7002 14.3C2.0002 14.7 2.3002 15.1 2.7002 15.5C4.3002 17 6.6002 18 9.0002 18C11.4002 18 13.7002 17 15.3002 15.4C15.7002 15 16.0002 14.6 16.3002 14.2C16.0002 13.8 15.7002 13.4 15.3002 13.1ZM4.5002 6C4.5002 3.7 6.3002 1.8 8.5002 1.5C8.7002 1.5 8.8002 1.5 9.0002 1.5C9.2002 1.5 9.3002 1.5 9.5002 1.5C11.7002 1.7 13.5002 3.7 13.5002 6C13.5002 8.5 11.5002 10.5 9.0002 10.5C6.5002 10.5 4.5002 8.5 4.5002 6ZM9.0002 16.5C6.9002 16.5 5.0002 15.6 3.6002 14.3C5.0002 12.9 6.9002 12 9.0002 12C11.1002 12 13.0002 12.9 14.3002 14.2C13.0002 15.6 11.1002 16.5 9.0002 16.5Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export function NavWebsiteErrors(props: NavIconProps) {
  const { isActive, color, ...otherProps } = props;
  const { colorValue } = subMenu(isActive, color);

  return (
    <svg {...otherProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='9' cy='9' r='8.25' stroke={color} strokeWidth='1.5' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 4.5C8.58579 4.5 8.25 4.83579 8.25 5.25V9.75C8.25 10.1642 8.58579 10.5 9 10.5C9.41421 10.5 9.75 10.1642 9.75 9.75V5.25C9.75 4.83579 9.41421 4.5 9 4.5ZM9 12C8.58579 12 8.25 12.3358 8.25 12.75C8.25 13.1642 8.58579 13.5 9 13.5C9.41421 13.5 9.75 13.1642 9.75 12.75C9.75 12.3358 9.41421 12 9 12Z'
        fill={color || colorValue}
      />
    </svg>
  );
}

export const NavOutgoingTraffic = ({ className, color = 'currentColor' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 5C3.82843 5 4.5 4.32843 4.5 3.5C4.5 2.67157 3.82843 2 3 2C2.17157 2 1.5 2.67157 1.5 3.5C1.5 4.32843 2.17157 5 3 5ZM5.90549 4.25C5.57245 5.54392 4.39788 6.5 3 6.5C1.34315 6.5 0 5.15685 0 3.5C0 1.84315 1.34315 0.5 3 0.5C4.39788 0.5 5.57245 1.45608 5.90549 2.75H12.75C14.8211 2.75 16.5 4.42893 16.5 6.5C16.5 8.57107 14.8211 10.25 12.75 10.25H5.25C4.00736 10.25 3 11.2574 3 12.5C3 13.7426 4.00736 14.75 5.25 14.75H12.0945C12.4275 13.4561 13.6021 12.5 15 12.5C16.6569 12.5 18 13.8431 18 15.5C18 17.1569 16.6569 18.5 15 18.5C13.6021 18.5 12.4275 17.5439 12.0945 16.25H5.25C3.17893 16.25 1.5 14.5711 1.5 12.5C1.5 10.4289 3.17893 8.75 5.25 8.75H12.75C13.9926 8.75 15 7.74264 15 6.5C15 5.25736 13.9926 4.25 12.75 4.25H5.90549Z'
        fill={color}
      />
    </svg>
  );
};
