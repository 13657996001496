import { apiStatus } from '@va/constants';
import { RESET_REQUEST, RESET_REQUEST_STATUS } from '@va/dashboard/actions/api';
import { applyReducers } from '@va/dashboard/reducers';
import * as Types from '@va/standalone/shared/actions';
import Immutable from 'immutable';
import * as Account from './account';
import * as Billing from './billing';
import * as Weebly from './weebly';

const apiReducer = (state = Immutable.Map(), action) => {
  switch (action.type) {
    case RESET_REQUEST_STATUS:
      return state.setIn([action.requestName, 'status'], apiStatus.NONE);
    case RESET_REQUEST:
      return state.set(action.requestName, Immutable.Map({ status: apiStatus.NONE }));
    case Types.standalone.Api.REFRESH_TOKEN:
      return state.set('isRefreshingToken', true);
    case Types.standalone.Api.REFRESH_TOKEN_ENDED:
      return state.set('isRefreshingToken', false);
    case Types.standalone.Api.LOGOUT:
    case Types.standalone.App.RESET_STORE:
      //Reset the standalone part of the store to the initial values
      return Immutable.Map();
    default:
      return applyReducers(state, action, {
        user: Account.createUser,
        appsumoUser: Account.createAppsumoUser,
        activate: Account.activateUser,
        login: Account.loginUser,
        checkLogin: Account.checkLogin,
        backgroundLogin: Account.backgroundLogin,
        forgotPassword: Account.forgotPassword,
        resetPassword: Account.resetPassword,
        websites: Account.getWebsites,
        acceptContributorRole: Account.acceptContributorRole,
        rejectContributorRole: Account.rejectContributorRole,
        leaveWebsite: Account.leaveWebsite,
        updateWebsiteLocale: Account.updateWebsiteLocale,
        changeEmail: Account.changeEmail,
        updateUserInfo: Account.updateUserInfo,
        changeAgencyStatus: Account.changeAgencyStatus,
        changeWebsiteWhiteLabelStatus: Account.changeWebsiteWhiteLabelStatus,
        confirmUpdateEmail: Account.confirmUpdateEmail,
        resetEmail: Account.resetEmail,
        getWebsiteOverview: Account.getWebsiteOverview,
        deleteAccount: Account.deleteAccount,
        getTrackingCode: Account.getTrackingCode,
        removeWebsite: Account.removeWebsite,
        resendRegisterConfirmation: Account.resendRegisterConfirmation,
        registerContributor: Account.registerContributor,
        generateImpersonateToken: Account.generateImpersonateToken,
        migrationsUser: Account.findMigrationsUser,
        createWebsiteForMigration: Account.createWebsiteForMigration,
        createNewUserForMigration: Account.createNewUserForMigration,
        createNewSubscriber: Account.createNewSubscriber,
        getVouchers: Account.getVouchers,
        getVouchersInfo: Account.getVouchersInfo,
        addVoucher: Account.addVoucher,
        applyLifetimeDeal: Account.applyLifetimeDeal,
        getGoogleImporterAuthUrl: Account.getGoogleImporterAuthUrl,
        getGoogleImporterViews: Account.getGoogleImporterViews,
        startGoogleImporter: Account.startGoogleImporter,
        getGoogleImporterProgress: Account.getGoogleImporterProgress,
        getUpdatePaymentMethodUrl: Billing.getUpdatePaymentMethodUrl,
        updateSubscription: Billing.updateSubscription,
        getPaymentAuthToken: Billing.getPaymentAuthToken,
        getBraintreeAuthToken: Billing.getBraintreeAuthToken,
        getPaymentMethod: Billing.getPaymentMethod,
        getPaymentPlans: Billing.getPaymentPlans,
        getAllSubscriptions: Billing.getAllSubscriptions,
        generateInvoice: Billing.generateInvoice,
        getComputedSubscriptionPrice: Billing.getComputedSubscriptionPrice,
        validateVat: Billing.validateVat,
        getCustomer: Billing.getCustomer,
        getAgencyCustomer: Billing.getAgencyCustomer,
        submitPayment: Billing.submitPayment,
        createCustomer: Billing.createCustomer,
        createPaymentMethod: Billing.createPaymentMethod,
        createSubscription: Billing.createSubscription,
        cancelSubscription: Billing.cancelSubscription,
        cancelSubscriptionImmediate: Billing.cancelSubscriptionImmediate,
        reactivateSubscription: Billing.reactivateSubscription,
        updateCustomer: Billing.updateCustomer,
        validatePromoCode: Billing.validatePromoCode,
        weeblyLogin: Weebly.weeblyLogin,
        weeblySync: Weebly.weeblySync,
        getAgencyThemes: Account.getAgencyThemes,
        getAgencyUi: Account.getAgencyUi,
        getAgencyQuaterlyCommission: Account.getAgencyQuaterlyCommission,
      });
  }
};
export default apiReducer;
