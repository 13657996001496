export function DiscountArrowBold(props) {
  const { color, ...otherProps } = props;
  const fillColor = color || '#F5A623';
  return (
    <svg
      width='33px'
      height='35px'
      viewBox='0 0 33 35'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...otherProps}
    >
      <g id='app-flow-for-free' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='nov2019---02-overview-modal-upgrade' transform='translate(-1336.000000, -800.000000)' fill={fillColor}>
          <g id='modal/ask-for-session-replay' transform='translate(299.000000, 110.000000)'>
            <g id='Group-7' transform='translate(1037.000000, 690.000000)'>
              <path
                d='M25.7893,0.6359 C20.8933,7.1229 27.7263,14.8979 28.8623,21.4649 C29.4773,25.0159 26.8943,26.2379 23.6703,26.7549 C21.3213,27.1329 18.9503,27.2569 16.5753,27.3329 C11.6593,27.4909 6.7313,27.4299 1.8123,27.4499 C-0.1167,27.4579 -0.1207,30.4579 1.8123,30.4499 C7.5783,30.4259 13.3803,30.5759 19.1373,30.2249 C22.4073,30.0259 27.8473,30.0279 30.4843,27.7409 C37.8063,21.3919 22.9203,9.3829 28.3803,2.1509 C29.5433,0.6099 26.9393,-0.8871 25.7893,0.6359'
                id='Fill-1'
              ></path>
              <path
                d='M4.2668,23.7833 C3.2868,25.1463 2.1038,26.2973 0.7218,27.2513 C-0.2362,27.9123 -0.2452,29.1813 0.7218,29.8423 C2.6198,31.1363 4.3808,32.5703 6.0428,34.1543 C7.4408,35.4863 9.5658,33.3683 8.1638,32.0333 C6.3198,30.2753 4.3408,28.6863 2.2358,27.2513 L2.2358,29.8423 C4.0618,28.5823 5.5628,27.0983 6.8578,25.2973 C7.9858,23.7263 5.3848,22.2273 4.2668,23.7833'
                id='Fill-4'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
