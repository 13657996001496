import { makeAction } from '@va/store';

export const GET_CONVERSION_TYPES = 'GET_CONVERSION_TYPES';
export const GET_CONVERSION_TYPES_SUCCEEDED = 'GET_CONVERSION_TYPES_SUCCEEDED';
export const GET_CONVERSION_TYPES_FAILED = 'GET_CONVERSION_TYPES_FAILED';

export const ADD_CONVERSION_TYPE = 'ADD_CONVERSION_TYPE';
export const ADD_CONVERSION_TYPE_SUCCEEDED = 'ADD_CONVERSION_TYPE_SUCCEEDED';
export const ADD_CONVERSION_TYPE_FAILED = 'ADD_CONVERSION_TYPE_FAILED';

export const EDIT_CONVERSION_TYPE = 'EDIT_CONVERSION_TYPE';
export const EDIT_CONVERSION_TYPE_SUCCEEDED = 'EDIT_CONVERSION_TYPE_SUCCEEDED';
export const EDIT_CONVERSION_TYPE_FAILED = 'EDIT_CONVERSION_TYPE_FAILED';

export const REMOVE_CONVERSION_TYPE = 'REMOVE_CONVERSION_TYPE';
export const REMOVE_CONVERSION_TYPE_SUCCEEDED = 'REMOVE_CONVERSION_TYPE_SUCCEEDED';
export const REMOVE_CONVERSION_TYPE_FAILED = 'REMOVE_CONVERSION_TYPE_FAILED';

export const START_EDITING_CONVERSION_TYPE = 'START_EDITING_CONVERSION_TYPE';

export const ADD_CONVERSION_PAGE = 'ADD_CONVERSION_PAGE';
export const REMOVE_CONVERSION_PAGE = 'REMOVE_CONVERSION_PAGE';

export const getConversionTypes = makeAction(GET_CONVERSION_TYPES);
export const getConversionTypesSucceeded = makeAction(GET_CONVERSION_TYPES_SUCCEEDED, 'data');
export const getConversionTypesFailed = makeAction(GET_CONVERSION_TYPES_FAILED, 'error');

export const addConversionType = makeAction(ADD_CONVERSION_TYPE, 'data');
export const addConversionTypeSucceeded = makeAction(ADD_CONVERSION_TYPE_SUCCEEDED);
export const addConversionTypeFailed = makeAction(ADD_CONVERSION_TYPE_FAILED, 'error');

export const editConversionType = makeAction(EDIT_CONVERSION_TYPE, 'id', 'data');
export const editConversionTypeSucceeded = makeAction(EDIT_CONVERSION_TYPE_SUCCEEDED);
export const editConversionTypeFailed = makeAction(EDIT_CONVERSION_TYPE_FAILED, 'error');

export const removeConversionType = makeAction(REMOVE_CONVERSION_TYPE, 'id');
export const removeConversionTypeSucceeded = makeAction(REMOVE_CONVERSION_TYPE_SUCCEEDED);
export const removeConversionTypeFailed = makeAction(REMOVE_CONVERSION_TYPE_FAILED, 'error');

export const startEditingConversionType = makeAction(START_EDITING_CONVERSION_TYPE, 'data');

export const addConversionPage = makeAction(ADD_CONVERSION_PAGE, 'id', 'data');
export const removeConversionPage = makeAction(REMOVE_CONVERSION_PAGE, 'id');
