import { getInstanceId } from '@va/dashboard/selectors/app';
import { getWebsiteTimezone } from '@va/dashboard/selectors/core';
import { get } from '@va/http-client';
import { PageItemType } from '@va/ui/design-system';
import { isNil } from 'lodash';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import { SessionLogResponse } from '@va/types/sessions';

export const usePageVisitsInfo = (sessionId: string, shoudlFetchData = true) => {
  const { data: sessionData, isLoading } = useGetSessionsLog(sessionId, shoudlFetchData);

  const pageVisits = useMemo(
    () => sessionData?.data?.sessions?.filter((session: any) => session.id === sessionId)?.[0]?.pageVisits ?? [],
    [sessionData?.data?.sessions, sessionId],
  );
  const timezone = useSelector(getWebsiteTimezone);

  const mappedData: PageItemType[] = useMemo(
    () =>
      pageVisits?.map(
        (
          session: {
            title: string;
            url: string;
            unixTs: number;
            privacyLevel: number;
          },
          index: number,
        ) => {
          const { title, url, unixTs, privacyLevel } = session;
          return {
            title,
            url: url,
            privacyLevel,
            topRightInfo: '',
            bottomRightInfo: moment(unixTs * 1000)
              .tz(timezone)
              .format('L hh:mm A'),
          };
        },
      ),
    [pageVisits, timezone],
  );

  return { mappedData, isLoading };
};

export const useGetSessionsLog = (sessionId: string, shouldFetchData = true) => {
  const websiteId = useSelector(getInstanceId);

  const fetcher = useCallback((url: string, sessionId: string) => {
    return get(url, { sessionId }) as Promise<SessionLogResponse>;
  }, []);
  const swr = useSWR(shouldFetchData ? [`/websites/${websiteId}/sessions/log`, sessionId] : null, fetcher);
  return {
    ...swr,
    isLoading: swr.isValidating ? !swr.error && isNil(swr.data) : false,
  };
};
