import { apiStatus, modalName, validationTranslationKeys } from '@va/constants';
import { updateSettings, updateWebsiteFlags } from '@va/dashboard/actions/api';
import { closeModal } from '@va/dashboard/actions/ui';
import { getRequestStatus } from '@va/dashboard/selectors/api';
import { getWebsite } from '@va/dashboard/selectors/core';
import { isModalOpen } from '@va/dashboard/selectors/ui';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { ModalFormWrapper } from '@va/ui/components/modals';
import { CheckboxInput, Paragraph, SelectDropdown, TextInput } from '@va/ui/design-system';
import { useBusinessSectors } from '@va/util/hooks';
import { useFormik } from 'formik';
import Immutable from 'immutable';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

enum FormFieldsNames {
  companyName = 'companyName',
  businessSector = 'businessSector',
  shouldNotShowAgain = 'shouldNotShowAgain',
}

const UpdateCompanyDetailsModal = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const websiteData = useSelector(getWebsite);
  const isOpen = useSelector((state) => isModalOpen(state, modalName.updateCompanyDetail));
  const isSubmitApiStatus = useSelector((state) => getRequestStatus(state, 'updateSettings'));

  const { businessSectorOptions, getSelectedBusinessSector } = useBusinessSectors();

  const { showSuccessNotification, showErrorNotification } = useAddNotification();

  const setShouldShowFrontendFlag = useCallback(() => {
    dispatch(updateWebsiteFlags({ [modalName.updateCompanyDetail]: true }));
  }, [dispatch]);

  const { values, errors, setFieldValue, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    validationSchema: getValidationSchema(translate),
    initialValues: {
      [FormFieldsNames.companyName]: websiteData?.companyName ?? '',
      [FormFieldsNames.businessSector]: undefined,
      [FormFieldsNames.shouldNotShowAgain]: false,
    },
    onSubmit: (values) => {
      dispatch(
        updateSettings(Immutable.fromJS({ companyName: values.companyName, businessSector: values.businessSector })),
      );
    },
  });

  const handleClose = useCallback(() => {
    if (values.shouldNotShowAgain) {
      setShouldShowFrontendFlag();
    }
    dispatch(closeModal(modalName.updateCompanyDetail));
  }, [dispatch, setShouldShowFrontendFlag, values.shouldNotShowAgain]);

  useEffect(() => {
    if (isSubmitApiStatus === apiStatus.SUCCEEDED) {
      showSuccessNotification();
      handleClose();
    }
    if (isSubmitApiStatus === apiStatus.FAILED) {
      showErrorNotification();
    }
  }, [isSubmitApiStatus, handleClose, showSuccessNotification, showErrorNotification]);

  return (
    <ModalFormWrapper
      title={translate('modal.updateCompanyDetail.title')}
      onClose={handleClose}
      isOpen={isOpen}
      onSubmit={handleSubmit}
      isSaveButtonDisabled={isSubmitApiStatus === apiStatus.IN_PROGRESS}
    >
      <div className='flex flex-col gap-3'>
        <Paragraph>{translate('modal.updateCompanyDetail.subtitle')}</Paragraph>
        <TextInput
          required
          name={FormFieldsNames.companyName}
          label={translate('modal.updateCompanyDetail.placeholder.companyName')}
          clearField={() => {
            setFieldValue(FormFieldsNames.companyName, '');
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.companyName}
          error={touched.companyName ? translate(errors.companyName as string) : undefined}
          autoFocus
        />
        <SelectDropdown
          label={translate('dropdown.competition.businessSector.placeholder')}
          onChange={(option) => {
            setFieldValue(FormFieldsNames.businessSector, option?.value);
          }}
          selectedOption={values.businessSector && getSelectedBusinessSector(values.businessSector)}
          dropdownOptions={businessSectorOptions}
          error={touched.businessSector ? translate(errors.businessSector as string) : undefined}
          required
        />
        <CheckboxInput
          onBlur={handleBlur}
          label={translate('modal.updateCompanyDetail.hideHint')}
          name={FormFieldsNames.shouldNotShowAgain}
          id='shouldNotShowAgain'
          checked={values.shouldNotShowAgain}
          onChange={handleChange}
          error={touched.shouldNotShowAgain ? translate(errors.shouldNotShowAgain as string) : undefined}
        />
      </div>
    </ModalFormWrapper>
  );
};

export default UpdateCompanyDetailsModal;

function getValidationSchema(translate: (k: string) => string) {
  return yup.object().shape({
    [FormFieldsNames.companyName]: yup.string().required(translate(validationTranslationKeys.required)),
    [FormFieldsNames.businessSector]: yup.string().required(translate(validationTranslationKeys.required)),
  });
}
