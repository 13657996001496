import classNames from 'classnames';
import { IconProps } from '.';

export const FilledCheckIcon = ({ className, color, onClick }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM10.5 16.591L11.2955 15.7955L17.2955 9.7955L15.7045 8.2045L10.5 13.409L8.2955 11.2045L6.7045 12.7955L9.7045 15.7955L10.5 16.591Z'
        fill={color ?? 'var(--color-primary)'}
      />
    </svg>
  );
};
