import { IconProps } from '.';

export const DashedLineGraphIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='22'
      height='18'
      viewBox='0 0 22 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M0.5 9H5L8 6L14 12L17 9H21.5' stroke={color} strokeWidth='1.5' strokeDasharray='3 0.75' />
    </svg>
  );
};
