import { platform } from '@va/constants';
import { getWebsite } from '@va/dashboard/selectors/core';
import { getCustomizationOptions } from '@va/dashboard/selectors/customization';
import { onContactSupport } from '@va/dashboard/util-helpers/misc';
import { useTranslate } from '@va/localization';
import { hasTrackingCodeError } from '@va/standalone/shared/selectors';
import { Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const TrackingCodeStatusTooltip = () => {
  const translate = useTranslate();
  const trackingCodeError = useSelector(hasTrackingCodeError);
  const website = useSelector(getWebsite);
  const isWeebly = website.platform === platform.WEEBLY;
  const { providerName, supportCenterDomain } = useSelector(getCustomizationOptions);

  const errorMessage = useMemo(() => {
    if (isWeebly)
      return translate('topBar.notification.checkTracking.message.weebly.error', {
        providerName,
      });

    return translate('topBar.notification.checkTracking.message.goToTrackingTab.error', {
      to: `/website/${website.id}/settings/tracking-code`,
      providerName,
      supportCenterDomain,
    });
  }, [isWeebly, translate, website.id, providerName, supportCenterDomain]);

  return (
    <div className='tracking-code-status-tooltip p-4 space-y-3 text-left w-full'>
      <div className='flex items-center gap-5'>
        <Paragraph
          size={paragraphSizes.large}
          weight={fontWeights.semibold}
          className={classNames('rounded-12 px-5 py-3', {
            'bg-green-pure': !trackingCodeError,
            'bg-red-pure': trackingCodeError,
          })}
        >
          {translate(`topBar.notification.checkTracking.message.status.${trackingCodeError ? 'failed' : 'succeeded'}`)}
        </Paragraph>
        <Paragraph className='font-semibold text-15'>
          {translate(
            `topBar.notification.checkTracking.message.${isWeebly ? 'weebly.' : ''}header.${
              trackingCodeError ? 'failed' : 'succeeded'
            }`,
          )}
        </Paragraph>
      </div>
      <Paragraph size={paragraphSizes.tiny}>
        {trackingCodeError ? (
          errorMessage
        ) : (
          <p onClick={onContactSupport}>
            {translate('topBar.notification.custom.checkTracking.succeeded', {
              websiteUrl: website.url,
              to: `/website/${website.id}/settings/tracking-code`,
            })}
          </p>
        )}
      </Paragraph>
    </div>
  );
};

export default TrackingCodeStatusTooltip;
