import { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react';

type ExpandableGridContextProps = {
  toggle: (cardId: string) => void;
  isExpanded: (cardId: string) => boolean;
  expandedCardId: string | undefined;
  getCardHeight: (expanded: boolean) => string;
  getGridRow: (expanded: boolean) => string;
  getGridColumn: (expanded: boolean) => string;
};

const ExpandableGridContext = createContext<ExpandableGridContextProps>({} as ExpandableGridContextProps);

export const ExpandableGridContextProvider = ({
  children,
  getCardHeight,
  getGridColumn,
  getGridRow,
}: PropsWithChildren<{
  getCardHeight: (expanded: boolean) => string;
  getGridRow: (expanded: boolean) => string;
  getGridColumn: (expanded: boolean) => string;
}>) => {
  const [expandedCardId, setExpandedCardId] = useState<string | undefined>(undefined);

  const toggle = (cardId: string) => {
    if (cardId === expandedCardId) {
      setExpandedCardId(undefined);
    } else {
      setExpandedCardId(cardId);
    }
  };

  const isExpanded = useCallback(
    (cardId: string) => {
      return cardId === expandedCardId;
    },
    [expandedCardId],
  );

  return (
    <ExpandableGridContext.Provider
      value={{ toggle, expandedCardId, isExpanded, getCardHeight, getGridColumn, getGridRow }}
    >
      {children}
    </ExpandableGridContext.Provider>
  );
};

export const useExpandableGridContext = () => useContext(ExpandableGridContext);
