import { useTranslate } from '@va/localization';
import { SingleSelectFilter, TextFilter } from '@va/shared/feature-filters';
import { Filter } from '@va/types/filters';
import { useMemo } from 'react';

const useWhiteLabelTabsFilterOptions = () => {
  const translate = useTranslate();

  const filterOptions: Filter[] = useMemo(
    () => [
      new SingleSelectFilter({
        id: '1',
        name: 'whiteLabel',
        label: translate('filters.options.whiteLabelStatus'),
        inputProps: {
          useOptions: () => ({
            data: [
              { label: translate('agencyWhiteLabel.option.btn.enabled'), value: 1 },
              { label: translate('agencyWhiteLabel.option.btn.disabled'), value: 0 },
            ],
          }),
        },
      }),
      new TextFilter({
        id: '2',
        name: 'url',
        label: translate('filters.options.searchByUrl'),
        inputProps: {
          label: translate('filter.urlName'),
        },
      }),
    ],
    [translate],
  );
  return filterOptions;
};

export default useWhiteLabelTabsFilterOptions;
