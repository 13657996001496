import { DEFAULT_REPORT_BLOCK_IDS, tabNames } from '@va/constants';
import { PageContent } from '@va/dashboard/shared/ui-layout';
import { useActiveTab } from '@va/dashboard/util-hooks';
import { FiltersContextProvider } from '@va/shared/feature-filters';
import { isAgencyAccount } from '@va/standalone/shared/selectors';
import { useSelector } from 'react-redux';
import AgencyWhiteLabelTasks from '../agency-white-label/agency-white-label-tasks/AgencyWhiteLabelTasks';
import WelcomePageAgencyWhiteLabel from '../agency-white-label/welcome-page-agency-white-label';
import { AgencyWhiteLabelContextProvider } from './AgencyWhiteLabelContext';
import ManageWhiteLabelTable from './manage-white-label/ManageWhiteLabelTable';
import useWhiteLabelTabsFilterOptions from './useWhiteLabelTabsFilterOptions';

const AgencyWhiteLabelv2 = () => {
  useActiveTab(tabNames.agencyWhiteLabel);
  const agencyAccount = useSelector(isAgencyAccount);

  const filterOptions = useWhiteLabelTabsFilterOptions();

  return (
    <FiltersContextProvider
      reportBlockId={DEFAULT_REPORT_BLOCK_IDS.manageWhiteLabelWebsitesTable}
      allFilterOptions={filterOptions}
    >
      <AgencyWhiteLabelContextProvider tab={tabNames.agencyWhiteLabel}>
        <PageContent
          className='flex flex-col gap-3'
          renderTopItems={() => (agencyAccount ? <AgencyWhiteLabelTasks /> : null)}
        >
          {agencyAccount ? <ManageWhiteLabelTable /> : <WelcomePageAgencyWhiteLabel />}
        </PageContent>
      </AgencyWhiteLabelContextProvider>
    </FiltersContextProvider>
  );
};

export default AgencyWhiteLabelv2;
