import { IconProps } from '.';

export const TripleDotIcon = ({ className, color = '#3C3C3C' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='2' cy='9' r='2' fill={color} />
      <circle cx='9' cy='9' r='2' fill={color} />
      <circle cx='16' cy='9' r='2' fill={color} />
    </svg>
  );
};
