import { NegativeTagIcon } from '@va/icons';
import { useLocalizationContext } from '@va/localization';
import { AlarmingEventTrigger, EventListItem } from '@va/types/events';
import { fontWeights, IconWithBackground, Paragraph, paragraphSizes, ParagraphWithTooltip } from '@va/ui/design-system';
import { CloseButton, useTooltipContext } from '@va/ui/tooltips';
import { addNumberSeparator } from '@va/util/helpers';
import { useWindowDimensions } from '@va/util/hooks';
import { VisitorActionListItem } from './VisitorActionListItem';
import { abeColumnTranslationKey, abeTitleTranslationKey } from './constants/visitorActions';

type VisitorActionsTooltipProps = {
  alarmingEvents: EventListItem[];
  alarmingEventsTotal: number;
  regularEvents?: EventListItem[];
  regularEventsTotal?: number;
  titleTranslationKey?: string;
  columnTranslationKey?: string;
};

export function VisitorActionsTooltip({
  alarmingEvents,
  alarmingEventsTotal,
  regularEvents,
  regularEventsTotal,
  titleTranslationKey = abeTitleTranslationKey,
  columnTranslationKey = abeColumnTranslationKey,
}: VisitorActionsTooltipProps) {
  const { locale, translate } = useLocalizationContext();
  const { height: windowHeight } = useWindowDimensions();
  const events = regularEvents ? [...alarmingEvents, ...regularEvents] : alarmingEvents;
  const { context } = useTooltipContext();
  return (
    <div
      className='rounded-3xl flex flex-col gap-2 overflow-hidden'
      style={{ maxHeight: windowHeight < 750 ? 250 : 400 }}
    >
      <div className='flex justify-between items-center px-8 pt-8 pb-1'>
        <Paragraph className='flex items-center truncate' size={paragraphSizes.large} weight={fontWeights.medium}>
          <span className='truncate max-w-xs'>{translate(titleTranslationKey)}</span>
          {regularEventsTotal && (
            <span className='ml-1'>{`(${addNumberSeparator(regularEventsTotal, locale)}) ·`}</span>
          )}

          <span className='flex items-center gap-1 px-[3px] ml-1'>
            <NegativeTagIcon color='#f06651' />
            <span className='text-red-sienna'>{addNumberSeparator(alarmingEventsTotal, locale)}</span>
          </span>
        </Paragraph>
        <CloseButton onClose={() => context.onOpenChange(false)} />
      </div>

      <div className='grow scrollbar scrollbar-thumb px-30 mb-30 overflow-y-auto'>
        <div className='flex items-center gap-10 justify-between mb-3'>
          <ParagraphWithTooltip size={paragraphSizes.tiny} weight={fontWeights.medium}>
            {translate(columnTranslationKey)}
          </ParagraphWithTooltip>
          <ParagraphWithTooltip size={paragraphSizes.tiny} weight={fontWeights.medium}>
            {translate('text.total')}
          </ParagraphWithTooltip>
        </div>

        <ul className='space-y-2'>
          {events.map((event) => (
            <VisitorActionListItem
              key={event.eventType}
              translationKey={event.translationKey}
              count={event.count}
              icon={
                <IconWithBackground
                  className={event.eventType in AlarmingEventTrigger ? 'bg-red-negative' : 'bg-black-dark'}
                  icon={() =>
                    event.eventType in AlarmingEventTrigger ? <NegativeTagIcon color='white' /> : event.icon
                  }
                />
              }
            />
          ))}
        </ul>
      </div>
    </div>
  );
}
