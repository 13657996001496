export const StickerIcon = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 16.5C9.04162 16.5 9.08315 16.4997 9.12461 16.499C9.7572 12.731 12.731 9.75719 16.499 9.12461C16.4997 9.08315 16.5 9.04162 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM18 9C18 13.4657 14.7475 17.1718 10.4821 17.8785C9.99994 17.9584 9.50482 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM10.6915 16.3085C11.3333 13.5246 13.5246 11.3333 16.3085 10.6915C15.6667 13.4754 13.4754 15.6667 10.6915 16.3085ZM8.03033 11.0303L12.5303 6.53033L11.4697 5.46967L7.5 9.43934L5.03033 6.96967L3.96967 8.03033L6.96967 11.0303L7.5 11.5607L8.03033 11.0303Z'
        fill='black'
      />
    </svg>
  );
};
