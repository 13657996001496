import { LogLevel } from '@twipla/worker';
import Config from 'Config';
import BaseWorker from '../base-worker/BaseWorker';

const isStandaloneApp = () => {
  return Config.appType === 'standalone';
};

/**
 * Worker implementation for tracking twipla.com
 */
class TwiplaWebsiteWorker extends BaseWorker {
  private static instance: TwiplaWebsiteWorker;

  private constructor() {
    super({
      websiteId: Config.presentationWebsiteId,
      logLevel: LogLevel.ERROR,
      // Must only run on /register page
      trackUrlChanges: false,
      rewriteUrl: undefined,
      enabled: isStandaloneApp(),
      usePolls: false,
    });
  }

  public static getInstance() {
    if (!TwiplaWebsiteWorker.instance) {
      TwiplaWebsiteWorker.instance = new TwiplaWebsiteWorker();
    }
    return TwiplaWebsiteWorker.instance;
  }
}

export const WorkerInstance = TwiplaWebsiteWorker.getInstance();
