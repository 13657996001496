import Immutable from 'immutable';

export const getTooltips = state => {
  return state.getIn(['core', 'ui', 'tooltips', 'items'], Immutable.Map());
};

export const getTooltip = (state, id) => {
  return state.getIn(['core', 'ui', 'tooltips', 'items', id], Immutable.Map());
};

export const getTooltipIdsToRebind = state => {
  return state.getIn(['core', 'ui', 'tooltips', 'requestedRebind'], Immutable.List());
};
