import { IconProps } from '.';

export const LinkIcon = ({ color = '#696969', className }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.3053 7.94133C15.4769 6.76975 15.4769 4.87026 14.3053 3.69869C13.1338 2.52711 11.2343 2.52711 10.0627 3.69869L9.00205 4.75935C8.41626 4.17356 7.46652 4.17356 6.88073 4.75935L9.00205 2.63803C10.7594 0.880667 13.6087 0.880667 15.366 2.63803C17.1234 4.39539 17.1234 7.24463 15.366 9.00199L13.2447 11.1233C11.4873 12.8807 8.63809 12.8807 6.88073 11.1233C6.50882 10.7514 6.21562 10.3306 6.00113 9.88159L7.16452 8.71819C7.29605 9.2107 7.555 9.67626 7.94139 10.0626C9.11296 11.2342 11.0125 11.2342 12.184 10.0626L14.3053 7.94133ZM7.94139 14.3053L9.00205 13.2446C9.58784 13.8304 10.5376 13.8304 11.1234 13.2446L9.00205 15.3659C7.24469 17.1233 4.39545 17.1233 2.63809 15.3659C0.880728 13.6086 0.880729 10.7593 2.63809 9.00199L4.75941 6.88067C6.51677 5.12331 9.36601 5.12331 11.1234 6.88067C11.4953 7.25257 11.7885 7.67338 12.003 8.12239L10.8396 9.28578C10.708 8.79327 10.4491 8.32771 10.0627 7.94133C8.89114 6.76975 6.99164 6.76975 5.82007 7.94133L3.69875 10.0626C2.52717 11.2342 2.52718 13.1337 3.69875 14.3053C4.87032 15.4769 6.76982 15.4769 7.94139 14.3053Z'
        fill={color}
      />
    </svg>
  );
};
