import { modalName } from '@va/constants';
import { startUpgradeProcess } from '@va/dashboard/actions/app';
import { closeModal } from '@va/dashboard/actions/ui';
import { getCurrentPlanName } from '@va/dashboard/selectors/app';
import { getModalExtraProps, isModalOpen } from '@va/dashboard/selectors/ui';
import { useTranslate } from '@va/localization';
import { ConfirmationModal } from '@va/shared/util-confirmation';
import { subscriptionStatus } from '@va/standalone/shared/constants';
import { Button, Paragraph } from '@va/ui/design-system';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const FeatureLimitReached = () => {
  const translate = useTranslate();
  const isOpen = useSelector((state) => isModalOpen(state, modalName.featureLimitReached));
  const planName = useSelector((state) => getCurrentPlanName(state, subscriptionStatus.ACTIVE));
  const dispatch = useDispatch();
  const extraProps = useSelector((state) => getModalExtraProps(state, modalName.featureLimitReached));

  const [featureName, setFeatureName] = useState('');
  const [featureLimit, setFeatureLimit] = useState('');

  const onClose = useCallback(() => {
    dispatch(closeModal(modalName.featureLimitReached));
  }, [dispatch]);

  useEffect(() => {
    if (extraProps === null) return;
    setFeatureName(extraProps.get('featureName'));
    setFeatureLimit(extraProps.get('featureLimit'));
  }, [extraProps]);

  return (
    <ConfirmationModal
      title={translate(`modal.${featureName}ReachedLimit.limitReached`)}
      info={
        <>
          <Paragraph>{translate(`modal.${featureName}ReachedLimit.title`)}</Paragraph>
          <br />
          <Paragraph>
            {translate(`modal.${featureName}ReachedLimit.text`, {
              planName,
              count: featureLimit,
            })}
          </Paragraph>
        </>
      }
      onClose={onClose}
      isOpen={isOpen}
      footerButtons={
        <Button
          text={translate('button.viewOtherPlans')}
          onClick={() => {
            onClose();
            dispatch(startUpgradeProcess());
          }}
          className='w-full'
        />
      }
    />
  );
};

export default FeatureLimitReached;
