import { IconProps } from './index';

export const HeartRateIcon = ({ className = '', color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='20'
      height='19'
      viewBox='0 0 20 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.5'
        d='M4.375 8.45001H6.625L8.875 10.7L11.125 6.20001L13.375 8.45001H15.625'
        stroke={color}
        strokeWidth='1.5'
      />
      <path
        d='M18.0782 3.22511L17.4422 3.62261C19.4535 6.84069 18.6662 11.0628 15.6303 13.3398L10 17.5625L4.36972 13.3398C1.33379 11.0628 0.546506 6.84069 2.5578 3.62261L2.76311 3.29413C4.23299 0.94231 7.5086 0.569257 9.46967 2.53033L10 3.06066L10.5303 2.53033C12.4914 0.569257 15.767 0.94231 17.2369 3.29413L17.4422 3.62261L18.0782 3.22511Z'
        stroke={color}
        strokeWidth='1.5'
      />
    </svg>
  );
};
