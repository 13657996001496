import { useRefreshContext } from '@va/util/components';
import { useEffect } from 'react';

export const useMutateOnRefresh = (mutate: () => void) => {
  const { isRefreshing, isAutoRefreshing, isManualRefreshing } = useRefreshContext();

  useEffect(() => {
    if (!isRefreshing) return;
    mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshing]);

  useEffect(() => {
    if (!isAutoRefreshing) return;
    mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoRefreshing]);

  return { isRefreshing, isAutoRefreshing, isManualRefreshing };
};
