import { patch, post } from '@va/http-client';
import { getUserId } from '@va/standalone/shared/helpers';
import { getAgencyId } from '@va/standalone/shared/selectors';
import { ThemeType } from '@va/ui/components/white-label-customisation';
import { useAsyncFunction, useFetchData } from '@va/util/hooks';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useGetAllAgencyThemes = () => {
  const userId = getUserId();
  return useFetchData<ThemeType[]>(`/users/${userId}/agencyui/theme/all`);
};

export const useEditTheme = () => {
  const asyncFunc = useCallback((themeId: string, data: Object) => {
    const userId = getUserId();
    return patch(`/users/${userId}/agencyui/theme/${themeId}`, {}, data);
  }, []);
  return useAsyncFunction<typeof asyncFunc>(asyncFunc);
};

export const useCreateNewTheme = () => {
  const userId = getUserId();
  const asyncFunc = useCallback(
    (data: Object) => post<ThemeType>(`/users/${userId}/agencyui/theme`, {}, data),
    [userId],
  );
  return useAsyncFunction(asyncFunc);
};

export const useUpdateAgencyUi = () => {
  const agencyId = useSelector(getAgencyId);
  const asyncFunc = useCallback(
    (data: Object) => {
      const userId = getUserId();
      return patch(`/users/${userId}/agencyui/${agencyId}`, {}, data);
    },
    [agencyId],
  );
  return useAsyncFunction<typeof asyncFunc>(asyncFunc);
};

export const useCreateAgencyUi = () => {
  const asyncFunc = useCallback(async (data: Object) => {
    const userId = getUserId();
    return post(`/users/${userId}/agencyui`, {}, data);
  }, []);
  return useAsyncFunction<typeof asyncFunc>(asyncFunc);
};

export const useSendDummyEmail = () => {
  const agencyId = useSelector(getAgencyId);
  const asyncFunc = useCallback(
    async (email: string) => {
      const userId = getUserId();
      return post(`/users/${userId}/agencyui/${agencyId}/dummy-email`, {}, { emailTo: email });
    },
    [agencyId],
  );
  return useAsyncFunction<typeof asyncFunc>(asyncFunc);
};
