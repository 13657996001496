import { useTranslate } from '@va/localization';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import { FC, PropsWithChildren, useMemo } from 'react';
import './SidebarPopup.scss';
import { SidebarPopupProps } from './propDefinitions';

const SidebarPopup: FC<PropsWithChildren<SidebarPopupProps>> = ({
  children,
  title,
  content,
  showPopup,
  onHeaderClick,
  linkTo: LinkTo,
}) => {
  const translate = useTranslate();

  const renderTooltipContent = useMemo(() => {
    return (
      <div className='w-[260px] bg-white flex flex-col gap-2'>
        <div
          className='text-sm flex items-center justify-center p-2 overflow-hidden bg-gray-100 rounded-[15px] leading-8 capitalize text-primary font-semibold relative'
          onClick={onHeaderClick}
        >
          {title}
          {LinkTo && <LinkTo className='h-full absolute w-full left-0 z-10' translate={translate} />}
        </div>
        {content()}
      </div>
    );
  }, [LinkTo, content, onHeaderClick, title, translate]);

  return (
    <div className='sidebar_popup'>
      <TooltipWrapper
        interactive
        placement='right-start'
        tooltipClassNames={classNames('bg-secondary rounded-12 border-2 border-gray-mercury ml-2.5 opacity-100 ')}
        disabled={!!showPopup}
        content={renderTooltipContent}
        arrowColor='#FFFFFF'
      >
        {children}
      </TooltipWrapper>
    </div>
  );
};

export default SidebarPopup;
