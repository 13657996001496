import classNames from 'classnames';
import PropTypes from 'prop-types';

/** @deprecated */
const OptionsHeader = ({ children, first }) => {
  return (
    <>
      {!first && <div className='border-alto border-b-2' />}
      <div
        className={classNames(
          'bg-alabaster text-gray-faded border-2 border-white px-3 py-2 text-xs leading-normal font-semibold',
          { 'rounded-t-lg': first },
        )}
      >
        {children}
      </div>
      <div className='border-alto border-b-2' />
    </>
  );
};
OptionsHeader.propTypes = {
  first: PropTypes.bool,
  children: PropTypes.node,
};

export default OptionsHeader;
