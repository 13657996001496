import { IconProps } from '.';

export const ColorOptionIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12' cy='12' r='11.25' fill='white' stroke='#C3C3C3' strokeWidth='1.5' />
      <circle cx='12' cy='12' r='8' fill={color} />
    </svg>
  );
};


