import { IconProps } from '.';

export const DisplayResolutionIcon = ({ className, color = 'var(--color-primary)' }: IconProps) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.5 1.5H13.5C15.1569 1.5 16.5 2.84315 16.5 4.5V13.5C16.5 15.1569 15.1569 16.5 13.5 16.5H4.5C2.84315 16.5 1.5 15.1569 1.5 13.5V4.5C1.5 2.84315 2.84315 1.5 4.5 1.5ZM0 4.5C0 2.01472 2.01472 0 4.5 0H13.5C15.9853 0 18 2.01472 18 4.5V13.5C18 15.9853 15.9853 18 13.5 18H4.5C2.01472 18 0 15.9853 0 13.5V4.5ZM11.6893 5.25H9V3.75H13.5H14.25V4.5V9H12.75V6.31066L6.31066 12.75H9V14.25H4.5H3.75V13.5L3.75 9H5.25L5.25 11.6893L11.6893 5.25Z'
        fill={color}
      />
    </svg>
  );
};


