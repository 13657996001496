import { GlobalTimePeriodFilter } from '@va/dashboard/components';
import { ChartVisualizationComponentProps } from '@va/types/charts';
import { useReportBlockCtx } from '@va/ui/components/report-block';
import { ChartTiles, RadarChart } from '@va/ui/design-system';
import { memo } from 'react';
import { useCommonChartViewData } from './shared/useCommonChartViewData';

export const RadarChartViewComponent = memo((props: ChartVisualizationComponentProps) => {
  const { reversePercentageColors, loadingPlaceholdersCount } = props;

  const { chartData, tiles, toggleKpiVisibility, tooltipFn, isLoading, errorComponent } =
    useCommonChartViewData<'radar'>({
      ...props,
      tooltipMode: 'follow-point',
    });
  const { id } = useReportBlockCtx();

  if (errorComponent) return errorComponent;

  return (
    <div className='space-y-3'>
      <ChartTiles
        reversePercentageColors={reversePercentageColors}
        isLoading={isLoading}
        loadingPlaceholdersCount={loadingPlaceholdersCount}
        tiles={tiles}
        onClick={(id) => {
          toggleKpiVisibility(id);
        }}
      />
      <div className='w-full flex min-h-[48px] justify-end'>
        <GlobalTimePeriodFilter />
      </div>
      <RadarChart
        id={`${id}-radar-diagram-canvas`}
        chartData={chartData}
        tooltipFn={tooltipFn}
        isLoading={isLoading}
        wrapperStyle={{ height: '400px' }}
      />
    </div>
  );
});
