import { TooltipWrapper } from '@va/ui/tooltips';
import { useCheckOverflow, useWindowDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import { FC, createRef, memo } from 'react';

type NestedNavigationBackButtonProps = {
  onBackClick: () => void;
  parentPage?: string;
  currentPage: string;
};

export const NestedNavigationBackButton: FC<NestedNavigationBackButtonProps> = memo(
  ({ onBackClick, parentPage, currentPage }) => {
    const wrapperRef = createRef<HTMLDivElement>();
    const isWrapperOverflowing = useCheckOverflow(wrapperRef);
    const { isMobile } = useWindowDimensions();

    return (
      <div className='flex truncate'>
        <TooltipWrapper
          content={parentPage ? `${parentPage} / ${currentPage}` : currentPage}
          ref={wrapperRef}
          disabled={!isWrapperOverflowing}
        >
          <p className={classNames('cursor-pointer sm-initial:inline-block truncate')}>
            {parentPage && !isMobile && (
              <span className='text-gray-dusty color-gray-dusty' onClick={onBackClick}>
                {parentPage}&nbsp;/&nbsp;
              </span>
            )}
            <span className='text-black'>{currentPage}</span>
          </p>
        </TooltipWrapper>
      </div>
    );
  },
);
