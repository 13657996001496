import React from 'react';
import { IconProps } from '.';

export const EyeIconSlashed = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.05026 6.36396C6.78393 3.63029 11.2161 3.63029 13.9497 6.36396L16.5858 9L13.9497 11.636C11.2161 14.3697 6.78392 14.3697 4.05025 11.636L1.41421 9L4.05026 6.36396Z'
        stroke={color}
        strokeWidth='2'
      ></path>
      <circle cx='9' cy='9' r='2' stroke={color} strokeWidth='2'></circle>
      <path d='M7 11L1 17' stroke={color} strokeWidth='2' strokeLinecap='round'></path>
      <path d='M17 1L11 7' stroke={color} strokeWidth='2' strokeLinecap='round'></path>
    </svg>
  );
};


