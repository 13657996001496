import { NavSearchIconLeft, NavSearchIconRight } from '@va/icons';
import { useTranslate } from '@va/localization';
import { SearchInput } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import { FunctionComponent, memo } from 'react';
import { SidebarHeaderProps } from './propDefinitions';

const SidebarHeader: FunctionComponent<SidebarHeaderProps> = ({ isExpanded, searchItem, handleSearch, onToggle }) => {
  const translate = useTranslate();
  return (
    <div
      className={classNames('py-4 text-sm flex justify-center w-full z-20 px-2 lg:px-18px bg-secondary', {
        'sticky top-0': isExpanded,
      })}
    >
      {isExpanded && (
        <div className='lg:mr-3 w-full'>
          <SearchInput
            placeholder={translate('filter.searchByFeatureName')}
            value={searchItem}
            onChange={handleSearch.bind(this)}
          />
        </div>
      )}
      <div
        className={classNames('bg-white-snow hover:bg-gray-mercury rounded-[15px] hidden lg:block z-1', {
          isOpen: isExpanded,
        })}
        onClick={onToggle.bind(this, !isExpanded)}
      >
        <TooltipWrapper
          placement='right'
          tooltipClassNames='ml-1 text-13 font-inter extra-padding'
          content={translate(`nav.${!isExpanded ? 'expandMenu' : 'collapseMenu'}`)}
        >
          <span>
            {!isExpanded ? (
              <NavSearchIconRight className='arrow-icon cursor-pointer' />
            ) : (
              <NavSearchIconLeft className='arrow-icon cursor-pointer' />
            )}
          </span>
        </TooltipWrapper>
      </div>
    </div>
  );
};

export default memo(SidebarHeader);
