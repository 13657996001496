import React from 'react';

export const PlusIcon: React.FC<{ color?: string; className?: string }> = ({ color = '#666666', className }) => {
  return (
    <svg
      className={className}
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.25 18C34.25 26.6985 27.1985 33.75 18.5 33.75C9.80152 33.75 2.75 26.6985 2.75 18C2.75 9.30152 9.80152 2.25 18.5 2.25C27.1985 2.25 34.25 9.30152 34.25 18ZM36.5 18C36.5 27.9411 28.4411 36 18.5 36C8.55887 36 0.5 27.9411 0.5 18C0.5 8.05887 8.55887 0 18.5 0C28.4411 0 36.5 8.05887 36.5 18ZM16.25 11.25C16.25 10.0074 17.2574 9 18.5 9C19.7426 9 20.75 10.0074 20.75 11.25V15.75H25.25C26.4926 15.75 27.5 16.7574 27.5 18C27.5 19.2426 26.4926 20.25 25.25 20.25H20.75V24.75C20.75 25.9926 19.7426 27 18.5 27C17.2574 27 16.25 25.9926 16.25 24.75V20.25H11.75C10.5074 20.25 9.5 19.2426 9.5 18C9.5 16.7574 10.5074 15.75 11.75 15.75H16.25V11.25Z'
        fill={color ?? 'var(--color-primary)'}
      />
    </svg>
  );
};
