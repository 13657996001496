import { makeAction } from '@va/store';
import { coordinates } from '@va/util/misc';
import { VISITOR_FAILED, VISITOR_REQUEST, VISITOR_SUCCEEDED } from './visits';

export const GET_LATEST_VISITORS_OSS = 'API_GET_LATEST_VISITORS_OSS';
export const GET_LATEST_VISITORS_OSS_SUCCEEDED = 'API_GET_LATEST_VISITORS_OSS_SUCCEEDED';
export const GET_LATEST_VISITORS_OSS_FAILED = 'API_GET_LATEST_VISITORS_OSS_FAILED';

export const GET_LATEST_VISITORS_BROWSERS = 'API_GET_LATEST_VISITORS_BROWSERS';
export const GET_LATEST_VISITORS_BROWSERS_SUCCEEDED = 'API_GET_LATEST_VISITORS_BROWSERS_SUCCEEDED';
export const GET_LATEST_VISITORS_BROWSERS_FAILED = 'API_GET_LATEST_VISITORS_BROWSERS_FAILED';

export const LATEST_VISITORS_MAP_REQUEST = 'LATEST_VISITORS_MAP_REQUEST';
export const LATEST_VISITORS_MAP_SUCCEEDED = 'LATEST_VISITORS_MAP_SUCCEEDED';
export const LATEST_VISITORS_MAP_FAILED = 'LATEST_VISITORS_MAP_FAILED';

export const EXPORT_VISITORS_REQUEST = 'EXPORT_VISITORS_REQUEST';
export const EXPORT_VISITORS_SUCCEEDED = 'EXPORT_VISITORS_SUCCEEDED';
export const EXPORT_VISITORS_FAILED = 'EXPORT_VISITORS_FAILED';

export const EXPORT_VISITORS_PAGE_REQUEST = 'EXPORT_VISITORS_PAGE_REQUEST';
export const EXPORT_VISITORS_PAGE_SUCCEEDED = 'EXPORT_VISITORS_PAGE_SUCCEEDED';
export const EXPORT_VISITORS_PAGE_FAILED = 'EXPORT_VISITORS_PAGE_FAILED';

export const EXPORT_VISITORS_CANCEL = 'EXPORT_VISITORS_CANCEL';

export const getLatestVisitorsOss = makeAction(GET_LATEST_VISITORS_OSS);
export const getLatestVisitorsOssSucceeded = makeAction(GET_LATEST_VISITORS_OSS_SUCCEEDED, 'data');
export const getLatestVisitorsOssFailed = makeAction(GET_LATEST_VISITORS_OSS_FAILED, 'error');

export const getLatestVisitorsBrowsers = makeAction(GET_LATEST_VISITORS_BROWSERS);
export const getLatestVisitorsBrowsersSucceeded = makeAction(GET_LATEST_VISITORS_BROWSERS_SUCCEEDED, 'data');
export const getLatestVisitorsBrowsersFailed = makeAction(GET_LATEST_VISITORS_BROWSERS_FAILED, 'error');

export const requestVisitorInformation = makeAction(VISITOR_REQUEST, 'id');
// TODO Update types
export const requestVisitorInformationSucceeded = (
  data: { deviceType: any; browserName: any; pagesVisited: string },
  sessionKey: undefined,
) => ({
  type: VISITOR_SUCCEEDED,
  sessionKey: sessionKey,
  data: {
    information: {
      ...data,
      device: data.deviceType,
      browser: data.browserName,
      pagesVisited: parseInt(data.pagesVisited),
    },
  },
});
export const requestVisitorInformationFailed = makeAction(VISITOR_FAILED, 'error', 'id');

export const requestLatestVisitorsMap = makeAction(LATEST_VISITORS_MAP_REQUEST, 'filter');
// TODO Update types
export const requestLatestVisitorsMapSucceeded = (data: any, filter: any) => ({
  type: LATEST_VISITORS_MAP_SUCCEEDED,
  data: { items: coordinates.getItemsInCircle(data) },
  filter: filter,
});
export const requestLatestVisitorsMapFailed = makeAction(LATEST_VISITORS_MAP_FAILED, 'error', 'filter');

export const exportVisitors = makeAction(EXPORT_VISITORS_REQUEST, 'filter', 'format', 'translate');
export const exportVisitorsSucceeded = makeAction(EXPORT_VISITORS_SUCCEEDED, 'data');
export const exportVisitorsFailed = makeAction(EXPORT_VISITORS_FAILED, 'error');

export const exportVisitorsPage = makeAction(EXPORT_VISITORS_PAGE_REQUEST, 'filter');
export const exportVisitorsPageSucceeded = makeAction(EXPORT_VISITORS_PAGE_SUCCEEDED, 'data');
export const exportVisitorsPageFailed = makeAction(EXPORT_VISITORS_PAGE_FAILED, 'error');

export const exportVisitorsCancel = makeAction(EXPORT_VISITORS_CANCEL);
