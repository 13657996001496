export const VideoIcon = () => {
  return (
    <svg width='60' height='61' viewBox='0 0 60 61' fill='black' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M57 30.5C57 45.4117 44.9117 57.5 30 57.5C15.0883 57.5 3 45.4117 3 30.5C3 15.5883 15.0883 3.5 30 3.5C44.9117 3.5 57 15.5883 57 30.5ZM60 30.5C60 47.0685 46.5685 60.5 30 60.5C13.4315 60.5 0 47.0685 0 30.5C0 13.9315 13.4315 0.5 30 0.5C46.5685 0.5 60 13.9315 60 30.5ZM42 28.7679L45 30.5L42 32.232L25.5 41.7583L22.5 43.4904V40.0263L22.5 20.9737V17.5096L25.5 19.2417L42 28.7679ZM25.5 22.7058L39 30.5L25.5 38.2942L25.5 22.7058Z'
        fill=''
      />
    </svg>
  );
};
