import React from 'react';
import PropTypes from 'prop-types';

export const PlusIconFilled = ({ color = '#F7761B', ...props }) => {
  return (
    <svg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.5 15.5C11.6421 15.5 15 12.1421 15 8C15 3.85786 11.6421 0.5 7.5 0.5C3.35786 0.5 0 3.85786 0 8C0 12.1421 3.35786 15.5 7.5 15.5ZM6.5 7V3.5H8.5V7H12V9H8.5V12.5H6.5V9H3V7L6.5 7Z'
        fill={color}
      />
    </svg>
  );
};

PlusIconFilled.propTypes = {
  color: PropTypes.string,
};
