import { getAccountInformation } from '@va/dashboard/selectors/api';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { RouterWarningModal } from '@va/shared/util-confirmation';
import { getAgencyUiSucceeded } from '@va/standalone/shared/actions';
import {
  ThemeColorsType,
  ThemeComponent,
  ThemePreviewModal,
  ThemeType,
} from '@va/ui/components/white-label-customisation';
import { colorNameToHex, getInitialsFromUserInfo, isValidCssColorName } from '@va/util/helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetAllAgencyThemes, useUpdateAgencyUi } from '../apiClient';
import useAgencyUi from '../useAgencyUi';
import CreateCustomThemeModal from './CreateCustomThemeModal';
import EditCustomThemeModal from './EditCustomThemeModal';

const ThemeSectionContainer = () => {
  const [isThemePreviewModalOpen, setIsThemePreviewModalOpen] = useState(false);
  const [isEditCustomThemeModalOpen, setIsEditCustomThemeModalOpen] = useState(false);
  const [isCreateCustomThemeModalOpen, setIsCreateCustomThemeModalOpen] = useState(false);
  const [selectedThemeId, setSelectedThemeId] = useState<string | undefined>(undefined);

  const { data: themes = [], isLoading: isLoadingGetAllThemes } = useGetAllAgencyThemes();

  const { showSuccessNotification, showErrorNotification } = useAddNotification();

  const updateAgencyUi = useUpdateAgencyUi();

  const agencyUi: { theme: ThemeType; id: string } | undefined = useAgencyUi();

  const closeThemePreviewModal = useCallback(() => {
    setIsThemePreviewModalOpen(false);
  }, []);

  const dispatch = useDispatch();
  const translate = useTranslate();
  const selectedTheme = useMemo(() => themes.find((theme) => theme.id === selectedThemeId), [selectedThemeId, themes]);
  const customTheme = useMemo(() => themes.find((theme) => theme.name.toLowerCase() === 'custom'), [themes]);
  const hasMadeChanges = useMemo(() => {
    if (!agencyUi?.theme && selectedTheme?.name.toLowerCase() === 'default') return false;
    return agencyUi?.theme?.id !== selectedTheme?.id;
  }, [agencyUi?.theme?.id, selectedTheme]);

  // Populating the initial state from redux and any time the redux store changes
  useEffect(() => {
    if (selectedThemeId) return;
    if (!agencyUi?.theme) {
      setSelectedThemeId(themes.find((t) => t.name.toLowerCase() === 'default')?.id);
      return;
    }
    setSelectedThemeId(agencyUi?.theme?.id);
  }, [agencyUi?.theme, selectedThemeId, themes]);

  // Updating the redux store with the updated data
  useEffect(() => {
    if (!updateAgencyUi.isSucceeded) return;
    dispatch(getAgencyUiSucceeded({ data: updateAgencyUi.data }));
    showSuccessNotification();
    updateAgencyUi.reset();
  }, [dispatch, showSuccessNotification, updateAgencyUi.data, updateAgencyUi.isSucceeded, updateAgencyUi]);

  useEffect(() => {
    if (updateAgencyUi.error) {
      showErrorNotification();
      updateAgencyUi.reset();
    }
  }, [showErrorNotification, updateAgencyUi]);

  const updateSelectedThemeId = useCallback((themeId: string) => {
    setSelectedThemeId(themeId);
  }, []);

  const userInformation = useSelector(getAccountInformation);
  const userInitials = useMemo(() => getInitialsFromUserInfo(userInformation), [userInformation]);

  return (
    <>
      <RouterWarningModal when={hasMadeChanges} />
      {selectedTheme && isThemePreviewModalOpen && (
        <ThemePreviewModal
          userMenuText={userInitials}
          themeName={selectedTheme.name}
          colors={selectedTheme.colors}
          isModalOpen={isThemePreviewModalOpen}
          closeModal={closeThemePreviewModal}
        />
      )}
      {customTheme && (
        <EditCustomThemeModal
          closeModal={() => {
            setIsEditCustomThemeModalOpen(false);
          }}
          isOpen={isEditCustomThemeModalOpen}
          themeColors={customTheme.colors}
          themeId={customTheme.id}
          themeName={customTheme.name}
        />
      )}

      {!customTheme && (
        <CreateCustomThemeModal
          updateSelectedThemeId={updateSelectedThemeId}
          isOpen={isCreateCustomThemeModalOpen}
          closeModal={() => {
            setIsCreateCustomThemeModalOpen(false);
          }}
        />
      )}
      <ThemeComponent
        updateSelectedThemeId={updateSelectedThemeId}
        selectedThemeId={selectedThemeId}
        onSave={(selectedThemeId) => {
          updateAgencyUi.execute({ themeId: selectedThemeId });
        }}
        onEditClick={() => {
          if (customTheme) {
            setIsEditCustomThemeModalOpen(true);
          } else {
            setIsCreateCustomThemeModalOpen(true);
          }
        }}
        onPreviewClick={() => {
          setIsThemePreviewModalOpen(true);
        }}
        themes={themes}
        isLoadingThemes={isLoadingGetAllThemes}
        saveButtonProps={{
          tooltip: !agencyUi?.id ? translate('whiteLabelCustomization.info.firstFillCustomUrl') : undefined,
          disabled: updateAgencyUi.isLoading || !selectedThemeId || !agencyUi?.id || !hasMadeChanges,
        }}
        editButtonProps={{
          disabled: !agencyUi?.id,
          text: customTheme
            ? translate('whiteLabel.settings.theme.editCustom')
            : translate('whiteLabel.settings.theme.createCustom'),
        }}
      />
    </>
  );
};

export default ThemeSectionContainer;

export function formatThemeColors(colors: ThemeColorsType) {
  return Object.entries(colors).reduce((acc, [key, color]) => {
    return {
      ...acc,
      [key]: isValidCssColorName(color) ? colorNameToHex(color) : color,
    };
  }, {});
}
