export const GoogleIcon = (props: JSX.IntrinsicElements['svg']) => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='Google Logo'>
        <path
          id='Google Logo_2'
          d='M17.8238 9.20706C17.8238 8.59529 17.7742 7.98021 17.6684 7.37836H9.17963V10.844H14.0407C13.839 11.9617 13.1909 12.9505 12.2418 13.5788V15.8274H15.1419C16.845 14.26 17.8238 11.9452 17.8238 9.20706Z'
          fill='#4285F4'
        />
        <path
          id='Google Logo_3'
          d='M9.17962 18C11.6069 18 13.6538 17.203 15.1452 15.8274L12.2451 13.5787C11.4382 14.1276 10.3965 14.4385 9.18292 14.4385C6.83503 14.4385 4.84429 12.8545 4.13 10.7249H1.13727V13.043C2.66505 16.082 5.77683 18 9.17962 18Z'
          fill='#34A853'
        />
        <path
          id='Google Logo_4'
          d='M4.12676 10.7249C3.74978 9.60717 3.74978 8.39685 4.12676 7.27913V4.961H1.13734C-0.139122 7.50399 -0.139122 10.5 1.13734 13.043L4.12676 10.7249Z'
          fill='#FBBC04'
        />
        <path
          id='Google Logo_5'
          d='M9.17961 3.56212C10.4627 3.54228 11.7028 4.02509 12.632 4.91133L15.2015 2.34188C13.5745 0.8141 11.4151 -0.0258486 9.17961 0.000606478C5.77683 0.000606478 2.66505 1.9186 1.13727 4.96093L4.12669 7.27906C4.83767 5.14612 6.83173 3.56212 9.17961 3.56212Z'
          fill='#EA4335'
        />
      </g>
    </svg>
  );
};
