import { GREEN_COLOR } from './constants';

export function ButtonLoader(rawProps) {
  const { color, ...props } = rawProps;
  const logoColor = color || GREEN_COLOR;
  return (
    <svg
      version='1.1'
      id='loader-1'
      x='0px'
      y='0px'
      width='20px'
      height='20px'
      viewBox='0 0 40 40'
      enableBackground='new 0 0 40 40'
      {...props}
    >
      <path
        opacity='0.2'
        fill={logoColor}
        d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'
      />
      <path
        fill={logoColor}
        d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
    C22.32,8.481,24.301,9.057,26.013,10.047z'
      >
        <animateTransform
          attributeType='xml'
          attributeName='transform'
          type='rotate'
          from='0 20 20'
          to='360 20 20'
          dur='0.5s'
          repeatCount='indefinite'
        />
      </path>
    </svg>
  );
}

export function ButtonCheckmark(rawProps) {
  const { color, ...props } = rawProps;
  const checkmarkColor = color || GREEN_COLOR;
  return (
    <svg
      version='1.1'
      id='Layer_1'
      x='0px'
      y='0px'
      height='20px'
      width='20px'
      viewBox='0 0 20 20'
      enableBackground='new 0 20 20'
    >
      <polygon fill={checkmarkColor} points='0.7,9.4 1.9,8.2 7,13.3 17.8,2.6 19,3.8 7,15.7 ' />
    </svg>
  );
}

export function ButtonFailedIcon(rawProps) {
  const { color, ...props } = rawProps;
  const iconColor = color ? color : '#D1555A';
  return (
    <svg
      version='1.1'
      id='Layer_1'
      x='0px'
      y='0px'
      height='20px'
      width='20px'
      viewBox='0 0 20 20'
      enableBackground='new 0 20 20'
      {...props}
    >
      <polygon
        fill={iconColor}
        points='18.8,2.9 17.7,1.7 9.9,8.9 2.1,1.7 1,2.9 8.7,10 1,17 2.1,18.2 9.9,11.1 17.7,18.2 18.8,17 11.1,10 '
      />
    </svg>
  );
}

export function SeeMore(props) {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 16 16'
      xmlSpace='preserve'
      {...props}
    >
      <g>
        <circle fill='#FFFFFF' cx='7.8' cy='7.8' r='7' />
        <path
          fill='#13232A'
          d='M7.8,1.2c3.6,0,6.5,2.9,6.5,6.5s-2.9,6.5-6.5,6.5s-6.5-2.9-6.5-6.5S4.2,1.2,7.8,1.2 M7.8,0.2
        c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5S11.9,0.2,7.8,0.2L7.8,0.2z'
        />
      </g>
      <g>
        <g>
          <circle fill='#13232A' cx='3.8' cy='7.8' r='1' />
        </g>
        <g>
          <circle fill='#13232A' cx='11.8' cy='7.8' r='1' />
        </g>
        <g>
          <circle fill='#13232A' cx='7.8' cy='7.8' r='1' />
        </g>
      </g>
    </svg>
  );
}

export function SeeMoreArrow({ className, color = '#5C04B4' }) {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 22 13'
      xmlSpace='preserve'
      className={className}
    >
      <polygon fill={color} points='16.3,12.2 15.7,11.7 20.4,7 0,7 0,6.1 20.4,6.1 15.7,1.4 16.3,0.8 22,6.5 ' />
    </svg>
  );
}

export function SeeMoreHorizontally(props) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='21' cy='12' r='3' fill='#999999' />
      <circle cx='12' cy='12' r='3' fill='#999999' />
      <circle cx='3' cy='12' r='3' fill='#999999' />
    </svg>
  );
}
