import React from 'react';

export function WixClaimStep1(props) {
  return <svg width="56px" height="45px" viewBox="0 0 56 45" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <g id="symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="modal/claim-card/input-email" transform="translate(-401.000000, -396.000000)">
        <g id="Group" transform="translate(401.000000, 396.000000)">
          <path d="M30.217,17.3332 L55.253,17.3332 L55.253,0.0012 L30.217,0.0012 L30.217,17.3332 Z M31.217,16.3322 L54.253,16.3322 L54.253,1.0002 L31.217,1.0002 L31.217,16.3322 Z" id="Fill-1" fill="#009688"/>
          <path d="M30.2166,0.0006 L30.2166,0.9626 L42.7356,13.4806 L55.2526,0.9626 L55.2526,0.0006 L30.2166,0.0006 Z M31.5796,0.9626 L53.8906,0.9626 L42.7356,12.1186 L31.5796,0.9626 Z" id="Fill-2" fill="#009688"/>
          <polygon id="Fill-3" fill="#000000" points="8.717 44.1132 33.125 44.1132 33.125 43.1132 8.717 43.1132"/>
          <polygon id="Fill-4" fill="#000000" points="16.061 43.6142 17.061 43.6142 17.061 34.4162 16.061 34.4162"/>
          <polygon id="Fill-5" fill="#000000" points="24.779 43.6142 25.78 43.6142 25.78 34.4162 24.779 34.4162"/>
          <polygon id="Fill-6" fill="#13232A" points="0 5.8992 0 35.3352 41.843 35.3352 41.843 19.3182 40.843 19.3182 40.843 34.3352 1 34.3352 1 6.9002 28.711 6.9002 28.711 5.8992"/>
        </g>
      </g>
    </g>
  </svg>;
}

export function WixClaimStep2(props) {
  return <svg width="57px" height="47px" viewBox="0 0 57 47" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <g id="symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="modal/claim-card/input-email" transform="translate(-401.000000, -514.000000)">
        <g id="Group" transform="translate(401.000000, 514.000000)">
          <polygon id="Fill-6" fill="#009688" points="40.1276 17.561 29.7736 7.207 30.4816 6.5 40.1276 16.147 56.2736 0 56.9816 0.707"/>
          <path d="M21.6891,46.4692 C9.7291,46.4692 0.0001,36.7402 0.0001,24.7802 C0.0001,12.8212 9.7291,3.0922 21.6891,3.0922 C23.5721,3.0922 25.4871,3.3542 27.3821,3.8712 L27.3821,5.1882 C25.5311,4.6492 23.6171,4.3752 21.6891,4.3752 C10.4371,4.3752 1.2841,13.5282 1.2841,24.7802 C1.2841,36.0312 10.4371,45.1862 21.6891,45.1862 C32.9411,45.1862 42.0941,36.0312 42.0941,24.7802 C42.0941,23.4492 41.9601,22.1042 41.6921,20.7722 L42.9981,20.7722 C43.2501,22.1122 43.3771,23.4592 43.3771,24.7802 C43.3771,36.7402 33.6481,46.4692 21.6891,46.4692" id="Fill-4" fill="#13232A"/>
          <path d="M9.217,33.3332 L34.253,33.3332 L34.253,16.0012 L9.217,16.0012 L9.217,33.3332 Z M10.217,32.3322 L33.253,32.3322 L33.253,17.0002 L10.217,17.0002 L10.217,32.3322 Z" id="Fill-1" fill="#1E2E35"/>
          <path d="M9.2166,16.0006 L9.2166,16.9626 L21.7356,29.4806 L34.2526,16.9626 L34.2526,16.0006 L9.2166,16.0006 Z M10.5796,16.9626 L32.8906,16.9626 L21.7356,28.1186 L10.5796,16.9626 Z" id="Fill-2" fill="#1E2E35"/>
        </g>
      </g>
    </g>
  </svg>
}

export function WixClaimStep3(props) {
  return <svg width="56px" height="42px" viewBox="0 0 56 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <g id="symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="modal/claim-card/input-email" transform="translate(-401.000000, -634.000000)">
        <g id="Group" transform="translate(401.000000, 634.000000)">
          <path d="M0,41.2805 L17.886,41.2805 L17.886,6.5305 L0,6.5305 L0,41.2805 Z M1.001,40.2805 L16.886,40.2805 L16.886,7.5305 L1.001,7.5305 L1.001,40.2805 Z" id="Fill-1" fill="#009688"/>
          <path d="M8.7318,37.1563 C8.7318,37.0143 8.8468,36.8923 8.9408,36.8923 C9.0368,36.8923 9.1528,37.0143 9.1528,37.1563 C9.1528,37.3873 8.7318,37.3873 8.7318,37.1563 M8.9428,35.7413 C8.1628,35.7413 7.5278,36.3763 7.5278,37.1563 C7.5278,37.9343 8.1628,38.5693 8.9428,38.5693 C9.7218,38.5693 10.3568,37.9343 10.3568,37.1563 C10.3568,36.3763 9.7218,35.7413 8.9428,35.7413" id="Fill-2" fill="#009688"/>
          <polygon id="Fill-4" fill="#13232A" points="18.399 40.8095 45.586 40.8095 45.586 39.8095 18.399 39.8095"/>
          <polygon id="Fill-6" fill="#13232A" points="26.638 40.3095 27.638 40.3095 27.638 33.6315 26.638 33.6315"/>
          <polygon id="Fill-7" fill="#13232A" points="36.347 40.3095 37.347 40.3095 37.347 33.6315 36.347 33.6315"/>
          <path d="M32.5062,29.8106 C32.5062,30.6576 31.8192,31.3436 30.9732,31.3436 C30.1262,31.3436 29.4402,30.6576 29.4402,29.8106 C29.4402,28.9636 30.1262,28.2776 30.9732,28.2776 C31.8192,28.2776 32.5062,28.9636 32.5062,29.8106" id="Fill-8" fill="#13232A"/>
          <polygon id="Fill-9" fill="#13232A" points="8.1898 0 8.1898 4.825 9.1898 4.825 9.1898 1 54.7958 1 54.7958 33.132 19.3058 33.132 19.3058 34.132 55.7958 34.132 55.7958 0"/>
          <polygon id="Fill-10" fill="#13232A" points="19.332 24.9845 54.796 24.9845 54.796 23.9845 19.332 23.9845"/>
          <polygon id="Fill-11" fill="#009688" points="0.682 10.6375 17.196 10.6375 17.196 9.6375 0.682 9.6375"/>
          <polygon id="Fill-12" fill="#009688" points="0.001 34.6905 17.196 34.6905 17.196 33.6905 0.001 33.6905"/>
        </g>
      </g>
    </g>
  </svg>
}
