import { DataViewOption } from '@va/constants';
import {
  isMediumDevice as isMediumDeviceSelector,
  isTabletDevice as isTabletDeviceSelector,
} from '@va/dashboard/selectors/ui';
import { useTranslate } from '@va/localization';
import { DashboardCard, LargeTitle } from '@va/ui/design-system';
import classNames from 'classnames';
import { PropsWithChildren, forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';

type ExpandableCardProps = {
  title: string;
  isLoading: boolean;
  isExpanded: boolean;
  changeExpandedCard: (id: string) => void;
  percent: number;
  reversePercentageColors?: boolean;
  total: string;
  id: string;
  gridPosition: number;
  tooltipText: string;
};

const ExpandableCard = forwardRef<HTMLDivElement, PropsWithChildren<ExpandableCardProps>>(
  (
    {
      children,
      title,
      percent,
      total,
      isExpanded,
      tooltipText,
      changeExpandedCard,
      id,
      isLoading,
      gridPosition,
      reversePercentageColors = false,
    },
    ref,
  ) => {
    const translate = useTranslate();
    const [showContent, setShowContent] = useState(true);

    const isMediumDevice = useSelector((state) => isMediumDeviceSelector(state));
    const isTabletDevice = useSelector((state) => isTabletDeviceSelector(state));

    const getGridRowStyle = () => {
      if (!isExpanded || isMediumDevice) return 'auto';
      if (isTabletDevice) {
        if (gridPosition % 2 !== 0) return `${Math.ceil(gridPosition / 2)}/span 2`;
        return 'auto';
      }
      return '1/3';
    };

    const getGridColumnStyle = () => {
      if (!isExpanded) return 'auto';
      if (isMediumDevice) return 'auto/2';
      if (isTabletDevice) return 'auto/span 2';
      return '1/3';
    };

    return (
      <DashboardCard
        ref={ref}
        isLoading={isLoading}
        placeholdersCount={isExpanded ? 7 : 2}
        title={
          <LargeTitle
            isLoading={isLoading}
            title={title}
            heading={total}
            percentageChange={percent}
            reversePercentageColors={reversePercentageColors}
            hideSeparator
            tooltip={tooltipText}
            visualization={{ selectedView: DataViewOption.lineDiagram }}
          />
        }
        className={classNames('rounded-30 overflow-hidden')}
        style={{
          gridRow: getGridRowStyle(),
          gridColumn: getGridColumnStyle(),
          height: isExpanded ? '720px' : '360px',
        }}
        seeMoreButtonLabel={isExpanded ? translate('button.collapse') : translate('button.expand')}
        onSeeMoreButtonClick={() => {
          if (isExpanded) {
            changeExpandedCard('');
          } else {
            // Prevent content flickering on resize
            setShowContent(false);
            changeExpandedCard(id);
            setTimeout(() => {
              setShowContent(true);
            }, 1);
          }
        }}
      >
        <div className={classNames('mb-4 px-3 w-full grow overflow-hidden', !showContent && 'opacity-0')}>
          {children}
        </div>
      </DashboardCard>
    );
  },
);

export default ExpandableCard;
