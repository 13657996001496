import { IconProps } from '.';

export const PaypalIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width='98'
      height='24'
      viewBox='0 0 98 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>PayPal</title>
      <path
        d='M36.1336 5.3642H30.6688C30.3185 5.3642 29.9682 5.64444 29.8981 5.99475L27.7262 20.007C27.6562 20.2872 27.8663 20.4974 28.1466 20.4974H30.7389C31.0892 20.4974 31.4395 20.2172 31.5095 19.8668L32.07 16.0835C32.1401 15.7332 32.4203 15.453 32.8407 15.453H34.5922C38.1653 15.453 40.2672 13.7015 40.8277 10.2685C41.0378 8.79719 40.8277 7.60615 40.127 6.76542C39.3564 5.85462 37.9551 5.3642 36.1336 5.3642ZM36.7641 10.4787C36.4839 12.4404 34.9425 12.4404 33.5413 12.4404H32.7006L33.2611 8.79719C33.2611 8.58701 33.4712 8.44689 33.6814 8.44689H34.0317C35.0126 8.44689 35.9234 8.44689 36.4138 9.00737C36.7641 9.28762 36.9042 9.77805 36.7641 10.4787Z'
        fill='#003087'
      ></path>
      <path
        d='M52.3881 10.408H49.7958C49.5856 10.408 49.3755 10.5482 49.3755 10.7584L49.2353 11.459L49.0252 11.1787C48.4647 10.338 47.2036 10.0577 45.9425 10.0577C43.07 10.0577 40.6178 12.2296 40.1274 15.3123C39.8472 16.8537 40.1975 18.3249 41.1083 19.3058C41.8789 20.2166 43.07 20.637 44.4011 20.637C46.7131 20.637 48.0443 19.1657 48.0443 19.1657L47.9042 19.8663C47.8341 20.1465 48.0443 20.4268 48.3245 20.4268H50.7066C51.0569 20.4268 51.4072 20.1465 51.4773 19.7962L52.8785 10.8284C52.9486 10.6883 52.6683 10.408 52.3881 10.408ZM48.815 15.4524C48.5347 16.9237 47.4138 17.9746 45.8724 17.9746C45.1017 17.9746 44.5412 17.7645 44.1209 17.274C43.7005 16.7836 43.5604 16.1531 43.7005 15.4524C43.9107 13.9812 45.1718 12.9302 46.6431 12.9302C47.4138 12.9302 47.9742 13.2105 48.3946 13.6309C48.7449 14.1213 48.885 14.7518 48.815 15.4524Z'
        fill='#003087'
      ></path>
      <path
        d='M66.3298 10.4081H63.7376C63.4573 10.4081 63.2472 10.5483 63.107 10.7584L59.4639 16.0831L57.9225 10.9686C57.8524 10.6183 57.5021 10.4081 57.2219 10.4081H54.6296C54.3494 10.4081 54.0691 10.6884 54.2093 11.0387L57.0818 19.5161L54.3494 23.2994C54.1392 23.5796 54.3494 24 54.6997 24H57.292C57.5722 24 57.7824 23.8599 57.9225 23.6497L66.6801 11.0387C66.8903 10.8285 66.6801 10.4081 66.3298 10.4081Z'
        fill='#003087'
      ></path>
      <path
        d='M75.0173 5.36401H69.5526C69.2023 5.36401 68.852 5.64426 68.7819 5.99456L66.61 19.9367C66.54 20.217 66.7501 20.4272 67.0304 20.4272H69.8328C70.1131 20.4272 70.3233 20.217 70.3233 20.0068L70.9538 16.0133C71.0239 15.663 71.3041 15.3828 71.7245 15.3828H73.476C77.0491 15.3828 79.1509 13.6312 79.7114 10.1982C79.9216 8.72695 79.7114 7.53591 79.0108 6.69517C78.1701 5.85444 76.8389 5.36401 75.0173 5.36401ZM75.6479 10.4785C75.3677 12.4402 73.8263 12.4402 72.4251 12.4402H71.5844L72.1448 8.79701C72.1448 8.58682 72.355 8.4467 72.5652 8.4467H72.9155C73.8964 8.4467 74.8072 8.4467 75.2976 9.00719C75.6479 9.28744 75.718 9.77786 75.6479 10.4785Z'
        fill='#00AAEE'
      ></path>
      <path
        d='M91.2721 10.408H88.6799C88.4697 10.408 88.2595 10.5482 88.2595 10.7584L88.1194 11.459L87.9092 11.1787C87.3487 10.338 86.0876 10.0577 84.8265 10.0577C81.954 10.0577 79.5019 12.2296 79.0114 15.3123C78.7312 16.8537 79.0815 18.3249 79.9923 19.3058C80.763 20.2166 81.954 20.637 83.2852 20.637C85.5972 20.637 86.9283 19.1657 86.9283 19.1657L86.7882 19.8663C86.7182 20.1465 86.9283 20.4268 87.2086 20.4268H89.5907C89.941 20.4268 90.2913 20.1465 90.3613 19.7962L91.7626 10.8284C91.7626 10.6883 91.5524 10.408 91.2721 10.408ZM87.6289 15.4524C87.3487 16.9237 86.2277 17.9746 84.6864 17.9746C83.9157 17.9746 83.3552 17.7645 82.9349 17.274C82.5145 16.7836 82.3744 16.1531 82.5145 15.4524C82.7247 13.9812 83.9858 12.9302 85.4571 12.9302C86.2277 12.9302 86.7882 13.2105 87.2086 13.6309C87.6289 14.1213 87.7691 14.7518 87.6289 15.4524Z'
        fill='#00AAEE'
      ></path>
      <path
        d='M94.3544 5.71432L92.1125 19.9367C92.0424 20.217 92.2526 20.4272 92.5328 20.4272H94.7748C95.1251 20.4272 95.4754 20.1469 95.5455 19.7966L97.7874 5.85444C97.8575 5.5742 97.6473 5.36401 97.367 5.36401H94.8448C94.5646 5.36401 94.4245 5.50414 94.3544 5.71432Z'
        fill='#00AAEE'
      ></path>
      <path
        d='M17.0925 6.0277C17.3371 4.19318 17.0925 2.97017 16.1114 1.83452C15.0474 0.611505 13.1666 0 10.7957 0H3.84375C3.43491 0 2.94484 0.43679 2.86185 0.873579L0 20.267C0 20.6165 0.245476 21.0533 0.654311 21.0533H4.90689L4.66142 22.9751C4.5793 23.3246 4.82478 23.5866 5.1515 23.5866H8.75064C9.16035 23.5866 9.56831 23.3246 9.65043 22.8878V22.6257L10.386 18.0831V17.9084C10.4681 17.4716 10.8769 17.1221 11.2858 17.1221H11.7776C15.2929 17.1221 17.9922 15.6371 18.8099 11.2692C19.2187 9.43465 19.0563 7.94957 18.0744 6.90127C17.8289 6.55184 17.4192 6.28977 17.0925 6.0277Z'
        fill='#00AAEE'
      ></path>
      <path
        d='M17.0924 6.0277C17.337 4.19318 17.0924 2.97017 16.1114 1.83452C15.0474 0.611506 13.1666 0 10.7957 0H3.84375C3.43491 0 2.94483 0.43679 2.86184 0.87358L0 20.267C0 20.6165 0.245476 21.0533 0.654311 21.0533H4.90689L6.05215 13.8026L5.97004 14.0646C6.05215 13.5405 6.46099 13.1911 6.95107 13.1911H8.99524C13.0032 13.1911 16.1114 11.4439 17.0924 6.55185C17.0112 6.28977 17.0924 6.20241 17.0924 6.0277Z'
        fill='#012169'
      ></path>
      <path
        d='M7.11617 6.0277C7.19654 5.76563 7.36078 5.50355 7.60625 5.32884C7.76874 5.32884 7.85085 5.24148 8.01421 5.24148H13.4121C14.0672 5.24148 14.7198 5.32884 15.2125 5.41619C15.375 5.41619 15.5392 5.41619 15.7026 5.50355C15.8659 5.59091 16.0293 5.59091 16.1114 5.67827C16.1927 5.67827 16.2739 5.67827 16.3569 5.67827C16.6015 5.76563 16.847 5.94034 17.0924 6.0277C17.337 4.19318 17.0924 2.97017 16.1114 1.74716C15.1295 0.524148 13.2496 0 10.8769 0H3.84375C3.43491 0 2.94483 0.349432 2.86184 0.87358L0 20.267C0 20.6165 0.245476 21.0533 0.654311 21.0533H4.90689L6.05215 13.8026L7.11617 6.0277Z'
        fill='#003087'
      ></path>
    </svg>
  );
};
