export const SamsungInternetIcon: React.FC<JSX.IntrinsicElements['svg'] & { monochrome?: boolean }> = ({
  monochrome,
  ...props
}) => {
  if (monochrome)
    return (
      <svg
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 24 24'
        xmlSpace='preserve'
        {...props}
      >
        <path
          d='M12,0.9C5.9,0.9,0.9,5.9,0.9,12s5,11.1,11.1,11.1s11.1-5,11.1-11.1S18.1,0.9,12,0.9z M12,22.2
C6.4,22.2,1.8,17.6,1.8,12S6.4,1.8,12,1.8S22.2,6.4,22.2,12C22.2,17.6,17.6,22.2,12,22.2z'
        />
        <path
          id='land'
          d='M7.4,6C7.3,5.9,7.2,5.9,7.1,5.8L7,5.7C6.8,5.8,6.6,5.8,6.4,5.9c-0.1,0-0.1,0.2-0.1,0.4
c0,0.1,0,0.3-0.1,0.3C6.1,6.7,6.1,6.8,6,6.9C6,7,6,7.2,5.9,7.3C5.7,7.5,5.5,7.7,5.3,8C5.2,8.1,5.2,8.2,5.2,8.3
c0,0.2,0.1,0.4,0.1,0.6c0,0.1,0,0.2-0.1,0.3C5.1,9.2,5,9.2,5,9.1C4.9,8.9,4.8,8.8,4.7,8.6C4.6,8.5,3.9,8.4,3.8,8.5
C3.6,8.7,3.4,8.6,3.1,8.6C2.8,8.5,2.6,8.7,2.6,9c0,0.3,0.1,0.5,0,0.8c0,0.2,0,0.3,0.1,0.3c0.1,0.1,0.2,0.2,0.3,0.2
c0.1,0.1,0.3,0.1,0.4,0c0.2-0.3,0.5-0.2,0.7-0.2C4,10.3,4,10.4,3.9,10.5c-0.1,0.2,0,0.3,0.2,0.3s0.3,0,0.5,0.1
c0.1,0,0.1,0.1,0.2,0.2c0,0.1,0,0.3,0,0.4c0,0.2,0.1,0.3,0.2,0.4c0.4,0.2,0.8,0.1,1.3-0.1c0.3-0.2,0.7-0.1,1.1-0.1
c0.2,0,0.4,0.1,0.6,0.3l0.1,0.1c0.3,0.4,0.7,0.6,1.2,0.7c0.2,0,0.3,0.1,0.3,0.3c0.2,0.7,0.7,1.1,1.4,1.2c0.3,0,0.6,0.2,0.9,0.3
c0.1,0,0.1,0.1,0.2,0.2c0.1,0.6-0.1,1.1-0.5,1.6c-0.1,0.1-0.1,0.2-0.1,0.4s-0.1,0.4-0.1,0.5s-0.1,0.3-0.2,0.3
c-0.2,0.2-0.5,0.4-0.8,0.5c-0.2,0.1-0.3,0.2-0.2,0.4c0,0.1,0,0.3-0.1,0.4c-0.7,0.8-1.4,1.5-2,2.2C8,21.2,8,21.2,7.9,21.3
c-0.5-0.2-0.9-0.5-1.4-0.7c0-0.5,0.1-1.1,0.1-1.6s0.1-1,0.1-1.4c0-0.1,0-0.3-0.1-0.3C6.4,17.1,6.2,17,6,16.8
c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.3-0.2-0.6-0.4-0.9C5.1,15.1,5,14.8,5,14.4c0-0.2,0-0.4,0.2-0.6c0.3-0.2,0.3-0.5,0.3-0.8
c0-0.1,0-0.1,0-0.2c0-0.2-0.1-0.3-0.2-0.3c-0.4,0-0.7-0.2-1.1-0.4c-0.1,0-0.1-0.1-0.1-0.2c0-0.3-0.1-0.4-0.3-0.6
c-0.1-0.1-0.2-0.1-0.3-0.2C3.4,11,3.2,11,3.1,11c-0.3,0-0.6,0-0.9,0c-0.2,0-0.3,0-0.4-0.2c0.1-0.9,0.3-1.7,0.6-2.5
c0.4-1,0.9-1.8,1.5-2.6c0.8-1.1,1.8-1.9,3-2.6C7.2,3,7.4,2.8,7.7,2.7C7.8,2.9,7.9,3.1,8,3.2c0.1,0.2,0.1,0.3,0.3,0.4
s0.5,0.2,0.6,0.5C8.9,4.2,9,4.3,9,4.5L8.9,4.6C8.6,4.7,8.3,4.8,8,5c0,0-0.2,0-0.3,0S7.4,5,7.3,5v0.1c0.2,0.2,0.4,0.1,0.6,0.1
C8,5.3,8.2,5.4,8.3,5.6C8.1,5.9,7.8,6,7.4,6z M20.1,18.1c-0.2-0.4-0.4-0.8-0.6-1.2c0-0.1-0.1-0.2-0.1-0.3c0.2-0.4,0.1-0.9,0.1-1.4
c0-0.1,0-0.3,0-0.4c0-0.2,0-0.4-0.2-0.6c-0.2-0.2-0.1-0.5-0.1-0.7c0-0.3,0-0.4-0.3-0.3c-0.2,0-0.3,0-0.4-0.2c0-0.1-0.1-0.2-0.2-0.3
c-0.2-0.1-0.5-0.2-0.7,0c-0.3,0.3-0.7,0.4-1.2,0.4h-0.1c-0.2,0-0.2-0.1-0.3-0.2c-0.2-0.5-0.5-1-0.9-1.4C15,11.4,15,11.3,15,11.2
c0-0.4,0-0.9,0-1.3c0-0.1,0-0.2,0.1-0.3c0.2-0.3,0.4-0.7,0.8-0.9C16,8.7,16,8.5,16,8.4c0-0.3,0.2-0.5,0.5-0.6l0,0
c0.5,0,0.9-0.2,1.2-0.5c0.1-0.1,0.3-0.1,0.4-0.1c0.3,0,0.6,0,0.9,0c0.1,0,0.2,0,0.2,0.2c-0.2,0.5,0,0.4,0.3,0.6
c0.3,0.1,0.6,0.2,0.9,0.3c0.2,0.1,0.3,0,0.4-0.2c0-0.1,0.1-0.2,0.1-0.4c0,0,0.1,0,0.2-0.1c0.4,0.8,0.7,1.7,0.9,2.6
c0.5,2.8-0.1,5.4-1.7,7.8C20.3,18,20.2,18.1,20.1,18.1z M20.9,6.9c-0.1,0-0.2-0.1-0.3-0.1c-0.4-0.2-0.8-0.5-1.2-0.7
C19.1,5.9,18.8,6,18.5,6c-0.2,0-0.3,0-0.3,0.3c0,0.1-0.1,0.2-0.1,0.2c-0.3,0-0.4,0.1-0.5,0.4c-0.1,0.1-0.2,0.2-0.2,0.4
c-0.1,0.2-0.2,0.3-0.4,0.2c-0.3,0-0.5-0.3-0.7-0.5c-0.1-0.1-0.1-0.2,0-0.3c0.1-0.6,0-0.5,0.6-0.6c0.1,0,0.3,0,0.4,0S17.6,6,17.6,6
c0.1-0.2,0.1-0.4-0.1-0.6c-0.1-0.1-0.3-0.2-0.4-0.4c0.1,0,0.1,0,0.2,0s0.2,0,0.3,0c0.3,0.1,0.6,0,0.8-0.3c0.1-0.1,0.3-0.3,0.4-0.4
C19.5,5,20.3,5.9,20.9,6.9z M17.1,4.6c-0.1,0-0.2,0-0.3-0.1c0-0.2,0.1-0.3,0.1-0.4s0-0.3,0-0.4c-0.1-0.3-0.2-0.6-0.3-0.8
c0.5,0.3,0.9,0.6,1.4,0.8c0,0.2-0.1,0.5-0.1,0.7s-0.1,0.2-0.2,0.2C17.5,4.6,17.3,4.6,17.1,4.6z M10.4,2.9c-0.3,0-0.5-0.1-0.6-0.3
C9.7,2.4,9.5,2.3,9.4,2.1C10,2,10.7,1.9,11.3,1.8c-0.1,0.1-0.2,0.2-0.2,0.3c-0.2,0.2-0.5,0.4-0.3,0.8C10.6,2.9,10.5,2.9,10.4,2.9z'
        />
      </svg>
    );

  return (
    <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M18.2577 6.31568C18.035 4.64413 17.4434 3.12952 16.4028 2.02003C15.2927 0.979875 13.7773 0.38863 12.105 0.166001C10.5093 -0.045679 9.42112 0.00541626 9.42112 0.00541626C9.42112 0.00541626 8.33298 -0.045679 6.73729 0.166001C5.06491 0.38863 3.54954 0.979875 2.44314 2.02003C1.39882 3.12952 0.807281 4.64413 0.584541 6.31568C0.372755 7.91058 0.423876 8.99818 0.423876 8.99818C0.423876 8.99818 0.372755 10.0858 0.584541 11.6807C0.807281 13.3522 1.39882 14.8668 2.43949 15.9763C3.54954 17.0201 5.06491 17.6114 6.73363 17.834C8.32933 18.0457 9.41747 17.9946 9.41747 17.9946C9.41747 17.9946 10.5056 18.0457 12.1013 17.834C13.7737 17.6114 15.2891 17.0201 16.3955 15.9763C17.4398 14.8668 18.0313 13.3522 18.2504 11.6807C18.4622 10.0858 18.4111 8.99818 18.4111 8.99818C18.4111 8.99818 18.4695 7.91058 18.2577 6.31568Z'
        fill='#7882FF'
      />
      <path
        d='M14.0293 7.66603C14.7414 10.2208 13.2443 12.8704 10.6882 13.5821C8.1322 14.2938 5.48123 12.7975 4.76919 10.2427C4.05715 7.68793 5.55426 5.03828 8.1103 4.3266C10.6663 3.61491 13.3173 5.11127 14.0293 7.66603Z'
        fill='white'
      />
      <path
        d='M14.0292 7.66602C14.2956 8.62588 14.2518 9.59668 13.9598 10.4726C13.9379 10.5419 13.9124 10.6149 13.8832 10.6843'
        fill='white'
      />
      <path d='M13.9526 7.66602C14.1388 8.33755 14.1753 9.01639 14.0767 9.66238L13.9526 7.66602Z' fill='white' />
      <path
        d='M12.6125 12.3267C11.3966 12.3267 9.9177 12.0165 8.4425 11.4581C6.9673 10.8997 5.65642 10.1479 4.7472 9.34128C3.27201 8.03105 3.36329 7.01645 3.54952 6.52739C3.73574 6.03834 4.34189 5.21716 6.31369 5.21716C7.52963 5.21716 9.00848 5.52738 10.4837 6.08578C11.9589 6.64418 13.2698 7.39601 14.179 8.20258C15.6542 9.51281 15.5629 10.5274 15.3767 11.0165C15.1941 11.5055 14.5879 12.3267 12.6125 12.3267ZM6.31734 6.73907C5.40447 6.73907 5.01376 6.96535 4.9736 7.06754C4.93343 7.16973 5.07949 7.60039 5.76232 8.20623C6.52547 8.88142 7.70125 9.55296 8.98657 10.0384C10.2719 10.5274 11.5974 10.8084 12.6161 10.8084C13.529 10.8084 13.9197 10.5822 13.9599 10.48C14.0001 10.3778 13.854 9.94712 13.1748 9.34128C12.4117 8.66609 11.2359 7.99455 9.95056 7.50915C8.65794 7.02009 7.3361 6.73907 6.31734 6.73907Z'
        fill='#4D5CC1'
      />
      <path
        d='M4.97363 7.0821L13.9526 10.4909C14.252 9.60766 14.2995 8.62955 14.0293 7.66604C13.3173 5.11128 10.6699 3.61491 8.11025 4.3266C6.64236 4.73536 5.52501 5.78281 4.97363 7.0821Z'
        fill='white'
      />
    </svg>
  );
};
