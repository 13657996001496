import { DropdownArrow } from '@va/util/components';
import { useMeasure } from '@va/util/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { animated, useSpring } from 'react-spring';

export const CollapsableSection = ({ title, children, className }) => {
  const [open, setOpen] = useState(true);
  const [bind, { height }] = useMeasure();

  const animation = useSpring({ maxHeight: open ? (height ? height : 'auto') : 0 });

  const onClick = useCallback(() => setOpen(!open), [open]);

  return (
    <div className={classNames('-ml-4 lg:-ml-8 -mr-4 lg:-mr-8 border-gray-gallery border-b-2', className)}>
      <div
        onClick={onClick}
        className='bg-alabaster p-5 pl-4 lg:pl-8 pr-4 lg:pr-8 flex border-2 border-white cursor-pointer items-center'
      >
        <div className='text-mine-shaft text-lg leading-normal font-bold'>{title}</div>

        <DropdownArrow open={open} className='ml-auto' />
      </div>

      <animated.div style={animation} className='overflow-hidden'>
        <div {...bind}>
          <div className='p-4 lg:p-8'>{children}</div>
        </div>
      </animated.div>
    </div>
  );
};

CollapsableSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};
