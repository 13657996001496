import { useTranslate } from '@va/localization';
import { Filter } from '@va/types/filters';
import { useMemo } from 'react';
import { TextFilter } from '@va/shared/feature-filters';

export const useVisitsByCompanyFilterOptions = () => {
  const translate = useTranslate();
  const options: Filter[] = useMemo(
    () => [
      new TextFilter({
        id: '1',
        name: 'companyOrgName',
        label: translate('filters.options.searchByCompanyName'),
        inputProps: { label: translate('filters.labels.companyName') },
      }),
    ],
    [translate],
  );

  return options;
};
