import { defaultReactSelectStylesV2 } from '@va/constants';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import Select from 'react-select';

const DropdownInput = ({
  translate,
  field: { name },
  form: { setFieldValue, values, errors },
  placeholder,
  label,
  options,
  required,
}) => {
  return (
    <Fragment>
      <div className='dropdown-input rounded-12 border-solid border-2 border-gray-gallery mt-6px '>
        <span className='dropdown-input-label text-gray-devil text-xs leading-5 font-medium font-inter'>
          {label} {required && <span className='text-red-negative'>*</span>}
        </span>
        <Select
          name='form-select'
          value={options.find((item) => item.value === values[name]) || null}
          styles={defaultReactSelectStylesV2}
          placeholder={placeholder}
          options={options}
          clearable={false}
          onChange={(item) => (item ? setFieldValue(name, item.value) : null)}
          autoBlur
        />
      </div>
      {errors && errors[name] && <div className='mt-2 text-sm text-red-negative ml-2'>{translate(errors[name])}</div>}
    </Fragment>
  );
};

DropdownInput.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  required: PropTypes.bool,
  //from FastField
  field: PropTypes.shape({
    name: PropTypes.string,
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    values: PropTypes.object,
    errors: PropTypes.object,
  }),
  //withTranslate
  translate: PropTypes.func,
};

export default withTranslate(DropdownInput);
