import { TooltipWrapper } from '@va/ui/tooltips';
import { PropsWithChildren } from 'react';
import { AvailableFiltersDropdown } from '../AvailableFiltersDropdown';

export const AddFilterTooltip = ({
  children,
  isOpen,
  openChange,
}: PropsWithChildren<{ isOpen: boolean; openChange: (open: boolean) => void }>) => {
  return (
    <TooltipWrapper
      useDefaultStyle={false}
      placement='bottom'
      trigger='click'
      disabled={!isOpen}
      arrow={false}
      interactive
      open={isOpen}
      onOpenChange={openChange}
      content={
        <AvailableFiltersDropdown
          closeDropdown={() => {
            openChange(false);
          }}
        />
      }
      zIndex={'var(--zIndex-filters-dropdown)'}
    >
      {children}
    </TooltipWrapper>
  );
};
