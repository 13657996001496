import { TEST_IDS } from '@va/constants';
import { MinusIcon, SettingsIcon, SquareIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { SelectActionDropdown, SelectActionDropdownOption } from '@va/ui/design-system';
import classNames from 'classnames';
import { useMemo } from 'react';

type BulkOperationsProps = {
  allSelected: boolean;
  selectedItemsCount: number;
  selectOptions: SelectActionDropdownOption[];
  actionsOptions: SelectActionDropdownOption[];
  clearSelectedItems: () => void;
  wrapperClassName?: string;
  selectedOption: number | null;
  size?: 'small' | 'large';
};

export const BulkOperations = ({
  allSelected,
  selectedItemsCount,
  selectOptions,
  clearSelectedItems,
  actionsOptions,
  wrapperClassName,
  selectedOption,
  size = 'large',
}: BulkOperationsProps) => {
  const translate = useTranslate();

  const hasSelectedItems = selectedItemsCount > 0;

  const selectDropdownText = useMemo(() => {
    if (allSelected) return translate('bulkOperations.allItems', { count: selectedItemsCount ?? 0 });
    if (selectedItemsCount > 0) return translate('bulkOperations.items', { count: selectedItemsCount ?? 0 });
    return translate('button.select');
  }, [allSelected, selectedItemsCount, translate]);

  return (
    <div className={classNames('flex gap-3 flex-wrap items-center', wrapperClassName)}>
      <SelectActionDropdown
        options={selectOptions}
        icon={() =>
          hasSelectedItems ? (
            <button
              onClick={(e) => {
                e.stopPropagation();
                clearSelectedItems();
              }}
            >
              <MinusIcon color='#EA2A0C' className='w-4 h-4 pointer-events-none' />
            </button>
          ) : (
            <SquareIcon color='#696969' className='w-4 h-4' />
          )
        }
        text={selectDropdownText}
        tooltipOptions={{ zIndex: 999, appendTo: document.body, placement: 'bottom-start' }}
        selectedOption={selectedOption}
        size={size}
        data-testid-btn={TEST_IDS.generic.bulkOperations.selectBtn}
      />

      {hasSelectedItems && (
        <SelectActionDropdown
          options={actionsOptions}
          text={translate('button.selectAction')}
          icon={() => <SettingsIcon color='#696969' />}
          size={size}
          tooltipOptions={{
            zIndex: 999,
            appendTo: document.body,
            placement: 'bottom-start',
          }}
        />
      )}
    </div>
  );
};
