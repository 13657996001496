import { LAYOUT_PORTAL_ID, PAGE_HEADER_RESIZE_EVENT } from '@va/constants';
import '@va/styles/index.scss';
import { useElementDimensions } from '@va/util/hooks';
import { EventBus } from '@va/util/misc';
import classNames from 'classnames';
import 'leaflet/dist/leaflet.css';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { useHistory } from 'react-router';
import 'rodal/lib/rodal.css';
import './layout.scss';

export type LayoutProps = {
  hasBackButton?: boolean;
};

export function useScrollToTopEffect() {
  const history = useHistory();
  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (!rootElement) return;
    rootElement.scrollTop = 0;
  }, [history.location.key]);
}

export const getLayoutClassName = (isSidebarVisible: boolean) =>
  classNames('layout-content grow relative flex flex-col transition-all duration-200 ease-in-out', {
    'sidebar-visible': isSidebarVisible,
  });

export function LayoutPortal({ children }: { children: React.ReactNode }) {
  const [wrapper, setWrapper] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const layoutPortalWrapper = document.getElementById(LAYOUT_PORTAL_ID);
    setWrapper(layoutPortalWrapper);
  }, []);

  if (!wrapper) {
    return null;
  }

  return createPortal(children, wrapper);
}

export const LayoutStickyHeaderWrapper = () => {
  const ref = useRef<HTMLDivElement | any>(null);

  const { height, width } = useElementDimensions(ref);

  useEffect(() => {
    EventBus.dispatch(PAGE_HEADER_RESIZE_EVENT, { height, width });
  }, [height, width]);

  return (
    <div
      ref={ref}
      id={LAYOUT_PORTAL_ID}
      className='z-[var(--zIndex-layout)] top-[var(--top-bar-height)] flex flex-col sticky'
    ></div>
  );
};
