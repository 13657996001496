import { TEST_IDS } from '@va/constants';
import { useTranslate } from '@va/localization';
import { Button, GrayGradientDivider, ModalHeader, Paragraph } from '@va/ui/design-system';
import { ModalWrapper } from '@va/util/components';
import classNames from 'classnames';

export const ConfirmationModal = ({
  title,
  info,
  onClose,
  isOpen,
  onConfirmation,
  footerButtons,
  actionButtonText,
  className,
}: {
  title: string;
  info: string | React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
  onConfirmation?: () => void;
  footerButtons?: React.ReactNode;
  actionButtonText?: string;
  className?: string;
}) => {
  const translate = useTranslate();

  return (
    <ModalWrapper closeModal={onClose} isModalOpen={isOpen}>
      <div className={classNames('max-w-600 w-screen', className)}>
        <ModalHeader title={title} onClose={onClose} />
        <Paragraph className='px-5 py-3'>{info}</Paragraph>
        <GrayGradientDivider />
        {footerButtons ? (
          <div className='p-3'>{footerButtons}</div>
        ) : (
          <div className='flex flex-row justify-end p-3 gap-3'>
            <Button
              className='rounded-12 flex-grow'
              color='tertiary'
              text={translate('button.cancel')}
              onClick={onClose}
              data-testid={TEST_IDS.generic.buttons.cancel}
            />
            <Button
              className='rounded-12 flex-grow text-red-pure'
              color='tertiary'
              text={actionButtonText ?? translate('button.delete')}
              onClick={() => {
                onClose();
                onConfirmation && onConfirmation();
              }}
              data-testid={TEST_IDS.generic.buttons.apply}
            />
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};

export default ConfirmationModal;
