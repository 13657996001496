import { ONE_WEEK } from '@va/constants';
import { getWebsite } from '@va/dashboard/selectors/core';
import { hasHigherPremiumPackageToUpgrade, hasPermission } from '@va/standalone/shared/selectors';
import { hasPassedTimeUntil } from '@va/util/helpers';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import UpgradeToHigherPackageBadge from './UpgradeToHigherPackageBadge';

class UpgradeToHigherPackageBadgeContainer extends Component {
  render() {
    if (!this.props.shouldShowUpgradeToHigherPackageBadge || !this.props.hasFeaturePermission) return null;
    return <UpgradeToHigherPackageBadge textContent={this.props.textContent} />;
  }
}

const mapStateToProps = (state) => {
  const website = getWebsite(state);
  const fFlags = website.frontendFlags;
  const hasSeenUpgradeToHigherPackageBadge = fFlags && fFlags.dontShowUpgradeToHigherPackage;
  const upgradedAtTs = website.firstPremiumTs || website.installDate;
  const canUpgradeToHigherPlan = hasHigherPremiumPackageToUpgrade(state);
  return {
    hasFeaturePermission: hasPermission(state),
    shouldShowUpgradeToHigherPackageBadge:
      canUpgradeToHigherPlan && !hasSeenUpgradeToHigherPackageBadge && hasPassedTimeUntil(4 * ONE_WEEK, upgradedAtTs),
  };
};

UpgradeToHigherPackageBadgeContainer.propTypes = {
  textContent: PropTypes.any,
  //Connected
  hasFeaturePermission: PropTypes.bool.isRequired,
  shouldShowUpgradeToHigherPackageBadge: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(UpgradeToHigherPackageBadgeContainer);
