import { TooltipWrapper } from '@va/ui/tooltips';
import dayjs from 'dayjs';
import React from 'react';

const Calendar = (props) => {
  const { calendar, index, isBetweenRange, betweenRangeClasses, datepickerClasses, isRange, setDate, tooltipContent } =
    props;

  return (
    <div className='grid grid-cols-7 gap-y-0.5 my-1'>
      {calendar.date().map((date) => {
        return (
          <TooltipWrapper disabled={!date.disabled || date.unix() > dayjs().unix()} content={tooltipContent} key={date}>
            <div
              className={`relative ${
                isBetweenRange(date) ? `${index === 0 ? '' : 'bg-class'} text-black` : ''
              } ${betweenRangeClasses(date)}`}
              key={index}
            >
              <div>
                <button
                  type='button'
                  className={`litepie-datepicker-date calendar-date-btn z-1 relative w-12 h-12 flex justify-center items-center text-xs 2xl:text-sm focus:outline-none
                    ${datepickerClasses(date)}
                    ${isRange() ? 'transition-all' : 'transition-colors'}
                    ${date.disabled ? '' : 'calendar-date-btn-hover'}
                  `}
                  disabled={date.disabled || date.inRange()}
                  onClick={(e) => {
                    e.preventDefault();
                    setDate(date, false);
                  }}
                >
                  {date.date()}
                </button>
              </div>
            </div>
          </TooltipWrapper>
        );
      })}
    </div>
  );
};

export default React.memo(Calendar);
