export const FacebookAppIcon: React.FC<JSX.IntrinsicElements['svg'] & { monochrome?: boolean }> = ({
  monochrome,
  ...props
}) => {
  if (monochrome)
    return (
      <svg
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 24 24'
        xmlSpace='preserve'
        {...props}
      >
        <path
          d='M22.2,0.6H2.3c-0.7,0-1.2,0.6-1.2,1.2v19.9c0,0.7,0.6,1.2,1.2,1.2h19.9c0.7,0,1.2-0.6,1.2-1.2V1.9
C23.4,1.2,22.8,0.6,22.2,0.6z M22.4,21.8c0,0.1-0.1,0.2-0.2,0.2H2.3c-0.1,0-0.2-0.1-0.2-0.2V1.9c0-0.1,0.1-0.2,0.2-0.2h19.9
c0.1,0,0.2,0.1,0.2,0.2V21.8z'
        />
        <path
          id='f'
          d='M16.4,23v-8.7h2.9l0.4-3.4h-3.3V8.7c0-1,0.3-1.6,1.7-1.6h1.8v-3c-0.3,0-1.4-0.1-2.6-0.1
C14.7,4,13,5.6,13,8.5V11h-3v3.4h3V23H16.4z'
        />
      </svg>
    );

  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M18 9C18 4.02891 13.9711 0 9 0C4.02891 0 0 4.02891 0 9C0 13.493 3.29062 17.216 7.59375 17.891V11.6016H5.30859V9H7.59375V7.01719C7.59375 4.76191 8.93672 3.51562 10.9934 3.51562C11.9777 3.51562 13.0078 3.69141 13.0078 3.69141V5.90625H11.8723C10.7543 5.90625 10.4062 6.60059 10.4062 7.3125V9H12.9023L12.5033 11.6016H10.4062V17.891C14.7094 17.216 18 13.493 18 9Z'
        fill='#1877F2'
      />
    </svg>
  );
};
