import { LOAD_EXTERNAL_DASHBOARD_SCRIPT_REQUEST } from '@va/dashboard/actions/api';
import { all, call, select, takeLatest } from 'redux-saga/effects';

import Api from '@va/dashboard/api-client/index';
import { getWebsite } from '@va/dashboard/selectors/core';

export function* watchers() {
  yield all([takeLatest(LOAD_EXTERNAL_DASHBOARD_SCRIPT_REQUEST, loadExternalDashboardScript)]);
}

const createScriptElement = (scriptId, snippet) => {
  const externalScript = document.createElement('script');
  externalScript.id = scriptId;
  externalScript.innerText = snippet;
  document.body.appendChild(externalScript);
};

export function* loadExternalDashboardScript(action) {
  try {
    const SCRIPT_ID = 'dashboard-js';
    const website = yield select(getWebsite);
    const response = yield call(Api.getExternalDashboardScript, action.platform || website.platform);

    const dashboardJsNodeExist = document.querySelector(`#${SCRIPT_ID}`);
    if (!response) return;
    if (dashboardJsNodeExist) {
      const sameJsContent = response.snippet === dashboardJsNodeExist.innerText;
      if (sameJsContent) return;
      dashboardJsNodeExist.remove();
      createScriptElement(SCRIPT_ID, response.snippet);
    } else {
      createScriptElement(SCRIPT_ID, response.snippet);
    }
  } catch (error) {
    console.warn(error);
  }
}
