import {
  SEND_FEEDBACK_FAILED,
  SEND_FEEDBACK_REQUEST,
  SEND_FEEDBACK_SUCCEEDED,
  SEND_REVIEW_FAILED,
  SEND_REVIEW_REQUEST,
  SEND_REVIEW_SUCCEEDED,
} from '@va/dashboard/actions/api';
import { apiRequest } from './core/util';

export const sendFeedback = apiRequest(SEND_FEEDBACK_REQUEST, SEND_FEEDBACK_SUCCEEDED, SEND_FEEDBACK_FAILED);

export const sendReview = apiRequest(SEND_REVIEW_REQUEST, SEND_REVIEW_SUCCEEDED, SEND_REVIEW_FAILED);
