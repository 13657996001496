import { ONE_MONTH, ONE_WEEK, ONE_YEAR } from '@va/constants';
import { getWebsite } from '@va/dashboard/selectors/core';
import { TimePeriodFilter, TimePeriodFilterProps } from '@va/ui/design-system';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const VisaTimePeriodFilter: React.FC<TimePeriodFilterProps> = (props) => {
  const { installDate } = useSelector(getWebsite);
  const websiteAge = useMemo(() => Date.now() / 1000 - installDate, [installDate]);

  return (
    <TimePeriodFilter
      disabled={{ day: false, week: websiteAge < ONE_WEEK, month: websiteAge < ONE_MONTH, year: websiteAge < ONE_YEAR }}
      {...props}
    />
  );
};
