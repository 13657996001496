export function RefreshIconV2(props) {
  const { color, ...rest } = props;
  const colorValue = color ? color : '#666666';
  return (
    <svg {...rest} width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 3C6.61553 3 5.26215 3.41054 4.11101 4.17971C2.95986 4.94888 2.06266 6.04213 1.53284 7.32122C1.00303 8.6003 0.864406 10.0078 1.1345 11.3656C1.4046 12.7235 2.07129 13.9708 3.05025 14.9497C4.02922 15.9287 5.2765 16.5954 6.63437 16.8655C7.99224 17.1356 9.3997 16.997 10.6788 16.4672C11.9579 15.9373 13.0511 15.0401 13.8203 13.889C14.5895 12.7378 15 11.3845 15 10'
        stroke={colorValue}
        strokeWidth='2'
      />
      <path d='M12 3L8 6L8 -1.74846e-07L12 3Z' fill={colorValue} />
    </svg>
  );
}
