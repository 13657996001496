import {
  AGREEMENT_REQUEST,
  agreementFailed,
  agreementSucceeded,
  GET_AGREEMENT_REQUEST,
  GET_ANNOUNCEMENTS,
  GET_FEATURE_PERMISSIONS,
  GET_PRIVACY_SETTINGS,
  getAgreementFailed,
  getAgreementSucceeded,
  getAnnouncementsFailed,
  getAnnouncementsSucceeded,
  getFeaturePermissionsFailed,
  getFeaturePermissionsSucceeded,
  getPrivacySettingsFailed,
  getPrivacySettingsSucceeded,
  requestWebsiteFailed,
  requestWebsiteSucceeded,
  resetRequest,
  SIGN_DPA_REQUEST,
  signDPAFailed,
  signDPASucceeded,
  UPDATE_SETTINGS_FAILED,
  UPDATE_SETTINGS_SUCCEEDED,
  UPDATE_WEBSITE_FLAG,
  UPDATE_WEBSITE_FLAGS_REQUEST,
  updateSettings,
  updateWebsite,
  updateWebsiteFlags,
  updateWebsiteFlagsFailed,
  updateWebsiteFlagsSucceeded,
  WEBSITE_REQUEST,
} from '@va/dashboard/actions/api';
import { setGlobalFilter } from '@va/dashboard/actions/ui';
import Api from '@va/dashboard/api-client/index';
import { getInstanceId } from '@va/dashboard/selectors/app';
import {
  getWebsiteFrontendFlags,
  getWebsite as getWebsiteSelector,
  getWebsiteTimezone,
} from '@va/dashboard/selectors/core';
import { getLocale } from '@va/dashboard/selectors/ui';
import { T3_URL_PARAMS } from '@va/standalone/shared/constants';
import {
  announcementsMapper,
  getFilterDateWithTimezone,
  getParameterByName,
  getTimePeriodFromSessionStorage,
  isValidWebsiteId,
} from '@va/util/helpers';
import { getPredefinedFilters } from '@va/wix-mini/shared/selectors';
import { PREDEFINED_PERIOD } from '@va/wix-mini/shared/types';
import Immutable from 'immutable';
import moment from 'moment';
import { all, call, delay, put, select, spawn, take, takeLatest } from 'redux-saga/effects';
export function* watchers() {
  yield all([
    takeLatest(WEBSITE_REQUEST, getWebsite),
    takeLatest(UPDATE_WEBSITE_FLAGS_REQUEST, updateWebsiteFlagsSaga),
    takeLatest(GET_AGREEMENT_REQUEST, getAgreement),
    takeLatest(AGREEMENT_REQUEST, agreement),
    takeLatest(SIGN_DPA_REQUEST, signAgreement),
    takeLatest(GET_FEATURE_PERMISSIONS, getFeaturePermissions),
    takeLatest(GET_ANNOUNCEMENTS, getAnnouncements),
    takeLatest(UPDATE_WEBSITE_FLAG, updateWebsiteFlagWatcher),
    takeLatest(GET_PRIVACY_SETTINGS, getPrivacySettingsWatcher),
  ]);
}

function* updateWebsiteFlagWatcher(action) {
  yield put(updateWebsiteFlags({ [action.key]: action.value }, action.optimisticUpdate));
}

function* handleFilterAfterWebsiteSwitch(installDateFromFetch, timezone) {
  const previousFilter = yield select((state) => state.getIn(['core', 'ui', 'globalFilter']));
  const websiteTimezone = yield select(getWebsiteTimezone);
  const websiteId = yield select(getInstanceId);
  const startDateQueryParam = getParameterByName(T3_URL_PARAMS.startDate);
  const endDateQueryParam = getParameterByName(T3_URL_PARAMS.endDate);
  let newFilter;
  let fromDate;

  const predefinedFilters = yield select(getPredefinedFilters);

  if (predefinedFilters?.timePeriod) {
    yield spawn(setPredefinedTimePeriod);
    return;
  }

  if (startDateQueryParam && endDateQueryParam) {
    yield put(
      setGlobalFilter({
        from: moment(startDateQueryParam, 'YYYY-MM-DD').tz(websiteTimezone, true).startOf('day'),
        until: moment(endDateQueryParam, 'YYYY-MM-DD').tz(websiteTimezone, true).endOf('day'),
      }),
    );
    return;
  }

  const storedFilter = getTimePeriodFromSessionStorage(websiteId, websiteTimezone, installDateFromFetch);

  if (storedFilter) {
    if (storedFilter.from.unix() < installDateFromFetch) {
      fromDate = moment(installDateFromFetch * 1000).startOf('day');
    } else {
      fromDate = storedFilter.from;
    }
    yield put(setGlobalFilter({ from: fromDate, until: storedFilter.until }));
    return;
  }

  if (previousFilter) {
    if (previousFilter.from.unix() < installDateFromFetch) {
      fromDate = moment(installDateFromFetch * 1000).startOf('day');
    }
  } else {
    const date = getFilterDateWithTimezone(moment().subtract(29, 'days').startOf('day'), timezone);
    fromDate = date.unix() > installDateFromFetch ? date : moment(installDateFromFetch * 1000).startOf('day');
  }

  if (fromDate) {
    newFilter = {
      from: fromDate,
      until: moment().endOf('day'),
    };

    yield put(setGlobalFilter(newFilter));
  }
}

function* setPredefinedTimePeriod() {
  const { timePeriod, timezone } = yield select(getPredefinedFilters);

  if (!timePeriod) return;

  let timeFilter = {
    from: undefined,
    until: moment().tz(timezone).endOf('day'),
  };

  switch (timePeriod) {
    case PREDEFINED_PERIOD.TODAY:
      timeFilter.from = moment().tz(timezone).startOf('day');
      break;
    case PREDEFINED_PERIOD.YESTERDAY:
      timeFilter.from = moment().tz(timezone).subtract(1, 'day').startOf('day');
      timeFilter.until = moment().tz(timezone).subtract(1, 'day').endOf('day');
      break;
    case PREDEFINED_PERIOD.THIS_WEEK:
      timeFilter.from = moment().tz(timezone).startOf('week');
      break;
    case PREDEFINED_PERIOD.LAST_7_DAYS:
      timeFilter.from = moment().tz(timezone).subtract(6, 'day').startOf('day');
      break;
    case PREDEFINED_PERIOD.THIS_MONTH:
      timeFilter.from = moment().tz(timezone).startOf('month');
      break;
    case PREDEFINED_PERIOD.LAST_30_DAYS:
    default:
      timeFilter.from = moment().tz(timezone).subtract(29, 'day').startOf('day');
  }

  yield put(setGlobalFilter(timeFilter));
}

export function* getWebsite() {
  try {
    const websiteId = yield select(getInstanceId);
    const data = yield call(Api.getWebsite, websiteId);

    window.timezone = data.timezone;
    // TODO Maybe set momentJS and DayJS timezone here?

    yield call(handleFilterAfterWebsiteSwitch, data['installDate'], data['timezone']);
    yield put(requestWebsiteSucceeded(data));
  } catch (error) {
    yield put(requestWebsiteFailed(error));
  }
}

export function* updateWebsiteFlagsSaga(action) {
  const currentFrontendFlags = yield select(getWebsiteFrontendFlags);
  const websiteId = yield select(getInstanceId);

  const performOptimisticUpdate = action.optimisticUpdate;

  try {
    if (performOptimisticUpdate) {
      // Optimistically update the flags
      yield put(updateWebsite({ frontendFlags: { ...currentFrontendFlags, ...action.data } }));
    }

    const flagsResponse = yield call(Api.updateWebsiteFrontendFlags, websiteId, action.data);
    yield put(updateWebsiteFlagsSucceeded(flagsResponse));

    // Just to make sure the flags are in sync
    const websiteResponse = yield call(Api.getWebsite, websiteId);
    yield put(requestWebsiteSucceeded(websiteResponse));
  } catch (error) {
    if (performOptimisticUpdate) {
      // Return to old frontend flags if the request fails
      yield put(updateWebsite({ frontendFlags: currentFrontendFlags }));
    }

    yield put(updateWebsiteFlagsFailed(error));
  } finally {
    yield delay(3000);
    yield put(resetRequest('updateWebsiteFlags'));
  }
}

export function* agreement() {
  try {
    const websiteId = yield select(getInstanceId);

    const agreementRequestData = {
      privacyTermsAccepted: true,
      dpaAccepted: true,
      businessConditionAccepted: true,
    };

    yield call(Api.agreement, websiteId, agreementRequestData);

    yield put(agreementSucceeded());
  } catch (error) {
    yield put(agreementFailed(error));
  }
}

export function* getAgreement() {
  try {
    const websiteId = yield select(getInstanceId);
    const data = yield call(Api.getAgreement, websiteId);
    yield put(getAgreementSucceeded(data));
  } catch (error) {
    yield put(getAgreementFailed(error));
  }
}

export function* signAgreement(action) {
  try {
    const websiteId = yield select(getInstanceId);
    const website = yield select(getWebsiteSelector);

    const websiteInfoDetailsChanged =
      action.websiteInfo.firstName !== website.firstName ||
      action.websiteInfo.lastName !== website.lastName ||
      action.websiteInfo.companyName !== website.companyName;
    if (websiteInfoDetailsChanged) {
      yield put(updateSettings(Immutable.fromJS(action.websiteInfo)));
      const updateWebsiteAction = yield take([UPDATE_SETTINGS_SUCCEEDED, UPDATE_SETTINGS_FAILED]);
      if (updateWebsiteAction.type === UPDATE_SETTINGS_SUCCEEDED) {
        yield call(Api.signAgreement, websiteId, { signatureImage: action.signatureImage });
        yield put(signDPASucceeded());
      } else {
        yield put(signDPAFailed());
      }
    } else {
      yield call(Api.signAgreement, websiteId, { signatureImage: action.signatureImage });
      yield put(signDPASucceeded());
    }
  } catch (error) {
    yield put(signDPAFailed(error));
  }
}

export function* getFeaturePermissions() {
  try {
    const websiteId = yield select(getInstanceId);
    if (!isValidWebsiteId(websiteId)) throw new Error('No active website');
    const response = yield call(Api.getFeaturePermissions, websiteId);
    yield put(getFeaturePermissionsSucceeded(response));
  } catch (error) {
    yield put(getFeaturePermissionsFailed(error));
  }
}

export function* getAnnouncements() {
  try {
    const locale = yield select(getLocale);
    const website = yield select(getWebsiteSelector);

    const ipCountryISO = website && website.ipCountryISO;
    const response = yield call(Api.getAnnouncements, locale || 'en', ipCountryISO, website.id);
    const data = response?.payload[locale] || response?.payload['en'];
    yield put(getAnnouncementsSucceeded({ data: announcementsMapper(data) }));
  } catch (error) {
    yield put(getAnnouncementsFailed(error));
  }
}

export function* getPrivacySettingsWatcher() {
  try {
    const websiteId = yield select(getInstanceId);
    const response = yield call(Api.getPrivacySettings, websiteId);
    yield put(getPrivacySettingsSucceeded({ data: response?.payload }));
  } catch (error) {
    yield put(getPrivacySettingsFailed(error));
  }
}
