import { IconProps } from '@va/icons';
import {
  Button,
  ButtonShapes,
  fontWeights,
  Paragraph,
  ParagraphProps,
  paragraphSizes,
  ParagraphWithTooltip,
} from '@va/ui/design-system';
import { TooltipWrapper, TooltipWrapperProps } from '@va/ui/tooltips';
import { useCheckOverflow } from '@va/util/hooks';
import classNames from 'classnames';
import { FC, MouseEventHandler, ReactNode, useRef } from 'react';

type ListCardItemProps = {
  topText: string | ReactNode;
  topTextProps?: {
    fontSize?: paragraphSizes;
    colorClassName?: string;
  };
  bottomTextProps?: Omit<ParagraphProps, 'children'>;
  bottomNode?: ReactNode;
  bottomText?: string | number;
  wrapperClassName?: string;
};

type ListCardItemWithIconProps = {
  className?: string;
  icon: ReactNode;
  onClick?: MouseEventHandler;
  topTextTooltipProps?: Partial<TooltipWrapperProps>;
} & ListCardItemProps;

type ListCardItemWithButtonProps = {
  icon: FC<IconProps>;
  onClick?: MouseEventHandler;
  buttonTooltip?: string;
  buttonDataTestId?: string;
} & ListCardItemProps;

export function ListCardItemWithButton({
  icon: Icon,
  buttonTooltip,
  topText,
  bottomText,
  bottomNode,
  onClick,
  wrapperClassName,
  buttonDataTestId,
}: ListCardItemWithButtonProps) {
  return (
    <div className={classNames('flex flex-row items-center flex-nowrap gap-2 overflow-hidden', wrapperClassName)}>
      <Button
        data-testid={buttonDataTestId}
        tooltip={buttonTooltip}
        color={'secondary'}
        shape={ButtonShapes.circle}
        icon={(_className, color) => <Icon color={color} />}
        onClick={onClick}
      />

      <div className='overflow-hidden'>
        <ParagraphWithTooltip
          colorClassName='text-gray-charcoal'
          weight={fontWeights.medium}
          className={classNames('text-left')}
        >
          {topText}
        </ParagraphWithTooltip>

        {bottomText && (
          <Paragraph size={paragraphSizes.tiny} colorClassName='text-primary'>
            {bottomText}
          </Paragraph>
        )}
        {bottomNode}
      </div>
    </div>
  );
}

export function ListCardItemWithIcon({
  icon,
  topText,
  bottomText,
  bottomNode = null,
  className,
  onClick,
  wrapperClassName,
  topTextTooltipProps,
  bottomTextProps,
  topTextProps,
}: ListCardItemWithIconProps) {
  const topRef = useRef(null);
  const isTopOverflowing = useCheckOverflow(topRef);

  return (
    <div className={classNames('flex flex-row items-center flex-nowrap gap-2 overflow-hidden', wrapperClassName)}>
      <div className='shrink-0' onClick={onClick}>
        {icon}
      </div>
      <div className={classNames('overflow-hidden', className)}>
        <TooltipWrapper
          {...topTextTooltipProps}
          content={topTextTooltipProps?.content ?? topText}
          disabled={!isTopOverflowing && !topTextTooltipProps?.content}
          ref={topRef}
        >
          <Paragraph
            colorClassName={classNames('text-left text-gray-charcoal', topTextProps?.colorClassName)}
            weight={fontWeights.medium}
            className='truncate'
            size={topTextProps?.fontSize ?? paragraphSizes.normal}
          >
            {topText}
          </Paragraph>
        </TooltipWrapper>

        {bottomText && (
          <Paragraph size={paragraphSizes.tiny} colorClassName='text-primary' {...bottomTextProps}>
            {bottomText}
          </Paragraph>
        )}
        {bottomNode}
      </div>
    </div>
  );
}
