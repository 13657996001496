import classNames from 'classnames';
import { useCallback } from 'react';
import { FilterBlock } from '../../FilterBlock';
import { FilterInput } from '../../FilterInput';
import { useFiltersContext } from '../../filters-context';
import { IntervalFilter, IntervalFilterInputProps, IntervalFilterValue } from './IntervalFilter';

type IntervalFilterAppliedProps = {
  filter: IntervalFilter;
  viewOnly?: boolean;
  disableEditing?: boolean;
  isGrouped?: boolean;
  size?: 'small' | 'medium' | 'large';
} & IntervalFilterInputProps;

export const IntervalFilterApplied = ({
  filter,
  viewOnly,
  disableEditing,
  isGrouped,
  size = 'large',
}: IntervalFilterAppliedProps) => {
  const { updateAppliedFilter } = useFiltersContext();
  const { values, id, operator, operatorOptions, label } = filter;

  const filterValue = values[0];

  const onChange = useCallback(
    (newValue: IntervalFilterValue) => {
      updateAppliedFilter(id, { values: [newValue] });
    },
    [id, updateAppliedFilter],
  );

  return (
    <FilterBlock
      filterId={id}
      fieldName={label}
      operator={operator}
      operatorOptions={operatorOptions}
      hideDeleteButton={viewOnly}
      disabled={disableEditing}
      size={size}
      isGrouped={isGrouped}
      input={
        <div
          className={classNames('flex items-center gap-2 h-full rounded-r-12', {
            '!bg-transparent': viewOnly,
          })}
        >
          <FilterInput
            size={1}
            className='rounded-r-12'
            onChange={(e) => {
              onChange({ min: Number(e.target.value), max: filterValue.max });
            }}
            value={filterValue.min}
            name='min'
            disabled={disableEditing}
          />
          <div>-</div>
          <FilterInput
            size={1}
            className={classNames('rounded-12', {})}
            onChange={(e) => {
              onChange({ min: filterValue.min, max: Number(e.target.value) });
            }}
            value={filterValue.max}
            name='max'
            disabled={disableEditing}
          />
        </div>
      }
    />
  );
};
