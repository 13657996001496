import { reducer as formReducer } from 'redux-form/immutable';

import { combineReducers, coreReducer, customizationReducer } from '@va/dashboard/reducers';
import { LOGOUT } from '@va/standalone/shared/actions';
import apiReducer from './api';
import appReducer from './app';
import uiReducer from './ui';

const rootReducer = combineReducers({
  form: formReducer,
  ui: uiReducer,
  api: apiReducer,
  app: appReducer,
  core: coreReducer,
  customization: customizationReducer,
});

const rootReducerWithLogout = (state, action) => rootReducer(action.type === LOGOUT ? undefined : state, action);

export default rootReducerWithLogout;
