import React from 'react';
import { IconProps } from '.';

export const ChartDataIcon = ({ className = '', color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_11507_14547'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='18'
        height='18'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.2734 2.81L13.9014 8.25196H18V9.75196H13.0986L10.7266 6.19391L7.72658 15.1939L4.09861 9.75196H0V8.25196H4.90139L7.27342 11.81L10.2734 2.81Z'
          fill='black'
        />
      </mask>
      <g mask='url(#mask0_11507_14547)'>
        <rect x='-3' y='-3' width='24' height='24' fill={color ?? 'var(--color-primary)'} />
      </g>
    </svg>
  );
};


