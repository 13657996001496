import { useTranslate } from '@va/localization';
import { DataViewOption } from '@va/types/report-block';
import { DataTableV8, useControlledTableState } from '@va/ui/components/data-table';
import { ReportBlock } from '@va/ui/components/report-block';
import { useMemo } from 'react';
import { useGetWebsiteInvoices } from './invoices-api-client';
import { useWebsiteInvoicesColumns } from './useWebsiteInvoicesColumns';

const WEBSITE_INVOICES_RB = 'website-invoices';

const WebsiteInvoices = () => {
  const translate = useTranslate();

  const visualizationComponents = useMemo(() => {
    return {
      [DataViewOption.table]: Table,
    };
  }, []);

  return (
    <ReportBlock
      id={WEBSITE_INVOICES_RB}
      nodes={{
        pagination: <div id={WEBSITE_INVOICES_RB} />,
      }}
      title={translate('standalone.accountSettings.invoices.websiteInvoices.table.title')}
      titleTooltip={translate('standalone.accountSettings.invoices.websiteInvoices.table.titleTooltip')}
      visualization={{ selectedView: DataViewOption.table }}
      visualizationComponents={visualizationComponents}
    ></ReportBlock>
  );
};

export default WebsiteInvoices;

const Table = () => {
  const columns = useWebsiteInvoicesColumns();
  const { isLoading, data } = useGetWebsiteInvoices();
  const { pagination, setPagination, grouping, setGrouping, sorting, setSorting } = useControlledTableState({
    pagination: { pageSize: 6 },
  });

  return (
    <DataTableV8
      id='website-invoices-table'
      columns={columns}
      data={data ?? []}
      isLoading={isLoading}
      state={{
        pagination,
        grouping,
        sorting,
      }}
      paginationContainerId={WEBSITE_INVOICES_RB}
      onPaginationChange={setPagination}
      onGroupingChange={setGrouping}
      onSortingChange={setSorting}
      manualSorting={false}
      manualPagination={false}
      className='max-h-500px'
      rowIdKey='receiptUrl'
    />
  );
};
