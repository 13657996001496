import React from 'react';
import {BLACK_COLOR, GREEN_COLOR, WHITE_COLOR} from '../constants';

export function CheckboxChecked(props) {
  return <svg className="checkbox-icon checked" version="1.1" height="16" width="16"
              viewBox="0 0 16 16" style={{enableBackground: 'new 0 0 16 16'}}>
    <g>
      <path fill={props.fill || GREEN_COLOR} d="M2,15.5c-0.8,0-1.5-0.7-1.5-1.5V2c0-0.8,0.7-1.5,1.5-1.5h12c0.8,0,1.5,0.7,1.5,1.5v12c0,0.8-0.7,1.5-1.5,1.5H2
		z"/>
      <path fill={props.fill || GREEN_COLOR} d="M14,1c0.6,0,1,0.5,1,1v12c0,0.6-0.5,1-1,1H2c-0.6,0-1-0.5-1-1V2c0-0.6,0.5-1,1-1H14 M14,0H2C0.9,0,0,0.9,0,2
		v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V2C16,0.9,15.1,0,14,0L14,0z"/>
    </g>
    <polygon fill={WHITE_COLOR} points="13.7,4 7,10.7 2.9,6.7 2.2,7.4 7,12.1 14.4,4.7 "/>
  </svg>;
}

export function CheckboxUnchecked(props) {
  return <svg className="checkbox-icon unchecked" version="1.1" height="16" width="16"
              viewBox="0 0 16 16" style={{enableBackground: 'new 0 0 16 16'}}>
    <g>
      <path fill={props.fill || BLACK_COLOR} d="M14,1c0.6,0,1,0.5,1,1v12c0,0.6-0.5,1-1,1H2c-0.6,0-1-0.5-1-1V2c0-0.6,0.5-1,1-1H14 M14,0H2C0.9,0,0,0.9,0,2
		v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V2C16,0.9,15.1,0,14,0L14,0z"/>
    </g>
  </svg>;
}
