import { useState } from 'react';

export const useUserMenu = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = (open: boolean) => setIsExpanded(open);

  const closeMenu = () => setIsExpanded(false);

  return { isExpanded, toggleExpanded, closeMenu };
};
