import { IconProps } from './index';

export const HeatmapsIcon = ({ className = '', color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.5541 4.94657C14.7649 5.52868 14.9056 6.14431 14.9662 6.78329C14.4672 6.49898 13.9235 6.28418 13.3483 6.15209C12.9149 6.05258 12.4636 6 12 6C8.68629 6 6 8.68629 6 12C6 12.4636 6.05258 12.9149 6.15209 13.3483C6.28418 13.9235 6.49898 14.4672 6.78329 14.9662C6.14431 14.9056 5.52868 14.7649 4.94657 14.5541C2.06153 13.5096 0 10.7455 0 7.5C0 3.35786 3.35786 0 7.5 0C10.7455 0 13.5096 2.06153 14.5541 4.94657ZM7.5 1.5C9.7345 1.5 11.6845 2.72207 12.7171 4.53393C12.4809 4.51148 12.2417 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 12.2417 4.51148 12.4809 4.53393 12.7171C2.72207 11.6845 1.5 9.7345 1.5 7.5C1.5 4.18629 4.18629 1.5 7.5 1.5ZM7.50004 15.9687C7.22842 15.661 6.98788 15.3252 6.78333 14.9662C7.01922 14.9886 7.25829 15 7.50004 15C7.86619 15 8.22621 14.9738 8.57832 14.9231C11.8575 14.4509 14.451 11.8574 14.9231 8.57829C14.9738 8.22617 15 7.86615 15 7.5C15 7.25825 14.9886 7.01918 14.9662 6.78329C15.3252 6.98784 15.661 7.22838 15.9688 7.5C16.1512 7.66105 16.3238 7.83303 16.4856 8.01491C17.4277 9.07463 18 10.4705 18 12C18 15.3137 15.3137 18 12 18C10.4705 18 9.07466 17.4277 8.01495 16.4855C7.83307 16.3238 7.66109 16.1512 7.50004 15.9687ZM12 16.5C11.3409 16.5 10.7146 16.3584 10.15 16.1035C12.9894 15.23 15.23 12.9894 16.1036 10.15C16.3584 10.7145 16.5 11.3409 16.5 12C16.5 14.4853 14.4853 16.5 12 16.5Z'
        fill={color ?? 'black'}
      />
    </svg>
  );
};
