import { TEST_IDS, platform } from '@va/constants';
import { getWebsite } from '@va/dashboard/selectors/core';
import { getSupportCenterDomain } from '@va/dashboard/selectors/customization';
import { CheckmarkIcon, NavPageVisits as NavPages } from '@va/icons';
import { useTranslate } from '@va/localization';
import { getTrackingCode } from '@va/standalone/shared/actions';
import { appHistory } from '@va/standalone/shared/router';
import { Button, Paragraph } from '@va/ui/design-system';
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

type CopyCreatedEventTagSnippetProps = {
  handleCreateNewEventTag: () => void;
  handleCancelClick: () => void;
  codeSnippet: string;
};

const CopyCreatedEventTagSnippet: React.FC<CopyCreatedEventTagSnippetProps> = ({
  handleCreateNewEventTag,
  handleCancelClick,
  codeSnippet,
}) => {
  const supportCenterDomain = useSelector(getSupportCenterDomain);
  const translate = useTranslate();
  const dispatch = useDispatch();
  const website = useSelector(getWebsite);

  return (
    <div className='flex flex-col justify-between min-h-[600px] xs:min-h-[400px]'>
      <div>
        <div className='flex sm:flex-col flex-nowrap w-full justify-between items-center flex-row min-h-[72px] bg-white-snow rounded-18 pl-18px pr-12px sm:p-12px'>
          <Paragraph className='flex-wrap mr-4 mb-0 sm:mb-4 p-2 max-w-full sm:max-w-[400px]'>{codeSnippet}</Paragraph>
          <div>
            <CopyCodeToClipBoard text={codeSnippet} />
          </div>
        </div>
        <Paragraph colorClassName='text-gray-devilSolid' className='mt-[15px] mb-1.5 ml-[18px]'>
          {translate('eventTracking.addEventTagInfo')}
        </Paragraph>
        <Paragraph colorClassName='text-gray-devilSolid' className='mt-[15px] mb-1.5 ml-[18px]'>
          {website.platform === platform.CUSTOM && translate('eventTracking.updateTrackingCode.standalone')}
          {website.platform !== platform.CUSTOM &&
            website.platform !== platform.WIX &&
            translate('eventTracking.updateTrackingCode.plugins')}
        </Paragraph>
        {website.platform === platform.CUSTOM && (
          <div
            className='text-gray-devilSolid cursor-pointer my-5 ml-[18px]'
            onClick={() => {
              dispatch(getTrackingCode());
              handleCancelClick();
              appHistory.push(`/website/${website?.id}/settings/tracking-code`);
            }}
          >
            {translate('button.viewUpdatedTrackingCode')}
          </div>
        )}
        <div className='flex sm:flex-col'>
          <Button
            text={translate('eventTracking.htmlInstructions.label')}
            color='secondary'
            disabled={false}
            className='w-full'
            onClick={() =>
              window.open(translate('eventTracking.htmlInstructions.link', { supportCenterDomain }), '_blank')
            }
          />
          {/* TODO-  Uncomment these buttons when we have link  */}
          {/*<Button
            text={translate('eventTracking.wordpressInstructions.label')}
            color='secondary'
            disabled={false}
            className='w-full ml-[18px] sm:ml-0 mt-0 sm:mt-2'
            onClick={() => window.open(translate('eventTracking.wordpressInstructions.link'))}
          />
          <Button
            text={translate('eventTracking.wixInstructions')}
            color='secondary'
            disabled={false}
            className='w-full ml-[18px] sm:ml-0 mt-0 sm:mt-2'
            onClick={() => window.open(translate('eventTracking.wixInstructions.link'))}
          />*/}
        </div>
      </div>
      <div className='flex sm:flex-col'>
        <Button
          text={translate('button.close')}
          color='secondary'
          disabled={false}
          className='w-full justify-center'
          onClick={handleCancelClick}
        />
        <Button
          text={translate('eventTracking.createNewEventTag')}
          onClick={handleCreateNewEventTag}
          color='primary'
          className='w-full justify-center ml-[18px] sm:ml-0 mt-0 sm:mt-4'
        />
      </div>
    </div>
  );
};

export default CopyCreatedEventTagSnippet;

type CopyCodeToClipBoardProps = {
  text: string;
};

const CopyCodeToClipBoard: React.FC<CopyCodeToClipBoardProps> = ({ text }) => {
  const translate = useTranslate();
  const [copied, setCopied] = useState(false);

  return (
    <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
      <Button
        text={copied ? translate('button.copiedToClipboardText') : translate('button.copyToClipboardText')}
        color={copied ? 'secondary' : 'primary'}
        className='w-full'
        icon={() => {
          if (copied) return <CheckmarkIcon className='fill-primary' />;
          return <NavPages color={`white`} isActive />;
        }}
        data-testid={TEST_IDS.generic.buttons.copyToClipboard}
      />
    </CopyToClipboard>
  );
};
