export function AddClients(props) {
  return (
    <svg {...props} width='270' height='180' viewBox='0 0 270 180' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='270' height='180' rx='24' fill='#F9F9F9' />
      <path d='M45 43C45 38.5817 48.5817 35 53 35H217C221.418 35 225 38.5817 225 43V51H45V43Z' fill='#EEEEEE' />
      <path d='M60 24C60 19.5817 63.5817 16 68 16H202C206.418 16 210 19.5817 210 24V32H60V24Z' fill='#F3F3F3' />
      <circle cx='53' cy='43' r='2' fill='#DDDDDD' />
      <circle cx='61' cy='43' r='2' fill='#DDDDDD' />
      <circle cx='69' cy='43' r='2' fill='#DDDDDD' />
      <path d='M30 66C30 59.3726 35.3726 54 42 54H228C234.627 54 240 59.3726 240 66V180H30V66Z' fill='white' />
      <path d='M30 66C30 59.3726 35.3726 54 42 54H228C234.627 54 240 59.3726 240 66V78H30V66Z' fill='#F3F3F3' />
      <circle cx='42' cy='66' r='3' fill='#ED290E' />
      <circle cx='54' cy='66' r='3' fill='#F7DA1B' />
      <circle cx='66' cy='66' r='3' fill='#0EED82' />
      <circle cx='135' cy='129' r='30' fill='#F0FBFA' />
      <rect x='133.5' y='120' width='3' height='18' fill='#04B5A6' />
      <rect x='144' y='127.5' width='3' height='18' transform='rotate(90 144 127.5)' fill='#04B5A6' />
    </svg>
  );
}

export function InviteClients(props) {
  return (
    <svg {...props} width='270' height='180' viewBox='0 0 270 180' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='270' height='180' rx='24' fill='#F9F9F9' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M150.619 31.3877C141.631 23.6839 128.369 23.6839 119.381 31.3877L30 108V168C30 174.627 35.3726 180 42 180H228C234.627 180 240 174.627 240 168V108L150.619 31.3877Z'
        fill='#DDDDDD'
      />
      <rect x='60' y='60' width='150' height='120' fill='white' />
      <circle cx='135' cy='120' r='30' fill='#F0FBFA' />
      <path d='M126 120L132 126C135 123 144 114 144 114' stroke='#04B5A6' strokeWidth='3' />
      <path d='M120 180L240 180L240 108L120 180Z' fill='#F3F3F3' />
      <path d='M150 180L30 180L30 108L150 180Z' fill='#F3F3F3' />
      <path
        d='M121.237 147.634C129.501 141.85 140.499 141.85 148.763 147.634L195 180H75L121.237 147.634Z'
        fill='#EEEEEE'
      />
    </svg>
  );
}

export function CustomizeClients(props) {
  return (
    <svg {...props} width='270' height='180' viewBox='0 0 270 180' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='270' height='180' rx='24' fill='#F9F9F9' />
      <path d='M90 33C90 26.3726 95.3726 21 102 21H270V156C270 169.255 259.255 180 246 180H90V33Z' fill='white' />
      <path d='M90 33C90 26.3726 95.3726 21 102 21H270V54H90V33Z' fill='#F3F3F3' />
      <circle cx='109.5' cy='37.5' r='4.5' fill='#ED290E' />
      <circle cx='127.5' cy='37.5' r='4.5' fill='#F7DA1B' />
      <circle cx='145.5' cy='37.5' r='4.5' fill='#0EED82' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M83.891 74.4198C80.1677 67.1934 69.8356 67.1934 66.1122 74.4198L65.8273 74.9728C63.59 79.315 58.5431 81.4054 53.8907 79.917L53.2982 79.7274C45.5553 77.2502 38.2492 84.5567 40.7268 92.2995L40.9154 92.889C42.4043 97.5418 40.3135 102.589 35.9707 104.826L35.4204 105.11C28.1932 108.833 28.1932 119.166 35.4204 122.889L35.9707 123.173C40.3135 125.41 42.4043 130.458 40.9154 135.11L40.7268 135.7C38.2492 143.443 45.5553 150.749 53.2982 148.272L53.8907 148.082C58.5431 146.594 63.59 148.684 65.8273 153.027L66.1122 153.58C69.8356 160.806 80.1677 160.806 83.891 153.58L84.176 153.027C86.4133 148.684 91.4601 146.594 96.1125 148.082L96.7051 148.272C104.448 150.749 111.754 143.443 109.276 135.7L109.088 135.11C107.599 130.458 109.69 125.41 114.033 123.173L114.583 122.889C121.81 119.166 121.81 108.833 114.583 105.11L114.033 104.826C109.69 102.589 107.599 97.5418 109.088 92.889L109.276 92.2995C111.754 84.5567 104.448 77.2502 96.7051 79.7274L96.1125 79.917C91.4601 81.4054 86.4133 79.3149 84.176 74.9728L83.891 74.4198ZM75.0336 128.272C82.8994 128.272 89.276 121.896 89.276 114.031C89.276 106.165 82.8994 99.7894 75.0336 99.7894C67.1677 99.7894 60.7912 106.165 60.7912 114.031C60.7912 121.896 67.1677 128.272 75.0336 128.272Z'
        fill='#CCCCCC'
      />
      <rect x='174' y='84' width='96' height='12' rx='6' fill='#F0FBFA' />
      <rect x='174' y='111' width='96' height='12' rx='6' fill='#F3F3F3' />
      <rect x='174' y='138' width='96' height='12' rx='6' fill='#F3F3F3' />
      <circle cx='156' cy='90' r='6' fill='#59CEC4' />
      <circle cx='156' cy='117' r='6' fill='#F3F3F3' />
      <circle cx='156' cy='144' r='6' fill='#F3F3F3' />
    </svg>
  );
}

export function EarnCommission(props) {
  return (
    <svg {...props} width='270' height='180' viewBox='0 0 270 180' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='270' height='180' rx='24' fill='#F9F9F9' />
      <path d='M30 57C30 50.3726 35.3726 45 42 45H270V135H42C35.3726 135 30 129.627 30 123V57Z' fill='white' />
      <rect x='147' y='72' width='90' height='12' rx='6' fill='#F3F3F3' />
      <rect x='147' y='96' width='60' height='12' rx='6' fill='#F3F3F3' />
      <circle cx='129' cy='78' r='6' fill='#F3F3F3' />
      <circle cx='129' cy='102' r='6' fill='#F3F3F3' />
      <path d='M30 162C30 155.373 35.3726 150 42 150H270V156C270 169.255 259.255 180 246 180H30V162Z' fill='#F3F3F3' />
      <path d='M270 12C270 5.37258 264.627 0 258 0H30V6C30 19.2548 40.7452 30 54 30H270V12Z' fill='#F3F3F3' />
      <circle cx='75' cy='90' r='30' fill='#F0FBFA' />
      <path
        d='M75 81L78.174 85.6313L83.5595 87.2188L80.1357 91.6687L80.2901 97.2812L75 95.4L69.7099 97.2812L69.8643 91.6687L66.4405 87.2188L71.826 85.6313L75 81Z'
        stroke='#04B5A6'
        strokeWidth='3'
      />
    </svg>
  );
}

export function Preview(props) {
  const { color, ...restProps } = props;
  const colorValue = color ? color : '#04B5A6';
  return (
    <svg {...restProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.05026 6.36396C6.78393 3.63029 11.2161 3.63029 13.9497 6.36396L16.5858 9L13.9497 11.636C11.2161 14.3697 6.78392 14.3697 4.05025 11.636L1.41421 9L4.05026 6.36396Z'
        stroke={colorValue}
        strokeWidth='2'
      />
      <circle cx='9' cy='9' r='2' stroke={colorValue} strokeWidth='2' />
    </svg>
  );
}
