import { GREEN_COLOR } from '@va/constants';

export const SpecificPages = ({ className, active }: { className?: string; active?: boolean }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 124 84' className={className}>
      <g fill='none' fillRule='evenodd' opacity={active ? '1' : '.6'}>
        <path fill='#FFFFFF' d='M.83 83.928h122.71V.839H.83z'></path>
        <path fill='#FFFFFF' d='M.9.903h121.479v81.29H.9z'></path>
        <path fill='#13232A' d='M0 83.928h123.54V0H0v83.928zm1.732-1.75h120.075V1.748H1.732v80.43z'></path>
        <path
          fill={active ? GREEN_COLOR : '#000'}
          opacity='.395'
          d='M8.998 64.84h16.583v-1.614H8.998zM32.394 65.744h16.583v-1.615H32.394zM53.09 65.744h16.583v-1.615H53.091zM74.687 65.744h16.582v-1.615H74.687zM8.998 71.555h29.849V69.94H8.998zM43.192 71.163h29.849v-1.615H43.192zM56.69 76.582H86.54v-1.614H56.69zM76.487 71.163h29.848v-1.615H76.487zM8.998 76.59h19.07v-1.614H8.998zM46.792 60.324h19.07V58.71h-19.07zM36.894 54.905h19.07V53.29h-19.07zM80.086 60.324h19.07V58.71h-19.07zM70.188 54.905h19.07V53.29h-19.07zM57.59 49.486h19.07V47.87H57.59zM92.684 54.905h19.07V53.29h-19.07zM80.086 49.486h19.07V47.87h-19.07zM32.394 76.582h7.462v-1.614h-7.462zM44.092 76.582h7.462v-1.614h-7.462zM95.383 65.744h7.463v-1.615h-7.463zM102.582 49.486h7.462V47.87h-7.462zM102.582 60.324h7.462V58.71h-7.462zM69.288 60.324h7.462V58.71h-7.462zM59.39 54.905h7.462V53.29H59.39zM46.792 49.486h7.462V47.87h-7.462zM17.097 32.324h16.582V30.71H17.097zM40.493 33.228h16.582v-1.615H40.493zM61.19 33.228h16.582v-1.615H61.189zM82.786 33.228h16.582v-1.615H82.786zM17.097 39.038h29.848v-1.614H17.097zM51.291 38.647H81.14v-1.615H51.291zM64.789 44.066h29.848v-1.614H64.79zM84.585 38.647h29.849v-1.615H84.585zM17.097 44.074h19.07V42.46h-19.07zM54.89 27.808h19.07v-1.614H54.89zM44.992 22.389h19.07v-1.615h-19.07zM88.185 27.808h19.07v-1.614h-19.07zM78.286 22.389h19.07v-1.615h-19.07zM65.689 16.97h19.07v-1.615h-19.07zM100.782 22.389h19.07v-1.615h-19.07zM88.185 16.97h19.07v-1.615h-19.07zM17.097 49.11h7.462v-1.615h-7.462zM40.493 44.066h7.462v-1.614h-7.462zM52.19 44.066h7.463v-1.614h-7.462zM103.482 33.228h7.462v-1.615h-7.462zM110.68 16.97h7.463v-1.615h-7.462zM110.68 27.808h7.463v-1.614h-7.462zM77.387 27.808h7.462v-1.614h-7.462zM67.488 22.389h7.462v-1.615h-7.462zM54.89 16.97h7.463v-1.615H54.89z'
        ></path>
        <path fill='#FFFFFF' d='M1.8 1.806L122.379.903 1.799 82.193z'></path>
        <path stroke='#13232A' strokeWidth='2' d='M.9 83.097L122.379.839'></path>
        <path
          fill='#000'
          d='M12.437 22.66h77.547l18.631-12.589H12.437v12.59zm2.068-2.098h80.878l11.164-8.392H14.505v8.392zM12.437 29.375h16.582v-2.518H12.437zM12.437 36.089h29.848V33.57H12.437zM12.437 41.125h19.07v-2.518h-19.07zM12.437 46.16h7.462v-2.518h-7.462z'
        ></path>
      </g>
    </svg>
  );
};

export const AnyPages = ({ className, active }: { className?: string; active?: boolean }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 87' className={className}>
      <g fill='none' fillRule='evenodd'>
        <path fill='#FFFFFF' d='M1.149 86.92h126.487V1.853H1.149z'></path>
        <path fill='#13232A' d='M.294 86.92h127.342V.994H.294V86.92zm1.786-1.791h123.77V2.783H2.08V85.13z'></path>
        <path
          fill='#000'
          d='M13.114 24.194h99.138V11.305H13.114v12.889zm2.132-2.148h94.874v-8.593H15.246v8.593zM13.114 31.068h17.093V28.49H13.114zM13.114 37.942h30.767v-2.578H13.114zM13.114 43.098h19.657V40.52H13.114zM13.114 48.253h7.692v-2.578h-7.692z'
        ></path>
        <path
          fill='#13232A'
          d='M42.971 58.564l-7.214 10.34-4.542-3.265-8.7 9.251h23.93V62.373l-3.474-3.809zm.19 2.964l1.443 1.582v9.906H26.827l4.62-4.915 3.249 2.334 1.5 1.078 1.062-1.524 5.904-8.461z'
        ></path>
        <path
          fill={active ? '#00796B' : '#CACACA'}
          d='M46.197 63.105l4.104-6.032 9.02 11.381 7.104-10.47 9.584.227 11.163-18.55 9.697 12.974V74.03h-58.97z'
        ></path>
        <path
          fill={active ? GREEN_COLOR : '#70858E'}
          d='M35.785 74.752l10.36-12.234L60.91 73.156l15.542-12.5 6.735 2.927 14.247-16.755 13.987 27.924z'
        ></path>
      </g>
    </svg>
  );
};
