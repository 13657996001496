import { tabNames } from '@va/constants';
import { PageContent, PageHeader, TitleAndContextMenu } from '@va/dashboard/shared/ui-layout';
import { useActiveTab } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { NestedNavigationBackButton } from '@va/ui/design-system';
import { useHistory, useParams } from 'react-router';
import EnableSessionRecordingsBanner from './components/banner/EnableSessionRecordingsBanner';
import { AbesByPageRb, RecordingsWithAbesRb } from './components/details-report-blocks';

export const ABEDetailsTab = () => {
  useActiveTab(tabNames.eventTracking);
  const translate = useTranslate();
  const { trigger } = useParams<{ trigger: string }>();
  const history = useHistory();

  const onBackClick = () => {
    history.push('/behaviour/event-tracking/alarming-behavior');
  };

  return (
    <>
      <PageHeader
        title={
          <TitleAndContextMenu
            title={
              <NestedNavigationBackButton
                onBackClick={onBackClick}
                parentPage={translate(`all.eventTracking.alarmingBehaviorEvents.title`)}
                currentPage={translate(`all.eventTracking.alarmingBehaviorEvents.${trigger}`)}
              />
            }
            onBackClick={onBackClick}
          />
        }
        hideFeatureCounter
      />
      <PageContent>
        <EnableSessionRecordingsBanner />
        <div className='flex flex-col gap-30px'>
          <AbesByPageRb />
          <RecordingsWithAbesRb />
        </div>
      </PageContent>
    </>
  );
};
