import { CONSENT_FLAG, tabNames } from '@va/constants';
import { ConsentSettings } from '@va/dashboard/feature/consent';
import { PageContent } from '@va/dashboard/shared/ui-layout';
import { useActiveTab } from '@va/dashboard/util-hooks';

export default function ConsentPage() {
  useActiveTab(tabNames.consent);
  return (
    <PageContent className='account-tab'>
      <ConsentSettings priorConsent={CONSENT_FLAG.YOUTUBE} />
    </PageContent>
  );
}
