import React from 'react';
import { IconProps } from '.';

export const MoveCursorIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_11468_36279'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='18'
        height='18'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.5304 3.53033L9.00006 0L5.46973 3.53033L6.53039 4.59099L8.25006 2.87132V6H9.75006V2.87132L11.4697 4.59099L12.5304 3.53033ZM10.5 9C10.5 9.82843 9.82843 10.5 9 10.5C8.17157 10.5 7.5 9.82843 7.5 9C7.5 8.17157 8.17157 7.5 9 7.5C9.82843 7.5 10.5 8.17157 10.5 9ZM9.00006 18L5.46974 14.4697L6.5304 13.409L8.25007 15.1287V12H9.75007V15.1287L11.4697 13.409L12.5304 14.4697L9.00006 18ZM3.53033 5.46967L0 9L3.53033 12.5303L4.59099 11.4697L2.87132 9.75H6V8.25H2.87132L4.59099 6.53033L3.53033 5.46967ZM18 8.99999L14.4697 12.5303L13.409 11.4697L15.1287 9.74999H12V8.24999H15.1287L13.409 6.53032L14.4697 5.46966L18 8.99999Z'
          fill='black'
        />
      </mask>
      <g mask='url(#mask0_11468_36279)'>
        <rect x='-3' y='-3' width='24' height='24' fill={color ?? 'var(--color-primary)'} />
      </g>
    </svg>
  );
};


