import { LocalizationContext } from '@va/localization';
import { PureComponent } from 'react';

/** @deprecated */
export function withTranslate(WrappedComponent) {
  class WithTranslate extends PureComponent {
    render() {
      const { translate } = this.context;
      return <WrappedComponent translate={translate} {...this.props} />;
    }
  }
  WithTranslate.contextType = LocalizationContext;

  return WithTranslate;
}
