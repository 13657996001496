import QuestionDateIcon from '@va/images/date.svg?react';
import QuestionEmailIcon from '@va/images/email.svg?react';
import QuestionLongTextIcon from '@va/images/long-text.svg?react';
import QuestionMultipleChoiceIcon from '@va/images/multiple-choice.svg?react';
import QuestionPhoneIcon from '@va/images/phone.svg?react';
import QuestionRatingScaleIcon from '@va/images/rating-scale.svg?react';
import QuestionRatingIcon from '@va/images/rating.svg?react';
import QuestionShortTextIcon from '@va/images/short-text.svg?react';
import QuestionSliderIcon from '@va/images/slider.svg?react';
import QuestionWebsiteLinkIcon from '@va/images/website-link.svg?react';

export const FEEDBACK_STATUS = {
  INACTIVE: 1,
  DRAFT: 2,
  ACTIVE: 3,
};

export const VISITOR_TYPE = {
  ALL: 'all',
  NEW: 'new',
  RETURNING: 'returning',
  0: 'NEW',
  1: 'RETURNING',
};

export const RATING_SHAPE = {
  STAR: 1,
  HEART: 2,
  // CIRCLE: 3,
  THUMBS_UP: 4,
};

export const LIMIT = {
  CHARS: {
    QUESTION: 200,
    QUESTION_DESCRIPTION: 150,
    CHOICE_OPTION: 75,
  },
  NUMBER: {
    MULTIPLE_CHOICE: {
      MIN: 2,
      MAX: 6,
    },
    VERTICAL_PADDING: {
      MIN: 0,
      MAX: 40,
    },
    HORIZONTAL_PADDING: {
      MIN: 0,
      MAX: 60,
    },
    SURVEY_VISUALIZATION: {
      MIN: 0,
    },
  },
};

export const QUESTION_TYPE = {
  SHORT_TEXT: 1,
  LONG_TEXT: 2,
  MULTIPLE_CHOICE: 3,
  SLIDER: 4,
  RATING: 5,
  RATING_SCALE: 6,
  EMAIL: 7,
  PHONE: 8,
  WEBSITE_LINK: 9,
  DATE: 10,
};

export const TEXT_TYPE_QUESTION = [
  QUESTION_TYPE.SHORT_TEXT,
  QUESTION_TYPE.LONG_TEXT,
  QUESTION_TYPE.EMAIL,
  QUESTION_TYPE.PHONE,
  QUESTION_TYPE.WEBSITE_LINK,
  QUESTION_TYPE.DATE,
];

export function getLabelFromValue(value) {
  return Object.keys(QUESTION_TYPE).find((item) => QUESTION_TYPE[item] === value);
}

export const getIcon = (type) => {
  switch (type) {
    case QUESTION_TYPE.SHORT_TEXT:
      return QuestionShortTextIcon;

    case QUESTION_TYPE.LONG_TEXT:
      return QuestionLongTextIcon;

    case QUESTION_TYPE.MULTIPLE_CHOICE:
      return QuestionMultipleChoiceIcon;

    case QUESTION_TYPE.SLIDER:
      return QuestionSliderIcon;

    case QUESTION_TYPE.RATING_SCALE:
      return QuestionRatingScaleIcon;

    case QUESTION_TYPE.RATING:
      return QuestionRatingIcon;

    case QUESTION_TYPE.EMAIL:
      return QuestionEmailIcon;

    case QUESTION_TYPE.PHONE:
      return QuestionPhoneIcon;

    case QUESTION_TYPE.WEBSITE_LINK:
      return QuestionWebsiteLinkIcon;

    case QUESTION_TYPE.DATE:
      return QuestionDateIcon;

    default:
      return null;
  }
};

export const feedbackTransKeys = {
  FEEDBACK_POLLS_LIMIT_ERROR: 'feedback.polls.paddingLimitError',
  FEEDBACK_POLLS_COLOR_CODE_ERROR: 'feedback.polls.colorCodeError',
  FEEDBACK_POLLS_QUESTION_WITH_MULTICHOICES_REQUIRED_ERROR: 'feedback.polls.question.multiChoice.requiredError',
  FEEDBACK_SURVEYS_VISUALIZATION_ERROR: 'feedback.surveys.visualizationLimitError',
  FEEDBACK_SURVEYS_URL_VALIDATION_WITH_PROTOCOL: 'feedback.surveys.validation.websiteUrlWithProtocol',
};

export const positionChangeAction = { moveUp: 1, moveDown: 2 };

export const backButtonConfirmationTranslationKeysValues = {
  cardTitle: 'feedback.polls.goBackNote',
  cardDescription: 'feedback.polls.goBack.confirmation',
  cardCancelBtnText: 'button.cancel',
  cardConfirmBtnText: 'button.yes',
};
