import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { EditThemeModalComponent, ThemeColorsType } from '@va/ui/components/white-label-customisation';
import { useCallback, useEffect } from 'react';
import { useEditTheme, useGetAllAgencyThemes } from '../apiClient';
import { formatThemeColors } from './ThemeSectionContainer';

const EditCustomThemeModal = ({
  isOpen,
  themeId,
  themeName,
  closeModal,
  themeColors,
}: {
  isOpen: boolean;
  themeId: string;
  themeName: string;
  closeModal: () => void;
  themeColors: ThemeColorsType;
}) => {
  const translate = useTranslate();

  const { execute, isLoading, error, isSucceeded, clearError, resetSuccessState } = useEditTheme();

  const { mutate: mutateAllAgencyThemes } = useGetAllAgencyThemes();

  const { showErrorNotification, showSuccessNotification } = useAddNotification();

  const onSave = useCallback(
    (values: ThemeColorsType & { name: string }) => {
      const { name, ...colors } = values;

      const formattedColors = formatThemeColors(colors);

      execute(themeId, { name, ...formattedColors });
    },
    [execute, themeId],
  );

  useEffect(() => {
    if (!isSucceeded) return;
    mutateAllAgencyThemes();
    closeModal();
    showSuccessNotification();
    resetSuccessState();
  }, [closeModal, isSucceeded, mutateAllAgencyThemes, resetSuccessState, showSuccessNotification]);

  useEffect(() => {
    if (error) {
      showErrorNotification();
      clearError();
    }
  }, [clearError, error, showErrorNotification]);

  return (
    <EditThemeModalComponent
      themeName={themeName}
      initialColors={themeColors}
      title={translate('whiteLabel.settings.theme.editCustom')}
      closeModal={closeModal}
      onSave={onSave}
      isOpen={isOpen}
      isSaveButtonDisabled={isLoading}
    />
  );
};

export default EditCustomThemeModal;
