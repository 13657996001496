import React from 'react';
import PropTypes from 'prop-types';

export function OpenInOther(props) {
  const fillColor = props.fillColor || '#009688';
  return <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <g id="002-desktop-billing-flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="30-app-standalone-subscription-settings-professional" transform="translate(-698.000000, -697.000000)" fill={fillColor}>
        <g id="invoices" transform="translate(123.000000, 609.000000)">
          <g id="table-entries" transform="translate(18.000000, 88.000000)">
            <g id="view-icon" transform="translate(557.000000, 0.000000)">
              <polygon id="Fill-1" points="0 -0.000225 0 17.999775 18 17.999775 18 10.499775 17.25 10.499775 17.25 17.249775 0.75 17.249775 0.75 0.749775 7.5 0.749775 7.5 -0.000225"/>
              <polygon id="Fill-2" points="7.23465 10.2348 7.7649 10.76505 17.7504 0.7803 17.22015 0.2493"/>
              <polygon id="Fill-3" points="10.5003 -0.000225 10.5003 0.749775 17.2503 0.749775 17.2503 7.499775 18.0003 7.499775 18.0003 -0.000225"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>;
}

OpenInOther.propTypes = {
  fillColor: PropTypes.string,
};
