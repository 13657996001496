import React from 'react';
import { IconProps } from '.';

export const ClickIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={className}
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_11468_40787'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='19'
        height='18'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.75 1.5C13.0631 1.5 15.8744 3.64818 16.8671 6.62765L18.2905 6.15318C17.0992 2.57782 13.7257 0 9.75 0C4.77944 0 0.75 4.02944 0.75 9C0.75 12.9757 3.32783 16.3493 6.9032 17.5405L7.37767 16.1171C4.39819 15.1244 2.25 12.3131 2.25 9C2.25 4.85786 5.60786 1.5 9.75 1.5ZM12.75 18L9.75 9L18.75 12L14.25 13.5L12.75 18ZM14.0202 7.57659C13.4246 5.78891 11.7378 4.5 9.75 4.5C7.26472 4.5 5.25 6.51472 5.25 9C5.25 10.9878 6.53891 12.6746 8.32659 13.2702L7.85212 14.6936C5.46855 13.8995 3.75 11.6504 3.75 9C3.75 5.68629 6.43629 3 9.75 3C12.4004 3 14.6495 4.71854 15.4436 7.10212L14.0202 7.57659ZM9.75 7.5C10.4126 7.5 10.9749 7.92963 11.1734 8.52551L12.5968 8.05104C12.1997 6.85926 11.0752 6 9.75 6C8.09315 6 6.75 7.34315 6.75 9C6.75 10.3252 7.60926 11.4497 8.80104 11.8468L9.27549 10.4234C8.67962 10.2248 8.25 9.6626 8.25 9C8.25 8.17157 8.92157 7.5 9.75 7.5Z'
          fill='black'
        />
      </mask>
      <g mask='url(#mask0_11468_40787)'>
        <rect x='-2.25' y='-3' width='24' height='24' fill={color ?? 'var(--color-primary)'} />
      </g>
    </svg>
  );
};


