import { useTranslate } from '@va/localization';
import { ConfirmationDialogType, ConfirmationModal } from '@va/shared/util-confirmation';

export const DeleteSingleRecordingConfirmationDialog: ConfirmationDialogType = ({ onClose, onConfirm }) => {
  const translate = useTranslate();

  return (
    <ConfirmationModal
      title={translate('recordings.actions.delete')}
      isOpen={true}
      onConfirmation={onConfirm}
      onClose={onClose}
      info={translate('recordings.actions.info.delete')}
    />
  );
};
