// @ts-nocheck
import { Link } from '@va/util/router';
import parse from 'html-react-parser';

export const replaceUnicode = (str: string) => {
  return str && str.replace(/&quot;/g, '"').replace(/&amp;/g, '&');
};

export const handleCustomTags = (text: string) => {
  const interpretedTransString = parse(text);

  const customTagsArray = ['internal-link'];
  if (typeof interpretedTransString == 'object' && interpretedTransString.length > 1) {
    const attributes = {};
    let internalLinkIndex = interpretedTransString.findIndex(function (element) {
      return customTagsArray.indexOf(element.type) !== -1;
    });
    while (internalLinkIndex >= 0) {
      const internalLink = interpretedTransString[internalLinkIndex];
      attributes.to = internalLink.props.to || '/';
      attributes.className = internalLink.props.class || 'bold';
      if (internalLink.props.openmodalname) attributes.openmodalname = internalLink.props.openmodalname;
      if (internalLink.props.closemodalname) attributes.closemodalname = internalLink.props.closemodalname;
      interpretedTransString[internalLinkIndex] = (
        <Link {...attributes}>{replaceUnicode(internalLink.props.children)}</Link>
      );
      internalLinkIndex = interpretedTransString.findIndex(function (element) {
        return customTagsArray.indexOf(element.type) !== -1;
      });
    }
  }
  if (customTagsArray.indexOf(interpretedTransString.type) >= 0) {
    const attributes = {};
    switch (interpretedTransString.type) {
      case 'internal-link':
        attributes.to = interpretedTransString.props.to || '/';
        attributes.className = interpretedTransString.props.class || 'bold';
        if (interpretedTransString.props.openmodalname)
          attributes.openmodalname = interpretedTransString.props.openmodalname;
        if (interpretedTransString.props.closemodalname)
          attributes.closemodalname = interpretedTransString.props.closemodalname;
        return <Link {...attributes}>{replaceUnicode(interpretedTransString.props.children)}</Link>;
      default:
        console.log('Wrong value for interpretedTransString.type');
        return;
    }
  } else {
    return interpretedTransString;
  }
};
