export const AmazonSilkIcon: React.FC<JSX.IntrinsicElements['svg']> = (props) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 24 24'
      xmlSpace='preserve'
      {...props}
    >
      <path
        d='M11.9,2.5c5.3,0,9.6,4.2,9.6,9.4s-4.3,9.4-9.6,9.4s-9.6-4.2-9.6-9.4S6.6,2.5,11.9,2.5 M11.9,1.5
			C6.1,1.5,1.3,6.2,1.3,11.9S6,22.3,11.9,22.3s10.6-4.7,10.6-10.4S17.8,1.5,11.9,1.5L11.9,1.5z'
      />
      <path
        d='M7.4,2.6c0,0-1.4,0.2-2.2,1.1S4.4,4.7,4,5s-0.8,1.3-1,2c-0.2,0.7-0.2,2.5,2.2,3.7s5.8,3.9,5.8,3.9s2.1,2,2,4
      s-0.8,3-3.4,3.3c-2.7,0.2-5.7-3.1-5.7-3.1s1,2,3.4,3c2.4,1,4.8,0.8,4.8,0.8s5-0.1,5.2-5.3c0.2-5.3-6-8.6-6-8.6s-4.5-2.9-5-3.9
      S6.5,3.1,7.4,2.6z'
      />
    </svg>
  );
};
