export enum PRIVACY_FEATURES {
  ipAddressTracking = 'ipAddressTracking',
  individualPageHistory = 'individualPageHistory',
  returningVisitorTracking = 'returningVisitorTracking',
  screenResolutionTracking = 'screenResolutionTracking',
}

export enum PrivacyLevelsEnum {
  defaultPrivacy = 0,
  basicPrivacy = 1,
  GDPRMode = 2,
  completePrivacy = 3,
}

export type PrivacyLevelType = {
  levelKey: PrivacyLevelsEnum;
  selected: boolean;
  exceptions: string[];
};
