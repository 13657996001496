import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { getAgencyUi } from '@va/standalone/shared/actions';
import { LegalLinksComponent, LinkEnum } from '@va/ui/components/white-label-customisation';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateAgencyUi } from '../apiClient';
import useAgencyUi from '../useAgencyUi';

const LegalLinkContainer = () => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  const agencyUi = useAgencyUi();
  const { id: agencyId, agencyLegalLinks } = agencyUi;

  const { showSuccessNotification, showErrorNotification } = useAddNotification();
  const { execute: updateLegalLinks, isLoading, isSucceeded, error, reset } = useUpdateAgencyUi();

  const getLink = useCallback(
    (type: LinkEnum) => {
      return agencyLegalLinks?.find((item: { type: number; link: string }) => item.type === type)?.link ?? '';
    },
    [agencyLegalLinks],
  );

  const initialFormValues = useMemo(
    () => ({
      legalNoticeLink: getLink(LinkEnum.legalNoticelink),
      privacyPolicyLink: getLink(LinkEnum.privacyPolicyLink),
    }),
    [getLink],
  );

  useEffect(() => {
    if (isSucceeded) {
      reset();
      showSuccessNotification();
      dispatch(getAgencyUi());
    }
  }, [isSucceeded, showSuccessNotification, dispatch, reset]);

  useEffect(() => {
    if (error) {
      showErrorNotification();
    }
  }, [error, showErrorNotification]);

  return (
    <LegalLinksComponent
      onSubmit={(values) => {
        updateLegalLinks({
          agencyLegalLinks: [
            { type: LinkEnum.legalNoticelink, link: values.legalNoticeLink },
            {
              type: LinkEnum.privacyPolicyLink,
              link: values.privacyPolicyLink,
            },
          ],
        });
      }}
      isLoading={isLoading}
      initialFormValues={initialFormValues}
      saveButtonTooltip={!agencyId ? translate('whiteLabelCustomization.info.firstFillCustomUrl') : undefined}
    />
  );
};

export default LegalLinkContainer;
