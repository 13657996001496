import { TimePeriod } from '@va/types/time';
import { CoreScaleOptions, Scale } from 'chart.js';

/**
 * Used for smaller line charts
 * Limits the number of ticks to 2 or 3 (depending on the total number)
 * 2 ticks for even number of labels
 * 3 ticks for odd number of labels
 * @param axis
 * @param limitTicks
 * @returns
 */
export function limitTicksCallback(axis: Scale<CoreScaleOptions>, limitTicks?: boolean) {
  if (!axis.ticks.length) return;
  const { length } = axis.ticks;
  if (!limitTicks) {
    axis.ticks = axis.ticks.map((tick, index) => {
      if (index === 0 || index === length - 1) {
        return { ...tick, major: true };
      }
      return tick;
    });
    return;
  }

  if (length % 2 === 0) {
    axis.ticks = axis.ticks.filter((tick, index) => index === 0 || index === length - 1);
    return;
  }

  axis.ticks = axis.ticks.filter(
    (tick, index) => index === 0 || index === Math.floor(length / 2) || index === length - 1,
  );
}

export function buildTicks(color = '#696969', size = 15) {
  return {
    font: {
      family: window.fontFamily,
      size,
      weight: 500,
      lineHeight: '150%',
    },
    color,
  };
}

/** @deprecated use import from util helpers */
export function mapTooltipLabels(labels: Array<{ from: string; until: string }>, unit: TimePeriod) {
  if (unit === TimePeriod.day) {
    return labels.map(({ from, until }) => ({
      tooltip: from,
      axis: from,
    }));
  }

  return labels.map(({ from, until }) => ({
    tooltip: `${from} - ${until}`,
    axis: `${from} - ${until}`,
  }));
}

/** @deprecated use import from util helpers */
export function mapChartLabels(labels: Array<{ from: string; until: string }>) {
  return labels.map(({ from }) => from);
}
