import { useRecordingsContext } from '@va/dashboard/shared/recordings';
import { TrashCanIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, ModalHeader, TextInput } from '@va/ui/design-system';
import { ModalWrapper } from '@va/util/components';
import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from 'react';
import { useAddNote, useDeleteComment, useUpdateComment } from './apiClient';

export type CommentType = {
  createdAt: number;
  key: string;
  sessionKey: string;
  text: string;
  type: string;
};

const AddNoteModal = () => {
  const { isAddNoteModalOpen, closeAddNoteModal, currentRecording, mutateRecordings, updateCurrentRecording } =
    useRecordingsContext();
  const [inputValue, setInputValue] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isEditing, setIsEditing] = useState(false);

  const translate = useTranslate();

  useEffect(() => {
    if (currentRecording?.comment) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [currentRecording?.comment]);

  useEffect(() => {
    if (!currentRecording?.comment) return;
    setInputValue(currentRecording.comment.text);
  }, [currentRecording?.comment]);

  const { execute: addNote, isLoading: isLoadingAddComment, error: apiError } = useAddNote(currentRecording?.id ?? '');

  const { execute: updateComment, isLoading: isLoadingUpdateComment } = useUpdateComment(
    currentRecording?.id ?? '',
    currentRecording?.comment?.id ?? '',
  );

  const {
    execute: deleteComment,
    isSucceeded: deleteCommentSucceeded,
    isLoading: isLoadingDeleteComment,
  } = useDeleteComment(currentRecording?.id ?? '', currentRecording?.comment?.id ?? '');

  const isLoading = isLoadingAddComment || isLoadingUpdateComment || isLoadingDeleteComment;

  // Exit edit state
  useEffect(() => {
    if (!deleteCommentSucceeded) return;
    setIsEditing(false);
    updateCurrentRecording({ ...currentRecording!, comment: undefined });
    setInputValue('');
  }, [currentRecording, deleteCommentSucceeded, updateCurrentRecording]);

  // Mutate recordings on onmount
  useEffect(() => {
    return () => {
      mutateRecordings();
    };
  }, [mutateRecordings]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '') setError('');
    setInputValue(e.target.value);
  };

  const hasNoteChanged = useMemo(
    () => inputValue !== currentRecording?.comment?.text,
    [inputValue, currentRecording?.comment?.text],
  );

  const isSubmitDisabled = useMemo(() => isLoading || !hasNoteChanged, [isLoading, hasNoteChanged]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (isSubmitDisabled) return;
    try {
      if (!inputValue) {
        setError(translate('form.requiredField'));
        return;
      }
      const fnToExecute = isEditing ? updateComment : addNote;
      const updatedComment = await fnToExecute(inputValue);

      updateCurrentRecording({
        ...currentRecording!,
        comment: {
          id: updatedComment.key,
          text: updatedComment.text,
          timestamp: updatedComment.createdAt,
          typeString: updatedComment.type,
        },
      });
    } catch (err) {
      setError(translate('notifications.generalError'));
    }
  };

  useEffect(() => {
    if (apiError) {
      setError(translate('notifications.generalError'));
    }
  }, [apiError, translate]);

  const titleText = useMemo(() => {
    if (isEditing) return translate('recordings.modal.editNote');
    return translate('recordings.modal.addNote');
  }, [isEditing, translate]);

  return (
    <ModalWrapper isModalOpen={isAddNoteModalOpen} closeModal={closeAddNoteModal}>
      <div className='max-w-[480px]'>
        <ModalHeader title={titleText} onClose={() => closeAddNoteModal()} />
        <div className='p-18px'>
          <form onSubmit={onSubmit}>
            <TextInput
              error={error}
              value={inputValue}
              label={translate('recordings.addNote.input.label')}
              name='note'
              clearField={() => {
                setInputValue('');
              }}
              onChange={onChange}
              autoFocus
            />
            <div className='flex items-center gap-3 mt-3'>
              <Button
                type='button'
                onClick={() => {
                  closeAddNoteModal();
                }}
                className='flex-1'
                color='tertiary'
                text={translate('button.cancel')}
              />
              <Button
                type='submit'
                disabled={isSubmitDisabled}
                className='flex-1'
                color='primary'
                text={translate('button.submit')}
              />
              {isEditing && (
                <Button
                  type='button'
                  onClick={deleteComment}
                  color='destructive'
                  icon={() => <TrashCanIcon color='#EA2A0C' />}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AddNoteModal;
