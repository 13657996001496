import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { LogoComponent } from '@va/ui/components/white-label-customisation';
import { useEffect, useMemo, useState } from 'react';
import { useUploadAgencyLogo } from '../white-label-customization-apiClients';
import useUploadLogo from './useUploadLogo';

const LogoSectionContainer = () => {
  const [logoId, setLogoId] = useState('');

  const translate = useTranslate();

  const { showErrorNotification } = useAddNotification();

  const {
    agencyLogo,
    isCustomisationEnabled,
    executeEditAgencyUi,
    isEditAgencyUiRequestLoading,
    deleteAgencyLogo,
    logoAlt,
    currentLogoId,
  } = useUploadLogo();

  const {
    data,
    isLoading: isUploadLogoApiLoading,
    isSucceeded: uploadLogoSuccess,
    execute: uploadAgencyLogo,
  } = useUploadAgencyLogo();

  const initialFormValues = {
    logoAlt: logoAlt || '',
    logo: agencyLogo || '',
  };

  useEffect(() => {
    setLogoId(currentLogoId);
  }, [currentLogoId]);

  useEffect(() => {
    if (uploadLogoSuccess && data) {
      setLogoId(data.fileId);
    }
  }, [data, uploadLogoSuccess]);

  const isSubmitButtonDisabled = useMemo(
    () => !isCustomisationEnabled || isUploadLogoApiLoading || isEditAgencyUiRequestLoading,
    [isUploadLogoApiLoading, isEditAgencyUiRequestLoading, isCustomisationEnabled],
  );

  return (
    <LogoComponent
      initialFormValues={initialFormValues}
      onSubmit={({ logoAlt }) => {
        if (!logoId && !!currentLogoId) {
          deleteAgencyLogo();
        }
        executeEditAgencyUi({ logoId: logoId, logoAlt: logoAlt });
      }}
      onFileSelect={(file) => {
        uploadAgencyLogo(file);
      }}
      onFileClear={() => {
        setLogoId('');
      }}
      saveButtonProps={{
        disabled: isSubmitButtonDisabled,
        tooltip: !isCustomisationEnabled ? translate('whiteLabelCustomization.info.firstFillCustomUrl') : '',
      }}
      onUploadError={(message) => {
        showErrorNotification(2000, message);
      }}
    />
  );
};

export default LogoSectionContainer;
