import ButtonGroupV2 from '@va/deprecated/components/buttongroups/ButtonGroupV2';
import { FastField } from 'formik';
import PropTypes from 'prop-types';

const ToggleButtonsField = ({ name, className, options, disabled }) => {
  return (
    <FastField name={name}>
      {({ field, form }) => (
        <ButtonGroupV2
          value={field.value}
          onChange={(value) => form.setFieldValue(name, value)}
          options={options}
          className={className}
          disabled={disabled}
        />
      )}
    </FastField>
  );
};

ToggleButtonsField.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }).isRequired,
  ).isRequired,
  disabled: PropTypes.bool,
};

export default ToggleButtonsField;
