import { apiStatus } from '@va/constants';
import { closeModal } from '@va/dashboard/actions/ui';
import { getAccountInformation } from '@va/dashboard/selectors/api';
import { isModalOpen as modalOpen } from '@va/dashboard/selectors/ui';
import { PencilEditIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { startChangeEmailFlow, updateUserInfo } from '@va/standalone/shared/actions';
import { modalName } from '@va/standalone/shared/constants';
import { getApiRequestStatus } from '@va/standalone/shared/selectors';
import { ModalFormWrapper } from '@va/ui/components/modals';
import { Button, Paragraph, TextInput, fontWeights } from '@va/ui/design-system';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

enum FormFieldsNames {
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
}

const AccountDetailsModal = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const accountInformation = useSelector(getAccountInformation);

  const isModalOpen = useSelector((state) => modalOpen(state, modalName.ACCOUNT_INFO));
  const updateUserInfoState = useSelector((state) => getApiRequestStatus(state, 'updateUserInfo'));

  const updateUserInfoInProgress = useMemo(() => updateUserInfoState === apiStatus.IN_PROGRESS, [updateUserInfoState]);
  const updateUserInfoIsSuccedded = useMemo(() => updateUserInfoState === apiStatus.SUCCEEDED, [updateUserInfoState]);
  const updateUserInfoIsFailed = useMemo(() => updateUserInfoState === apiStatus.FAILED, [updateUserInfoState]);

  const initialForValues = useMemo(() => {
    return {
      [FormFieldsNames.email]: accountInformation?.email ?? '',
      [FormFieldsNames.firstName]: accountInformation?.firstName ?? '',
      [FormFieldsNames.lastName]: accountInformation?.lastName ?? '',
    };
  }, [accountInformation]);

  const { values, handleChange, setFieldValue, handleSubmit, resetForm } = useFormik({
    enableReinitialize: true,
    initialValues: initialForValues,
    onSubmit: (values) => {
      dispatch(updateUserInfo({ firstName: values.firstName, lastName: values.lastName }));
    },
  });

  const handleClose = useCallback(() => {
    resetForm();
    dispatch(closeModal(modalName.ACCOUNT_INFO));
  }, [dispatch, resetForm]);

  useEffect(() => {
    if (updateUserInfoIsSuccedded) {
      handleClose();
    }
  }, [updateUserInfoIsSuccedded, handleClose]);

  const isFormValuesChanged = useMemo(() => {
    return initialForValues?.firstName !== values.firstName || initialForValues?.lastName !== values.lastName;
  }, [initialForValues, values]);

  return (
    <ModalFormWrapper
      title={translate(`modal.accountInfo.title`)}
      isOpen={isModalOpen}
      onClose={() => handleClose()}
      onSubmit={handleSubmit}
      isSaveButtonDisabled={!isFormValuesChanged || updateUserInfoInProgress}
      saveButtonProps={{
        text: updateUserInfoInProgress ? translate('button.isLoading') : translate(`button.submit`),
      }}
    >
      <div className='flex flex-col p-3 gap-2'>
        <div className='flex gap-2 items-center'>
          <TextInput
            id={FormFieldsNames.email}
            label={translate('form.placeholders.email')}
            name={FormFieldsNames.email}
            required
            onChange={handleChange}
            clearField={() => {
              setFieldValue(FormFieldsNames.email, '');
            }}
            value={values.email}
            disabled
            wrapperClassName='w-full'
          />
          <Button
            icon={(_, color) => <PencilEditIcon color={color} />}
            color={'tertiary'}
            onClick={() => {
              handleClose();
              dispatch(startChangeEmailFlow());
            }}
            tooltip={translate('button.changeEmail')}
          />
        </div>
        <div className='flex flex-col md:flex-row gap-2'>
          <TextInput
            id={FormFieldsNames.firstName}
            label={translate('form.placeholders.firstName')}
            name={FormFieldsNames.firstName}
            onChange={handleChange}
            clearField={() => {
              setFieldValue(FormFieldsNames.firstName, '');
            }}
            value={values.firstName}
            wrapperClassName='w-full md:w-1/2'
          />
          <TextInput
            id={FormFieldsNames.lastName}
            label={translate('form.placeholders.lastName')}
            name={FormFieldsNames.lastName}
            onChange={handleChange}
            clearField={() => {
              setFieldValue(FormFieldsNames.lastName, '');
            }}
            value={values.lastName}
            wrapperClassName='w-full md:w-1/2'
          />
        </div>
        {updateUserInfoIsFailed && (
          <Paragraph weight={fontWeights.medium} className='ml-2 text-negative'>
            {translate('form.generalError')}
          </Paragraph>
        )}
      </div>
    </ModalFormWrapper>
  );
};

export default AccountDetailsModal;
