import { getInstanceId } from '@va/dashboard/selectors/app';
import { patch, post, remove } from '@va/http-client';
import { useAsyncFunction } from '@va/util/hooks';
import { useSelector } from 'react-redux';
import { type CommentType } from './AddNoteModal';

export const useAddNote = (recordingId: string) => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = async (comment: string) => {
    return await post<CommentType>(`/websites/${websiteId}/sessions/${recordingId}/comments`, {}, { comment });
  };
  return useAsyncFunction(asyncFunc);
};

export const useUpdateComment = (sessionId: string, commentId?: string) => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = (comment: string) =>
    patch<CommentType>(`/websites/${websiteId}/sessions/${sessionId}/comments/${commentId}`, {}, { comment });
  return useAsyncFunction(asyncFunc);
};

export const useDeleteComment = (sessionId: string, commentId?: string) => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = () => remove(`/websites/${websiteId}/sessions/${sessionId}/comments/${commentId}`);
  return useAsyncFunction<typeof asyncFunc>(asyncFunc);
};
