import { IconProps } from './index';
export const CampaignsIcon = ({ className = '', color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C9.42002 1.5 9.83198 1.53453 10.2332 1.6009L10.4798 0.121076C9.99837 0.041432 9.50402 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 8.49598 17.9586 8.00163 17.8789 7.52018L16.3991 7.76682C16.4655 8.16802 16.5 8.57998 16.5 9ZM9 3C9.33602 3 9.66558 3.02762 9.98655 3.08072L9.73991 4.56054C9.49919 4.52072 9.25201 4.5 9 4.5C6.51472 4.5 4.5 6.51472 4.5 9C4.5 11.4853 6.51472 13.5 9 13.5C11.4853 13.5 13.5 11.4853 13.5 9C13.5 8.74799 13.4793 8.50081 13.4395 8.26009L14.9193 8.01345C14.9724 8.33442 15 8.66398 15 9C15 12.3137 12.3137 15 9 15C5.68629 15 3 12.3137 3 9C3 5.68629 5.68629 3 9 3ZM9 6C9.168 6 9.33278 6.01381 9.49326 6.04036L9.24663 7.52018C9.16639 7.50691 9.084 7.5 9 7.5C8.17157 7.5 7.5 8.17157 7.5 9C7.5 9.82843 8.17157 10.5 9 10.5C9.82843 10.5 10.5 9.82843 10.5 9C10.5 8.916 10.4931 8.8336 10.4798 8.75336L11.9596 8.50672C11.9862 8.66721 12 8.83199 12 9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9C6 7.34315 7.34315 6 9 6ZM12.0934 7.18131L10.5 7.5L10.8187 5.90656L11.4699 2.65045C11.7781 1.10933 13.1313 0 14.7029 0C16.5239 0 18 1.47614 18 3.29706C18 4.8687 16.8907 6.22187 15.3495 6.53009L12.0934 7.18131ZM15.0554 5.05922L12.4121 5.58787L12.9408 2.94463C13.1088 2.10464 13.8463 1.5 14.7029 1.5C15.6954 1.5 16.5 2.30457 16.5 3.29706C16.5 4.15368 15.8954 4.89122 15.0554 5.05922Z'
        fill={color ?? 'var(--color-primary'}
      />
    </svg>
  );
};
