import Config from 'Config';
import { LocalStorage } from '@va/util/helpers';
import { storageItems } from '@va/standalone/shared/constants';

export function openPricingPage() {
  const appId = Config.wixAppId;
  const instance = LocalStorage.getItem(storageItems.shortInstanceId);

  if (!appId || !instance) return;
  window.open(`https://www.wix.com/apps/upgrade/${appId}?appInstanceId=${instance}`, '_blank');
}
