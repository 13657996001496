import { IconProps } from '.';

export const ReplayIcon = ({ className, color = '#000000' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.5 3L9 6L9 3.5C10.2856 3.5 11.5423 3.88122 12.6112 4.59545C13.6801 5.30968 14.5132 6.32484 15.0052 7.51256C15.4972 8.70028 15.6259 10.0072 15.3751 11.2681C15.1243 12.529 14.5052 13.6872 13.5962 14.5962C12.6872 15.5052 11.529 16.1243 10.2681 16.3751C9.00721 16.6259 7.70028 16.4972 6.51256 16.0052C5.32484 15.5132 4.30968 14.6801 3.59545 13.6112C2.88122 12.5423 2.5 11.2856 2.5 10H1C1 11.5822 1.46919 13.129 2.34824 14.4446C3.22729 15.7602 4.47672 16.7855 5.93853 17.391C7.40034 17.9965 9.00887 18.155 10.5607 17.8463C12.1126 17.5376 13.538 16.7757 14.6569 15.6569C15.7757 14.538 16.5376 13.1126 16.8463 11.5607C17.155 10.0089 16.9965 8.40034 16.391 6.93853C15.7855 5.47672 14.7602 4.22729 13.4446 3.34824C12.129 2.46919 10.5823 2 9 2L9 0L4.5 3ZM8.25 12.5981L11.625 10.6495C12.125 10.3608 12.125 9.63916 11.625 9.35048L8.25 7.40192C7.75 7.11325 7.125 7.47409 7.125 8.05144L7.125 11.9486C7.125 12.5259 7.75 12.8868 8.25 12.5981Z'
        fill={color}
      />
    </svg>
  );
};
