import { IconProps } from './index';

export const SettingsIcon = ({ className = '', color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.25 2H9.75V2.25734C9.75 4.26188 12.1736 5.26575 13.591 3.84833L13.7729 3.66637L14.8336 4.72703L14.6516 4.90901C13.2342 6.32642 14.2381 8.75 16.2426 8.75H16.5V10.25H16.2426C14.2381 10.25 13.2342 12.6736 14.6517 14.091L14.8336 14.273L13.773 15.3336L13.591 15.1516C12.1736 13.7342 9.75 14.7381 9.75 16.7426V17H8.25V16.7426C8.25 14.7381 5.82644 13.7342 4.40901 15.1516L4.22701 15.3336L3.16635 14.273L3.34833 14.091C4.76576 12.6736 3.76187 10.25 1.75734 10.25H1.5V8.75H1.75736C3.7619 8.75 4.76578 6.32643 3.34835 4.90901L3.16637 4.72703L4.22704 3.66637L4.40901 3.84834C5.82643 5.26576 8.25 4.2619 8.25 2.25735V2ZM13.7729 1.54505L12.7123 2.60571L12.5303 2.78767C12.0579 3.26014 11.25 2.92552 11.25 2.25734V2V0.5H9.75H8.25H6.75V2V2.25735C6.75 2.92553 5.94214 3.26016 5.46967 2.78768L5.2877 2.60571L4.22704 1.54505L3.16638 2.60571L2.10571 3.66637L1.04505 4.72703L2.10571 5.78769L2.28769 5.96967C2.76017 6.44214 2.42554 7.25 1.75736 7.25H1.5H0V8.75V10.25V11.75H1.5H1.75734C2.42552 11.75 2.76014 12.5579 2.28767 13.0303L2.10569 13.2123L1.04503 14.273L2.10569 15.3336L3.16635 16.3943L4.22701 17.455L5.28767 16.3943L5.46967 16.2123C5.94214 15.7398 6.75 16.0744 6.75 16.7426V17V18.5H8.25H9.75H11.25V17V16.7426C11.25 16.0745 12.0579 15.7398 12.5303 16.2123L12.7123 16.3943L13.773 17.455L14.8336 16.3943L15.8943 15.3336L16.955 14.273L15.8943 13.2123L15.7123 13.0303C15.2398 12.5579 15.5745 11.75 16.2426 11.75H16.5H18V10.25V8.75V7.25H16.5H16.2426C15.5744 7.25 15.2398 6.44214 15.7123 5.96967L15.8943 5.78769L16.9549 4.72703L15.8943 3.66637L14.8336 2.60571L13.7729 1.54505ZM11.25 9.5C11.25 10.7426 10.2426 11.75 9 11.75C7.75736 11.75 6.75 10.7426 6.75 9.5C6.75 8.25736 7.75736 7.25 9 7.25C10.2426 7.25 11.25 8.25736 11.25 9.5ZM12.75 9.5C12.75 11.5711 11.0711 13.25 9 13.25C6.92893 13.25 5.25 11.5711 5.25 9.5C5.25 7.42893 6.92893 5.75 9 5.75C11.0711 5.75 12.75 7.42893 12.75 9.5Z'
        fill={color ?? 'var(--color-primary)'}
      />
    </svg>
  );
};


