export const WarningCircleIcon = ({ color = 'currentColor', ...props }: JSX.IntrinsicElements['svg']) => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18ZM9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17ZM9.72878 4.27532C9.92033 4.46687 10.0199 4.73175 10.0021 5.00205V9.00205C10.0199 9.27236 9.92033 9.53723 9.72878 9.72878C9.53723 9.92033 9.27236 10.0199 9.00205 10.0021C8.73175 10.0199 8.46687 9.92033 8.27532 9.72878C8.08377 9.53723 7.98417 9.27236 8.00205 9.00205V5.00205C7.98417 4.73175 8.08377 4.46687 8.27532 4.27532C8.46687 4.08377 8.73175 3.98417 9.00205 4.00205C9.27236 3.98417 9.53723 4.08377 9.72878 4.27532ZM9.72878 12.2753C9.92033 12.4669 10.0199 12.7317 10.0021 13.0021C10.0199 13.2724 9.92033 13.5372 9.72878 13.7288C9.53723 13.9203 9.27236 14.0199 9.00205 14.0021C8.73175 14.0199 8.46687 13.9203 8.27532 13.7288C8.08377 13.5372 7.98417 13.2724 8.00205 13.0021C7.98417 12.7317 8.08377 12.4669 8.27532 12.2753C8.46687 12.0838 8.73175 11.9842 9.00205 12.0021C9.27236 11.9842 9.53723 12.0838 9.72878 12.2753Z'
      fill={color}
    />
  </svg>
);
