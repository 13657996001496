import TrackingCodeInstalationResultView from './TrackingCodeInstalationResultView';
import TrackingCodeStep from './TrackingCodeStep';
import InstallTrackingCodeContextProvider, { InstallTrackingCodeContext } from './useTrackingCodeInstallationView';

enum ViewEnum {
  installation = 'installation',
  installationResult = 'installationResult',
}

export const InstallTrackingCodeStep = ({
  onError,
  onClickRetryInstallation,
  onOpenDashboardClick,
}: {
  onError: () => void;
  onClickRetryInstallation: () => void;
  onOpenDashboardClick?: () => void;
}) => {
  return (
    <InstallTrackingCodeContextProvider onError={onError}>
      <InstallTrackingCodeContext.Consumer>
        {({ view, setView }) => {
          return (
            <div>
              {view === ViewEnum.installation && <TrackingCodeStep />}
              {view === ViewEnum.installationResult && (
                <TrackingCodeInstalationResultView
                  onOpenDashboardClick={onOpenDashboardClick}
                  onClickRetryInstallation={() => {
                    onClickRetryInstallation();
                    setView(ViewEnum.installation);
                  }}
                />
              )}
            </div>
          );
        }}
      </InstallTrackingCodeContext.Consumer>
    </InstallTrackingCodeContextProvider>
  );
};
