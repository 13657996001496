import { BrowserIcon, DeviceIcon, OperatingSystemIcon, WebsiteManagementGlobe } from '@va/icons';
import { useTranslate } from '@va/localization';
import { DeviceTypesEnum } from '@va/types/device';
import classNames from 'classnames';
import React from 'react';
import './DetailsCell.scss';
import { VisitDetails } from './VisitDetails';

type DetailsCellProps = {
  ip?: string;
  browser: string;
  device: DeviceTypesEnum;
  platform: string;
  display?: string;
  className?: string;
  smallVersion?: boolean;
  size?: 'small' | 'medium';
  squareIcon?: boolean;
};

export const DetailsCell: React.FC<DetailsCellProps> = ({
  browser,
  platform,
  device,
  ip,
  className,
  display,
  smallVersion,
  size = 'medium',
  squareIcon = false,
}) => {
  const translate = useTranslate();

  return (
    <div
      className={classNames('flex items-center', className, {
        'details-cell-small-version': smallVersion,
        'gap-2': size === 'medium',
        'gap-[3px]': size === 'small',
      })}
    >
      {ip && (
        <VisitDetails
          tooltipText={translate(`panels.latestVisitors.ip`)}
          tooltipSubtext={ip}
          icon={<WebsiteManagementGlobe color='#696969' />}
          size={size}
          squareIcon={squareIcon}
        />
      )}
      <VisitDetails
        tooltipSubtext={
          <div className='flex flex-col text-center'>
            <div>{translate(`panels.latestVisitors.deviceType.${device}`)}</div>
            {display && <div>{display}</div>}
          </div>
        }
        tooltipText={translate(`panels.latestVisitors.device`)}
        icon={<DeviceIcon deviceType={device} color='#696969' />}
        size={size}
        squareIcon={squareIcon}
      />
      <VisitDetails
        tooltipSubtext={platform}
        tooltipText={translate(`panels.latestVisitors.operatingSystem`)}
        icon={<OperatingSystemIcon osName={platform} />}
        size={size}
        squareIcon={squareIcon}
      />
      <VisitDetails
        tooltipText={translate(`panels.latestVisitors.browser`)}
        tooltipSubtext={browser}
        icon={<BrowserIcon browserName={browser} />}
        size={size}
        squareIcon={squareIcon}
      />
    </div>
  );
};
