export const EdgeIcon: React.FC<JSX.IntrinsicElements['svg'] & { monochrome?: boolean }> = ({
  monochrome,
  ...props
}) => {
  if (monochrome)
    return (
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 24 24'
        {...props}
      >
        <g style={{ display: 'none' }}>
          <path
            fill='#13232A'
            style={{ display: 'inline' }}
            d='M21.6,5.9l0.3,0.3L22,6.1c0.8-1.9,0.8-3.3,0.2-4.2C21.6,1.1,20.4,1,20,1c-0.1,0-0.2,0-0.2,0
  c-1.4,0-3,0.7-4.1,1.2c-1,0.6-1.8,1.1-2,1.2c-0.3,0-1.1-0.2-2.3-0.2c-1.4,0-3.6,0.2-5.9,1.4c-4.4,2.7-4.3,7.5-4.3,7.5v0.3l0.2-0.2
  c3.2-4.3,7-6.6,8.5-6.6c-2.9,2-5.5,4.3-7.3,7.3c-1.4,2.5-2,4.6-2.3,5.5c-0.1,0.1-0.1,0.2-0.1,0.3c-0.3,0.9-0.3,2.6,0.6,3.7
  c0.6,0.7,1.4,1.1,2.5,1.1c3,0,6-2.1,6.4-2.3c0.2,0,0.9,0.1,2.5,0.1c4.1,0,6.5-1.8,7.8-3.3c1.4-1.6,1.9-3.3,1.9-3.3v-0.1h-7.1v0.1
  c-0.1,0-0.5,2-2.9,2c-0.9,0-1.7-0.3-2.2-0.8c-0.8-0.9-0.9-2.1-0.9-2.4h13.1v-0.1c0.3-4.5-1.7-6.9-3.5-8.2c-1.5-1.1-3.1-1.5-3.7-1.8
  c0.7-0.4,3-1.7,5-1.7c1,0,1.6,0.3,2,0.8C22.5,3.6,21.6,5.8,21.6,5.9L21.6,5.9z M4.4,22.3c-0.9,0-1.5-0.2-2-0.6
  c-0.5-0.9-0.3-2.1,0-2.9c0.2-0.6,0.5-1.2,0.7-1.5C3.5,17.9,5.2,20,9,20.9C8.3,21.3,6.2,22.3,4.4,22.3z M17.8,6
  c2.1,1.5,3.1,3.6,3.1,6.5H7.8v1c0,0.2,0,1.8,1.2,3.1c0.7,0.7,1.8,1.1,2.9,1.1s2.6-0.4,3.5-2l5-0.1c-0.3,0.5-0.7,1.1-1.2,1.7
  c-1.2,1.4-3.3,3-7,3c-0.7,0-1.2,0-1.7-0.1c-0.6-0.1-1.3-0.3-1.3-0.3C5.6,19,4.1,17,3.9,16.7l-0.8-1.2l-0.8,1.3
  c-0.2,0.4-0.6,1-0.8,1.7c-0.2,0.4-0.4,1.3-0.3,2.3c-0.1-0.6-0.1-1.3,0-1.7l0.1-0.8c0.3-1,0.9-2.8,2.1-5c1.6-2.7,5-6,7.6-7.9
  C11.2,5.3,10.3,5,10.3,5C8.5,4.7,5.4,6.5,2.9,8.9C3.4,7.7,4.4,6.4,6,5.4c1.6-0.8,3.3-1.3,5.3-1.3c0.7,0,1.4,0.1,1.8,0.1
  C13.9,4.2,16.8,5.3,17.8,6z'
          />
          <path
            fill='#13232A'
            style={{ display: 'inline' }}
            d='M8.8,7.8c-1,1.1-1,2.6-1,2.6v1h8v-1c0-0.2,0-1.7-1.2-2.7c-0.7-0.7-1.6-1-2.8-1C10.5,6.7,9.5,7,8.8,7.8z
M14.8,10.4h-6c0-0.2,0.1-1.2,0.7-1.9C10,8,10.8,7.7,11.8,7.7S13.5,8,14,8.5C14.7,9.1,14.8,10.1,14.8,10.4z'
          />
        </g>
        <g>
          <path
            d='M8.2,10.4v0.5h7.5v-0.5c0-2-1.7-3.6-3.7-3.6S8.2,8.4,8.2,10.4z M15.1,10.4H8.6c0-1.8,1.5-3.2,3.2-3.2
  C13.7,7.3,15.1,8.7,15.1,10.4z'
          />
          <path
            d='M22.1,1.1L22.1,1.1c-1.7-1.6-5.1-0.9-9,1.6c-0.4,0-0.8-0.1-1.2-0.1c-5.8,0-10.5,4.3-10.5,9.5
  c0,0.1,0,0.1,0,0.1C3.8,8.5,7.2,5.8,10,5C9,5.8,7.9,6.8,7,7.7c-2.2,2.3-4,4.8-5.2,7.1c-1.8,3.5-2.2,6.6-0.7,8.1
  c1.6,1.6,5,0.9,8.8-1.5c0.6,0.1,1.3,0.2,1.9,0.2c0.5,0,0.9,0,1.4-0.1c4.1-0.5,7.5-3.2,8.7-6.7h-7c-0.4,1.3-1.6,2.2-3.1,2.2
  c-1.8,0-3.2-1.4-3.2-3.2v-0.3h13.6c0.1-0.4,0.1-0.9,0.1-1.4c0-4.2-2.9-7.7-7-9c2.9-1.6,5.3-2.1,6.5-0.9c0.7,0.7,0.9,1.9,0.5,3.4
  h0.4C23.2,3.6,23,2,22.1,1.1z M2.5,22.4c-1.1-1-0.9-3.1,0.2-5.6c1.4,2.2,3.7,3.9,6.5,4.6C6.3,23,3.7,23.5,2.5,22.4z M20,1.1
  c-1.3,0-3,0.6-4.9,1.6l-1,0.6l1.1,0.3c4,1.3,6.7,4.7,6.7,8.5c0,0.3,0,0.6,0,0.9H8.2v0.8c0,2.1,1.7,3.7,3.7,3.7
  c1.5,0,2.8-0.9,3.4-2.2h5.9c-1.3,3.1-4.4,5.3-8,5.7c-0.4,0.1-0.9,0.1-1.3,0.1c-0.6,0-1.2-0.1-1.8-0.2H9.9l0,0l-0.5-0.1
  c-2.6-0.6-4.9-2.2-6.2-4.3l-0.5-0.8l-0.4,0.9c-1.2,2.8-1.3,5-0.1,6.2c0.2,0.2,0.4,0.4,0.7,0.5c-0.6-0.1-1-0.3-1.4-0.6
  c-1.3-1.2-1-4,0.8-7.5c1.2-2.4,3-4.8,5.2-7.1c0.9-0.9,2.5-2.4,2.7-2.5c0.8-0.7,1.4-0.8,1.4-0.8s-0.6-0.3-1.9,0
  c-2.8,0.7-5.4,2.9-7.6,5.8c0.9-4.2,5-7.3,9.8-7.3c0.4,0,0.8,0,1.1,0.1h0.2l0.1-0.1C15.7,1.8,17.9,1,19.6,1c0.9,0,1.6,0.2,2.1,0.7
  c0,0,0,0,0.1,0.1C21.3,1.2,20.7,1.1,20,1.1z'
          />
        </g>
      </svg>
    );

  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M16.2452 13.3959C16.0053 13.5214 15.7578 13.632 15.5042 13.727C14.6971 14.029 13.8421 14.1829 12.9803 14.1812C9.65347 14.1812 6.75552 11.8928 6.75552 8.95615C6.75977 8.56228 6.86907 8.17668 7.07213 7.83916C7.27519 7.50164 7.56464 7.22442 7.91062 7.03613C4.90158 7.16268 4.12823 10.2983 4.12823 12.1353C4.12823 17.3294 8.91527 17.856 9.94664 17.856C10.5027 17.856 11.3415 17.6943 11.8449 17.5354L11.937 17.5045C13.874 16.8348 15.5254 15.5256 16.6192 13.7924C16.6527 13.7396 16.6677 13.6772 16.6618 13.6149C16.6558 13.5527 16.6293 13.4942 16.5864 13.4487C16.5435 13.4033 16.4867 13.3734 16.4249 13.3638C16.3631 13.3542 16.2999 13.3655 16.2452 13.3959Z'
        fill='url(#paint0_linear_1120_52943)'
      />
      <path
        opacity='0.35'
        d='M16.2452 13.3959C16.0053 13.5214 15.7578 13.632 15.5042 13.727C14.6971 14.029 13.8421 14.1829 12.9803 14.1812C9.65347 14.1812 6.75552 11.8928 6.75552 8.95615C6.75977 8.56228 6.86907 8.17668 7.07213 7.83916C7.27519 7.50164 7.56464 7.22442 7.91062 7.03613C4.90158 7.16268 4.12823 10.2983 4.12823 12.1353C4.12823 17.3294 8.91527 17.856 9.94664 17.856C10.5027 17.856 11.3415 17.6943 11.8449 17.5354L11.937 17.5045C13.874 16.8348 15.5254 15.5256 16.6192 13.7924C16.6527 13.7396 16.6677 13.6772 16.6618 13.6149C16.6558 13.5527 16.6293 13.4942 16.5864 13.4487C16.5435 13.4033 16.4867 13.3734 16.4249 13.3638C16.3631 13.3542 16.2999 13.3655 16.2452 13.3959Z'
        fill='url(#paint1_radial_1120_52943)'
      />
      <path
        d='M7.43326 16.973C6.80619 16.5838 6.26274 16.0738 5.83453 15.4727C5.34647 14.804 5.01061 14.0366 4.85052 13.2244C4.69043 12.4122 4.70998 11.5748 4.9078 10.7709C5.10562 9.96704 5.47691 9.21619 5.99565 8.57101C6.51438 7.92584 7.16799 7.40196 7.91063 7.03614C8.12998 6.93279 8.5047 6.74578 9.00316 6.75492C9.35434 6.75748 9.70015 6.84133 10.0135 6.99989C10.3269 7.15846 10.5992 7.38744 10.8093 7.66888C11.0932 8.04795 11.2497 8.50721 11.2564 8.98076C11.2564 8.966 12.9761 3.38452 5.63206 3.38452C2.54569 3.38452 0.00769122 6.31341 0.00769122 8.88304C-0.00448277 10.2423 0.28636 11.5873 0.859079 12.8201C1.79506 14.8172 3.43184 16.4009 5.45861 17.2707C7.48537 18.1405 9.76098 18.2357 11.8533 17.5382C11.1207 17.7692 10.3467 17.8386 9.58471 17.7415C8.82272 17.6444 8.09085 17.3831 7.43959 16.9758L7.43326 16.973Z'
        fill='url(#paint2_linear_1120_52943)'
      />
      <path
        opacity='0.41'
        d='M7.43326 16.973C6.80619 16.5838 6.26274 16.0738 5.83453 15.4727C5.34647 14.804 5.01061 14.0366 4.85052 13.2244C4.69043 12.4122 4.70998 11.5748 4.9078 10.7709C5.10562 9.96704 5.47691 9.21619 5.99565 8.57101C6.51438 7.92584 7.16799 7.40196 7.91063 7.03614C8.12998 6.93279 8.5047 6.74578 9.00316 6.75492C9.35434 6.75748 9.70015 6.84133 10.0135 6.99989C10.3269 7.15846 10.5992 7.38744 10.8093 7.66888C11.0932 8.04795 11.2497 8.50721 11.2564 8.98076C11.2564 8.966 12.9761 3.38452 5.63206 3.38452C2.54569 3.38452 0.00769122 6.31341 0.00769122 8.88304C-0.00448277 10.2423 0.28636 11.5873 0.859079 12.8201C1.79506 14.8172 3.43184 16.4009 5.45861 17.2707C7.48537 18.1405 9.76098 18.2357 11.8533 17.5382C11.1207 17.7692 10.3467 17.8386 9.58471 17.7415C8.82272 17.6444 8.09085 17.3831 7.43959 16.9758L7.43326 16.973Z'
        fill='url(#paint3_radial_1120_52943)'
      />
      <path
        d='M10.7094 10.4655C10.6525 10.5394 10.4774 10.6413 10.4774 10.8635C10.4774 11.047 10.5969 11.2234 10.8093 11.3718C11.8202 12.0748 13.7262 11.982 13.7311 11.982C14.4802 11.9802 15.2151 11.7775 15.8592 11.395C16.509 11.0156 17.0483 10.4729 17.4235 9.82074C17.7988 9.1686 17.9971 8.42967 17.9986 7.67726C18.0169 6.10173 17.4362 5.05419 17.2014 4.59018C15.7116 1.67606 12.4959 4.40558e-08 8.99892 4.40558e-08C6.63401 -0.000233803 4.36405 0.93048 2.68001 2.59084C0.995961 4.25121 0.0331956 6.50775 -6.10352e-05 8.87243C0.0336852 6.30351 2.58715 4.22882 5.6243 4.22882C5.87037 4.22882 7.27365 4.25272 8.5771 4.93679C9.72587 5.54 10.3277 6.26835 10.746 6.99038C11.1805 7.74053 11.2578 8.68824 11.2578 9.06577C11.2578 9.44331 11.0652 10.0029 10.7094 10.4655Z'
        fill='url(#paint4_radial_1120_52943)'
      />
      <path
        d='M10.7094 10.4655C10.6525 10.5394 10.4774 10.6413 10.4774 10.8635C10.4774 11.047 10.5969 11.2234 10.8093 11.3718C11.8202 12.0748 13.7262 11.982 13.7311 11.982C14.4802 11.9802 15.2151 11.7775 15.8592 11.395C16.509 11.0156 17.0483 10.4729 17.4235 9.82074C17.7988 9.1686 17.9971 8.42967 17.9986 7.67726C18.0169 6.10173 17.4362 5.05419 17.2014 4.59018C15.7116 1.67606 12.4959 4.40558e-08 8.99892 4.40558e-08C6.63401 -0.000233803 4.36405 0.93048 2.68001 2.59084C0.995961 4.25121 0.0331956 6.50775 -6.10352e-05 8.87243C0.0336852 6.30351 2.58715 4.22882 5.6243 4.22882C5.87037 4.22882 7.27365 4.25272 8.5771 4.93679C9.72587 5.54 10.3277 6.26835 10.746 6.99038C11.1805 7.74053 11.2578 8.68824 11.2578 9.06577C11.2578 9.44331 11.0652 10.0029 10.7094 10.4655Z'
        fill='url(#paint5_radial_1120_52943)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1120_52943'
          x1='4.12823'
          y1='12.4475'
          x2='16.6663'
          y2='12.4475'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#0C59A4' />
          <stop offset='1' stopColor='#114A8B' />
        </linearGradient>
        <radialGradient
          id='paint1_radial_1120_52943'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(11.0532 12.5463) scale(6.70565 6.37037)'
        >
          <stop offset='0.72' stopOpacity='0' />
          <stop offset='0.95' stopOpacity='0.53' />
          <stop offset='1' />
        </radialGradient>
        <linearGradient
          id='paint2_linear_1120_52943'
          x1='10.7383'
          y1='7.00872'
          x2='2.90705'
          y2='15.5388'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#1B9DE2' />
          <stop offset='0.16' stopColor='#1595DF' />
          <stop offset='0.67' stopColor='#0680D7' />
          <stop offset='1' stopColor='#0078D4' />
        </linearGradient>
        <radialGradient
          id='paint3_radial_1120_52943'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(4.96307 13.9912) rotate(-81.3844) scale(10.0835 8.14646)'
        >
          <stop offset='0.76' stopOpacity='0' />
          <stop offset='0.95' stopOpacity='0.5' />
          <stop offset='1' />
        </radialGradient>
        <radialGradient
          id='paint4_radial_1120_52943'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(1.81819 3.33081) rotate(92.2906) scale(14.2431 30.3349)'
        >
          <stop stopColor='#35C1F1' />
          <stop offset='0.11' stopColor='#34C1ED' />
          <stop offset='0.23' stopColor='#2FC2DF' />
          <stop offset='0.31' stopColor='#2BC3D2' />
          <stop offset='0.67' stopColor='#36C752' />
        </radialGradient>
        <radialGradient
          id='paint5_radial_1120_52943'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(16.8781 5.442) rotate(73.7398) scale(6.84345 5.56509)'
        >
          <stop stopColor='#66EB6E' />
          <stop offset='1' stopColor='#66EB6E' stopOpacity='0' />
        </radialGradient>
      </defs>
    </svg>
  );
};
