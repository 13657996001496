import { IconProps } from '.';

export const TimerIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 5.11099 13.54 1.91332 9.75 1.53703V4.5H8.25V0H9H9.75V0.0308053C14.3696 0.411918 18 4.282 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 6.51472 1.00736 4.26472 2.63604 2.63604L3.6967 3.6967C2.33947 5.05393 1.5 6.92893 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM5.81804 12.182C6.11093 12.4749 6.11093 12.9497 5.81804 13.2426C5.52515 13.5355 5.05027 13.5355 4.75738 13.2426C4.46449 12.9497 4.46449 12.4749 4.75738 12.182C5.05027 11.8891 5.52515 11.8891 5.81804 12.182ZM13.2427 5.81802C13.5356 5.52513 13.5356 5.05025 13.2427 4.75736C12.9498 4.46447 12.4749 4.46447 12.182 4.75736C11.8891 5.05025 11.8891 5.52513 12.182 5.81802C12.4749 6.11091 12.9498 6.11091 13.2427 5.81802ZM13.2427 12.182C13.5356 12.4749 13.5356 12.9497 13.2427 13.2426C12.9498 13.5355 12.4749 13.5355 12.182 13.2426C11.8891 12.9497 11.8891 12.4749 12.182 12.182C12.4749 11.8891 12.9498 11.8891 13.2427 12.182ZM9.75 14.25C9.75 13.8358 9.41421 13.5 9 13.5C8.58579 13.5 8.25 13.8358 8.25 14.25C8.25 14.6642 8.58579 15 9 15C9.41421 15 9.75 14.6642 9.75 14.25ZM14.25 8.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75C13.8358 9.75 13.5 9.41421 13.5 9C13.5 8.58579 13.8358 8.25 14.25 8.25ZM4.5 9C4.5 8.58579 4.16421 8.25 3.75 8.25C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75C4.16421 9.75 4.5 9.41421 4.5 9ZM7.37171 7.37171L8.08139 9.50074C8.34516 10.2921 9.34951 10.5292 9.93934 9.93934C10.5292 9.34951 10.2921 8.34516 9.50074 8.08138L7.37171 7.37171ZM5.59293 6.77878L6.65836 9.97508C7.27476 11.8243 9.62169 12.3783 11 11C12.3783 9.62169 11.8243 7.27476 9.97508 6.65836L6.77878 5.59293L5 5L5.59293 6.77878Z'
        fill={color}
      />
    </svg>
  );
};
