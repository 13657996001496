import { getSupportCenterDomain } from '@va/dashboard/selectors/customization';
import { InfoIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { getNavIconByName } from '@va/wix/shared/helpers';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const SsrOnboardingStep1 = ({ onSuccess }: { onSuccess: () => void }) => {
  const translate = useTranslate();
  const supportCenterDomain = useSelector(getSupportCenterDomain);

  const featureList = useMemo(
    () => [
      {
        title: translate('modal.addWixSsrCode.firstPage.features.recordings', { supportCenterDomain }),
        tooltip: translate('modal.addWixSsrCode.feature1'),
        icon: 'recordings',
      },
      {
        title: translate('modal.addWixSsrCode.firstPage.features.heatmaps', { supportCenterDomain }),
        tooltip: translate('modal.addWixSsrCode.feature2'),
        icon: 'heatmaps',
      },
      {
        title: translate('modal.addWixSsrCode.firstPage.features.polls', { supportCenterDomain }),
        tooltip: translate('modal.addWixSsrCode.feature3'),
        icon: 'polls',
      },
      {
        title: translate('modal.addWixSsrCode.firstPage.features.eventTracking', { supportCenterDomain }),
        tooltip: translate('modal.addWixSsrCode.feature4'),
        icon: 'eventTracking',
      },
    ],
    [translate, supportCenterDomain],
  );

  return (
    <div className='mt-12 max-w-[600px] mx-auto flex flex-col gap-3'>
      <div className='bg-[#FFE1AE] rounded-3xl w-full p-3 grid-cols-2 xs:grid-cols-1 grid gap-[9px] mb-18px'>
        {featureList.map(({ title, tooltip, icon }, index) => (
          <div
            key={index}
            className={'col-span-1 flex items-center justify-center bg-white rounded-12 p-15px px-3 gap-2'}
          >
            <div className='w-6 h-6 flex items-center'>{getNavIconByName(icon, false, 'var(--color-primary)')}</div>
            <div className='flex items-center justify-between w-full'>
              <TooltipWrapper interactive content={tooltip}>
                <div
                  className={classNames('text-gray-charcoal font-medium text-15 leading-6 truncate', {
                    'underline-dashed': tooltip,
                  })}
                >
                  {title}
                </div>
              </TooltipWrapper>
            </div>
          </div>
        ))}
      </div>
      <div className={classNames('text-15 leading-6 mb-6px tracking-[0.015em] text-center')}>
        {translate('modal.addWixSsrCode.firstPageDesc')}
      </div>
      <div className='rounded-18 bg-white-snow flex font-medium text-15 leading-6 items-center justify-center px-30 py-15px'>
        <div className='w-6'>
          <InfoIcon />
        </div>
        <div className='pl-4'>{translate('modal.addWixSsrCode.ssrSupport', { supportCenterDomain })}</div>
      </div>
      <Button className='w-full' text={translate('button.getStarted')} onClick={onSuccess} />
    </div>
  );
};

export default SsrOnboardingStep1;
