export const TwitterAppIcon: React.FC<JSX.IntrinsicElements['svg'] & { monochrome?: boolean }> = ({
  monochrome,
  ...props
}) => {
  if (monochrome)
    return (
      <svg
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 24 24'
        xmlSpace='preserve'
        {...props}
      >
        <path
          d='M18.9,0.9H5c-2.3,0-4.2,1.9-4.2,4.2V19c0,2.3,1.9,4.2,4.2,4.2h13.9c2.3,0,4.2-1.9,4.2-4.2V5.1
C23.1,2.8,21.2,0.9,18.9,0.9z M22.1,18.9c0,1.7-1.4,3.2-3.2,3.2H5c-1.7,0-3.2-1.4-3.2-3.2V5.1c0-1.7,1.4-3.2,3.2-3.2h13.9
c1.7,0,3.2,1.4,3.2,3.2C22.1,5.1,22.1,18.9,22.1,18.9z'
        />
        <path
          d='M17.5,9.5v0.4c0,3.6-2.7,7.8-7.8,7.8c-1.5,0-3-0.5-4.2-1.2c0.2,0,0.4,0,0.7,0c1.3,0,2.5-0.4,3.4-1.2
c-1.2,0-2.2-0.8-2.5-1.9c0.2,0,0.3,0,0.5,0s0.5,0,0.7-0.1c-1.2-0.3-2.2-1.4-2.2-2.7l0,0c0.4,0.2,0.8,0.3,1.2,0.3
c-0.7-0.5-1.2-1.3-1.2-2.3c0-0.5,0.1-1,0.4-1.4c1.3,1.7,3.4,2.7,5.6,2.9c0-0.2-0.1-0.4-0.1-0.6c0-1.5,1.2-2.7,2.7-2.7
c0.8,0,1.5,0.3,2,0.9c0.6-0.1,1.2-0.4,1.7-0.7c-0.2,0.6-0.6,1.2-1.2,1.5c0.6-0.1,1.1-0.2,1.6-0.4C18.5,8.6,18,9.1,17.5,9.5z'
        />
      </svg>
    );

  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M16.1572 5.3262C16.1682 5.48488 16.1682 5.64357 16.1682 5.80371C16.1682 10.6834 12.4534 16.3112 5.6607 16.3112V16.3083C3.65411 16.3112 1.68921 15.7364 0 14.6527C0.291773 14.6878 0.585009 14.7054 0.878976 14.7061C2.54186 14.7076 4.15722 14.1496 5.46545 13.1222C3.88519 13.0922 2.49945 12.0619 2.01536 10.5576C2.56892 10.6644 3.13931 10.6425 3.68263 10.494C1.95978 10.1459 0.720293 8.63223 0.720293 6.87428C0.720293 6.85819 0.720293 6.84284 0.720293 6.82748C1.23364 7.1134 1.80841 7.27209 2.39634 7.28964C0.773675 6.20518 0.273492 4.04649 1.25338 2.35874C3.12834 4.66587 5.8947 6.06843 8.86435 6.21688C8.56673 4.93425 8.97331 3.59019 9.93272 2.68854C11.4201 1.29037 13.7594 1.36203 15.1576 2.84869C15.9846 2.68562 16.7773 2.38214 17.5027 1.95216C17.2271 2.80701 16.6501 3.53315 15.8793 3.99457C16.6113 3.90829 17.3265 3.71231 18 3.41322C17.5042 4.15618 16.8797 4.80335 16.1572 5.3262Z'
        fill='#1D9BF0'
      />
    </svg>
  );
};
