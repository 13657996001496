import { IconProps } from '.';

export const VisitOverviewIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 1.5C12.3131 1.5 15.1244 3.64818 16.1171 6.62765L17.5405 6.15318C16.3492 2.57782 12.9757 0 9 0C4.02944 0 0 4.02944 0 9C0 12.9757 2.57783 16.3493 6.1532 17.5405L6.62767 16.1171C3.64819 15.1244 1.5 12.3131 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5ZM12 18L9 9L18 12L13.5 13.5L12 18ZM13.2702 7.57659C12.6746 5.78891 10.9878 4.5 9 4.5C6.51472 4.5 4.5 6.51472 4.5 9C4.5 10.9878 5.78891 12.6746 7.57659 13.2702L7.10212 14.6936C4.71855 13.8995 3 11.6504 3 9C3 5.68629 5.68629 3 9 3C11.6504 3 13.8995 4.71854 14.6936 7.10212L13.2702 7.57659ZM9 7.5C9.6626 7.5 10.2249 7.92963 10.4234 8.52551L11.8468 8.05104C11.4497 6.85926 10.3252 6 9 6C7.34315 6 6 7.34315 6 9C6 10.3252 6.85926 11.4497 8.05104 11.8468L8.52549 10.4234C7.92962 10.2248 7.5 9.6626 7.5 9C7.5 8.17157 8.17157 7.5 9 7.5Z'
        fill={color}
      />
    </svg>
  );
};
