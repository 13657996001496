import { createNewQuestion, getIcon, getLabelFromValue, QUESTION_TYPE } from '@va/dashboard/modules/feedback/shared';
import { useTranslate } from '@va/localization';
import { FormContext } from '@va/util/misc';
import classNames from 'classnames';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { memo, useCallback, useContext, useMemo } from 'react';
import Select from 'react-select';
import VerticalDivider from './VerticalDivider';

const QuestionTypeDropdown = ({ name, remove, index, replace }) => {
  const formConfig = useContext(FormContext);
  const { isFormDisabled } = formConfig;

  const options = useMemo(
    () =>
      Object.keys(QUESTION_TYPE).map((item) => {
        return { value: QUESTION_TYPE[item], label: item.toLowerCase() };
      }),
    [],
  );

  const fieldFn = useCallback(
    ({ field, form }) => (
      <Select
        isDisabled={isFormDisabled}
        input
        defaultValue={{ value: field.value, label: getLabelFromValue(field.value) }}
        styles={customStyles}
        options={options}
        components={customComponents}
        isSearchable={false}
        isClearable={false}
        onChange={(option) => {
          replace(index, createNewQuestion(option.value));
        }}
      />
    ),
    [isFormDisabled, options, replace, index],
  );

  return <Field name={name}>{fieldFn}</Field>;
};

QuestionTypeDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default memo(QuestionTypeDropdown);

const Option = (props) => {
  const { data, options, selectOption, isSelected } = props;
  const { value, label } = data;
  let isLast = false;

  if (options[options.length - 1] === data) {
    isLast = true;
  }

  const Icon = getIcon(value);

  const translate = useTranslate();

  return (
    <div className='flex h-14 rounded-lg cursor-pointer' onClick={() => selectOption(data)}>
      <div className={classNames('h-full', { 'border-b-2 border-gray-gallery': !isLast })}>
        <div
          className={classNames('border-2 border-white flex justify-center rounded-l-lg items-center h-full w-14', {
            'bg-alabaster': isSelected,
          })}
        >
          <Icon />
        </div>
      </div>

      <VerticalDivider />

      <div className='flex grow items-center pl-2'>{translate('feedback.question.' + label)}</div>
    </div>
  );
};

const SingleValue = ({ getValue }) => {
  const selectedOption = getValue()[0];
  const Icon = getIcon(selectedOption.value);
  const translate = useTranslate();

  return (
    <div className='flex h-14 rounded-lg items-center w-full'>
      <div className='border-2 border-white flex justify-center rounded-l-lg items-center h-full w-14 bg-alabaster shrink-0'>
        <Icon />
      </div>

      <VerticalDivider />

      <div className='truncate pl-2 min-w-0 h-full flex items-center'>
        {translate(`feedback.question.${selectedOption.label.toLowerCase()}`)}
      </div>
    </div>
  );
};

const customStyles = {
  menu: (provided) => ({
    ...provided,
    borderTop: '0',
    borderBottom: '0',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.06)',
    borderRadius: '12px 0px 12px 12px',
    margin: 0,
    zIndex: 2,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    flexWrap: 'no-wrap',
  }),
  control: (provided) => ({
    ...provided,
    cursor: 'pointer',
    border: '2px solid #EEEEEE',
    borderRadius: '0.5rem 0 0 0.5rem',
    boxShadow: 'none',
    '&:hover': {
      border: '2px solid #EEEEEE',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

const customComponents = { Option, SingleValue };
