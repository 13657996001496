import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { Formik } from 'formik';
import { number, object, string } from 'yup';

import { modalName, supportEmailAddress } from '@va/constants';
import { closeModal } from '@va/dashboard/actions/ui';
import { getAccountInformation } from '@va/dashboard/selectors/api';
import { getProviderName } from '@va/dashboard/selectors/customization';
import PopUpCard from '@va/deprecated/components/PopUpCard';
import ModalFooter from 'standalone/components/modals/common/components/modal-footer';
import CustomOfferFields from './CustomOfferFields';
import EmailTemplateCustomOffer from './EmailTemplateCustomOffer';
import './style.scss';

class CustomUpgradeOfferComponent extends Component {
  handleFormSubmission(values) {
    const mailSubject = this.props.translate('customOffer.email.subject', {
      providerName: this.props.providerName,
    });
    const mailBody = EmailTemplateCustomOffer(values, this.props.translate);
    window.open(`mailto:${supportEmailAddress}?subject=${mailSubject}&body=${mailBody}`);
    this.props.closeModal();
  }

  render() {
    const { translate, hasBackButton } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={this.props.initialValues && this.props.initialValues}
        validationSchema={schema}
        onSubmit={(values) => this.handleFormSubmission(values)}
      >
        {({ handleSubmit, submitForm, isValid, errors, setFieldTouched }) => (
          <form
            name='custom-plan'
            onSubmit={handleSubmit}
            className='custom-plan-form flex flex-col h-full cancel-parent-padding'
          >
            <PopUpCard
              type={'card-grey'}
              title={translate('modal.customOffer.title')}
              colorTheme={'no-theme'}
              footerButtons={
                <ModalFooter
                  submitBtnText={translate('button.sendOffer')}
                  cancelBtnText={translate('button.back')}
                  onCancelClick={this.props.backToInitialScreen}
                  isSubmitDisabled={!isValid}
                  setFieldTouched={setFieldTouched}
                  onSubmitClick={submitForm}
                />
              }
              hasBackButton={hasBackButton}
            >
              <CustomOfferFields translate={translate} errors={errors} />
            </PopUpCard>
          </form>
        )}
      </Formik>
    );
  }
}

const schema = object().shape({
  industry: number().required('form.requiredField'),
  traffic: string().required('form.requiredField'),
  email: string().required('form.requiredField'),
});

CustomUpgradeOfferComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  backToInitialScreen: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  hasBackButton: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const accountInfo = getAccountInformation(state);
  return {
    providerName: getProviderName(state),
    initialValues: {
      email: accountInfo.email,
      companyName: accountInfo.companyName,
    },
  };
};

const mapDispatchToProps = {
  closeModal: () => closeModal(modalName.upgrade),
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomUpgradeOfferComponent);
