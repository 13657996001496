import { modalName, tabNames, tabsWithFeatureLimit } from '@va/constants';
import { hasUnlimitedVoucher } from '@va/dashboard/selectors/app';
import { getActiveTab, isModalOpen } from '@va/dashboard/selectors/ui';
import { useFeatureCounterContext } from '@va/dashboard/shared/feature-counter';
import { useTranslate } from '@va/localization';
import { isAccountSettingsPageSelected } from '@va/standalone/shared/helpers';
import { hasUserRole } from '@va/standalone/shared/selectors';
import { UserRole } from '@va/types/website';
import { TooltipWrapper } from '@va/ui/tooltips';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Counter } from './counter';
import FeaturesCounterTooltip from './features-status-counter/FeaturesCounterTooltip';
import { isWixApp } from '@va/util/helpers';

export const FeatureCounter = () => {
  const translate = useTranslate();
  const activeTab = useSelector(getActiveTab);
  const isUpgradeModalOpen = useSelector((state) => isModalOpen(state, modalName.upgrade));
  const unlimitedVoucherExist: boolean = useSelector(hasUnlimitedVoucher);
  const isDashboardContributor = useSelector(hasUserRole)(UserRole.DASHBOARD);

  const featureCounts: any = useFeatureCounterContext();

  const counterInfo = useMemo(() => {
    if (!tabsWithFeatureLimit.includes(activeTab)) {
      return {
        label: translate('featureCounter.pageVisits.limitLeft'),
        total: featureCounts?.pageVisits?.limit ?? 0,
        consumed: featureCounts?.pageVisits?.count ?? 0,
      };
    }

    if (activeTab === tabNames.eventTracking && isWixApp()) {
      return {
        label: translate(`featureCounter.eventTrackingWix.limitLeft`),
        total: featureCounts?.[activeTab]?.limit ?? 0,
        consumed: featureCounts?.[activeTab]?.count ?? 0,
      };
    }

    return {
      label: translate(`featureCounter.${activeTab}.limitLeft`),
      total: featureCounts?.[activeTab]?.limit ?? 0,
      consumed: featureCounts?.[activeTab]?.count ?? 0,
    };
  }, [activeTab, featureCounts, translate]);

  if (isDashboardContributor) return null;
  if (isAccountSettingsPageSelected()) return null;

  return (
    <TooltipWrapper
      tooltipClassNames='rounded-3xl sm:px-3 feature-counter-tooltip sm:w-full w-560px max-w-560px'
      placement='bottom'
      interactive
      content={<FeaturesCounterTooltip />}
      disabled={isUpgradeModalOpen}
      trigger={'click'}
      arrow={false}
      useDefaultStyle={false}
    >
      <div className='w-full lg:w-auto sm:mb-18px'>
        <Counter
          className='w-full lg:w-auto md:mr-3'
          consumed={counterInfo?.consumed}
          total={counterInfo?.total}
          label={counterInfo?.label}
          hasInfiniteLimit={unlimitedVoucherExist}
        />
      </div>
    </TooltipWrapper>
  );
};

export default memo(FeatureCounter);
