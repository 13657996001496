import { Selectors3asFPermissions, selectAllowUpgrade, selectStpLimit } from '@va/aaas/shared/selectors';
import { PlanIndexes, PlanNames } from '@va/constants';
import { startUpgradeProcess } from '@va/dashboard/actions/app';
import { getCurrentPlanName } from '@va/dashboard/selectors/app';
import { UpgradePlanTooltipComponent } from '@va/dashboard/ui/components';
import { useLocale, useTranslate } from '@va/localization';
import { setUpgradePlanIndex } from '@va/standalone/shared/actions';
import { hasPermission } from '@va/standalone/shared/selectors';
import { BlinkingCircle, Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';

import { addNumberSeparator, is3asApp } from '@va/util/helpers';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function CompanyRevealPreview() {
  const translate = useTranslate();
  return (
    <TooltipWrapper
      content={is3asApp() ? <AaasMinCreditsTooltip /> : <UpgradePlanTooltip />}
      useDefaultStyle={false}
      trigger='click'
      interactive
    >
      <div className='flex gap-[6px] items-center cursor-pointer'>
        <BlinkingCircle color='#F66F1E' />
        <Paragraph className='text-orange-pure truncate' size={paragraphSizes.tiny} weight={fontWeights.medium}>
          {translate('all.latestVisitors.ipCell.ip2CompanyPreview.label')}
        </Paragraph>
      </div>
    </TooltipWrapper>
  );
}

const UpgradePlanTooltip = () => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const activePlanName = useSelector(getCurrentPlanName);
  const hasUpgradePermission = useSelector(hasPermission);

  return (
    <UpgradePlanTooltipComponent
      className='max-w-[384px]'
      requiredPlanText={translate('all.upgradePlanTooltip.requiredPlan', { planName: PlanNames.Pro })}
      currentPlanText={translate('all.upgradePlanTooltip.currentPlan', { planName: activePlanName })}
      title={translate('all.ip2companyTooltip.title')}
      text={translate('all.ip2companyTooltip.details')}
      image={<Image />}
      onUpgradeClick={() => {
        if (!hasUpgradePermission) return;
        dispatch(setUpgradePlanIndex(PlanIndexes.Pro));
        dispatch(startUpgradeProcess());
      }}
    />
  );
};

const AaasMinCreditsTooltip = () => {
  const translate = useTranslate();
  const stpLimit = useSelector(selectStpLimit);
  const minStps = useSelector(Selectors3asFPermissions.limit.ipToCompany);
  const allowUpgrade = useSelector(selectAllowUpgrade);
  const dispatch = useDispatch();
  const { locale } = useLocale();

  const onIntpUpgrade = useCallback(() => {
    dispatch(startUpgradeProcess());
  }, [dispatch]);

  return (
    <UpgradePlanTooltipComponent
      className='max-w-[384px]'
      requiredPlanText={translate('aaas.requiredCreditsCount', { count: addNumberSeparator(minStps, locale) })}
      currentPlanText={translate('aaas.currentCreditsCount', { count: addNumberSeparator(stpLimit, locale) })}
      title={translate('all.ip2companyTooltip.title')}
      text={translate('all.ip2companyTooltip.details')}
      image={<Image />}
      onUpgradeClick={allowUpgrade ? onIntpUpgrade : undefined}
    />
  );
};

const Image = () => {
  const translate = useTranslate();
  return (
    <div
      className='flex flex-row rounded-18 relative pl-6 gap-3 overflow-hidden'
      style={{ background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)' }}
    >
      <div className='flex flex-col items-start justify-center'>
        <Paragraph size={paragraphSizes.tiny} weight={fontWeights.medium} colorClassName='text-white/75'>
          {translate('all.ip2companyTooltip.mockIp')}
        </Paragraph>
        <Paragraph className='text-orange-tan' weight={fontWeights.medium}>
          {translate('all.ip2companyTooltip.mockCompany')}
        </Paragraph>
      </div>
      <Globe />
    </div>
  );
};

const Globe = () => {
  return (
    <svg
      width='90'
      height='90'
      viewBox='0 0 90 90'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{
        margin: '-9px -9px -9px auto',
      }}
    >
      <mask
        id='mask0_1238_28963'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='90'
        height='90'
      >
        <circle cx='45' cy='45' r='43.875' stroke='#FFCF78' strokeWidth='2.25' />
        <path
          d='M73.875 45C73.875 57.2353 70.5667 68.2614 65.2771 76.1958C59.9823 84.138 52.7911 88.875 45 88.875C37.2089 88.875 30.0177 84.138 24.7229 76.1958C19.4333 68.2614 16.125 57.2353 16.125 45C16.125 32.7647 19.4333 21.7386 24.7229 13.8042C30.0177 5.86197 37.2089 1.125 45 1.125C52.7911 1.125 59.9823 5.86197 65.2771 13.8042C70.5667 21.7386 73.875 32.7647 73.875 45Z'
          stroke='#FFCF78'
          strokeWidth='2.25'
        />
        <path
          d='M58.875 45C58.875 57.3391 57.2062 68.4634 54.5393 76.4641C53.2039 80.4702 51.6386 83.6311 49.9551 85.7673C48.2616 87.9162 46.5786 88.875 45 88.875C43.4214 88.875 41.7384 87.9162 40.0449 85.7673C38.3614 83.6311 36.7961 80.4702 35.4607 76.4641C32.7938 68.4634 31.125 57.3391 31.125 45C31.125 32.6609 32.7938 21.5366 35.4607 13.536C36.7961 9.52977 38.3614 6.36889 40.0449 4.23267C41.7384 2.08376 43.4214 1.125 45 1.125C46.5786 1.125 48.2616 2.08376 49.9551 4.23267C51.6386 6.36889 53.2039 9.52977 54.5393 13.536C57.2062 21.5366 58.875 32.6609 58.875 45Z'
          stroke='#FFCF78'
          strokeWidth='2.25'
        />
        <path
          d='M45 73.875C32.7647 73.875 21.7386 70.5667 13.8042 65.2771C5.86197 59.9823 1.125 52.7911 1.125 45C1.125 37.2089 5.86197 30.0177 13.8042 24.7228C21.7386 19.4332 32.7647 16.125 45 16.125C57.2353 16.125 68.2614 19.4333 76.1958 24.7229C84.138 30.0177 88.875 37.2089 88.875 45C88.875 52.7911 84.138 59.9823 76.1958 65.2771C68.2614 70.5667 57.2353 73.875 45 73.875Z'
          stroke='#FFCF78'
          strokeWidth='2.25'
        />
        <path
          d='M45 58.875C32.6609 58.875 21.5366 57.2062 13.5359 54.5393C9.52976 53.2039 6.36889 51.6386 4.23267 49.9551C2.08376 48.2616 1.125 46.5786 1.125 45C1.125 43.4214 2.08376 41.7384 4.23267 40.0449C6.36889 38.3614 9.52976 36.7961 13.5359 35.4607C21.5366 32.7938 32.6609 31.125 45 31.125C57.3391 31.125 68.4634 32.7938 76.464 35.4607C80.4702 36.7961 83.6311 38.3614 85.7673 40.0449C87.9162 41.7384 88.875 43.4214 88.875 45C88.875 46.5786 87.9162 48.2616 85.7673 49.9551C83.6311 51.6386 80.4702 53.2039 76.464 54.5393C68.4634 57.2062 57.3391 58.875 45 58.875Z'
          stroke='#FFCF78'
          strokeWidth='2.25'
        />
      </mask>
      <g mask='url(#mask0_1238_28963)'>
        <rect x='-9' y='9' width='90' height='72' fill='url(#paint0_linear_1238_28963)' />
      </g>
      <defs>
        <linearGradient id='paint0_linear_1238_28963' x1='-9' y1='45' x2='81' y2='45' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#FFB380' />
          <stop offset='1' stopColor='#FFCC80' />
        </linearGradient>
      </defs>
    </svg>
  );
};
