import { IconProps } from '.';

export const PageConversionIcon = ({ color, className = '' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.5 12H14.25C15.4926 12 16.5 10.9926 16.5 9.75V7.5H1.5V13.5C1.5 15.1569 2.84315 16.5 4.5 16.5H9.75C10.9926 16.5 12 15.4926 12 14.25V13.5V12H13.5ZM1.5 6H16.5V4.5C16.5 2.84315 15.1569 1.5 13.5 1.5H4.5C2.84315 1.5 1.5 2.84315 1.5 4.5V6ZM18 7.5V6V4.5C18 2.01472 15.9853 0 13.5 0H4.5C2.01472 0 0 2.01472 0 4.5V6V7.5V13.5C0 15.9853 2.01472 18 4.5 18H9.75C11.8211 18 13.5 16.3211 13.5 14.25V13.5H14.25C16.3211 13.5 18 11.8211 18 9.75V7.5ZM3.75 4.5C4.16421 4.5 4.5 4.16421 4.5 3.75C4.5 3.33579 4.16421 3 3.75 3C3.33579 3 3 3.33579 3 3.75C3 4.16421 3.33579 4.5 3.75 4.5ZM7.5 3.75C7.5 4.16421 7.16421 4.5 6.75 4.5C6.33579 4.5 6 4.16421 6 3.75C6 3.33579 6.33579 3 6.75 3C7.16421 3 7.5 3.33579 7.5 3.75ZM9.75 4.5C10.1642 4.5 10.5 4.16421 10.5 3.75C10.5 3.33579 10.1642 3 9.75 3C9.33579 3 9 3.33579 9 3.75C9 4.16421 9.33579 4.5 9.75 4.5Z'
        fill={color ?? 'black'}
      />
    </svg>
  );
};
