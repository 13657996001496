import { EmptyItem, WeeblyIcon, WixIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { InstallPluginsView } from '@va/standalone/core/components';
import { Paragraph, paragraphSizes } from '@va/ui/design-system';
import { Dispatch, SetStateAction, useMemo } from 'react';

const ChooseWebsitePlatformStep = ({ setActiveStep }: { setActiveStep: Dispatch<SetStateAction<number>> }) => {
  const translate = useTranslate();

  const platformsList = useMemo(
    () => [
      {
        title: translate('trackingCode.installation.wix'),
        subtitle: translate('register.installPlugin'),
        icon: WixIcon,
        onClick: () => window.open(translate('trackingCode.installation.wixUrl')),
      },
      {
        title: translate('trackingCode.installation.weebly'),
        subtitle: translate('register.installPlugin'),
        icon: WeeblyIcon,
        onClick: () => window.open(translate('trackingCode.installation.weeblyUrl')),
      },
      {
        title: translate('trackingCode.installation.noPlatform'),
        subtitle: translate('trackingCode.installation.noPlatformInfo'),
        icon: EmptyItem,
        onClick: () => {
          setActiveStep((prevStep: number) => {
            const currStep = prevStep + 1;
            return currStep;
          });
        },
      },
    ],
    [translate, setActiveStep],
  );

  return (
    <div className='mt-12'>
      <Paragraph className='!font-550 text-gray-code text-center' size={paragraphSizes.large}>
        {translate('standalone.websiteCreation.choosePlatform.title')}
      </Paragraph>
      <InstallPluginsView platformsList={platformsList} />
    </div>
  );
};

export default ChooseWebsitePlatformStep;
