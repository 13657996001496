import { flagKeys } from './misc.constants';

export const PLATFORM_IDS = {
  WIX: 0,
  CUSTOM: 1,
  WORDPRESS: 2,
  KYVIO: 3,
  DRUPAL: 4,
  DRUPAL7: 5,
  DRUPAL8: 7,
  TYPO: 7,
  OCTOBER: 8,
  MAGENTO: 9,
  OPENCART: 10,
  WEEBLY: 11,
};

export enum Platform {
  WIX = 'WIX',
  CUSTOM = 'CUSTOM',
  KYVIO = 'KYVIO',
  WEEBLY = 'WEEBLY',
  WORDPRESS = 'WORDPRESS',
  DRUPAL = 'DRUPAL',
  OCTOBER = 'OCTOBER',
  TYPO = 'TYPO',
  JOOMLA = 'JOOMLA',
  MAGENTO = 'MAGENTO',
  OPENCART = 'OPENC',
  AAAS = 'AAAS',
  PIXPA = 'PIXPA',
  // todo make sure these are used or remove them
  DRUPAL7 = 'DRUPAL7',
  DRUPAL8 = 'DRUPAL8',
}

export enum PlanNames {
  Free = 'Free',
  Basic = 'Basic',
  Advanced = 'Advanced',
  Pro = 'Pro',
  ProPlus = 'Pro +',
  Custom = 'Custom',

  // Deleted plans; might still be found on subscriptions that were created before deletion
  ProPlusPlus = 'Pro ++',
  Executive = 'Executive',
  ExecutivePlus = 'Executive +',
  ExecutivePlusPlus = 'Executive ++',
  Unicorn = 'Unicorn',
}

export const DeletedPlans = [
  PlanNames.ProPlusPlus,
  PlanNames.Executive,
  PlanNames.ExecutivePlus,
  PlanNames.ExecutivePlusPlus,
  PlanNames.Unicorn,
];

export enum PlanIndexes {
  Basic = 0,
  Advanced = 1,
  Pro = 2,
  ProPlus = 3,
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}

export enum TrackingCodeStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}

export enum ApiRequestStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

export const POLLS_AND_SURVEYS_QUESTION_LIMIT = 65;
export const MAXIMUM_FUNNEL_LEVELS_COUNT = 8;

export const UPDATE_WEBSITES_EVENT = 'UPDATE_WEBSITES';

export const FRONTEND_FLAGS_ADDED_BEFORE_FIRST_LOGIN = [
  flagKeys.ONBOARDING_FINISHED,
  flagKeys.LAST_COMPLETED_ONBOARDING_STEP,
];

export const UNTRANSLATED_LOCALE = 'UNTR';

export const websiteCreationSteps = {
  choosePlatform: 0,
  websiteUrl: 1,
  privacyLevels: 2,
  loading: 3,
  trackingCodeInstallation: 4,
};

export const websiteFlagKeys = {
  CREATION_PROCESS_COMPLETED: 'creationCompleted',
  ACCEPTED_HEATMAP: 'acceptedHeatmap',
  RECORDINGS_SETTINGS: 'recordingsSettings',
  WELCOME_FUNNELS: 'welcomeFunnel',
  ONBOARDING_COMPLETED: 'onboardingCompleted',
  LAST_COMPLETED_ONBOARDING_STEP: 'lastCompletedOnboardingStep',
};

export const featureTourFlagKeys = {
  dashboard: 'dashboardTourCompleted',
};

export const defaultPrivacyLevel = [
  {
    levelKey: 0,
    selected: false,
    exceptions: [],
  },
  {
    levelKey: 1,
    selected: false,
    exceptions: [],
  },
  {
    levelKey: 2,
    selected: false,
    exceptions: [],
  },
  {
    levelKey: 3,
    selected: true,
    exceptions: [],
  },
];

export type SupportedCurrency = 'EUR' | 'USD' | 'GBP' | 'RON';

export type Currency = {
  code: SupportedCurrency;
  symbol?: string;
};

export const websiteCurrencies: Currency[] = [
  { code: 'EUR', symbol: '€' },
  { code: 'USD', symbol: '$' },
  { code: 'GBP', symbol: '£' },
  { code: 'RON' },
];

export const currencySymbols: { [key in SupportedCurrency]: string } = {
  EUR: '€',
  USD: '$',
  GBP: '£',
  RON: '',
};
