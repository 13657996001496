export function StarV2(props) {
  const { color, ...otherProps } = props;
  const colorValue = color ? color : '#666666';

  return (
    <svg {...otherProps} width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.35026 2.40718C9.14467 1.24803 10.8553 1.24803 11.6497 2.40717L13.1247 4.55925C13.3847 4.93871 13.7676 5.21692 14.2089 5.34699L16.7114 6.08469C18.0593 6.48202 18.5879 8.10896 17.731 9.22269L16.14 11.2904C15.8595 11.655 15.7132 12.1052 15.7259 12.565L15.7976 15.173C15.8363 16.5777 14.4523 17.5832 13.1283 17.1124L10.6701 16.2383C10.2367 16.0842 9.76334 16.0842 9.3299 16.2383L6.87172 17.1124C5.5477 17.5832 4.16374 16.5777 4.20238 15.173L4.27411 12.565C4.28675 12.1052 4.14049 11.655 3.85997 11.2904L2.269 9.22269C1.41207 8.10896 1.94069 6.48203 3.28859 6.08469L5.79111 5.34699C6.23236 5.21692 6.61528 4.93871 6.87535 4.55925L8.35026 2.40718Z'
        stroke={colorValue}
        strokeWidth='2'
      />
    </svg>
  );
}
