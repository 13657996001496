import { useTranslate } from '@va/localization';
import { ConfirmationDialogType, ConfirmationModal } from '@va/shared/util-confirmation';
import { Paragraph } from '@va/ui/design-system';

export const RemoveAdditionalFiltersModal: ConfirmationDialogType = ({ onClose, onConfirm }) => {
  const translate = useTranslate();

  return (
    <ConfirmationModal
      title={translate('modal.confirmation.removeAllFilters.title')}
      info={<Paragraph>{translate('modal.confirmation.removeAllFilters.subTitle')}</Paragraph>}
      onClose={onClose}
      isOpen
      onConfirmation={() => onConfirm()}
      actionButtonText={translate('button.remove')}
    />
  );
};
