import { BLACK_COLOR, WHITE_COLOR } from './constants';

export function ExportVisitsMetrics(props) {
  return (
    <svg
      viewBox='0 0 30 30'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <path
        fill={props.isActive ? WHITE_COLOR : BLACK_COLOR}
        d='M19.9,13.4c-0.3-0.7-0.9-1.2-1.8-1.2c-0.3,0-0.5,0.1-0.8,0.2c-0.4-0.5-1.1-0.8-1.8-0.7v-2
      c0-1.1-0.8-1.9-1.9-1.9c-1.1,0-1.9,0.8-1.9,1.9v3.9c-0.9-0.6-2.1-1.2-3-0.3c-1,1-0.1,2.5,1.2,4.4c0.5,0.8,1.2,1.7,1.7,2.8
      c1.6,2.8,3.7,3.4,5.3,3.4c2.9,0,5.2-2.3,5.2-5.2v-3.3c0-1.1-0.8-1.9-1.9-1.9C20.2,13.3,20,13.3,19.9,13.4z M21.2,15.2v3.3
      c0,2.3-1.9,4.2-4.2,4.2c-1.9,0-3.3-1-4.4-2.8c-0.6-1.1-1.2-2-1.8-2.9c-1.1-1.6-1.7-2.7-1.3-3.1c0.1-0.1,0.3-0.2,0.4-0.2
      c0.5,0,1.1,0.4,2,1.2l0.8,0.8V9.6c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9v3.8l0.8-0.6c0.4-0.3,1.1-0.2,1.3,0.4l0.2,0.6l0.5-0.4
      c0.2-0.1,0.4-0.2,0.6-0.2c0.5,0,0.9,0.4,0.9,0.9v1l0.8-0.6c0.2-0.1,0.3-0.2,0.5-0.2C20.8,14.3,21.2,14.7,21.2,15.2z'
      />
      <path
        fill={props.isActive ? WHITE_COLOR : BLACK_COLOR}
        d='M21.6,3h-1h-16v24h23V10V9L21.6,3z M26.2,9h-4.6V4.4L26.2,9z M26.6,26h-21V4h15v6h6V26z'
      />
    </svg>
  );
}

export function ExportVisitorsMetrics(props) {
  return (
    <svg
      viewBox='0 0 30 30'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <g>
        <path
          fill={props.isActive ? WHITE_COLOR : BLACK_COLOR}
          d='M13.3,18.7c0.7,0.1,1.4,0.3,2.1,0.4c0.8,0.2,1.6,0.3,2.4,0.5c0,1.9,0,3.9,0,5.8H1.8c0-1.9,0-3.9,0-5.8
        c1.5-0.3,3-0.6,4.5-0.9c0.8,0.7,2,1.1,3.5,1.1C11.3,19.9,12.5,19.5,13.3,18.7 M13,17.7c-0.5,0.8-1.9,1.2-3.2,1.2
        c-1.3,0-2.6-0.4-3.1-1.2c-2,0.4-3.9,0.8-5.8,1.2c0,2.5,0,5.1,0,7.6h17.9c0-2.5,0-5.1,0-7.6C16.8,18.4,14.9,18,13,17.7L13,17.7z'
        />
        <path
          fill={props.isActive ? WHITE_COLOR : BLACK_COLOR}
          d='M9.7,5.6c1.1,0,2.3,0.5,3.1,1.3c1,1,1.5,2.5,1.2,3.9c-0.3,1.7-1.9,3.2-3.7,3.4c-0.2,0-0.4,0-0.6,0
        c-1.2,0-2.4-0.5-3.2-1.4c-1-1.1-1.4-2.8-0.9-4.3c0.5-1.5,2-2.7,3.6-2.9C9.5,5.6,9.6,5.6,9.7,5.6 M9.7,4.6c-0.2,0-0.3,0-0.5,0
        c-2,0.2-3.9,1.6-4.5,3.5c-0.6,1.8-0.2,3.9,1.1,5.3c1,1.1,2.4,1.8,3.9,1.8c0.2,0,0.5,0,0.7-0.1c2.2-0.2,4.1-2,4.5-4.2
        c0.4-1.7-0.2-3.6-1.4-4.8C12.6,5.2,11.2,4.6,9.7,4.6L9.7,4.6z'
        />
        <path
          fill={props.isActive ? WHITE_COLOR : BLACK_COLOR}
          d='M21.5,5.6c0.9,0,1.9,0.4,2.5,1.1c0.8,0.8,1.2,2,0.9,3.2c-0.3,1.4-1.5,2.6-3,2.8c-0.2,0-0.3,0-0.5,0
        c-1,0-1.9-0.4-2.6-1.2C18,10.6,17.8,9.2,18.2,8c0.4-1.2,1.6-2.2,3-2.3C21.3,5.6,21.4,5.6,21.5,5.6 M21.5,4.6c-0.1,0-0.3,0-0.4,0
        c-1.7,0.2-3.3,1.4-3.8,3c-0.5,1.5-0.2,3.3,0.9,4.5c0.8,0.9,2.1,1.5,3.3,1.5c0.2,0,0.4,0,0.6,0c1.9-0.2,3.5-1.7,3.8-3.6
        c0.3-1.5-0.2-3-1.2-4.1C23.9,5.1,22.7,4.6,21.5,4.6L21.5,4.6z'
        />
        <path
          fill={props.isActive ? WHITE_COLOR : BLACK_COLOR}
          d='M14.9,18c0-0.2,0-0.3,0-0.5c1.2-0.2,2.4-0.5,3.6-0.7c0.7,0.6,1.8,0.9,3,0.9c1.3,0,2.3-0.3,3-0.9
        c0.6,0.1,1.1,0.2,1.7,0.3c0.6,0.1,1.3,0.3,1.9,0.4c0,1.6,0,3.1,0,4.7h-9.4c0,0.3,0,0.7,0,1h10.4c0-2.2,0-4.3,0-6.5
        c-1.7-0.3-3.3-0.7-4.9-1c-0.5,0.7-1.6,1-2.7,1c-1.1,0-2.2-0.3-2.6-1c-1.7,0.3-3.3,0.7-5,1c0,0.4,0,0.8,0,1.1
        C14.2,17.9,14.6,18,14.9,18z'
        />
      </g>
    </svg>
  );
}

export function VisitsLimitReachedIcon(props) {
  return (
    <svg width='165' height='98' viewBox='0 0 165 98' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M103.499 60.8791L91.52 85.7276L91.498 85.782L91.476 85.8365L91.432 85.9345C90.948 86.9254 90.299 87.8182 89.507 88.5914C87.626 90.4425 85.14 91.466 82.5 91.466C79.86 91.466 77.374 90.4425 75.504 88.5914C71.643 84.7694 71.643 78.5518 75.504 74.7298C76.307 73.9349 77.22 73.2925 78.232 72.8242L78.562 72.6718L103.499 60.8791ZM117.502 47.0176L75.713 66.77L75.383 66.9225C73.722 67.6956 72.182 68.7627 70.829 70.1129C64.383 76.4938 64.394 86.8382 70.829 93.2082C74.052 96.4096 78.276 97.9994 82.5 97.9994C86.724 97.9994 90.948 96.3987 94.171 93.2082C95.535 91.858 96.602 90.3336 97.394 88.7002L97.471 88.526L117.502 47.0176Z'
        fill='#FC355A'
      />
      <path
        d='M164.736 75.1333C163.24 56.3282 155.309 39.3307 143.121 26.2749C141.625 24.6742 140.074 23.128 138.457 21.658C124.509 8.89622 106.095 0.860222 85.8 0.0653333C84.711 0.0217778 83.611 0 82.5 0C81.389 0 80.289 0.0217778 79.2 0.0653333C58.905 0.849333 40.502 8.89622 26.554 21.658C24.926 23.128 23.364 24.6742 21.879 26.2858C9.691 39.3416 1.76 56.3391 0.264 75.1333C0.088 77.2893 0 79.4671 0 81.6667H23.1C23.1 79.4562 23.221 77.2784 23.463 75.1333C24.86 62.6764 30.217 51.3738 38.247 42.4884C39.71 40.866 41.283 39.3198 42.922 37.8607C52.668 29.2149 65.307 23.716 79.2 22.9647C80.289 22.8993 81.389 22.8667 82.5 22.8667C83.611 22.8667 84.711 22.8993 85.8 22.9647C99.704 23.716 112.332 29.2258 122.089 37.8607C123.728 39.3198 125.279 40.8551 126.753 42.4776C134.783 51.3629 140.14 62.6656 141.537 75.1333C141.779 77.2784 141.9 79.4562 141.9 81.6667H165C165 79.4671 164.912 77.2893 164.736 75.1333ZM16.83 75.1333H6.886C8.36 58.1684 15.576 42.7933 26.576 30.9244L33.55 37.828C24.332 47.9111 18.26 60.8471 16.83 75.1333ZM79.2 16.4204C63.459 17.1827 49.17 23.4002 38.225 33.2111L31.251 26.3076C44 14.7218 60.753 7.39356 79.2 6.60956V16.4204ZM85.8 16.4204V6.60956C104.247 7.39356 121.011 14.7218 133.76 26.3076L126.786 33.2111C115.841 23.4002 101.541 17.1827 85.8 16.4204Z'
        fill='#FC355A'
      />
    </svg>
  );
}
