import classNames from 'classnames';
import './blinking-circle.css';

type BlinkingCircleProps = {
  color: string;
  className?: string;
};

export function BlinkingCircle({ color = '#F66F1E', className }: BlinkingCircleProps) {
  return (
    <div className={classNames('circle shrink-0', className)} style={{ '--blinking-circle-color': color }}>
      <div className='inner-circle shrink-0' style={{ '--blinking-circle-color': color }}></div>
    </div>
  );
}
