import { TEST_IDS } from '@va/constants';
import { EraseIcon, SearchIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { useOutsideClick } from '@va/util/hooks';
import classNames from 'classnames';
import { ChangeEvent, useRef, useState } from 'react';

type SearchFieldProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  clearField: () => void;
  value: string;
  name?: string;
  id?: string;
  placeholder?: string;
  className?: string;
  'data-testid'?: string;
};

export const SearchField: React.FC<SearchFieldProps> = ({
  value,
  onChange,
  name,
  id,
  placeholder,
  clearField,
  className,
  'data-testid': dataTestId,
}) => {
  const [focused, setFocused] = useState(false);

  const translate = useTranslate();
  const inputContainerRef = useRef<HTMLDivElement>(null);

  useOutsideClick(inputContainerRef, () => {
    setFocused(false);
  });

  return (
    <div
      ref={inputContainerRef}
      onClick={() => {
        setFocused(true);
      }}
      className={classNames('h-12 rounded-12  border overflow-hidden flex  items-center p-1', className, {
        'border-primary bg-white': focused,
        'border-transparent bg-white-snow': !focused,
      })}
      data-testid={dataTestId}
    >
      <SearchIcon className='shrink-0' color='#696969' />
      <input
        className='p-2 w-full h-full bg-transparent placeholder-gray-devil'
        value={value}
        onChange={onChange}
        name={name}
        id={id}
        placeholder={placeholder ?? translate('labels.search')}
      />

      {value !== '' && (
        <button
          onClick={clearField}
          className={classNames(
            'aspect-square h-full rounded-md flex items-center shrink-0 justify-center hover:bg-gray-concrete',
            {
              'bg-white': !focused,
              'bg-white-snow': focused,
            },
          )}
          data-testid={TEST_IDS.generic.buttons.erase}
        >
          <EraseIcon className='text-gray-charcoal' />
        </button>
      )}
    </div>
  );
};
