import { FEATURE_TOUR_IDS } from '@va/constants';
import { isWebsitePublished } from '@va/dashboard/selectors/app';
import { getWebsiteUrl } from '@va/dashboard/selectors/core';
import { ExternalLinkIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, ButtonProps } from '@va/ui/design-system';
import { getWellFormedUrl, isWebsiteUrlValid } from '@va/util/helpers';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

const GoToWebsiteButton = ({ ...buttonProps }: ButtonProps) => {
  const translate = useTranslate();
  const websiteUrl = useSelector(getWebsiteUrl);
  const isPublished: boolean = useSelector(isWebsitePublished);

  const buttonDisabled = useMemo(() => !isWebsiteUrlValid(isPublished, websiteUrl), [isPublished, websiteUrl]);

  return (
    <Button
      dataTourId={FEATURE_TOUR_IDS.goToWebsiteBtn}
      tooltip={isPublished ? translate('topNav.goToWebsite') : translate('card.tooltip.siteNotPublished')}
      color='secondary'
      icon={() => <ExternalLinkIcon />}
      onClick={() => {
        window.open(getWellFormedUrl(websiteUrl));
      }}
      disabled={buttonDisabled}
      {...buttonProps}
    />
  );
};

export default React.memo(GoToWebsiteButton);
