import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

const DropoutLevel = ({ startPercentage, percentage, value, text, height = 80, width }) => {
  let linesBorderPixels = useMemo(
    () => calculatePixelsForLines(width, startPercentage, Math.max(startPercentage - percentage, 30)),
    [width, startPercentage, percentage],
  );

  return (
    <div className='relative flex justify-center items-center'>
      <div
        className={'m-0 m-auto'}
        style={{
          width: startPercentage + '%',
          borderLeft: linesBorderPixels + 'px solid transparent',
          borderRight: linesBorderPixels + 'px solid transparent',
          borderTop: height + 'px solid #f4f4f4',
        }}
      />
      <div className='flex flex-column justify-center leading-6 text-center absolute'>
        <span className='text-xl text-center text-devil-gray font-semibold'>
          {percentage > 0 && '-'}
          {percentage} %
        </span>
        <span className='level-responses text-sm text-gray-dusty font-semibold'>
          {value > 0 && '-'}
          {value} {text}
        </span>
      </div>
    </div>
  );
};

DropoutLevel.propTypes = {
  startPercentage: PropTypes.number,
  percentage: PropTypes.number,
  value: PropTypes.number,
  text: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default memo(DropoutLevel);

function calculatePixelsForLines(width, currentWidth, nextWidth) {
  const currentLevelMargin = calculateMargin(currentWidth);
  const nextLevelMargin = calculateMargin(nextWidth);
  const differenceBetweenMargins = nextLevelMargin - currentLevelMargin;

  return (width * differenceBetweenMargins) / 100;
}

function calculateMargin(percentage) {
  return (100 - percentage) / 2;
}
