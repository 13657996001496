import { UPDATE_WEBSITES_EVENT } from '@va/constants';
import { WebsitesQueryFilter, WebsiteType } from '@va/types/website';
import { EventBus } from '@va/util/misc';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useGetWebsites } from './apiClient';

export const useWebsiteSelectorInfiniteScroll = (websitesQueryFilter: WebsitesQueryFilter) => {
  const [websites, setWebsites] = useState<WebsiteType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [queryFilter, setQueryFilter] = useState<WebsitesQueryFilter>(websitesQueryFilter);
  const [shouldModifyQueryFilter, setShouldModifyQueryFilter] = useState(true);
  const [controller, setController] = useState(new AbortController());

  const {
    data: websitesData,
    isValidating,
    mutate: getWebsites,
  } = useGetWebsites(controller, queryFilter, {
    onSuccess: () => {
      setShouldModifyQueryFilter(true);
    },
  });
  const totalWebsites = websitesData?.total ?? 0;

  const observer = useRef<IntersectionObserver | null>(null);

  const lastElementRef = useCallback(
    (node: any) => {
      if (!node) return;
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (!shouldModifyQueryFilter) return;
        if (!entries[0].isIntersecting) return;
        if (!websitesData) return;
        const hasMore = websites.length < websitesData?.total;
        if (!hasMore) return;

        setQueryFilter((prev) => ({ ...prev, start: prev.start + 10 }));
        setShouldModifyQueryFilter(false);
      });

      observer.current.observe(node);
    },
    [shouldModifyQueryFilter, websites.length, websitesData],
  );

  useEffect(() => {
    const getWebsiteListener = EventBus.addListener(UPDATE_WEBSITES_EVENT, () => {
      setController((prev) => {
        prev.abort();
        return new AbortController();
      });
      setQueryFilter(websitesQueryFilter);
      setWebsites([]);
      getWebsites();
    });

    return () => {
      getWebsiteListener.removeListener();
    };
  }, [getWebsites]);

  useEffect(() => {
    if (!websitesData) return;

    setWebsites((prev) => {
      const newWebsites: WebsiteType[] = [];
      websitesData.websites.forEach((website: WebsiteType) => {
        const isAlready = prev.find((existentWebsite) => existentWebsite.id === website.id);
        if (isAlready) return;
        newWebsites.push(website);
      });
      return [...prev, ...newWebsites];
    });
  }, [websitesData]);

  const handleChange = useCallback((value: string) => {
    setController((prev) => {
      prev.abort();
      return new AbortController();
    });
    setWebsites([]);
    setQueryFilter((prev) => ({ ...prev, start: 0, url: value }));
  }, []);

  const onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
      handleChange(e.target.value);
    },
    [handleChange],
  );

  const clearSearchField = useCallback(() => {
    setSearchValue('');
    handleChange('');
  }, [handleChange]);

  return { lastElementRef, websites, onSearch, searchValue, clearSearchField, isValidating, totalWebsites };
};
