import { CheckMarkWithCircle, CopyIcon, TrackingCodeIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { TooltipWrapper } from '@va/ui/tooltips';
import { useCheckOverflow } from '@va/util/hooks';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

type CopyActionCardType = {
  subTitle: string;
  title: string;
  className?: string;
};

export const CopyActionCard = (props: CopyActionCardType) => {
  const [isCopied, setIsCopied] = useState(false);
  const { title, subTitle } = props;
  const translate = useTranslate();
  const subTitleRef = useRef(null);
  const isOverflow = useCheckOverflow(subTitleRef);

  const resetCopyState = () => {
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  const handleCopy = () => {
    setIsCopied(true);
    resetCopyState();
  };

  return (
    <CopyToClipboard text={subTitle} onCopy={handleCopy.bind(this)}>
      <div
        key={subTitle}
        className={classNames(
          'bg-white-snow p-3 mb-3 rounded-18 flex items-center justify-between hover:bg-white-smoke cursor-pointer',
          { 'bg-primary-lighter-20': isCopied },
          props.className,
        )}
      >
        <div className='flex w-4/5'>
          <div
            className={classNames('h-12 min-w-[48px] rounded-24 bg-white flex items-center justify-center', {
              'bg-primary': isCopied,
            })}
          >
            <TrackingCodeIcon color='#000000' />
          </div>

          <div className='ml-3 flex justify-center flex-col w-4/5 overflow-hidden'>
            <div className={classNames('text-xs text-devil-gray leading-18 font-medium', { 'text-primary': isCopied })}>
              {title}
            </div>
            <TooltipWrapper
              disabled={!isOverflow}
              content={isCopied ? `${translate('button.copiedToClipboardText')}!` : subTitle}
              ref={subTitleRef}
            >
              <div
                className={classNames('text-sm text-gray-charcoal leading-24 font-medium truncate', {
                  'text-primary': isCopied,
                })}
              >
                {isCopied ? `${translate('button.copiedToClipboardText')}!` : subTitle}
              </div>
            </TooltipWrapper>
          </div>
        </div>
        <div className={'mr-3 cursor-pointer'}>
          {isCopied ? <CheckMarkWithCircle color={'var(--color-primary)'} /> : <CopyIcon color={'#C3C3C3'} />}{' '}
        </div>
      </div>
    </CopyToClipboard>
  );
};
