import { FilterCondition, FilterConditionsObject } from '@va/types/filter-templates';
import { Filter, FilterOperators, FilterOptions, isNestedFiltersPage } from '@va/types/filters';
import { StorageKeysManager } from '@va/util/misc';
import { isNil } from 'lodash';
import { SessionStorage } from '../storage';

export function buildApiConditions(appliedFilters: Filter[]) {
  const conditions: FilterCondition[] = [];

  appliedFilters.forEach((filter) => {
    const { values, operator } = filter;
    const filteredValues = values.filter((val) => val !== '' && !isNil(val));
    if (filteredValues.length === 0) return;

    const apiFieldName = filter.getApiFieldName(values, operator);
    const apiOperator = filter.getApiOperator(operator);
    const mappedValues = filter.getValueMapper(filteredValues, operator);

    const existent = conditions.find((cond) => cond.member === apiFieldName);

    if (existent && existent.operator === apiOperator) {
      existent.values = existent.values.concat(mappedValues);
    } else {
      conditions.push({
        member: apiFieldName,
        operator: apiOperator,
        values: mappedValues,
      });
    }
  });

  return conditions;
}

export function buildApiConditionsObject(appliedFilters: Filter[]) {
  return { and: buildApiConditions(appliedFilters) };
}

export function buildTemplateConditionsObject(
  appliedFilters: Filter[],
  stringifyValues = false,
): FilterConditionsObject {
  return {
    and: appliedFilters.map((filter) => ({
      member: filter.name,
      operator: filter.operator,
      values: stringifyValues ? stringifyFilterTemplatesValues(filter.values) : filter.values,
    })),
  };
}

export const stringifyFilterTemplatesValues = (values: any[]) => {
  return values.map((val) => {
    return JSON.stringify(val);
  });
};

export const parseFilterTemplateValues = (values: string[]): any[] => {
  return values.map((val) => {
    try {
      const parsedVal = JSON.parse(val);
      return parsedVal;
    } catch (error) {
      console.error(error);
      return val;
    }
  });
};

export function storeFilterConditions(websiteId: string, reportBlockId: string, conditionsObj: FilterConditionsObject) {
  SessionStorage.setItem(
    StorageKeysManager.getReportFilterConditionsKey({ websiteId: websiteId, reportBlockId: reportBlockId }),
    JSON.stringify(conditionsObj),
  );
}

export function storeFilters(websiteId: string, reportBlockId: string, appliedFilters: Filter[]) {
  const conditionsObj = buildTemplateConditionsObject(appliedFilters);
  storeFilterConditions(websiteId, reportBlockId, conditionsObj);
}

export function createFiltersFromConditions(conditionsObj: FilterConditionsObject, allFilterOptions: FilterOptions) {
  const filters: Filter[] = [];

  if (!conditionsObj?.and) {
    return filters;
  }

  conditionsObj?.and?.forEach((cond) => {
    const filter = findFilter(allFilterOptions, cond.member);
    if (!filter) return;
    const clone = Object.assign({}, filter);
    clone.values = cond.values;
    clone.operator = cond.operator as FilterOperators;
    filters.push(clone);
  });
  return filters;
}

export function findFilter(options: FilterOptions, name: string): Filter | undefined {
  for (const option of options) {
    if (isNestedFiltersPage(option)) {
      const filter = findFilter(option.filters, name);
      if (filter) {
        return filter;
      }
    } else {
      if (option.name === name) {
        return option;
      }
    }
  }
}

export const storeSegmentIds = (websiteId: string, reportBlockId: string, ids: string[]) => {
  return SessionStorage.setItem(
    StorageKeysManager.getReportSegmentsKey({ websiteId, reportBlockId }),
    JSON.stringify(ids),
  );
};
