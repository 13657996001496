import { addNotificationV2 } from '@va/dashboard/actions/ui';
import { useTranslate } from '@va/localization';
import { NotificationTypeEnum } from '@va/types/notification';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useAddNotification = () => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const showSuccessNotification = useCallback(
    (autoDismiss = 4000, text?: string) => {
      dispatch(
        addNotificationV2({
          text: text ?? translate('notifications.success'),
          autoDismiss: autoDismiss,
          type: NotificationTypeEnum.success,
        }),
      );
    },
    [dispatch, translate],
  );
  const showErrorNotification = useCallback(
    (autoDismiss = 4000, text?: string) => {
      dispatch(
        addNotificationV2({
          text: text ?? translate('notifications.generalError'),
          autoDismiss: autoDismiss,
          type: NotificationTypeEnum.error,
        }),
      );
    },
    [dispatch, translate],
  );

  const showInfoNotification = useCallback(
    (text: React.ReactNode, autoDismissMS = 4000) => {
      dispatch(
        addNotificationV2({
          text: text,
          autoDismiss: autoDismissMS,
          type: NotificationTypeEnum.info,
        }),
      );
    },
    [dispatch],
  );

  return { showSuccessNotification, showErrorNotification, showInfoNotification };
};
