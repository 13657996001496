import { useTranslate } from '@va/localization';
import { Button, GrayGradientDivider, ModalHeader, Paragraph, fontWeights } from '@va/ui/design-system';
import { ModalWrapper } from '@va/util/components';
import { tKeys } from './RequireAppReinstall';

export const RequireAppReinstallModal = ({ close, isOpen }: { close: () => void; isOpen: boolean }) => {
  const translate = useTranslate();

  return (
    <ModalWrapper isModalOpen={isOpen} closeModal={close}>
      <div className='w-screen max-w-[630px]'>
        <ModalHeader title={translate(tKeys.title)} onClose={close} />
        <div className='p-6'>
          <div>
            <Paragraph className='inline' weight={fontWeights.bold}>
              {translate(tKeys.info1)}
            </Paragraph>
            <Paragraph className='inline ml-1'>{translate(tKeys.info2)}</Paragraph>
          </div>
          <div className='space-y-3 my-18px'>
            {tKeys.steps.map((key, index) => {
              return (
                <div key={index} className='flex items-center gap-3'>
                  <div className='w-6 h-6 rounded-full bg-primary flex items-center justify-center text-white shrink-0'>
                    {index + 1}
                  </div>
                  <Paragraph weight={fontWeights.medium}>{translate(key)}</Paragraph>
                </div>
              );
            })}
          </div>

          <Paragraph weight={fontWeights.medium}>{translate(tKeys.info3)}</Paragraph>
        </div>
        <GrayGradientDivider />
        <div className='p-18px'>
          <Button onClick={close} className='w-full' color='primary' text={translate('button.gotIt')} />
        </div>
      </div>
    </ModalWrapper>
  );
};
