import { IconProps } from '.';

export const TextIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.5 12H12V13.5V14.25C12 15.4926 10.9926 16.5 9.75 16.5H4.5C2.84315 16.5 1.5 15.1569 1.5 13.5V4.5C1.5 2.84315 2.84315 1.5 4.5 1.5H13.5C15.1569 1.5 16.5 2.84315 16.5 4.5V9.75C16.5 10.9926 15.4926 12 14.25 12H13.5ZM13.5 13.5V14.25C13.5 16.3211 11.8211 18 9.75 18H4.5C2.01472 18 0 15.9853 0 13.5V4.5C0 2.01472 2.01472 0 4.5 0H13.5C15.9853 0 18 2.01472 18 4.5V9.75C18 11.8211 16.3211 13.5 14.25 13.5H13.5ZM7.5 4.25H4.5V5.75H6.75V11H8.25V5.75H10.5V4.25H7.5Z'
        fill={color}
      />
    </svg>
  );
};
