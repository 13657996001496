import { post } from '@va/http-client';

export const weeblyLogin = (data) => {
  const url = '/weebly/token';

  return post(url, {}, data);
};

export const weeblyRefreshToken = (data) => {
  const url = '/weebly/token-refresh';

  return post(url, {}, data);
};

export const weeblySync = (data) => {
  const url = '/weebly/sync';

  return post(url, {}, data);
};
