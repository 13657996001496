import { RowData, Table } from '@tanstack/react-table';
import { useEffect } from 'react';

/**
 * Clamps the page index to the last page if the current page index is greater than the total page count.
 */
export const useClampedPage = <TData extends RowData>(table: Table<TData>) => {
  const pageCount = table.getPageCount();
  const pageIndex = table.getState().pagination.pageIndex;

  useEffect(() => {
    if (pageCount === 0 || pageIndex < pageCount) return;

    table.setPageIndex(pageCount - 1);
  }, [pageCount, pageIndex, table]);
};
