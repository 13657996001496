import { apiStatus } from '@va/constants';
import { newApiRequest } from '@va/dashboard/reducers';
import * as Types from '@va/standalone/shared/actions';
import Immutable from 'immutable';

// === Paddle ===

export const getUpdatePaymentMethodUrl = newApiRequest(
  Types.standalone.Api.GET_UPDATE_PAYMENT_METHOD_URL,
  Types.standalone.Api.GET_UPDATE_PAYMENT_METHOD_URL_SUCCEEDED,
  Types.standalone.Api.GET_UPDATE_PAYMENT_METHOD_URL_FAILED,
);

export const updateSubscription = newApiRequest(
  Types.standalone.Api.UPDATE_SUBSCRIPTION_REQUEST,
  Types.standalone.Api.UPDATE_SUBSCRIPTION_SUCCEEDED,
  Types.standalone.Api.UPDATE_SUBSCRIPTION_FAILED,
);

// === Common ===

export const getPaymentMethod = newApiRequest(
  Types.standalone.Api.GET_PAYMENT_METHOD_REQUEST,
  Types.standalone.Api.GET_PAYMENT_METHOD_SUCCEEDED,
  Types.standalone.Api.GET_PAYMENT_METHOD_FAILED,
);

export const getPaymentPlans = newApiRequest(
  Types.standalone.Api.GET_PAYMENT_PLANS_REQUEST,
  Types.standalone.Api.GET_PAYMENT_PLANS_SUCCEEDED,
  Types.standalone.Api.GET_PAYMENT_PLANS_FAILED,
);

export const getAllSubscriptions = newApiRequest(
  Types.standalone.Api.GET_ALL_SUBSCRIPTIONS_REQUEST,
  Types.standalone.Api.GET_ALL_SUBSCRIPTIONS_SUCCEEDED,
  Types.standalone.Api.GET_ALL_SUBSCRIPTIONS_FAILED,
  Immutable.Map({
    status: apiStatus.NONE,
    data: [],
  }),
  (state, data) => state.set('data', Immutable.List(data.error ? [] : data)),
);

export const cancelSubscription = newApiRequest(
  Types.standalone.Api.CANCEL_SUBSCRIPTION_REQUEST,
  Types.standalone.Api.CANCEL_SUBSCRIPTION_SUCCEEDED,
  Types.standalone.Api.CANCEL_SUBSCRIPTION_FAILED,
);

// === Braintree ===

export const getPaymentAuthToken = newApiRequest(
  Types.standalone.Api.GET_CUSTOMER_AUTH_TOKEN_REQUEST,
  Types.standalone.Api.GET_CUSTOMER_AUTH_TOKEN_SUCCEEDED,
  Types.standalone.Api.GET_CUSTOMER_AUTH_TOKEN_FAILED,
);

export const getBraintreeAuthToken = newApiRequest(
  Types.standalone.Api.GET_BRAINTREE_AUTH_TOKEN_REQUEST,
  Types.standalone.Api.GET_BRAINTREE_AUTH_TOKEN_SUCCEEDED,
  Types.standalone.Api.GET_BRAINTREE_AUTH_TOKEN_FAILED,
);

export const getComputedSubscriptionPrice = newApiRequest(
  Types.standalone.Api.GET_COMPUTED_SUBSCRIPTION_PRICE_REQUEST,
  Types.standalone.Api.GET_COMPUTED_SUBSCRIPTION_PRICE_SUCCEEDED,
  Types.standalone.Api.GET_COMPUTED_SUBSCRIPTION_PRICE_FAILED,
);

export const validateVat = newApiRequest(
  Types.standalone.Api.VALIDATE_VAT_REQUEST,
  Types.standalone.Api.VALIDATE_VAT_SUCCEEDED,
  Types.standalone.Api.VALIDATE_VAT_FAILED,
);

export const getCustomer = newApiRequest(
  Types.standalone.Api.GET_CUSTOMER_REQUEST,
  Types.standalone.Api.GET_CUSTOMER_SUCCEEDED,
  Types.standalone.Api.GET_CUSTOMER_FAILED,
);

export const submitPayment = newApiRequest(
  Types.standalone.Api.SUBMIT_PAYMENT_REQUEST,
  Types.standalone.Api.SUBMIT_PAYMENT_SUCCEEDED,
  Types.standalone.Api.SUBMIT_PAYMENT_FAILED,
);

export const createCustomer = newApiRequest(
  Types.standalone.Api.CREATE_CUSTOMER_REQUEST,
  Types.standalone.Api.CREATE_CUSTOMER_SUCCEEDED,
  Types.standalone.Api.CREATE_CUSTOMER_FAILED,
);

export const createPaymentMethod = newApiRequest(
  Types.standalone.Api.CREATE_PAYMENT_METHOD_REQUEST,
  Types.standalone.Api.CREATE_PAYMENT_METHOD_SUCCEEDED,
  Types.standalone.Api.CREATE_PAYMENT_METHOD_FAILED,
);

export const createSubscription = newApiRequest(
  Types.standalone.Api.CREATE_SUBSCRIPTION_REQUEST,
  Types.standalone.Api.CREATE_SUBSCRIPTION_SUCCEEDED,
  Types.standalone.Api.CREATE_SUBSCRIPTION_FAILED,
);

export const cancelSubscriptionImmediate = newApiRequest(
  Types.standalone.Api.CANCEL_SUBSCRIPTION_IMMEDIATE_REQUEST,
  Types.standalone.Api.CANCEL_SUBSCRIPTION_IMMEDIATE_SUCCEEDED,
  Types.standalone.Api.CANCEL_SUBSCRIPTION_IMMEDIATE_FAILED,
);

export const reactivateSubscription = newApiRequest(
  Types.standalone.Api.REACTIVATE_SUBSCRIPTION_REQUEST,
  Types.standalone.Api.REACTIVATE_SUBSCRIPTION_SUCCEEDED,
  Types.standalone.Api.REACTIVATE_SUBSCRIPTION_FAILED,
);

export const updateCustomer = newApiRequest(
  Types.standalone.Api.UPDATE_CUSTOMER_REQUEST,
  Types.standalone.Api.UPDATE_CUSTOMER_SUCCEEDED,
  Types.standalone.Api.UPDATE_CUSTOMER_FAILED,
);

export const generateInvoice = newApiRequest(
  Types.standalone.Api.GENERATE_INVOICE,
  Types.standalone.Api.GENERATE_INVOICE_SUCCEEDED,
  Types.standalone.Api.GENERATE_INVOICE_FAILED,
);

export const validatePromoCode = newApiRequest(
  Types.standalone.Api.VALIDATE_PROMO_CODE,
  Types.standalone.Api.VALIDATE_PROMO_CODE_SUCCEEDED,
  Types.standalone.Api.VALIDATE_PROMO_CODE_FAILED,
);

export const getAgencyCustomer = newApiRequest(
  Types.standalone.Api.GET_AGENCY_CUSTOMER_REQUEST,
  Types.standalone.Api.GET_AGENCY_CUSTOMER_SUCCEEDED,
  Types.standalone.Api.GET_AGENCY_CUSTOMER_FAILED,
);
