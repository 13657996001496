import { TooltipOptions } from '@va/types/tooltip';
import {
  Button,
  fontWeights,
  GrayGradientDivider,
  Paragraph,
  paragraphSizes,
  ParagraphWithTooltip,
} from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { DropdownArrow } from '@va/util/components';
import { useCheckOverflow } from '@va/util/hooks';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { PropsWithChildren, useCallback, useRef, useState } from 'react';

export type AccordionSectionProps = {
  bgColorClass?: string;
  icon?: React.ReactNode;
  title?: string;
  titleTooltip?: string | React.ReactNode;
  titleTooltipProps?: TooltipOptions;
  subtitle?: string;
  isOpenExternal?: boolean;
  toggleIsOpenExternal?: () => void;
  heightClass?: string;
  className?: string;
  titleClassName?: string;
  subtitleClassName?: string;
  isInitiallyOpen?: boolean;
  infoIcon?: React.ReactElement;
  required?: boolean;
  'data-testid'?: string;
};

export const AccordionSection: React.FC<PropsWithChildren<AccordionSectionProps>> = ({
  children,
  bgColorClass = 'bg-white-snow',
  icon,
  title,
  subtitle,
  titleTooltip,
  titleTooltipProps,
  isOpenExternal,
  toggleIsOpenExternal,
  heightClass = 'h-72',
  className,
  titleClassName,
  subtitleClassName,
  isInitiallyOpen = false,
  infoIcon,
  required = false,
  'data-testid': dataTestId,
}) => {
  const [isOpenInternal, setIsOpenInternal] = useState(isInitiallyOpen);

  const titleRef = useRef(null);
  const isTitleOverflowing = useCheckOverflow(titleRef);

  const isOpen = !isNil(isOpenExternal) ? isOpenExternal : isOpenInternal;

  const getTitleTooltip = useCallback(() => {
    if (titleTooltip) return titleTooltip;
    if (isTitleOverflowing) return title;
    return undefined;
  }, [isTitleOverflowing, title, titleTooltip]);

  return (
    <div className={classNames('rounded-18', bgColorClass)}>
      <div
        onClick={() => {
          if (!isNil(isOpenExternal) && toggleIsOpenExternal) {
            toggleIsOpenExternal();
          } else {
            setIsOpenInternal((prev) => !prev);
          }
        }}
        className={classNames(
          'flex  rounded-18 items-center px-3 text-gray-dark gap-3 hover:bg-gray-concrete hover:transition-colors duration-300 overflow-hidden',
          {
            '!rounded-b-none': isOpen && children,
          },
          heightClass,
          className,
        )}
        data-testid={dataTestId}
      >
        {icon && <div className='shrink-0'>{icon}</div>}
        <div className='ml-2 overflow-hidden w-full'>
          {title && (
            <TooltipWrapper
              {...titleTooltipProps}
              content={getTitleTooltip()}
              disabled={!titleTooltip && !isTitleOverflowing}
            >
              <Paragraph
                ref={titleRef}
                className={classNames(
                  'truncate',
                  {
                    'underline-dashed': titleTooltip,
                  },
                  titleClassName,
                )}
                colorClassName='text-gray-dark-charcoal'
                weight={fontWeights.medium}
              >
                <>
                  {title}
                  {required && <span className='text-red-negative ml-1'>*</span>}
                </>
              </Paragraph>
            </TooltipWrapper>
          )}

          {subtitle && (
            <ParagraphWithTooltip
              size={paragraphSizes.tiny}
              colorClassName='text-gray-dark-charcoal'
              weight={fontWeights.medium}
              className={subtitleClassName}
            >
              {subtitle}
            </ParagraphWithTooltip>
          )}
        </div>
        {infoIcon && <div>{infoIcon}</div>}
        <Button
          className='ml-auto !bg-gray-concrete'
          color='tertiary'
          icon={() => <DropdownArrow color='#696969' open={isOpen} />}
        />
      </div>
      {isOpen && children && (
        <div className='overflow-hidden transition-all duration-200'>
          <GrayGradientDivider />
          {children}
        </div>
      )}
    </div>
  );
};
