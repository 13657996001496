import { CloseIconWithCircle, RightArrowIcon } from '@va/icons';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import { ElementType } from 'react';
import { Button, ButtonShapes } from '../button/Button';
import { HorizontalSeparator } from '../separators';
import { Heading5, ParagraphWithTooltip, fontWeights, paragraphSizes } from '../typography';
import { TEST_IDS } from '@va/constants';

//Use type from @va/types
/**@deprecated */
export type ContextMenuProps = {
  title?: string;
  subtitle?: string;
  onClose?: () => void;
  render: (onClose?: () => void) => JSX.Element;
};

export function ContextMenu({ title, subtitle, onClose, render }: ContextMenuProps) {
  return (
    <div
      className='bg-white min-w-400 max-w-[400px] rounded-24 p-6px flex flex-col gap'
      style={{
        boxShadow: '0px 1.5px 7.5px 0px rgba(0, 0, 0, 0.06)',
      }}
    >
      {title && (
        <>
          <div className='flex flex-row justify-between items-center px-12px py-6px'>
            <div>
              <Heading5 colorClassName='text-gray-charcoal' weight={fontWeights.semibold} className='truncate'>
                {title}
              </Heading5>
              {subtitle && (
                <ParagraphWithTooltip size={paragraphSizes.tiny} colorClassName='text-primary'>
                  {subtitle}
                </ParagraphWithTooltip>
              )}
            </div>
            {onClose && (
              <Button color='quaternary' onClick={onClose} icon={(_, color) => <CloseIconWithCircle color={color} />} />
            )}
          </div>
          <HorizontalSeparator className='-mx-[6px] my-6-px' />
        </>
      )}
      {render(onClose)}
    </div>
  );
}

type ContextMenuItemProps = {
  icon: ElementType;
  title: string;
  subtitle?: string;
  subtitleColorClass?: string;
  onClick: () => void;
  disabled?: boolean;
  tooltip?: string;
};

export function ContextMenuItem({
  icon: Icon,
  title,
  subtitle,
  subtitleColorClass,
  onClick,
  disabled,
  tooltip,
}: ContextMenuItemProps) {
  return (
    <TooltipWrapper content={tooltip} disabled={!tooltip}>
      <div
        className={classNames(
          'flex items-center gap-18px p-12px rounded-18px hover:bg-white-snow  group active:bg-black-lighter-06',
          { 'hover:cursor-not-allowed': disabled, 'hover:cursor-pointer': !disabled },
        )}
        onClick={() => {
          if (disabled) return;
          onClick();
        }}
      >
        <Button
          shape={ButtonShapes.circle}
          color={'tertiary'}
          icon={(_, color) => <Icon color={color} />}
          className='group-hover:bg-white'
          disabled={disabled}
        />
        <div className='flex-grow overflow-hidden'>
          <ParagraphWithTooltip weight={fontWeights.medium} size={paragraphSizes.normal}>
            {title}
          </ParagraphWithTooltip>
          <ParagraphWithTooltip
            size={paragraphSizes.tiny}
            colorClassName={classNames(subtitleColorClass ? subtitleColorClass : 'text-gray-devil')}
          >
            {subtitle}
          </ParagraphWithTooltip>
        </div>
        <div className='flex-shrink-0 p-15px'>
          <RightArrowIcon color='#696969' className='h-18px w-18px' />
        </div>
      </div>
    </TooltipWrapper>
  );
}
