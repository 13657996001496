import { TEST_IDS } from '@va/constants';
import { PageHeaderTooltip } from '@va/dashboard/ui/components';
import { LeftCircleArrowIcon } from '@va/icons';
import { Button, fontWeights, Heading3 } from '@va/ui/design-system';
import classNames from 'classnames';
import { useMemo } from 'react';

type TitleAndContextMenuProps = {
  title: string | React.ReactNode;
  tooltipTransKey?: string;
  onBackClick?: () => void;
  size?: 'small' | 'large';
};

export const TitleAndContextMenu = ({
  title,
  tooltipTransKey = '',
  onBackClick,
  size = 'large',
}: TitleAndContextMenuProps) => {
  const backButton = useMemo(() => {
    if (!onBackClick) return null;

    return (
      <Button
        color='tertiary'
        className='mr-2'
        icon={() => (
          <LeftCircleArrowIcon className={classNames({ 'w-[15px] h-[15px]': size === 'small' })} color='#3C3C3C' />
        )}
        onClick={() => onBackClick()}
        data-testid={TEST_IDS.generic.buttons.back}
        size={size}
      />
    );
  }, [onBackClick, size]);

  return (
    <div className='flex flex-row items-center mr-4 flex-nowrap min-w-210 lg:min-w-auto truncate'>
      {backButton}
      <PageHeaderTooltip
        title={title}
        baseKey={tooltipTransKey}
        trigger='click'
        render={(shouldShow: boolean) => (
          <Heading3
            data-testid={TEST_IDS.specific.pageTitle}
            colorClassName='text-gray-code'
            weight={fontWeights.semibold}
            className={classNames('truncate', {
              'underline-dashed cursor-default': shouldShow,
            })}
          >
            {title}
          </Heading3>
        )}
      />
    </div>
  );
};
