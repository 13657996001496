export const InformationIcon = ({
  color = 'currentColor',
  ...props
}: { color?: string } & JSX.IntrinsicElements['svg']) => (
  <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 14.375C3.13401 14.375 0 11.241 0 7.375C0 3.50901 3.13401 0.375 7 0.375C10.866 0.375 14 3.50901 14 7.375C14 11.241 10.866 14.375 7 14.375ZM7 13.375C10.3137 13.375 13 10.6887 13 7.375C13 4.06129 10.3137 1.375 7 1.375C3.68629 1.375 1 4.06129 1 7.375C1 10.6887 3.68629 13.375 7 13.375ZM6 6.375H8V10.375L9 11.375H5L6 10.375V7.23214H5L6 6.375ZM7 3.375C7.55228 3.375 8 3.82272 8 4.375C8 4.92728 7.55228 5.375 7 5.375C6.44772 5.375 6 4.92728 6 4.375C6 3.82272 6.44772 3.375 7 3.375Z'
      fill={color}
    />
  </svg>
);
