import classNames from 'classnames';
import { IconProps } from '.';

export const LocationIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.99995 15L13.3695 7.71744C15.4073 4.32102 12.9608 0 8.99995 0C5.03908 0 2.59256 4.32102 4.63041 7.71744L8.99995 15ZM8.99995 12.0845L12.0832 6.94569C13.5212 4.54906 11.7949 1.5 8.99995 1.5C6.20502 1.5 4.47867 4.54906 5.91665 6.94569L8.99995 12.0845ZM3.59983 9.89972C1.41392 10.7207 0 12.0278 0 13.5C0 15.9853 4.02944 18 9 18C13.9706 18 18 15.9853 18 13.5C18 12.0278 16.5861 10.7207 14.4002 9.89972L13.5424 11.1864C13.962 11.3277 14.3474 11.4868 14.6931 11.6596C16.1624 12.3943 16.5 13.1316 16.5 13.5C16.5 13.8684 16.1624 14.6057 14.6931 15.3403C13.3136 16.0301 11.3029 16.5 9 16.5C6.69709 16.5 4.68636 16.0301 3.30686 15.3403C1.83764 14.6057 1.5 13.8684 1.5 13.5C1.5 13.1316 1.83764 12.3943 3.30686 11.6596C3.65258 11.4868 4.03795 11.3277 4.45763 11.1864L3.59983 9.89972ZM9 6.5C9.82843 6.5 10.5 5.82843 10.5 5C10.5 4.17157 9.82843 3.5 9 3.5C8.17157 3.5 7.5 4.17157 7.5 5C7.5 5.82843 8.17157 6.5 9 6.5Z'
        fill={color}
      />
    </svg>
  );
};


