import classNames from 'classnames';
import { IconProps } from '.';

export const IlustrationSuccess = ({ className, color }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M57 30C57 44.9117 44.9117 57 30 57C15.0883 57 3 44.9117 3 30C3 15.0883 15.0883 3 30 3C44.9117 3 57 15.0883 57 30ZM60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30ZM40.0607 25.0607L37.9393 22.9393L27 33.8787L22.0607 28.9393L19.9393 31.0607L25.9393 37.0607L27 38.1213L28.0607 37.0607L40.0607 25.0607Z'
        fill={color ?? 'black'}
      />
    </svg>
  );
};


