import { IconProps } from '@va/icons';
import { fontWeights, Paragraph, paragraphSizes } from '@va/ui/design-system';
import classNames from 'classnames';

type PlatformType = {
  title: string;
  subtitle: string;
  icon: (props: IconProps) => JSX.Element;
  onClick: () => void;
};

export const InstallPluginsView = ({ platformsList }: { platformsList: PlatformType[] }) => {
  return (
    <div className='flex flex-col items-center py-6 px-18px'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-18px w-full md:max-w-600px'>
        {platformsList.map(({ icon: Icon, title, subtitle, onClick }, index) => {
          return (
            <div
              key={index}
              className={classNames(
                'flex items-center bg-white-snow md:col-span-auto-1 rounded-3xl px-6 py-15px md:p-6 w-full gap-18px hover:bg-gray-concrete cursor-pointer group last:md:col-span-auto-2',
              )}
              onClick={onClick}
            >
              <Icon className='h-12 w-12 shrink-0' />
              <div className='flex flex-col flex-wrap'>
                <Paragraph
                  size={paragraphSizes.large}
                  weight={fontWeights.medium}
                  colorClassName='text-gray-charcoal'
                  className='transition-translate ease-linear delay-100 duration-200 md:translate-y-2/4 translate-y-0 group-hover:translate-y-0 text-15px md:text-18'
                >
                  {title}
                </Paragraph>
                <Paragraph
                  colorClassName='text-gray-devil'
                  weight={fontWeights.medium}
                  className='transition-opacity ease-linear delay-100 duration-200 opacity-100 md:opacity-0 group-hover:opacity-100 text-12 md:text-15'
                >
                  {subtitle}
                </Paragraph>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
