import { mutationConfig, useMutationObserver } from '@va/util/hooks';
import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

type PortalProps = {
  elementSelector: string;
  mutationObserver?: {
    targetNode: string;
  };
};

const MAX_ATTEMPTS = 20;
const INTERVAL_MS = 50;

export const Portal: React.FC<PropsWithChildren<PortalProps>> = ({ elementSelector, mutationObserver, children }) => {
  const [wrapperElement, setWrapperElement] = useState<Element | null>(null);
  const { targetNode } = mutationObserver || {};

  const target = targetNode ? document.querySelector(targetNode) : null;

  const mutationObserverCallback = useCallback(() => {
    if (wrapperElement) return;

    const foundElement = document.querySelector(elementSelector);

    if (foundElement) {
      setWrapperElement(foundElement);
    }
  }, [elementSelector, wrapperElement]);

  useMutationObserver(target, mutationObserverCallback, {
    ...mutationConfig,
    subtree: true,
    childList: true,
  });

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const attemptToQueryElement = useCallback(() => {
    let count = 0;
    intervalRef.current = setInterval(() => {
      if (count >= MAX_ATTEMPTS && intervalRef.current) {
        clearInterval(intervalRef.current);
        return;
      }
      count++;
      const foundElement = document.querySelector(elementSelector);
      if (foundElement) {
        setWrapperElement(foundElement);
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      }
    }, INTERVAL_MS);
  }, [elementSelector]);

  useEffect(() => {
    if (!mutationObserver?.targetNode || wrapperElement) return;
    setTimeout(() => {
      // needed in case targetNode is rendered in the same cycle as elementSelector
      attemptToQueryElement();
    }, 50);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const foundElement = document.querySelector(elementSelector);

    if (foundElement) {
      setWrapperElement(foundElement);
      return;
    }

    if (!mutationObserver?.targetNode) {
      // For backup
      attemptToQueryElement();
    }
  }, [attemptToQueryElement, elementSelector, mutationObserver?.targetNode]);

  if (!wrapperElement) return null;

  return createPortal(children, wrapperElement);
};
