import { useTranslate } from '@va/localization';
import { ComparisonFilter, FilterOptions } from '@va/shared/feature-filters';
import { useMemo } from 'react';
import { countComparisonOperators, percentOfTotalComparisonOperators } from '../data/constants';

export const useAlarmingBehaviorEventsFilteringOptions = (): FilterOptions => {
  const translate = useTranslate();

  const options = useMemo(
    () => [
      new ComparisonFilter({
        id: 'count',
        name: 'count',
        label: translate('all.eventTracking.alarmingBehaviorEvents.filters.numberOfDetections'),
        inputProps: { type: 'number' },
        operatorOptions: countComparisonOperators,
      }),
      new ComparisonFilter({
        id: 'percentOfTotal',
        name: 'percentOfTotal',
        label: translate('all.eventTracking.alarmingBehaviorEvents.filters.percentOfTotal'),
        inputProps: { type: 'number' },
        valueMapper(value) {
          return [value / 100];
        },
        operator: percentOfTotalComparisonOperators[0],
        operatorOptions: percentOfTotalComparisonOperators,
      }),
    ],
    [translate],
  );

  return options;
};
