import { FilterIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, Paragraph, fontWeights, ParagraphWithTooltip } from '@va/ui/design-system';
import { useTooltipContext } from '@va/ui/tooltips';
import { Checkmark } from '@va/util/components';
import { useCallback } from 'react';
type MetricSelectorDropdownProps = {
  selectedMetric: string;
  selectMetric: (metric: string) => void;
  metricsList: string[];
  translationsMap: {
    [x: string]: string;
  };
};
export function MetricSelectorDropdown({
  selectedMetric,
  selectMetric,
  metricsList,
  translationsMap,
}: MetricSelectorDropdownProps) {
  const { context } = useTooltipContext();
  const translate = useTranslate();

  const closeTooltip = useCallback(() => {
    context.onOpenChange(false);
  }, [context]);

  return (
    <div className='bg-white p-2 rounded-12 shadow-md'>
      <ul>
        {metricsList.map((metric) => (
          <li
            key={metric}
            onClick={() => {
              selectMetric(metric);
              closeTooltip();
            }}
            className='h-12 px-3 rounded-12 hover:bg-white-snow flex items-center justify-between gap-3'
          >
            <div className='flex items-center gap-3 truncate'>
              <FilterIcon color='#696969' className={'shrink-0'} />
              <ParagraphWithTooltip weight={fontWeights.medium}>
                {translate(translationsMap[metric])}
              </ParagraphWithTooltip>
            </div>
            <Checkmark className={'shrink-0'} selected={metric === selectedMetric} />
          </li>
        ))}
      </ul>
      <Button onClick={closeTooltip} className='w-full' color='tertiary' text={translate('button.close')} />
    </div>
  );
}
