import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  addConversionTypeFailed,
  addConversionTypeSucceeded,
  ADD_CONVERSION_TYPE,
  editConversionTypeFailed,
  editConversionTypeSucceeded,
  EDIT_CONVERSION_TYPE,
  getConversionTypes,
  getConversionTypesFailed,
  getConversionTypesSucceeded,
  GET_CONVERSION_TYPES,
  removeConversionTypeFailed,
  removeConversionTypeSucceeded,
  REMOVE_CONVERSION_TYPE,
  resetRequestStatus,
} from '@va/dashboard/actions/api';
import { popRemovingConversionType, pushRemovingConversionType } from '@va/dashboard/actions/app';
import Api from '@va/dashboard/api-client/index';
import { getInstanceId } from '@va/dashboard/selectors/app';

export function* watchers() {
  yield all([
    takeLatest(GET_CONVERSION_TYPES, getConversionTypesSaga),
    takeLatest(ADD_CONVERSION_TYPE, addConversionTypeSaga),
    takeLatest(EDIT_CONVERSION_TYPE, editConversionTypeSaga),
    takeEvery(REMOVE_CONVERSION_TYPE, removeConversionTypeSaga),
  ]);
}

export function* getConversionTypesSaga() {
  try {
    const websiteId = yield select(getInstanceId);
    const response = yield call(Api.getConversionTypes, websiteId);
    yield put(getConversionTypesSucceeded({ data: response }));
  } catch (error) {
    yield put(getConversionTypesFailed(error));
    yield delay(3000);
    yield put(resetRequestStatus('getConversionType'));
  }
}

export function* addConversionTypeSaga(action) {
  try {
    const websiteId = yield select(getInstanceId);
    yield call(Api.addConversionType, websiteId, action.data);
    yield put(addConversionTypeSucceeded());
    yield put(getConversionTypes());
  } catch (error) {
    yield put(addConversionTypeFailed(error));
    yield delay(3000);
    yield put(resetRequestStatus('addConversionType'));
  }
}

export function* editConversionTypeSaga(action) {
  try {
    const websiteId = yield select(getInstanceId);
    yield call(Api.editConversionType, websiteId, action.id, action.data);
    yield put(editConversionTypeSucceeded());
    yield put(getConversionTypes());
  } catch (error) {
    yield put(editConversionTypeFailed(error));
    yield delay(3000);
    yield put(resetRequestStatus('editConversionType'));
  }
}

export function* removeConversionTypeSaga(action) {
  yield put(pushRemovingConversionType(action.id));
  try {
    const websiteId = yield select(getInstanceId);
    yield call(Api.removeConversionType, websiteId, action.id);
    yield put(removeConversionTypeSucceeded());
    yield put(popRemovingConversionType(action.id));
    yield put(getConversionTypes());
  } catch (error) {
    yield put(removeConversionTypeFailed(error));
    yield put(popRemovingConversionType(action.id));
  }
}
