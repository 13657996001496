import { ArrowsIcon, Clock, LayersIcon, LinkIcon, LoaderIcon } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { fontWeights, Paragraph, paragraphSizes, ParagraphWithTooltip } from '@va/ui/design-system';
import { CloseButton, TooltipWrapper, useTooltipContext } from '@va/ui/tooltips';
import { appHasDarkTheme, getUrlWithoutProtocolAndTrailingSlash, getWellFormedUrl } from '@va/util/helpers';
import { useCheckOverflow, useWindowDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import moment from 'moment';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

export type PageItemType = {
  title?: string;
  url: string;
  topRightInfo: string;
  bottomRightInfo: string;
  privacyLevel: number;
};

type VisitedPagesTooltipProps = {
  sessionId?: string;
  isLoading?: boolean;
  title: string;
  items: PageItemType[];
  sessionStart?: number;
  showSubTitle?: boolean;
  showVisitPagesAndDate?: boolean;
  isPageHistoryTrackingEnabled: boolean;
};

export const VisitedPagesTooltip: React.FC<VisitedPagesTooltipProps> = ({
  sessionId,
  title,
  items,
  isLoading,
  sessionStart,
  showSubTitle = true,
  showVisitPagesAndDate = false,
  isPageHistoryTrackingEnabled,
}) => {
  const { height: windowHeight } = useWindowDimensions();
  const isDarkThemeApplied = appHasDarkTheme();
  const translate = useTranslate();
  const { locale } = useLocale();
  const { context } = useTooltipContext();

  if (isLoading)
    return (
      <div className='rounded-12 bg-black-lighter-84 flex justify-center items-center py-4 lg:py-0'>
        <LoaderIcon color={!isDarkThemeApplied ? 'white' : undefined} />
      </div>
    );
  return (
    <div
      className={classNames('rounded-12 flex flex-col gap-3px overflow-hidden px-12px py-9px', {
        'bg-black-lighter-84': !isDarkThemeApplied,
        'bg-white': isDarkThemeApplied,
      })}
      style={{ maxHeight: windowHeight < 750 ? 250 : 400, maxWidth: 432 }}
    >
      <div>
        <div className='flex justify-between items-center truncate'>
          {sessionId ? (
            <Link to={`/visitors/history/${sessionId}`}>
              <ParagraphWithTooltip
                className='underline text-left truncate'
                size={paragraphSizes.normal}
                weight={fontWeights.medium}
                colorClassName={isDarkThemeApplied ? undefined : 'text-white'}
              >
                {title}
              </ParagraphWithTooltip>
            </Link>
          ) : (
            <Paragraph
              className={classNames('text-left truncate', {
                underline: sessionId,
              })}
              size={paragraphSizes.normal}
              weight={fontWeights.medium}
              colorClassName={isDarkThemeApplied ? undefined : 'text-white'}
            >
              {title}
            </Paragraph>
          )}
          <CloseButton onClose={() => context.onOpenChange(false)} />
        </div>
        {showSubTitle && (
          <div className='flex items-center gap-1'>
            <ArrowsIcon color={isDarkThemeApplied ? 'black' : 'white'} />
            <Paragraph
              weight={fontWeights.medium}
              size={paragraphSizes.extraTiny}
              colorClassName={isDarkThemeApplied ? undefined : 'text-white'}
            >
              {translate('card.latestVisitors.visitorsHistory.sortedNewestFirst')}
            </Paragraph>
          </div>
        )}
        {showVisitPagesAndDate && (
          <div className='flex items-center gap-3'>
            <div className='flex flex-nowrap items-center gap-1'>
              <LayersIcon color={isDarkThemeApplied ? 'black' : 'white'} />
              <Paragraph
                weight={fontWeights.medium}
                colorClassName={isDarkThemeApplied ? undefined : 'text-white'}
                size={paragraphSizes.tiny}
              >
                {translate('visitorsHistory.pages', {
                  pageCount: items?.length ?? 0,
                })}
              </Paragraph>
            </div>
            {sessionStart && (
              <div className='flex flex-nowrap items-center gap-1'>
                <Clock color={isDarkThemeApplied ? 'black' : 'white'} />
                <Paragraph
                  weight={fontWeights.medium}
                  colorClassName={isDarkThemeApplied ? undefined : 'text-white'}
                  size={paragraphSizes.tiny}
                >
                  {moment(sessionStart * 1000)
                    .locale(locale)
                    .format('L')}
                </Paragraph>
              </div>
            )}
          </div>
        )}
      </div>
      <div className='grow scrollbar scrollbar-thumb overflow-y-auto pr-1'>
        <div
          className='grid items-center w-full gap-2 overflow-hidden'
          style={{ gridTemplateColumns: items?.at(0)?.topRightInfo ? 'auto auto auto' : 'auto auto' }}
        >
          <div>
            <ParagraphWithTooltip
              size={paragraphSizes.tiny}
              weight={fontWeights.medium}
              colorClassName={isDarkThemeApplied ? undefined : 'text-white'}
            >
              {translate('card.latestVisitors.visitorsHistory.pages')}
            </ParagraphWithTooltip>
          </div>
          {items?.at(0)?.topRightInfo && (
            <ParagraphWithTooltip
              size={paragraphSizes.extraTiny}
              weight={fontWeights.medium}
              colorClassName={isDarkThemeApplied ? undefined : 'text-white'}
              className={'text-right'}
            >
              {translate('card.latestVisitors.visitorsHistory.timeSpent')}
            </ParagraphWithTooltip>
          )}
          <ParagraphWithTooltip
            size={paragraphSizes.extraTiny}
            weight={fontWeights.medium}
            colorClassName={isDarkThemeApplied ? undefined : 'text-white'}
            className={'text-right'}
          >
            {translate('card.latestVisitors.visitorsHistory.timeOfVisit')}
          </ParagraphWithTooltip>

          <ul className='contents space-y-2'>
            {items.map((item, index) => {
              return (
                <PageListItem isPageHistoryTrackingEnabled={isPageHistoryTrackingEnabled} key={index} item={item} />
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

type PageListItemProps = {
  item: PageItemType;
  isPageHistoryTrackingEnabled: boolean;
};

const PageListItem: React.FC<PageListItemProps> = ({
  item: { url, title, bottomRightInfo, topRightInfo, privacyLevel },
  isPageHistoryTrackingEnabled,
}) => {
  const urlWithoutProtocols = getUrlWithoutProtocolAndTrailingSlash(url);
  const urlRef = useRef(null);
  const isDarkThemeApplied = appHasDarkTheme();
  const isUrlOverflowing = useCheckOverflow(urlRef);
  const translate = useTranslate();

  return (
    <li className='contents flex items-center gap-10 justify-between'>
      <div className='flex items-center gap-1.5 truncate'>
        <a
          href={isPageHistoryTrackingEnabled ? url : undefined}
          target='_blank'
          rel='noreferrer'
          className={classNames('shrink-0 rounded-full flex items-center justify-center', {
            'pointer-events-none cursor-not-allowed': !url,
          })}
        >
          <LinkIcon color={isDarkThemeApplied ? 'black' : 'white'} />
        </a>
        <div className='flex flex-col text-left truncate'>
          <ParagraphWithTooltip
            weight={fontWeights.medium}
            className='truncate w-full block'
            colorClassName={isDarkThemeApplied ? undefined : 'text-white'}
            size={paragraphSizes.tiny}
          >
            {title}
          </ParagraphWithTooltip>
          {isPageHistoryTrackingEnabled ? (
            <TooltipWrapper disabled={!isUrlOverflowing} content={url} ref={urlRef}>
              {privacyLevel === undefined || privacyLevel < 2 ? (
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  className='truncate text-white text-xxs'
                  href={getWellFormedUrl(url)}
                >
                  {urlWithoutProtocols}
                </a>
              ) : (
                <ParagraphWithTooltip
                  className='overflow-hidden truncate'
                  colorClassName={isDarkThemeApplied ? undefined : 'text-white'}
                  size={paragraphSizes.tiny}
                >
                  {translate('panels.recordings.consentless.hiddenPages')}
                </ParagraphWithTooltip>
              )}
            </TooltipWrapper>
          ) : (
            <ParagraphWithTooltip
              className='overflow-hidden truncate'
              colorClassName={isDarkThemeApplied ? undefined : 'text-white'}
              size={paragraphSizes.tiny}
            >
              {translate('panels.recordings.consentless.hiddenPages')}
            </ParagraphWithTooltip>
          )}
        </div>
      </div>
      {topRightInfo && (
        <Paragraph
          colorClassName={isDarkThemeApplied ? 'text-gray-charcoal/83' : 'text-white-83'}
          weight={fontWeights.medium}
          className={'text-right'}
          size={paragraphSizes.tiny}
        >
          {topRightInfo}
        </Paragraph>
      )}
      <Paragraph
        className='min-w-fit text-right whitespace-nowrap'
        colorClassName={isDarkThemeApplied ? 'text-gray-charcoal/66' : 'text-white-66'}
        size={paragraphSizes.tiny}
      >
        {bottomRightInfo}
      </Paragraph>
    </li>
  );
};
