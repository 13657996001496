import { CheckedSquareIcon, SquareIcon } from '@va/icons';
import { DISABLED_CLASSES, Paragraph, SECONDARY_CLASSES, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { getResponsiveTooltipHeight } from '@va/util/helpers';
import { useWindowDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import React, { ReactNode, useCallback } from 'react';

export type SelectActionDropdownOption = {
  onClick: () => void;
  icon?: () => ReactNode;
  text: string;
  textColorClass?: string;
  disabled?: boolean;
  tooltip?: string;
  hideDropdownOnClick?: boolean;
  tooltipProps?: {
    visible: boolean;
  };
  'data-testid'?: string;
  hidden?: boolean;
};

type SelectActionDropdownContentProps = {
  options: SelectActionDropdownOption[];
  hideDropdown: () => void;
  selectedOption?: number | null;
};

export const SelectActionDropdownContent: React.FC<SelectActionDropdownContentProps> = ({
  options,
  hideDropdown,
  selectedOption,
}) => {
  const { height: windowHeight } = useWindowDimensions();

  const getOptionIcon = useCallback(
    (index: number, icon?: () => ReactNode) => {
      if (icon) return icon();
      if (selectedOption === index) return <CheckedSquareIcon color='#696969' />;
      return <SquareIcon color='#696969' />;
    },
    [selectedOption],
  );

  return (
    <div className='flex flex-col bg-white shadow-overlay rounded-12 p-6px text-gray-charcoal min-w-[312px]'>
      <div
        className='overflow-auto scrollbar scrollbar-thumb'
        style={{ maxHeight: getResponsiveTooltipHeight(windowHeight) }}
      >
        {options.map(
          (
            {
              onClick,
              hideDropdownOnClick = true,
              text,
              tooltipProps,
              icon,
              textColorClass,
              tooltip,
              disabled = false,
              'data-testid': dataTestId,
              hidden = false,
            },
            index,
          ) => {
            if (hidden) return null;

            return (
              <TooltipWrapper key={index} content={tooltip} disabled={!tooltip} open={tooltipProps?.visible}>
                <button
                  onClick={() => {
                    if (disabled) return;
                    if (hideDropdownOnClick) {
                      hideDropdown();
                    }
                    onClick();
                  }}
                  data-testid={dataTestId}
                  className={classNames(
                    'flex w-full gap-3 rounded-12 h-12 items-center px-3 hover:bg-white-snow min-w-150 transition-colors duration-300',
                    disabled && DISABLED_CLASSES,
                    selectedOption === index && SECONDARY_CLASSES,
                  )}
                >
                  {icon ? getOptionIcon(index, icon) : getOptionIcon(index)}
                  <Paragraph
                    colorClassName={disabled ? undefined : textColorClass}
                    weight={fontWeights.medium}
                    size={paragraphSizes.tiny}
                  >
                    {text}
                  </Paragraph>
                </button>
              </TooltipWrapper>
            );
          },
        )}
      </div>
    </div>
  );
};
