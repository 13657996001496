import { RadioInput, RadioInputSelected } from '@va/icons';
import classNames from 'classnames';

type RadioInputProps = {
  selected: boolean;
  className?: string;
  selectedColor?: string;
};

export const RadioInputButton = ({ selected, className, selectedColor }: RadioInputProps) => {
  return selected ? (
    <RadioInputSelected color={selectedColor} className={classNames('h-3 w-3', className)} />
  ) : (
    <RadioInput className={classNames(className)} />
  );
};
