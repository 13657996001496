import { TEST_IDS } from '@va/constants';
import { VisitDetails } from '@va/dashboard/components';
import { DeviceIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { DeviceTypesEnum } from '@va/types/device';

type DeviceCellProps = {
  value: DeviceTypesEnum;
};

export function DeviceCell({ value }: DeviceCellProps) {
  const translate = useTranslate();
  const actualDeviceType = value === DeviceTypesEnum.mobile ? DeviceTypesEnum.tablet : value;
  const translatedText = translate(`panels.latestVisitors.deviceType.${actualDeviceType}`);
  return (
    <VisitDetails
      data-testid={TEST_IDS.generic.visitInfo.device}
      tooltipText={translate(`panels.latestVisitors.device`)}
      tooltipSubtext={translatedText}
      icon={<DeviceIcon deviceType={value} color='#696969' />}
    />
  );
}
