export const facebookGroup = {
  standard: 'https://www.facebook.com/groups/visitor.analytics/',
  sumo: 'https://www.facebook.com/groups/272402916735937/',
};

export const homePageLink = 'https://www.twipla.com';
export const lastDateModalShown = 'LastDateModalShown';
export const cookielessTrackingInfoLink =
  'https://www.visitor-analytics.io/en/why-visitor-analytics/cookieless-tracking/';
export const commonPresentationLinks = {
  homepage: homePageLink,
  aboutUs: homePageLink + '/en/company/about-us',
  blog: homePageLink + '/en/blog',
  ssrTermsOfUse: homePageLink + '/assets/legal/Visitor-Analytics_Terms-of-use_2019-10-14.pdf',
  ssrService: homePageLink + '/assets/legal/Visitor-Analytics_Service-description_2019-10-14.pdf',
  ssrDpa: homePageLink + '/assets/legal/Visitor-Analytics_Data-processing-agreement_2019-10-14.pdf',
  facebookGroup: facebookGroup.standard,
};

export const supportLinks = {
  home: commonPresentationLinks.homepage + '/en/support',
  contact: commonPresentationLinks.homepage + '/en/contact',
  installVA: commonPresentationLinks.homepage + '/en/support/integrating-with-other-platforms',
  installVideoTracking: commonPresentationLinks.homepage + '/en/faq/wix/#accordion-26135',
  appSumoClaim:
    commonPresentationLinks.homepage + '/en/support/all-about-visitor-analytics-features/further-topics#c1065',
  appSumoFreeTrial:
    commonPresentationLinks.homepage + '/en/support/all-about-visitor-analytics-features/further-topics#c1074',
  cancelSubscription: commonPresentationLinks.homepage + '/en/support/premium-plan-billing/standalone?#c551',
  wixSsrLimitation: commonPresentationLinks.homepage + '/en/faq/wix/#accordion-26135',
  standaloneSsrLimitation: commonPresentationLinks.homepage + '/en/faq/standalone/#accordion-26076',
  customEvents:
    commonPresentationLinks.homepage + '/en/support/all-about-features/visitor-behavior/event-tracking#c40848',
  pricing: homePageLink + '/en/pricing',
};

export const wixPresentationLinks = {
  ...commonPresentationLinks,
  legal: commonPresentationLinks.homepage + '/en/support/legal-data-privacy-certificates/wix-integration',
  terms: commonPresentationLinks.homepage + '/en/support/legal-data-privacy-certificates/wix-integration/terms-of-use',
  gdprCommitment:
    commonPresentationLinks.homepage +
    '/en/support/legal-data-privacy-certificates/gdpr-compliance/gdpr-compliance-overview',
  yourPrivacyPolicy:
    commonPresentationLinks.homepage +
    '/en/support/legal-data-privacy-certificates/standard-integration/what-to-include-in-your-privacy-policy',
  downloadTerms: commonPresentationLinks.homepage + '/assets/legal/wix/Visitor-Analytics-Terms-Of-Use-Wix.pdf',
  optOut:
    commonPresentationLinks.homepage +
    '/en/support/legal-data-privacy-certificates/wix-integration/opt-out-do-not-track-dnt',
  cookieInfo: commonPresentationLinks.homepage + '/wix-cookie-information', //TODO update unused cookie-info link if needed
  ssrTermsOfUse:
    commonPresentationLinks.homepage + '/assets/legal/wix/Visitor-Analytics-Terms-Of-Use-Wix_11-06-2019.pdf',
};

export const standalonePresentationLinks = {
  ...commonPresentationLinks,
  legal: commonPresentationLinks.homepage + '/en/support/legal-data-privacy-certificates/standard-integration',
  terms:
    commonPresentationLinks.homepage + '/en/support/legal-data-privacy-certificates/standard-integration/terms-of-use',
  gdprCommitment:
    commonPresentationLinks.homepage +
    '/en/support/legal-data-privacy-certificates/gdpr-compliance/gdpr-compliance-overview',
  yourPrivacyPolicy:
    commonPresentationLinks.homepage +
    '/en/support/legal-data-privacy-certificates/standard-integration/what-to-include-in-your-privacy-policy',
  downloadTerms:
    commonPresentationLinks.homepage + '/assets/legal/standard/Visitor-Analytics-Terms-and-Conditions-Standard.pdf',
  cookieInfo: commonPresentationLinks.homepage + '/standard-cookie-information', //TODO update unused cookie-info link if needed
  affiliateLink: 'https://affiliates.visitor-analytics.io',
};
