import { AlarmingBehavior, AlarmingBehaviorResponse, alarmingEventsTranslations } from '@va/types/events/events.types';
import { DonutChartWithListItem } from '@va/ui/components/doughnut-chart-card';
import { addNumberSeparator } from '@va/util/helpers';
import { CircleNegativeTagIcon } from '../components/visualization/CircleNegativeTagIcon';
import { metricsMap } from '../data/metrics';

export const createListItem = (
  title: string,
  color: string,
  percentage: number,
  count: number,
  countLabel: string,
  progressTooltip: string | number,
  centerData: string | number,
): DonutChartWithListItem => ({
  icon: <CircleNegativeTagIcon circleColor={color} />,
  title: title,
  percentage: percentage,
  countLabel: countLabel,
  count: count,
  progress: [{ color: color, percentage: percentage }],
  color: color,
  progressTooltip: `${title}: ${progressTooltip}`,
  customCenterData: centerData,
});

export function calculateEventCounts(
  selectedAlarmingBehaviorEvents: AlarmingBehavior[],
  data: AlarmingBehaviorResponse | undefined,
) {
  const totalEventsCount = data?.payload.reduce((totalCount, behavior) => totalCount + behavior.count, 0) ?? 0;
  const selectedEventsCount = selectedAlarmingBehaviorEvents.reduce(
    (selectedCount, event) => selectedCount + event.count,
    0,
  );
  const remainingEventsCount = Math.max(0, totalEventsCount - selectedEventsCount);

  return { remainingEventsCount };
}

export function createProgressListItems(
  selectedAlarmingBehaviorEvents: AlarmingBehavior[],
  selectedMetric: string,
  locale: string,
  eventsLabel: string,
  translate: (key: string, options?: Record<string, unknown>) => string,
) {
  return selectedAlarmingBehaviorEvents.map((item) => {
    const progressTooltip =
      selectedMetric === metricsMap.percentOfTotal
        ? addNumberSeparator(item.percentOfTotal * 100, locale, { style: 'percent' })
        : item.count;
    const centerData =
      selectedMetric === metricsMap.percentOfTotal
        ? addNumberSeparator(item.percentOfTotal * 100, locale, { style: 'percent' })
        : item.count;

    const percentOfTotal = item.percentOfTotal * 100;
    const numberOfEvents = addNumberSeparator(item.count, locale);
    return createListItem(
      translate(alarmingEventsTranslations[item.trigger]),
      item.color,
      percentOfTotal,
      item.count,
      `${numberOfEvents} ${eventsLabel}`,
      progressTooltip,
      centerData,
    );
  });
}

export function createOthersListItem(
  othersLabel: string,
  remainingPercentage: number,
  remainingEventsCount: number,
  selectedMetric: string,
  locale: string,
  eventsLabel: string,
) {
  const othersProgressTooltip =
    selectedMetric === metricsMap.percentOfTotal
      ? addNumberSeparator(remainingPercentage, locale, { style: 'percent' })
      : remainingEventsCount;
  const centerData =
    selectedMetric === metricsMap.percentOfTotal
      ? addNumberSeparator(remainingPercentage, locale, { style: 'percent' })
      : remainingEventsCount;
  const numberOfEvents = addNumberSeparator(remainingEventsCount, locale);
  return createListItem(
    othersLabel,
    '#C3C3C3',
    remainingPercentage,
    remainingEventsCount,
    `${numberOfEvents} ${eventsLabel}`,
    othersProgressTooltip,
    centerData,
  );
}

export function calculatePercentages(selectedAlarmingBehaviorEvents: AlarmingBehavior[]) {
  const totalSelectedPercentage = selectedAlarmingBehaviorEvents.reduce(
    (selectedPercentage, event) => selectedPercentage + event.percentOfTotal * 100,
    0,
  );
  const remainingPercentage = Number(Math.max(0, 100 - totalSelectedPercentage));
  return { remainingPercentage };
}

export function valueMapperBasedOnMetric(value: number, metric: string, locale: string): any {
  switch (metric) {
    case metricsMap.count:
      return value;
    case metricsMap.percentOfTotal:
      return addNumberSeparator(value, locale, { style: 'percent', maximumSignificantDigits: 2 });
    default:
      return addNumberSeparator(value, locale);
  }
}
