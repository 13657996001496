import classNames from 'classnames';
import { IconProps } from '.';

export const SwitchIcon = ({ color = 'black', className }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='19'
      height='12'
      viewBox='0 0 19 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.5 1.5H12.5C14.9853 1.5 17 3.51472 17 6C17 8.48528 14.9853 10.5 12.5 10.5H6.5C4.01472 10.5 2 8.48528 2 6C2 3.51472 4.01472 1.5 6.5 1.5ZM0.5 6C0.5 2.68629 3.18629 0 6.5 0H12.5C15.8137 0 18.5 2.68629 18.5 6C18.5 9.31371 15.8137 12 12.5 12H6.5C3.18629 12 0.5 9.31371 0.5 6ZM15.5 6C15.5 7.65685 14.1569 9 12.5 9C10.8431 9 9.5 7.65685 9.5 6C9.5 4.34315 10.8431 3 12.5 3C14.1569 3 15.5 4.34315 15.5 6Z'
        fill={color}
      />
    </svg>
  );
};
