export const scrollToEventListNode = (node: HTMLElement) => {
  setTimeout(() => {
    const parentBoundingRect = node?.parentElement?.getBoundingClientRect();
    const nodeBoundingRect = node.getBoundingClientRect();
    const scrollTopDiff = nodeBoundingRect.top - (parentBoundingRect?.top ?? 0);

    node.parentElement?.scrollBy({
      top: scrollTopDiff,
      left: 0,
      behavior: 'smooth',
    });
  }, 0);
};
