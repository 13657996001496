import { useMeasure } from '@va/util/hooks';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import DropoutLevel from './DropoutLevel';
import FunnelLevel from './FunnelLevel';

const FunnelGraphLevel = ({
  isFirst,
  isLast,
  level: { percentage, value, dropoutPercentage, dropoutValue },
  levelText,
  dropoutText,
  getGraphLevelHeight,
}) => {
  const [bind, { height, width }] = useMeasure();

  useEffect(() => {
    if (getGraphLevelHeight) getGraphLevelHeight(height);
  }, [height]);

  return (
    <div className='flex flex-column' {...bind}>
      <FunnelLevel percentage={percentage} value={value} text={levelText} isFirst={isFirst} isLast={isLast} />

      {!isLast ? (
        <DropoutLevel
          startPercentage={percentage}
          percentage={dropoutPercentage}
          value={dropoutValue}
          text={dropoutText}
          width={width}
          height={height / 2.1}
        />
      ) : (
        <div style={{ height: height / 2.1 }} />
      )}
    </div>
  );
};

FunnelGraphLevel.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  level: PropTypes.shape({
    percentage: PropTypes.number,
    value: PropTypes.number,
    dropoutPercentage: PropTypes.number,
    dropoutValue: PropTypes.number,
  }),
  levelText: PropTypes.string,
  dropoutText: PropTypes.string,
  getGraphLevelHeight: PropTypes.func,
};

export default FunnelGraphLevel;
