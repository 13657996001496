export const EditIcon = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6 1H5C2.79086 1 1 2.79086 1 5V13C1 15.2091 2.79086 17 5 17H13C15.2091 17 17 15.2091 17 13V12'
        stroke='#999999'
        strokeWidth='2'
      />
      <path
        d='M8 7L7 11L11 10L16.3787 4.62132C16.7765 4.2235 17 3.68393 17 3.12132C17 1.94975 16.0503 1 14.8787 1C14.3161 1 13.7765 1.2235 13.3787 1.62132L8 7Z'
        stroke='#999999'
        strokeWidth='2'
      />
    </svg>
  );
};
