import { get } from '@va/http-client';
import { getTypo3LocaleQueryParamValue, toQueryString } from '@va/util/helpers';
import Config from 'Config';

export type AnnouncementBannerContent = {
  id: string;
  matches: string;
  text_html: string;
  text_plain: string;
  icon: string;
  bg_color: string;
  text_color: string;
  href_match: string;
};

type AnnouncementBannerResponse = {
  payload: {
    info: {
      datetime: string;
    } & { [locale: string]: AnnouncementBannerContent };
  };
  meta: {
    page: number;
    pageSize: number | null;
    pageTotal: number | null;
    total: number | null;
  };
};

export const getAnnouncementBanner: (websiteId: string, locale: string) => Promise<AnnouncementBannerResponse> = (
  websiteId,
  locale,
) => {
  const queryData = {
    locale: getTypo3LocaleQueryParamValue(locale),
    websiteId: websiteId,
  };

  const url = `${Config.announcementBannerApiUrl}?${toQueryString(queryData)}`;
  return get(url, {}, {}, true) as Promise<AnnouncementBannerResponse>;
};
