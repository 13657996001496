import { IconProps } from '.';

export const HorizontalArrowsIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='9' cy='9' r='8.25' stroke={color} strokeWidth='1.5' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.03044 11.4697L6.31077 9.75006H9.00011H11.6895L9.96978 11.4697L11.0304 12.5304L14.0304 9.53039L14.5608 9.00006L14.0304 8.46973L11.0304 5.46973L9.96978 6.53039L11.6895 8.25006L9.00011 8.25006H6.31077L8.03044 6.53039L6.96978 5.46973L3.96978 8.46973L3.43945 9.00006L3.96978 9.53039L6.96978 12.5304L8.03044 11.4697Z'
        fill={color}
      />
    </svg>
  );
};
