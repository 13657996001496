import {
  GENERATE_IMPERSONATE_TOKEN,
  GENERATE_IMPERSONATE_TOKEN_FAILED,
  GENERATE_IMPERSONATE_TOKEN_SUCCEEDED,
} from '@va/dashboard/actions/api';
import { newApiRequest } from './core/util';

export const generateImpersonateToken = newApiRequest(
  GENERATE_IMPERSONATE_TOKEN,
  GENERATE_IMPERSONATE_TOKEN_SUCCEEDED,
  GENERATE_IMPERSONATE_TOKEN_FAILED,
);
