import { IconProps } from '.';

export const CheckedSquareIcon = ({ className, color = '#3C3C3C' }: IconProps) => {
  return (
    <svg
      className={className}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.25073"
        y="2.25027"
        width="13.5"
        height="13.5"
        rx="3"
        stroke={color ?? 'black'}
        strokeWidth="1.5"
      />
      <path
        d="M8.85639 11.5607L13.3508 7.06066L12.2914 6L8.32672 9.96967L5.86012 7.5L4.80078 8.56066L7.79705 11.5607L8.32672 12.091L8.85639 11.5607Z"
        fill={color}
      />
    </svg>
  );
};


