import { getInstanceId } from '@va/dashboard/selectors/app';
import { post } from '@va/http-client';
import { isDefaultWebsiteId } from '@va/util/helpers';
import { useLazyDataFetch } from '@va/util/hooks';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

type CountDataType = {
  payload: { count: number };
};

function sessionsTotalMapper(data: { sessionsTotal: number }) {
  return data?.sessionsTotal;
}

const countResponseMapper = (response: CountDataType) => {
  return response?.payload?.count ?? 0;
};

export const usePollsCount = (shouldFetch = true) => {
  const websiteId = useSelector(getInstanceId);
  const mapper = useCallback(countResponseMapper, []);
  return useLazyDataFetch<number, Error>(
    isDefaultWebsiteId(websiteId) || !shouldFetch ? null : `/websites/${websiteId}/polls/count`,
    undefined,
    mapper,
  );
};

export const useFunnelsCount = (shouldFetch = true) => {
  const websiteId = useSelector(getInstanceId);
  const mapper = useCallback(countResponseMapper, []);
  return useLazyDataFetch<number, Error>(
    isDefaultWebsiteId(websiteId) || !shouldFetch ? null : `/websites/${websiteId}/funnels/count`,
    undefined,
    mapper,
  );
};

export const useSurveysCount = (shouldFetch = true) => {
  const websiteId = useSelector(getInstanceId);
  const mapper = useCallback(countResponseMapper, []);
  return useLazyDataFetch<number, Error>(
    isDefaultWebsiteId(websiteId) || !shouldFetch ? null : `/websites/${websiteId}/surveys/count`,
    undefined,
    mapper,
  );
};

export const useHeatmapsCount = (shouldFetch = true) => {
  const websiteId = useSelector(getInstanceId);
  const mapper = useCallback(countResponseMapper, []);
  return useLazyDataFetch<number, Error>(
    () => (isDefaultWebsiteId(websiteId) || !shouldFetch ? null : `/websites/${websiteId}/heatmaps/count`),
    undefined,
    mapper,
  );
};

export const useEventsCount = (shouldFetch = true) => {
  const websiteId = useSelector(getInstanceId);
  const mapper = useCallback((response: { error: any; payload: { used: number; limit: number }; success: boolean }) => {
    return response?.payload?.used ?? 0;
  }, []);
  return useLazyDataFetch<number, Error>(
    isDefaultWebsiteId(websiteId) || !shouldFetch
      ? null
      : `/websites/${websiteId}/tp-counter-api/subscriptions/${websiteId}/touchpoints?feature=event-tracking`,
    undefined,
    mapper,
  );
};

export const useRecordingsCount = (shouldFetch = true) => {
  const websiteId = useSelector(getInstanceId);
  const mapper = useCallback(countResponseMapper, []);
  const url = useMemo(() => `/websites/${websiteId}/recordings/count`, [websiteId]);
  return useLazyDataFetch<number, Error>(isDefaultWebsiteId(websiteId) || !shouldFetch ? null : url, undefined, mapper);
};

export const useUnseenRecordings = () => {
  const websiteId = useSelector(getInstanceId);

  const getUnseenRecordings = useCallback(async (url: string, filters: Record<string, unknown>) => {
    const res = await post<{ sessionsTotal: number }>(url, {}, filters);
    return sessionsTotalMapper(res);
  }, []);
  return useLazyDataFetch<number>(
    // TODO Update to use real boolean values
    [`/v2/websites/${websiteId}/sessions/recordings`, { watched: 'false' }],
    undefined,
    undefined,
    getUnseenRecordings,
  );
};

export const useStarredRecordings = () => {
  const websiteId = useSelector(getInstanceId);

  const getStarredRecordings = useCallback(async (url: string, filter: Record<string, unknown>) => {
    const res = await post<{ sessionsTotal: number; data: any }>(url, {}, filter);
    return sessionsTotalMapper(res);
  }, []);

  return useLazyDataFetch(
    // TODO Update to use real boolean values
    [`/v2/websites/${websiteId}/sessions/recordings`, { starred: 'true' }],
    undefined,
    undefined,
    getStarredRecordings,
  );
};

export const useLeftVisitsCount = (shouldFetch = true) => {
  const websiteId = useSelector(getInstanceId);
  const mapper = useCallback(countResponseMapper, []);
  return useLazyDataFetch<number, Error>(
    isDefaultWebsiteId(websiteId) || !shouldFetch ? null : `/websites/${websiteId}/visits/count`,
    undefined,
    mapper,
  );
};

export const useFunnelsHighestConversionRate = () => {
  const websiteId = useSelector(getInstanceId);
  const mapper = useCallback((data: { payload: { highestConversionRate: number } }) => {
    return data?.payload?.highestConversionRate;
  }, []);

  const url = useMemo(() => `/v2/websites/${websiteId}/funnels/highest-conversion-rate`, [websiteId]);

  return useLazyDataFetch<number, Error>(url, undefined, mapper);
};

export const useFunnelsAverageConversionRate = () => {
  const websiteId = useSelector(getInstanceId);
  const mapper = useCallback((data: { payload: { 'average-conversion-rate': number } }) => {
    return data?.payload['average-conversion-rate'];
  }, []);
  const url = useMemo(() => `/v2/websites/${websiteId}/funnels/average-conversion-rate`, [websiteId]);

  return useLazyDataFetch<number, Error>(url, undefined, mapper);
};

export const useRecordingsAverageDuration = () => {
  const websiteId = useSelector(getInstanceId);

  const url = useMemo(() => `/v2/websites/${websiteId}/sessions/recordings/average-duration`, [websiteId]);

  const mapper = useCallback((data: { payload: { 'average-duration': number } }) => {
    return Number(data?.payload['average-duration']);
  }, []);

  return useLazyDataFetch<number, Error>(url, undefined, mapper);
};

export const usePollsAverageCompletionRate = () => {
  const websiteId = useSelector(getInstanceId);
  const url = useMemo(() => `/v2/websites/${websiteId}/polls/average-completion-rate`, [websiteId]);
  const mapper = useCallback((data: { payload: { 'average-completion-rate': number } }) => {
    return data?.payload['average-completion-rate'];
  }, []);
  return useLazyDataFetch<number, Error>(url, undefined, mapper);
};

export const usePollsTotalInitiates = () => {
  const websiteId = useSelector(getInstanceId);
  const url = useMemo(() => `/v2/websites/${websiteId}/polls/initiates`, [websiteId]);
  const mapper = useCallback((data: CountDataType) => {
    return data?.payload?.count;
  }, []);
  return useLazyDataFetch<number, Error>(url, undefined, mapper);
};

export const usePollsTotalResponses = () => {
  const websiteId = useSelector(getInstanceId);
  const url = useMemo(() => `/v2/websites/${websiteId}/polls/responses`, [websiteId]);
  const mapper = useCallback((data: CountDataType) => {
    return data?.payload?.count;
  }, []);
  return useLazyDataFetch<number, Error>(url, undefined, mapper);
};

export const useSurveysAverageCompletionRate = () => {
  const websiteId = useSelector(getInstanceId);
  const url = useMemo(() => `/v2/websites/${websiteId}/surveys/average-completion-rate`, [websiteId]);
  const mapper = useCallback((data: { payload: { 'average-completion-rate': number } }) => {
    return data?.payload['average-completion-rate'];
  }, []);
  return useLazyDataFetch<number, Error>(url, undefined, mapper);
};

export const useSurveysTotalInitiates = () => {
  const websiteId = useSelector(getInstanceId);
  const url = useMemo(() => `/v2/websites/${websiteId}/surveys/initiates`, [websiteId]);
  const mapper = useCallback((data: CountDataType) => {
    return data?.payload?.count;
  }, []);
  return useLazyDataFetch<number, Error>(url, undefined, mapper);
};

export const useSurveysTotalResponses = () => {
  const websiteId = useSelector(getInstanceId);
  const url = useMemo(() => `/v2/websites/${websiteId}/surveys/responses`, [websiteId]);
  const mapper = useCallback((data: CountDataType) => {
    return data?.payload?.count;
  }, []);
  return useLazyDataFetch<number, Error>(url, undefined, mapper);
};

export const useSurveysStats = () => {
  const { data: averageCompletionRate = 0, isLoading: isLoadingAvgCompletionRate } = useSurveysAverageCompletionRate();
  const { data: totalInitiates = 0, isLoading: isLoadingTotalInitiates } = useSurveysTotalInitiates();
  const { data: totalResponses = 0, isLoading: isLoadingTotalResponses } = useSurveysTotalResponses();

  const isLoading = isLoadingAvgCompletionRate || isLoadingTotalInitiates || isLoadingTotalResponses;

  return {
    averageCompletionRate,
    totalInitiates,
    totalResponses,
    isLoading,
  };
};

export const useRecordingsStats = () => {
  const { data: unseen = 0, isLoading: isLoadingUnseen } = useUnseenRecordings();
  const { data: starred = 0, isLoading: isLoadingStarred } = useStarredRecordings();
  const { data: averageDuration = 0, isLoading: isLoadingAverageDuration } = useRecordingsAverageDuration();
  const isLoading = isLoadingStarred || isLoadingUnseen || isLoadingAverageDuration;
  return {
    unseen,
    starred,
    averageDuration,
    isLoading,
  };
};

export const useFunnelsStats = () => {
  const { data: highestConversionRate = 0, isLoading: isLoadingHighestRate } = useFunnelsHighestConversionRate();
  const { data: averageConversionRate = 0, isLoading: isLoadingAverageRate } = useFunnelsAverageConversionRate();

  const isLoading = isLoadingAverageRate || isLoadingHighestRate;
  return {
    averageConversionRate,
    highestConversionRate,
    isLoading,
  };
};

export const usePollsStats = () => {
  const { data: averageCompletionRate = 0, isLoading: isLoadingAvgCompletionRate } = usePollsAverageCompletionRate();
  const { data: totalInitiates = 0, isLoading: isLoadingTotalInitiates } = usePollsTotalInitiates();
  const { data: totalResponses = 0, isLoading: isLoadingTotalResponses } = usePollsTotalResponses();

  const isLoading = isLoadingAvgCompletionRate || isLoadingTotalInitiates || isLoadingTotalResponses;

  return {
    averageCompletionRate,
    totalInitiates,
    totalResponses,
    isLoading,
  };
};
