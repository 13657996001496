import { SectionHeader } from '@va/ui/components/white-label-customisation';
import { Button, ButtonProps, RadioInputOption } from '@va/ui/design-system';
import { PencilEditIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import Skeleton from 'react-loading-skeleton';
import { ThemeColorOptions } from './ThemeColorOptions';
import { ThemeType } from './types';

type ThemeComponentProps = {
  themes: ThemeType[];
  isLoadingThemes: boolean;
  editButtonProps?: ButtonProps;
  saveButtonProps?: ButtonProps;
  selectedThemeId: string | undefined;
  onSave: (selectedThemeId: string) => void;
  updateSelectedThemeId: (themeId: string) => void;
  onEditClick: () => void;
  onPreviewClick: () => void;
};

export const ThemeComponent: React.FC<ThemeComponentProps> = ({
  themes,
  isLoadingThemes,
  editButtonProps,
  saveButtonProps,
  onSave,
  selectedThemeId,
  updateSelectedThemeId,
  onEditClick,
  onPreviewClick,
}) => {
  const translate = useTranslate();

  return (
    <div className='space-y-18'>
      <SectionHeader
        title={translate(`whiteLabel.settings.title.theme`)}
        description={translate('whiteLabel.theme.info')}
      />
      <div className='p-2 rounded-18 bg-white-snow space-y-2'>
        {isLoadingThemes && <Skeleton className='h-60 rounded-18 mb-1' count={4} />}
        {themes.map(({ colors, name, id }, index) => {
          const colorValues = Object.values(colors);

          return (
            <RadioInputOption
              onClick={() => {
                updateSelectedThemeId(id);
              }}
              key={index}
              selected={selectedThemeId === id}
              title={name}
              rightSideNode={<ThemeColorOptions colors={colorValues} />}
            />
          );
        })}
        <div className='flex gap-2 py-1'>
          <Button
            className='flex-1 justify-center'
            onClick={onEditClick}
            icon={(_, color) => <PencilEditIcon color={color} />}
            {...editButtonProps}
          />
          <Button
            className='flex-1'
            disabled={!selectedThemeId}
            tooltip={!selectedThemeId ? translate('whiteLabel.previewTheme.selectTheme') : undefined}
            color='quaternary'
            text={translate('whiteLabel.settings.previewTheme')}
            onClick={onPreviewClick}
          />
        </div>
      </div>
      <Button
        onClick={() => {
          if (!selectedThemeId) return;
          onSave(selectedThemeId);
        }}
        className='w-full'
        color='secondary'
        text={translate('button.saveChanges')}
        {...saveButtonProps}
      />
    </div>
  );
};
