import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import FunnelGraphLevel from './FunnelGraphLevel';

/** @deprecated */
const FunnelGraph = ({ levelText, dropoutText, levels, getGraphLevelHeight }) => {
  const parsedLevels = useMemo(() => {
    const parsedLevels = [];
    for (let i = 0; i < levels.length; i++) {
      const firstLevel = levels[0] || 1;
      const level = levels[i];
      const nextLevel = levels[i + 1];

      const isLastLevel = i === levels.length + 1;

      parsedLevels.push({
        value: level,
        percentage: Math.round((level * 100) / firstLevel),
        ...(!isLastLevel && { dropoutValue: level - nextLevel }),
        ...(!isLastLevel && {
          dropoutPercentage: Math.round((level * 100) / firstLevel - (nextLevel * 100) / firstLevel),
        }),
      });
    }
    return parsedLevels;
  }, [levels]);

  return (
    <div className='flex flex-column'>
      {parsedLevels.map((level, index) => {
        return (
          <FunnelGraphLevel
            isFirst={index === 0}
            isLast={index === parsedLevels.length - 1}
            key={'level' + index}
            level={level}
            levelText={levelText}
            dropoutText={dropoutText}
            getGraphLevelHeight={getGraphLevelHeight}
          />
        );
      })}
    </div>
  );
};

FunnelGraph.propTypes = {
  levelText: PropTypes.string.isRequired,
  dropoutText: PropTypes.string.isRequired,
  levels: PropTypes.arrayOf(PropTypes.number),
  getGraphLevelHeight: PropTypes.func.isRequired,
};

export default memo(FunnelGraph);
