import { LIMIT, QUESTION_TYPE } from '@va/dashboard/modules/feedback/shared';
import DebouncedTextarea from '@va/deprecated/form/DebouncedTextarea';
import { TripleDotIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { TooltipWrapper } from '@va/ui/tooltips';
import { FormContext } from '@va/util/misc';
import classNames from 'classnames';
import { FastField, Field, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { forwardRef, memo, useContext, useMemo } from 'react';
import ChoiceEditor from './ChoiceEditor';
import FixedChoiceEditor from './FixedChoiceEditor';
import Question from './Question';
import QuestionOptions from './QuestionOptions';
import QuestionTypeDropdown from './QuestionTypeDropdown';

const EditableQuestion = ({
  number,
  onDuplicate,
  onDelete,
  name,
  totalQuestions,
  onPositionChange,
  remove,
  index,
  replace,
}) => {
  const formConfig = useContext(FormContext);
  const { isFormDisabled } = formConfig;
  return (
    <Question
      number={number}
      header={
        <div className='flex w-full items-stretch'>
          <div className='grow min-w-0'>
            <QuestionTypeDropdown name={`${name}.type`} remove={remove} index={index} replace={replace} />
          </div>
          <div
            className={classNames('rounded-r-lg border-2 border-l-0 border-gray-gallery', {
              'pointer-events-none': isFormDisabled,
            })}
          >
            <TooltipWrapper
              trigger='click'
              arrow={false}
              content={
                <QuestionOptions
                  number={number}
                  totalQuestions={totalQuestions}
                  name={name}
                  onDelete={onDelete}
                  onDuplicate={onDuplicate}
                  onPositionChange={onPositionChange}
                />
              }
              useDefaultStyle={false}
            >
              <div className='w-full h-full'>
                <OptionsButton />
              </div>
            </TooltipWrapper>
          </div>
        </div>
      }
    >
      <QuestionBody name={name} />
      <DescriptionWrapper name={name} />
      <EditorWrapper name={name} />
    </Question>
  );
};

EditableQuestion.propTypes = {
  number: PropTypes.number.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default memo(EditableQuestion);

const QuestionBody = memo(function QuestionBody({ name }) {
  const translate = useTranslate();
  const formConfig = useContext(FormContext);
  const { isFormDisabled } = formConfig;
  const { getFieldProps } = useFormikContext();
  const fieldProps = getFieldProps(name);
  const {
    value: { type },
  } = fieldProps;

  const fieldName = useMemo(() => `${name}.body`, [name]);

  return (
    <Field name={fieldName}>
      {({ field }) => {
        return (
          <DebouncedTextarea
            name={field.name}
            disabled={isFormDisabled}
            className='w-full leading-normal bg-white'
            placeholder={translate(getQuestionPlaceHolder(type))}
            maxLength={LIMIT.CHARS.QUESTION}
            value={field.value}
            onChange={field.onChange}
          />
        );
      }}
    </Field>
  );
});

const DescriptionWrapper = memo(function DescriptionWrapper({ name }) {
  const translate = useTranslate();
  const { getFieldProps } = useFormikContext();
  const formConfig = useContext(FormContext);
  const { isFormDisabled } = formConfig;

  const {
    value: { hasDescription },
  } = getFieldProps(name);

  if (!hasDescription) return null;

  return (
    <FastField name={`${name}.description`}>
      {({ field }) => (
        <DebouncedTextarea
          disabled={isFormDisabled}
          className='w-full text-sm leading-normal text-devil-gray bg-white'
          placeholder={translate('feedback.question.descriptionPlaceholder')}
          maxLength={LIMIT.CHARS.QUESTION_DESCRIPTION}
          {...field}
        />
      )}
    </FastField>
  );
});

const EditorWrapper = memo(function EditorWrapper({ name }) {
  const { getFieldProps } = useFormikContext();

  const {
    value: { type },
  } = getFieldProps(name);

  const Editor = getBodyEditor(type);

  if (!Editor) return null;

  return (
    <div className='mt-4'>
      <Editor name={`${name}.choices`} />
    </div>
  );
});

const OptionsButton = forwardRef(function OptionsButton(_props, ref) {
  const cssClasses = useMemo(() => {
    const border = 'border-2 border-white rounded-r-lg';
    const background = 'transition duration-100 hover:bg-alabaster active:bg-gray-concrete';
    return classNames(border, background, 'w-14 h-full flex items-center justify-center focus:outline-none');
  }, []);
  return (
    <button type='button' ref={ref} className={cssClasses}>
      <TripleDotIcon />
    </button>
  );
});

const getBodyEditor = (type) => {
  switch (type) {
    case QUESTION_TYPE.MULTIPLE_CHOICE:
      return ChoiceEditor;
    case QUESTION_TYPE.SLIDER:
    case QUESTION_TYPE.RATING_SCALE:
    case QUESTION_TYPE.RATING:
      return FixedChoiceEditor;
    default:
      return null;
  }
};

const getQuestionPlaceHolder = (type) => {
  switch (type) {
    case QUESTION_TYPE.SHORT_TEXT:
      return 'feedback.question.placeholder.shortText';
    case QUESTION_TYPE.LONG_TEXT:
      return 'feedback.question.placeholder.longText';
    case QUESTION_TYPE.MULTIPLE_CHOICE:
      return 'feedback.question.placeholder.multipleChoice';
    case QUESTION_TYPE.SLIDER:
      return 'feedback.question.placeholder.slider';
    case QUESTION_TYPE.RATING:
      return 'feedback.question.placeholder.rating';
    case QUESTION_TYPE.RATING_SCALE:
      return 'feedback.question.placeholder.ratingScale';
    case QUESTION_TYPE.EMAIL:
      return 'feedback.question.placeholder.email';
    case QUESTION_TYPE.PHONE:
      return 'feedback.question.placeholder.phone';
    case QUESTION_TYPE.WEBSITE_LINK:
      return 'feedback.question.placeholder.website';
    case QUESTION_TYPE.DATE:
      return 'feedback.question.placeholder.date';
    default:
      return null;
  }
};
