import React from 'react';

export const DesktopIllustration = () => {
  return (
    <svg width='100%' height='100%' viewBox='0 0 1000 800' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.5 38.25C4.5 19.6104 19.6104 4.5 38.25 4.5H1320V0H38.25C17.1251 0 0 17.1251 0 38.25V702H4.5V82.5H1320V78H4.5V38.25Z'
        fill='url(#paint0_linear_14042_343149)'
      />
      <circle cx='41.25' cy='41.25' r='9' fill='#EA2A0C' />
      <circle cx='77.25' cy='41.25' r='9' fill='#C3C3C3' />
      <circle cx='113.25' cy='41.25' r='9' fill='#C3C3C3' />
      <defs>
        <linearGradient
          id='paint0_linear_14042_343149'
          x1='0.365769'
          y1='351.194'
          x2='1320'
          y2='351.194'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E1E1E1' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  );
};
