import { IconProps } from '../misc';

export const LockIconFilled = ({ color = '#2478F0', className }: IconProps) => {
  return (
    <svg
      className={className}
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12.5' cy='12' r='11.25' stroke={color} strokeWidth='1.5' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5 21C17.4706 21 21.5 16.9706 21.5 12C21.5 7.02944 17.4706 3 12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21ZM14.75 9.75C14.75 10.6675 14.2009 11.4567 13.4134 11.8069L14 16.5H11L11.5866 11.8069C10.7991 11.4567 10.25 10.6675 10.25 9.75C10.25 8.50736 11.2574 7.5 12.5 7.5C13.7426 7.5 14.75 8.50736 14.75 9.75Z'
        fill={color}
      />
    </svg>
  );
};
