class LocalStorage {
  storage: Record<string, unknown> = {};
  isLocalStorageAvailable: boolean;
  static instance: any;

  constructor() {
    this.isLocalStorageAvailable = this.checkLocalStorageAvailability();

    if (typeof LocalStorage.instance === 'object') {
      return LocalStorage.instance;
    }
  }

  checkLocalStorageAvailability() {
    try {
      localStorage.setItem('test-storage', '1');
      localStorage.removeItem('test-storage');
      return true;
    } catch (e) {
      return false;
    }
  }

  setItem(key: string, value: any) {
    if (this.isLocalStorageAvailable) {
      return localStorage.setItem(key, value);
    } else {
      this.storage[key] = value;
      return this.storage;
    }
  }

  getItem(key: string): string | null {
    if (this.isLocalStorageAvailable) {
      return localStorage.getItem(key);
    } else {
      return this.storage[key] as string;
    }
  }

  removeItem(key: string) {
    if (this.isLocalStorageAvailable) {
      return localStorage.removeItem(key);
    } else {
      // eslint-disable-next-line no-prototype-builtins
      if (this.storage.hasOwnProperty(key)) {
        delete this.storage[key];
      }
      return this.storage;
    }
  }
}

export default new LocalStorage();
