import { get, post } from '@va/http-client';
import { storageItems } from '@va/standalone/shared/constants';
import { LocalStorage } from '@va/util/helpers';

export const setUserFlag = (data: any) => {
  const id = LocalStorage.getItem(storageItems.userId);
  const url = `/users/${id}/frontendFlags`;

  return post(url, {}, data);
};

export const getAccountInformation = (data: any) => {
  const id = LocalStorage.getItem(storageItems.userId);
  const url = `/users/${id}`;

  return get(url, data);
};
