import { IconProps } from './index';

export const FilledLineIcon = ({ className = '', color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path opacity='0.15' d='M3 6.5H0V15.5H18V6.5H15L12 3.5L6 9.5L3 6.5Z' fill={color} />
      <path d='M0 6.5H3L6 9.5L12 3.5L15 6.5H18' stroke={color} strokeWidth='1.5' />
    </svg>
  );
};
