import { useTranslate } from '@va/localization';
import { RouterWarningModal } from '@va/shared/util-confirmation';
import { Button, ButtonProps, fontWeights, Paragraph, RadioInputOption } from '@va/ui/design-system';
import { useEffect, useMemo, useState } from 'react';
import { SectionHeader } from '../misc/SectionHeader';

export enum LinkEnum {
  legalNoticelink = 1,
  privacyPolicyLink = 2,
}

type FontComponentProps = {
  fontName: string | undefined;
  fontOptions: { label: string; value: string }[];
  title: string;
  description: string;
  saveButtonProps?: ButtonProps;
  onSave: (selectedFont: string) => void;
};

export const FontComponent: React.FC<FontComponentProps> = ({
  fontName,
  fontOptions,
  title,
  description,
  onSave,
  saveButtonProps,
}) => {
  const [selectedFontName, setSelectedFontName] = useState(fontName);

  const translate = useTranslate();

  useEffect(() => {
    if (!fontName) return;
    setSelectedFontName(fontName);
  }, [fontName]);

  const hasMadeChanges = useMemo(() => selectedFontName !== fontName, [fontName, selectedFontName]);

  return (
    <>
      <RouterWarningModal when={hasMadeChanges} />
      <div className='space-y-18'>
        <SectionHeader title={title} description={description} />
        <div className='p-2 rounded-18 bg-white-snow space-y-2'>
          {fontOptions.map((option) => (
            <RadioInputOption
              selected={selectedFontName === option.value}
              title={option.label}
              onClick={() => {
                setSelectedFontName(option.value);
              }}
              rightSideNode={
                <Paragraph
                  weight={fontWeights.medium}
                  colorClassName='text-gray-devil'
                  style={{ fontFamily: option.value }}
                >
                  Aa Bb Cc
                </Paragraph>
              }
            />
          ))}
        </div>
        <Button
          {...saveButtonProps}
          onClick={() => {
            if (!selectedFontName) return;
            onSave(selectedFontName);
          }}
          color='secondary'
          className='mt-4 w-full'
          text={translate('button.saveChanges')}
          disabled={!hasMadeChanges || !selectedFontName || saveButtonProps?.disabled}
        />
      </div>
    </>
  );
};
