import {
  ADD_CONVERSION_TYPE,
  ADD_CONVERSION_TYPE_FAILED,
  ADD_CONVERSION_TYPE_SUCCEEDED,
  EDIT_CONVERSION_TYPE,
  EDIT_CONVERSION_TYPE_FAILED,
  EDIT_CONVERSION_TYPE_SUCCEEDED,
  GET_CONVERSION_TYPES,
  GET_CONVERSION_TYPES_FAILED,
  GET_CONVERSION_TYPES_SUCCEEDED,
  REMOVE_CONVERSION_TYPE,
  REMOVE_CONVERSION_TYPE_FAILED,
  REMOVE_CONVERSION_TYPE_SUCCEEDED,
} from '@va/dashboard/actions/api';
import { newApiRequest } from './core/util';

export const getConversionTypes = newApiRequest(
  GET_CONVERSION_TYPES,
  GET_CONVERSION_TYPES_SUCCEEDED,
  GET_CONVERSION_TYPES_FAILED,
);

export const addConversionType = newApiRequest(
  ADD_CONVERSION_TYPE,
  ADD_CONVERSION_TYPE_SUCCEEDED,
  ADD_CONVERSION_TYPE_FAILED,
);

export const editConversionType = newApiRequest(
  EDIT_CONVERSION_TYPE,
  EDIT_CONVERSION_TYPE_SUCCEEDED,
  EDIT_CONVERSION_TYPE_FAILED,
);

export const removeConversionType = newApiRequest(
  REMOVE_CONVERSION_TYPE,
  REMOVE_CONVERSION_TYPE_SUCCEEDED,
  REMOVE_CONVERSION_TYPE_FAILED,
);
