import { IconProps } from '.';

export const WebsiteManagementGlobe = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='9' cy='9' r='8.25' stroke={color} strokeWidth='1.5' />
      <path
        d='M14.625 9C14.625 11.4216 13.97 13.5969 12.9306 15.1559C11.8895 16.7176 10.4925 17.625 9 17.625C7.50754 17.625 6.11047 16.7176 5.06938 15.1559C4.03004 13.5969 3.375 11.4216 3.375 9C3.375 6.57843 4.03004 4.40307 5.06938 2.84405C6.11047 1.28242 7.50754 0.375 9 0.375C10.4925 0.375 11.8895 1.28242 12.9306 2.84405C13.97 4.40307 14.625 6.57843 14.625 9Z'
        stroke={color}
        strokeWidth='0.75'
      />
      <path
        d='M11.625 9C11.625 11.4562 11.2926 13.6643 10.7656 15.2454C10.5014 16.0379 10.1951 16.6522 9.87321 17.0606C9.54797 17.4733 9.25006 17.625 9 17.625C8.74994 17.625 8.45203 17.4733 8.12679 17.0606C7.80489 16.6522 7.4986 16.0379 7.23444 15.2454C6.70741 13.6643 6.375 11.4562 6.375 9C6.375 6.54381 6.70741 4.33571 7.23444 2.75462C7.4986 1.96212 7.80489 1.34784 8.12679 0.93938C8.45203 0.526695 8.74994 0.375 9 0.375C9.25006 0.375 9.54797 0.526695 9.87321 0.93938C10.1951 1.34784 10.5014 1.96212 10.7656 2.75462C11.2926 4.33571 11.625 6.54381 11.625 9Z'
        stroke={color}
        strokeWidth='0.75'
      />
      <path
        d='M9 14.625C6.57843 14.625 4.40307 13.97 2.84405 12.9306C1.28242 11.8895 0.375 10.4925 0.375 9C0.375 7.50754 1.28242 6.11047 2.84405 5.06938C4.40307 4.03003 6.57843 3.375 9 3.375C11.4216 3.375 13.5969 4.03003 15.1559 5.06938C16.7176 6.11047 17.625 7.50754 17.625 9C17.625 10.4925 16.7176 11.8895 15.1559 12.9306C13.5969 13.97 11.4216 14.625 9 14.625Z'
        stroke={color}
        strokeWidth='0.75'
      />
      <path
        d='M9 11.625C6.54381 11.625 4.33571 11.2926 2.75462 10.7656C1.96212 10.5014 1.34784 10.1951 0.93938 9.87321C0.526695 9.54797 0.375 9.25006 0.375 9C0.375 8.74994 0.526695 8.45203 0.939381 8.12679C1.34784 7.80489 1.96212 7.4986 2.75462 7.23444C4.33571 6.70741 6.54381 6.375 9 6.375C11.4562 6.375 13.6643 6.70741 15.2454 7.23444C16.0379 7.4986 16.6522 7.80489 17.0606 8.12679C17.4733 8.45203 17.625 8.74994 17.625 9C17.625 9.25006 17.4733 9.54797 17.0606 9.87321C16.6522 10.1951 16.0379 10.5014 15.2454 10.7656C13.6643 11.2926 11.4562 11.625 9 11.625Z'
        stroke={color}
        strokeWidth='0.75'
      />
    </svg>
  );
};
