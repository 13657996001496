import { makeAction } from '@va/store';

export const SET_USER_FLAG_REQUEST = 'API_SET_USER_FLAG_REQUEST';
export const SET_USER_FLAG_SUCCEEDED = 'API_SET_USER_FLAG_SUCCEEDED';
export const SET_USER_FLAG_FAILED = 'API_SET_USER_FLAG_FAILED';

export const GET_ACCOUNT_INFORMATION_REQUEST = 'API_GET_ACCOUNT_INFORMATION_REQUEST';
export const GET_ACCOUNT_INFORMATION_SUCCEEDED = 'API_GET_ACCOUNT_INFORMATION_SUCCEEDED';
export const GET_ACCOUNT_INFORMATION_FAILED = 'API_GET_ACCOUNT_INFORMATION_FAILED';

export const setUserFlag = makeAction(SET_USER_FLAG_REQUEST, 'label', 'value');
export const setUserFlagsSucceeded = makeAction(SET_USER_FLAG_SUCCEEDED, 'data');
export const setUserFlagFailed = makeAction(SET_USER_FLAG_FAILED, 'error');

export const getAccountInformation = makeAction(GET_ACCOUNT_INFORMATION_REQUEST);
export const getAccountInformationSucceeded = makeAction(GET_ACCOUNT_INFORMATION_SUCCEEDED, 'data');
export const getAccountInformationFailed = makeAction(GET_ACCOUNT_INFORMATION_FAILED, 'error');
