import { apiRequestResponseMsg, defaultSsrSettings } from '@va/constants';
import { createSsrSettings } from '@va/dashboard/actions/api';
import { getSsrSettingsSelector } from '@va/dashboard/selectors/core';
import { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

enum ViewEnum {
  installation = 'installation',
  installationResult = 'installationResult',
}

type InstallTrackingCodeContextTypes = {
  onSuccess: () => void;
  onError: () => void;
  view: ViewEnum;
  setView: (value: ViewEnum) => void;
};

export const InstallTrackingCodeContext = createContext<InstallTrackingCodeContextTypes>(
  {} as InstallTrackingCodeContextTypes,
);

const InstallTrackingCodeContextProvider: React.FC<PropsWithChildren<{ onError: () => void }>> = ({
  onError,
  children,
}) => {
  const [view, setView] = useState(ViewEnum.installation);
  const ssrSettings = useSelector(getSsrSettingsSelector);
  const dispatch = useDispatch();

  const handleOnSuccessInstallation = useCallback(() => {
    if (ssrSettings?.error?.message === apiRequestResponseMsg.NOT_FOUND_SSR_SETTINGS) {
      dispatch(createSsrSettings(defaultSsrSettings));
    }

    setView(ViewEnum.installationResult);
  }, [dispatch, ssrSettings?.error?.message]);

  const handleOnFailedInstallation = useCallback(() => {
    setView(ViewEnum.installationResult);
    onError();
  }, [onError]);

  return (
    <InstallTrackingCodeContext.Provider
      value={{ onSuccess: handleOnSuccessInstallation, onError: handleOnFailedInstallation, view, setView }}
    >
      {children}
    </InstallTrackingCodeContext.Provider>
  );
};

export default InstallTrackingCodeContextProvider;

export const useInstallTrackingCodeContext = () => {
  return useContext(InstallTrackingCodeContext);
};
