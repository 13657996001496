import { AnyAction, Dispatch } from 'redux';

export const crashReporterMiddleware =
  (store: unknown) =>
  (next: Dispatch<AnyAction>) =>
  (action: {
    error?: {
      message: string;
      code: string;
      stack: string;
    };
    type: string;
  }) => {
    try {
      if (action.error) {
        const errorObject = {
          message: action.error.message,
          code: action.error.code,
          stackTrace: action.error.stack,
          action: action.type,
        };

        console.error(errorObject);
      }
      return next(action);
    } catch (error) {
      console.error('Error logged by Crash reporter middleware:', error);
      throw error;
    }
  };
