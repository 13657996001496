import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { getAgencyUiSucceeded } from '@va/standalone/shared/actions';
import { agencyWhiteLabelFontFamily as fontFamilyOptions } from '@va/standalone/shared/constants';
import { FontComponent } from '@va/ui/components/white-label-customisation';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateAgencyUi } from '../apiClient';
import useAgencyUi from '../useAgencyUi';

const FontSectionContainer = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const { showSuccessNotification, showErrorNotification } = useAddNotification();

  const agencyUi: { fontName: string; id: string } | undefined = useAgencyUi();

  const { execute: changeFontFamily, isLoading, isSucceeded, error, clearError, data } = useUpdateAgencyUi();

  useEffect(() => {
    if (!isSucceeded) return;
    showSuccessNotification();
    dispatch(getAgencyUiSucceeded({ data }));
  }, [data, dispatch, isSucceeded, showSuccessNotification]);

  useEffect(() => {
    if (!error) return;
    clearError();
    showErrorNotification();
  }, [clearError, error, showErrorNotification]);

  return (
    <FontComponent
      title={translate('whiteLabel.font.title')}
      description={translate('whiteLabel.font.info')}
      fontName={agencyUi?.fontName}
      fontOptions={fontFamilyOptions}
      onSave={(selectedFont) => {
        changeFontFamily({ fontName: selectedFont });
      }}
      saveButtonProps={{
        tooltip: !agencyUi ? translate('whiteLabelCustomization.info.firstFillCustomUrl') : undefined,
        disabled: !agencyUi || isLoading,
      }}
    />
  );
};

export default FontSectionContainer;
