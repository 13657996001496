export function setCookie(cname: string, cvalue: string, expSeconds: number, cdomain?: string, cpath?: string) {
  const d = new Date();
  d.setTime(d.getTime() + expSeconds * 1000);

  const name = `${cname}=${cvalue}`;
  const expires = 'expires=' + d.toUTCString();
  const path = cpath ? `path=${cpath}` : 'path=/';
  const domain = cdomain ? `domain=${cdomain}` : '';

  document.cookie = createCookieString([name, expires, path, domain]);
}

export function getCookie(cname: string) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function deleteCookie(cname: string, cpath?: string, cdomain?: string) {
  const name = `${cname}=`;
  const expires = 'expires=Thu, 01 Jan 1970 00:00:01 GMT';
  const path = cpath ? `path=${cpath}` : '';
  const domain = cdomain ? `domain=${cdomain}` : '';

  document.cookie = createCookieString([name, expires, path, domain]);
}

export function createCookieString(cookieElement: string[]) {
  return cookieElement.filter((element) => element).join(';');
}
