import { includes } from 'lodash';

const apiStatus = {
  NONE: 'NONE',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
};

export function noneOrFailedStatus(status: string) {
  return includes([apiStatus.NONE, apiStatus.FAILED], status);
}

export function someInProgress(...args: string[]) {
  return includes(args, apiStatus.IN_PROGRESS);
}

export function successOrFailedStatus(status: string) {
  return includes([apiStatus.SUCCEEDED, apiStatus.FAILED], status);
}

export function someFailed(...args: string[]) {
  return includes(args, apiStatus.FAILED);
}

// TODO Test
export function someSucceeded(...args: any[]) {
  return includes(Array.from(args), apiStatus.SUCCEEDED);
}

export function noneOrInProgressStatus(status: string) {
  return includes([apiStatus.NONE, apiStatus.IN_PROGRESS], status);
}
