import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { apiStatus } from '@va/constants';
import LoadingCard from '@va/deprecated/components/PopUpCard/card-types/LoadingCard';
import { renderIf, someInProgress, successOrFailedStatus } from '@va/util/helpers';
import MessageCard from 'standalone/components/cards/PopUpCard/card-types/MessageCard';
import EnterNewEmail from './EnterNewEmail';

export default class ChangeEmailCard extends PureComponent {
  render() {
    const showViews = {
      showEnterNewEmail: this.props.changeEmailStatus === apiStatus.NONE,
      showMessage: successOrFailedStatus(this.props.changeEmailStatus),
      showLoading: someInProgress(this.props.changeEmailStatus),
      isEmailError: this.props.changeEmailStatus === apiStatus.FAILED,
    };

    const renderEnterNewEmail = renderIf(showViews.showEnterNewEmail);
    const renderMessage = renderIf(showViews.showMessage);
    const renderLoading = renderIf(showViews.showLoading);

    return (
      <div className='modal-content'>
        {renderEnterNewEmail(
          <EnterNewEmail translate={this.props.translate} cancel={this.props.closeModal} {...this.props} />,
        )}

        {renderMessage(
          <MessageCard
            modalName='changeEmail'
            translate={this.props.translate}
            onClose={this.props.closeModal}
            isRequestEmailError={showViews.isEmailError}
          />,
        )}

        {renderLoading(<LoadingCard />)}
      </div>
    );
  }
}

ChangeEmailCard.propTypes = {
  translate: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  changeEmailStatus: PropTypes.oneOf(Object.values(apiStatus)).isRequired,
};
