import { apiStatus } from '@va/constants';
import { resetRequestStatus } from '@va/dashboard/actions/api';
import { closeModal } from '@va/dashboard/actions/ui';
import { isModalOpen } from '@va/dashboard/selectors/ui';
import ModalWrapper from '@va/dashboard/shared/modals/ModalWrapper';
import LoadingCard from '@va/deprecated/components/PopUpCard/card-types/LoadingCard';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import * as Selectors from '@va/standalone/shared/selectors';
import { renderIf, successOrFailedStatus } from '@va/util/helpers';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import MessageCard from 'standalone/components/cards/PopUpCard/card-types/MessageCard';

class FeedbackModal extends PureComponent {
  constructor(props) {
    super(props);

    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onCloseModal() {
    if (this.props.requestStatus === apiStatus.IN_PROGRESS) {
      return;
    }

    this.props.resetRequestStatus(this.props.name);
    this.props.closeModal(this.props.name);
  }

  render() {
    const { translate, requestStatus } = this.props;
    const showLoading = includes([apiStatus.NONE, apiStatus.IN_PROGRESS], requestStatus);

    const renderLoading = renderIf(showLoading);
    const renderMessage = renderIf(successOrFailedStatus(requestStatus));

    return (
      <ModalWrapper closeModal={this.onCloseModal} isOpen={this.props.isModalOpen} showCloseButton={!showLoading}>
        <React.Fragment>
          {renderMessage(
            <MessageCard
              modalName={this.props.name}
              translate={translate}
              onClose={this.onCloseModal}
              isConfirmEmailError={requestStatus === apiStatus.FAILED}
              confirmationView={true}
            />,
          )}

          {renderLoading(<LoadingCard colorTheme={this.props.colorTheme} />)}
        </React.Fragment>
      </ModalWrapper>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isModalOpen: isModalOpen(state, ownProps.name),
    requestStatus: Selectors.standalone.Api.getApiRequestStatus(state, ownProps.name),
  };
}

const mapDispatchToProps = {
  resetRequestStatus: resetRequestStatus,
  closeModal: closeModal,
};

FeedbackModal.propTypes = {
  name: PropTypes.string.isRequired,
  colorTheme: PropTypes.oneOf(['theme-green', 'theme-red']),

  translate: PropTypes.func.isRequired,
  requestStatus: PropTypes.string.isRequired,
  resetRequestStatus: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(FeedbackModal));
