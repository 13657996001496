export const WarningTriangleIcon = ({
  color = 'currentColor',
  ...props
}: { color?: string } & JSX.IntrinsicElements['svg']) => (
  <svg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.6762 12.444C18.3395 13.5746 17.9512 15.0234 16.8089 15.6799C16.4441 15.8896 16.0298 16 15.608 16H2.39165C1.07078 16 0 14.9401 0 13.6327C0 13.2151 0.111565 12.805 0.323378 12.444L6.93153 1.179C7.59478 0.0483394 9.05846 -0.336033 10.2007 0.320479C10.5603 0.527154 10.8593 0.823059 11.0681 1.179L17.6762 12.444ZM10.2055 1.68498C10.0848 1.4792 9.9115 1.30764 9.70244 1.18748C9.03502 0.803892 8.17907 1.02867 7.79407 1.68498L1.18592 12.9499C1.06405 13.1577 1 13.3931 1 13.6327C1 14.3855 1.62079 15 2.39165 15H15.608C15.855 15 16.0975 14.9354 16.3106 14.8129C16.972 14.4328 17.1952 13.6002 16.8137 12.9499L10.2055 1.68498ZM9 4C9.55228 4 10 4.44772 10 5V9C10 9.55228 9.55228 10 9 10C8.44771 10 8 9.55228 8 9V5C8 4.44772 8.44771 4 9 4ZM9 12C9.55228 12 10 12.4477 10 13C10 13.5523 9.55228 14 9 14C8.44771 14 8 13.5523 8 13C8 12.4477 8.44771 12 9 12Z'
      fill={color}
    />
  </svg>
);
