import { NO_PLATFORM, platformsById } from '@va/constants';
import { storageItems } from '@va/standalone/shared/constants';
import moment from 'moment';
import { LocalStorage } from '../storage';

export function getRefreshTokenExpiry() {
  return LocalStorage.getItem(storageItems.refreshTokenExpiry);
}

export function isRefreshTokenExpired() {
  return moment().unix() > Number(getRefreshTokenExpiry());
}

export const getPlatformById = (id: number) =>
  platformsById[id && id.toString()] !== undefined ? platformsById[id.toString()].code : NO_PLATFORM.code;
