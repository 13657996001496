import { isMobileDevice } from '@va/dashboard/selectors/ui';
import { AsyncStatus, useTrackingCodeVerifier } from '@va/dashboard/util-hooks';
import { Checkmark, LineGraphIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { getTrackingCode } from '@va/standalone/shared/actions';
import { Button, IconWithBackground, InfoSectionItem } from '@va/ui/design-system';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInstallTrackingCodeContext } from './useTrackingCodeInstallationView';

const VerifyTrackingCodeInstallation = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const { onSuccess } = useInstallTrackingCodeContext();
  const isMobile = useSelector(isMobileDevice);

  const { verify: checkTrackingCode, status } = useTrackingCodeVerifier(onSuccess);

  useEffect(() => {
    dispatch(getTrackingCode());
  }, [dispatch]);

  const handleCodeTrackerClick = useCallback(() => {
    checkTrackingCode();
  }, [checkTrackingCode]);

  const isLoading = useMemo(() => status === AsyncStatus.IN_PROGRESS, [status]);

  const icon = useMemo(() => {
    if (status === AsyncStatus.ERROR)
      return <IconWithBackground className='!bg-negative' icon={() => <LineGraphIcon color={'#fff'} />} />;
    if (status === AsyncStatus.SUCCESS)
      return <IconWithBackground className='!bg-positive' icon={() => <Checkmark color={'#fff'} />} />;
    return <IconWithBackground className='!bg-gray-charcoal' icon={() => <LineGraphIcon color={'#fff'} />} />;
  }, [status]);

  const title = useMemo(() => {
    if (status === AsyncStatus.ERROR) return translate('trackingCode.installation.installError');
    if (status === AsyncStatus.SUCCESS) return translate('trackingCode.installation.successInstalled');
    return translate('trackingCode.installation.unInstalled');
  }, [status, translate]);

  const actionButton = useMemo(() => {
    if (status === AsyncStatus.ERROR)
      return (
        <Button
          onClick={handleCodeTrackerClick}
          text={isMobile ? translate('button.retry') : translate('trackingCode.installation.retryVerification')}
          color='quinary'
        />
      );
    if (status === AsyncStatus.SUCCESS)
      return (
        <Button
          onClick={() => {
            onSuccess();
          }}
          text={translate('button.continue')}
          color='primary'
        />
      );
    return (
      <Button
        disabled={isLoading}
        onClick={handleCodeTrackerClick}
        text={translate('trackingCode.installation.verifyInstallation')}
        color={isMobile ? 'quinary' : 'tertiary'}
      />
    );
  }, [handleCodeTrackerClick, isLoading, isMobile, onSuccess, status, translate]);

  return (
    <InfoSectionItem
      label={translate('trackingCode.installation.trackingCodeStatus')}
      rightNode={actionButton}
      title={title}
      icon={icon}
      className='bg-white-snow sm-initial:bg-white rounded-18px'
    />
  );
};

export default VerifyTrackingCodeInstallation;
