import { makeAction } from '@va/store';
import * as Types from './types';

export const weeblyLogin = makeAction(Types.WEEBLY_LOGIN);
export const weeblyLoginSucceeded = makeAction(Types.WEEBLY_LOGIN_SUCCEEDED, 'data');
export const weeblyLoginFailed = makeAction(Types.WEEBLY_LOGIN_FAILED, 'error');

export const weeblySync = makeAction(Types.WEEBLY_SYNC);
export const weeblySyncSucceeded = makeAction(Types.WEEBLY_SYNC_SUCCEEDED, 'data');
export const weeblySyncFailed = makeAction(Types.WEEBLY_SYNC_FAILED, 'error');
