import { IconProps } from '.';

export const VideoSettingsIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5 1.5H3C2.17157 1.5 1.5 2.17157 1.5 3V9C1.5 9.82843 2.17157 10.5 3 10.5H10.5C11.3284 10.5 12 9.82843 12 9V6.71074V5.28926V3C12 2.17157 11.3284 1.5 10.5 1.5ZM10.5 12C12.0385 12 13.3066 10.8418 13.4798 9.34978L13.993 9.99131C15.3217 11.6521 18 10.7126 18 8.58574V3.41426C18 1.28738 15.3217 0.347883 13.993 2.00869L13.4798 2.65022C13.3066 1.15816 12.0385 0 10.5 0H3C1.34315 0 0 1.34315 0 3V9C0 10.6569 1.34315 12 3 12H10.5ZM13.6643 7.17927C13.558 7.04628 13.5 6.88105 13.5 6.71074V5.28926C13.5 5.11895 13.558 4.95372 13.6643 4.82074L15.1643 2.94574C15.6072 2.39213 16.5 2.7053 16.5 3.41426V8.58574C16.5 9.2947 15.6072 9.60787 15.1643 9.05427L13.6643 7.17927ZM7.59098 3.34835L7.23743 3.7019L7.17678 3.76256C7.01929 3.92005 6.75 3.80851 6.75 3.58578V3.5V3H6.25H5.75H5.25V3.5V3.58578C5.25 3.80851 4.98071 3.92005 4.82322 3.76256L4.76257 3.7019L4.40901 3.34835L4.05546 3.7019L3.70191 4.05546L3.34835 4.40901L3.7019 4.76256L3.76256 4.82322C3.92006 4.98071 3.80851 5.25 3.58579 5.25H3.5H3V5.75V6.25V6.75H3.5H3.58578C3.80851 6.75 3.92005 7.01928 3.76256 7.17678L3.7019 7.23744L3.34834 7.59099L3.7019 7.94454L4.05545 8.2981L4.409 8.65165L4.76256 8.2981L4.82322 8.23743C4.98071 8.07994 5.25 8.19148 5.25 8.41421V8.5V9H5.75H6.25H6.75V8.5V8.41421C6.75 8.19149 7.01929 8.07994 7.17678 8.23744L7.23744 8.2981L7.59099 8.65165L7.94455 8.2981L8.2981 7.94454L8.65165 7.59099L8.2981 7.23744L8.23744 7.17678C8.07995 7.01929 8.19149 6.75 8.41422 6.75H8.5H9V6.25V5.75V5.25H8.5H8.41421C8.19148 5.25 8.07994 4.98071 8.23743 4.82322L8.29809 4.76256L8.65164 4.40901L8.29809 4.05546L7.94454 3.7019L7.59098 3.34835ZM7.5 6C7.5 6.82843 6.82843 7.5 6 7.5C5.17157 7.5 4.5 6.82843 4.5 6C4.5 5.17157 5.17157 4.5 6 4.5C6.82843 4.5 7.5 5.17157 7.5 6Z'
        fill={color}
      />
    </svg>
  );
};
