import { PencilEditIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, LoadingLogo } from '@va/ui/design-system';
import classNames from 'classnames';
import React, { Fragment, PropsWithChildren, forwardRef } from 'react';

type DefaultSectionProps = {
  title: string;
  onButtonClick?: () => void;
  additionalClass?: string;
  isButtonDisabled?: boolean;
  sectionName?: string;
  inProgress?: boolean;
  leftSideNode?: React.ReactNode;
  id?: string;
};

export const DetailsSection = forwardRef<HTMLDivElement, PropsWithChildren<DefaultSectionProps>>(
  (
    { title, onButtonClick, children, additionalClass, isButtonDisabled, sectionName, inProgress, leftSideNode, id },
    ref,
  ) => {
    const translate = useTranslate();

    return (
      <div
        id={id}
        ref={ref}
        className={classNames('flex py-16 details-section flex-col md:flex-row relative', additionalClass)}
      >
        {inProgress ? (
          <div className='relative w-full h-28'>
            <LoadingLogo />
          </div>
        ) : (
          <Fragment>
            <div className='flex flex-column md:w-4/12 w-full'>
              <div className='font-semibold text-2xl md:text-3xl leading-36 tracking-tight text-mine-shaft mb-4 break-words'>
                {title}
              </div>
              {onButtonClick && (
                <div className='mb-8 md:mb-0'>
                  <Button
                    onClick={onButtonClick}
                    color='primary'
                    className='max-w-270px justify-center w-full'
                    text={translate('button.editInformation')}
                    icon={() => <PencilEditIcon color={isButtonDisabled ? '#969696' : 'white'} />}
                    disabled={isButtonDisabled}
                    tooltip={isButtonDisabled && translate(`account.${sectionName}.disabled.info`)}
                  />
                </div>
              )}
              {leftSideNode}
            </div>
            <div className='w-full md:w-8/12 flex-column md:pl-8'>{children}</div>
          </Fragment>
        )}
      </div>
    );
  },
);
