import { GRAY_ALTO } from '@va/constants';
import { getInitialUpgradePlanSliderValue } from '@va/standalone/shared/selectors';
import { SliderHandle } from '@va/svg-visa-icons';
import PropTypes from 'prop-types';
import Rcslider, { Handle } from 'rc-slider';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import './UpgradeSlider.scss';

const UpgradeSlider = (props) => {
  const sliderRef = useRef();
  const startRailLineRef = useRef();
  const endRailLineRef = useRef();
  const { uiSliderVal, activePlanIndex, plansCount, setSubscriptionPlan, showEndRailLine } = props;

  const externalPlanSliderValue = useSelector(getInitialUpgradePlanSliderValue);

  const initialSliderValue = useMemo(() => {
    if (externalPlanSliderValue) return externalPlanSliderValue;
    if (activePlanIndex < 0) return 1;
    if (activePlanIndex >= 0) return activePlanIndex + 1;
    if (uiSliderVal) return uiSliderVal;
    return 1;
  }, [activePlanIndex, externalPlanSliderValue, uiSliderVal]);

  const [val, setValue] = useState(initialSliderValue);

  useEffect(() => {
    setTimeout(() => {
      const sliderWidth = sliderRef && sliderRef.current && sliderRef.current.clientWidth;
      const oneStepWidth = sliderWidth / (plansCount - 1);

      if (startRailLineRef && startRailLineRef.current) {
        startRailLineRef.current.style.width = `${oneStepWidth}px`;
      }
      if (showEndRailLine && endRailLineRef && endRailLineRef.current) {
        endRailLineRef.current.style.width = `${oneStepWidth}px`;
      }
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let subscriptionPlanIndex;

    if (externalPlanSliderValue) {
      subscriptionPlanIndex = externalPlanSliderValue;
    } else {
      subscriptionPlanIndex = activePlanIndex < 0 ? 1 : activePlanIndex + 1;
    }

    setValue(subscriptionPlanIndex);
    setSubscriptionPlan(subscriptionPlanIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePlanIndex]);

  return (
    <div className={'custom-slider'}>
      <div className='flex'>
        <div ref={startRailLineRef} className='extra-rail-line border-b-2 border-solid border-green-persian' />
        <div className='w-full' ref={sliderRef}>
          <Rcslider
            handle={(handleProps) => {
              return (
                <Handle {...handleProps}>
                  <div className='absolute -top-2px -left-2px'>
                    <SliderHandle color={window.PRIMARY_COLOR} />
                  </div>
                </Handle>
              );
            }}
            className='simple-slider'
            onChange={(value) => {
              setValue(value);
              setSubscriptionPlan(value, value);
            }}
            min={1}
            max={plansCount}
            value={val}
            onAfterChange={(value) => {
              setValue(value);
              setSubscriptionPlan(value, value);
            }}
            dotStyle={{
              height: 15,
              width: 15,
              borderColor: GRAY_ALTO,
            }}
            railStyle={{
              height: 2,
              marginTop: -2,
              backgroundColor: GRAY_ALTO,
            }}
            dots
            trackStyle={{
              backgroundColor: window.PRIMARY_COLOR,
              marginTop: -2,
              height: 2,
            }}
            activeDotStyle={{
              borderColor: window.PRIMARY_COLOR,
              backgroundColor: window.PRIMARY_COLOR,
            }}
            handleStyle={{
              height: 42,
              width: 42,
              marginTop: -22,
              marginLeft: 0,
              position: 'relative',
              shadow: 'none',
              borderColor: window.PRIMARY_COLOR,
            }}
          />
        </div>
        {showEndRailLine && (
          <div ref={endRailLineRef} className='extra-rail-line border-b-2 border-solid border-gray-gallery' />
        )}
      </div>
    </div>
  );
};

UpgradeSlider.defaultProps = {
  showEndRailLine: true,
};

UpgradeSlider.propTypes = {
  setSubscriptionPlan: PropTypes.func.isRequired,
  uiSliderVal: PropTypes.number.isRequired,
  activePlanIndex: PropTypes.number.isRequired,
  plansCount: PropTypes.number.isRequired,
  previousModalView: PropTypes.string,
  showEndRailLine: PropTypes.bool,
};

export default UpgradeSlider;
