import { TooltipWrapper } from '@va/ui/tooltips';
import { useCheckOverflow } from '@va/util/hooks';
import { ReactNode, useRef } from 'react';

type DialogBoxProps = {
  title: string;
  children: ReactNode;
  handleClose?: () => void;
  footerTitle?: string;
};

export const DialogBox = (props: DialogBoxProps) => {
  const { title, children, footerTitle, handleClose } = props;
  const subTitleRef = useRef(null);
  const isOverflow = useCheckOverflow(subTitleRef);

  return (
    <div className='popup-box w-full rounded-18'>
      <div className={'w-full rounded-18 p-4 pt-6 md:p-6 flex justify-between items-center'}>
        <TooltipWrapper disabled={!isOverflow} content={title}>
          <h1
            ref={subTitleRef}
            className='font-semibold leading-24 md:leading-9 text-left text-21 md:text-30 tracking-[-0.03em] md:tracking-wideTight w-4/5 truncate'
          >
            {title}
          </h1>
        </TooltipWrapper>
      </div>
      <div className={'bg-gradient-to-r from-gray-gallery-darker h-[1.5px] '} />
      <div className={'w-full p-6 '}>{children}</div>
      {footerTitle && (
        <div className={'w-full rounded-18 p-4 md:p-6 '}>
          <div
            className='w-full font-medium leading-6 tracking-tightestDefault text-15 flex justify-center items-center p-3 bg-white-snow rounded-12 cursor-pointer'
            onClick={handleClose}
          >
            {footerTitle}
          </div>
        </div>
      )}
    </div>
  );
};
