import React from 'react';

export const PlayIconSmall: React.FC<{ color?: string; className?: string }> = ({ color = '#999999', className }) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='9' cy='9' r='8' stroke={color} strokeWidth='1.5' />
      <path d='M13 9L7 12.4641L7 5.5359L13 9Z' fill={color} />
    </svg>
  );
};
