export enum paragraphSizes {
  extraTiny = 'extraTiny',
  tiny = 'tiny',
  normal = 'normal',
  medium = 'medium',
  large = 'large',
}

let sizeToClassMap = {
  [paragraphSizes.extraTiny]: 'text-xxs leading-15px',
  [paragraphSizes.tiny]: 'text-xs leading-18',
  [paragraphSizes.normal]: 'text-15 leading-24 -tracking-0012',
  [paragraphSizes.medium]: 'text-base leading-18 tracking-0012',
  [paragraphSizes.large]: 'text-18 leading-24 tracking',
};

export const paragraphSizeClassNames = (size: paragraphSizes) => sizeToClassMap[size];

export const setParagraphSizes = (sizes: { [key in paragraphSizes]?: string }) => {
  sizeToClassMap = { ...sizeToClassMap, ...sizes };
};
