import { getBusinessSectorOptions } from '@va/util/helpers';
import { useTranslate } from '@va/localization';
import { useCallback, useMemo } from 'react';

export const useBusinessSectors = () => {
  const translate = useTranslate();
  const businessSectorOptions = useMemo(() => getBusinessSectorOptions(translate), [translate]);

  const getSelectedBusinessSector = useCallback(
    (businessSectorId: number | string) => {
      return businessSectorOptions.find((option) => Number(option.value) === Number(businessSectorId));
    },
    [businessSectorOptions],
  );

  return { businessSectorOptions, getSelectedBusinessSector };
};
