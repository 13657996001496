import { PropsWithChildren, createContext, useCallback, useContext } from 'react';

type TableContext = { getPreviousValue: (index: number, key: string) => number };

const TableContext = createContext<TableContext>({ getPreviousValue: () => 0 });

export const TableProvider = ({
  children,
  previousData,
}: PropsWithChildren<{ previousData: Record<string, any>[] }>) => {
  const getPreviousValue = useCallback(
    (index: number, key: string) => {
      if (!previousData) return 0;
      if (previousData[index]) return previousData[index][key];
      return 0;
    },
    [previousData],
  );

  return <TableContext.Provider value={{ getPreviousValue }}>{children}</TableContext.Provider>;
};

export const useTableContext = () => useContext(TableContext);
