import { FEATURE_TOUR_IDS, pathName } from '@va/constants';
import { ArrowRightIcon, GlobeIcon, NavAddCircle, SettingsIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { isUserLoggedInWithCustomUrl } from '@va/standalone/shared/helpers';
import { useWebsiteSelectorInfiniteScroll } from '@va/standalone/shared/hooks';
import { isAgencyOwner } from '@va/standalone/shared/selectors';
import { WebsitesQueryFilter } from '@va/types/website';
import {
  Button,
  GrayGradientDivider,
  IconWithBackground,
  Paragraph,
  ParagraphWithTooltip,
  RadioInputButton,
  SearchField,
  fontWeights,
} from '@va/ui/design-system';
import { getUrlWithoutProtocolAndTrailingSlash } from '@va/util/helpers';
import classNames from 'classnames';
import React, { forwardRef, useEffect, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

type WebsiteSelectorDropdownProps = {
  className?: string;
  closeDropdown: () => void;
  initializeTotalWebsites: (total: number) => void;
  onWebsiteSelect: (websiteId: string, websiteName: string) => void;
  activeWebsiteId: string;
};

const websitesFilterQuery: WebsitesQueryFilter = {
  start: 0,
  length: 10,
  url: '',
  withSharedWebsites: true,
};

const WebsiteSelectorDropdown: React.FC<WebsiteSelectorDropdownProps> = ({
  className,
  closeDropdown,
  initializeTotalWebsites,
  onWebsiteSelect,
  activeWebsiteId,
}) => {
  const translate = useTranslate();
  const history = useHistory();
  const isCustomDomain = isUserLoggedInWithCustomUrl();
  const agencyOwner = useSelector(isAgencyOwner);

  const { websites, lastElementRef, onSearch, searchValue, clearSearchField, isValidating, totalWebsites } =
    useWebsiteSelectorInfiniteScroll(websitesFilterQuery);

  const canAddWebsite = useMemo(() => {
    if (!isCustomDomain) return true;
    if (isCustomDomain && agencyOwner) return true;
    return false;
  }, [agencyOwner, isCustomDomain]);

  useEffect(() => {
    initializeTotalWebsites(totalWebsites);
  }, [initializeTotalWebsites, totalWebsites]);

  return (
    <div className={classNames('text-gray-charcoal p-2 rounded-18 bg-white border-2 border-gray-concrete', className)}>
      <div className='bg-white pb-2'>
        <SearchField className='h-10' value={searchValue} onChange={onSearch} clearField={clearSearchField} />
      </div>
      <ul className='flex flex-col gap-2 h-36 overflow-y-auto scrollbar scrollbar-thumb'>
        {websites.length === 0 && !isValidating && (
          <div className='w-full h-full flex items-center justify-center'>
            <Paragraph>{translate('labels.noWebsites')}</Paragraph>
          </div>
        )}
        {websites.map((website, index) => {
          const url = getUrlWithoutProtocolAndTrailingSlash(website.url);

          const commonProps = {
            onWebsiteSelect: onWebsiteSelect,
            closeDropdown: closeDropdown,
            websiteId: website.id,
            url: url,
            activeWebsiteId: activeWebsiteId,
          };

          if (index === websites.length - 1)
            return <WebsiteListItem key={website.id} ref={lastElementRef} {...commonProps} />;
          return <WebsiteListItem key={website.id} {...commonProps} />;
        })}
        {isValidating && <Skeleton count={4} className='rounded-15 h-12 mb-1' />}
      </ul>
      <GrayGradientDivider className='my-2 -translate-x-2' />
      <div className='space-y-2'>
        {canAddWebsite && (
          <Button
            dataTourId={FEATURE_TOUR_IDS.addWebsiteBtn}
            color='tertiary'
            className='w-full'
            onClick={() => {
              closeDropdown();
              history.push('/my-account/add-website');
            }}
            icon={() => <NavAddCircle />}
            text={translate('button.addNewWebsite')}
            rightSideIcon={<ArrowRightIcon />}
          />
        )}
        <Button
          dataTourId={FEATURE_TOUR_IDS.manageWebsitesBtn}
          color='tertiary'
          className='w-full'
          onClick={() => {
            closeDropdown();
            history.push(pathName.manageWebsites);
          }}
          icon={() => <SettingsIcon className='w-18 h-18' color='black' />}
          text={translate('button.manageWebsites')}
          rightSideIcon={<ArrowRightIcon />}
        />
      </div>
    </div>
  );
};

export default WebsiteSelectorDropdown;

type WebsiteListItemProps = {
  url: string;
  websiteId: string;
  closeDropdown: () => void;
  onWebsiteSelect: (websiteId: string, websiteName: string) => void;
  activeWebsiteId: string;
};

const WebsiteListItem = forwardRef<HTMLLIElement, WebsiteListItemProps>(
  ({ url, websiteId, closeDropdown, onWebsiteSelect, activeWebsiteId }, ref) => {
    const isActive = websiteId === activeWebsiteId;

    return (
      <li
        onClick={() => {
          closeDropdown();
          onWebsiteSelect(websiteId, url);
        }}
        className='flex h-12 items-center gap-3 hover:bg-white-snow group px-2 rounded-15 cursor-pointer'
        ref={ref}
      >
        <IconWithBackground
          className='!bg-white-snow shrink-0 w-9 h-9 group-hover:!bg-white'
          icon={() => <GlobeIcon color='var(--color-primary)' className='w-5 h-5' />}
        />
        <ParagraphWithTooltip
          tooltipProps={{
            tooltipClassNames: 'p-1',
          }}
          colorClassName='text-primary'
          weight={fontWeights.medium}
          className='whitespace-nowrap'
        >
          {url}
        </ParagraphWithTooltip>
        <RadioInputButton className='ml-auto shrink-0' selected={isActive} />
      </li>
    );
  },
);
