import { useTranslate } from '@va/localization';
import { ConfirmationDialogType, ConfirmationModal } from '@va/shared/util-confirmation';
import { fontWeights, Paragraph, paragraphSizes } from '@va/ui/design-system';

const UpdateCustomDomainPrefixModal: ConfirmationDialogType = ({ onClose, onConfirm }) => {
  const translate = useTranslate();

  const infoItems = [1, 2, 3, 4, 5];

  return (
    <ConfirmationModal
      title={translate('modal.confirmation.updateCustomDomainPrefix.title')}
      info={
        <>
          <Paragraph weight={fontWeights.medium} size={paragraphSizes.large} className='mb-2'>
            {translate('modal.confirmation.updateCustomDomainPrefix.subTitle')}
          </Paragraph>
          {infoItems.map((item) => (
            <Paragraph key={item} weight={fontWeights.medium}>
              {translate(`modal.confirmation.updateCustomDomainPrefix.info${item}`)}
            </Paragraph>
          ))}
        </>
      }
      onClose={onClose}
      isOpen
      onConfirmation={() => onConfirm()}
      actionButtonText={translate('button.confirm')}
      className='!max-w-800px'
    />
  );
};

export default UpdateCustomDomainPrefixModal;
