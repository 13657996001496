import { IconProps } from '.';

export const PencilEditIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 14.1213V16.5H3.87868L14.159 6.21967L11.7803 3.84099L1.5 14.1213ZM16.0607 4.31802L15.2197 5.15901L12.841 2.78033L13.682 1.93934C13.9633 1.65804 14.3448 1.5 14.7426 1.5H15C15.8284 1.5 16.5 2.17157 16.5 3V3.25736C16.5 3.65518 16.342 4.03672 16.0607 4.31802ZM4.5 18L17.1213 5.37868C17.6839 4.81607 18 4.05301 18 3.25736V3C18 1.34315 16.6569 0 15 0H14.7426C13.947 0 13.1839 0.316071 12.6213 0.87868L0 13.5V16.5V18H1.5H4.5ZM17.25 18H7.5L9 16.5H17.25C17.6642 16.5 18 16.8358 18 17.25C18 17.6642 17.6642 18 17.25 18Z'
        fill={color ?? 'black'}
      />
    </svg>
  );
};


