import { Component } from 'react';

import { modalName } from '@va/standalone/shared/constants';
import ManagePaymentMethod from '../components/ManagePaymentMethod';

export default class AddCreditCard extends Component {
  render() {
    return <ManagePaymentMethod modalName={modalName.ADD_CREDIT_CARD} />;
  }
}
