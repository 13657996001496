import { useVisitorsHistory, VisitSession } from '@va/dashboard/api-client/Visitors';
import { getSelectedVisitorId } from '@va/dashboard/selectors/ui';
import { createContext, forwardRef, PropsWithChildren, useContext, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

type VisitorHistoryContextType = {
  isLoading: boolean;
  data: VisitSession[] | undefined;
};

const VisitorHistoryContext = createContext({} as VisitorHistoryContextType);

export const VisitorHistoryContextProvider = forwardRef<
  { refreshVisitorsHistoryData: () => void },
  PropsWithChildren<{}>
>(({ children }, ref) => {
  const { sessionKey } = useParams<{ sessionKey: string }>();
  const selectedVisitorId = useSelector(getSelectedVisitorId) || sessionKey;
  const { isLoading, data, mutate } = useVisitorsHistory(selectedVisitorId);

  useImperativeHandle(ref, () => ({
    refreshVisitorsHistoryData: mutate,
  }));

  return <VisitorHistoryContext.Provider value={{ isLoading, data }}>{children}</VisitorHistoryContext.Provider>;
});

const useVisitorHistoryContext = () => useContext(VisitorHistoryContext);

export default useVisitorHistoryContext;
