import { useTrafficChannelValues } from '@va/dashboard/api-client/traffic-channels';
import { useTranslate } from '@va/localization';
import { MultiSelectFilter } from '@va/shared/feature-filters';
import { useMemo } from 'react';

export const useNewReferrerFilter = ({ name }: { name: string }) => {
  const translate = useTranslate();

  const filter = useMemo(
    () =>
      new MultiSelectFilter({
        name: name,
        id: name,
        label: translate('filter.referrerUrl'),
        inputProps: { useOptions: () => useTrafficChannelValues('unknown'), showSearchInput: true },
      }),
    [name, translate],
  );

  return filter;
};
