import { useTranslate } from '@va/localization';
import { BlinkingCircle } from '@va/ui/design-system';
import classNames from 'classnames';

type TabNewMarkerProps = {
  isNew?: boolean;
  isExpanded?: boolean;
  isMainMenu?: boolean;
  isBeta?: boolean;
};

const TabNewMarker = (props: TabNewMarkerProps) => {
  const translate = useTranslate();
  const { isNew, isExpanded, isMainMenu, isBeta } = props;

  if (!isNew && !isBeta) return null;
  if (!isExpanded) return null;

  if (isMainMenu) {
    return (
      <div className={classNames(`flex flex-row flex-nowrap items-center gap-1  bg-[#FEEADE] p-1 rounded-md mr-2`)}>
        <BlinkingCircle color='#ff9933' />
        <span className='text-10.5 leading-tight text-orange-pure font-semibold'>
          {isNew && translate('leftNav.new')}
          {isBeta && translate('leftNav.beta')}
        </span>
      </div>
    );
  }

  return (
    <div className='pr-2'>
      <BlinkingCircle color='#ff9933' />
    </div>
  );
};

export default TabNewMarker;
