import { SupportButtons } from '@va/dashboard/components';
import { isTrackingCodeInstalled as isTrackingCodeInstalledSelector } from '@va/dashboard/selectors/app';
import { CollectingDataIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, Heading1, Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const TrackingCodeInstalationResultView = ({
  onClickRetryInstallation,
  onOpenDashboardClick,
}: {
  onClickRetryInstallation: () => void;
  onOpenDashboardClick?: () => void;
}) => {
  const isTrackingCodeInstalled = useSelector(isTrackingCodeInstalledSelector);
  const translate = useTranslate();
  const history = useHistory();

  const title = useMemo(() => {
    if (isTrackingCodeInstalled) return translate('onboarding.trackingCode.installationComplete');
    return translate('onboarding.trackingCode.installationIncomplete');
  }, [isTrackingCodeInstalled, translate]);

  const statusText = useMemo(() => {
    if (isTrackingCodeInstalled) return translate('onboarding.trackingCode.status.collectingData');
    return translate('onboarding.trackingCode.status.notCollectingData');
  }, [isTrackingCodeInstalled, translate]);

  const infoText = useMemo(() => {
    if (isTrackingCodeInstalled) return translate('onboarding.trackingCode.success.note');
    return translate('onaboarding.trackingCode.error.note');
  }, [isTrackingCodeInstalled, translate]);

  const openDashboard = useCallback(() => {
    history.push('/');
    onOpenDashboardClick?.();
  }, [history, onOpenDashboardClick]);

  return (
    <div className='flex justify-center'>
      <div className='text-center max-w-[540px] mt-[21px]'>
        <Heading1 weight={fontWeights.semibold}>{title}</Heading1>
        <div className='flex items-center justify-center gap-2 my-3'>
          <CollectingDataIcon
            className={classNames({
              'animate-[spin_3s_linear_infinite]': isTrackingCodeInstalled,
            })}
            color={isTrackingCodeInstalled ? 'var(--color-primary)' : 'var(--color-negative)'}
          />
          <Paragraph
            size={paragraphSizes.large}
            colorClassName={classNames({
              'text-primary': isTrackingCodeInstalled,
              'text-negative': !isTrackingCodeInstalled,
            })}
            weight={fontWeights.medium}
          >
            {statusText}
          </Paragraph>
        </div>
        <Paragraph colorClassName='text-gray-devil' weight={fontWeights.medium}>
          {infoText}
        </Paragraph>
        <Button
          onClick={isTrackingCodeInstalled ? openDashboard : onClickRetryInstallation}
          className='mt-18px mb-3 w-full'
          text={
            isTrackingCodeInstalled
              ? translate('onboarding.trackingCode.openDashboard')
              : translate('onboarding.trackingCode.retryTrackingCodeInstallation')
          }
        />
        {!isTrackingCodeInstalled && (
          <Button
            onClick={openDashboard}
            className='w-full'
            color='tertiary'
            text={translate('onboarding.trackingCode.installLater')}
          />
        )}
        <Paragraph className='mb-3 mt-12'>{translate('onboarding.trackingCode.message.support')}</Paragraph>
        <SupportButtons />
      </div>
    </div>
  );
};

export default TrackingCodeInstalationResultView;
