import { IconProps } from './index';

export const SlidersIcon = ({ className = '', color }: IconProps) => {
  return (
    <svg
      className={className}
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_11485_144821'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='12'
        height='12'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.25 0.75C5.25 0.335787 5.58579 0 6 0C6.41421 0 6.75 0.335786 6.75 0.75V3C7.16421 3 7.5 3.33579 7.5 3.75C7.5 4.16421 7.16421 4.5 6.75 4.5H6H5.25C4.83579 4.5 4.5 4.16421 4.5 3.75C4.5 3.33579 4.83579 3 5.25 3V0.75ZM5.25 6.75C5.25 6.33579 5.58579 6 6 6C6.41421 6 6.75 6.33579 6.75 6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75ZM1.5 6C1.08579 6 0.75 6.33579 0.75 6.75C0.75 7.16421 1.08579 7.5 1.5 7.5V9.75C1.5 10.1642 1.83579 10.5 2.25 10.5C2.66421 10.5 3 10.1642 3 9.75V7.5C3.41421 7.5 3.75 7.16421 3.75 6.75C3.75 6.33579 3.41421 6 3 6H2.25H1.5ZM2.25 1.5C1.83579 1.5 1.5 1.83579 1.5 2.25V3.75C1.5 4.16421 1.83579 4.5 2.25 4.5C2.66421 4.5 3 4.16421 3 3.75V2.25C3 1.83579 2.66421 1.5 2.25 1.5ZM8.25 6.75C8.25 6.33579 8.58579 6 9 6H9.75H10.5C10.9142 6 11.25 6.33579 11.25 6.75C11.25 7.16421 10.9142 7.5 10.5 7.5V9.75C10.5 10.1642 10.1642 10.5 9.75 10.5C9.33579 10.5 9 10.1642 9 9.75V7.5C8.58579 7.5 8.25 7.16421 8.25 6.75ZM9.75 1.5C9.33579 1.5 9 1.83579 9 2.25V3.75C9 4.16421 9.33579 4.5 9.75 4.5C10.1642 4.5 10.5 4.16421 10.5 3.75V2.25C10.5 1.83579 10.1642 1.5 9.75 1.5Z'
          fill='black'
        />
      </mask>
      <g mask='url(#mask0_11485_144821)'>
        <rect x='-3' y='-3' width='18' height='18' fill={color ?? 'var(--color-primary)'} />
      </g>
    </svg>
  );
};
