import { useCallback, useState } from 'react';

export const useCopyToClipboard = (timeout = 1000) => {
  const [success, setSuccess] = useState(false);

  const onClickCopy = useCallback(
    (text: string) => {
      navigator.clipboard.writeText(text);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, timeout);
    },
    [timeout],
  );

  return { onClickCopy, success };
};
