export const RadioButtonIcon = ({
  className,
  selected = false,
}: {
  selected: boolean;
  className?: string;
}) => {
  const outerCircleColor = selected ? 'var(--color-primary)' : '#969696';
  const innerCircleColor = selected ? 'var(--color-primary)' : '#FFFFFF';
  return (
    <svg
      className={className}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6"
        cy="6"
        r="5.25"
        stroke={outerCircleColor}
        strokeWidth="1.5"
      />
      <circle cx="6" cy="6" r="2.5" fill={innerCircleColor} />
    </svg>
  );
};


