export function FeedbackSuccess(props) {
  return (
    <svg
      width='35'
      height='35'
      viewBox='0 0 57 47'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <defs>
        <polygon id='path-1' points='27.981 18.0908 27.981 0.53 0.7739 0.53 0.7739 18.0908 27.981 18.0908' />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <g transform='translate(-661 -434)'>
          <g transform='translate(661 433)'>
            <path
              fill='#13232A'
              d='M23.9199,31.7802 L23.5049,31.7802 L23.2119,32.0732 L21.5049,33.7802 L19.9199,33.7802 L19.9199,20.7802 L16.9199,20.7802 L16.9199,14.7802 L19.2699,14.7802 L23.0059,23.1862 L23.2699,23.7802 L23.9199,23.7802 L25.9199,23.7802 L25.9199,31.7802 L23.9199,31.7802 Z M11.9199,33.7802 L11.9199,21.7802 L15.9199,21.7802 L16.9199,21.7802 L18.9199,21.7802 L18.9199,33.7802 L11.9199,33.7802 Z M33.9199,21.7802 L25.9199,21.7802 L25.9199,22.7802 L23.9199,22.7802 L19.9199,13.7802 L15.9199,13.7802 L15.9199,20.7802 L10.9199,20.7802 L10.9199,34.7802 L19.9199,34.7802 L21.9199,34.7802 L23.9199,32.7802 L25.9199,32.7802 L25.9199,35.7802 L33.9199,35.7802 L33.9199,34.7802 L26.9199,34.7802 L26.9199,32.7802 L26.9199,22.7802 L33.9199,22.7802 L33.9199,21.7802 Z'
            />
            <g transform='translate(0 .47)'>
              <path
                fill='#13232A'
                d='M29.1699,32.3105 C29.1699,32.5855 28.6699,32.5855 28.6699,32.3105 C28.6699,32.1725 28.7819,32.0605 28.9199,32.0605 C29.0579,32.0605 29.1699,32.1725 29.1699,32.3105 M28.1699,32.3105 C28.1699,32.7235 28.5059,33.0605 28.9199,33.0605 C29.3339,33.0605 29.6699,32.7235 29.6699,32.3105 C29.6699,31.8975 29.3339,31.5605 28.9199,31.5605 C28.5059,31.5605 28.1699,31.8975 28.1699,32.3105'
              />
              <path
                fill='#13232A'
                d='M21.689,47 C9.73,47 0,37.271 0,25.311 C0,13.352 9.73,3.622 21.689,3.622 C23.572,3.622 25.487,3.885 27.382,4.402 L27.382,5.719 C25.531,5.179 23.617,4.905 21.689,4.905 C10.438,4.905 1.284,14.059 1.284,25.311 C1.284,36.562 10.438,45.716 21.689,45.716 C32.941,45.716 42.095,36.562 42.095,25.311 C42.095,23.98 41.96,22.634 41.692,21.302 L42.998,21.302 C43.25,22.643 43.378,23.99 43.378,25.311 C43.378,37.271 33.648,47 21.689,47'
              />
              <g transform='translate(29)'>
                <mask id='mask-2' fill='#fff'>
                  <use xlinkHref='#path-1' />
                </mask>
                <polygon
                  fill='#009688'
                  mask='url(mask-2)'
                  points='11.1279 18.0908 0.7739 7.7368 1.4819 7.0298 11.1279 16.6768 27.2739 0.5298 27.9819 1.2368'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function FeedbackFail(props) {
  return (
    <svg
      width='35'
      height='35'
      viewBox='-4 0 57 42'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <defs>
        <polygon id='path-1' points='0 22.31075 0 44 43.378 44 43.378 0.6215 0 0.6215 0 22.31075' />
        <polygon id='path-3' points='17.841 18.1489 17.841 0.589 0.9878 0.589 0.9878 18.1489 17.841 18.1489' />
        <polygon id='path-5' points='17.2681 0.589 0.4141 0.589 0.4141 18.1489 17.2681 18.1489 17.2681 0.589' />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <g transform='translate(-661 -436)'>
          <g transform='translate(661 435)'>
            <path
              fill='#13232A'
              d='M23.9199,29.7215 L23.5049,29.7215 L23.2119,30.0145 L21.5049,31.7215 L19.9199,31.7215 L19.9199,18.7215 L16.9199,18.7215 L16.9199,12.7215 L19.2699,12.7215 L23.0059,21.1275 L23.2699,21.7215 L23.9199,21.7215 L25.9199,21.7215 L25.9199,29.7215 L23.9199,29.7215 Z M11.9199,31.7215 L11.9199,19.7215 L15.9199,19.7215 L16.9199,19.7215 L18.9199,19.7215 L18.9199,31.7215 L11.9199,31.7215 Z M33.9199,19.7225 L25.9199,19.7225 L25.9199,20.7215 L23.9199,20.7215 L19.9199,11.7215 L15.9199,11.7215 L15.9199,18.7215 L10.9199,18.7215 L10.9199,32.7215 L19.9199,32.7215 L21.9199,32.7215 L23.9199,30.7215 L25.9199,30.7215 L25.9199,33.7225 L33.9199,33.7225 L33.9199,32.7225 L26.9199,32.7225 L26.9199,30.7215 L26.9199,20.7225 L33.9199,20.7225 L33.9199,19.7225 Z'
            />
            <g transform='translate(0 .411)'>
              <path
                d='M29.1699,30.311 C29.1699,30.586 28.6699,30.586 28.6699,30.311 C28.6699,30.172 28.7819,30.061 28.9199,30.061 C29.0579,30.061 29.1699,30.172 29.1699,30.311 M28.1699,30.311 C28.1699,30.723 28.5059,31.061 28.9199,31.061 C29.3339,31.061 29.6699,30.723 29.6699,30.311 C29.6699,29.897 29.3339,29.561 28.9199,29.561 C28.5059,29.561 28.1699,29.897 28.1699,30.311'
                fill='#13232A'
              />
              <g transform='translate(0 1)'>
                <mask id='mask-2' fill='#fff'>
                  <use xlinkHref='#path-1' />
                </mask>
                <path
                  fill='#13232A'
                  mask='url(mask-2)'
                  d='M21.689,44.0005 C9.73,44.0005 0,34.2705 0,22.3115 C0,10.3525 9.73,0.6215 21.689,0.6215 C23.572,0.6215 25.487,0.8855 27.382,1.4025 L27.382,2.7195 C25.531,2.1785 23.617,1.9045 21.689,1.9045 C10.438,1.9045 1.284,11.0595 1.284,22.3115 C1.284,33.5625 10.438,42.7155 21.689,42.7155 C32.941,42.7155 42.095,33.5625 42.095,22.3115 C42.095,20.9805 41.96,19.6335 41.692,18.3015 L42.998,18.3015 C43.25,19.6435 43.378,20.9905 43.378,22.3115 C43.378,34.2705 33.648,44.0005 21.689,44.0005'
                />
              </g>
              <g transform='translate(31)'>
                <mask id='mask-4' fill='#fff'>
                  <use xlinkHref='#path-3' />
                </mask>
                <polygon
                  fill='#FF5252'
                  mask='url(mask-4)'
                  points='0.9878 18.1489 0.9878 16.7349 17.1338 0.5879 17.8418 1.2949'
                />
              </g>
              <g transform='translate(31)'>
                <mask id='mask-6' fill='#fff'>
                  <use xlinkHref='#path-5' />
                </mask>
                <polygon
                  fill='#FF5252'
                  mask='url(mask-6)'
                  points='17.2681 18.1489 17.2681 16.7349 1.1221 0.5879 0.4141 1.2949'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
