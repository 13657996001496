import { DateComparisonPeriod } from '@va/ui/components/report-block';
import { Dispatch, PropsWithChildren, SetStateAction, createContext, useCallback, useContext, useState } from 'react';

type ContextType = {
  expandedCard: string;
  changeExpandedCard: (id: string) => void;
  showPrevious: boolean;
  toggleShowPrevious: () => void;
  activeComparisonOption: DateComparisonPeriod;
  setActiveComparisonOption: Dispatch<SetStateAction<DateComparisonPeriod>>;
};

const VisitorsCardsContext = createContext<ContextType>({} as ContextType);

export const VisitorsCardsContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [expandedCard, setExpandedCard] = useState<string>('');
  const [showPrevious, setShowPrevious] = useState(false);
  const [activeComparisonOption, setActiveComparisonOption] = useState(DateComparisonPeriod.PREVIOUS);

  const changeExpandedCard = useCallback((id: string) => {
    setExpandedCard(id);
  }, []);

  const toggleShowPrevious = useCallback(() => {
    setShowPrevious((prev) => !prev);
  }, []);

  return (
    <VisitorsCardsContext.Provider
      value={{
        expandedCard,
        changeExpandedCard,
        toggleShowPrevious,
        showPrevious,
        activeComparisonOption,
        setActiveComparisonOption,
      }}
    >
      {children}
    </VisitorsCardsContext.Provider>
  );
};

export const useVisitorsCardsContext = () => {
  return useContext(VisitorsCardsContext);
};

export default useVisitorsCardsContext;
