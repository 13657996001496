import { UNKNOWN_KEY } from '@va/constants';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { getGlobalFilter } from '@va/dashboard/selectors/ui';
import { useTranslate } from '@va/localization';
import { SelectDropdownOption } from '@va/ui/design-system';
import { getCountryOptions, toQueryString } from '@va/util/helpers';
import { useLazyDataFetch } from '@va/util/hooks';
import { sumBy } from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const noOfItemsToShow = {
  DEFAULT: 12,
  EXTENDED: 20,
};

type LocationVisitors = { count: number; countryCode: string };
type LocationVisitorsResponse = { sortedData: VisitorByCountry[]; totalCount: number; maxCount: number };

export type VisitorByCity = LocationVisitors & { name: string };
export type VisitorByCountry = LocationVisitors;

type VisitorsByCountryResponse = LocationVisitorsResponse;
type VisitorsByCityResponse = LocationVisitorsResponse & { filteredCountryCodes: SelectDropdownOption<string>[] };

const validateCountryCode = <T extends LocationVisitors>(data: T[]): T[] => {
  return data.map((visitor) => ({
    ...visitor,
    countryCode: !visitor.countryCode.trim() ? UNKNOWN_KEY : visitor.countryCode,
  }));
};

export const useVisitorsByCountry = () => {
  const websiteId = useSelector(getInstanceId);
  const queryData = useSelector(getGlobalFilter);

  const mapper = useCallback((response: VisitorByCountry[]) => {
    const validData = validateCountryCode(response);
    const sortedData = validData.sort((a, b) => b.count - a.count);
    const totalCount = sumBy(validData, (visitor) => visitor.count);
    const maxCount = sortedData[0].count;

    return {
      sortedData,
      totalCount,
      maxCount,
    };
  }, []);

  return useLazyDataFetch<VisitorsByCountryResponse>(
    `/websites/${websiteId}/visitors/by-country?${toQueryString(queryData)}`,
    undefined,
    mapper,
  );
};

export const useVisitorsByCity = () => {
  const websiteId = useSelector(getInstanceId);
  const queryData = useSelector(getGlobalFilter);
  const translate = useTranslate();

  const mapper = useCallback(
    (response: VisitorByCity[]) => {
      const validData = validateCountryCode(response);
      const sortedData = validData.sort((a, b) => b.count - a.count);
      const totalCount = sumBy(validData, (visitor) => visitor.count);
      const maxCount = sortedData[0].count;

      const countryCodesResponse = validData.map((visit) => visit.countryCode);
      const countries = getCountryOptions(translate);
      const filteredCountryCodes = countries.filter((c) => countryCodesResponse.indexOf(c.value) >= 0 && c.label);

      return { sortedData, totalCount, filteredCountryCodes, maxCount };
    },
    [translate],
  );

  return useLazyDataFetch<VisitorsByCityResponse>(
    `/websites/${websiteId}/visitors/by-city?${toQueryString(queryData)}`,
    undefined,
    mapper,
  );
};
