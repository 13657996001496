import { pathName } from '@va/constants';
import { CogIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { useHistory } from 'react-router';
import { UserMenuButton } from './UserMenuButton';

export function AccountSettingsButton({ onClose }: { onClose: () => void }) {
  const history = useHistory();
  const translate = useTranslate();

  return (
    <UserMenuButton
      leftIcon={<CogIcon />}
      text={translate('menu.myAccount')}
      onClick={() => {
        history.push(pathName.accountSettings);
        onClose();
      }}
    />
  );
}
