import classNames from 'classnames';
import { IconProps } from '.';

export const SquareIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='1.25' y='1.25' width='13.5' height='13.5' rx='3' stroke={color} strokeWidth='1.5' />
    </svg>
  );
};
