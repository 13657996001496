import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class BaseChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: this.props.width ? this.props.width : 200,
      previousWidth: this.props.width ? this.props.width : 200,
    };
    this.containerRef = React.createRef();
    this.updateWidth = this.updateWidth.bind(this);
  }

  componentDidMount() {
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
    this.props.createChart(this.state.width);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth);
  }

  componentDidUpdate(prevProps, prevState) {
    const recreateChart =
      !isEqual(this.props.data, prevProps.data) ||
      Math.abs(this.state.width - this.state.previousWidth) > 25 ||
      (this.state.width >= 800 && prevState.width < 800) ||
      (this.state.width < 800 && prevState.width >= 800);

    if (recreateChart) {
      this.setState({ previousWidth: this.state.width });
      this.removeChart();
      this.props.createChart(this.state.width);
      this.callPostUpdateCallback();
    }
  }

  callPostUpdateCallback() {
    if (this.props.postUpdate) {
      this.props.postUpdate();
    }
  }

  updateWidth() {
    if (this.containerRef && this.containerRef.current) {
      this.setState({ width: this.containerRef.current.clientWidth });
    }
  }

  removeChart() {
    let domNode = document.querySelector('#' + this.props.id);
    if (domNode && domNode.firstChild) {
      domNode.innerHTML = '';
    }
  }

  render() {
    return (
      <div className={this.props.className} ref={this.containerRef}>
        <div id={this.props.id} />
      </div>
    );
  }
}

BaseChart.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  additionalStyle: PropTypes.string,
  createChart: PropTypes.func.isRequired,
  data: PropTypes.any,
  width: PropTypes.number,
  postUpdate: PropTypes.func,
};
