import { getInstanceId } from '@va/dashboard/selectors/app';
import { useCurrentPeriodFilter } from '@va/dashboard/util-hooks';
import { FunnelType } from '@va/types/funnel';
import { toQueryString } from '@va/util/helpers';
import { useFetchData } from '@va/util/hooks';
import { useSelector } from 'react-redux';

export const useGetFunnels = () => {
  const webisteId = useSelector(getInstanceId);
  const currentPeriodFilter = useCurrentPeriodFilter();
  return useFetchData<FunnelType[]>(`/websites/${webisteId}/funnels?${toQueryString(currentPeriodFilter)}`);
};
