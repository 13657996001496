import { RootState } from '@va/wix-mini/shared/types';
import { Website } from '@visitor-analytics/3as-sdk';

type IntpWebsite = Website & { intpName: string };

export const isInitialized = (state: RootState) => state.getIn(['app'])?.initialized as boolean;

export const getWixInstanceId = (state: RootState) => state.getIn(['app'])?.instanceId || '';

export const selectIntpWebsite = (state: RootState) => (state.getIn(['app'])?.intpWebsite as IntpWebsite) ?? {};

export const selectSessionRecordingsLimit = (state: RootState) => {
  const stpRates = state.getIn(['app'])?.stpRates;

  if (!stpRates) return undefined;
  const { stpLimit, stpRateSessionRecording } = stpRates;
  if (stpLimit === 0 && stpRateSessionRecording === 0) return undefined;

  return stpLimit / stpRateSessionRecording;
};

export const selectSessionRecordingsConsumed = (state: RootState) => {
  const stpRates = state.getIn(['app'])?.stpRates;

  const { stpConsumed = 0 } = stpRates;
  return stpConsumed;
};

export const isSessionRecordingsLimitReached = (state: RootState) => {
  const stpRates = state.getIn(['app'])?.stpRates;
  if (!stpRates) return false;
  const { stpConsumed, stpLimit } = stpRates;

  return stpConsumed >= stpLimit;
};

export const selectCurrentPlanName = (state: RootState) => {
  const intpWebsite = state.getIn(['app'])?.intpWebsite as IntpWebsite | undefined;

  return intpWebsite?.packageName;
};

export const getPredefinedFilters = (state: RootState) => {
  return state.get('app').predefinedFilters;
};
