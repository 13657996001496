import { IconProps } from '.';

export const BaseButtonIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='white'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='18' height='18'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9 6C10.6569 6 12 4.65685 12 3C12 1.34315 10.6569 0 9 0C7.34315 0 6 1.34315 6 3C6 4.65685 7.34315 6 9 6ZM16.5 9C16.5 9.82843 15.8284 10.5 15 10.5C14.1716 10.5 13.5 9.82843 13.5 9C13.5 8.17157 14.1716 7.5 15 7.5C15.8284 7.5 16.5 8.17157 16.5 9ZM18 9C18 10.6569 16.6569 12 15 12C13.3431 12 12 10.6569 12 9C12 7.34315 13.3431 6 15 6C16.6569 6 18 7.34315 18 9ZM9 16.5C9.82843 16.5 10.5 15.8284 10.5 15C10.5 14.1716 9.82843 13.5 9 13.5C8.17157 13.5 7.5 14.1716 7.5 15C7.5 15.8284 8.17157 16.5 9 16.5ZM9 18C10.6569 18 12 16.6569 12 15C12 13.3431 10.6569 12 9 12C7.34315 12 6 13.3431 6 15C6 16.6569 7.34315 18 9 18ZM4.5 9C4.5 9.82843 3.82843 10.5 3 10.5C2.17157 10.5 1.5 9.82843 1.5 9C1.5 8.17157 2.17157 7.5 3 7.5C3.82843 7.5 4.5 8.17157 4.5 9ZM6 9C6 10.6569 4.65685 12 3 12C1.34315 12 0 10.6569 0 9C0 7.34315 1.34315 6 3 6C4.65685 6 6 7.34315 6 9Z'
          fill='black'
        />
      </mask>
      <g mask='url(#mask0)'>
        <rect x='-3' y='-3' width='24' height='24' fillOpacity='0.8333' />
      </g>
    </svg>
  );
};
