// Added to the resulting index.html of the WiX app. Not needed on non-WiX apps
const Wix = window.Wix;

/**
 * @memberOf Wix
 * @name Wix.Settings
 */

/**
 * @methodOf Wix.Settings
 * @name Wix.Settings.openBillingPage
 */
export function onUpgrade() {
  return Wix.Settings.openBillingPage();
}

/**
 * @memberOf Wix
 * @name Wix.Dashboard
 */

/**
 * @methodOf Wix.Dashboard
 * @name Wix.Dashboard.getProducts
 */
export function getProducts(onSuccess) {
  return Wix.Dashboard.getProducts(onSuccess);
}

/**
 * @memberOf Wix
 * @name Wix.Dashboard
 */

/**
 * @methodOf Wix.Dashboard
 * @name Wix.Dashboard.getEditorUrl
 */
export function getEditorUrl(callback) {
  return Wix.Dashboard.getEditorUrl(callback);
}

/**
 * @memberOf Wix
 * @name Wix.Dashboard
 */

/**
 * @methodOf Wix.Dashboard
 * @name Wix.Dashboard.openBillingPage
 */
export function openBillingPage() {
  return Wix.Dashboard.openBillingPage();
}
