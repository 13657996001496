import { getHighlightedRecordingId } from '@va/dashboard/selectors/app';
import { useVisitorRecordings } from '@va/dashboard/shared/recordings';
import { useFiltersContext } from '@va/shared/feature-filters';
import { storageItems } from '@va/standalone/shared/constants';
import { PageType, RecordingType, RecordingsDataType, RecordingsFiltersType } from '@va/types/recordings';
import { OnTableQueryChangeFunc } from '@va/types/table';
import { ControlledTableState, useControlledTableState } from '@va/ui/components/data-table';
import { LocalStorage, formatSessionDuration, getUrlWithoutTrailingSlash } from '@va/util/helpers';
import { FeaturePaginationKey, useSessionStoragePagination } from '@va/util/hooks';
import moment from 'moment';
import {
  PropsWithChildren,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { KeyedMutator } from 'swr';
import { recordingsSelectOptionsEnum } from '../types';
import useDeleteRecordings, { DeleteRecordingsCallType } from '../useDeleteRecordings';

type RecordingsContextType = {
  currentRecording: RecordingType | null | undefined;
  updateCurrentRecording: (recording: RecordingType) => void;
  recordings: RecordingType[];
  totalPages: number;
  isLoading: boolean;
  pageSize: number;
  isAddNoteModalOpen: boolean;
  mutateRecordings: KeyedMutator<RecordingsDataType>;
  onTableQueryChange: OnTableQueryChangeFunc<RecordingType[]>;
  closeAddNoteModal: () => void;
  openAddNoteModal: (recordingId: string) => void;
  totalCount: number;
  pageNumber: number;
  changeCurrentPage: (pageIndex: number) => void;
  changePageSize: (pageSize: number) => void;
  selectedRecordingsIds: string[];
  selectRecording: (recordingId: string) => void;
  deselectRecording: (recordingId: string) => void;
  clearSelectedRecordingsIds: () => void;
  selectAllRecordings: () => void;
  isRecordingSelected: (recordingId: string) => boolean;
  selectedBulkOption: number | null;
  setSelectedBulkOption: (option: number | null) => void;
  deleteBulk: () => Promise<any>;
  deleteIndividual: (sessionIds: string[]) => Promise<any>;
  tableState: ControlledTableState;
};

const RecordingsContext = createContext<RecordingsContextType>({} as RecordingsContextType);

type RecordingsContextProviderProps = PropsWithChildren<{
  config?: {
    initialPageSize?: number;
    initialFilters?: Partial<RecordingsFiltersType>;
  };
}>;

export const RecordingsContextProvider = forwardRef<any, RecordingsContextProviderProps>(
  ({ children, config }, ref) => {
    const { getStoredPageSize, storePageSize } = useSessionStoragePagination(FeaturePaginationKey.recordings);

    const highlightedRecordingId = useSelector(getHighlightedRecordingId);
    const controlledTableState = useControlledTableState({
      rowSelection: {
        [highlightedRecordingId]: true,
      },
      pagination: {
        pageSize: config?.initialPageSize ?? getStoredPageSize() ?? 12,
        pageIndex: 0,
      },
    });
    const { setPagination, pagination } = controlledTableState;
    const { pageIndex, pageSize } = pagination;

    const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);
    const [currentRecording, setCurrentRecording] = useState<RecordingType | undefined | null>(null);
    const [selectedRecordingsIds, setSelectedRecordingsIds] = useState<string[]>([]);
    const [selectedBulkOption, setSelectedBulkOption] = useState<recordingsSelectOptionsEnum | null>(null);

    const {
      data: recordingsData,
      isLoading,
      mutate: mutateRecordings,
    } = useVisitorRecordings({ pageNumber: pageIndex, pageSize, initialFilters: config?.initialFilters });

    const clearSelectedRecordingsIds = useCallback(() => {
      setSelectedRecordingsIds([]);
    }, []);

    const deleteBulk = useDeleteRecordings(DeleteRecordingsCallType.bulk);
    const deleteIndividual = useDeleteRecordings(DeleteRecordingsCallType.individual, clearSelectedRecordingsIds);

    const recordings = useMemo(() => recordingsData?.data ?? [], [recordingsData]);

    const totalCount = recordingsData?.sessionsTotal ?? 0;
    const { appliedFilterValues } = useFiltersContext();
    const totalPages = Math.ceil(totalCount / pageSize);

    useEffect(() => {
      if (deleteBulk.isSucceeded || deleteIndividual.isSucceeded) {
        mutateRecordings();
      }
    }, [deleteBulk.isSucceeded, deleteIndividual.isSucceeded, mutateRecordings]);

    useEffect(() => {
      if (selectedRecordingsIds.length === 0) return;
      setSelectedRecordingsIds([]);
      setSelectedBulkOption(null);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appliedFilterValues]);

    useEffect(() => {
      const recordingIds = recordings.map((recording) => recording.id);
      LocalStorage.setItem(storageItems.recordingIds, JSON.stringify(recordingIds));
    }, [recordings]);

    useImperativeHandle(ref, () => ({
      refreshRecordingsData: mutateRecordings,
    }));

    const onTableQueryChange: OnTableQueryChangeFunc<RecordingType[]> = async (data) => {
      const { pagination } = data;
      setPagination((p) => ({ ...p, pageSize: pagination?.pageSize ?? 12, pageIndex: pagination?.pageIndex ?? 0 }));
      if (pagination?.pageSize) {
        storePageSize(pagination?.pageSize);
      }
    };

    const closeAddNoteModal = useCallback(() => {
      setIsAddNoteModalOpen(false);
      setCurrentRecording(null);
    }, []);

    const openAddNoteModal = useCallback(
      (recordingId: string) => {
        setCurrentRecording(recordings.find((recording) => recording.id === recordingId));
        setIsAddNoteModalOpen(true);
      },
      [recordings],
    );

    const updateCurrentRecording = (recording: RecordingType) => {
      setCurrentRecording(recording);
    };
    const changeCurrentPage = useCallback(
      (pageIndex: number) => {
        setPagination((p) => ({ ...p, pageIndex: pageIndex }));
      },
      [setPagination],
    );

    const changePageSize = useCallback(
      (pageSize: number) => {
        setPagination((p) => ({ ...p, pageSize, pageIndex: 0 }));
        storePageSize(pageSize);
      },
      [setPagination, storePageSize],
    );

    const selectRecording = useCallback((recordingId: string) => {
      setSelectedRecordingsIds((prev) => [...prev, recordingId]);
      setSelectedBulkOption(null);
    }, []);

    const deselectRecording = useCallback((recordingId: string) => {
      setSelectedRecordingsIds((prev) => prev.filter((id) => id !== recordingId));
      setSelectedBulkOption(null);
    }, []);

    const isRecordingSelected = useCallback(
      (recordingId: string) => {
        return !!selectedRecordingsIds.includes(recordingId);
      },
      [selectedRecordingsIds],
    );

    const selectAllRecordings = useCallback(() => {
      setSelectedRecordingsIds(recordings.map((recording) => recording.id));
    }, [recordings]);

    return (
      <RecordingsContext.Provider
        value={{
          updateCurrentRecording,
          openAddNoteModal,
          closeAddNoteModal,
          mutateRecordings,
          isAddNoteModalOpen,
          totalCount,
          changeCurrentPage,
          changePageSize,
          pageSize,
          pageNumber: pageIndex,
          isLoading: isLoading || deleteBulk.isLoading || deleteIndividual.isLoading,
          recordings: recordings,
          currentRecording,
          totalPages,
          onTableQueryChange,
          selectedRecordingsIds,
          selectRecording,
          deselectRecording,
          clearSelectedRecordingsIds,
          selectAllRecordings,
          isRecordingSelected,
          selectedBulkOption,
          setSelectedBulkOption,
          deleteIndividual: deleteIndividual.execute,
          deleteBulk: deleteBulk.execute as () => Promise<any>,
          tableState: controlledTableState,
        }}
      >
        {children}
      </RecordingsContext.Provider>
    );
  },
);

export const useRecordingsContext = () => useContext(RecordingsContext);

export function getMappedPages(pages: PageType[], websiteUrl: string, locale: string) {
  return pages
    .sort((a, b) => a.timestamp - b.timestamp)
    .map((page) => {
      const duration = formatSessionDuration(page.duration);
      const timestamp = moment(page.timestamp).format('L hh:mm A');

      return {
        url: getUrlWithoutTrailingSlash(websiteUrl) + page.url,
        topRightInfo: duration,
        bottomRightInfo: timestamp,
        privacyLevel: page.privacyLevel,
      };
    });
}
