class Config {
  private apiUrl = '';
  private authProvider: () => Record<string, string> = () => ({});
  private static instance: Config;

  private constructor() {}

  public static getInstance(): Config {
    if (!this.instance) {
      this.instance = new Config();
    }
    return this.instance;
  }

  setApiBaseUrl(baseUrl: string) {
    this.apiUrl = baseUrl;
  }

  getApiBaseUrl() {
    return this.apiUrl;
  }

  setAuthProvider(authProvider: () => Record<string, string>) {
    this.authProvider = authProvider;
  }

  getAuthProvider() {
    return this.authProvider;
  }
}

export default Config.getInstance();
