import { useTranslate } from '@va/localization';
import { Paragraph, paragraphSizes } from '@va/ui/design-system';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';

/**@deprecated */
export const ValueOrNullCell = ({ value, column, state }) => {
  const translate = useTranslate();
  const othersGrouped = !column?.isGrouped && state?.groupBy?.length > 0;
  if (isNil(value)) {
    return (
      <Paragraph colorClassName='text-gray-silver' size={paragraphSizes.normal}>
        N/A
      </Paragraph>
    );
  }

  if (othersGrouped) {
    return (
      <Paragraph colorClassName='text-gray-silver' size={paragraphSizes.normal}>
        {Number.parseInt(value) === 1
          ? translate('eventTracking.table.value', { count: value })
          : translate('eventTracking.table.values', { count: value })}
      </Paragraph>
    );
  }

  return (
    <Paragraph colorClassName='text-gray-silver' size={paragraphSizes.normal}>
      {value}
    </Paragraph>
  );
};

ValueOrNullCell.propTypes = {
  value: PropTypes.any,
  column: PropTypes.shape({
    isGrouped: PropTypes.bool.isRequired,
  }),
  state: PropTypes.shape({
    groupBy: PropTypes.array,
  }),
};
