import { IconProps } from '.';

export const UnfilledLineGraphIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='22'
      height='18'
      viewBox='0 0 22 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M0.5 9H5L8 12L14 6L17 9H21.5' stroke={color} strokeWidth='1.5' />
    </svg>
  );
};
