import { cardColorTheme, SSR_REACHED_LIMIT } from '@va/constants';
import PopUpCard from '@va/deprecated/components/PopUpCard';
import Translation from '@va/deprecated/components/Translation';
import PropTypes from 'prop-types';
import './SsrReachedLimit.scss';
import SsrReachedLimitFooterButtons from './SsrReachedLimitFooterButtons';

export default function SsrReachedLimit(props) {
  return (
    <PopUpCard
      type='card-grey'
      colorTheme={cardColorTheme.GREEN}
      title={props.translate('modal.ssrReachedLimit.title')}
      footerButtons={
        <SsrReachedLimitFooterButtons
          translate={props.translate}
          goToWebsiteSettings={props.goToWebsiteSettings}
          closeModal={props.closeModal}
        />
      }
      smallerBottomSpace
    >
      <div className='text-content'>
        <p>{props.translate('modal.ssrReachedLimit.subtitle', { count: SSR_REACHED_LIMIT })}</p>
        <p>
          <Translation translationKey='modal.ssrReachedLimit.removeOldRecordings' />
        </p>
        <p>
          <Translation translationKey='modal.ssrReachedLimit.note.higherLimit' />
        </p>
      </div>
    </PopUpCard>
  );
}

SsrReachedLimit.propTypes = {
  translate: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  goToWebsiteSettings: PropTypes.func.isRequired,
};
