/* eslint-disable jsx-a11y/anchor-is-valid */

import { getWebsiteUrl } from '@va/dashboard/selectors/core';
import { useCheckPrivacySetting } from '@va/dashboard/util-hooks';
import { LayersIcon, LoginIcon, LogoutIcon } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { PRIVACY_FEATURES } from '@va/standalone/shared/types';
import { PageType } from '@va/types/recordings';
import {
  Button,
  PageItemType,
  Paragraph,
  VisitedPagesTooltip,
  fontWeights,
  paragraphSizes,
} from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import {
  addNumberSeparator,
  getUrlWithoutProtocolAndTrailingSlash,
  getUrlWithoutTrailingSlash,
} from '@va/util/helpers';
import { useIsHovering } from '@va/util/hooks';
import classNames from 'classnames';
import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getMappedPages } from '../context/recordingsContext';

type PagesCellProps = {
  pages: PageType[];
  totalCount: number;
  entryPage: string;
  exitPage: string;
  countryCode: string;
  sessionId: string;
  size?: 'small' | 'medium';
  isClickable?: boolean;
};

export const PagesCell: React.FC<PagesCellProps> = ({
  sessionId,
  pages,
  totalCount,
  entryPage,
  exitPage,
  countryCode,
  size = 'medium',
  isClickable = true,
}) => {
  const websiteUrl = useSelector(getWebsiteUrl);
  const history = useHistory();
  const { locale } = useLocale();
  const translate = useTranslate();
  const visitedPagesBtnRef = useRef<HTMLButtonElement>(null);
  const isHovering = useIsHovering(visitedPagesBtnRef);

  const { isTrackingEnabled } = useCheckPrivacySetting();

  const isPageHistoryTrackingEnabled = isTrackingEnabled(PRIVACY_FEATURES.individualPageHistory, countryCode);

  const mappedPages: PageItemType[] = useMemo(
    () => getMappedPages(pages, websiteUrl, locale),
    [locale, pages, websiteUrl],
  );

  const websiteUrlWithoutTrailingSlash = getUrlWithoutTrailingSlash(websiteUrl);

  const completeEntryPage = websiteUrlWithoutTrailingSlash + entryPage;
  const entryPageWithoutProtocol = getUrlWithoutProtocolAndTrailingSlash(completeEntryPage);
  const completeExitPage = websiteUrlWithoutTrailingSlash + exitPage;
  const exitPageWithoutProtocol = getUrlWithoutProtocolAndTrailingSlash(completeExitPage);

  return (
    <div className='w-full flex gap-3 items-center justify-start'>
      <Button
        text={addNumberSeparator(totalCount, locale)}
        icon={(className, color) => <LayersIcon className={className} color={color} />}
        className={classNames('min-w-fit', {
          'text-primary [&_svg>path]:fill-primary': !isHovering,
          'py-9px': size === 'small',
          'cursor-default': !isClickable || !isPageHistoryTrackingEnabled,
        })}
        textClasses={classNames({
          'text-xs': size === 'small',
        })}
        color={isHovering ? 'primary' : 'quinary'}
        ref={visitedPagesBtnRef}
        tooltip={
          <VisitedPagesTooltip
            isPageHistoryTrackingEnabled={isPageHistoryTrackingEnabled}
            title={translate('card.latestVisitors.visitorsHistory.title')}
            items={mappedPages}
          />
        }
        tooltipProps={{
          useDefaultStyle: false,
          interactive: true,
        }}
        onClick={(event) => {
          if (!isClickable || !isPageHistoryTrackingEnabled) return;
          event.stopPropagation();
          history.push(`/visitors/history/${sessionId}`);
        }}
      />

      <div className='flex flex-col gap-3 overflow-hidden'>
        <a
          target={'_blank'}
          rel='noreferrer'
          aria-disabled={!isPageHistoryTrackingEnabled}
          href={isPageHistoryTrackingEnabled ? completeEntryPage : undefined}
          onClick={(event) => event.stopPropagation()}
        >
          <div className='flex items-center gap-2'>
            <TooltipWrapper content={translate('recordings.entryPage')}>
              <div>
                <LoginIcon color='var(--color-text-secondary)' className='shrink-0' />
              </div>
            </TooltipWrapper>
            <TooltipWrapper
              content={
                isPageHistoryTrackingEnabled ? (
                  <p className='break-words'>{entryPageWithoutProtocol}</p>
                ) : (
                  translate('panels.recordings.consentless.hiddenPages')
                )
              }
            >
              <Paragraph
                size={paragraphSizes.tiny}
                colorClassName='text-text-secondary'
                className='truncate max-w-md hover:underline'
                weight={fontWeights.medium}
              >
                {isPageHistoryTrackingEnabled
                  ? entryPageWithoutProtocol
                  : translate('panels.recordings.consentless.hiddenPages')}
              </Paragraph>
            </TooltipWrapper>
          </div>
        </a>
        <a
          target={'_blank'}
          rel='noreferrer'
          aria-disabled={!isPageHistoryTrackingEnabled}
          href={isPageHistoryTrackingEnabled ? completeExitPage : undefined}
          onClick={(event) => event.stopPropagation()}
        >
          <div className='flex items-center gap-2'>
            <TooltipWrapper content={translate('recordings.exitPage')}>
              <div>
                <LogoutIcon color='var(--color-text-secondary)' className='shrink-0' />
              </div>
            </TooltipWrapper>
            <TooltipWrapper
              content={
                isPageHistoryTrackingEnabled ? (
                  <p className='break-words'>{exitPageWithoutProtocol}</p>
                ) : (
                  translate('panels.recordings.consentless.hiddenPages')
                )
              }
            >
              <Paragraph
                size={paragraphSizes.tiny}
                colorClassName='text-text-secondary'
                className='max-w-md truncate hover:underline'
                weight={fontWeights.medium}
              >
                {isPageHistoryTrackingEnabled
                  ? exitPageWithoutProtocol
                  : translate('panels.recordings.consentless.hiddenPages')}
              </Paragraph>
            </TooltipWrapper>
          </div>
        </a>
      </div>
    </div>
  );
};
