import { FunnelIcon, VideoCameraIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import {
  FilterInputComponentRefType,
  FilterSelectDropdown,
  PickOperatorBtn,
  useFiltersContext,
  useOperator,
} from '@va/shared/feature-filters';
import { Button } from '@va/ui/design-system';
import { isNil } from 'lodash';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { useFunnelFilterOptions } from '../../filter-options';
import { FunnelFilter, FunnelFilterValue } from './FunnelFilter';

type FunnelFilterInputProps = { filter: FunnelFilter };

export const FunnelFilterInput = forwardRef<FilterInputComponentRefType, FunnelFilterInputProps>(({ filter }, ref) => {
  const [filterValue, setFilterValue] = useState<FunnelFilterValue>({ id: undefined, lvl: undefined });

  const { operator, setOperator } = useOperator(filter.operator);
  const { applySingleNewFilter } = useFiltersContext();

  const { funnelOptions, levelOptions, selectedFunnel } = useFunnelFilterOptions(filterValue);

  const onSubmit = useCallback(() => {
    const clone = Object.assign({}, filter);
    clone.values = [filterValue];
    applySingleNewFilter(clone);
  }, [applySingleNewFilter, filter, filterValue]);

  useImperativeHandle(ref, () => ({ submit: onSubmit }));

  const translate = useTranslate();

  const selectedLevelName = useMemo(() => {
    if (isNil(filterValue.lvl)) return undefined;
    return levelOptions.find((lvl) => lvl.value === filterValue.lvl)?.label;
  }, [filterValue.lvl, levelOptions]);

  return (
    <div className='space-y-2'>
      <PickOperatorBtn
        onOperatorChange={setOperator}
        selectedOperator={operator}
        operatorOptions={filter.operatorOptions}
      />
      <FilterSelectDropdown
        tooltipClassName='w-screen !max-w-[480px]'
        placement='bottom'
        selectedOptions={!isNil(filterValue.id) ? { [filterValue.id]: true } : {}}
        options={funnelOptions}
        onChange={(option) => {
          setFilterValue({ id: option.value as string, lvl: undefined });
        }}
        triggerClassName='w-full'
      >
        <Button
          className='grow'
          color='tertiary'
          text={selectedFunnel?.name ?? translate('filters.options.selectFunnel')}
          icon={() => <FunnelIcon color='#696969' />}
        />
      </FilterSelectDropdown>

      <FilterSelectDropdown
        tooltipClassName='w-screen !max-w-[480px]'
        placement='bottom'
        selectedOptions={!isNil(filterValue.lvl) ? { [filterValue.lvl]: true } : {}}
        options={levelOptions}
        onChange={(option) => {
          setFilterValue({ lvl: Number(option.value), id: filterValue.id });
        }}
        triggerClassName='w-full'
      >
        <Button
          className='grow'
          color='tertiary'
          text={selectedLevelName ?? translate('filters.options.funnelLevel')}
          icon={() => <VideoCameraIcon color='#696969' />}
        />
      </FilterSelectDropdown>
    </div>
  );
});
