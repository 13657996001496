import { LogLevel, TwiplaWorker } from '@twipla/worker';
import { TrackEventProps } from '@va/types/twipla-worker';
import Config from 'Config';

type Env = 'dev' | 'stage' | 'production';
type TwiplaWorkerParams = {
  websiteId: string;
  logLevel?: LogLevel;
  trackUrlChanges?: boolean;
  rewriteUrl?: () => string;
  enabled: boolean;
  usePolls?: boolean;
};

export default abstract class BaseWorker {
  #twiplaWorker: TwiplaWorker | null;
  readonly #enabled: boolean;

  protected constructor({ websiteId, logLevel, trackUrlChanges, rewriteUrl, enabled, usePolls }: TwiplaWorkerParams) {
    this.#enabled = enabled;

    if (!enabled) {
      this.#twiplaWorker = null;
      return;
    }

    this.#twiplaWorker = new TwiplaWorker({
      websiteId,
      env: Config.workerEnv as Env,
      logLevel,
      trackUrlChanges,
      rewriteUrl,
      usePolls,
    });
  }

  public async run() {
    if (!this.#enabled) return;
    try {
      await this.#twiplaWorker?.run();
    } catch (e) {
      console.error(e);
    }
  }

  public trackEvent({ category, action, label, value, fieldsObject }: TrackEventProps) {
    if (!this.#enabled) return;
    this.#twiplaWorker?.sendEvent(category, action, label, value, fieldsObject ?? '');
  }

  public trackEcom({ category, action, label, value, fieldsObject }: TrackEventProps) {
    if (!this.#enabled) return;
    this.#twiplaWorker?.sendEcom(category, action, label, value, fieldsObject ?? '');
  }
}
