import {
  AGREEMENT_FAILED,
  AGREEMENT_REQUEST,
  AGREEMENT_SUCCEEDED,
  CREATE_WEBSITE_FAILED,
  CREATE_WEBSITE_REQUEST,
  CREATE_WEBSITE_SUCCEEDED,
  GET_AGREEMENT_FAILED,
  GET_AGREEMENT_REQUEST,
  GET_AGREEMENT_SUCCEEDED,
  GET_ANNOUNCEMENTS,
  GET_ANNOUNCEMENTS_FAILED,
  GET_ANNOUNCEMENTS_SUCCEEDED,
  GET_FEATURE_PERMISSIONS,
  GET_FEATURE_PERMISSIONS_FAILED,
  GET_FEATURE_PERMISSIONS_SUCCEEDED,
  GET_PRIVACY_SETTINGS,
  GET_PRIVACY_SETTINGS_FAILED,
  GET_PRIVACY_SETTINGS_SUCCEEDED,
  SIGN_DPA_FAILED,
  SIGN_DPA_REQUEST,
  SIGN_DPA_SUCCEEDED,
  UPDATE_WEBSITE_FLAGS_FAILED,
  UPDATE_WEBSITE_FLAGS_REQUEST,
  UPDATE_WEBSITE_FLAGS_SUCCEEDED,
} from '@va/dashboard/actions/api';
import { newApiRequest } from './core/util';

export const createWebsite = newApiRequest(CREATE_WEBSITE_REQUEST, CREATE_WEBSITE_SUCCEEDED, CREATE_WEBSITE_FAILED);

export const updateWebsiteFlags = newApiRequest(
  UPDATE_WEBSITE_FLAGS_REQUEST,
  UPDATE_WEBSITE_FLAGS_SUCCEEDED,
  UPDATE_WEBSITE_FLAGS_FAILED,
);

export const agreement = newApiRequest(AGREEMENT_REQUEST, AGREEMENT_SUCCEEDED, AGREEMENT_FAILED);

export const getAgreement = newApiRequest(GET_AGREEMENT_REQUEST, GET_AGREEMENT_SUCCEEDED, GET_AGREEMENT_FAILED);

export const signDPA = newApiRequest(SIGN_DPA_REQUEST, SIGN_DPA_SUCCEEDED, SIGN_DPA_FAILED);

export const getFeaturePermissions = newApiRequest(
  GET_FEATURE_PERMISSIONS,
  GET_FEATURE_PERMISSIONS_SUCCEEDED,
  GET_FEATURE_PERMISSIONS_FAILED,
);

export const getAnnouncements = newApiRequest(GET_ANNOUNCEMENTS, GET_ANNOUNCEMENTS_SUCCEEDED, GET_ANNOUNCEMENTS_FAILED);

export const getPrivacySettings = newApiRequest(
  GET_PRIVACY_SETTINGS,
  GET_PRIVACY_SETTINGS_SUCCEEDED,
  GET_PRIVACY_SETTINGS_FAILED,
);
