import { tabNames } from '@va/constants';
import { getCustomizationOptions } from '@va/dashboard/selectors/customization';
import { getActiveTab, isReportBlockPage } from '@va/dashboard/selectors/ui';
import { useTranslate } from '@va/localization';
import {
  Capterra,
  Crozdesk,
  Facebook,
  G2,
  Instagram,
  LinkedIn,
  Medium,
  Pinterest,
  ProductHunt,
  SaasWorthy,
  SoftwareSuggest,
  Trustpilot,
  Twitter,
  Xing,
  Youtube,
} from '@va/svg-visa-icons';
import classNames from 'classnames';
import moment from 'moment';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import './Footer.scss';

type FooterProps = {
  presentationLinks?: {
    homepage?: string;
    aboutUs?: string;
    blog?: string;
    facebookGroup?: string;
    legal?: string;
    terms?: string;
    dpa?: string;
    gdprCommitment?: string;
    yourPrivacyPolicy?: string;
    affiliateLink?: string;
  };
};

export const Footer = memo((props: FooterProps) => {
  const translate = useTranslate();
  const { providerName, supportCenterDomain } = useSelector(getCustomizationOptions);
  const shouldApplyBg = useSelector(isReportBlockPage);
  const { presentationLinks } = props;

  const activeTab = useSelector(getActiveTab);

  if (activeTab === tabNames.privacyCenter) {
    return null;
  }

  return (
    <div className={classNames(`transition-all duration-200 ease-in-out page-footer`)}>
      <div
        className={classNames('footer-content', {
          'bg-[#F2F2F2]': shouldApplyBg,
          'bg-transparent': !shouldApplyBg,
        })}
      >
        <div className='footer-links'>
          <div className='first-row'>
            <div className='centered-row'>
              <div className='links-group'>
                <div className='footer-link'>{translate('footer.label.aboutUs', { supportCenterDomain })}</div>
                <div className='footer-link'>{translate('footer.label.blog', { supportCenterDomain })}</div>
                <div className='footer-link no-separator-mobile'>
                  {translate('support.helpCenter', { supportCenterDomain })}
                </div>
              </div>
              <div className='links-group'>
                <div className='footer-link'>{translate('footer.label.facebookGroup')}</div>
                {presentationLinks?.affiliateLink && (
                  <div className='footer-link'>{translate('footer.becomeAnAffiliate')}</div>
                )}
              </div>
            </div>
          </div>

          <div className='second-row'>
            <div className='centered-row'>
              <div className='footer-copyright'>
                <span className='copyright-char'>&copy;</span>
                <span className='copyright-text'>{moment().year() + ' ' + providerName}</span>
              </div>

              <div className='links-group'>
                <div className='footer-link'>{translate('footer.label.terms', { supportCenterDomain })}</div>
                <div className='footer-link'>{translate('footer.label.dpa', { supportCenterDomain })}</div>
              </div>

              <div className='links-group'>
                <div className='footer-link'>{translate('footer.label.gdprCommitment', { supportCenterDomain })}</div>
                <div className='footer-link'>
                  {translate('footer.label.yourPrivacyPolicy', { supportCenterDomain })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='footer-icons-container'>
          <div className='footer-icons'>
            <a rel='noreferrer' href={`${translate('footer.link.facebook')}`} className='footer-icon' target='_blank'>
              <Facebook />
            </a>
            <a rel='noreferrer' href={`${translate('footer.link.twitter')}`} className='footer-icon' target='_blank'>
              <Twitter />
            </a>
            <a rel='noreferrer' href={`${translate('footer.link.instagram')}`} className='footer-icon' target='_blank'>
              <Instagram />
            </a>
            <a rel='noreferrer' href={`${translate('footer.link.linkedIn')}`} className='footer-icon' target='_blank'>
              <LinkedIn />
            </a>
            <a rel='noreferrer' href={`${translate('footer.link.xing')}`} className='footer-icon' target='_blank'>
              <Xing />
            </a>
            <a rel='noreferrer' href={`${translate('footer.link.youtube')}`} className='footer-icon' target='_blank'>
              <Youtube />
            </a>
            <a rel='noreferrer' href={`${translate('footer.link.medium')}`} className='footer-icon' target='_blank'>
              <Medium />
            </a>
            <a rel='noreferrer' href={`${translate('footer.link.pinterest')}`} className='footer-icon' target='_blank'>
              <Pinterest />
            </a>
            <a rel='noreferrer' href={`${translate('footer.link.capterra')}`} className='footer-icon' target='_blank'>
              <Capterra />
            </a>
            <a rel='noreferrer' href={`${translate('footer.link.crozdesk')}`} className='footer-icon' target='_blank'>
              <Crozdesk />
            </a>
            <a rel='noreferrer' href={`${translate('footer.link.g2')}`} className='footer-icon' target='_blank'>
              <G2 />
            </a>
            <a
              rel='noreferrer'
              href={`${translate('footer.link.productHunt')}`}
              className='footer-icon'
              target='_blank'
            >
              <ProductHunt />
            </a>
            <a rel='noreferrer' href={`${translate('footer.link.saasWorthy')}`} className='footer-icon' target='_blank'>
              <SaasWorthy />
            </a>
            <a
              rel='noreferrer'
              href={`${translate('footer.link.softwareSuggest')}`}
              className='footer-icon'
              target='_blank'
            >
              <SoftwareSuggest />
            </a>
            <a rel='noreferrer' href={`${translate('footer.link.trustPilot')}`} className='footer-icon' target='_blank'>
              <Trustpilot />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});
