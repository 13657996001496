import { FilterIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { FilterOperators } from '@va/types/filters';
import { ParagraphWithTooltip } from '@va/ui/design-system';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import { useState } from 'react';
import { PickOperatorModal } from './PickOperatorModal';

export const PickOperatorBtn = ({
  selectedOperator,
  onOperatorChange,
  operatorOptions,
}: {
  selectedOperator: FilterOperators;
  onOperatorChange: (operator: FilterOperators) => void;
  operatorOptions?: FilterOperators[];
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const translate = useTranslate();
  const hasOptions = operatorOptions && operatorOptions.length !== 0;

  return (
    <>
      <PickOperatorModal
        selectedOperator={selectedOperator}
        onChange={onOperatorChange}
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
        }}
        operators={operatorOptions ?? []}
      />
      <button
        type='button'
        onClick={() => {
          setIsModalOpen(true);
        }}
        disabled={!hasOptions}
        className={classNames(
          'w-full overflow-hidden flex-2 flex items-center justify-start rounded-md bg-white-snow text-sm px-2 py-1 gap-9px',
          {
            'hover:bg-gray-concrete cursor-pointer': hasOptions,
            'cursor-not-allowed': !hasOptions,
          },
        )}
      >
        <FilterIcon className='w-3 h-3' color='#696969' />
        <ParagraphWithTooltip>{capitalize(translate(`filters.operators.${selectedOperator}`))}</ParagraphWithTooltip>
      </button>
    </>
  );
};
