import { apiStatus, defaultPrivacyLevel } from '@va/constants';
import { getWebsite } from '@va/dashboard/selectors/core';
import { PRIVACY_FEATURES, PrivacyLevelType } from '@va/standalone/shared/types';
import { privacyLevelFeatures } from '@va/util/helpers';
import { isArray } from 'lodash';
import { createSelector } from 'reselect';
import { getApiRequest } from './api';

const initialPrivacyLevel = createSelector([getWebsite], (website) => {
  const { maxPrivacyMode, consentlessTracking, anonymizeIps } = website;
  if (maxPrivacyMode) return 3;
  if (consentlessTracking) return 2;
  if (anonymizeIps) return 1;
  return 0;
});

export const getPrivacySettingsData = createSelector(
  [
    (state) => getApiRequest(state, 'getPrivacySettings') as { status: apiStatus; data: PrivacyLevelType[] },
    initialPrivacyLevel,
  ],
  (privacySettings, initialLevel) => {
    if (privacySettings.status !== apiStatus.SUCCEEDED) {
      return defaultPrivacyLevel.map((level) => {
        return { ...level, selected: level.levelKey === initialLevel };
      });
    }
    return privacySettings?.data;
  },
);

export const currentActivePrivacyLevel = createSelector([getPrivacySettingsData], (privacyLevels: any) => {
  return privacyLevels?.find((level: PrivacyLevelType) => level.selected)?.levelKey;
});

export const isTrackingEnabled = (state: any) => {
  return (featureName: PRIVACY_FEATURES, countryCode: string) => {
    const privacyLevelData = getPrivacySettingsData(state);
    const activeLevel = currentActivePrivacyLevel(state);

    //@ts-ignore
    const lockedOnLevel = privacyLevelData?.find((level) => level.exceptions.includes(countryCode))?.levelKey;
    const response = privacyLevelFeatures(lockedOnLevel ?? activeLevel);

    if (isArray(response)) {
      return response.includes(featureName);
    }

    return response;
  };
};
