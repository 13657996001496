import DropdownInput from '@va/deprecated/form/DropdownInputWithLabel';
import TextInput from '@va/deprecated/form/TextInputWithLabel';
import { getBusinessSectorOptions } from '@va/util/helpers';
import { FastField, Field as FormikField } from 'formik';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

export default class CustomOfferFields extends PureComponent {
  getOptions(name) {
    const industryOptions = getBusinessSectorOptions(this.props.translate);

    const monthlyTrafficOptions = [
      { value: '<1M visits', label: '< 1M visits' },
      { value: '<2,5M visits', label: '< 2,5M visits' },
      { value: '<5M visits', label: '<5M visits' },
      { value: '<10M visits', label: '<10M visits' },
      { value: '<15M visits', label: '<15M visits' },
      { value: '<20M visits', label: '<20M visits' },
      { value: '<25M visits', label: '<25M visits' },
      { value: '>25M visits', label: '>25M visits' },
    ];

    switch (name) {
      case 'industry':
        return industryOptions;
      case 'traffic':
        return monthlyTrafficOptions;
      default:
        return [];
    }
  }

  render() {
    const { translate, errors } = this.props;

    return (
      <div className='input-fields-area'>
        <div className='input-with-header'>
          <div className='input-header'>{translate('modal.customOffer.input.header.industry')}</div>
          <FastField
            name='industry'
            component={DropdownInput}
            placeholder={translate('form.placeholders.chooseIndustry')}
            label={translate('form.placeholders.chooseIndustry')}
            options={this.getOptions('industry')}
            required
          />
        </div>
        <div className='input-with-header'>
          <div className='input-header'>{translate('modal.customOffer.input.header.traffic')}</div>
          <FastField
            name='traffic'
            component={DropdownInput}
            placeholder={translate('form.placeholders.chooseTraffic')}
            label={translate('form.placeholders.chooseTraffic')}
            options={this.getOptions('traffic')}
            required
          />
        </div>
        <div className='input-with-header'>
          <div className='input-header'>{translate('modal.customOffer.input.header.moreAboutUser')}</div>
          <TextInput label={translate('form.placeholders.email')} error={errors.email} required>
            <FastField
              as='input'
              name='email'
              className='text-mine-shaft font-medium text-lg bg-transparent w-full leading-6'
              placeholder={translate('form.placeholders.email')}
            />
          </TextInput>

          <div className='multiple-inputs'>
            <TextInput label={translate('form.placeholders.businessName')} additionalStyle={'mr-4 sm:mr-0'}>
              <FastField
                as='input'
                name='companyName'
                className='text-mine-shaft font-medium text-lg bg-transparent w-full leading-6 mr-4'
                placeholder={translate('form.placeholders.businessName')}
              />
            </TextInput>

            <TextInput label={translate('form.placeholders.jobRole')}>
              <FastField
                as='input'
                name='jobRole'
                className='text-mine-shaft font-medium text-lg bg-transparent w-full leading-6'
                placeholder={translate('form.placeholders.jobRole')}
              />
            </TextInput>
          </div>

          <div className='input-with-header'>
            <TextInput label={translate('modal.customOffer.input.header.additionalNotes')}>
              <FormikField name={'additionalNotes'}>
                {({ field }) => (
                  <TextareaAutosize
                    className='w-full leading-normal bg-white'
                    placeholder={translate('modal.customOffer.input.header.additionalNotes')}
                    {...field}
                  />
                )}
              </FormikField>
            </TextInput>
          </div>
        </div>
      </div>
    );
  }
}

CustomOfferFields.propTypes = {
  translate: PropTypes.func.isRequired,
  errors: PropTypes.object,
};
