import { DropdownIcon } from '@va/icons';
import classNames from 'classnames';
import React from 'react';
import './dropdown-arrow.scss';

// TODO add storybook
export const DropdownArrow: React.FC<{
  className?: string;
  iconClassName?: string;
  open?: boolean;
  color?: string;
}> = ({ iconClassName, className, open, color }) => {
  return (
    <div
      id='dropdown-arrow-icon'
      className={classNames('transform transition-transform duration-150', { open: !open }, className)}
    >
      <DropdownIcon color={color} className={iconClassName} />
    </div>
  );
};
