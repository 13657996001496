import {
  GET_NOTIFICATION_SETTINGS_FAILED,
  GET_NOTIFICATION_SETTINGS_REQUEST,
  GET_NOTIFICATION_SETTINGS_SUCCEEDED,
  SETTINGS_FAILED,
  SETTINGS_REQUEST,
  SETTINGS_SUCCEEDED,
  UPDATE_ACCOUNT_INFO_FAILED,
  UPDATE_ACCOUNT_INFO_REQUEST,
  UPDATE_ACCOUNT_INFO_SUCCEEDED,
  UPDATE_SETTINGS_FAILED,
  UPDATE_SETTINGS_NOTIFICATIONS_FAILED,
  UPDATE_SETTINGS_NOTIFICATIONS_REQUEST,
  UPDATE_SETTINGS_NOTIFICATIONS_SUCCEEDED,
  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCEEDED,
} from '@va/dashboard/actions/api';
import { newApiRequest } from './core/util';

export const getSettings = newApiRequest(SETTINGS_REQUEST, SETTINGS_SUCCEEDED, SETTINGS_FAILED);

export const getNotificationSettings = newApiRequest(
  GET_NOTIFICATION_SETTINGS_REQUEST,
  GET_NOTIFICATION_SETTINGS_SUCCEEDED,
  GET_NOTIFICATION_SETTINGS_FAILED,
);

export const updateSettings = newApiRequest(UPDATE_SETTINGS_REQUEST, UPDATE_SETTINGS_SUCCEEDED, UPDATE_SETTINGS_FAILED);

export const updateSettingsNotifications = newApiRequest(
  UPDATE_SETTINGS_NOTIFICATIONS_REQUEST,
  UPDATE_SETTINGS_NOTIFICATIONS_SUCCEEDED,
  UPDATE_SETTINGS_NOTIFICATIONS_FAILED,
);

export const updateAccountInfo = newApiRequest(
  UPDATE_ACCOUNT_INFO_REQUEST,
  UPDATE_ACCOUNT_INFO_SUCCEEDED,
  UPDATE_ACCOUNT_INFO_FAILED,
);
