export function CookieIcon(props) {
  return (
    <svg {...props} width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.0085 3.43331C21.766 1.52223 18.4677 1.52223 16.2251 3.43331L15.166 4.33586C14.2051 5.1548 13.0102 5.64972 11.7516 5.75015L10.3645 5.86084C7.42749 6.09522 5.09522 8.42749 4.86084 11.3645L4.75015 12.7516C4.64972 14.0102 4.1548 15.2051 3.33586 16.166L2.43331 17.2251C0.52223 19.4677 0.522229 22.766 2.43331 25.0085L3.33586 26.0676C4.1548 27.0286 4.64972 28.2234 4.75015 29.482L4.86084 30.8691C5.09522 33.8062 7.42749 36.1384 10.3645 36.3728L11.7516 36.4835C13.0102 36.5839 14.2051 37.0789 15.166 37.8978L16.2251 38.8003C18.4677 40.7114 21.766 40.7114 24.0085 38.8003L25.0676 37.8978C26.0286 37.0789 27.2234 36.5839 28.482 36.4835L29.8691 36.3728C31.9402 36.2075 33.7106 34.9991 34.6566 33.271C33.7194 32.539 33.1168 31.3983 33.1168 30.1168C33.1168 28.9147 33.6471 27.8364 34.4866 27.1031C32.4663 25.8754 31.1168 23.6537 31.1168 21.1168C31.1168 18.58 32.4663 16.3583 34.4866 15.1305C33.6471 14.3973 33.1168 13.319 33.1168 12.1168C33.1168 10.8354 33.7194 9.6947 34.6566 8.96263C33.7106 7.23456 31.9402 6.02612 29.8691 5.86084L28.482 5.75015C27.2234 5.64972 26.0286 5.1548 25.0676 4.33586L24.0085 3.43331Z'
        fill='#EDAB26'
      />
      <path
        opacity='0.3'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.12883 7.12891C5.85379 8.13421 4.99802 9.646 4.86084 11.365L4.75015 12.752C4.64972 14.0106 4.1548 15.2055 3.33586 16.1665L2.43331 17.2255C0.52223 19.4681 0.522229 22.7664 2.43331 25.0089L3.33586 26.068C4.1548 27.029 4.64972 28.2238 4.75015 29.4824L4.86084 30.8695C5.09522 33.8066 7.42749 36.1388 10.3645 36.3732L11.7516 36.4839C13.0102 36.5843 14.2051 37.0793 15.166 37.8982L16.2251 38.8008C18.4677 40.7118 21.766 40.7118 24.0085 38.8008L25.0676 37.8982C26.0286 37.0793 27.2234 36.5843 28.482 36.4839L29.8691 36.3732C31.5879 36.2361 33.0996 35.3804 34.1049 34.1056C33.2013 34.818 32.0872 35.2761 30.8691 35.3733L29.4821 35.484C28.2235 35.5844 27.0286 36.0793 26.0676 36.8983L25.0086 37.8008C22.766 39.7119 19.4677 39.7119 17.2252 37.8008L16.1661 36.8983C15.2051 36.0793 14.0102 35.5844 12.7517 35.484L11.3646 35.3733C8.42752 35.1389 6.09525 32.8066 5.86087 29.8696L5.75018 28.4825C5.64975 27.2239 5.15483 26.0291 4.33589 25.0681L3.43334 24.009C1.52226 21.7664 1.52226 18.4681 3.43334 16.2256L4.33589 15.1665C5.15483 14.2055 5.64975 13.0107 5.75018 11.7521L5.86087 10.365C5.95809 9.14673 6.41626 8.03251 7.12883 7.12891Z'
        fill='#111111'
      />
      <circle opacity='0.12' cx='14.1168' cy='16.1172' r='3' fill='#111111' />
      <circle cx='15.1168' cy='15.1172' r='3' fill='#444444' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.6787 16.6784C17.2237 16.9564 16.689 17.1166 16.1168 17.1166C14.46 17.1166 13.1168 15.7734 13.1168 14.1166C13.1168 13.5444 13.277 13.0096 13.555 12.5547C12.6925 13.0816 12.1168 14.0319 12.1168 15.1166C12.1168 16.7734 13.46 18.1166 15.1168 18.1166C16.2015 18.1166 17.1517 17.5409 17.6787 16.6784Z'
        fill='#111111'
      />
      <circle opacity='0.12' cx='25.1168' cy='29.1172' r='3' fill='#111111' />
      <circle cx='26.1168' cy='28.1172' r='3' fill='#444444' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.6787 29.6784C28.2237 29.9564 27.689 30.1166 27.1168 30.1166C25.46 30.1166 24.1168 28.7734 24.1168 27.1166C24.1168 26.5444 24.277 26.0096 24.555 25.5547C23.6925 26.0816 23.1168 27.0319 23.1168 28.1166C23.1168 29.7734 24.46 31.1166 26.1168 31.1166C27.2015 31.1166 28.1517 30.5409 28.6787 29.6784Z'
        fill='#111111'
      />
      <circle opacity='0.12' cx='11.1168' cy='30.1172' r='2' fill='#111111' />
      <circle cx='12.1168' cy='29.1172' r='2' fill='#444444' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.9401 29.9394C13.689 30.053 13.4103 30.1162 13.1168 30.1162C12.0123 30.1162 11.1168 29.2208 11.1168 28.1162C11.1168 27.8227 11.18 27.544 11.2936 27.293C10.5996 27.6068 10.1168 28.3051 10.1168 29.1162C10.1168 30.2208 11.0123 31.1162 12.1168 31.1162C12.9279 31.1162 13.6263 30.6334 13.9401 29.9394Z'
        fill='#111111'
      />
      <circle opacity='0.12' cx='26.1168' cy='15.1172' r='2' fill='#111111' />
      <circle cx='27.1168' cy='14.1172' r='2' fill='#444444' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.9401 14.9394C28.689 15.053 28.4103 15.1162 28.1168 15.1162C27.0123 15.1162 26.1168 14.2208 26.1168 13.1162C26.1168 12.8227 26.18 12.544 26.2936 12.293C25.5996 12.6068 25.1168 13.3051 25.1168 14.1162C25.1168 15.2208 26.0123 16.1162 27.1168 16.1162C27.9279 16.1162 28.6263 15.6334 28.9401 14.9394Z'
        fill='#111111'
      />
      <circle opacity='0.12' cx='37.1168' cy='21.1172' r='2' fill='#111111' />
      <circle cx='38.1168' cy='20.1172' r='2' fill='#EDAB26' />
      <path
        opacity='0.2'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39.9401 20.9394C39.689 21.053 39.4103 21.1162 39.1168 21.1162C38.0123 21.1162 37.1168 20.2208 37.1168 19.1162C37.1168 18.8227 37.18 18.544 37.2936 18.293C36.5996 18.6068 36.1168 19.3051 36.1168 20.1162C36.1168 21.2208 37.0123 22.1162 38.1168 22.1162C38.9279 22.1162 39.6263 21.6334 39.9401 20.9394Z'
        fill='#111111'
      />
      <circle cx='38.6168' cy='28.6172' r='1.5' fill='#EDAB26' />
      <path
        opacity='0.1'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40.0525 29.0531C39.9147 29.0949 39.7684 29.1174 39.6168 29.1174C38.7884 29.1174 38.1168 28.4458 38.1168 27.6174C38.1168 27.4658 38.1393 27.3195 38.1811 27.1816C37.5652 27.3683 37.1168 27.9405 37.1168 28.6174C37.1168 29.4458 37.7884 30.1174 38.6168 30.1174C39.2937 30.1174 39.8659 29.669 40.0525 29.0531Z'
        fill='#111111'
      />
      <circle opacity='0.2' cx='28.1168' cy='21.1172' r='1' fill='#111111' />
      <circle opacity='0.6' cx='34.1168' cy='23.1172' r='1' fill='#EDAB26' />
      <circle opacity='0.6' cx='38.1168' cy='14.1172' r='1' fill='#EDAB26' />
      <circle opacity='0.2' cx='6.11682' cy='21.1172' r='1' fill='#111111' />
      <circle opacity='0.2' cx='10.1168' cy='11.1172' r='1' fill='#111111' />
      <circle opacity='0.2' cx='20.1168' cy='35.1172' r='1' fill='#111111' />
      <circle opacity='0.2' cx='18.1168' cy='23.1172' r='2' fill='#111111' />
      <circle opacity='0.2' cx='21.6168' cy='7.61719' r='1.5' fill='#111111' />
      <circle opacity='0.9' cx='30' cy='30' r='12' fill='#ED290E' />
      <path d='M27 27L33 33' stroke='white' strokeWidth='2' />
      <path d='M33 27L27 33' stroke='white' strokeWidth='2' />
    </svg>
  );
}
