import { useTranslate } from '@va/localization';

type NoDataMessageProps = {
  message?: string;
  type?: 'card' | 'panel';
};

/** @deprecated */
export const NoDataMessage = ({ message, type = 'card' }: NoDataMessageProps) => {
  const translate = useTranslate();

  return (
    <div className={`${type}-no-data-container`}>
      <div className='no-data-subtitle'>{message || translate('card.noData.title')}</div>
    </div>
  );
};
