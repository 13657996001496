import { connect } from 'react-redux';

import { removeNotification, resetClearNotifications } from '@va/dashboard/actions/ui';
import { getNotification, needToClearNotifications } from '@va/dashboard/selectors/ui';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import NotificationSystemWrapper from './NotificationSystemWrapperComponent';

const mapStateToProps = (state) => {
  return {
    notification: getNotification(state),
    needToClearNotifications: needToClearNotifications(state),
  };
};

const mapDispatchToProps = {
  removeNotificationFromStore: removeNotification,
  resetClearNotifications: resetClearNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(NotificationSystemWrapper));
