import classNames from 'classnames';
import { CHARCOAL_COLOR, CODE_COLOR } from '../constants';

const getColor = (isActive?: boolean) => (isActive ? CODE_COLOR : CHARCOAL_COLOR);

type TabIconProps = { isActive?: boolean; color?: string; className?: string };

export function TabTrafficShare({ isActive, color, className }: TabIconProps) {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.99995 15L13.3695 7.71744C15.4073 4.32102 12.9608 0 8.99995 0C5.03908 0 2.59256 4.32102 4.63041 7.71744L8.99995 15ZM8.99995 12.0845L12.0832 6.94569C13.5212 4.54906 11.7949 1.5 8.99995 1.5C6.20502 1.5 4.47867 4.54906 5.91665 6.94569L8.99995 12.0845ZM3.59983 9.89972C1.41392 10.7207 0 12.0278 0 13.5C0 15.9853 4.02944 18 9 18C13.9706 18 18 15.9853 18 13.5C18 12.0278 16.5861 10.7207 14.4002 9.89972L13.5424 11.1864C13.962 11.3277 14.3474 11.4868 14.6931 11.6596C16.1624 12.3943 16.5 13.1316 16.5 13.5C16.5 13.8684 16.1624 14.6057 14.6931 15.3403C13.3136 16.0301 11.3029 16.5 9 16.5C6.69709 16.5 4.68636 16.0301 3.30686 15.3403C1.83764 14.6057 1.5 13.8684 1.5 13.5C1.5 13.1316 1.83764 12.3943 3.30686 11.6596C3.65258 11.4868 4.03795 11.3277 4.45763 11.1864L3.59983 9.89972ZM9 6.5C9.82843 6.5 10.5 5.82843 10.5 5C10.5 4.17157 9.82843 3.5 9 3.5C8.17157 3.5 7.5 4.17157 7.5 5C7.5 5.82843 8.17157 6.5 9 6.5Z'
        fill={getColor(isActive)}
      />
    </svg>
  );
}

export function TabTrafficSession({ isActive, color, className }: TabIconProps) {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 5.11099 13.54 1.91332 9.75 1.53703V4.5H8.25V0H9H9.75V0.0308053C14.3696 0.411918 18 4.282 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 6.51472 1.00736 4.26472 2.63604 2.63604L3.6967 3.6967C2.33947 5.05393 1.5 6.92893 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM5.81804 12.182C6.11093 12.4749 6.11093 12.9497 5.81804 13.2426C5.52515 13.5355 5.05027 13.5355 4.75738 13.2426C4.46449 12.9497 4.46449 12.4749 4.75738 12.182C5.05027 11.8891 5.52515 11.8891 5.81804 12.182ZM13.2427 5.81802C13.5356 5.52513 13.5356 5.05025 13.2427 4.75736C12.9498 4.46447 12.4749 4.46447 12.182 4.75736C11.8891 5.05025 11.8891 5.52513 12.182 5.81802C12.4749 6.11091 12.9498 6.11091 13.2427 5.81802ZM13.2427 12.182C13.5356 12.4749 13.5356 12.9497 13.2427 13.2426C12.9498 13.5355 12.4749 13.5355 12.182 13.2426C11.8891 12.9497 11.8891 12.4749 12.182 12.182C12.4749 11.8891 12.9498 11.8891 13.2427 12.182ZM9.75 14.25C9.75 13.8358 9.41421 13.5 9 13.5C8.58579 13.5 8.25 13.8358 8.25 14.25C8.25 14.6642 8.58579 15 9 15C9.41421 15 9.75 14.6642 9.75 14.25ZM14.25 8.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75C13.8358 9.75 13.5 9.41421 13.5 9C13.5 8.58579 13.8358 8.25 14.25 8.25ZM4.5 9C4.5 8.58579 4.16421 8.25 3.75 8.25C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75C4.16421 9.75 4.5 9.41421 4.5 9ZM7.37171 7.37171L8.08139 9.50074C8.34516 10.2921 9.34951 10.5292 9.93934 9.93934C10.5292 9.34951 10.2921 8.34516 9.50074 8.08138L7.37171 7.37171ZM5.59293 6.77878L6.65836 9.97508C7.27476 11.8243 9.62169 12.3783 11 11C12.3783 9.62169 11.8243 7.27476 9.97508 6.65836L6.77878 5.59293L5 5L5.59293 6.77878Z'
        fill={getColor(isActive)}
      />
    </svg>
  );
}

export function TabTrafficPagesVisit({ isActive, color, className }: TabIconProps) {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 4.5V13.5C1.5 14.3284 2.17157 15 3 15H9C9.82843 15 10.5 14.3284 10.5 13.5V9V4.5C10.5 3.67157 9.82843 3 9 3H6H3C2.17157 3 1.5 3.67157 1.5 4.5ZM3 1.5C1.34315 1.5 0 2.84315 0 4.5V13.5C0 15.1569 1.34315 16.5 3 16.5H9C10.6569 16.5 12 15.1569 12 13.5V9V4.5C12 2.84315 10.6569 1.5 9 1.5H6H3ZM13.5 3.75C13.5 3.33579 13.8358 3 14.25 3C14.6642 3 15 3.33579 15 3.75V14.25C15 14.6642 14.6642 15 14.25 15C13.8358 15 13.5 14.6642 13.5 14.25V3.75ZM17.25 4.5C16.8358 4.5 16.5 4.83579 16.5 5.25V12.75C16.5 13.1642 16.8358 13.5 17.25 13.5C17.6642 13.5 18 13.1642 18 12.75V5.25C18 4.83579 17.6642 4.5 17.25 4.5Z'
        fill={getColor(isActive)}
      />
    </svg>
  );
}

export function TabTrafficBounceRate({ isActive, color, className }: TabIconProps) {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.75 0.75C9.75 0.335786 9.41421 0 9 0C8.58579 0 8.25 0.335786 8.25 0.75C8.25 1.16421 8.58579 1.5 9 1.5C9.41421 1.5 9.75 1.16421 9.75 0.75ZM0.75 9.75C1.16421 9.75 1.5 9.41421 1.5 9C1.5 8.58579 1.16421 8.25 0.75 8.25C0.335786 8.25 0 8.58579 0 9C0 9.41421 0.335786 9.75 0.75 9.75ZM17.25 9.75C17.6642 9.75 18 9.41421 18 9C18 8.58579 17.6642 8.25 17.25 8.25C16.8358 8.25 16.5 8.58579 16.5 9C16.5 9.41421 16.8358 9.75 17.25 9.75ZM9 16.5C9.41421 16.5 9.75 16.8358 9.75 17.25C9.75 17.6642 9.41421 18 9 18C8.58579 18 8.25 17.6642 8.25 17.25C8.25 16.8358 8.58579 16.5 9 16.5ZM6.00956 0.508762C3.44464 1.41207 1.41209 3.44464 0.508789 6.00956C0.588359 6.00323 0.668803 6 0.75 6C1.19995 6 1.62677 6.09906 2.00983 6.27656C2.77068 4.32512 4.32512 2.77068 6.27655 2.00981C6.09905 1.62675 6 1.19994 6 0.75C6 0.668793 6.00323 0.58834 6.00956 0.508762ZM11.7235 2.00978C13.6749 2.77063 15.2294 4.32508 15.9902 6.27652C16.3733 6.09904 16.8001 6 17.25 6C17.3312 6 17.4117 6.00323 17.4913 6.00956C16.588 3.44461 14.5554 1.41202 11.9904 0.50873C11.9968 0.588319 12 0.668782 12 0.75C12 1.19993 11.901 1.62673 11.7235 2.00978ZM15.9902 11.7235C15.2294 13.6749 13.6749 15.2294 11.7235 15.9902C11.901 16.3733 12 16.8001 12 17.25C12 17.3312 11.9968 17.4117 11.9904 17.4913C14.5554 16.588 16.588 14.5554 17.4913 11.9904C17.4117 11.9968 17.3312 12 17.25 12C16.8001 12 16.3733 11.901 15.9902 11.7235ZM2.00983 11.7234C2.77068 13.6749 4.32512 15.2293 6.27655 15.9902C6.09905 16.3732 6 16.8001 6 17.25C6 17.3312 6.00323 17.4117 6.00956 17.4912C3.44465 16.5879 1.41209 14.5554 0.508789 11.9904C0.588358 11.9968 0.668803 12 0.75 12C1.19995 12 1.62677 11.9009 2.00983 11.7234ZM12 9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9C6 7.34315 7.34315 6 9 6C10.6569 6 12 7.34315 12 9ZM13.5 9C13.5 11.4853 11.4853 13.5 9 13.5C6.51472 13.5 4.5 11.4853 4.5 9C4.5 6.51472 6.51472 4.5 9 4.5C11.4853 4.5 13.5 6.51472 13.5 9Z'
        fill={getColor(isActive)}
      />
    </svg>
  );
}

export function TabAccountSettings({ isActive, color, className }: TabIconProps) {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.8 13.1C14.9 12.2 13.9 11.6 12.7 11.1C14.4 10.1 15.5 8.2 15.5 6C15.5 5.4 15.4 4.7 15.2 4.1C14.4 1.7 12.2 0 9.50001 0C6.80001 0 4.60001 1.7 3.80001 4.1C3.60001 4.7 3.50001 5.4 3.50001 6C3.50001 8.1 4.60001 10 6.30001 11.1C5.10001 11.5 4.10001 12.2 3.20001 13.1C2.80001 13.5 2.50001 13.9 2.20001 14.3C2.50001 14.7 2.80001 15.1 3.20001 15.5C4.80001 17 7.10001 18 9.50001 18C11.9 18 14.2 17 15.8 15.4C16.2 15 16.5 14.6 16.8 14.2C16.5 13.8 16.2 13.4 15.8 13.1ZM5.00001 6C5.00001 3.7 6.80001 1.8 9.00001 1.5C9.20001 1.5 9.30001 1.5 9.50001 1.5C9.70001 1.5 9.80001 1.5 10 1.5C12.2 1.7 14 3.7 14 6C14 8.5 12 10.5 9.50001 10.5C7.00001 10.5 5.00001 8.5 5.00001 6ZM9.50001 16.5C7.40001 16.5 5.50001 15.6 4.10001 14.3C5.50001 12.9 7.40001 12 9.50001 12C11.6 12 13.5 12.9 14.8 14.2C13.5 15.6 11.6 16.5 9.50001 16.5Z'
        fill={getColor(isActive)}
      />
    </svg>
  );
}

export function TabManageWebsites({ isActive, color, className }: TabIconProps) {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 0C4.17157 0 3.5 0.671573 3.5 1.5H15.5C15.5 0.671573 14.8284 0 14 0H5ZM2 4.5C2 3.67157 2.67157 3 3.5 3H15.5C16.3284 3 17 3.67157 17 4.5H2ZM15.5 7.5H3.5C2.67157 7.5 2 8.17157 2 9V15C2 15.8284 2.67157 16.5 3.5 16.5H15.5C16.3284 16.5 17 15.8284 17 15V9C17 8.17157 16.3284 7.5 15.5 7.5ZM3.5 6C1.84315 6 0.5 7.34315 0.5 9V15C0.5 16.6569 1.84315 18 3.5 18H15.5C17.1569 18 18.5 16.6569 18.5 15V9C18.5 7.34315 17.1569 6 15.5 6H3.5Z'
        fill={getColor(isActive)}
      />
    </svg>
  );
}

export function TabAgencyCommission({ isActive, color, className }: TabIconProps) {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.05933 10.0593L10.9987 2.12H16.3774V7.49868L8.43801 15.438C7.85223 16.0238 6.90248 16.0238 6.31669 15.438L3.05933 12.1807C2.47355 11.5949 2.47355 10.6451 3.05933 10.0593ZM10.3774 0.619995H16.3774H17.8774V2.12V8.12L9.49868 16.4987C8.3271 17.6702 6.42761 17.6702 5.25603 16.4987L1.99867 13.2413C0.827102 12.0697 0.827102 10.1702 1.99867 8.99868L10.3774 0.619995ZM13.3774 6.62C14.2058 6.62 14.8774 5.94842 14.8774 5.12C14.8774 4.29157 14.2058 3.62 13.3774 3.62C12.5489 3.62 11.8774 4.29157 11.8774 5.12C11.8774 5.94842 12.5489 6.62 13.3774 6.62Z'
        fill={getColor(isActive)}
      />
    </svg>
  );
}

export function TabManageBilling({ isActive, color, className }: TabIconProps) {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.5 4.5L2.17705 5.33853L9.5 9L16.8229 5.33853L18.5 4.5L16.8229 3.66147L9.5 0L2.17705 3.66147L0.5 4.5ZM3.8541 4.5L9.5 7.32295L15.1459 4.5L9.5 1.67705L3.8541 4.5ZM3.5 7.5L2.17705 8.16147L0.5 9L2.17705 9.83853L9.5 13.5L16.8229 9.83853L18.5 9L16.8229 8.16147L15.5 7.5L13.8229 8.33853L15.1459 9L9.5 11.8229L3.8541 9L5.17705 8.33853L3.5 7.5ZM2.17705 12.6615L3.5 12L5.17705 12.8385L3.8541 13.5L9.5 16.3229L15.1459 13.5L13.8229 12.8385L15.5 12L16.8229 12.6615L18.5 13.5L16.8229 14.3385L9.5 18L2.17705 14.3385L0.5 13.5L2.17705 12.6615Z'
        fill={getColor(isActive)}
      />
    </svg>
  );
}

export function TabConsent({ isActive, color, className }: TabIconProps) {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 14.1213V16.5H4.37868L14.659 6.21967L12.2803 3.84099L2 14.1213ZM16.5607 4.31802L15.7197 5.15901L13.341 2.78033L14.182 1.93934C14.4633 1.65804 14.8448 1.5 15.2426 1.5H15.5C16.3284 1.5 17 2.17157 17 3V3.25736C17 3.65518 16.842 4.03672 16.5607 4.31802ZM5 18L17.6213 5.37868C18.1839 4.81607 18.5 4.05301 18.5 3.25736V3C18.5 1.34315 17.1569 0 15.5 0H15.2426C14.447 0 13.6839 0.316071 13.1213 0.87868L0.5 13.5V16.5V18H2H5ZM17.75 18H8L9.5 16.5H17.75C18.1642 16.5 18.5 16.8358 18.5 17.25C18.5 17.6642 18.1642 18 17.75 18Z'
        fill={color || getColor(isActive)}
      />
    </svg>
  );
}
