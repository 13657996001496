import { nonTranslatableText } from '@va/constants';
import { getApiRequestImmutable } from '@va/dashboard/selectors/api';
import { getCurrentPlanName } from '@va/dashboard/selectors/app';
import { getProviderName } from '@va/dashboard/selectors/customization';
import Translation from '@va/deprecated/components/Translation';
import { useLocale } from '@va/localization';
import { subscriptionStatus } from '@va/standalone/shared/constants';
import { addNumberSeparator, getViewOnWixUrl } from '@va/util/helpers';
import { getMetaSiteId } from '@va/wix/shared/selectors';
import { useSelector } from 'react-redux';

export const UpgradeToHigherPackageBadgeTextContent = () => {
  const { locale } = useLocale();
  const currentPlanName = useSelector((state) => getCurrentPlanName(state, subscriptionStatus.ACTIVE));
  const permissions = useSelector((state) => getApiRequestImmutable(state, 'getFeaturePermissions'));
  const metaSiteId = useSelector(getMetaSiteId);
  const providerName = useSelector(getProviderName);

  return (
    <Translation
      translationKey='topNav.badge.wixClaimed.upgradeBehaviourFeature'
      parameters={{
        currentPlanName: currentPlanName,
        currentRecordingsCount: addNumberSeparator(permissions.getIn(['recordings', 'limit'], 0), locale),
        currentHeatmapsCount: addNumberSeparator(permissions.getIn(['heatmaps', 'limit'], 0), locale),
        currentFunnelsCount: addNumberSeparator(permissions.getIn(['funnels', 'limit'], 0), locale),
        currentPollsCount: addNumberSeparator(permissions.getIn(['polls', 'limit'], 0), locale),
        currentSurveysCount: addNumberSeparator(permissions.getIn(['surveys', 'limit'], 0), locale),
        visitorAnalytics: nonTranslatableText.appName,
        upgradeOnWixUrl: getViewOnWixUrl(metaSiteId),
        className: 'bold',
        providerName: providerName,
      }}
    />
  );
};
