import { createColumnHelper } from '@tanstack/react-table';
import { getTransactionStatus } from '@va/dashboard/util-helpers/invoices';
import { CheckmarkIcon, Clock, GlobeIcon } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { BILLING_CURRENCY_SYMBOL } from '@va/standalone/shared/constants';
import { HeaderCellV8 } from '@va/ui/components/data-table';
import { IconWithBackground, ListCardItemWithIcon, Paragraph } from '@va/ui/design-system';
import { getFormattedDateAndTime, getUrlWithoutProtocolAndTrailingSlash } from '@va/util/helpers';
import moment from 'moment';
import { useMemo } from 'react';
import { StatusButton } from '../agency-white-label-v2/components/TrackingCodeStatus';
import { DownloadInvoiceButton } from './DownloadInvoiceButton';
import { WebsiteInvoicesTypes } from './invoices-api-client';

const columnHelper = createColumnHelper<WebsiteInvoicesTypes>();

export const useWebsiteInvoicesColumns = () => {
  const translate = useTranslate();
  const { locale } = useLocale();
  const columns = useMemo(
    () => [
      columnHelper.accessor('date', {
        meta: {
          cellClassName: '!min-w-210',
        },
        header: (context) => (
          <HeaderCellV8
            context={context}
            text={translate('standalone.accountSettings.invoices.websiteInvoices.table.headers.date')}
          />
        ),
        cell: ({ getValue }) => {
          const unixTs = getValue().getTime() / 1000;
          return (
            <ListCardItemWithIcon
              icon={
                <IconWithBackground
                  className='bg-white-snow group-hover:bg-white'
                  icon={() => <Clock strokeColor='#696969' />}
                />
              }
              topText={getFormattedDateAndTime(unixTs, locale)}
            />
          );
        },
        enableSorting: true,
      }),
      columnHelper.accessor('websiteUrl', {
        meta: {
          cellClassName: '!justify-start !min-w-210',
        },
        header: (context) => (
          <HeaderCellV8
            context={context}
            text={translate('standalone.accountSettings.invoices.websiteInvoices.table.headers.websiteUrl')}
          />
        ),
        cell: ({ getValue }) => (
          <ListCardItemWithIcon
            icon={
              <IconWithBackground
                className='bg-white-snow group-hover:bg-white'
                icon={() => <GlobeIcon color='#696969' />}
              />
            }
            topText={getUrlWithoutProtocolAndTrailingSlash(getValue())}
          />
        ),
        enableSorting: true,
      }),
      columnHelper.accessor('amount', {
        header: (context) => (
          <HeaderCellV8
            context={context}
            text={translate('standalone.accountSettings.invoices.websiteInvoices.table.headers.amount')}
          />
        ),
        cell: ({ getValue }) => <Paragraph>{`${BILLING_CURRENCY_SYMBOL}${getValue()}`}</Paragraph>,
        enableSorting: true,
      }),
      columnHelper.accessor('transactionStatus', {
        header: (context) => (
          <HeaderCellV8
            context={context}
            text={translate('standalone.accountSettings.invoices.websiteInvoices.table.headers.status')}
          />
        ),
        cell: ({ row }) => {
          const { amount, transactionStatus } = row.original;
          return (
            <StatusButton
              icon={<CheckmarkIcon color='#06A25A' />}
              text={translate(`card.invoices.status.${getTransactionStatus(transactionStatus, amount)}`)}
              isActive
            />
          );
        },
        enableSorting: true,
      }),
      columnHelper.group({
        id: 'type',
        header: (context) => (
          <HeaderCellV8
            context={context}
            text={translate('standalone.accountSettings.invoices.websiteInvoices.table.headers.receiptView')}
          />
        ),
        cell: ({ row }) => <DownloadInvoiceButton invoice={row.original} />,
      }),
    ],
    [locale, translate],
  );

  return columns;
};
