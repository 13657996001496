import classNames from 'classnames';
import React from 'react';
import { IconProps } from '.';

export const ProcessIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 5C3.82843 5 4.5 4.32843 4.5 3.5C4.5 2.67157 3.82843 2 3 2C2.17157 2 1.5 2.67157 1.5 3.5C1.5 4.32843 2.17157 5 3 5ZM5.90549 4.25C5.57245 5.54392 4.39788 6.5 3 6.5C1.34315 6.5 0 5.15685 0 3.5C0 1.84315 1.34315 0.5 3 0.5C4.39788 0.5 5.57245 1.45608 5.90549 2.75H12.75C14.8211 2.75 16.5 4.42893 16.5 6.5C16.5 8.57107 14.8211 10.25 12.75 10.25H5.25C4.00736 10.25 3 11.2574 3 12.5C3 13.7426 4.00736 14.75 5.25 14.75H12.0945C12.4275 13.4561 13.6021 12.5 15 12.5C16.6569 12.5 18 13.8431 18 15.5C18 17.1569 16.6569 18.5 15 18.5C13.6021 18.5 12.4275 17.5439 12.0945 16.25H5.25C3.17893 16.25 1.5 14.5711 1.5 12.5C1.5 10.4289 3.17893 8.75 5.25 8.75H12.75C13.9926 8.75 15 7.74264 15 6.5C15 5.25736 13.9926 4.25 12.75 4.25H5.90549Z'
        fill={color}
      />
    </svg>
  );
};


