import { apiGateway } from '@va/api-client';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { getAccessToken } from '@va/standalone/shared/helpers';
import { useAsyncFunction, useFetchData } from '@va/util/hooks';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export type SubscriberResponse = {
  wixPromotions?: boolean;
  wixNewsletter?: boolean;
  standalonePromotions?: boolean;
  standaloneNewsletter?: boolean;
};

export enum SubscriptionList {
  standalonePromotions = 'PromotionsStandalone',
  standaloneNewsletter = 'NewsletterStandalone',
  wixNewsletter = 'NewsletterWix',
  wixPromotions = 'PromotionsWix',
}

type SubscriberPayload = {
  lists: SubscriptionList[];
};

const getConfig = () => {
  return {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
};

export const useNewsletterSubscriberWix = () => {
  const websiteId = useSelector(getInstanceId);
  const func = useCallback(async (url: string) => {
    const res = await apiGateway.get(url, getConfig());
    if (!res) return [];
    return res;
  }, []);

  return useFetchData<SubscriberResponse>(
    `v1/websites/${websiteId}/newsletter/subscriber`,
    {
      revalidateOnMount: true,
      revalidateIfStale: true,
    },
    undefined,
    func,
  );
};

export const useNewsletterSubscriberSA = () => {
  const func = useCallback(async (url: string) => {
    const res = await apiGateway.get(url, getConfig());
    if (!res) return [];
    return res;
  }, []);

  return useFetchData<SubscriberResponse>(
    `v1/newsletter/subscriber`,
    {
      revalidateOnMount: true,
      revalidateIfStale: true,
    },
    undefined,
    func,
  );
};

export const useSubscribeToNewsletterWix = () => {
  const websiteId = useSelector(getInstanceId);

  const func = useCallback(
    (data: SubscriberPayload): Promise<SubscriberResponse> => {
      return apiGateway.post(`v1/websites/${websiteId}/newsletter/subscribe`, data, getConfig());
    },
    [websiteId],
  );
  return useAsyncFunction<typeof func>(func);
};

export const useUnsubscribeToNewsletterWix = () => {
  const websiteId = useSelector(getInstanceId);

  const func = useCallback(
    (data: SubscriberPayload): Promise<SubscriberResponse> => {
      return apiGateway.post(`v1/websites/${websiteId}/newsletter/unsubscribe`, data, getConfig());
    },
    [websiteId],
  );
  return useAsyncFunction<typeof func>(func);
};

export const useSubscribeToNewsletterSA = () => {
  const func = useCallback((data: SubscriberPayload): Promise<SubscriberResponse> => {
    return apiGateway.post(`v1/newsletter/subscribe`, data, getConfig());
  }, []);
  return useAsyncFunction<typeof func>(func);
};

export const useUnsubscribeToNewsletterSA = () => {
  const func = useCallback((data: SubscriberPayload): Promise<SubscriberResponse> => {
    return apiGateway.post(`v1/newsletter/unsubscribe`, data, getConfig());
  }, []);
  return useAsyncFunction<typeof func>(func);
};
