import { DataTablePaginationV2 } from '@va/ui/design-system';
import { useManageWebsitesContext } from './ManageWebsiteContext';

const ManageWebsitesPagination = ({
  showCountInfo = true,
  showPageSizeSelector = true,
  showPaginationBtns = true,
  className,
}: {
  showCountInfo?: boolean;
  showPageSizeSelector?: boolean;
  showPaginationBtns?: boolean;
  className?: string;
}) => {
  const { totalWebsites, websiteFilterQuery, setWebsiteFilterQuery, offset, setOffset } = useManageWebsitesContext();

  const { length: pageSize } = websiteFilterQuery;

  return (
    <DataTablePaginationV2
      total={totalWebsites}
      totalPages={Math.ceil(totalWebsites / pageSize)}
      currentPage={offset}
      pageSize={pageSize}
      setCurrentPage={(value) => {
        setOffset(value);
        setWebsiteFilterQuery((prevFilters) => {
          return { ...prevFilters, start: value * pageSize };
        });
      }}
      setPageSize={(value) => {
        setOffset(0);
        setWebsiteFilterQuery((prevFilters) => {
          return { ...prevFilters, start: 0, length: value };
        });
      }}
      showCountInfo={showCountInfo}
      showPageSizeSelector={showPageSizeSelector}
      showPaginationBtns={showPaginationBtns}
      className={className}
    />
  );
};

export default ManageWebsitesPagination;
