import { FastField } from 'formik';
import PropTypes from 'prop-types';
import Rcslider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useEffect } from 'react';
import Setting from './Setting';
import './SliderSetting.scss';

/** @deprecated */
const SliderSetting = ({ children, description, name, min = 0, max = 10, initialValue }) => {
  return (
    <Setting
      description={description}
      field={
        <FastField name={name}>
          {({ field, form }) => (
            <div className='flex w-16 ml-auto'>
              <div className='w-full'>
                <Slider min={min} max={max} field={field} form={form} name={name} initialValue={initialValue} />
              </div>
              <div className='ml-4 text-devil-gray text font-medium text-sm'>{initialValue || field.value}</div>
            </div>
          )}
        </FastField>
      }
    >
      {children}
    </Setting>
  );
};
SliderSetting.propTypes = {
  children: PropTypes.string,
  description: PropTypes.string,
  name: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default SliderSetting;

const Slider = ({ field, form, min, max, name, initialValue }) => {
  useEffect(() => {
    if (!field.value) form.setFieldValue(name, min);
  }, []);

  return (
    <Rcslider
      className='simple-slider'
      onChange={(value) => {
        return form.setFieldValue(name, value);
      }}
      min={min}
      max={max}
      defaultValue={initialValue || min}
    />
  );
};
