import { EditorIcon, EyeIcon, SharpStartIcon } from '@va/icons';
import { UserRole as UserRoleEnum } from '@va/types/website';
import { fontWeights, ParagraphWithTooltip } from '@va/ui/design-system';

const UserRole = ({ role }: { role: UserRoleEnum }) => {
  return (
    <div className='inline-flex gap-1.5 items-center'>
      {userRoleIcon(role, '#696969', 'h-3 w-3')}
      <ParagraphWithTooltip colorClassName='text-gray-charcoal' weight={fontWeights.semibold}>
        {role.charAt(0).toUpperCase() + role.slice(1)}
      </ParagraphWithTooltip>
    </div>
  );
};

export default UserRole;

const userRoleIcon = (role: UserRoleEnum, color?: string, className?: string) => {
  const commonProps = {
    className,
    color,
  };
  switch (role) {
    case UserRoleEnum.OWNER:
      return <SharpStartIcon {...commonProps} />;
    case UserRoleEnum.EDITOR:
      return <EditorIcon {...commonProps} />;
    case UserRoleEnum.WATCHER:
      return <EyeIcon {...commonProps} />;
    default:
      return null;
  }
};
