import { IconProps } from '.';

export const HorizontalBarGraphIcon = ({ color = 'black', className }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 4.5V13.5C16.5 15.16 15.16 16.5 13.5 16.5H4.5C2.84 16.5 1.5 15.16 1.5 13.5V4.5C1.5 2.84 2.84 1.5 4.5 1.5H13.5C15.16 1.5 16.5 2.84 16.5 4.5ZM18 13.5C18 15.99 15.99 18 13.5 18H4.5C2.01 18 0 15.99 0 13.5V4.5C0 2.01 2.01 0 4.5 0H13.5C15.99 0 18 2.01 18 4.5V13.5ZM8.25 11.25C8.66 11.25 9 10.91 9 10.5C9 10.09 8.66 9.75 8.25 9.75H3.75C3.34 9.75 3 10.09 3 10.5C3 10.91 3.34 11.25 3.75 11.25H8.25ZM12 7.5C12 7.91 11.66 8.25 11.25 8.25H3.75C3.34 8.25 3 7.91 3 7.5C3 7.09 3.34 6.75 3.75 6.75H11.25C11.66 6.75 12 7.09 12 7.5ZM14.25 5.25C14.66 5.25 15 4.91 15 4.5C15 4.09 14.66 3.75 14.25 3.75H3.75C3.34 3.75 3 4.09 3 4.5C3 4.91 3.34 5.25 3.75 5.25H14.25ZM6 13.5C6 13.91 5.66 14.25 5.25 14.25H3.75C3.34 14.25 3 13.91 3 13.5C3 13.09 3.34 12.75 3.75 12.75H5.25C5.66 12.75 6 13.09 6 13.5Z'
        fill={color}
      />
    </svg>
  );
};
