import PropTypes from 'prop-types';

export function InformationIconComponent(props) {
  const fillColor = props.fill || '#13232A';
  if (props.hovered) {
    return (
      <svg
        style={props.style}
        className={props.className}
        width='20px'
        height='20px'
        viewBox='0 0 20 20'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <g id='symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='icon/-info/black-hover' fill={fillColor}>
            <g id='Page-1'>
              <path
                d='M10,0 C4.486,0 0,4.486 0,10 C0,15.514 4.486,20 10,20 C15.514,20 20,15.514 20,10 C20,4.486 15.514,0 10,0 M10,0.918 C15.008,0.918 19.082,4.992 19.082,10 C19.082,15.008 15.008,19.082 10,19.082 C4.992,19.082 0.918,15.008 0.918,10 C0.918,4.992 4.992,0.918 10,0.918'
                id='Fill-1'
              />
              <path
                d='M10.6514,6.8193 C10.4684,7.0023 10.2524,7.0923 10.0004,7.0923 C9.7484,7.0923 9.5314,7.0023 9.3494,6.8193 C9.1684,6.6373 9.0764,6.4213 9.0764,6.1693 C9.0764,5.9173 9.1684,5.6993 9.3494,5.5173 C9.5314,5.3363 9.7484,5.2443 10.0004,5.2443 C10.2524,5.2443 10.4684,5.3363 10.6514,5.5173 C10.8344,5.6993 10.9234,5.9173 10.9234,6.1693 C10.9234,6.4213 10.8344,6.6373 10.6514,6.8193 Z M9.3694,15.1843 L10.6304,15.1843 L10.6304,8.5483 L9.3694,8.5483 L9.3694,15.1843 Z M10.0004,2.1603 C5.6704,2.1603 2.1604,5.6703 2.1604,10.0003 C2.1604,14.3303 5.6704,17.8403 10.0004,17.8403 C14.3304,17.8403 17.8394,14.3303 17.8394,10.0003 C17.8394,5.6703 14.3304,2.1603 10.0004,2.1603 Z'
                id='Fill-3'
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }

  return (
    <svg
      style={props.style}
      className={props.className}
      width='20px'
      height='20px'
      viewBox='0 0 20 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='icon/information-black'>
          <g id='Group'>
            <text id='i' fontFamily='Avenir-Medium, Avenir' fontSize='14' fontWeight='400' fill={fillColor}>
              <tspan x='8' y='15'>
                i
              </tspan>
            </text>
            <rect id='Rectangle-6' stroke={fillColor} x='0.5' y='0.5' width='19' height='19' rx='9.5' />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function FaqIcon(props) {
  const { color, ...restProps } = props;
  const colorValue = color ? color : '#000000';

  return (
    <svg {...restProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 9C16.5 10.801 15.8652 12.4536 14.8072 13.7465L12.6681 11.6074C13.1919 10.8718 13.5 9.97187 13.5 9C13.5 8.02813 13.1919 7.12821 12.6681 6.3926L14.8072 4.25345C15.8652 5.54635 16.5 7.19905 16.5 9ZM9 16.5C10.801 16.5 12.4536 15.8652 13.7465 14.8072L11.6074 12.6681C10.8718 13.1919 9.97187 13.5 9 13.5C8.02813 13.5 7.12821 13.1919 6.3926 12.6681L4.25345 14.8072C5.54635 15.8652 7.19905 16.5 9 16.5ZM3.19279 13.7465C2.13477 12.4536 1.5 10.801 1.5 9C1.5 7.19905 2.13477 5.54635 3.19279 4.25345L5.33194 6.3926C4.80809 7.12821 4.5 8.02813 4.5 9C4.5 9.97187 4.80809 10.8718 5.33194 11.6074L3.19279 13.7465ZM6.3926 5.33194C7.12821 4.80809 8.02813 4.5 9 4.5C9.97187 4.5 10.8718 4.80809 11.6074 5.33194L13.7465 3.19279C12.4536 2.13477 10.801 1.5 9 1.5C7.19905 1.5 5.54635 2.13477 4.25345 3.19279L6.3926 5.33194ZM18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM12 9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9C6 7.34315 7.34315 6 9 6C10.6569 6 12 7.34315 12 9Z'
        fill={colorValue}
      />
    </svg>
  );
}

InformationIconComponent.propTypes = {
  hovered: PropTypes.bool,
  fill: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.string,
};
