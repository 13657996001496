import { CommonFilterParams, Filter, FilterOperators } from '@va/types/filters';
import { TextFilterInputApplied } from '../text-filter';
import { ComparisonFilterInput } from './ComparisonFilterInput';

export type ComparisonFilterValue = number;

export type ComparisonFilterInputProps = {
  type: 'text' | 'number';
};

type ComparisonFilterParams = CommonFilterParams<ComparisonFilterValue, ComparisonFilterInputProps>;

export class ComparisonFilter extends Filter<ComparisonFilterValue, ComparisonFilterInputProps> {
  constructor(params: ComparisonFilterParams) {
    super({
      ...params,
      operator: params.operator || FilterOperators.equal,
      input: ComparisonFilterInput,
      appliedInput: TextFilterInputApplied,
    });
  }
}
