import { SOURCE_TAB } from '@va/constants';
import {
  CountryLocationIcon,
  DetailsCell,
  PageVisitsInSessionCell,
  VideoRecordingButton,
} from '@va/dashboard/components';
import { useLocale, useTranslate } from '@va/localization';
import { VisitorTypes } from '@va/types/visitors';
import { Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { getFormattedDateAndTime } from '@va/util/helpers';
import { LatestVisitorsItem } from '../LatestVisitorsTable';
import { Ip2CompanyInfo, IpValueNode } from '../cells';

type VisitorsListItemProps = {
  visitor: LatestVisitorsItem;
};

export const VisitorsListItem = ({ visitor }: VisitorsListItemProps) => {
  const {
    countryCode,
    location,
    status,
    pageVisit: { unixTs },
    companyOrgName,
    companyOrgType,
    browser,
    device,
    platform,
    id,
    pageVisitsInSession,
    hasRecording,
    ip,
  } = visitor;
  const translate = useTranslate();
  const { locale } = useLocale();

  return (
    <div className='w-full'>
      <div className='flex gap-3'>
        <div className='shrink-0'>
          <CountryLocationIcon countryCode={countryCode} location={location} />
        </div>
        <div className='w-full overflow-hidden'>
          <div>
            <Paragraph className='inline mr-1' weight={fontWeights.medium}>
              {translate(`card.latestVisits.${VisitorTypes[status]}`)}
            </Paragraph>
            <Paragraph colorClassName='text-gray-devil' size={paragraphSizes.tiny} className='inline'>
              {getFormattedDateAndTime(unixTs, locale)}
            </Paragraph>
          </div>
          <div className=''>
            <IpValueNode className='text-12 text-gray-charcoal' ip={ip} countryCode={countryCode} />
            <Ip2CompanyInfo companyName={companyOrgName} companyType={companyOrgType} />
          </div>
        </div>
      </div>
      <div className='pl-12 flex gap-3 justify-between w-full'>
        <DetailsCell className='!gap-0' browser={browser} device={device} platform={platform} smallVersion={true} />
        <div className='flex items-center gap-1 sm-initial:gap-2'>
          <PageVisitsInSessionCell sessionId={id} pageVisitsCount={pageVisitsInSession} countryCode={countryCode} />
          <VideoRecordingButton
            sourceTab={SOURCE_TAB.LATEST_VISITORS}
            sessionInfo={{ unixTs, id, hasRecording: hasRecording }}
          />
        </div>
      </div>
    </div>
  );
};
