import { useTranslate } from '@va/localization';
import { ConfirmationDialogType, ConfirmationModal } from '@va/shared/util-confirmation';

export const DeleteFilterTemplateConfirmationDialog: ConfirmationDialogType = ({ onClose, onConfirm }) => {
  const translate = useTranslate();

  return (
    <ConfirmationModal
      title={translate('all.filterTemplates.delete.title')}
      info={translate('all.filterTemplates.delete.info')}
      onClose={onClose}
      isOpen={true}
      onConfirmation={onConfirm}
    />
  );
};
