export const LevelIcon = ({ className = '', color = '#F0F0F0' }: { className?: string; color?: string }) => {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 24C5.56463e-07 17.6348 2.52856 11.5303 7.02944 7.02944C11.5303 2.52856 17.6348 -7.59042e-08 24 0V4.5C18.8283 4.5 13.8684 6.55446 10.2114 10.2114C6.55446 13.8684 4.5 18.8283 4.5 24H0Z'
        fill={color}
      />
    </svg>
  );
};
