import { startUpgradeProcess } from '@va/dashboard/actions/app';
import { clearNotifications } from '@va/dashboard/actions/ui';
import Translation from '@va/deprecated/components/Translation';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import { isWixClaimed } from '@va/standalone/shared/selectors';
import { TooltipWrapper } from '@va/ui/tooltips';
import PropTypes from 'prop-types';
import { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';

export class NotificationLimitChangeSubscriptionButton extends PureComponent {
  getTranslationKey() {
    if (this.props.isWixClaimed) {
      return this.props.translate('notifications.visitsLimit.manageSubscriptionOnWix');
    }
    return this.props.translate('notifications.visitsLimit.manageSubscription');
  }
  openUpgradeModalAction() {
    this.props.clearNotifications();
    this.props.startUpgradeProcess();
  }

  renderContent() {
    return (
      <Translation
        translationKey={this.getTranslationKey()}
        onClick={() => (this.props.isDisabled ? null : this.openUpgradeModalAction())}
      />
    );
  }
  render() {
    const { tooltipData, isDisabled, translate } = this.props;
    return (
      <Fragment>
        {isDisabled ? (
          <TooltipWrapper interactive content={translate(tooltipData)}>
            <div>{this.renderContent()}</div>
          </TooltipWrapper>
        ) : (
          this.renderContent()
        )}
      </Fragment>
    );
  }
}

NotificationLimitChangeSubscriptionButton.propTypes = {
  translate: PropTypes.func.isRequired,
  startUpgradeProcess: PropTypes.func.isRequired,
  isWixClaimed: PropTypes.bool.isRequired,
  clearNotifications: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  tooltipData: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isWixClaimed: isWixClaimed(state),
  };
};

const mapDispatchToProps = {
  startUpgradeProcess: startUpgradeProcess,
  clearNotifications: clearNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(NotificationLimitChangeSubscriptionButton));
