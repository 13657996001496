import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { apiStatus } from '@va/constants';
import { resetRequestStatus } from '@va/dashboard/actions/api';
import { closeModal, openModal } from '@va/dashboard/actions/ui';
import { isModalOpen } from '@va/dashboard/selectors/ui';
import ModalWrapper from '@va/dashboard/shared/modals/ModalWrapper';
import LoadingPopUpCard from '@va/deprecated/components/PopUpCard/card-types/LoadingCard';
import MessageCard from '@va/deprecated/components/PopUpCard/card-types/MessageCard';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import { modalName } from '@va/standalone/shared/constants';
import * as Selectors from '@va/standalone/shared/selectors';
import { renderIf, successOrFailedStatus } from '@va/util/helpers';

class ReactivateSubscriptionContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.onModalClose = this.onModalClose.bind(this);
  }

  onModalClose() {
    if (this.props.reactivateSubscriptionStatus === apiStatus.IN_PROGRESS) {
      return;
    }

    this.props.resetRequestStatus('reactivateSubscription');
    this.props.closeModal();
  }

  render() {
    const { translate, reactivateSubscriptionStatus } = this.props;
    const showLoadingView = renderIf(reactivateSubscriptionStatus === apiStatus.IN_PROGRESS);
    const showFeedbackView = renderIf(successOrFailedStatus(reactivateSubscriptionStatus));

    return (
      <ModalWrapper
        isOpen={this.props.isModalOpen}
        closeModal={this.onModalClose}
        showCloseButton={reactivateSubscriptionStatus !== apiStatus.IN_PROGRESS}
      >
        <React.Fragment>
          {showLoadingView(<LoadingPopUpCard />)}
          {showFeedbackView(
            <MessageCard
              modalName={modalName.REACTIVATE_SUBSCRIPTION}
              translate={translate}
              onClose={this.onModalClose}
              apiError={reactivateSubscriptionStatus === apiStatus.FAILED}
            />,
          )}
        </React.Fragment>
      </ModalWrapper>
    );
  }
}

ReactivateSubscriptionContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isModalOpen: isModalOpen(state, modalName.REACTIVATE_SUBSCRIPTION),
    reactivateSubscriptionStatus: Selectors.standalone.Api.getApiRequestStatus(state, 'reactivateSubscription'),
  };
};

const mapDispatchToProps = {
  closeModal: () => closeModal(modalName.REACTIVATE_SUBSCRIPTION),
  openModal: openModal,
  resetRequestStatus: resetRequestStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(ReactivateSubscriptionContainer));
