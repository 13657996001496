import { Filter } from '@va/types/filters';
import { isArray } from 'lodash';

export const getTableFilters = (appliedFilters: Record<string, unknown>) => {
  const groupBy = appliedFilters['group'] ?? [];

  const entries = Object.entries(appliedFilters);

  const filters =
    entries
      .map(([key, value]) => ({
        id: key,
        value: value as string,
      }))
      .filter((filter) => filter.id !== 'group') ?? [];

  return { groupBy: isArray(groupBy) ? groupBy : [groupBy], filters };
};

export const applyFilterValues = (filter: Filter, appliedFilter: Filter) => {
  return { ...filter, values: appliedFilter.values, operator: appliedFilter.operator };
};
