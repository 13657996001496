import classNames from 'classnames';

type ColorPickerIconProps = {
  fillColor?: string;
  className?: string;
};

export const ColorPickerIcon: React.FC<ColorPickerIconProps> = ({ fillColor, className }) => {
  return (
    <div
      className={classNames('w-6 h-6 rounded-full flex items-center justify-center', className)}
      style={{
        background: `conic-gradient(
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%
)`,
      }}
    >
      <div className='w-4 h-4 rounded-full bg-white flex items-center justify-center'>
        <div className='w-3 h-3 rounded-full ' style={{ backgroundColor: fillColor }}></div>
      </div>
    </div>
  );
};
