import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { NavigationSliceState } from '@va/dashboard/types/store';

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: { isSidebarOpen: false } as NavigationSliceState,
  reducers: {
    openSidebar: (state) => {
      state.isSidebarOpen = true;
    },
    closeSidebar: (state) => {
      state.isSidebarOpen = false;
    },
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    setIsSidebarOpen(state, action: PayloadAction<boolean>) {
      state.isSidebarOpen = action.payload;
    },
  },
});

const { reducer, actions } = navigationSlice;

export { actions as navigationActions, reducer as navigationReducer };
