import { DuplicateIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { TooltipWrapper } from '@va/ui/tooltips';
import PropTypes from 'prop-types';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

const CopyableBox = ({ text, copyText, copiedText }) => {
  const translate = useTranslate();
  const [copied, setCopied] = useState(false);
  return (
    <div className='flex grow border-2 border-gray-concrete rounded-lg text-sm'>
      <TooltipWrapper interactive content={<div className='p-1'>{text}</div>}>
        <div className='xs:min-w-[50%] w-full text-18 leading-24 font-semibold tracking text-gray-devil text-center text-ellipsis p-3 border-r-2 border-2 border-white bg-gray-concrete bg-white rounded-bl-md rounded-tl-md'>
          {text}
        </div>
      </TooltipWrapper>
      <div className='bg-gray-concrete' style={{ width: '2px' }} />
      <div className='xs:min-w-[50%] flex items-center min-w-150 text-center bg-gray-concrete border-2 border-white rounded-br-md rounded-tr-md p-3 cursor-pointer'>
        <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
          <div className='flex m-auto'>
            <DuplicateIcon className='mr-2 my-auto w-7' />
            <span className='font-15 leading-15 font-semibold tracking-tightestDefault text-gray-devil'>
              {copied
                ? copiedText || translate('button.copiedToClipboard')
                : copyText || translate('button.copyToClipboard')}
            </span>
          </div>
        </CopyToClipboard>
      </div>
    </div>
  );
};

CopyableBox.propTypes = {
  text: PropTypes.string.isRequired,
  copyText: PropTypes.string,
  copiedText: PropTypes.string,
};

export default CopyableBox;
