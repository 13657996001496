import { TEST_IDS } from '@va/constants';
import { useTranslate } from '@va/localization';
import { TimePeriod } from '@va/types/time';
import { useWindowDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { SelectableButtonsGroup } from '../selectable-buttons-group';

export type TimePeriodFilterProps = {
  shortFormat?: boolean;
  smallButtons?: boolean;
  disabled?: Record<keyof typeof TimePeriod, boolean>;
  hideTitle?: boolean;
  onChange: (value: TimePeriod) => void;
  selectedTimePeriod: TimePeriod;
};

// TODO Add story file
export const TimePeriodFilter: React.FC<TimePeriodFilterProps> = ({
  shortFormat,
  smallButtons,
  disabled,
  hideTitle,
  onChange,
  selectedTimePeriod,
}) => {
  const translate = useTranslate();
  const { isMobile } = useWindowDimensions();
  const showShortFormat = useMemo(() => shortFormat || isMobile, [isMobile, shortFormat]);

  const getPeriodLabel = useCallback(
    (timePeriod: string) => {
      if (showShortFormat) {
        return translate(`filters.short.${timePeriod}`);
      }
      return translate(`filters.${timePeriod}`);
    },
    [showShortFormat, translate],
  );

  const getPeriodTooltip = useCallback(
    (timePeriod: string) => {
      if (showShortFormat) {
        return translate(`filters.${timePeriod}`);
      }
      return null;
    },
    [showShortFormat, translate],
  );

  const title = useMemo(() => {
    if (!hideTitle) return translate('filters.showDataBy');
    return undefined;
  }, [hideTitle, translate]);

  const periods = useMemo(
    () => [
      {
        label: getPeriodLabel(TimePeriod.day),
        tooltip: getPeriodTooltip(TimePeriod.day),
        value: TimePeriod.day,
        disabled: disabled?.day,
      },
      {
        label: getPeriodLabel(TimePeriod.week),
        tooltip: getPeriodTooltip(TimePeriod.week),
        value: TimePeriod.week,
        disabled: disabled?.week,
      },
      {
        label: getPeriodLabel(TimePeriod.month),
        tooltip: getPeriodTooltip(TimePeriod.month),
        value: TimePeriod.month,
        disabled: disabled?.month,
      },
      {
        label: getPeriodLabel(TimePeriod.year),
        tooltip: getPeriodTooltip(TimePeriod.year),
        value: TimePeriod.year,
        disabled: disabled?.year,
      },
    ],
    [disabled?.day, disabled?.month, disabled?.week, disabled?.year, getPeriodLabel, getPeriodTooltip],
  );

  return (
    <SelectableButtonsGroup
      data-testid={TEST_IDS.generic.timeUnitSelector}
      selectedValue={selectedTimePeriod}
      title={title}
      buttons={periods}
      disabledTooltip={translate('filter.installedToday')}
      onChange={(value) => {
        onChange(value as TimePeriod);
      }}
      small={smallButtons}
      className={classNames({
        'self-center': showShortFormat,
      })}
      inactiveButtonColor='quinary'
    />
  );
};
