import { BILLING_CURRENCY_SYMBOL, subscriptionType } from '@va/standalone/shared/constants';

export function getPlanPrice(price, uiSubscriptionType) {
  if (uiSubscriptionType === subscriptionType.YEARLY) {
    price = (price / 12).toFixed(2);
  }
  const monthlyPriceSplitByDot = price.toString().split('.');
  const priceDigit = monthlyPriceSplitByDot[0];
  const priceCents =
    monthlyPriceSplitByDot[1] && monthlyPriceSplitByDot[1].length === 1
      ? monthlyPriceSplitByDot[1] + '0'
      : monthlyPriceSplitByDot[1] || '00';

  return `${BILLING_CURRENCY_SYMBOL}${priceDigit}.${priceCents} `;
}
