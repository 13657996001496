import { useTranslate } from '@va/localization';
import { Button, Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { DropdownArrow } from '@va/util/components';
import { usePrevious } from '@va/util/hooks';
import classNames from 'classnames';
import { isEqual, isNil } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { filterValidationKeys } from '../../constants';
import { FilterBlock } from '../../FilterBlock';
import { FilterInput } from '../../FilterInput';
import { useFiltersContext } from '../../filters-context';
import { useFilterValidation } from '../../useFilterValidation';
import { MultiSelectFields } from './MultiSelectFields';
import { TextFilter, TextFilterValue } from './TextFilter';

type TextFilterInputAppliedProps = {
  filter: TextFilter;
  disableEditing?: boolean;
  viewOnly?: boolean;
  isGrouped?: boolean;
  size?: 'small' | 'medium' | 'large';
};

export const TextFilterInputApplied = ({
  filter,
  disableEditing,
  size = 'large',
  isGrouped,
  viewOnly,
}: TextFilterInputAppliedProps) => {
  const { values, id, label, operator, operatorOptions, inputProps, appliedLabel } = filter;

  const { updateAppliedFilter } = useFiltersContext();

  const onChange = useCallback(
    (value: string | number) => {
      updateAppliedFilter(id, { values: [value] });
    },
    [id, updateAppliedFilter],
  );

  return (
    <FilterBlock
      filterId={id}
      fieldName={appliedLabel ?? label}
      operator={operator}
      operatorOptions={operatorOptions}
      hideDeleteButton={viewOnly}
      disabled={disableEditing}
      size={size}
      isGrouped={isGrouped}
      input={
        filter.allowMultiSelect ? (
          <MultiInputFilter
            className={classNames({ '!bg-transparent': disableEditing })}
            values={values}
            onChange={(data: TextFilterValue[]) => updateAppliedFilter(id, { values: data })}
            filter={filter}
            disabled={disableEditing}
          />
        ) : (
          <FilterInput
            className={classNames('rounded-r-12', { '!bg-transparent': disableEditing })}
            clearField={() => {
              onChange('');
            }}
            size={10}
            value={values[0]}
            disabled={disableEditing}
            onChange={(e) => {
              const eventValue = e.target.value;

              if (inputProps?.type === 'number') {
                if (eventValue === '') {
                  onChange(eventValue);
                  return;
                }

                onChange(Number(eventValue));
                return;
              }

              onChange(eventValue);
            }}
            {...inputProps}
          />
        )
      }
    />
  );
};

const MultiInputFilter = ({
  values = [],
  onChange,
  filter,
  disabled,
  className,
}: {
  values: TextFilterValue[];
  onChange: (data: TextFilterValue[]) => void;
  filter: TextFilter;
  disabled?: boolean;
  className?: string;
}) => {
  const translate = useTranslate();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <TooltipWrapper
      content={<TooltipContent values={values} onChange={onChange} onClose={() => setIsOpen(false)} filter={filter} />}
      arrow={false}
      trigger='click'
      placement='bottom'
      useDefaultStyle={false}
      onOpenChange={(open) => setIsOpen(open)}
      open={isOpen}
      disabled={disabled}
    >
      <div className={classNames('gap-3 input-wrapper', className, { 'cursor-pointer': !disabled })}>
        <div className='flex gap-2'>
          <Paragraph size={paragraphSizes.tiny} weight={fontWeights.medium}>
            {values[0]}
          </Paragraph>
          {values.length > 1 && (
            <Paragraph colorClassName='text-gray-devil' size={paragraphSizes.tiny} weight={fontWeights.medium}>
              {translate('all.filters.otherItemsCount', { count: values.length - 1 })}
            </Paragraph>
          )}
        </div>
        <DropdownArrow open={isOpen} color='#969696' />
      </div>
    </TooltipWrapper>
  );
};

const TooltipContent = ({
  values = [],
  onChange,
  onClose,
  filter,
}: {
  values: TextFilterValue[];
  onChange: (data: TextFilterValue[]) => void;
  onClose: () => void;
  filter: TextFilter;
}) => {
  const translate = useTranslate();
  const initialData = useMemo(() => (values.length === 0 ? [''] : values), [values]);
  const [data, setData] = useState(initialData);
  const prevData = usePrevious(data);

  const { handleSubmit, error, validate } = useFilterValidation({
    value: data,
    onSubmit: () => onChange(data),
    validationFunc: (val) => {
      const hasUndefinedValues = val.filter((item) => {
        return isNil(item) || (typeof item === 'string' && !item.replace(/\s/g, '').length);
      });

      if (hasUndefinedValues.length > 0 || val.length === 0) {
        return filterValidationKeys.required;
      }
    },
  });

  useEffect(() => {
    if (!isEqual(data, prevData)) {
      try {
        handleSubmit();
        validate(data);
      } catch {
        // An error message is already shown to the user
      }
    }
  }, [data, handleSubmit, prevData, validate]);

  return (
    <div className='flex gap-3 flex-col bg-white shadow-overlay p-6px rounded-18 text-gray-charcoal sm:w-350px md:w-420px w-auto'>
      <MultiSelectFields allValues={data} setAllValues={setData} filter={filter} error={error} />
      <Button
        disabled={error !== undefined}
        text={translate('button.close')}
        color='tertiary'
        className='w-full justify-center'
        onClick={onClose}
      />
    </div>
  );
};
