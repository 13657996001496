import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { TOOLTIPS_IDS } from '@va/constants';
import { removeTooltip, updateAllTooltips, updateTooltip, updateTooltips } from '@va/dashboard/actions/ui';
import { getTooltipIdsToRebind, getTooltips } from '@va/dashboard/selectors/ui';
import Tooltip from './Tooltip/index';
import * as Utils from './utils';

export class TooltipManager extends Component {
  constructor(props) {
    super(props);

    this.tooltipRefs = {};
    this.bindMethods(['onClick', 'reset', 'updateTooltipPosition']);
  }

  bindMethods(methodArray) {
    methodArray.forEach((methodName) => {
      this[methodName] = this[methodName].bind(this);
    });
  }

  componentDidMount() {
    this.bindGlobalListeners();
  }

  componentWillUnmount() {
    this.unbindGlobalListeners();
  }

  componentDidUpdate() {
    // update the position for every tooltip
    // when a tooltip's target or content is changed we consider it's not positioned anymore
    this.props.tooltips
      .filter((item) => !item.get('isPositioned') && item.has('target'))
      .forEach(this.updateTooltipPosition);
  }

  render() {
    const defaultPosition = Immutable.Map({ tooltip: { top: 0, left: 0 } });
    const tooltips = this.props.tooltips.map((item, id) => {
      const show = item.get('show', false) && item.get('isPositioned', false);
      const forcedPlace = item.getIn(['position', 'forcedPlace']);

      return (
        <Tooltip
          key={id}
          place={forcedPlace ? forcedPlace : item.get('place', 'top')}
          show={show}
          position={item.get('position', defaultPosition).toJS()}
          render={item.get('render')}
          data={item.get('props', Immutable.Map()).toJS()}
          className={item.get('className')}
          ref={(node) => (this.tooltipRefs[id] = node)}
        />
      );
    });
    return <div>{tooltips.toArray()}</div>;
  }

  bindGlobalListeners() {
    window.addEventListener('click', this.onClick);
    window.addEventListener('scroll', this.reset, true);
    window.addEventListener('resize', this.reset);
  }

  unbindGlobalListeners() {
    window.removeEventListener('click', this.onClick);
    window.removeEventListener('scroll', this.reset, true);
    window.removeEventListener('resize', this.reset);
  }

  onClick(event) {
    // ! this has some problems with nested tooltip targets
    const tooltip = Utils.findClosest(event.target, '[data-tooltip-id]');
    const tooltipContainer = Utils.findClosest(event.target, '.tooltip-component');

    // when the click isn't in a tooltip container we hide all tooltips that opted for this global feature
    // todo: a solution to find out what tooltip container was clicked so that other tooltips can be hidden
    if (!tooltipContainer) {
      const tooltipId = tooltip ? tooltip.getAttribute('data-tooltip-id') : null;
      const tooltips = this.props.tooltips
        // check that it opted for this feature, it's shown and that it wasn't clicked on
        .filter(
          (item) =>
            item.hasIn(['globalFeatures', 'hideOnExternalClick']) && item.get('show') && item.get('id') !== tooltipId,
        )
        .reduce((acc, item) => Object.assign(acc, { [item.get('id')]: { show: false, frozen: false } }), {});

      if (Object.keys(tooltips).length > 0) {
        this.props.updateTooltips(tooltips);
      }
    }
  }

  reset(event) {
    if (event?.target?.id === TOOLTIPS_IDS.liveVisitorsTooltip) return;
    // dispatch action only if it actually makes a difference
    if (this.hasVisibleTooltips()) {
      this.props.updateAllTooltips({ show: false, frozen: false });
    }
  }

  hasVisibleTooltips() {
    // if there is a tooltip that is shown
    return undefined !== this.props.tooltips.find((item) => item.get('show'));
  }

  updateTooltipPosition(tooltip, id) {
    const target = tooltip.get('target') ? tooltip.get('target').toJS() : null;
    if (!target) {
      return;
    }

    const node = this.tooltipRefs[id].getContainer();

    let place = tooltip.get('place');
    let positions = Utils.getPositions(target, node);
    const tooltipSize = { width: node.clientWidth, height: node.clientHeight };

    const result = Utils.computeVisiblePosition(tooltipSize, positions, place);
    this.props.updateTooltip(id, {
      isPositioned: true,
      position: {
        tooltip: {
          left: result.tooltipPosition.x,
          top: result.tooltipPosition.y,
        },
        anchor:
          result.anchorPosition !== null
            ? {
                left: result.anchorPosition.x,
                top: result.anchorPosition.y,
              }
            : null,
        forcedPlace: result.forcedPlace,
      },
    });
  }
}

const mapStateToProps = (state) => {
  return {
    tooltips: getTooltips(state),
    rebindIds: getTooltipIdsToRebind(state),
  };
};

export default connect(mapStateToProps, {
  updateTooltip: updateTooltip,
  updateTooltips: updateTooltips,
  updateAllTooltips: updateAllTooltips,
  removeTooltip: removeTooltip,
})(TooltipManager);

TooltipManager.propTypes = {
  updateTooltip: PropTypes.func.isRequired,
  updateTooltips: PropTypes.func.isRequired,
  updateAllTooltips: PropTypes.func.isRequired,
  removeTooltip: PropTypes.func.isRequired,

  tooltips: PropTypes.instanceOf(Immutable.Map).isRequired,
  rebindIds: PropTypes.instanceOf(Immutable.List).isRequired,
};
