import { IconProps } from '..';

export const DesktopDeviceIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 3H15C15.8284 3 16.5 3.67157 16.5 4.5V10.5C16.5 11.3284 15.8284 12 15 12H3C2.17157 12 1.5 11.3284 1.5 10.5V4.5C1.5 3.67157 2.17157 3 3 3ZM0 4.5C0 2.84315 1.34315 1.5 3 1.5H15C16.6569 1.5 18 2.84315 18 4.5V10.5C18 12.1569 16.6569 13.5 15 13.5H3C1.34315 13.5 0 12.1569 0 10.5V4.5ZM3.75 15C3.33579 15 3 15.3358 3 15.75C3 16.1642 3.33579 16.5 3.75 16.5H14.25C14.6642 16.5 15 16.1642 15 15.75C15 15.3358 14.6642 15 14.25 15H3.75Z'
        fill={color ?? 'var(--color-primary)'}
      />
    </svg>
  );
};
