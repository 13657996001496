import { apiRequestResponseMsg, platform, UPDATE_WEBSITES_EVENT } from '@va/constants';
import { useRemoveWebsiteApi } from '@va/dashboard/api-client/Website';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { getProviderName } from '@va/dashboard/selectors/customization';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { Checkmark } from '@va/icons';
import { useTranslate } from '@va/localization';
import { getWebsites } from '@va/standalone/shared/actions';
import { useLogin } from '@va/standalone/shared/api-client/Auth';
import { useCancelSubcriptionImmediate } from '@va/standalone/shared/api-client/billing';
import { subscriptionStatus } from '@va/standalone/shared/constants';
import { getAccountOwnerEmail } from '@va/standalone/shared/selectors';
import { ModalFormWrapper } from '@va/ui/components/modals';
import { CheckboxInput, fontWeights, Paragraph, TextInput } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { EventBus } from '@va/util/misc';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useRemoveWebsiteContext } from './removeWebsiteContext';

const wixDeletingAppsLink = 'https://support.wix.com/en/article/deleting-apps';

const RemoveWebsiteModal = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const currentUserEmail = useSelector(getAccountOwnerEmail);
  const instanceId = useSelector(getInstanceId);
  const providerName = useSelector(getProviderName);
  const { showSuccessNotification, showErrorNotification } = useAddNotification();

  const { isModalOpen, closeModal, websiteId, subscription, websitePlatform } = useRemoveWebsiteContext();

  const isWixWebsite = useMemo(() => websitePlatform === platform.WIX, [websitePlatform]);

  const shouldCancelSubscription = useMemo(() => {
    if (!subscription || isWixWebsite) return false;
    if (subscription.status.toLowerCase() === subscriptionStatus.ACTIVE.toLowerCase()) return true;
    return false;
  }, [isWixWebsite, subscription]);

  const loginCheck = useLogin();
  const removeWebsite = useRemoveWebsiteApi();
  const cancelSubscription = useCancelSubcriptionImmediate();

  const { values, errors, handleChange, handleSubmit, setFieldValue, touched } = useFormik({
    initialValues: { password: '', sendDataSelected: false, reason: '' },
    onSubmit: (values) => {
      loginCheck.execute(currentUserEmail, values.password);
    },
    validationSchema: yup.object().shape({
      password: yup.string().required(translate('form.requiredField')),
    }),
  });

  const executeRemoveWebsite = useCallback(() => {
    removeWebsite.execute(websiteId!, {
      email: currentUserEmail,
      sendDataSelected: values.sendDataSelected,
      reason: values.reason,
    });
  }, [currentUserEmail, removeWebsite, values.reason, values.sendDataSelected, websiteId]);

  useEffect(() => {
    if (!loginCheck.isSucceeded) return;
    loginCheck.resetSuccessState();

    if (isWixWebsite) {
      executeRemoveWebsite();
      return;
    }

    if (shouldCancelSubscription) {
      cancelSubscription.execute(websiteId);
    } else {
      executeRemoveWebsite();
    }
  }, [cancelSubscription, executeRemoveWebsite, isWixWebsite, loginCheck, shouldCancelSubscription, websiteId]);

  useEffect(() => {
    if (cancelSubscription.isSucceeded) {
      cancelSubscription.resetSuccessState();
      executeRemoveWebsite();
    }
  }, [cancelSubscription, executeRemoveWebsite]);

  useEffect(() => {
    if (!removeWebsite.isSucceeded) return;
    removeWebsite.resetSuccessState();
    dispatch(getWebsites());
    EventBus.dispatch(UPDATE_WEBSITES_EVENT);
    showSuccessNotification(3000, translate('notifications.removeWebsite.success'));
    closeModal();
  }, [
    closeModal,
    dispatch,
    instanceId,
    removeWebsite,
    removeWebsite.isSucceeded,
    showSuccessNotification,
    translate,
    websiteId,
  ]);

  useEffect(() => {
    if (cancelSubscription.error || removeWebsite.error) {
      const isErrorExternal =
        cancelSubscription.error?.message === apiRequestResponseMsg.SUBSCRIPTION_CANCEL_FAIL_EXTERNAL ||
        removeWebsite.error?.message === apiRequestResponseMsg.WEBSITE_REMOVE_FAIL_EXTERNAL;

      const notificationMessage =
        isErrorExternal && websitePlatform === platform.WEEBLY
          ? translate('modal.removeExternalWebsite.WEEBLY.confirmation.failed', { providerName })
          : translate('reusableText.error.contactOurSupport');

      cancelSubscription.clearError();
      removeWebsite.clearError();
      showErrorNotification(5000, notificationMessage);
    }
  }, [
    cancelSubscription,
    cancelSubscription.error,
    providerName,
    removeWebsite,
    removeWebsite.error,
    showErrorNotification,
    translate,
    websitePlatform,
  ]);

  const modalTitle = useMemo(() => {
    if (shouldCancelSubscription) return translate('button.cancelSubscriptionAndDeleteWebsite');
    return translate('modal.removeWebsite.title');
  }, [shouldCancelSubscription, translate]);

  const modalInfo = useMemo(() => {
    if (isWixWebsite) return <RemoveWixWebsiteInfo />;
    if (shouldCancelSubscription) return <RemoveWebsiteWithActiveSubscriptionInfo />;
    return <RemoveWebsiteWithInactiveSubscriptionInfo />;
  }, [isWixWebsite, shouldCancelSubscription]);

  const buttonText = useMemo(() => {
    if (shouldCancelSubscription) return translate('button.cancelSubscriptionAndDeleteWebsite');
    return translate('button.removeWebsite');
  }, [shouldCancelSubscription, translate]);

  const displayReasonInput = useMemo(
    () => !isWixWebsite && shouldCancelSubscription,
    [isWixWebsite, shouldCancelSubscription],
  );

  return (
    <ModalFormWrapper
      saveButtonProps={{ color: 'destructive', text: buttonText }}
      onSubmit={handleSubmit}
      title={modalTitle}
      isOpen={isModalOpen}
      onClose={closeModal}
      isSaveButtonDisabled={loginCheck.isLoading || removeWebsite.isLoading || cancelSubscription.isLoading}
    >
      {modalInfo}
      {!isWixWebsite && (
        <div className='my-5 flex items-center gap-1'>
          <CheckboxInput
            id='sendDataSelected'
            onChange={handleChange}
            name='sendDataSelected'
            checked={values.sendDataSelected}
          />
          <TooltipWrapper
            disabled={!shouldCancelSubscription}
            content={shouldCancelSubscription && translate('modal.removeWebsite.sendDataInfoTooltip')}
          >
            <Paragraph
              className={classNames({
                'underline-dashed': shouldCancelSubscription,
              })}
            >
              {translate('modal.removeWebsite.sendDataAndRemoveWebsite')}
            </Paragraph>
          </TooltipWrapper>
        </div>
      )}
      {displayReasonInput && (
        <TextInput
          wrapperClassName='mt-5'
          id='reason'
          label={translate('modal.removeWebsite.form.cancelReason')}
          name='reason'
          type='text'
          onChange={handleChange}
          clearField={() => {
            setFieldValue('reason', '');
          }}
          value={values.reason}
          error={touched.reason ? translate(errors.reason || '') : undefined}
          autoFocus
        />
      )}
      <div className='h-0 overflow-hidden'>
        {/* This input is to prevent the browser from autocompleting the reason input with the email */}
        <input type='email' />
      </div>
      <Paragraph className='mt-5 mb-3'>{translate('modal.checkPassword.mainText')}</Paragraph>
      <TextInput
        id='password'
        label={translate('register.form.password.label')}
        name='password'
        type='password'
        required
        onChange={handleChange}
        clearField={() => {
          setFieldValue('password', '');
        }}
        value={values.password}
        autoComplete='new-password'
        error={touched.password ? translate(errors.password || '') : undefined}
        autoFocus={!displayReasonInput}
      />
      {loginCheck.error && (
        <Paragraph weight={fontWeights.medium} className='ml-2 mt-2 text-negative'>
          {translate('login.error.incorrectPassword')}
        </Paragraph>
      )}
    </ModalFormWrapper>
  );
};

export default RemoveWebsiteModal;

const RemoveWixWebsiteInfo = () => {
  const translate = useTranslate();
  const providerName = useSelector(getProviderName);
  return (
    <div className='text-center'>
      <Paragraph>
        {translate('modal.removeWebsite.wixClaimed.text1', {
          providerName,
        })}
      </Paragraph>
      <Paragraph>
        {translate('modal.removeWebsite.wixClaimed.text2', {
          deletingAppsLink: wixDeletingAppsLink,
          className: 'highlighted-word',
        })}
      </Paragraph>
    </div>
  );
};

const RemoveWebsiteWithInactiveSubscriptionInfo = () => {
  const translate = useTranslate();
  const providerName = useSelector(getProviderName);
  return (
    <div className='space-y-3 text-center'>
      <Paragraph>
        {translate('modal.removeWebsite.text1', {
          providerName,
        })}
      </Paragraph>
      <Paragraph>{translate('modal.removeWebsite.text2')}</Paragraph>
    </div>
  );
};

const RemoveWebsiteWithActiveSubscriptionInfo = () => {
  const translate = useTranslate();
  const providerName = useSelector(getProviderName);
  return (
    <div>
      <Paragraph className='mb-2'>
        {translate('modal.removeWebsite.withActiveSubscription.text1', {
          providerName,
        })}
      </Paragraph>
      <ul className='space-y-1'>
        <ListItem text={translate('modal.removeWebsite.withActiveSubscription.removedFeatures.l1')} />
        <ListItem text={translate('modal.removeWebsite.withActiveSubscription.removedFeatures.l2')} />
        <ListItem text={translate('modal.removeWebsite.withActiveSubscription.removedFeatures.l3')} />
      </ul>
    </div>
  );
};

const ListItem = ({ text }: { text: string }) => {
  return (
    <li className='flex items-center gap-2'>
      <Checkmark />
      {text}
    </li>
  );
};
