export const FacebookMessengerIcon: React.FC<JSX.IntrinsicElements['svg'] & { monochrome?: boolean }> = ({
  monochrome,
  ...props
}) => {
  if (monochrome)
    return (
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        viewBox='0 0 24 24'
        xmlSpace='preserve'
        {...props}
      >
        <path
          d='M12.1,1.8c5.6,0,10.2,4.2,10.2,9.4s-4.6,9.4-10.2,9.4c-1,0-2-0.1-2.9-0.4l-0.4-0.1l-0.4,0.2l-2.3,1.3v-2.3v-0.5
      l-0.4-0.3c-2.4-1.8-3.8-4.5-3.8-7.3C1.9,6.1,6.5,1.8,12.1,1.8 M12.1,0.8C5.9,0.8,0.9,5.5,0.9,11.3c0,3.3,1.6,6.2,4.2,8.1v4l3.8-2.1
      c1,0.3,2.1,0.4,3.2,0.4c6.2,0,11.2-4.7,11.2-10.4C23.3,5.5,18.3,0.8,12.1,0.8L12.1,0.8z'
        />
        <polygon points='13.8,11.4 10.9,8.4 4.8,14.9 10.4,11.8 13.2,14.9 19.3,8.4 ' />
      </svg>
    );

  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M18 9C18 4.02891 13.9711 0 9 0C4.02891 0 0 4.02891 0 9C0 13.493 3.29062 17.216 7.59375 17.891V11.6016H5.30859V9H7.59375V7.01719C7.59375 4.76191 8.93672 3.51562 10.9934 3.51562C11.9777 3.51562 13.0078 3.69141 13.0078 3.69141V5.90625H11.8723C10.7543 5.90625 10.4062 6.60059 10.4062 7.3125V9H12.9023L12.5033 11.6016H10.4062V17.891C14.7094 17.216 18 13.493 18 9Z'
        fill='#1877F2'
      />
    </svg>
  );
};
