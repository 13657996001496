import * as Account from './Account';
import * as Agency from './Agency';
import * as Auth from './Auth';
import * as Billing from './billing';
import * as FirstPromoter from './first-promoter';
import * as Weebly from './weebly';

export const createUser = Auth.createUser;
export const activateUser = Auth.activateUser;
export const login = Auth.login;
export const generateImpersonateToken = Auth.generateImpersonateToken;
export const refreshToken = Auth.refreshToken;
export const refreshImpersonateToken = Auth.refreshImpersonateToken;
export const forgotPassword = Auth.forgotPassword;
export const resetPassword = Auth.resetPassword;
export const resendRegisterConfirmation = Account.resendRegisterConfirmation;
export const registerContributor = Auth.registerContributor;
export const addVoucher = Auth.addVoucher;
export const getGoogleImporterAuthUrl = Auth.getGoogleImporterAuthUrl;
export const getGoogleImporterViews = Auth.getGoogleImporterViews;
export const startGoogleImporter = Auth.startGoogleImporter;
export const getGoogleImporterProgress = Auth.getGoogleImporterProgress;

export const updateAccountSettings = Account.updateAccountSettings;
export const confirmUpdateEmail = Account.confirmUpdateEmail;
export const resetEmail = Account.resetEmail;
export const getWebsiteOverview = Account.getWebsiteOverview;
export const deleteAccount = Account.deleteAccount;
export const removeWebsite = Account.removeWebsite;
export const getWebsites = Account.getWebsites;
export const getWebsite = Account.getWebsite;
export const updateWebsite = Account.updateWebsite;
export const getTrackingCode = Account.getTrackingCode;
export const deleteWebsiteContributorAccepted = Account.deleteWebsiteContributorAccepted;
export const acceptContributorRole = Account.acceptContributorRole;
export const rejectContributorRole = Account.rejectContributorRole;
export const acceptContributorRoleWithToken = Account.acceptContributorRoleWithToken;
export const rejectContributorRoleWithToken = Account.rejectContributorRoleWithToken;
export const findMigrationsUser = Account.findMigrationsUser;
export const createWebsiteForMigration = Account.createWebsiteForMigration;
export const createNewUserForMigration = Account.createNewUserForMigration;
export const createNewSubscriber = Account.createNewSubscriber;
export const getVouchers = Account.getVouchers;
export const getVouchersInfo = Account.getVouchersInfo;
export const applyLifetimeDeal = Account.applyLifetimeDeal;
export const getUpdatePaymentMethodUrl = Billing.getUpdatePaymentMethodUrl;
export const updateSubscription = Billing.updateSubscription;
export const getCustomerPaymentAuthToken = Billing.getCustomerPaymentAuthToken;
export const getPaymentAuthToken = Billing.getPaymentAuthToken;
export const getCustomer = Billing.getCustomer;
export const getPaymentMethod = Billing.getPaymentMethod;
export const getPaymentPlans = Billing.getPaymentPlans;
export const getAllSubscriptions = Billing.getAllSubscriptions;
export const generateInvoice = Billing.generateInvoice;
export const getComputedSubscriptionPrice = Billing.getComputedSubscriptionPrice;
export const validateVat = Billing.validateVat;
export const createCustomer = Billing.createCustomer;
export const createPaymentMethod = Billing.createPaymentMethod;
export const createSubscription = Billing.createSubscription;
export const cancelSubscription = Billing.cancelSubscription;
export const cancelSubscriptionImmediate = Billing.cancelSubscriptionImmediate;
export const reactivateSubscription = Billing.reactivateSubscription;
export const updateCustomer = Billing.updateCustomer;
export const validatePromoCode = Billing.validatePromoCode;
export const getAgencyCustomer = Billing.getAgencyCustomer;

export const signUpPromoter = FirstPromoter.signUpPromoter;

export const weeblyLogin = Weebly.weeblyLogin;
export const weeblyRefreshToken = Weebly.weeblyRefreshToken;
export const weeblySync = Weebly.weeblySync;

export const getAgencyThemes = Agency.getAgencyThemes;
export const getAgencyUi = Agency.getAgencyUi;
export const getWebsiteAgencyUi = Agency.getWebsiteAgencyUi;
export const getCustomUrlAgencyUi = Agency.getCustomUrlAgencyUi;
export const changeWebsiteWhiteLabelStatus = Agency.changeWebsiteWhiteLabelStatus;
export const getAgencyQuaterlyCommission = Agency.getAgencyQuaterlyCommission;
