class SessionStorage {
  storage: Record<string, unknown> = {};
  isSessionStorageAvailable: boolean;
  static instance: any;

  constructor() {
    this.isSessionStorageAvailable = this.checkSessionStorageAvailability();
    if (typeof SessionStorage.instance === 'object') {
      return SessionStorage.instance;
    }
  }

  checkSessionStorageAvailability() {
    try {
      sessionStorage.setItem('test-storage', '1');
      sessionStorage.removeItem('test-storage');
      return true;
    } catch (e) {
      return false;
    }
  }

  setItem(key: string, value: any) {
    if (this.isSessionStorageAvailable) {
      return sessionStorage.setItem(key, value);
    } else {
      this.storage[key] = value;
      return this.storage;
    }
  }

  getItem(key: string): string | null {
    if (this.isSessionStorageAvailable) {
      return sessionStorage.getItem(key);
    } else {
      return this.storage[key] as string;
    }
  }

  removeItem(key: string) {
    if (this.isSessionStorageAvailable) {
      return sessionStorage.removeItem(key);
    } else {
      // eslint-disable-next-line no-prototype-builtins
      if (this.storage.hasOwnProperty(key)) {
        delete this.storage[key];
      }
      return this.storage;
    }
  }
}

export default new SessionStorage();
