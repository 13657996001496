import { useTranslate } from '@va/localization';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

export const useFileUpload = ({
  onChange,
  value,
  disabled,
  onError,
}: {
  onChange: (file: File) => void;
  onError: (message: string) => void;
  value: string | File | null;
  disabled?: boolean;
}) => {
  const [preview, setPreview] = useState<any>();
  const translate = useTranslate();

  const fileErrorText = useCallback(
    (errorCode: string) => {
      if (errorCode === 'file-too-large') {
        return translate('file.error.tooLarge');
      }
      if (errorCode === 'file-invalid-type') {
        return translate('file.error.notSupported');
      }
      return translate('file.error.somethingWentWrong');
    },
    [translate],
  );

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: { errors: { code: string }[] }[]) => {
      if (fileRejections.length > 0) {
        const [{ errors }] = fileRejections;

        onError(fileErrorText(errors[0]?.code));

        return;
      }

      onChange(acceptedFiles[0]);

      const reader = new FileReader();

      reader.onload = function (e) {
        setPreview(e?.target?.result);
      };

      reader.readAsDataURL(acceptedFiles[0]);
    },
    [fileErrorText, onChange, onError],
  );

  const dropzoneSettings = useMemo<DropzoneOptions>(
    () => ({
      onDrop,
      accept: ['image/svg+xml', 'image/png', 'image/jpeg'],
      maxFiles: 1,
      maxSize: 1048576,
      disabled: disabled,
    }),
    [onDrop, disabled],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone(dropzoneSettings);

  useEffect(() => {
    setPreview(value);
  }, [value]);

  return { getRootProps, getInputProps, preview, isDragActive, setPreview };
};
