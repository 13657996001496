import { PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useExpandableGridContext } from './expandableGridContext';

type ExpandableCardContextProps = {
  id: string;
  expanded: boolean;
  toggle: () => void;
  showContent: boolean;
};

const ExpandableCardContext = createContext<ExpandableCardContextProps>({} as ExpandableCardContextProps);

export const ExpandableCardContextProvider = ({ children, id }: PropsWithChildren<{ id: string }>) => {
  const [showContent, setShowContent] = useState(true);
  const { isExpanded, toggle } = useExpandableGridContext();

  const expanded = useMemo(() => isExpanded(id), [id, isExpanded]);

  const toggleExpanded = useCallback(() => {
    setShowContent(false);
    toggle(id);
    // Prevent content flickering on resize
    setTimeout(() => {
      setShowContent(true);
    }, 1);
  }, [id, toggle]);

  return (
    <ExpandableCardContext.Provider value={{ id, expanded, toggle: toggleExpanded, showContent }}>
      {children}
    </ExpandableCardContext.Provider>
  );
};

export const useExpandableCardContext = () => useContext(ExpandableCardContext);
