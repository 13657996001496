import React, { useCallback, useEffect, useRef, useState } from 'react';

export const useElementDimensions = (elementRef: React.RefObject<HTMLElement>, timesToRetry = 100) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [isObserving, setIsObserving] = useState(false);
  const [retry, setRetry] = useState(0);

  const resizeObserverRef = useRef(
    new ResizeObserver((entries) => {
      if (!entries[0]) return;
      setWidth(entries[0].contentRect.width);
      setHeight(entries[0].contentRect.height);
    }),
  );

  const observe = useCallback(() => {
    if (!elementRef.current) {
      setRetry((prev) => prev + 1);
      return;
    }
    if (isObserving) return;
    setIsObserving(true);
    resizeObserverRef.current.observe(elementRef.current);
  }, [elementRef, isObserving]);

  useEffect(() => {
    if (retry === timesToRetry || isObserving) return;
    observe();
  }, [isObserving, observe, retry, timesToRetry]);

  return { width, height };
};
