import { get, patch, post, remove } from '@va/http-client';

export const getConversionTypes = (websiteId) => {
  const url = `/websites/${websiteId}/conversion-types`;
  return get(url, {});
};

export const addConversionType = (websiteId, data) => {
  const url = `/websites/${websiteId}/conversion-types`;
  return post(url, {}, data);
};

export const editConversionType = (websiteId, conversionId, data) => {
  const url = `/websites/${websiteId}/conversion-types/${conversionId}`;
  return patch(url, {}, data);
};

export const removeConversionType = (websiteId, conversionId) => {
  const url = `/websites/${websiteId}/conversion-types/${conversionId}`;
  return remove(url, {});
};
