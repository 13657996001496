import { TEST_IDS, apiStatus, customReactSelectStyles } from '@va/constants';
import { isMobile } from '@va/dashboard/selectors/ui';
import Translation from '@va/deprecated/components/Translation';
import { useTranslate } from '@va/localization';
import { getAgencyQuaterlyCommission } from '@va/standalone/shared/actions';
import { commissionPayoutQuarters } from '@va/standalone/shared/constants';
import { getApiRequest, getApiRequestStatus } from '@va/standalone/shared/selectors';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import './CommissionPayoutsDetails.scss';

const CommissionPayoutsDetails = (props) => {
  const dispatch = useDispatch();
  const { setAnnualCommissionPayout } = props;
  const translate = useTranslate();
  const currentYear = String(new Date().getFullYear());
  const [years, setYears] = useState([{ label: `${currentYear}`, value: `${currentYear}` }]);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const getQuaterlyCommissionData = useSelector((state) => getApiRequest(state, 'getAgencyQuaterlyCommission')).get(
    'data',
  );
  const quaterlyCommissionStatus = useSelector((state) => getApiRequestStatus(state, 'getAgencyQuaterlyCommission'));
  let quaterlyCommissionData = getQuaterlyCommissionData?.toJS();

  if (!getQuaterlyCommissionData?.get(currentYear)) {
    //if we do not recieve data from API for the current year
    quaterlyCommissionData = { ...quaterlyCommissionData, [currentYear]: { q1: 0, q2: 0, q3: 0, q4: 0 } };
  }

  useEffect(() => {
    dispatch(getAgencyQuaterlyCommission());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (quaterlyCommissionStatus === apiStatus.SUCCEEDED) {
      const yearsToDisplayCommission = [];
      for (const key of Object.keys(quaterlyCommissionData)) {
        yearsToDisplayCommission.push({ label: key, value: key });
      }
      setYears(yearsToDisplayCommission);
    }
  }, [quaterlyCommissionStatus, getQuaterlyCommissionData, quaterlyCommissionData]);

  useEffect(() => {
    const sumOfCurrentYearCommission = Object.values(quaterlyCommissionData[selectedYear]).reduce(
      (value1, value2) => value1 + value2,
    );
    setAnnualCommissionPayout(sumOfCurrentYearCommission.toFixed(2));
  }, [quaterlyCommissionData, selectedYear, setAnnualCommissionPayout]);

  return (
    <div className='commission-payouts-details-wrapper flex flex-column'>
      <div className='flex justify-center font-semibold text-21 leading-30 text-mine-shaft text-center px-4'>
        {translate('agency.commissionPayouts.recentAndUpcoming.title')}
      </div>
      <div className='flex justify-center'>
        <PayoutsByYear selectedYear={selectedYear} options={years} handleChange={setSelectedYear} />
      </div>
      <div className='my-16'>
        <PayoutGraphs selectedYear={selectedYear} quarterlyPayouts={quaterlyCommissionData} />
      </div>
    </div>
  );
};

CommissionPayoutsDetails.propTypes = {
  setAnnualCommissionPayout: PropTypes.func.isRequired,
};

export default CommissionPayoutsDetails;

const PayoutGraphs = (props) => {
  const { selectedYear, quarterlyPayouts } = props;
  const commissionsForYear = Object.values(quarterlyPayouts[selectedYear]);
  const highestNumber = Math.max(...commissionsForYear);

  function handleGraphHeight(value) {
    if (value === highestNumber && value > 0) return 200;
    if (value === 0) return 0;
    return (value / highestNumber) * 200;
  }

  return (
    <div className='payout-graphs-wrapper flex'>
      {commissionPayoutQuarters.map((item, index) => {
        return (
          <Graph
            selectedYear={selectedYear}
            index={index + 1}
            quarterName={item}
            amount={commissionsForYear[index]}
            height={handleGraphHeight(commissionsForYear[index])}
          />
        );
      })}
    </div>
  );
};

PayoutGraphs.propTypes = {
  selectedYear: PropTypes.number.isRequired,
  quarterlyPayouts: PropTypes.array,
};

const Graph = (props) => {
  const { selectedYear, index, amount, height, quarterName } = props;
  const translate = useTranslate();
  const isMobileDevice = useSelector(isMobile);
  return (
    <div key={index} className='payout-quarter-graph' style={{ height: height + 'px' }}>
      <div className='paid-commission-amount font-semibold sm:text-14 text-lg leading-normal text-mine-shaft'>
        {amount} USD
      </div>
      <div className='paid-commission-for-quarter flex flex-column items-center'>
        <div className='quarter-number font-medium text-lg leading-normal text-gray-devil '>
          <Translation
            translationKey={isMobileDevice ? `label.shortLetter.quarter${index}` : `label.quarter${index}`}
            parameters={{ quarterNumber: index }}
          />
        </div>
        <div className='flex flex-row sm:flex-col font-medium text-15 leading-24 text-gray-dusty'>
          <span>{translate(`quarter.${quarterName}.shortName`)}</span> <span className='ml-1'>{selectedYear}</span>
        </div>
      </div>
    </div>
  );
};

Graph.propTypes = {
  selectedYear: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  quarterName: PropTypes.string.isRequired,
};

const PayoutsByYear = (props) => {
  const translate = useTranslate();
  const { selectedYear, options, handleChange } = props;

  return (
    <div className='payouts-by-year flex rounded-xl mt-4'>
      <div className='payout-year-label-wrapper'>
        <div className='payout-year-label font-medium text-xs leading-18 text-gray-dusty'>
          {translate('option.btn.yearly')}
        </div>
      </div>
      <Select
        name='role'
        type='select'
        value={options.find((item) => item.value === `${selectedYear}`) || null}
        options={options}
        onChange={(option) => handleChange(option.value)}
        defaultValue={options[0]}
        styles={customStyles}
        id={TEST_IDS.generic.whiteLabel.payoutsByYearSelect}
      />
    </div>
  );
};

PayoutsByYear.propTypes = {
  options: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
};

const customStyles = {
  ...customReactSelectStyles,
  control: (provided) => ({
    ...provided,
    ...customReactSelectStyles.control(),
    minWidth: '150px',
    borderRadius: '0 12px 12px 0',
  }),
};
