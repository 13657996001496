export const QuestionCircleIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='12' cy='12' r='11' stroke='#999999' strokeWidth='2' />
      <path
        d='M9 9V9C9 7.34315 10.3431 6 12 6H12.1716C13.7337 6 15 7.26633 15 8.82843V8.82843C15 9.57857 14.702 10.298 14.1716 10.8284L13.1716 11.8284C12.4214 12.5786 12 13.596 12 14.6569V15'
        stroke='#999999'
        strokeWidth='2'
      />
      <circle cx='12' cy='18' r='1' fill='#999999' />
    </svg>
  );
};
