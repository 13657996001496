import { ADD_NOTIFICATION_EVENT, supportLinks } from '@va/constants';
import { NotificationTypeEnum } from '@va/types/notification';
import { is3asApp } from '@va/util/helpers';
import { EventBus } from '@va/util/misc';
import Config from 'Config';
import { openChatwootWidget } from '../chatwoot';

export const onContactSupport = () => {
  if (!Config.chatwootToken && !is3asApp()) {
    window.open(supportLinks.contact, '_blank');
    return;
  }

  if (is3asApp()) {
    EventBus.dispatch(ADD_NOTIFICATION_EVENT, {
      type: NotificationTypeEnum.error,
      translationKey: 'notifications.generalError',
    });
    return;
  }

  openChatwootWidget();
};
