import { useCallback, useState } from 'react';

export function useFilterValidation<T, D = string>({
  validationFunc,
  onSubmit,
  value,
}: {
  validationFunc: (value: T) => D | void;
  onSubmit: () => void;
  value: T;
}) {
  const [error, setError] = useState<D | undefined>(undefined);

  const validate = useCallback(
    (value: T) => {
      const err = validationFunc(value);
      if (err) {
        setError(err);
      } else {
        setError(undefined);
      }
    },
    [validationFunc],
  );

  const handleSubmit = useCallback(() => {
    const err = validationFunc(value);
    if (err) {
      setError(err);
      throw new Error();
    } else {
      setError(undefined);
    }
    onSubmit();
  }, [onSubmit, validationFunc, value]);

  return { handleSubmit, error, validate };
}
