import {
  GET_ACCOUNT_INFORMATION_FAILED,
  GET_ACCOUNT_INFORMATION_REQUEST,
  GET_ACCOUNT_INFORMATION_SUCCEEDED,
  SET_USER_FLAG_FAILED,
  SET_USER_FLAG_REQUEST,
  SET_USER_FLAG_SUCCEEDED,
} from '@va/dashboard/actions/api';
import { newApiRequest } from './core/util';

export const setUserFlag = newApiRequest(SET_USER_FLAG_REQUEST, SET_USER_FLAG_SUCCEEDED, SET_USER_FLAG_FAILED);

export const getAccountInformation = newApiRequest(
  GET_ACCOUNT_INFORMATION_REQUEST,
  GET_ACCOUNT_INFORMATION_SUCCEEDED,
  GET_ACCOUNT_INFORMATION_FAILED,
);
