import { YOUTUBE_CHANNEL_ID } from '@va/constants';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class YoutubeSubscribeBtn extends PureComponent {
  constructor(props) {
    super(props);
    this.buttonRef = React.createRef();
    this.state = {
      isInitialized: false,
    };
  }

  componentDidMount() {
    if (this.state.isInitialized) {
      return;
    }
    const youtubeScript = document.createElement('script');
    youtubeScript.src = 'https://apis.google.com/js/platform.js';
    youtubeScript.id = 'youtube-subscribe';
    this.buttonRef.current.parentNode.appendChild(youtubeScript);
    this.setState({
      isInitialized: true,
    });
  }

  componentWillUnmount() {
    const iframesData = document.getElementsByTagName('iframe');
    for (let i = 0; i < iframesData.length; i++) {
      const srcData = iframesData[i].src;
      const hasIframe = srcData.includes('www.youtube.com/subscribe_embed');
      if (hasIframe) {
        document.getElementsByTagName('iframe')[i].remove();
      }
    }
    document.getElementById('youtube-subscribe').remove();
  }

  render() {
    const { channelId, layout, theme, count } = this.props;
    return (
      <div className='youtube-subscribe-container'>
        <div
          ref={this.buttonRef}
          className='g-ytsubscribe'
          data-channelid={channelId}
          data-layout={layout}
          data-theme={theme}
          data-count={count}
        />
      </div>
    );
  }
}

YoutubeSubscribeBtn.defaultProps = {
  channelId: YOUTUBE_CHANNEL_ID,
  layout: 'default',
  theme: 'dark',
  count: 'hidden',
};

YoutubeSubscribeBtn.propTypes = {
  channelId: PropTypes.string.isRequired,
  layout: PropTypes.string,
  theme: PropTypes.string,
  count: PropTypes.string,
};
