import classNames from 'classnames';
import PropTypes from 'prop-types';

export const StatusPill = ({
  colors: { bg, text, bullet } = STATUS_COLORS.GRAY,
  width = 'w-40',
  children,
  className,
}) => {
  return (
    <div className={`status-pill-wrapper flex w-full ${className}`}>
      <div className={classNames('flex items-center font-semibold text-sm p-4 rounded-full w-40', bg, text, width)}>
        <div className={classNames('rounded-full w-3 h-3 mr-3', bullet)} />
        <div>{children}</div>
      </div>
    </div>
  );
};

StatusPill.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  className: PropTypes.string,
  colors: PropTypes.shape({
    bg: PropTypes.string,
    text: PropTypes.string,
    bullet: PropTypes.string,
  }),
};

export const STATUS_COLORS = {
  GRAY: {
    bg: 'bg-gradient-to-r from-alabaster',
    text: 'text-devil-gray',
    bullet: 'bg-devil-gray',
  },
  YELLOW: {
    bg: 'bg-gradient-to-r from-fuel-yellow-faded',
    text: 'text-fuel-yellow',
    bullet: 'bg-fuel-yellow',
  },
  GREEN: {
    bg: 'bg-gradient-to-r from-aqua-spring',
    text: 'text-persian-green',
    bullet: 'bg-persian-green',
  },
};
