import { IconProps } from '..';

export const MobileDeviceIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 1.5H11.25V2.25C11.25 2.66421 10.9142 3 10.5 3H7.5C7.08579 3 6.75 2.66421 6.75 2.25V1.5H6C5.17157 1.5 4.5 2.17157 4.5 3V15C4.5 15.8284 5.17157 16.5 6 16.5H12C12.8284 16.5 13.5 15.8284 13.5 15V3C13.5 2.17157 12.8284 1.5 12 1.5ZM6 0C4.34315 0 3 1.34315 3 3V15C3 16.6569 4.34315 18 6 18H12C13.6569 18 15 16.6569 15 15V3C15 1.34315 13.6569 0 12 0H6ZM9 15C9.41421 15 9.75 14.6642 9.75 14.25C9.75 13.8358 9.41421 13.5 9 13.5C8.58579 13.5 8.25 13.8358 8.25 14.25C8.25 14.6642 8.58579 15 9 15Z'
        fill={color ?? 'var(--color-primary)'}
      />
    </svg>
  );
};
