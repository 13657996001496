import { TEST_IDS } from '@va/constants';
import { DownloadIcon } from '@va/icons';
import { InvoiceIssuer } from '@va/standalone/shared/types';
import { Button } from '@va/ui/design-system';
import { InvoicesTypes } from './invoices-api-client';

type DownloadInvoiceButtonProps = {
  invoice: InvoicesTypes;
};

export function DownloadInvoiceButton({ invoice }: DownloadInvoiceButtonProps) {
  const onDownloadClick = () => {
    if (invoice.type === InvoiceIssuer.PADDLE) {
      window.open(invoice.receiptUrl, '_blank');
      return;
    }

    if (invoice.type === InvoiceIssuer.BRAINTREE) {
      window.open(`/invoice/${invoice.token}`, '_blank');
      return;
    }

    console.log('Invalid invoice issuer!');
  };

  return (
    <Button
      data-testid={TEST_IDS.generic.invoices.downloadInvoiceBtn}
      icon={(_, color) => <DownloadIcon color={color} />}
      color={'quaternary'}
      onClick={onDownloadClick}
    />
  );
}
