import classNames from 'classnames';
import { IconProps } from '.';

export const RecordIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      xmlns='http://www.w3.org/2000/svg'
      fill={color}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.25 0V1.5L6.75 1.5V0H11.25ZM9 12C10.6569 12 12 10.6569 12 9C12 7.34315 10.6569 6 9 6C7.34315 6 6 7.34315 6 9C6 10.6569 7.34315 12 9 12ZM9 13.5C11.4853 13.5 13.5 11.4853 13.5 9C13.5 6.51472 11.4853 4.5 9 4.5C6.51472 4.5 4.5 6.51472 4.5 9C4.5 11.4853 6.51472 13.5 9 13.5ZM6.75 16.5V18H11.25V16.5H6.75ZM18 11.25H16.5V6.75H18V11.25ZM1.5 6.75L0 6.75V11.25H1.5L1.5 6.75ZM16.5 4.5C16.5 2.84315 15.1569 1.5 13.5 1.5V0C15.9853 0 18 2.01472 18 4.5H16.5ZM1.5 4.5C1.5 2.84315 2.84315 1.5 4.5 1.5V0C2.01472 0 0 2.01472 0 4.5H1.5ZM1.5 13.5C1.5 15.1569 2.84315 16.5 4.5 16.5V18C2.01472 18 0 15.9853 0 13.5H1.5ZM16.5 13.5C16.5 15.1569 15.1569 16.5 13.5 16.5V18C15.9853 18 18 15.9853 18 13.5H16.5Z'
      />
    </svg>
  );
};
