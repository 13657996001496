import React, { useCallback } from 'react';

export const useMergeRefs = (refs: Array<React.Ref<HTMLElement | null>>) => {
  const mergeRefs = useCallback(
    (el: HTMLElement | null) => {
      refs.forEach((ref) => {
        if (typeof ref === 'function') {
          ref(el);
        } else {
          if (ref) {
            // @ts-ignore
            ref.current = el;
          }
        }
      });
    },
    [refs],
  );

  return mergeRefs;
};
