import { get } from '@va/http-client';

/**
 * @param  {string} resource
 * @return {requestFunction}
 */
const getRequest = (resource) => {
  /**
   * @function requestFunction
   *
   * @param  {string} websiteId
   * @param  {Object} filter
   * @param  {string} filter.from
   * @param  {string} filter.until
   * @return {Promise}
   */
  return function requestFunction(websiteId, filter) {
    const url = `/websites/${websiteId}/overview/${resource}`;
    return get(url, filter ? filter : {});
  };
};

export const getOverviewAggregatesVisitors = (websiteId, filter) => {
  return getRequest('visitors')(websiteId, filter);
};

export const getOverviewAggregatesNewVisitors = (websiteId, filter) => {
  return getRequest('new-visitors')(websiteId, filter);
};

export const getOverviewAggregatesPageVisits = (websiteId, filter) => {
  return getRequest('page-visits')(websiteId, filter);
};

export const getAggregatesConversions = (websiteId, filter) => {
  const url = `/websites/${websiteId}/conversions`;
  return get(url, filter ? filter : {});
};
