import { AnsweredQuestion } from '@va/dashboard/modules/feedback/shared';
import { LoadingLogo } from '@va/ui/design-system';
import PropTypes from 'prop-types';

export const FeedbackResultsPage = ({ results, isLoading, featureName }) => {
  if (isLoading)
    return (
      <div className='relative min-h-400'>
        <LoadingLogo />
      </div>
    );

  const { questions } = results;

  const totalParticipantsCount = Math.max.apply(
    Math,
    questions.map((q) => q.answerCount),
  );

  return (
    <div className='p-2 lg:p-6 space-y-6'>
      {questions.map((question, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <AnsweredQuestion
          featureName={featureName}
          totalParticipantsCount={totalParticipantsCount}
          key={index}
          number={index + 1}
          {...question}
        />
      ))}
    </div>
  );
};

FeedbackResultsPage.propTypes = {
  featureName: PropTypes.oneOf(['polls', 'surveys']).isRequired,
  isLoading: PropTypes.bool,
  results: PropTypes.shape({
    questions: PropTypes.array,
  }),
};
