export const CommentIcon = ({ color = 'black', ...props }: JSX.IntrinsicElements['svg']) => {
  return (
    <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M0.5 2.79999C0.5 1.55735 1.50736 0.549988 2.75 0.549988H10.25C11.4926 0.549988 12.5 1.55735 12.5 2.79999V7.29999C12.5 8.54263 11.4926 9.54999 10.25 9.54999H8L6.5 11.8L5 9.54999H2.75C1.50736 9.54999 0.5 8.54263 0.5 7.29999V2.79999Z'
        fill={color}
      />
    </svg>
  );
};
