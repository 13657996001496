import { useTranslate } from '@va/localization';
import { LogoApp } from '@va/svg-visa-icons';

export const ResetRequestedPage = () => {
  const translate = useTranslate();

  return (
    <div className='page-content reset-data-in-progress-content pt-16'>
      <div className='logo-container'>
        <LogoApp />
      </div>
      <div className='title'>{translate('card.deleteData.resetRequested.text1')}</div>
      <div className='subtitle'>{translate('modal.deleteData.confirmation.succeeded')}</div>
      <div className='page-footer-image' />
    </div>
  );
};
