export const GearIcon = () => {
  return (
    <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.1156 3.29899C11.7462 1.52582 14.2538 1.52582 14.8844 3.29899L15.1279 3.98365C15.525 5.1003 16.8005 5.62865 17.8709 5.11985L18.5272 4.80788C20.2269 3.99993 22.0001 5.77312 21.1921 7.47282L20.8802 8.12911C20.3714 9.19949 20.8997 10.475 22.0163 10.8721L22.701 11.1156C24.4742 11.7462 24.4742 14.2538 22.701 14.8844L22.0163 15.1279C20.8997 15.525 20.3714 16.8005 20.8802 17.8709L21.1921 18.5272C22.0001 20.2269 20.2269 22.0001 18.5272 21.1921L17.8709 20.8802C16.8005 20.3714 15.525 20.8997 15.1279 22.0163L14.8844 22.701C14.2538 24.4742 11.7462 24.4742 11.1156 22.701L10.8721 22.0163C10.475 20.8997 9.19949 20.3714 8.12911 20.8802L7.47282 21.1921C5.77312 22.0001 3.99993 20.2269 4.80788 18.5272L5.11985 17.8709C5.62865 16.8005 5.1003 15.525 3.98365 15.1279L3.29899 14.8844C1.52582 14.2538 1.52581 11.7462 3.29899 11.1156L3.98365 10.8721C5.1003 10.475 5.62865 9.19949 5.11985 8.12911L4.80788 7.47282C3.99994 5.77312 5.77312 3.99993 7.47282 4.80788L8.12911 5.11985C9.19949 5.62865 10.475 5.1003 10.8721 3.98365L11.1156 3.29899Z'
        stroke='#666666'
        strokeWidth='2'
      />
      <circle cx='13' cy='13' r='4' stroke='#666666' strokeWidth='2' />
    </svg>
  );
};
