import { OnChangeFn, PaginationState, SortingState } from '@tanstack/react-table';
import { openModal } from '@va/dashboard/actions/ui';
import { RightArrowIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { AddFilterButton, AppliedFilters } from '@va/shared/feature-filters';
import { modalName } from '@va/standalone/shared/constants';
import { DataViewOption } from '@va/types/report-block';
import { DataTableV8, useControlledTableState } from '@va/ui/components/data-table';
import { ReportBlock } from '@va/ui/components/report-block';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useAgencyWhiteLabelContext from '../../agency-white-label-v2/AgencyWhiteLabelContext';
import { OrderType, WebsiteType } from '../../agency-white-label-v2/agency-white-label-apiClient';
import TotalCommissionPayout from './commission-header/TotalCommissionPayout';
import { useCommissionDetailsColumns } from './useCommisionDetailsColumns';

const COMMISION_DETAILS_RB = 'commission-details';

const CommissionDetailsTable = () => {
  const translate = useTranslate();
  const { isLoading, totalWebsitesCount, websites, websiteFilterQuery, setWebsiteFilterQuery } =
    useAgencyWhiteLabelContext();

  const dispatch = useDispatch();
  const columns = useCommissionDetailsColumns();

  const { pagination, setPagination, sorting, setSorting } = useControlledTableState({
    pagination: { pageSize: websiteFilterQuery.length },
  });

  const handlePaginationChange = useCallback<OnChangeFn<PaginationState>>(
    (updater) => {
      if (typeof updater !== 'function') return;

      const updatedPagination = updater(pagination);
      setPagination(updatedPagination);
      setWebsiteFilterQuery((prevFilters) => ({
        ...prevFilters,
        start: (updatedPagination?.pageIndex ?? 0) * (updatedPagination?.pageSize ?? 0),
        length: updatedPagination?.pageSize ?? 10,
      }));
    },
    [pagination, setPagination, setWebsiteFilterQuery],
  );

  const handleSortingChange = useCallback<OnChangeFn<SortingState>>(
    (updater) => {
      if (typeof updater !== 'function') return;
      const updatedSorting = updater(sorting);
      setSorting(updatedSorting);
      setWebsiteFilterQuery((prevFilters) => ({
        ...prevFilters,
        order: updatedSorting[0]?.desc ? OrderType.DESC : OrderType.ASC,
      }));
    },
    [setSorting, setWebsiteFilterQuery, sorting],
  );

  return (
    <ReportBlock
      id={COMMISION_DETAILS_RB}
      title={translate('standalone.accountSettings.commission.title')}
      nodes={{
        rightSide: (
          <TotalCommissionPayout
            icon={<RightArrowIcon className='min-h-18 min-w-18' color='#969696' />}
            onClick={() => dispatch(openModal(modalName.COMMISSION_PAYOUTS))}
          />
        ),
        filterButton: <AddFilterButton />,
        appliedFilters: <AppliedFilters />,
        pagination: <div id={COMMISION_DETAILS_RB} />,
      }}
      visualization={{ selectedView: DataViewOption.table }}
      visualizationComponents={{
        [DataViewOption.table]: () => (
          <DataTableV8<WebsiteType>
            id='commission-details-table'
            className='min-w-[1000px]'
            columns={columns}
            data={isLoading ? [] : websites}
            state={{ pagination, sorting }}
            paginationContainerId={COMMISION_DETAILS_RB}
            onPaginationChange={handlePaginationChange}
            onSortingChange={handleSortingChange}
            isLoading={isLoading}
            rowCount={totalWebsitesCount}
          />
        ),
      }}
    />
  );
};

export default CommissionDetailsTable;
