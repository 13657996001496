import { updateTooltip } from '@va/dashboard/actions/ui';
import { getTooltips } from '@va/dashboard/selectors/ui';
import invariant from 'invariant';
import React from 'react';
import { connect } from 'react-redux';

function getComponentName(component) {
  return component.displayName || component.name || 'Component';
}

/**
 * Create a new React component that passes the 'hovered' prop to the wrapped component.
 * The prop is a boolean and is true when the wrapped component is hovered and false otherwise.
 *
 * Usage:
 * @example
 * import withHover from '<path-to-this-file>';
 *
 * function MyComponent(props) {
 *    return props.hovered ? 'Hovered!' : 'Not hovered.';
 * }
 *
 * export default withHover()(MyComponent);
 * // OR
 * export default withHover('div', 'hover-class')(MyComponent);
 * // OR
 * export default withHover('p', 'hover-class', {width: '50%'})(MyComponent);
 *
 * @param {string} [element=span] - React element to use as a wrapper
 * @param {string} [className] - Class supplied to the wrapper
 * @param {object} [style] - Style supplied to the wrapper
 * @return {function}
 * @deprecated
 */
export function withHover(element, className, style) {
  return function withHover(WrappedComponent) {
    invariant(
      typeof WrappedComponent === 'function',
      `You must pass a component to the function returned by connect. Instead received ${JSON.stringify(
        WrappedComponent,
      )}`,
    );

    class WithHover extends React.Component {
      constructor(props) {
        super(props);
        this.state = { isHovered: false };
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
      }

      onMouseEnter() {
        if (!this.state.isHovered) {
          this.setState({ isHovered: true });
        }
      }

      onMouseLeave() {
        if (this.state.isHovered) {
          this.setState({ isHovered: false });
        }

        if (this.props.tooltipId && !this.isFrozen()) {
          this.props.updateTooltip(this.props.tooltipId, { show: false });
        }
      }

      isFrozen() {
        const item = this.props.tooltips.get(this.props.tooltipId);
        return item.get('frozen', false);
      }

      render() {
        const Component = element ? element : 'span';

        // We need to extract the props that come from connect
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        const { tooltips, updateTooltip, ...propsToPass } = this.props;

        return (
          <Component
            className={className}
            style={style}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
          >
            <WrappedComponent {...propsToPass} hovered={this.props.active || this.state.isHovered} />
          </Component>
        );
      }
    }

    const mapStateToProps = (state) => {
      return {
        tooltips: getTooltips(state),
      };
    };

    const mapDispatchToProps = {
      updateTooltip: updateTooltip,
    };

    WithHover.displayName = `WithHover(${getComponentName(WrappedComponent)})`;
    return connect(mapStateToProps, mapDispatchToProps)(WithHover);
  };
}
