import { getWebsite, isPremium } from '@va/dashboard/selectors/core';
import { createSelector } from 'reselect';

export const isMigrationEmailSent = (state: any) => state.getIn(['app', 'isMigrationEmailSent'], false);

export const isMonthlyPayer = createSelector(
  [(state: any) => state.getIn(['core', 'website', 'cycleType'], 0), isPremium],
  (cycleType, isPremium) => {
    /*
     cycleType: { 0, 1, 2, 3 }
     0 - unknown
     1 - free
     2 - monthly
     3 - yearly
     */
    return isPremium && cycleType === 2;
  },
);

export const getMetaSiteId = createSelector([getWebsite], (website) => {
  return website.metaSiteId;
});

export const getPremiumPlans = (state: any) => state.getIn(['app', 'premiumPlan']).toJS();
