import { closeModal } from '@va/dashboard/actions/ui';
import { isModalOpen } from '@va/dashboard/selectors/ui';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

/** @deprecated */
export function withModal(WrappedComponent, modalName) {
  class WithModal extends Component {
    render() {
      return <WrappedComponent isOpen={this.props.isModalOpen} closeModal={this.props.closeModal} {...this.props} />;
    }
  }

  WithModal.propTypes = {
    name: PropTypes.string,
    isModalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
  };

  const mapStateToProps = (state) => ({
    isModalOpen: isModalOpen(state, modalName),
  });

  const mapDispatchToProps = {
    closeModal: () => closeModal(modalName),
  };

  return connect(mapStateToProps, mapDispatchToProps)(WithModal);
}
