import { getInstanceId } from '@va/dashboard/selectors/app';
import PopUpCard from '@va/deprecated/components/PopUpCard';
import { useLocale, useTranslate } from '@va/localization';
import { updateSubscription } from '@va/standalone/shared/actions';
import {
  SUBSCRIPTION_PROVIDER_TYPE,
  subscriptionType as SUBSCRIPTION_TYPE,
  subscriptionStatus,
} from '@va/standalone/shared/constants';
import {
  getLastCreatedSubscription,
  getPaymentPlans,
  getSelectedPlanId,
  getSubscriptionPlan,
  getSubscriptionType,
} from '@va/standalone/shared/selectors';
import { LoadingLogo } from '@va/ui/design-system';
import classNames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalFooter from '../../common/components/modal-footer';

const CheckoutCard = ({
  unitPrice,
  taxPrice,
  promotionalDiscountPrice,
  totalPrice,
  currency,
  showCheckoutComplete,
  showAddVatReminder,
}) => {
  const Paddle = window.Paddle;
  const dispatch = useDispatch();
  const websiteKey = useSelector(getInstanceId);
  const selectedSubscriptionType = useSelector(getSubscriptionType);
  const selectedSubscriptionPlan = useSelector(getSubscriptionPlan);
  const selectedPlanId = useSelector(getSelectedPlanId);
  const subscription = useSelector(getLastCreatedSubscription);
  const paymentPlans = useSelector(getPaymentPlans);
  const { locale } = useLocale();
  const translate = useTranslate();
  const [unitPriceWithoutVat, setUnitPriceWithoutVat] = useState(unitPrice);
  const [pricesWithoutVat, setPricesWithoutVat] = useState(false);
  const shouldUpdateSubscription =
    !showCheckoutComplete &&
    subscription &&
    subscription.type === SUBSCRIPTION_PROVIDER_TYPE.PADDLE &&
    subscription.status === subscriptionStatus.ACTIVE &&
    !subscription.isActiveUntil;
  const shouldShowUpgradeFromCancelRequestedMessage =
    subscription && subscription.status === subscriptionStatus.ACTIVE && subscription.isActiveUntil;

  useEffect(() => {
    if (unitPrice) setUnitPriceWithoutVat(unitPrice);
  }, [unitPrice]);

  useEffect(() => {
    if (!shouldUpdateSubscription) {
      Paddle.Checkout.open({
        method: 'inline',
        product: selectedPlanId,
        passthrough: `{"websiteKey": "${websiteKey}", "billingCycle": "${selectedSubscriptionType}"}`,
        locale: locale,
        frameTarget: 'upgrade-checkout-form', // The className of your checkout <div>
        allowQuantity: false,
        disableLogout: true,
        frameInitialHeight: 416,
        frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;', // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
      });
    } else {
      const selectedPaymentPlans = paymentPlans[selectedSubscriptionType];
      setUnitPriceWithoutVat(selectedPaymentPlans[selectedSubscriptionPlan].price);
      setPricesWithoutVat(true);
    }
  }, []);

  const priceType = selectedSubscriptionType === SUBSCRIPTION_TYPE.YEARLY ? 'perYear' : 'perMonth';
  return (
    <PopUpCard
      type={'card-grey'}
      title={translate('upgradeModal.standalone.title.checkout')}
      subtitle={
        <CheckoutCardSubtitle showCheckoutComplete={showCheckoutComplete} showAddVatReminder={showAddVatReminder} />
      }
      colorTheme={'no-theme'}
      footerButtons={
        shouldUpdateSubscription && (
          <ModalFooter
            submitBtnText={translate('button.changeSubscription')}
            onSubmitClick={() => dispatch(updateSubscription())}
          />
        )
      }
      withBackBtn
      smallerBottomSpace
      noPadding
    >
      <div className='plan-info-container'>
        <div className='upgrade-checkout-form' />

        <div
          className={classNames('relative mt-3 mb-6px sm:my-4 border-2 border-solid border-white-smoke rounded-12', {
            'min-h-200': !unitPriceWithoutVat,
          })}
        >
          {!unitPriceWithoutVat ? (
            <LoadingLogo />
          ) : (
            <Fragment>
              {unitPriceWithoutVat && (
                <PriceElement
                  keyLabel={translate(`upgradeCard.billing.price.${priceType}`)}
                  valueLabel={`${currency}${unitPriceWithoutVat}`}
                />
              )}
              <PriceElement
                keyLabel={translate('upgradeCard.billing.price.promoCodeDiscount')}
                valueLabel={`-${currency}${promotionalDiscountPrice}`}
              />
              <PriceElement
                keyLabel={translate('upgradeCard.billing.price.vat')}
                valueLabel={`${currency}${taxPrice}`}
              />
              <PriceElement
                keyLabel={translate(`upgradeCard.billing.grandTotal.${priceType}`)}
                valueLabel={`${currency}${totalPrice}`}
                isLastOne
                semiBold
              />
            </Fragment>
          )}
        </div>
        {pricesWithoutVat && (
          <Fragment>
            <div className='py-1 text-sm'>
              {translate('upgradeCard.billing.changeSubscription.pricesWithoutVatNotice')}
            </div>
            <div className='py-1 text-xs'>
              {translate('upgradeCard.billing.changeSubscription.pricesProRatedNotice')}
            </div>
          </Fragment>
        )}
        {shouldShowUpgradeFromCancelRequestedMessage && (
          <div className='py-1 text-sm'>
            {translate('upgradeCard.billing.changeSubscription.upgradeFromCancelRequestedNotice')}
          </div>
        )}
      </div>
    </PopUpCard>
  );
};

export default CheckoutCard;

const CheckoutCardSubtitle = ({ showCheckoutComplete, showAddVatReminder }) => {
  const translate = useTranslate();
  if (showCheckoutComplete) {
    return translate('upgradeModal.standalone.subtitle.checkoutComplete');
  } else if (showAddVatReminder) {
    return translate('upgradeModal.standalone.subtitle.addVatReminder');
  }

  return null;
};

const PriceElement = ({ keyLabel, valueLabel, semiBold, isLastOne }) => {
  return (
    <div
      className={classNames('p-4 flex justify-between border-solid border-gray-gallery', {
        'border-b-2': !isLastOne,
      })}
    >
      <div className='flex items-baseline'>
        <div
          className={classNames('font-inter font-medium leading-normal text-gray-devil', {
            'text-18': semiBold,
            'text-15': !semiBold,
          })}
        >
          {keyLabel}
        </div>
      </div>
      <div
        className={classNames('font-inter text-18 leading-5', {
          'font-semibold': semiBold,
          'font-medium': !semiBold,
        })}
      >
        {valueLabel}
      </div>
    </div>
  );
};
