import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class Tooltip extends PureComponent {
  getContainer() {
    return this.container;
  }

  render() {
    const className = `tooltip-component place-${this.props.place}`
      + (this.props.show ? ' show' : '')
      + (this.props.className ? ` ${this.props.className}` : '');

    return <div className={className} style={this.props.position.tooltip} ref={node => this.container = node}>
      {this.props.render(this.props.data)}
      <div className="after" style={this.props.position.anchor}/>
    </div>;
  }
}

Tooltip.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,

  place: PropTypes.oneOf(['top', 'bottom', 'left', 'right']).isRequired,
  position: PropTypes.shape({
    tooltip: PropTypes.shape({
      top: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
    }).isRequired,
    anchor: PropTypes.shape({
      top: PropTypes.number,
      left: PropTypes.number,
    })
  }).isRequired,
  show : PropTypes.bool.isRequired,
  render: PropTypes.func.isRequired,
};
