import classNames from 'classnames';
import React from 'react';
import { IconProps } from '@va/icons';

export const AbeIcon = ({ color, className }: IconProps) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classNames(className)}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.4988 2.12012L2.55946 10.0595C1.97367 10.6452 1.97367 11.595 2.55946 12.1808L5.81682 15.4381C6.4026 16.0239 7.35235 16.0239 7.93814 15.4381L10.0972 13.2791L11.1578 14.3398L8.9988 16.4988C7.82722 17.6704 5.92773 17.6704 4.75616 16.4988L1.4988 13.2414C0.327224 12.0699 0.327224 10.1704 1.4988 8.9988L9.87748 0.620117H15.8775H17.3775V2.12012V8.12012L14.3398 11.1578L13.2791 10.0971L15.8775 7.4988V2.12012H10.4988ZM14.3775 5.12012C14.3775 5.94854 13.7059 6.62012 12.8775 6.62012C12.049 6.62012 11.3775 5.94854 11.3775 5.12012C11.3775 4.29169 12.049 3.62012 12.8775 3.62012C13.7059 3.62012 14.3775 4.29169 14.3775 5.12012Z'
        fill={color}
      />
      <path opacity='0.75' d='M9 9L16.5 16.5M16.5 16.5V12M16.5 16.5H12' stroke={color} strokeWidth='1.5' />
    </svg>
  );
};
