import { IconProps } from '.';

export const EndChainIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='12'
      height='19'
      viewBox='0 0 12 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 12.25C1.5 9.76472 3.51472 7.75 6 7.75C8.48528 7.75 10.5 9.76472 10.5 12.25C10.5 14.7353 8.48528 16.75 6 16.75C3.51472 16.75 1.5 14.7353 1.5 12.25ZM5.25 6.29642C2.29027 6.66549 7.92025e-07 9.19029 5.24537e-07 12.25C2.34843e-07 15.5637 2.68629 18.25 6 18.25C9.31371 18.25 12 15.5637 12 12.25C12 9.19029 9.70973 6.66549 6.75 6.29642L6.75 4L5.25 4L5.25 6.29642ZM5.25 2.5L6.75 2.5L6.75 0.25L5.25 0.249999L5.25 2.5ZM3.75 12.25C3.75 11.0074 4.75736 10 6 10C7.24264 10 8.25 11.0074 8.25 12.25C8.25 13.4926 7.24264 14.5 6 14.5C4.75736 14.5 3.75 13.4926 3.75 12.25Z'
        fill={color}
      />
    </svg>
  );
};


