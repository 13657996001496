import { useTranslate } from '@va/localization';
import { ConfirmationDialogProps, ConfirmationModal } from '@va/shared/util-confirmation';
import { Button } from '@va/ui/design-system';

export function LanguageSelectionConfirmationModal({ onClose, onConfirm }: ConfirmationDialogProps) {
  const translate = useTranslate();

  return (
    <ConfirmationModal
      info={translate('all.languageConfirmation.info')}
      title={translate('all.languageConfirmation.title')}
      isOpen={true}
      onClose={onClose}
      onConfirmation={onConfirm}
      footerButtons={
        <div className='flex gap-2'>
          <Button onClick={onClose} className='flex-1' color='tertiary' text={translate('button.cancel')} />
          <Button
            onClick={onConfirm}
            className='flex-1'
            color='primary'
            text={translate('all.languageConfirmation.selectAutoLanguage')}
          />
        </div>
      }
    />
  );
}
