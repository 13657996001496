import { cardColorTheme } from '@va/constants';
import { TooltipWrapper } from '@va/ui/tooltips';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/**
 * @deprecated Use the button from /design-system/button
 * @see /design-system/button
 */
export default class Button extends PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  onClick(event) {
    if (this.props.disabled) {
      event.preventDefault();
    } else if (this.props.onClick != null) {
      if (this.props.websiteId) {
        this.props.setUpgradeRequest(this.props.websiteId);
      }
      this.props.onClick();
    }
  }
  getBtnClass() {
    let className = 'button ';
    className += this.props.colorTheme + ' ';
    className += this.props.disabled ? ' disabled ' : '';
    className += this.props.filled ? ' filled ' : '';
    className += this.props.bigger ? ' bigger ' : '';
    className += this.props.className ? this.props.className : '';

    return className;
  }
  render() {
    return (
      <TooltipWrapper
        disabled={!this.props.tooltipId}
        interactive
        content={<div className={'tooltip card-tooltip'}>{this.props.tooltip}</div>}
      >
        <button
          className={this.getBtnClass()}
          disabled={this.props.tooltip ? false : this.props.disabled}
          onClick={(event) => this.onClick(event)}
        >
          {this.props.text ? this.props.text : this.props.children}
        </button>
      </TooltipWrapper>
    );
  }
}

Button.propTypes = {
  colorTheme: PropTypes.oneOf(Object.values(cardColorTheme)).isRequired,
  text: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  bigger: PropTypes.bool,
  filled: PropTypes.bool,
  tooltipId: PropTypes.string,
  tooltipPlace: PropTypes.string,
  tooltip: PropTypes.string,
  className: PropTypes.string,
};

Button.defaultProps = {
  bigger: false,
  disabled: false,
  colorTheme: 'theme-green',
  tooltipPlace: 'top',
};
