import { IconProps } from '.';

export const ExpandIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C7.63392 1.5 6.35315 1.86523 5.25 2.50337V15.4966C6.35315 16.1348 7.63392 16.5 9 16.5ZM1.5 9C1.5 6.90195 2.36148 5.00512 3.75 3.64393V14.3561C2.36148 12.9949 1.5 11.098 1.5 9ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9.96967 11.4697L11.6893 9.75H7.5V8.25H11.6893L9.96967 6.53033L11.0303 5.46967L14.0303 8.46967L14.5607 9L14.0303 9.53033L11.0303 12.5303L9.96967 11.4697Z'
        fill={color}
      />
    </svg>
  );
};


