import { MappedDataType } from '@va/dashboard/api-client/VisitorsOverviewV2';
import { useTranslate } from '@va/localization';
import { ExternalTooltip, LineChart, LoadingLogo } from '@va/ui/design-system';
import { memo } from 'react';

type VisitorChartType = {
  chartId: string;
  inProgress: boolean;
  data?: MappedDataType;
  hasError: boolean;
};

const VisitorsChart = ({ chartId, inProgress, data, hasError }: VisitorChartType) => {
  const translate = useTranslate();

  if (inProgress)
    return (
      <div className='relative h-full w-full'>
        <LoadingLogo />
      </div>
    );
  if (hasError)
    return (
      <div className='h-full w-full flex justify-center items-center'>{translate('notifications.generalError')}</div>
    );

  return (
    <LineChart
      id={chartId}
      chartData={getChartData(data)}
      className='h-full'
      tooltipFn={(context) => getChartTooltip(context, data)}
    />
  );
};

export default memo(VisitorsChart);

/** @deprecated */
function getChartTooltip(context: any, data: any) {
  if (!context) return null;
  return (
    <ExternalTooltip
      context={context}
      params={{
        datasets: data?.tooltipDatasets ?? ({} as any),
        dates: data?.dates ?? ({} as any),
        showPrevious: false,
      }}
    />
  );
}

/** @deprecated */
function getChartData(data: any) {
  return {
    datasets: data?.currentDataset ?? [],
    labels: data?.currentLabels ?? [],
  };
}
