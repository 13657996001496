import { newApiRequest } from '@va/dashboard/reducers';
import * as Types from '@va/standalone/shared/actions';

export const weeblyLogin = newApiRequest(
  Types.standalone.Api.WEEBLY_LOGIN,
  Types.standalone.Api.WEEBLY_LOGIN_SUCCEEDED,
  Types.standalone.Api.WEEBLY_LOGIN_FAILED,
);

export const weeblySync = newApiRequest(
  Types.standalone.Api.WEEBLY_SYNC,
  Types.standalone.Api.WEEBLY_SYNC_SUCCEEDED,
  Types.standalone.Api.WEEBLY_SYNC_FAILED,
);
