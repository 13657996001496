import {
  sendFeedbackFailed,
  sendFeedbackSucceeded,
  sendReviewFailed,
  sendReviewSucceeded,
  SEND_FEEDBACK_REQUEST,
  SEND_REVIEW_REQUEST,
} from '@va/dashboard/actions/api';
import Api from '@va/dashboard/api-client/index';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

export function* watchers() {
  yield all([takeLatest(SEND_FEEDBACK_REQUEST, sendFeedback), takeLatest(SEND_REVIEW_REQUEST, sendReview)]);
}

export function* sendFeedback(action) {
  try {
    const websiteId = yield select(getInstanceId);
    const data = yield call(Api.sendFeedback, websiteId, {
      liked: action.liked,
      message: action.message,
    });
    yield put(sendFeedbackSucceeded(data));
  } catch (error) {
    yield put(sendFeedbackFailed(error));
  }
}

export function* sendReview(action) {
  try {
    const websiteId = yield select(getInstanceId);
    const data = yield call(Api.sendReview, websiteId, {
      dontShowReview: action.dontShowReview,
      reviewStars: action.reviewStars,
      reviewFeedback: action.reviewFeedback,
    });
    yield put(sendReviewSucceeded(data));
  } catch (error) {
    yield put(sendReviewFailed(error));
  }
}
