import { PlanNames, Platform, TEST_IDS } from '@va/constants';
import { startUpgradeProcess } from '@va/dashboard/actions/app';
import { LightIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { subscriptionStatus } from '@va/standalone/shared/constants';
import { getPlanFrequencyById } from '@va/standalone/shared/selectors';
import { UserRole } from '@va/types/website';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { SubscriptionType, WebsiteType } from '../agency-white-label-apiClient';
import BillingPlan from './BillingPlan';

const PlanInfoCell = ({
  subscriptionInfo,
  websiteInfo,
  showPlanFrequency = true,
}: {
  subscriptionInfo: SubscriptionType;
  websiteInfo: WebsiteType;
  showPlanFrequency: boolean;
}) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const isWix = websiteInfo.platform.toLowerCase() === Platform.WIX.toLowerCase();
  const onUpgradeClick = () => dispatch(startUpgradeProcess({ websiteId: websiteInfo.id }));
  const websiteSubscription = websiteInfo.subscription;
  const hasActiveSubscription = [subscriptionStatus.ACTIVE, subscriptionStatus.PAUSED].includes(
    websiteSubscription?.status,
  );
  const activeUntil = hasActiveSubscription && (websiteSubscription?.isActiveUntil || websiteSubscription?.activeUntil);
  const planName = websiteSubscription?.planName;
  const isContributor = websiteInfo.types[0] !== UserRole.OWNER;

  const frequency = useSelector((state) => getPlanFrequencyById(state, websiteSubscription?.planId));

  return (
    <TooltipWrapper disabled={!isContributor || isWix} content={translate('tooltips.contributorRole')}>
      <div
        className={classNames(
          'inline-flex items-center gap-1.5 py-9px pl-3 pr-15px group-hover:bg-white bg-white-snow rounded-10.5 cursor-pointer text-15 leading-24 font-medium text-gray-charcoal',
          { 'opacity-50': isContributor },
        )}
        onClick={() => {
          if (isContributor) return;
          onUpgradeClick();
        }}
        data-testid={TEST_IDS.generic.whiteLabel.pricingPlanBtn}
      >
        <LightIcon
          color={getIconColorAccordingToPlan(
            planName as PlanNames,
            hasActiveSubscription || !!activeUntil,
            websiteInfo.unlimitedPlanActive,
          )}
        />
        <div className={classNames('flex flex-column')}>
          <BillingPlan website={websiteInfo} />
          {subscriptionInfo && showPlanFrequency && (
            <div
              className={classNames('font-medium text-xs leading-18', {
                'text-red-negative': !hasActiveSubscription,
                'text-green-green': hasActiveSubscription,
              })}
            >
              {frequency && translate(`websitePlan.frequency.${frequency}`)}
            </div>
          )}
        </div>
      </div>
    </TooltipWrapper>
  );
};

export default PlanInfoCell;

const getIconColorAccordingToPlan = (planName: PlanNames, hasActiveSubscription = true, unlimitedPlan = false) => {
  if (unlimitedPlan) return '#04B5A6';
  if (!hasActiveSubscription) return '#ED290E';
  switch (planName) {
    case PlanNames.Advanced:
      return '#2478F0';
    case PlanNames.Pro:
    case PlanNames.ProPlus:
    case PlanNames.ProPlusPlus:
      return '#F9995D';
    case PlanNames.Executive:
    case PlanNames.ExecutivePlus:
    case PlanNames.ExecutivePlusPlus:
      return '#F66F1E';
    case PlanNames.Unicorn:
      return '#ED3FA5';
    default:
      return '#3C3C3C';
  }
};
