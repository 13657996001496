import {
  modalName as commonModalName,
  defaultStepFilter,
  defaultTrafficFilter,
  panelsPaginationTabNames,
} from '@va/constants';
import {
  ADD_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  CLOSE_MODAL,
  HIDE_BANNER,
  MARK_ANNOUNCEMENT_VIEWED_IN_SESSION,
  OPEN_CHECK_LOGIN_MODAL,
  OPEN_MODAL,
  REMOVE_NOTIFICATION,
  RESET_CLEAR_NOTIFICATIONS,
  RESET_PANELS_PAGINATION,
  RESET_UPGRADE_REQUEST,
  SET_ACTIVE_TAB,
  SET_ANNOUNCEMENT_DATA,
  SET_CARD_VIEW,
  SET_CONSENT_IN_SESSION,
  SET_DEMOGRAPHIC_COUNTRY,
  SET_DEVICE_PAGE_BAR_CHART_ID,
  SET_GLOBAL_FILTER,
  SET_IS_WELCOME_PAGE,
  SET_LOCALE,
  SET_MAIN_DASHBOARD_CHART_PAGE_URL_FILTER_VALUE,
  SET_MODAL_MOUNTED,
  SET_NEW_FUNNEL_URL_PATTERN,
  SET_NEW_HEATMAP_URL_PATTERN,
  SET_PASSWORD_WRONG_MESSAGE_VISIBILITY,
  SET_RECORDINGS_OVERVIEW_INITIAL_FILTERS,
  SET_RECORDINGS_OVERVIEW_PAGE_URL_FILTER_VALUE,
  SET_STEP_FILTER,
  SET_TAB_PROPERTY,
  SET_TRAFFIC_FILTER,
  SET_WINDOW_WIDTH,
  SHOW_BANNER,
  TOGGLE_BAR_CHART_SHOWN_DATA,
  TOGGLE_STEP_FILTER_WITH_PREVIOUS,
  UPGRADE_REQUEST,
} from '@va/dashboard/actions/ui';
import Immutable from 'immutable';

import { navigationReducer } from '@va/dashboard/slices';
import { applyReducers } from '../reducers.helpers';
import filterReducer from './filter';
import { notificationsReducer } from './notifications';
import tooltipsReducer from './tooltips';
import translationsReducer from './translations';

//TODO: Add further modals here by priority.
const uiModalsPriority = [
  //EmailLink click opening
  commonModalName.changeEmailConfirmation,
  commonModalName.resetEmailConfirmation,
  commonModalName.deleteAccountConfirmation,
  commonModalName.upgrade,

  //Automatic opening
  commonModalName.addWebsite,
  commonModalName.firstLogin,
  commonModalName.noData,
  commonModalName.boostTraffic,
  commonModalName.upgradeToYearly,
  commonModalName.recordingsSettings,
  commonModalName.upgradeUntilPriceRises,
  commonModalName.updateCompanyDetail,
  commonModalName.announcement,
  commonModalName.visitsLimitReached,
  commonModalName.announceGoogleImport,
  commonModalName.googleImport,
  commonModalName.featureLimitReached,

  //User interaction opening
  commonModalName.changeEmail,
  commonModalName.changePassword,
  commonModalName.deleteAccount,
  commonModalName.signDPA,
  commonModalName.exportData,
  commonModalName.accountSettingsRequired,
  commonModalName.directAccess,
  commonModalName.heatmapManualScreenshot,
];

function getNextModalToOpen(state) {
  let nextModalName;

  uiModalsPriority.every((modalName) => {
    if (state.getIn(['modals', 'queue']).keySeq().includes(modalName)) {
      nextModalName = modalName;
      return false;
    }

    return true;
  });

  return nextModalName;
}

const uiReducer = (
  state = Immutable.Map({
    locale: 'en',
    trafficFilter: defaultTrafficFilter,
    stepFilter: defaultStepFilter,
    isWelcomePage: false,
  }),
  action,
) => {
  switch (action.type) {
    case SET_LOCALE:
      return state.set('locale', action.locale);
    case SET_TAB_PROPERTY:
      return state.setIn([action.tab, action.propertyName], action.propertyValue);
    case ADD_NOTIFICATION:
      return state.set('notification', action.notification);
    case REMOVE_NOTIFICATION:
      return state.remove('notification');
    case CLEAR_NOTIFICATIONS:
      return state.set('clearNotifications', true);
    case RESET_CLEAR_NOTIFICATIONS:
      return state.set('clearNotifications', false);
    case SET_ACTIVE_TAB: {
      const currentActiveTab = state.get('activeTab');
      return state.set('activeTab', action.tab).set('previousActiveTab', currentActiveTab);
    }
    case SET_GLOBAL_FILTER: {
      let tabName = state.get('activeTab');
      const currentFilters = state.getIn(['globalFilter']);
      if (panelsPaginationTabNames.includes(tabName)) {
        return state
          .setIn(['globalFilter'], action.filter)
          .setIn(['previousGlobalFilter'], currentFilters)
          .setIn(['panelsPagination', tabName, 'start'], action.filter.start)
          .setIn(['panelsPagination', tabName, 'length'], action.filter.length);
      }

      return state.setIn(['globalFilter'], action.filter).setIn(['previousGlobalFilter'], currentFilters);
    }
    case RESET_PANELS_PAGINATION:
      return state.remove('panelsPagination');
    case SET_TRAFFIC_FILTER:
      return state.setIn(['trafficFilter'], action.filter);
    case SET_RECORDINGS_OVERVIEW_INITIAL_FILTERS:
      return state.setIn(['recordingsOverviewInitialFilter'], action.filter);
    case SET_MAIN_DASHBOARD_CHART_PAGE_URL_FILTER_VALUE:
      return state.setIn(['urlParamsAppConfig', 'mainDashboardChart', 'pageUrlFilterValue'], action.pageUrl);
    case SET_RECORDINGS_OVERVIEW_PAGE_URL_FILTER_VALUE:
      return state.setIn(['urlParamsAppConfig', 'recordingsOverview', 'pageUrlFilterValue'], action.pageUrl);
    case SET_NEW_HEATMAP_URL_PATTERN:
      return state.setIn(['urlParamsAppConfig', 'heatmaps', 'newHeatmapPageUrl'], action.urlPattern);
    case SET_STEP_FILTER:
      return state.setIn(['stepFilter'], action.filter);
    case SET_NEW_FUNNEL_URL_PATTERN:
      return state.setIn(['urlParamsAppConfig', 'funnels', 'newFunnelPageUrl'], action.urlPattern);
    case TOGGLE_STEP_FILTER_WITH_PREVIOUS:
      return state.update('stepFilterWithPrevious', (value) => !value);
    case SET_WINDOW_WIDTH:
      return state.setIn(['windowWidth'], action.width);
    case SET_DEMOGRAPHIC_COUNTRY:
      return state.setIn(['demographics', 'selectedCountry'], action.country);
    case TOGGLE_BAR_CHART_SHOWN_DATA:
      return state.updateIn(
        ['horizontalBarChart', 'showAllData', action.chartId ? action.chartId : 'default'],
        (value) => !value,
      );
    case SET_CARD_VIEW:
      return state
        .setIn([action.cardName, 'view'], action.view)
        .setIn([action.cardName, 'previousView'], action.previousView);
    case SET_MODAL_MOUNTED:
      return state.setIn(['modals', 'mounted'], true);
    case OPEN_CHECK_LOGIN_MODAL:
      return state
        .setIn(['modals', 'checkLogin', 'open'], true)
        .setIn(['modals', 'checkLogin', 'pendingModalName'], action.pendingModalName)
        .setIn(['modals', 'checkLogin', 'colorTheme'], action.colorTheme);
    case SET_PASSWORD_WRONG_MESSAGE_VISIBILITY:
      return state.updateIn(['modals', 'checkLogin', 'showPasswordWrongMessage'], () => action.value);
    case OPEN_MODAL:
      state = state.mergeIn(['modals', 'queue'], Immutable.fromJS({ [action.modalName]: 'pending' }));
      if (action.extraProps) {
        state = state.setIn(['modals', action.modalName, 'extraProps'], Immutable.fromJS(action.extraProps));
      }

      if (state.getIn(['modals', 'queue']).size === 1) {
        return state.setIn(['modals', action.modalName, 'open'], true);
      }
      return state;
    case CLOSE_MODAL:
      state = state.deleteIn(['modals', 'queue', action.modalName]).setIn(['modals', action.modalName, 'open'], false);
      if (state.getIn(['modals', action.modalName], 'extraProps')) {
        state = state.deleteIn(['modals', action.modalName, 'extraProps']);
      }

      if (state.getIn(['modals', 'queue']) && state.getIn(['modals', 'queue']).size > 0) {
        return state.setIn(['modals', getNextModalToOpen(state), 'open'], true);
      }
      return state;
    case SHOW_BANNER:
      return state.setIn(['banners', action.bannerName, 'open'], true);
    case HIDE_BANNER:
      return state.setIn(['banners', action.bannerName, 'open'], false);
    case SET_ANNOUNCEMENT_DATA:
      return state.setIn(['modals', 'announcement', 'data'], [...action.value]);
    case MARK_ANNOUNCEMENT_VIEWED_IN_SESSION:
      return state.setIn(['modals', 'announcement', 'viewedInSession'], true);
    case UPGRADE_REQUEST:
      return state.set('upgradeRequest', action.id);
    case RESET_UPGRADE_REQUEST:
      return state.set('upgradeRequest', null);
    case SET_CONSENT_IN_SESSION:
      return state.setIn(['consent', action.consentFlag], action.value);
    case SET_DEVICE_PAGE_BAR_CHART_ID:
      return state.set('devicePageBarChartId', action.value);
    case SET_IS_WELCOME_PAGE: {
      return state.set('isWelcomePage', action.value);
    }
    default:
      return applyReducers(state, action, {
        tooltips: tooltipsReducer,
        translations: translationsReducer,
        filter: filterReducer,
        notifications: notificationsReducer,
        navigation: navigationReducer,
      });
  }
};
export default uiReducer;
