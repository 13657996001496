import { TEST_IDS } from '@va/constants';
import { useTranslate } from '@va/localization';
import { TestAttributes } from '@va/types/component';
import { Button, ButtonColors, GrayGradientDivider, ModalHeader } from '@va/ui/design-system';
import { ModalWrapper } from '@va/util/components';
import classNames from 'classnames';
import { FormEvent, PropsWithChildren, ReactNode } from 'react';

type ModalFormWrapperProps = {
  title: string | ReactNode;
  onClose: () => void;
  isOpen: boolean;
  onSubmit: (e?: FormEvent<HTMLFormElement>) => void;
  isSaveButtonDisabled?: boolean;
  additionalFooterContent?: ReactNode;
  saveButtonProps?: {
    color?: ButtonColors;
    text?: string;
    className?: string;
  };
  cancelButtonProps?: {
    color?: ButtonColors;
    text?: string;
    className?: string;
  };
  className?: string;
} & TestAttributes;

export const ModalFormWrapper: React.FC<PropsWithChildren<ModalFormWrapperProps>> = ({
  title,
  onClose,
  isOpen,
  onSubmit,
  children,
  isSaveButtonDisabled = false,
  saveButtonProps,
  cancelButtonProps,
  className,
  additionalFooterContent,
  ...rest
}) => {
  const translate = useTranslate();

  return (
    <ModalWrapper closeModal={onClose} isModalOpen={isOpen}>
      <div data-testid={rest['data-testid']} className={classNames('max-w-600 w-screen', className)}>
        <ModalHeader title={title} onClose={onClose} />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(e);
          }}
        >
          <div className='p-3'>{children}</div>
          <GrayGradientDivider />
          {additionalFooterContent}
          <div className='flex flex-row flex-nowrap p-3 gap-3'>
            <Button
              type='button'
              data-testid={TEST_IDS.generic.buttons.close}
              className={classNames('rounded-12 flex-grow w-1/4', cancelButtonProps?.className)}
              color={cancelButtonProps?.color || 'tertiary'}
              text={cancelButtonProps?.text || translate('button.close')}
              onClick={onClose}
              textClasses='min-w-min'
            />
            <Button
              type={isSaveButtonDisabled ? 'button' : 'submit'}
              data-testid={TEST_IDS.generic.buttons.submit}
              className={classNames('rounded-12 flex-grow text-red-pure w-3/4', saveButtonProps?.className)}
              color={saveButtonProps?.color || 'primary'}
              disabled={isSaveButtonDisabled}
              text={saveButtonProps?.text || translate('button.saveChanges')}
            />
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};
