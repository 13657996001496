import { IconProps } from './index';

export const ErrorIcon = ({ className = '', color = '#7A05F0' }: IconProps) => {
  return (
    <svg
      className={className}
      width='72'
      height='72'
      viewBox='0 0 72 72'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M36 24V42' stroke={color} strokeWidth='6' />
      <path d='M36 48V54' stroke={color} strokeWidth='6' />
      <path
        d='M57.1076 63H14.8924C7.94632 63 3.61848 55.4651 7.11843 49.4652L28.226 13.2808C31.6989 7.32722 40.3011 7.32722 43.774 13.2808L64.8816 49.4652C68.3815 55.4651 64.0537 63 57.1076 63Z'
        stroke={color}
        strokeWidth='6'
      />
    </svg>
  );
};
