import { IconProps } from '.';

export const DragIcon = ({ color = 'black', className }: IconProps) => {
  return (
    <svg
      className={className}
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.0304 3.53033L9.50006 0L5.96973 3.53033L7.03039 4.59099L8.75006 2.87132V6H10.2501V2.87132L11.9697 4.59099L13.0304 3.53033ZM11 9C11 9.82843 10.3284 10.5 9.5 10.5C8.67157 10.5 8 9.82843 8 9C8 8.17157 8.67157 7.5 9.5 7.5C10.3284 7.5 11 8.17157 11 9ZM9.50006 18L5.96974 14.4697L7.0304 13.409L8.75007 15.1287V12H10.2501V15.1287L11.9697 13.409L13.0304 14.4697L9.50006 18ZM4.03033 5.46967L0.5 9L4.03033 12.5303L5.09099 11.4697L3.37132 9.75H6.5V8.25H3.37132L5.09099 6.53033L4.03033 5.46967ZM18.5 8.99999L14.9697 12.5303L13.909 11.4697L15.6287 9.74999H12.5V8.24999H15.6287L13.909 6.53032L14.9697 5.46966L18.5 8.99999Z'
        fill={color}
      />
    </svg>
  );
};
