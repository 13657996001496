export const StarCircleIcon = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM10.5889 6.21937L9.67255 4.36274C9.39744 3.80531 8.60256 3.80531 8.32745 4.36274L7.41115 6.21937C7.3019 6.44072 7.09073 6.59415 6.84644 6.62965L4.79753 6.92737C4.18237 7.01676 3.93674 7.77274 4.38187 8.20664L5.86448 9.65183C6.04124 9.82413 6.1219 10.0724 6.08018 10.3157L5.73018 12.3563C5.6251 12.969 6.26817 13.4362 6.81839 13.1469L8.65099 12.1835C8.86949 12.0686 9.13051 12.0686 9.34901 12.1835L11.1816 13.1469C11.7318 13.4362 12.3749 12.969 12.2698 12.3563L11.9198 10.3157C11.8781 10.0724 11.9588 9.82413 12.1355 9.65183L13.6181 8.20664C14.0633 7.77274 13.8176 7.01676 13.2025 6.92737L11.1536 6.62965C10.9093 6.59415 10.6981 6.44072 10.5889 6.21937Z'
        fill='#FFDF00'
      />
    </svg>
  );
};
