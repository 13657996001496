import React from 'react';

export const PauseIcon: React.FC<{ color?: string; className?: string }> = ({ color = '#999999', className }) => {
  return (
    <svg
      className={className}
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.5 33.75C27.1985 33.75 34.25 26.6985 34.25 18C34.25 9.30152 27.1985 2.25 18.5 2.25C9.80152 2.25 2.75 9.30152 2.75 18C2.75 26.6985 9.80152 33.75 18.5 33.75ZM18.5 36C28.4411 36 36.5 27.9411 36.5 18C36.5 8.05887 28.4411 0 18.5 0C8.55887 0 0.5 8.05887 0.5 18C0.5 27.9411 8.55887 36 18.5 36ZM12.5 12.75C12.5 11.5074 13.5074 10.5 14.75 10.5C15.9926 10.5 17 11.5074 17 12.75V23.25C17 24.4926 15.9926 25.5 14.75 25.5C13.5074 25.5 12.5 24.4926 12.5 23.25V12.75ZM22.25 10.5C21.0074 10.5 20 11.5074 20 12.75V23.25C20 24.4926 21.0074 25.5 22.25 25.5C23.4926 25.5 24.5 24.4926 24.5 23.25V12.75C24.5 11.5074 23.4926 10.5 22.25 10.5Z'
        fill={color ?? 'var(--color-primary)'}
      />
    </svg>
  );
};
