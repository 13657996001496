import { Map } from 'immutable';

export function applyReducers(state: any, action: string, reducers: any) {
  const reducerKeys = Object.keys(reducers);

  return state.withMutations((temporaryState: any) => {
    reducerKeys.forEach((domainName) => {
      const reducer = reducers[domainName];
      const currentDomainState = temporaryState.get(domainName);
      const newDomainState = reducer(currentDomainState, action);

      validateState(newDomainState, domainName, action);
      temporaryState.set(domainName, newDomainState);
    });
  });
}

export const validateState = (nextState: any, reducerName: string, action: any) => {
  if (nextState === undefined) {
    throw new Error(
      'Reducer "' +
        reducerName +
        '" returned undefined when handling "' +
        action.type +
        '" action. To ignore an action, you must explicitly return the previous state.',
    );
  }
};

export function combineReducers(reducers: any) {
  return (state = Map(), action: any) => {
    return applyReducers(state, action, reducers);
  };
}
