import { makeAction } from '@va/store';
import * as Types from './types';
export * from './types';

export const startCheckPasswordFlow = makeAction(Types.START_CHECK_PASSWORD_FLOW, 'nextModal', 'theme');
export const endCheckPasswordFlow = makeAction(Types.END_CHECK_PASSWORD_FLOW, 'succeeded');
export const startChangeEmailFlow = makeAction(Types.START_CHANGE_EMAIL_FLOW);
export const startDeleteAccountFlow = makeAction(Types.START_DELETE_ACCOUNT_FLOW);
export const startLeaveWebsiteFlow = makeAction(Types.START_LEAVE_WEBSITE_FLOW, 'websiteId');
export const startConfirmContributorFlow = makeAction(Types.START_CONFIRM_CONTRIBUTOR_FLOW);
export const startCancelSubscriptionFlow = makeAction(Types.START_CANCEL_SUBSCRIPTION_FLOW);
export const startDeleteSubscriptionFlow = makeAction(Types.START_DELETE_SUBSCRIPTION_FLOW);

export const initialize = makeAction(Types.INITIALIZE);
export const initializeSucceeded = makeAction(Types.INITIALIZE_SUCCEEDED);

export const resetStore = makeAction(Types.RESET_STORE);
export const setActiveWebsite = makeAction(Types.SET_ACTIVE_WEBSITE, 'websiteId', 'handleRoute', 'fromManageWebsites');
export const setActiveWebsiteSucceeded = makeAction(Types.SET_ACTIVE_WEBSITE_SUCCEEDED, 'data');
export const setBrainTreeInstanceLoaded = makeAction(Types.SET_BRAINTREE_INSTANCE_LOADED, 'value');

export const setAppsumoStep = makeAction(Types.SET_APPSUMO_STEP, 'step');
export const setAppsumoUserId = makeAction(Types.SET_APPSUMO_USER_ID, 'userId');
export const updateVouchersList = makeAction(Types.UPDATE_VOUCHERS_LIST, 'voucher');
export const setVouchersList = makeAction(Types.SET_VOUCHERS_LIST, 'vouchers');
export const authExternal = makeAction(Types.AUTH_EXTERNAL, 'token');

export const refreshSubscriptionDetails = makeAction(Types.REFRESH_SUBSCRIPTION_DETAILS);

export const setPaddleCallbackEvent = makeAction(Types.SET_PADDLE_CALLBACK_EVENT, 'data');

export const setOnboardingStep = makeAction(Types.SET_ONBOARDING_STEP, 'data');
export const setWebsiteCreationStep = makeAction(Types.SET_WEBSITE_CREATION_STEP, 'data');
export const setChatwootAttributesAction = makeAction(Types.SET_CHATWOOT_ATTRIBUTES);

export const setUpgradePlanStep = makeAction(Types.SET_UPGRADE_PLAN_STEP, 'data');
export const setUpgradePlanIndex = makeAction(Types.SET_UPGRADE_PLAN_INDEX, 'data');
export const resetUpgradePlanIndex = makeAction(Types.RESET_UPGRADE_PLAN_INDEX);
