import { subscriptionType } from '@va/standalone/shared/constants';
import Immutable from 'immutable';
import { getPaymentPlans } from './app';

const basePath = ['ui'];

export const isModalOpen = (state, modalName) => state.getIn([...basePath, 'modals', modalName, 'open'], false);

export const getSelectedWebsiteInfo = (state) =>
  state.getIn([...basePath, 'manageWebsites', 'selected'], Immutable.Map());

export const getRememberedEmailForLogin = (state) => state.getIn([...basePath, 'rememberLogin', 'email'], null);

export const getSubscriptionTypeUi = (state) =>
  state.getIn([...basePath, 'subscription', 'type'], subscriptionType.YEARLY);
export const getSubscriptionPlan = (state) => state.getIn([...basePath, 'subscription', 'packageNo'], 1);
export const getSliderVal = (state) => state.getIn([...basePath, 'subscription', 'sliderVal'], 1);
export const getInitialUpgradePlanSliderValue = (state) =>
  state.getIn([...basePath, 'initialUpgradePlanSliderValue'], undefined);

export const getSelectedPlanId = (state) => {
  const paymentPlans = getPaymentPlans(state);
  const selectedSubscriptionPlan = getSubscriptionPlan(state);
  const selectedSubscriptionType = getSubscriptionTypeUi(state);
  return paymentPlans && paymentPlans[selectedSubscriptionType][selectedSubscriptionPlan]?.planId;
};

export const getSubscriptionPlanId = (state) => {
  const selectedSubscriptionType = getSubscriptionTypeUi(state);
  const subscriptionPlanIndex = getSubscriptionPlan(state);
  const paymentPlans = getPaymentPlans(state);

  return paymentPlans[selectedSubscriptionType][subscriptionPlanIndex].planId;
};
