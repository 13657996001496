import { get, patch, post, put, remove } from '@va/http-client';
import { storageItems } from '@va/standalone/shared/constants';
import { LocalStorage } from '@va/util/helpers';
import { useAsyncFunction } from '@va/util/hooks';
import { useCallback } from 'react';

export const updateAccountSettings = (data) => {
  const id = LocalStorage.getItem(storageItems.userId);
  const url = `/users/${id}`;

  return patch(url, {}, data);
};

export const confirmUpdateEmail = (data) => {
  const url = `/users/confirm-update-email`;

  return post(url, {}, data);
};

export const resetEmail = (data) => {
  const url = `/users/reset-email`;

  return post(url, {}, data);
};

export const getWebsiteOverview = (data) => {
  const url = `/websites/${data.id}/overview/visitors-summary`;
  return get(url, data.filter ? data.filter : {});
};

export const deleteAccount = (data) => {
  const id = LocalStorage.getItem(storageItems.userId);
  const url = `/users/${id}`;

  return remove(url, data);
};

export const confirmDeleteAccount = (data) => {
  const url = `/users/delete/confirm`;

  return post(url, {}, data);
};

export const removeWebsite = (data, id) => {
  const url = `/websites/${id}/remove`;

  return post(url, {}, data);
};

export const getWebsites = (data, id) => {
  const url = `/users/${id}/websites`;

  return get(url, data);
};

export const getWebsite = (data, id) => {
  const url = `/websites/${id}`;
  return get(url, data);
};

export const updateWebsite = (data, id) => {
  const url = `/websites/${id}`;

  return put(url, {}, data);
};

export const getTrackingCode = (id) => {
  const url = `/v2/websites/${id}/tracking-codes`;

  return get(url, {});
};

export const deleteWebsiteContributorAccepted = (contributorId, websiteId) => {
  const url = `/contributors/${contributorId}/websites/${websiteId}`;

  return remove(url, {});
};

export const acceptContributorRole = (data, id) => {
  const url = `/contributors/requests/${id}/accept`;

  return post(url, {}, data);
};

export const rejectContributorRole = (data, id) => {
  const url = `/contributors/requests/${id}/reject`;

  return post(url, {}, data);
};

export const acceptContributorRoleWithToken = (data) => {
  const url = `/contributors/requests/accept`;

  return post(url, {}, data);
};

export const rejectContributorRoleWithToken = (data) => {
  const url = `/contributors/requests/reject`;

  return post(url, {}, data);
};

export const resendRegisterConfirmation = (data) => {
  const url = `/users/confirm-resend`;

  return post(url, {}, data);
};

export const findMigrationsUser = (data) => {
  const url = `/migrations/users`;

  return get(url, data);
};

export const createWebsiteForMigration = (data, userId) => {
  const url = `/migrations/users/${userId}/websites`;

  return post(url, {}, data);
};

export const createNewUserForMigration = (data) => {
  const url = `/migrations/users`;

  return post(url, {}, data);
};

export const createNewSubscriber = (data) => {
  const url = `/subscriptions/official-launch`;

  return post(url, {}, data, {});
};

export const getVouchers = (userId) => {
  const id = userId || LocalStorage.getItem(storageItems.userId);
  const url = `/sumo/users/${id}/vouchers`;
  return get(url, {});
};

export const getVouchersInfo = (userId) => {
  const id = userId || LocalStorage.getItem(storageItems.userId);
  const url = `/sumo/users/${id}/vouchers/info`;
  return get(url, {});
};

export const applyLifetimeDeal = (id) => {
  const url = `/sumo/websites/${id}`;

  return post(url, {}, id);
};

export const useUpdateAccountSettings = () => {
  const asyncFunc = useCallback((data) => {
    const id = LocalStorage.getItem(storageItems.userId);
    return put(`/users/${id}`, {}, data);
  }, []);
  return useAsyncFunction(asyncFunc);
};
