import { IconProps } from '.';

export const SortAndFilterIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='12'
      height='16'
      viewBox='0 0 12 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M3 5L6 2L9 5' stroke={color} strokeWidth='1.5' />
      <path d='M9 11L6 14L3 11' stroke={color} strokeWidth='1.5' />
      <circle cx='1.5' cy='8' r='1.5' fill={color} fillOpacity='0.25' />
      <circle cx='6' cy='8' r='1.5' fill={color} fillOpacity='0.25' />
      <circle cx='10.5' cy='8' r='1.5' fill={color} fillOpacity='0.25' />
    </svg>
  );
};
