import { createContext } from 'react';

export const FormContext = createContext(true);

export const FormContextProvider = (props) => {
  const { config } = props;

  const contextValue = {
    ...config,
  };
  return <FormContext.Provider value={contextValue}>{props.children}</FormContext.Provider>;
};
