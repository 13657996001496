import { Platform } from '@va/constants';
import { getWebsite } from '@va/dashboard/selectors/core';
import { getChatwootToken } from '@va/dashboard/selectors/customization';
import { isMobileDevice } from '@va/dashboard/selectors/ui';
import { InternalChatwootEvents } from '@va/dashboard/util-helpers/chatwoot';
import { ChatIcon, LoaderIcon } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { UserRole } from '@va/types/website';
import { Button, ButtonShapes } from '@va/ui/design-system';
import { getFullName, isWixApp } from '@va/util/helpers';
import { EventBus } from '@va/util/misc';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './chatwoot_widget.css';

const ChatwootSDKEvents = {
  CHATWOOT_READY: 'chatwoot:ready',
  CHATWOOT_ERROR: 'chatwoot:error',
};

enum ChatwootState {
  INITIAL,
  LOADING,
  LOADED,
  FAILED,
}

export const ChatwootWidget: React.FC = () => {
  const [chatwootState, setChatwootState] = useState<ChatwootState>(ChatwootState.INITIAL);
  const [chatwootLocalAttributes, setChatwootLocalAttributes] = useState<Record<string, unknown>>();
  const websiteInfo = useSelector(getWebsite);
  const chatwootToken = useSelector(getChatwootToken);
  const translate = useTranslate();
  const isMobile = useSelector(isMobileDevice);

  const isInvoicePage = window.location.pathname.includes('/invoice/');

  const { locale } = useLocale();
  useEffect(() => {
    if (!chatwootToken) return;

    window.chatwootSettings = {
      hideMessageBubble: true,
      position: 'right',
      locale: locale,
      type: 'standard',
    };
  }, []);

  useEffect(() => {
    window.$chatwoot?.setLocale(locale);
  }, [locale]);

  const onLoad = useCallback(() => {
    window.$chatwoot?.toggle('open');

    chatwootLocalAttributes && setChatwootAttributes(chatwootLocalAttributes);

    setChatwootState(ChatwootState.LOADED);
    setTimeout(() => {
      setChatwootState(ChatwootState.INITIAL);
    }, 500);
  }, [chatwootLocalAttributes]);

  const onError = useCallback((err: unknown) => {
    setChatwootState(ChatwootState.FAILED);
    if (process.env.NODE_ENV === 'development') {
      console.log('Chatwoot error', err);
    }
  }, []);

  // FOR WIX
  useEffect(() => {
    if (!isWixApp() || !window.$chatwoot || !websiteInfo) return;

    const { url, lastName, firstName, email, installDate } = websiteInfo;

    const attributes = {
      website_url: url,
      email: email,
      name: getFullName({ firstName, lastName }),
      website_platform: Platform.WIX,
      website_role: UserRole.OWNER,
      website_created_at: moment(installDate * 1000).format('MMM Do YYYY, h:mm:ss'),
    };

    setChatwootAttributes(attributes);
  }, [websiteInfo]);

  const setAttributes = (attributes: Record<string, unknown>) => {
    setChatwootLocalAttributes(attributes);
  };

  const initializeWidget = useCallback(() => {
    if (window.$chatwoot?.hasLoaded) {
      window.$chatwoot?.toggle('open');
      return;
    }

    setChatwootState(ChatwootState.LOADING);

    (function (d, t) {
      const BASE_URL = 'https://chat.hostedby.net';
      const scriptSrc = BASE_URL + '/packs/js/sdk.js';

      const isAlreadyCreated = !!document.querySelector(`[src='${scriptSrc}']`);

      if (isAlreadyCreated) return;
      const g = d.createElement(t) as any;
      const s = d.getElementsByTagName(t)[0] as any;
      g.src = scriptSrc;
      s.parentNode.insertBefore(g, s);
      g.async = !0;
      g.onload = function () {
        window.chatwootSDK!.run({
          websiteToken: chatwootToken,
          baseUrl: BASE_URL,
        });
      };
    })(document, 'script');
  }, [chatwootToken]);

  useEffect(() => {
    window.addEventListener(ChatwootSDKEvents.CHATWOOT_READY, onLoad);
    window.addEventListener(ChatwootSDKEvents.CHATWOOT_ERROR, onError);
    const onOpenListener = EventBus.addListener(InternalChatwootEvents.OPEN_CHATWOOT, initializeWidget);
    const onSetAttributesListener = EventBus.addListener(InternalChatwootEvents.SET_ATTRIBUTES, setAttributes);

    return () => {
      window.removeEventListener(ChatwootSDKEvents.CHATWOOT_READY, onLoad);
      window.removeEventListener(ChatwootSDKEvents.CHATWOOT_ERROR, onError);
      onOpenListener.removeListener();
      onSetAttributesListener.removeListener();
    };
  }, [initializeWidget, onError, onLoad]);

  if (!chatwootToken || isMobile || isInvoicePage) {
    return null;
  }
  return (
    // TODO Update zIndex with value from the constants
    <div className='fixed bottom-[10px] right-[10px]' style={{ zIndex: 9999999 }}>
      <Button
        tooltip={translate('all.chatwoot.chatWithUs')}
        shape={ButtonShapes.circle}
        onClick={() => {
          initializeWidget();
        }}
        icon={(className, color) => {
          if (chatwootState === ChatwootState.LOADING) {
            return <LoaderIcon className={className} color={color} />;
          }
          return <ChatIcon className={className} color={color} />;
        }}
      />
    </div>
  );
};

export function setChatwootAttributes(attributes: Record<string, unknown>) {
  if (!window.$chatwoot) {
    // Save the attributes locally until the chatwoot is enabled
    EventBus.dispatch(InternalChatwootEvents.SET_ATTRIBUTES, attributes);
    return;
  }

  try {
    window.$chatwoot.setCustomAttributes(attributes);
  } catch (error) {
    console.log(error);
  }
}
