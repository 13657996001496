import { tabNames } from '@va/constants';
import {
  BackIconRounded,
  ChatIcon,
  ExternalLinkIcon,
  GlobeIcon,
  NavBehaviour,
  NavDashboard,
  NavFeedback,
  NavStatistic,
  NavWebStatsOverview,
  PlaceholderLogoIcon,
  SearchIcon,
  TripleDotIcon,
} from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import {
  Heading3,
  IconWithBackground,
  ListCardItemWithIcon,
  Paragraph,
  ParagraphWithTooltip,
  ProgressBar,
  fontWeights,
} from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { DropdownArrow } from '@va/util/components';
import { addNumberSeparator } from '@va/util/helpers';
import { useCheckOverflow } from '@va/util/hooks';
import { ReactElement, useCallback, useRef } from 'react';
import { ThemeColorsType } from './types';

const navItems: NavItemType[] = [
  {
    name: tabNames.webStatsOverview,
    icon: <NavWebStatsOverview />,
  },
  {
    name: tabNames.behaviour,
    icon: <NavBehaviour />,
  },
  {
    name: tabNames.feedback,
    icon: <NavFeedback />,
  },
  {
    name: 'chatAutomation',
    icon: <ChatIcon />,
  },
];

export const ThemePreview = ({ colors, userMenuText }: { colors: ThemeColorsType; userMenuText: string }) => {
  const translate = useTranslate();
  const { locale } = useLocale();
  const { primary, secondary, tertiary, negative, positive, neutral } = colors;

  const tabTitleRef = useRef<HTMLDivElement>(null);
  const isTabTitleOverflowing = useCheckOverflow(tabTitleRef);

  const getPercentage = useCallback(
    (percentage: number) => {
      return addNumberSeparator(percentage, locale, {
        style: 'percent',
        signDisplay: 'exceptZero',
      });
    },
    [locale],
  );

  return (
    <div className='bg-white border-[1.5px] rounded-18 border-gray-mercury overflow-hidden'>
      <div className='p-1 px-3 flex gap-2 items-center' style={{ backgroundColor: tertiary }}>
        <div className='flex gap-2 items-center bg-white rounded-12 h-12 py-1 px-3 w-full max-w-110 sm-initial:max-w-[204px]'>
          <div className='hidden sm-initial:block'>
            <GlobeIcon color={primary} />
          </div>
          <ParagraphWithTooltip weight={fontWeights.medium}>
            {translate('whiteLabel.settings.previewTheme')}
          </ParagraphWithTooltip>
          <DropdownArrow className='hidden sm-initial:block' open={false} />
        </div>
        <div className='aspect-square w-12 shrink-0 flex items-center justify-center bg-white rounded-12'>
          <ExternalLinkIcon color={primary} />
        </div>

        <div className='hidden sm-initial:flex items-center gap-2 ml-10'>
          <PlaceholderLogoIcon color={primary} />
          <Paragraph weight={fontWeights.semibold}>{translate('labels.logo')}</Paragraph>
        </div>
        <div className='bg-white shrink-0 rounded-15 p-12px flex gap-2 items-center ml-auto'>
          <div
            className='rounded-full w-10 h-10 p-2 flex items-center justify-center font-semibold text-lg uppercase text-white'
            style={{ backgroundColor: primary }}
          >
            {userMenuText}
          </div>
          <DropdownArrow open={false} />
        </div>
      </div>
      <div className='flex sm-initial:grid sm-initial:grid-cols-5 overflow-hidden'>
        <div
          className='sm-initial:col-span-2 border-r-[1.5px] p-3 border-gray-mercury space-y-2'
          style={{ backgroundColor: secondary }}
        >
          <div className='flex gap-2'>
            <div className='hidden sm-initial:flex bg-white items-center h-12 gap-2 rounded-12 w-full max-w-[204px] px-3'>
              <SearchIcon color='#3C3C3C' />
              <ParagraphWithTooltip className='grow' weight={fontWeights.medium}>
                {translate('labels.search')}
              </ParagraphWithTooltip>
            </div>
            <div className='flex items-center shrink-0 justify-center w-12 h-12 bg-white rounded-12 mx-auto'>
              <BackIconRounded className='rotate-180 sm-initial:rotate-0' color='#3C3C3C' />
            </div>
          </div>
          <div className='h-60 rounded-12 flex items-center px-2 gap-3' style={{ backgroundColor: tertiary }}>
            <IconWithBackground
              className='shrink-0'
              style={{ backgroundColor: primary }}
              icon={() => <NavDashboard color='white' />}
            />
            <ParagraphWithTooltip className='hidden sm-initial:block' weight={fontWeights.medium}>
              {translate(`tab.${tabNames.overview}`)}
            </ParagraphWithTooltip>
          </div>
          {navItems.map(({ name, icon }, index) => (
            <NavItem key={index} name={name} icon={icon} />
          ))}
        </div>

        <div className='sm-initial:col-span-3 relative'>
          <div className='p-4'>
            <div className='flex items-center gap-4 '>
              <TooltipWrapper disabled={!isTabTitleOverflowing} content={translate(`tab.${tabNames.overview}`)}>
                <Heading3
                  ref={tabTitleRef}
                  className='underline-dashed inline-block truncate'
                  weight={fontWeights.semibold}
                >
                  {translate(`tab.${tabNames.overview}`)}
                </Heading3>
              </TooltipWrapper>
              <TripleDotIcon />
              <div
                className='sm-initial:ml-auto h-12 w-12 rounded-12 shrink-0 flex items-center justify-center'
                style={{ backgroundColor: primary }}
              >
                <SearchIcon color='white' />
              </div>
            </div>
          </div>
          <div className='max-w-150 ml-5 translate-y-[2px] flex items-center justify-center p-3 gap-3 border-b-2 border-gray-charcoal'>
            <NavStatistic />
            <Paragraph weight={fontWeights.medium}>{translate('tab.overview')}</Paragraph>
          </div>
          <div className='border-b border-gray-mercury h-1.5px '></div>
          <div className='pl-18 pt-18 max-h-[283px]'>
            <RowItem
              count={'01'}
              topText={translate('card.linechart.label.visits')}
              bottomNode={
                <Paragraph style={{ color: positive }} weight={fontWeights.medium}>
                  {getPercentage(12.5)}
                </Paragraph>
              }
              barInfo={[{ color: '#2478F0', percentage: 70 }]}
            />
            <RowItem
              count={'02'}
              topText={translate('card.linechart.label.sessions')}
              bottomNode={
                <Paragraph style={{ color: neutral }} weight={fontWeights.medium}>
                  {getPercentage(0)}
                </Paragraph>
              }
              barInfo={[
                { color: '#2478F0', percentage: 50 },
                { color: '#428AF3', percentage: 50 },
              ]}
            />
            <RowItem
              count={'03'}
              topText={translate('card.linechart.label.newVisitors')}
              bottomNode={
                <Paragraph style={{ color: negative }} weight={fontWeights.medium}>
                  {getPercentage(-5.4)}
                </Paragraph>
              }
              barInfo={[{ color: '#A50FEC', percentage: 70 }]}
            />
            <RowItem
              count={'04'}
              topText={translate('card.linechart.label.returningVisitors')}
              bottomNode={
                <Paragraph style={{ color: positive }} weight={fontWeights.medium}>
                  {getPercentage(540)}
                </Paragraph>
              }
              barInfo={[{ color: '#ED3FA5', percentage: 70 }]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

type NavItemType = {
  icon: React.ReactNode;
  name: string;
};

const NavItem: React.FC<NavItemType> = ({ icon, name }) => {
  const translate = useTranslate();

  return (
    <div className='h-60 rounded-12 flex items-center px-2 gap-3'>
      <IconWithBackground className='bg-white shrink-0' icon={() => icon} />
      <Paragraph className='hidden sm-initial:block truncate' weight={fontWeights.medium}>
        {translate(`tab.${name}`)}
      </Paragraph>
      <DropdownArrow className='hidden sm-initial:block ml-auto' open={false} />
    </div>
  );
};

type ChartRowTypes = {
  count: string;
  topText: string;
  bottomNode: ReactElement;
  barInfo: {
    color: string;
    percentage: number;
  }[];
};

const RowItem = ({ count, topText, bottomNode, barInfo }: ChartRowTypes) => {
  return (
    <div className='flex gap-3 py-3 pl-3 w-full'>
      <ListCardItemWithIcon
        wrapperClassName='!gap-18px w-3/5'
        icon={
          <IconWithBackground
            className='shrink-0 bg-white-snow'
            icon={() => (
              <ParagraphWithTooltip style={{ color: barInfo[0].color }} weight={fontWeights.semibold}>
                {count}
              </ParagraphWithTooltip>
            )}
          />
        }
        topText={<ParagraphWithTooltip weight={fontWeights.semibold}>{topText}</ParagraphWithTooltip>}
        bottomNode={bottomNode}
      />
      <ProgressBar progress={barInfo} className={'w-2/5'} />
    </div>
  );
};
