import { IconProps } from '.';

export const BulbIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='24'
      viewBox='0 0 18 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.25 8.25C2.25 4.52208 5.27208 1.5 9 1.5C12.7279 1.5 15.75 4.52208 15.75 8.25C15.75 10.3496 14.7923 12.2248 13.2871 13.464C12.7015 13.9461 12.25 14.6794 12.25 15.5386C12.25 16.0696 11.8196 16.5 11.2886 16.5H6.71139C6.18043 16.5 5.75 16.0696 5.75 15.5386C5.75 14.6794 5.29849 13.9461 4.71291 13.464C3.20771 12.2248 2.25 10.3496 2.25 8.25ZM4.88251 17.186C4.48933 16.7497 4.25 16.1721 4.25 15.5386C4.25 15.2235 4.08053 14.8864 3.75952 14.6221C1.92307 13.1102 0.75 10.8168 0.75 8.25C0.75 3.69365 4.44365 0 9 0C13.5563 0 17.25 3.69365 17.25 8.25C17.25 10.8168 16.0769 13.1102 14.2405 14.6221C13.9195 14.8864 13.75 15.2235 13.75 15.5386C13.75 16.1721 13.5107 16.7497 13.1175 17.186C13.5091 17.5908 13.75 18.1423 13.75 18.75C13.75 19.9081 12.875 20.8619 11.75 20.9863V21.25C11.75 22.7688 10.5188 24 9 24C7.48122 24 6.25 22.7688 6.25 21.25V20.9863C5.12501 20.8619 4.25 19.9081 4.25 18.75C4.25 18.1423 4.49094 17.5908 4.88251 17.186ZM6.71139 18H6.5C6.08579 18 5.75 18.3358 5.75 18.75C5.75 19.1642 6.08579 19.5 6.5 19.5H7H11H11.5C11.9142 19.5 12.25 19.1642 12.25 18.75C12.25 18.3358 11.9142 18 11.5 18H11.2886H6.71139ZM9 4.5C6.92893 4.5 5.25 6.17893 5.25 8.25H3.75C3.75 5.3505 6.1005 3 9 3V4.5ZM7.75 21V21.25C7.75 21.9404 8.30964 22.5 9 22.5C9.69036 22.5 10.25 21.9404 10.25 21.25V21H7.75Z'
        fill={color}
      />
    </svg>
  );
};
