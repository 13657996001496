import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';

type ConfirmationDialogContextType = {
  confirm: (onConfirmation: () => void, ConfirmationModal: ConfirmationDialogType) => void;
};

const ConfirmationDialogContext = createContext<ConfirmationDialogContextType>({} as ConfirmationDialogContextType);

export type ConfirmationDialogProps = { onConfirm: () => void; onClose: () => void };
export type ConfirmationDialogType = React.FC<ConfirmationDialogProps>;

export const ConfirmationDialogContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [onConfirmation, setOnConfirmation] = useState<(() => void) | undefined>(undefined);
  const [ConfirmationModal, setConfirmationModal] = useState<ConfirmationDialogType | undefined>(undefined);

  const confirm = useCallback((onConfirmation: () => void, ConfirmationModal: ConfirmationDialogType) => {
    setConfirmationModal(() => ConfirmationModal);
    setOnConfirmation(() => () => onConfirmation());
  }, []);

  const onClose = useCallback(() => {
    setConfirmationModal(undefined);
    setOnConfirmation(undefined);
  }, []);

  return (
    <ConfirmationDialogContext.Provider value={{ confirm }}>
      {ConfirmationModal && onConfirmation && (
        <ConfirmationModal
          onConfirm={() => {
            onConfirmation();
            onClose();
          }}
          onClose={onClose}
        />
      )}
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

export const useConfirmationDialogContext = () => useContext(ConfirmationDialogContext);
