import { TEST_IDS } from '@va/constants';
import { EraseIcon, EyeIcon, EyeIconSlashed, FilledCheckIcon, FilledInformationIcon, InfoIcon } from '@va/icons';
import { DISABLED_CLASSES, fontWeights, Paragraph, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { useInnerOnChange, useOutsideClick } from '@va/util/hooks';
import classNames from 'classnames';
import { ChangeEvent, FocusEventHandler, ReactNode, useMemo, useRef, useState } from 'react';
import './text-input.scss';

type InputColorsType = 'secondary' | 'tertiary';

export type TextInputProps = {
  debouncedInput?: boolean;
  label?: string;
  info?: string;
  infoTooltipText?: string;
  tooltip?: string;
  error?: string;
  icon?: ReactNode;
  color?: InputColorsType;
  placeholder?: string;
  name?: string;
  id?: string;
  value?: string | number;
  disabled?: boolean;
  clearField: () => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  wrapperClassName?: string;
  autoComplete?: string;
  required?: boolean;
  onBlur?: FocusEventHandler;
  type?: string;
  heightClass?: string;
  clearIcon?: ReactNode;
  showErrorMessage?: boolean;
  showTogglePasswordVisibilityButton?: boolean;
  bgColor?: string;
  bgColorHover?: string;
  bgColorFocus?: string;
  onFocusCapture?: () => void;
  dataTestId?: string;
  showIcons?: boolean;
  inputWrapperClassName?: string;
  autoFocus?: boolean;
  size?: 'small' | 'medium';
  optionalLabel?: string;
  labelColor?: string;
};

export function TextInput({
  wrapperClassName,
  placeholder,
  icon,
  label,
  name,
  id,
  value,
  autoComplete,
  required = false,
  disabled = false,
  info,
  infoTooltipText,
  error,
  onBlur,
  onChange,
  clearField,
  tooltip,
  type = 'text',
  heightClass,
  clearIcon,
  showErrorMessage = true,
  showTogglePasswordVisibilityButton = false,
  bgColor = '#f9f9f9',
  bgColorHover = '#f0f0f0',
  bgColorFocus = 'white',
  debouncedInput = true,
  onFocusCapture,
  dataTestId,
  showIcons = true,
  inputWrapperClassName,
  autoFocus,
  size = 'medium',
  optionalLabel,
  labelColor = 'text-gray-devil',
}: TextInputProps) {
  const [focused, setFocused] = useState(false);
  const inputContainerRef = useRef(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useOutsideClick(inputContainerRef, () => {
    setFocused(false);
  });

  const inputType = useMemo(() => {
    if (type !== 'password' || !value || !showTogglePasswordVisibilityButton) return type;
    if (isPasswordVisible) {
      return 'text';
    } else {
      return 'password';
    }
  }, [isPasswordVisible, showTogglePasswordVisibilityButton, type, value]);

  const { innerOnChange, innerValue } = useInnerOnChange(onChange, 500, value);

  return (
    <div className={classNames(wrapperClassName)}>
      <TooltipWrapper content={tooltip} disabled={!tooltip}>
        <div
          onClick={() => {
            if (disabled) return;
            setFocused(true);
          }}
          ref={inputContainerRef}
          className={classNames(
            'text-input-container flex items-center justify-between relative rounded-15 border border-transparent overflow-hidden',
            disabled && `${DISABLED_CLASSES} disabled`,
            heightClass,
            inputWrapperClassName,
            {
              'px-18px': size === 'medium',
              'px-3': size === 'small',
              'h-[30px]': size === 'small' && !heightClass,
              'h-[60px]': size === 'medium' && !heightClass,
            },
          )}
          style={{
            ['--text-input-bgColor' as string]: bgColor,
            ['--text-input-bgColorHover' as string]: bgColorHover,
            ['--text-input-bgColorFocus' as string]: bgColorFocus,
          }}
        >
          <div className='grow flex items-center gap-15px h-full'>
            {icon && <div className='shrink-0'>{icon}</div>}
            <div className='form-floating grow'>
              <input
                data-testid={dataTestId}
                tabIndex={0}
                type={inputType}
                className={classNames(
                  '!appearance-none form-control w-full bg-transparent font-medium outline-none leading-6 text-gray-charcoal',
                  size,
                  {
                    '!transform-none no-label': !label,
                    'cursor-not-allowed': disabled,
                    'with-label': label,
                    'text-15': size === 'medium',
                    'text-14': size === 'small',
                  },
                )}
                value={debouncedInput ? innerValue : value}
                id={id}
                name={name}
                onChange={debouncedInput ? innerOnChange : onChange}
                autoComplete={autoComplete}
                placeholder={placeholder || ' '} // If the placeholder is empty the input won't behave properly
                onBlur={onBlur}
                disabled={disabled}
                onFocusCapture={onFocusCapture}
                autoFocus={autoFocus}
              />
              {label && (
                <label
                  tabIndex={-1}
                  htmlFor={name}
                  className={classNames(
                    'font-primary whitespace-nowrap truncate block leading-none max-w-full font-medium pointer-events-none',
                    labelColor,
                    {
                      '!text-red-pure': error,
                      'text-15': size === 'medium',
                      'text-14': size === 'small',
                    },
                  )}
                >
                  {label}
                  {required && <span className='text-red-negative ml-1'>*</span>}
                  {optionalLabel && <span className='text-gray-devil ml-1'>{optionalLabel}</span>}
                </label>
              )}
            </div>
          </div>
          <div className='flex items-center gap-1' tabIndex={-1}>
            {showTogglePasswordVisibilityButton && type === 'password' && (
              <button
                tabIndex={-1}
                type='button'
                onClick={() => {
                  setIsPasswordVisible((prev) => !prev);
                }}
                className='aspect-square flex items-center justify-center h-full'
              >
                {isPasswordVisible ? (
                  <EyeIconSlashed color='#3C3C3C' className='w-5 h-5' />
                ) : (
                  <EyeIcon color='#3C3C3C' className='w-5 h-5' />
                )}
              </button>
            )}
            {showIcons && (
              <InputIcon
                value={value}
                error={error}
                clearField={clearField}
                disabled={disabled}
                focused={focused}
                clearIcon={clearIcon}
              />
            )}
          </div>
        </div>
      </TooltipWrapper>
      {showErrorMessage && <InputMessage error={error} />}
      {info && <InputMessage info={info} infoTooltipText={infoTooltipText} />}
    </div>
  );
}

type InputIconProps = {
  value?: string | number | null;
  error?: string;
  focused?: boolean;
  disabled: boolean;
  clearField: () => void;
  clearIcon: ReactNode;
};

const InputIcon = ({ value, error, focused, clearField, disabled, clearIcon }: InputIconProps) => {
  if (error && !focused) return <FilledInformationIcon color='#EA2A0C' />;

  if (!focused && value && !error) return <FilledCheckIcon color='#06A25A' />;

  if (focused && value)
    return (
      <button
        type='button'
        onClick={disabled ? undefined : clearField}
        className={classNames('w-10 h-10 rounded-12 flex items-center justify-center shrink-0', {
          'bg-white': !focused,
          'bg-white-snow': focused,
          'hover:bg-gray-concrete cursor-pointer': !disabled,
          'bg-gray-mercury opacity-80 cursor-not-allowed': disabled,
        })}
        data-testid={TEST_IDS.generic.buttons.erase}
      >
        {clearIcon ? clearIcon : <EraseIcon color={disabled ? '#999999' : '#3C3C3C'} />}
      </button>
    );
  return null;
};

type InputMessageProps = {
  error?: string;
  info?: string;
  infoTooltipText?: string;
  className?: string;
};

export const InputMessage = ({ error, info, infoTooltipText, className }: InputMessageProps) => {
  const paragraphClasses = useMemo(() => 'ml-2 mt-1 mb-2', []);

  if (error)
    return (
      <Paragraph
        weight={fontWeights.medium}
        size={paragraphSizes.tiny}
        className={classNames(' text-red-pure', className, paragraphClasses)}
      >
        {error}
      </Paragraph>
    );

  if (info)
    return (
      <Paragraph
        weight={fontWeights.medium}
        size={paragraphSizes.tiny}
        className={classNames(' text-gray-charcoal flex items-center gap-2', className, paragraphClasses)}
      >
        {info}
        {infoTooltipText && (
          <TooltipWrapper content={infoTooltipText}>
            <InfoIcon className='ml-1' />
          </TooltipWrapper>
        )}
      </Paragraph>
    );

  return null;
};
