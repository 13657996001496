import { TEST_IDS } from '@va/constants';
import { NegativeTagIcon } from '@va/icons';

export function CircleNegativeTagIcon({ circleColor }: { circleColor?: string }) {
  return (
    <div className='flex items-center relative'>
      <div
        data-testid={TEST_IDS.generic.icon}
        className='flex items-center justify-center shrink-0 w-12 h-12 rounded-full bg-white-snow hover:bg-white group-hover:bg-white'
      >
        <NegativeTagIcon />
        {circleColor && (
          <div className={`absolute bottom-0 left-10 w-3 h-3 rounded-full`} style={{ backgroundColor: circleColor }} />
        )}
      </div>
    </div>
  );
}
