import React from 'react';
import PropTypes from 'prop-types';

const Month = ({ updateMonth, months }) => {
  return (
    <div className='flex flex-wrap mt-1.5'>
      {months.map((month, key) => {
        return (
          <div key={month} className='w-1/2 px-0.5'>
            <span className='flex rounded-md mt-1.5'>
              <button
                type='button'
                className='btn-class relative px-3 py-2 block w-full leading-6 rounded-md bg-white text-xs 2xl:text-sm tracking-wide font-medium transition-colors uppercase border border-transparent hover:bg-white-snow focus:bg-primary-50 focus:border-primary focus:ring focus:ring-1 focus:ring-primary focus:ring-opacity-0 focus:outline-none '
                onClick={updateMonth.bind(this, key)}
              >
                {month.replace(/<[^>]+>/g, '')}
              </button>
            </span>
          </div>
        );
      })}
    </div>
  );
};
export default React.memo(Month);

Month.propTypes = {
  updateMonth: PropTypes.func,
  months: PropTypes.array,
};
