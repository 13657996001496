import classNames from 'classnames';
import { IconProps } from '.';

export const PlayTriangleIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='9'
      height='10'
      viewBox='0 0 9 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.25 8.19722V1.80278C0.25 0.604733 1.58522 -0.109854 2.58205 0.554701L7.37789 3.75192C8.26849 4.34566 8.26849 5.65434 7.37789 6.24808L2.58205 9.4453C1.58522 10.1099 0.25 9.39527 0.25 8.19722Z'
        fill={color}
      />
    </svg>
  );
};


