import React from 'react';
import PropTypes from 'prop-types';

const CurrentPlanInfo = props => {
  const { title, planName } = props;

  return (
    <div className='border border-b-2 border-t-2 border-solid border-gray-gallery'>
      <div className='flex flex-column align-center  bg-gray-alabaster my-1 py-4'>
        <span className='leading-normal font-inter font-medium text-lg text-gray-devil'>{title}</span>
        <span className='font-inter font-semibold text-21 leading-30 break-all'>{planName}</span>
      </div>
    </div>
  );
};

CurrentPlanInfo.propTypes = {
  title: PropTypes.string.isRequired,
  planName: PropTypes.string.isRequired,
};

export default CurrentPlanInfo;
