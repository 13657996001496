import classNames from 'classnames';
import { IconProps } from '.';

export const FolderIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='16'
      viewBox='0 0 18 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 7.25V12.5C16.5 13.3284 15.8284 14 15 14H3C2.17157 14 1.5 13.3284 1.5 12.5V7.25H16.5ZM18 5.75V7.25V12.5C18 14.1569 16.6569 15.5 15 15.5H3C1.34315 15.5 0 14.1569 0 12.5V7.25V5.75V3.5C0 1.84315 1.34315 0.5 3 0.5H4.87886C5.5925 0.5 6.2726 0.802892 6.75 1.33333C7.2274 1.86378 7.9075 2.16667 8.62114 2.16667H15C16.6569 2.16667 18 3.50981 18 5.16667V5.75ZM1.5 5.75V3.5C1.5 2.67157 2.17157 2 3 2H4.87886C5.16727 2 5.44213 2.12241 5.63506 2.33678C6.39692 3.18329 7.48227 3.66667 8.62114 3.66667H15C15.8284 3.66667 16.5 4.33824 16.5 5.16667V5.75H1.5Z'
        fill={color}
      />
    </svg>
  );
};


