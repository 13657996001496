import { getInstanceId } from '@va/dashboard/selectors/app';
import { isWixWebsite } from '@va/dashboard/selectors/core';
import { get, patch, post, postBase64 } from '@va/http-client';
import { WebsiteResponse } from '@va/types/website';
import { getTypo3LocaleQueryParamValue, isWixApp, toQueryString } from '@va/util/helpers';
import { useAsyncFunction, useFetchData } from '@va/util/hooks';
import Config from 'Config';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

type BlacklistedDomainsResponse = {
  payload: string[];
};

export const getWebsite = (websiteId: string) => {
  const url = isWixApp() ? `/wix/websites/${websiteId}` : `/websites/${websiteId}`;

  return get(url);
};

export const useGetWebsite = (websiteId: string) => {
  const url = isWixApp() ? `/wix/websites/${websiteId}` : `/websites/${websiteId}`;

  return useFetchData<WebsiteResponse, Error>(url);
};

export const agreement = (websiteId: string, data: Record<string, unknown>) => {
  const url = `/agreements/websites/${websiteId}/create`;

  return post(url, {}, data);
};

export const getAgreement = (websiteId: string) => {
  const url = `/agreements/websites/${websiteId}`;

  return get(url, {});
};

export const signAgreement = (websiteId: string, data: Record<string, unknown>) => {
  const url = `/agreements/websites/${websiteId}/sign`;

  return postBase64(url, {}, data);
};

export const updateSubscription = (websiteId: string, data: Record<string, unknown>) => {
  const url = `/websites/${websiteId}/notification-settings`;

  return patch(url, {}, data);
};

export const getFeaturePermissions = (websiteId: string) => {
  const url = `/websites/${websiteId}/feature-permissions`;

  return get(url, {});
};

export const getPageVisitsCount = (websiteId: string) => {
  const url = `/websites/${websiteId}/visits/count`;

  return get(url, {});
};

export const getAnnouncements = (locale: string, country: string, websiteId: string) => {
  const queryData = {
    locale: getTypo3LocaleQueryParamValue(locale),
    geolocation: country,
    websiteId: websiteId,
  };

  const url = `${Config.announcementModalApiUrl}?${toQueryString(queryData)}`;
  return get(url, undefined, undefined, true);
};

export const useAddNewWebsite = () => {
  const asyncFunc = useCallback(
    async (data: { url?: string; timezone?: string; platform?: string; source?: 'onboarding' | 'dashboard' }) => {
      if (!data.timezone) {
        data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }

      return await post<WebsiteResponse>('/websites', {}, data);
    },
    [],
  );
  return useAsyncFunction(asyncFunc);
};

export const useRemoveWebsiteApi = () => {
  const asyncFunc = useCallback(
    (websiteId: string, data: { sendDataSelected: boolean; email: string; reason?: string }) => {
      return post(`/websites/${websiteId}/remove`, {}, data);
    },
    [],
  );
  return useAsyncFunction<typeof asyncFunc>(asyncFunc);
};

export const useUpdateWebsiteSettings = () => {
  const websiteId = useSelector(getInstanceId);
  const isWix = useSelector(isWixWebsite);
  const asyncFunc = useMemo(
    () => (data: any) =>
      patch<WebsiteResponse>(isWix ? `/wix/websites/${websiteId}` : `/websites/${websiteId}`, {}, data),
    [isWix, websiteId],
  );
  return useAsyncFunction(asyncFunc);
};

export const getPrivacySettings = (websiteId: string) =>
  get(`/websites/${websiteId}/pc-api/v1/websites/${websiteId}/settings`, {});

export const useGetWhitelistedDomains = () => {
  const websiteId = useSelector(getInstanceId);
  return useFetchData<string[], Error>(`/websites/${websiteId}/whitelisted-domains`);
};

export const useGetBlacklistedDomains = () => {
  const websiteId = useSelector(getInstanceId);
  return useFetchData<BlacklistedDomainsResponse, Error>(`/v2/websites/${websiteId}/blacklisted-domains`);
};
