import { modalName } from '@va/constants';
import { closeModal } from '@va/dashboard/actions/ui';
import { isModalOpen } from '@va/dashboard/selectors/ui';
import { withGoToWebsiteSettings } from '@va/deprecated/components/withGoToWebsiteSettings';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import ModalWrapper from '../ModalWrapper';
import SsrReachedLimit from './SsrReachedLimit';

class SsrReachedLimitContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.goToWebsiteSettings = this.goToWebsiteSettings.bind(this);
  }

  goToWebsiteSettings() {
    this.props.closeModal();
    this.props.goToWebsiteSettings();
  }

  render() {
    return (
      <ModalWrapper isOpen={this.props.isModalOpen} closeModal={this.props.closeModal} showCloseButton={false}>
        <div className='ssr-reached-limit-container'>
          <SsrReachedLimit
            translate={this.props.translate}
            closeModal={this.props.closeModal}
            goToWebsiteSettings={this.goToWebsiteSettings}
          />
        </div>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isModalOpen: isModalOpen(state, modalName.ssrReachedLimit),
  };
};

const mapDispatchToProps = {
  closeModal: () => closeModal(modalName.ssrReachedLimit),
};

SsrReachedLimitContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  //withGoToWebsiteSettings
  goToWebsiteSettings: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withGoToWebsiteSettings(withTranslate(SsrReachedLimitContainer)));
