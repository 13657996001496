import { useTranslate } from '@va/localization';
import { ConfirmationDialogProps, ConfirmationModal } from '@va/shared/util-confirmation';

const CancelAgencySubscriptionModal: React.FC<ConfirmationDialogProps> = ({ onClose, onConfirm }) => {
  const translate = useTranslate();

  return (
    <ConfirmationModal
      info={translate('modal.changeAgencyAccountToNormal.accessInfo')}
      title={translate('modal.changeAgencyAccountToNormal.title')}
      onClose={onClose}
      onConfirmation={onConfirm}
      isOpen={true}
      actionButtonText={translate('button.confirm')}
    />
  );
};

export default CancelAgencySubscriptionModal;
