import React from 'react';

export const PlayIcon: React.FC<{ color?: string; className?: string }> = ({ color, className }) => {
  return (
    <svg
      className={className}
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.25 18C34.25 26.6985 27.1985 33.75 18.5 33.75C9.80152 33.75 2.75 26.6985 2.75 18C2.75 9.30152 9.80152 2.25 18.5 2.25C27.1985 2.25 34.25 9.30152 34.25 18ZM36.5 18C36.5 27.9411 28.4411 36 18.5 36C8.55887 36 0.5 27.9411 0.5 18C0.5 8.05887 8.55887 0 18.5 0C28.4411 0 36.5 8.05887 36.5 18ZM16.25 24.4952L25.25 19.299C26.25 18.7217 26.25 17.2783 25.25 16.701L16.25 11.5048C15.25 10.9275 14 11.6491 14 12.8038L14 23.1962C14 24.3509 15.25 25.0725 16.25 24.4952Z'
        fill={color ?? 'var(--color-primary)'}
      />
    </svg>
  );
};
