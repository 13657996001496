import { IconProps } from '..';

export const TabletDeviceIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 3H7.5V15H12C13.0749 15 13.7908 14.9988 14.3408 14.9539C14.8734 14.9104 15.1171 14.8334 15.2715 14.7548C15.6948 14.539 16.039 14.1948 16.2548 13.7715C16.3334 13.6171 16.4104 13.3734 16.4539 12.8408C16.4988 12.2908 16.5 11.5749 16.5 10.5V7.5C16.5 6.42515 16.4988 5.70923 16.4539 5.15916C16.4104 4.6266 16.3334 4.3829 16.2548 4.22852C16.039 3.80516 15.6948 3.46095 15.2715 3.24524C15.1171 3.16657 14.8734 3.08962 14.3408 3.04611C13.7908 3.00117 13.0749 3 12 3ZM3.65916 3.04611C4.20923 3.00117 4.92515 3 6 3L6 15C4.92515 15 4.20923 14.9988 3.65916 14.9539C3.1266 14.9104 2.8829 14.8334 2.72852 14.7548C2.30516 14.539 1.96095 14.1948 1.74524 13.7715C1.66657 13.6171 1.58962 13.3734 1.54611 12.8408C1.50117 12.2908 1.5 11.5749 1.5 10.5V7.5C1.5 6.42515 1.50117 5.70923 1.54611 5.15916C1.58962 4.6266 1.66657 4.3829 1.74524 4.22852C1.96095 3.80516 2.30516 3.46095 2.72852 3.24524C2.8829 3.16657 3.1266 3.08962 3.65916 3.04611ZM2.04754 16.0913C2.8497 16.5 3.8998 16.5 6 16.5H7.5H12C14.1002 16.5 15.1503 16.5 15.9525 16.0913C16.6581 15.7317 17.2317 15.1581 17.5913 14.4525C18 13.6503 18 12.6002 18 10.5V7.5C18 5.3998 18 4.3497 17.5913 3.54754C17.2317 2.84193 16.6581 2.26825 15.9525 1.90873C15.1503 1.5 14.1002 1.5 12 1.5H7.5H6C3.8998 1.5 2.8497 1.5 2.04754 1.90873C1.34193 2.26825 0.768251 2.84193 0.408726 3.54754C0 4.3497 0 5.3998 0 7.5V10.5C0 12.6002 0 13.6503 0.408726 14.4525C0.768251 15.1581 1.34193 15.7317 2.04754 16.0913ZM3.75 6.75C4.16421 6.75 4.5 6.41421 4.5 6C4.5 5.58579 4.16421 5.25 3.75 5.25C3.33579 5.25 3 5.58579 3 6C3 6.41421 3.33579 6.75 3.75 6.75ZM4.5 9C4.5 9.41421 4.16421 9.75 3.75 9.75C3.33579 9.75 3 9.41421 3 9C3 8.58579 3.33579 8.25 3.75 8.25C4.16421 8.25 4.5 8.58579 4.5 9ZM3.75 12.75C4.16421 12.75 4.5 12.4142 4.5 12C4.5 11.5858 4.16421 11.25 3.75 11.25C3.33579 11.25 3 11.5858 3 12C3 12.4142 3.33579 12.75 3.75 12.75Z'
        fill={color ?? 'var(--color-primary)'}
      />
    </svg>
  );
};
