import { fontWeights, Paragraph, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';

export const SubSectionHeader = ({
  tooltip,
  text,
  className,
}: {
  tooltip?: string | React.ReactNode;
  text: string;
  className?: string;
}) => {
  return (
    <TooltipWrapper trigger='click' content={tooltip} disabled={!tooltip}>
      <Paragraph
        weight={fontWeights.medium}
        className={classNames('text-gray-dark-charcoal underline-dashed inline-block mb-3 cursor-default', className)}
        size={paragraphSizes.large}
      >
        {text}
      </Paragraph>
    </TooltipWrapper>
  );
};
