import { getInstanceId } from '@va/dashboard/selectors/app';
import { getGlobalFilter } from '@va/dashboard/selectors/ui';
import { toQueryString } from '@va/util/helpers';
import { useLazyDataFetch } from '@va/util/hooks';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CompanyType } from './company-reveal';

export type CompanyVisitItem = {
  visits: number;
  companyOrgName: string;
  companyType: CompanyType;
  visitsPercentage: number;
};

type VisitsByCompanyResponse = {
  payload: CompanyVisitItem[];
  meta: {
    total: number;
    page: number;
    pageTotal: number;
    pageSize: number;
  };
};

type VisitsByCompanyQuery = { companyOrgName?: string; page: number; hideCompanyOrgName: boolean; pageSize: number };

export const useVisitsByCompany = (queryFilter: VisitsByCompanyQuery, shouldFetchData = true) => {
  const { from, until } = useSelector(getGlobalFilter);
  const { companyOrgName, page, pageSize, hideCompanyOrgName } = queryFilter;
  const instanceId = useSelector(getInstanceId);

  const query = useMemo(
    () => ({
      from: from.toISOString(),
      until: until.toISOString(),
      companyOrgName,
      page,
      hideCompanyOrgName,
      pageSize,
    }),
    [companyOrgName, from, hideCompanyOrgName, page, until, pageSize],
  );

  const mapper = useCallback((response: VisitsByCompanyResponse) => {
    const { payload, meta } = response;
    return { items: payload ?? [], meta };
  }, []);

  return useLazyDataFetch<{
    items: Array<CompanyVisitItem>;
    meta: {
      total: number;
      page: number;
      pageTotal: number;
      pageSize: number;
    };
  }>(
    shouldFetchData ? `/v2/websites/${instanceId}/visits-by-company?${toQueryString(query)}` : null,
    { revalidateOnFocus: false },
    mapper,
  );
};
