import { DEFAULT_REPORT_BLOCK_TYPE } from '@va/constants';
import { ExportFormat, useRequestExport } from '@va/dashboard/api-client/export';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { getWebsiteUrl } from '@va/dashboard/selectors/core';
import { usePrepareLatestVisitorsListRequests } from '@va/dashboard/shared/visitors';
import { useExportNotification } from '@va/dashboard/util-hooks';
import { useLocale, useTranslate } from '@va/localization';
import { getAccountOwnerEmail } from '@va/standalone/shared/selectors';
import { DownloadBtn } from '@va/ui/components/buttons';
import { isWixApp, isWixMiniApp } from '@va/util/helpers';
import moment from 'moment';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const ExportButtonContainer = () => {
  const { execute } = useRequestExport();
  const websiteId = useSelector(getInstanceId);
  const { locale } = useLocale();
  const websiteUrl = useSelector(getWebsiteUrl);
  const userEmail = useSelector(getAccountOwnerEmail);

  const { from, payload, until } = usePrepareLatestVisitorsListRequests();
  const translate = useTranslate();
  const { showErrorNotification, showExportStartedNotification } = useExportNotification();

  const startExport = useCallback(
    async (format: ExportFormat) => {
      try {
        await execute(websiteId, {
          from: moment(from).unix(),
          until: moment(until).unix(),
          locale: locale,
          exportType: DEFAULT_REPORT_BLOCK_TYPE.visitors.latestVisitorsTable,
          websiteUrl: websiteUrl,
          payload: payload,
          format: format,
          ...(!isWixApp() && !isWixMiniApp() && { emailTo: userEmail }),
        });

        showExportStartedNotification();
      } catch (error) {
        showErrorNotification();
      }
    },
    [
      execute,
      from,
      locale,
      payload,
      showErrorNotification,
      showExportStartedNotification,
      until,
      userEmail,
      websiteId,
      websiteUrl,
    ],
  );

  return (
    <DownloadBtn
      title={translate('all.exportType.scheduled')}
      downloadXLSX={() => {
        startExport(ExportFormat.xlsx);
      }}
      downloadCSV={() => {
        startExport(ExportFormat.csv);
      }}
    />
  );
};
