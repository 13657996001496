import PropTypes from 'prop-types';

export function SliderHandle(props) {
  const { color, ...restProps } = props;
  const colorValue = color ? color : '#04B5A6';
  return (
    <svg {...restProps} width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='21' cy='21' r='20' fill='white' stroke={colorValue} strokeWidth='2' />
      <path d='M30 21L23.25 26.1962L23.25 15.8038L30 21Z' fill={colorValue} />
      <path d='M12 21L18.75 26.1962L18.75 15.8038L12 21Z' fill={colorValue} />
    </svg>
  );
}

SliderHandle.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};
