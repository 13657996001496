type ReportBaseKeyArgs = {
  websiteId: string;
  reportBlockId: string;
  feature: string;
};

export class StorageKeysManager {
  static createReportBasedKey({ websiteId, reportBlockId, feature }: ReportBaseKeyArgs): string {
    return `twipla-${websiteId}-${reportBlockId}-${feature}`;
  }

  static createWebsiteBasedKey({ websiteId, feature }: { websiteId: string; feature: string }) {
    return `twipla-${websiteId}-${feature}`;
  }

  static getReportFilterConditionsKey({ websiteId, reportBlockId }: Omit<ReportBaseKeyArgs, 'feature'>): string {
    return this.createReportBasedKey({ websiteId, reportBlockId, feature: 'applied-filter-conditions' });
  }

  static getReportSegmentsKey({ websiteId, reportBlockId }: Omit<ReportBaseKeyArgs, 'feature'>) {
    return this.createReportBasedKey({ websiteId, reportBlockId, feature: 'applied-segments' });
  }

  static getTimePeriodKey(websiteId: string) {
    return this.createWebsiteBasedKey({ websiteId, feature: 'time-period' });
  }

  static getTodayDateKey() {
    return `twipla-todays-date`;
  }
}
