import { ToggleSwitch } from '@va/ui/design-system';
import { FastField } from 'formik';
import PropTypes from 'prop-types';
import Setting from './Setting';

/** @deprecated */
const BoolSetting = ({ children, description, name, postUpdate, initialValue }) => {
  return (
    <Setting
      description={description}
      field={
        <FastField name={name}>
          {({ field, form }) => (
            <ToggleSwitch
              onChange={(value) => {
                form.setFieldValue(name, value);
                postUpdate && postUpdate(value);
              }}
              checked={initialValue || field.value}
              onColor={window.PRIMARY_COLOR}
              onHandleColor={window.PRIMARY_COLOR}
            />
          )}
        </FastField>
      }
    >
      {children}
    </Setting>
  );
};
BoolSetting.propTypes = {
  children: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  postUpdate: PropTypes.func,
  initialValue: PropTypes.bool,
};

export default BoolSetting;
