import { modalName } from '@va/constants';
import { closeModal } from '@va/dashboard/actions/ui';
import { isModalOpen } from '@va/dashboard/selectors/ui';
import { useTranslate } from '@va/localization';
import { Button, GrayGradientDivider, ModalHeader, Paragraph } from '@va/ui/design-system';

import { getProviderName } from '@va/dashboard/selectors/customization';
import { ModalWrapper } from '@va/dashboard/shared/modals/ModalWrapperV2/ModalWrapper';
import { DeleteWebsite } from '@va/svg-visa-icons';
import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const DeleteAccountModal = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const modalContentRef = useRef<HTMLDivElement | null>(null);
  const isOpen = useSelector((state) => isModalOpen(state, modalName.deleteAccount));
  const providerName = useSelector(getProviderName);

  const handleModalClose = useCallback(() => {
    dispatch(closeModal(modalName.deleteAccount));
  }, [dispatch]);

  return (
    <ModalWrapper closeModal={handleModalClose} modalContentRef={modalContentRef} isModalOpen={isOpen}>
      <div ref={modalContentRef} className='modal-content max-w-720px'>
        <ModalHeader title={translate('modal.deleteAccount.title')} onClose={() => handleModalClose()} />
        <div className='flex flex-col px-3 py-5 items-center gap-3'>
          <DeleteWebsite />
          <Paragraph className='mt-2'>
            {translate('modal.deleteAccount.removeWebsites.message', { providerName })}
          </Paragraph>
          <Button
            text={translate('button.goToManageWebsites')}
            onClick={() => {
              history.push('/my-account/websites');
              handleModalClose();
            }}
          />
        </div>
        <GrayGradientDivider />
        <div className='flex p-3'>
          <Button color='tertiary' text={translate('button.close')} onClick={handleModalClose} className='grow' />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default DeleteAccountModal;
