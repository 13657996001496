import classNames from 'classnames';
import { IconProps } from '.';

export const EmptyCircleIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='9' cy='9' r='8.25' stroke={color} strokeWidth='1.5' />
    </svg>
  );
};


