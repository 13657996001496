export function LogoApp(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg width='180' height='24' viewBox='0 0 180 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M62.3585 8.864V6.8H70.6785V8.864H67.6545V18H65.3665V8.864H62.3585ZM94.2549 13.76L96.6549 6.8H99.3429L94.6229 18.624L91.1029 11.264L87.5669 18.624L82.8629 6.8H85.5509L87.9829 13.76L91.1029 6.24L94.2549 13.76ZM112.159 6.8H114.479V18H112.159V6.8ZM128.413 6.8H130.701V18H128.413V6.8ZM129.805 8.736V6.8H132.029C132.915 6.8 133.667 6.944 134.285 7.232C134.915 7.52 135.395 7.93067 135.725 8.464C136.056 8.99733 136.221 9.632 136.221 10.368C136.221 11.0933 136.056 11.728 135.725 12.272C135.395 12.8053 134.915 13.216 134.285 13.504C133.667 13.792 132.915 13.936 132.029 13.936H129.805V12H132.029C132.637 12 133.117 11.8613 133.469 11.584C133.821 11.3067 133.997 10.9013 133.997 10.368C133.997 9.824 133.821 9.41867 133.469 9.152C133.117 8.87467 132.637 8.736 132.029 8.736H129.805ZM149.512 6.8H151.8V16H156.264V18H149.512V6.8ZM171.131 15.456L171.403 13.648H176.779L177.051 15.456H171.131ZM174.059 10.432L172.379 14.288L172.427 14.8L170.955 18H168.379L174.059 6.144L179.739 18H177.147L175.707 14.896L175.739 14.32L174.059 10.432Z'
        fill='black'
        fillOpacity='0.84'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2857 24H17.1427L6.857 0H0L10.2857 24ZM30.857 24H37.7143L34.2857 16L29.7143 5.333L32 0H20.5713L16 10.667L19.4287 18.667L24 8L26.2857 13.333L30.857 24Z'
        fill='url(#paint0_linear_148_178654)'
      />
      <path d='M48 0L40 18.667L36.5713 10.667L41.143 0H48Z' fill='url(#paint1_linear_148_178654)' />
      <defs>
        <linearGradient id='paint0_linear_148_178654' x1='0' y1='12' x2='48' y2='12' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#F56600' />
          <stop offset='1' stopColor='#5C04B4' />
        </linearGradient>
        <linearGradient id='paint1_linear_148_178654' x1='0' y1='12' x2='48' y2='12' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#F56600' />
          <stop offset='1' stopColor='#5C04B4' />
        </linearGradient>
      </defs>
    </svg>
  );
}
