import { QuestionCircleIcon } from '@va/icons';
import PropTypes from 'prop-types';
import IconTooltip from './IconTooltip';

/**
 * Icon tooltip that wraps an question icon
 */
const QuestionTooltip = ({ children }) => {
  return <IconTooltip icon={() => <QuestionCircleIcon className='w-6 h-6' />}>{children}</IconTooltip>;
};

QuestionTooltip.propTypes = {
  children: PropTypes.node,
};

export default QuestionTooltip;
