import { PageContent } from '@va/dashboard/shared/ui-layout';
import { useTranslate } from '@va/localization';
import { getAgencyUi } from '@va/standalone/shared/actions';
import { isAgencyAccount } from '@va/standalone/shared/selectors';
import { QuickNavigator } from '@va/ui/design-system';
import { useElementDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WelcomePageAgencyWhiteLabel from '../agency-white-label/welcome-page-agency-white-label';
import CustomDomainsSection from './CustomDomains/CustomDomainsSection';
import CustomEmailAddresses from './CustomEmailAddresses/CustomEmailAddresses';
import FontSectionContainer from './FontSection/FontSectionContainer';
import LogoSectionContainer from './LogoSection/LogoSectionContainer';
import ThemeSectionContainer from './ThemeSection/ThemeSectionContainer';
import LegalLinkContainer from './legal-links/LegalLinkContainer';
import useAgencyUi from './useAgencyUi';

const WhiteLabelCustomization = () => {
  const agencyAccount = useSelector(isAgencyAccount);

  return (
    <PageContent>{!agencyAccount ? <WelcomePageAgencyWhiteLabel /> : <WhiteLabelCustomizationContent />}</PageContent>
  );
};

export default WhiteLabelCustomization;

const WhiteLabelCustomizationContent = () => {
  const pageContentRef = useRef<HTMLDivElement>(null);
  const translate = useTranslate();
  const { width: pageContentWidth } = useElementDimensions(pageContentRef);
  const showQuickNavigator = pageContentWidth > 1280;
  const dispatch = useDispatch();
  const { fontName, theme } = useAgencyUi();
  const { name: themeName } = theme ?? {};

  useEffect(() => {
    dispatch(getAgencyUi());
  }, [dispatch]);

  const quickNavigationOptions = useMemo(
    () => [
      {
        id: 'custom-domain',
        title: translate('whiteLabel.navigation.customDomain.title'),
        component: <CustomDomainsSection />,
      },
      {
        id: 'custom-email',
        title: translate('whiteLabel.navigation.customEmail.title'),
        component: <CustomEmailAddresses />,
      },
      {
        id: 'legal-link',
        title: translate('whiteLabel.navigation.legalLink.title'),
        component: <LegalLinkContainer />,
      },
      {
        id: 'agency-logo',
        title: translate('whiteLabel.navigation.agencyLogo.title'),
        component: <LogoSectionContainer />,
      },
      {
        id: 'custom-theme',
        title: translate('whiteLabel.navigation.customTheme.title'),
        component: <ThemeSectionContainer />,
        subTitle: themeName,
      },
      {
        id: 'font',
        title: translate('whiteLabel.navigation.font.title'),
        component: <FontSectionContainer />,
        subTitle: fontName,
      },
    ],
    [translate, fontName, themeName],
  );

  return (
    <div ref={pageContentRef} className='lg:flex lg:gap-60 relative'>
      <div
        className={classNames('absolute top-0 h-full left-0 transition-all duration-500', {
          '-translate-x-[300px] opacity-0': !showQuickNavigator,
        })}
      >
        <QuickNavigator navigatorClass={'top-[230px] min-w-300 max-w-300'} list={quickNavigationOptions} offset={220} />
      </div>

      <div className='lg:flex lg:grow lg:justify-center '>
        <div className={classNames('space-y-12 w-full lg:max-w-600 transition-transform duration-500')}>
          {quickNavigationOptions.map(({ id, component }) => (
            <div key={id} id={id} className='max-w-full'>
              {component}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
