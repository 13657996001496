import { makeAction } from '@va/store';
import * as Types from './types';

// === Paddle ===

export const getUpdatePaymentMethodUrl = makeAction(Types.GET_UPDATE_PAYMENT_METHOD_URL);
export const getUpdatePaymentMethodUrlSucceeded = makeAction(Types.GET_UPDATE_PAYMENT_METHOD_URL_SUCCEEDED, 'data');
export const getUpdatePaymentMethodUrlFailed = makeAction(Types.GET_UPDATE_PAYMENT_METHOD_URL_FAILED, 'error');

export const updateSubscription = makeAction(Types.UPDATE_SUBSCRIPTION_REQUEST, 'data');
export const updateSubscriptionSucceeded = makeAction(Types.UPDATE_SUBSCRIPTION_SUCCEEDED, 'data');
export const updateSubscriptionFailed = makeAction(Types.UPDATE_SUBSCRIPTION_FAILED, 'error');

export const getAgencyCustomer = makeAction(Types.GET_AGENCY_CUSTOMER_REQUEST);
export const getAgencyCustomerSucceeded = makeAction(Types.GET_AGENCY_CUSTOMER_SUCCEEDED, 'data');
export const getAgencyCustomerFailed = makeAction(Types.GET_AGENCY_CUSTOMER_FAILED, 'error');

// === Common ===

export const getPaymentMethod = makeAction(Types.GET_PAYMENT_METHOD_REQUEST);
export const getPaymentMethodSucceeded = makeAction(Types.GET_PAYMENT_METHOD_SUCCEEDED, 'data');
export const getPaymentMethodFailed = makeAction(Types.GET_PAYMENT_METHOD_FAILED, 'error');

export const getPaymentPlans = makeAction(Types.GET_PAYMENT_PLANS_REQUEST, 'planType');
export const getPaymentPlansSucceeded = makeAction(Types.GET_PAYMENT_PLANS_SUCCEEDED, 'data');
export const getPaymentPlansFailed = makeAction(Types.GET_PAYMENT_PLANS_FAILED, 'error');

export const getAllSubscriptions = makeAction(Types.GET_ALL_SUBSCRIPTIONS_REQUEST);
export const getAllSubscriptionsSucceeded = makeAction(Types.GET_ALL_SUBSCRIPTIONS_SUCCEEDED, 'data');
export const getAllSubscriptionsFailed = makeAction(Types.GET_ALL_SUBSCRIPTIONS_FAILED, 'error');

export const cancelSubscription = makeAction(Types.CANCEL_SUBSCRIPTION_REQUEST, 'data');
export const cancelSubscriptionSucceeded = makeAction(Types.CANCEL_SUBSCRIPTION_SUCCEEDED, 'data');
export const cancelSubscriptionFailed = makeAction(Types.CANCEL_SUBSCRIPTION_FAILED, 'error');

// === Braintree ===

export const getPaymentAuthToken = makeAction(Types.GET_CUSTOMER_AUTH_TOKEN_REQUEST);
export const getPaymentAuthTokenSucceeded = makeAction(Types.GET_CUSTOMER_AUTH_TOKEN_SUCCEEDED, 'data');
export const getPaymentAuthTokenFailed = makeAction(Types.GET_CUSTOMER_AUTH_TOKEN_FAILED, 'error');

export const getBraintreeAuthToken = makeAction(Types.GET_BRAINTREE_AUTH_TOKEN_REQUEST);
export const getBraintreeAuthTokenSucceeded = makeAction(Types.GET_BRAINTREE_AUTH_TOKEN_SUCCEEDED, 'data');
export const getBraintreeAuthTokenFailed = makeAction(Types.GET_BRAINTREE_AUTH_TOKEN_FAILED, 'error');

export const getCustomer = makeAction(Types.GET_CUSTOMER_REQUEST);
export const getCustomerSucceeded = makeAction(Types.GET_CUSTOMER_SUCCEEDED, 'data');
export const getCustomerFailed = makeAction(Types.GET_CUSTOMER_FAILED, 'error');

export const getComputedSubscriptionPrice = makeAction(Types.GET_COMPUTED_SUBSCRIPTION_PRICE_REQUEST, 'formName');
export const getComputedSubscriptionPriceSucceeded = makeAction(
  Types.GET_COMPUTED_SUBSCRIPTION_PRICE_SUCCEEDED,
  'data',
);
export const getComputedSubscriptionPriceFailed = makeAction(Types.GET_COMPUTED_SUBSCRIPTION_PRICE_FAILED, 'error');

export const validateVat = makeAction(Types.VALIDATE_VAT_REQUEST, 'formName');
export const validateVatSucceeded = makeAction(Types.VALIDATE_VAT_SUCCEEDED, 'data');
export const validateVatFailed = makeAction(Types.VALIDATE_VAT_FAILED, 'error');

export const submitPayment = makeAction(Types.SUBMIT_PAYMENT_REQUEST, 'token', 'nonce', 'data', 'newCard');
export const submitPaymentSucceeded = makeAction(Types.SUBMIT_PAYMENT_SUCCEEDED, 'data');
export const submitPaymentFailed = makeAction(Types.SUBMIT_PAYMENT_FAILED, 'error');

export const createCustomer = makeAction(Types.CREATE_CUSTOMER_REQUEST, 'data');
export const createCustomerSucceeded = makeAction(Types.CREATE_CUSTOMER_SUCCEEDED, 'data');
export const createCustomerFailed = makeAction(Types.CREATE_CUSTOMER_FAILED, 'error');

export const createPaymentMethod = makeAction(Types.CREATE_PAYMENT_METHOD_REQUEST, 'token', 'nonce', 'data');
export const createPaymentMethodSucceeded = makeAction(Types.CREATE_PAYMENT_METHOD_SUCCEEDED, 'data');
export const createPaymentMethodFailed = makeAction(Types.CREATE_PAYMENT_METHOD_FAILED, 'error');

export const createSubscription = makeAction(Types.CREATE_SUBSCRIPTION_REQUEST, 'data');
export const createSubscriptionSucceeded = makeAction(Types.CREATE_SUBSCRIPTION_SUCCEEDED, 'data');
export const createSubscriptionFailed = makeAction(Types.CREATE_SUBSCRIPTION_FAILED, 'error');

export const cancelSubscriptionImmediate = makeAction(Types.CANCEL_SUBSCRIPTION_IMMEDIATE_REQUEST, 'data');
export const cancelSubscriptionImmediateSucceeded = makeAction(Types.CANCEL_SUBSCRIPTION_IMMEDIATE_SUCCEEDED, 'data');
export const cancelSubscriptionImmediateFailed = makeAction(Types.CANCEL_SUBSCRIPTION_IMMEDIATE_FAILED, 'error');

export const reactivateSubscription = makeAction(Types.REACTIVATE_SUBSCRIPTION_REQUEST, 'data');
export const reactivateSubscriptionSucceeded = makeAction(Types.REACTIVATE_SUBSCRIPTION_SUCCEEDED, 'data');
export const reactivateSubscriptionFailed = makeAction(Types.REACTIVATE_SUBSCRIPTION_FAILED, 'error');

export const updateCustomer = makeAction(Types.UPDATE_CUSTOMER_REQUEST, 'data');
export const updateCustomerSucceeded = makeAction(Types.UPDATE_CUSTOMER_SUCCEEDED, 'data');
export const updateCustomerFailed = makeAction(Types.UPDATE_CUSTOMER_FAILED, 'error');

export const generateInvoice = makeAction(Types.GENERATE_INVOICE, 'data');
export const generateInvoiceSucceeded = makeAction(Types.GENERATE_INVOICE_SUCCEEDED, 'data');
export const generateInvoiceFailed = makeAction(Types.GENERATE_INVOICE_FAILED, 'error');

export const validatePromoCode = makeAction(Types.VALIDATE_PROMO_CODE, 'voucherCode');
export const validatePromoCodeSucceeded = makeAction(Types.VALIDATE_PROMO_CODE_SUCCEEDED, 'data');
export const validatePromoCodeFailed = makeAction(Types.VALIDATE_PROMO_CODE_FAILED, 'error');
