import { connect } from 'react-redux';

import { modalName as commonModalName } from '@va/constants';
import { getApiRequestImmutable } from '@va/dashboard/selectors/api';
import { getCurrentPlanName } from '@va/dashboard/selectors/app';
import { getModalExtraProps, getPreviousCardView } from '@va/dashboard/selectors/ui';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import * as Actions from '@va/standalone/shared/actions';
import { FREE_PLAN_DETAILS } from '@va/standalone/shared/constants';
import * as Selectors from '@va/standalone/shared/selectors';
import { getLastCreatedSubscription } from '@va/standalone/shared/selectors';
import UpgradeSliderComponent from './UpgradeSliderComponent';

const mapStateToProps = (state) => {
  const uiSubscriptionType = Selectors.standalone.Ui.getSubscriptionTypeUi(state);
  const paymentPlans = Selectors.standalone.App.getPaymentPlans(state);
  const featurePermissions = getApiRequestImmutable(state, 'getFeaturePermissions');
  const lastSubscription = getLastCreatedSubscription(state);
  const unlimitedVoucherCode = featurePermissions.getIn(['unlimitedVoucher', 'voucherCode'], null);

  const modalExtraProps = getModalExtraProps(state, commonModalName.upgrade);
  let planName = getCurrentPlanName(state) ?? FREE_PLAN_DETAILS.planName;
  const planIndex = modalExtraProps ? modalExtraProps.get('planIndex') : undefined;

  return {
    uiSubscriptionType: uiSubscriptionType,
    uiSubscriptionPlan: Selectors.standalone.Ui.getSubscriptionPlan(state),
    uiSliderVal: Selectors.standalone.Ui.getSliderVal(state),
    paymentPlans: paymentPlans,
    selectedPaymentPlans: paymentPlans[uiSubscriptionType],
    activePlanIndex: Selectors.standalone.App.getPlanIndex(state),
    plansCount: paymentPlans[uiSubscriptionType].length - 1,
    previousModalView: getPreviousCardView(state, commonModalName.upgrade),
    lastSubscription: lastSubscription,
    unlimitedVoucherCode: unlimitedVoucherCode,
    planName: planName,
    planIndex: planIndex,
  };
};

const mapDispatchToProps = {
  setSubscriptionType: Actions.standalone.Ui.setSubscriptionType,
  setSubscriptionPlan: Actions.standalone.Ui.setSubscriptionPlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(UpgradeSliderComponent));
