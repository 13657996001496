import { IconProps } from '.';

export const ConversionEventIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.75 5.90549C11.0439 5.57246 12 4.39788 12 3C12 1.34315 10.6569 0 9 0C7.34315 0 6 1.34315 6 3C6 4.39788 6.95608 5.57245 8.25 5.90549V8.25H5.90549C5.57245 6.95608 4.39788 6 3 6C1.34315 6 0 7.34315 0 9C0 10.6569 1.34315 12 3 12C4.39788 12 5.57245 11.0439 5.90549 9.75H8.25V12.0945C6.95608 12.4275 6 13.6021 6 15C6 16.6569 7.34315 18 9 18C10.6569 18 12 16.6569 12 15C12 13.6021 11.0439 12.4275 9.75 12.0945V9.75H12.0945C12.4275 11.0439 13.6021 12 15 12C16.6569 12 18 10.6569 18 9C18 7.34315 16.6569 6 15 6C13.6021 6 12.4275 6.95608 12.0945 8.25H9.75V5.90549ZM13.5 9C13.5 9.82843 14.1716 10.5 15 10.5C15.8284 10.5 16.5 9.82843 16.5 9C16.5 8.17157 15.8284 7.5 15 7.5C14.1716 7.5 13.5 8.17157 13.5 9ZM3 10.5C2.17157 10.5 1.5 9.82843 1.5 9C1.5 8.17157 2.17157 7.5 3 7.5C3.82843 7.5 4.5 8.17157 4.5 9C4.5 9.82843 3.82843 10.5 3 10.5Z'
        fill={color}
      />
    </svg>
  );
};


