import Immutable from 'immutable';

const columnSizes = { xllg: 6, llg: 5, lg: 4, md: 3, sm: 2, xs: 1, xxs: 1 };

export const createGrid = (orderedCards, gridLayout, sameCards) => {
  let layouts = new Immutable.List();
  for (let columnSize in columnSizes) {
    let gridMatrix = createGridMatrix(Object.keys(gridLayout).length * 2, columnSizes[columnSize]);

    for (let i = 0; i < orderedCards.length; i++) {
      const card = orderedCards[i];
      const foundCard = sameCards ? { [card + i]: gridLayout[card] } : { [card]: gridLayout[card] };
      if (foundCard) {
        layouts = addCardToMatrix(foundCard, gridMatrix, columnSize, layouts);
      }
    }
  }
  return { items: layouts.toJS() };
};

const addCardToMatrix = (card, gridMatrix, columnSize, layouts) => {
  const numberOfRows = gridMatrix.length,
    numberOfCols = gridMatrix[0].length,
    cardName = Object.keys(card)[0],
    cardWidth = card[cardName][columnSize].width,
    cardHeight = card[cardName][columnSize].height;

  rowIterator: for (let rowIndex = 0; rowIndex < numberOfRows; rowIndex++) {
    for (let colIndex = 0; colIndex < numberOfCols; colIndex++) {
      if (
        gridMatrix[rowIndex][colIndex] === 0 &&
        (gridMatrix[rowIndex][colIndex + 1] === 0 || !gridMatrix[rowIndex][colIndex + 1])
      ) {
        if (cardWidth <= numberOfCols - colIndex) {
          gridMatrix[rowIndex].fill(1, colIndex, colIndex + cardWidth);
          for (let i = rowIndex; i < rowIndex + cardHeight; i++) {
            gridMatrix[i].fill(1, colIndex, colIndex + cardWidth);
          }
          return saveCardPosition(card, rowIndex, colIndex, columnSize, layouts);
        } else {
          continue rowIterator;
        }
      }
    }
  }
  return layouts;
};

const saveCardPosition = (card, row, column, columnSize, layouts) => {
  const cardName = Object.keys(card)[0];
  const cardItem = {
    card: cardName,
    layouts: {},
  };

  cardItem.layouts[columnSize] = {
    x: column,
    y: row,
    w: card[cardName][columnSize].width,
    h: card[cardName][columnSize].height,
  };
  const index = layouts.findIndex((item) => {
    return item.get('card') === cardItem.card;
  });
  if (index !== -1) {
    return layouts.mergeDeepIn([index], cardItem);
  } else {
    return layouts.push(Immutable.fromJS(cardItem));
  }
};

const createGridMatrix = (rows, columns) => {
  let matrix = [];
  for (let i = 0; i < rows; i++) {
    matrix.push(new Array(columns).fill(0));
  }
  return matrix;
};
