import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import Select from 'react-select';

/** @deprecated */
const SingleValueDropdown = ({ options, onChange, initialValue }) => {
  useEffect(() => {
    if (!initialValue) onChange(options[0]);
  }, []);

  return (
    <Select
      // input
      defaultValue={initialValue || options[0]}
      styles={customStyles}
      options={options}
      components={customComponents}
      isSearchable={false}
      isClearable={false}
      onChange={onChange}
    />
  );
};

SingleValueDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SingleValueDropdown;

const Option = (props) => {
  const { data, selectOption, isSelected } = props;
  const { label } = data;

  return (
    <div
      className={classNames('flex cursor-pointer p-2 hover:bg-gray-concrete', { 'bg-gray-concrete': isSelected })}
      onClick={() => selectOption(data)}
    >
      <div className='flex grow items-center text-mine-shaft'>{label}</div>
    </div>
  );
};

const SingleValue = ({ getValue }) => {
  const selectedOption = getValue()[0];

  return (
    <div className='flex rounded-lg items-center w-full'>
      <div className='truncate pl-2 min-w-0 h-full flex items-center text-mine-shaft'>{selectedOption.label}</div>
    </div>
  );
};

const customStyles = {
  menu: (provided) => ({
    ...provided,
    borderTop: '0',
    borderBottom: '0',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.06)',
    borderRadius: '12px 0px 12px 12px',
    margin: 0,
    zIndex: 2,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    overflow: 'hidden',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    flexWrap: 'no-wrap',
  }),
  control: (provided) => ({
    ...provided,
    cursor: 'pointer',
    border: '2px solid #EEEEEE',
    borderRadius: '0.5rem',
    boxShadow: 'none',
    '&:hover': {
      border: '2px solid #EEEEEE',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

const customComponents = { Option, SingleValue };
