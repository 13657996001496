import { ModalHeader } from '@va/ui/design-system';

import { useTranslate } from '@va/localization';
import { Button, GrayGradientDivider, RadioInputOption } from '@va/ui/design-system';
import { ModalWrapper } from '@va/util/components';
import { ThemeColorOptions } from './ThemeColorOptions';
import { ThemePreview } from './ThemePreview';
import { ThemeColorsType } from './types';

type ThemePreviewModalProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  colors: ThemeColorsType;
  themeName: string;
  userMenuText: string;
};

export const ThemePreviewModal: React.FC<ThemePreviewModalProps> = ({
  isModalOpen,
  closeModal,
  colors,
  themeName,
  userMenuText,
}) => {
  const translate = useTranslate();
  return (
    <ModalWrapper isModalOpen={isModalOpen} closeModal={closeModal}>
      <>
        <ModalHeader title={translate('whiteLabel.settings.previewTheme')} onClose={closeModal} />
        <div className='p-1 sm-initial:p-3'>
          <RadioInputOption
            className='my-2 cursor-default'
            onClick={() => {}}
            selected={true}
            title={themeName}
            rightSideNode={<ThemeColorOptions colors={Object.values(colors)} showTooltip />}
          />
        </div>
        <GrayGradientDivider className='-translate-x-3' />
        <div className='p-2 sm-initial:p-6'>
          <ThemePreview userMenuText={userMenuText} colors={colors} />
          <Button
            onClick={closeModal}
            className='w-full mt-3'
            color='tertiary'
            text={translate('whiteLabel.settings.closePreview')}
          />
        </div>
      </>
    </ModalWrapper>
  );
};
