import { InformationIconComponent } from '@va/svg-visa-icons';
import { Paragraph } from '@va/ui/design-system';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

export const InformationBox = ({ className, children }: PropsWithChildren<{ className: string }>) => {
  const classes = classNames(
    'flex items-center bg-white-snow px-15px py-18px divide-transparent divide-x-15 rounded-21',
    className,
  );

  return (
    <div className={classes}>
      <div>
        <InformationIconComponent />
      </div>
      <Paragraph>{children}</Paragraph>
    </div>
  );
};
