import classNames from 'classnames';
import { CSSProperties, ReactNode, forwardRef } from 'react';

type IconWithBackgroundType = {
  className?: string;
  type?: 'circle' | 'square';
  icon: ({ className }: { className?: string }) => ReactNode;
  style?: CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  size?: 'small' | 'medium' | 'large';
};

type SizeStyle = { [key in NonNullable<IconWithBackgroundType['size']>]: string };

const sizeClasses: SizeStyle = {
  small: 'h-9 w-9',
  medium: 'h-42px w-42px',
  large: 'h-12 w-12',
};

const iconSizeClasses: SizeStyle = {
  small: 'w-[15px] h-[15px]',
  medium: 'w-[15px] h-[15px]',
  large: 'w-[18px] h-[18px]',
};

const roundedClasses = {
  circle: { small: 'rounded-full', medium: 'rounded-full', large: 'rounded-full' } as SizeStyle,
  square: { small: 'rounded-9px', medium: 'rounded-10.5', large: 'rounded-12' } as SizeStyle,
};

export const IconWithBackground = forwardRef<HTMLDivElement, IconWithBackgroundType>(
  ({ className, type = 'circle', icon, style, onClick, size = 'large' }, ref) => {
    return (
      <div
        ref={ref}
        onClick={onClick}
        style={style}
        className={classNames(
          'flex items-center shrink-0 justify-center',
          roundedClasses[type][size],
          className,
          sizeClasses[size],
        )}
      >
        {icon?.({ className: iconSizeClasses[size] })}
      </div>
    );
  },
);

export default IconWithBackground;
