import { supportLinks } from '@va/constants';
import { getSupportCenterDomain } from '@va/dashboard/selectors/customization';
import { isMobileDevice } from '@va/dashboard/selectors/ui';
import { CodeEditorWithCopyBtn } from '@va/dashboard/ui/components';
import { AsyncStatus, useTrackingCodeVerifier } from '@va/dashboard/util-hooks';
import { Checkmark, ExternalLinkIcon, LineGraphIcon, NotesIcon, UserIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { getTrackingCode } from '@va/standalone/shared/actions';
import { getTrackingCode as getTrackingCodeSelector } from '@va/standalone/shared/selectors';
import { Button, Heading5, IconWithBackground, InfoSectionItem, Paragraph, fontWeights } from '@va/ui/design-system';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ManualTrackingCodeInstallationView = ({ onSuccess, onError }: { onSuccess: () => void; onError: () => void }) => {
  const dispatch = useDispatch();
  const trackingCode = useSelector(getTrackingCodeSelector);

  const { verify: checkTrackingCode, status } = useTrackingCodeVerifier(onSuccess);

  useEffect(() => {
    dispatch(getTrackingCode());
  }, [dispatch]);

  const handleCodeTrackerClick = useCallback(() => {
    checkTrackingCode();
  }, [checkTrackingCode]);

  const translate = useTranslate();

  const isLoading = useMemo(() => status === AsyncStatus.IN_PROGRESS, [status]);
  const isMobile = useSelector(isMobileDevice);

  const icon = useMemo(() => {
    if (status === AsyncStatus.ERROR)
      return <IconWithBackground className='!bg-negative' icon={() => <LineGraphIcon color={'#fff'} />} />;
    if (status === AsyncStatus.SUCCESS)
      return <IconWithBackground className='!bg-positive' icon={() => <Checkmark color={'#fff'} />} />;
    return <IconWithBackground className='!bg-gray-charcoal' icon={() => <LineGraphIcon color={'#fff'} />} />;
  }, [status]);

  const title = useMemo(() => {
    if (status === AsyncStatus.ERROR) return translate('trackingCode.installation.installError');
    if (status === AsyncStatus.SUCCESS) return translate('trackingCode.installation.successInstalled');
    return translate('trackingCode.installation.unInstalled');
  }, [status, translate]);

  const actionButton = useMemo(() => {
    if (status === AsyncStatus.ERROR)
      return (
        <Button
          onClick={handleCodeTrackerClick}
          text={isMobile ? translate('button.retry') : translate('trackingCode.installation.retryVerification')}
          color='quinary'
        />
      );
    if (status === AsyncStatus.SUCCESS)
      return (
        <Button
          onClick={() => {
            onSuccess();
          }}
          text={translate('button.continue')}
          color='primary'
        />
      );
    return (
      <Button
        disabled={isLoading}
        onClick={handleCodeTrackerClick}
        text={translate('trackingCode.installation.verifyInstallation')}
        color='quinary'
      />
    );
  }, [handleCodeTrackerClick, isLoading, isMobile, onSuccess, status, translate]);

  const supportCenterDomain = useSelector(getSupportCenterDomain);

  return (
    <div className='w-full sm-initial:max-w-[540px] mt-[21px] mb-36px'>
      <Heading5 className='text-center text-gray-code' weight={fontWeights.semibold}>
        {translate('onboarding.trackingCode.title')}
      </Heading5>
      <Paragraph weight={fontWeights.medium} className='text-center mb-24px'>
        {translate('onboarding.trackingCode.info')}
      </Paragraph>
      <div>
        <CodeEditorWithCopyBtn code={trackingCode} />
        <Button
          onClick={() => {
            onError();
          }}
          className='w-full my-3'
          color='primary'
          text={translate('onboarding.trackingCode.installLater')}
        />
        <InfoSectionItem
          className='rounded-12'
          label={translate('trackingCode.installation.trackingCodeStatus')}
          rightNode={actionButton}
          title={title}
          icon={icon}
        />
      </div>
      <div className='p-18px rounded-24 bg-white-snow my-24px'>
        <Heading5 weight={fontWeights.semibold} className='text-center my-18'>
          {translate('onboarding.trackingCode.resources.title')}
        </Heading5>
        <div className='space-y-3'>
          <ResourceItem
            link={translate('trackingCode.instructions.installSnippetGuideUrl', { supportCenterDomain })}
            label={translate('labels.guide')}
            title={translate('onboarding.trackingCode.resources.addToSourceCode')}
            icon={<NotesIcon color='#696969' />}
          />
          <ResourceItem
            link={supportLinks.contact}
            label={translate('labels.requestHelp')}
            title={translate('trackingCode.instructions.contactOurSupport')}
            icon={<UserIcon color='#696969' />}
          />
        </div>
      </div>
    </div>
  );
};

export default ManualTrackingCodeInstallationView;

const ResourceItem = ({
  link,
  label,
  icon,
  title,
}: {
  link: string;
  title: string;
  icon: React.ReactNode;
  label: string;
}) => {
  return (
    <a className='block' href={link} target='_blank' rel='noreferrer'>
      <InfoSectionItem
        label={label}
        className='bg-white rounded-18 hover:bg-gray-concrete'
        title={title}
        icon={<IconWithBackground className='!bg-white-snow' icon={() => icon} />}
        rightNode={<ExternalLinkIcon color='#C3C3C3' />}
      />
    </a>
  );
};
