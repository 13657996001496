import { get } from '@va/http-client';

/**
 * @param  {string} resource
 * @return {requestFunction}
 * @deprecated TODO Delete all
 */
const getRequest = (resource) => {
  /**
   * @function requestFunction
   *
   * @param  {string} websiteId
   * @param  {Object} filter
   * @param  {string} filter.from
   * @param  {string} filter.until
   * @return {Promise}
   */
  return function requestFunction(websiteId, filter) {
    const url = `/websites/${websiteId}/pages/${resource}`;
    return get(url, filter ? filter : {});
  };
};

export const visits = getRequest('visits');
export const landing = getRequest('landing');
export const referers = getRequest('referrers');

export const clickpaths = getRequest('clickpaths');
export const conversions = getRequest('conversions');

export const getPagesListSearchable = (websiteId, queryData = {}) => {
  const url = `/websites/${websiteId}/pages-new`;
  return get(url, queryData);
};

/** @deprecated */
export const referrerDetails = (websiteId, filter, referrerUrl) => {
  const url = `/websites/${websiteId}/pages/referrers/clickpath`;
  return get(url, filter ? { ...filter, referrerUrl } : { referrerUrl });
};
