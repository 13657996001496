import { TEST_IDS } from '@va/constants';
import { fontWeights, ParagraphWithTooltip, RadioInputButton } from '@va/ui/design-system';
import { DropdownOptionType } from './HowDidYouFindUsDropdown';

export function HowDidYouFindUsOption({
  handleOptionSelect,
  option,
  isSelected,
}: {
  handleOptionSelect: (option: DropdownOptionType) => void;
  option: DropdownOptionType;
  isSelected: boolean;
}) {
  return (
    <li
      key={option.value}
      className='flex min-h-30px items-center hover:bg-white-snow group px-3 rounded-15 cursor-pointer'
      onClick={() => handleOptionSelect(option)}
      data-testid={TEST_IDS.helpers.createListItemId('how-did-you-find-us')}
    >
      <ParagraphWithTooltip weight={fontWeights.medium} className='whitespace-nowrap'>
        {option.label}
      </ParagraphWithTooltip>
      <RadioInputButton className='ml-auto shrink-0' selected={isSelected} />
    </li>
  );
}
