import {
  ADD_DYNAMIC_PAGE,
  ADD_DYNAMIC_PAGE_FAILED,
  ADD_DYNAMIC_PAGE_SUCCEEDED,
  EDIT_DYNAMIC_PAGE,
  EDIT_DYNAMIC_PAGE_FAILED,
  EDIT_DYNAMIC_PAGE_SUCCEEDED,
  GET_DYNAMIC_PAGES,
  GET_DYNAMIC_PAGES_FAILED,
  GET_DYNAMIC_PAGES_SUCCEEDED,
  REMOVE_DYNAMIC_PAGE,
  REMOVE_DYNAMIC_PAGE_FAILED,
  REMOVE_DYNAMIC_PAGE_SUCCEEDED,
} from '@va/dashboard/actions/api';
import { newApiRequest } from './core/util';

export const getDynamicPages = newApiRequest(GET_DYNAMIC_PAGES, GET_DYNAMIC_PAGES_SUCCEEDED, GET_DYNAMIC_PAGES_FAILED);

export const addDynamicPage = newApiRequest(ADD_DYNAMIC_PAGE, ADD_DYNAMIC_PAGE_SUCCEEDED, ADD_DYNAMIC_PAGE_FAILED);

export const editDynamicPage = newApiRequest(EDIT_DYNAMIC_PAGE, EDIT_DYNAMIC_PAGE_SUCCEEDED, EDIT_DYNAMIC_PAGE_FAILED);

export const removeDynamicPage = newApiRequest(
  REMOVE_DYNAMIC_PAGE,
  REMOVE_DYNAMIC_PAGE_SUCCEEDED,
  REMOVE_DYNAMIC_PAGE_FAILED,
);
