import { IconProps } from '.';

export const FilledLineGraphIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path opacity='0.15' d='M3 6H0V15H18V6H15L12 3L6 9L3 6Z' fill={color} />
      <path d='M0 6H3L6 9L12 3L15 6H18' stroke={color} strokeWidth='1.5' />
    </svg>
  );
};
