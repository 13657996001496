import { CheckmarkIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { UnCheckMarkIcon } from '@va/svg-visa-icons';
import classNames from 'classnames';

const WEBSITE_STATUS = {
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
};

const TrackingCodeStatus = ({ isInstalled }: any) => {
  const translate = useTranslate();
  const statusText = isInstalled ? WEBSITE_STATUS.ACTIVE : WEBSITE_STATUS.INACTIVE;

  return (
    <StatusButton
      icon={isInstalled ? <CheckmarkIcon color={'#06A25A'} /> : <UnCheckMarkIcon className='h-18 w-18' />}
      text={translate(`trackingWebsite.status.${statusText.toLowerCase()}`)}
      isActive={isInstalled}
    />
  );
};

export default TrackingCodeStatus;

export const StatusButton = ({ icon, text, isActive }: { icon: React.ReactNode; text: string; isActive: boolean }) => {
  return (
    <div
      className={classNames(
        'inline-flex items-center gap-7.5px py-9px pl-3 pr-15px text-15 leading-24 font-medium group-hover:bg-white rounded-24',
        { 'bg-green-apple text-green-pure': isActive },
        { 'bg-white-snow text-gray-charcoal': !isActive },
      )}
    >
      {icon}
      <div>{text}</div>
    </div>
  );
};
