import { IconProps } from '.';

export const SharpStartIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='17'
      viewBox='0 0 18 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 0L11.0102 6.23316L17.5595 6.21885L12.2526 10.0568L14.2901 16.2812L9 12.42L3.70993 16.2812L5.74739 10.0568L0.440492 6.21885L6.98977 6.23316L9 0Z'
        fill={color}
      />
    </svg>
  );
};
