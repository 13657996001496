import React from 'react';
import PropTypes from 'prop-types';

export default function CustomUpgradeSection(props) {
  return (
    <div className={'custom-upgrade-section text-center'}>
      <p className='paragraph bold'>{props.translate('upgradeCard.customPlan.text1')}</p>
      <p className='paragraph'>{props.translate('upgradeCard.customPlan.text2')}</p>
    </div>
  );
}

CustomUpgradeSection.propTypes = {
  translate: PropTypes.func.isRequired,
};
