import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { CloseIconWithCircle } from './CloseIconWithCircle';
import { SuccessIcon } from './SuccessIcon';

type StatusIconProps = {
  isLoading?: boolean;
  hasError: boolean;
  className?: string;
};

export const StatusIcon = ({ isLoading = false, hasError, className }: StatusIconProps) => {
  if (isLoading) {
    return (
      <div className='rounded-full overflow-hidden'>
        <Skeleton className={classNames('w-20 h-20 md:w-28 md:h-28', className)} />
      </div>
    );
  }
  if (hasError)
    return <CloseIconWithCircle color='#EA2A0C' className={classNames('w-20	h-20 md:w-28 md:h-28', className)} />;
  return <SuccessIcon color='#06A25A' className={classNames('w-20	h-20 md:w-28 md:h-28', className)} />;
};
