import { apiStatus } from '@va/constants';
import {
  EXPORT_VISITORS_CANCEL,
  EXPORT_VISITORS_FAILED,
  EXPORT_VISITORS_PAGE_FAILED,
  EXPORT_VISITORS_PAGE_SUCCEEDED,
  EXPORT_VISITORS_REQUEST,
  EXPORT_VISITORS_SUCCEEDED,
  GET_LATEST_VISITORS_BROWSERS,
  GET_LATEST_VISITORS_BROWSERS_FAILED,
  GET_LATEST_VISITORS_BROWSERS_SUCCEEDED,
  GET_LATEST_VISITORS_OSS,
  GET_LATEST_VISITORS_OSS_FAILED,
  GET_LATEST_VISITORS_OSS_SUCCEEDED,
  LATEST_VISITORS_MAP_FAILED,
  LATEST_VISITORS_MAP_REQUEST,
  LATEST_VISITORS_MAP_SUCCEEDED,
  VISITOR_FAILED,
  VISITOR_REQUEST,
  VISITOR_SUCCEEDED,
} from '@va/dashboard/actions/api';
import Immutable from 'immutable';
import { apiRequest } from './core/util';

export const getVisitor = apiRequest(
  VISITOR_REQUEST,
  VISITOR_SUCCEEDED,
  VISITOR_FAILED,
  Immutable.fromJS({
    inProgress: false,
    information: {},
  }),
);

export const getLatestVisitorsOss = apiRequest(
  GET_LATEST_VISITORS_OSS,
  GET_LATEST_VISITORS_OSS_SUCCEEDED,
  GET_LATEST_VISITORS_OSS_FAILED,
  Immutable.fromJS({
    inProgress: false,
    data: [],
  }),
);

export const getLatestVisitorsBrowsers = apiRequest(
  GET_LATEST_VISITORS_BROWSERS,
  GET_LATEST_VISITORS_BROWSERS_SUCCEEDED,
  GET_LATEST_VISITORS_BROWSERS_FAILED,
  Immutable.fromJS({
    inProgress: false,
    data: [],
  }),
);

export const latestVisitorsMap = apiRequest(
  LATEST_VISITORS_MAP_REQUEST,
  LATEST_VISITORS_MAP_SUCCEEDED,
  LATEST_VISITORS_MAP_FAILED,
  Immutable.fromJS({
    inProgress: false,
    items: [],
  }),
);

export const exportVisitors = (
  state = Immutable.Map({
    status: apiStatus.NONE,
    sessions: Immutable.List(),
  }),
  action,
) => {
  switch (action.type) {
    case EXPORT_VISITORS_REQUEST:
      return state.set('status', apiStatus.IN_PROGRESS).set('format', action.format);
    case EXPORT_VISITORS_PAGE_SUCCEEDED:
      return state
        .set('sessionsTotal', action.data.sessionsTotal)
        .update('sessions', (list) => list.push(action.data.sessions));
    case EXPORT_VISITORS_SUCCEEDED:
      return state
        .update('sessions', (list) => list.clear())
        .delete('sessionsTotal')
        .delete('format')
        .merge({
          status: apiStatus.SUCCEEDED,
          href: action.data ? action.data.href : undefined,
          download: action.data ? action.data.download : undefined,
        });
    case EXPORT_VISITORS_CANCEL:
      return state
        .update('sessions', (list) => list.clear())
        .delete('sessionsTotal')
        .delete('format')
        .merge({
          status: apiStatus.FAILED,
        });
    case EXPORT_VISITORS_FAILED:
    case EXPORT_VISITORS_PAGE_FAILED:
      return state
        .update('sessions', (list) => list.clear())
        .delete('sessionsTotal')
        .delete('format')
        .merge({
          status: apiStatus.FAILED,
        });
    default:
      return state;
  }
};
