import { LogoutIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { logout } from '@va/standalone/shared/actions';
import { useDispatch } from 'react-redux';
import { UserMenuButton } from './UserMenuButton';

export function LogoutButton() {
  const translate = useTranslate();
  const dispatch = useDispatch();

  return (
    <UserMenuButton
      leftIcon={<LogoutIcon />}
      text={translate('button.logout')}
      onClick={() => {
        dispatch(logout());
      }}
    />
  );
}
