import { userActions } from '@va/constants';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { SaveTemplateBtnComponent, useFiltersContext } from '@va/shared/feature-filters';
import { hasPermission } from '@va/standalone/shared/selectors';
import { isEqual } from 'lodash';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import SaveDataTemplateModal from './SaveFIlterTemplateModal';
import { useGetFilterTemplates } from './api';

export const SaveFilterTemplateButton = ({ isAddFilterBtnVisible }: { isAddFilterBtnVisible: boolean }) => {
  const [isSaveDataTemplateModalOpen, setIsSaveDataTemplateModalOpen] = useState(false);
  const hasCreatePermission = useSelector((state) => hasPermission(state, userActions.Create));
  const { appliedFilters, reportBlockId, getAppliedFilterValues } = useFiltersContext();
  const { showErrorNotification } = useAddNotification();
  const translate = useTranslate();

  const { data: templates = [] } = useGetFilterTemplates(reportBlockId);

  const isAppliedAlready = useCallback(() => {
    if (!templates) return false;

    for (const template of templates) {
      const appliedConditions = appliedFilters.map((filter) => ({
        member: filter.name,
        operator: filter.operator,
        values: filter.values,
      }));

      const isSameTemplate = isEqual(template.conditions.and, appliedConditions);
      if (isSameTemplate) {
        return true;
      }
    }
  }, [appliedFilters, templates]);

  const onClick = useCallback(() => {
    const hasFiltersApplied = Object.keys(getAppliedFilterValues(true)).length > 0;
    if (!hasFiltersApplied) {
      showErrorNotification(2000, translate('all.filterTemplates.errors.noFiltersApplied'));
      return;
    }

    if (isAppliedAlready()) {
      showErrorNotification(2000, translate('all.filterTemplates.errors.existsAlready'));
      return;
    }

    setIsSaveDataTemplateModalOpen(true);
  }, [getAppliedFilterValues, isAppliedAlready, showErrorNotification, translate]);

  const closeModal = useCallback(() => {
    setIsSaveDataTemplateModalOpen(false);
  }, []);

  return (
    <>
      {isSaveDataTemplateModalOpen && (
        <SaveDataTemplateModal isOpen={isSaveDataTemplateModalOpen} onClose={closeModal} />
      )}

      <SaveTemplateBtnComponent
        tooltip={!hasCreatePermission && translate('tooltips.contributorRole')}
        onClick={onClick}
        disabled={!hasCreatePermission}
        isAddFilterBtnVisible={isAddFilterBtnVisible}
      />
    </>
  );
};
