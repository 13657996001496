import { IconProps } from '.';

export const SplitTableLineDiagram = ({ className, color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.25 12.75L2.25 5.25C2.25 3.59315 3.59315 2.25 5.25 2.25H8.25V15.75H5.25C3.59315 15.75 2.25 14.4069 2.25 12.75ZM9.75 15.75H12.75C14.4069 15.75 15.75 14.4069 15.75 12.75V5.25C15.75 3.59315 14.4069 2.25 12.75 2.25H9.75L9.75 15.75ZM5.25 17.25C2.76472 17.25 0.75 15.2353 0.75 12.75L0.75 5.25C0.75 2.76472 2.76472 0.75 5.25 0.75H12.75C15.2353 0.75 17.25 2.76472 17.25 5.25V12.75C17.25 15.2353 15.2353 17.25 12.75 17.25H5.25Z'
        fill={color}
      />
      <path
        d='M3.75 5.99951C3.75 5.5853 4.08579 5.24951 4.5 5.24951H8.25V6.74951H4.5C4.08579 6.74951 3.75 6.41373 3.75 5.99951Z'
        fill={color}
      />
      <path
        opacity='0.5'
        d='M3.75 9C3.75 8.58579 4.08579 8.25 4.5 8.25H8.25V9.75H4.5C4.08579 9.75 3.75 9.41421 3.75 9Z'
        fill={color}
      />
      <path
        opacity='0.25'
        d='M3.75 11.999C3.75 11.5848 4.08579 11.249 4.5 11.249H8.25V12.749H4.5C4.08579 12.749 3.75 12.4132 3.75 11.999Z'
        fill={color}
      />
      <path opacity='0.5' d='M9 10.4995L12 13.4995L15 7.49951H16.5' stroke={color} strokeWidth='0.75' />
      <path opacity='0.5' d='M9 4.49951L12 7.49951L15 10.4995H16.5' stroke={color} strokeWidth='0.75' />
    </svg>
  );
};
