import { useTranslate } from '@va/localization';
import { UserRole } from '@va/types/website';
import { TooltipWrapper } from '@va/ui/tooltips';
import { useIsHovering } from '@va/util/hooks';
import classNames from 'classnames';
import CountCircle from 'common/components/misc/CountCircle';
import UserAvatar from 'common/components/misc/user-avatar/UserAvatar';
import { useRef } from 'react';

type ContributorsListProps = {
  activeContributors: { fullName: string }[];
  pendingContributorsCount: number;
  onClick: () => void;
  userRole: UserRole;
};

export default function ContributorsList({
  activeContributors,
  pendingContributorsCount,
  onClick,
  userRole,
}: ContributorsListProps) {
  const translate = useTranslate();

  const visibleItems = activeContributors.slice(0, 6);
  const remainingItemsNumber = activeContributors.length - visibleItems.length;

  const listContainerRef = useRef<HTMLDivElement>(null);
  const isHovering = useIsHovering(listContainerRef);
  const isContributor = userRole !== UserRole.OWNER;

  return (
    <TooltipWrapper disabled={!isContributor} content={translate('tooltips.contributorRole')}>
      <div className='flex justify-between items-center w-full cursor-pointer'>
        <div
          ref={listContainerRef}
          className='flex w-full'
          onClick={() => {
            if (isContributor) return;
            onClick();
          }}
        >
          {visibleItems.map((item, index) => (
            <div
              key={item.fullName}
              className={classNames('transition-all duration-300', {
                '!transform-none': !isHovering || remainingItemsNumber !== 0,
                '-ml-15px': index !== 0,
              })}
              style={{ zIndex: index, transform: `translateX(${index * 7}px)` }}
            >
              {<UserAvatar text={item.fullName} isContributor={isContributor} />}
            </div>
          ))}
          {remainingItemsNumber > 0 && (
            <CountCircle count={remainingItemsNumber} className='bg-magenta-pure -ml-15px z-10' />
          )}
        </div>
        {pendingContributorsCount > 0 && (
          <CountCircle count={pendingContributorsCount} className='bg-gray-dustySolid ' />
        )}
      </div>
    </TooltipWrapper>
  );
}
