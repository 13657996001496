import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

/** @deprecated */
export function withGoToWebsiteSettings(WrappedComponent) {
  class WithGoToWebsiteSettings extends PureComponent {
    constructor(props) {
      super(props);

      this.goToWebsiteSettings = this.goToWebsiteSettings.bind(this);
    }

    goToWebsiteSettings() {
      this.props.history.push('/settings');
    }

    render() {
      return <WrappedComponent goToWebsiteSettings={this.goToWebsiteSettings} {...this.props} />;
    }
  }

  WithGoToWebsiteSettings.propTypes = {
    history: PropTypes.object.isRequired,
  };

  return withRouter(WithGoToWebsiteSettings);
}
