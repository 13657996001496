import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';

import { apiStatus } from '@va/constants';
import { resetRequestStatus } from '@va/dashboard/actions/api';
import { closeModal } from '@va/dashboard/actions/ui';
import ModalWrapper from '@va/dashboard/shared/modals/ModalWrapper';
import { getWebsiteTimezone } from '@va/dashboard/selectors/core';
import { isModalOpen } from '@va/dashboard/selectors/ui';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import * as Actions from '@va/standalone/shared/actions';
import * as Selectors from '@va/standalone/shared/selectors';
import { modalName } from '@va/standalone/shared/constants';
import CancelSubscriptionComponent from './CancelSubscriptionComponent';

class CancelSubscriptionContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onCloseModal() {
    if (this.props.cancelSubscriptionStatus === apiStatus.IN_PROGRESS) {
      return;
    }

    this.props.resetRequestStatus('cancelSubscription');
    this.props.closeModal();
    this.props.destroy();
  }

  render() {
    return (
      <ModalWrapper
        closeModal={this.onCloseModal}
        isOpen={this.props.isModalOpen}
        showCloseButton={!(this.props.cancelSubscriptionStatus === apiStatus.IN_PROGRESS)}
      >
        <CancelSubscriptionComponent {...this.props} closeModal={this.onCloseModal} />
      </ModalWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cancelSubscriptionStatus: Selectors.standalone.Api.getApiRequestStatus(state, 'cancelSubscription'),
    activeSubscription: Selectors.standalone.App.getLastCreatedSubscription(state),
    timezone: getWebsiteTimezone(state),
    isModalOpen: isModalOpen(state, modalName.CANCEL_SUBSCRIPTION),
  };
};

const mapDispatchToProps = {
  onSubmit: (data) => Actions.standalone.Api.cancelSubscription(data),
  resetRequestStatus: resetRequestStatus,
  closeModal: () => closeModal(modalName.CANCEL_SUBSCRIPTION),
};

CancelSubscriptionContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  cancelSubscriptionStatus: PropTypes.oneOf(Object.values(apiStatus)).isRequired,
  closeModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  resetRequestStatus: PropTypes.func.isRequired,
  destroy: PropTypes.func.isRequired,
};
const ChangeEmailForm = reduxForm({
  form: 'cancelSubscriptionForm',
  getFormState: (state) => Selectors.standalone.getFormState(state),
})(CancelSubscriptionContainer);

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(ChangeEmailForm));
