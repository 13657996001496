import classNames from 'classnames';
import { IconProps } from '.';

export const CreditCardIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='22'
      height='16'
      viewBox='0 0 22 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.25 2H17.75C18.9926 2 20 3.00736 20 4.25H2C2 3.00736 3.00736 2 4.25 2ZM0.5 5.75V4.25C0.5 2.17893 2.17893 0.5 4.25 0.5H17.75C19.8211 0.5 21.5 2.17893 21.5 4.25V5.75V7.25V8.75V11.75C21.5 13.8211 19.8211 15.5 17.75 15.5H4.25C2.17893 15.5 0.5 13.8211 0.5 11.75V8.75V7.25V5.75ZM20 5.75V7.25H2V5.75H20ZM2 11.75V8.75H20V11.75C20 12.9926 18.9926 14 17.75 14H4.25C3.00736 14 2 12.9926 2 11.75ZM4.625 12.5C5.24632 12.5 5.75 11.9963 5.75 11.375C5.75 10.7537 5.24632 10.25 4.625 10.25C4.00368 10.25 3.5 10.7537 3.5 11.375C3.5 11.9963 4.00368 12.5 4.625 12.5Z'
        fill={color ?? 'black'}
      />
    </svg>
  );
};


