import { GrayGradientDivider } from '@va/ui/design-system';
import classNames from 'classnames';

export const ChoiceSeparator = ({
  label,
  className,
  labelClassName,
}: {
  label: string;
  className?: string;
  labelClassName?: string;
}) => {
  return (
    <div className={classNames('flex flex-row gap-30 items-center w-full my-24', className)}>
      <GrayGradientDivider className='flex-1 rotate-180' />
      <div className={classNames('text-12 text-gray-devil leading-6', labelClassName)}>{label}</div>
      <GrayGradientDivider className='flex-1' />
    </div>
  );
};
