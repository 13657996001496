import { useAddNotification } from '@va/dashboard/util-hooks';
import { getAgencyLogoUrl } from '@va/http-client';
import { getAgencyUi } from '@va/standalone/shared/actions';
import { getUserId } from '@va/standalone/shared/helpers';
import { getApiRequest } from '@va/standalone/shared/selectors';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeleteAgencyLogo, useEditAgencyUi } from '../white-label-customization-apiClients';

const useUploadLogo = () => {
  const dispatch = useDispatch();
  const userId = getUserId();
  const { data: agencyUi } = useSelector((state) => getApiRequest(state, 'getAgencyUi'))?.toJS() ?? {};
  const { id: agencyId, customUrl, logoId: currentLogoId, logoAlt } = agencyUi || {};
  const {
    isLoading: isEditAgencyUiRequestLoading,
    isSucceeded: editAgencyUiSuccess,
    error: editAgencyUiError,
    execute: executeEditAgencyUi,
  } = useEditAgencyUi();
  const {
    execute: deleteAgencyLogo,
    isSucceeded: deleteAgencyLogoSuccess,
    error: deleteAgencyLogoError,
  } = useDeleteAgencyLogo();
  const { showSuccessNotification, showErrorNotification } = useAddNotification();

  const isCustomisationEnabled = useMemo(() => !!customUrl, [customUrl]);

  const agencyLogo = useMemo(() => {
    if (!userId || !agencyId || !currentLogoId) return '';
    return getAgencyLogoUrl(userId, agencyId);
  }, [agencyId, userId, currentLogoId]);

  useEffect(() => {
    if (editAgencyUiSuccess || deleteAgencyLogoSuccess) {
      dispatch(getAgencyUi());
      showSuccessNotification();
    }
    if (editAgencyUiError || deleteAgencyLogoError) {
      showErrorNotification();
    }
  }, [
    editAgencyUiSuccess,
    editAgencyUiError,
    dispatch,
    showSuccessNotification,
    showErrorNotification,
    deleteAgencyLogoSuccess,
    deleteAgencyLogoError,
  ]);

  return {
    agencyId,
    isCustomisationEnabled,
    agencyUi,
    agencyLogo,
    executeEditAgencyUi,
    isEditAgencyUiRequestLoading,
    deleteAgencyLogo,
    logoAlt,
    currentLogoId,
  };
};

export default useUploadLogo;
