import { apiStatus, modalName } from '@va/constants';
import { closeModal } from '@va/dashboard/actions/ui';
import { isModalOpen } from '@va/dashboard/selectors/ui';
import ModalWrapper from '@va/dashboard/shared/modals/ModalWrapper';
import LoadingPopUpCard from '@va/deprecated/components/PopUpCard/card-types/LoadingCard';
import MessageCard from '@va/deprecated/components/PopUpCard/card-types/MessageCard';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import BackIcon from '@va/images/BackInCircleIcon.svg?react';
import CloseIcon from '@va/images/CloseInCircleIcon.svg?react';
import CheckMarkIcon from '@va/images/GreenCheckmarkIcon.svg?react';
import { startGoogleImporter } from '@va/standalone/shared/actions';
import { getApiRequest, getApiRequestStatus } from '@va/standalone/shared/selectors';
import { Button } from '@va/ui/design-system';
import { renderIf } from '@va/util/helpers';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';

const VIEWS = {
  ACCOUNT: 'account',
  PROPERTY: 'property',
  VIEW: 'view',
};

const SelectOptions = ({ selectedId, onChange, options, activeView }) => {
  if (!options) return null;
  return (
    options &&
    options.map((item) => {
      const isSelected = item.get('id') === selectedId;
      return (
        <div
          key={item.get('id')}
          className={classNames('p-5 flex justify-between hover:bg-white-smoke rounded-md cursor-pointer', {
            'bg-white-smoke': isSelected,
          })}
          onClick={() => onChange(activeView, item.get('id'))}
        >
          <div>
            <div>{item.get('name')}</div>
            <div className='text-xs'>{item.get('id')}</div>
          </div>
          {isSelected && <CheckMarkIcon />}
        </div>
      );
    })
  );
};

const ModalHeader = ({ onBackClick, closeModal, title, activeView }) => {
  return (
    <div className='p-5 flex justify-between border-solid border-b-2 border-white-smoke'>
      <BackIcon
        className={classNames('cursor-pointer', {
          invisible: activeView === VIEWS.ACCOUNT,
        })}
        onClick={onBackClick}
      />
      <div className='text-3xl'>{title}</div>
      <CloseIcon className='cursor-pointer' onClick={closeModal} />
    </div>
  );
};

const ModalFooter = (props) => {
  return <div className='p-5 flex flex-row-reverse bg-white-smoke text-right'>{props.children}</div>;
};

class GoogleImport extends PureComponent {
  constructor(props) {
    super(props);
    this.initialValues = {
      activeView: VIEWS.ACCOUNT,
      activeOptions: this.props.allViews || null,
      selected: {
        [VIEWS.ACCOUNT]: null,
        [VIEWS.PROPERTY]: null,
        [VIEWS.VIEW]: null,
      },
    };
    this.state = this.initialValues;
  }

  handleChange = (activeView, id) => {
    return this.setState({
      selected: {
        ...this.state.selected,
        [activeView]: id,
      },
    });
  };

  handleBackClick = () => {
    switch (this.state.activeView) {
      case VIEWS.ACCOUNT:
        return null;
      case VIEWS.PROPERTY:
        return this.setState({
          activeView: VIEWS.ACCOUNT,
          activeOptions: this.props.allViews,
        });
      case VIEWS.VIEW:
        return this.setState({
          activeView: VIEWS.PROPERTY,
          activeOptions: this.props.allViews
            .find((item) => item.get('id') === this.state.selected[VIEWS.ACCOUNT])
            .get('properties')
            .filter((item) => item.get('views')),
        });
      default:
        return null;
    }
  };

  handleOnContinueClick = () => {
    switch (this.state.activeView) {
      case VIEWS.ACCOUNT:
        return (
          this.state.selected[VIEWS.ACCOUNT] &&
          this.setState({
            activeView: VIEWS.PROPERTY,
            activeOptions: this.props.allViews
              .find((item) => item.get('id') === this.state.selected[VIEWS.ACCOUNT])
              .get('properties')
              .filter((item) => item.get('views')),
          })
        );
      case VIEWS.PROPERTY:
        return (
          this.state.selected[VIEWS.PROPERTY] &&
          this.setState({
            activeView: VIEWS.VIEW,
            activeOptions: this.state.activeOptions
              .find((item) => item.get('id') === this.state.selected[VIEWS.PROPERTY])
              .get('views'),
          })
        );
      case VIEWS.VIEW:
        if (this.state.selected[VIEWS.VIEW]) {
          this.props.startGoogleImporter(this.state.selected[VIEWS.VIEW]);
          this.props.closeModal();
        }
        break;
      default:
        return null;
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isModalOpen && !this.props.isModalOpen) {
      this.setState(this.initialValues);
    }
  }

  render() {
    const { translate, inProgress, apiError, allViews, isModalOpen, closeModal } = this.props;
    const hasData = allViews && allViews.size > 0;

    const renderList = renderIf(!inProgress && !apiError);
    const renderErrorMessage = renderIf(apiError);
    const renderLoading = renderIf(inProgress);

    return (
      <ModalWrapper isOpen={isModalOpen} showCloseButton={false}>
        <Fragment>
          {renderLoading(<LoadingPopUpCard />)}
          {renderErrorMessage(<MessageCard modalName={modalName.generalError} onClose={closeModal} apiError />)}
          {renderList(
            <Fragment>
              <ModalHeader
                title={translate(`modal.googleImport.select.${this.state.activeView}.title`)}
                closeModal={closeModal}
                onBackClick={this.handleBackClick}
                activeView={this.state.activeView}
              />
              <div
                className='p-5 overflow-hidden overflow-y-auto overflow-x-hidden'
                style={{ maxHeight: 'calc(100vh - 300px' }}
              >
                {hasData ? (
                  <SelectOptions
                    onChange={this.handleChange}
                    options={this.state.activeOptions || allViews}
                    activeView={this.state.activeView}
                    selectedId={this.state.selected[this.state.activeView]}
                  />
                ) : (
                  <div className=''>{translate('modal.googleImport.noData')}</div>
                )}
              </div>
              <ModalFooter>
                <>
                  {hasData && (
                    <Button
                      text={translate('button.continue')}
                      onClick={this.handleOnContinueClick}
                      disabled={!this.state.selected[this.state.activeView]}
                    />
                  )}
                  <div className='px-10 mt-auto mb-auto cursor-pointer' onClick={closeModal}>
                    {translate('button.cancel')}
                  </div>
                </>
              </ModalFooter>
            </Fragment>,
          )}
        </Fragment>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const getGoogleImporterViewsStatus = getApiRequestStatus(state, 'getGoogleImporterViews');
  return {
    isModalOpen: isModalOpen(state, modalName.googleImport),
    inProgress: getGoogleImporterViewsStatus === apiStatus.IN_PROGRESS,
    apiError: getGoogleImporterViewsStatus === apiStatus.FAILED,
    allViews: getApiRequest(state, 'getGoogleImporterViews').get('allViews'),
  };
};

const mapDispatchToProps = {
  closeModal: () => closeModal(modalName.googleImport),
  startGoogleImporter: startGoogleImporter,
};

GoogleImport.propTypes = {
  // connect
  closeModal: PropTypes.func.isRequired,
  startGoogleImporter: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  inProgress: PropTypes.bool.isRequired,
  apiError: PropTypes.bool.isRequired,
  allViews: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(GoogleImport));
