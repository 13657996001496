export const ManageContributorsIcon = () => {
  return (
    <svg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M14 9H17M17 9V6M17 9H20M17 9V12' stroke='#666666' strokeWidth='2' />
      <path
        d='M1 15C1 12.2386 3.23858 10 6 10V10C8.76142 10 11 12.2386 11 15V16H1V15Z'
        stroke='#666666'
        strokeWidth='2'
      />
      <circle cx='6' cy='6' r='4' stroke='#666666' strokeWidth='2' />
    </svg>
  );
};
