import PropTypes from 'prop-types';
import OptionsHeader from './OptionsHeader';

/** @deprecated */
export const OptionsSection = ({ children, title, first }) => {
  return (
    <>
      <OptionsHeader first={first}>{title}</OptionsHeader>
      <div className='py-2'>{children}</div>
    </>
  );
};

OptionsSection.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  first: PropTypes.bool,
};
