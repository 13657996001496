import { CheckMarkWithCircle } from '@va/svg-visa-icons';
import { fontWeights, Paragraph, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { useCheckOverflow } from '@va/util/hooks';
import classNames from 'classnames';
import { memo, useRef } from 'react';
import { ListType } from './QuickNavigator';

type QuickNavigatorListItemType = {
  item: ListType;
  activeElementId: string;
};
const QuickNavigatorListItem = ({ item, activeElementId }: QuickNavigatorListItemType) => {
  const titleRef = useRef(null);
  const isTitleOverflow = useCheckOverflow(titleRef);

  return (
    <TooltipWrapper disabled={!isTitleOverflow} content={item.title}>
      <a
        href={`#${item.id}`}
        className={classNames('flex items-center justify-between p-3 rounded-12 cursor-pointer ', {
          'bg-white text-primary': activeElementId === item.id,
          'hover:bg-white-smoke': activeElementId !== item.id,
        })}
        data-testid={`nav-link-${item.id}`}
      >
        <Paragraph className={'truncate w-full pointer-events-none'} ref={titleRef} weight={fontWeights.semibold}>
          {item.title}
        </Paragraph>
        <div className='flex whitespace-nowrap gap-1 ml-9 pointer-events-none'>
          {item.subTitle && (
            <Paragraph
              className='pointer-events-none'
              size={paragraphSizes.tiny}
              weight={fontWeights.medium}
              colorClassName={'text-devil-gray'}
            >
              {item.subTitle}
            </Paragraph>
          )}
          {activeElementId === item.id && <CheckMarkWithCircle color={'var(--color-primary)'} />}
        </div>
      </a>
    </TooltipWrapper>
  );
};

export default memo(QuickNavigatorListItem);
