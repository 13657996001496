import { useTranslate } from '@va/localization';
import { SectionHeader } from '@va/ui/components/white-label-customisation';
import { TabbedButton } from '@va/ui/design-system';
import { useMemo, useState } from 'react';
import CustomSubdomainContainer from './CustomSubdomainContainer';

const CustomDomainsSection = () => {
  const translate = useTranslate();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const domainTabs = useMemo(
    () => [
      {
        label: translate('customDomains.tabs.subdomain.title'),
        tooltip: translate('customDomains.tabs.subdomain.tooltip'),
        value: 0,
        className: '!w-full !h-54 sm-initial:flex-1',
      },
      {
        label: translate('customDomains.tabs.customDomain.title'),
        tooltip: translate('customDomains.tabs.customDomain.tooltip'),
        value: 1,
        className: '!w-full !h-54 sm-initial:flex-1',
        disabled: true,
      },
    ],
    [translate],
  );

  return (
    <div className='flex flex-col gap-5'>
      <SectionHeader
        title={translate('whiteLabel.customization.customDomain.title')}
        description={translate('whiteLabel.customization.customDomain.description')}
      />
      <TabbedButton
        className='!h-auto flex-col sm-initial:flex-row'
        buttonOptions={domainTabs}
        selectedValue={selectedTabIndex}
        onChange={setSelectedTabIndex}
      />
      {selectedTabIndex === 0 && <CustomSubdomainContainer />}
      {/* TODO uncomment and continue implementation when functionality is concrete */}
      {/* {selectedTabIndex === 1 && <CustomDomain subdomain='' onChangeDomain={() => {}} />} */}
    </div>
  );
};

export default CustomDomainsSection;
