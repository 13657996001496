import {
  useEventsCount,
  useFunnelsCount,
  useHeatmapsCount,
  useLeftVisitsCount,
  usePollsCount,
  useRecordingsCount,
  useSurveysCount,
} from '@va/dashboard/api-client/FeatureCards';
import { getApiRequest } from '@va/dashboard/selectors/api';
import { useMutateOnRefresh } from '@va/dashboard/util-hooks';
import { is3asApp } from '@va/util/helpers';
import { createContext, PropsWithChildren, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';

type LimitType = {
  limit: number;
};

type FeatureCardDataType = {
  count: number;
  limit: number;
};

export type LimitDataType = {
  eventTracking?: LimitType;
  funnels?: LimitType;
  heatmaps?: LimitType;
  polls?: LimitType;
  recordings?: LimitType;
  surveys?: LimitType;
  pageVisits?: LimitType;
  isPayable?: boolean;
  unlimitedVoucher?: unknown;
  wixCycleType?: number;
  wixPermissionPack?: number;
  [key: string]: unknown;
};

type ContextDataType = {
  isRefreshing: boolean;
  limits?: LimitDataType;
  polls: FeatureCardDataType & { mutate: () => void };
  eventTracking: FeatureCardDataType;
  recordings: FeatureCardDataType & { mutate: () => void };
  surveys: FeatureCardDataType & { mutate: () => void };
  heatmaps: FeatureCardDataType & { mutate: () => void };
  funnels: FeatureCardDataType & { mutate: () => void };
  pageVisits: FeatureCardDataType;
};

const FeatureCounterContext = createContext<ContextDataType>({} as ContextDataType);

type Features = {
  surveys: boolean;
  polls: boolean;
  funnels: boolean;
  heatmaps: boolean;
  events: boolean;
  recordings: boolean;
  visits: boolean;
};

const FeatureCounterContextProvider: React.FC<PropsWithChildren<{ features?: Features }>> = ({
  children,
  features,
}) => {
  const limits: any = useSelector((state: {}) => getApiRequest(state, 'getFeaturePermissions'));

  const { data: surveysCount, mutate: mutateSurveysCount } = useSurveysCount(features?.surveys);
  const { data: pollsCount, mutate: mutatePollsCount } = usePollsCount(features?.polls);
  const { data: funnelsCount, mutate: mutateFunnelsCount } = useFunnelsCount(features?.funnels);
  const { data: heatmapsCount, mutate: mutateHeatmapsCount } = useHeatmapsCount(features?.heatmaps);
  const { data: eventsCount, mutate: mutateEventsCount } = useEventsCount(features?.events);
  const { data: recordingsCount, mutate: mutateRecordingsCount } = useRecordingsCount(features?.recordings);
  const { data: visitsCount } = useLeftVisitsCount(features?.visits);

  const mutateData = useCallback(() => {
    mutatePollsCount();
    mutateFunnelsCount();
    mutateSurveysCount();
    mutateHeatmapsCount();
    mutateEventsCount();
    mutateRecordingsCount();
  }, [
    mutateEventsCount,
    mutateFunnelsCount,
    mutateHeatmapsCount,
    mutatePollsCount,
    mutateRecordingsCount,
    mutateSurveysCount,
  ]);

  const { isManualRefreshing } = useMutateOnRefresh(mutateData);

  // VISA-9571 Set 3as limits to Infinity as they are stp-based instead of plan-based as for other platforms
  if (is3asApp()) {
    return (
      <FeatureCounterContext.Provider
        value={{
          isRefreshing: isManualRefreshing,
          polls: {
            count: pollsCount ?? 0,
            limit: Infinity,
            mutate: mutatePollsCount,
          },
          eventTracking: {
            count: eventsCount ?? 0,
            limit: Infinity,
          },
          recordings: {
            count: recordingsCount ?? 0,
            limit: Infinity,
            mutate: mutateRecordingsCount,
          },
          heatmaps: { count: heatmapsCount ?? 0, limit: Infinity ?? 0, mutate: mutateHeatmapsCount },
          surveys: {
            count: surveysCount ?? 0,
            limit: Infinity,
            mutate: mutateSurveysCount,
          },
          funnels: {
            count: funnelsCount ?? 0,
            limit: Infinity,
            mutate: mutateFunnelsCount,
          },
          pageVisits: {
            count: visitsCount ?? 0,
            limit: Infinity,
          },
        }}
      >
        {children}
      </FeatureCounterContext.Provider>
    );
  }

  return (
    <FeatureCounterContext.Provider
      value={{
        isRefreshing: isManualRefreshing,
        polls: {
          count: pollsCount ?? 0,
          limit: limits?.polls?.limit ?? 0,
          mutate: mutatePollsCount,
        },
        eventTracking: {
          count: eventsCount ?? 0,
          limit: limits?.eventTracking?.limit ?? 0,
        },
        recordings: {
          count: recordingsCount ?? 0,
          limit: limits?.recordings?.limit ?? 0,
          mutate: mutateRecordingsCount,
        },
        heatmaps: { count: heatmapsCount ?? 0, limit: limits?.heatmaps?.limit ?? 0, mutate: mutateHeatmapsCount },
        surveys: {
          count: surveysCount ?? 0,
          limit: limits?.surveys?.limit ?? 0,
          mutate: mutateSurveysCount,
        },
        funnels: {
          count: funnelsCount ?? 0,
          limit: limits?.funnels?.limit ?? 0,
          mutate: mutateFunnelsCount,
        },
        pageVisits: {
          count: visitsCount ?? 0,
          limit: limits?.visits?.limit ?? 0,
        },
      }}
    >
      {children}
    </FeatureCounterContext.Provider>
  );
};

const useFeatureCounterContext = () => {
  return useContext(FeatureCounterContext);
};

export { FeatureCounterContextProvider, useFeatureCounterContext };
