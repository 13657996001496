import { modalName as commonModalName } from '@va/constants';
import { closeModal, openModal } from '@va/dashboard/actions/ui';
import { LocalizationContext } from '@va/localization';
import { modalName as standaloneModalName } from '@va/standalone/shared/constants';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

/** @deprecated */
class Translation extends PureComponent {
  constructor(props) {
    super(props);
    this.handlePartialOnClick = this.handlePartialOnClick.bind(this);
  }

  overrideOnClick(item) {
    //handle opening custom modals
    if (item && typeof item === 'object') {
      const modalNameToBeOpened = item.getIn(['props', 'openmodalname']);
      if (
        Object.keys(commonModalName).includes(modalNameToBeOpened) ||
        Object.keys(standaloneModalName).includes(modalNameToBeOpened)
      ) {
        return item.setIn(['props', 'onClick'], () => this.props.openModal(modalNameToBeOpened));
      }
      const modalNameToBeClosed = item.getIn(['props', 'closemodalname']);
      if (
        Object.keys(commonModalName).includes(modalNameToBeClosed) ||
        Object.keys(standaloneModalName).includes(modalNameToBeClosed)
      ) {
        return item.setIn(['props', 'onClick'], () => this.props.closeModal(modalNameToBeClosed));
      }
    }
    return item;
  }

  handlePartialOnClick(translatedText) {
    let clonedTranslatedText;
    if (translatedText && typeof translatedText === 'object') {
      if (translatedText.length > 1) {
        clonedTranslatedText = Immutable.fromJS(translatedText).map((item) => this.overrideOnClick(item));
      } else {
        clonedTranslatedText = this.overrideOnClick(Immutable.fromJS(translatedText));
      }
      clonedTranslatedText = clonedTranslatedText.toJS();
    }

    return clonedTranslatedText || translatedText;
  }

  render() {
    const { translate } = this.context;
    const { translationKey, parameters, className } = this.props;
    return (
      <span onClick={this.props.onClick} className={className}>
        {this.handlePartialOnClick(translate(translationKey, parameters))}
      </span>
    );
  }
}

const mapDispatchToProps = {
  openModal: openModal,
  closeModal: closeModal,
};

Translation.contextType = LocalizationContext;

export default connect(null, mapDispatchToProps)(Translation);

Translation.propTypes = {
  translationKey: PropTypes.string.isRequired,
  parameters: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
  //Connected
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};
