import { IconProps } from '.';

export const InputIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 5.25027C1.5 4.00763 2.50736 3.00027 3.75 3.00027V15.0003C2.50736 15.0003 1.5 13.9929 1.5 12.7503V5.25027ZM5.25 1.50027H3.75C1.67893 1.50027 0 3.17921 0 5.25027V12.7503C0 14.8213 1.67893 16.5003 3.75 16.5003H5.25H14.25C16.3211 16.5003 18 14.8213 18 12.7503V5.25027C18 3.17921 16.3211 1.50027 14.25 1.50027H5.25ZM14.25 15.0003H5.25V9.75027H10.1893L8.46967 11.4699L9.53033 12.5306L12.5303 9.5306L13.0607 9.00027L12.5303 8.46994L9.53033 5.46994L8.46967 6.53061L10.1893 8.25027H5.25V3.00027H14.25C15.4926 3.00027 16.5 4.00763 16.5 5.25027V12.7503C16.5 13.9929 15.4926 15.0003 14.25 15.0003Z'
        fill={color}
      />
    </svg>
  );
};
