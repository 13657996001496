import classNames from 'classnames';
import { IconProps } from '.';

export const WhiteLabelCustomizationIcon = ({ className = '', color = 'black' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.00003 16.5L9 9L9.00003 1.5C9.00002 1.5 9.00001 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5C9.00001 16.5 9.00002 16.5 9.00003 16.5ZM9.00004 0C9.00003 0 9.00001 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C9.00001 18 9.00003 18 9.00004 18C9.62135 18 10.2279 17.937 10.8138 17.8172C14.915 16.978 18 13.3493 18 9C18 4.02944 13.9706 0 9.00004 0Z'
        fill={color}
      />
    </svg>
  );
};
