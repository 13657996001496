// TODO Razvan add types to props
const Flag4x3 = (props: any) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 640 480'
      style={{ enableBackground: 'new 0 0 400 300' }}
      xmlSpace='preserve'
    >
      <rect fill='#FFFFFF' width='640' height='480' />
      <g>
        <polygon
          fill='#F5F5F5'
          points='0,310.4 0,344.2 99.2,344.2 0,409.1 0,435 140.2,344.2 202.1,344.2 0,475.2 0,480 76.2,480 
            235.8,375.5 235.8,480 257.6,480 257.6,480 269.4,480 269.4,310.4'
        />
        <g>
          <rect y='256' fill='#F5F5F5' width='640' height='54.4' />
          <g>
            <g>
              <polygon fill='#41479B' points='99.2,344.2 0,344.2 0,409.1 ' />
              <polygon fill='#41479B' points='540.8,344.2 640,409.1 640,344.2 ' />
            </g>
            <g>
              <polygon
                fill='#FF4B55'
                points='269.4,209.3 0,209.3 0,310.4 269.4,310.4 269.4,480 370.6,480 370.6,310.4 640,310.4 640,209.3 
                        370.6,209.3 				'
              />
              <polygon fill='#FF4B55' points='140.2,344.2 0,435 0,475.2 202.1,344.2' />
            </g>
          </g>
          <g>
            <rect fill='#FF4B55' width='640' height='49.3' />
            <rect y='208.2' fill='#FF4B55' width='640' height='48' />
            <rect y='104' fill='#FF4B55' width='640' height='49.3' />
            <rect fill='#41479B' width='344.3' height='256' />
            <g>
              <path
                fill='#F5F5F5'
                d='M44,25.8l2.7,8H55c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1H39l2.7-8C42.2,24.6,43.7,24.6,44,25.8z'
              />
              <path
                fill='#F5F5F5'
                d='M44,92.2l2.7,8H55c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1H39l2.7-8C42.2,91.2,43.7,91.2,44,92.2z'
              />
              <path
                fill='#F5F5F5'
                d='M44,165.8l2.7,8H55c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1H39l2.7-8C42.2,164.8,43.7,164.8,44,165.8z'
              />
              <path
                fill='#F5F5F5'
                d='M76.3,57.3l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5.1c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C74.6,56.2,76,56.2,76.3,57.3z'
              />
              <path
                fill='#F5F5F5'
                d='M76.3,130.9l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.7,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.9-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C74.6,129.8,76,129.8,76.3,130.9z'
              />
              <path
                fill='#F5F5F5'
                d='M76.3,200.8l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C74.6,199.8,76,199.8,76.3,200.8z'
              />
              <path
                fill='#F5F5F5'
                d='M108.6,25.8l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.7,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C106.9,24.6,108.3,24.6,108.6,25.8z'
              />
              <path
                fill='#F5F5F5'
                d='M108.6,92.2l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.7,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C106.9,91.2,108.3,91.2,108.6,92.2z'
              />
              <path
                fill='#F5F5F5'
                d='M108.6,165.8l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C106.9,164.8,108.3,164.8,108.6,165.8z'
              />
              <path
                fill='#F5F5F5'
                d='M141,57.3l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5.1c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C139.2,56.2,140.6,56.2,141,57.3z'
              />
              <path
                fill='#F5F5F5'
                d='M141,130.9l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C139.2,129.8,140.6,129.8,141,130.9z'
              />
              <path
                fill='#F5F5F5'
                d='M141,200.8l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C139.2,199.8,140.6,199.8,141,200.8z'
              />
              <path
                fill='#F5F5F5'
                d='M173.3,25.8l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C171.4,24.6,172.8,24.6,173.3,25.8z'
              />
              <path
                fill='#F5F5F5'
                d='M173.3,92.2l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C171.4,91.2,172.8,91.2,173.3,92.2z'
              />
              <path
                fill='#F5F5F5'
                d='M173.3,165.8l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C171.4,164.8,172.8,164.8,173.3,165.8z'
              />
              <path
                fill='#F5F5F5'
                d='M205.4,57.3l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C203.7,56.2,205.1,56.2,205.4,57.3z'
              />
              <path
                fill='#F5F5F5'
                d='M205.4,130.9l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C203.7,129.8,205.1,129.8,205.4,130.9z'
              />
              <path
                fill='#F5F5F5'
                d='M205.4,200.8l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C203.7,199.8,205.1,199.8,205.4,200.8z'
              />
              <path
                fill='#F5F5F5'
                d='M237.8,25.8l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C236,24.6,237.4,24.6,237.8,25.8z'
              />
              <path
                fill='#F5F5F5'
                d='M237.8,92.2l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C236,91.2,237.4,91.2,237.8,92.2z'
              />
              <path
                fill='#F5F5F5'
                d='M237.8,165.8l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C236,164.8,237.4,164.8,237.8,165.8z'
              />
              <path
                fill='#F5F5F5'
                d='M270.1,57.3l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C268.3,56.2,269.8,56.2,270.1,57.3z'
              />
              <path
                fill='#F5F5F5'
                d='M270.1,130.9l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C268.3,129.8,269.8,129.8,270.1,130.9z'
              />
              <path
                fill='#F5F5F5'
                d='M270.1,200.8l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C268.3,199.8,269.8,199.8,270.1,200.8z'
              />
              <path
                fill='#F5F5F5'
                d='M302.4,25.8l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C300.6,24.6,302.1,24.6,302.4,25.8z'
              />
              <path
                fill='#F5F5F5'
                d='M302.4,92.2l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C300.6,91.2,302.1,91.2,302.4,92.2z'
              />
              <path
                fill='#F5F5F5'
                d='M302.4,165.8l2.7,8h8.3c1.1,0,1.6,1.4,0.6,2.1l-6.7,5l2.6,8c0.3,1-0.8,1.9-1.8,1.3l-6.9-5l-6.9,5
                        c-1,0.6-2.1-0.2-1.8-1.3l2.6-8l-6.7-5c-0.8-0.6-0.5-2.1,0.6-2.1h8.3l2.7-8C300.6,164.8,302.1,164.8,302.4,165.8z'
              />
            </g>
            <rect y='256.2' fill='#FF4B55' width='640' height='48' />
          </g>
        </g>
        <polygon fill='#41479B' points='563.8,480 404.2,375.5 404.2,480 	' />
        <polygon fill='#41479B' points='235.8,480 235.8,375.5 76.2,480 	' />
        <polygon
          fill='#F5F5F5'
          points='398.4,480 404.2,480 404.2,375.5 563.8,480 613.6,480 404.2,344.2 466.1,344.2 640,457 640,409.1 
            540.8,344.2 640,344.2 640,304 370.6,304 370.6,480 	'
        />
        <polygon fill='#FF4B55' points='466.1,344.2 404.2,344.2 613.6,480 640,480 640,457 	' />
      </g>
    </svg>
  );
};

const Flag1x1 = (props: any) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 512 512'
      style={{ enableBackground: 'new 0 0 512 512' }}
      xmlSpace='preserve'
    >
      <rect x='0' y='0' fill='#FFFFFF' width='512' height='512' />
      <line fill='#F0F0F0' x1='512' y1='512' x2='0' y2='512' />
      <polygon fill='#EA2A0C' points='0,256 0,304 208,304 208,512 304,512 304,304 512,304 512,256.4 ' />
      <g>
        <polygon fill='#1B57AB' points='406.9,333.9 512,439 512,333.9 	' />
        <polygon fill='#0052B4' points='333.9,333.9 512,512 512,461.6 384.3,333.9 	' />
        <polygon fill='#1B57AB' points='464.6,512 333.9,381.3 333.9,512 	' />
      </g>
      <polygon fill='#F0F0F0' points='333.9,333.9 512,512 512,461.6 384.3,333.9 ' />
      <polygon fill='#EA2A0C' points='333.9,333.9 512,512 512,461.6 384.3,333.9 ' />
      <g>
        <polygon fill='#1B57AB' points='80.3,333.9 0,414.2 0,333.9 	' />
        <polygon fill='#1B57AB' points='178.1,356.6 178.1,512 22.7,512 	' />
      </g>
      <polygon fill='#EA2A0C' points='127.7,333.9 0,461.6 0,512 0,512 178.1,333.9 ' />
      <path fill='#C42126' d='M256,0c-5,0-10,0.2-15.1,0.4h30.1C266,0.2,261,0,256,0z' />
      <path fill='#E73625' d='M362,234.3' />
      <path fill='#1B57AB' d='M255.8,256L256,0.2l0,0L0,0v256' />
      <g>
        <polygon
          fill='#EFEFEF'
          points='42.9,154.7 46.9,166.8 59.5,166.8 49.3,174.2 53.1,186.2 42.9,178.8 32.6,186.2 36.6,174.2 
     26.2,166.8 39.1,166.8 	'
        />
        <polygon
          fill='#EFEFEF'
          points='42.9,197.3 46.9,209.3 59.5,209.3 49.3,216.9 53.1,229 42.9,221.4 32.6,229 36.6,216.9 26.2,209.3 
     39.1,209.3 	'
        />
        <polygon
          fill='#EFEFEF'
          points='85.4,90.9 89.4,102.9 102.1,102.9 91.9,110.3 95.7,122.4 85.4,114.9 75.2,122.4 79.2,110.3 69,102.9 
     81.6,102.9 	'
        />
        <polygon
          fill='#EFEFEF'
          points='85.4,133.4 89.4,145.5 102.1,145.5 91.9,152.9 95.7,164.9 85.4,157.5 75.2,164.9 79.2,152.9 69,145.5 
     81.6,145.5 	'
        />
        <polygon
          fill='#EFEFEF'
          points='85.4,176 89.4,188 102.1,188 91.9,195.5 95.7,207.7 85.4,200.1 75.2,207.7 79.2,195.5 69,188 
     81.6,188 	'
        />
        <polygon
          fill='#EFEFEF'
          points='128,69.6 132,81.6 144.7,81.6 134.4,89 138.4,101.1 128,93.7 117.8,101.1 121.8,89 111.5,81.6 
     124.2,81.6 	'
        />
        <polygon
          fill='#EFEFEF'
          points='128,112.1 132,124.2 144.7,124.2 134.4,131.6 138.4,143.7 128,136.2 117.8,143.7 121.8,131.6 
     111.5,124.2 124.2,124.2 	'
        />
        <polygon
          fill='#EFEFEF'
          points='128,154.7 132,166.8 144.7,166.8 134.4,174.2 138.4,186.2 128,178.8 117.8,186.2 121.8,174.2 
     111.5,166.8 124.2,166.8 	'
        />
        <polygon
          fill='#EFEFEF'
          points='128,197.3 132,209.3 144.7,209.3 134.4,216.9 138.4,229 128,221.4 117.8,229 121.8,216.9 111.5,209.3 
     124.2,209.3 	'
        />
        <polygon
          fill='#EFEFEF'
          points='170.8,48.3 174.6,60.3 187.2,60.3 177,67.8 181,79.8 170.8,72.4 160.5,79.8 164.3,67.8 154.1,60.3 
     166.8,60.3 	'
        />
        <polygon
          fill='#EFEFEF'
          points='170.8,90.9 174.6,102.9 187.2,102.9 177,110.3 181,122.4 170.8,114.9 160.5,122.4 164.3,110.3 
     154.1,102.9 166.8,102.9 	'
        />
        <polygon
          fill='#EFEFEF'
          points='85.6,48.3 89.4,60.3 102.1,60.3 91.9,67.8 95.9,79.8 85.6,72.4 75.4,79.8 79.2,67.8 69,60.3 
     81.6,60.3 	'
        />
        <polygon
          fill='#EFEFEF'
          points='170.8,133.4 174.6,145.5 187.2,145.5 177,152.9 181,164.9 170.8,157.5 160.5,164.9 164.3,152.9 
     154.1,145.5 166.8,145.5 	'
        />
        <polygon
          fill='#EFEFEF'
          points='170.8,176 174.6,188 187.2,188 177,195.5 181,207.7 170.8,200.1 160.5,207.7 164.3,195.5 154.1,188 
     166.8,188 	'
        />
        <polygon
          fill='#EFEFEF'
          points='213.3,27 217.1,39.1 229.8,39.1 219.6,46.5 223.6,58.5 213.3,51.1 203.1,58.5 206.9,46.5 196.7,39.1 
     209.3,39.1 	'
        />
        <polygon
          fill='#EFEFEF'
          points='213.3,69.6 217.1,81.6 229.8,81.6 219.6,89 223.6,101.1 213.3,93.7 203.1,101.1 206.9,89 196.7,81.6 
     209.3,81.6 	'
        />
        <polygon
          fill='#EFEFEF'
          points='213.3,112.1 217.1,124.2 229.8,124.2 219.6,131.6 223.6,143.7 213.3,136.2 203.1,143.7 206.9,131.6 
     196.7,124.2 209.3,124.2 	'
        />
        <polygon
          fill='#EFEFEF'
          points='213.3,154.7 217.1,166.8 229.8,166.8 219.6,174.2 223.6,186.2 213.3,178.8 203.1,186.2 206.9,174.2 
     196.7,166.8 209.3,166.8 	'
        />
        <polygon
          fill='#EFEFEF'
          points='213.3,197.3 217.1,209.3 229.8,209.3 219.6,216.9 223.6,229 213.3,221.4 203.1,229 206.9,216.9 
     196.7,209.3 209.3,209.3 	'
        />
        <polygon
          fill='#EFEFEF'
          points='128.2,27 132,39.1 144.7,39.1 134.4,46.5 138.4,58.5 128.2,51.1 118,58.5 121.8,46.5 111.5,39.1 
     124.2,39.1 	'
        />
        <polygon
          fill='#EFEFEF'
          points='43.1,27 46.9,39.1 59.5,39.1 49.3,46.5 53.3,58.5 43.1,51.1 32.8,58.5 36.6,46.5 26.4,39.1 39.1,39.1 
       '
        />
        <polygon
          fill='#EFEFEF'
          points='43.1,69.6 46.9,81.6 59.5,81.6 49.3,89 53.3,101.1 43.1,93.7 32.8,101.1 36.6,89 26.4,81.6 39.1,81.6 
       '
        />
        <polygon
          fill='#EFEFEF'
          points='43.1,112.1 46.9,124.2 59.5,124.2 49.3,131.6 53.3,143.7 43.1,136.2 32.8,143.7 36.6,131.6 
     26.4,124.2 39.1,124.2 	'
        />
      </g>
      <rect x='256' y='51.2' fill='#EA2A0C' width='256' height='51.2' />
      <rect x='256' y='153.6' fill='#EA2A0C' width='256' height='51.2' />
    </svg>
  );
};

// TODO Razvan add types to props
export function EnglishFlagIcon(props: any) {
  if (props.size === '1x1') {
    return <Flag1x1 {...props} />;
  }

  return <Flag4x3 {...props} />;
}
