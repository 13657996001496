import { IconProps } from '.';

export const ResetIcon = ({ className = '', color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 6.5L13.5 3.5L9 0.5L9 2.5C7.41775 2.5 5.87103 2.96919 4.55544 3.84824C3.23985 4.72729 2.21447 5.97672 1.60897 7.43853C1.00347 8.90034 0.845039 10.5089 1.15372 12.0607C1.4624 13.6126 2.22433 15.038 3.34315 16.1569C4.46197 17.2757 5.88743 18.0376 7.43928 18.3463C8.99113 18.655 10.5997 18.4965 12.0615 17.891C13.5233 17.2855 14.7727 16.2602 15.6518 14.9446C16.5308 13.629 17 12.0822 17 10.5H15.5C15.5 11.7856 15.1188 13.0423 14.4046 14.1112C13.6903 15.1801 12.6752 16.0132 11.4874 16.5052C10.2997 16.9972 8.99279 17.1259 7.73192 16.8751C6.47104 16.6243 5.31285 16.0052 4.40381 15.0962C3.49477 14.1872 2.8757 13.029 2.6249 11.7681C2.3741 10.5072 2.50282 9.20028 2.99479 8.01256C3.48676 6.82484 4.31988 5.80968 5.3888 5.09545C6.45772 4.38122 7.71443 4 9 4L9 6.5Z'
        fill={color ?? 'black'}
      />
    </svg>
  );
};
