import { apiRequestResponseMsg } from '@va/constants';
import { GET_ACCOUNT_INFORMATION_SUCCEEDED, getAccountInformation } from '@va/dashboard/actions/api';
import * as Actions from '@va/standalone/shared/actions';
import { WEEBLY_LOGIN, WEEBLY_SYNC } from '@va/standalone/shared/actions';
import * as Api from '@va/standalone/shared/api-client';
import { accountType, storageItems } from '@va/standalone/shared/constants';
import { addCredentialsToLocalStorage } from '@va/standalone/shared/helpers';
import { appHistory } from '@va/standalone/shared/router';
import { getApiRequest, getLastOnboardingStep, userHasFinishedOnboarding } from '@va/standalone/shared/selectors';
import { LocalStorage, getParameterByName } from '@va/util/helpers';
import { isNumber } from 'lodash';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';

export function* watchers() {
  yield all([takeLatest(WEEBLY_LOGIN, weeblyLogin), takeLatest(WEEBLY_SYNC, weeblySync)]);
}

function* weeblyLogin() {
  try {
    let response = yield call(Api.weeblyLogin, {
      data: getParameterByName('jwt'),
    });
    response = {
      ...response,
      accountType: accountType.WEEBLY,
    };
    addCredentialsToLocalStorage(response);
    const weeblySyncResponse = yield select(getApiRequest, 'weeblySync');
    LocalStorage.setItem(storageItems.lastWebsiteId, weeblySyncResponse.get('websiteId'));
    yield put(Actions.standalone.Api.weeblyLoginSucceeded(response));

    yield put(getAccountInformation());
    yield take(GET_ACCOUNT_INFORMATION_SUCCEEDED);

    const hasFinishedOnboarding = yield select(userHasFinishedOnboarding);
    const lastCompletedOnboardingStep = yield select(getLastOnboardingStep);

    if (hasFinishedOnboarding) {
      appHistory.push('/');
      yield put(Actions.standalone.App.initialize());
      return;
    }

    if (isNumber(lastCompletedOnboardingStep)) {
      yield put(Actions.standalone.App.setOnboardingStep(lastCompletedOnboardingStep + 1));
    }
    appHistory.push('/weebly-onboarding');
  } catch (error) {
    yield put(Actions.standalone.Api.weeblyLoginFailed(error));
    appHistory.push('/error');
  }
}

function* weeblySync() {
  try {
    const response = yield call(Api.weeblySync, {
      data: getParameterByName('jwt'),
    });
    yield put(Actions.standalone.Api.weeblySyncSucceeded(response));

    //Dispatch weeblyLogin action after sync succeeded.
    yield put(Actions.standalone.Api.weeblyLogin());
  } catch (error) {
    yield put(Actions.standalone.Api.weeblySyncFailed(error));
    const weeblySyncResponse = yield select(getApiRequest, 'weeblySync');
    const domainNotConnectedError =
      weeblySyncResponse.getIn(['error', 'message']) === apiRequestResponseMsg.WEEBLY_WEBSITE_DOMAIN_NOT_CONNECTED;
    if (!domainNotConnectedError) {
      appHistory.push('/error');
    }
  }
}
