import { ChartTile } from '@va/types/charts';
import classNames from 'classnames';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { HorizontalScroll } from '../../horizontal-scroll';
import { ChartTileComponent } from './ChartTile';

type ChartTilesProps = {
  tiles: Omit<ChartTile, 'onClick'>[];
  onClick?: (tileId: string) => void;
  isLoading?: boolean;
  loadingPlaceholdersCount?: number;
  reversePercentageColors?: boolean;
};

export const ChartTiles = memo(
  ({ tiles, onClick, isLoading, loadingPlaceholdersCount = 5, reversePercentageColors }: ChartTilesProps) => {
    if (isLoading) {
      return (
        <div className={classNames('flex gap-3 h-[107px]')}>
          {Array.from(Array(loadingPlaceholdersCount).keys()).map((_, index) => {
            return (
              <div className='flex-1' key={`skeleton-${index}`}>
                <Skeleton className={'h-full rounded-24'} />
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <HorizontalScroll>
        <div className='flex gap-3'>
          {tiles.map((tile, index) => {
            return (
              <ChartTileComponent
                reversePercentageColors={reversePercentageColors}
                key={tile.label + index}
                {...tile}
                onClick={onClick ? () => onClick(tile.id) : undefined}
              />
            );
          })}
        </div>
      </HorizontalScroll>
    );
  },
);
