export const defaultTheme = {
  PRIMARY_COLOR: '#5C04B4',
  SECONDARY_COLOR: '#FFFFFF',
  TERTIARY_COLOR: '#FFFFFF',
  POSITIVE_COLOR: '#06A25A',
  NEGATIVE_COLOR: '#EA2A0C',
  NEUTRAL_COLOR: '#C3C3C3',
  FONT_FAMILY: `Inter, sans-serif`,
  TEXT_DARK_COLOR: '#0F0F0F',
  TEXT_PRIMARY_COLOR: '#3C3C3C',
  TEXT_SECONDARY_COLOR: '#696969',
  TEXT_TERTIARY_COLOR: '#C3C3C3',
};

export const DONUT_CHART_COLORS = ['#15A296', '#2478F0', '#A50FEC', '#ED3FA5', '#F66F1E', '#D2D2D2'];

window.PRIMARY_COLOR = defaultTheme.PRIMARY_COLOR;
window.SECONDARY_COLOR = defaultTheme.SECONDARY_COLOR;
window.TERTIARY_COLOR = defaultTheme.TERTIARY_COLOR;
window.POSITIVE_COLOR = defaultTheme.POSITIVE_COLOR;
window.NEGATIVE_COLOR = defaultTheme.NEGATIVE_COLOR;
window.NEUTRAL_COLOR = defaultTheme.NEUTRAL_COLOR;
window.fontFamily = defaultTheme.FONT_FAMILY;

export const CSSColorNames = {
  aliceblue: '#f0f8ff',
  antiquewhite: '#faebd7',
  aqua: '#00ffff',
  aquamarine: '#7fffd4',
  azure: '#f0ffff',
  beige: '#f5f5dc',
  bisque: '#ffe4c4',
  black: '#000000',
  blanchedalmond: '#ffebcd',
  blue: '#0000ff',
  blueviolet: '#8a2be2',
  brown: '#a52a2a',
  burlywood: '#deb887',
  cadetblue: '#5f9ea0',
  chartreuse: '#7fff00',
  chocolate: '#d2691e',
  coral: '#ff7f50',
  cornflowerblue: '#6495ed',
  cornsilk: '#fff8dc',
  crimson: '#dc143c',
  cyan: '#00ffff',
  darkblue: '#00008b',
  darkcyan: '#008b8b',
  darkgoldenrod: '#b8860b',
  darkgray: '#a9a9a9',
  darkgreen: '#006400',
  darkkhaki: '#bdb76b',
  darkmagenta: '#8b008b',
  darkolivegreen: '#556b2f',
  darkorange: '#ff8c00',
  darkorchid: '#9932cc',
  darkred: '#8b0000',
  darksalmon: '#e9967a',
  darkseagreen: '#8fbc8f',
  darkslateblue: '#483d8b',
  darkslategray: '#2f4f4f',
  darkturquoise: '#00ced1',
  darkviolet: '#9400d3',
  deeppink: '#ff1493',
  deepskyblue: '#00bfff',
  dimgray: '#696969',
  dodgerblue: '#1e90ff',
  firebrick: '#b22222',
  floralwhite: '#fffaf0',
  forestgreen: '#228b22',
  fuchsia: '#ff00ff',
  gainsboro: '#dcdcdc',
  ghostwhite: '#f8f8ff',
  gold: '#ffd700',
  goldenrod: '#daa520',
  gray: '#808080',
  green: '#008000',
  greenyellow: '#adff2f',
  honeydew: '#f0fff0',
  hotpink: '#ff69b4',
  'indianred ': '#cd5c5c',
  indigo: '#4b0082',
  ivory: '#fffff0',
  khaki: '#f0e68c',
  lavender: '#e6e6fa',
  lavenderblush: '#fff0f5',
  lawngreen: '#7cfc00',
  lemonchiffon: '#fffacd',
  lightblue: '#add8e6',
  lightcoral: '#f08080',
  lightcyan: '#e0ffff',
  lightgoldenrodyellow: '#fafad2',
  lightgrey: '#d3d3d3',
  lightgreen: '#90ee90',
  lightpink: '#ffb6c1',
  lightsalmon: '#ffa07a',
  lightseagreen: '#20b2aa',
  lightskyblue: '#87cefa',
  lightslategray: '#778899',
  lightsteelblue: '#b0c4de',
  lightyellow: '#ffffe0',
  lime: '#00ff00',
  limegreen: '#32cd32',
  linen: '#faf0e6',
  magenta: '#ff00ff',
  maroon: '#800000',
  mediumaquamarine: '#66cdaa',
  mediumblue: '#0000cd',
  mediumorchid: '#ba55d3',
  mediumpurple: '#9370d8',
  mediumseagreen: '#3cb371',
  mediumslateblue: '#7b68ee',
  mediumspringgreen: '#00fa9a',
  mediumturquoise: '#48d1cc',
  mediumvioletred: '#c71585',
  midnightblue: '#191970',
  mintcream: '#f5fffa',
  mistyrose: '#ffe4e1',
  moccasin: '#ffe4b5',
  navajowhite: '#ffdead',
  navy: '#000080',
  oldlace: '#fdf5e6',
  olive: '#808000',
  olivedrab: '#6b8e23',
  orange: '#ffa500',
  orangered: '#ff4500',
  orchid: '#da70d6',
  palegoldenrod: '#eee8aa',
  palegreen: '#98fb98',
  paleturquoise: '#afeeee',
  palevioletred: '#d87093',
  papayawhip: '#ffefd5',
  peachpuff: '#ffdab9',
  peru: '#cd853f',
  pink: '#ffc0cb',
  plum: '#dda0dd',
  powderblue: '#b0e0e6',
  purple: '#800080',
  rebeccapurple: '#663399',
  red: '#ff0000',
  rosybrown: '#bc8f8f',
  royalblue: '#4169e1',
  saddlebrown: '#8b4513',
  salmon: '#fa8072',
  sandybrown: '#f4a460',
  seagreen: '#2e8b57',
  seashell: '#fff5ee',
  sienna: '#a0522d',
  silver: '#c0c0c0',
  skyblue: '#87ceeb',
  slateblue: '#6a5acd',
  slategray: '#708090',
  snow: '#fffafa',
  springgreen: '#00ff7f',
  steelblue: '#4682b4',
  tan: '#d2b48c',
  teal: '#008080',
  thistle: '#d8bfd8',
  tomato: '#ff6347',
  turquoise: '#40e0d0',
  violet: '#ee82ee',
  wheat: '#f5deb3',
  white: '#ffffff',
  whitesmoke: '#f5f5f5',
  yellow: '#ffff00',
  yellowgreen: '#9acd32',
};

export const themes = {
  BLACK: 'theme-black',
  GREEN: 'theme-green',
  DARK_GREEN: 'theme-dark-green',
  RED: 'theme-red',
  BLUE: 'theme-blue',
  GRAY: 'theme-gray',
  PURPLE: 'theme-purple',
  ORANGE: 'theme-orange',
  LIGHT_ORANGE: 'theme-light-orange',
  NO_THEME: 'no-theme',
};

export const cardColorTheme = {
  ...themes,
};

export const buttonGroupTheme = {
  GREEN: 'theme-green',
};

export const GREEN_COLOR = '#009688';
export const PERSIAN_GREEN_COLOR = '#04B5A6';
export const PURE_TURQUOISE_COLOR = '#1EE4D2';
export const MINE_SHAFT_COLOR = '#333333';
export const GRAY_ALABASTER_COLOR = '#F9F9F9';
export const ORANGE_COLOR = '#F7761B';
export const GRAY_COLOR = '#888';
export const GRAY_ALTO = '#DDDDDD';
export const GRAY_DISABLED_COLOR = '#cdcdcd';
export const BLACK_COLOR = '#13232A';
export const WHITE_COLOR = '#fff';
export const GREEN_CHART_COLOR = '#008D7D';
export const LIGHT_GREEN_CHART_COLOR = '#4FBB90';
export const FUEL_YELLOW_COLOR = '#edab26';
export const RIPE_LEMON_COLOR = '#f7da1b';
export const SSR_REACHED_LIMIT = 15;
export const FUNNEL_DETAIL_URL_MAX_LIMIT = 30;
export const GRAY_CONCRETE = '#F3F3F3';
export const RED_COLOR = '#FFCCBB';
export const DEVIL_GRAY = '#666666';
export const GRAY_DUSTY = '#999999';
export const BLUE_COLOR = '#2675ED';
export const LIGHT_BLUE_COLOR = '#f0f7fe';
export const GRAY_GALLERY = '#EEEEEE';
export const GRAY_SILVER = '#C3C3C3';
export const SILVER = '#CCCCCC';
export const SILVER_GRAY = '#CCCCCC';
export const PURE_BLUE = '#2478F0';
export const LIGHT_BLUE = '#1E66CF';
export const GREEN_DARKER_COLOR = '#016D62';
export const PURE_BLACK_COLOR = '#3c3c3c';
export const LIGHT_GREY_COLOR = '#f9f9f9';
export const GREY_COLOR = '#969696';
export const CHARCOAL_COLOR = '#3C3C3C';
export const CODE_COLOR = '#1E1E1E';
