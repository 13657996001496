import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const HeaderWithTooltipCell = ({ title, tooltip }) => {
  return (
    <div className='flex items-center'>
      <TooltipWrapper disabled={!tooltip} content={tooltip}>
        <span className={classNames('ml-2', { 'underline-dashed': tooltip })}>{title}</span>
      </TooltipWrapper>
    </div>
  );
};

HeaderWithTooltipCell.propTypes = {
  tooltip: PropTypes.string,
  title: PropTypes.string,
};
