import { apiStatus } from '@va/constants';
import { OPEN_MODAL, openModal } from '@va/dashboard/actions/ui';
import { useTranslate } from '@va/localization';
import { useConfirmationDialogContext } from '@va/shared/util-confirmation';
import { START_CREATE_AGENCY_FLOW, changeAgencyStatus } from '@va/standalone/shared/actions';
import { modalName as standaloneModals } from '@va/standalone/shared/constants';
import { getApiRequest, getApiRequestStatus, isAgencyAccount } from '@va/standalone/shared/selectors';
import { Button, DetailsSection } from '@va/ui/design-system';
import { getBusinessSectorName } from '@va/util/helpers';
import React, { Fragment, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelAgencySubscriptionModal from '../../agency-white-label-v2/CancelAgencySubscriptionModal';
import InfoField from '../common/InfoField';

const BusinessDetails = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const { confirm } = useConfirmationDialogContext();
  const agencyAccount = useSelector(isAgencyAccount);
  const agencyCustomerInfo = useSelector((state) => getApiRequest(state, 'getAgencyCustomer'));
  const agencyCustomerInfoApiStatus = useSelector((state) => getApiRequestStatus(state, 'agencyCustomerInfo'));

  const businessSectorName = getBusinessSectorName(agencyCustomerInfo.get('businessSector'));

  function handleSelectedWhiteLabelWebsite(websiteId: string) {
    dispatch({ type: START_CREATE_AGENCY_FLOW, websiteId: websiteId });
  }

  function handleAgencyFreePlanClick() {
    dispatch(
      openModal(standaloneModals.WHITE_LABEL_WEBSITE, {
        onSubmit: handleSelectedWhiteLabelWebsite,
      }),
    );
  }

  const InfoSection = ({
    leftSection,
    rightSection,
  }: {
    leftSection: React.ReactNode;
    rightSection?: React.ReactNode;
  }) => {
    return (
      <div className='flex flex-column md:flex-row mb-4'>
        <div className='w-full md:w-7/12'>{leftSection}</div>
        <div className='flex justify-start md:justify-center w-full md:w-4/12 mt-4 md:mt-0'>
          <div className='w-full'>{rightSection}</div>
        </div>
      </div>
    );
  };

  const agencySubscriptionButtons = useMemo(() => {
    return (
      <div className='w-full mt-5 space-y-3 mb-8 md:mb-0 md:mt-8'>
        {agencyAccount && (
          <Button
            text={translate('whiteLabel.remove')}
            className='w-full max-w-270px justify-center'
            color={'destructive'}
            onClick={() => {
              confirm(() => {
                dispatch(changeAgencyStatus(false));
              }, CancelAgencySubscriptionModal);
            }}
          />
        )}
      </div>
    );
  }, [agencyAccount, translate, confirm, dispatch]);

  const isInProgress = useMemo(
    () => agencyCustomerInfoApiStatus === apiStatus.IN_PROGRESS,
    [agencyCustomerInfoApiStatus],
  );

  return (
    <DetailsSection
      title={translate('account.businessDetails.title')}
      sectionName={'businessDetails'}
      additionalClass='bg-gray-alabaster'
      onButtonClick={() =>
        dispatch({
          type: OPEN_MODAL,
          modalName: standaloneModals.BUSINESS_INFO,
        })
      }
      inProgress={isInProgress}
      leftSideNode={agencyAccount ? agencySubscriptionButtons : undefined}
    >
      {!isInProgress && (
        <Fragment>
          <InfoSection
            leftSection={
              <InfoField
                label={translate('form.placeholders.businessName')}
                value={agencyCustomerInfo.get('companyName')}
              />
            }
            rightSection={
              <InfoField
                label={translate('form.placeholders.businessSector')}
                value={translate(businessSectorName[0])}
              />
            }
          />
          <InfoSection
            leftSection={
              <InfoField
                label={translate('form.placeholders.vatIdentificationNumber')}
                value={agencyCustomerInfo.get('vat')}
              />
            }
            rightSection={
              <InfoField
                label={translate('form.placeholders.agencyAccount')}
                value={translate(agencyAccount ? 'account.agency.enabled' : 'account.agency.notEnabled')}
              />
            }
          />

          <InfoSection
            leftSection={
              <InfoField
                label={translate('form.placeholders.streetAndNumber')}
                value={agencyCustomerInfo.get('street')}
              />
            }
            rightSection={
              <InfoField label={translate('form.placeholders.zipCode')} value={agencyCustomerInfo.get('zipcode')} />
            }
          />
          <InfoSection
            leftSection={
              <InfoField label={translate('form.placeholders.city')} value={agencyCustomerInfo.get('city')} />
            }
            rightSection={
              <InfoField label={translate('form.placeholders.state')} value={agencyCustomerInfo.get('state')} />
            }
          />

          {!agencyAccount && (
            <Fragment>
              <div className='mt-4 md:mt-8'>{translate('account.businessDetails.agencyAccountInfo')}</div>
              <div className='my-3 '>
                <Button
                  onClick={handleAgencyFreePlanClick}
                  className={'!bg-orange-pure'}
                  text={translate('button.agencyWhiteLabel.tryFreePlan')}
                />
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </DetailsSection>
  );
};

export default BusinessDetails;
