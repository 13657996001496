import { Platform } from '@va/constants';
import { useTranslate } from '@va/localization';
import { useConfirmationDialogContext } from '@va/shared/util-confirmation';
import {
  applyLifetimeDeal,
  changeWebsiteWhiteLabelStatus,
  setActiveWebsite,
  startLeaveWebsiteFlow,
} from '@va/standalone/shared/actions';
import { subscriptionStatus } from '@va/standalone/shared/constants';
import { isAgencyAccount, isAgencyOwner, isSumoUser } from '@va/standalone/shared/selectors';
import { HorizontalSeparator } from '@va/ui/design-system';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import {
  BinIcon,
  GridIcon,
  LogoutIcon,
  ManageContributorsIcon,
  NavDashboard,
  SettingsIcon,
  ShareIcon,
  TrackingCodeIcon,
} from '@va/icons';

import { addNotification } from '@va/dashboard/actions/ui';
import { VoucherIcon } from '@va/svg-visa-icons';
import { TooltipWrapper } from '@va/ui/tooltips';
import { FC, MouseEventHandler, ReactNode, useCallback, useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useRemoveWebsiteContext } from '../../RemoveWebsiteV2/removeWebsiteContext';
import WhiteLabelConfirmationModal from '../../agency-white-label-v2/WhiteLabelConfirmationModal';
import { SubscriptionType } from '../../agency-white-label-v2/agency-white-label-apiClient';
import './WebsiteActions.scss';

type WebsiteActionsProps = {
  websiteId: string;
  whiteLabel: boolean;
  isWebsiteOwner: boolean;
  setWebsiteActionsVisible: (isVisible: boolean) => void;
  platformName: Platform;
  isSumoActive: boolean;
  subscription: SubscriptionType | null;
};

const WebsiteActions: FC<WebsiteActionsProps> = ({
  websiteId,
  whiteLabel,
  isWebsiteOwner,
  setWebsiteActionsVisible,
  platformName,
  isSumoActive,
  subscription,
}) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const { confirm } = useConfirmationDialogContext();

  const isWixWebsite = platformName === Platform.WIX;
  const agencyAccount = useSelector(isAgencyAccount);
  const agencyOwner = useSelector(isAgencyOwner);
  const isSumoUserAccount = useSelector(isSumoUser);

  const handleWhiteLabelStatus = useCallback(
    (status: boolean) => {
      dispatch(changeWebsiteWhiteLabelStatus(websiteId, status));
    },

    [dispatch, websiteId],
  );

  const { startRemoveWebsiteFlow } = useRemoveWebsiteContext();
  const shouldShowSumoLifetimeDeal = useMemo(() => {
    // Platforms for which the sumo lifetime deal will never be enabled
    const bannedPlatforms = [Platform.AAAS, Platform.WIX, Platform.PIXPA];
    if (bannedPlatforms.includes(platformName)) return false;
    if (!isWebsiteOwner || !isSumoUserAccount) return false;
    if (
      !subscription ||
      subscription?.status === subscriptionStatus.CANCELED ||
      subscription?.status === subscriptionStatus.DELETED
    ) {
      return true;
    }
  }, [isWebsiteOwner, isSumoUserAccount, subscription, platformName]);

  const handleWhiteLabelStatusChange = useCallback(() => {
    setWebsiteActionsVisible(false);

    if (whiteLabel) {
      return confirm(() => {
        handleWhiteLabelStatus(false);
      }, WhiteLabelConfirmationModal);
    }

    return handleWhiteLabelStatus(true);
  }, [whiteLabel, confirm, handleWhiteLabelStatus, setWebsiteActionsVisible]);

  const commonActions = useMemo(() => {
    return (
      <Action
        icon={<NavDashboard />}
        label={<Link to={`/website/${websiteId}`}>{translate('table.manageWebsites.actions.jumpToDashboard')}</Link>}
        onClick={() => {
          dispatch(setActiveWebsite(websiteId));
        }}
      />
    );
  }, [dispatch, translate, websiteId]);

  const OwnerActions = (
    <>
      <Action
        icon={<TrackingCodeIcon />}
        label={
          <Link to={`/website/${websiteId}/settings/tracking-code`}>
            {translate('table.manageWebsites.actions.viewTrackingCode')}
          </Link>
        }
        onClick={() => {
          dispatch(setActiveWebsite(websiteId));
        }}
      />
      {commonActions}
      <Action
        icon={<ManageContributorsIcon />}
        label={translate('table.manageWebsites.actions.manageContributors')}
        onClick={() => {
          dispatch(setActiveWebsite(websiteId));
          history.push(`/website/${websiteId}/settings/manage-contributors`);
          setWebsiteActionsVisible(false);
        }}
      />

      {shouldShowSumoLifetimeDeal && (
        <Action
          icon={<VoucherIcon height='20' width='20' />}
          disabled={isSumoActive}
          label={isSumoActive ? translate('appsumo.applied') : translate('appsumo.apply')}
          onClick={() => [dispatch(applyLifetimeDeal(websiteId)), setWebsiteActionsVisible(false)]}
        />
      )}
      <Action
        icon={<SettingsIcon color='black' />}
        label={
          <Link to={`/website/${websiteId}/settings`}>{translate('table.manageWebsites.actions.websiteSettings')}</Link>
        }
        onClick={() => {
          dispatch(setActiveWebsite(websiteId));
          setWebsiteActionsVisible(false);
        }}
      />
      <Action
        icon={<ShareIcon className='share-icon' />}
        label={
          <CopyToClipboard text={websiteId}>
            <div>{translate('button.copyId')}</div>
          </CopyToClipboard>
        }
        onClick={() => {
          dispatch(
            addNotification({
              title: 'notifications.websiteIdCopied',
              level: 'success',
              autoDismiss: 5,
            }),
          );
          setWebsiteActionsVisible(false);
        }}
      />
      {agencyAccount && agencyOwner && (
        <Action
          icon={<GridIcon />}
          label={translate(`table.manageWebsites.actions.${whiteLabel ? 'disableAgency' : 'enableAgency'}`)}
          onClick={handleWhiteLabelStatusChange}
          tooltip={isWixWebsite ? translate('table.manageWebsites.actions.wixWhiteLabelNotAllowed') : undefined}
          disabled={isWixWebsite}
        />
      )}
      <HorizontalSeparator className='' />
      <Action
        icon={<BinIcon />}
        label={translate('table.manageWebsites.actions.removeWebsite')}
        hoverLabelClass={'hover:text-red-pure'}
        onClick={() => {
          startRemoveWebsiteFlow(websiteId, subscription, platformName);
          setWebsiteActionsVisible(false);
        }}
      />
    </>
  );

  const ContributorActions = (
    <>
      {commonActions}
      <Action
        icon={<ManageContributorsIcon />}
        label={translate('table.manageWebsites.actions.contactOwner')}
        onClick={() => {
          dispatch(setActiveWebsite(websiteId));
          history.push(`/website/${websiteId}/settings/manage-contributors`);
          setWebsiteActionsVisible(false);
        }}
      />
      <Action
        icon={<LogoutIcon />}
        label={translate('table.manageWebsites.actions.leaveWebsite')}
        hoverLabelClass={'hover:text-red-pure'}
        onClick={() => {
          dispatch(startLeaveWebsiteFlow(websiteId));
          setWebsiteActionsVisible(false);
        }}
      />
    </>
  );

  return (
    <div className='bg-white rounded-12' style={{ boxShadow: '0 0 30px rgba(0, 0, 0, 0.06)' }}>
      {isWebsiteOwner ? OwnerActions : ContributorActions}
    </div>
  );
};

export default WebsiteActions;

type ActionProps = {
  icon: ReactNode;
  label: ReactNode;
  onClick: MouseEventHandler;
  hoverLabelClass?: string;
  tooltip?: string | null | undefined;
  disabled?: boolean;
};

const Action: FC<ActionProps> = ({
  icon,
  label,
  onClick,
  hoverLabelClass = 'hover:text-primary',
  tooltip,
  disabled,
}) => {
  const actionContent = (
    <div
      className={classNames(
        'text-15 leading-normal text-mine-shaft tracking-default px-4 py-3 flex font-medium ',
        { [`cursor-pointer ${hoverLabelClass}`]: !disabled },
        { 'cursor-default text-opacity-50': disabled },
      )}
      onClick={!disabled ? onClick : undefined}
    >
      <div className='website-actions-icon'>{icon}</div>
      <div className='ml-3 flex items-center break-all'>{label}</div>
    </div>
  );
  return (
    <TooltipWrapper
      interactive
      disabled={!tooltip}
      zIndex={10000}
      content={<div className='p-2 flex flex-wrap'>{tooltip}</div>}
    >
      {actionContent}
    </TooltipWrapper>
  );
};
