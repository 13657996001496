export const FeedbackFeaturePreview = () => {
  return (
    <svg width='366' height='220' viewBox='0 0 366 220' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0)'>
        <rect width='366' height='220' fill='white' />
        <rect width='366' height='220' fill='#B4E9E4' />
        <path d='M0 0H330V168C330 184.569 316.569 198 300 198H0V0Z' fill='#F0FBFA' />
        <path
          d='M264 153C264 144.716 270.716 138 279 138V138C287.284 138 294 144.716 294 153V168H279C270.716 168 264 161.284 264 153V153Z'
          fill='#04B5A6'
        />
        <circle cx='273' cy='153' r='2' fill='white' />
        <circle cx='279' cy='153' r='2' fill='white' />
        <circle cx='285' cy='153' r='2' fill='white' />
        <g filter='url(#filter0_d)'>
          <circle cx='189' cy='63' r='63' fill='white' />
        </g>
        <path d='M84 0H294V126H96C89.3726 126 84 120.627 84 114V0Z' fill='white' />
        <path d='M84 96H294V126H96C89.3726 126 84 120.627 84 114V96Z' fill='#04B5A6' />
        <circle cx='141' cy='111' r='4' fill='#59CEC4' />
        <rect x='151' y='107' width='90' height='8' rx='4' fill='#59CEC4' />
        <rect x='102' y='48' width='60' height='6' rx='3' fill='#F3F3F3' />
        <rect x='216' y='48' width='60' height='6' rx='3' fill='#F3F3F3' />
        <rect x='186' y='66' width='90' height='18' rx='9' fill='#04B5A6' />
        <path
          d='M114.525 15.6108C115.717 13.872 118.283 13.872 119.475 15.6108L121.687 18.8389C122.077 19.4081 122.651 19.8254 123.313 20.0205L127.067 21.127C129.089 21.723 129.882 24.1634 128.597 25.834L126.21 28.9357C125.789 29.4825 125.57 30.1578 125.589 30.8476L125.696 34.7595C125.754 36.8666 123.678 38.3749 121.692 37.6686L118.005 36.3574C117.355 36.1262 116.645 36.1262 115.995 36.3574L112.308 37.6686C110.322 38.3749 108.246 36.8666 108.304 34.7595L108.411 30.8476C108.43 30.1578 108.211 29.4825 107.79 28.9357L105.403 25.834C104.118 24.1634 104.911 21.723 106.933 21.127L110.687 20.0205C111.349 19.8254 111.923 19.4081 112.313 18.8389L114.525 15.6108Z'
          fill='#04B5A6'
        />
        <path
          d='M262.65 16.1761L264.862 19.4042C265.382 20.1631 266.148 20.7195 267.031 20.9797L270.784 22.0862C272.132 22.4836 272.661 24.1105 271.804 25.2242L269.418 28.3259C268.856 29.055 268.564 29.9553 268.589 30.875L268.697 34.787C268.735 36.1918 267.351 37.1973 266.027 36.7264L262.34 35.4152C261.473 35.107 260.527 35.107 259.66 35.4152L255.973 36.7264C254.649 37.1973 253.265 36.1918 253.303 34.787L253.411 30.875C253.436 29.9553 253.144 29.055 252.582 28.3259L250.196 25.2242C249.339 24.1105 249.868 22.4836 251.216 22.0862L254.969 20.9797C255.852 20.7195 256.618 20.1631 257.138 19.4042L259.35 16.1761C260.145 15.0169 261.855 15.0169 262.65 16.1761Z'
          fill='#F0FBFA'
          stroke='#04B5A6'
          stroke-width='2'
        />
        <path
          d='M222.525 15.6108C223.717 13.872 226.283 13.872 227.475 15.6108L229.687 18.8389C230.077 19.4081 230.651 19.8254 231.313 20.0205L235.067 21.127C237.089 21.723 237.882 24.1634 236.597 25.834L234.21 28.9357C233.789 29.4825 233.57 30.1578 233.589 30.8476L233.696 34.7595C233.754 36.8666 231.678 38.3749 229.692 37.6686L226.005 36.3574C225.355 36.1262 224.645 36.1262 223.995 36.3574L220.308 37.6686C218.322 38.3749 216.246 36.8666 216.304 34.7595L216.411 30.8476C216.43 30.1578 216.211 29.4825 215.79 28.9357L213.403 25.834C212.118 24.1634 212.911 21.723 214.933 21.127L218.687 20.0205C219.349 19.8254 219.923 19.4081 220.313 18.8389L222.525 15.6108Z'
          fill='#04B5A6'
        />
        <path
          d='M186.525 15.6108C187.717 13.872 190.283 13.872 191.475 15.6108L193.687 18.8389C194.077 19.4081 194.651 19.8254 195.313 20.0205L199.067 21.127C201.089 21.723 201.882 24.1634 200.597 25.834L198.21 28.9357C197.789 29.4825 197.57 30.1578 197.589 30.8476L197.696 34.7595C197.754 36.8666 195.678 38.3749 193.692 37.6686L190.005 36.3574C189.355 36.1262 188.645 36.1262 187.995 36.3574L184.308 37.6686C182.322 38.3749 180.246 36.8666 180.304 34.7595L180.411 30.8476C180.43 30.1578 180.211 29.4825 179.79 28.9357L177.403 25.834C176.118 24.1634 176.911 21.723 178.933 21.127L182.687 20.0205C183.349 19.8254 183.923 19.4081 184.313 18.8389L186.525 15.6108Z'
          fill='#04B5A6'
        />
        <path
          d='M150.525 15.6108C151.717 13.872 154.283 13.872 155.475 15.6108L157.687 18.8389C158.077 19.4081 158.651 19.8254 159.313 20.0205L163.067 21.127C165.089 21.723 165.882 24.1634 164.597 25.834L162.21 28.9357C161.789 29.4825 161.57 30.1578 161.589 30.8476L161.696 34.7595C161.754 36.8666 159.678 38.3749 157.692 37.6686L154.005 36.3574C153.355 36.1262 152.645 36.1262 151.995 36.3574L148.308 37.6686C146.322 38.3749 144.246 36.8666 144.304 34.7595L144.411 30.8476C144.43 30.1578 144.211 29.4825 143.79 28.9357L141.403 25.834C140.118 24.1634 140.911 21.723 142.933 21.127L146.687 20.0205C147.349 19.8254 147.923 19.4081 148.313 18.8389L150.525 15.6108Z'
          fill='#04B5A6'
        />
      </g>
      <defs>
        <filter
          id='filter0_d'
          x='66'
          y='-60'
          width='246'
          height='246'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
          <feOffset />
          <feGaussianBlur stdDeviation='30' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
        <clipPath id='clip0'>
          <rect width='366' height='220' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
