import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

type ModalWrapperPropTypes = {
  closeModal: () => void;
  isModalOpen: boolean;
  modalContentRef: React.RefObject<Element>;
  modalBackdropSelector?: string;
};

/**
 *
 * @deprecated Use ModalWrapper from libs
 * TODO Delete
 */
const ModalWrapper: React.FC<PropsWithChildren<ModalWrapperPropTypes>> = ({
  children,
  closeModal,
  isModalOpen,
  modalContentRef,
  modalBackdropSelector = '#root',
}) =>
  createPortal(
    <div
      onClick={(e) => {
        if (modalContentRef.current && !modalContentRef.current.contains(e.target as any)) {
          closeModal();
        }
      }}
      className={classNames(
        'fixed flex text-white items-center justify-center transition-all duration-1000 bg-black-lighter-50 top-0 left-0 w-full z-[var(--zIndex-modal)] p-3 min-h-full',
        !isModalOpen ? 'hidden' : '',
      )}
      // https://stackoverflow.com/a/75648985
      style={{
        height: '100svh',
      }}
    >
      {children}
    </div>,
    document.querySelector(modalBackdropSelector)!,
  );

export { ModalWrapper };
