import { openModal } from '@va/dashboard/actions/ui';
import { getSupportCenterDomain } from '@va/dashboard/selectors/customization';
import QuestionTooltip from '@va/deprecated/components/tooltips/QuestionTooltip';
import { useTranslate } from '@va/localization';
import { START_CREATE_AGENCY_FLOW } from '@va/standalone/shared/actions';
import { modalName } from '@va/standalone/shared/constants';
import { AddClients, CustomizeClients, EarnCommission, InviteClients } from '@va/svg-visa-icons';
import { Button } from '@va/ui/design-system';
import { useDispatch, useSelector } from 'react-redux';
import './WelcomePageAgencyWhiteLabel.scss';

const WelcomePageAgencyWhiteLabel = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const supportCenterDomain = useSelector(getSupportCenterDomain);

  function handleSelectedWhiteLabelWebsite(websiteId: string) {
    dispatch({ type: START_CREATE_AGENCY_FLOW, websiteId: websiteId });
  }

  function handleAgencyFreePlanClick() {
    dispatch(
      openModal(modalName.WHITE_LABEL_WEBSITE, {
        onSubmit: handleSelectedWhiteLabelWebsite,
        isWixWebsiteAllowed: false,
      }),
    );
  }

  const features = [
    {
      icon: <AddClients />,
      title: 'agencyWhiteLabel.welcomePage.feature1',
      description: 'agencyWhiteLabel.welcomePage.description1',
    },
    {
      icon: <InviteClients />,
      title: 'agencyWhiteLabel.welcomePage.feature2',
      description: 'agencyWhiteLabel.welcomePage.description2',
    },
    {
      icon: <CustomizeClients />,
      title: 'agencyWhiteLabel.welcomePage.feature3',
      description: 'agencyWhiteLabel.welcomePage.description3',
    },
    {
      icon: <EarnCommission />,
      title: 'agencyWhiteLabel.welcomePage.feature4',
      description: 'agencyWhiteLabel.welcomePage.description4',
    },
  ];

  return (
    <div className='flex flex-column h-full w-full bg-gray-default welcome-page-agency-white-label-wrapper px-6'>
      <div className='flex justify-center text-center font-semibold welcome-page-title text-mine-shaft'>
        {translate('agencyWhiteLabel.welcomePage.title')}
      </div>
      <div className='flex justify-center mt-3 flex-wrap welcome-page-feature-section'>
        {features.map((item, index) => {
          return (
            <div className='px-6 flex flex-column mb-3' key={`feature-${index}`}>
              {item.icon}
              <div className='welcome-page-feature-content'>
                <div className='mt-4 mb-2 font-semibold text-21 leading-30 tracking-normalTight'>
                  {translate(item.title)}
                </div>
                <div className='text-15 leading-24 tracking-tightestDefault'>{translate(item.description)}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className='flex justify-center'>
        <Button text={translate('button.agencyWhiteLabel.tryFreePlan')} onClick={handleAgencyFreePlanClick} />
      </div>
      <div className='flex justify-center mt-3'>
        <div className='flex items-center bg-gray-alabaster p-6 rounded-12'>
          <QuestionTooltip>
            {translate('agencyWhiteLabel.welcomePage.tooltip.helpDesk', { supportCenterDomain })}
          </QuestionTooltip>
          <div className='ml-2'>{translate('agencyWhiteLabel.welcomePage.helpDesk', { supportCenterDomain })}</div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePageAgencyWhiteLabel;
