import { nonTranslatableText } from '@va/constants';
import { getSettings } from '@va/dashboard/selectors/api';
import { getFormValue } from '@va/dashboard/selectors/core';
import { getProviderName } from '@va/dashboard/selectors/customization';
import { isMobileSm } from '@va/dashboard/selectors/ui';
import PopUpCard from '@va/deprecated/components/PopUpCard';
import Translation from '@va/deprecated/components/Translation';
import FormInput from '@va/deprecated/form/FormInput';
import { validator } from '@va/deprecated/form/form-helpers/form-validator';
import { BusinessName, ProfileIcon, SeeMoreArrow } from '@va/svg-visa-icons';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import { reduxForm } from 'redux-form/immutable';

class Sign extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isCanvasEmpty: true,
    };

    this.handleEndSignature = this.handleEndSignature.bind(this);
    this.handleClearSignature = this.handleClearSignature.bind(this);
    this.handleSignAgreement = this.handleSignAgreement.bind(this);
    this.handleLater = this.handleLater.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.canvasWidth !== this.props.canvasWidth || (prevProps.isModalOpen && !this.props.isModalOpen)) {
      if (this.sigCanvas) this.handleClearSignature();
    }
  }

  handleEndSignature() {
    this.setState({ isCanvasEmpty: false });
  }

  handleClearSignature() {
    this.setState({ isCanvasEmpty: true });
    this.sigCanvas.clear();
  }

  handleSignAgreement() {
    this.props.signAgreement(this.sigCanvas.toDataURL(), {
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      companyName: this.props.companyName,
      email: this.props.email,
    });
  }

  handleLater() {
    this.props.closeModal();
  }

  renderFooterButtons() {
    const { translate } = this.props;

    return (
      <React.Fragment>
        <button type='button' onClick={this.handleLater} className={'footer-button'}>
          {translate('button.later')}
        </button>
        <button
          type='button'
          onClick={this.handleSignAgreement}
          className={'colored footer-button'}
          disabled={!this.props.valid || this.state.isCanvasEmpty}
        >
          {translate('button.sign')}
        </button>
      </React.Fragment>
    );
  }

  render() {
    const { translate, canvasWidth, dpaLink, providerName } = this.props;

    return (
      <form className='sign-dpa-card'>
        <PopUpCard
          type='card-grey'
          colorTheme='theme-green'
          invalid={!this.props.valid || this.state.isCanvasEmpty}
          footerButtons={this.renderFooterButtons()}
          title={translate('modal.signDPA.title', {
            providerName,
            dataProcessingAgreement: nonTranslatableText.dpa,
          })}
          subtitle={
            <React.Fragment>
              <p className='paragraph'>
                <Translation
                  translationKey={'modal.signDPA.businessCondition'}
                  parameters={{
                    dataProcessingAgreement: nonTranslatableText.dpa,
                    href: dpaLink,
                    className: 'terms-link',
                  }}
                />
              </p>
              <p className='paragraph'>
                <Translation
                  translationKey={'modal.signDPA.subtitle'}
                  parameters={{
                    providerName: providerName,
                    dataProcessingAgreement: nonTranslatableText.dpa,
                    href: dpaLink,
                    className: 'terms-link',
                  }}
                />
              </p>
            </React.Fragment>
          }
          noPadding
        >
          <React.Fragment>
            <div className='information-container'>
              <div className='customer-information'>
                <div className='two-inputs-container'>
                  <FormInput
                    name='firstName'
                    placeholder='card.notifications.placeholders.firstName'
                    type='text'
                    icon={<ProfileIcon />}
                    validate={[validator.required]}
                    translate={translate}
                    onChange={(event, value) => this.setState({ hasFirstName: !!value })}
                    required
                  />
                  <FormInput
                    name='lastName'
                    placeholder='card.notifications.placeholders.lastName'
                    type='text'
                    icon={<ProfileIcon />}
                    validate={[validator.required]}
                    translate={translate}
                    onChange={(event, value) => this.setState({ hasLastName: !!value })}
                    required
                  />
                </div>
                <div className={this.props.isMobileSm && !this.props.valid ? 'required-margin' : ''}>
                  <FormInput
                    name='companyName'
                    placeholder='form.placeholders.businessName'
                    type='text'
                    icon={<BusinessName />}
                    translate={translate}
                  />
                </div>
                <div className='information-text'>
                  <div className='sign-instructions-text'>{translate('modal.signDPA.instructionText')}</div>
                  <div className='see-more-arrow'>
                    <SeeMoreArrow className='icon see-more-icon' />
                  </div>
                </div>
              </div>
              <div className='signature-area'>
                <div>
                  <SignatureCanvas
                    ref={(node) => (this.sigCanvas = node)}
                    onEnd={this.handleEndSignature}
                    canvasProps={{ width: canvasWidth, height: 200, className: 'sig-canvas' }}
                  />
                </div>
                <div className='clear-btn' onClick={this.handleClearSignature}>
                  {translate('button.clear')}
                </div>
              </div>
            </div>
          </React.Fragment>
        </PopUpCard>
      </form>
    );
  }
}

Sign.propTypes = {
  translate: PropTypes.func.isRequired,
  signAgreement: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  canvasWidth: PropTypes.number.isRequired,
  isModalOpen: PropTypes.bool,
  dpaLink: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const apiSettings = getSettings(state);

  return {
    providerName: getProviderName(state),
    isMobileSm: isMobileSm(state),
    firstName: getFormValue(state, 'SignForm', 'firstName'),
    lastName: getFormValue(state, 'SignForm', 'lastName'),
    companyName: getFormValue(state, 'SignForm', 'companyName'),
    email: apiSettings.email,

    initialValues: Immutable.fromJS({
      firstName: apiSettings.firstName || '',
      lastName: apiSettings.lastName || '',
      companyName: apiSettings.companyName || '',
    }),
  };
};

const SignForm = reduxForm({
  form: 'SignForm',
})(Sign);

export default connect(mapStateToProps, null)(SignForm);
