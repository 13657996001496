import { useEffect } from 'react';

export function useListenForMessage(onMessage: (message: MessageEvent) => void) {
  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);
}
