import { apiStatus } from '@va/constants';
import { setCardView } from '@va/dashboard/actions/ui';
import { getCardView, getPreviousCardView, isModalOpen } from '@va/dashboard/selectors/ui';
import {
  refreshSubscriptionDetails,
  setPaddleCallbackEvent,
  setSubscriptionPlan,
  setSubscriptionType,
} from '@va/standalone/shared/actions';
import { subscriptionType, upgradeModalView } from '@va/standalone/shared/constants';
import { getPaddleCheckoutEvents, getPlanIndex } from '@va/standalone/shared/selectors';
import getSymbolFromCurrency from 'currency-symbol-map';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import CheckoutCard from '../../../Billing';

const paddleEventsForUpdateDiscount = [
  'Checkout.Coupon.Applied',
  'Checkout.Coupon.Remove',
  'Checkout.Vat.Applied',
  'Checkout.Vat.Remove',
];

const defaultState = {
  unitPrice: '',
  taxPrice: '',
  priceWithDiscountApplied: '',
  promotionalDiscountPrice: '0.00',
  totalPrice: '',
  currency: '$',
  showCheckoutComplete: false,
  showAddVatReminder: false,
};

class PaddleCheckout extends Component {
  constructor(props) {
    super(props);

    this.state = defaultState;
  }

  updatePrices = (data) => {
    // console.warn('Paddle.Setup updatePrices eventCallback data:', data); //TODO: remove after testing on DEV

    const prices = data.eventData?.checkout?.prices?.customer;
    const linePrice = data.eventData?.checkout?.prices?.customer?.items[0]?.line_price;
    const { net_after_discount, net_discount, tax_after_discount, gross_after_discount } = linePrice;
    if (!linePrice) return;
    // The data.event will specify the event type
    if (data.event === 'Checkout.Loaded') {
      this.setState({ unitPrice: net_after_discount });
    }
    if (paddleEventsForUpdateDiscount.includes(data.event)) {
      this.setState({ promotionalDiscountPrice: net_discount });
    }
    if (data.event === 'Checkout.Complete') {
      this.setState({ showCheckoutComplete: true, showAddVatReminder: false });
      setTimeout(() => {
        this.props.refreshSubscriptionDetails(); // Wait at least 3sec until we call api to get new subscription details. Paddle Webhook may delay.
      }, 3000);
    }
    if (data.event === 'Checkout.Location.Submit' || data.event === 'Checkout.Vat.Remove') {
      this.setState({ showAddVatReminder: true });
    }
    if (data.event === 'Checkout.Vat.Applied') {
      this.setState({ showAddVatReminder: false });
    }
    this.setState({ currency: getSymbolFromCurrency(prices.currency) });
    this.setState({ taxPrice: tax_after_discount });
    this.setState({ totalPrice: gross_after_discount });
  };

  componentDidUpdate(prevProps, prevState) {
    const { isModalOpen, modalView, updateSubscriptionStatus } = this.props;
    const { promotionalDiscountPrice } = this.state;

    if (this.props.getPaddleCheckoutEvents !== prevProps.getPaddleCheckoutEvents) {
      this.updatePrices(this.props.getPaddleCheckoutEvents);
    }

    if (!prevProps.isModalOpen && isModalOpen) {
      this.resetToDefaultValues();
      this.handleOnModalViewChange(upgradeModalView.INITIAL);
    }

    if (prevState.promotionalDiscountPrice !== promotionalDiscountPrice) {
      this.setState({
        promotionalDiscountPrice: promotionalDiscountPrice,
      });
    }

    if (prevProps.modalView !== modalView && prevProps.modalView === upgradeModalView.CHECKOUT_FORM) {
      // Reset checkout screen state.
      this.setState(defaultState);
    }
    if (
      prevProps.updateSubscriptionStatus !== updateSubscriptionStatus &&
      (updateSubscriptionStatus === apiStatus.SUCCEEDED || updateSubscriptionStatus === apiStatus.FAILED)
    ) {
      this.handleOnModalViewChange(upgradeModalView.UPDATE_SUBSCRIPTION_FEEDBACK);
    }
  }

  resetToDefaultValues() {
    const activePlanIndex = Math.max(this.props.activePlanIndex, 1);
    this.props.setSubscriptionType(subscriptionType.YEARLY);
    this.props.setSubscriptionPlan(activePlanIndex, activePlanIndex);
  }

  handleOnModalViewChange(modalView, previousModalView) {
    this.props.setCardView(this.props.currentModalName, modalView, previousModalView);
  }

  render() {
    return (
      <CheckoutCard
        unitPrice={this.state.unitPrice}
        taxPrice={this.state.taxPrice}
        promotionalDiscountPrice={this.state.promotionalDiscountPrice}
        totalPrice={this.state.totalPrice}
        currency={this.state.currency}
        showCheckoutComplete={this.state.showCheckoutComplete}
        showAddVatReminder={this.state.showAddVatReminder}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isModalOpen: isModalOpen(state, ownProps.currentModalName),
    modalView: getCardView(state, ownProps.currentModalName),
    previousModalView: getPreviousCardView(state, ownProps.currentModalName),
    getPaddleCheckoutEvents: getPaddleCheckoutEvents(state),
    activePlanIndex: getPlanIndex(state),
  };
};

const mapDispatchToProps = {
  setCardView: setCardView,
  setSubscriptionType: setSubscriptionType,
  setSubscriptionPlan: setSubscriptionPlan,
  refreshSubscriptionDetails: refreshSubscriptionDetails,
  setPaddleCallbackEvent: setPaddleCallbackEvent,
};

PaddleCheckout.propTypes = {
  currentModalName: PropTypes.string.isRequired,
  //connect
  setCardView: PropTypes.func.isRequired,
  setSubscriptionType: PropTypes.func.isRequired,
  setSubscriptionPlan: PropTypes.func.isRequired,
  refreshSubscriptionDetails: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  modalView: PropTypes.string.isRequired,
  previousModalView: PropTypes.string.isRequired,
  activePlanIndex: PropTypes.number.isRequired,
  updateSubscriptionStatus: PropTypes.oneOf(Object.values(apiStatus)).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaddleCheckout);
