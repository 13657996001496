import PropTypes from 'prop-types';
import { StatusPill } from './StatusPill';

export const StatusCell = ({ value }) => {
  return <StatusPill status={value} />;
};

StatusCell.propTypes = {
  value: PropTypes.number,
};
