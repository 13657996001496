import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { apiStatus, modalName } from '@va/constants';
import { requestSettings, resetRequestStatus, signDPA } from '@va/dashboard/actions/api';
import { closeModal } from '@va/dashboard/actions/ui';
import ModalWrapper from '@va/dashboard/shared/modals/ModalWrapper';
import { getRequestStatus } from '@va/dashboard/selectors/api';
import { getWindowWidth, isModalOpen } from '@va/dashboard/selectors/ui';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import { someInProgress } from '@va/util/helpers';
import SignDPACard from './SignDPACard';

class SignDPAContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.onModalClose = this.onModalClose.bind(this);
  }

  onModalClose() {
    if (!someInProgress(this.props.signDPAStatus, this.props.requestSettingsStatus)) {
      this.props.resetRequestStatus();
      this.props.closeModal();
    }
  }

  render() {
    return (
      <ModalWrapper
        isOpen={this.props.isModalOpen}
        closeModal={this.onModalClose}
        showCloseButton={!someInProgress(this.props.signDPAStatus, this.props.requestSettingsStatus)}
      >
        <SignDPACard
          translate={this.props.translate}
          signAgreement={this.props.signAgreement}
          signDPAStatus={this.props.signDPAStatus}
          requestSettingsStatus={this.props.requestSettingsStatus}
          requestSettings={this.props.requestSettings}
          closeModal={this.onModalClose}
          windowWidth={this.props.windowWidth}
          isModalOpen={this.props.isModalOpen}
          dpaLink={this.props.dpaLink}
        />
      </ModalWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    requestSettingsStatus: getRequestStatus(state, 'settings'),
    signDPAStatus: getRequestStatus(state, 'signDPA'),
    isModalOpen: isModalOpen(state, modalName.signDPA),
    windowWidth: getWindowWidth(state),
  };
}

const mapDispatchToProps = {
  closeModal: () => closeModal(modalName.signDPA),
  resetRequestStatus: () => resetRequestStatus('signDPA'),
  requestSettings: requestSettings,
  signAgreement: signDPA,
};

SignDPAContainer.propTypes = {
  //Required external props
  dpaLink: PropTypes.string.isRequired,

  //Own props
  translate: PropTypes.func.isRequired,
  signAgreement: PropTypes.func.isRequired,
  signDPAStatus: PropTypes.oneOf(Object.values(apiStatus)).isRequired,
  resetRequestStatus: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  windowWidth: PropTypes.number.isRequired,
  requestSettings: PropTypes.func.isRequired,
  requestSettingsStatus: PropTypes.oneOf(Object.values(apiStatus)).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(SignDPAContainer));
