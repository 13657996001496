import { Iterable, Map } from 'immutable';
import { applyMiddleware, createStore as reduxCreateStore, Reducer } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { crashReporterMiddleware } from '../middleware/crashReporterMiddleware';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createLogger } from 'redux-logger';

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const toJS = (immutable: Iterable<string, unknown>): string | Record<string, unknown> => {
  if (Iterable.isIterable(immutable)) {
    if (immutable.count() > 3000) {
      return `Collection of ${immutable.count()} items, skipping.`;
    } else {
      return immutable
        .toSeq()
        .map((item) => toJS(item as Iterable<string, unknown>))
        .toJS();
    }
  }
  return immutable;
};

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const loggerMiddleware = createLogger({
  stateTransformer: (state) => toJS(state),
  titleFormatter: (action, time, took) => `${action.type} @ ${time} (in ${took.toFixed(2)} ms)`,
  duration: true,
  collapsed: (state, action) => !action.error,
  level: (action) => {
    return action.error ? 'error' : 'info';
  },
});

export const createStore = (reducer: Reducer, sagas: () => Generator, history = null) => {
  const sagaMiddleware = createSagaMiddleware({
    onError: handleSagaError,
    context: { history },
  });
  const middleWares = [
    sagaMiddleware,
    //loggerMiddleware,
    crashReporterMiddleware,
  ];
  const finalMiddleWares = middleWares.filter((middleWare) => middleWare != null);

  const environment = import.meta.env.MODE;
  let store;
  if (environment === 'production') {
    store = reduxCreateStore(reducer, Map(), applyMiddleware(...finalMiddleWares));
  } else {
    store = reduxCreateStore(reducer, Map(), composeWithDevTools(applyMiddleware(...finalMiddleWares)));
  }

  sagaMiddleware.run(sagas);
  return store;
};

const handleSagaError = (error: Error) => {
  console.error('Error logged by Saga error handler:', error);
};
