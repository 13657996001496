import { createColumnHelper } from '@tanstack/react-table';
import { Platform, TEST_IDS, platform } from '@va/constants';
import { startUpgradeProcess } from '@va/dashboard/actions/app';
import { CoinsIcon, LightningIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { setActiveWebsite } from '@va/standalone/shared/actions';
import { getActiveWebsiteId } from '@va/standalone/shared/selectors';
import { HeaderCellV8 } from '@va/ui/components/data-table';
import { Button, Paragraph, ParagraphWithTooltip } from '@va/ui/design-system';
import { DropdownArrow } from '@va/util/components';
import { removeProtocol } from '@va/util/helpers';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WebsiteType } from '../../agency-white-label-v2/agency-white-label-apiClient';
import PlanInfoCell from '../../agency-white-label-v2/components/PlanInfoCell';
import TrackingCodeStatus from '../../agency-white-label-v2/components/TrackingCodeStatus';
import WhiteLabelStatusCell from '../../agency-white-label-v2/components/WhiteLabelStatusCell';

const columnHelper = createColumnHelper<WebsiteType>();

export const useCommissionDetailsColumns = () => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const activeWebsiteId = useSelector(getActiveWebsiteId);

  const columns = useMemo(
    () => [
      columnHelper.accessor('url', {
        meta: {
          cellClassName: '!justify-start',
        },
        minSize: 210,
        header: (context) => (
          <HeaderCellV8 context={context} text={translate('commissionDetails.table.header.websiteName')} />
        ),
        cell: ({ row }) => {
          const { url, id } = row.original;

          function handleSelectedWebsite() {
            dispatch(setActiveWebsite(id, false, true));
          }

          return (
            <ParagraphWithTooltip
              onClick={handleSelectedWebsite}
              className='font-medium text-15 leading-normal text-mine-shaft cursor-pointer truncate'
            >
              {removeProtocol(url)}
            </ParagraphWithTooltip>
          );
        },
        enableSorting: true,
      }),
      columnHelper.accessor('trackingCodeInstalled', {
        header: (context) => (
          <HeaderCellV8 context={context} text={translate('commissionDetails.table.header.trackingStatus')} />
        ),
        cell: ({ getValue }) => {
          return <TrackingCodeStatus isInstalled={getValue()} />;
        },
      }),
      columnHelper.group({
        id: 'whiteLabel',
        header: (context) => (
          <HeaderCellV8 context={context} text={translate('commissionDetails.table.header.whiteLabelStatus')} />
        ),
        cell: ({ row }) => {
          const { id: websiteId, platform, types, whiteLabel } = row?.original ?? {};
          return (
            <WhiteLabelStatusCell
              whiteLabelEnabled={whiteLabel as boolean}
              websiteId={websiteId}
              platform={platform as Platform}
              userRole={types[0]}
            />
          );
        },
      }),
      columnHelper.group({
        id: 'subscription',
        meta: {
          cellClassName: '!justify-start',
        },
        minSize: 180,
        header: (context) => (
          <HeaderCellV8 context={context} text={translate('commissionDetails.table.header.pricingPlan')} />
        ),
        cell: ({ row }) => {
          const { subscription } = row.original;
          return <PlanInfoCell subscriptionInfo={subscription} websiteInfo={row.original} showPlanFrequency={false} />;
        },
      }),
      columnHelper.accessor('commission', {
        header: (context) => (
          <HeaderCellV8
            context={context}
            text={translate('commissionDetails.table.header.commissionRevenue')}
            tooltip={translate('commission.table.header.commissionRevenueTooltip')}
          />
        ),
        minSize: 180,
        cell: ({ getValue }) => {
          return (
            <div className='flex gap-6px items-center'>
              <CoinsIcon color='#696969' className='!h-3 !w-3' />
              <Paragraph colorClassName='#3C3C3C'>{getValue() ?? '0.00'}$</Paragraph>
            </div>
          );
        },
      }),
      columnHelper.group({
        id: 'options',
        header: (context) => (
          <HeaderCellV8
            context={context}
            text={translate('commissionDetails.table.header.options')}
            tooltip={translate('commission.table.header.optionsTooltip')}
          />
        ),
        minSize: 290,
        cell: ({ row }) => {
          const { id: websiteId, platform: websitePlatform, unlimitedPlanActive } = row.original;

          const isWixWebsite = websitePlatform === platform.WIX;

          const buttonText = translate(
            isWixWebsite ? 'commission.website.upgradeOnWix' : 'commission.website.upgradePlan',
          );

          const handleUpgradeClick = () => {
            if (websiteId !== activeWebsiteId) {
              dispatch(setActiveWebsite(websiteId));
            }
            dispatch(startUpgradeProcess());
          };

          const disabled = isWixWebsite || unlimitedPlanActive;

          return (
            <Button
              color='tertiary'
              text={buttonText}
              icon={(_, color) => <LightningIcon color={color} className='shrink-0' />}
              rightSideIcon={
                !isWixWebsite && (
                  <DropdownArrow
                    open
                    color={disabled ? 'var(--color-primary-lighter-20)' : '#969696'}
                    className='shrink-0 ml-auto'
                  />
                )
              }
              className='w-full xl:max-w-270px'
              onClick={() => {
                if (isWixWebsite) return;
                handleUpgradeClick();
              }}
              disabled={disabled}
              tooltip={unlimitedPlanActive ? translate('tooltips.upgradeNotAvailable.hasUnlimitedVoucher') : null}
              data-testid={TEST_IDS.generic.whiteLabel.upgradePlanBtn}
            />
          );
        },
      }),
    ],
    [activeWebsiteId, dispatch, translate],
  );
  return columns;
};
