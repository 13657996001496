import { onContactSupport } from '@va/dashboard/util-helpers/misc';
import { CustomerSupport, GoBackIcon, LogoutIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { logout } from '@va/standalone/shared/actions';
import { Button } from '@va/ui/design-system';
import { isStandaloneApp } from '@va/util/helpers';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import './styles.scss';

type GeneralErrorPageProps = {
  status?: string;
  title?: string;
  canGoBack?: boolean;
  statusClassNames?: string;
  titleClassNames?: string;
};

export const GeneralErrorPage = ({
  title,
  status,
  canGoBack = true,
  statusClassNames,
  titleClassNames,
}: GeneralErrorPageProps) => {
  const translate = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();

  const locationState = (history.location.state as GeneralErrorPageProps) ?? {};

  const titleText = useMemo(() => {
    if (locationState.title) return translate(locationState.title);
    if (title) return title;
    return translate('generalErrorPage.desc');
  }, [locationState.title, title, translate]);

  const displayGoBackButton = useMemo(() => {
    if (locationState?.canGoBack) return true;
    if (locationState?.canGoBack === false) return false;
    return canGoBack;
  }, [canGoBack, locationState?.canGoBack]);

  const handleGoBack = () => {
    history.goBack();
  };
  const handleLogOut = () => {
    dispatch(logout());
  };

  return (
    <div className='general-error-page flex items-center justify-end h-full flex-col'>
      <div className='flex items-center justify center flex-col pt-20 w-2/3 md:w-[56%] h-full'>
        <div
          className={classNames(
            'text-center font-bold text-8xl md:text-[180px] md:leading-[180px] tracking-0036 text-primary',
            statusClassNames,
          )}
        >
          {status ?? 404}
        </div>
        <div
          className={classNames(
            'text-center font-medium text-xl md:text-30 md:leading-9 tracking-n0024 mb-3 text-primary',
            titleClassNames,
          )}
        >
          {titleText}
        </div>
        <div className='w-full md:w-[70%] flex flex-col items-center gap-18px mb-8 md:mb-16'>
          {displayGoBackButton && (
            <Button
              className='!rounded-12 w-full h-12 flex items-center justify-center md:w-80'
              icon={(iconClasses, iconColor) => (
                <GoBackIcon className={classNames('w-6 h-6', iconClasses)} color={iconColor} />
              )}
              text={translate('generalErrorPage.goBack')}
              color={'primary'}
              onClick={handleGoBack}
            />
          )}
          {isStandaloneApp() && (
            <Button
              className='!rounded-12 w-full h-12 flex items-center justify-center md:w-80'
              icon={(iconClasses, iconColor) => (
                <LogoutIcon className={classNames('w-6 h-6', iconClasses)} color={iconColor} />
              )}
              text={translate('generalErrorPage.logOut')}
              color={'primary'}
              onClick={handleLogOut}
            />
          )}
        </div>

        <div className='flex flex-col w-full gap-18px justify-center items-center pb-[80px] mt-5 md:mt-36'>
          <div className='font-medium text-15 md:text-21 text-devil-gray leading-30 text-center'>
            {translate('generalErrorPage.needHelp')}
          </div>
          <Button
            onClick={onContactSupport}
            className='w-full md:w-80 flex justify-center items-center'
            icon={() => <CustomerSupport color={'#3c3c3c'} />}
            text={translate('button.contactSupport')}
            color='tertiary'
          />
        </div>
      </div>

      <div className='blob -z-1 -top-1/2 rounded-full absolute ' />
    </div>
  );
};
