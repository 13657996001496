import { DEFAULT_REPORT_BLOCK_IDS, tabNames } from '@va/constants';
import { PageContent } from '@va/dashboard/shared/ui-layout';
import { useActiveTab } from '@va/dashboard/util-hooks';
import { isUserLoggedInWithCustomUrl } from '@va/standalone/shared/helpers';
import { isAgencyAccount, isAgencyOwner } from '@va/standalone/shared/selectors';

import { FiltersContextProvider } from '@va/shared/feature-filters';

import { GeneralErrorPage } from '@va/dashboard/ui/pages';
import { useSelector } from 'react-redux';
import { AgencyWhiteLabelContextProvider } from '../agency-white-label-v2/AgencyWhiteLabelContext';
import useWhiteLabelTabsFilterOptions from '../agency-white-label-v2/useWhiteLabelTabsFilterOptions';
import CommissionDetailsTable from './commission-details/CommissionDetailsTable';
import WelcomePageAgencyCommission from './welcome-page-agency-commission';

const AgencyCommission = () => {
  const agencyAccount = useSelector(isAgencyAccount); //TODO verify if user has agency subscription
  const agencyOwner = useSelector(isAgencyOwner);

  const filterOptions = useWhiteLabelTabsFilterOptions();

  useActiveTab(tabNames.agencyCommission);

  if (isUserLoggedInWithCustomUrl() && !agencyOwner) {
    return <GeneralErrorPage />;
  }

  return (
    <>
      <FiltersContextProvider
        reportBlockId={DEFAULT_REPORT_BLOCK_IDS.agencyCommisionTable}
        allFilterOptions={filterOptions}
      >
        <AgencyWhiteLabelContextProvider tab={tabNames.agencyCommission}>
          <PageContent>{agencyAccount ? <CommissionDetailsTable /> : <WelcomePageAgencyCommission />}</PageContent>
        </AgencyWhiteLabelContextProvider>
      </FiltersContextProvider>
    </>
  );
};

export default AgencyCommission;
