import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { apiStatus } from '@va/constants';
import LoadingCard from '@va/deprecated/components/PopUpCard/card-types/LoadingCard';
import { renderIf } from '@va/util/helpers';

import MessageCard from '@va/deprecated/components/PopUpCard/card-types/MessageCard';
import { someInProgress, successOrFailedStatus } from '@va/util/helpers';
import LeaveFeedback from './LeaveFeedback';

export default class CancelSubscriptionComponent extends PureComponent {
  render() {
    const { cancelSubscriptionStatus } = this.props;
    const showViews = {
      showLeaveFeedback: cancelSubscriptionStatus === apiStatus.NONE,
      showMessage: successOrFailedStatus(cancelSubscriptionStatus),
      showLoading: someInProgress(cancelSubscriptionStatus),
      apiError: cancelSubscriptionStatus === apiStatus.FAILED,
    };

    const renderLeaveFeedback = renderIf(showViews.showLeaveFeedback);
    const renderMessage = renderIf(showViews.showMessage);
    const renderLoading = renderIf(showViews.showLoading);

    return (
      <div className='modal-content'>
        {renderLeaveFeedback(<LeaveFeedback {...this.props} />)}

        {renderMessage(
          <MessageCard
            modalName='cancelSubscription'
            translate={this.props.translate}
            onClose={this.props.closeModal}
            apiError={showViews.apiError}
          />,
        )}

        {renderLoading(<LoadingCard />)}
      </div>
    );
  }
}

CancelSubscriptionComponent.propTypes = {
  translate: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  cancelSubscriptionStatus: PropTypes.oneOf(Object.values(apiStatus)).isRequired,
};
