import moment from 'moment';
import { getTwoDigitFormat } from '../number';
import { capitalizeFirstLetter } from '../string/string.helpers';

export function getTimeFromSeconds(seconds: number) {
  const days = Math.floor(seconds / (24 * 3600));
  seconds -= days * (24 * 3600);

  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;

  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  const daysId = days > 1 ? 'days' : 'day';
  const hoursId = hours > 1 ? 'hours' : 'hour';
  const minutesId = minutes > 1 ? 'minutes' : 'minute';
  const secondsId = seconds > 1 ? 'seconds' : 'second';

  let id = '';
  if (days > 0) {
    id = daysId + (hours > 0 ? capitalizeFirstLetter(hoursId) : '');
  } else if (hours > 0) {
    id = hoursId + (minutes > 0 ? capitalizeFirstLetter(minutesId) : '');
  } else if (minutes > 0) {
    id = minutesId;
  } else if (seconds > 0) {
    id = secondsId;
  } else {
    return [null];
  }

  return [
    `inTime.${id}`,
    {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    },
  ];
}

export function elapsedTimeSince(unixTs: number) {
  let seconds = Math.round(new Date().getTime() / 1000) - unixTs;

  const days = Math.floor(seconds / (24 * 3600));
  seconds -= days * (24 * 3600);

  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;

  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  return { days, hours, minutes, seconds };
}

export function getElapsedTimeTranslationParameters(unixTs: number, timezone: string) {
  const elapsedTime = elapsedTimeSince(unixTs);

  if (elapsedTime.days >= 1) {
    return [
      moment(unixTs * 1000)
        .tz(timezone)
        .format('L') +
        ' ' +
        moment(unixTs * 1000)
          .tz(timezone)
          .format('LT'),
      elapsedTime,
    ];
  }

  const days = elapsedTime.days;
  const hours = elapsedTime.hours;
  const minutes = elapsedTime.minutes;
  const seconds = elapsedTime.seconds;

  const daysId = days > 1 ? 'days' : 'day';
  const hoursId = hours > 1 ? 'hours' : 'hour';
  const minutesId = minutes > 1 ? 'minutes' : 'minute';
  const secondsId = seconds > 1 ? 'seconds' : 'second';

  let id = '';
  if (days > 0) {
    id = daysId + (hours > 0 ? capitalizeFirstLetter(hoursId) : '');
  } else if (hours > 0) {
    id = hoursId + (minutes > 0 ? capitalizeFirstLetter(minutesId) : '');
  } else if (minutes > 0) {
    id = minutesId;
  } else {
    id = secondsId;
  }

  return [`time.${id}`, elapsedTime];
}

export function isToday(timestamp: number) {
  const today = moment();
  return today.diff(moment(timestamp * 1000), 'd') === 0;
}

/**
 * @deprecated Only used in one place, should be deleted
 */
export function getHourlyTimeDurationFromMs(ms: number) {
  if (!ms || ms < 1000) return '00:00:00';

  let seconds = Math.floor(ms / 1000);
  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  return [getTwoDigitFormat(hours), getTwoDigitFormat(minutes), getTwoDigitFormat(seconds)].join(':');
}

export function formatMomentDuration(duration: moment.Duration) {
  const seconds = duration.seconds();
  const minutes = duration.minutes();
  const hours = duration.hours();
  const days = duration.days();
  return { seconds, minutes, hours, days };
}

export function formatTime(data: { seconds: number; minutes: number; hours: number; days: number }) {
  const { seconds, minutes, hours, days } = data;

  const formattedDays = days !== 0 ? (days < 9 ? '0' + days + ':' : days + ':') : '';
  const formattedHours = hours !== 0 ? (hours < 9 ? '0' + hours + ':' : hours + ':') : days !== 0 ? '00:' : '';
  const formattedMinutes = minutes > 9 ? minutes + ':' : '0' + minutes + ':';
  const formattedSeconds = seconds > 9 ? seconds : '0' + seconds;

  return formattedDays + formattedHours + formattedMinutes + formattedSeconds;
}

export const formatSessionDuration = (duration: number) => {
  return formatTime(formatMomentDuration(moment.duration(duration)));
};

/**
 *
 * @deprecated use isInThePast function instead
 *
 */
export function hasPassedTimeUntil(timeTs: number, untilTs: number) {
  return moment().unix() - untilTs > timeTs;
}

/**
 *
 * @param baseTime milliseconds
 * @param offset seconds
 */
export function isInThePast(baseTime: number, offset = 0) {
  return moment().diff(baseTime, 'seconds') > offset;
}

export function calculateMillisecondsInDays(daysNumber: number) {
  return daysNumber * 60 * 60 * 24 * 1000;
}
