import classNames from 'classnames';
import './LoaderBar.scss';

export const LoaderBar = ({ className }: { className?: string }) => {
  return (
    <div className={classNames('loader-bar-wrapper', className)}>
      <span className='loader-bar' />
    </div>
  );
};
