import { TEST_IDS } from '@va/constants';
import { MinusIcon, PlusIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { ButtonLoader } from '@va/svg-visa-icons';
import { Button, InputMessage as ErrorMessage, Paragraph, paragraphSizes } from '@va/ui/design-system';
import { useFileUpload } from '@va/util/hooks';
import classNames from 'classnames';
import { useMemo } from 'react';

export const UploadLogo = ({
  name,
  onChange,
  value,
  onClear,
  error,
  isLoading,
  disabled = false,
  onUploadError,
}: {
  name: string;
  onChange: (file: File) => void;
  onUploadError: (message: string) => void;
  value: string | File | null;
  onClear: () => void;
  error?: string;
  isLoading: boolean;
  disabled?: boolean;
}) => {
  const translate = useTranslate();

  const { getRootProps, getInputProps, preview } = useFileUpload({ onChange, value, disabled, onError: onUploadError });

  const image = useMemo(() => {
    if (isLoading) {
      return <ButtonLoader color='var(--color-primary)' />;
    }

    if (value) {
      return <img className='object-contain max-h-full max-w-full' src={preview} alt='preview' />;
    }

    return <PlusIcon className='h-60 w-60' />;
  }, [isLoading, value, preview]);

  return (
    <div
      className={classNames('flex flex-col w-full lg:w-270px', {
        'pointer-events-none': disabled,
      })}
    >
      <div
        className='bg-gray-concrete hover:bg-gray-alto rounded-30 p-3 cursor-pointer group z-1 w-full h-180px'
        {...getRootProps()}
      >
        <input name={name} disabled={!!value} {...getInputProps()} />
        <div className='flex items-center justify-center w-full h-full border-dashed hover:border-solid border-2 border-gray-dustySolid hover:border-gray-concrete rounded-30 p-3 relative'>
          {value && (
            <Button
              color='tertiary'
              className='absolute bottom-3 right-3 hidden group-hover:flex z-999'
              icon={() => <MinusIcon color='#ED290E' />}
              onClick={onClear}
              data-testid={TEST_IDS.generic.buttons.erase}
            />
          )}
          {image}
        </div>
      </div>
      {error && <ErrorMessage error={error} />}
      <Paragraph size={paragraphSizes.tiny} className='mt-3 text-center' colorClassName='text-gray-dustySolid'>
        {translate('uploadLogo.supportedFilesFormat')}
      </Paragraph>
      <Paragraph size={paragraphSizes.tiny} className='text-center' colorClassName='text-gray-dustySolid'>
        {translate('uploadLogo.maxUploadFileSize')}
      </Paragraph>
    </div>
  );
};
