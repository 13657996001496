import { tabNames, websiteCreationSteps } from '@va/constants';
import { useActiveTab } from '@va/dashboard/util-hooks';
import { BackIconRounded } from '@va/icons';
import { useTranslate } from '@va/localization';
import { getWebsiteCreationStep } from '@va/standalone/shared/selectors';
import { Button, Heading5, MultiStepWizard, Paragraph, paragraphSizes } from '@va/ui/design-system';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useAddWebsiteSteps } from './useAddWebsiteSteps';

export const AddWebsitePage = () => {
  const translate = useTranslate();
  const history = useHistory();

  const activeStep = useSelector(getWebsiteCreationStep);
  const { steps, changeActiveStep } = useAddWebsiteSteps();

  useActiveTab(tabNames.addWebsite);

  const isBackButtonVisible = useMemo(() => steps[activeStep]?.canReturn, [activeStep, steps]);

  const onBackClick = useCallback(() => {
    if (activeStep === websiteCreationSteps.choosePlatform) {
      history.push('/my-account/websites');
      return;
    }
    changeActiveStep(activeStep - 1);
  }, [activeStep, changeActiveStep, history]);

  useEffect(() => {
    return () => {
      changeActiveStep(websiteCreationSteps.choosePlatform);
    };
  }, [changeActiveStep]);

  return (
    <div className='min-h-[90vh] flex flex-col'>
      <div className='grow'>
        <div className='px-3 h-[82.5px] flex justify-center items-center w-full border-b-[1.5px] border-gray-mercury'>
          <Button
            className={classNames({
              invisible: !isBackButtonVisible,
            })}
            onClick={onBackClick}
            color='tertiary'
            icon={(_, color) => <BackIconRounded color={color} />}
          />
          <Heading5 className='!text-15 grow !font-525 text-center sm-initial:!text-21 sm-initial:!font-medium'>
            {translate('standalone.websiteCreation.pageTitle')}
          </Heading5>
        </div>
        <div className='mt-6 px-3 md:mt-[72px]'>
          <MultiStepWizard onStepChange={changeActiveStep} activeStep={activeStep} steps={steps} />
        </div>
      </div>
      <div>
        <Paragraph className='text-center p-3 text-gray-devil' size={paragraphSizes.tiny}>
          {translate('app.copyrightText')}
        </Paragraph>
      </div>
    </div>
  );
};
