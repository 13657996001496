import { TEST_IDS, websiteUrlRegex } from '@va/constants';
import { CloseIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { RouterWarningModal } from '@va/shared/util-confirmation';
import { Button, InputMessage, Paragraph, TextInput, fontWeights } from '@va/ui/design-system';
import { useFormik } from 'formik';
import { ChangeEvent, useMemo } from 'react';
import { object, string } from 'yup';
import { SectionHeader } from '../misc';

type ValuesType = {
  legalNoticeLink: string;
  privacyPolicyLink: string;
};

const formFieldNames = {
  legalNoticeLink: 'legalNoticeLink',
  privacyPolicyLink: 'privacyPolicyLink',
};

export const LegalLinksComponent = ({
  initialFormValues,
  onSubmit,
  isLoading,
  saveButtonTooltip,
}: {
  initialFormValues: ValuesType;
  onSubmit: (values: ValuesType) => void;
  isLoading: boolean;
  saveButtonTooltip?: string;
}) => {
  const translate = useTranslate();

  const {
    values: { legalNoticeLink, privacyPolicyLink },
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValues,
    validationSchema: validationSchema(translate),
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const clearField = (fieldName: string) => {
    setFieldValue(fieldName, '');
  };

  const hasSomethingChanged = useMemo(() => {
    return (
      initialFormValues.legalNoticeLink !== legalNoticeLink || initialFormValues.privacyPolicyLink !== privacyPolicyLink
    );
  }, [initialFormValues.legalNoticeLink, initialFormValues.privacyPolicyLink, legalNoticeLink, privacyPolicyLink]);

  const isSubmitButtonDisabled = useMemo(() => !hasSomethingChanged || isLoading, [hasSomethingChanged, isLoading]);

  return (
    <>
      <RouterWarningModal when={hasSomethingChanged} />
      <div className='flex flex-col gap-18px'>
        <SectionHeader
          title={translate('whiteLabelCustomization.legalLink.title')}
          description={translate('whiteLabelCustomization.legalLink.subTitle')}
        />
        <form onSubmit={handleSubmit} className='flex flex-col gap-18px'>
          <LinkInputField
            name={formFieldNames.legalNoticeLink}
            label={translate('whiteLabelCustomization.legalLink.form.legalNoticeLink.label')}
            onChange={handleChange}
            value={legalNoticeLink}
            error={touched.legalNoticeLink ? errors.legalNoticeLink : ''}
            clearField={(name) => clearField(name)}
          />
          <LinkInputField
            name={formFieldNames.privacyPolicyLink}
            label={translate('whiteLabelCustomization.legalLink.form.privacyPolicyLink.label')}
            onChange={handleChange}
            value={privacyPolicyLink}
            error={touched.privacyPolicyLink ? errors.privacyPolicyLink : ''}
            clearField={(name) => clearField(name)}
          />
          <Button
            type={isSubmitButtonDisabled ? 'button' : 'submit'}
            text={isLoading ? translate('button.loading') : translate('button.saveChanges')}
            color='tertiary'
            className='w-full'
            disabled={isSubmitButtonDisabled}
            tooltip={saveButtonTooltip}
            data-testid={TEST_IDS.generic.buttons.submit}
          />
        </form>
      </div>
    </>
  );
};

export const LinkInputField = ({
  name,
  label,
  onChange,
  value,
  error,
  clearField,
}: {
  name: string;
  label: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  error: string | undefined;
  clearField: (fieldName: string) => void;
}) => {
  return (
    <div className='flex flex-col'>
      <div className='flex items-center py-1.5 pl-6 pr-3 bg-white-snow rounded-18 gap-3'>
        <Paragraph weight={fontWeights.medium} colorClassName='text-gray-devilSolid'>
          https://
        </Paragraph>
        <TextInput
          label={label}
          name={name}
          onChange={onChange}
          clearField={() => clearField(name)}
          wrapperClassName='w-full'
          bgColor='#F0F0F0'
          bgColorHover='#E1E1E1'
          value={value}
          clearIcon={<CloseIcon color='#3C3C3C' />}
        />
      </div>
      <InputMessage error={error} />
    </div>
  );
};

const validationSchema = (translate: Function) => {
  return object().shape({
    legalNoticeLink: string().matches(
      websiteUrlRegex,
      translate('whiteLabelCustomization.legalLink.form.legalNoticeLink.error'),
    ),
    privacyPolicyLink: string().matches(
      websiteUrlRegex,
      translate('whiteLabelCustomization.legalLink.form.privacyPolicyLink.error'),
    ),
  });
};
