import { CountryLocationIcon } from '@va/dashboard/components';
import { useTranslate } from '@va/localization';
import { LocationType } from '@va/types/location';
import { VisitorTypes } from '@va/types/visitors';
import { fontWeights, Paragraph, paragraphSizes, ParagraphWithTooltip } from '@va/ui/design-system';
import classNames from 'classnames';
import React from 'react';

type VisitorDetailsCellProps = {
  visitorType: VisitorTypes;
  countryCode: string;
  location: LocationType;
  size?: 'small' | 'medium';
  squareIcon?: boolean;
  isClickable?: boolean;
};

export const VisitorDetailsCell: React.FC<VisitorDetailsCellProps> = ({
  visitorType,
  countryCode,
  location,
  size = 'medium',
  squareIcon,
  isClickable = true,
}) => {
  const translate = useTranslate();
  return (
    <div
      className={classNames('flex items-center justify-start max-w-full w-full overflow-hidden', {
        'gap-2': size === 'medium',
        'gap-1.5': size === 'small',
      })}
    >
      <CountryLocationIcon
        countryCode={countryCode}
        location={location}
        size={size}
        square={squareIcon}
        isClickable={isClickable}
      />
      <div className='text-start'>
        <ParagraphWithTooltip
          size={paragraphSizes.tiny}
          className='truncate whitespace-nowrap'
          weight={fontWeights.medium}
        >
          {translate(`card.latestVisits.${VisitorTypes[visitorType]}`)}
        </ParagraphWithTooltip>
        <Paragraph colorClassName='text-text-secondary' size={paragraphSizes.tiny} weight={fontWeights.medium}>
          {countryCode ? translate(`country.name.${countryCode}`) : '-'}
        </Paragraph>
      </div>
    </div>
  );
};
