import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { renderField } from './form-helpers/form-helper';

const FormInput = (props) => {
  const {
    name,
    type,
    placeholder,
    placeholderTransParams,
    validate,
    errorTransParams,
    icon,
    rightInputIcon,
    translate,
    required,
    onChange,
    shouldAutoFocus,
    autoCompleteType,
    initialValue,
    disabled,
    hasAutoFill,
    untouch,
    normalize,
    inputClassName,
    showErrorWithoutTouching,
    hideLabel,
  } = props;

  return (
    <Field
      name={name}
      component={renderField}
      type={type}
      placeholder={translate(placeholder, placeholderTransParams)}
      className='form-input'
      inputClassName={inputClassName}
      showErrorWithoutTouching={showErrorWithoutTouching}
      validate={validate}
      errorTransParams={errorTransParams}
      icon={icon}
      rightInputIcon={rightInputIcon}
      translate={translate}
      shouldAutoFocus={shouldAutoFocus}
      autoCompleteType={autoCompleteType}
      initialValue={initialValue}
      disabled={disabled}
      required={required}
      hasAutoFill={hasAutoFill}
      untouch={untouch}
      normalize={normalize}
      onChange={onChange}
      hideLabel={hideLabel}
      min={props.min}
      max={props.max}
    />
  );
};

FormInput.propTypes = {
  placeholder: PropTypes.string,
  placeholderTransParams: PropTypes.object,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  validate: PropTypes.array,
  errorTransParams: PropTypes.object,
  icon: PropTypes.object,
  rightInputIcon: PropTypes.object,
  translate: PropTypes.func.isRequired,
  shouldAutoFocus: PropTypes.bool,
  autoCompleteType: PropTypes.oneOf(['email', 'new-password', 'current-password', 'off']),
  initialValue: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  hasAutoFill: PropTypes.bool,
  untouch: PropTypes.func,
  hideLabel: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default FormInput;
