import { Platform } from '@va/constants';
import { startUpgradeProcess } from '@va/dashboard/actions/app';
import { PlusIconFilled } from '@va/icons';
import { useTranslate } from '@va/localization';
import { APP_SUMO_PACKAGE_NAME, subscriptionStatus } from '@va/standalone/shared/constants';
import { isSumoUser } from '@va/standalone/shared/selectors';
import { WebsiteType } from '@va/types/website';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import SubscriptionTooltip from 'standalone/components/misc/SubscriptionTooltip';

const BillingPlan = ({ website }: { website: WebsiteType }) => {
  const translate = useTranslate();
  const sumoAccountType = useSelector(isSumoUser);
  const dispatch = useDispatch();
  const websiteSubscription = website?.subscription;
  const isOwner = !!website?.isWebsiteOwner;

  const hasActiveSubscription =
    websiteSubscription?.status &&
    [subscriptionStatus.ACTIVE, subscriptionStatus.PAUSED].includes(websiteSubscription?.status);

  const onUpgradeClick = () => {
    if (!isOwner) return;
    dispatch(startUpgradeProcess({ websiteId: website.id }));
  };

  const roleKey = (isOwner: boolean) => (isOwner ? 'owner' : 'contributor');

  const activeUntil = hasActiveSubscription && (websiteSubscription?.isActiveUntil || websiteSubscription?.activeUntil);

  const planName = websiteSubscription?.planName;

  //If it's a WIX website
  if (website.platform === Platform.WIX && roleKey(website.isWebsiteOwner as boolean) === 'owner')
    return (
      <button className='plan-link' onClick={onUpgradeClick}>
        {translate('plan.viewOnWix')}
      </button>
    );

  if (sumoAccountType && website.sumoActive && !hasActiveSubscription)
    return (
      <div onClick={onUpgradeClick} className='plan-green cursor-pointer'>
        {APP_SUMO_PACKAGE_NAME}
      </div>
    );

  const planNameWithSubscription = (
    <TooltipWrapper disabled={isOwner} content={translate('tooltips.contributorRole')}>
      <div
        onClick={onUpgradeClick}
        className={classNames('width-fit-content table-cell', {
          'text-orange-dark': activeUntil || !hasActiveSubscription,
          'text-green-green': !activeUntil && hasActiveSubscription,
          'cursor-not-allowed': !isOwner,
          'cursor-pointer': isOwner,
        })}
      >
        {planName}
      </div>
    </TooltipWrapper>
  );

  if (websiteSubscription && (activeUntil || !hasActiveSubscription))
    return (
      <div onClick={onUpgradeClick}>
        <TooltipWrapper
          interactive
          content={<SubscriptionTooltip activeSubscription={websiteSubscription} timezone={website.timezone} />}
        >
          {planNameWithSubscription}
        </TooltipWrapper>
      </div>
    );

  if (websiteSubscription && !activeUntil) return planNameWithSubscription;

  if (website.sumoActive) return null;

  return (
    <TooltipWrapper disabled={isOwner} content={translate('tooltips.contributorRole')}>
      <div
        className={classNames('flex', { 'cursor-not-allowed': !isOwner, 'cursor-pointer': isOwner })}
        onClick={onUpgradeClick}
      >
        <div className='text-orange-dark mr-4'>{translate('plan.free')}</div>
        <PlusIconFilled className='my-auto' />
      </div>
    </TooltipWrapper>
  );
};

BillingPlan.propTypes = {
  website: PropTypes.object.isRequired,
  hasActiveSubscription: PropTypes.bool,
};

export default BillingPlan;
