import { TEST_IDS } from '@va/constants';
import { useCheckPrivacySetting } from '@va/dashboard/util-hooks';
import { UserIcon } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { PRIVACY_FEATURES } from '@va/standalone/shared/types';
import { VisitorTypes } from '@va/types/visitors';
import { ListCardItemWithButton, ParagraphWithTooltip, paragraphSizes } from '@va/ui/design-system';
import { getFormattedDateAndTime } from '@va/util/helpers';
import { useHistory } from 'react-router';

export const StatusCell = ({
  status,
  unixTs,
  countryCode,
  id,
}: {
  status: VisitorTypes;
  unixTs: number;
  countryCode: string;
  id: string;
}) => {
  const translate = useTranslate();
  const { locale } = useLocale();
  const { isTrackingEnabled } = useCheckPrivacySetting();
  const isPageHistoryTrackingEnabled = isTrackingEnabled(PRIVACY_FEATURES.individualPageHistory, countryCode);
  const history = useHistory();

  return (
    <ListCardItemWithButton
      buttonDataTestId={TEST_IDS.generic.links.userHistory}
      icon={UserIcon}
      topText={`${translate(`card.latestVisits.${VisitorTypes[status]}`)}`}
      bottomNode={
        <ParagraphWithTooltip
          className='whitespace-nowrap text-left'
          size={paragraphSizes.tiny}
          colorClassName='text-gray-devil'
        >
          {getFormattedDateAndTime(unixTs, locale)}
        </ParagraphWithTooltip>
      }
      buttonTooltip={
        isPageHistoryTrackingEnabled
          ? translate('panels.latestVisitors.seeHistory')
          : translate('panels.latestVisitors.consentless.userType')
      }
      onClick={() => isPageHistoryTrackingEnabled && history.push(`/visitors/history/${id}`)}
    />
  );
};
