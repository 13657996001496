export const ChromeIcon: React.FC<JSX.IntrinsicElements['svg'] & { monochrome?: boolean }> = ({
  monochrome = false,
  ...props
}) => {
  if (monochrome)
    return (
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 24 24'
        {...props}
      >
        <path
          d='M22,7.5h-7.8c1.4,0.9,2.4,2.5,2.4,4.4c0,0.4-0.1,0.8-0.1,1.1v0.1c-0.1,0.6-0.3,1.1-0.6,1.6L11,23.3h0.4
c6.3,0,11.4-5,11.4-11.4C22.9,10.3,22.6,8.8,22,7.5z M12.5,22.5l4.2-7.3c0.4-0.7,0.6-1.3,0.7-1.9l0,0c0.1-0.4,0.1-0.9,0.1-1.3
c0-1.3-0.5-2.6-1.2-3.6h5.2c0.4,1.1,0.6,2.3,0.6,3.6C22.1,17.4,17.8,22,12.5,22.5z'
        />
        <path
          d='M11.6,17c-1.4,0-2.8-0.6-3.7-1.6c-0.4-0.4-0.7-0.7-1-1.1l-0.1-0.2L1.9,5.9c-1.1,1.7-1.7,3.8-1.7,6
c0,5.8,4.3,10.6,9.9,11.2l3.9-6.8C13.3,16.8,12.4,17,11.6,17z M9.7,22.3C4.7,21.5,1,17.1,1,11.9c0-1.5,0.3-3,1-4.4l4.2,7.1l0.1,0.2
c0.3,0.5,0.6,0.8,1,1.2c1.1,1.1,2.7,1.8,4.3,1.8c0.2,0,0.5,0,0.7,0L9.7,22.3z'
        />
        <path
          d='M11.6,0.5c-3.7,0-7.1,1.8-9.1,4.6l3.8,6.6c0.1,0.1,0.1,0.2,0.1,0.3v-0.2c0-2.5,1.9-4.7,4.2-5.1h0.2
c0.3,0,0.7-0.1,1.1-0.1h9.6C19.7,3.1,15.9,0.5,11.6,0.5z M10.8,6h-0.2C8.5,6.3,6.8,7.8,6.1,9.7L3.4,5.2c2-2.5,5-3.9,8.2-3.9
c3.4,0,6.6,1.7,8.6,4.5H12C11.6,5.9,11.3,5.9,10.8,6z'
        />
        <path
          d='M11.6,7.8c-2.2,0-4.1,1.9-4.1,4.1S9.3,16,11.6,16c2.2,0,4.1-1.8,4.1-4.1S13.8,7.7,11.6,7.8z M11.6,15.2
c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3h0.1c1.8,0,3.2,1.5,3.2,3.3C14.9,13.7,13.4,15.2,11.6,15.2z'
        />
      </svg>
    );

  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M17.0095 4.95012H9.00074C7.02068 4.95012 5.31095 6.47981 5.04063 8.36976L2.07093 3.23991C3.69056 1.26048 6.21126 0 9.00074 0C12.5111 0 15.5709 1.9802 17.0095 4.95012Z'
        fill='#EA4335'
      />
      <path
        d='M12.5103 10.9802C12.87 10.4402 13.051 9.71972 13.051 9C13.051 7.74029 12.4202 6.57007 11.5199 5.85035H17.4608C17.8215 6.86099 18.0039 7.92675 17.9999 9C17.9999 13.9501 13.9505 18 8.99997 18H8.46009L12.5103 10.9802Z'
        fill='#FBBC04'
      />
      <path
        d='M8.99997 13.0499C7.47045 13.0499 6.21049 12.2399 5.4904 10.9802L1.44018 4.04988C0.539875 5.4901 0 7.20031 0 9C0 13.4996 3.24002 17.19 7.55979 17.9097L10.5303 12.7799C9.99038 12.9596 9.53984 13.0499 8.99997 13.0499Z'
        fill='#34A853'
      />
      <path
        d='M8.99998 12.1496C9.83464 12.1503 10.6354 11.8188 11.2261 11.2281C11.8168 10.6375 12.1491 9.83601 12.1499 8.99999C12.1491 8.16396 11.8168 7.36249 11.2261 6.77184C10.6354 6.18119 9.83464 5.84972 8.99998 5.85034C8.16531 5.84972 7.36458 6.18119 6.77388 6.77184C6.18318 7.36249 5.85088 8.16396 5.85006 8.99999C5.85088 9.83601 6.18318 10.6375 6.77388 11.2281C7.36458 11.8188 8.16531 12.1503 8.99998 12.1496Z'
        fill='#4285F4'
      />
    </svg>
  );
};
