import { useAlarmingBehaviorEvents } from '@va/dashboard/api-client/event-tracking.api';
import { useAddNotification, useMutateOnRefresh } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { useFiltersContext } from '@va/shared/feature-filters';
import {
  AlarmingBehavior,
  AlarmingBehaviorEventsContextParams,
  AlarmingBehaviorResponse,
  UseAlarmingBehaviorEventsParams,
} from '@va/types/events/events.types';
import { useControlledTableState } from '@va/ui/components/data-table';
import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { pageSize } from '../data/constants';
import { metricsMap } from '../data/metrics';
import { formatApiParameters, mapAlarmingBehaviorResponse, sortByMapper } from '../helpers/apiHelper';

const AlarmingBehaviorEventsContext = createContext<AlarmingBehaviorEventsContextParams>(
  {} as AlarmingBehaviorEventsContextParams,
);

export const AlarmingBehaviorEventsContextProvider = ({
  children,
  paginationId,
  initialFilters,
  group = 'trigger',
  order,
}: PropsWithChildren<
  {
    paginationId: string;
  } & Omit<UseAlarmingBehaviorEventsParams, 'pagination'>
>) => {
  const translate = useTranslate();
  const { appliedFilters } = useFiltersContext();
  const { showErrorNotification } = useAddNotification();
  const [selectedMetric, setSelectedMetric] = useState<string>(metricsMap.count);
  const [selectedAlarmingBehaviorEvents, setSelectedAlarmingBehaviorEvents] = useState<AlarmingBehavior[]>([]);

  const { pagination, setPagination, sorting, setSorting, rowSelection, setRowSelection } = useControlledTableState({
    pagination: { pageSize: pageSize },
  });

  const { query, body } = useMemo(
    () => formatApiParameters(appliedFilters, initialFilters, group, pagination, order ?? sortByMapper(sorting)),
    [appliedFilters, initialFilters, group, pagination, order, sorting],
  );
  const { data, isLoading, mutate } = useAlarmingBehaviorEvents<AlarmingBehaviorResponse>({
    query,
    body,
    mapper: mapAlarmingBehaviorResponse,
  });

  const toggleABESelection = (behavior: AlarmingBehavior) => {
    setSelectedAlarmingBehaviorEvents((prev) => {
      const isSelected = prev.find((item) => item[group] === behavior[group]);
      if (isSelected) {
        return prev.filter((item) => item[group] !== behavior[group]);
      } else {
        if (prev.length >= 8) {
          showErrorNotification(5000, translate('all.alarmingBehaviorEvents.errors.maxEventsSelectedReached'));
          return prev;
        }
        return [...prev, behavior];
      }
    });
  };

  const filteredSelectedAlarmingBehaviorEvents = useMemo(() => {
    if (!data) return [];

    return data.payload.filter((item) => selectedAlarmingBehaviorEvents.some((event) => event[group] === item[group]));
  }, [data, group, selectedAlarmingBehaviorEvents]);

  const selectMetric = (metric: string) => setSelectedMetric(metric);
  const { isManualRefreshing } = useMutateOnRefresh(mutate);

  return (
    <AlarmingBehaviorEventsContext.Provider
      value={{
        data,
        isLoading: isLoading || isManualRefreshing,
        pagination,
        paginationId,
        setPagination,
        sorting,
        setSorting,
        rowSelection,
        setRowSelection,
        selectedAlarmingBehaviorEvents: filteredSelectedAlarmingBehaviorEvents,
        toggleABESelection,
        selectedMetric,
        selectMetric,
        // TODO Find a better name for this field
        groupingKey: group ?? 'trigger',
      }}
    >
      {children}
    </AlarmingBehaviorEventsContext.Provider>
  );
};

export const useAlarmingBehaviorEventsContext = () => useContext(AlarmingBehaviorEventsContext);
