import { ADD_NOTIFICATION_V2, REMOVE_NOTIFICATION_V2 } from '@va/dashboard/actions/ui';
import { NotificationTypeEnum } from '@va/types/notification';
import Immutable from 'immutable';

type InitialStateType = {
  text: string | null;
  type: NotificationTypeEnum | null;
  showNotification: boolean;
  autoDismiss: number | null; // milliseconds
};

const initialState: InitialStateType = {
  showNotification: false,
  text: null,
  type: null,
  autoDismiss: null,
};

export const notificationsReducer = (
  state = Immutable.Map(initialState),
  action: { payload: { text: string; type: NotificationTypeEnum; autoDismiss?: number }; type: string },
) => {
  switch (action.type) {
    case ADD_NOTIFICATION_V2: {
      return state
        .set('text', action.payload?.text)
        .set('type', action.payload?.type)
        .set('showNotification', true)
        .set('autoDismiss', action.payload?.autoDismiss ?? 2000);
    }
    case REMOVE_NOTIFICATION_V2: {
      return state.set('text', null).set('type', null).set('showNotification', false).set('autoDismiss', null);
    }
    default:
      return state;
  }
};
