import { validationTranslationKeys } from '@va/constants';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { useFiltersContext } from '@va/shared/feature-filters';
import { getUserId } from '@va/standalone/shared/helpers';
import { ModalFormWrapper } from '@va/ui/components/modals';
import { TextInput } from '@va/ui/design-system';
import { buildTemplateConditionsObject } from '@va/util/helpers';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useCreateFilterTemplate, useGetFilterTemplates } from './api';

const SaveDataTemplateModal = ({ onClose, isOpen }: { onClose: () => void; isOpen: boolean }) => {
  const translate = useTranslate();
  const websiteId = useSelector(getInstanceId);
  const createTemplate = useCreateFilterTemplate();
  const { showSuccessNotification, showErrorNotification } = useAddNotification();

  const { appliedFilters, reportBlockId } = useFiltersContext();
  const { mutate } = useGetFilterTemplates(reportBlockId);

  const { values, handleChange, handleSubmit, errors, setFieldValue } = useFormik({
    onSubmit: (values) => {
      if (!reportBlockId) return;
      createTemplate.execute(websiteId, {
        name: values.name,
        reportBlockId: reportBlockId,
        createdBy: getUserId(),
        conditions: buildTemplateConditionsObject(appliedFilters, true),
      });
    },
    validationSchema: yup.object().shape({ name: yup.string().required(validationTranslationKeys.required) }),
    initialValues: { name: '' },
  });

  useEffect(() => {
    if (createTemplate.isSucceeded) {
      onClose();
      mutate();
      createTemplate.resetSuccessState();
      showSuccessNotification();
    }
  }, [createTemplate, createTemplate.isSucceeded, mutate, onClose, showSuccessNotification]);

  useEffect(() => {
    if (createTemplate.error) {
      createTemplate.clearError();
      showErrorNotification();
    }
  }, [createTemplate, createTemplate.error, showErrorNotification]);

  return (
    <ModalFormWrapper
      onSubmit={handleSubmit}
      onClose={onClose}
      isOpen={isOpen}
      title={translate('all.filterTemplates.saveTemplateTitle')}
      isSaveButtonDisabled={createTemplate.isLoading}
    >
      <TextInput
        clearField={() => {
          setFieldValue('name', '');
        }}
        label={translate('all.filterTemplates.labels.name')}
        name='name'
        value={values.name}
        error={errors.name ? translate(errors.name) : undefined}
        onChange={handleChange}
        autoFocus
      />
    </ModalFormWrapper>
  );
};

export default SaveDataTemplateModal;
