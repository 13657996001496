import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { nonTranslatableText } from '@va/constants';
import Translation from '@va/deprecated/components/Translation';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import { FeedbackFail, FeedbackSuccess } from '@va/svg-visa-icons';
import PopUpCard from '../index';
/** @deprecated */
class MessageCard extends PureComponent {
  renderConfirmationSucceeded() {
    const { translate, onClose, modalName, processing, title, subtitle } = this.props;
    const succeededType = processing ? 'processing' : 'succeeded';

    return (
      <PopUpCard
        type='card-grey'
        colorTheme='theme-green'
        title={title || translate(`modal.confirmation.${succeededType}.title`)}
        footerButtons={onClose && <div onClick={onClose}>{translate('button.close')}</div>}
      >
        <div className='text-center'>
          <div className='feedback-icon-container'>
            <FeedbackSuccess />
          </div>
          <p className='mb-2'>
            {subtitle || (
              <Translation
                translationKey={`modal.${modalName}.confirmation.${succeededType}`}
                onClick={this.props.onClose}
                parameters={Object.assign(
                  { visitorAnalytics: nonTranslatableText.appName },
                  this.props.translateParams,
                )}
              />
            )}
          </p>
        </div>
      </PopUpCard>
    );
  }

  renderConfirmationFailed() {
    const { translate, onClose, modalName, subtitle, title } = this.props;

    return (
      <PopUpCard
        type='card-grey'
        colorTheme='theme-red'
        title={title || translate('modal.confirmation.failed.title')}
        footerButtons={onClose && <div onClick={onClose}>{translate('button.close')}</div>}
      >
        <div className='text-center'>
          <div className='feedback-icon-container'>
            <FeedbackFail />
          </div>
          <p className='mb-2'>
            {subtitle || translate(`modal.${modalName}.confirmation.failed`, this.props.translateParams)}
          </p>
        </div>
      </PopUpCard>
    );
  }

  render() {
    return this.props.apiError ? this.renderConfirmationFailed() : this.renderConfirmationSucceeded();
  }
}

export default withTranslate(MessageCard);

MessageCard.propTypes = {
  modalName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  processing: PropTypes.bool,
  apiError: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  translateParams: PropTypes.object,
  //withTranslate
  translate: PropTypes.func.isRequired,
};
