import { Button, ButtonColors, ButtonProps } from '@va/ui/design-system';
import classNames from 'classnames';
import { forwardRef } from 'react';

type SelectableButtonProps = {
  selected?: boolean;
  small?: boolean;
  activeButtonColor?: ButtonColors;
  inactiveButtonColor?: ButtonColors;
} & ButtonProps;

export const SelectableButton = forwardRef<HTMLButtonElement, SelectableButtonProps>(
  (
    { selected = false, activeButtonColor = 'quaternary', inactiveButtonColor = 'secondary', small = false, ...props },
    ref,
  ) => {
    const buttonClasses = classNames(props.className, 'shrink-0', {
      '!py-6px': small,
    });

    return (
      <Button
        ref={ref}
        color={selected ? activeButtonColor : inactiveButtonColor}
        {...props}
        className={buttonClasses}
      />
    );
  },
);
