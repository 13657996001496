import { apiStatus } from '@va/constants';
import { setUserFlag } from '@va/dashboard/actions/api';
import { getAccountInformation } from '@va/dashboard/selectors/api';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { GlobeIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { updateUserInfo } from '@va/standalone/shared/actions';
import { getApiRequestStatus, getWebsites } from '@va/standalone/shared/selectors';
import { SelectorButton } from '@va/ui/components/buttons';
import { ParagraphWithTooltip, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { DropdownArrow } from '@va/util/components';
import { getUrlWithoutProtocolAndTrailingSlash } from '@va/util/helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebsiteSelectorDropdown from './WebsiteSelectorDropdown';

export const DefaultWebsiteSelector = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const closeDropdown = useCallback(() => setIsDropdownVisible(false), []);
  const { showSuccessNotification, showErrorNotification } = useAddNotification();
  const { url: firstWebsiteUrl, id: firstWebsiteId } = useSelector(getWebsites)?.get('websites').first()?.toJS() ?? {};

  const { frontendFlags, specialWebsiteId } = useSelector(getAccountInformation);
  const defaultWebsiteName = frontendFlags?.defaultWebsiteName;

  const accountInfoApiStatus = useSelector((state) => getApiRequestStatus(state, 'updateUserInfo'));

  useEffect(() => {
    if (accountInfoApiStatus === apiStatus.SUCCEEDED) {
      showSuccessNotification();
    }
    if (accountInfoApiStatus === apiStatus.FAILED) {
      showErrorNotification();
    }
  }, [accountInfoApiStatus, showSuccessNotification, showErrorNotification]);

  const websiteUrl = useMemo(
    () => (specialWebsiteId ? defaultWebsiteName : firstWebsiteUrl),
    [defaultWebsiteName, firstWebsiteUrl, specialWebsiteId],
  );

  return (
    <TooltipWrapper
      useDefaultStyle={false}
      trigger='click'
      tooltipClassNames='w-350px md:w-420px'
      open={isDropdownVisible}
      onOpenChange={(open) => {
        setIsDropdownVisible(open);
      }}
      interactive
      arrow={false}
      content={
        <WebsiteSelectorDropdown
          initializeTotalWebsites={() => {}}
          closeDropdown={closeDropdown}
          onWebsiteSelect={(websiteId, websiteName) => {
            dispatch(updateUserInfo({ specialWebsite: websiteId }));
            dispatch(setUserFlag('defaultWebsiteName', websiteName));
          }}
          activeWebsiteId={specialWebsiteId ?? firstWebsiteId}
        />
      }
    >
      <div className='w-full md:w-420px'>
        <SelectorButton
          leftElement={<GlobeIcon color='#696969' />}
          topNode={
            <ParagraphWithTooltip
              weight={fontWeights.medium}
              colorClassName='text-gray-charcoal'
              className='truncate w-full'
            >
              {translate('account.accountOptions.defaultWebsite.label')}
            </ParagraphWithTooltip>
          }
          bottomNode={
            <ParagraphWithTooltip
              size={paragraphSizes.tiny}
              weight={fontWeights.medium}
              colorClassName='text-gray-charcoal'
              className='truncate w-full'
            >
              {getUrlWithoutProtocolAndTrailingSlash(websiteUrl ?? '')}
            </ParagraphWithTooltip>
          }
          rightElement={<DropdownArrow open={isDropdownVisible} />}
          className='bg-white'
        />
      </div>
    </TooltipWrapper>
  );
};
