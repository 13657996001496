import { setIgnoreVisits, setIgnoreVisitsIframeLoaded } from '@va/dashboard/actions/app';
import { getIgnoreVisits } from '@va/dashboard/selectors/app';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const IgnoreVisitsIFrameContainer: React.FC<{
  ignore: boolean;
  hash: string;
}> = ({ hash }) => {
  const ignoreVisits = useSelector(getIgnoreVisits);

  const { link, isLoaded, inProgress, isIgnored, ignore } = ignoreVisits;

  const iframeRef = useRef<any>(null);

  const dispatch = useDispatch();

  const dispatchSetEvent = useCallback(
    (value: boolean) => {
      iframeRef?.current?.contentWindow.postMessage(
        {
          functionName: 'getCookieMessage',
          recordSelfVisitOption: value,
          cookieName: 'wix_ignoreVisits_' + hash,
        },
        '*',
      );
    },
    [hash],
  );

  const dispatchGetEvent = useCallback(() => {
    iframeRef?.current?.contentWindow.postMessage(
      {
        functionName: 'getRecordSelfVisitValue',
        cookieName: 'wix_ignoreVisits_' + hash,
      },
      '*',
    );
  }, [hash]);

  const messageListener = useCallback(
    (event: any) => {
      if (hash && event.data.type === 'IGNORE_VISIT_LOADED') {
        dispatch(setIgnoreVisitsIframeLoaded());
        dispatchGetEvent();
      }

      if (event.data.functionName === 'getRecordSelfVisitValue') {
        dispatch(setIgnoreVisits(!event.data.recordSelfVisit));
      }
    },

    [dispatch, dispatchGetEvent, hash],
  );

  const sync = useCallback(() => {
    if (isLoaded && !inProgress && ignore !== undefined && isIgnored !== ignore) {
      dispatch(setIgnoreVisits(ignore));

      dispatchSetEvent(!ignore);
    }
  }, [dispatch, dispatchSetEvent, ignore, inProgress, isIgnored, isLoaded]);

  useEffect(() => {
    window.addEventListener('message', messageListener, false);

    return () => window.removeEventListener('message', messageListener);
  }, [messageListener]);

  useEffect(() => {
    sync();
  }, [sync]);

  return (
    <iframe
      ref={iframeRef}
      title='ignore-visits-iframe'
      id='ignore-visits-iframe'
      src={link}
      style={{ display: 'none' }}
    />
  );
};

export default memo(IgnoreVisitsIFrameContainer);
