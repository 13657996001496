import { IconProps } from '.';

export const LayerIcon = ({ className, color = '#000000' }: IconProps) => {
  return (
    <svg
      className={className}
      width='13'
      height='12'
      viewBox='0 0 13 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.40139 4.60093L0.5 4L1.40139 3.39908L6.5 0L11.5986 3.39908L12.5 4L11.5986 4.60093L6.5 8L1.40139 4.60093ZM6.5 6.79815L2.30278 4L6.5 1.20185L10.6972 4L6.5 6.79815ZM2.30278 8L3.5 7.20185L2.59861 6.60093L1.40139 7.39908L0.5 8L1.40139 8.60093L6.5 12L11.5986 8.60093L12.5 8L11.5986 7.39908L10.4014 6.60093L9.5 7.20185L10.6972 8L6.5 10.7982L2.30278 8Z'
        fill={color}
      />
    </svg>
  );
};


