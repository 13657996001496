export const getCustomizationOptions = (state: any) => {
  return state.get('customization') as { providerName: string; supportCenterDomain: string; chatwootToken: string };
};

export const getProviderName = (state: any) => {
  return state.get('customization').providerName;
};

export const getSupportCenterDomain = (state: any) => {
  return state.get('customization').supportCenterDomain;
};

export const getChatwootToken = (state: any) => {
  return state.get('customization').chatwootToken;
};
