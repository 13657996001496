import { IconProps } from '.';

export const RadioInputUnselected = ({ className = '', color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='9' cy='9' r='8.25' stroke={color ?? '#3C3C3C'} strokeWidth='1.5' />
    </svg>
  );
};

export const RadioInputUnselectedBig = ({ className = '', color }: IconProps) => {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12' cy='12' r='10.875' stroke={color ?? '#C3C3C3'} strokeWidth='2.25' />
    </svg>
  );
};
