import { makeAction } from '@va/store';

export * from './account';
export * from './admin';
export * from './conversionTypes';
export * from './dynamicPages';
export * from './sessionReplays';
export * from './visitors';
export * from './visits';
export * from './website';

export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTHENTICATE_SUCCEEDED = 'AUTHENTICATE_SUCCEEDED';
export const AUTHENTICATE_FAILED = 'AUTHENTICATE_FAILED';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_ENDED = 'REFRESH_TOKEN_ENDED';

export const WEBSITE_REQUEST = 'WEBSITE_REQUEST';
export const WEBSITE_SUCCEEDED = 'WEBSITE_SUCCEEDED';
export const WEBSITE_FAILED = 'WEBSITE_FAILED';
export const WEBSITE_UPDATE = 'WEBSITE_UPDATE';

export const CREATE_WEBSITE_REQUEST = 'CREATE_WEBSITE_REQUEST';
export const CREATE_WEBSITE_SUCCEEDED = 'CREATE_WEBSITE_SUCCEEDED';
export const CREATE_WEBSITE_FAILED = 'CREATE_WEBSITE_FAILED';

export const UPDATE_WEBSITE_FLAGS_REQUEST = 'UPDATE_WEBSITE_FLAGS_REQUEST';
export const UPDATE_WEBSITE_FLAGS_SUCCEEDED = 'UPDATE_WEBSITE_FLAGS_SUCCEEDED';
export const UPDATE_WEBSITE_FLAGS_FAILED = 'UPDATE_WEBSITE_FLAGS_FAILED';

export const UPDATE_WEBSITE_FLAG = 'UPDATE_WEBSITE_FLAG';

export const AGREEMENT_REQUEST = 'AGREEMENT_REQUEST';
export const AGREEMENT_SUCCEEDED = 'AGREEMENT_SUCCEEDED';
export const AGREEMENT_FAILED = 'AGREEMENT_FAILED';

export const GET_AGREEMENT_REQUEST = 'GET_AGREEMENT_REQUEST';
export const GET_AGREEMENT_SUCCEEDED = 'GET_AGREEMENT_SUCCEEDED';
export const GET_AGREEMENT_FAILED = 'GET_AGREEMENT_FAILED';

export const SIGN_DPA_REQUEST = 'SIGN_DPA_REQUEST';
export const SIGN_DPA_SUCCEEDED = 'SIGN_DPA_SUCCEEDED';
export const SIGN_DPA_FAILED = 'SIGN_DPA_FAILED';

export const GET_FEATURE_PERMISSIONS = 'GET_FEATURE_PERMISSIONS';
export const GET_FEATURE_PERMISSIONS_SUCCEEDED = 'GET_FEATURE_PERMISSIONS_SUCCEEDED';
export const GET_FEATURE_PERMISSIONS_FAILED = 'GET_FEATURE_PERMISSIONS_FAILED';

export const GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS';
export const GET_ANNOUNCEMENTS_SUCCEEDED = 'GET_ANNOUNCEMENTS_SUCCEEDED';
export const GET_ANNOUNCEMENTS_FAILED = 'GET_ANNOUNCEMENTS_FAILED';

export const SETTINGS_REQUEST = 'SETTINGS_REQUEST';
export const SETTINGS_SUCCEEDED = 'SETTINGS_SUCCEEDED';
export const SETTINGS_FAILED = 'SETTINGS_FAILED';

export const GET_NOTIFICATION_SETTINGS_REQUEST = 'GET_NOTIFICATION_SETTINGS_REQUEST';
export const GET_NOTIFICATION_SETTINGS_SUCCEEDED = 'GET_NOTIFICATION_SETTINGS_SUCCEEDED';
export const GET_NOTIFICATION_SETTINGS_FAILED = 'GET_NOTIFICATION_SETTINGS_FAILED';

export const UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST';
export const UPDATE_SETTINGS_SUCCEEDED = 'UPDATE_SETTINGS_SUCCEEDED';
export const UPDATE_SETTINGS_FAILED = 'UPDATE_SETTINGS_FAILED';

export const UPDATE_SETTINGS_NOTIFICATIONS_REQUEST = 'UPDATE_SETTINGS_NOTIFICATIONS_REQUEST';
export const UPDATE_SETTINGS_NOTIFICATIONS_SUCCEEDED = 'UPDATE_SETTINGS_NOTIFICATIONS_SUCCEEDED';
export const UPDATE_SETTINGS_NOTIFICATIONS_FAILED = 'UPDATE_SETTINGS_NOTIFICATIONS_FAILED';

export const UPDATE_ACCOUNT_INFO_REQUEST = 'UPDATE_ACCOUNT_INFO_REQUEST';
export const UPDATE_ACCOUNT_INFO_SUCCEEDED = 'UPDATE_ACCOUNT_INFO_SUCCEEDED';
export const UPDATE_ACCOUNT_INFO_FAILED = 'UPDATE_ACCOUNT_INFO_FAILED';

export const UPDATE_ANONYMIZE_IPS_REQUEST = 'UPDATE_ANONYMIZE_IPS_REQUEST';
export const UPDATE_ANONYMIZE_IPS_SUCCEEDED = 'UPDATE_ANONYMIZE_IPS_SUCCEEDED';
export const UPDATE_ANONYMIZE_IPS_FAILED = 'UPDATE_ANONYMIZE_IPS_FAILED';

export const FIRST_LOGIN_GDPR_SUBMIT_REQUEST = 'FIRST_LOGIN_GDPR_SUBMIT_REQUEST';
export const FIRST_LOGIN_GDPR_SUBMIT_SUCCEEDED = 'FIRST_LOGIN_GDPR_SUBMIT_SUCCEEDED';
export const FIRST_LOGIN_GDPR_SUBMIT_FAILED = 'FIRST_LOGIN_GDPR_SUBMIT_FAILED';

export const SEND_FEEDBACK_REQUEST = 'SEND_FEEDBACK_REQUEST';
export const SEND_FEEDBACK_SUCCEEDED = 'SEND_FEEDBACK_SUCCEEDED';
export const SEND_FEEDBACK_FAILED = 'SEND_FEEDBACK_FAILED';

export const SEND_REVIEW_REQUEST = 'SEND_REVIEW_REQUEST';
export const SEND_REVIEW_SUCCEEDED = 'SEND_REVIEW_SUCCEEDED';
export const SEND_REVIEW_FAILED = 'SEND_REVIEW_FAILED';

export const SET_REQUEST_STATUS = 'SET_REQUEST_STATUS';
export const RESET_REQUEST_STATUS = 'RESET_REQUEST_STATUS';
export const RESET_REQUEST = 'RESET_REQUEST';

export const MARK_MODAL_AS_VIEWED_REQUEST = 'MARK_MODAL_AS_VIEWED_REQUEST';
export const MARK_MODAL_AS_VIEWED_REQUEST_FAILED = 'MARK_MODAL_AS_VIEWED_REQUEST_FAILED';

export const LOAD_EXTERNAL_DASHBOARD_SCRIPT_REQUEST = 'LOAD_EXTERNAL_DASHBOARD_SCRIPT_REQUEST';

// authenticate
export const authenticate = makeAction(AUTHENTICATE, 'instance');
export const authenticateSucceeded = makeAction(AUTHENTICATE_SUCCEEDED, 'data');
export const authenticateFailed = makeAction(AUTHENTICATE_FAILED, 'error');

export const refreshToken = makeAction(REFRESH_TOKEN, 'data');
export const refreshTokenEnded = makeAction(REFRESH_TOKEN_ENDED);

// website
export const requestWebsite = makeAction(WEBSITE_REQUEST);
export const requestWebsiteSucceeded = makeAction(WEBSITE_SUCCEEDED, 'data');
export const requestWebsiteFailed = makeAction(WEBSITE_FAILED, 'error');
export const updateWebsite = makeAction(WEBSITE_UPDATE, 'data');

export const createWebsite = makeAction(CREATE_WEBSITE_REQUEST, 'data');
export const createWebsiteSucceeded = makeAction(CREATE_WEBSITE_SUCCEEDED, 'data');
export const createWebsiteFailed = makeAction(CREATE_WEBSITE_FAILED, 'error');

export const updateWebsiteFlags = makeAction(UPDATE_WEBSITE_FLAGS_REQUEST, 'data', 'optimisticUpdate');
export const updateWebsiteFlagsSucceeded = makeAction(UPDATE_WEBSITE_FLAGS_SUCCEEDED, 'data');
export const updateWebsiteFlagsFailed = makeAction(UPDATE_WEBSITE_FLAGS_FAILED, 'error');

export const updateWebsiteFlag = makeAction(UPDATE_WEBSITE_FLAG, 'key', 'value', 'optimisticUpdate');

export const agreement = makeAction(AGREEMENT_REQUEST, 'data');
export const agreementSucceeded = makeAction(AGREEMENT_SUCCEEDED);
export const agreementFailed = makeAction(AGREEMENT_FAILED, 'error');

export const getAgreement = makeAction(GET_AGREEMENT_REQUEST);
export const getAgreementSucceeded = makeAction(GET_AGREEMENT_SUCCEEDED, 'data');
export const getAgreementFailed = makeAction(GET_AGREEMENT_FAILED, 'error');

export const signDPA = makeAction(SIGN_DPA_REQUEST, 'signatureImage', 'websiteInfo');
export const signDPASucceeded = makeAction(SIGN_DPA_SUCCEEDED);
export const signDPAFailed = makeAction(SIGN_DPA_FAILED, 'error');

export const getFeaturePermissions = makeAction(GET_FEATURE_PERMISSIONS);
export const getFeaturePermissionsSucceeded = makeAction(GET_FEATURE_PERMISSIONS_SUCCEEDED, 'data');
export const getFeaturePermissionsFailed = makeAction(GET_FEATURE_PERMISSIONS_FAILED, 'error');

export const getAnnouncements = makeAction(GET_ANNOUNCEMENTS, 'locale');
export const getAnnouncementsSucceeded = makeAction(GET_ANNOUNCEMENTS_SUCCEEDED, 'data');
export const getAnnouncementsFailed = makeAction(GET_ANNOUNCEMENTS_FAILED, 'error');
//settings
export const setRequestStatus = makeAction(SET_REQUEST_STATUS, 'requestName', 'requestStatus');
export const resetRequestStatus = makeAction(RESET_REQUEST_STATUS, 'requestName');
export const resetRequest = makeAction(RESET_REQUEST, 'requestName');

export const requestSettings = makeAction(SETTINGS_REQUEST);
export const requestSettingsSucceeded = makeAction(SETTINGS_SUCCEEDED, 'data');
export const requestSettingsFailed = makeAction(SETTINGS_FAILED, 'error');

export const getNotificationSettings = makeAction(GET_NOTIFICATION_SETTINGS_REQUEST);
export const getNotificationSettingsSucceeded = makeAction(GET_NOTIFICATION_SETTINGS_SUCCEEDED, 'data');
export const getNotificationSettingsFailed = makeAction(GET_NOTIFICATION_SETTINGS_FAILED, 'error');

export const updateSettings = makeAction(UPDATE_SETTINGS_REQUEST, 'data');
export const updateSettingsSucceeded = makeAction(UPDATE_SETTINGS_SUCCEEDED, 'data');
export const updateSettingsFailed = makeAction(UPDATE_SETTINGS_FAILED, 'error');

export const updateSettingsNotifications = makeAction(UPDATE_SETTINGS_NOTIFICATIONS_REQUEST, 'data');
export const updateSettingsNotificationsSucceeded = makeAction(UPDATE_SETTINGS_NOTIFICATIONS_SUCCEEDED, 'data');
export const updateSettingsNotificationsFailed = makeAction(UPDATE_SETTINGS_NOTIFICATIONS_FAILED, 'error');

export const updateAccountInfo = makeAction(UPDATE_ACCOUNT_INFO_REQUEST, 'data');
export const updateAccountInfoSucceeded = makeAction(UPDATE_ACCOUNT_INFO_SUCCEEDED);
export const updateAccountInfoFailed = makeAction(UPDATE_ACCOUNT_INFO_FAILED, 'error');

//feedback
export const sendFeedback = makeAction(SEND_FEEDBACK_REQUEST, 'liked', 'message');
export const sendFeedbackSucceeded = makeAction(SEND_FEEDBACK_SUCCEEDED, 'data');
export const sendFeedbackFailed = makeAction(SEND_FEEDBACK_FAILED, 'error');

export const sendReview = makeAction(SEND_REVIEW_REQUEST, 'dontShowReview', 'reviewStars', 'reviewFeedback');
export const sendReviewSucceeded = makeAction(SEND_REVIEW_SUCCEEDED, 'data');
export const sendReviewFailed = makeAction(SEND_REVIEW_FAILED, 'error');

//modals
/**
 * @deprecated bad naming, use updateWebsiteFlag instead
 */
export const markModalAsViewed = makeAction(MARK_MODAL_AS_VIEWED_REQUEST, 'modalFlagName', 'modalFlagValue'); // TODO Delete and replace it with updateWebsiteFlag
export const markModalAsViewedFailed = makeAction(MARK_MODAL_AS_VIEWED_REQUEST_FAILED, 'error');

//external
export const loadExternalDashboardScript = makeAction(LOAD_EXTERNAL_DASHBOARD_SCRIPT_REQUEST, 'platform');
