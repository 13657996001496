import { TEST_IDS } from '@va/constants';
import { fontWeights, Paragraph } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { getInitials, stringToHslColor } from '@va/util/helpers';

type UserAvatarTypes = {
  text: string;
  isContributor?: boolean;
};

const UserAvatar = ({ text, isContributor }: UserAvatarTypes) => {
  const colorValue = stringToHslColor(text ?? 'N/A', '50', '60');
  return (
    <TooltipWrapper content={text} disabled={!text || isContributor}>
      <div
        data-testid={TEST_IDS.generic.avatar}
        className='w-12 h-12 rounded-full flex items-center justify-center text-white'
        style={{ backgroundColor: colorValue }}
      >
        <Paragraph weight={fontWeights.medium}>{getInitials(text ?? 'N/A')}</Paragraph>
      </div>
    </TooltipWrapper>
  );
};

export default UserAvatar;
