import { TOTAL_KEY } from '@va/constants';
import { useLocale, useTranslate } from '@va/localization';
import { addNumberSeparator, getPercentageColors } from '@va/util/helpers';
import { useCustomizationContext } from '@va/util/misc';
import classNames from 'classnames';
import { isNumber, round } from 'lodash';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { Paragraph, ParagraphWithTooltip, fontWeights, paragraphSizes } from '../../../typography';

/** @deprecated */
export type TooltipRow = {
  color: string;
  value: number | string;
  unit?: string;
  linkTo?: string;
};

/** @deprecated */
type TooltipGridRowProps = {
  showPrevious: boolean;
  currentValue: TooltipRow;
  previousValue: TooltipRow;
  percentageChange: number | '-';
  rowKey: string;
  reversePercentageColors?: boolean;
};

/** @deprecated */
export function TooltipGridRow({
  showPrevious,
  currentValue,
  previousValue,
  percentageChange,
  rowKey,
  reversePercentageColors = false,
}: TooltipGridRowProps) {
  const translate = useTranslate();
  const { locale } = useLocale();
  const history = useHistory();

  function formatPercentage(percentage: number | '-') {
    if (percentage === '-' || percentage === 0) return '-';
    return addNumberSeparator(round(percentage, 1), locale, {
      style: 'percent',
      signDisplay: 'exceptZero',
    });
  }

  const { getCustomValue } = useCustomizationContext();

  return (
    <>
      <div className='flex flex-row flex-nowrap items-center gap-2'>
        <div className='flex flex-col'>
          <div className='flex flex-row flex-nowrap items-center gap-2 w-auto'>
            <div
              className='rounded-full h-2 w-2 shrink-0'
              style={{
                backgroundColor: currentValue.color,
              }}
            />
            <ParagraphWithTooltip className='leading-normal !line-clamp-2 !whitespace-normal'>
              {rowKey === TOTAL_KEY ? translate('text.total') : translate(rowKey)}
            </ParagraphWithTooltip>
          </div>
          {getCustomValue('showLinks', true) && currentValue?.linkTo && (
            <Paragraph
              size={paragraphSizes.tiny}
              weight={fontWeights.semibold}
              className='text-green-pure cursor-pointer'
              onClick={() => history.push(currentValue.linkTo as string)}
            >
              {translate('tooltip.label.moreDetails')}
            </Paragraph>
          )}
        </div>
      </div>
      <TooltipValue className='h-full' {...currentValue} />

      {showPrevious && previousValue && <TooltipValue className='h-full' {...previousValue} />}
      <div
        className={classNames(
          'text-right h-full',
          getPercentageColors(percentageChange === '-' ? 0 : percentageChange, reversePercentageColors),
        )}
      >
        {formatPercentage(percentageChange)}
      </div>
    </>
  );
}

const TooltipValue: FC<{ value: number | string; unit?: string; className?: string }> = ({
  value,
  unit,
  className,
}) => {
  const { locale } = useLocale();

  if (isNumber(value))
    return (
      <div className={className}>
        {addNumberSeparator(value, locale)} {unit}
      </div>
    );
  return (
    <div className={className}>
      {value} {unit}
    </div>
  );
};
