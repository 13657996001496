import { TEST_IDS } from '@va/constants';
import { useCheckPrivacySetting } from '@va/dashboard/util-hooks';
import { ClockIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { AppliedAddFilterButton, AppliedFilters, useFiltersContext } from '@va/shared/feature-filters';
import { getEventIcon } from '@va/shared/ssr-player';
import { PRIVACY_FEATURES } from '@va/standalone/shared/types';
import { FilterOperators } from '@va/types/filters';
import { MappedEventType } from '@va/types/recordings';
import {
  Button,
  fontWeights,
  IconWithBackground,
  InfoSectionItem,
  Paragraph,
  paragraphSizes,
} from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { formatMomentDuration, formatTime } from '@va/util/helpers';
import classNames from 'classnames';
import moment from 'moment';
import React, { memo, useEffect, useMemo, useRef } from 'react';
import { useThrottledCallback } from 'use-debounce';
import { scrollToEventListNode } from './helpers';
import useRecordingsDetailsContext from './recordingsDetailsContext';

const RecordingsActionsV2 = () => {
  const { events } = useRecordingsDetailsContext();
  const { appliedFilters } = useFiltersContext();

  const memoizedEvents = useMemo(() => events, [events]);

  const filteredEvents = useMemo(() => {
    const isFilters = appliedFilters.filter((f) => f.operator === FilterOperators.is && f.values.length > 0);
    const isNotFilters = appliedFilters.filter((f) => f.operator === FilterOperators.isNot && f.values.length > 0);

    return memoizedEvents.filter((event) => {
      const passesIsFilters = isFilters.length === 0 || isFilters.every((f) => f.values.includes(event.name));
      const passesIsNotFilters = isNotFilters.every((f) => !f.values.includes(event.name));

      return passesIsFilters && passesIsNotFilters;
    });
  }, [memoizedEvents, appliedFilters]);

  return (
    <div>
      <div className='sticky top-0 pt-1.5 pb-18px bg-white z-10'>
        <AppliedFilters keepHorizontalDivider={false} keepRemoveAll={false} />
        {appliedFilters.length === 0 && (
          <div className='bg-white-snow p-3px rounded-12 w-fit'>
            <AppliedAddFilterButton className='!rounded-12' />
          </div>
        )}
      </div>
      <div className='w-full flex flex-col gap-1 max-h-[700px] rounded-18 overflow-auto scrollbar scrollbar-thumb relative'>
        <EventsList events={filteredEvents} />
      </div>
    </div>
  );
};

export default RecordingsActionsV2;

type EventListItemProps = {
  event: MappedEventType;
  onClick: (event: MappedEventType) => void;
  isPageHistoryTrackingEnabled: boolean;
  dataTestId: string;
  variant?: 'default' | 'wix-mini';
  selected: boolean;
};

type EventListItemStyles = {
  className?: string;
  iconClassName?: string;
  buttonClassName?: string;
  squareIcons?: boolean;
  size?: 'small' | 'large';
  iconType?: 'circle' | 'square';
};

const styles: Record<NonNullable<EventListItemProps['variant']>, EventListItemStyles> = {
  default: {
    size: 'large',
    iconClassName: 'bg-white',
  },
  'wix-mini': {
    size: 'small',
    className: '!bg-white !p-1.5',
    iconClassName: 'bg-white-catskill',
    buttonClassName: 'bg-white-catskill',
    iconType: 'square',
  },
};

export const EventListItem: React.FC<EventListItemProps> = memo(
  ({ event, onClick, isPageHistoryTrackingEnabled, dataTestId, variant = 'default', selected }) => {
    const translate = useTranslate();
    const fromTimestamp = formatTime(formatMomentDuration(moment.duration(event.delay)));
    const toTimestamp = event.to && formatTime(formatMomentDuration(moment.duration(event.to)));

    const { icon: eventIcon, backgroundColor: iconBgColor } = useMemo(
      () => getEventIcon({ eventDetails: event.name }),
      [event.name],
    );

    return (
      <InfoSectionItem
        dataTestId={dataTestId}
        textColReverse={true}
        className={classNames(styles[variant].className, {
          '!bg-gray-concrete': selected,
        })}
        rightNode={
          <div className='flex items-center gap-2'>
            <Paragraph
              className='whitespace-nowrap'
              size={styles[variant].size === 'small' ? paragraphSizes.tiny : paragraphSizes.normal}
              weight={fontWeights.medium}
            >
              {toTimestamp ? fromTimestamp + ' - ' + toTimestamp : fromTimestamp}
            </Paragraph>
            <Button
              data-testid={TEST_IDS.generic.buttons.jumpToEvent}
              className={styles[variant].buttonClassName}
              color='quaternary'
              icon={() => (
                <ClockIcon
                  className={classNames({ 'w-[15px] h-[15px]': styles[variant].size === 'small' })}
                  color='var(--color-text-primary)'
                />
              )}
              onClick={() => {
                onClick(event);
              }}
              tooltip={translate('recordings.jumpToAction')}
              size={styles[variant].size}
            />
          </div>
        }
        icon={
          <IconWithBackground
            className={styles[variant].iconClassName}
            style={{ backgroundColor: iconBgColor }}
            size={styles[variant].size}
            type={styles[variant].iconType}
            icon={({ className }) => eventIcon(className)}
          />
        }
        title={
          <TooltipWrapper placement='bottom' followCursorAxis='x' content={event.tooltipText}>
            <Paragraph colorClassName='text-text-primary' weight={fontWeights.medium} className='truncate'>
              {event.details}
            </Paragraph>
          </TooltipWrapper>
        }
        label={isPageHistoryTrackingEnabled ? event.page : undefined}
      />
    );
  },
);

const EventsList = ({ events }: { events: MappedEventType[] }) => {
  const { onEventClick, sessionInfo, parentEventIndex, currentEventIndex } = useRecordingsDetailsContext();
  const eventsMapRef = useRef<Map<unknown, HTMLElement> | null>(null);
  const { isTrackingEnabled } = useCheckPrivacySetting();

  const memoizedEvents = useMemo(() => events, [events]);

  const getMap = () => {
    if (!eventsMapRef.current) {
      eventsMapRef.current = new Map();
    }
    return eventsMapRef.current;
  };

  const scrollElementIntoView = useThrottledCallback(scrollToEventListNode, 300, { trailing: true });

  useEffect(() => {
    const map = getMap();
    const node = map?.get(parentEventIndex);

    if (!node) return;

    scrollElementIntoView(node);
  }, [parentEventIndex, scrollElementIntoView]);

  const isPageHistoryTrackingEnabled = isTrackingEnabled(
    PRIVACY_FEATURES.individualPageHistory,
    sessionInfo?.countryCode as string,
  );

  return (
    <>
      {memoizedEvents.map((event, index) => {
        return (
          <div
            key={index}
            ref={(node) => {
              const map = getMap();
              if (node) {
                map?.set(index, node);
              } else {
                map?.delete(index);
              }
            }}
          >
            <EventListItem
              event={event}
              onClick={onEventClick}
              isPageHistoryTrackingEnabled={isPageHistoryTrackingEnabled}
              dataTestId={TEST_IDS.helpers.createListItemId(`recording-action-${index}`)}
              selected={
                parentEventIndex === currentEventIndex ? index === parentEventIndex : index === currentEventIndex
              }
            />
          </div>
        );
      })}
    </>
  );
};
