import { IconProps } from '.';

export const CommentsIcon = ({ color = '#000000', className }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.75 1.5C0.335786 1.5 0 1.83579 0 2.25C0 2.66421 0.335787 3 0.75 3H17.25C17.6642 3 18 2.66421 18 2.25C18 1.83579 17.6642 1.5 17.25 1.5H0.75ZM0.75 6C0.335786 6 0 6.33579 0 6.75C0 7.16421 0.335787 7.5 0.75 7.5H17.25C17.6642 7.5 18 7.16421 18 6.75C18 6.33579 17.6642 6 17.25 6H0.75ZM0 11.25C0 10.8358 0.335786 10.5 0.75 10.5H17.25C17.6642 10.5 18 10.8358 18 11.25C18 11.6642 17.6642 12 17.25 12H0.75C0.335787 12 0 11.6642 0 11.25ZM0.75 15C0.335786 15 0 15.3358 0 15.75C0 16.1642 0.335786 16.5 0.75 16.5H11.25C11.6642 16.5 12 16.1642 12 15.75C12 15.3358 11.6642 15 11.25 15H0.75Z'
        fill={color}
      />
    </svg>
  );
};


