import classNames from 'classnames';
import { IconProps } from '.';

export const SkipIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='10.1499' cy='10' r='9' stroke='black' strokeWidth='1.5' />
      <path
        d='M11.125 9.35048C11.625 9.63916 11.625 10.3608 11.125 10.6495L6.625 13.2476C6.125 13.5363 5.5 13.1754 5.5 12.5981L5.5 7.40192C5.5 6.82457 6.125 6.46373 6.625 6.7524L11.125 9.35048Z'
        fill={color}
      />
      <path
        d='M15.625 9.35048C16.125 9.63916 16.125 10.3608 15.625 10.6495L11.125 13.2476C10.625 13.5363 10 13.1754 10 12.5981L10 7.40192C10 6.82457 10.625 6.46373 11.125 6.7524L15.625 9.35048Z'
        fill={color}
      />
    </svg>
  );
};
