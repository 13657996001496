import { getApiRequest } from '@va/standalone/shared/selectors';
import { useSelector } from 'react-redux';

const useAgencyUi = () => {
  return (
    useSelector((state) => getApiRequest(state, 'getAgencyUi'))
      ?.get('data')
      ?.toJS() ?? {}
  );
};

export default useAgencyUi;
