import { InformationIconComponent } from '@va/svg-visa-icons';
import { TooltipWrapper } from '@va/ui/tooltips';
import PropTypes from 'prop-types';
import { useState } from 'react';
import './infoTooltip.scss';
/**
 * Simple tooltip that wraps an info icon
 * Uses new tooltip implementation that does not rely on redux
 */
const InfoTooltip = ({ children, fill }) => {
  // Used for the active state of the info icon
  const [isActive, setIsActive] = useState(false);

  return (
    <TooltipWrapper
      interactive
      content={children}
      tooltipClassNames='font-inter'
      open={isActive}
      onOpenChange={(open) => setIsActive(open)}
    >
      <div className='flex items-center cursor-pointer'>
        <InformationIconComponent hovered={isActive} fill={fill} />
      </div>
    </TooltipWrapper>
  );
};

InfoTooltip.defaultProps = {
  fill: 'black',
};

InfoTooltip.propTypes = {
  children: PropTypes.element.isRequired,
};

export default InfoTooltip;
