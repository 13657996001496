export const GoogleAnalyticsIcon = ({ color, ...props }: JSX.IntrinsicElements['svg']) => (
  <svg width='90' height='90' viewBox='0 0 90 90' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect width='90' height='90' rx='24' fill='#F3F3F3' />
    <path
      d='M54.5625 26.1562V63.2812C54.5625 67.4353 57.4284 69.75 60.4688 69.75C63.2812 69.75 66.375 67.7812 66.375 63.2812V26.4375C66.375 22.6294 63.5625 20.25 60.4688 20.25C57.375 20.25 54.5625 22.8741 54.5625 26.1562Z'
      fill={color ?? '#F9AB00'}
    />
    <path
      d='M39.0938 45V63.2812C39.0938 67.4353 41.9597 69.75 45 69.75C47.8125 69.75 50.9062 67.7812 50.9062 63.2812V45.2812C50.9062 41.4731 48.0938 39.0938 45 39.0938C41.9062 39.0938 39.0938 41.7178 39.0938 45Z'
      fill={color ?? '#E37400'}
    />
    <path
      d='M29.5312 69.748C32.7932 69.748 35.4375 67.1037 35.4375 63.8418C35.4375 60.5799 32.7932 57.9355 29.5312 57.9355C26.2693 57.9355 23.625 60.5799 23.625 63.8418C23.625 67.1037 26.2693 69.748 29.5312 69.748Z'
      fill={color ?? '#E37400'}
    />
  </svg>
);
