import { getProviderName } from '@va/dashboard/selectors/customization';
import { useLocalizationContext } from '@va/localization';
import { getLocalizedDate, getTimestampFromIso } from '@va/util/helpers';
import { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { dateOptions, getRelevantMaintenancePeriod } from './helpers';

type MaintenanceBannerProps = {
  render: (message: string) => ReactNode;
};

export const MaintenanceBanner = ({ render }: MaintenanceBannerProps) => {
  const { locale, translate } = useLocalizationContext();
  const maintenancePeriod = getRelevantMaintenancePeriod();
  const providerName = useSelector(getProviderName);

  const message = useMemo(() => {
    if (!maintenancePeriod) return null;

    const [start, end] = (maintenancePeriod as [string, string]) ?? [];

    return translate('all.maintenanceBanner.text', {
      providerName,
      start: getLocalizedDate(getTimestampFromIso(start), locale, dateOptions),
      end: getLocalizedDate(getTimestampFromIso(end), locale, dateOptions),
    });
  }, [locale, maintenancePeriod, providerName, translate]);

  if (!maintenancePeriod) return null;

  return render(message);
};
