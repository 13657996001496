import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { SubscriptionType } from '../agency-white-label-v2/agency-white-label-apiClient';
import RemoveWebsiteModal from './RemoveWebsiteModal';

type ContextType = {
  isModalOpen: boolean;
  startRemoveWebsiteFlow: (
    websiteId: string,
    subscription: SubscriptionType | null,
    platform: string
  ) => void;
  websiteId: string | undefined;
  subscription: SubscriptionType | null | undefined;
  closeModal: () => void;
  websitePlatform: string | undefined;
};

const RemoveWebsiteContext = createContext<ContextType>({} as ContextType);

const RemoveWebsiteContextProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [websiteId, setWebsiteId] = useState<string>();
  const [websitePlatform, setWebsitePlatform] = useState<string>();
  const [subscription, setSubscription] = useState<SubscriptionType | null>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const startRemoveWebsiteFlow = useCallback(
    (
      websiteId: string,
      subscription: SubscriptionType | null,
      platform: string
    ) => {
      setWebsiteId(websiteId);
      setSubscription(subscription);
      setWebsitePlatform(platform);
      setIsModalOpen(true);
    },
    []
  );

  const resetState = () => {
    setWebsiteId(undefined);
    setSubscription(undefined);
    setWebsitePlatform(undefined);
  };

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    resetState();
  }, []);

  return (
    <RemoveWebsiteContext.Provider
      value={{
        websiteId,
        subscription,
        isModalOpen,
        startRemoveWebsiteFlow,
        closeModal,
        websitePlatform,
      }}
    >
      {isModalOpen && <RemoveWebsiteModal />}
      {children}
    </RemoveWebsiteContext.Provider>
  );
};

const useRemoveWebsiteContext = () => useContext(RemoveWebsiteContext);

export { RemoveWebsiteContextProvider, useRemoveWebsiteContext };
