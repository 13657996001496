import classNames from 'classnames';
import { IconProps } from '.';

export const MoreOptionsIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20ZM11 21.5C16.799 21.5 21.5 16.799 21.5 11C21.5 5.20101 16.799 0.5 11 0.5C5.20101 0.5 0.5 5.20101 0.5 11C0.5 16.799 5.20101 21.5 11 21.5ZM7.25 12.125C7.87132 12.125 8.375 11.6213 8.375 11C8.375 10.3787 7.87132 9.875 7.25 9.875C6.62868 9.875 6.125 10.3787 6.125 11C6.125 11.6213 6.62868 12.125 7.25 12.125ZM11 12.125C11.6213 12.125 12.125 11.6213 12.125 11C12.125 10.3787 11.6213 9.875 11 9.875C10.3787 9.875 9.875 10.3787 9.875 11C9.875 11.6213 10.3787 12.125 11 12.125ZM15.875 11C15.875 11.6213 15.3713 12.125 14.75 12.125C14.1287 12.125 13.625 11.6213 13.625 11C13.625 10.3787 14.1287 9.875 14.75 9.875C15.3713 9.875 15.875 10.3787 15.875 11Z'
        fill={color}
      />
    </svg>
  );
};


