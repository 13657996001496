import ButtonGroupV2 from '@va/deprecated/components/buttongroups/ButtonGroupV2';
import { useTranslate } from '@va/localization';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { buttonGroupTheme } from '@va/constants';
import { subscriptionType } from '@va/standalone/shared/constants';

const SubscriptionSwitchButton = (props) => {
  const { uiSubscriptionType, setSubscriptionType, uiSubscriptionPlan, paymentPlans } = props;
  const translate = useTranslate();

  function calculateSavingPercentage() {
    const yearlyPrice = parseFloat(paymentPlans.yearly[uiSubscriptionPlan].price);
    const monthlyPrice = parseFloat(paymentPlans.monthly[uiSubscriptionPlan].price);
    return Math.round((1 - yearlyPrice / (monthlyPrice * 12)) * 100);
  }

  const boolOptions = useMemo(
    () => [
      {
        label: translate('option.btn.monthly'),
        value: subscriptionType.MONTHLY,
      },
      {
        label: translate('option.btn.yearly'),
        value: subscriptionType.YEARLY,
        description: translate('upgradeModal.saveUpTo', {
          percent: calculateSavingPercentage(),
        }),
      },
    ],
    [translate],
  );

  return (
    <div className='flex justify-center mt-6px'>
      <ButtonGroupV2
        value={uiSubscriptionType}
        onChange={(value) => setSubscriptionType(value)}
        options={boolOptions}
        className='grow w-full justify-center'
        theme={buttonGroupTheme.GREEN}
        noWidth
        borderRadius={{
          leftBorderRadius: 'rounded-l-30',
          rightBorderRadius: 'rounded-r-30',
        }}
      />
    </div>
  );
};

export default SubscriptionSwitchButton;

SubscriptionSwitchButton.propTypes = {
  setSubscriptionType: PropTypes.func.isRequired,
  uiSubscriptionType: PropTypes.oneOf(Object.values(subscriptionType)).isRequired,
  uiSubscriptionPlan: PropTypes.number.isRequired,
  paymentPlans: PropTypes.object.isRequired,
};
