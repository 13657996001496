import { createColumnHelper } from '@tanstack/react-table';
import { isCompanyRevealFeatureEnabled } from '@va/dashboard/selectors/app';
import { CompanyRevealLabel, CompanyRevealPreview, CompanyType, CompanyVisitItem } from '@va/dashboard/shared/visitors';
import { LinkIcon } from '@va/icons';
import { useLocalizationContext } from '@va/localization';
import {
  fontWeights,
  IconWithBackground,
  ListCardItemWithIcon,
  Paragraph,
  paragraphSizes,
  ProgressBar,
} from '@va/ui/design-system';
import { addNumberSeparator } from '@va/util/helpers';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const columnHelper = createColumnHelper<CompanyVisitItem>();
export const useVisitsByCompanyColumns = () => {
  const { locale, translate } = useLocalizationContext();
  return useMemo(
    () => [
      columnHelper.display({
        id: 'title',
        meta: {
          useDefaultCellStyle: false,
          cellClassName: 'justify-start',
        },
        cell: ({
          row: {
            original: { companyOrgName, companyType },
          },
        }) => (
          <ListCardItemWithIcon
            icon={<IconWithBackground className='!bg-white-snow group-hover:!bg-white' icon={() => <LinkIcon />} />}
            topTextTooltipProps={{
              content: <TitleTopText companyName={companyOrgName} companyType={companyType} className='text-white' />,
            }}
            topText={<TitleTopText companyName={companyOrgName} companyType={companyType} />}
          />
        ),
      }),
      columnHelper.display({
        id: 'progress',
        cell: ({
          row: {
            original: { visitsPercentage },
          },
        }) => (
          <div className='w-full'>
            <ProgressBar progress={[{ percentage: visitsPercentage * 100, color: window.PRIMARY_COLOR }]} />
          </div>
        ),
      }),
      columnHelper.display({
        id: 'percentage',
        meta: {
          useDefaultCellStyle: false,
          cellClassName: 'justify-end',
        },
        cell: ({
          row: {
            original: { visitsPercentage, visits },
          },
        }) => {
          const percentage = visitsPercentage * 100;
          return (
            <div className={'text-right'}>
              <Paragraph weight={fontWeights.medium}>
                {addNumberSeparator(percentage, locale, { style: 'percent', signDisplay: 'never' })}
              </Paragraph>
              <Paragraph weight={fontWeights.medium} size={paragraphSizes.tiny}>
                {translate('all.companyVisits.visitsCount', { count: addNumberSeparator(visits, locale) })}
              </Paragraph>
            </div>
          );
        },
      }),
    ],
    [locale, translate],
  );
};

type TitleTopTextProps = {
  companyName: string | null;
  companyType: CompanyType;
  className?: string;
};

const TitleTopText = ({ companyName, companyType, className }: TitleTopTextProps) => {
  const showIp2Company = useSelector(isCompanyRevealFeatureEnabled);

  return showIp2Company ? (
    <CompanyRevealLabel companyName={companyName} companyType={companyType} className={className} />
  ) : (
    <CompanyRevealPreview />
  );
};
