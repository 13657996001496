import { PropsWithChildren, RefObject, createContext, useContext, useEffect, useRef, useState } from 'react';

type CtxValues = {
  isIntersecting: boolean | undefined;
  setIsIntersecting: React.Dispatch<React.SetStateAction<boolean>>;
};

const IntersectionObserverCtx = createContext<CtxValues>({ isIntersecting: undefined, setIsIntersecting: () => {} });

/**
 * @example
 * 1. Wrap the component inside this low level provider:
 *    <IntersectionObserverProvider>
 *      <Component />
 *    </IntersectionObserverProvider>
 * 2. Setup the observer inside the component:
 *    const elementRef = useRef()
 *    useStartIntersectionObserverCtx(elementRef)
 * 3. Access the isIntersecting state from anywhere inside the component tree without prop drilling
 */
export const IntersectionObserverProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  return (
    <IntersectionObserverCtx.Provider value={{ isIntersecting, setIsIntersecting }}>
      {children}
    </IntersectionObserverCtx.Provider>
  );
};

export const useIntersectionObserverCtx = () => useContext(IntersectionObserverCtx);

export const useStartIntersectionObserverCtx = (elRef: RefObject<Element>) => {
  const { setIsIntersecting } = useIntersectionObserverCtx();

  const observer = useRef(
    new IntersectionObserver((entry) => {
      const entry0 = entry[0];
      setIsIntersecting(entry0.isIntersecting);
    }, {}),
  );

  useEffect(() => {
    if (!elRef.current) return;
    const obs = observer.current;
    obs.observe(elRef.current);

    return () => {
      obs.disconnect();
    };
  }, [elRef]);
};
