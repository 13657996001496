import { cardColorTheme } from '@va/constants';
import PopUpCard from '@va/deprecated/components/PopUpCard';
import Translation from '@va/deprecated/components/Translation';
import PropTypes from 'prop-types';
import UpgradeGradientButton from './UpgradeGradientButton';
import './VisitsLimitReached.scss';
export default function VisitsLimitReached(props) {
  const { translate, closeModal, planName, visitLimitCount, isFree, hasChangeSubscriptionPermission } = props;
  return (
    <PopUpCard
      type='card-grey'
      colorTheme={cardColorTheme.PURPLE}
      title={translate('modal.visitsLimitReached.title')}
      footerButtons={
        <div className='upgrade-gradient-button-container' onClick={hasChangeSubscriptionPermission && closeModal}>
          <UpgradeGradientButton
            isDisabled={!hasChangeSubscriptionPermission}
            tooltipData={'tooltips.contributorRole'}
          />
        </div>
      }
    >
      <div className='text-content'>
        <p className='paragraph-with-padding-bottom'>
          {isFree ? (
            translate('modal.visitsLimitReached.text1', { count: visitLimitCount })
          ) : (
            <Translation
              translationKey={'modal.visitsLimitReached.text3'}
              parameters={{ currentPlanName: planName, count: visitLimitCount }}
            />
          )}
        </p>
        <p>{translate('modal.visitsLimitReached.text2')}</p>
      </div>
    </PopUpCard>
  );
}

VisitsLimitReached.propTypes = {
  translate: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isFree: PropTypes.bool.isRequired,
  planName: PropTypes.string,
  visitLimitCount: PropTypes.number,
  hasChangeSubscriptionPermission: PropTypes.bool.isRequired,
};
