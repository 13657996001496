import { tabNames, websiteCreationSteps, websiteFlagKeys } from '@va/constants';
import { updateWebsiteFlag } from '@va/dashboard/actions/api';
import { DataPrivacyStep } from '@va/dashboard/feature/privacy-center';
import { useActiveTab } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { InstallTrackingCodeStep } from '@va/standalone/core/shared/onboarding';
import { setWebsiteCreationStep } from '@va/standalone/shared/actions';
import { storageItems } from '@va/standalone/shared/constants';
import { PrivacyLevelsEnum } from '@va/standalone/shared/types';
import { MultiStepWizardStep } from '@va/ui/design-system';
import { LocalStorage } from '@va/util/helpers';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import AddWebsiteUrlStep from './AddWebsiteUrlStep';
import ChooseWebsitePlatformStep from './ChooseWebsitePlatformStep';
import { LoadingStep } from './LoadingStep';

export type AddWebsiteValues = {
  websiteUrl?: string;
  privacyMode?: PrivacyLevelsEnum;
};

export const useAddWebsiteSteps = () => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  useActiveTab(tabNames.addWebsite);

  const [stepErrors, setStepErrors] = useState<Record<number, boolean>>({});
  const [addWebsiteValues, setAddWebsiteValues] = useState<AddWebsiteValues>({});

  useEffect(() => {
    const storageAddWebsiteValues = LocalStorage.getItem(storageItems.addWebsite);
    if (!storageAddWebsiteValues) return;

    try {
      const initialAddWebsiteValues = JSON.parse(storageAddWebsiteValues);
      if (!isEqual(initialAddWebsiteValues, addWebsiteValues)) {
        setAddWebsiteValues(initialAddWebsiteValues);
      }
    } catch (error) {
      console.error('Error parsing add website values from local storage', error);
    }
  }, [addWebsiteValues]);

  const changeActiveStep = useCallback(
    (step: number) => {
      dispatch(setWebsiteCreationStep(step));
    },
    [dispatch],
  );

  const updateAddWebsiteValues = useCallback(
    (values: Partial<AddWebsiteValues>) => {
      setAddWebsiteValues((prev) => {
        const updatedValues = { ...prev, ...values };
        LocalStorage.setItem(storageItems.addWebsite, JSON.stringify(updatedValues));

        return updatedValues;
      });
    },
    [setAddWebsiteValues],
  );

  const resetAddWebsiteValues = useCallback(() => {
    setAddWebsiteValues({});
    LocalStorage.removeItem(storageItems.addWebsite);
  }, [setAddWebsiteValues]);

  const steps = useMemo<MultiStepWizardStep[]>(
    () => [
      {
        label: translate('standalone.websiteCreation.step1.title'),
        component: (
          <ChooseWebsitePlatformStep
            setActiveStep={() => {
              changeActiveStep(websiteCreationSteps.websiteUrl);
            }}
          />
        ),
        canReturn: true,
      },
      {
        label: translate('standalone.websiteCreation.step2.title'),
        component: (
          <AddWebsiteUrlStep
            onContinue={(value) => {
              updateAddWebsiteValues({ websiteUrl: value });
              changeActiveStep(websiteCreationSteps.privacyLevels);
            }}
            initialValue={addWebsiteValues.websiteUrl}
          />
        ),
        canReturn: true,
      },
      {
        label: translate('standalone.websiteCreation.step3.title'),
        component: (
          <DataPrivacyStep
            onContinue={(value) => {
              updateAddWebsiteValues({ privacyMode: value });
              changeActiveStep(websiteCreationSteps.loading);
            }}
            initialValue={addWebsiteValues.privacyMode}
          />
        ),
        canReturn: true,
      },
      {
        label: translate('onboarding.loadingStep.title'),
        component: (
          <LoadingStep
            onSuccess={() => {
              dispatch(updateWebsiteFlag(websiteFlagKeys.CREATION_PROCESS_COMPLETED, true));
              changeActiveStep(websiteCreationSteps.trackingCodeInstallation);
            }}
            values={addWebsiteValues}
          />
        ),
      },
      {
        label: translate('standalone.websiteCreation.step4.title'),
        hasError: stepErrors[websiteCreationSteps.trackingCodeInstallation],
        component: (
          <InstallTrackingCodeStep
            onError={() => {
              setStepErrors({ [websiteCreationSteps.trackingCodeInstallation]: true });
            }}
            onClickRetryInstallation={() => {
              setStepErrors({});
            }}
            onOpenDashboardClick={resetAddWebsiteValues}
          />
        ),
      },
    ],
    [
      addWebsiteValues,
      changeActiveStep,
      dispatch,
      resetAddWebsiteValues,
      stepErrors,
      translate,
      updateAddWebsiteValues,
    ],
  );

  return { steps, changeActiveStep };
};
