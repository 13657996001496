import { addNumberSeparator } from '@va/util/helpers';
import './NotificationLimitProgressBar.scss';
import { useLocalizationContext } from '@va/localization';

export default function NotificationLimitProgressBar({
  limit,
  consumed,
  translationKey,
}: {
  limit: number;
  consumed: number;
  translationKey?: string;
}) {
  const { translate, locale } = useLocalizationContext();
  const limitProgress = (consumed / limit) * 100;

  return (
    <div className='limit-notification-visits-counter'>
      <div className='page-visits-counter-container'>
        <div className='page-visits'>{translate('premiumOverlay.pageVisits.title')}</div>
        <div className='page-visits-counter'>
          {translate(translationKey ?? 'notifications.visitsLimit.visitsCounter', {
            visitsCount: addNumberSeparator(consumed, locale),
            visitsTotal: addNumberSeparator(limit, locale),
          })}
        </div>
      </div>
      <div className='page-visits-limit-progress-bar'>
        <div
          className='progress'
          style={{ width: `${limitProgress}%`, borderRadius: limitProgress === 100 ? '36px' : '36px 0 0 36px' }}
        />
      </div>
    </div>
  );
}
