export default function GradientChart(d3, domPointer, width, height, data, colors, margin) {
  const barHeight = height;
  const w = width - margin.left - margin.right;
  const chartColors = colors.chartColor;
  const chartData = [data.limit, data.remainingLimit];
  const total = d3.sum(chartData);
  const backgroundChartColor = colors.backgroundColor;
  const xScale = d3.scaleLinear().domain([0, total]).range([0, w]);
  const refContainer = '#' + domPointer;
  const remainingChartWidthCal = (data.remainingLimit / data.limit) * 100;
  const remainingChartWidth = (width / 100) * remainingChartWidthCal;

  const selection = d3
    .select(refContainer)
    .append('svg')
    .attr('class', 'first-chart')
    .attr('width', width - remainingChartWidth)
    .attr('height', height)
    .append('g')
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

  const grad = selection
    .append('defs')
    .append('linearGradient')
    .attr('id', 'grad')
    .attr('x1', '10%')
    .attr('x2', '100%')
    .attr('y1', '0%')
    .attr('y2', '0%');

  grad
    .selectAll('stop')
    .data(chartColors)
    .enter()
    .append('stop')
    .style('stop-color', function (d) {
      return d;
    })
    .attr('offset', function (d, i) {
      return 100 * (i / (chartColors.length - 1)) + '%';
    });

  selection
    .selectAll('rect')
    .data(chartData)
    .enter()
    .append('rect')
    .attr('class', 'rect-stacked')
    .attr('x', function (d, i) {
      return xScale(i ? data.limit : 0);
    })
    .attr('y', 0)
    .attr('height', barHeight)
    .attr('width', (d, i) => xScale(chartData[i]))
    .style('fill', 'url(#grad)');

  // background chartsecons chart
  const selection_background_chart = d3
    .select(refContainer)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

  selection_background_chart
    .selectAll('rect')
    .data(chartData)
    .enter()
    .append('rect')
    .attr('class', 'rect-stacked')
    .attr('x', function (d, i) {
      return xScale(i ? data.limit : 0);
    })
    .attr('y', 0)
    .attr('height', barHeight)
    .attr('width', (d, i) => xScale(chartData[i]))
    .style('fill', backgroundChartColor);
}
