import { modalName } from '@va/constants';
import { closeModal } from '@va/dashboard/actions/ui';
import { isModalOpen } from '@va/dashboard/selectors/ui';
import { useTranslate } from '@va/localization';
import { Button, GrayGradientDivider, ModalHeader, Paragraph } from '@va/ui/design-system';
import { ModalWrapper } from '@va/util/components';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const AccountSettingsRequiredModal = () => {
  const translate = useTranslate();

  const dispatch = useDispatch();

  const isOpen = useSelector((state) => isModalOpen(state, modalName.accountSettingsRequired));

  const handleModalClose = useCallback(() => {
    dispatch(closeModal(modalName.accountSettingsRequired));
  }, [dispatch]);

  return (
    <ModalWrapper closeModal={handleModalClose} isModalOpen={isOpen}>
      <div className='modal-content max-w-720px'>
        <ModalHeader title={translate('modal.accountSettingsRequired.title')} onClose={() => handleModalClose()} />
        <div className='flex p-3'>
          <Paragraph>{translate('modal.accountSettingsRequired.text')}</Paragraph>
        </div>
        <GrayGradientDivider />
        <div className='flex p-3'>
          <Button color='tertiary' text={translate('button.close')} onClick={handleModalClose} className='grow' />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AccountSettingsRequiredModal;
