import { fontWeights, Paragraph, paragraphSizes } from '@va/ui/design-system';
import { formatMomentDuration, formatTime } from '@va/util/helpers';
import moment from 'moment';
import React from 'react';

type DurationCellProps = {
  duration: number;
  size?: 'small' | 'medium';
};

export const DurationCell: React.FC<DurationCellProps> = ({ duration, size = 'medium' }) => {
  const momentDuration = formatMomentDuration(moment.duration(duration));

  return (
    <Paragraph size={size === 'medium' ? paragraphSizes.normal : paragraphSizes.tiny} weight={fontWeights.medium}>
      {formatTime(momentDuration)}
    </Paragraph>
  );
};
