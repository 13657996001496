import { TEST_IDS, VISITORS_MAP_CENTER_PROPERTY, tabNames } from '@va/constants';
import { setTabProperty } from '@va/dashboard/actions/ui';
import { NavMilestone } from '@va/icons';
import { useTranslate } from '@va/localization';
import { LocationType } from '@va/types/location';
import { TooltipWrapper } from '@va/ui/tooltips';
import { Flag } from '@va/util/components';
import { isValidCountryCode } from '@va/util/helpers';
import classNames from 'classnames';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

export const CountryLocationIcon: React.FC<{
  location: LocationType;
  countryCode: string;
  size?: 'small' | 'medium';
  square?: boolean;
  className?: string;
  isClickable?: boolean;
}> = ({ location, countryCode, size, square = false, className, isClickable = true }) => {
  const history = useHistory();
  const translate = useTranslate();
  const { onClickLocation, hasLocation } = useClickLocation(location);
  const [isFlagHovered, setFlagHovered] = useState(false);

  const isCountryCodeValid = isValidCountryCode(countryCode);

  const ICON_WRAPPER_CLASS = classNames('bg-white-snow group-hover:bg-white flex justify-center items-center', {
    'rounded-full': !square,
    'rounded-9px': square,
    'w-9 h-9': size === 'small',
    'w-12 h-12': size === 'medium',
  });

  const icon = () => {
    if (isFlagHovered && hasLocation) {
      return (
        <TooltipWrapper
          content={
            isCountryCodeValid
              ? translate('panels.latestVisitors.locationPinTooltip')
              : translate('panels.latestVisitors.noCountryInfo')
          }
        >
          <button
            data-testid={TEST_IDS.generic.links.location}
            onClick={() =>
              isClickable && isCountryCodeValid && [onClickLocation(location), history.push('/visitors/map')]
            }
            className={classNames(ICON_WRAPPER_CLASS, { 'cursor-default': !isClickable })}
          >
            <NavMilestone />
          </button>
        </TooltipWrapper>
      );
    }

    return (
      <div data-testid={TEST_IDS.generic.links.location} className={ICON_WRAPPER_CLASS}>
        <Flag countryCode={countryCode} size='1x1' className='w-18 h-18 rounded-full' />
      </div>
    );
  };

  return (
    <div
      className={classNames({ 'cursor-pointer': isClickable }, className)}
      onMouseEnter={() => isClickable && setFlagHovered(true)}
      onMouseLeave={() => isClickable && setFlagHovered(false)}
    >
      {icon()}
    </div>
  );
};

export const useClickLocation = (location: LocationType) => {
  const hasLocation = Number.isFinite(location.lat) && Number.isFinite(location.lng);
  const dispatch = useDispatch();
  const onClickLocation = ({ lat, lng }: LocationType) => {
    const location = { latitude: lat, longitude: lng };
    dispatch(setTabProperty(tabNames.latestVisitorsMap, VISITORS_MAP_CENTER_PROPERTY, location));
  };
  return { hasLocation, onClickLocation };
};
