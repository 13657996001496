import { useTranslate } from '@va/localization';
import { Flag } from '@va/util/components';
import PropTypes from 'prop-types';

export const CountryCell = ({ value }) => {
  const translate = useTranslate();
  return (
    <div className='flex items-center'>
      <Flag countryCode={value} className='rounded-1/2 h-5 w-5 mr-3' size={'1x1'} />
      <span>{translate(`country.name.${value}`)}</span>
    </div>
  );
};

CountryCell.propTypes = {
  value: PropTypes.string, //countryCode
};
