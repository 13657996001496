import { TEST_IDS } from '@va/constants';
import classNames from 'classnames';
import { ReactNode } from 'react';

export const SelectorButton = ({
  leftElement,
  topNode,
  bottomNode,
  rightElement,
  className,
}: {
  leftElement?: ReactNode;
  topNode: ReactNode;
  bottomNode: ReactNode;
  rightElement?: ReactNode;
  className?: string;
}) => {
  return (
    <div
      data-testid={TEST_IDS.generic.dropdown}
      className={classNames('flex items-center flex-nowrap w-full rounded-21 p-4 cursor-pointer', className)}
    >
      {leftElement && (
        <div className={'shrink-0 h-12 w-12 rounded-full flex items-center justify-center bg-white-snow'}>
          {leftElement}
        </div>
      )}
      <div className='flex flex-col items-start grow truncate px-15px w-full'>
        {topNode}
        {bottomNode}
      </div>
      {rightElement && <div className='flex shrink-0'>{rightElement}</div>}
    </div>
  );
};
