import React from 'react';

export const MinusIcon: React.FC<{ color?: string; className?: string }> = ({ color = '#666666', className }) => {
  return (
    <svg
      className={className}
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='9' cy='9.25' r='8.25' stroke={color} strokeWidth='1.5' />
      <path d='M13.5 8.5V10L4.5 10L4.5 8.5L13.5 8.5Z' fill={color} />
    </svg>
  );
};
