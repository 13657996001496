import { apiStatus } from '@va/constants';
import { closeModal, openModal } from '@va/dashboard/actions/ui';
import { isMobileDevice } from '@va/dashboard/selectors/ui';
import { NavFeedback, NavSettings } from '@va/icons';
import { useTranslate } from '@va/localization';
import { modalName } from '@va/standalone/shared/constants';
import { getApiRequest, getApiRequestStatus } from '@va/standalone/shared/selectors';
import { CheckMarkWithFilledBackground, UnCheckMarkIcon } from '@va/svg-visa-icons';
import classNames from 'classnames';
import moment from 'moment';
import { FC, ReactNode, memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useAgencyWhiteLabelContext from '../../agency-white-label-v2/AgencyWhiteLabelContext';
import './AgencyWhiteLabelTasks.scss';

const AgencyWhiteLabelTasks = memo(() => {
  const dispatch = useDispatch();
  const isMobile = useSelector(isMobileDevice);
  const agencyUi = useSelector((state) => getApiRequest(state, 'getAgencyUi'))?.get('data');

  const agencyUiStatus = useSelector((state) => getApiRequestStatus(state, 'getAgencyUi'));
  const createdAtValue = agencyUi?.get('createdAt');
  const agencyCustomUrl = agencyUi?.get('customUrl');
  const fromNowValue = useMemo(() => moment(createdAtValue).fromNow(), [createdAtValue]);
  const hasAgencyUi = agencyUiStatus === apiStatus.SUCCEEDED && agencyCustomUrl;
  const history = useHistory();

  const { data: websites, isLoading: isLoadingWebsites } = useAgencyWhiteLabelContext();
  const hasContributor = websites?.hasContributors || false;

  const inProgress = useMemo(() => {
    return agencyUiStatus === apiStatus.IN_PROGRESS || isLoadingWebsites;
  }, [agencyUiStatus, isLoadingWebsites]);

  function handleManageContributorClick(selectedWebsite: string) {
    dispatch(closeModal(modalName.WHITE_LABEL_WEBSITE));
    history.push(`/website/${selectedWebsite}/settings/manage-contributors`);
  }

  const whiteLabelFeatures = [
    {
      name: 'contributor',
      icon: <NavSettings />,
      isChecked: hasContributor,
      onClick: () =>
        dispatch(
          openModal(modalName.WHITE_LABEL_WEBSITE, {
            onSubmit: handleManageContributorClick,
            isWixWebsiteAllowed: true,
          }),
        ),
    },
    {
      name: 'settingsAndAppearnce',
      icon: <NavFeedback />,
      onClick: () => history.push('/my-account/white-label-customization'),
      isChecked: hasAgencyUi,
      lastModified: fromNowValue,
    },
  ];

  if (inProgress || isMobile || (hasContributor && agencyCustomUrl)) {
    return null;
  }

  return (
    <div className='agency-white-label-tasks-wrapper'>
      {whiteLabelFeatures.map((item) => {
        return <Task key={item.name} baseTransKey={'whileLabel.manage'} item={item} />;
      })}
    </div>
  );
});

export default AgencyWhiteLabelTasks;

const Task: FC<{
  baseTransKey: string;
  item: {
    name: string;
    icon: ReactNode;
    onClick: () => void;
    isChecked: boolean;
    lastModified?: string | number;
  };
}> = (props) => {
  const translate = useTranslate();
  const { baseTransKey, item } = props;
  const { name, icon, onClick, isChecked, lastModified } = item;
  return (
    <div
      key={name}
      className={classNames(
        'w-full feature-section flex mobile:flex-col justify-between p-4 flex-wrap cursor-pointer',
        { 'border-green-salem z-10 relative': isChecked },
        { 'border-gray-gallery': !isChecked },
      )}
      onClick={() => (onClick ? onClick() : undefined)}
    >
      <div className='flex items-center w-4/6 mobile:w-full'>
        {isChecked ? (
          <div className='check-mark-icon'>
            <CheckMarkWithFilledBackground />{' '}
          </div>
        ) : (
          <div className='uncheck-mark-icon'>
            <UnCheckMarkIcon />
          </div>
        )}
        <div className='flex flex-column ml-6px'>
          <div className='font-semibold text-lg leading-normal text-mine-shaft'>
            {translate(`${baseTransKey}.${name}.title`)}
          </div>
          <div className='font-normal text-15 leading-normal text-gray-devil'>
            {translate(`${baseTransKey}.${name}.subTitle`)}
          </div>
        </div>
      </div>
      <div className='flex items-center mt-0 sm:mt-4 w-2/6 justify-end mobile:justify-start moblie:flex-row-reverse mobile:w-full mobile:mt-6px '>
        <div className='mr-6px '>
          <div className='font-medium text-15 leading-normal text-mine-shaft'>
            {translate(`${baseTransKey}.${name}.label`)}
          </div>
          {lastModified && (
            <div className='font-normal text-xs leading-18 text-gray-devil flex w-full justify-end'>
              <span>{translate(`agencyWhiteLabel.lastModified`)}</span> : {lastModified}
            </div>
          )}
        </div>
        <div>{icon}</div>
      </div>
    </div>
  );
};
