export const MoreOptionsDropdownIcon = () => {
  return (
    <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='36' height='36' rx='12' fill='#F9F9F9' />
      <circle cx='11' cy='18' r='2' fill='#666666' />
      <circle cx='18' cy='18' r='2' fill='#666666' />
      <circle cx='25' cy='18' r='2' fill='#666666' />
    </svg>
  );
};
