import { countryCodes } from '@va/assets';
import { cloneDeep } from 'lodash';

/**
 *
 * @deprecated TODO RECHECK
 */
export function getFilterWithSearchType(filter: any, excludeSearchText?: string) {
  let type = 'general';

  // 1-3 digits
  //    or
  // 1-3 groups of 1-3 digits and dot, optionally followed by 1-3 digits
  const ipRegex = /^([0-9]{1,3}|([0-9]{1,3}\.){1,3}([0-9]{1,3})?)$/;
  if (ipRegex.test(filter.search)) {
    type = 'ip';
  }

  // clone filter so we don't change the one from our state
  const newFilter = cloneDeep(filter);

  if (type === 'general') {
    // create map: name to alpha2
    const map: { [countryName: string]: string } = countryCodes.reduce(
      (acc, item) =>
        Object.assign(acc, {
          [item.name && item.name.toLowerCase()]: item.alpha2,
        }),
      {},
    );

    const code = map[newFilter.search && newFilter.search.toLowerCase()];
    if (code) {
      type = 'countryCode';
      newFilter.search = code;
    }
  }

  if (newFilter.search === 'windows') {
    type = 'platform';
    newFilter.search = 'win';
  }

  const search = { [type]: excludeSearchText ? '' : newFilter.search || '' };
  return Object.assign({}, newFilter, { search: search });
}
