import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ChromeIcon } from '../browser-icons';
import { AppleIcon } from './AppleIcon';
import { BlackBerryLogo } from './BlackBerryIcon';
import { LinuxLogo } from './LinuxIcon';

export function OS(props: JSX.IntrinsicElements['svg']) {
  const os = props.type ?? '';
  switch (os.toLowerCase().trim()) {
    case 'macos':
    case 'macosx':
    case 'macintosh':
    case 'macos mavericks':
    case 'macos yosemite':
    case 'macos el capitan':
    case 'macos sierra':
    case 'macos high sierra':
    case 'macos mojave':
    case 'macos catalina':
    case 'macos big sur':
    case 'ios':
    case 'ipados':
      return <AppleIcon color='#FFFFFF80' {...props} />;
    case 'win95':
    case 'win98':
    case 'win2000':
    case 'winnt':
    case 'winrt8':
    case 'winrt8.1':
    case 'win32':
    case 'win7':
    case 'win8':
    case 'win8.1':
    case 'win10':
    case 'winxp':
    case 'windows xp':
    case 'winvista':
    case 'windows':
    case 'windows 10':
    case 'windows 7':
    case 'windows 8':
    case 'windows 8.1':
    case 'winmobile':
    case 'winphone':
    case 'winphone7':
    case 'winphone7.5':
    case 'winphone8':
    case 'winphone8.1':
    case 'winphone10':
      return <Windows {...props} />;
    case 'linux':
    case 'linux smartphone os':
    case 'ubuntu':
      return <LinuxLogo {...props} color='#FFFFFF80' />;
    case 'android':
    case 'android pie':
    case 'android oreo':
    case 'android for googletv':
      return <Android {...props} color='#FFFFFF80' />;
    case 'rim os':
    case 'rim tablet os':
      return <BlackBerryLogo {...props} color='#FFFFFF80' />;
    case 'chromeos':
      return <ChromeIcon {...props} />;
    case 'unknown':
    case 'default browser':
      return <UnknownOS {...props} />;
    case null:
    case undefined:
      return null;
    default:
      return <UnknownOS {...props} />;
  }
}

OS.propTypes = {
  className: PropTypes.string.isRequired,
  type: PropTypes.string,
};

function Android({ color, ...props }: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        d='M15.6,2.8l0.6-0.9c0.1-0.1,0.1-0.3,0.1-0.5S16.2,1.1,16,1c-0.3-0.2-0.7-0.1-0.9,0.2l-0.8,1.1
      c-1.4-0.4-2.9-0.4-4.2,0L9.4,1.1C9.2,0.8,8.8,0.7,8.5,0.9S8.1,1.5,8.3,1.8l0.6,0.9c-1.5,1-2.5,2.6-2.5,4.2c0,0.3,0.2,0.5,0.5,0.5
      h10.9c0.3,0,0.5-0.2,0.5-0.5C18.2,5.3,17.2,3.7,15.6,2.8z M7.4,6.4c0.1-1.2,1.1-2.4,2.4-3c0.1,0,0.1-0.1,0.2-0.1
      c0.1,0,0.1,0,0.2,0c1.3-0.5,2.9-0.5,4.3,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0.1,0.2,0.1c1.2,0.6,2.1,1.8,2.3,3H7.4z M17.7,7.9H6.8
      c-0.3,0-0.5,0.2-0.5,0.5v8.8c0,0.9,0.8,1.7,1.7,1.7h0.4v2.3c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6v-2.3h1.1v2.3
      c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6v-2.3h0.4c0.9,0,1.7-0.8,1.7-1.7V8.3C18.2,8.1,18,7.9,17.7,7.9z M17.2,17.1
      c0,0.4-0.3,0.8-0.8,0.8h-0.9c-0.1,0-0.3,0.1-0.4,0.2C15,18.2,15,18.4,15,18.5s0,0.1,0,0.2v2.5c0,0.4-0.3,0.7-0.7,0.7
      s-0.7-0.3-0.7-0.7v-2.5c0-0.1,0-0.1,0-0.2s0-0.3-0.1-0.4s-0.2-0.2-0.4-0.2h-2.2c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.1,0.3-0.1,0.4
      s0,0.1,0,0.2v2.5c0,0.4-0.3,0.7-0.7,0.7S9,21.6,9,21.2v-2.5c0-0.1,0-0.1,0-0.2s0-0.3-0.1-0.4s-0.2-0.2-0.4-0.2H8.1
      c-0.4,0-0.8-0.3-0.8-0.8V8.8h9.9V17.1L17.2,17.1z M4.4,8.1c-0.9,0-1.6,0.7-1.6,1.6v5c0,0.9,0.7,1.6,1.6,1.6S6,15.6,6,14.7v-5
      C6.1,8.8,5.3,8.1,4.4,8.1z M5.1,14.7c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7v-5C3.7,9.3,4,9,4.4,9s0.7,0.3,0.7,0.7V14.7z
       M20.1,8.1c-0.9,0-1.6,0.7-1.6,1.6v5c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6v-5C21.8,8.8,21,8.1,20.1,8.1z M20.8,14.7
      c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7v-5c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7V14.7z M14.5,4.3
      c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6C15.1,4.6,14.8,4.3,14.5,4.3z M10.3,4.3c-0.3,0-0.6,0.3-0.6,0.6
      s0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6C10.8,4.6,10.6,4.3,10.3,4.3z'
        fill={color}
      />
    </svg>
  );
}

function Windows({ className, ...props }: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classNames('rounded', className)}
      {...props}
    >
      <path d='M7.29199 0.5H0.166992V7.625H7.29199V0.5Z' fill='#F25022' />
      <path d='M15.167 0.5H8.04199V7.625H15.167V0.5Z' fill='#7FBA00' />
      <path d='M7.29199 8.375H0.166992V15.5H7.29199V8.375Z' fill='#00A4EF' />
      <path d='M15.167 8.375H8.04199V15.5H15.167V8.375Z' fill='#FFB900' />
    </svg>
  );
}

export function UnknownOS(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 20 20'
      xmlSpace='preserve'
      {...props}
    >
      <g>
        <path
          d='M10,14.2c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6C10.6,14,10.3,14.2,10,14.2z M10,13.5
        c-0.1,0-0.2,0.1-0.2,0.2c0,0.2,0.4,0.2,0.4,0C10.2,13.5,10.1,13.5,10,13.5z'
        />
      </g>
      <g>
        <path d='M13.4,18H6.6l1.8-3.3h3.3L13.4,18z M7.9,17.2h4.2l-0.9-1.7H8.8L7.9,17.2z' />
      </g>
      <path d='M0.8,2.5v9v0.7v3.3h18.3v-3.3v-0.7v-9H0.8z M18.4,14.7H1.6v-2.5h16.8V14.7z M1.6,11.5V3.3h16.8v8.2H1.6z' />
      <g>
        <path
          d='M8.5,4.6C8.9,4.2,9.4,4,10.1,4c0.6,0,1.1,0.2,1.5,0.5c0.4,0.3,0.5,0.8,0.5,1.3c0,0.3-0.1,0.6-0.2,0.8
        S11.5,7.1,11,7.5c-0.3,0.3-0.5,0.5-0.6,0.7c-0.1,0.2-0.1,0.5-0.1,0.9H9.5c0-0.4,0.1-0.8,0.2-1s0.3-0.6,0.7-0.9l0.4-0.4
        c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.2,0.2-0.4,0.2-0.6c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.9-0.3c-0.5,0-0.9,0.2-1.1,0.6
        C8.8,5.5,8.8,5.8,8.8,6.1H7.9C7.9,5.5,8.1,5,8.5,4.6z M9.5,9.8h0.9v0.9H9.5V9.8z'
        />
      </g>
    </svg>
  );
}
