import { IconProps } from '.';

export const PriceTagIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.55946 10.0593L10.4988 2.12H15.8775V7.49868L7.93814 15.438C7.35235 16.0238 6.4026 16.0238 5.81682 15.438L2.55946 12.1807C1.97367 11.5949 1.97367 10.6451 2.55946 10.0593ZM9.87748 0.619995H15.8775H17.3775V2.12V8.12L8.9988 16.4987C7.82722 17.6702 5.92773 17.6702 4.75616 16.4987L1.4988 13.2413C0.327224 12.0697 0.327224 10.1702 1.4988 8.99868L9.87748 0.619995ZM12.8775 6.62C13.7059 6.62 14.3775 5.94842 14.3775 5.12C14.3775 4.29157 13.7059 3.62 12.8775 3.62C12.049 3.62 11.3775 4.29157 11.3775 5.12C11.3775 5.94842 12.049 6.62 12.8775 6.62Z'
        fill={color}
      />
    </svg>
  );
};
