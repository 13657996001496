import { ONE_MINUTE } from '@va/constants';
import { updateWebsiteFlags as updateWebsiteFlagsAction } from '@va/dashboard/actions/api';
import { getWebsite } from '@va/dashboard/selectors/core';
import { useTranslate } from '@va/localization';
import { deleteCookie, generateString, getWellFormedUrl, setCookie } from '@va/util/helpers';
import { useListenForMessage } from '@va/util/hooks';
import Config from 'Config';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAddNotification } from './useAddNotification';

const COOKIE_VERIFY_TRACKING_CODE = 'verify_trackingCode';

export enum AsyncStatus {
  INITIAL = 'INITIAL',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export function useTrackingCodeVerifier(onSuccessCallback: () => void) {
  const { id: websiteId, url: websiteUrl } = useSelector(getWebsite);

  const randomToken = useMemo(() => generateString(16), []);
  const timeoutHandle = useRef<NodeJS.Timeout>();
  const popupWindow = useRef<Window | null>();
  const dispatch = useDispatch();
  const translate = useTranslate();

  const [status, setStatus] = useState<AsyncStatus>(AsyncStatus.INITIAL);
  const { showSuccessNotification, showErrorNotification } = useAddNotification();

  const cookieDomain = Config.customDomain;

  useEffect(() => {
    if (status === AsyncStatus.ERROR || status === AsyncStatus.SUCCESS) {
      deleteCookie(COOKIE_VERIFY_TRACKING_CODE, cookieDomain);
    }
  }, [cookieDomain, status]);

  const updateTrackingCodeFlag = useCallback(
    (isTrackingCodeValid: boolean) => {
      dispatch(
        updateWebsiteFlagsAction({
          trackingCodeInstalled: isTrackingCodeValid,
          ssrCodeInstalled: isTrackingCodeValid,
        }),
      );
    },
    [dispatch],
  );

  const verify = useCallback(async () => {
    // await is needed here to prevent issues that can appear on some browsers after window.open is called, check VISA-7769
    // when window.open is called, it is interrupting React's process and causing the state updates to become out of sync
    await setStatus(AsyncStatus.IN_PROGRESS);

    try {
      const formattedUrl = getWellFormedUrl(websiteUrl);
      if (!formattedUrl) {
        throw new Error('URL not set');
      }

      popupWindow.current = window.open(
        `${formattedUrl}?visa_worker_validate=${randomToken}&visa_worker_id=${websiteId}&cacheBuster=${String(
          Math.random(),
        )}`,
        'VISA Verify Tracking code',
        'width=auto,height=auto',
      );

      if (!popupWindow.current) {
        throw new Error('Could not open popup');
      }

      const workerValidateCookie = {
        websiteId: websiteId,
        validateToken: randomToken,
      };
      setCookie(COOKIE_VERIFY_TRACKING_CODE, JSON.stringify(workerValidateCookie), ONE_MINUTE, cookieDomain);
    } catch (error) {
      console.warn(error);
      setStatus(AsyncStatus.ERROR);
      showErrorNotification(2000, translate('notifications.trackingCode.couldNotOpenPopup'));
      return;
    }

    // This must be larger than the timeout on the wix worker (currently at 5000ms)
    const verifyTimeout = 15000;
    timeoutHandle.current = setTimeout(() => {
      popupWindow.current?.close();

      showErrorNotification(5000, translate('notifications.trackingCode.snippetNotFound'));
      updateTrackingCodeFlag(false);
      setStatus(AsyncStatus.ERROR);
    }, verifyTimeout);
  }, [websiteUrl, randomToken, websiteId, cookieDomain, showErrorNotification, translate, updateTrackingCodeFlag]);

  const onSuccess = useCallback(() => {
    timeoutHandle.current && clearTimeout(timeoutHandle.current);
    showSuccessNotification(5000, translate('notifications.trackingCode.snippetFound'));

    popupWindow.current?.close();

    updateTrackingCodeFlag(true);
    onSuccessCallback();

    setStatus(AsyncStatus.SUCCESS);
  }, [onSuccessCallback, showSuccessNotification, translate, updateTrackingCodeFlag]);

  const onMessage = useCallback(
    (message: MessageEvent) => {
      if (!message || !message.data || typeof message.data !== 'string') {
        return;
      }

      let decodedMessage: { type: string; token: string };
      try {
        decodedMessage = JSON.parse(message.data);
      } catch (error) {
        if (process.env['NODE_ENV'] === 'development') {
          console.error('An error has occurred while decoding message data:', message.data);
        }
        return;
      }

      switch (decodedMessage.type) {
        case 'VISA_WORKER_VALIDATE':
          if (decodedMessage.token !== randomToken) return;

          onSuccess();
          return;

        default:
          return;
      }
    },
    [randomToken, onSuccess],
  );

  useListenForMessage(onMessage);

  return { verify, status };
}
