import { BLACK_COLOR, tabNames } from '@va/constants';
import { usePollQuestionResults, useSurveyQuestionResults } from '@va/dashboard/api-client/Feedback';
import { QUESTION_TYPE, TEXT_TYPE_QUESTION, VISITOR_TYPE } from '@va/dashboard/modules/feedback/shared';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { isMobile } from '@va/dashboard/selectors/ui';
import { useLocale, useTranslate } from '@va/localization';
import { PlusInCircleIcon } from '@va/svg-visa-icons';
import { LoadingLogo } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { getFormattedDateAndTime } from '@va/util/helpers';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import './AnsweredQuestion.scss';
import Question from './Question';

const MAX_ANSWERS_LIMIT = 5;

const AnsweredQuestionContainer = (props) => {
  const [showAllAnswers, setShowAllAnswers] = useState(false);

  return !showAllAnswers ? (
    <AnsweredQuestion {...props} showAllAnswers={showAllAnswers} setShowAllAnswers={setShowAllAnswers} />
  ) : (
    <AnsweredQuestionWithAllResults
      {...props}
      featureName={props.featureName}
      questionId={props.id}
      showAllAnswers={showAllAnswers}
      setShowAllAnswers={setShowAllAnswers}
    />
  );
};

const AnsweredQuestionWithAllResults = (props) => {
  const { id } = useParams();
  const instanceId = useSelector(getInstanceId);
  const useFeedbackQuestionResults =
    props.featureName === tabNames.surveys ? useSurveyQuestionResults : usePollQuestionResults;
  const { data: results, isLoading } = useFeedbackQuestionResults(instanceId, id, props.questionId);

  if (isLoading)
    return (
      <div className='flex relative min-h-200'>
        <LoadingLogo />
      </div>
    );

  return <AnsweredQuestion {...props} answers={results.answers} />;
};

const AnsweredQuestion = ({
  number,
  type,
  body,
  description,
  answers = [],
  labels = [],
  answerCount,
  average,
  totalParticipantsCount,
  showAllAnswers,
  setShowAllAnswers,
}) => {
  const translate = useTranslate();
  const isTextTypeQuestion = TEXT_TYPE_QUESTION.includes(type);

  const answersGraph = useMemo(() => {
    switch (type) {
      case QUESTION_TYPE.MULTIPLE_CHOICE:
        return <HorizontalAnswers answers={answers} />;
      case QUESTION_TYPE.RATING:
      case QUESTION_TYPE.RATING_SCALE:
        return <VerticalAnswers answers={answers} labels={labels} />;
      case QUESTION_TYPE.SLIDER:
        return <SliderAnswer average={average} labels={labels} />;
      default:
        return <TextAnswers answers={answers} />;
    }
  }, [answers, average, labels, type]);

  return (
    <Question
      number={number}
      type={type}
      className='text-lg leading-snug'
      overrideChildrenStyle={isTextTypeQuestion && 'py-4'}
      rightHeaderSlot={
        <div className='text-xs font-medium text-devil-gray'>
          {translate('feedback.stats.responsesOutOf', {
            count: answerCount,
            outOfCount: totalParticipantsCount,
          })}
        </div>
      }
    >
      <div
        className={classNames('text-devil-gray font-black text-xl', {
          'px-6': isTextTypeQuestion,
        })}
      >
        {body}
      </div>
      {description && (
        <div
          className={classNames('py-2 text-sm leading-normal text-devil-gray', {
            'px-6': isTextTypeQuestion,
          })}
        >
          {description}
        </div>
      )}

      {answersGraph}

      {isTextTypeQuestion && answerCount > MAX_ANSWERS_LIMIT && (
        <FooterSeeMoreOrLess showAllAnswers={showAllAnswers} setShowAllAnswers={setShowAllAnswers} />
      )}
    </Question>
  );
};

AnsweredQuestion.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.number.isRequired,
  body: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string,
      visitor: PropTypes.string,
      date: PropTypes.number,
    }),
  ),
};

export default AnsweredQuestionContainer;

const FooterSeeMoreOrLess = ({ showAllAnswers, setShowAllAnswers }) => {
  const translate = useTranslate();
  return (
    <div className='absolute w-full align-center'>
      <span className='bg-white px-4'>
        {showAllAnswers ? (
          <span className='px-2 py-2 text-base cursor-pointer' onClick={() => setShowAllAnswers(false)}>
            {translate('feedback.stats.seeLess')}
          </span>
        ) : (
          <span className='px-4 py-2 text-base contents cursor-pointer' onClick={() => setShowAllAnswers(true)}>
            {translate('feedback.stats.seeMore')}
            <PlusInCircleIcon height='16' width='16' class={'inline-flex ml-2'} color={BLACK_COLOR} />
          </span>
        )}
      </span>
    </div>
  );
};

const Divider = () => {
  return <hr className='border-gray-gallery border-t-2 mt-4 mb-4 -ml-6 -mr-6' />;
};

const TextAnswers = ({ answers }) => {
  const translate = useTranslate();
  const { locale } = useLocale();
  const sortedAnswers = answers.sort((d1, d2) => new Date(d2.createdAt) - new Date(d1.createdAt));
  return (
    <div className='max-h-580 overflow-y-auto overflow-x-hidden answered-question-graph'>
      {sortedAnswers.map((answer, index) => {
        return (
          <div key={index}>
            <Divider />
            <div className='flex items-center px-6'>
              <div className='mr-2'>{answer.body}</div>
              <div className='ml-auto text-devil-gray text-xs font-medium flex-wrap	flex justify-end'>
                {VISITOR_TYPE[answer.visitor] && (
                  <span className='whitespace-nowrap'>
                    {translate(`feedback.stats.visitor.${VISITOR_TYPE[answer.visitor].toLowerCase()}`)}
                    ,&nbsp;
                  </span>
                )}

                <span className='text-gray-dusty whitespace-nowrap'>
                  {getFormattedDateAndTime(answer.createdAt, locale)}
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const HorizontalAnswers = ({ answers }) => {
  const mappedAnswers = useMemo(
    () =>
      answers.map((answer) => {
        return {
          choice: Object.keys(answer)[0],
          amount: Object.values(answer)[0],
        };
      }),
    [answers],
  );
  const total = useMemo(() => mappedAnswers.reduce((acc, curr) => acc + curr.amount, 0), [mappedAnswers]);
  const sortedAnswers = useMemo(() => mappedAnswers.sort((a, b) => b.amount - a.amount), [mappedAnswers]);

  return (
    <div>
      <Divider />

      {sortedAnswers.map((answer, index) => {
        return <HorizontalAnswer key={index} answer={answer} total={total} />;
      })}
    </div>
  );
};

const HorizontalAnswer = ({ answer: { amount, choice }, total }) => {
  const translate = useTranslate();
  const percentage = useMemo(() => ((amount / total) * 100).toFixed(0), [amount, total]);
  const progressBarStyle = useMemo(() => ({ width: `${percentage}%` }), [percentage]);
  const isMobileDevice = useSelector(isMobile);

  return (
    <div className='flex mt-4 items-center font-semibold leading-normal'>
      {!isMobileDevice && <div className='w-1/6'>{percentage === 'NaN' ? '0%' : `${percentage}%`}</div>}

      <div className='flex items-center relative grow text-white bg-fuel-yellow/60 rounded-xl p-4'>
        <div
          style={progressBarStyle}
          className='bg-gradient-to-r from-fuel-yellow-60 to-fuel-yellow absolute inset-0 rounded-xl'
        />
        <span className='z-1 mr-2 flex flex-column'>
          {isMobileDevice && <span>{percentage}%</span>}
          {choice}
        </span>
        <span className='z-1 ml-auto text-sm'>
          {amount} {translate('feedback.stats.responses')}
        </span>
      </div>
    </div>
  );
};
HorizontalAnswer.propTypes = {
  total: PropTypes.number.isRequired,
  answer: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    choice: PropTypes.string.isRequired,
  }).isRequired,
};

const VerticalAnswers = ({ answers, labels }) => {
  const total = useMemo(() => answers.reduce((acc, curr) => acc + curr, 0), [answers]);

  return (
    <div className='font-semibold'>
      <Divider />

      <div className='flex mb-4 text-gray-dusty space-x-6 lg:space-x-12'>
        {labels.map((label, index) => (
          <TooltipWrapper interactive content={label}>
            <div key={index} className={'w-1/3 block align-center truncate overflow-ellipsis'}>
              {label}
            </div>
          </TooltipWrapper>
        ))}
      </div>

      <div className='overflow-y-auto'>
        <div className='flex space-x-6 lg:space-x-12'>
          {answers.map((answer, index) => {
            return <VerticalAnswer key={index} amount={answer} scale={index + 1} total={total} />;
          })}
        </div>
      </div>
    </div>
  );
};

const VerticalAnswer = ({ amount, scale, total }) => {
  const translate = useTranslate();
  const percentage = useMemo(() => ((amount / total) * 100).toFixed(0), [amount, total]);
  const progressBarStyle = useMemo(() => ({ height: `${percentage}%` }), [percentage]);

  return (
    <div className='grow items-center font-semibold leading-normal'>
      <div className='h-64 flex items-center justify-between flex-col relative text-white bg-fuel-yellow/60 rounded-30 p-4 overflow-hidden'>
        <div
          style={progressBarStyle}
          className='bg-gradient-to-t from-fuel-yellow-60 to-fuel-yellow absolute inset-x-0 bottom-0'
        />

        <div className='text-sm z-1'>
          {amount} {translate('feedback.stats.responses')}
        </div>

        <div className='z-1'>{scale}</div>
      </div>
      <div className='text-center mt-4'>{total > 0 && amount > 0 ? percentage + '%' : ''}</div>
    </div>
  );
};

const SliderAnswer = ({ labels, average }) => {
  const translate = useTranslate();

  return (
    <div>
      <Divider />

      <div className='flex mt-4 items-center font-semibold leading-normal xs:overflow-auto'>
        <div className='w-1/6'>
          <div className='font-normal text-sm text-gray-dusty sm:mb-4'>{translate('feedback.stats.average')}</div>
          <div>{average}%</div>
        </div>

        <div className='grow sm:my-4 xs:ml-6px'>
          <div className='flex justify-between text-gray-dusty mb-2'>
            {labels.map((label, index) => (
              <div className='pr-2' key={index}>
                {label}
              </div>
            ))}
          </div>

          <div className='relative'>
            <div className='bg-silver/60 py-1 rounded-lg relative overflow-hidden'>
              <div
                style={{ width: `${average}%` }}
                className='bg-gradient-to-t from-fuel-yellow-60 to-fuel-yellow absolute left-0 inset-y-0'
              />
            </div>

            <div
              style={{ left: `${average}%` }}
              className='border-2 border-white h-4 w-4 absolute rounded-full top-1/2 transform -translate-y-1/2'
            >
              <div className='rounded-full h-full w-full bg-fuel-yellow' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
