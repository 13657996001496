import classNames from 'classnames';
import { IconProps } from '.';
import './EmptyIcon.scss';
export const EmptyItem = ({ color = '#F66F1E', className }: IconProps) => {
  return (
    <svg
      className={classNames('empty-icon-wrapper', className)}
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        className='circle-empty-icon'
        cx='24'
        cy='24'
        r='21'
        stroke={color}
        strokeWidth='6'
        strokeMiterlimit='3.8637'
        strokeDasharray='3 6'
      />
      <mask
        id='mask0_27117_77642'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='14'
        y='14'
        width='19'
        height='19'
      >
        <circle cx='23.8' cy='23.8' r='8.25' stroke='black' strokeWidth='1.5' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M24.55 19.3H23.05V23.05L19.3 23.05V24.55H23.05V28.3H24.55V24.55H28.3V23.05L24.55 23.05V19.3Z'
          fill='black'
        />
      </mask>
      <g mask='url(#mask0_27117_77642)'>
        <rect className='addition-empty-icon' x='11.8' y='11.8' width='24' height='24' fill={color} />
      </g>
    </svg>
  );
};


