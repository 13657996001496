import { TEST_IDS } from '@va/constants';
import { NegativeTagIcon } from '@va/icons';
import { useLocale } from '@va/localization';
import { AlarmingEvents } from '@va/types/events';
import { Button, VisitorActionsTooltip } from '@va/ui/design-system';
import { addNumberSeparator } from '@va/util/helpers';
import { useIsHovering } from '@va/util/hooks';
import classNames from 'classnames';
import { useMemo, useRef } from 'react';
import { sumObjectValues, transformAlarmingEvents } from '../helpers/visitorActions';

type ABECellProps = {
  alarmingEvents: AlarmingEvents;
};

export function ABECell({ alarmingEvents }: ABECellProps) {
  const { locale } = useLocale();
  const abeBtnRef = useRef<HTMLButtonElement>(null);
  const isHovering = useIsHovering(abeBtnRef);
  const alarmingEventsTotal = useMemo(() => sumObjectValues(alarmingEvents), [alarmingEvents]);
  const mappedAlarmingEvents = useMemo(() => transformAlarmingEvents(alarmingEvents), [alarmingEvents]);

  return (
    <div className='w-full flex items-center justify-center px-3'>
      <Button
        text={addNumberSeparator(alarmingEventsTotal, locale)}
        icon={(className) => <NegativeTagIcon className={className} color={'var(--color-primary)'} />}
        className={classNames('min-w-fit', {
          'text-primary ': !isHovering,
        })}
        onClick={(event) => event.stopPropagation()}
        ref={abeBtnRef}
        color={isHovering ? 'primary' : 'quinary'}
        tooltip={
          alarmingEventsTotal > 0 && (
            <VisitorActionsTooltip alarmingEvents={mappedAlarmingEvents} alarmingEventsTotal={alarmingEventsTotal} />
          )
        }
        tooltipProps={{
          interactive: true,
          tooltipClassNames: '!rounded-3xl md:min-w-[520px]',
        }}
        data-testid={TEST_IDS.generic.buttons.seeMore}
      />
    </div>
  );
}
