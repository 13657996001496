import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export const useInnerOnChange = (
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  debounceTime = 250,
  initialValue?: string | number,
) => {
  const [innerValue, setInnerValue] = useState(initialValue ?? '');

  const deboucedOnChange = useDebouncedCallback((e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  }, debounceTime);

  useEffect(() => {
    if (initialValue !== innerValue) {
      setInnerValue(initialValue ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const innerOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setInnerValue(e.target.value);
      deboucedOnChange(e);
    },
    [deboucedOnChange],
  );

  return { innerValue, innerOnChange };
};
