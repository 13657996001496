import { apiStatus } from '@va/constants';
import { newApiRequest } from '@va/dashboard/reducers';
import * as Types from '@va/standalone/shared/actions';
import Immutable from 'immutable';

export const getWebsites = newApiRequest(
  Types.standalone.Api.GET_WEBSITES_REQUEST,
  Types.standalone.Api.GET_WEBSITES_SUCCEEDED,
  Types.standalone.Api.GET_WEBSITES_FAILED,
);

export const acceptContributorRole = newApiRequest(
  Types.standalone.Api.ACCEPT_CONTRIBUTOR_ROLE_REQUEST,
  Types.standalone.Api.ACCEPT_CONTRIBUTOR_ROLE_SUCCEEDED,
  Types.standalone.Api.ACCEPT_CONTRIBUTOR_ROLE_FAILED,
);

export const rejectContributorRole = newApiRequest(
  Types.standalone.Api.REJECT_CONTRIBUTOR_ROLE_REQUEST,
  Types.standalone.Api.REJECT_CONTRIBUTOR_ROLE_SUCCEEDED,
  Types.standalone.Api.REJECT_CONTRIBUTOR_ROLE_FAILED,
);

export const registerContributor = newApiRequest(
  Types.standalone.Api.REGISTER_CONTRIBUTOR_REQUEST,
  Types.standalone.Api.REGISTER_CONTRIBUTOR_SUCCEEDED,
  Types.standalone.Api.REGISTER_CONTRIBUTOR_FAILED,
);

export const leaveWebsite = newApiRequest(
  Types.standalone.Api.LEAVE_WEBSITE_REQUEST,
  Types.standalone.Api.LEAVE_WEBSITE_SUCCEEDED,
  Types.standalone.Api.LEAVE_WEBSITE_FAILED,
);

export const updateWebsiteLocale = newApiRequest(
  Types.standalone.Api.UPDATE_WEBSITE_LOCALE_REQUEST,
  Types.standalone.Api.UPDATE_WEBSITE_LOCALE_SUCCEEDED,
  Types.standalone.Api.UPDATE_WEBSITE_LOCALE_FAILED,
);

export const changeEmail = newApiRequest(
  Types.standalone.Api.CHANGE_EMAIL_REQUEST,
  Types.standalone.Api.CHANGE_EMAIL_SUCCEEDED,
  Types.standalone.Api.CHANGE_EMAIL_FAILED,
);

export const updateUserInfo = newApiRequest(
  Types.standalone.Api.UPDATE_USER_INFO_REQUEST,
  Types.standalone.Api.UPDATE_USER_INFO_SUCCEEDED,
  Types.standalone.Api.UPDATE_USER_INFO_FAILED,
);

export const changeAgencyStatus = newApiRequest(
  Types.standalone.Api.CHANGE_AGENCY_STATUS_REQUEST,
  Types.standalone.Api.CHANGE_AGENCY_STATUS_SUCCEEDED,
  Types.standalone.Api.CHANGE_AGENCY_STATUS_FAILED,
);

export const changeWebsiteWhiteLabelStatus = newApiRequest(
  Types.standalone.Api.CHANGE_WEBSITE_WHITE_LABEL_STATUS_REQUEST,
  Types.standalone.Api.CHANGE_WEBSITE_WHITE_LABEL_STATUS_SUCCEEDED,
  Types.standalone.Api.CHANGE_WEBSITE_WHITE_LABEL_STATUS_FAILED,
);

export const changeAccountDetail = newApiRequest(
  Types.standalone.Api.CHANGE_ACCOUNT_DETAIL_REQUEST,
  Types.standalone.Api.CHANGE_ACCOUNT_DETAIL_SUCCEEDED,
  Types.standalone.Api.CHANGE_ACCOUNT_DETAIL_FAILED,
);

export const confirmUpdateEmail = newApiRequest(
  Types.standalone.Api.CONFIRM_UPDATE_EMAIL_REQUEST,
  Types.standalone.Api.CONFIRM_UPDATE_EMAIL_SUCCEEDED,
  Types.standalone.Api.CONFIRM_UPDATE_EMAIL_FAILED,
);

export const resetEmail = newApiRequest(
  Types.standalone.Api.RESET_EMAIL_REQUEST,
  Types.standalone.Api.RESET_EMAIL_SUCCEEDED,
  Types.standalone.Api.RESET_EMAIL_FAILED,
);

export const getWebsiteOverview = (state = Immutable.Map(), action) => {
  switch (action.type) {
    case Types.standalone.Api.GET_WEBSITE_OVERVIEW_FAILED:
      return state.setIn([action.error.get('id'), 'status'], apiStatus.FAILED);
    case Types.standalone.Api.INITIATE_WEBSITE_OVERVIEW:
      return state.setIn([action.id, 'status'], apiStatus.IN_PROGRESS);
    case Types.standalone.Api.RESET_WEBSITE_OVERVIEW:
      return Immutable.Map().set('status', apiStatus.NONE);
    default:
      return newApiRequest(
        Types.standalone.Api.GET_WEBSITE_OVERVIEW,
        Types.standalone.Api.GET_WEBSITE_OVERVIEW_SUCCEEDED,
        'notused',
      )(state, action);
  }
};

export const deleteAccount = newApiRequest(
  Types.standalone.Api.DELETE_ACCOUNT_REQUEST,
  Types.standalone.Api.DELETE_ACCOUNT_SUCCEEDED,
  Types.standalone.Api.DELETE_ACCOUNT_FAILED,
);

export const getTrackingCode = newApiRequest(
  Types.standalone.Api.GET_TRACKING_CODE_REQUEST,
  Types.standalone.Api.GET_TRACKING_CODE_SUCCEEDED,
  Types.standalone.Api.GET_TRACKING_CODE_FAILED,
);

export const removeWebsite = newApiRequest(
  Types.standalone.Api.REMOVE_WEBSITE_REQUEST,
  Types.standalone.Api.REMOVE_WEBSITE_SUCCEEDED,
  Types.standalone.Api.REMOVE_WEBSITE_FAILED,
);

export const createUser = newApiRequest(
  Types.standalone.Api.CREATE_USER_REQUEST,
  Types.standalone.Api.CREATE_USER_SUCCEEDED,
  Types.standalone.Api.CREATE_USER_FAILED,
);
export const createAppsumoUser = newApiRequest(
  Types.standalone.Api.CREATE_APPSUMO_USER_REQUEST,
  Types.standalone.Api.CREATE_APPSUMO_USER_SUCCEEDED,
  Types.standalone.Api.CREATE_APPSUMO_USER_FAILED,
);

export const addVoucher = newApiRequest(
  Types.standalone.Api.ADD_VOUCHER,
  Types.standalone.Api.ADD_VOUCHER_SUCCEEDED,
  Types.standalone.Api.ADD_VOUCHER_FAILED,
);

export const getVouchers = newApiRequest(
  Types.standalone.Api.GET_VOUCHERS,
  Types.standalone.Api.GET_VOUCHERS_SUCCEEDED,
  Types.standalone.Api.GET_VOUCHERS_FAILED,
);

export const getVouchersInfo = newApiRequest(
  Types.standalone.Api.GET_VOUCHERS_INFO,
  Types.standalone.Api.GET_VOUCHERS_INFO_SUCCEEDED,
  Types.standalone.Api.GET_VOUCHERS_INFO_FAILED,
);

export const activateUser = newApiRequest(
  Types.standalone.Api.ACTIVATE_USER_REQUEST,
  Types.standalone.Api.ACTIVATE_USER_SUCCEEDED,
  Types.standalone.Api.ACTIVATE_USER_FAILED,
);

export const loginUser = newApiRequest(
  Types.standalone.Api.LOGIN_REQUEST,
  Types.standalone.Api.LOGIN_SUCCEEDED,
  Types.standalone.Api.LOGIN_FAILED,
);

export const checkLogin = newApiRequest(
  Types.standalone.Api.CHECK_LOGIN_REQUEST,
  Types.standalone.Api.CHECK_LOGIN_SUCCEEDED,
  Types.standalone.Api.CHECK_LOGIN_FAILED,
);

export const backgroundLogin = newApiRequest(
  Types.standalone.Api.BACKGROUND_LOGIN_REQUEST,
  Types.standalone.Api.BACKGROUND_LOGIN_SUCCEEDED,
  Types.standalone.Api.BACKGROUND_LOGIN_FAILED,
);

export const forgotPassword = newApiRequest(
  Types.standalone.Api.FORGOT_PASSWORD_REQUEST,
  Types.standalone.Api.FORGOT_PASSWORD_SUCCEEDED,
  Types.standalone.Api.FORGOT_PASSWORD_FAILED,
);

export const resetPassword = newApiRequest(
  Types.standalone.Api.RESET_PASSWORD_REQUEST,
  Types.standalone.Api.RESET_PASSWORD_SUCCEEDED,
  Types.standalone.Api.RESET_PASSWORD_FAILED,
);

export const resendRegisterConfirmation = newApiRequest(
  Types.standalone.Api.RESEND_REGISTRATION_CONFIRMATION_REQUEST,
  Types.standalone.Api.RESEND_REGISTRATION_CONFIRMATION_SUCCEEDED,
  Types.standalone.Api.RESEND_REGISTRATION_CONFIRMATION_FAILED,
);

export const generateImpersonateToken = newApiRequest(
  Types.standalone.Api.GENERATE_IMPERSONATE_TOKEN_REQUEST,
  Types.standalone.Api.GENERATE_IMPERSONATE_TOKEN_SUCCEEDED,
  Types.standalone.Api.GENERATE_IMPERSONATE_TOKEN_FAILED,
);

export const findMigrationsUser = newApiRequest(
  Types.standalone.Api.FIND_MIGRATIONS_USER_REQUEST,
  Types.standalone.Api.FIND_MIGRATIONS_USER_SUCCEEDED,
  Types.standalone.Api.FIND_MIGRATIONS_USER_FAILED,
);

export const createWebsiteForMigration = newApiRequest(
  Types.standalone.Api.CREATE_WEBSITE_FOR_MIGRATION_REQUEST,
  Types.standalone.Api.CREATE_WEBSITE_FOR_MIGRATION_SUCCEEDED,
  Types.standalone.Api.CREATE_WEBSITE_FOR_MIGRATION_FAILED,
);

export const createNewUserForMigration = newApiRequest(
  Types.standalone.Api.CREATE_NEW_USER_FOR_MIGRATION_REQUEST,
  Types.standalone.Api.CREATE_NEW_USER_FOR_MIGRATION_SUCCEEDED,
  Types.standalone.Api.CREATE_NEW_USER_FOR_MIGRATION_FAILED,
);

export const createNewSubscriber = newApiRequest(
  Types.standalone.Api.CREATE_NEW_SUBSCRIBER,
  Types.standalone.Api.CREATE_NEW_SUBSCRIBER_SUCCEEDED,
  Types.standalone.Api.CREATE_NEW_SUBSCRIBER_FAILED,
);

export const applyLifetimeDeal = newApiRequest(
  Types.standalone.Api.APPLY_LIFETIME_DEAL_REQUEST,
  Types.standalone.Api.APPLY_LIFETIME_DEAL_SUCCEEDED,
  Types.standalone.Api.APPLY_LIFETIME_DEAL_FAILED,
);

export const getGoogleImporterAuthUrl = newApiRequest(
  Types.standalone.Api.GET_GOOGLE_IMPORTER_AUTH_URL_REQUEST,
  Types.standalone.Api.GET_GOOGLE_IMPORTER_AUTH_URL_SUCCEEDED,
  Types.standalone.Api.GET_GOOGLE_IMPORTER_AUTH_URL_FAILED,
);

export const getGoogleImporterViews = newApiRequest(
  Types.standalone.Api.GET_GOOGLE_IMPORTER_VIEWS_REQUEST,
  Types.standalone.Api.GET_GOOGLE_IMPORTER_VIEWS_SUCCEEDED,
  Types.standalone.Api.GET_GOOGLE_IMPORTER_VIEWS_FAILED,
);

export const startGoogleImporter = newApiRequest(
  Types.standalone.Api.START_GOOGLE_IMPORTER_REQUEST,
  Types.standalone.Api.START_GOOGLE_IMPORTER_SUCCEEDED,
  Types.standalone.Api.START_GOOGLE_IMPORTER_FAILED,
);

export const getGoogleImporterProgress = newApiRequest(
  Types.standalone.Api.GET_GOOGLE_IMPORTER_PROGRESS_REQUEST,
  Types.standalone.Api.GET_GOOGLE_IMPORTER_PROGRESS_SUCCEEDED,
  Types.standalone.Api.GET_GOOGLE_IMPORTER_PROGRESS_FAILED,
);

export const getAgencyThemes = newApiRequest(
  Types.standalone.Api.GET_AGENCY_THEMES_REQUEST,
  Types.standalone.Api.GET_AGENCY_THEMES_SUCCEEDED,
  Types.standalone.Api.GET_AGENCY_THEMES_FAILED,
);

export const getAgencyUi = newApiRequest(
  Types.standalone.Api.GET_AGENCY_UI_REQUEST,
  Types.standalone.Api.GET_AGENCY_UI_SUCCEEDED,
  Types.standalone.Api.GET_AGENCY_UI_FAILED,
);

export const getAgencyQuaterlyCommission = newApiRequest(
  Types.standalone.Api.GET_AGENCY_QUATERLY_COMMISSION_REQUEST,
  Types.standalone.Api.GET_AGENCY_QUATERLY_COMMISSION_SUCCEEDED,
  Types.standalone.Api.GET_AGENCY_QUATERLY_COMMISSION_FAILED,
);
