import { GlobalTimePeriodFilter } from '@va/dashboard/components';
import { ChartVisualizationComponentProps, LineDiagramOptions, LineStyling } from '@va/types/charts';
import { useReportBlockCtx } from '@va/ui/components/report-block';
import {
  ChartTiles,
  ChartTilesContextProvider,
  LineChart,
  LineChartConfigOptions,
  LineDataViewOptions,
} from '@va/ui/design-system';

import { BaseTimeseriesDataset } from '@va/types/time-series';
import { memo, useCallback, useState } from 'react';
import { useCommonChartViewData } from './shared/useCommonChartViewData';

export const LineDiagramViewComponent = memo((props: ChartVisualizationComponentProps) => {
  const {
    loadingPlaceholdersCount,
    lineDiagramType = LineDiagramOptions.DEFAULT,
    reversePercentageColors,
    nodes,
    datasetKeys,
  } = props;

  const initializeLineStyles = useCallback(() => {
    return datasetKeys.reduce((acc, key) => {
      acc[key] = LineStyling.UNFILLED;
      return acc;
    }, {} as Record<string, LineStyling>);
  }, [datasetKeys]);

  const [lineStyles, setLineStyles] = useState<Record<string, LineStyling>>(initializeLineStyles());

  const getExtraDatasetConfig = useCallback(
    (dataset: BaseTimeseriesDataset<any>) => {
      if (lineDiagramType === LineDiagramOptions.DEFAULT) {
        return getDatasetConfigOptions(lineStyles[dataset.key]);
      }
      return getDatasetConfigOptions(lineDiagramType);
    },
    [lineDiagramType, lineStyles],
  );

  const { chartData, tiles, toggleKpiVisibility, tooltipFn, isLoading, errorComponent } =
    useCommonChartViewData<'line'>({
      ...props,
      getExtraDatasetConfig: getExtraDatasetConfig,
      renderTileContextMenu: (key) => <LineDataViewOptions tileId={key} />,
    });

  const { id } = useReportBlockCtx();

  const handleLineStylesChange = useCallback((kpi: string, value: string) => {
    setLineStyles((prev) => {
      prev[kpi] = value as LineStyling;
      return { ...prev };
    });
  }, []);

  if (errorComponent) return errorComponent;

  return (
    <div className='space-y-3'>
      <ChartTilesContextProvider
        onKpiDataViewChange={handleLineStylesChange}
        options={lineStyles}
        displayContextMenu={lineDiagramType === LineDiagramOptions.DEFAULT}
      >
        <ChartTiles
          reversePercentageColors={reversePercentageColors}
          isLoading={isLoading}
          loadingPlaceholdersCount={loadingPlaceholdersCount}
          tiles={tiles}
          onClick={(id) => {
            toggleKpiVisibility(id);
          }}
        />
      </ChartTilesContextProvider>
      <div className='w-full flex min-h-12 justify-end gap-3'>
        {nodes?.compareTo}
        <GlobalTimePeriodFilter />
      </div>
      <LineChart
        isLoading={isLoading}
        tooltipFn={tooltipFn}
        id={`${id}-line-diagram-canvas`}
        chartData={chartData}
        wrapperStyle={{ height: '400px' }}
        configOptions={getChartConfig(lineDiagramType)}
      />
    </div>
  );
});

const getChartConfig = (chartType: string): LineChartConfigOptions => {
  switch (chartType) {
    case LineDiagramOptions.STACKED:
      return { stacked: true };
    default:
      return {};
  }
};

const getDatasetConfigOptions = (chartType: string) => {
  switch (chartType) {
    case LineDiagramOptions.STACKED:
      return {
        fill: true,
        stack: 'line-stacked',
        borderDash: [0, 0],
      };
    case LineStyling.FILLED:
      return {
        fill: true,
        borderDash: [0, 0],
      };
    case LineDiagramOptions.STEPPED:
      return {
        stepped: true,
      };
    case LineStyling.DASHED:
      return {
        fill: false,
        stepped: false,
        borderDash: [10, 5],
      };
    default:
      return {
        fill: false,
        stepped: false,
        borderDash: [0, 0],
      };
  }
};
