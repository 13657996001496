import { defaultTheme } from '@va/constants';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { getAgencyUiSucceeded } from '@va/standalone/shared/actions';
import { EditThemeModalComponent, ThemeColorsType } from '@va/ui/components/white-label-customisation';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCreateNewTheme, useGetAllAgencyThemes, useUpdateAgencyUi } from '../apiClient';
import { formatThemeColors } from './ThemeSectionContainer';

const defaultColors = {
  primary: '',
  secondary: '',
  tertiary: '',
  positive: defaultTheme.POSITIVE_COLOR,
  neutral: defaultTheme.NEUTRAL_COLOR,
  negative: defaultTheme.NEGATIVE_COLOR,
};

const CreateCustomThemeModal = ({
  closeModal,
  isOpen,
  updateSelectedThemeId,
}: {
  closeModal: () => void;
  isOpen: boolean;
  updateSelectedThemeId: (id: string) => void;
}) => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const { mutate: mutateAllAgencyThemes } = useGetAllAgencyThemes();

  const createTheme = useCreateNewTheme();
  const updateAgencyUi = useUpdateAgencyUi();

  const { showErrorNotification, showSuccessNotification } = useAddNotification();

  const onSave = useCallback(
    (values: ThemeColorsType & { name: string }) => {
      const { name, ...colors } = values;
      const formattedColors = formatThemeColors(colors);
      createTheme.execute({ name, ...formattedColors });
    },
    [createTheme],
  );

  useEffect(() => {
    if (createTheme.error) {
      showErrorNotification();
      createTheme.reset();
    }
  }, [createTheme, showErrorNotification]);

  useEffect(() => {
    if (createTheme.isSucceeded && createTheme.data) {
      updateAgencyUi.execute({ themeId: createTheme.data.id });
      updateSelectedThemeId(createTheme.data.id);
      createTheme.reset();
    }
  }, [createTheme, updateAgencyUi, updateSelectedThemeId]);

  useEffect(() => {
    if (updateAgencyUi.isSucceeded && updateAgencyUi.data) {
      mutateAllAgencyThemes();
      dispatch(getAgencyUiSucceeded({ data: updateAgencyUi.data }));
      closeModal();
      showSuccessNotification();
    }
  }, [
    closeModal,
    dispatch,
    mutateAllAgencyThemes,
    showSuccessNotification,
    updateAgencyUi.data,
    updateAgencyUi.isSucceeded,
  ]);

  return (
    <EditThemeModalComponent
      themeName={'Custom'}
      initialColors={defaultColors}
      title={translate('whiteLabel.settings.theme.createCustom')}
      closeModal={closeModal}
      onSave={onSave}
      isOpen={isOpen}
      isSaveButtonDisabled={createTheme.isLoading || updateAgencyUi.isLoading}
    />
  );
};

export default CreateCustomThemeModal;
