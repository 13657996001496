import { IconProps } from '../misc';

export const LockClosedV2 = ({ className = '', color = '#696969' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.75 4.5V6H11.25V4.5C11.25 3.25736 10.2426 2.25 9 2.25C7.75736 2.25 6.75 3.25736 6.75 4.5ZM4.5 6V6.02015C4.00912 6.04526 3.65782 6.10167 3.35195 6.22836C2.61687 6.53284 2.03284 7.11687 1.72836 7.85195C1.5 8.40326 1.5 9.10217 1.5 10.5V13.5C1.5 14.8978 1.5 15.5967 1.72836 16.1481C2.03284 16.8831 2.61687 17.4672 3.35195 17.7716C3.90326 18 4.60218 18 6 18H12C13.3978 18 14.0967 18 14.6481 17.7716C15.3831 17.4672 15.9672 16.8831 16.2716 16.1481C16.5 15.5967 16.5 14.8978 16.5 13.5V10.5C16.5 9.10217 16.5 8.40326 16.2716 7.85195C15.9672 7.11687 15.3831 6.53284 14.6481 6.22836C14.3422 6.10167 13.9909 6.04526 13.5 6.02015V6V4.5C13.5 2.01472 11.4853 0 9 0C6.51472 0 4.5 2.01472 4.5 4.5V6ZM4.51169 8.27333C4.83571 8.25122 5.2703 8.25 6 8.25H6.75H11.25H12C12.7297 8.25 13.1643 8.25122 13.4883 8.27333C13.6888 8.28701 13.774 8.30531 13.7961 8.31091C13.9726 8.38674 14.1133 8.52743 14.1891 8.70393C14.1947 8.726 14.213 8.8112 14.2267 9.01169C14.2488 9.33571 14.25 9.7703 14.25 10.5V13.5C14.25 14.2297 14.2488 14.6643 14.2267 14.9883C14.213 15.1888 14.1947 15.274 14.1891 15.2961C14.1133 15.4726 13.9726 15.6133 13.7961 15.6891C13.774 15.6947 13.6888 15.713 13.4883 15.7267C13.1643 15.7488 12.7297 15.75 12 15.75H6C5.2703 15.75 4.83571 15.7488 4.51169 15.7267C4.3112 15.713 4.226 15.6947 4.20393 15.6891C4.02743 15.6133 3.88674 15.4726 3.81091 15.2961C3.80531 15.274 3.78701 15.1888 3.77333 14.9883C3.75122 14.6643 3.75 14.2297 3.75 13.5V10.5C3.75 9.7703 3.75122 9.33571 3.77333 9.01169C3.78701 8.8112 3.80531 8.72599 3.81091 8.70393C3.88674 8.52743 4.02743 8.38674 4.20393 8.31091C4.22599 8.30531 4.3112 8.28701 4.51169 8.27333Z'
        fill={color}
      />
    </svg>
  );
};
