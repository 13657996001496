import { useCallback, useEffect, useRef, useState } from 'react';

const ANCHOR_REGEX_HASH = /^#[^ ]+$/;

type useQuickNavigatorType = {
  offset: number;
  elementsId: string[];
  scrollContainerId: string;
};

const useQuickNavigator = ({ offset, elementsId, scrollContainerId }: useQuickNavigatorType) => {
  const [isScrollEventDisable, setIsScrollEventDisabled] = useState(true);
  const [activeElementId, setActiveElementId] = useState(window.location.hash.slice(1) || elementsId[0]);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const scrollContainerRef = useRef(document.getElementById(scrollContainerId)!);

  const onScroll = useCallback(() => {
    const domElements = elementsId.map((id) => document.getElementById(id));
    const elementsRect = domElements.map((element) => element?.getBoundingClientRect());
    const dataIndex = elementsRect?.findIndex((rect) => rect && rect.height + rect.top - offset > 0);
    const activeElementId = elementsId[dataIndex] as string;
    setActiveElementId(activeElementId);
  }, [elementsId, offset]);

  const toggleScrollEvent = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsScrollEventDisabled(true);
    timeoutRef.current = setTimeout(() => {
      setIsScrollEventDisabled(false);
    }, 800);
  }, []);

  const scrollToElement = useCallback(
    (id: string, scrollBehaviour: ScrollBehavior = 'smooth') => {
      const element = document.getElementById(id);
      if (!element) return;
      const topPosition = element.getBoundingClientRect().top;
      toggleScrollEvent();
      scrollContainerRef.current.scrollBy({
        left: 0,
        top: topPosition - offset,
        behavior: scrollBehaviour,
      });
    },
    [toggleScrollEvent, offset],
  );

  useEffect(() => {
    const delegateAnchors = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.tagName.toLowerCase() !== 'a') return;
      const href = target.getAttribute('href') ?? '';
      const isHash = ANCHOR_REGEX_HASH.test(href);
      if (isHash) {
        const id = href.slice(1);
        e.preventDefault();
        scrollToElement(id);
        setActiveElementId(id);
      }
    };

    document.body.addEventListener('click', delegateAnchors);
    return () => {
      document.body.removeEventListener('click', delegateAnchors);
    };
  }, [scrollToElement]);

  // First scroll after the page has loaded
  useEffect(() => {
    const hash = window.location.hash;
    if (!hash) return;
    const id = hash.slice(1);
    scrollToElement(id, 'auto');
  }, [scrollToElement]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (!isScrollEventDisable) {
      scrollContainer.addEventListener('scroll', onScroll);
    }

    return () => {
      scrollContainer.removeEventListener('scroll', onScroll);
    };
  }, [isScrollEventDisable, onScroll]);

  useEffect(() => {
    toggleScrollEvent();
  }, [toggleScrollEvent]);

  useEffect(() => {
    replaceHashInUrlWithoutTriggeringEvent('#' + activeElementId);
  }, [activeElementId]);

  return { activeElementId };
};

export default useQuickNavigator;

function replaceHashInUrlWithoutTriggeringEvent(hash: string) {
  window.history.replaceState(null, '', window.location.pathname + window.location.search + hash);
}
