import { TrackEventProps } from '@va/types/twipla-worker';
import { DashboardAppWorker } from '@va/util/misc';
import { RefObject, useEffect } from 'react';
import { useIsHovering } from './useIsHovering';

export const useTrackEventOnHover = (ref: RefObject<HTMLElement>, event: Omit<TrackEventProps, 'action'>) => {
  const isHovering = useIsHovering(ref);

  useEffect(() => {
    if (!isHovering) return;
    if (isHovering) {
      DashboardAppWorker.trackEvent({
        category: event.category,
        action: 'onmouseover',
        label: event.label,
        value: event.value,
        fieldsObject: event.fieldsObject || '',
      });
    }
  }, [event.category, event.fieldsObject, event.label, event.value, isHovering]);
};
