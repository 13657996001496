import { useTranslate } from '@va/localization';
import { CodeEditor } from '../code-editor/CodeEditor';
import './CodeEditorWithCopyBtn.scss';
import CopyToClipboardBtn from './CopyToClipboardBtn';

type CodeEditorWithCopyBtnType = {
  code: string;
  editorClassName?: string;
};
export const CodeEditorWithCopyBtn = ({ code, editorClassName }: CodeEditorWithCopyBtnType) => {
  const translate = useTranslate();
  return (
    <div className='relative  '>
      <CodeEditor className={editorClassName} value={code} />
      <CopyToClipboardBtn
        btnClass={'w-full justify-center'}
        content={code}
        onCopyText={translate('button.copiedToClipboardText')}
        btnText={translate('button.copySnippetToClipboardText')}
        containerClassName={
          'copy-code-btn-wrapper h-12 bg-primary bottom-3 right-3 z-10 absolute flex rounded-xl w-1/2 xs:w-11/12 m-auto'
        }
      />
    </div>
  );
};
