import { IconProps } from '..';

export const AndroidIcon = ({ className }: IconProps) => {
  return (
    <svg
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M13.1407 8.49019C12.9924 8.49019 12.8475 8.44626 12.7242 8.36395C12.601 8.28163 12.5049 8.16464 12.4482 8.02776C12.3914 7.89088 12.3766 7.74026 12.4055 7.59495C12.4344 7.44963 12.5058 7.31616 12.6106 7.21139C12.7155 7.10663 12.849 7.03528 12.9944 7.00638C13.1399 6.97748 13.2906 6.99231 13.4275 7.04901C13.5645 7.10571 13.6816 7.20172 13.7639 7.32491C13.8463 7.4481 13.8903 7.59293 13.8903 7.74109C13.8903 7.93976 13.8113 8.1303 13.6707 8.27079C13.5302 8.41127 13.3395 8.49019 13.1407 8.49019ZM4.85606 8.49019C4.7078 8.49019 4.56287 8.44626 4.43961 8.36395C4.31634 8.28163 4.22026 8.16464 4.16352 8.02776C4.10679 7.89088 4.09194 7.74026 4.12087 7.59495C4.14979 7.44963 4.22118 7.31616 4.32601 7.21139C4.43085 7.10663 4.56441 7.03528 4.70982 7.00638C4.85522 6.97748 5.00594 6.99231 5.14291 7.04901C5.27988 7.10571 5.39695 7.20172 5.47932 7.32491C5.56169 7.4481 5.60565 7.59293 5.60565 7.74109C5.60565 7.93976 5.52667 8.1303 5.3861 8.27079C5.24552 8.41127 5.05486 8.49019 4.85606 8.49019ZM13.4112 3.98254L14.9104 1.37697C14.9349 1.30968 14.9365 1.23621 14.915 1.16791C14.8935 1.09961 14.8501 1.04029 14.7914 0.999134C14.7328 0.957976 14.6623 0.937269 14.5907 0.940218C14.5191 0.943166 14.4505 0.969605 14.3954 1.01544C14.3842 1.03022 14.3754 1.04672 14.3694 1.0643L12.8539 3.70245C11.6416 3.15986 10.3283 2.87938 9 2.87938C7.6717 2.87938 6.35839 3.15986 5.14612 3.70245L3.62738 1.07733C3.58089 1.02443 3.5185 0.988017 3.44956 0.973534C3.38062 0.959052 3.30884 0.967283 3.24497 0.996994C3.1811 1.02671 3.12859 1.0763 3.0953 1.13834C3.062 1.20038 3.04972 1.27154 3.06029 1.34114C3.06685 1.35915 3.07681 1.37574 3.08962 1.39L4.58555 3.99557C3.29379 4.69929 2.19799 5.71379 1.39733 6.94727C0.596667 8.18075 0.116404 9.59428 0 11.0599H18C17.8851 9.59177 17.4052 8.17549 16.6039 6.93959C15.8025 5.70369 14.7051 4.68728 13.4112 3.98254Z'
        fill='#3DDC84'
      />
    </svg>
  );
};
