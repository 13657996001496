import { apiStatus } from '@va/constants';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useLocale, useTranslate } from '@va/localization';
import { getAgencyUi } from '@va/standalone/shared/actions';
import { getApiRequest, getApiRequestStatus } from '@va/standalone/shared/selectors';
import { CustomSubdomainComponent } from '@va/ui/components/white-label-customisation';
import Config from 'Config';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateAgencyUi, useUpdateAgencyUi } from '../apiClient';

const DEFAULT_DOMAIN = Config.customDomain.slice(1);
const domains = [{ label: DEFAULT_DOMAIN, value: 0 }];
const DUPLICATE_DOMAIN_ERROR = 'AGENCY_UI_CUSTOM_URL_DUPLICATE';

const CustomSubdomainContainer = () => {
  const { showSuccessNotification, showErrorNotification } = useAddNotification();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const { locale } = useLocale();

  const domainSupportUrl = useMemo(() => `https://visitor-analytics.io/${locale}/support`, [locale]);

  const agencyUiStatus = useSelector((state) => getApiRequestStatus(state, 'getAgencyUi'));
  const agencyUi = useSelector((state) => getApiRequest(state, 'getAgencyUi')).get('data');
  const agencyCustomUrl = agencyUi?.get('customUrl');
  const agencyId = agencyUi?.get('id');
  const isCustomDomainLoading = agencyUiStatus === apiStatus.IN_PROGRESS;

  const {
    execute: updateAgencyUi,
    isLoading: isUpdateLoading,
    isSucceeded: isCustomDomainUpdateSucceeded,
    error: updateAgencyUiError,
  } = useUpdateAgencyUi();

  const {
    execute: createAgencyUi,
    isLoading: isCreateLoading,
    isSucceeded: isCreateAgencySucceeded,
    error: createAgencyError,
  } = useCreateAgencyUi();

  useEffect(() => {
    if (updateAgencyUiError) return;
    if (isCustomDomainUpdateSucceeded) {
      dispatch(getAgencyUi());
      showSuccessNotification(2000);
    }
  }, [showSuccessNotification, dispatch, isCustomDomainUpdateSucceeded, updateAgencyUiError]);

  useEffect(() => {
    if (createAgencyError) return;
    if (isCreateAgencySucceeded) {
      dispatch(getAgencyUi());
      showSuccessNotification(2000);
    }
  }, [createAgencyError, dispatch, isCreateAgencySucceeded, showSuccessNotification]);

  useEffect(() => {
    if (!updateAgencyUiError && !createAgencyError) return;

    if (
      updateAgencyUiError?.message === DUPLICATE_DOMAIN_ERROR ||
      createAgencyError?.message === DUPLICATE_DOMAIN_ERROR
    ) {
      showErrorNotification(2000, translate('whiteLabel.customDomain.errors.alreadyTaken'));
      return;
    }
    showErrorNotification();
  }, [showErrorNotification, updateAgencyUiError, dispatch, createAgencyError, translate]);

  const onSaveDomain = useCallback(
    (domainUrl: string) => {
      if (agencyId) {
        updateAgencyUi({ customUrl: domainUrl });
      } else {
        createAgencyUi({ customUrl: domainUrl });
      }
    },
    [agencyId, updateAgencyUi, createAgencyUi],
  );

  return (
    <CustomSubdomainComponent
      customUrl={agencyCustomUrl}
      onSubmit={onSaveDomain}
      saveButtonProps={{
        disabled: isUpdateLoading || isCreateLoading || isCustomDomainLoading,
      }}
      domains={domains}
      supportCenterUrl={domainSupportUrl}
      isCustomDomainLoading={isCustomDomainLoading}
    />
  );
};

export default CustomSubdomainContainer;
