import { tabNames } from '@va/constants';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useFiltersContext } from '@va/shared/feature-filters';
import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import {
  OrderType,
  useChangeWhiteLabelStatus,
  useWebsites,
  WebsitesResponseType,
  WebsiteType,
} from './agency-white-label-apiClient';

type WebsiteFilterQueryType = {
  start: number;
  length: number;
  withContributors?: boolean;
  withSubscription: boolean;
  order: OrderType;
  url?: string;
  whiteLabel?: boolean | null;
  withSharedWebsites?: boolean;
  withCommission?: boolean;
};

type AgencyWhiteLabelContextType = {
  isLoading: boolean;
  data: WebsitesResponseType | undefined;
  websiteFilterQuery: WebsiteFilterQueryType;
  setWebsiteFilterQuery: React.Dispatch<React.SetStateAction<WebsiteFilterQueryType>>;
  websites: WebsiteType[];
  totalWebsitesCount: number;
  totalWhiteLabeled: number;
  getWebsites: () => void;
  changeWebsiteWhiteLabelStatusLoading: boolean;
  changeWebsiteWhiteLabelStatusSuccess: boolean;
  changeWebsiteWhiteLabelStatusFailed: any;
  changeWebsiteWhiteLabelStatus: (websiteId: string, status: boolean) => void;
  offset: number;
  setOffset: (value: number) => void;
};

const AgencyWhiteLabelContext = createContext({} as AgencyWhiteLabelContextType);

export const AgencyWhiteLabelContextProvider: React.FC<PropsWithChildren<{ tab: string }>> = ({ children, tab }) => {
  const defaultWebsiteQuery = useMemo(
    () =>
      Object.assign(
        {
          start: 0,
          length: 12,
          order: OrderType.ASC,
          withSubscription: true,
        },
        getWebsitesTabQuery(tab),
      ),
    [tab],
  );

  const [websiteFilterQuery, setWebsiteFilterQuery] = useState<WebsiteFilterQueryType>(defaultWebsiteQuery);
  const { showSuccessNotification, showErrorNotification } = useAddNotification();
  const [offset, setOffset] = useState(0);
  const { data, isLoading, mutate: getWebsites } = useWebsites(websiteFilterQuery);
  const {
    isLoading: changeWebsiteWhiteLabelStatusLoading,
    isSucceeded: changeWebsiteWhiteLabelStatusSuccess,
    error: changeWebsiteWhiteLabelStatusFailed,
    execute: changeWebsiteWhiteLabelStatus,
  } = useChangeWhiteLabelStatus();
  const { total: totalWebsitesCount = 0, totalWhiteLabeled = 0, websites = [] } = data || {};

  const { appliedFilterValues } = useFiltersContext<{ url?: string; whiteLabel?: boolean }>();

  useEffect(() => {
    const url = appliedFilterValues['url'];
    const whiteLabel = appliedFilterValues['whiteLabel'];
    setWebsiteFilterQuery((prev) => ({ ...prev, start: 0, offset: 0, url, whiteLabel }));
  }, [appliedFilterValues]);

  useEffect(() => {
    if (changeWebsiteWhiteLabelStatusSuccess) {
      getWebsites();
      showSuccessNotification();
    }
    if (changeWebsiteWhiteLabelStatusFailed) {
      showErrorNotification();
    }
  }, [
    changeWebsiteWhiteLabelStatusSuccess,
    changeWebsiteWhiteLabelStatusFailed,
    getWebsites,
    showSuccessNotification,
    showErrorNotification,
  ]);

  return (
    <AgencyWhiteLabelContext.Provider
      value={{
        isLoading,
        data,
        websites,
        websiteFilterQuery,
        setWebsiteFilterQuery,
        totalWebsitesCount,
        getWebsites,
        changeWebsiteWhiteLabelStatusLoading,
        changeWebsiteWhiteLabelStatusSuccess,
        changeWebsiteWhiteLabelStatusFailed,
        changeWebsiteWhiteLabelStatus,
        totalWhiteLabeled,
        offset,
        setOffset,
      }}
    >
      {children}
    </AgencyWhiteLabelContext.Provider>
  );
};

function getWebsitesTabQuery(tabName: string) {
  if (tabName === tabNames.agencyCommission) {
    return { withCommission: true };
  }
  if (tabName === tabNames.agencyWhiteLabel) {
    return { withContributors: true, withSharedWebsites: true, withContributorRequests: true };
  }
}

const useAgencyWhiteLabelContext = () => useContext(AgencyWhiteLabelContext);
export default useAgencyWhiteLabelContext;
