import { IconProps } from '.';

export const ShareIcon = ({ color = '#999999', className }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.4506 3.5492C13.2715 2.37007 11.3598 2.37007 10.1806 3.5492L9.23327 4.49657L7.81905 3.08236L8.76642 2.13499C10.7266 0.174811 13.9047 0.174814 15.8648 2.13499C17.825 4.09516 17.825 7.27324 15.8648 9.23341L13.0227 12.0755C11.5858 13.5125 9.25601 13.5125 7.81905 12.0755L5.92432 10.1808L7.33853 8.76657L9.23327 10.6613C9.88918 11.3172 10.9526 11.3172 11.6085 10.6613L14.4506 7.8192C15.6298 6.64007 15.6298 4.72833 14.4506 3.5492Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.54912 14.4508C4.72825 15.6299 6.63999 15.6299 7.81912 14.4508L8.76649 13.5034L10.1807 14.9176L9.23333 15.865C7.27316 17.8252 4.09509 17.8252 2.13491 15.865C0.174736 13.9048 0.174734 10.7267 2.13491 8.76656L4.97702 5.92445C6.41398 4.48749 8.74375 4.48749 10.1807 5.92445L12.0754 7.81919L10.6612 9.2334L8.76649 7.33867C8.11058 6.68276 7.04714 6.68275 6.39123 7.33866L3.54912 10.1808C2.37 11.3599 2.37 13.2716 3.54912 14.4508Z'
        fill={color}
      />
    </svg>
  );
};
