import * as Icons from '@va/icons';
import { capitalizeFirstLetter } from '@va/util/helpers';
import classNames from 'classnames';
import { filter } from 'lodash';
import { SidebarItemProps } from './propDefinitions';

export function getIconByName(rawName: string, active: boolean, disabled?: boolean) {
  const name = capitalizeFirstLetter(rawName);
  const Component = (Icons as any)['Nav' + name];
  if (!Component) {
    console.error(`Icon not found for ${name}`);
    return;
  }

  const color = () => {
    if (disabled) return '#999';
    if (!active) return 'var(--color-primary)';
    return false;
  };

  return (
    <span
      id={`key-${rawName}`}
      className={classNames(
        {
          'bg-primary': active,
          'bg-white-snow group-hover:bg-white': !active,
        },
        'flex shrink-0 items-center justify-center p-9px rounded-full z-[1] h-42px w-42px',
      )}
    >
      <Component isActive={active} color={color()} />
    </span>
  );
}

export const getFilteredList = (
  sidebarItems: SidebarItemProps[],
  searchStr: string,
  translate: (string: string) => string,
) => {
  if (searchStr.length === 0) {
    return sidebarItems;
  }
  const getFilteredChild = (child: SidebarItemProps) =>
    child.submenu?.filter((item) => translate(`tab.${item.name}`).toLowerCase().includes(searchStr.toLowerCase()));

  const filteredList: SidebarItemProps[] = [];
  sidebarItems.forEach((item) => {
    if (item.submenu) {
      const filteredChildList = getFilteredChild(item) || [];
      if (filteredChildList.length > 0) {
        filteredList.push({
          ...item,
          submenu: filteredChildList,
        });
        return;
      }
    }
    if (translate(`tab.${item.name}`).toLowerCase().includes(searchStr)) {
      filteredList.push(item);
    }
    return false;
  });
  return filteredList;
};

export const getParent = (sidebarItems: SidebarItemProps[], key: string = '') => {
  const parent: SidebarItemProps[] = filter(sidebarItems, {
    submenu: [{ name: key }],
  });
  return (parent.length > 0 && parent[0]) || null;
};

export const getTopLevelParent = (sidebarItems: SidebarItemProps[], key: string = '') => {
  const submenu: any[] = filter(sidebarItems, { submenu: [{ children: [] }] });
  const child: SidebarItemProps[] | false =
    submenu.length > 0 && filter(submenu[0]?.submenu, { children: [{ name: key }] });
  return (!!child && child.length > 0 && child[0].name) || null;
};
