import { TEST_IDS } from '@va/constants';
import { useCheckPrivacySetting, usePageVisitsInfo } from '@va/dashboard/util-hooks';
import { LayersIcon } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { PRIVACY_FEATURES } from '@va/standalone/shared/types';
import { Button, VisitedPagesTooltip } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { addNumberSeparator } from '@va/util/helpers';
import classNames from 'classnames';
import { useState } from 'react';
import { useHistory } from 'react-router';

export const PageVisitsInSessionCell = ({
  sessionId,
  pageVisitsCount,
  countryCode,
}: {
  sessionId: string;
  pageVisitsCount: number;
  countryCode: string;
}) => {
  const { locale } = useLocale();
  const translate = useTranslate();
  const history = useHistory();

  const [shoudlFetchData, setShouldFetchData] = useState(false);

  const { isTrackingEnabled } = useCheckPrivacySetting();
  const isPageHistoryTrackingEnabled = isTrackingEnabled(PRIVACY_FEATURES.individualPageHistory, countryCode);
  const { mappedData, isLoading } = usePageVisitsInfo(sessionId, shoudlFetchData);

  return (
    <TooltipWrapper
      interactive
      useDefaultStyle={false}
      tooltipClassNames={classNames({
        'w-screen max-w-[500px] lg:w-auto': isPageHistoryTrackingEnabled,
      })}
      content={
        isPageHistoryTrackingEnabled ? (
          <VisitedPagesTooltip
            isPageHistoryTrackingEnabled={isPageHistoryTrackingEnabled}
            isLoading={isLoading}
            title={translate('card.latestVisitors.visitorsHistory.title')}
            items={mappedData}
          />
        ) : (
          <div className={'text-left bg-black-lighter-84 rounded-12 text-white p-3 text-sm max-w-300'}>
            {translate('panels.latestVisitors.consentless.hiddenPagesTooltip')}
          </div>
        )
      }
    >
      <div onMouseEnter={() => isPageHistoryTrackingEnabled && !isLoading && setShouldFetchData(true)}>
        <Button
          data-testid={TEST_IDS.generic.links.visitedPagesHistory}
          className='justify-center'
          color='secondary'
          text={addNumberSeparator(pageVisitsCount, locale)}
          icon={(_, color) => <LayersIcon color={color} />}
          onClick={() => {
            if (!isPageHistoryTrackingEnabled) return;
            history.push(`/visitors/history/${sessionId}`);
          }}
        />
      </div>
    </TooltipWrapper>
  );
};
