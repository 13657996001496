import { Button, ButtonProps, InputMessage, TextInput, TextInputProps } from '@va/ui/design-system';
import React from 'react';

type ActionInputProps = {
  info?: string;
  textInputProps: TextInputProps;
  buttonProps: ButtonProps;
};

export const ActionInput: React.FC<ActionInputProps> = ({ info, buttonProps, textInputProps }) => {
  return (
    <div>
      <div className='rounded-18 bg-white-snow flex items-center gap-3 p-1.5'>
        <TextInput bgColor='#F0F0F0' wrapperClassName='flex-grow' showErrorMessage={false} {...textInputProps} />
        {buttonProps.onClick && (
          <Button
            {...buttonProps}
            disabled={!textInputProps.value || !!textInputProps.error || buttonProps.disabled}
            className='min-w-150 justify-center'
          />
        )}
      </div>
      {textInputProps.error && <InputMessage error={textInputProps.error} />}
      {info && <InputMessage className='text-gray-dusty ml-5' info={info} />}
    </div>
  );
};
