import { IconProps } from '.';

export const FallbackIconRectangle = ({ color = 'black', className }: IconProps) => {
  return (
    <svg
      width='24'
      height='18'
      viewBox='0 0 24 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <rect x='0.75' y='0.75' width='22.5' height='16.5' stroke={color} strokeWidth='1.5' strokeDasharray='3 1.5' />
    </svg>
  );
};

export const FallbackIconCircle = ({ color = 'black', className }: IconProps) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <circle cx='9' cy='9' r='8.25' stroke={color} strokeWidth='1.5' strokeDasharray='3 2.25' />
    </svg>
  );
};
