import { all } from 'redux-saga/effects';
import * as ConversionTypes from './conversionTypes';
import * as DynamicPages from './dynamicPages';
import * as External from './external';
import * as Modal from './onetime-modals';
import * as SessionReplays from './sessionReplays';
import * as Settings from './settings';
import * as Support from './support';
import * as Visitors from './visitors';
import * as Website from './website';

export function* watchers() {
  yield all([
    Website.watchers(),
    Visitors.watchers(),
    Settings.watchers(),
    Support.watchers(),
    Modal.watchers(),
    SessionReplays.watchers(),
    DynamicPages.watchers(),
    ConversionTypes.watchers(),
    External.watchers(),
  ]);
}
