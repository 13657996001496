import { fontWeights, Paragraph } from '@va/ui/design-system';
import { MouseEventHandler } from 'react';

type UserMenuButtonProps = {
  leftIcon: React.ReactNode;
  text: React.ReactNode;
  onClick?: MouseEventHandler;
  rightIcon?: React.ReactNode;
};

export function UserMenuButton({ leftIcon, text, onClick, rightIcon }: UserMenuButtonProps) {
  return (
    <button
      className='w-full flex flex-row items-center justify-between h-12 px-3 bg-white hover:bg-white-snow rounded-12'
      onClick={onClick}
    >
      <div className='flex flex-row items-center'>
        <div className='h-6 w-6 text-center flex items-center'>{leftIcon}</div>
        {typeof text === 'string' ? (
          <Paragraph className='pl-3' weight={fontWeights.medium}>
            {text}
          </Paragraph>
        ) : (
          text
        )}
      </div>
      {rightIcon}
    </button>
  );
}
