import { IconProps } from '.';

export const StopIcon = ({ color = 'black', className }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.7465 15.0572C12.4536 16.1152 10.801 16.75 9 16.75C4.85786 16.75 1.5 13.3921 1.5 9.25C1.5 7.44905 2.13477 5.79635 3.19279 4.50345L13.7465 15.0572ZM14.8072 13.9965L4.25345 3.44279C5.54635 2.38477 7.19905 1.75 9 1.75C13.1421 1.75 16.5 5.10786 16.5 9.25C16.5 11.051 15.8652 12.7036 14.8072 13.9965ZM18 9.25C18 14.2206 13.9706 18.25 9 18.25C4.02944 18.25 0 14.2206 0 9.25C0 4.27944 4.02944 0.25 9 0.25C13.9706 0.25 18 4.27944 18 9.25Z'
        fill={color}
      />
    </svg>
  );
};
