import { useLocalizationContext } from '@va/localization';
import { ChartContext } from '@va/types/charts';
import { BaseTimeseriesResponse } from '@va/types/time-series';
import { externalChartTooltip } from '@va/ui/design-system';
import { chartHelpers, formatLineDiagramDateLabels } from '@va/util/helpers';
import { ChartData } from 'chart.js';
import { useCallback, useMemo, useState } from 'react';
import { useAlarmingBehaviorEventsContext } from '../../../context/ABEContext';
import { metricsDatasetLabel } from '../../../data/metrics';
import { valueMapperBasedOnMetric } from '../../../helpers/donutChartHelper';

export const useABELineChart = ({ titleKey, subtitleKey }: { titleKey: string; subtitleKey: string }) => {
  const { translate, locale } = useLocalizationContext();
  const { selectedAlarmingBehaviorEvents, selectedMetric, groupingKey } = useAlarmingBehaviorEventsContext();
  const [eventsData, setEventsData] = useState<
    Record<string, { data: BaseTimeseriesResponse<string> | undefined; isLoading: boolean }>
  >({});

  const isLoading = useMemo(
    () => selectedAlarmingBehaviorEvents.some((event) => !eventsData[event[groupingKey]]),
    [eventsData, groupingKey, selectedAlarmingBehaviorEvents],
  );
  const firstEventData = useMemo(
    () =>
      selectedAlarmingBehaviorEvents.length > 0
        ? eventsData[selectedAlarmingBehaviorEvents[0][groupingKey]]
        : undefined,
    [eventsData, groupingKey, selectedAlarmingBehaviorEvents],
  );

  const chartData = useMemo(() => {
    const labels = firstEventData?.data?.chartLabels
      ? formatLineDiagramDateLabels(firstEventData?.data?.chartLabels, locale)
      : [];
    const datasets = selectedAlarmingBehaviorEvents
      .map((event) => {
        const dataset = eventsData[event[groupingKey]]?.data?.items[metricsDatasetLabel[selectedMetric]];

        return (
          dataset &&
          chartHelpers.buildChartDataset({
            dataset,
            hiddenDatasets: [],
            isCurrent: true,
            isPreviousEnabled: false,
            getDatasetColor: () => event.color,
            getDatasetLabel: (dataset) => dataset.label,
          })
        );
      })
      .filter(Boolean) as ChartData<'line'>['datasets'];

    return { datasets, labels };
  }, [
    eventsData,
    firstEventData?.data?.chartLabels,
    groupingKey,
    locale,
    selectedAlarmingBehaviorEvents,
    selectedMetric,
  ]);

  const tooltipFn = useCallback(
    (ctx: ChartContext) => {
      return externalChartTooltip(ctx, {
        currentTimeIntervals: firstEventData?.data?.timeIntervals ?? [],
        previousTimeIntervals: [],
        showPrevious: false,
        title: translate(titleKey),
        subtitle: translate(subtitleKey),
        valueTransformer: (val) => valueMapperBasedOnMetric(val, selectedMetric, locale),
      });
    },
    [firstEventData?.data?.timeIntervals, locale, selectedMetric, subtitleKey, titleKey, translate],
  );

  const handleDataChange = useCallback(
    (data: BaseTimeseriesResponse<string> | undefined, isLoading: boolean, selectedEvent: string) => {
      setEventsData((prev) => ({
        ...prev,
        [selectedEvent]: { data, isLoading },
      }));
    },
    [],
  );

  return {
    handleDataChange,
    chartData,
    tooltipFn,
    isLoading,
  };
};
