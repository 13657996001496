import { DEFAULT_REPORT_BLOCK_IDS } from '@va/constants';
import { useRecordingsColumnsV8, useRecordingsContext } from '@va/dashboard/shared/recordings';
import { RecordingType } from '@va/types/recordings';
import { DataTableV8 } from '@va/ui/components/data-table';
import { getReportBlockPaginationId } from '@va/ui/components/report-block';
import { useRefreshContext } from '@va/util/components';
import { memo, ReactNode } from 'react';

type RecordingsTableProps = {
  variant?: 'default' | 'wix-mini';
  noDataMessage?: (() => ReactNode) | ReactNode;
};

const RecordingsTable = memo(({ variant, noDataMessage }: RecordingsTableProps) => {
  const { recordings, isLoading, tableState, totalCount } = useRecordingsContext();
  const { isManualRefreshing } = useRefreshContext();
  const isInProgress = isLoading || isManualRefreshing;

  const columns = useRecordingsColumnsV8(variant);

  return (
    <DataTableV8<RecordingType>
      id={'recordings-table-v2'}
      paginationContainerId={getReportBlockPaginationId(DEFAULT_REPORT_BLOCK_IDS.recordingsTable)}
      isLoading={isInProgress}
      data={isInProgress ? [] : recordings}
      columns={columns}
      state={{
        pagination: tableState.pagination,
        rowSelection: tableState.rowSelection,
        sorting: tableState.sorting,
      }}
      rowCount={totalCount}
      minWidth={1200}
      enableMultiRowSelection={false}
      onRowSelectionChange={tableState.setRowSelection}
      onPaginationChange={tableState.setPagination}
      onSortingChange={tableState.setSorting}
      size={variant === 'wix-mini' ? 'small' : 'large'}
      noDataMessage={noDataMessage}
      displayBottomPagination={variant === 'wix-mini'}
    />
  );
});

export default RecordingsTable;
