export function makeAction<T extends any[]>(type: string, ...argNames: (string | undefined)[]) {
  return function (...args: T) {
    const action: { type: string; [key: string]: T[number] } = { type };
    argNames.forEach((arg, index) => {
      if (!arg) return;
      action[arg] = args[index];
    });
    return action;
  };
}
