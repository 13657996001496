import classNames from 'classnames';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import PropTypes from 'prop-types';

const TextInput = (props) => {
  const { translate, label, additionalStyle, error, required } = props;
  return (
    <div className={classNames('flex flex-col', additionalStyle)}>
      <div className={classNames('text-input rounded-12 border-solid border-2 border-gray-gallery mt-6px')}>
        <span className='text-input-label text-gray-devil text-xs leading-5 font-medium font-inter'>
          {label} {required && <span className='text-red-negative'>*</span>}
        </span>
        {props.children}
      </div>
      {error && <div className='mt-2 text-sm text-red-negative ml-2'>{translate(error)}</div>}
    </div>
  );
};

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  additionalStyle: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  //withTranslate
  translate: PropTypes.func,
};

export default withTranslate(TextInput);
