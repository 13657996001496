import classNames from 'classnames';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/xml/xml.js';
import 'codemirror/theme/neo.css';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import './CodeEditor.scss';

const DEFAULT_CSS_OPTIONS = {
  theme: 'neo',
  readOnly: true,
  mode: 'xml',
  lineNumbers: true,
  styleActiveLine: true,
  lineWrapping: true,
};

type CodeEditorType = {
  className?: string;
  value: string;
};

export const CodeEditor = ({ value, className }: CodeEditorType) => {
  return (
    <CodeMirror
      className={classNames('bg-white-snow rounded-2xl', className)}
      value={`${value}`}
      options={DEFAULT_CSS_OPTIONS}
    />
  );
};
