import { defaultReactSelectStyles, pageType } from '@va/constants';
import { getPagesListSearchable } from '@va/dashboard/api-client/Page';
import { getDynamicPagesList, getInstanceId } from '@va/dashboard/selectors/app';
import { getWebsite } from '@va/dashboard/selectors/core';
import { useTranslate } from '@va/localization';
import { DynamicPageIcon, StaticPageIcon } from '@va/svg-visa-icons';
import { decodeMalformedURIComponent, getUrlWithTrailingSlash } from '@va/util/helpers';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AsyncPaginate } from 'react-select-async-paginate';

import './PageSelection.scss';

const PAGE_ITEMS_BATCH_COUNT = 100;

const getPageIconByType = (type) => {
  switch (type) {
    case pageType.STATIC:
      return StaticPageIcon;
    case pageType.DYNAMIC:
      return DynamicPageIcon;
    default:
      return StaticPageIcon;
  }
};

const Option = (props) => {
  const { data, selectOption, isSelected, selectProps } = props;
  const { value, label, type, isDisabled } = data;
  const { withPageTypeIcon } = selectProps;
  const Icon = getPageIconByType(type);

  return (
    <div
      className={classNames('flex align-center py-1 px-2 cursor-pointer hover:bg-green-aquaSpring', {
        'bg-green-cruise': isSelected,
        'cursor-pointer hover:bg-green-aquaSpring': !isDisabled,
        'cursor-disabled text-gray-disabled': isDisabled,
      })}
      onClick={() => !isDisabled && selectOption(data)}
    >
      {withPageTypeIcon && (
        <div className='pr-2'>
          <Icon className='page-icon my-auto h-full w-8' />
        </div>
      )}
      <div className='text-left'>
        <div className='font-medium text-14'>{label}</div>
        <div className='font-light text-12'>{value}</div>
      </div>
    </div>
  );
};

/** @deprecated */
export const PageSelection = ({
  name,
  selectedPage,
  onChange,
  onSelectOpen,
  placeholder,
  clearable,
  withPageTypeIcon,
  maxMenuHeight,
  pageOptionsData,
  onlyDynamicPages,
  includeDynamicPages,
  showDefaultHomePage,
  excludedPageKeys,
  isDisabled,
  styles,
  customComponents,
}) => {
  const selectedValue = selectedPage && {
    value: selectedPage.url,
    label: selectedPage.url,
  };

  let hideDropdownIndicator = {};
  if (selectedValue !== null && selectedValue !== undefined) {
    hideDropdownIndicator = { DropdownIndicator: () => null };
  }

  const websiteId = useSelector(getInstanceId);
  const website = useSelector(getWebsite);
  const translate = useTranslate();
  const dynamicPages = useSelector(getDynamicPagesList);

  async function loadOptions(search, loadedOptions) {
    if (pageOptionsData) {
      return {
        options: pageOptionsData,
        hasMore: false,
      };
    }
    if (onlyDynamicPages) {
      return {
        options: dynamicPages,
        hasMore: false,
      };
    }
    const optionsLength =
      includeDynamicPages && !search.length ? loadedOptions.length - dynamicPages.length : loadedOptions.length;
    const response = await getPagesListSearchable(websiteId, {
      search: { general: search },
      start: Math.max(optionsLength, 0),
      length: PAGE_ITEMS_BATCH_COUNT,
    }).then((r) => {
      return r;
    });

    if (optionsLength === 0 && response.pageItems.length === 0 && showDefaultHomePage) {
      const websiteBaseUrl = getUrlWithTrailingSlash(website.url);
      const defaultHomeStaticPage = {
        value: websiteBaseUrl,
        label: 'Home',
        key: 'homePageKey',
        type: pageType.STATIC,
      };
      return {
        options: [defaultHomeStaticPage],
        hasMore: false,
      };
    }

    return {
      options: response.pageItems.map((page) => ({
        label: page.title,
        value: decodeMalformedURIComponent(page.url),
        key: page.key,
        type: pageType.STATIC,
        isDisabled: excludedPageKeys && excludedPageKeys.includes(page.key),
      })),
      hasMore: response.pageTotal > optionsLength,
    };
  }

  let dynamicPagesList = dynamicPages;
  if (excludedPageKeys) {
    dynamicPagesList = dynamicPages.map((page) => {
      return {
        ...page,
        isDisabled: excludedPageKeys.includes(page.key),
      };
    });
  }

  return (
    <div className='common-page-selector dropdown'>
      <AsyncPaginate
        loadOptions={loadOptions}
        debounceTimeout={400}
        onOpen={onSelectOpen}
        components={customComponents || { Option, ...hideDropdownIndicator }}
        name={name || 'page-select'}
        options={includeDynamicPages ? dynamicPagesList : null}
        value={selectedValue}
        isDisabled={isDisabled}
        styles={styles || defaultReactSelectStyles}
        placeholder={placeholder || translate('labels.dropdownList.selectPage')}
        clearable={clearable}
        onChange={(option) => {
          if (option) {
            onChange({
              url: option.value,
              title: option.label,
              key: option.key,
              type: option.type,
            });
          }
        }}
        withPageTypeIcon={withPageTypeIcon}
        maxMenuHeight={maxMenuHeight}
        autoBlur
      />
    </div>
  );
};

PageSelection.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSelectOpen: PropTypes.func,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  selectedPage: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    key: PropTypes.string,
    type: PropTypes.oneOf(Object.values(pageType)),
  }),
  pageOptionsData: PropTypes.arrayOf({
    url: PropTypes.string,
    title: PropTypes.string,
    key: PropTypes.string,
    type: PropTypes.oneOf(Object.values(pageType)),
  }),
  withPageTypeIcon: PropTypes.bool,
  isDisabled: PropTypes.bool,
  excludedPageKeys: PropTypes.array,
  showDefaultHomePage: PropTypes.bool,
  includeDynamicPages: PropTypes.bool, // Do not use it along with onlyDynamicPages
  onlyDynamicPages: PropTypes.bool, // Do not use it along with includeDynamicPages
  styles: PropTypes.object,
  customComponents: PropTypes.object,
};

PageSelection.defaultProps = {
  withPageTypeIcon: true,
  clearable: false,
  maxMenuHeight: 300,
};

export default PageSelection;
