import { all, put, takeLatest } from 'redux-saga/effects';

import { markModalAsViewedFailed, MARK_MODAL_AS_VIEWED_REQUEST, updateWebsiteFlags } from '@va/dashboard/actions/api';

export function* watchers() {
  yield all([takeLatest(MARK_MODAL_AS_VIEWED_REQUEST, markModalAsViewed)]);
}

export function* markModalAsViewed(action) {
  try {
    yield put(
      updateWebsiteFlags({
        [action.modalFlagName]: action.modalFlagValue || true,
      }),
    );
  } catch (error) {
    yield put(markModalAsViewedFailed(error));
  }
}
