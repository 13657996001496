class RequestCanceler {
  private static instance?: RequestCanceler = undefined;
  private entries = {} as Record<string, AbortController>;

  public static getInstance(): RequestCanceler {
    if (!this.instance) {
      this.instance = new RequestCanceler();
    }

    return this.instance;
  }

  onRequestStart(requestId: string): AbortSignal {
    const controller = this.entries[requestId];
    if (controller) {
      controller.abort();
    }

    const ctrl = new AbortController();

    this.entries[requestId] = ctrl;

    return ctrl.signal;
  }

  onRequestEnd(requestId: string) {
    delete this.entries[requestId];
  }

  cancelReq(requestId: string) {
    const controller = this.entries[requestId];
    if (!controller) return;

    controller.abort();
  }

  generateId() {
    return String(Math.random());
  }
}

export default RequestCanceler.getInstance();
