import { supportLinks } from '@va/constants';
import { onContactSupport } from '@va/dashboard/util-helpers/misc';
import { CustomerSupport, UserIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button } from '@va/ui/design-system';

export const SupportButtons = ({ contactButtonLink }: { contactButtonLink?: string }) => {
  const translate = useTranslate();
  return (
    <div className='flex flex-col sm-initial:flex-row gap-3 mt-18px'>
      <a href={supportLinks.home} target={'_blank'} rel='noreferrer' className='flex-1'>
        <Button
          className='w-full justify-center'
          text={translate('button.supportCenter')}
          color='tertiary'
          icon={(_, color) => <CustomerSupport color={color} />}
        />
      </a>
      <Button
        onClick={onContactSupport}
        className='flex-1 w-full justify-center'
        text={translate('button.contactSupport')}
        color='tertiary'
        icon={(_, color) => <UserIcon color={color} />}
      />
    </div>
  );
};
