import { SearchIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { AnnouncementContentType } from '@va/types/announcements';
import { Button } from '@va/ui/design-system';
import { useTrackEventOnHover } from '@va/util/hooks';
import { DashboardAppWorker } from '@va/util/misc';
import { Link } from '@va/util/router';
import { FC, PropsWithChildren, useMemo, useRef } from 'react';

export const FooterButtons = ({
  content,
  isChecked,
  onClose,
  modalId,
}: {
  content: AnnouncementContentType;
  isChecked: boolean;
  onClose: () => void;
  modalId: string;
}) => {
  const translate = useTranslate();
  const confirmationNeeded = content.confirmation_needed;
  const cancelButtonRef = useRef<HTMLDivElement>(null);
  const confirmButtonRef = useRef<HTMLDivElement>(null);

  const target = useMemo(() => {
    if (content.target_url_internal) {
      return { url: content.target_url_internal, isInternal: true };
    }
    return { url: content.target_url, isInternal: false };
  }, [content.target_url, content.target_url_internal]);

  useTrackEventOnHover(cancelButtonRef, {
    category: 'Inapp Modal',
    label: content.button_cancel_text || 'button_cancel',
    value: modalId,
  });

  useTrackEventOnHover(confirmButtonRef, {
    category: 'Inapp Modal',
    label: content.button_ok_text || 'button_confirm',
    value: modalId,
    fieldsObject: `destination:${target.url}`,
  });

  const primaryButtonContent = useMemo(() => {
    return (
      <Button
        text={content.button_ok_text || translate('button.readMore')}
        disabled={confirmationNeeded && !isChecked}
        className='w-full justify-center'
        icon={(_, color) => <SearchIcon color={color} />}
        onClick={() => {
          DashboardAppWorker.trackEvent({
            category: 'Inapp Modal',
            action: 'onclick',
            label: content.button_ok_text || 'button_confirm',
            value: modalId,
            fieldsObject: `destination:${target.url}`,
          });
          target.isInternal && onClose();
        }}
      />
    );
  }, [content.button_ok_text, translate, confirmationNeeded, isChecked, modalId, target, onClose]);

  return (
    <div className='flex gap-3 bg-white pb-6 pt-18px sticky bottom-0 flex-wrap-reverse'>
      {content.button_cancel_text && (
        <div ref={cancelButtonRef} className='flex-auto max-w-full'>
          <Button
            onClick={() => {
              onClose();
              DashboardAppWorker.trackEvent({
                category: 'Inapp Modal',
                action: 'onclick',
                label: content.button_cancel_text || 'button_close',
                value: modalId,
              });
            }}
            text={content.button_cancel_text || translate('button.close')}
            color='tertiary'
            className='shrink-0 w-full'
          />
        </div>
      )}
      <div className='flex-auto justify-center max-w-full' ref={confirmButtonRef}>
        {target.url && (!confirmationNeeded || isChecked) ? (
          <FooterLink url={target.url} internal={target.isInternal}>
            {primaryButtonContent}
          </FooterLink>
        ) : (
          primaryButtonContent
        )}
      </div>
    </div>
  );
};

const FooterLink: FC<PropsWithChildren<{ internal: boolean; url: string }>> = ({ internal, url, children }) => {
  if (internal) {
    return <Link to={url}>{children}</Link>;
  }
  return (
    <a href={url} rel='noreferrer' target='_blank' className='w-full'>
      {children}
    </a>
  );
};
