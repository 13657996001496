import { TooltipWrapper } from '@va/ui/tooltips';
import { MouseEventHandler, ReactNode } from 'react';

export type ActionProps = {
  icon: ReactNode;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  description?: string;
  disabled?: boolean;
  disabledReason?: string;
};

const Action = ({ icon, children, onClick, description, disabled = false, disabledReason }: ActionProps) => {
  return (
    <TooltipWrapper disabled={!disabled || !disabledReason} content={disabledReason}>
      <div>
        <button
          type='button'
          onClick={onClick}
          className='group w-full focus:outline-none px-3 py-2 leading-normal flex items-center hover:bg-alabaster active:bg-gray-concrete disabled:opacity-50'
          disabled={disabled}
        >
          <div className='mr-4'>
            <div className='rounded-full bg-gray-concrete w-9 h-9 flex items-center justify-center'>
              <div className='w-5 h-5 flex items-center justify-center'>{icon}</div>
            </div>
          </div>
          <div className='text-left'>
            <div className='font-medium text-mine-shaft text-sm group-hover:text-primary'>{children}</div>
            {description && <div className='text-devil-gray text-xs group-hover:text-primary'>{description}</div>}
          </div>
        </button>
      </div>
    </TooltipWrapper>
  );
};

export default Action;
