import { useEffect } from 'react';

export const mutationConfig = {
  attributes: false,
  characterData: false,
  childList: false,
  subtree: false,
};

export const useMutationObserver = (
  targetNode: Element | null,
  callback: MutationCallback,
  options = mutationConfig,
) => {
  useEffect(() => {
    if (!targetNode) return;

    const observer = new MutationObserver(callback);
    observer.observe(targetNode, options);
    return () => {
      observer.disconnect();
    };
  }, [callback, options, targetNode]);
};
