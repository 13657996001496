import { apiStatus } from '@va/constants';
import { clearNotifications } from '@va/dashboard/actions/ui';
import { AnnouncementBanner } from '@va/dashboard/components';
import { getInstanceId, getModulePermissionsStatus, isInitializing } from '@va/dashboard/selectors/app';
import { getWebsite, isDataResetRequested, isWhiteLabelEnabled } from '@va/dashboard/selectors/core';
import { isTabletDevice } from '@va/dashboard/selectors/ui';
import { FeatureCounterContextProvider } from '@va/dashboard/shared/feature-counter';
import {
  LayoutProps,
  LayoutStickyHeaderWrapper,
  TopBar,
  getLayoutClassName,
  useScrollToTopEffect,
} from '@va/dashboard/shared/ui-layout';
import TooltipManager from '@va/deprecated/components/tooltips/TooltipManager';
import { useLocalizationContext } from '@va/localization';
import { updateWebsiteLocale } from '@va/standalone/shared/actions';
import { isAccountSettingsPageSelected } from '@va/standalone/shared/helpers';
import { getAccountLocale, getApiRequestStatus, hasUserRole } from '@va/standalone/shared/selectors';
import { UserRole } from '@va/types/website';
import { LoaderBar } from '@va/ui/design-system';
import { PageHeaderContextProvider, RefreshContextProvider } from '@va/util/components';
import { extractLanguage, getParameterByName } from '@va/util/helpers';
import { UpgradeToHigherPackageBadgePreHeaderWixClaimed } from '@va/wix/shared/components';
import NotificationSystemWrapper from 'common/components/misc/NotificationSystemWrapper';
import { FC, PropsWithChildren, memo, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Footer from 'standalone/components/footer/FooterWrapper';
import ResetRequestedPage from 'standalone/components/pages/ResetRequestedPage';
import { LayoutPreHeader } from './LayoutPreHeader';
import { SidebarWrapper } from './SidebarWrapper';

function Layout({ children, hasBackButton = false }: PropsWithChildren<LayoutProps>) {
  useScrollToTopEffect();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const websiteInfo = useSelector(getWebsite);
  const initializing = useSelector(isInitializing);

  const websiteId = useSelector(getInstanceId);
  const resetRequested = useSelector(isDataResetRequested);
  const whiteLabelEnabled = useSelector(isWhiteLabelEnabled);
  const isTablet = useSelector(isTabletDevice);
  const userLocale = useSelector(getAccountLocale);
  const { locale, setLocale } = useLocalizationContext();
  const showSidebar = useMemo(() => isTablet || !isAccountSettingsPageSelected(pathname), [isTablet, pathname]);
  const agencyUiStatus = useSelector((state) => getApiRequestStatus(state, 'getAgencyUi'));
  const modulePermissionsStatus = useSelector(getModulePermissionsStatus);
  const isDashboardContributor = useSelector(hasUserRole)(UserRole.DASHBOARD);

  const showLoadingLogo = useMemo(() => {
    if (isAccountSettingsPageSelected()) return false;
    if (agencyUiStatus === apiStatus.IN_PROGRESS) return true;
    if (modulePermissionsStatus !== apiStatus.SUCCEEDED) return true;
    return !websiteInfo.exists;
  }, [agencyUiStatus, modulePermissionsStatus, websiteInfo.exists]);

  useEffect(() => {
    dispatch(clearNotifications());
  }, [dispatch, websiteId]);

  useEffect(() => {
    const parameterLocale = extractLanguage();

    if (userLocale && parameterLocale && parameterLocale !== userLocale) {
      setLocale(parameterLocale);
      dispatch(updateWebsiteLocale(parameterLocale));
      return;
    }

    if (userLocale && userLocale !== locale) {
      setLocale(userLocale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocale]);

  const contentClassName = useMemo(() => getLayoutClassName(showSidebar), [showSidebar]);

  if (initializing) {
    return <LoaderBar />;
  }

  if (resetRequested && !window.location.pathname.includes('/my-account')) {
    return (
      <>
        <ResetRequestedPage />
      </>
    );
  }

  return (
    <>
      <TopBar hasBackButton={hasBackButton} />
      {showLoadingLogo ? (
        <LoaderBar />
      ) : (
        <>
          <LayoutContent contentClassName={contentClassName} useFeatureCounterContext={!isDashboardContributor}>
            {children}
          </LayoutContent>
          {!whiteLabelEnabled && !isAccountSettingsPageSelected() && <Footer />}
        </>
      )}
    </>
  );
}

export default memo(Layout);

const LayoutContent: FC<
  PropsWithChildren<{
    contentClassName: string;
    useFeatureCounterContext: boolean;
  }>
> = ({ contentClassName, children, useFeatureCounterContext }) => {
  const content = (
    <>
      <SidebarWrapper />
      <RefreshContextProvider>
        <PageHeaderContextProvider>
          <div className='flex relative pt-[70px] min-h-full'>
            <div className={contentClassName}>
              <LayoutStickyHeaderWrapper />
              <AnnouncementBanner />
              <LayoutPreHeader />
              <UpgradeToHigherPackageBadgePreHeaderWixClaimed />
              <NotificationSystemWrapper />
              <TooltipManager />
              {children}
            </div>
          </div>
        </PageHeaderContextProvider>
      </RefreshContextProvider>
    </>
  );

  if (useFeatureCounterContext) {
    return <FeatureCounterContextProvider>{content}</FeatureCounterContextProvider>;
  }

  return content;
};
