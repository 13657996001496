import { apiStatus, platform, websiteStatus } from '@va/constants';
import {
  getAggregatesConversions,
  getOverviewAggregatesNewVisitors,
  getOverviewAggregatesPageVisits,
  getOverviewAggregatesVisitors,
} from '@va/dashboard/api-client/Overview';
import { useTranslate } from '@va/localization';
import { getWebsiteOverview as getVisitorsSummary, setActiveWebsite } from '@va/standalone/shared/actions';
import { getApiRequestStatus, getWebsiteOverview } from '@va/standalone/shared/selectors';
import { SeeMoreHorizontally } from '@va/svg-visa-icons';
import { TimePeriod } from '@va/types/time';
import { Button, fontWeights, Paragraph, STATUS_COLORS, StatusPill } from '@va/ui/design-system';
import { getUrlWithoutProtocolAndTrailingSlash, setResizeObserver } from '@va/util/helpers';
import classNames from 'classnames';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { mapperFunction, StatsResponseType } from '@va/dashboard/api-client/VisitorsOverviewV2';
import { getGlobalFilter, getWindowWidth } from '@va/dashboard/selectors/ui';
import { UserRole } from '@va/types/website';
import { TooltipWrapper } from '@va/ui/tooltips';
import { MappedWebsite } from '../../agency-white-label-v2/agency-white-label-apiClient';
import BillingPlan from './BillingPlan';
import Overview from './Overview';
import PendingContributorRequest from './PendingContributorRequest';
import VisitorsChart from './VisitorsChart';
import WebsiteActions from './WebsiteActions';
import './WebsitesTableRow.scss';

const WebsitesTableRow = ({ website, index }: { website: MappedWebsite; index: number }) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const websiteId = website.id;
  const websiteTimezone = website.timezone;
  const websiteDetail = useSelector((state) => getWebsiteOverview(state, websiteId));
  const summaryStatus = websiteDetail.get('status');
  const trackingCodeInstalled = website.trackingCodeInstalled;
  const inProgress = summaryStatus === apiStatus.IN_PROGRESS;
  const userRole = website.userRole;
  const isWebsiteOwner = userRole === UserRole.OWNER;

  const pendingContributorRequest = !isWebsiteOwner && website.contributorRequests.length > 0;
  const manageWebsiteRef = useRef<HTMLDivElement>(null);
  const visitorsChartRef = useRef<HTMLDivElement>(null);
  const [isWebsiteActionsVisible, setWebsiteActionsVisible] = useState(false);
  const platformName = website.platform;
  const windowWidth = useSelector(getWindowWidth);
  const contributorApiStatus = useSelector((state) => getApiRequestStatus(state, 'acceptContributorRole'));
  const dateRangeFilter = useSelector(getGlobalFilter);
  const [chartData, setChartData] = useState<StatsResponseType>();
  const [selectedOption, setSelectedOption] = useState(1);
  const [chartIsInProgress, setChartIsInProgress] = useState(false);
  const [chartHasError, setChartHasError] = useState(false);

  const currentTotalCount = chartData?.current?.total ?? 0;
  const previousTotalCount = chartData?.previous?.total ?? 0;

  const getRequestName = useCallback(() => {
    switch (selectedOption) {
      case 2:
        return getOverviewAggregatesNewVisitors;
      case 3:
        return getOverviewAggregatesPageVisits;
      case 4:
        return getAggregatesConversions;
      default:
        return getOverviewAggregatesVisitors;
    }
  }, [selectedOption]);

  const handleApiCall = useCallback(async () => {
    setChartIsInProgress(true);
    try {
      const response = await getRequestName()(websiteId, {
        ...dateRangeFilter,
        withPrevious: true,
      });
      setChartData(response);
      setChartIsInProgress(false);
    } catch (err) {
      setChartHasError(true);
      setChartIsInProgress(false);
    }
  }, [dateRangeFilter, getRequestName, websiteId]);

  useEffect(() => {
    setResizeObserver(manageWebsiteRef, handleResize);
  }, []);

  useEffect(() => {
    handleApiCall();
  }, [selectedOption, dateRangeFilter, handleApiCall]);

  function handleResize() {
    if (!manageWebsiteRef || !manageWebsiteRef?.current) {
      return;
    }
    let manageWebsiteSectionWidth =
      manageWebsiteRef && manageWebsiteRef.current && manageWebsiteRef.current.clientWidth - 350;
    if (window.innerWidth > 1000) {
      manageWebsiteSectionWidth = manageWebsiteSectionWidth - 220;
    }

    if (visitorsChartRef && visitorsChartRef.current) {
      visitorsChartRef.current.style.width = `${manageWebsiteSectionWidth}px`;
    }
  }

  useEffect(() => {
    dispatch(getVisitorsSummary(websiteId, websiteTimezone));
  }, [dispatch, websiteId, websiteTimezone, dateRangeFilter]);

  useEffect(() => {
    if (contributorApiStatus === apiStatus.SUCCEEDED) {
      dispatch(getVisitorsSummary(websiteId, websiteTimezone));
    }
  }, [contributorApiStatus, websiteId, dispatch, websiteTimezone]);

  function handleSelectedWebsite() {
    if (!isWebsiteOwner && website.status === websiteStatus.PENDING) return;
    dispatch(setActiveWebsite(websiteId, false, true));
  }

  const overviewContent = (
    <Overview
      websiteDetail={websiteDetail}
      inProgress={inProgress || chartIsInProgress}
      setSelectedOption={setSelectedOption}
      selectedOption={selectedOption}
      currentCount={currentTotalCount}
      previousCount={previousTotalCount}
    />
  );

  const visitorsChartDataMapped = useMemo(() => {
    if (!chartData) return;
    return mapperFunction(chartData, `chart.dataSet.${getChartName(selectedOption)}.label`, TimePeriod.day);
  }, [chartData, selectedOption]);

  const websiteUrl = getUrlWithoutProtocolAndTrailingSlash(website.url);

  return (
    <div
      ref={manageWebsiteRef}
      key={`manage-websites-website-${index}`}
      className={classNames(
        'manage-websites-wrapper flex sm:flex-col border-b-2 sm:border-2 sm:mb-4 sm:rounded-24 border-solid border-white-smoke',
        { 'min-h-[390px]': !pendingContributorRequest },
        { 'min-h-[210px]': pendingContributorRequest },
      )}
    >
      <div className='website-info-section p-5 border-r-2 sm:border-0 w-full border-solid border-white-smoke'>
        <div className='flex justify-between'>
          <TooltipWrapper interactive content={website.url}>
            <div
              onClick={handleSelectedWebsite}
              className='text-ellipsis text-21 leading-24 font-semibold self-center cursor-pointer'
            >
              {websiteUrl}
            </div>
          </TooltipWrapper>

          {!pendingContributorRequest && (
            <TooltipWrapper
              open={isWebsiteActionsVisible}
              content={
                <WebsiteActions
                  isSumoActive={website?.sumoActive}
                  subscription={website.subscription}
                  websiteId={websiteId}
                  whiteLabel={website.whiteLabel}
                  isWebsiteOwner={isWebsiteOwner}
                  setWebsiteActionsVisible={setWebsiteActionsVisible}
                  platformName={platformName}
                />
              }
              trigger='click'
              onOpenChange={(open) => setWebsiteActionsVisible(open)}
              useDefaultStyle={false}
            >
              <div>
                <Button
                  color='tertiary'
                  onClick={() => setWebsiteActionsVisible(!isWebsiteActionsVisible)}
                  icon={(className, color) => <SeeMoreHorizontally className={className} color={color} />}
                />
              </div>
            </TooltipWrapper>
          )}
        </div>
        <div className='py-3 flex justify-between items-center'>
          <StatusPill
            colors={trackingCodeInstalled ? STATUS_COLORS.GREEN : STATUS_COLORS.YELLOW}
            width={'w-full'}
            className='justify-start'
          >
            {translate(`manageWebsites.website.tracking.${trackingCodeInstalled ? 'active' : 'inactive'}`)}
          </StatusPill>
          {platformName === platform.WIX && (
            <span className='border-2 px-3 py-1 text-12 rounded-12 text-ceSoir '>{platformName}</span>
          )}
        </div>

        <div className='py-3'>
          <div className='text-12 leading-18 text-gray-dusty'>
            {translate('card.manageWebsites.header.websiteRole')}:
          </div>
          <div className='text-15 leading-24 font-semibold'>
            {translate(`card.manageWebsites.website.role.${userRole}`)}
          </div>
        </div>

        <div className='flex justify-between py-3'>
          {isWebsiteOwner && (
            <div>
              <div className='text-12 text-gray-dusty'>{translate('card.manageWebsites.header.whiteLabel')}:</div>
              <div className='text-15 leading-24 font-semibold'>
                {translate(`option.btn.${website.whiteLabel ? 'enabled' : 'disabled'}`)}
              </div>
            </div>
          )}

          <div>
            <div className='text-12 text-gray-dusty'>{translate('card.manageWebsites.header.plan')}:</div>
            <div className='text-15 leading-24 font-semibold'>
              {website.unlimitedPlanActive ? (
                <Paragraph weight={fontWeights.medium} colorClassName='text-green-green'>
                  {translate('plan.unlimited')}
                </Paragraph>
              ) : (
                <BillingPlan website={website} />
              )}
            </div>
          </div>
        </div>
        {windowWidth >= 768 && windowWidth < 1000 && !pendingContributorRequest && overviewContent}
      </div>

      {pendingContributorRequest ? (
        <div className='w-full h-full bg-gray-alabaster border-solid border-2 sm:rounded-b-24 border-white min-h-[210px]'>
          <PendingContributorRequest requestId={website.contributorRequests[0].id} userRole={userRole!} />
        </div>
      ) : (
        <>
          {(windowWidth >= 1000 || windowWidth < 768) && (
            <div className='visitors-overview-section relative p-5 w-full sm:w-auto border-r-2 sm:border-t-2 sm:border-r-0 border-solid border-white-smoke'>
              {overviewContent}
            </div>
          )}

          <div
            ref={visitorsChartRef}
            className='visitor-chart-manage-websites relative p-5 w-full block sm:hidden min-h-[390px]'
          >
            <VisitorsChart
              chartId={`visitors-${index}`}
              inProgress={inProgress || chartIsInProgress}
              hasError={chartHasError}
              data={visitorsChartDataMapped}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default memo(WebsitesTableRow);

function getChartName(option: number) {
  switch (option) {
    case 1:
      return 'visitors';
    case 2:
      return 'newVisitors';
    case 3:
      return 'pageVisits';
    case 4:
      return 'conversion';
    default:
      return;
  }
}
