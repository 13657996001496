import { AlarmingBehavior } from '@va/types/events';
import { DataTableV8 } from '@va/ui/components/data-table';
import { ReportBlockBody, ReportBlockHeader, ReportBlockSubHeader } from '@va/ui/components/report-block';
import { useAlarmingBehaviorEventsContext } from '../../context/ABEContext';
import { useAbesByPageColumns } from './useAbesByPageColumns';

export const AbesByPageTable = () => {
  const {
    data,
    isLoading,
    setSorting,
    pagination,
    paginationId,
    sorting,
    setPagination,
    setRowSelection,
    rowSelection,
    toggleABESelection,
  } = useAlarmingBehaviorEventsContext();
  const columns = useAbesByPageColumns();
  return (
    <ReportBlockBody border={false} className={'min-h-[500px]'}>
      <ReportBlockHeader />
      <ReportBlockSubHeader />
      <DataTableV8<AlarmingBehavior>
        id='abes-by-page'
        data={data?.payload ?? []}
        columns={columns}
        isLoading={isLoading}
        enableMultiRowSelection
        rowCount={data?.meta?.total ?? 0}
        state={{ pagination, sorting, rowSelection }}
        paginationContainerId={paginationId}
        onPaginationChange={setPagination}
        onRowSelectionChange={setRowSelection}
        onSortingChange={setSorting}
        onRowClick={(row) => toggleABESelection(row.original)}
      />
    </ReportBlockBody>
  );
};
