import { Paragraph, paragraphSizes } from '@va/ui/design-system';
import { appHasDarkTheme } from '@va/util/helpers';

/** @deprecated */
export function TooltipTitle({ title = '' }: { title?: string }) {
  const isDarkThemeApplied = appHasDarkTheme();
  if (!title) return <div />;
  return (
    <Paragraph
      size={paragraphSizes.large}
      colorClassName={isDarkThemeApplied ? 'text-black' : 'text-white'}
      className='truncate'
    >
      {title}
    </Paragraph>
  );
}
