import { Clock } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { addNumberSeparator, getFormattedDateAndTime } from '@va/util/helpers';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { CellWithIcon } from './CellWithIcon';

/**@deprecated */
export const DateCell = ({ value, column, state }) => {
  const translate = useTranslate();
  const { locale } = useLocale();
  const othersGrouped = !column?.isGrouped && state?.groupBy?.length > 0;
  if (othersGrouped) {
    const valueAsNumber = Number.parseInt(value);

    return (
      <CellWithIcon
        leftElement={<Clock strokeColor='#969696' />}
        topText={
          valueAsNumber === 1
            ? translate('eventTracking.table.value', {
                count: addNumberSeparator(valueAsNumber, locale),
              })
            : translate('eventTracking.table.values', {
                count: addNumberSeparator(valueAsNumber, locale),
              })
        }
      />
    );
  }

  const momentValue = moment(value).unix();

  return (
    <CellWithIcon
      leftElement={<Clock strokeColor='#969696' />}
      topText={getFormattedDateAndTime(momentValue, locale)}
    />
  );
};

DateCell.propTypes = {
  value: PropTypes.string.isRequired,
  column: PropTypes.shape({
    isGrouped: PropTypes.bool.isRequired,
  }),
  state: PropTypes.shape({
    groupBy: PropTypes.array,
  }),
};
