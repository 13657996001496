import { apiStatus } from '@va/constants';
import Immutable from 'immutable';

/**
 * @deprecated
 * @see newApiRequest
 */
export const apiRequest =
  (request, succeeded, failed, initialState = Immutable.Map(), updateFunc = (state, data) => state.merge(data)) =>
  (state = initialState, action) => {
    switch (action.type) {
      case request:
        return state.set('inProgress', true);
      case succeeded:
        return (action.data ? updateFunc(state, action.data) : state).set('inProgress', false).remove('error');
      case failed:
        return (
          action.error
            ? updateFunc(Immutable.Map(), { error: { code: action.error.code, message: action.error.message } })
            : Immutable.Map()
        ).set('inProgress', false);
      default:
        return state;
    }
  };

export const newApiRequest =
  (
    request,
    succeeded,
    failed,
    initialState = Immutable.Map({ status: apiStatus.NONE }),
    updateFunc = (state, data) => state.merge(data),
  ) =>
  (state = initialState, action) => {
    switch (action.type) {
      case request:
        return state.set('status', apiStatus.IN_PROGRESS);
      case succeeded:
        return (action.data ? updateFunc(state, action.data) : state)
          .set('status', apiStatus.SUCCEEDED)
          .remove('error');
      case failed:
        return (
          action.error
            ? updateFunc(Immutable.Map(), { error: { code: action.error.code, message: action.error.message } })
            : Immutable.Map()
        ).set('status', apiStatus.FAILED);
      default:
        return state;
    }
  };
