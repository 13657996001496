import React from 'react';
import { IconProps } from '.';

export const FunnelIcon = ({ color = 'black', className }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 1.875V4.875H12.75V1.875L16.5 1.875ZM11.25 1.875V4.875H6.75V1.875L11.25 1.875ZM11.25 0.375H12.75H16.5H18V1.875V4.875V6.375H17.25C14.3506 6.375 12.0001 8.72539 12 11.6248V11.625V13.1248V16.1248V17.6248H10.5H7.5H6L6 16.1248V13.1248L6 11.625C6 8.72551 3.64949 6.375 0.75 6.375L0 6.375V4.875V1.875V0.375H1.5H5.25H6.75H11.25ZM5.25 1.875V4.875H1.5L1.5 1.875L5.25 1.875ZM4.993 6.375C6.5222 7.61243 7.49993 9.50442 7.5 11.6248H10.5C10.5001 9.50442 11.4778 7.61243 13.007 6.375L12.75 6.375H11.25H6.75H5.25L4.993 6.375ZM10.5 13.1248V16.1248H7.5V13.1248H10.5Z'
        fill={color}
      />
    </svg>
  );
};


