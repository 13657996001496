import { IconProps } from '.';

export const CloseIcon = ({ color = 'black', className }: IconProps) => {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM10.409 12L7.4545 9.0455L9.0455 7.4545L12 10.409L14.9545 7.4545L16.5455 9.0455L13.591 12L16.5455 14.9545L14.9545 16.5455L12 13.591L9.0455 16.5455L7.4545 14.9545L10.409 12Z'
        fill={color}
      />
    </svg>
  );
};
