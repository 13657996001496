import { IconProps } from '.';

export const RadioInputSelected = ({ className, color = '#06A25A' }: IconProps) => {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12' cy='12' r='10.875' stroke={color} strokeWidth='2.25' />
      <circle cx='12' cy='12' r='6' fill={color} />
    </svg>
  );
};
