export const GREEN_COLOR = '#009688';
export const GREEN_DARKER_COLOR = '#016D62';
export const RED_COLOR = '#FF5252';
export const BLACK_COLOR = '#13232A';
export const WHITE_COLOR = '#FFFFFF';
export const BLUE_COLOR = '#2478F0';
export const PURE_BLACK_COLOR = '#3c3c3c';
export const LIGHT_GREY_COLOR = '#f9f9f9';
export const GREY_COLOR = '#969696';
export const CHARCOAL_COLOR = '#3C3C3C';
export const CODE_COLOR = '#1E1E1E';
