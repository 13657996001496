import { IconProps } from '.';

export const PieChartIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='9' cy='9' r='8.25' stroke={color} strokeWidth='1.5' />
      <path
        opacity='0.75'
        d='M9 3C9.78793 3 10.5681 3.15519 11.2961 3.45672C12.0241 3.75825 12.6855 4.20021 13.2426 4.75736C13.7998 5.31451 14.2417 5.97595 14.5433 6.7039C14.8448 7.43185 15 8.21207 15 9L9 9V3Z'
        fill={color}
      />
      <path
        opacity='0.5'
        d='M15 9C15 9.78793 14.8448 10.5681 14.5433 11.2961C14.2417 12.0241 13.7998 12.6855 13.2426 13.2426L9 9H15Z'
        fill={color}
      />
      <path
        opacity='0.25'
        d='M13.2426 13.2426C12.4035 14.0818 11.3344 14.6532 10.1705 14.8847C9.00666 15.1162 7.80026 14.9974 6.7039 14.5433C5.60754 14.0892 4.67047 13.3201 4.01118 12.3334C3.35189 11.3467 3 10.1867 3 9L9 9L13.2426 13.2426Z'
        fill={color}
      />
    </svg>
  );
};
