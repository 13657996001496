import { UNTRANSLATED_LOCALE } from '@va/constants';
import { isTranslationKeysAccessEnabled } from '@va/util/helpers';
import Config from 'Config';

export function getSupportedLanguages() {
  const languages: string[] = [...Config.supportedLanguages];
  if (isTranslationKeysAccessEnabled()) {
    languages.push(UNTRANSLATED_LOCALE);
  }
  return languages;
}

export function getTranslationsUrl(locale: string) {
  return `${Config.translationsUrl}/${locale}.json`;
}
