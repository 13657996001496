import { TEST_IDS } from '@va/constants';
import { CheckmarkIcon, CopyIcon, ExternalLinkIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, ParagraphWithTooltip, fontWeights } from '@va/ui/design-system';
import { useCopyToClipboard } from '@va/util/hooks';
import classNames from 'classnames';
import { SubSectionHeader } from '../misc';

export const DomainSupportUrlComponent = ({ url, placeholder }: { url: string; placeholder?: String }) => {
  const translate = useTranslate();

  const { onClickCopy: copySupportUrl, success: copySupportUrlSuccess } = useCopyToClipboard();

  return (
    <div>
      <SubSectionHeader
        className='mt-24'
        text={translate('whiteLabel.ownSupport.title')}
        tooltip={translate('whiteLabel.ownSupport.tooltip')}
      />
      <div className='w-full flex justify-end items-center gap-3 p-3 bg-white-snow text-gray-devil rounded-18 mb-18px'>
        <ParagraphWithTooltip weight={fontWeights.medium} className='grow text-gray-charcoal'>
          {url ? url : placeholder}
        </ParagraphWithTooltip>
        <Button
          onClick={() => copySupportUrl(url)}
          tooltip={
            copySupportUrlSuccess ? translate('button.copiedToClipboard') : translate('button.copyToClipboardText')
          }
          color={'quaternary'}
          disabled={!url}
          icon={() => (copySupportUrlSuccess ? <CheckmarkIcon color='white' /> : <CopyIcon color='#3C3C3C' />)}
          className={classNames(copySupportUrlSuccess && '!bg-green-pure')}
          data-testid={TEST_IDS.generic.buttons.copyToClipboard}
        />
        <Button
          disabled={!url}
          onClick={() => {
            window.open(url, '_blank');
          }}
          color='quaternary'
          icon={() => <ExternalLinkIcon color='#3C3C3C' />}
          tooltip={translate('domain.goToWebsite')}
          data-testid={TEST_IDS.generic.buttons.goToWebsite}
        />
      </div>
    </div>
  );
};
