import { PropsWithChildren, createContext, useContext } from 'react';

type GlobalFiltersConfig = {
  TemplatesTab?: ({ closeDropdown }: { closeDropdown: () => void }) => JSX.Element;
  SaveTemplateButton?: ({ isAddFilterBtnVisible }: { isAddFilterBtnVisible: boolean }) => JSX.Element;
  reportErrorFunc: (message: string) => void;
  websiteId?: string;
};

export const GlobalFiltersConfigCtx = createContext<GlobalFiltersConfig>({
  reportErrorFunc: (err) => {
    console.error(err);
  },
});

export const GlobalFiltersConfigProvider = ({ children, ...config }: PropsWithChildren<GlobalFiltersConfig>) => {
  return <GlobalFiltersConfigCtx.Provider value={config}>{children}</GlobalFiltersConfigCtx.Provider>;
};

export const useGlobalFiltersConfig = () => useContext(GlobalFiltersConfigCtx);
