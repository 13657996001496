import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { supportLinks } from '@va/constants';
import PopUpCard from '@va/deprecated/components/PopUpCard';
import Translation from '@va/deprecated/components/Translation';
import FormInput from '@va/deprecated/form/FormInput';
import { getNextBillingDate } from '@va/standalone/shared/helpers';

import './style.scss';

export default class LeaveFeedback extends PureComponent {
  renderFooterButtons() {
    const disabledClass = this.props.invalid ? 'disabled' : '';
    return (
      <React.Fragment>
        <button type='submit' className={`footer-button colored ${disabledClass}`}>
          {this.props.translate('button.cancelSiteSubscription')}
        </button>
        <div onClick={this.props.closeModal}>{this.props.translate('button.forgetAboutIt')}</div>
      </React.Fragment>
    );
  }

  render() {
    const { translate, activeSubscription, timezone, handleSubmit, invalid } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <PopUpCard
          type='card-grey'
          title={translate('modal.cancelSubscription.title')}
          colorTheme={'theme-red'}
          invalid={invalid}
          footerButtons={this.renderFooterButtons()}
        >
          <React.Fragment>
            <div className={'paragraph'}>
              {translate('modal.cancelSubscription.text1', {
                endBillingDate: getNextBillingDate(activeSubscription, timezone),
              })}
            </div>
            <Translation
              translationKey={'modal.cancelSubscription.text2'}
              parameters={{ cancelSubscriptionLink: supportLinks.cancelSubscription, className: 'link-button' }}
            />
            <div className='leave-feedback-header'>{translate('modal.cancelSubscription.feedbackHeader')}</div>
            <FormInput name='reason' type='text' translate={translate} placeholder={''} shouldAutoFocus />
          </React.Fragment>
        </PopUpCard>
      </form>
    );
  }
}

LeaveFeedback.propTypes = {
  translate: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  activeSubscription: PropTypes.any,
  timezone: PropTypes.string,
  invalid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
