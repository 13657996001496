import {
  createFilterTemplate,
  deleteFilterTemplates,
  getFilterTemplates,
  updateFilterTemplate,
} from '@va/dashboard/api-client/filter-templates.api';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { FilterTemplate } from '@va/types/filter-templates';
import { parseFilterTemplateValues, toQueryString } from '@va/util/helpers';
import { useAsyncFunction, useFetchData } from '@va/util/hooks';
import { isArray } from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useCreateFilterTemplate = () => {
  return useAsyncFunction<typeof createFilterTemplate>(createFilterTemplate);
};

export const useUpdateFilterTemplate = () => {
  return useAsyncFunction<typeof updateFilterTemplate>(updateFilterTemplate);
};

export const useDeleteFilterTemplate = () => {
  return useAsyncFunction<typeof deleteFilterTemplates>(deleteFilterTemplates);
};

export const useGetFilterTemplates = (reportBlockId: string | undefined) => {
  const websiteId = useSelector(getInstanceId);
  const fetcher = useCallback(async () => {
    if (!reportBlockId) return;
    const res = await getFilterTemplates(websiteId, reportBlockId);
    if (!isArray(res)) {
      return [];
    }

    return res.map((template) => {
      return {
        ...template,
        conditions: {
          and: template.conditions.and?.map((cond) => ({ ...cond, values: parseFilterTemplateValues(cond.values) })),
        },
      };
    });
  }, [reportBlockId, websiteId]);
  const key = `get-filterTemplates?${toQueryString({ websiteId, reportBlockId })}`;
  return useFetchData<FilterTemplate[] | null>(!reportBlockId ? null : key, undefined, undefined, fetcher);
};
