import { IconProps } from '.';

export const RadioInput = ({ className, color = '#969696' }: IconProps) => {
  return (
    <svg
      className={className}
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='6' cy='6' r='5.25' stroke={color} strokeWidth='1.5' />
    </svg>
  );
};
