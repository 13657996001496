import { IconProps } from '..';

export const OtherDevicesIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.75 1.5C2.50736 1.5 1.5 2.50736 1.5 3.75V15C1.5 15.8284 2.17157 16.5 3 16.5V18C1.34315 18 0 16.6569 0 15V3.75C0 1.67893 1.67893 0 3.75 0H15C16.6569 0 18 1.34315 18 3H16.5C16.5 2.17157 15.8284 1.5 15 1.5H3.75ZM11.25 6H15.75C16.1642 6 16.5 6.33579 16.5 6.75V15.75C16.5 16.1642 16.1642 16.5 15.75 16.5H11.25C10.8358 16.5 10.5 16.1642 10.5 15.75V11.25V6.75C10.5 6.33579 10.8358 6 11.25 6ZM9 9.12803V6.75C9 5.50736 10.0074 4.5 11.25 4.5H15.75C16.9926 4.5 18 5.50736 18 6.75V15.75C18 16.9926 16.9926 18 15.75 18H11.25C10.6737 18 10.1481 17.7834 9.75 17.4271C9.35193 17.7834 8.82627 18 8.25 18H6.75C5.50736 18 4.5 16.9926 4.5 15.75V11.25C4.5 10.0074 5.50736 9 6.75 9H8.25C8.51298 9 8.76542 9.04512 9 9.12803ZM9 11.25C9 10.8358 8.66421 10.5 8.25 10.5H6.75C6.33579 10.5 6 10.8358 6 11.25V15.75C6 16.1642 6.33579 16.5 6.75 16.5H8.25C8.66421 16.5 9 16.1642 9 15.75V11.25ZM13.5 15C13.9142 15 14.25 14.6642 14.25 14.25C14.25 13.8358 13.9142 13.5 13.5 13.5C13.0858 13.5 12.75 13.8358 12.75 14.25C12.75 14.6642 13.0858 15 13.5 15Z'
        fill={color ?? 'var(--color-primary)'}
      />
    </svg>
  );
};
