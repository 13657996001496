import { Placement } from '@floating-ui/react';
import { useTranslate } from '@va/localization';
import {
  DISABLED_CLASSES,
  fontWeights,
  InputMessage,
  Paragraph,
  paragraphSizes,
  RadioInputButton,
} from '@va/ui/design-system';
import { TooltipWrapper, useTooltipContext } from '@va/ui/tooltips';
import { DropdownArrow } from '@va/util/components';
import { useOutsideClick } from '@va/util/hooks';
import classNames from 'classnames';
import { isEmpty, isNil } from 'lodash';
import React, { ChangeEvent, FocusEventHandler, useEffect, useRef, useState } from 'react';

export type SelectDropdownOption<T> = {
  label: string;
  value: T;
  disabled?: boolean;
};

export type SelectDropdownProps<T> = {
  wrapperClassName?: string;
  buttonClassName?: string;
  icon?: React.ReactNode;
  label?: string;
  dropdownPlacement?: Placement;
  error?: string;
  placeholderText?: string;
  selectedOption?: SelectDropdownOption<T> | null;
  dropdownOptions: SelectDropdownOption<T>[];
  onChange: (option: SelectDropdownOption<T>) => void;
  onBlur?: FocusEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  infoTooltip?: string;
  required?: boolean;
  appendTo?: HTMLElement;
  keepParentWidth?: boolean;
  bgColor?: string;
  bgHover?: string;
  bgFocus?: string;
  inputDisabled?: boolean;
  dataTestId?: string;
  size?: 'medium' | 'large';
  comparisonType?: 'includes' | 'match';
};

export function SelectDropdown<T = string>({
  wrapperClassName,
  selectedOption,
  dropdownOptions,
  label,
  icon,
  dropdownPlacement = 'bottom-start',
  onChange,
  onBlur,
  error,
  disabled = false,
  infoTooltip,
  buttonClassName,
  required,
  appendTo,
  keepParentWidth = true,
  bgColor = 'bg-white-snow',
  bgHover = 'bg-gray-concrete',
  bgFocus = 'bg-gray-gallery-darker',
  inputDisabled = false,
  dataTestId,
  size = 'large',
  comparisonType = 'includes',
}: SelectDropdownProps<T>) {
  const [isFocused, setIsFocused] = useState(false);
  const [filteredOptions, setFilteredOption] = useState(dropdownOptions);
  const [filterQuery, setFilterQuery] = useState('');

  const buttonRef = useRef(null);
  const translate = useTranslate();

  useOutsideClick(buttonRef, () => {
    setIsFocused(false);
  });

  const onQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterQuery(e.target.value);
  };

  useEffect(() => {
    if (!filterQuery) return setFilteredOption(dropdownOptions);
    setFilteredOption(
      dropdownOptions.filter((option) =>
        comparisonType === 'match'
          ? option.label.toLocaleLowerCase().startsWith(filterQuery.toLocaleLowerCase())
          : option.label.toLocaleLowerCase().includes(filterQuery.toLocaleLowerCase()),
      ),
    );
  }, [comparisonType, dropdownOptions, filterQuery]);

  const onOptionChangeHandler = (option: SelectDropdownOption<T>) => {
    setFilterQuery('');
    onChange(option);
  };

  const scrollSelectedIntoView = () => {
    if (isNil(selectedOption?.value)) return;
    const element = document.getElementById(`${selectedOption?.value}`);
    element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  return (
    <div className={classNames('select-dropdown-wrapper relative', wrapperClassName)}>
      <TooltipWrapper
        trigger={disabled ? 'hover' : 'click'}
        appendTo={appendTo}
        arrow={disabled}
        interactive={true}
        placement={disabled ? 'top' : dropdownPlacement}
        useDefaultStyle={disabled}
        disabled={infoTooltip ? false : disabled}
        useParentWidth={keepParentWidth}
        content={
          infoTooltip ? (
            infoTooltip
          ) : (
            <Dropdown
              onOptionChangeHandler={onOptionChangeHandler}
              filteredOptions={filteredOptions}
              selectedOption={selectedOption}
            />
          )
        }
      >
        <div>
          <button
            onBlur={onBlur}
            type='button'
            ref={buttonRef}
            onClick={() => {
              setIsFocused((prev) => {
                if (!prev) {
                  setTimeout(() => scrollSelectedIntoView(), 0);
                }
                return !prev;
              });
            }}
            className={classNames(
              'flex w-full gap-15px items-center rounded-15 px-18px transition-colors duration-300',
              disabled && DISABLED_CLASSES,
              {
                'h-12': size === 'medium',
                'h-60px': size === 'large',
                [`${bgFocus}`]: isFocused,
                [`${bgColor}`]: !isFocused,
                [`hover:${bgHover}`]: !disabled,
              },
              buttonClassName,
            )}
          >
            {icon && <div className='shrink-0'>{icon}</div>}
            <div className='text-left grow truncate'>
              {label && (
                <Paragraph
                  colorClassName='text-gray-devil text-ellipsis'
                  size={paragraphSizes.tiny}
                  weight={fontWeights.medium}
                >
                  {label}
                  {required && <span className='text-red-negative ml-2'>*</span>}
                </Paragraph>
              )}
              <input
                onChange={onQueryChange}
                onKeyDown={(e) => {
                  // VISA-10474 - a Spacebar press triggers a click event on the focused element causing the dropdown to open/close
                  if (e.code === 'Space') {
                    e.stopPropagation();
                    e.preventDefault();
                    setFilterQuery((p) => `${p} `);
                  }
                }}
                disabled={disabled || inputDisabled}
                type='text'
                value={filterQuery}
                autoComplete='NO_NEED'
                placeholder={
                  isEmpty(selectedOption?.label) ? translate('label.no.option.selected') : selectedOption?.label
                }
                className={classNames('w-full outline-none bg-transparent placeholder-gray-charcoal font-medium', {
                  'cursor-not-allowed': disabled,
                })}
                data-testid={dataTestId}
              />
            </div>
            <DropdownArrow open={isFocused} className='ml-auto shrink-0' color='#969696' />
          </button>
        </div>
      </TooltipWrapper>
      {error && <InputMessage error={error} />}
    </div>
  );
}

type DropdownProps<T> = {
  onOptionChangeHandler: (option: SelectDropdownOption<T>) => void;
  filteredOptions: SelectDropdownOption<T>[];
  selectedOption?: SelectDropdownOption<T> | null;
};

function Dropdown<T>({ onOptionChangeHandler, selectedOption, filteredOptions }: DropdownProps<T>) {
  const translate = useTranslate();
  const { context } = useTooltipContext();

  return (
    <div className={'bg-white rounded-15 shadow-overlay p-6px'}>
      <ul className='scrollbar scrollbar-thumb max-h-[200px] overflow-y-auto text-gray-charcoal'>
        {filteredOptions.length === 0 && <Paragraph className='p-5'>{translate('select.search.noMatch')}</Paragraph>}
        {filteredOptions.map((option, index) => {
          return (
            <DropdownOption
              key={index}
              option={option}
              isSelected={selectedOption?.value === option.value}
              onClick={(option) => {
                onOptionChangeHandler(option);
                context.onOpenChange(false);
              }}
            />
          );
        })}
      </ul>
    </div>
  );
}

type DropdownOptionProps<T> = {
  option: SelectDropdownOption<T>;
  isSelected: boolean;
  onClick: (option: SelectDropdownOption<T>) => void;
};

function DropdownOption<T>({ option, onClick, isSelected }: DropdownOptionProps<T>) {
  return (
    <li
      id={`${option.value}`}
      className={classNames(
        'cursor-default p-12px flex items-center gap-3 rounded-15 transition-colors duration-500',
        option?.disabled && DISABLED_CLASSES,
        {
          'hover:bg-white-snow': !option?.disabled,
        },
      )}
      onClick={() => {
        if (option?.disabled) return;
        onClick(option);
      }}
    >
      <RadioInputButton selected={isSelected} className='w-6 h-6 shrink-0' />
      <Paragraph weight={fontWeights.medium}>{option.label}</Paragraph>
    </li>
  );
}
