import React from 'react';

export const PauseIconSmall: React.FC<{ color?: string; className?: string }> = ({ color = '#999999', className }) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='9' cy='9' r='8' stroke={color} strokeWidth='1.5' />
      <path d='M7 5L7 9L7 13' stroke={color} strokeWidth='1.5' />
      <path d='M11 5L11 9L11 13' stroke={color} strokeWidth='1.5' />
    </svg>
  );
};
