import { validationTranslationKeys } from '@va/constants';
import { CloseIconWithCircle } from '@va/icons';
import { CheckMarkWithCircle } from '@va/svg-visa-icons';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import * as yup from 'yup';
import { Button } from '../button';
import { useTranslate } from '@va/localization';
import { TooltipWrapper } from '@va/ui/tooltips';

type TitleInputProps = {
  initialValue: string;
  onSave: (value: string) => void;
  name?: string;
};

export const TitleInput = ({ initialValue, name = 'title', onSave }: TitleInputProps) => {
  const translate = useTranslate();
  const [isFormActive, setFormActive] = useState(false);

  const { handleSubmit, handleChange, values, setFieldValue, errors } = useFormik({
    onSubmit: (values) => {
      onSave(values.title);
      setFormActive(false);
    },
    initialValues: { title: initialValue },
    validationSchema: () =>
      yup.object().shape({
        title: yup
          .string()
          .required(translate(validationTranslationKeys.required))
          .max(100, translate(validationTranslationKeys.maximumCharactersCount, { count: 100 })),
      }),
  });

  const handleClose = useCallback(() => {
    setFieldValue(name, initialValue);
    setFormActive(false);
  }, [initialValue, name, setFieldValue]);

  const buttonsClasses = 'h-12 w-12 rounded-t-none hover:!bg-gray-concrete';

  return (
    <form onSubmit={handleSubmit}>
      <TooltipWrapper
        useDefaultStyle={false}
        tooltipClassNames={'bg-white rounded-12'}
        open={!!errors.title}
        arrow={false}
        content={errors.title ? <div className='text-red-pure'>{errors.title}</div> : undefined}
        placement={'right'}
      >
        <div
          className={classNames('relative h-12 px-18px py-3 bg-white-snow', {
            'rounded-t-12 rounded-br-12': isFormActive,
            'rounded-12': !isFormActive,
            'border-red-pure border-solid border-[1.5px]': errors.title,
          })}
        >
          <input
            onBlur={(e) => {
              if (e.relatedTarget && e.relatedTarget.closest('button[type="submit"]')) return;
              setFieldValue(name, initialValue);
              setFormActive(false);
            }}
            className='bg-transparent w-full truncate'
            onChange={handleChange}
            name={name}
            type='text'
            value={values.title}
            onFocusCapture={() => {
              setFormActive(true);
            }}
          />

          {isFormActive && (
            <div className='rounded-bl-xl rounded-br-xl flex absolute top-12 left-0 z-999 !opacity-100'>
              <Button
                type='submit'
                className={buttonsClasses}
                color='tertiary'
                icon={() => <CheckMarkWithCircle color={'#3C3C3C'} />}
              />
              <Button
                className={buttonsClasses}
                color='tertiary'
                icon={() => <CloseIconWithCircle color={'#3C3C3C'} />}
                onClick={handleClose}
              />
            </div>
          )}
        </div>
      </TooltipWrapper>
    </form>
  );
};
