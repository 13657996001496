import { RefObject, useCallback, useEffect } from 'react';

export const useOutsideClick = (ref: RefObject<HTMLElement>, callback: () => void, isEnabled = true) => {
  const handleClick = useCallback(
    (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    },
    [callback, ref],
  );

  useEffect(() => {
    if (isEnabled) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      if (isEnabled) {
        document.removeEventListener('click', handleClick);
      }
    };
  }, [isEnabled, handleClick]);
};
