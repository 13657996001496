import { cardColorTheme, platform } from '@va/constants';
import { startUpgradeProcess } from '@va/dashboard/actions/app';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { getWebsite } from '@va/dashboard/selectors/core';
import Button from '@va/deprecated/components/Button';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import { TooltipWrapper } from '@va/ui/tooltips';
import PropTypes from 'prop-types';
import { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import './UpgradeGradientButton.scss';
import * as Config from 'Config';

/** @deprecated */
export class UpgradeGradientButton extends PureComponent {
  getButtonText() {
    if (this.props.isWixClaimed) {
      return this.props.translate('button.visitsLimitReached.upgradeOnWix');
    }
    return this.props.translate('button.visitsLimitReached.upgradeSubscription');
  }

  buttonContent() {
    const { isDisabled, isWix, startUpgradeProcess } = this.props;
    return (
      <Button
        onClick={isDisabled ? null : startUpgradeProcess}
        text={this.getButtonText()}
        colorTheme={isDisabled ? cardColorTheme.GRAY : isWix ? cardColorTheme.PURPLE : cardColorTheme.RED}
        className={'gradient-button'}
      />
    );
  }

  render() {
    const { isDisabled, tooltipData, translate } = this.props;
    return (
      <Fragment>
        {isDisabled ? (
          <TooltipWrapper interactive content={translate(tooltipData)}>
            <div>{this.buttonContent()}</div>
          </TooltipWrapper>
        ) : (
          this.buttonContent()
        )}
      </Fragment>
    );
  }
}

UpgradeGradientButton.propTypes = {
  translate: PropTypes.func.isRequired,
  isWixClaimed: PropTypes.bool.isRequired,
  isWix: PropTypes.bool.isRequired,
  startUpgradeProcess: PropTypes.func.isRequired,
  websiteId: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  tooltipData: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const isWixWebsite = getWebsite(state).platform === platform.WIX;
  const isStandaloneApp = Config.appType === 'standalone';
  return {
    websiteId: getInstanceId(state),
    isWix: Config.appType === 'wix',
    isWixClaimed: isWixWebsite && isStandaloneApp,
  };
};

const mapDispatchToProps = {
  startUpgradeProcess: startUpgradeProcess,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(UpgradeGradientButton));
