import { IconProps } from '.';

export const ForbiddenIcon = ({ className = '', color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.7465 15.3072C12.4536 16.3652 10.801 17 9 17C4.85786 17 1.5 13.6421 1.5 9.5C1.5 7.69905 2.13477 6.04635 3.19279 4.75345L13.7465 15.3072ZM14.8072 14.2465L4.25345 3.69279C5.54635 2.63477 7.19905 2 9 2C13.1421 2 16.5 5.35786 16.5 9.5C16.5 11.301 15.8652 12.9536 14.8072 14.2465ZM18 9.5C18 14.4706 13.9706 18.5 9 18.5C4.02944 18.5 0 14.4706 0 9.5C0 4.52944 4.02944 0.5 9 0.5C13.9706 0.5 18 4.52944 18 9.5Z'
        fill={color ?? '#EA2A0C'}
      />
    </svg>
  );
};
