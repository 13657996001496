import { platform } from '@va/constants';
import { getWebsitePlatform } from '@va/dashboard/selectors/core';
import { isAccountSettingsPageSelected } from '@va/standalone/shared/helpers';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import UpgradeToHigherPackageBadge from '../UpgradeToHigherPackageBadgeContent';
import '../UpgradeToHigherPackageBadgePreHeader/UpgradeToHigherPackageBadgePreHeader.scss';
import { UpgradeToHigherPackageBadgeTextContent } from '../UpgradeToHigherPackageBadgeTextContent';

export const UpgradeToHigherPackageBadgePreHeaderWixClaimed = () => {
  const { pathname } = useLocation();
  const websitePlatform = useSelector(getWebsitePlatform);
  if (websitePlatform !== platform.WIX || isAccountSettingsPageSelected(pathname)) {
    return null;
  }
  return (
    <div className={'upgrade-to-advanced-badge-header order-second'}>
      <UpgradeToHigherPackageBadge textContent={<UpgradeToHigherPackageBadgeTextContent />} />
    </div>
  );
};
