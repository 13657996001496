import { RefObject, useEffect } from 'react';

export const useHashScroll = (elementRef: RefObject<HTMLElement | null>) => {
  useEffect(() => {
    const hash = window.location.hash;
    if (!hash) return;
    const id = hash.slice(1);
    setTimeout(() => {
      if (!elementRef.current) return;
      if (id !== elementRef.current.id) return;
      elementRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }, [elementRef]);
};
