import { IconProps } from '.';

export const ListIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.25 2.625C1.83579 2.625 1.5 2.96079 1.5 3.375C1.5 3.78921 1.83579 4.125 2.25 4.125H15.75C16.1642 4.125 16.5 3.78921 16.5 3.375C16.5 2.96079 16.1642 2.625 15.75 2.625H2.25ZM3.75 7.125C3.75 6.71079 4.08579 6.375 4.5 6.375H13.5C13.9142 6.375 14.25 6.71079 14.25 7.125C14.25 7.53921 13.9142 7.875 13.5 7.875H4.5C4.08579 7.875 3.75 7.53921 3.75 7.125ZM2.25 10.125C1.83579 10.125 1.5 10.4608 1.5 10.875C1.5 11.2892 1.83579 11.625 2.25 11.625H15.75C16.1642 11.625 16.5 11.2892 16.5 10.875C16.5 10.4608 16.1642 10.125 15.75 10.125H2.25ZM4.5 13.875C4.08579 13.875 3.75 14.2108 3.75 14.625C3.75 15.0392 4.08579 15.375 4.5 15.375H13.5C13.9142 15.375 14.25 15.0392 14.25 14.625C14.25 14.2108 13.9142 13.875 13.5 13.875H4.5Z'
        fill={color}
      />
    </svg>
  );
};
