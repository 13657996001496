import { handleAnnouncementModal } from '@va/dashboard/actions/app';
import { RadioButtonIcon } from '@va/icons';
import { getSupportedLanguages, useLocale, useTranslate } from '@va/localization';
import { useConfirmationDialogContext } from '@va/shared/util-confirmation';
import { updateWebsiteLocale } from '@va/standalone/shared/actions';
import { HorizontalDivider, Paragraph, SearchInput, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { Flag } from '@va/util/components';
import { getCountryCodeByLocale, isManuallyTranslatedLanguage } from '@va/util/helpers';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UserMenuButton } from '../userMenu/UserMenuButton';
import { LanguageSelectionConfirmationModal } from './LanguageSelectionConfirmationModal';

type LanguageSelectorProps = {
  onLanguageSelected: () => void;
  onBackClick?: () => void;
  languageListWrapperClassName?: string;
};

export default function LanguageSelector({
  onLanguageSelected,
  onBackClick,
  languageListWrapperClassName,
}: LanguageSelectorProps) {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const { confirm } = useConfirmationDialogContext();

  const supportedLanguages: Array<string> = getSupportedLanguages();
  const [searchValue, setSearchValue] = useState('');
  const { rawLocale, setLocale } = useLocale();

  const updateLocale = useCallback(
    (locale: string) => {
      setLocale(locale);
      dispatch(updateWebsiteLocale(locale));
      dispatch(handleAnnouncementModal(locale));
    },
    [dispatch, setLocale],
  );

  return (
    <>
      <div
        className='group flex rounded-18 items-center p-3 bg-white hover:bg-white-snow cursor-pointer'
        onClick={() => {
          if (onBackClick) onBackClick();
        }}
      >
        {!!onBackClick && <BackButtonIcon />}
        <Paragraph
          size={paragraphSizes.large}
          weight={fontWeights.medium}
          colorClassName='text-gray-code'
          className='ml-5'
        >
          {translate('locale.language')}
        </Paragraph>
      </div>
      <HorizontalDivider />
      <SearchInput className='' value={searchValue} onChange={(value) => setSearchValue(value)} />
      <div
        className={classNames('max-h-40 overflow-y-auto scrollbar scrollbar-thumb mt-3', languageListWrapperClassName)}
      >
        {supportedLanguages.map((language) => {
          const languageName: string = translate(`locale.${language}.name`);
          const isActive = language === rawLocale;
          const isAutoTranslated = !isManuallyTranslatedLanguage(language);

          if (searchValue && !languageName.toLowerCase().includes(searchValue.toLowerCase())) return null;

          return (
            <UserMenuButton
              key={language}
              leftIcon={
                <Flag
                  className='rounded-full h-6 w-6'
                  countryCode={getCountryCodeByLocale(language)}
                  useEnglishLanguageFlag
                  size='1x1'
                />
              }
              text={
                <Paragraph className='pl-3 whitespace-nowrap mr-3' weight={fontWeights.medium}>
                  {languageName}
                  {isAutoTranslated && (
                    <span className='text-gray-dusty font-medium text-15 ml-1'>
                      {translate('all.labels.autoTranslated')}
                    </span>
                  )}
                </Paragraph>
              }
              rightIcon={
                <div className='h-4 w-4 flex items-center justify-center'>
                  <RadioButtonIcon selected={isActive} />
                </div>
              }
              onClick={() => {
                if (isAutoTranslated) {
                  confirm(() => {
                    updateLocale(language);
                    onLanguageSelected();
                  }, LanguageSelectionConfirmationModal);
                } else {
                  updateLocale(language);
                  onLanguageSelected();
                }
              }}
            />
          );
        })}
      </div>
    </>
  );
}

const BackButtonIcon = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9ZM18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM10.3447 3.96967L5.84467 8.46967L5.31434 9L5.84467 9.53033L10.3447 14.0303L11.4053 12.9697L7.43566 9L11.4053 5.03033L10.3447 3.96967Z'
        fill='#969696'
      />
    </svg>
  );
};
