import { apiRequestResponseMsg, defaultSsrSettings } from '@va/constants';
import { createSsrSettings } from '@va/dashboard/actions/api';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { getSsrCodeInstalledFlag, getSsrSettingsSelector, isPremium } from '@va/dashboard/selectors/core';
import { getSupportCenterDomain } from '@va/dashboard/selectors/customization';
import { TrackingCodeStatusCard } from '@va/dashboard/ui/cards';
import { CodeEditorWithCopyBtn } from '@va/dashboard/ui/components';
import { useTrackingCodeVerifier } from '@va/dashboard/util-hooks';
import { Analytics, Checkmark, InfoIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { useFetchData } from '@va/util/hooks';
import classNames from 'classnames';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type SsrOnboardingStep2Props = {
  onSuccess: () => void;
};

const useGetSsrTrackingCode = () => {
  const websiteId = useSelector(getInstanceId);

  const mapperFn = useCallback((response: { ssrTrackingCode: string }) => {
    return response.ssrTrackingCode || '';
  }, []);

  return useFetchData(`/websites/${websiteId}/ssr-tracking-code`, undefined, mapperFn);
};

type CodeStatusType = {
  codeTrackerIcon: JSX.Element;
  codeTrackerIconClass: string;
  statusBtnText: string;
  codeTrackerStatus: string;
  handleCodeTrackerClick: () => void;
};

const SsrOnboardingStep2 = ({ onSuccess }: SsrOnboardingStep2Props) => {
  const dispatch = useDispatch();
  const { data: ssrTrackingCode, isLoading } = useGetSsrTrackingCode();
  const ssrCodeInstalledFlag = useSelector(getSsrCodeInstalledFlag);
  const isPremiumWebsite = useSelector(isPremium);
  const ssrSettings = useSelector(getSsrSettingsSelector);
  const supportCenterDomain = useSelector(getSupportCenterDomain);

  const translate = useTranslate();

  const onTrackingCodeValidated = useCallback(() => {
    if (isPremiumWebsite && ssrSettings?.error?.message === apiRequestResponseMsg.NOT_FOUND_SSR_SETTINGS) {
      dispatch(createSsrSettings(defaultSsrSettings));
    }
    onSuccess();
  }, [dispatch, isPremiumWebsite, onSuccess, ssrSettings?.error?.message]);

  const { verify } = useTrackingCodeVerifier(onTrackingCodeValidated);
  const onVerifyTrackingCode = useCallback(() => {
    verify();
  }, [verify]);

  const codeStatus: CodeStatusType[] = useMemo(
    () => [
      {
        codeTrackerIcon: <Analytics />,
        codeTrackerIconClass: 'bg-gray-charcoal',
        codeTrackerStatus: translate('modal.addWixSsrCode.unInstalled'),
        statusBtnText: translate('modal.addWixSsrCode.verifyInstallation'),
        handleCodeTrackerClick: onVerifyTrackingCode,
      },
      {
        codeTrackerIcon: <Checkmark color={'#fff'} />,
        codeTrackerIconClass: 'bg-green-pure',
        codeTrackerStatus: translate('modal.addWixSsrCode.successInstalled'),
        statusBtnText: translate('modal.addWixSsrCode.closeWindow'),
        handleCodeTrackerClick: () => {
          console.log('on close');
        },
      },
      {
        codeTrackerIcon: <InfoIcon color={'#fff'} />,
        codeTrackerIconClass: 'bg-red-pure',
        codeTrackerStatus: translate('modal.addWixSsrCode.installError'),
        statusBtnText: translate('modal.addWixSsrCode.verifyInstallation'),
        handleCodeTrackerClick: onVerifyTrackingCode,
      },
    ],
    [translate, onVerifyTrackingCode],
  );

  const codeTracking = useMemo(() => {
    if (ssrCodeInstalledFlag === true) {
      return codeStatus[1];
    }
    if (ssrCodeInstalledFlag === false) {
      return codeStatus[2];
    }
    return codeStatus[0];
  }, [ssrCodeInstalledFlag, codeStatus]);

  return (
    <div className='mt-12 max-w-[600px] mx-auto'>
      <div className={classNames('text-15 leading-6 mb-6px tracking-[0.015em] text-left')}>
        {translate('modal.addWixSsrCode.secPageDesc', { supportCenterDomain })}
      </div>
      <CodeEditorWithCopyBtn code={ssrTrackingCode ?? ''} />
      <TrackingCodeStatusCard
        isLoading={isLoading}
        {...codeTracking}
        title={translate('modal.addWixSsrCode.codeTrackingStatus')}
      />
    </div>
  );
};

export default memo(SsrOnboardingStep2);
