export type AnnouncementContentType = {
  image: string;
  headline: string;
  text: string;
  confirmation_text: string;
  button_ok_text: string;
  button_cancel_text: string;
  confirmation_needed: boolean;
  target_url: string;
  // used for redirects to external pages
  target_url_raw: string;
  // used for redirects within the dashboard
  target_url_internal: string;
  internal_t3_link: string | null | undefined;
  internal_t3_link_utm: string | null | undefined;
  voucher_code: null | undefined | string;
  video: {
    url: string;
    poster: string;
  } | null;
  show_newsletter_toggle: boolean;
  show_email_reports_toggle: boolean;
};

type StaticCategoriesType = {
  installDate: string | number;
  numberOfMonthlyVisits: number | string;
  hadFirstVisit: boolean | null | undefined | string;
  hadFirstLogin: boolean | null | undefined | string;
  geolocation: string;
};

export type AnnouncementCategoriesType = StaticCategoriesType & {
  platform: AnnouncementsPlatformType; // possible comma-separated values
  subscriptionType: AnnouncementsSubscriptionType; // possible comma-separated values
  userType: AnnouncementUserType; // possible comma-separated values
  messageType: AnnouncementMessageType; // possible comma-separated values
};

export type AnnouncementTransformedCategoriesType = StaticCategoriesType & {
  platform: AnnouncementsPlatformTypeEnum[];
  subscriptionType: AnnouncementsSubscriptionTypeEnum[];
  userType: AnnouncementUserTypeEnum[];
  messageType: AnnouncementsMessageTypeEnum[];
};

export type DefaultAnnouncementType = {
  id: string;
  categories: AnnouncementCategoriesType;
  content: AnnouncementContentType;
};

export type AnnouncementType = {
  id: string;
  categories: AnnouncementTransformedCategoriesType;
  content: AnnouncementContentType;
  isAccountBased?: boolean;
  forceDisplay?: boolean;
};

export type AnnouncementsSubscriptionType = AnnouncementsSubscriptionTypeEnum | string;
export type AnnouncementsPlatformType = AnnouncementsPlatformTypeEnum | string;
export type AnnouncementMessageType = AnnouncementsMessageTypeEnum | undefined | string;
export type AnnouncementUserType = AnnouncementUserTypeEnum | string;

export enum AnnouncementUserTypeEnum {
  all = 'all',
  owner = 'owner',
  contributor = 'contributor',
  internal = 'internal',
  awl = 'awl',
  awl_owner = 'awl_owner',
  awl_contributor = 'awl_contributor',
}

export enum AnnouncementsMessageTypeEnum {
  firstlogin = 'firstlogin',
  standard = 'standard',
}

export enum AnnouncementsSubscriptionTypeEnum {
  all = 'all',
  free = 'free',
  premium = 'premium',
}

export enum AnnouncementsPlatformTypeEnum {
  all = 'all',
  wix = 'wix',
  standalone = 'standalone',
}
