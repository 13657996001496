import { FilterConditionsObject } from '@va/types/filter-templates';
import { Filter } from '@va/types/filters';
import { PropsWithChildren, createContext, useCallback, useContext } from 'react';

type getCustomValueFunc = <T>(valueName: keyof CustomizationValues, defaultValue: T) => T;

type CustomizationValues = {
  initialTitle: string;
  initialAppliedFilters: Filter[];
  initialFilterConditions: FilterConditionsObject;
  initialSelectedSegmentsIds: string[];
  initialFiltersExpanded: boolean;
  initialSegmentsExpanded: boolean;

  disabledFiltersMessage: string;
  disabledSegmentsMessage: string;

  filterTemplatesAvailable?: boolean;
  showLinks: boolean; // TODO Make use of this customization option to hide links that redirect to different modules for report blocks

  onFilterChange?: Function;
  sessionStoragePersistenceForFilters?: boolean;
  sessionStoragePersistenceForSegments?: boolean;
};

type CustomizationContext = {
  getCustomValue: getCustomValueFunc;
};

const CustomizationContext = createContext<CustomizationContext>({
  getCustomValue: (_, defaultValue) => defaultValue,
});

/**
 * This is and alternative to providing customization options through props
 * Particulary usefull for deep nested components, in order to avoid prop drilling
 */
export const CustomizationProvider = ({ children, ...rest }: PropsWithChildren<Partial<CustomizationValues>>) => {
  const getCustomValue = useCallback(
    (name: keyof CustomizationValues, defaultValue: any) => {
      const val = rest[name];

      if (val === undefined) {
        return defaultValue;
      }

      return val;
    },
    [rest],
  );

  return <CustomizationContext.Provider value={{ getCustomValue }}>{children}</CustomizationContext.Provider>;
};

export const useCustomizationContext = () => useContext(CustomizationContext);
