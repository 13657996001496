import { getInstanceId } from '@va/dashboard/selectors/app';
import { getGlobalFilter, getStepFilter } from '@va/dashboard/selectors/ui';
import { useSelector } from 'react-redux';

export const useCurrentPeriodFilter = (keepOffset = true, convertToIso = true) => {
  const { from, until } = useSelector(getGlobalFilter);
  const { period: unit } = useSelector(getStepFilter);
  const websiteId = useSelector(getInstanceId);

  return {
    from: convertToIso ? from.toISOString(keepOffset) : from,
    until: convertToIso ? until.toISOString(keepOffset) : until,
    unit,
    websiteId,
  };
};
