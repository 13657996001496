import { useSelector } from 'react-redux';
import { getWebsite } from '@va/dashboard/selectors/core';
import { getAccountInformation } from '@va/dashboard/selectors/api';
import { useMemo } from 'react';
import { getInitialsFromUserInfo, isStandaloneApp } from '@va/util/helpers';

export function useUserInformation() {
  const websiteInfo = useSelector(getWebsite);
  const accountInfo = useSelector(getAccountInformation);
  const userInfo = useMemo(() => (isStandaloneApp() ? accountInfo : websiteInfo), [accountInfo, websiteInfo]);
  const userInitials = useMemo(() => getInitialsFromUserInfo(userInfo), [userInfo]);

  return { userInfo, userInitials };
}
