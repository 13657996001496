import { useInnerOnChange } from '@va/util/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';

const DebouncedTextarea = ({ onChange, value, delay = 300, className, ...props }) => {
  const { innerOnChange, innerValue } = useInnerOnChange(onChange, delay, value);

  return (
    <TextareaAutosize
      className={classNames('resize-none', className)}
      value={innerValue}
      onChange={innerOnChange}
      {...props}
    />
  );
};

DebouncedTextarea.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
};

export default DebouncedTextarea;
