import { UNTRANSLATED_LOCALE } from '@va/constants';
import { inRange } from 'lodash';

export function addNumberSeparator(input: any, locale = 'en', options: Intl.NumberFormatOptions = {}) {
  if (locale === UNTRANSLATED_LOCALE) {
    locale = 'en';
  }

  let number = parseFloat(input);

  if (isNaN(input) || isNaN(number) || (typeof input === 'string' && input.includes('%'))) return input;

  if (options?.style === 'percent') {
    number = number / 100;
    options = { useGrouping: true, maximumSignificantDigits: 3, ...options };
  }

  if (options?.notation === 'compact' && options?.compactDisplay === 'short') {
    if (locale === 'de' && number >= 1000 && number <= 10000) return new Intl.NumberFormat(locale).format(number);
    return new Intl.NumberFormat(locale, {
      useGrouping: true,
      maximumSignificantDigits: 3,
      ...options,
    }).format(number);
  }
  return new Intl.NumberFormat(locale, { useGrouping: true, ...options }).format(number);
}

export const getTwoDigitFormat = (digits: number) => (digits >= 10 ? digits : '0' + digits);

/** @deprecated inRange from lodash directly */
export function isNumberInRange(number: number, min: number, max: number) {
  return inRange(number, min, max);
}

export function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function calculateDiscount(fromNumber: string, number: string) {
  return Math.floor((1 - parseFloat(number) / parseFloat(fromNumber)) * 100);
}

// TODO Make sure there is only one calculate percentage method
export const calculatePercentFromTotal = (
  val: number,
  total: number,
  options?: { round?: boolean; precision?: number },
) => {
  if (!val || !total) return 0;

  const { round = true } = options ?? {};

  const rawValue = (val * 100) / total;

  if (options?.precision) return Number(rawValue.toFixed(options.precision));

  if (round) return Math.round(rawValue);

  return rawValue;
};

// TODO Percentage function; ensure uniqueness
export const calculateValueFromPercent = (percent: number, total: number) => {
  if (percent && total) {
    return Math.round((percent * total) / 100);
  }
  return 0;
};

export function getNumberWithArithmeticOperator(number: number) {
  if (number === 0) return number;
  if (number > 0) return `+${number}`;
  return `-${Math.abs(number)}`;
}

export const computePercentage = (amount: number, total: number) => ((100 * amount) / total).toFixed(1);

/** @deprecated use getPercentageChange */
export function getPercentage(num1: number, num2: number) {
  const diff = num1 - num2;
  if (num2 === 0 && num1 === 0) return 0;
  if (num2 === 0 && num1 > 0) return 100;
  return Math.round((diff / num2) * 100);
}

export const getPercentageChange = (previousValue = 0, currentValue = 0) => {
  if (currentValue === 0 || previousValue === 0) return 0;
  return ((currentValue - previousValue) / Math.abs(previousValue)) * 100;
};

export const formatCurrencyNumber = (val: number, locale: string, currency: string) => {
  try {
    return addNumberSeparator(val, locale, { style: 'currency', currency: currency });
  } catch (error) {
    console.error(error);
    return addNumberSeparator(val, locale);
  }
};

export const getNumberPassedThreshold = (number: number, thresholds: number[]) => {
  const sortedThresholds = thresholds.sort((a, b) => b - a);

  for (const threshold of sortedThresholds) {
    if (number >= threshold) {
      return threshold as (typeof thresholds)[number];
    }
  }

  return 0;
};
