import React from 'react';
import { IconProps } from '.';
export const ArrowRightIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg className={className} width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1.11611 1L7 7L1 13' stroke={color} strokeWidth='1.5' />
    </svg>
  );
};

