import { IconProps } from '.';

export const SegmentIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.5'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 9C18 9.53332 17.9536 10.0558 17.8646 10.5636L16.3871 10.3048C16.4612 9.88207 16.5 9.44618 16.5 9C16.5 8.55382 16.4612 8.11793 16.3871 7.69518L17.8646 7.43635C17.9536 7.94419 18 8.46668 18 9ZM0 9C0 9.53332 0.0463888 10.0558 0.135352 10.5636L1.61285 10.3048C1.5388 9.88207 1.5 9.44618 1.5 9C1.5 8.55382 1.5388 8.11793 1.61285 7.69518L0.135351 7.43635C0.0463888 7.94419 0 8.46668 0 9ZM1.94997 6.43411L0.540469 5.92097C0.903057 4.92501 1.43652 4.01115 2.10539 3.21484L3.25397 4.1796C2.69588 4.84401 2.25163 5.60551 1.94997 6.43411ZM4.1796 3.25397L3.21484 2.10539C4.01115 1.43652 4.92501 0.903057 5.92097 0.540469L6.43411 1.94997C5.60551 2.25163 4.84401 2.69588 4.1796 3.25397ZM7.69518 1.61285L7.43635 0.135352C7.94419 0.0463888 8.46668 0 9 0C9.53332 0 10.0558 0.0463888 10.5636 0.135351L10.3048 1.61285C9.88207 1.5388 9.44618 1.5 9 1.5C8.55382 1.5 8.11793 1.5388 7.69518 1.61285ZM11.5659 1.94997L12.079 0.540469C13.075 0.903057 13.9889 1.43652 14.7852 2.10539L13.8204 3.25397C13.156 2.69588 12.3945 2.25163 11.5659 1.94997ZM14.746 4.1796L15.8946 3.21484C16.5635 4.01115 17.0969 4.92501 17.4595 5.92097L16.05 6.43411C15.7484 5.60551 15.3041 4.84401 14.746 4.1796Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0004 9C12.0004 10.6569 10.6572 12 9.00037 12C7.34351 12 6.00037 10.6569 6.00037 9C6.00037 7.34315 7.34351 6 9.00037 6C10.6572 6 12.0004 7.34315 12.0004 9ZM11.7326 12.5759C12.8073 11.7535 13.5004 10.4577 13.5004 9C13.5004 6.51472 11.4856 4.5 9.00037 4.5C6.51508 4.5 4.50037 6.51472 4.50037 9C4.50037 10.4577 5.19346 11.7535 6.26808 12.5758C4.84702 13.2056 3.68399 14.3116 2.98145 15.6914C4.57595 17.1266 6.68614 18 9.00032 18C11.3145 18 13.4247 17.1266 15.0192 15.6914C14.6973 15.0593 14.2788 14.4846 13.7831 13.9868C13.1968 13.3982 12.5026 12.9171 11.7326 12.5759ZM9.00032 16.5C7.53067 16.5 6.15975 16.0773 5.00251 15.3468C5.96548 14.2167 7.39918 13.5 9.00029 13.5C10.6014 13.5 12.0351 14.2167 12.9981 15.3468C11.8409 16.0773 10.4699 16.5 9.00032 16.5Z'
        fill={color}
      />
    </svg>
  );
};
