import { get, patch, post } from '@va/http-client';

export const getVideoTrackingCode = (websiteId) => {
  const url = `/websites/${websiteId}/ssr-tracking-code`;
  return get(url, {});
};

export const getRecordingsCount = (websiteId) => {
  const url = `/websites/${websiteId}/recordings/count`;
  return get(url, {});
};

export const createSsrSettings = (websiteId, data) => {
  const url = `/websites/${websiteId}/ssr-settings`;
  return post(url, {}, data);
};

export const getSsrSettings = (websiteId) => {
  const url = `/websites/${websiteId}/ssr-settings`;
  return get(url, {});
};

export const updateSsrSettings = (websiteId, ssrSettingsId, data) => {
  const url = `/websites/${websiteId}/ssr-settings/${ssrSettingsId}`;
  return patch(url, {}, data);
};
