import { createColumnHelper } from '@tanstack/react-table';
import { WebsiteManagementGlobe } from '@va/icons';
import { useTranslate } from '@va/localization';
import { setActiveWebsite } from '@va/standalone/shared/actions';
import { HeaderCellV8 } from '@va/ui/components/data-table';
import { ListCardItemWithIcon, ParagraphWithTooltip, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { getFullNameFromUserInfo, getUrlWithoutProtocolAndTrailingSlash, getWellFormedUrl } from '@va/util/helpers';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { WebsiteType } from '../agency-white-label-apiClient';
import ContributorsList from '../components/ContributorsList';
import PlanInfoCell from '../components/PlanInfoCell';
import TrackingCodeStatus from '../components/TrackingCodeStatus';
import UserRole from '../components/UserRole';
import WhiteLabelStatusCell from '../components/WhiteLabelStatusCell';
import { getFormattedContributorLists } from '../utils/contributorsFormatter';

const columnHelper = createColumnHelper<WebsiteType>();

export const useManageWhiteLabelColumns = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const translate = useTranslate();

  const columns = useMemo(
    () => [
      columnHelper.accessor('url', {
        meta: {
          cellClassName: '!justify-start',
        },
        header: (context) => (
          <HeaderCellV8 context={context} text={translate('agencyWhiteLabel.table.header.website')} />
        ),
        cell: ({ row }) => {
          const { contributors, contributorRequests, url } = row.original;

          const totalContributorsCount = contributors.length + contributorRequests.length + 1; // + 1 is for the owner

          return (
            <ListCardItemWithIcon
              icon={
                <div className='h-12 w-12 rounded-full bg-white-snow group-hover:bg-white flex items-center justify-center'>
                  <WebsiteManagementGlobe />
                </div>
              }
              topText={
                <ParagraphWithTooltip
                  colorClassName='text-left text-gray-charcoal hover:text-primary hover:border-b-1 border-primary'
                  weight={fontWeights.medium}
                  className='truncate'
                >
                  <a href={getWellFormedUrl(url)} target='_blank' rel='noreferrer'>
                    {getUrlWithoutProtocolAndTrailingSlash(url)}
                  </a>
                </ParagraphWithTooltip>
              }
              bottomNode={
                <ParagraphWithTooltip
                  weight={fontWeights.medium}
                  size={paragraphSizes.tiny}
                  colorClassName='text-gray-devilSolid'
                >
                  {translate('manageWhiteLabel.table.contributorsCount', {
                    count: totalContributorsCount,
                  })}
                </ParagraphWithTooltip>
              }
              wrapperClassName={'!gap-15px text-left'}
            />
          );
        },
        enableSorting: true,
      }),
      columnHelper.accessor('trackingCodeInstalled', {
        header: (context) => (
          <HeaderCellV8 context={context} text={translate('agencyWhiteLabel.table.header.trackingStatus')} />
        ),
        cell: ({ getValue }) => {
          return <TrackingCodeStatus isInstalled={getValue()} />;
        },
      }),
      columnHelper.group({
        id: 'whiteLabel',
        header: (context) => (
          <HeaderCellV8 context={context} text={translate('agencyWhiteLabel.table.header.whiteLabelStatus')} />
        ),
        cell: ({ row }) => {
          const { id: websiteId, platform, types, whiteLabel } = row.original;
          return (
            <WhiteLabelStatusCell
              whiteLabelEnabled={whiteLabel}
              websiteId={websiteId}
              platform={platform}
              userRole={types[0]}
            />
          );
        },
      }),
      columnHelper.group({
        id: 'subscription',
        meta: {
          cellClassName: '!justify-start',
        },
        header: (context) => (
          <HeaderCellV8 context={context} text={translate('agencyWhiteLabel.table.header.pricingPlan')} />
        ),
        cell: ({ row }) => {
          const { subscription } = row.original;
          return <PlanInfoCell subscriptionInfo={subscription} websiteInfo={row.original} showPlanFrequency={false} />;
        },
      }),
      columnHelper.group({
        id: 'contributors',
        minSize: 360,
        meta: {
          cellClassName: '!justify-start !min-w-min',
        },
        header: (context) => (
          <HeaderCellV8 context={context} text={translate('agencyWhiteLabel.table.header.contributors')} />
        ),
        cell: ({ row }) => {
          const {
            id: websiteId,
            owner: { firstName, lastName, email },
            types,
            contributors,
            contributorRequests,
          } = row.original;

          const activeContributorsList = getFormattedContributorLists(contributors);
          const pendingContributorsCount = contributorRequests.length;
          const websiteOwner = {
            fullName: getFullNameFromUserInfo({ firstName, lastName, email }),
          };

          return (
            <ContributorsList
              activeContributors={[websiteOwner, ...activeContributorsList]}
              pendingContributorsCount={pendingContributorsCount}
              onClick={() => {
                history.push(`/website/${websiteId}/settings/manage-contributors`);
                dispatch(setActiveWebsite(websiteId));
              }}
              userRole={types[0]}
            />
          );
        },
      }),
      columnHelper.accessor('types', {
        header: (context) => (
          <HeaderCellV8 context={context} text={translate('agencyWhiteLabel.table.header.yourRole')} />
        ),
        cell: ({ getValue }) => {
          return <UserRole role={getValue()[0]} />;
        },
      }),
    ],
    [dispatch, history, translate],
  );

  return columns;
};
