export function CheckMarkWithFilledBackground({
  bgColor = '#0B9952',
  color = '#FFF',
  ...props
}: JSX.IntrinsicElements['svg'] & { bgColor?: string }) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='12' cy='12' r='12' fill={bgColor} />
      <path d='M7.5 12L10.5 15L16.5 9' stroke={color} strokeWidth='2' />
    </svg>
  );
}

export function UnCheckMarkIcon({ color = '#DDDDDD', ...props }: JSX.IntrinsicElements['svg']) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='12' cy='12' r='11' stroke={color} strokeWidth='2' />
    </svg>
  );
}
