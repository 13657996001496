import { get, patch, post, put } from '@va/http-client';
import { useAsyncFunction } from '@va/util/hooks';

// === Paddle ===

export const getUpdatePaymentMethodUrl = (websiteId, subscriptionId) => {
  const url = `/websites/${websiteId}/subscriptions/${subscriptionId}/update-payment-url`;
  return get(url, {});
};

export const updateSubscription = (data, websiteId, subscriptionId) => {
  const url = `/websites/${websiteId}/subscriptions/${subscriptionId}`;
  return patch(url, {}, data);
};

export const getAgencyCustomer = (userId) => {
  const url = `/users/${userId}/customers`;
  return get(url, {});
};

export const createAgencyCustomer = (userId, agencyData) => {
  const url = `/users/${userId}/customers`;
  return post(url, {}, agencyData);
};

export const updateAgencyCustomer = (userId, customerId, agencyData) => {
  const url = `/users/${userId}/customers/${customerId}`;
  return put(url, {}, agencyData);
};

// === Common ===

export const getPaymentMethod = (websiteId, subscriptionId) => {
  const url = `/websites/${websiteId}/subscriptions/${subscriptionId}/payment-method`;
  return get(url, {});
};

export const getPaymentPlans = (websiteId, data) => {
  const url = `/websites/${websiteId}/payment-plans`;
  return get(url, data ? data : {});
};

export const getAllSubscriptions = (websiteId) => {
  const url = `/websites/${websiteId}/subscriptions`;
  return get(url, {});
};

export const cancelSubscription = (data, websiteId, subscriptionId) => {
  const url = `/websites/${websiteId}/subscriptions/${subscriptionId}/billing-cancel`;
  return post(url, {}, data);
};

export const cancelSubscriptionImmediate = (websiteId) => {
  const url = `/websites/${websiteId}/subscriptions/cancel`;
  return post(url, {}, {});
};

export const useCancelSubcriptionImmediate = () => {
  return useAsyncFunction(cancelSubscriptionImmediate);
};

// === Braintree ===

export const getPaymentAuthToken = () => {
  const url = `/braintree/token`;
  return get(url, {});
};

export const getCustomerPaymentAuthToken = (websiteId, customerId) => {
  const url = `/websites/${websiteId}/customers/${customerId}/token`;
  return get(url, {});
};

export const getCustomer = (websiteId) => {
  const url = `/websites/${websiteId}/customers`;
  return get(url, {});
};

export const getComputedSubscriptionPrice = (websiteId, data) => {
  const url = `/websites/${websiteId}/subscriptions/compute-update-price`;
  return get(url, data ? data : {});
};

export const validateVat = (data) => {
  const url = `/vat/validate`;
  return get(url, data ? data : {});
};

export const createCustomer = (data, websiteId) => {
  const url = `/websites/${websiteId}/customers`;
  return post(url, {}, data);
};

export const createPaymentMethod = (data, websiteId, customerId) => {
  const url = `/websites/${websiteId}/customers/${customerId}/payment-methods`;
  return post(url, {}, data);
};

export const createSubscription = (data, websiteId) => {
  const url = `/websites/${websiteId}/subscriptions`;
  return post(url, {}, data);
};

export const reactivateSubscription = (data, websiteId, subscriptionId) => {
  const url = `/websites/${websiteId}/subscriptions/${subscriptionId}/billing-reactivate`;
  return post(url, {}, data);
};

export const updateCustomer = (data, websiteId, customerId) => {
  const url = `/websites/${websiteId}/customers/${customerId}`;
  return put(url, {}, data);
};

export const generateInvoice = (data) => {
  const url = `/invoices/${data.invoiceKey}`;
  return get(url, {});
};

export const validatePromoCode = (data, websiteId) => {
  const url = `/websites/${websiteId}/subscriptions/validate-voucher`;
  return get(url, data);
};
