import { IconProps } from './index';
export const ActiveToggleIcon = ({ className = '', color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 1.5H12C14.4853 1.5 16.5 3.51472 16.5 6C16.5 8.48528 14.4853 10.5 12 10.5H6C3.51472 10.5 1.5 8.48528 1.5 6C1.5 3.51472 3.51472 1.5 6 1.5ZM0 6C0 2.68629 2.68629 0 6 0H12C15.3137 0 18 2.68629 18 6C18 9.31371 15.3137 12 12 12H6C2.68629 12 0 9.31371 0 6ZM15 6C15 7.65685 13.6569 9 12 9C10.3431 9 9 7.65685 9 6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6Z'
        fill={color ?? '#696969'}
      />
    </svg>
  );
};
