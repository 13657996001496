import { IconProps } from '.';

export function LoaderIcon({ color, className }: IconProps) {
  const colorValue = color ? color : window.PRIMARY_COLOR;
  return (
    <svg
      className={className}
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z'
        fill={colorValue}
        fillOpacity='0.066666'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M31.5 24C31.5 28.1421 28.1421 31.5 24 31.5C19.8579 31.5 16.5 28.1421 16.5 24H15C15 28.9706 19.0294 33 24 33C28.9706 33 33 28.9706 33 24C33 19.0294 28.9706 15 24 15V16.5C28.1421 16.5 31.5 19.8579 31.5 24ZM24 29.25C26.8995 29.25 29.25 26.8995 29.25 24C29.25 21.1005 26.8995 18.75 24 18.75V20.25C26.0711 20.25 27.75 21.9289 27.75 24C27.75 26.0711 26.0711 27.75 24 27.75V29.25ZM25.5 24C25.5 24.8284 24.8284 25.5 24 25.5C23.1716 25.5 22.5 24.8284 22.5 24C22.5 23.1716 23.1716 22.5 24 22.5C24.8284 22.5 25.5 23.1716 25.5 24Z'
        fill={colorValue}
      />
    </svg>
  );
}
