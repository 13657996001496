import PropTypes from 'prop-types';
import { BLACK_COLOR, WHITE_COLOR } from './constants';

export function Checkbox(props) {
  const { active, color, className, ...otherProps } = props;
  const fillColor = color || '#009688';

  const checked = (
    <svg
      width='20px'
      height='20px'
      viewBox='0 0 20 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      {...otherProps}
    >
      <g id='symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <rect stroke={fillColor} x='0.5' y='0.5' width='19' height='19' rx='4' />
        <rect stroke={fillColor} fill={fillColor} strokeWidth='1' x='3.5' y='3.5' width='13' height='13' rx='4' />
      </g>
    </svg>
  );

  const unchecked = (
    <svg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' className={className} {...otherProps}>
      <g id='symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='icon-email-report-not-selected' stroke={className === 'required' ? '#ff5252' : color || '#13232A'}>
          <rect id='Rectangle-7' x='0.5' y='0.5' width='19' height='19' rx='4' />
        </g>
      </g>
    </svg>
  );

  return active ? checked : unchecked;
}

/** @deprecated */
export function CheckboxPremium(props) {
  const colors = props.colors || { color1: '#7464BB', color2: '#8F7CED' };
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 20 20'
      xmlSpace='preserve'
      {...props}
    >
      <path
        fill={colors.color1}
        d='M15.5,20h-11C2,20,0,18,0,15.5v-11C0,2,2,0,4.5,0h11C18,0,20,2,20,4.5v11C20,18,18,20,15.5,20z'
      />
      <path
        fill={colors.color2}
        d='M4.5,1C2.6,1,1,2.6,1,4.5v11C1,17.4,2.6,19,4.5,19h11c1.9,0,3.5-1.6,3.5-3.5v-11C19,2.6,17.4,1,15.5,1H4.5z'
      />
      <path
        fill='#FFFFFF'
        d='M12.8,10.2v-1c0.9-0.1,1.6-0.9,1.6-1.9c0-1-0.7-1.8-1.6-1.9V4H7.4v1.4C6.5,5.6,5.9,6.4,5.9,7.3
      c0,1,0.7,1.8,1.6,1.9v1c0,1.6,1.2,2.8,2.7,2.8H9.9v1H8.1v0.3V15h-1v1h6v-1h-1v-1h-1h-0.8v-1h-0.2C11.6,13,12.8,11.7,12.8,10.2z
       M12.8,5.9C13.5,6,14,6.6,14,7.3c0,0.7-0.5,1.3-1.1,1.4V5.9z M7.4,8.7C6.8,8.6,6.3,8,6.3,7.3c0-0.7,0.5-1.3,1.1-1.4V8.7z M10.1,12.5
      c-1.2,0-2.2-1.1-2.2-2.4V4.5h4.5v5.7C12.4,11.5,11.4,12.5,10.1,12.5z'
      />
    </svg>
  );
}

Checkbox.propTypes = {
  active: PropTypes.bool.isRequired,
  className: PropTypes.string,
  colors: PropTypes.object,
};

export function Email(props) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 25 17'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <defs>
        <polygon id='path-1' points='24.858 15.9995 24.858 0.353 0 0.353 0 15.9995 24.858 15.9995' />
      </defs>
      <g fillRule='evenodd'>
        <path d='M21.6487,4.704 C21.6487,4.526 21.5887,4.347 21.4707,4.228 C21.3517,4.11 21.1727,4.03 20.9757,4.03 C20.7967,4.03 20.6387,4.09 20.5197,4.208 L13.1317,11.161 C12.9337,11.359 12.6767,11.458 12.4187,11.458 C12.1617,11.458 11.9237,11.359 11.7057,11.161 L4.3577,4.208 L4.3177,4.189 C4.1987,4.11 4.0407,4.05 3.9017,4.05 C3.6047,4.05 3.3277,4.248 3.2487,4.486 C3.1697,4.724 3.2487,5.021 3.4267,5.179 L7.4477,8.962 L3.4657,12.448 C3.3477,12.567 3.2487,12.725 3.2487,12.904 C3.2287,13.082 3.2877,13.261 3.4067,13.379 C3.5257,13.517 3.7237,13.598 3.9217,13.598 C4.0807,13.598 4.2387,13.538 4.3577,13.438 L8.4377,9.853 L10.8347,12.111 C11.2707,12.527 11.8447,12.745 12.4587,12.745 C13.0727,12.745 13.6467,12.508 14.0827,12.111 L16.4797,9.853 L20.5797,13.438 C20.6987,13.538 20.8567,13.598 21.0147,13.598 C21.2127,13.598 21.4117,13.517 21.5297,13.379 C21.6487,13.261 21.6887,13.082 21.6887,12.904 C21.6687,12.725 21.5887,12.548 21.4707,12.448 L17.4297,8.942 L21.4507,5.16 C21.5697,5.06 21.6287,4.882 21.6487,4.704' />
        <g id='Group-5' transform='translate(0 .647)'>
          <mask id='mask-2' fill='#fff'>
            <use xlinkHref='#path-1' />
          </mask>
          <path
            d='M23.6888,14.4355 C23.6888,14.6535 23.5108,14.8315 23.2928,14.8315 L1.5648,14.8315 C1.3468,14.8315 1.1688,14.6535 1.1688,14.4355 L1.1688,1.9175 C1.1688,1.6995 1.3468,1.5205 1.5648,1.5205 L23.2928,1.5205 C23.5108,1.5205 23.6888,1.6995 23.6888,1.9175 L23.6888,14.4355 Z M23.2928,0.3525 L1.5648,0.3525 C0.6928,0.3525 -0.0002,1.0655 -0.0002,1.9175 L-0.0002,14.4355 C-0.0002,15.3065 0.7128,15.9995 1.5648,15.9995 L23.2928,15.9995 C24.1648,15.9995 24.8578,15.2875 24.8578,14.4355 L24.8578,1.9175 C24.8768,1.0455 24.1648,0.3525 23.2928,0.3525 L23.2928,0.3525 Z'
            mask='url(mask-2)'
          />
        </g>
      </g>
    </svg>
  );
}

export function BusinessName(props) {
  return (
    <svg
      width='26px'
      height='20px'
      viewBox='0 0 26 20'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <g id='symbols' stroke='none' strokeWidth='1' fillRule='evenodd'>
        <g id='field-/business-name-/normal' transform='translate(-10.000000, -5.000000)'>
          <g id='icon/business-name' transform='translate(10.000000, 5.000000)'>
            <path d='M0,20 L26,20 L26,2 L0,2 L0,20 Z M1,19 L25,19 L25,3 L1,3 L1,19 Z' id='Fill-1' />
            <polygon
              id='Fill-2'
              points='8.0001 0.0002 8.0001 2.5002 9.0001 2.5002 9.0001 1.0002 17.0001 1.0002 17.0001 2.5002 18.0001 2.5002 18.0001 0.0002'
            />
            <polygon id='Fill-3' points='3 5 4 5 4 2.501 3 2.501' />
            <polygon id='Fill-4' points='22 5 23.001 5 23.001 2.501 22 2.501' />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function Timezone(props) {
  return (
    <svg
      width='16px'
      height='22px'
      viewBox='0 0 16 22'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <g id='modals' stroke='none' strokeWidth='1' fillRule='evenodd'>
        <g id='modal/FIRST-SCREEN/fields-required' transform='translate(-368.000000, -619.000000)'>
          <g id='location' transform='translate(353.000000, 619.000000)'>
            <g id='Page-1' transform='translate(15.000000, 0.000000)'>
              <path
                d='M7.8403,1 C4.0683,1 1.0003,4.068 1.0003,7.84 C1.0003,9.693 1.7293,11.43 3.0543,12.728 L7.8353,17.518 L12.6283,12.724 C13.9533,11.425 14.6803,9.691 14.6803,7.84 C14.6803,4.068 11.6123,1 7.8403,1 Z M7.8343,18.932 L2.3503,13.438 C0.8363,11.954 0.0003,9.965 0.0003,7.84 C0.0003,3.517 3.5173,0 7.8403,0 C12.1633,0 15.6803,3.517 15.6803,7.84 C15.6803,9.961 14.8473,11.948 13.3333,13.435 L7.8343,18.932 Z'
                id='Fill-1'
              />
              <polygon id='Fill-3' points='2.655 21.5566 13.025 21.5566 13.025 20.5566 2.655 20.5566' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function ShowPassword(props) {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 20 20'
      xmlSpace='preserve'
      {...props}
    >
      <g>
        <path
          fill={props.color ?? '#12232A'}
          d='M10,6.3C8,6.3,6.3,8,6.3,10c0,2,1.6,3.7,3.7,3.7c2,0,3.7-1.6,3.7-3.7C13.7,8,12,6.3,10,6.3z M10,12.5
        c-1.4,0-2.5-1.1-2.5-2.5S8.6,7.5,10,7.5s2.5,1.1,2.5,2.5S11.4,12.5,10,12.5z'
        />
        <path
          fill={props.color ?? '#12232A'}
          d='M15,5c-1.3-1.3-3.1-2.1-5-2.1C8.1,2.9,6.3,3.7,5,5l-5,5l5,5c1.3,1.3,3.1,2.1,5,2.1c1.9,0,3.7-0.7,5-2.1l5-5
        L15,5z M10,15.9c-1.6,0-3.1-0.6-4.2-1.7L1.6,10l4.2-4.2C6.9,4.7,8.4,4.1,10,4.1c1.6,0,3.1,0.6,4.2,1.7l4.2,4.2l-4.2,4.2
        C13.1,15.3,11.6,15.9,10,15.9z'
        />
      </g>
    </svg>
  );
}

export function HidePassword(props) {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 20 20'
      xmlSpace='preserve'
      {...props}
    >
      <g>
        <path
          fill='#12232A'
          d='M11.5,11.7l0.6,1l0.2-0.2c0.7-0.6,1.1-1.6,1.1-2.6c0-2-1.6-3.6-3.6-3.6c-0.4,0-0.9,0.1-1,0.2L8.6,6.6l0.6,0.9
        h0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.2,0c1.4,0,2.5,1.1,2.5,2.5c0,0.6-0.3,1.2-0.7,1.7L11.5,11.7z'
        />
        <path
          fill='#12232A'
          d='M3.8,1.6l1.7,2.9C5.2,4.7,5.1,4.8,4.9,4.9l-5,5l5,5C6.3,16.2,8,17,9.9,17c0.8,0,1.7-0.2,2.5-0.5l1.7,3L15,19
        L4.7,1.1L3.8,1.6z M9.9,15.8c-1.5,0-3-0.6-4.2-1.8L1.6,9.9l4.2-4.2C5.8,5.7,5.9,5.6,6,5.5c0,0,0,0,0,0l1.2,2C6.6,8.2,6.3,9,6.3,9.9
        c0,2,1.6,3.6,3.6,3.6c0.2,0,0.5,0,0.7-0.1l1.2,2.1C11.1,15.7,10.5,15.8,9.9,15.8z M9.9,12.5c-1.4,0-2.5-1.1-2.5-2.5
        c0-0.5,0.2-0.9,0.4-1.3l2.2,3.7C10,12.4,9.9,12.5,9.9,12.5z'
        />
        <path
          fill='#12232A'
          d='M7.7,4.5c0.7-0.3,1.6-0.4,2.2-0.4c1.5,0,3,0.6,4.2,1.8l4.2,4.1L14,14.1c-0.2,0.2-0.4,0.4-0.5,0.4l-0.2,0.1
        l0.7,1l0.2-0.1c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.4-0.3l5-5l-5-5c-1.5-1.3-3.3-2-5.1-2c-0.8,0-1.8,0.2-2.8,0.5L6.8,3.5
        l0.7,1L7.7,4.5z'
        />
      </g>
    </svg>
  );
}

export function VoucherIcon(props) {
  return (
    <svg
      width='25px'
      height='25px'
      viewBox='0 0 25 25'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <g id='desktop-appsumo' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='03-appsumo-landing-page-after-register' transform='translate(-427.000000, -2232.000000)' fill='#13232A'>
          <g id='register' transform='translate(170.000000, 1981.000000)'>
            <g id='field-email-normal' transform='translate(247.000000, 251.000000)'>
              <g id='icon/voucher-code/normal' transform='translate(10.000000, 0.000000)'>
                <g id='Group'>
                  <polygon id='Fill-1' points='24.1461 0 15.3961 8.75 16.1041 9.457 24.8531 0.707'></polygon>
                  <path
                    d='M13.5,3.3534 L0,16.8534 L8,24.8534 L21.5,11.3534 L21.5,3.3534 L13.5,3.3534 Z M1.414,16.8534 L13.914,4.3534 L20.5,4.3534 L20.5,10.9394 L8,23.4394 L1.414,16.8534 Z'
                    id='Fill-2'
                  ></path>
                  <path
                    d='M16,5.8535 C14.343,5.8535 13,7.1965 13,8.8535 C13,10.5105 14.343,11.8535 16,11.8535 C17.657,11.8535 19,10.5105 19,8.8535 C19,7.1965 17.657,5.8535 16,5.8535 M16,6.8535 C17.103,6.8535 18,7.7505 18,8.8535 C18,9.9565 17.103,10.8535 16,10.8535 C14.897,10.8535 14,9.9565 14,8.8535 C14,7.7505 14.897,6.8535 16,6.8535'
                    id='Fill-3'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export function ThumbDownIcon(props) {
  return (
    <svg width='13px' height='22px' viewBox='0 0 13 22'>
      <defs>
        <polygon
          id='path-1'
          points='6.3368621e-07 0.00218602705 12.9941589 0.00218602705 12.9941589 20.2424058 6.3368621e-07 20.2424058'
        ></polygon>
      </defs>
      <g id='desktop-appsumo' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='01-appsumo-landing-page' transform='translate(-826.000000, -832.000000)'>
          <g
            id='Group'
            transform='translate(832.500000, 844.000000) rotate(180.000000) translate(-832.500000, -844.000000) translate(826.000000, 832.000000)'
          >
            <g id='Group-3' transform='translate(0.000000, 0.014155)'>
              <mask id='mask-2' fill={WHITE_COLOR}>
                <use xlinkHref='#path-1'></use>
              </mask>
              <g id='Clip-2'></g>
              <path
                d='M5.26094218,20.2424058 L5.26094218,19.5230128 C5.26094218,19.5230128 4.17631264,19.4191267 3.46658423,18.1033282 L0.1814403,10.9857224 C0.1814403,10.9857224 -0.0123882353,10.5894421 0.000628035044,10.3547587 C0.000628035044,10.3547587 -0.0262831039,9.57758974 0.66388836,9.23518048 C0.66388836,9.23518048 1.35038273,9.00433692 1.80290964,9.53143079 C1.80290964,9.53143079 2.14614869,9.78726558 2.45868561,10.5144358 L3.19597597,11.9283282 L3.54425882,12.5169727 L3.54748035,6.71130939 L3.56700476,3.80575244 C3.56700476,3.80575244 3.75256796,2.87468235 4.33206859,2.67462228 C4.33206859,2.67462228 4.99981952,2.42838698 5.37747034,2.84389887 C5.37747034,2.84389887 5.87921502,3.17477247 5.89548536,3.86730313 L5.89548536,4.76070738 L5.84465682,11.3858426 C5.84465682,11.3858426 5.88271314,8.9620015 6.02922753,8.58111289 C6.02922753,8.58111289 6.41939024,7.60004406 7.21048661,7.69237822 C7.21048661,7.69237822 8.02414994,7.65391514 8.35621126,8.47725932 C8.35621126,8.47725932 8.66861802,8.86265482 8.5123577,10.1204008 L8.52532516,11.4089302 C8.52532516,11.4089302 8.4992438,9.26210788 8.60993091,9.08513542 C8.72061802,8.90814668 8.87037021,8.3233582 9.72332641,8.29257472 C9.72332641,8.29257472 10.4721036,8.3233582 10.7520836,9.06974368 C10.7520836,9.06974368 10.8627707,9.10822303 10.8627707,9.79304155 L10.8953277,11.2088701 C10.8953277,11.2088701 10.8497382,9.78534568 10.9409009,9.6699239 C10.9409009,9.6699239 11.2338971,8.93893016 11.754792,8.93893016 C11.754792,8.93893016 12.353817,8.74654969 12.6924028,9.30058723 C12.6924028,9.30058723 13.0244804,9.51602278 12.9919234,10.3932218 L12.8390473,14.5203557 C12.8390473,14.5203557 12.6028671,18.0057875 11.194832,19.4902768 L11.1850536,20.2424058 L5.26094218,20.2424058 Z'
                id='Fill-1'
                fill='#F4C16D'
                mask='url(#mask-2)'
              ></path>
            </g>
            <path
              d='M11.2339036,23.2970023 L5.15027785,23.2905755 C4.75746308,23.2901687 4.43641677,22.968439 4.43682353,22.5756243 L4.43884105,20.6730035 C4.43926408,20.2801887 4.76097747,19.9591424 5.15379224,19.9595492 L11.2374343,19.9659922 C11.6302328,19.966399 11.9512954,20.2881287 11.9508561,20.6809434 L11.9488548,22.5835642 C11.9484481,22.976379 11.6267184,23.2974253 11.2339036,23.2970023'
              id='Fill-4'
              fill='#13232A'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function Tag(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 25 25'
      style={{ enableBackground: 'new 0 0 25 25' }}
      xmlSpace='preserve'
      {...props}
    >
      <path
        fill={BLACK_COLOR}
        d='M24.9,0.8l-0.7-0.7l-3.4,3.4h-7.3L0.1,16.9l8,8l13.5-13.5V4.1L24.9,0.8z M20.6,11L8.1,23.5l-6.6-6.6L14,4.4h5.9
    l-2.1,2.1c-0.5-0.3-1.1-0.6-1.7-0.6c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3c0-0.6-0.2-1.2-0.6-1.7l2.1-2.1V11z M18.1,8.9
    c0,1.1-0.9,2-2,2s-2-0.9-2-2c0-1.1,0.9-2,2-2c0.4,0,0.7,0.1,1,0.3l-1.6,1.6l0.7,0.7l1.6-1.6C18,8.2,18.1,8.6,18.1,8.9z'
      />
    </svg>
  );
}

export function Edit(props) {
  const { color, ...restProps } = props;
  const colorValue = color ? color : BLACK_COLOR;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 25 25'
      style={{ enableBackground: 'new 0 0 25 25' }}
      xmlSpace='preserve'
      {...restProps}
    >
      <path
        fill={colorValue}
        d='M20,1.8l3.1,3.1l-18,18H2.1v-3.1L20,1.8 M20,0.1L0.9,19.3V24h4.8L24.8,4.9L20,0.1L20,0.1z'
      />
    </svg>
  );
}

export function StaticPageIcon(props) {
  return (
    <svg width='28' height='20' viewBox='0 0 28 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect x='0.5' y='1' width='26.6364' height='18' fill={WHITE_COLOR} stroke={BLACK_COLOR} />
      <rect x='2.94922' y='3.73438' width='21.3106' height='5.47727' fill={WHITE_COLOR} stroke={BLACK_COLOR} />
      <path d='M2.58203 11.8735H24.6595' stroke={BLACK_COLOR} />
      <path d='M2.58203 13.8804H16.6313' stroke={BLACK_COLOR} />
      <path d='M2.58203 15.8872H16.6313' stroke={BLACK_COLOR} />
    </svg>
  );
}

export function DynamicPageIcon(props) {
  return (
    <svg width='36' height='30' viewBox='0 0 36 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M32.3012 16.1504C32.3012 23.4432 25.7323 29.3553 17.6291 29.3553C9.52594 29.3553 2.95703 23.4432 2.95703 16.1504'
        stroke={BLACK_COLOR}
      />
      <path d='M5.40234 10.2816L3.07207 13.7051L1.00072 10.2816' stroke={BLACK_COLOR} />
      <path d='M34.7461 19.0846L32.4158 15.6611L30.3445 19.0846' stroke={BLACK_COLOR} />
      <path
        d='M2.95664 13.7051C2.95664 6.41223 9.52555 0.500216 17.6287 0.500216C25.7319 0.500216 32.3008 6.41223 32.3008 13.7051'
        stroke={BLACK_COLOR}
      />
      <rect x='7.65625' y='8.30371' width='20.7194' height='13.9321' fill={WHITE_COLOR} stroke={BLACK_COLOR} />
      <rect x='9.58203' y='10.4526' width='16.5339' height='4.09048' fill={WHITE_COLOR} stroke={BLACK_COLOR} />
      <path d='M9.18359 16.7422H26.5342' stroke={BLACK_COLOR} />
      <path d='M9.18359 18.3193H20.2249' stroke={BLACK_COLOR} />
      <path d='M9.18359 19.8965H20.2249' stroke={BLACK_COLOR} />
    </svg>
  );
}
