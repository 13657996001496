import { ACCOUNT_SETTINGS_NOTIFICATIONS_ID } from '@va/constants';
import { useTranslate } from '@va/localization';
import { DetailsSection, fontWeights, Paragraph } from '@va/ui/design-system';
import { useHashScroll } from '@va/util/hooks';
import { NotificationSettings } from 'common/components/misc/NotificationSettings/NotificationSettings';
import { useRef } from 'react';
import { useNewsletterPreferencesSa } from './useNewsletterPreferencesSa';

export function NewsletterPreferences() {
  const { onNewsletterChange, isSubscribedByType, isSubscriptionLoading } = useNewsletterPreferencesSa();
  const translate = useTranslate();
  const elementRef = useRef<HTMLDivElement>(null);
  useHashScroll(elementRef);

  return (
    <DetailsSection
      title={translate('account.notifications.settings')}
      ref={elementRef}
      id={ACCOUNT_SETTINGS_NOTIFICATIONS_ID}
      leftSideNode={
        <Paragraph colorClassName='text-gray-charcoal' weight={fontWeights.medium}>
          {translate('account.notificationSettings.label')}
        </Paragraph>
      }
    >
      <div className='grid mobile:grid-cols-1 grid-cols-2 gap-3 mt-4 md:mt-0'>
        <NotificationSettings
          isLoading={isSubscriptionLoading}
          newsletterKey='standaloneNewsletter'
          promotionsKey='standalonePromotions'
          onNewsletterChange={onNewsletterChange}
          isSubscribedByType={isSubscribedByType}
        />
      </div>
    </DetailsSection>
  );
}
