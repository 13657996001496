import { pathName } from '@va/constants';
import { VisaNestedPageNavigation } from '@va/dashboard/components';
import { PageHeader, TitleAndContextMenu } from '@va/dashboard/shared/ui-layout';
import { ListIcon, WhiteLabelCustomizationIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { AddWebsitePage } from '@va/standalone/core/feature/website-creation';
import { getAgencyUi } from '@va/standalone/shared/actions';
import { isUserLoggedInWithCustomUrl } from '@va/standalone/shared/helpers';
import { isAgencyOwner } from '@va/standalone/shared/selectors';
import {
  TabAccountSettings,
  TabAgencyCommission,
  TabConsent,
  TabManageBilling,
  TabManageWebsites,
} from '@va/svg-visa-icons';
import { buildNestedNavigation, buildNestedRoute } from '@va/util/helpers';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router';
import AccountSettings from './AccountSettings';
import ConsentPage from './ConsentPage';
import ManageWebsitesContainer from './ManageWebsites/ManageWebsitesContainer';
import WhiteLabelCustomization from './WhiteLabelCustomization/WhiteLabelCustomization';
import AccountInvoices from './account-invoices/AccountInvoices';
import AgencyCommission from './agency-commission';
import AgencyWhiteLabelV2 from './agency-white-label-v2/AgencyWhiteLabelV2';

const MyAccount: FC<{}> = () => {
  const translate = useTranslate();
  const agencyOwner = useSelector(isAgencyOwner);
  const dispatch = useDispatch();

  // needed to ensure agencyUi data is correct in the WL Websites tab
  useEffect(() => {
    if (isUserLoggedInWithCustomUrl()) return;
    dispatch(getAgencyUi());
  }, [dispatch]);

  const ownerRoutes = useMemo(
    () =>
      buildNestedNavigation([
        buildNestedRoute({
          link: '/white-label-customization',
          label: translate('standalone.accountSettings.whiteLabelCustomization.title'),
          component: WhiteLabelCustomization,
          icon: WhiteLabelCustomizationIcon,
        }),
        buildNestedRoute({
          link: '/agency-white-label',
          label: translate('standalone.accountSettings.whiteLabelWebsites.title'),
          component: AgencyWhiteLabelV2,
          icon: ListIcon,
        }),
        buildNestedRoute({
          link: '/agency-commission',
          label: translate('standalone.accountSettings.commission.title'),
          component: AgencyCommission,
          icon: TabAgencyCommission,
        }),
        buildNestedRoute({
          link: '/invoices',
          label: translate('standalone.accountSettings.invoices.title'),
          component: AccountInvoices,
          icon: TabManageBilling,
        }),
      ]),
    [translate],
  );

  const otherRoutes = useMemo(
    () =>
      buildNestedNavigation([
        buildNestedRoute({
          link: '/info',
          label: translate('standalone.accountSettings.myAccount.title'),
          component: AccountSettings,
          icon: TabAccountSettings,
        }),
        buildNestedRoute({
          link: '/websites',
          label: translate('standalone.accountSettings.manageWebsites.title'),
          component: ManageWebsitesContainer,
          icon: TabManageWebsites,
        }),
        buildNestedRoute({
          link: '/consent',
          label: translate('standalone.accountSettings.consent.title'),
          component: ConsentPage,
          icon: TabConsent,
        }),
      ]),
    [translate],
  );

  const allRoutes = useMemo(() => {
    const nonOwnerRoutes = [...otherRoutes];
    if (!agencyOwner && isUserLoggedInWithCustomUrl()) {
      return nonOwnerRoutes;
    }
    nonOwnerRoutes.splice(2, 0, ...ownerRoutes);
    return nonOwnerRoutes;
  }, [agencyOwner, otherRoutes, ownerRoutes]);

  const isAddWebsiteRoute = window.location.pathname === pathName.addWebsite;

  return (
    <>
      {!isAddWebsiteRoute && (
        <>
          <PageHeader
            title={
              <TitleAndContextMenu
                title={translate('standalone.accountSettings.title')}
                tooltipTransKey={'tooltipTab.myAccount'}
              />
            }
            hideDatePicker
            hideBorder
          />
          <VisaNestedPageNavigation routes={allRoutes} />
        </>
      )}
      <Route path='/my-account/add-website' exact component={AddWebsitePage} />
    </>
  );
};

export default MyAccount;
