import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { REQUIRED_COLOR } from '@va/constants';
import { LoadingLogo } from '@va/ui/design-system';
import PopUpCard from '../index';

/** @deprecated */
export default class LoadingPopUpCard extends PureComponent {
  getThemeColor() {
    switch (this.props.colorTheme) {
      case 'theme-red':
        return REQUIRED_COLOR;
      case 'theme-green':
      default:
        return window.PRIMARY_COLOR;
    }
  }

  render() {
    return (
      <PopUpCard type={this.props.cardType} colorTheme={this.props.colorTheme}>
        <div className='relative min-h-350 min-w-360 flex items-center justify-center'>
          <LoadingLogo />
        </div>
      </PopUpCard>
    );
  }
}

LoadingPopUpCard.propTypes = {
  cardType: PropTypes.string,
  colorTheme: PropTypes.string,
};

LoadingPopUpCard.defaultProps = {
  cardType: 'card-grey',
  colorTheme: 'theme-green',
};
