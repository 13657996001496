import { createColumnHelper } from '@tanstack/react-table';
import { SOURCE_TAB } from '@va/constants';
import { CountryLocationIcon, PageVisitsInSessionCell, VideoRecordingButton } from '@va/dashboard/components';
import { isTabletDevice } from '@va/dashboard/selectors/ui';
import { useTranslate } from '@va/localization';
import { HeaderCellV8 } from '@va/ui/components/data-table';
import { ListCardItemWithIcon } from '@va/ui/design-system';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LatestVisitorsItem } from '../LatestVisitorsTable';
import { BrowserCell, DeviceCell, IpCell, OperatingSystemCell } from '../cells';
import { VisitorsListItem } from '../visitors-list-card';
import { StatusCell } from './cells/StatusCell';

const columnHelper = createColumnHelper<LatestVisitorsItem>();

export const useLatestVisitorsColumnsV8 = () => {
  const translate = useTranslate();
  const isTablet = useSelector(isTabletDevice);

  const columns = useMemo(() => {
    if (isTablet) {
      return [
        columnHelper.accessor('status', {
          cell: (info) => {
            return <VisitorsListItem visitor={info.row.original} />;
          },
          header: () => <div></div>,
          meta: {
            useDefaultCellStyle: false,
          },
        }),
      ];
    }

    return [
      columnHelper.accessor('status', {
        header: (ctx) => <HeaderCellV8 context={ctx} text={translate('tab.latestVisitors.visitorsTypeAndDate')} />,
        cell: (info) => {
          const {
            countryCode,
            pageVisit: { unixTs },
            id,
          } = info.row.original;
          const status = info.getValue();
          return <StatusCell countryCode={countryCode} id={id} status={status} unixTs={unixTs} />;
        },
        meta: {
          cellClassName: 'min-w-180 !justify-start',
        },
      }),
      columnHelper.accessor('countryCode', {
        header: (ctx) => <HeaderCellV8 context={ctx} text={translate('tab.latestVisitors.countryInfo')} />,
        cell: (info) => {
          const { location } = info.row.original;
          const countryCode = info.getValue();
          return (
            <div className='flex items-center overflow-hidden'>
              <ListCardItemWithIcon
                icon={<CountryLocationIcon location={location} countryCode={countryCode} />}
                topText={translate(`country.name.${countryCode}`)}
              />
            </div>
          );
        },
        meta: { cellClassName: '!justify-start' },
      }),
      columnHelper.accessor('ip', {
        header: (ctx) => <HeaderCellV8 context={ctx} text={translate('tab.latestVisitors.visitorIp')} />,
        cell: (info) => {
          const { companyOrgName, companyOrgType, countryCode } = info.row.original;
          const ip = info.getValue();
          return (
            <IpCell value={ip} companyName={companyOrgName} companyType={companyOrgType} countryCode={countryCode} />
          );
        },
        meta: {
          cellClassName: '!justify-start',
        },
        enableSorting: true,
      }),
      columnHelper.accessor('device', {
        header: (ctx) => <HeaderCellV8 context={ctx} text={translate('tab.latestVisitors.deviceInfo')} />,
        cell: (info) => {
          const { device, platform, browser } = info.row.original;
          return (
            <div className='flex flex-row gap-2'>
              <DeviceCell value={device} />
              <OperatingSystemCell value={platform} />
              <BrowserCell value={browser} />
            </div>
          );
        },
      }),
      columnHelper.accessor('pageVisitsInSession', {
        header: (ctx) => <HeaderCellV8 context={ctx} text={translate('tab.latestVisitors.pageVisits')} />,
        cell: (info) => {
          const { id, pageVisitsInSession, countryCode } = info.row.original;
          return (
            <PageVisitsInSessionCell sessionId={id} pageVisitsCount={pageVisitsInSession} countryCode={countryCode} />
          );
        },
      }),
      columnHelper.accessor('hasRecording', {
        header: (ctx) => <HeaderCellV8 context={ctx} text={translate('tab.latestVisitors.sessionRecordings')} />,
        cell: (info) => {
          const {
            id,
            pageVisit: { unixTs },
            hasRecording,
          } = info.row.original;
          return (
            <VideoRecordingButton
              sourceTab={SOURCE_TAB.OVERVIEW}
              sessionInfo={{ unixTs, id, hasRecording: hasRecording }}
            />
          );
        },
      }),
    ];
  }, [isTablet, translate]);

  return columns;
};
