import { ChromeIcon } from '../browser-icons/ChromeIcon';
import { FallbackIconCircle } from '../misc/FallbackIcons';
import { AndroidIcon } from './AndroidIcon';
import { AppleIcon } from './AppleIcon';
import { BlackBerryLogo } from './BlackBerryIcon';
import { LinuxLogo } from './LinuxIcon';
import { WindowsIcon } from './WindowsIcon';

export const OperatingSystemIcon = ({ osName, className }: { osName: string; className?: string }) => {
  switch (osName?.toLowerCase()?.trim()) {
    case 'macos':
    case 'macosx':
    case 'macintosh':
    case 'macos mavericks':
    case 'macos yosemite':
    case 'macos el capitan':
    case 'macos sierra':
    case 'macos high sierra':
    case 'macos mojave':
    case 'macos catalina':
    case 'macos big sur':
    case 'ios':
    case 'ipados':
      return <AppleIcon className={className} />;
    case 'win95':
    case 'win98':
    case 'win2000':
    case 'winnt':
    case 'winrt8':
    case 'winrt8.1':
    case 'win32':
    case 'win7':
    case 'win8':
    case 'win8.1':
    case 'win10':
    case 'winxp':
    case 'windows xp':
    case 'winvista':
    case 'windows':
    case 'windows 10':
    case 'windows 7':
    case 'windows 8':
    case 'windows 8.1':
    case 'winmobile':
    case 'winphone':
    case 'winphone7':
    case 'winphone7.5':
    case 'winphone8':
    case 'winphone8.1':
    case 'winphone10':
      return <WindowsIcon className={className} />;
    case 'linux':
    case 'linux smartphone os':
    case 'ubuntu':
      return <LinuxLogo className={className} />;
    case 'android':
    case 'android pie':
    case 'android oreo':
    case 'android for googletv':
      return <AndroidIcon className={className} />;
    case 'rim os':
    case 'rim tablet os':
      return <BlackBerryLogo className={className} />;
    case 'chromeos':
      return <ChromeIcon className={className} />;
    case 'unknown':
    case 'default browser':
      return <FallbackIconCircle className={className} />;
    default:
      return <FallbackIconCircle className={className} />;
  }
};
