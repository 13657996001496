import { apiStatus, UPDATE_WEBSITES_EVENT } from '@va/constants';
import { VisitorsCardsContextProvider } from '@va/dashboard/modules/visitors';
import { isMobileDevice, isTabletDevice } from '@va/dashboard/selectors/ui';
import { AddFilterButton, AppliedFilters, useFiltersContext } from '@va/shared/feature-filters';
import { PageContent, PageHeaderItemsWrapper } from '@va/dashboard/shared/ui-layout';
import { NavAddCircle } from '@va/icons';
import { useTranslate } from '@va/localization';
import { MAX_APPLIED_SUMO_VOUCHERS } from '@va/standalone/shared/constants';
import { isUserLoggedInWithCustomUrl } from '@va/standalone/shared/helpers';
import {
  getApiRequest,
  getApiRequestStatus,
  getVouchersList,
  isAgencyOwner,
  isSumoUser,
} from '@va/standalone/shared/selectors';
import { SeeMoreArrow } from '@va/svg-visa-icons';
import { Button, LoadingLogo, NoDataMessage, ReportBlock } from '@va/ui/design-system';
import { EventBus } from '@va/util/misc';
import { Link } from '@va/util/router';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { MappedWebsite } from '../agency-white-label-v2/agency-white-label-apiClient';
import { RemoveWebsiteContextProvider } from '../RemoveWebsiteV2/removeWebsiteContext';
import { useManageWebsitesContext } from './ManageWebsiteContext';
import ManageWebsitesPagination from './ManageWebsitesPagination';
import WebsitesTableRow from './WebsitesTableRow/WebsitesTableRow';

const ManageWebsitesPage = () => {
  const translate = useTranslate();
  const sumoAccountType = useSelector(isSumoUser);
  const vouchers = useSelector(getVouchersList);

  const isMobile = useSelector(isMobileDevice);
  const isTablet = useSelector(isTabletDevice);
  const { setWebsiteFilterQuery, websitesData, getWebsites, isLoading, totalWebsites, setOffset } =
    useManageWebsitesContext();
  const { websites = [] } = websitesData ?? {};
  const vouchersInfo = useSelector((state) => getApiRequest(state, 'getVouchersInfo'))?.toJS() ?? {};
  const changeWhiteLabelStatus = useSelector((state) => getApiRequestStatus(state, 'changeWebsiteWhiteLabelStatus'));
  const agencyOwner = useSelector(isAgencyOwner);
  const history = useHistory();

  const { appliedFilterValues } = useFiltersContext();

  useEffect(() => {
    const trackingStatus = appliedFilterValues['trackingStatus'];
    const url = appliedFilterValues['url'];
    setOffset(0);
    setWebsiteFilterQuery((prev) => ({ ...prev, start: 0, url, trackingStatus }));
  }, [appliedFilterValues, setOffset, setWebsiteFilterQuery]);

  useEffect(() => {
    if (changeWhiteLabelStatus === apiStatus.SUCCEEDED) {
      getWebsites();
    }
  }, [changeWhiteLabelStatus, getWebsites]);

  useEffect(() => {
    const listener = EventBus.addListener(UPDATE_WEBSITES_EVENT, () => {
      getWebsites();
    });
    return () => {
      listener.removeListener();
    };
  }, [getWebsites]);

  const tableContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className='relative flex justify-center w-full h-44'>
          <LoadingLogo />
        </div>
      );
    }
    if (totalWebsites > 0) {
      return (
        <div className='border-2 sm:border-0 border-solid border-white-smoke rounded-12'>
          <WebsitesList allWebsitesList={websites} />
        </div>
      );
    }
    return <NoDataMessage type={'panel'} />;
  }, [isLoading, websites, totalWebsites]);

  const hasWebsiteCreatePermission = useMemo(
    () => !isUserLoggedInWithCustomUrl() || (isUserLoggedInWithCustomUrl() && agencyOwner),
    [agencyOwner],
  );

  return (
    <RemoveWebsiteContextProvider>
      <PageHeaderItemsWrapper>
        {hasWebsiteCreatePermission && (
          <Button
            onClick={() => {
              history.push('/my-account/add-website');
            }}
            text={isTablet ? undefined : translate('button.addNewWebsite')}
            icon={(_, color) => <NavAddCircle color={color} />}
          />
        )}
      </PageHeaderItemsWrapper>

      <PageContent>
        {sumoAccountType && vouchers.size > 0 && vouchersInfo?.vouchers && (
          <div className='mt-10'>
            <SumoVouchersInfoHeader vouchers={vouchers} vouchersInfo={vouchersInfo} />
          </div>
        )}
        <ReportBlock
          title={translate('standalone.accountSettings.manageWebsites.title')}
          nodes={{
            filterButton: <AddFilterButton />,
            pagination: <ManageWebsitesPagination showPageSizeSelector={!isMobile} className='flex justify-end' />,
            appliedFilters: <AppliedFilters />,
          }}
        >
          <div className='account-tab'>
            <div className='mt-5'>{tableContent}</div>
          </div>
          <ManageWebsitesPagination
            showCountInfo={isMobile}
            showPageSizeSelector={isMobile}
            showPaginationBtns={false}
            className='mt-3 justify-end'
          />
        </ReportBlock>
      </PageContent>
    </RemoveWebsiteContextProvider>
  );
};

export default ManageWebsitesPage;

const WebsitesList = ({ allWebsitesList }: { allWebsitesList: MappedWebsite[] }) => {
  if (allWebsitesList) {
    return (
      <VisitorsCardsContextProvider>
        {allWebsitesList.map((website, index) => (
          <WebsitesTableRow key={index} website={website} index={index} />
        ))}
      </VisitorsCardsContextProvider>
    );
  }
  return null;
};

/**
 *
 * TODO- Will fix the type for Vouchers
 */
const SumoVouchersInfoHeader = ({
  vouchers,
  vouchersInfo,
}: {
  vouchers: any;
  vouchersInfo: { vouchers: number; visitsLimit: number; maxUsage: number; applied: number };
}) => {
  const translate = useTranslate();

  return (
    <div className='appsumo-websites-info'>
      {translate(vouchers.size === 1 ? 'appsumo.websitesInfo.singleCode' : 'appsumo.websitesInfo.multipleCodes', {
        codes: vouchersInfo?.vouchers ?? 0,
        visits: vouchersInfo?.visitsLimit ?? 0,
        totalWebsites: vouchersInfo?.maxUsage ?? 0,
        websites: vouchersInfo?.applied ?? 0,
      })}
      {vouchers.size < MAX_APPLIED_SUMO_VOUCHERS ? (
        <span>&nbsp;{translate('appsumo.websitesInfo.addMoreVouchers')}</span>
      ) : null}

      <Link to={'/appsumo'}>
        <SeeMoreArrow className={'icon see-more-icon'} />
      </Link>
    </div>
  );
};
