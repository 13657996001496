import { useTranslate } from '@va/localization';
import { fontWeights, HorizontalDivider, Paragraph, paragraphSizes } from '@va/ui/design-system';
import { getFullNameFromUserInfo, getInitialsFromUserInfo } from '@va/util/helpers';
import classNames from 'classnames';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

type UserInfoProps = {
  userInformation: { firstName?: string; lastName?: string };
  onClose: () => void;
  hasLinkAccesible?: boolean;
};

export function UserInfo({ userInformation, onClose, hasLinkAccesible }: UserInfoProps) {
  const translate = useTranslate();
  const userInitials = useMemo(() => getInitialsFromUserInfo(userInformation), [userInformation]);

  return (
    <>
      <div
        className={classNames('group flex flex-row rounded-18 items-center px-3 pb-3 my-3 bg-white flex-nowrap', {
          'hover:bg-white-snow cursor-pointer': hasLinkAccesible,
        })}
      >
        <div className='rounded-full bg-primary w-9 h-9 flex justify-center items-center text-white shrink-0 font-primary'>
          {userInitials.toUpperCase()}
        </div>
        <div className='pl-18px overflow-hidden'>
          <Paragraph
            size={paragraphSizes.large}
            colorClassName='text-gray-charcoal'
            weight={fontWeights.medium}
            className='truncate'
          >
            {getFullNameFromUserInfo(userInformation)}
          </Paragraph>
          {hasLinkAccesible && (
            <Link to='/my-account/info' onClick={onClose}>
              <Paragraph colorClassName='text-gray-devil group-hover:text-primary' weight={fontWeights.medium}>
                {translate('standalone.accountSettings.myAccount.title')}
              </Paragraph>
            </Link>
          )}
        </div>
      </div>
      <HorizontalDivider />
    </>
  );
}
