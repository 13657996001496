import classNames from 'classnames';
import React from 'react';
import { IconProps } from '.';

export const FullscreenIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 0.75H0.75V1.5V7.5H2.25V3.31066L6.21967 7.28033L7.28033 6.21967L3.31066 2.25H7.5V0.75H1.5ZM16.5 0.75H17.25V1.5V7.5H15.75V3.31066L11.7803 7.28033L10.7197 6.21967L14.6893 2.25H10.5V0.75H16.5ZM17.25 17.2495H16.5H10.5V15.7495H14.6893L10.7197 11.7798L11.7803 10.7192L15.75 14.6889V10.4995H17.25V16.4995V17.2495ZM1.5 17.2495H0.75V16.4995V10.4995H2.25L2.25 14.6889L6.21967 10.7192L7.28033 11.7798L3.31066 15.7495H7.5V17.2495H1.5Z'
        fill={color}
      />
    </svg>
  );
};


