import { Selectors3asFPermissions } from '@va/aaas/shared/selectors';
import {
  allowedWebsiteIdsStatsOverviewWithNoStartDateLimitation,
  defaultWebsiteId,
  gridLayout,
  nonTranslatableText,
  pageType,
  platform,
  STATS_OVERVIEW_RELEASE_DATE_UNIX_TS,
  userActions,
  websiteFlagKeys,
  websiteStatus,
} from '@va/constants';
import { getAccountInformation, getApiRequest } from '@va/dashboard/selectors/api';
import { getWebsite, getWebsiteFrontendFlags, hasSsrCodeInstalled, isWixWebsite } from '@va/dashboard/selectors/core';
import { APP_SUMO_PACKAGE_NAME, FREE_PLAN_DETAILS, subscriptionStatus } from '@va/standalone/shared/constants';
import {
  getActiveSubscription,
  getActiveWebsite,
  getLastCreatedSubscription,
  hasPermission,
} from '@va/standalone/shared/selectors';
import { decodeMalformedURIComponent, getUrlWithoutTrailingSlash, is3asApp, isWixApp } from '@va/util/helpers';
import { createGrid } from '@va/util/misc';
import { getWixBillingPackage } from '@va/wix/shared/helpers';
import { getPermissionPackage } from '@va/wix/shared/selectors';
import Config from 'Config';
import Immutable from 'immutable';
import { createSelector } from 'reselect';

export * from './feature-permissions';
export * from './feature-tour';
export * from './recordings';

//TODO remove toJS()
export const getIgnoreVisits = createSelector(
  [(state) => state.getIn(['core', 'app', 'ignoreVisits'], Immutable.Map())],
  (ignoreVisits) => ignoreVisits.toJS(),
);

export const getUserWebsiteUrl = createSelector(
  [(state) => state.getIn(['core', 'app', 'user', 'siteUrl'], '#')],
  (userWebsiteUrl) => userWebsiteUrl,
);

// TODO Double check this
export const isWebsitePublished = createSelector(
  [(state) => state.getIn(['core', 'app', 'user', 'isPublish'], 'false')],
  (isPublish) => isPublish,
);

export const getAuthenticationInstance = (state) => {
  return state.getIn(['core', 'app', 'user', 'instance'], null);
};

export const getPageSettingsGrid = () => {
  return createGrid(['ConversionsTypeCard', 'DynamicPagesCard'], gridLayout);
};

export const getSameCardGridLayout = (count, cardName) => {
  let items = new Array(count);
  items.fill(cardName);
  return createGrid(items, gridLayout, true);
};

export const getPendingRemovingDynamicPages = (state) =>
  state.getIn(['core', 'app', 'pendingRemovingDynamicPages'], Immutable.List()).toJS();
export const getPendingRemovingConversionTypes = (state) =>
  state.getIn(['core', 'app', 'pendingRemovingConversionTypes'], Immutable.List()).toJS();

export const hasFirstVisit = (state) => {
  const firstVisit = state.getIn(['core', 'app', 'website', 'hasFirstVisit']);
  return firstVisit ? true : state.getIn(['core', 'website', 'hadFirstVisit'], false);
};

export const getInstanceId = (state) => {
  const activeWebsite = getActiveWebsite(state);
  if (activeWebsite && activeWebsite.get('status') === websiteStatus.PENDING) return defaultWebsiteId;
  const instanceId = state.getIn(['core', 'app', 'instanceId']);
  return instanceId ?? defaultWebsiteId;
};
export const isInitializing = (state) => state.getIn(['core', 'app', 'initializing'], true);

export const areAllRecordingEventsLoaded = (state) =>
  state.getIn(['core', 'app', 'getAllRecordingEventsSucceeded'], false);

export const isTrackingCodeInstalled = createSelector(
  [getWebsite, isWixWebsite, hasSsrCodeInstalled],
  (website, isWix, hasSsrCode) => {
    const fFlags = website.frontendFlags;

    if (isWix) {
      return hasSsrCode;
    }

    return fFlags?.trackingCodeInstalled;
  },
);

export const hasHeatmapsFlag = (state) => {
  const website = getWebsite(state);
  const fFlags = website.frontendFlags;
  return fFlags && fFlags[websiteFlagKeys.ACCEPTED_HEATMAP];
};

export const hasFunnelsOnboardingFlag = createSelector(getWebsiteFrontendFlags, (flags) => {
  return flags[websiteFlagKeys.WELCOME_FUNNELS];
});

const mapPages = (staticPages, dynamicPages) => {
  let staticPagesList,
    dynamicPagesList = [];
  if (staticPages) {
    staticPagesList = staticPages.map((item) => ({
      key: item.key,
      type: pageType.STATIC,
      title: decodeMalformedURIComponent(item.title),
      url: decodeMalformedURIComponent(item.url),
    }));
  }
  if (dynamicPages) {
    dynamicPagesList = dynamicPages.map((item) => ({
      key: item.key,
      type: pageType.DYNAMIC,
      title: decodeMalformedURIComponent(item.name),
      url: decodeMalformedURIComponent(item.pattern),
    }));
  }

  return staticPagesList.concat(dynamicPagesList);
};

export const getConversionTypes = createSelector(
  [(state) => getApiRequest(state, 'getConversionTypes')],
  (conversionTypes) => {
    return (
      conversionTypes.data &&
      conversionTypes.data.map((ct) => ({
        key: ct.key,
        name: ct.name,
        pages: mapPages(ct.pages, ct.dynamicPages),
      }))
    );
  },
);

export const getWixPlanName = (state) => {
  return getWixBillingPackage(getPermissionPackage(state));
};

export const getCurrentPlanName = (state, status = subscriptionStatus.ACTIVE) => {
  const website = getWebsite(state);
  if (website.platform === platform.WIX) return getWixPlanName(state);
  let activeSubscription = getLastCreatedSubscription(state);
  if (status === subscriptionStatus.ACTIVE && activeSubscription.status !== subscriptionStatus.PAST_DUE) {
    // past_due subscriptions are considered still "active" until they are "expired" or "canceled".
    activeSubscription = getActiveSubscription(state);
  }
  if (!activeSubscription) {
    const sumoActive = website.sumoActive;
    if (sumoActive) return APP_SUMO_PACKAGE_NAME;
    return FREE_PLAN_DETAILS.planName;
  }

  return activeSubscription.planName;
};

export const getDynamicPagesList = (state) => {
  const dPagesRequest = getApiRequest(state, 'getDynamicPages');

  const website = getWebsite(state);
  let dynamicPages = [];
  if (dPagesRequest.data && dPagesRequest.data.length > 0) {
    dynamicPages = dPagesRequest.data.map((item) => ({
      key: item.key,
      value: getUrlWithoutTrailingSlash(website.url) + '/' + item.pattern,
      label: item.name,
      type: pageType.DYNAMIC,
    }));
  }
  return dynamicPages;
};

export const getStatsOverviewReleaseDate = (state) => {
  const websiteId = getInstanceId(state);
  if (allowedWebsiteIdsStatsOverviewWithNoStartDateLimitation.includes(websiteId)) return 1;
  const website = getWebsite(state);
  if (website.importTs) {
    //Allow imported websites data to view stats from installDate
    return website.installDate;
  }

  return (Config && Config.statsOverviewReleaseDate) || STATS_OVERVIEW_RELEASE_DATE_UNIX_TS;
};

export const getFeatureReleaseDate = (state, releaseDate) => {
  const website = getWebsite(state);
  if (website.importTs || !releaseDate) {
    //Allow imported websites data to view stats from installDate
    return website.installDate;
  }

  return releaseDate;
};

/**
 * @param {Object} state
 * @param {String} priorConsent - one of Object.values(CONSENT_FLAG)
 * @returns {Boolean | null | undefined}
 */
export const hasConsentFlag = (state, priorConsent) => {
  const website = getWebsite(state);
  const accountInformation = getAccountInformation(state);
  const flags = isWixApp() ? website.frontendFlags : accountInformation.frontendFlags;
  return flags && flags[priorConsent];
};

export const getUnlimitedVoucher = (state) => {
  const featurePermissions = getApiRequest(state, 'getFeaturePermissions');
  return featurePermissions?.unlimitedVoucher;
};

export const hasUnlimitedVoucher = (state) => {
  const unlimitedVoucher = getUnlimitedVoucher(state);
  return !!unlimitedVoucher?.voucherCode;
};

export const getFeaturePermissions = (state) => {
  return getApiRequest(state, 'getFeaturePermissions');
};

export const getFeaturePermissionsStatus = (state) => {
  if (is3asApp()) {
    return Selectors3asFPermissions.status(state);
  }

  const permissions = getFeaturePermissions(state);
  return permissions.status;
};

export const showRecordingsOnboarding = (state) => {
  const hasSsrSettingsPermission = hasPermission(state, userActions.Settings);
  const alreadySeenOnboarding = getWebsiteFrontendFlags(state)?.[websiteFlagKeys.RECORDINGS_SETTINGS];

  if (!alreadySeenOnboarding) return true;

  return hasSsrSettingsPermission && !alreadySeenOnboarding;
};

export const getIntpWebsiteInfo = (state) => state.getIn(['app'])?.intpWebsite ?? {};
export const getIntpName = createSelector(getIntpWebsiteInfo, (websiteInfo) => websiteInfo?.intpName);
export const getWebsiteName = createSelector(getIntpName, (intpName) =>
  is3asApp() ? intpName : nonTranslatableText.appName,
);

export const getModulePermissions = (state) => getApiRequest(state, 'getModulePermissions');

export const getModulePermissionsStatus = (state) => getModulePermissions(state).status;

export const isModuleEnabled = (state) => {
  const { data } = getModulePermissions(state);

  return (moduleName) => !data?.find((item) => item.module === moduleName)?.disabled;
};
