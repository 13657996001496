/**
 * @typedef {Object} computeVisiblePositionResult
 * @property {Point} tooltipPosition
 * @property {string|undefined} forcedPlace
 * @property {Point|undefined} anchorPosition
 *
 * @param {Size} tooltipSize
 * @param {Positions} positions
 * @param {string} place
 * @return {computeVisiblePositionResult}
 */
export function computeVisiblePosition(tooltipSize, positions, place) {
  const current = computePosition(tooltipSize, positions[place], place);
  if (current !== null) {
    return {
      tooltipPosition: current.tooltipPosition,
      anchorPosition: current.anchorPosition,
    };
  }

  // find visible placement from all possible placements
  const placement = ['top', 'left', 'right', 'bottom'].reduce((acc, placement) => {
    if (acc) {
      return acc;
    }

    const result = computePosition(tooltipSize, positions[placement], placement);
    if (result) {
      result.forcedPlace = placement;
    }

    return result;
  }, null);

  return placement !== null ? placement : { tooltipPosition: positions[place], anchorPosition: null };
}

/**
 * @typedef {Object} ComputePositionResult
 * @property {Point} tooltipPosition
 * @property {Point|null} anchorPosition
 *
 * @param {Size} tooltipSize
 * @param {Point} tooltipPosition
 * @param {string} place
 * @return {null|ComputePositionResult}
 */
export function computePosition(tooltipSize, tooltipPosition, place) {
  if (isVisible(tooltipPosition, tooltipSize)) {
    return {
      tooltipPosition: tooltipPosition,
      anchorPosition: null,
    };
  }

  const screenSize = getWindowSize();
  const visible = {
    vertically: isVisibleVertically(tooltipPosition, tooltipSize),
    horizontally: isVisibleHorizontally(tooltipPosition, tooltipSize),
  };

  const getOffset = (coordinate, width, max) => (coordinate < 0 ? 0 - coordinate : max - (coordinate + width));

  const shouldOffsetHorizontally =
    ['top', 'bottom'].includes(place) &&
    visible.vertically &&
    !visible.horizontally &&
    tooltipSize.width <= screenSize.width;

  if (shouldOffsetHorizontally) {
    const offset = getOffset(tooltipPosition.x, tooltipSize.width, screenSize.width);
    return {
      tooltipPosition: new Point(tooltipPosition.x + offset, tooltipPosition.y),
      anchorPosition: new Point(tooltipSize.width / 2 - offset),
    };
  }

  const shouldOffsetVertically =
    ['left', 'right'].includes(place) &&
    !visible.vertically &&
    visible.horizontally &&
    tooltipSize.height <= screenSize.height;

  if (shouldOffsetVertically) {
    const offset = getOffset(tooltipPosition.y, tooltipSize.height, screenSize.height);
    return {
      tooltipPosition: new Point(tooltipPosition.x, tooltipPosition.y + offset),
      anchorPosition: new Point(undefined, tooltipSize.height / 2 - offset),
    };
  }

  return null;
}

/**
 * @typedef {Object} Rect
 * @property {Number} x
 * @property {Number} y
 * @property {Number} width
 * @property {Number} height
 *
 * Calculate the position for every placement of the tooltip
 *
 * @param {Rect} targetRect
 *
 * @param {HTMLElement} node the tooltip DOM element
 *
 * @return {Positions}
 */
export function getPositions(targetRect, node) {
  const offsets = getOffsets(targetRect.width, targetRect.height, node.clientWidth, node.clientHeight);
  const { parentTop, parentLeft } = getParent(node);

  let positions = {};
  // compute 'top' and 'left' for all placements in case they are needed later,
  // for example, to check for which position the tooltip doesn't exit the screen
  Object.entries(offsets).forEach(([key, offset]) => {
    positions[key] = {
      x: parseInt(targetRect.x + targetRect.width / 2 + offset.x - parentLeft, 10),
      y: parseInt(targetRect.y + targetRect.height / 2 + offset.y - parentTop, 10),
    };
  });

  return positions;
}

/**
 * @param {Point} position
 * @param {Size} tooltipSize
 * @returns {boolean}
 */
function isVisible(position, tooltipSize) {
  return isVisibleHorizontally(position, tooltipSize) && isVisibleVertically(position, tooltipSize);
}

/**
 * @param {Point} position
 * @param {Size} tooltipSize
 * @returns {boolean}
 */
function isVisibleHorizontally(position, tooltipSize) {
  const windowSize = getWindowSize();

  return position.x >= 0 && position.x + tooltipSize.width <= windowSize.width;
}

/**
 * @param {Point} position
 * @param {Size} tooltipSize
 * @returns {boolean}
 */
function isVisibleVertically(position, tooltipSize) {
  const windowSize = getWindowSize();

  return position.y >= 0 && position.y + tooltipSize.height <= windowSize.height;
}

/**
 * @returns {Size}
 */
function getWindowSize() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

/**
 * Returns the offset on x and y for every placement
 *
 * @param  {number} targetWidth
 * @param  {number} targetHeight
 * @param  {number} tooltipWidth
 * @param  {number} tooltipHeight
 *
 * @return {{top: Point, bottom: Point, left: Point, right: Point}}
 */
const getOffsets = (targetWidth, targetHeight, tooltipWidth, tooltipHeight) => {
  const halfTooltipWidth = tooltipWidth / 2;
  const halfTooltipHeight = tooltipHeight / 2;

  const halfTargetWidth = targetWidth / 2;
  const halfTargetHeight = targetHeight / 2;
  // the triangle is the small "arrow" of the tooltip
  const triangleHeight = 2;

  const top = new Point(-halfTooltipWidth, -(halfTargetHeight + tooltipHeight + triangleHeight));
  const bottom = new Point(-halfTooltipWidth, halfTargetHeight);
  const left = new Point(-(halfTargetWidth + tooltipWidth + triangleHeight), -halfTooltipHeight);
  const right = new Point(halfTargetWidth, -halfTooltipHeight);

  return { top, bottom, left, right };
};

/**
 * Get the offset of the parent elements
 *
 * @param {HTMLElement} currentTarget
 *
 * @return {{parentTop: (*|number), parentLeft: (*|number)}}
 */
const getParent = currentTarget => {
  let currentParent = currentTarget;
  while (currentParent) {
    if (window.getComputedStyle(currentParent).getPropertyValue('transform') !== 'none') {
      break;
    }
    currentParent = currentParent.parentElement;
  }

  const parentTop = (currentParent && currentParent.getBoundingClientRect().top) || 0;
  const parentLeft = (currentParent && currentParent.getBoundingClientRect().left) || 0;

  return { parentTop, parentLeft };
};

/**
 * @property {*} x
 * @property {*} y
 */
class Point {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
}

/**
 * @typedef {Object} Size
 * @property {Number} width
 * @property {Number} height
 */

/**
 * @typedef {Object} Positions
 * @property {Point} top
 * @property {Point} bottom
 * @property {Point} left
 * @property {Point} right
 */
