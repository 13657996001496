import { AxiosHttpClient } from '@va/api-client';
import { AxiosRequestConfig } from 'axios';
import * as Config from 'Config';

export class ApiGateway extends AxiosHttpClient {
  private static apiClientInstance?: ApiGateway;

  private constructor() {
    super(`${Config.gatewayApiUrl}/`);
  }

  public static getInstance() {
    if (!this.apiClientInstance) {
      this.apiClientInstance = new ApiGateway();
    }
    return this.apiClientInstance;
  }

  public get = async <T = any>(url: string, config?: AxiosRequestConfig) => {
    const response = await this.instance.get<T>(url, config);
    return response.data;
  };

  public post = async <T = any>(url: string, data: {}, config?: AxiosRequestConfig) => {
    const response = await this.instance.post<T>(url, data, config);
    return response.data;
  };

  public patch = async (url: string, data: {}, config?: AxiosRequestConfig) => {
    const response = await this.instance.patch(url, data, config);
    return response.data;
  };

  public delete = async <T = unknown>(url: string, config?: AxiosRequestConfig) => {
    const response = await this.instance.delete<T>(url, config);
    return response.data;
  };
}

const apiGateway = ApiGateway.getInstance();
export default apiGateway;
