import { apiRequestResponseMsg, apiStatus, modalName, supportLinks } from '@va/constants';
import { closeModal } from '@va/dashboard/actions/ui';
import { getApiRequestImmutable } from '@va/dashboard/selectors/api';
import { isWixWebsite } from '@va/dashboard/selectors/core';
import { isModalOpen } from '@va/dashboard/selectors/ui';
import { CloseIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { getUserRole } from '@va/standalone/shared/selectors';
import { UserRole } from '@va/types/website';
import { Button, DialogBox } from '@va/ui/design-system';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalWrapper from '../ModalWrapper';
import './SsrTechnicalLimitation.scss';

type SsrTechnicalLimitationProps = {
  lines?: string[];
};

const SsrTechnicalLimitation = (props: SsrTechnicalLimitationProps) => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const userRole = useSelector(getUserRole);
  const isUserWatcher = userRole === UserRole.WATCHER;

  const modalOpenState = useSelector((state) => isModalOpen(state, modalName.ssrTechnicalLimitation));
  const { lines = ['l1', 'l2', 'l3', 'l4', 'l5'] } = props;
  const ssrSettings = useSelector((state) => getApiRequestImmutable(state, 'getSsrSettings'));
  const isWixPlatform = useSelector(isWixWebsite);
  const doesNotHaveSsrSettings =
    ssrSettings.status === apiStatus.FAILED &&
    ssrSettings.error &&
    ssrSettings.error.message === apiRequestResponseMsg.NOT_FOUND_SSR_SETTINGS;

  const recordingsSettingsPath = doesNotHaveSsrSettings ? '/behaviour/recordings' : '/settings/modules/recordings';
  const arrow = ' >>';

  const recordingSettingsKey = !isUserWatcher
    ? translate(`modal.ssrTechnicalLimitations.reasons.l5`, {
        to: recordingsSettingsPath,
        className: 'text-primary inline-block font-normal',
      })
    : translate(`modal.ssrTechnicalLimitations.reasons.noLink.l5`);

  const handleModalClose = () => {
    dispatch(closeModal(modalName.ssrTechnicalLimitation));
  };
  return (
    <ModalWrapper
      roundedCorners
      isOpen={modalOpenState}
      closeModal={handleModalClose}
      showCloseButton={true}
      closeIcon={
        <Button
          color='secondary'
          className='absolute top-4 right-6'
          icon={(classes) => <CloseIcon className={classes} />}
        />
      }
    >
      <div className='ssr-technical-limitation-container'>
        <DialogBox
          footerTitle={translate('button.understand')}
          handleClose={handleModalClose}
          title={translate('modal.ssrTechnicalLimitations.title')}
        >
          <div className='text-14 text-left font-normal md:!text-15 leading-4 md:leading-18'>
            <p className='mb-[15px]'>{translate('modal.ssrTechnicalLimitations.subtitle')}</p>
            <ul className='reason-list pl-3'>
              {lines.map((line, index) => (
                <li
                  className='reason text-black py-9px'
                  key={index}
                  onClick={(e) => {
                    const element = e.target as HTMLElement;
                    if (element.tagName.toLowerCase() === 'a') {
                      handleModalClose();
                    }
                  }}
                >
                  {line !== 'l5' && translate(`modal.ssrTechnicalLimitations.reasons.${line}`)}
                  {line === 'l5' && recordingSettingsKey}
                  {line === 'l1' && (
                    <React.Fragment>
                      &nbsp;
                      <a
                        href={isWixPlatform ? supportLinks.wixSsrLimitation : supportLinks.standaloneSsrLimitation}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='inline-block font-normal text-primary'
                      >
                        {translate('modal.ssrTechnicalLimitations.readMore')}
                      </a>
                      <span className='arrow text-primary'>{arrow}</span>
                    </React.Fragment>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </DialogBox>
      </div>
    </ModalWrapper>
  );
};

export default memo(SsrTechnicalLimitation);
