import { forwardRef } from 'react';
import { IconProps } from '.';

export const InfoIcon = forwardRef<SVGSVGElement, IconProps>(({ color = '#c3c3c3', className }, ref) => {
  return (
    <svg
      ref={ref}
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='9' cy='9' r='8.25' stroke={color} strokeWidth='1.5' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 4.5C8.58579 4.5 8.25 4.83579 8.25 5.25V9.75C8.25 10.1642 8.58579 10.5 9 10.5C9.41421 10.5 9.75 10.1642 9.75 9.75V5.25C9.75 4.83579 9.41421 4.5 9 4.5ZM9 12C8.58579 12 8.25 12.3358 8.25 12.75C8.25 13.1642 8.58579 13.5 9 13.5C9.41421 13.5 9.75 13.1642 9.75 12.75C9.75 12.3358 9.41421 12 9 12Z'
        fill={color}
      />
    </svg>
  );
});
