import { useActiveTab, usePageHeaderProps } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { FiltersContextProvider, SingleSelectFilter, TextFilter } from '@va/shared/feature-filters';
import { getVouchers, getVouchersInfo } from '@va/standalone/shared/actions';
import { accountSettingsTabNames } from '@va/standalone/shared/constants';
import { getOldestInstallDate, isSumoUser, userHasWebsites } from '@va/standalone/shared/selectors';
import { Filter } from '@va/types/filters';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ManageWebsitesContextProvider from './ManageWebsiteContext';
import ManageWebsitesPage from './ManageWebsitesPage';

const ManageWebsitesContainer = () => {
  useActiveTab(accountSettingsTabNames.manageWebsites);
  const oldestInstallDate = useSelector(getOldestInstallDate);
  const hasWebsites = useSelector(userHasWebsites);
  const translate = useTranslate();
  const dispatch = useDispatch();
  const isUserSumo = useSelector(isSumoUser);

  useEffect(() => {
    if (isUserSumo) {
      dispatch(getVouchersInfo());
      dispatch(getVouchers());
    }
  }, [isUserSumo, dispatch]);

  const options: Filter[] = useMemo(() => {
    return [
      new SingleSelectFilter({
        id: '1',
        name: 'trackingStatus',
        label: translate('filters.options.trackingCodeStatus'),
        inputProps: {
          useOptions: () => ({
            data: [
              { label: translate('option.btn.active'), value: 1 },
              { label: translate('option.btn.inActive'), value: 0 },
            ],
          }),
        },
      }),
      new TextFilter({
        id: '2',
        name: 'url',
        label: translate('filters.options.searchByUrl'),
        inputProps: {
          label: translate('filter.urlName'),
        },
      }),
    ];
  }, [translate]);

  const pageHeaderProps = useMemo(
    () => ({ hideDatePicker: hasWebsites ? false : true, dateEnabled: oldestInstallDate }),
    [hasWebsites, oldestInstallDate],
  );

  usePageHeaderProps(pageHeaderProps);

  return (
    <FiltersContextProvider allFilterOptions={options}>
      <ManageWebsitesContextProvider>
        <ManageWebsitesPage />
      </ManageWebsitesContextProvider>
    </FiltersContextProvider>
  );
};

export default ManageWebsitesContainer;
