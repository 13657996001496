export const LockOpen = () => {
  return (
    <svg width='22' height='18' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.625 1.5C15.6304 1.5 14.6766 1.89509 13.9733 2.59835C13.2701 3.30161 12.875 4.25544 12.875 5.25V6.09451C14.1689 6.42755 15.125 7.60212 15.125 9V15C15.125 16.6569 13.7819 18 12.125 18H3.125C1.46815 18 0.125 16.6569 0.125 15V9C0.125 7.34315 1.46815 6 3.125 6H11.375V5.25C11.375 3.85761 11.9281 2.52225 12.9127 1.53769C13.8973 0.553123 15.2326 0 16.625 0C18.0174 0 19.3527 0.553124 20.3373 1.53769C21.3219 2.52226 21.875 3.85761 21.875 5.25H20.375C20.375 4.25544 19.9799 3.30161 19.2766 2.59835C18.5734 1.89509 17.6196 1.5 16.625 1.5ZM12.125 7.5H3.125C2.29657 7.5 1.625 8.17157 1.625 9V15C1.625 15.8284 2.29657 16.5 3.125 16.5H12.125C12.9534 16.5 13.625 15.8284 13.625 15V9C13.625 8.17157 12.9534 7.5 12.125 7.5Z'
        fill='#969696'
      />
    </svg>
  );
};
