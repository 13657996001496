import { FilterBlock, FilterSelectDropdown, useFiltersContext } from '@va/shared/feature-filters';
import { Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useFunnelFilterOptions } from '../../filter-options';
import { FunnelFilter, FunnelFilterValue } from './FunnelFilter';

export const FunnelFilterInputApplied = ({
  filter,
  viewOnly,
  disableEditing,
  size = 'large',
}: {
  filter: FunnelFilter;
  viewOnly?: boolean;
  disableEditing?: boolean;
  size?: 'small' | 'medium' | 'large';
}) => {
  const { id, label, values, operator, operatorOptions } = filter;

  const filterValue = useMemo(() => values[0], [values]);

  const { funnelOptions, levelOptions, selectedFunnel } = useFunnelFilterOptions(filterValue);

  const { updateAppliedFilter } = useFiltersContext();

  const onChange = useCallback(
    (newValue: FunnelFilterValue) => {
      updateAppliedFilter(id, { values: [newValue] });
    },
    [id, updateAppliedFilter],
  );

  const selectedLevelName = useMemo(() => {
    if (isNil(filterValue.lvl)) return '-';
    return levelOptions.find((lvl) => lvl.value === filterValue.lvl)?.label ?? '-';
  }, [filterValue.lvl, levelOptions]);

  return (
    <FilterBlock
      filterId={id}
      operator={operator}
      fieldName={label}
      operatorOptions={operatorOptions}
      hideDeleteButton={viewOnly}
      disabled={disableEditing}
      size={size}
      input={
        <div className='flex items-center gap-1 h-full'>
          <FilterSelectDropdown
            triggerClassName={classNames('h-full px-3 bg-white flex items-center justify-center rounded-r-12', {
              '!bg-transparent': disableEditing,
            })}
            selectedOptions={filterValue.id ? { [filterValue.id]: true } : {}}
            onChange={(option) => {
              onChange({ id: option.value as string, lvl: undefined });
            }}
            options={funnelOptions}
            disabled={viewOnly || disableEditing}
          >
            <Paragraph size={paragraphSizes.tiny} weight={fontWeights.medium}>
              {selectedFunnel?.name || '-'}
            </Paragraph>
          </FilterSelectDropdown>
          <FilterSelectDropdown
            triggerClassName={classNames('h-full px-3 bg-white flex items-center justify-center rounded-12', {
              '!bg-transparent': disableEditing,
            })}
            selectedOptions={filterValue.lvl ? { [filterValue.lvl]: true } : {}}
            onChange={(option) => {
              onChange({ lvl: Number(option.value), id: filterValue.id });
            }}
            options={levelOptions}
            disabled={viewOnly || disableEditing}
          >
            <Paragraph size={paragraphSizes.tiny} weight={fontWeights.medium}>
              {selectedLevelName}
            </Paragraph>
          </FilterSelectDropdown>
        </div>
      }
    />
  );
};
