import { IconProps } from '.';

export const CustomerSupport = ({ className, color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 9C16.5 10.801 15.8652 12.4536 14.8072 13.7465L12.6681 11.6074C13.1919 10.8718 13.5 9.97187 13.5 9C13.5 8.02813 13.1919 7.12821 12.6681 6.3926L14.8072 4.25345C15.8652 5.54635 16.5 7.19905 16.5 9ZM9 16.5C10.801 16.5 12.4536 15.8652 13.7465 14.8072L11.6074 12.6681C10.8718 13.1919 9.97187 13.5 9 13.5C8.02813 13.5 7.12821 13.1919 6.3926 12.6681L4.25345 14.8072C5.54635 15.8652 7.19905 16.5 9 16.5ZM3.19279 13.7465C2.13477 12.4536 1.5 10.801 1.5 9C1.5 7.19905 2.13477 5.54635 3.19279 4.25345L5.33194 6.3926C4.80809 7.12821 4.5 8.02813 4.5 9C4.5 9.97187 4.80809 10.8718 5.33194 11.6074L3.19279 13.7465ZM6.3926 5.33194C7.12821 4.80809 8.02813 4.5 9 4.5C9.97187 4.5 10.8718 4.80809 11.6074 5.33194L13.7465 3.19279C12.4536 2.13477 10.801 1.5 9 1.5C7.19905 1.5 5.54635 2.13477 4.25345 3.19279L6.3926 5.33194ZM18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM12 9C12 10.6569 10.6569 12 9 12C7.34315 12 6 10.6569 6 9C6 7.34315 7.34315 6 9 6C10.6569 6 12 7.34315 12 9Z'
        fill={color ?? '#3c3c3c'}
      />
    </svg>
  );
};
