import { makeAction } from '@va/store';
import * as Types from './types/account';

export const updateWebsiteLocale = makeAction(Types.UPDATE_WEBSITE_LOCALE_REQUEST, 'locale');
export const updateWebsiteLocaleSucceeded = makeAction(Types.UPDATE_WEBSITE_LOCALE_SUCCEEDED);
export const updateWebsiteLocaleFailed = makeAction(Types.UPDATE_WEBSITE_LOCALE_FAILED, 'error');

export const confirmUpdateEmail = makeAction(Types.CONFIRM_UPDATE_EMAIL_REQUEST, 'token');
export const confirmUpdateEmailSucceeded = makeAction(Types.CONFIRM_UPDATE_EMAIL_SUCCEEDED);
export const confirmUpdateEmailFailed = makeAction(Types.CONFIRM_UPDATE_EMAIL_FAILED, 'error');

export const resetEmail = makeAction(Types.RESET_EMAIL_REQUEST, 'token');
export const resetEmailSucceeded = makeAction(Types.RESET_EMAIL_SUCCEEDED);
export const resetEmailFailed = makeAction(Types.RESET_EMAIL_FAILED, 'error');

export const changeEmail = makeAction(Types.CHANGE_EMAIL_REQUEST, 'data');
export const changeEmailSucceeded = makeAction(Types.CHANGE_EMAIL_SUCCEEDED);
export const changeEmailFailed = makeAction(Types.CHANGE_EMAIL_FAILED, 'error');

export const updateUserInfo = makeAction(Types.UPDATE_USER_INFO_REQUEST, 'data');
export const updateUserInfoSucceeded = makeAction(Types.UPDATE_USER_INFO_SUCCEEDED);
export const updateUserInfoFailed = makeAction(Types.UPDATE_USER_INFO_FAILED, 'error');

export const changeAgencyStatus = makeAction(Types.CHANGE_AGENCY_STATUS_REQUEST, 'status');
export const changeAgencyStatusSucceeded = makeAction(Types.CHANGE_AGENCY_STATUS_SUCCEEDED);
export const changeAgencyStatusFailed = makeAction(Types.CHANGE_AGENCY_STATUS_FAILED, 'error');

export const changeAccountDetail = makeAction(Types.CHANGE_ACCOUNT_DETAIL_REQUEST, 'data');
export const changeAccountDetailSucceeded = makeAction(Types.CHANGE_ACCOUNT_DETAIL_SUCCEEDED);
export const changeAccountDetailFailed = makeAction(Types.CHANGE_ACCOUNT_DETAIL_FAILED, 'error');

export const getWebsiteOverview = makeAction(Types.GET_WEBSITE_OVERVIEW, 'id', 'timezone');
export const initiateWebsiteOverview = makeAction(Types.INITIATE_WEBSITE_OVERVIEW, 'id');
export const resetWebsiteOverview = makeAction(Types.RESET_WEBSITE_OVERVIEW);
export const getWebsiteOverviewSucceeded = makeAction(Types.GET_WEBSITE_OVERVIEW_SUCCEEDED, 'data');
export const getWebsiteOverviewFailed = makeAction(Types.GET_WEBSITE_OVERVIEW_FAILED, 'error');

export const deleteAccount = makeAction(Types.DELETE_ACCOUNT_REQUEST);
export const deleteAccountSucceeded = makeAction(Types.DELETE_ACCOUNT_SUCCEEDED);
export const deleteAccountFailed = makeAction(Types.DELETE_ACCOUNT_FAILED, 'error');

export const refreshToken = makeAction(Types.REFRESH_TOKEN, 'data');
export const refreshTokenEnded = makeAction(Types.REFRESH_TOKEN_ENDED);

export const getTrackingCode = makeAction(Types.GET_TRACKING_CODE_REQUEST, 'data');
export const getTrackingCodeSucceeded = makeAction(Types.GET_TRACKING_CODE_SUCCEEDED, 'data');
export const getTrackingCodeFailed = makeAction(Types.GET_TRACKING_CODE_FAILED, 'error');

export const removeWebsite = makeAction(Types.REMOVE_WEBSITE_REQUEST, 'id', 'sendDataSelected', 'reason');
export const removeWebsiteSucceeded = makeAction(Types.REMOVE_WEBSITE_SUCCEEDED, 'data');
export const removeWebsiteFailed = makeAction(Types.REMOVE_WEBSITE_FAILED, 'error');

export const mutateWebsites = makeAction(Types.MUTATE_WEBSITES);

export const getWebsites = makeAction(Types.GET_WEBSITES_REQUEST);
export const getWebsitesSucceeded = makeAction(Types.GET_WEBSITES_SUCCEEDED, 'data');
export const getWebsitesFailed = makeAction(Types.GET_WEBSITES_FAILED, 'error');

export const acceptContributorRole = makeAction(
  Types.ACCEPT_CONTRIBUTOR_ROLE_REQUEST,
  'websiteId',
  'userRole',
  'token',
);
export const acceptContributorRoleSucceeded = makeAction(Types.ACCEPT_CONTRIBUTOR_ROLE_SUCCEEDED);
export const acceptContributorRoleFailed = makeAction(Types.ACCEPT_CONTRIBUTOR_ROLE_FAILED, 'error');

export const rejectContributorRole = makeAction(
  Types.REJECT_CONTRIBUTOR_ROLE_REQUEST,
  'websiteId',
  'userRole',
  'token',
);
export const rejectContributorRoleSucceeded = makeAction(Types.REJECT_CONTRIBUTOR_ROLE_SUCCEEDED);
export const rejectContributorRoleFailed = makeAction(Types.REJECT_CONTRIBUTOR_ROLE_FAILED, 'error');

export const registerContributor = makeAction(Types.REGISTER_CONTRIBUTOR_REQUEST, 'data');
export const registerContributorSucceeded = makeAction(Types.REGISTER_CONTRIBUTOR_SUCCEEDED, 'data');
export const registerContributorFailed = makeAction(Types.REGISTER_CONTRIBUTOR_FAILED, 'error');

export const leaveWebsite = makeAction(Types.LEAVE_WEBSITE_REQUEST, 'websiteId');
export const leaveWebsiteSucceeded = makeAction(Types.LEAVE_WEBSITE_SUCCEEDED);
export const leaveWebsiteFailed = makeAction(Types.LEAVE_WEBSITE_FAILED, 'error');

export const createUser = makeAction(Types.CREATE_USER_REQUEST, 'data');
export const createUserSucceeded = makeAction(Types.CREATE_USER_SUCCEEDED, 'data');
export const createUserFailed = makeAction(Types.CREATE_USER_FAILED, 'error');

export const activateUser = makeAction(Types.ACTIVATE_USER_REQUEST, 'token');
export const activateUserSucceeded = makeAction(Types.ACTIVATE_USER_SUCCEEDED);
export const activateUserFailed = makeAction(Types.ACTIVATE_USER_FAILED, 'error');

export const login = makeAction(Types.LOGIN_REQUEST, 'data');
export const loginSucceeded = makeAction(Types.LOGIN_SUCCEEDED, 'data');
export const loginFailed = makeAction(Types.LOGIN_FAILED, 'error');

export const checkLogin = makeAction(Types.CHECK_LOGIN_REQUEST, 'data');
export const checkLoginSucceeded = makeAction(Types.CHECK_LOGIN_SUCCEEDED);
export const checkLoginFailed = makeAction(Types.CHECK_LOGIN_FAILED, 'error');

export const backgroundLogin = makeAction(Types.BACKGROUND_LOGIN_REQUEST, 'data');
export const backgroundLoginSucceeded = makeAction(Types.BACKGROUND_LOGIN_SUCCEEDED, 'data');
export const backgroundLoginFailed = makeAction(Types.BACKGROUND_LOGIN_FAILED, 'error');

export const forgotPassword = makeAction(Types.FORGOT_PASSWORD_REQUEST, 'data');
export const forgotPasswordSucceeded = makeAction(Types.FORGOT_PASSWORD_SUCCEEDED);
export const forgotPasswordFailed = makeAction(Types.FORGOT_PASSWORD_FAILED, 'error');

export const resetPassword = makeAction(Types.RESET_PASSWORD_REQUEST, 'token', 'data');
export const resetPasswordSucceeded = makeAction(Types.RESET_PASSWORD_SUCCEEDED);
export const resetPasswordFailed = makeAction(Types.RESET_PASSWORD_FAILED, 'error');

export const resendRegistrationConfirmation = makeAction(Types.RESEND_REGISTRATION_CONFIRMATION_REQUEST, 'email');
export const resendRegistrationConfirmationSucceeded = makeAction(Types.RESEND_REGISTRATION_CONFIRMATION_SUCCEEDED);
export const resendRegistrationConfirmationFailed = makeAction(Types.RESEND_REGISTRATION_CONFIRMATION_FAILED, 'error');

export const generateImpersonateToken = makeAction(Types.GENERATE_IMPERSONATE_TOKEN_REQUEST, 'data');
export const generateImpersonateTokenSucceeded = makeAction(Types.GENERATE_IMPERSONATE_TOKEN_SUCCEEDED, 'data');
export const generateImpersonateTokenFailed = makeAction(Types.GENERATE_IMPERSONATE_TOKEN_FAILED, 'error');

export const logout = makeAction(Types.LOGOUT);

export const findMigrationsUser = makeAction(Types.FIND_MIGRATIONS_USER_REQUEST, 'data');
export const findMigrationsUserSucceeded = makeAction(Types.FIND_MIGRATIONS_USER_SUCCEEDED, 'data');
export const findMigrationsUserFailed = makeAction(Types.FIND_MIGRATIONS_USER_FAILED, 'error');

export const createWebsiteForMigration = makeAction(Types.CREATE_WEBSITE_FOR_MIGRATION_REQUEST, 'data');
export const createWebsiteForMigrationSucceeded = makeAction(Types.CREATE_WEBSITE_FOR_MIGRATION_SUCCEEDED, 'data');
export const createWebsiteForMigrationFailed = makeAction(Types.CREATE_WEBSITE_FOR_MIGRATION_FAILED, 'error');

export const createNewUserForMigration = makeAction(Types.CREATE_NEW_USER_FOR_MIGRATION_REQUEST, 'data');
export const createNewUserForMigrationSucceeded = makeAction(Types.CREATE_NEW_USER_FOR_MIGRATION_SUCCEEDED, 'data');
export const createNewUserForMigrationFailed = makeAction(Types.CREATE_NEW_USER_FOR_MIGRATION_FAILED, 'error');

export const newSubscriber = makeAction(Types.CREATE_NEW_SUBSCRIBER, 'data');
export const newSubscriberSucceeded = makeAction(Types.CREATE_NEW_SUBSCRIBER_SUCCEEDED, 'data');
export const newSubscriberFailed = makeAction(Types.CREATE_NEW_SUBSCRIBER_FAILED, 'error');

export const addVoucher = makeAction(Types.ADD_VOUCHER, 'data');
export const addVoucherSucceeded = makeAction(Types.ADD_VOUCHER_SUCCEEDED, 'data');
export const addVoucherFailed = makeAction(Types.ADD_VOUCHER_FAILED, 'error');

export const createAppsumoUser = makeAction(Types.CREATE_APPSUMO_USER_REQUEST, 'data');
export const createAppsumoUserSucceeded = makeAction(Types.CREATE_APPSUMO_USER_SUCCEEDED, 'data');
export const createAppsumoUserFailed = makeAction(Types.CREATE_APPSUMO_USER_FAILED, 'error');

export const getVouchers = makeAction(Types.GET_VOUCHERS);
export const getVouchersSucceeded = makeAction(Types.GET_VOUCHERS_SUCCEEDED, 'data');
export const getVouchersFailed = makeAction(Types.GET_VOUCHERS_FAILED, 'error');

export const getVouchersInfo = makeAction(Types.GET_VOUCHERS_INFO);
export const getVouchersInfoSucceeded = makeAction(Types.GET_VOUCHERS_INFO_SUCCEEDED, 'data');
export const getVouchersInfoFailed = makeAction(Types.GET_VOUCHERS_INFO_FAILED, 'error');

export const applyLifetimeDeal = makeAction(Types.APPLY_LIFETIME_DEAL_REQUEST, 'data');
export const applyLifetimeDealSucceeded = makeAction(Types.APPLY_LIFETIME_DEAL_SUCCEEDED, 'data');
export const applyLifetimeDealFailed = makeAction(Types.APPLY_LIFETIME_DEAL_FAILED, 'error');

export const getGoogleImporterAuthUrl = makeAction(Types.GET_GOOGLE_IMPORTER_AUTH_URL_REQUEST, 'userId');
export const getGoogleImporterAuthUrlSucceeded = makeAction(Types.GET_GOOGLE_IMPORTER_AUTH_URL_SUCCEEDED, 'data');
export const getGoogleImporterAuthUrlFailed = makeAction(Types.GET_GOOGLE_IMPORTER_AUTH_URL_FAILED, 'error');

export const getGoogleImporterViews = makeAction(Types.GET_GOOGLE_IMPORTER_VIEWS_REQUEST, 'queryParams');
export const getGoogleImporterViewsSucceeded = makeAction(Types.GET_GOOGLE_IMPORTER_VIEWS_SUCCEEDED, 'data');
export const getGoogleImporterViewsFailed = makeAction(Types.GET_GOOGLE_IMPORTER_VIEWS_FAILED, 'error');

export const startGoogleImporter = makeAction(Types.START_GOOGLE_IMPORTER_REQUEST, 'viewId');
export const startGoogleImporterSucceeded = makeAction(Types.START_GOOGLE_IMPORTER_SUCCEEDED, 'data');
export const startGoogleImporterFailed = makeAction(Types.START_GOOGLE_IMPORTER_FAILED, 'error');

export const getGoogleImporterProgress = makeAction(Types.GET_GOOGLE_IMPORTER_PROGRESS_REQUEST);
export const getGoogleImporterProgressSucceeded = makeAction(Types.GET_GOOGLE_IMPORTER_PROGRESS_SUCCEEDED, 'data');
export const getGoogleImporterProgressFailed = makeAction(Types.GET_GOOGLE_IMPORTER_PROGRESS_FAILED, 'error');
export const updateGoogleImporterProgress = makeAction(Types.UPDATE_GOOGLE_IMPORTER_PROGRESS);

export const getAgencyThemes = makeAction(Types.GET_AGENCY_THEMES_REQUEST);
export const getAgencyThemesSucceeded = makeAction(Types.GET_AGENCY_THEMES_SUCCEEDED, 'data');
export const getAgencyThemesFailed = makeAction(Types.GET_AGENCY_THEMES_FAILED, 'error');

export const getAgencyUi = makeAction(Types.GET_AGENCY_UI_REQUEST);
export const getAgencyUiSucceeded = makeAction(Types.GET_AGENCY_UI_SUCCEEDED, 'data');
export const getAgencyUiFailed = makeAction(Types.GET_AGENCY_UI_FAILED, 'error');

export const getAgencyQuaterlyCommission = makeAction(Types.GET_AGENCY_QUATERLY_COMMISSION_REQUEST);
export const getAgencyQuaterlyCommissionSucceeded = makeAction(Types.GET_AGENCY_QUATERLY_COMMISSION_SUCCEEDED, 'data');
export const getAgencyQuaterlyCommissionFailed = makeAction(Types.GET_AGENCY_QUATERLY_COMMISSION_FAILED, 'error');

export const changeWebsiteWhiteLabelStatus = makeAction(
  Types.CHANGE_WEBSITE_WHITE_LABEL_STATUS_REQUEST,
  'websiteId',
  'status',
);
export const changeWebsiteWhiteLabelStatusSucceeded = makeAction(Types.CHANGE_WEBSITE_WHITE_LABEL_STATUS_SUCCEEDED);
export const changeWebsiteWhiteLabelStatusFailed = makeAction(Types.CHANGE_WEBSITE_WHITE_LABEL_STATUS_FAILED, 'error');
