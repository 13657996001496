import { PureComponent } from 'react';

class CustomControls extends PureComponent {
  render() {
    let newVar = { ...this.props };
    delete newVar.controls;
    return this.props.controls(newVar);
  }
}

export default CustomControls;
