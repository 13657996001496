import { InputMessage, Paragraph, TextInput } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { ChangeEvent } from 'react';
import { ColorSelector } from '../../color-selector';
import { ColorPickerIcon } from './ColorPickerIcon';

type ThemeColorInputItemProps = {
  color: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSelectHexColor: (hexColor: string) => void;
  clearField: () => void;
  onBlur: (e: React.FocusEvent<any, Element>) => void;
  label: string;
  info?: string;
  name: string;
  error?: string;
};

export const ThemeColorInputItem: React.FC<ThemeColorInputItemProps> = ({
  color,
  onChange,
  clearField,
  label,
  info,
  name,
  onSelectHexColor,
  error,
}) => {
  return (
    <div>
      <div className='flex items-center justify-between gap-5'>
        <TextInput
          required={true}
          wrapperClassName='flex-grow'
          bgColor='#F0F0F0'
          name={name}
          clearField={clearField}
          onChange={onChange}
          label={label}
          value={color}
          autoComplete={'off'}
        />
        <TooltipWrapper
          trigger='click'
          useDefaultStyle={false}
          interactive
          arrow={false}
          content={<ColorSelector color={color} onChange={onSelectHexColor} />}
          tooltipClassNames='max-w-[230px]'
        >
          <div>
            <ColorPickerIcon className='shrink-0' fillColor={color} />
          </div>
        </TooltipWrapper>
      </div>
      {error && <InputMessage error={error} />}
      {info && (
        <Paragraph className='ml-5 mt-2' colorClassName='text-gray-devil'>
          {info}
        </Paragraph>
      )}
    </div>
  );
};
