import { useTranslate } from '@va/localization';
import { ConfirmationDialogProps, ConfirmationModal } from '@va/shared/util-confirmation';

const WhiteLabelConfirmationModal: React.FC<ConfirmationDialogProps> = ({ onClose, onConfirm }) => {
  const translate = useTranslate();

  return (
    <ConfirmationModal
      info={translate('modal.removeWhiteLabel.info')}
      title={translate('modal.removeWhiteLabel.title')}
      onClose={onClose}
      onConfirmation={onConfirm}
      isOpen={true}
      actionButtonText={translate('button.confirm')}
    />
  );
};

export default WhiteLabelConfirmationModal;
