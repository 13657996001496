import { Order } from '@va/types/events';
import { Direction } from '@va/types/table';

export const orderMap = {
  count: 'count',
  percentOfTotal: 'percentOfTotal',
};

export const AllowedOrders = Object.values(orderMap);

export const DEFAULT_ORDER: Order[] = [
  {
    member: orderMap.count,
    direction: Direction.DESC,
  },
];
