import { reportType, validationTranslationKeys } from '@va/constants';
import { getNotificationsCardOptions, getWebsite } from '@va/dashboard/selectors/core';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  EmailItem,
  useAddNotificationSettingsEmail,
  useGetNotificationSettings,
  useRemoveNotificationSettingsEmail,
  useResendNotificationSettingsEmail,
  useUpdateNotificationSettings,
} from '../apiClient';

const emailSchema = yup.string().email();

export enum ReportTypeEnum {
  daily = 0,
  monthly = 1,
  yearly = 2,
  never = 3,
}

const initialState = {
  reportType: undefined,
  emailList: undefined,
};

type FormValuesType = {
  reportType: ReportTypeEnum | undefined;
  emailList: EmailItem[] | undefined;
};

export type FrequencyType = {
  label: string;
  value: string;
  disabled: boolean;
};

const useNotificationPreferences = () => {
  const [formValues, setFormValues] = useState<FormValuesType>(initialState);
  const [emailAddress, setEmailAddress] = useState('');
  const [formError, setFormError] = useState<string>('');

  const { showSuccessNotification, showErrorNotification } = useAddNotification();

  const {
    data: notificationSettings,
    isLoading: isLoadingGettingNotificationSettings,
    mutate: mutateNotificationSettings,
  } = useGetNotificationSettings();

  const { execute: updateNotificationSettings, isSucceeded: isNotificationSettingsUpdateSucceeded } =
    useUpdateNotificationSettings();
  const { execute: addNotificationSettingsEmail } = useAddNotificationSettingsEmail();

  const { execute: resendEmailConfirmation } = useResendNotificationSettingsEmail();

  const { execute: removeEmail } = useRemoveNotificationSettingsEmail();

  const website = useSelector(getWebsite);
  const frequencyOptions = getNotificationsCardOptions().data;
  const translate = useTranslate();

  const hasModifiedEmailAddress = useMemo(() => emailAddress !== website.email, [emailAddress, website.email]);

  // Populate initial state for the form values
  useEffect(() => {
    if (!notificationSettings) return;
    setFormValues({
      reportType: notificationSettings.reportType,
      emailList: notificationSettings.emailList,
    });
  }, [notificationSettings]);

  const onReportsChange = useCallback(
    async (val: { name: string; value: boolean }) => {
      try {
        const { value } = val;
        let newReportType: { reportType: ReportTypeEnum };
        if (value === false) {
          newReportType = { reportType: ReportTypeEnum.never };
        } else {
          newReportType = { reportType: ReportTypeEnum.daily };
        }
        setFormValues((p) => ({ ...p, newReportType }));
        await updateNotificationSettings(newReportType);
        showSuccessNotification();
      } catch (err) {
        showErrorNotification();
      } finally {
        mutateNotificationSettings();
      }
    },
    [mutateNotificationSettings, showErrorNotification, showSuccessNotification, updateNotificationSettings],
  );

  const onFrequencyChange = useCallback(
    async (val: { name: string; value: number }) => {
      try {
        const { name, value } = val;
        const newFormValues = {
          ...formValues,
          [name]: value,
        };
        setFormValues(newFormValues);
        await updateNotificationSettings(newFormValues);
        mutateNotificationSettings();
        showSuccessNotification();
      } catch (err) {
        showErrorNotification();
      }
    },
    [
      formValues,
      mutateNotificationSettings,
      showErrorNotification,
      showSuccessNotification,
      updateNotificationSettings,
    ],
  );

  const emailError = useCallback(
    (email: string) => {
      const isValid = emailSchema.isValidSync(email);
      if (!isValid) return translate(validationTranslationKeys.invalidEmail);
      return null;
    },
    [translate],
  );

  const onChangeEmail = useCallback(
    (value: string) => {
      const error = emailError(value);
      if (error) {
        setFormError(error);
      } else {
        setFormError('');
      }
      setEmailAddress(value);
    },
    [emailError],
  );

  const clearEmailField = useCallback(() => {
    setEmailAddress('');
  }, []);

  const getFrequency = useCallback(() => {
    const { DAILY, WEEKLY, MONTHLY } = reportType;
    const opt = [DAILY, WEEKLY, MONTHLY];
    const frequency: FrequencyType[] = [];
    frequencyOptions.map((option) => {
      if (opt.includes(option.value)) {
        const obj: FrequencyType = {
          label: translate(`form.emailReports.${option.type}`),
          value: option.value.toString(),
          disabled: formValues.reportType === ReportTypeEnum.never,
        };
        frequency.push(obj);
      }
      return option;
    });
    return frequency;
  }, [formValues.reportType, frequencyOptions, translate]);

  const selectedFrequency = useMemo(() => {
    const frequency = getFrequency();
    const selected = frequency.find((val) => val.value === String(formValues.reportType));
    return selected?.value || '';
  }, [getFrequency, formValues]);

  const handleSaveEmailError = useCallback(
    (error: string | undefined) => {
      const ALREADY_EXISTING_EMAIL = 'ALREADY_EXISTING_EMAIL';
      if (error === ALREADY_EXISTING_EMAIL) {
        showErrorNotification(translate('all.generalSettings.notificationPreferences.notification.emailAlreadyExists'));
        return;
      }
      showErrorNotification();
    },
    [showErrorNotification, translate],
  );

  const onSaveEmail = useCallback(async () => {
    try {
      if (!emailAddress) return;
      const error = emailError(emailAddress);
      if (error) {
        setFormError(error);
        return;
      } else {
        setFormError('');
      }
      await addNotificationSettingsEmail({ email: emailAddress });
      clearEmailField();
      showSuccessNotification(3000, translate('all.generalSettings.notificationPreferences.notification.addEmail'));
      mutateNotificationSettings();
    } catch (err: unknown) {
      // @ts-ignore
      handleSaveEmailError(err?.message);
    }
  }, [
    emailAddress,
    emailError,
    addNotificationSettingsEmail,
    clearEmailField,
    showSuccessNotification,
    translate,
    mutateNotificationSettings,
    handleSaveEmailError,
  ]);

  const isSaveEmailButtonDisabled = useMemo(
    () => isLoadingGettingNotificationSettings || isNotificationSettingsUpdateSucceeded,
    [isLoadingGettingNotificationSettings, isNotificationSettingsUpdateSucceeded],
  );

  const allEmails = useMemo(() => notificationSettings?.emailList, [notificationSettings?.emailList]);

  const onRemoveEmail = useCallback(
    async (email: string) => {
      try {
        await removeEmail({ email });
        mutateNotificationSettings();
        showSuccessNotification();
      } catch (err) {
        showErrorNotification();
      }
    },
    [mutateNotificationSettings, removeEmail, showErrorNotification, showSuccessNotification],
  );

  const onResendEmailConfirmation = useCallback(
    async (email: string) => {
      try {
        await resendEmailConfirmation({ email });
        mutateNotificationSettings();
        showSuccessNotification(
          3000,
          translate('all.generalSettings.notificationPreferences.notification.resendEmail'),
        );
      } catch (err) {
        showErrorNotification();
      }
    },
    [mutateNotificationSettings, resendEmailConfirmation, showErrorNotification, showSuccessNotification, translate],
  );

  return {
    formValues,
    emailAddress,
    onFrequencyChange,
    onChangeEmail,
    clearEmailField,
    getFrequency,
    selectedFrequency,
    isLoadingGettingNotificationSettings,
    formError,
    hasModifiedEmailAddress,
    isSaveEmailButtonDisabled,
    onSaveEmail,
    onResendEmailConfirmation,
    onReportsChange,
    allEmails,
    onRemoveEmail,
    isNotificationSettingsUpdateSucceeded,
  };
};

export default useNotificationPreferences;
