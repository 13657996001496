import { useEffect } from 'react';
import RequestCanceler from './RequestCanceler';

export const useCancelOnUnmount = (reqId: string) => {
  useEffect(() => {
    return () => {
      RequestCanceler.cancelReq(reqId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
