import { Selectors3asFPermissions } from '@va/aaas/shared/selectors';
import { is3asApp, isWixMiniApp } from '@va/util/helpers';
import { getFeaturePermissions } from '.';

type FeaturePermissions = {
  customDashboard?: boolean;
  ip2company?: boolean;
  visitorSegments?: boolean;
  countryBasedPrivacy?: boolean;
  feedbackAdvertising?: boolean;
};

export const isCompanyRevealFeatureEnabled = (state: any) => {
  if (is3asApp()) {
    return Selectors3asFPermissions.isEnabled.ipToCompany(state);
  }

  const featurePermissions = getFeaturePermissions(state) as FeaturePermissions;
  return !!featurePermissions.ip2company;
};

export const isVisitorSegmentsFeatureEnabled = (state: any) => {
  if (is3asApp()) {
    return Selectors3asFPermissions.isEnabled.visitorSegments(state);
  }
  const featurePermissions = getFeaturePermissions(state) as FeaturePermissions;
  return !!featurePermissions.visitorSegments;
};

export const isCountryBasedPrivacyModeEnabled = (state: any) => {
  if (is3asApp() || isWixMiniApp()) {
    return Selectors3asFPermissions.isEnabled.countryBasedPrivacy(state);
  }

  const featurePermissions = getFeaturePermissions(state) as FeaturePermissions;
  return !!featurePermissions.countryBasedPrivacy;
};

export const isCustomDashboardFeatureEnabled = (state: any) => {
  if (is3asApp()) {
    return Selectors3asFPermissions.isEnabled.customDashboards(state);
  }

  const featurePermissions = getFeaturePermissions(state) as FeaturePermissions;
  return !!featurePermissions.customDashboard;
};

export const isFeedbackAdvertisingFeatureEnabled = (state: any) => {
  if (is3asApp()) {
    // Option is not visible in 3as
    return false;
  }

  const featurePermissions = getFeaturePermissions(state) as FeaturePermissions;
  return !!featurePermissions.feedbackAdvertising;
};
