import { BLACK_COLOR } from '@va/constants';
import { TestAttributes } from '@va/types/component';
import { TooltipWrapper } from '@va/ui/tooltips';
import { DropdownArrow } from '@va/util/components';
import classNames from 'classnames';
import { ElementType, useState } from 'react';

type NestedPageNavigationTabProps = TestAttributes & {
  label: string;
  tooltip?: string;
  icon?: ElementType;
  className?: string;
  activeColor?: string;
  isActive: boolean;
  hasTooltip: boolean;
  content: JSX.Element;
};

export const NestedPageNavigationTab = ({
  label,
  icon: Icon,
  className,
  activeColor = '#3C3C3C',
  isActive,
  hasTooltip,
  content,
  ...rest
}: NestedPageNavigationTabProps) => {
  const [isContentVisible, setIsContentVisible] = useState(false);

  return (
    <TooltipWrapper
      useDefaultStyle={false}
      arrow={false}
      trigger='click'
      content={{
        ...content,
        props: {
          ...content.props,
          onClose: () => {
            setIsContentVisible(false);
          },
        },
      }}
      open={isContentVisible}
      interactive
      tooltipClassNames='bg-white rounded-18px w-[440px]'
      onOpenChange={(open) => setIsContentVisible(open)}
    >
      <div
        data-testid={rest['data-testid']}
        id='nested-nav-link-wrapper'
        className='flex items-center relative cursor-pointer'
      >
        <span
          style={{ color: isActive ? activeColor : undefined }}
          className={classNames(
            `whitespace-nowrap active:bg-gray-concrete py-3 px-4 text-15 transition font-medium text-center flex items-center gap-2`,
            {
              'text-gray-charcoal hover:text-mine-shaft hover:bg-alabaster rounded-lg sm:rounded-none': !isActive,
            },
            className,
          )}
        >
          {Icon && (
            <Icon
              isActive={isActive}
              color={isActive ? activeColor : BLACK_COLOR}
              className='hidden sm-initial:block'
            />
          )}
          <span
            className={classNames({
              'underline-dashed': hasTooltip,
            })}
          >
            {label}
          </span>

          <span
            className={classNames(
              'flex items-center justify-center hover:bg-white-snow rounded-md h-[20px] w-[20px] cursor-pointer',
            )}
          >
            <DropdownArrow color={isActive ? activeColor : '#3C3C3C'} open={isContentVisible} />
          </span>
        </span>
        <div
          className='absolute bottom-0 left-0 right-0 h-[1px]'
          style={{
            backgroundColor: isActive ? activeColor : 'transparent',
          }}
        />
      </div>
    </TooltipWrapper>
  );
};
