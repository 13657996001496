import classNames from 'classnames';
import React, { ChangeEvent, FocusEventHandler } from 'react';
import { InputMessage } from '../text-input/TextInput';

type CheckboxInputProps = {
  checked: boolean;
  label?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: FocusEventHandler;
  id: string;
  name: string;
  error?: string;
  labelClassName?: string;
};

export const CheckboxInput: React.FC<CheckboxInputProps> = ({
  checked,
  label,
  onChange,
  id,
  name,
  error,
  onBlur,
  labelClassName,
}) => {
  return (
    <div>
      <label
        htmlFor={id}
        className={classNames(
          'text-gray-devil font-medium text-12 flex items-center gap-3 font-primary',
          labelClassName,
        )}
      >
        <input onBlur={onBlur} id={id} name={name} type='checkbox' onChange={onChange} />
        <span className='w-4 h-4 border-[1.5px] rounded-[4.5px] border-gray-silver shrink-0 flex items-center justify-center'>
          {checked && <span className='shrink-0 w-3 h-3 bg-primary rounded-[2.5px]'></span>}
        </span>
        <p>{label}</p>
      </label>
      {error && <InputMessage error={error} />}
    </div>
  );
};
