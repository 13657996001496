import { createEventTagModalView, validationTranslationKeys } from '@va/constants';
import { useTranslate } from '@va/localization';
import { Button, TextInput } from '@va/ui/design-system';
import { Formik, FormikProps, FormikValues } from 'formik';
import React from 'react';
import { number, object, string } from 'yup';

const formFieldNames = {
  eventCategory: 'eventCategory',
  eventAction: 'eventAction',
  eventLabel: 'eventLabel',
  eventValue: 'eventValue',
  fieldsObject: 'fieldsObject',
  bindingAction: 'bindingAction',
};

interface MyFormValues extends FormikValues {
  eventCategory: string;
  eventAction: string;
  eventLabel: string | null;
  eventValue: number | null;
  fieldsObject: string | null;
  bindingAction: string | null;
}

const formInitialValues: MyFormValues = {
  eventCategory: '',
  eventAction: '',
  eventLabel: null,
  eventValue: null,
  fieldsObject: null,
  bindingAction: null,
};

type CreateEventTagFormProps = {
  handleModalViewSwitch: Function;
  setCodeSnippet: Function;
};

const CreateEventTagForm: React.FC<CreateEventTagFormProps> = ({ handleModalViewSwitch, setCodeSnippet }) => {
  const translate = useTranslate();

  function handleFormSubmission(values: MyFormValues) {
    setCodeSnippet(
      `va("send", "event", "${values.eventCategory}", "${values.eventAction}", ${JSON.stringify(values.eventLabel)}, ${
        values.eventValue
      }, ${JSON.stringify(values.fieldsObject)})`,
    );
    handleModalViewSwitch(
      createEventTagModalView.COPY_CREATED_EVENT_TAG_SNIPPET,
      createEventTagModalView.CREATE_EVENT_TAG_VIEW,
    );
  }

  return (
    <div className='flex'>
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={businessInfoSchema}
        onSubmit={handleFormSubmission}
      >
        {({ handleSubmit, values, setFieldValue, errors, handleChange }: FormikProps<MyFormValues>) => {
          const clearField = (field: string) => {
            setFieldValue(field, '');
          };
          return (
            <form onSubmit={handleSubmit}>
              <div>
                <TextInput
                  name={formFieldNames.eventCategory}
                  value={values[formFieldNames.eventCategory]}
                  onChange={handleChange}
                  clearField={() => clearField(formFieldNames.eventCategory)}
                  label={translate('createEventTag.eventCategory.label')}
                  placeholder={translate('createEventTag.eventCategory.example')}
                  info={translate('createEventTag.eventCategory.info')}
                  error={translate(errors[formFieldNames.eventCategory] as string)}
                  infoTooltipText={translate('createEventTag.eventCategory.infoTooltip')}
                  required
                  autoFocus
                />
                <TextInput
                  name={formFieldNames.eventAction}
                  value={values[formFieldNames.eventAction]}
                  onChange={handleChange}
                  clearField={() => clearField(formFieldNames.eventAction)}
                  label={translate('createEventTag.eventAction.label')}
                  placeholder={translate('createEventTag.eventAction.example')}
                  info={translate('createEventTag.eventAction.info')}
                  error={translate(errors[formFieldNames.eventAction] as string)}
                  infoTooltipText={translate('createEventTag.eventAction.infoTooltip')}
                  required
                />
                <TextInput
                  name={formFieldNames.eventLabel}
                  value={values[formFieldNames.eventLabel]}
                  onChange={handleChange}
                  clearField={() => clearField(formFieldNames.eventLabel)}
                  label={translate('createEventTag.eventLabel.label')}
                  placeholder={translate('createEventTag.eventLabel.example')}
                  info={translate('createEventTag.eventLabel.info')}
                  infoTooltipText={translate('createEventTag.eventLabel.infoTooltip')}
                />
                <div className='flex flex-col sm-initial:flex-row sm-initial:gap-2 w-full'>
                  <TextInput
                    name={formFieldNames.eventValue}
                    value={values[formFieldNames.eventValue]}
                    onChange={handleChange}
                    clearField={() => clearField(formFieldNames.eventValue)}
                    label={translate('createEventTag.eventValue.label')}
                    placeholder={translate('createEventTag.eventValue.example')}
                    info={translate('createEventTag.eventValue.info')}
                    error={translate(errors[formFieldNames.eventValue] as string)}
                    infoTooltipText={translate('createEventTag.eventValue.infoTooltip')}
                    wrapperClassName='flex-1'
                  />
                  <TextInput
                    name={formFieldNames.fieldsObject}
                    value={values[formFieldNames.fieldsObject]}
                    onChange={handleChange}
                    clearField={() => clearField(formFieldNames.fieldsObject)}
                    label={translate('createEventTag.fieldsObject.label')}
                    placeholder={translate('createEventTag.fieldsObject.example')}
                    info={translate('createEventTag.fieldsObject.info')}
                    wrapperClassName='flex-1'
                    infoTooltipText={translate('createEventTag.fieldsObject.infoTooltip')}
                  />
                </div>
                {/* TODO-  un-comment this code after finding some other solution */}
                {/* <EventField info={translate('createEventTag.bindingAction.info')} infoTooltipText={translate('createEventTag.bindingAction.infoTooltip')}>
                  <DropDown
                    name={formFieldNames.bindingAction}
                    placeholder={translate('createEventTag.actions.chooseAction')}
                    text={translate('createEventTag.bindingAction.label')}
                    options={events}
                    onChange={(option: { label: string; value: string | number }) =>
                      setFieldValue(formFieldNames.bindingAction, option.value)
                    }
                    value={values[formFieldNames.bindingAction]}
                  />
                </EventField> */}
                <div className='flex sm:flex-col mt-6px'>
                  {/* un-comment this button when we have settings */}
                  {/* <Button
                    text={translate('eventTracking.eventTrackingSettings')}
                    color='secondary'
                    disabled={false}
                    className='w-full justify-center mr-[18px] sm:mr-0 mb-0 sm:mb-4'
                    onClick={() => {}}
                  /> */}
                  <Button
                    text={translate('eventTracking.generateEventTag')}
                    type='submit'
                    color='primary'
                    className='w-full justify-center'
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

const businessInfoSchema = object().shape({
  eventCategory: string().required(validationTranslationKeys.required),
  eventAction: string().required(validationTranslationKeys.required),
  eventLabel: string().nullable(),
  eventValue: number().typeError(validationTranslationKeys.number).nullable(),
  fieldsObject: string().nullable(),
});

export default CreateEventTagForm;
