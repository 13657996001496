import { buttonGroupTheme } from '@va/constants';
import classNames from 'classnames';
import { FC, memo } from 'react';
import './ButtonGroupV2.scss';

type Option = {
  value: any; //string
  label: string | JSX.Element;
  description?: string;
  count?: number;
};

type ButtonGroupProps = {
  options: Option[];
  className?: string;
  onChange: (value: any) => void;
  value: any;
  theme?: string;
  noWidth?: boolean;
  borderRadius?: { leftBorderRadius: string; rightBorderRadius: string };
  disabled?: boolean;
  btnClassName?: string;
};

const ButtonGroupV2: FC<ButtonGroupProps> = memo(function ButtonGroupV2({
  options,
  className,
  onChange,
  value,
  theme,
  noWidth,
  borderRadius = { leftBorderRadius: 'rounded-l-lg', rightBorderRadius: 'rounded-r-lg' },
  disabled,
  btnClassName,
}) {
  return (
    <div className={classNames('flex', className)}>
      {options.map((option, index) => {
        const isSelected = value === option.value;
        const isFirst = index === 0;
        const isLast = index === options.length - 1;

        return (
          <Button
            className={classNames(
              { '-ml-2px': !isFirst, 'cursor-default': disabled },
              { [`${borderRadius.leftBorderRadius} border-l-2`]: isFirst },
              { [`${borderRadius.rightBorderRadius} border-r-2`]: isLast },
              btnClassName,
            )}
            width={!noWidth ? `${(1 / options.length) * 100}%` : undefined}
            isSelected={isSelected}
            key={index}
            option={option}
            onClick={onChange}
            theme={theme}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
});

export default ButtonGroupV2;

type ButtonProps = {
  option: Option;
  isSelected: boolean;
  width?: string;
  onClick: (value: any) => void;
  className?: string;
  theme?: string;
  disabled?: boolean;
};

const Button: FC<ButtonProps> = memo(function Button({
  option,
  isSelected,
  width,
  onClick,
  className,
  theme,
  disabled,
}) {
  let commonStyles = 'relative focus:outline-none font-medium text-15 py-3 border-2';
  let selectedButtonStyle = 'z-1 border-devil-gray';
  const defaultButtonStyle = 'text-devil-gray border-alto';

  if (theme === buttonGroupTheme.GREEN) {
    selectedButtonStyle =
      'add-double-border z-1 border-green-persian text-green-persian bg-green-persian-200 bg-green-aquaSpring';
    commonStyles = `relative focus:outline-none font-medium border-2 text-20 button-group-green-theme`;
  }

  return (
    <button
      type='button'
      onClick={() => !disabled && onClick(option.value)}
      style={{ width }}
      className={classNames(commonStyles, isSelected ? selectedButtonStyle : defaultButtonStyle, className)}
    >
      <div className={classNames('flex justify-center', { 'flex-column': option.description })}>
        <span>{option.label}</span>
        {option.description && <span className='mt-2 text-12 font-medium leading-5 px-3'>{option.description}</span>}
        {option.count && <span className='ml-1'>({option.count})</span>}
      </div>
    </button>
  );
});
