import { differenceBy, isEqual } from 'lodash';

/**
 * Creates an empty array of N elements
 * @param {number} n
 * @returns array
 */
// eslint-disable-next-line prefer-spread
export const emptyArrayOfN = (n: number) => Array.apply(null, Array(n));

/**
 * Specifies if two arrays are equal
 * @param {array} arr1
 * @param {array} arr2
 * @returns
 */
export const areArraysEqual = (arr1: Array<unknown>, arr2: Array<unknown>) => {
  return isEqual(arr1.sort(), arr2.sort());
};

export const areArrayObjectsEqual = (arrOne: Array<any> = [], arrTwo: Array<any> = [], filterBy: string = '') => {
  const result = [...differenceBy(arrOne, arrTwo, filterBy), ...differenceBy(arrTwo, arrOne, filterBy)].length;
  return Boolean(result);
};
