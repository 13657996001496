import Immutable from 'immutable';
import moment from 'moment';
import { reducer as formReducer } from 'redux-form/immutable';
import apiReducer from './api';
import appReducer from './app';
import { combineReducers } from './reducers.helpers';
import uiReducer from './ui';

import {
  AUTHENTICATE,
  AUTHENTICATE_FAILED,
  AUTHENTICATE_SUCCEEDED,
  CREATE_WEBSITE_FAILED,
  CREATE_WEBSITE_REQUEST,
  CREATE_WEBSITE_SUCCEEDED,
  WEBSITE_FAILED,
  WEBSITE_REQUEST,
  WEBSITE_SUCCEEDED,
  WEBSITE_UPDATE,
} from '@va/dashboard/actions/api';

import {
  GENERATE_IMPERSONATE_TOKEN,
  GENERATE_IMPERSONATE_TOKEN_FAILED,
  GENERATE_IMPERSONATE_TOKEN_SUCCEEDED,
} from '@va/dashboard/actions/api';

export const authentication = (
  state = Immutable.Map({
    inProgress: false,
    token: null,
    instanceId: null,
  }),
  action,
) => {
  switch (action.type) {
    case AUTHENTICATE:
    case GENERATE_IMPERSONATE_TOKEN:
      return state.set('inProgress', true);
    case AUTHENTICATE_SUCCEEDED:
    case GENERATE_IMPERSONATE_TOKEN_SUCCEEDED:
      return state.merge(action.data).set('inProgress', false);
    case AUTHENTICATE_FAILED:
    case GENERATE_IMPERSONATE_TOKEN_FAILED:
      return state.set('inProgress', false);
    default:
      return state;
  }
};

const defaultWebsiteState = Immutable.Map({
  email: '',
  inProgress: false,
  premiumRequest: false,
  hasFirstLogin: false,
  exists: false,
  installDate: moment().unix(),
});

export const website = (state = defaultWebsiteState, action) => {
  switch (action.type) {
    case WEBSITE_REQUEST:
    case CREATE_WEBSITE_REQUEST:
      return defaultWebsiteState.set('isPayable', state.get('isPayable')).set('inProgress', true);
    case WEBSITE_SUCCEEDED:
    case CREATE_WEBSITE_SUCCEEDED:
      return state.merge(action.data).merge({
        inProgress: false,
        exists: true,
        email: action.data.email || '',
        hasFirstLogin: action.data.hasFirstLogin,
        businessSector: action.data.businessSector,
      });
    case WEBSITE_FAILED:
    case CREATE_WEBSITE_FAILED:
      return state.merge({
        inProgress: false,
        exists: false,
      });
    case WEBSITE_UPDATE: {
      return state.merge(action.data);
    }
    default:
      return state;
  }
};

export const coreReducer = combineReducers({
  ui: uiReducer,
  api: apiReducer,
  app: appReducer,
  authentication,
  website,
  form: formReducer,
});
