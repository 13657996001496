import classNames from 'classnames';
import './DottedLoadingIndicator.scss';

const DOTS_COUNT = 5;

type DottedLoadingIndicatorProps = {
  className?: string;
};

export const DottedLoadingIndicator = ({ className }: DottedLoadingIndicatorProps) => {
  return (
    <div className={classNames('va-dotted-loading-indicator', className)}>
      {Array.from(Array(DOTS_COUNT)).map((_, index) => (
        <div key={index} className='va-dotted-loading-indicator__dot'>
          <div />
        </div>
      ))}
    </div>
  );
};
