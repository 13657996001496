import { positionChangeAction, QUESTION_TYPE, RATING_SHAPE } from '@va/dashboard/modules/feedback/shared';
import { BinIcon, DuplicateIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { ArrowDown, ArrowUp } from '@va/svg-visa-icons';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { memo, useEffect, useMemo } from 'react';
import Action from '../dropdowns/options-dropdown/Action';
import BoolSetting from '../dropdowns/options-dropdown/BoolSetting';
import DropdownSetting from '../dropdowns/options-dropdown/DropdownSetting';
import OptionsDropdown from '../dropdowns/options-dropdown/OptionsDropdown';
import { OptionsSection } from '../dropdowns/options-dropdown/OptionsSection';
import SliderSetting from '../dropdowns/options-dropdown/SliderSetting';

const QuestionOptions = ({ onDuplicate, onDelete, name, number, totalQuestions, onPositionChange }) => {
  const translate = useTranslate();
  const { getFieldProps, setFieldValue } = useFormikContext();
  const { type, shape, steps, description, hasDescription, multipleChoice, labels } = getFieldProps(name).value;

  useEffect(() => {
    if (description) {
      setFieldValue(`${name}.hasDescription`, true);
    }
  }, [description, hasDescription, name, setFieldValue]);

  const optionalFields = useMemo(() => {
    return computeOptionalFields(type);
  }, [type]);

  const ratingShapeOptions = useMemo(
    () =>
      Object.keys(RATING_SHAPE).map((item) => {
        return {
          label: translate(`feedback.question.shape.${item.toLowerCase()}`),
          value: RATING_SHAPE[item],
        };
      }),
    [translate],
  );

  return (
    <OptionsDropdown>
      <OptionsSection first title={translate('feedback.question.settings')}>
        <BoolSetting
          postUpdate={(hasDescription) => !hasDescription && setFieldValue(`${name}.description`, '')}
          name={`${name}.hasDescription`}
          initialValue={hasDescription}
        >
          {translate('feedback.question.requireDescription.label')}
        </BoolSetting>
        {/* TODO should be enabled for survey */}
        {/* <BoolSetting name={`${name}.required`}>{translate('feedback.question.requireAnswer.label')}</BoolSetting> */}

        {optionalFields.multipleChoice && (
          <BoolSetting
            name={`${name}.multipleChoice`}
            description={translate('feedback.question.allowMultipleChoices.description')}
            initialValue={multipleChoice}
          >
            {translate('feedback.question.allowMultipleChoices.label')}
          </BoolSetting>
        )}

        {optionalFields.steps && (
          <SliderSetting min={3} max={7} name={`${name}.steps`} initialValue={steps}>
            {translate('feedback.question.steps.label')}
          </SliderSetting>
        )}

        {optionalFields.enableLabels && (
          <BoolSetting name={`${name}.labels`} initialValue={labels}>
            {translate('feedback.question.enableLabels.label')}
          </BoolSetting>
        )}

        {optionalFields.shape && (
          <DropdownSetting
            name={`${name}.shape`}
            options={ratingShapeOptions}
            initialValue={ratingShapeOptions.find((item) => item.value === shape)}
          >
            {translate('feedback.question.shape.label')}
          </DropdownSetting>
        )}
      </OptionsSection>

      <OptionsSection title={translate('feedback.question.options')}>
        {/* TODO will be added later with the question bank */}
        {/* <Action onClick={onSave} icon={<PlusIcon className='stroke-current text-devil-gray' />}>
          Save to Question Bank
        </Action> */}
        {number !== 1 && (
          <Action
            onClick={() => onPositionChange(positionChangeAction.moveUp)}
            icon={<ArrowUp color={'#000000'} className={'icon'} />}
          >
            {translate('feedback.question.moveUp')}
          </Action>
        )}

        {number !== totalQuestions && (
          <Action
            onClick={() => onPositionChange(positionChangeAction.moveDown)}
            icon={<ArrowDown color={'#000000'} className={'icon'} />}
          >
            {translate('feedback.question.moveDown')}
          </Action>
        )}

        <Action onClick={() => onDuplicate(getFieldProps(name).value)} icon={<DuplicateIcon />}>
          {translate('feedback.question.duplicate')}
        </Action>

        <Action onClick={onDelete} icon={<BinIcon />}>
          {translate('feedback.question.delete')}
        </Action>
      </OptionsSection>
    </OptionsDropdown>
  );
};

QuestionOptions.propTypes = {
  onDuplicate: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default memo(QuestionOptions);

function computeOptionalFields(questionType) {
  const fields = {
    multipleChoice: false,
    enableLabels: false,
    steps: false,
    shape: false,
  };

  if (questionType === QUESTION_TYPE.MULTIPLE_CHOICE) {
    fields.multipleChoice = true;
  }

  if (questionType === QUESTION_TYPE.RATING_SCALE) {
    fields.steps = 3;
    fields.enableLabels = true;
  }

  if (questionType === QUESTION_TYPE.SLIDER) {
    fields.enableLabels = true;
  }

  if (questionType === QUESTION_TYPE.RATING) {
    fields.enableLabels = true;
    fields.steps = 3;
    fields.shape = RATING_SHAPE.STAR;
  }

  return fields;
}
