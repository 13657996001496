import React from 'react';
import PropTypes from 'prop-types';

const Year = ({ years, setYear, asPrevOrNext = false }) => {
  return (
    <div className='flex flex-wrap'>
      {years.map(year => {
        return (
          <div key={year} className='w-1/2 px-0.5'>
            <span className='flex rounded-md mt-1.5'>
              <button
                type='button'
                className='btn-class relative px-3 py-2 block w-full leading-6 rounded-md bg-white text-xs 2xl:text-sm tracking-wide font-medium transition-colors uppercase border border-transparent hover:bg-white-snow focus:bg-primary-50 focus:border-primary focus:ring focus:ring-1 focus:ring-primary focus:ring-opacity-10 focus:outline-none '
                onClick={setYear.bind(this, year, asPrevOrNext)}
              >
                {year}
              </button>
            </span>
          </div>
        );
      })}
    </div>
  );
};
export default React.memo(Year);

Year.propTypes = {
  setYear: PropTypes.func,
  asPrevOrNext: PropTypes.bool,
  years: PropTypes.array,
};
