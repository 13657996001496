import { Component, PropsWithChildren } from 'react';

export class ErrorBoundary extends Component<PropsWithChildren> {
  static getDerivedStateFromError(error: any) {
    console.error('An error has ocurred!', JSON.stringify(error, null, 2));
    const chunkFailedMessage = /chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      console.error('Reloading to ensure chunk reload...');
      window.location.reload();
    }
  }

  override componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo);
  }

  override render() {
    return this.props.children;
  }
}
