import { FallbackIconCircle } from '@va/icons';
import { getOriginUrl } from '@va/util/helpers';
import classNames from 'classnames';
import { useMemo } from 'react';

type FaviconProps = {
  baseUrl?: string;
  size?: 'small' | 'medium' | 'large';
};

// VISA-9686
const FAVICON_EXCEPTIONS = ['localhost', 'mail.google.com'];

export function Favicon({ baseUrl, size = 'medium' }: FaviconProps) {
  const showFallback = useMemo(() => {
    if (!baseUrl) return true;
    return FAVICON_EXCEPTIONS.some((exception) => baseUrl.includes(exception));
  }, [baseUrl]);

  const sizeClassName = useMemo(
    () =>
      classNames({
        'w-22px h-22px': size === 'medium' || size === 'large',
        'w-16px h-16px': size === 'small',
      }),
    [size],
  );

  if (!baseUrl || showFallback) {
    return <FallbackIconCircle className={sizeClassName} />;
  }
  const url = getOriginUrl(baseUrl);

  return (
    <object
      className={classNames('flex justify-center items-center overflow-hidden', sizeClassName)}
      data={`${url}/favicon.ico`}
      type='image/x-icon'
    >
      <FallbackIconCircle className={sizeClassName} />
    </object>
  );
}
