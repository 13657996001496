import { IconProps } from './index';

export const ArrowIcon = ({ className = '', color }: IconProps) => {
  return (
    <svg
      className={className}
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_11485_114920'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='12'
        height='12'
      >
        <path d='M3 4.5L6 1.5L9 4.5' stroke='black' strokeWidth='1.5' />
        <path d='M9 7.5L6 10.5L3 7.5' stroke='black' strokeWidth='1.5' />
      </mask>
      <g mask='url(#mask0_11485_114920)'>
        <rect x='-3' y='-3' width='18' height='18' fill={color ?? 'var(--color-primary)'} />
      </g>
    </svg>
  );
};


