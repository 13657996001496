import { apiStatus, CONSENT_FLAG, modalName, REQUIRED_COLOR, tabNames } from '@va/constants';
import { setUserFlag, updateWebsiteFlags } from '@va/dashboard/actions/api';
import { setConsentInSession } from '@va/dashboard/actions/ui';
import { getRequestStatus } from '@va/dashboard/selectors/api';
import { getActiveTab, isMobile } from '@va/dashboard/selectors/ui';
import ToggleSwitch from '@va/deprecated/components/ToggleSwitch';
import InfoTooltip from '@va/deprecated/components/tooltips/InfoTooltip';
import Translation from '@va/deprecated/components/Translation';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import defaultThumbnailImg from '@va/images/thumbnails/default-video-thumbnail.jpg';
import campaignChartsVideoThumbnail from '@va/images/thumbnails/feature-video-adsCharts-thumbnail.jpg';
import campaignLatestVisitorsVideoThumbnail from '@va/images/thumbnails/feature-video-adsLatestVisitors-thumbnail.jpg';
import urlCampaignVideoThumbnail from '@va/images/thumbnails/feature-video-adsOverview-thumbnail.jpg';
import conversionsPagesVideoThumbnail from '@va/images/thumbnails/feature-video-conversions-thumbnail.jpg';
import dashboardVideoThumbnail from '@va/images/thumbnails/feature-video-dashboard-thumbnail.jpg';
import devicesVideoThumbnail from '@va/images/thumbnails/feature-video-devices-thumbnail.jpg';
import funnelVideoThumbnail from '@va/images/thumbnails/feature-video-funnels-thumbnail-min.jpg';
import visitorsHeatmapsVideoThumbnail from '@va/images/thumbnails/feature-video-heatmaps-thumbnail.jpg';
import landingPagesVideoThumbnail from '@va/images/thumbnails/feature-video-landingPages-thumbnail.jpg';
import latestVisitorsVideoThumbnail from '@va/images/thumbnails/feature-video-latest-thumbnail.jpg';
import visitorsCityCountryVideoThumbnail from '@va/images/thumbnails/feature-video-locations-video-thumbnail.jpg';
import visitorsMapVideoThumbnail from '@va/images/thumbnails/feature-video-map-thumbnail.jpg';
import myUrlCampaignVideoThumbnail from '@va/images/thumbnails/feature-video-myCampaigns-thumbnail.jpg';
import pageVisitsVideoThumbnail from '@va/images/thumbnails/feature-video-pageVisits-thumbnail.jpg';
import visitorsRecordingsVideoThumbnail from '@va/images/thumbnails/feature-video-recordings-thumbnail.jpg';
import referringSitesVideoThumbnail from '@va/images/thumbnails/feature-video-referringSites-thumbnail.jpg';
import visitorsTrafficChartsVideoThumbnail from '@va/images/thumbnails/feature-video-traffic-thumbnail.jpg';
import { getApiRequestStatus } from '@va/standalone/shared/selectors';
import { Youtube } from '@va/svg-visa-icons';
import { getManageConsentsRouteByApp, isWixApp } from '@va/util/helpers';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ResizeObserver from 'resize-observer-polyfill';

//TODO: add thumbnail img for each new youtube feature video.
const youtubeVideoFeatureTabThumbnails = {
  [tabNames.overview]: dashboardVideoThumbnail,
  [tabNames.latestVisitorsList]: latestVisitorsVideoThumbnail,
  [tabNames.latestVisitorsMap]: visitorsMapVideoThumbnail,
  [tabNames.trafficCharts]: visitorsTrafficChartsVideoThumbnail,
  [tabNames.latestVisitorsLocations]: visitorsCityCountryVideoThumbnail,
  [tabNames.pageVisits]: pageVisitsVideoThumbnail,
  [tabNames.landingPages]: landingPagesVideoThumbnail,
  [tabNames.conversions]: conversionsPagesVideoThumbnail,
  [tabNames.referringSites]: referringSitesVideoThumbnail,
  [tabNames.devices]: devicesVideoThumbnail,
  [tabNames.adsOverview]: urlCampaignVideoThumbnail,
  [tabNames.adsLatestVisitors]: campaignLatestVisitorsVideoThumbnail,
  [tabNames.myCampaigns]: myUrlCampaignVideoThumbnail,
  [tabNames.adsCharts]: campaignChartsVideoThumbnail,
  [tabNames.recordings]: visitorsRecordingsVideoThumbnail,
  [tabNames.heatmaps]: visitorsHeatmapsVideoThumbnail,
  [tabNames.funnels]: funnelVideoThumbnail,
};

const Button = (props) => {
  return (
    <button
      onClick={props.onClick}
      className={`px-4 rounded-18 focus:outline-none md:min-w-210 min-h-38 py-4 mr-2 xs:m-2 ${props.bgColorClass} ${props.extraClasses}`}
    >
      <div className='flex items-center justify-center font-light xs:text-14 tracking-tightestDefault relative'>
        {props.icon && <span className='mr-4'>{props.icon}</span>}
        <span className={`${props.textClasses}`}>{props.text}</span>
      </div>
    </button>
  );
};

Button.propTypes = {
  textClasses: PropTypes.string.isRequired,
  bgColorClass: PropTypes.string,
  icon: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  extraClasses: PropTypes.string.isRequired,
};

class YouTubeConsent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rememberOption: true,
    };
    this.onSwitchChange = this.onSwitchChange.bind(this);
    this.onAgreeBtnClick = this.onAgreeBtnClick.bind(this);
    this.onWatchOnYouTubeBtnClick = this.onWatchOnYouTubeBtnClick.bind(this);

    this.footerContentRef = React.createRef();
    this.backgroundImgRef = React.createRef();
  }

  componentDidMount() {
    const { isMobile } = this.props;

    if (isMobile) {
      const footerContent = this.footerContentRef.current;
      const backgroundImage = this.backgroundImgRef.current;
      let resizeObserver = new ResizeObserver(() => {
        backgroundImage.style.height = footerContent.offsetHeight + 'px';
      });

      resizeObserver.observe(footerContent);
      return () => {
        resizeObserver.unobserve(footerContent);
      };
    }
  }

  onSwitchChange(val) {
    this.setState({ rememberOption: val });
  }

  onAgreeBtnClick() {
    if (this.state.rememberOption) {
      isWixApp()
        ? this.props.updateWebsiteFlags({
            [CONSENT_FLAG.YOUTUBE]: true,
          })
        : this.props.setUserFlag(CONSENT_FLAG.YOUTUBE, true);
    } else {
      this.props.setConsentInSession(CONSENT_FLAG.YOUTUBE, true);
    }
  }

  onWatchOnYouTubeBtnClick() {
    const { youTubeUrl } = this.props;
    youTubeUrl && window.open(youTubeUrl, '_blank');
  }

  renderToolipData() {
    const { translate } = this.props;
    return (
      <div className='p-2'>
        <div className='text-15 leading-24 tracking-tightestDefault'>
          {translate('modal.consent.youtube.dataForwarded')}
        </div>
        <ul className='flex list-disc flex-column p-4'>
          <li className='display-list-item text-15'>{translate('modal.consent.dataForwarded.ip')}</li>
        </ul>
      </div>
    );
  }

  render() {
    const { translate, youTubeUrl, inProgress, modalNameToBeClosed, activeTab, thumbnailImg } = this.props;
    const youtubeThumbnail = thumbnailImg || youtubeVideoFeatureTabThumbnails[activeTab] || defaultThumbnailImg;

    return (
      <div className='relative font-primary'>
        <div
          ref={this.backgroundImgRef}
          className='blur-background filter blur-sm min-h-500 sm:min-h-400 bg-center bg-no-repeat bg-cover'
          style={{ backgroundImage: 'url(' + youtubeThumbnail + ')' }}
        />
        <div className={'absolute top-0 w-full'}>
          <div
            ref={this.footerContentRef}
            className='footer-content min-h-500 sm:min-h-400 flex flex-column justify-end items-center'
          >
            <div className='youtube-embed mb-8 sm:mb-6px sm:mt-10'>
              <Button
                icon={<Youtube color={REQUIRED_COLOR} class='youtube-consent-icon' />}
                onClick={this.onAgreeBtnClick}
                text={translate('button.playAllYoutubeVideosEmbedded')}
                bgColorClass='bg-white-light/90'
                extraClasses=' text-red-negative '
                textClasses='font-semibold text-21 sm:text-15 tracking-normalTight'
              />
            </div>

            {youTubeUrl && (
              <div className='youtube-embed mb-16 xs:mb-2 sm:mb'>
                <Button
                  onClick={this.onWatchOnYouTubeBtnClick}
                  text={translate('button.watchOnYouTube')}
                  bgColorClass='bg-white-light/70'
                  extraClasses=' text-black '
                  textClasses='font-medium text-15 tracking-tightestDefault'
                />
              </div>
            )}

            <div className='justify-end mx-2 '>
              <div className='touch-end flex flex-column -mb-1 bg-white-light/95 rounded-t-24'>
                <div className='flex flex-row p-5 justify-between items-center border-b-2 border-solid border-white'>
                  <div className='text-14 w-4/5'>{translate('modal.consent.whatDataSentToYoutube')}</div>
                  <div>
                    <InfoTooltip>{this.renderToolipData()}</InfoTooltip>
                  </div>
                </div>
                <div className={`flex justify-between p-5`}>
                  <div className='max-w-xl'>
                    <div className='font-medium py-2 text-18 sm:text-15 leading-24'>
                      {translate('label.rememberOption')}
                    </div>
                    <div className='font-light text-sm leading-24'>
                      <Translation
                        translationKey={'modal.consent.manageConsent'}
                        parameters={{
                          to: getManageConsentsRouteByApp(),
                          className: 'text-green-persian',
                          modalName: modalNameToBeClosed,
                        }}
                      />
                    </div>
                  </div>
                  <div className='my-auto mx-0'>
                    <ToggleSwitch
                      checked={this.state.rememberOption}
                      handleChange={this.onSwitchChange}
                      height={24}
                      width={40}
                      onColor={window.PRIMARY_COLOR}
                      onHandleColor={window.PRIMARY_COLOR}
                      disabled={inProgress}
                      withoutBorder
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const apiRequestStatus = isWixApp()
    ? getRequestStatus(state, 'updateWebsiteFlags')
    : getApiRequestStatus(state, 'setUserFlag');
  return {
    inProgress: apiRequestStatus === apiStatus.IN_PROGRESS,
    activeTab: getActiveTab(state),
    isMobile: isMobile(state),
  };
};

const mapDispatchToProps = {
  setUserFlag: setUserFlag,
  updateWebsiteFlags: updateWebsiteFlags,
  setConsentInSession: setConsentInSession,
};

YouTubeConsent.defaultProps = {
  youTubeUrl: null,
  modalNameToBeClosed: modalName.featureVideo,
};

YouTubeConsent.propTypes = {
  youTubeUrl: PropTypes.string.isRequired,
  modalNameToBeClosed: PropTypes.string,
  thumbnailImg: PropTypes.string,
  // withTranslate
  translate: PropTypes.func.isRequired,
  // connect
  setUserFlag: PropTypes.func.isRequired,
  updateWebsiteFlags: PropTypes.func.isRequired,
  setConsentInSession: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(YouTubeConsent));
