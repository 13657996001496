import * as Types from '@va/standalone/shared/actions';
import Immutable from 'immutable';

const uiReducer = (state = Immutable.Map(), action) => {
  switch (action.type) {
    case Types.standalone.Ui.SET_SELECTED_WEBSITE_INFO:
      return state.setIn(['manageWebsites', 'selected'], action.data);
    case Types.standalone.Ui.REMEMBER_EMAIL_FOR_LOGIN:
      return state.setIn(['rememberLogin', 'email'], action.email);
    case Types.standalone.Ui.SET_SUBSCRIPTION_TYPE:
      return state.setIn(['subscription', 'type'], action.subscriptionType);
    case Types.standalone.Ui.SET_SUBSCRIPTION_PLAN:
      return state
        .setIn(['subscription', 'packageNo'], action.packageNo)
        .setIn(['subscription', 'sliderVal'], action.sliderVal);
    case Types.standalone.Ui.SET_INITIAL_UPGRADE_PLAN_SLIDER_VALUE:
      return state.setIn(['initialUpgradePlanSliderValue'], action.payload);
    case Types.standalone.Ui.RESET_INITIAL_UPGRADE_PLAN_SLIDER_VALUE:
      return state.setIn(['initialUpgradePlanSliderValue'], undefined);
    default:
      return state;
  }
};

export default uiReducer;
