import { closeModal } from '@va/dashboard/actions/ui';
import { getModalExtraProps, isModalOpen as modalOpen } from '@va/dashboard/selectors/ui';
import { ModalWrapper } from '@va/dashboard/shared/modals/ModalWrapperV2/ModalWrapper';
import { useTranslate } from '@va/localization';
import { modalName } from '@va/standalone/shared/constants';
import { useWebsiteSelectorInfiniteScroll } from '@va/standalone/shared/hooks';
import { WebsiteManagementCircle } from '@va/svg-visa-icons';
import { UserRole, WebsitesQueryFilter } from '@va/types/website';
import { Button, GrayGradientDivider, ModalHeader, Paragraph, SearchField } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import WebsiteListItem from './WebsiteListItem';

const websitesQuery: WebsitesQueryFilter = {
  start: 0,
  length: 10,
  withSubscription: true,
  url: '',
  withSharedWebsites: false,
};

const WhiteLabelWebsites = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const { searchValue, clearSearchField, onSearch, totalWebsites, websites, lastElementRef, isValidating } =
    useWebsiteSelectorInfiniteScroll(websitesQuery);

  const isModalOpen = useSelector((state) => modalOpen(state, modalName.WHITE_LABEL_WEBSITE));
  const modalExtraProps = useSelector((state) => getModalExtraProps(state, modalName.WHITE_LABEL_WEBSITE));
  const onSubmit = modalExtraProps?.get('onSubmit');
  const isWixWebsiteAllowed = modalExtraProps?.get('isWixWebsiteAllowed');

  const modalContentRef = useRef<HTMLDivElement | null>(null);
  const [selectedWebsite, setSelectedWebsite] = useState<string | null>(null);
  const [totalWebsitesCount, setTotalWebsitesCount] = useState<number>(totalWebsites);

  useEffect(() => {
    if (totalWebsites === 0 && !searchValue) return;
    setTotalWebsitesCount(totalWebsites);
  }, [searchValue, totalWebsites]);

  const handleModalClose = useCallback(() => {
    setSelectedWebsite(null);
    clearSearchField();
    dispatch(closeModal(modalName.WHITE_LABEL_WEBSITE));
  }, [clearSearchField, dispatch]);

  const allWebsites = websites.filter((website) => website.types[0] === UserRole.OWNER);

  return (
    <ModalWrapper isModalOpen={isModalOpen} closeModal={handleModalClose} modalContentRef={modalContentRef}>
      <div ref={modalContentRef} className='modal-content max-w-720px'>
        <ModalHeader title={translate('modal.whiteLabelWebsites.title')} onClose={handleModalClose} />
        <div className='p-6'>
          <AvailableWebsitesInfo websitesCount={totalWebsitesCount} />
        </div>
        <SearchField className='h-10 mx-6' value={searchValue} onChange={onSearch} clearField={clearSearchField} />
        <ul className='px-6 pb-3 mt-4 h-500 overflow-auto scrollbar scrollbar-thumb'>
          {allWebsites.map((item, index) => {
            const itemProps = {
              website: item,
              onWebsiteSelect: setSelectedWebsite,
              selectedWebsite: selectedWebsite,
              isWixWebsiteAllowed,
            };

            if (index === allWebsites.length - 1) {
              return <WebsiteListItem key={`website-item-${item.id}`} ref={lastElementRef} {...itemProps} />;
            }
            return <WebsiteListItem key={`website-item-${item.id}`} {...itemProps} />;
          })}
          {isValidating && <Skeleton count={6} className='rounded-18 h-72 mt-2' />}
          {allWebsites.length === 0 && !isValidating && (
            <div className='w-full h-full flex items-center justify-center'>
              <Paragraph>{translate('labels.noWebsites')}</Paragraph>
            </div>
          )}
        </ul>
        <GrayGradientDivider />
        <div className='flex p-3'>
          <Button
            disabled={!selectedWebsite}
            color='tertiary'
            text={translate('button.continue')}
            onClick={() => {
              onSubmit(selectedWebsite);
            }}
            className='grow'
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default memo(WhiteLabelWebsites);

const AvailableWebsitesInfo = ({ websitesCount }: { websitesCount: number }) => {
  const translate = useTranslate();
  return (
    <div className='flex justify-between items-center'>
      <div className='flex flex-column'>
        <div className='flex items-center'>
          <TooltipWrapper interactive content={translate('whiteLabel.availableWebsites.tooltip.info')}>
            <span className='text-21 leading-30 font-semibold tracking-normalTight underline-dashed'>
              {translate('whiteLabel.availableWebsites.infoTitle')}
            </span>
          </TooltipWrapper>
        </div>
        <div className='text-15 leading-5 tracking-tightestDefault pt-1 text-gray-devil'>
          {translate('whiteLabel.availableWebsites.description')}
        </div>
      </div>
      <div>
        <TooltipWrapper interactive content={translate('whiteLabel.adminWebsitesCount.tooltip.info')}>
          <div className='flex items-center border-2 border-solid border-gray-concrete rounded-12 p-4'>
            <div>
              <WebsiteManagementCircle />
            </div>
            <span className='ml-2 font-semibold text-lg leading-5 text-gray-devil tracking-default'>
              {websitesCount}
            </span>
          </div>
        </TooltipWrapper>
      </div>
    </div>
  );
};
