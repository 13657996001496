import { fontWeights, ParagraphWithTooltip } from '@va/ui/design-system';
import classNames from 'classnames';
import { RadioInputButton } from '../radio-input-button/RadioInputButton';

type RadioInputOptionProps = {
  title: string;
  selected: boolean;
  rightSideNode?: React.ReactNode | string;
  className?: string;
  onClick: () => void;
};

export const RadioInputOption: React.FC<RadioInputOptionProps> = ({
  title,
  selected,
  rightSideNode,
  className,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'h-60 rounded-12 bg-gray-concrete cursor-pointer flex items-center justify-between hover:bg-gray-gallery active:bg-gray-mercury px-18 gap-15px',
        className,
      )}
    >
      <RadioInputButton className='w-[18px] h-[18px]' selected={selected} />
      <ParagraphWithTooltip className='text-gray-charcoal' weight={fontWeights.medium}>
        {title}
      </ParagraphWithTooltip>
      <div className='ml-auto'>{rightSideNode}</div>
    </div>
  );
};
