import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { modalName } from '@va/constants';
import { setCardView } from '@va/dashboard/actions/ui';
import { getInstanceId } from '@va/dashboard/selectors/app';
import PopUpCard from '@va/deprecated/components/PopUpCard';
import { useTranslate } from '@va/localization';
import { subscriptionStatus, upgradeModalView } from '@va/standalone/shared/constants';
import {
  getLastCreatedSubscription,
  getPaymentPlans,
  getSelectedPlanId,
  getSubscriptionPlan,
  getSubscriptionTypeUi,
  hasAllRequiredCustomerDetails,
  hasUpgradedBefore,
} from '@va/standalone/shared/selectors';
import { Button } from '@va/ui/design-system';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import UpgradeSlider from './components/UpgradeSlider/UpgradeSliderContainer';

const ChangeSubscriptionCard = (props) => {
  const translate = useTranslate();
  const history = useHistory();
  const {
    samePlanSelected,
    cancelRequestedSubscription,
    hasAllRequiredCustomerDetails,
    hasSubscriptionExpired,
    hasUpgradedBefore,
    plansCount,
    uiSubscriptionPlan,
    isNextBtnDisabled,
  } = props;

  const submitButtonProps = useMemo(() => {
    let nextModalView = upgradeModalView.CHECKOUT_FORM;
    let btnTranslationKey = 'button.upgrade';

    if (uiSubscriptionPlan === plansCount) {
      nextModalView = upgradeModalView.UPGRADE_CUSTOM_FORM;
      btnTranslationKey = 'button.dropUsALine';
    } else if (!hasAllRequiredCustomerDetails) {
      nextModalView = upgradeModalView.CUSTOMER_INFO_FORM;
    } else if (hasUpgradedBefore && !hasSubscriptionExpired) {
      btnTranslationKey = 'button.changeSubscription';
    }

    return { nextModalView, btnTranslationKey };
  }, [hasAllRequiredCustomerDetails, hasSubscriptionExpired, hasUpgradedBefore, plansCount, uiSubscriptionPlan]);

  const disabledTooltipContent = useMemo(() => {
    if (cancelRequestedSubscription) return translate('upgradeModal.standalone.tooltips.cancelRequestedUpgrade');
    if (samePlanSelected) return translate('upgradeModal.standalone.tooltips.samePlanSelected');
    return null;
  }, [cancelRequestedSubscription, samePlanSelected, translate]);

  const renderFooterButtons = () => {
    const { isNextBtnDisabled, isSubscriptionPastDue, websiteId, closeModal, setCardView } = props;
    const { nextModalView, btnTranslationKey } = submitButtonProps;
    return (
      <div className='p-2 border-none'>
        <div className='upgrade-modal-footer-buttons flex justify-end bg-gray-concrete items-center rounded-br-3xl rounded-bl-3xl '>
          <div className='cancel-upgrade-modal-footer-buttons' onClick={closeModal}>
            {translate('button.cancel')}
          </div>
          {!isSubscriptionPastDue && (
            <Button
              onClick={() =>
                !isNextBtnDisabled && setCardView(modalName.upgrade, nextModalView, upgradeModalView.INITIAL)
              }
              disabled={isNextBtnDisabled}
              text={translate(btnTranslationKey)}
              tooltip={disabledTooltipContent}
            />
          )}
          {isSubscriptionPastDue && (
            <Button
              text={translate('upgradeModal.standalone.subscriptionPastDue')}
              onClick={() => {
                closeModal();
                history.push(`/website/${websiteId}/settings/subscription`);
              }}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <PopUpCard
      type={'card-grey'}
      title={translate('upgradeModal.standalone.title.upgrade')}
      colorTheme={'no-theme'}
      smallerBottomSpace
      footerButtons={renderFooterButtons()}
      invalid={isNextBtnDisabled}
      noPadding
    >
      <UpgradeSlider hasSubscriptionExpired={hasSubscriptionExpired} />
    </PopUpCard>
  );
};

ChangeSubscriptionCard.propTypes = {
  closeModal: PropTypes.func.isRequired,
  //Own props
  uiSubscriptionPlan: PropTypes.number.isRequired,
  plansCount: PropTypes.number.isRequired,
  samePlanSelected: PropTypes.bool,
  cancelRequestedSubscription: PropTypes.bool,
  isNextBtnDisabled: PropTypes.bool,
  hasSubscriptionExpired: PropTypes.bool,
  hasAllRequiredCustomerDetails: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const uiSubscriptionType = getSubscriptionTypeUi(state);
  const uiSubscriptionPlan = getSubscriptionPlan(state);
  const lastSubscription = getLastCreatedSubscription(state);
  const selectedPlanId = getSelectedPlanId(state);
  const samePlanSelected =
    lastSubscription &&
    lastSubscription.status === subscriptionStatus.ACTIVE &&
    !lastSubscription.isActiveUntil &&
    selectedPlanId === lastSubscription.planId &&
    uiSubscriptionType === lastSubscription.planType;
  const cancelRequestedSubscription =
    lastSubscription && lastSubscription.status === subscriptionStatus.ACTIVE && !!lastSubscription.isActiveUntil;
  const isSubscriptionPastDue = lastSubscription.status === subscriptionStatus.PAST_DUE;
  return {
    uiSubscriptionPlan: uiSubscriptionPlan,
    plansCount: getPaymentPlans(state)[uiSubscriptionType].length - 1,
    hasUpgradedBefore: hasUpgradedBefore(state),
    hasAllRequiredCustomerDetails: hasAllRequiredCustomerDetails(state),
    samePlanSelected: samePlanSelected,
    cancelRequestedSubscription: cancelRequestedSubscription,
    isNextBtnDisabled: samePlanSelected || cancelRequestedSubscription || isSubscriptionPastDue,
    isSubscriptionPastDue,
    hasSubscriptionExpired:
      lastSubscription.status === subscriptionStatus.EXPIRED || lastSubscription.status === subscriptionStatus.CANCELED,
    websiteId: getInstanceId(state),
  };
};

const mapDispatchToProps = {
  setCardView: setCardView,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeSubscriptionCard);
