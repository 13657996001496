export const SplitTableRecording = ({ color = 'currentColor', ...props }: JSX.IntrinsicElements['svg']) => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.25 12.75L2.25 5.25C2.25 3.59315 3.59315 2.25 5.25 2.25H8.25V15.75H5.25C3.59315 15.75 2.25 14.4069 2.25 12.75ZM9.75 15.75H12.75C14.4069 15.75 15.75 14.4069 15.75 12.75V5.25C15.75 3.59315 14.4069 2.25 12.75 2.25H9.75L9.75 15.75ZM5.25 17.25C2.76472 17.25 0.75 15.2353 0.75 12.75L0.75 5.25C0.75 2.76472 2.76472 0.75 5.25 0.75H12.75C15.2353 0.75 17.25 2.76472 17.25 5.25V12.75C17.25 15.2353 15.2353 17.25 12.75 17.25H5.25Z'
        fill={color}
      />
      <path
        opacity='0.75'
        d='M15.4824 8.364C15.9524 8.65775 15.9524 9.34225 15.4824 9.636L12.8475 11.2828C12.348 11.595 11.7 11.2359 11.7 10.6468L11.7 7.35319C11.7 6.76411 12.348 6.40498 12.8475 6.71719L15.4824 8.364Z'
        fill={color}
      />
      <rect x='1.5' y='6' width='7.5' height='1.5' fill={color} />
      <rect x='1.5' y='10.5' width='7.5' height='1.5' fill={color} />
    </svg>
  );
};
