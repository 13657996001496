import FocusTrap from 'focus-trap-react';
import { PureComponent } from 'react';

export default class CustomFocusTrap extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeTrap: true,
    };

    this.unmountTrap = this.unmountTrap.bind(this);
  }

  unmountTrap() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.setState({ activeTrap: false });
    setTimeout(() => self.setState({ activeTrap: true }), 0);
  }

  render() {
    return (
      <FocusTrap
        className='not-closable-modal'
        active={this.state.activeTrap}
        focusTrapOptions={{
          clickOutsideDeactivates: true,
          onDeactivate: this.unmountTrap,
        }}
      >
        {this.props.children}
      </FocusTrap>
    );
  }
}
