export const LightIcon: React.FC<{ className?: string; color?: string }> = ({ className, color = 'black' }) => {
  return (
    <svg
      className={className}
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.5 11.25L10.25 2.25L10.25 6L10.25 6.75H11H15.5L8.75006 15.75V12V11.25H8.00006H3.5Z'
        stroke={color}
        strokeWidth='1.5'
      />
    </svg>
  );
};
