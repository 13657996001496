import React from 'react';
import { IconProps } from '.';

export const PageCheckIcon = (props: IconProps) => {
  return (
    <svg
      width='120'
      height='120'
      viewBox='0 0 120 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={props.className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M60 114C89.8234 114 114 89.8234 114 60C114 51.379 111.98 43.2298 108.387 36H54C44.0589 36 36 44.0589 36 54V108.387C43.2298 111.98 51.379 114 60 114ZM30 104.907V54C30 40.7452 40.7452 30 54 30H104.907C95.2196 15.5282 78.7225 6 60 6C30.1766 6 6 30.1766 6 60C6 78.7225 15.5282 95.2196 30 104.907ZM115.02 36.0284C118.223 43.3705 120 51.4777 120 60C120 93.1371 93.1371 120 60 120C51.4777 120 43.3705 118.223 36.0284 115.02C34.0031 114.136 32.0361 113.144 30.1346 112.051C30.0897 112.025 30.0448 111.999 30 111.973C12.066 101.599 0 82.2085 0 60C0 26.8629 26.8629 0 60 0C82.2085 0 101.599 12.066 111.973 30C113.087 31.9263 114.098 33.9204 114.996 35.9744C115.004 35.9924 115.012 36.0104 115.02 36.0284ZM85.2426 58.2426L81 54L65.1213 69.8787L58.2426 63L54 67.2426L63 76.2426L65.1213 78.364L67.2426 76.2426L85.2426 58.2426Z'
        fill={props.color || 'black'}
      />
    </svg>
  );
};
