import { modalName } from '@va/constants';
import { ChatIcon, CustomerSupport, FolderIcon, InfoIcon, SettingsIcon } from '@va/icons';
import { Button, Paragraph } from '@va/ui/design-system';
import { appHasDarkTheme } from '@va/util/helpers';
import classNames from 'classnames';

import { MARK_MODAL_AS_VIEWED_REQUEST } from '@va/dashboard/actions/api';
import { openModal } from '@va/dashboard/actions/ui';
import { getActiveTab } from '@va/dashboard/selectors/ui';
import { CloseButton, TooltipWrapper, useTooltipContext } from '@va/ui/tooltips';
import { PropsWithChildren, ReactNode, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageHeaderTooltipContextProvider, {
  PageHeaderTooltipProps,
  usePageHeaderTooltipContext,
} from './PageHeaderTooltipContext';

export const PageHeaderTooltip: React.FC<PropsWithChildren<PageHeaderTooltipProps>> = ({
  baseKey,
  render,
  title,
  trigger,
  disabled,
}) => {
  return (
    <PageHeaderTooltipContextProvider
      baseKey={baseKey}
      title={title}
      render={render}
      trigger={trigger}
      disabled={disabled}
    >
      <Tooltip />
    </PageHeaderTooltipContextProvider>
  );
};

const Tooltip = () => {
  const { shouldRenderTooltip, baseKey, render, trigger } = usePageHeaderTooltipContext();
  return (
    <TooltipWrapper
      disabled={!shouldRenderTooltip || !baseKey}
      interactive
      tooltipClassNames='!rounded-3xl w-[360px] max-w-[360px] xs:max-w-full'
      placement='bottom'
      content={<TooltipContent />}
      trigger={trigger}
    >
      <div className={'truncate'}>{render(shouldRenderTooltip)}</div>
    </TooltipWrapper>
  );
};

const TooltipContent = () => {
  const { shouldRender, titleText, tooltipKeys, getButtonLabel } = usePageHeaderTooltipContext();
  const { context } = useTooltipContext();
  return (
    <div className='p-5 rounded-xl flex flex-col gap-3'>
      <div className='flex gap-4 justify-between items-center'>
        <div className='flex items-center gap-3 truncate'>
          <div className='flex items-center justify-center h-12 w-12 rounded-xl bg-black-dark/75 shrink-0'>
            <InfoIcon color='#FFFFFF' />
          </div>
          {titleText}
        </div>
        <CloseButton onClose={() => context.onOpenChange(false)} />
      </div>
      {shouldRender(tooltipKeys.description) && <Paragraph>{tooltipKeys.description}</Paragraph>}
      {shouldRender(tooltipKeys.video.url) && (
        <PageHeaderTooltipOption
          text={getButtonLabel(tooltipKeys.video)}
          icon={() => <SettingsIcon color={'#3C3C3C'} className='shrink-0' />}
          url={tooltipKeys.video.url}
          isVideoOption
        />
      )}
      {shouldRender(tooltipKeys.glossary.url) && (
        <PageHeaderTooltipOption
          text={getButtonLabel(tooltipKeys.glossary)}
          icon={() => <FolderIcon color={'#3C3C3C'} className='shrink-0' />}
          url={tooltipKeys.glossary.url}
        />
      )}

      {shouldRender(tooltipKeys.supportCenter.url) && (
        <PageHeaderTooltipOption
          text={getButtonLabel(tooltipKeys.supportCenter)}
          icon={() => <CustomerSupport color={'#3C3C3C'} className='shrink-0' />}
          url={tooltipKeys.supportCenter.url}
        />
      )}

      {shouldRender(tooltipKeys.faq.url) && (
        <PageHeaderTooltipOption
          text={getButtonLabel(tooltipKeys.faq)}
          icon={() => <ChatIcon color={'#3C3C3C'} className='shrink-0' />}
          url={tooltipKeys.faq.url}
        />
      )}
    </div>
  );
};

export const PageHeaderTooltipOption: React.FC<{
  text: string;
  url: string;
  icon: () => ReactNode;
  isVideoOption?: boolean;
}> = ({ text, url, icon, isVideoOption }) => {
  const dispatch = useDispatch();
  const activeTab = useSelector(getActiveTab);
  const isDarkTheme = appHasDarkTheme();

  const onPlayClick = useCallback(() => {
    if (!isVideoOption) return;
    const VIDEO_OVERLAY_FLAG_SUFFIX = 'TabVideoOverlayViewed';
    dispatch({
      type: MARK_MODAL_AS_VIEWED_REQUEST,
      modalFlagName: activeTab + VIDEO_OVERLAY_FLAG_SUFFIX,
    });
    dispatch(openModal(modalName.featureVideo));
  }, [activeTab, dispatch, isVideoOption]);

  if (isVideoOption)
    return (
      <Button
        icon={() => icon()}
        text={text}
        className={classNames(
          'justify-center w-full',
          { '!bg-white': !isDarkTheme },
          { '!bg-white-smoke': isDarkTheme },
        )}
        textClasses='text-black-dark'
        onClick={onPlayClick}
      />
    );

  return (
    <a href={url} target='_blank' rel='noreferrer'>
      <Button
        icon={() => icon()}
        text={text}
        className={classNames(
          'justify-center w-full',
          { '!bg-white': !isDarkTheme },
          { '!bg-white-smoke': isDarkTheme },
        )}
        textClasses='text-black-dark'
      />
    </a>
  );
};
