import React from 'react';

export function DeleteWebsite(props) {
  return (
    <svg width="57px" height="52px" viewBox="0 0 57 52" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
      <g id="symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-159.000000, -212.000000)">
          <g transform="translate(159.000000, 212.000000)">
            <g fill="#009688">
              <path d="M13.363,0.00162272727 L13.363,3.79416818 L0,3.79416818 L0,11.3815773 L1.90866667,11.3815773 L1.90866667,49.3139864 L40.0913333,49.3139864 L40.0913333,11.3815773 L42,11.3815773 L42,3.79416818 L27.6826667,3.79416818 L27.6826667,0.00162272727 L13.363,0.00162272727 Z M15.274,3.79416818 L25.774,3.79416818 L25.774,1.89789545 L15.274,1.89789545 L15.274,3.79416818 Z M1.90866667,9.48298636 L40.0913333,9.48298636 L40.0913333,5.69044091 L1.90866667,5.69044091 L1.90866667,9.48298636 Z M34.363,47.4200318 L38.1826667,47.4200318 L38.1826667,11.3815773 L34.363,11.3815773 L34.363,47.4200318 Z M26.7283333,47.4200318 L32.4566667,47.4200318 L32.4566667,11.3815773 L26.7283333,11.3815773 L26.7283333,47.4200318 Z M17.1826667,47.4200318 L24.8173333,47.4200318 L24.8173333,11.3815773 L17.1826667,11.3815773 L17.1826667,47.4200318 Z M9.54566667,47.4200318 L15.274,47.4200318 L15.274,11.3815773 L9.54566667,11.3815773 L9.54566667,47.4200318 Z M3.81966667,47.4200318 L7.63933333,47.4200318 L7.63933333,11.3815773 L3.81966667,11.3815773 L3.81966667,47.4200318 Z" id="Page-1"/>
            </g>
            <path d="M31,48.7671958 L40.1193843,39.6453102 L41.00481,38.7623858 L40.1193843,37.8769601 L36.4588745,34.2164502 L57,26 L48.7835498,46.5411255 L45.1230399,42.8806157 L44.2376142,41.99519 L43.3546898,42.8806157 L34.2328042,52 L31,48.7671958 Z" id="Page-1" fill="#13232A" transform="translate(44.000000, 39.000000) scale(-1, 1) translate(-44.000000, -39.000000) "/>
          </g>
        </g>
      </g>
    </svg>
  );
}
