import { IconProps } from '.';

export const PlaceholderLogoIcon = ({ color = '#2478F0', className }: IconProps) => {
  return (
    <svg
      className={className}
      width='30'
      height='31'
      viewBox='0 0 30 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.9288 21.0775C29.6198 19.3534 30 17.4711 30 15.5C30 13.5289 29.6198 11.6466 28.9288 9.92249C27.845 11.4803 26.0416 12.5 24 12.5C23.1643 12.5 22.3686 12.3292 21.6458 12.0205C22.1913 13.0604 22.5 14.2442 22.5 15.5C22.5 16.7558 22.1913 17.9396 21.6458 18.9795C22.3686 18.6708 23.1643 18.5 24 18.5C26.0416 18.5 27.845 19.5197 28.9288 21.0775ZM18.4795 22.1458C18.1708 22.8686 18 23.6643 18 24.5C18 26.5416 19.0197 28.345 20.5775 29.4288C18.8534 30.1198 16.9711 30.5 15 30.5C6.71573 30.5 0 23.7843 0 15.5C0 7.21573 6.71573 0.5 15 0.5C16.9711 0.5 18.8534 0.880181 20.5775 1.57121C19.0197 2.65499 18 4.45842 18 6.5C18 7.33566 18.1708 8.13141 18.4795 8.85425C17.4396 8.30866 16.2558 8 15 8C10.8579 8 7.5 11.3579 7.5 15.5C7.5 19.6421 10.8579 23 15 23C16.2558 23 17.4396 22.6913 18.4795 22.1458Z'
        fill={color}
      />
    </svg>
  );
};


