export enum fontWeights {
  thin = 'thin',
  extralight = 'extralight',
  light = 'light',
  normal = 'normal',
  medium = 'medium',
  semibold = 'semibold',
  bold = 'bold',
  extrabold = 'extrabold',
  black = 'black',
}

export const fontWeightClassName = (weight: fontWeights) => `font-${weight}`;
