export function rewriteUrl() {
  let url = window.location.href;
  const websiteIndex = url.indexOf('/website');
  if (websiteIndex > 0) {
    // Extract websiteKey with first '/' included
    const websiteKey = url.substring(websiteIndex + 8, websiteIndex + 45);
    if (websiteKey.split('/').length === 2) {
      // remove websiteKey from URL
      url = url.replace(websiteKey, '');
    }
  }

  return url;
}

/**
 * Remove recordingId after /recordings and all the query params.
 * */
export function rewriteWixMiniUrl() {
  const url = new URL(window.location.href);
  const recordingsTabs = ['overview', 'general-settings', 'privacy-center'];

  const pathComponents = url.pathname.split('/');
  const recordingsIndex = pathComponents.indexOf('recordings');

  if (recordingsTabs.includes(pathComponents[recordingsIndex + 1])) return url.origin + url.pathname;

  return url.origin + pathComponents.slice(0, recordingsIndex + 1).join('/');
}
