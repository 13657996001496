import { TEST_IDS } from '@va/constants';
import { CloseIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { RouterWarningModal } from '@va/shared/util-confirmation';
import { Button, TextInput } from '@va/ui/design-system';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { SectionHeader } from '../misc';
import { UploadLogo } from './UploadLogo';

type FormValuesType = {
  logoAlt: string;
  logo: string | File;
};

const formFields = {
  logoAlt: 'logoAlt',
  logo: 'logo',
};

export const LogoComponent = ({
  onSubmit,
  onFileSelect,
  initialFormValues,
  onFileClear,
  saveButtonProps,
  onUploadError,
  hideLogoAlt,
}: {
  onSubmit: (values: FormValuesType) => void;
  onFileSelect?: (file: File) => void;
  onFileClear?: () => void;
  onUploadError: (message: string) => void;
  initialFormValues: FormValuesType;
  saveButtonProps?: { disabled?: boolean; tooltip?: string };
  hideLogoAlt?: boolean;
}) => {
  const translate = useTranslate();

  const { values, errors, setFieldValue, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValues,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });
  const clearField = (fieldName: string) => {
    setFieldValue(fieldName, '');
  };

  const hasSomethingChanged = useMemo(() => {
    return initialFormValues.logoAlt !== values.logoAlt || initialFormValues.logo !== values.logo;
  }, [initialFormValues.logo, initialFormValues.logoAlt, values.logo, values.logoAlt]);

  const isSaveButtonDisabled = useMemo(
    () => !hasSomethingChanged || saveButtonProps?.disabled,
    [hasSomethingChanged, saveButtonProps?.disabled],
  );

  return (
    <>
      <RouterWarningModal when={hasSomethingChanged} />
      <div>
        <div className={'flex flex-col gap-18px'}>
          <SectionHeader
            description={translate('whiteLabelCustomization.uploadLogo.subTitle')}
            title={translate('whiteLabelCustomization.uploadLogo.title')}
          />
          <form onSubmit={handleSubmit} className='flex flex-col gap-18px'>
            <UploadLogo
              onUploadError={onUploadError}
              name={formFields.logo}
              onChange={(file: File) => {
                setFieldValue(formFields.logo, file);
                onFileSelect?.(file);
              }}
              value={values.logo}
              onClear={() => {
                clearField(formFields.logo);
                onFileClear?.();
              }}
              isLoading={false}
              disabled={false}
            />
            {!hideLogoAlt && (
              <TextInput
                label={translate('whiteLabelCustomization.uploadLogo.altText.label')}
                name={formFields.logoAlt}
                onChange={handleChange}
                clearField={() => clearField(formFields.logoAlt)}
                wrapperClassName='w-full'
                value={values.logoAlt}
                info={translate('uploadLogo.maxUploadFileSizeInfo')}
                error={errors?.logo as string}
                clearIcon={<CloseIcon color='#3C3C3C' />}
              />
            )}
            <Button
              type={isSaveButtonDisabled ? 'button' : 'submit'}
              text={translate('button.saveChanges')}
              color='secondary'
              className='w-full'
              disabled={isSaveButtonDisabled}
              tooltip={saveButtonProps?.tooltip}
              data-testid={TEST_IDS.generic.buttons.submit}
            />
          </form>
        </div>
      </div>
    </>
  );
};
