import { fontWeights, LoadingLogo, Paragraph, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import { CSSProperties, forwardRef, ReactNode, Ref } from 'react';

type CardProps = {
  title: React.ReactNode;
  subtitle?: string;
  titleTooltip?: string;
  children: ReactNode;
  className?: string;
  inProgress?: boolean;
  rightSide?: ReactNode;
  style?: CSSProperties;
  border?: boolean;
};

export const Card = forwardRef(
  (
    { title, subtitle, titleTooltip, children, className, inProgress, rightSide, style, border = false }: CardProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const classes = classNames(className, 'bg-white sm:p-6 p-6 rounded-30 relative mb-30 flex flex-col gap-6', {
      'border border-gray-mercury': border,
    });
    return (
      <div className={classes} style={style} ref={ref}>
        <div className={'flex flex-wrap justify-between lg:items-center gap-3'}>
          <div className='flex flex-col justify-center gap-[18px]'>
            <div className={classNames('flex items-center gap-2')}>
              <TooltipWrapper interactive disabled={!titleTooltip} content={titleTooltip}>
                <Paragraph
                  size={paragraphSizes.large}
                  className={classNames({ 'underline-dashed': titleTooltip })}
                  weight={fontWeights.medium}
                >
                  {title}
                </Paragraph>
              </TooltipWrapper>
            </div>
            {subtitle && (
              <Paragraph size={paragraphSizes.tiny} weight={fontWeights.light} colorClassName='text-gray-charcoal'>
                {subtitle}
              </Paragraph>
            )}
          </div>
          <div className='ml-auto'>{rightSide}</div>
        </div>
        {inProgress ? (
          <div className='w-full min-h-[inherit] relative'>
            <LoadingLogo />
          </div>
        ) : (
          children
        )}
      </div>
    );
  },
);
