import { apiStatus, cardColorTheme } from '@va/constants';
import { resetRequestStatus } from '@va/dashboard/actions/api';
import { closeModal } from '@va/dashboard/actions/ui';
import { isModalOpen } from '@va/dashboard/selectors/ui';
import ModalWrapper from '@va/dashboard/shared/modals/ModalWrapper';
import LoadingCard from '@va/deprecated/components/PopUpCard/card-types/LoadingCard';
import MessageCard from '@va/deprecated/components/PopUpCard/card-types/MessageCard';
import { modalName } from '@va/standalone/shared/constants';
import { getApiRequestStatus } from '@va/standalone/shared/selectors';
import { renderIf, someInProgress, successOrFailedStatus } from '@va/util/helpers';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

class DeleteSubscriptionModal extends PureComponent {
  constructor(props) {
    super(props);

    this.onCloseModal = this.onCloseModal.bind(this);
  }

  onCloseModal() {
    if (this.props.deleteSubscriptionStatus === apiStatus.IN_PROGRESS) {
      return;
    }

    this.props.resetRequestStatus('cancelSubscriptionImmediate');
    this.props.closeModal();
  }

  render() {
    const { isModalOpen, deleteSubscriptionStatus } = this.props;
    const showViews = {
      showMessage: successOrFailedStatus(deleteSubscriptionStatus),
      showLoading: someInProgress(deleteSubscriptionStatus),
      apiError: deleteSubscriptionStatus === apiStatus.FAILED,
    };

    const renderMessage = renderIf(showViews.showMessage);
    const renderLoading = renderIf(showViews.showLoading);

    return (
      <ModalWrapper
        closeModal={this.onCloseModal}
        isOpen={isModalOpen}
        showCloseButton={!(deleteSubscriptionStatus === apiStatus.IN_PROGRESS)}
      >
        <div className='modal-content'>
          {renderMessage(
            <MessageCard
              modalName={modalName.DELETE_SUBSCRIPTION}
              onClose={this.onCloseModal}
              apiError={showViews.apiError}
            />,
          )}
          {renderLoading(<LoadingCard colorTheme={cardColorTheme.RED} />)}
        </div>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deleteSubscriptionStatus: getApiRequestStatus(state, 'cancelSubscriptionImmediate'),
    isModalOpen: isModalOpen(state, modalName.DELETE_SUBSCRIPTION),
  };
};

const mapDispatchToProps = {
  resetRequestStatus: resetRequestStatus,
  closeModal: () => closeModal(modalName.DELETE_SUBSCRIPTION),
};

DeleteSubscriptionModal.propTypes = {
  deleteSubscriptionStatus: PropTypes.oneOf(Object.values(apiStatus)).isRequired,
  closeModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  resetRequestStatus: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSubscriptionModal);
