import React, { memo } from 'react';
import { IconProps } from '.';

export const LightningIcon = (props: IconProps) => {
  return (
    <svg
      width='16'
      height='18'
      viewBox='0 0 16 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={props?.className}
    >
      <path
        d='M2 11.25L8.75 2.25L8.75 6L8.75 6.75H9.5H14L7.25006 15.75V12V11.25H6.50006H2Z'
        stroke={props?.color || 'black'}
        strokeWidth='1.5'
      />
    </svg>
  );
};


