export const AndroidWebviewIcon: React.FC<JSX.IntrinsicElements['svg']> = (props) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 24 24'
      xmlSpace='preserve'
      {...props}
    >
      <path
        d='M22,14.8L19.8,13c0-0.3,0.1-0.7,0.1-1s0-0.7-0.1-1L22,9.2c0.2-0.2,0.3-0.4,0.1-0.7L20,4.9
    c-0.1-0.2-0.4-0.3-0.6-0.2l-2.6,1c-0.5-0.4-1.1-0.8-1.8-1l-0.4-2.8c0-0.3-0.3-0.4-0.5-0.4H9.9c-0.3,0-0.5,0.2-0.5,0.4L9,4.7
    C8.4,5,7.8,5.3,7.2,5.7l-2.6-1C4.4,4.6,4.1,4.7,4,4.9L1.9,8.5C1.8,8.7,1.8,9,2,9.2l2.2,1.7c0,0.3-0.1,0.7-0.1,1s0,0.7,0.1,1L2,14.6
    c-0.2,0.2-0.3,0.4-0.1,0.7L4,18.9c0.1,0.2,0.4,0.3,0.6,0.2l2.6-1c0.5,0.4,1.1,0.8,1.8,1l0.4,2.8c0,0.3,0.3,0.4,0.5,0.4h4.2
    c0.3,0,0.5-0.2,0.5-0.4l0.4-2.8c0.6-0.3,1.2-0.6,1.8-1l2.6,1c0.2,0.1,0.5,0,0.6-0.2l2.1-3.6C22.3,15.2,22.2,14.9,22,14.8z
     M19.4,18.2l-2.7-1.1l-0.5,0.3c-0.5,0.4-1,0.7-1.6,0.9l-0.5,0.2l-0.4,2.9h-3.4l-0.4-2.9l-0.5-0.2c-0.5-0.2-1-0.5-1.5-0.9l-0.5-0.3
    l-2.8,1.1l-1.7-2.9l2.3-1.8l-0.1-0.6C5.1,12.6,5,12.3,5,12s0-0.6,0.1-0.9l0.1-0.6L2.9,8.7l1.7-2.9l2.7,1.1l0.5-0.3
    c0.5-0.4,1-0.7,1.6-0.9l0.5-0.2l0.4-2.9h3.4l0.4,2.9l0.5,0.2c0.5,0.2,1.1,0.5,1.5,0.9l0.5,0.3l2.8-1.1l1.7,2.9l-2.3,1.8l0.1,0.6
    c0,0.3,0.1,0.6,0.1,0.9s0,0.6-0.1,0.9l-0.1,0.6l2.3,1.8L19.4,18.2z'
      />
      <path
        d='M12,6.2c-3.1,0-5.7,2.6-5.7,5.8s2.5,5.8,5.7,5.8s5.7-2.6,5.7-5.8S15.2,6.2,12,6.2z M12,16.8
    c-2.6,0-4.7-2.1-4.7-4.8S9.4,7.2,12,7.2s4.7,2.1,4.7,4.8S14.6,16.8,12,16.8z'
      />
      <ellipse cx='12' cy='12' rx='3.8' ry='3.8' />
    </svg>
  );
};
