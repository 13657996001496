import {
  Checkmark,
  CustomerSupport,
  NavEventTracking,
  NavFunnels,
  NavHeatmaps,
  NavPolls,
  NavRecordings,
  NavSurveys,
  StarCircleIcon,
} from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { RECORDINGS_COUNT_PRO_PLUS_PLAN } from '@va/standalone/shared/constants';
import { addNumberSeparator } from '@va/util/helpers';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import PlanPrice from '../../common/components/plan-price';
import SubscriptionSwitchButton from '../../common/components/subscription-switch-button';
import { getPlanPrice } from '../../common/helpers';

type PlanInfoProps = {
  selectedPaymentPlans: any[];
  uiSubscriptionPlan: number;
  uiSubscriptionType: string;
  setSubscriptionType: (arg0: string) => void;
  paymentPlans: {};
};

const PlanInfo: React.FC<PlanInfoProps> = ({
  selectedPaymentPlans,
  uiSubscriptionPlan,
  uiSubscriptionType,
  setSubscriptionType,
  paymentPlans,
}) => {
  const { locale } = useLocale();
  const translate = useTranslate();

  const featuresForPlan = () => {
    const isPlanProPlusOrHigher =
      selectedPaymentPlans[uiSubscriptionPlan]['recordings'] > RECORDINGS_COUNT_PRO_PLUS_PLAN;

    const getFeatureCount = (featureName: string) => {
      return addNumberSeparator(selectedPaymentPlans[uiSubscriptionPlan][featureName], locale);
    };

    const featuresToDisplayInPlan = [
      {
        icon: NavRecordings,
        label: translate(`upgradeCard.subscription.recordingsIncluded`),
        info: getFeatureCount('recordings'),
      },
      {
        icon: NavEventTracking,
        label: translate(`upgradeCard.subscription.eventTrackingIncluded`),
        info: getFeatureCount('eventTracking'),
      },
      {
        icon: NavHeatmaps,
        label: translate(`upgradeCard.subscription.heatmapsIncluded`),
        info: getFeatureCount('heatmaps'),
      },
      {
        icon: NavFunnels,
        label: translate(`upgradeCard.subscription.funnelsIncluded`),
        info: getFeatureCount('funnels'),
      },
      {
        icon: NavPolls,
        label: translate(`upgradeCard.subscription.pollsIncluded`),
        info: getFeatureCount('polls'),
      },
      {
        icon: NavSurveys,
        label: translate(`upgradeCard.subscription.surveysIncluded`),
        info: getFeatureCount('surveys'),
      },
      {
        icon: CustomerSupport,
        label: translate(`upgradeCard.subscription.customerSupportIncluded`),
        info: isPlanProPlusOrHigher ? <StarCircleIcon /> : <Checkmark />,
      },
    ];

    return (
      <Fragment>
        <div className='feature-for-plan-wrapper border-2 border-solid border-white-smoke rounded-12'>
          {featuresToDisplayInPlan.map(({ icon: FeatureIcon, label, info }, index) => {
            return (
              <div
                key={index}
                className={classNames('feature-details flex justify-between border-solid border-gray-gallery', {
                  'border-b-2': index < featuresToDisplayInPlan.length - 1,
                })}
              >
                <div className='flex items-baseline'>
                  <div>
                    <FeatureIcon />
                  </div>
                  <div className='feature-name-included font-inter font-medium text-15 leading-normal text-gray-devil'>
                    {label}
                  </div>
                </div>
                <div className='font-inter font-semibold text-21'>{info}</div>
              </div>
            );
          })}
        </div>
      </Fragment>
    );
  };

  const planPrice = () => {
    const price = selectedPaymentPlans[uiSubscriptionPlan].price;

    return (
      <PlanPrice
        planName={selectedPaymentPlans[uiSubscriptionPlan].name}
        price={getPlanPrice(price, uiSubscriptionType)}
      />
    );
  };

  return (
    <div className='plan-info-container'>
      {planPrice()}
      <SubscriptionSwitchButton
        uiSubscriptionPlan={uiSubscriptionPlan}
        setSubscriptionType={setSubscriptionType}
        paymentPlans={paymentPlans}
        uiSubscriptionType={uiSubscriptionType}
      />
      {featuresForPlan()}
    </div>
  );
};

export default PlanInfo;
