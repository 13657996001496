import { deviceSizesMap, manualScreenshotPopupResolutions, modalName } from '@va/constants';
import { closeModal } from '@va/dashboard/actions/ui';
import { retakeHeatmapBackground } from '@va/dashboard/api-client/heatmaps.api';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { getWebsite } from '@va/dashboard/selectors/core';
import {
  getModalExtraProps,
  isMobileDevice as isMobileDeviceSelector,
  isModalOpen,
  isTabletDevice as isTabletDeviceSelector,
} from '@va/dashboard/selectors/ui';
import ModalWrapper from '@va/dashboard/shared/modals/ModalWrapper';
import { onContactSupport } from '@va/dashboard/util-helpers/misc';
import { CloseIcon, CurrentDeviceIcon, DesktopDeviceIcon, MobileDeviceIcon, TabletDeviceIcon } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import {
  Button,
  Heading3,
  Heading4,
  HorizontalSeparator,
  IllustrationButton,
  IllustrationCard,
  InformationBox,
  Paragraph,
  fontWeights,
} from '@va/ui/design-system';
import { getWellFormedUrl, isNumberInRange } from '@va/util/helpers';
import { useListenForMessage } from '@va/util/hooks';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ManualScreenshotContainer = () => {
  const translate = useTranslate();
  const { locale } = useLocale();
  const website = useSelector(getWebsite);
  const instanceId = useSelector(getInstanceId);
  const extraProps = useSelector((state) => getModalExtraProps(state, modalName.heatmapManualScreenshot));
  const isOpen = useSelector((state) => isModalOpen(state, modalName.heatmapManualScreenshot));
  const isTabletDevice = useSelector(isTabletDeviceSelector);
  const isMobileDevice = useSelector(isMobileDeviceSelector);
  const [shouldRefresh, setShouldRefresh] = useState(true);

  const dispatch = useDispatch();
  const close = () => {
    dispatch(closeModal(modalName.heatmapManualScreenshot));
  };

  const onMessage = (event: { origin: any; data: { action: any } }) => {
    const websiteUrl = website.url;

    if (!websiteUrl || !websiteUrl.includes(event.origin)) {
      return;
    }

    const { action } = event.data;
    if (action === 'CONTACT_US_CLICKED') {
      close();
      onContactSupport();
      setShouldRefresh(false);
    }

    if (action === 'POPUP_CLOSE') {
      close();
      if (shouldRefresh) {
        window.location.reload();
      }
    }
  };

  useListenForMessage(onMessage);

  const onCardClick = async (deviceType: number) => {
    const websiteId = instanceId ?? website.id;

    let popupDeviceType = deviceType;
    const popupResolution = manualScreenshotPopupResolutions[deviceType];

    if (deviceType === deviceSizesMap.current) {
      if (isNumberInRange(popupResolution.width, 768, 1024)) {
        popupDeviceType = deviceSizesMap.tablet;
      } else {
        popupDeviceType = deviceSizesMap.desktop;
      }
    }
    const heatmapId = extraProps.get('heatmapId');
    const heatmapUrl = getWellFormedUrl(extraProps.get('heatmapUrl'));
    const url = `${heatmapUrl}?visa_d=${popupDeviceType}&visa_h=${heatmapId}&primaryColor=${window.PRIMARY_COLOR.slice(
      1,
    )}&secondaryColor=${window.SECONDARY_COLOR.slice(1)}&locale=${locale}`;
    await retakeHeatmapBackground(websiteId);

    window.open(url, 'Manual Screenshot Heatmap', `width=${popupResolution.width},height=${popupResolution.height}`);
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      roundedCorners
      closeModal={close}
      closeIcon={
        <Button
          color='tertiary'
          className='absolute top-6 right-6'
          icon={(classes) => <CloseIcon className={classes} />}
        />
      }
    >
      <div className={'!w-[645px] p-30 heatmap-limit-reached-container'}>
        <Heading3 className={'pr-14'} weight={fontWeights.semibold}>
          {translate('modal.manualScreenshot.title')}
        </Heading3>
        <HorizontalSeparator className='my-30 -mx-30' />
        <Heading4 weight={fontWeights.semibold} colorClassName='text-gray-charcoal' className='pb-6px'>
          {translate('modal.manualScreenshot.subtitle')}
        </Heading4>
        <Paragraph colorClassName='text-gray-devil'>{translate('modal.manualScreenshot.description')}</Paragraph>
        <InformationBox className='mt-18px mb-24'>{translate('modal.manualScreenshot.informationBox')}</InformationBox>
        <div className='flex flex-col sm-initial:flex-row gap-5 mb-5'>
          <IllustrationCard
            className='flex flex-row sm-initial:flex-col gap-5 w-full'
            title={translate('modal.manualScreenshot.devices.desktop')}
            subtitle={translate('modal.manualScreenshot.takeScreenshot')}
            icon={(classes) => <DesktopDeviceIcon className={classes} />}
            disabled={isTabletDevice}
            onClick={() => {
              onCardClick(deviceSizesMap.desktop);
            }}
          />
          <IllustrationCard
            title={translate('modal.manualScreenshot.devices.tablet')}
            subtitle={translate('modal.manualScreenshot.takeScreenshot')}
            icon={(classes) => <TabletDeviceIcon className={classes} />}
            disabled={isMobileDevice}
            onClick={() => {
              onCardClick(deviceSizesMap.tablet);
            }}
          />
          <IllustrationCard
            title={translate('modal.manualScreenshot.devices.mobile')}
            subtitle={translate('modal.manualScreenshot.takeScreenshot')}
            icon={(classes) => <MobileDeviceIcon className={classes} />}
            onClick={() => {
              onCardClick(deviceSizesMap.mobile);
            }}
          />
        </div>
        <IllustrationButton
          className='mb-5 w-full'
          title={translate('modal.manualScreenshot.devices.current')}
          subtitle={translate('modal.manualScreenshot.takeScreenshot')}
          icon={(classes) => <CurrentDeviceIcon className={classes} />}
          onClick={() => {
            onCardClick(deviceSizesMap.current);
          }}
        />
        <Button
          text={translate('modal.manualScreenshot.cancel')}
          color='tertiary'
          className='w-full justify-center'
          onClick={close}
        />
      </div>
    </ModalWrapper>
  );
};

export default ManualScreenshotContainer;
