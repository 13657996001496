import { IconProps } from '../misc';

export const LockIcon = ({ className, color = '#000000' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.25 5.25C5.25 3.17893 6.92893 1.5 9 1.5C11.0711 1.5 12.75 3.17893 12.75 5.25V6H5.25V5.25ZM3.75 6.09451V5.25C3.75 2.35051 6.1005 0 9 0C11.8995 0 14.25 2.35051 14.25 5.25V6.09451C15.5439 6.42755 16.5 7.60212 16.5 9V15C16.5 16.6569 15.1569 18 13.5 18H4.5C2.84315 18 1.5 16.6569 1.5 15V9C1.5 7.60212 2.45608 6.42755 3.75 6.09451ZM3 9C3 8.17157 3.67157 7.5 4.5 7.5H13.5C14.3284 7.5 15 8.17157 15 9V15C15 15.8284 14.3284 16.5 13.5 16.5H4.5C3.67157 16.5 3 15.8284 3 15V9Z'
        fill={color}
      />
    </svg>
  );
};
