import { PencilEditIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { appVersion } from '@va/util/helpers';

export const AppVersion = () => {
  const translate = useTranslate();
  return (
    <div className='flex justify-between items-center h-9 rounded-[9px] px-3 bg-gradient-to-r from-gray-concrete w-full'>
      <div className='flex gap-9px items-center w-full'>
        <PencilEditIcon color='#969696' className='h-3 w-3' />
        <Paragraph size={paragraphSizes.tiny} weight={fontWeights.medium} colorClassName='text-gray-devilSolid'>
          {translate('label.appVersion')}
        </Paragraph>
      </div>
      <Paragraph
        size={paragraphSizes.tiny}
        weight={fontWeights.medium}
        colorClassName='text-gray-devilSolid'
        className='whitespace-nowrap'
      >
        {appVersion()}
      </Paragraph>
    </div>
  );
};
