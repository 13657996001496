import { defaultTimezone, TRAFFIC_FILTER } from '@va/constants';
import { setTrafficFilter } from '@va/dashboard/actions/ui';
import { getWebsite } from '@va/dashboard/selectors/core';
import { getTrafficFilter } from '@va/dashboard/selectors/ui';
import { Clock } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, Paragraph } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { DropdownArrow } from '@va/util/components';
import { getResponsiveTooltipHeight } from '@va/util/helpers';
import { useWindowDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import moment from 'moment';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const periodButtons = [{ id: TRAFFIC_FILTER.PAST_WEEK }, { id: TRAFFIC_FILTER.PAST_YEAR }];

type TrafficFilterProps = { refreshFn: Function; featureReleaseDate?: number };

const TrafficFilter: React.FC<TrafficFilterProps> = ({ refreshFn, featureReleaseDate }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [selectedSpecificYear, setSelectedSpecificYear] = useState<number | null>(null);
  const [featureReleaseYear, setFeatureReleaseYear] = useState<number | null>(null);
  const translate = useTranslate();
  const dispatch = useDispatch();
  const trafficFilter = useSelector(getTrafficFilter);
  const website = useSelector(getWebsite);
  const websiteLifetimeYears = getWebsiteLifetimeYears(website.installDate, website.timezone);

  useEffect(() => {
    if (!featureReleaseDate) return;
    setFeatureReleaseYear(moment(featureReleaseDate * 1000).year());
  }, [featureReleaseDate]);

  const refreshData = useCallback(
    (period: string, specificYear: number | null) => {
      dispatch(setTrafficFilter({ period: period, specificYear: specificYear }));
      refreshFn();
    },
    [dispatch, refreshFn],
  );

  const onChangeSpecificYear = useCallback(
    (year: number) => {
      if (featureReleaseYear && featureReleaseYear > year) return;
      refreshData(TRAFFIC_FILTER.SPECIFIC_YEAR, year);
      setIsDropdownVisible(false);
      setSelectedSpecificYear(year);
    },
    [featureReleaseYear, refreshData],
  );

  const isFilterSelected = useCallback(
    (option: string) => {
      return trafficFilter.period === option;
    },
    [trafficFilter.period],
  );

  const dropdownTooltipContent = useCallback(() => {
    if (!featureReleaseDate) return;
    const installedAfterReleaseDate = website.installDate < featureReleaseDate;
    if (!installedAfterReleaseDate) return;
    return translate('filter.featureReleaseDate', { releaseDate: moment(featureReleaseDate * 1000).format('L') });
  }, [featureReleaseDate, translate, website.installDate]);

  return (
    <div className='inline-flex flex-row gap-1 rounded-15 p-1 bg-white-snow flex-nowrap'>
      {periodButtons.map((period, index) => {
        return (
          <Button
            className='!py-9px'
            key={index}
            color={isFilterSelected(period.id) ? 'quaternary' : 'tertiary'}
            icon={() => <Clock strokeColor={isFilterSelected(period.id) ? window.PRIMARY_COLOR : '#3C3C3C'} />}
            text={translate(`filter.button.${period.id}`)}
            onClick={() => {
              refreshData(period.id, null);
            }}
          />
        );
      })}
      <TooltipWrapper
        placement='bottom'
        trigger='click'
        arrow={false}
        useDefaultStyle={false}
        useParentWidth
        open={isDropdownVisible}
        onOpenChange={(open) => setIsDropdownVisible(open)}
        interactive
        content={
          <SpecificYearsOptions
            websiteLifetimeYears={websiteLifetimeYears}
            selectedSpecificYear={selectedSpecificYear}
            featureReleaseYear={featureReleaseYear}
            onChangeSpecificYear={onChangeSpecificYear}
          />
        }
      >
        <div>
          <Button
            className='flex justify-between min-w-150 !py-9px'
            color={isFilterSelected(TRAFFIC_FILTER.SPECIFIC_YEAR) ? 'quaternary' : 'tertiary'}
            icon={() => (
              <Clock strokeColor={isFilterSelected(TRAFFIC_FILTER.SPECIFIC_YEAR) ? window.PRIMARY_COLOR : '#3C3C3C'} />
            )}
            text={trafficFilter.specificYear ? trafficFilter.specificYear : translate(`filter.button.lastYears`)}
            rightSideIcon={<DropdownArrow open={isDropdownVisible} color='#969696' />}
            onClick={() => {
              setIsDropdownVisible((prev) => !prev);
            }}
            tooltip={dropdownTooltipContent()}
          />
        </div>
      </TooltipWrapper>
    </div>
  );
};

const SpecificYearsOptions = ({
  websiteLifetimeYears,
  selectedSpecificYear,
  featureReleaseYear,
  onChangeSpecificYear,
}: {
  websiteLifetimeYears: number[];
  selectedSpecificYear: number | null;
  featureReleaseYear: number | null;
  onChangeSpecificYear: (year: number) => void;
}) => {
  const { height: windowHeight } = useWindowDimensions();

  return (
    <div
      className={classNames(
        'flex flex-col rounded-12 bg-white p-2 gap-1 text-gray-charcoal w-full overflow-auto shadow-overlay cursor-pointer scrollbar scrollbar-thumb',
        { 'min-h-[100px]': windowHeight < 500 },
      )}
      style={{ maxHeight: getResponsiveTooltipHeight(windowHeight) }}
    >
      {websiteLifetimeYears.map((year, index) => {
        const invalidOption = featureReleaseYear && featureReleaseYear > year;
        return (
          <div
            key={index}
            className={classNames('p-3 rounded-12', {
              'bg-gray-concrete': year === selectedSpecificYear,
              'hover:bg-white-snow': year !== selectedSpecificYear && !invalidOption,
            })}
            onClick={() => onChangeSpecificYear(year)}
          >
            <Paragraph
              colorClassName={classNames({
                'text-gray-disabled': invalidOption,
              })}
            >
              {year}
            </Paragraph>
          </div>
        );
      })}
    </div>
  );
};

export default memo(TrafficFilter);

function getWebsiteLifetimeYears(installDateTs: number, timezone = defaultTimezone) {
  const installDateYear = moment(installDateTs * 1000)
    .tz(timezone)
    .year();
  const currentYear = moment().tz(timezone).year();
  if (installDateYear >= currentYear) return [currentYear];
  const length = Math.floor(Math.abs(currentYear - installDateYear)) + 1;

  return Array.from(Array(length), (x, index) => installDateYear + index).reverse();
}
