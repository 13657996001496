import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { apiStatus, modalName } from '@va/constants';
import { renderIf, successOrFailedStatus } from '@va/util/helpers';

import LoadingPopUpCard from '@va/deprecated/components/PopUpCard/card-types/LoadingCard';
import MessageCard from '@va/deprecated/components/PopUpCard/card-types/MessageCard';
import Sign from './Sign';

const deviceWidthXS = 450;

const CANVAS_WIDTH = 350;
const CANVAS_WIDTH_XS = 250;

export default class SignDPACard extends PureComponent {
  getCanvasWidth() {
    return this.props.windowWidth <= deviceWidthXS ? CANVAS_WIDTH_XS : CANVAS_WIDTH;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.signDPAStatus === apiStatus.IN_PROGRESS && this.props.signDPAStatus === apiStatus.SUCCEEDED) {
      this.props.requestSettings();
    }
  }

  render() {
    const { translate, closeModal, signDPAStatus } = this.props;

    const showViews = {
      showInitialScreen: signDPAStatus === apiStatus.NONE,
      showLoading: signDPAStatus === apiStatus.IN_PROGRESS,
      showConfirmation: successOrFailedStatus(signDPAStatus),
      apiError: signDPAStatus === apiStatus.FAILED,
    };

    const renderInitialScreen = renderIf(showViews.showInitialScreen);
    const renderLoading = renderIf(showViews.showLoading);
    const renderConfirmation = renderIf(showViews.showConfirmation);

    return (
      <div className='modal-content'>
        {renderInitialScreen(<Sign canvasWidth={this.getCanvasWidth()} {...this.props} />)}

        {renderConfirmation(
          <MessageCard
            modalName={modalName.signDPA}
            translate={translate}
            onClose={closeModal}
            apiError={showViews.apiError}
          />,
        )}

        {renderLoading(<LoadingPopUpCard />)}
      </div>
    );
  }
}

SignDPACard.propTypes = {
  translate: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
  signDPAStatus: PropTypes.oneOf(Object.values(apiStatus)).isRequired,
  dpaLink: PropTypes.string.isRequired,
  requestSettings: PropTypes.func.isRequired,
};
