export const metricsMap = {
  count: 'count',
  percentOfTotal: 'percentOfTotal',
};

export const metricTranslations = {
  [metricsMap.count]: 'all.eventTracking.alarmingBehaviorEvents.donutChart.metrics.numberOfDetections',
  [metricsMap.percentOfTotal]: 'all.eventTracking.alarmingBehaviorEvents.donutChart.metrics.percentOfTotalEvents',
};

export const overviewMetricsList = [metricsMap.count, metricsMap.percentOfTotal];
export const detailViewMetricsList = [metricsMap.count];

export const metricsDatasetLabel = {
  [metricsMap.count]: 'Alarming Events Count',
  [metricsMap.percentOfTotal]: 'Alarming Events Percent of Total',
};
