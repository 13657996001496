import classNames from 'classnames';
import React from 'react';
import { IconProps } from '.';

export const LineGraphIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='14'
      viewBox='0 0 18 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2734 0.810059L13.9014 6.25202H18V7.75202H13.0986L10.7266 4.19397L7.72658 13.194L4.09861 7.75202H0V6.25202H4.90139L7.27342 9.81006L10.2734 0.810059Z'
        fill={color ?? 'black'}
      />
    </svg>
  );
};


