import React from 'react';
import { IconProps } from '.';

export const MinusStarIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0051 15.2843L9.85116 15.3704L10.6767 13.9412L10.7375 13.9752L13.6353 15.5966L12.9883 12.3419L12.8245 11.5181L13.4413 10.9479L15.878 8.69559L13.8457 8.45474L14.6623 7.04102L17.3264 7.35674L19.2851 7.58887L17.8367 8.92772L14.4595 12.0494L15.356 16.5593L15.7405 18.4932L14.0197 17.5305L10.0051 15.2843ZM10.0051 4.42954L10.4339 5.35868L11.3522 3.76879L10.8311 2.63957L10.0051 0.849609L9.17909 2.63957L7.25213 6.81534L2.6838 7.35674L0.725098 7.58887L2.17351 8.92772L5.5507 12.0494L5.01623 14.738L6.98387 11.3315L6.56888 10.9479L4.13221 8.69559L7.42866 8.30492L8.26235 8.20611L8.61411 7.44384L10.0051 4.42954ZM4.85161 18.0241C4.64434 18.383 4.76734 18.8418 5.12634 19.049C5.48534 19.2561 5.94439 19.1332 6.15166 18.7744L15.1586 3.18086C15.3659 2.82203 15.2429 2.36318 14.8839 2.15601C14.5249 1.94884 14.0659 2.07178 13.8586 2.43062L4.85161 18.0241Z'
        fill={color}
      />
    </svg>
  );
};
