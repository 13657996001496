import { TEST_IDS } from '@va/constants';
import { ChartContext, ChartTooltipMode } from '@va/types/charts';
import { TimeInterval } from '@va/types/time-series';
import { Tooltip, TooltipContent, useTooltipContext } from '@va/ui/tooltips';
import { useWindowDimensions } from '@va/util/hooks';
import { ChartDataset } from 'chart.js';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { ChartTooltipComponent, ChartTooltipValues } from './ChartTooltipComponent';
import './external-tooltip.scss';

type ExternalChartTooltipProps = {
  tooltipMode?: ChartTooltipMode;
  showPrevious: boolean;
  title?: string;
  subtitle?: string;
  reversePercentageColors?: boolean;
  currentTimeIntervals: TimeInterval[];
  previousTimeIntervals: TimeInterval[];
  valueTransformer?: (val: any) => any;
};

export const externalChartTooltip = (context: ChartContext, props: ExternalChartTooltipProps) => {
  return <ExternalChartTooltip context={context} {...props} />;
};

type TooltipProps = {
  context: ChartContext;
} & ExternalChartTooltipProps;

export const ExternalChartTooltip = ({
  context,
  showPrevious,
  title,
  subtitle,
  reversePercentageColors,
  currentTimeIntervals,
  previousTimeIntervals,
  valueTransformer,
  tooltipMode = 'fixed',
}: TooltipProps) => {
  const { isMobile } = useWindowDimensions();
  const [isTouchMoving, setIsTouchMoving] = useState(false);
  const [touchEnded, setTouchEnded] = useState(true);
  const [isTooltipInteractive, setTooltipInteractive] = useState(false);
  const { tooltip, chart } = context;
  const { height, top } = chart.chartArea;

  const onTouchMove = useCallback(() => {
    tooltip.opacity = 0;
    setIsTouchMoving(true);
  }, [tooltip]);

  const onTouchEnd = useCallback(() => {
    if (isTouchMoving) {
      tooltip.opacity = 0;
    }

    setTouchEnded(true);
    setIsTouchMoving(false);
  }, [isTouchMoving, tooltip]);

  const closeTooltip = useCallback(() => {
    tooltip.opacity = 0;
    setTouchEnded(false);
  }, [tooltip]);

  const handleOnScroll = useCallback(() => {
    if (tooltip.opacity === 0) return;
    if (chart.config.options) {
      chart.config.options.events = ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'];
    }
    chart.update('none');
    tooltip.opacity = 0;

    setTooltipInteractive(false);
  }, [chart, tooltip]);

  const debouncedOnScroll = useDebouncedCallback(() => {
    handleOnScroll();
  }, 250);

  const handleOnClick = useCallback(() => {
    if (tooltip.opacity === 0) return;
    setTooltipInteractive((prev) => {
      if (chart.config.options) {
        chart.config.options.events = prev ? ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'] : ['click'];
      }
      chart.update('none');
      return !prev;
    });
  }, [chart, tooltip]);

  useEffect(() => {
    window.addEventListener('touchend', onTouchEnd);
    window.addEventListener('touchmove', onTouchMove);

    window.addEventListener('scroll', debouncedOnScroll, { capture: true });
    window.addEventListener('click', handleOnClick, { capture: true });

    return () => {
      window.removeEventListener('touchmove', onTouchMove);
      window.removeEventListener('touchend', onTouchEnd);

      window.removeEventListener('scroll', debouncedOnScroll);
      window.removeEventListener('click', handleOnClick);
    };
  }, [onTouchEnd, onTouchMove, handleOnClick, debouncedOnScroll]);

  const { offsetLeft } = chart.canvas;

  const placeholderSize = 2;
  const left = offsetLeft + tooltip.caretX - placeholderSize / 2;

  const { dataPoints } = tooltip;

  const currentValues = useMemo(() => {
    if (!dataPoints) return {};
    return dataPoints.reduce((acc, point) => {
      const { dataset, raw } = point;
      if (!dataset.isCurrent) return acc;
      acc[dataset.label ?? ''] = {
        color: extractDatasetColor(dataset),
        value: raw as number,
        linkTo: dataset.linkTo,
        // @ts-ignore
        onLinkClick: dataset.onLinkClick,
        // @ts-ignore
        linkLabel: dataset.linkLabel,
      };
      return acc;
    }, {} as ChartTooltipValues);
  }, [dataPoints]);

  const previousValues = useMemo(() => {
    if (!dataPoints) return {};
    return dataPoints.reduce((acc, point) => {
      const { dataset, raw } = point;
      if (dataset.isCurrent) return acc;
      acc[dataset.label ?? ''] = { color: extractDatasetColor(dataset), value: raw as number };
      return acc;
    }, {} as ChartTooltipValues);
  }, [dataPoints]);

  if (!dataPoints) return null;

  const currentDate = currentTimeIntervals[dataPoints[0]?.dataIndex] ?? `N/A`;
  const previousDate = previousTimeIntervals[dataPoints[0]?.dataIndex] ?? 'N/A';

  return (
    <div className='chart-tooltip-wrapper pointer-events-none'>
      <Tooltip
        placement={isMobile ? 'top' : undefined}
        interactive
        open={tooltip.opacity === 1 && !isTouchMoving && touchEnded}
      >
        {tooltipMode === 'fixed' && (
          <FixedTrigger
            opacity={tooltip.opacity}
            left={left}
            top={top}
            placeholderSize={placeholderSize}
            height={height}
          />
        )}
        {tooltipMode === 'follow-point' && (
          <PointFollowerTrigger opacity={tooltip.opacity} left={tooltip.caretX} top={tooltip.caretY} />
        )}
        <TooltipContent
          tooltipClassNames={classNames('!rounded-xl p-4 !max-w-[540px]', {
            'pointer-events-auto': isTooltipInteractive,
          })}
          zIndex={1700}
        >
          <ChartTooltipComponent
            currentValues={currentValues}
            previousValues={previousValues}
            currentPeriod={currentDate}
            previousPeriod={previousDate}
            title={title}
            subtitle={subtitle}
            showPrevious={showPrevious}
            reversePercentageColors={reversePercentageColors}
            closeTooltip={closeTooltip}
            valueTransformer={valueTransformer}
          />
        </TooltipContent>
      </Tooltip>
    </div>
  );
};

type CommonTooltipTriggerProps = {
  opacity: number;
  top: number;
  left: number;
};

type TooltipTriggerProps = CommonTooltipTriggerProps & {
  height?: number;
  placeholderSize?: number;
  className?: string;
  bottom?: number;
};

const FixedTrigger = ({ left, top, opacity, height, placeholderSize }: TooltipTriggerProps) => {
  return (
    <TooltipTrigger
      left={left}
      opacity={opacity}
      top={top}
      className='chart-datapoint'
      bottom={0}
      height={height}
      placeholderSize={placeholderSize}
    />
  );
};
const PointFollowerTrigger = ({ left, top, opacity }: CommonTooltipTriggerProps) => {
  return <TooltipTrigger left={left} opacity={opacity} top={top} />;
};

const TooltipTrigger = ({ opacity, top, left, placeholderSize, height, className, bottom }: TooltipTriggerProps) => {
  const { refs, context } = useTooltipContext();

  useEffect(() => {
    context.update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [top, left, height]);

  // Overlaps the datapoint in the graph
  return (
    <div
      data-testid={TEST_IDS.generic.chart.datapoint}
      ref={refs.setReference}
      className={classNames(
        'absolute',
        {
          invisible: opacity === 0,
        },
        className,
      )}
      style={{
        bottom: bottom,
        top: top,
        left: left,
        width: placeholderSize,
        maxHeight: height,
      }}
    />
  );
};

function extractDatasetColor(dataset: ChartDataset): string {
  if (dataset.baseColor) {
    return dataset.baseColor;
  }

  if (dataset.backgroundColor) {
    return dataset.backgroundColor as string;
  }

  return '#1E1E1E';
}
