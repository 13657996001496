import { calculateMillisecondsInDays } from '@va/util/helpers';
import Config from 'Config';

export const dateOptions: Intl.DateTimeFormatOptions = {
  year: undefined,
  month: 'long',
  hour: 'numeric',
  minute: 'numeric',
  timeZone: 'UTC',
  timeZoneName: 'short',
};

export function getRelevantMaintenancePeriod() {
  if (!Array.isArray(Config?.maintenancePeriods)) {
    return null;
  }

  const currentDate = new Date();

  const sortedPeriods = Config?.maintenancePeriods.sort((a: [string, string], b: [string, string]) => {
    const aStartDate = new Date(a[0]).getTime();
    const bStartDate = new Date(b[0]).getTime();
    return aStartDate > bStartDate ? 1 : -1;
  });

  return sortedPeriods.find(([start, end]: [string, string]) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const paddedStartDate = new Date(startDate.getTime() - calculateMillisecondsInDays(7));

    if (currentDate.getTime() >= paddedStartDate.getTime() && currentDate.getTime() < startDate.getTime()) {
      return [start, end];
    } else if (currentDate.getTime() >= startDate.getTime() && currentDate.getTime() <= endDate.getTime()) {
      return [start, end];
    }
  });
}
