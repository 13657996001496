import { useTranslate } from '@va/localization';
import { useAddNotification } from './useAddNotification';

export const useExportNotification = () => {
  const { showInfoNotification, showErrorNotification } = useAddNotification();

  const translate = useTranslate();

  const showExportStartedNotification = () => {
    showInfoNotification(
      <span>
        <span className='text-15 font-medium block'>{translate('all.exportStartedNotification.title')}.</span>
        <span className='text-12 font-medium'>
          {translate('all.exportStartedNotification.info', { to: '/export/history' })}
        </span>
      </span>,
      10000,
    );
  };

  return { showExportStartedNotification, showErrorNotification };
};
