import classNames from 'classnames';
import { IconProps } from '.';

export const EraseIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.0607 2.55946L15.8787 5.37748L9.46969 11.7865L5.59101 7.90779L10.9393 2.55946C11.5251 1.97367 12.4749 1.97367 13.0607 2.55946ZM4.53035 8.96845L2.12132 11.3775L4.93934 14.1955C5.52513 14.7813 6.47487 14.7813 7.06066 14.1955L8.40903 12.8471L4.53035 8.96845ZM8.12132 15.2562L18 5.37748L14.1213 1.4988C12.9497 0.327223 11.0503 0.327224 9.87868 1.4988L0 11.3775L3.87868 15.2562C4.14746 15.5249 4.45455 15.732 4.78237 15.8775H1.5C1.08579 15.8775 0.75 16.2133 0.75 16.6275C0.75 17.0417 1.08579 17.3775 1.5 17.3775H16.5C16.9142 17.3775 17.25 17.0417 17.25 16.6275C17.25 16.2133 16.9142 15.8775 16.5 15.8775H7.21763C7.54545 15.732 7.85254 15.5249 8.12132 15.2562Z'
        fill={color}
      />
    </svg>
  );
};


