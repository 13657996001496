import { OnChangeFn, PaginationState } from '@tanstack/react-table';
import { useMutateOnRefresh } from '@va/dashboard/util-hooks';
import { CommonVisualizationComponentProps } from '@va/types/charts';
import { DataTableV8, useControlledTableState } from '@va/ui/components/data-table';
import { getReportBlockPaginationId, useReportBlockCtx } from '@va/ui/components/report-block';
import { memo, useCallback, useMemo } from 'react';
import { GlobalTimePeriodFilter } from '../../time-period-filter';
import { TableProvider } from './TableContext';
import { useTableViewComponentColumns } from './useTableViewComponentColumns';

export type TableViewComponentProps = {
  getTableHeaderTextFunc: (datasetKey: string, total: number) => string;
} & CommonVisualizationComponentProps;

export const TableViewComponent = memo(
  ({
    useCurrentData,
    usePreviousData,
    datasetKeys,
    getTableHeaderTextFunc,
    renderErrorComponent,
  }: TableViewComponentProps) => {
    const current = useCurrentData();
    const previous = usePreviousData();
    const { id } = useReportBlockCtx();

    const { isManualRefreshing } = useMutateOnRefresh(() => {
      current.mutate();
      previous.mutate();
    });

    const isLoading = current.isLoading || previous.isLoading || isManualRefreshing;

    const paginationId = useMemo(() => getReportBlockPaginationId(id), [id]);
    const columns = useTableViewComponentColumns({ datasetKeys, getTableHeaderTextFunc, currentData: current.data });
    const { pagination, setPagination, sorting, setSorting } = useControlledTableState({ pagination: { pageSize: 6 } });

    const handlePaginationChange = useCallback<OnChangeFn<PaginationState>>(
      (updater) => {
        if (typeof updater !== 'function') return;

        const updatedPagination = updater(pagination);
        setPagination(updatedPagination);
      },
      [pagination, setPagination],
    );

    const errorComponent = useMemo(() => {
      return renderErrorComponent
        ? renderErrorComponent(current.error ?? previous.error ?? undefined, isLoading)
        : undefined;
    }, [current.error, isLoading, previous.error, renderErrorComponent]);

    if (errorComponent) {
      return errorComponent;
    }

    return (
      <TableProvider previousData={previous.data?.tabularData ?? []}>
        <div className='space-y-3'>
          <div className='w-full flex min-h-[48px] justify-end'>
            <GlobalTimePeriodFilter />
          </div>
          <DataTableV8
            id={id}
            isLoading={isLoading}
            columns={columns}
            data={current.data?.tabularData ?? []}
            manualSorting={false}
            manualPagination={false}
            state={{ pagination, sorting }}
            onPaginationChange={handlePaginationChange}
            onSortingChange={setSorting}
            rowCount={current.data?.tabularData.length}
            paginationContainerId={paginationId}
          />
        </div>
      </TableProvider>
    );
  },
);
