import { IconProps } from '.';

export const CopyIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15 1.5H9C8.17157 1.5 7.5 2.17157 7.5 3V4.5H6V3C6 1.34315 7.34315 0 9 0H15C16.6569 0 18 1.34315 18 3V9C18 10.6569 16.6569 12 15 12H13.5V10.5H15C15.8284 10.5 16.5 9.82843 16.5 9V3C16.5 2.17157 15.8284 1.5 15 1.5ZM1.5 9C1.5 8.17157 2.17157 7.5 3 7.5H9C9.82843 7.5 10.5 8.17157 10.5 9V15C10.5 15.8284 9.82843 16.5 9 16.5H3C2.17157 16.5 1.5 15.8284 1.5 15V9ZM0 9C0 7.34315 1.34315 6 3 6H9C10.6569 6 12 7.34315 12 9V15C12 16.6569 10.6569 18 9 18H3C1.34315 18 0 16.6569 0 15V9Z'
        fill={color ?? 'var(--color-primary'}
      />
    </svg>
  );
};


