import { Platform } from '@va/constants';
import { patch } from '@va/http-client';
import { WebsiteStatus, storageItems } from '@va/standalone/shared/constants';
import { getUserRole } from '@va/standalone/shared/helpers';
import { SubscriptionStatus, UserRole } from '@va/types/website';
import { LocalStorage, toQueryString } from '@va/util/helpers';
import { useAsyncFunction, useFetchData } from '@va/util/hooks';
import { useCallback } from 'react';

export enum OrderType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type WebsiteQueryType = {
  start: number;
  length: number;
  withSubscription: boolean;
  withContributors?: boolean;
  withSharedWebsites?: boolean;
  withCommission?: boolean;
  order?: OrderType;
  withContributorRequests?: boolean;
};

type UserType = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type ContributorType = {
  user: UserType;
  types: UserRole;
};

export type ContributorRequestType = {
  id: string;
  fullName: string | null;
  email: string;
  types: UserRole[];
};

export type SubscriptionType = {
  activeUntil: null | string;
  isActiveUntil?: null | string;
  id: string;
  planId: string;
  status: SubscriptionStatus;
  updateUrl: string;
  planName: string;
};

export type WebsiteType = {
  id: string;
  isWebsiteOwner: boolean;
  url: string;
  platform: Platform;
  status: WebsiteStatus;
  timezone: string;
  sumoActive: boolean;
  whiteLabel: boolean;
  installDate: number;
  contributors: ContributorType[];
  contributorRequests: ContributorRequestType[];
  commission: null | number;
  subscription: SubscriptionType;
  trackingCodeInstalled: boolean | null;
  owner: { email: string; firstName: string; lastName: string };
  types: UserRole[];
  unlimitedPlanActive?: boolean;
};

export type WebsitesResponseType = {
  hasContributors: boolean;
  length: number;
  total: number;
  totalWhiteLabeled: number;
  websites: WebsiteType[];
};

export type MappedWebsite = WebsiteType & {
  userRole: UserRole | undefined;
};

export type MappedWebsiteResponse = {
  hasContributors: boolean;
  length: number;
  total: number;
  totalWhiteLabeled: number;
  websites: MappedWebsite[];
};

const mapWebsites = (websites: WebsiteType[]) => {
  const mappedData = websites?.map((website) => {
    const { types, contributorRequests } = website;
    return {
      ...website,
      isWebsiteOwner: types?.includes(UserRole.OWNER),
      userRole: getUserRole(types) || getUserRole(contributorRequests[0].types),
    };
  });
  return mappedData;
};

export const useWebsites = (websiteFilterQuery: WebsiteQueryType) => {
  const userId = LocalStorage.getItem(storageItems.userId);
  const mapperFn = useCallback((res: WebsitesResponseType) => {
    const { websites, ...restData } = res;
    return { ...restData, websites: mapWebsites(websites) };
  }, []);

  return useFetchData(
    `/users/${userId}/websites?${toQueryString(websiteFilterQuery)}`,
    {
      revalidateOnMount: true,
      revalidateIfStale: true,
    },
    mapperFn,
  );
};

export const useChangeWhiteLabelStatus = () => {
  const asyncFn = useCallback(async (websiteId: string, status: boolean) => {
    return patch(`/websites/${websiteId}/white-label/status/change`, {}, { status: status });
  }, []);

  return useAsyncFunction<typeof asyncFn>(asyncFn);
};
