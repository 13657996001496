import { useTranslate } from '@va/localization';
import { Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import classNames from 'classnames';

export const CombinationalOperator = ({
  size = 'large',
  operator = 'and',
  className,
}: {
  size?: 'small' | 'medium' | 'large';
  operator?: 'or' | 'and';
  className?: string;
}) => {
  const translate = useTranslate();
  return (
    <Paragraph
      weight={fontWeights.medium}
      size={paragraphSizes.tiny}
      className={classNames(
        'rounded-xl flex items-center justify-center px-3 bg-gray-concrete',
        {
          'h-12': size === 'large',
          'h-42px w-42px': size === 'small' || size === 'medium',
        },
        className,
      )}
    >
      {translate(`all.filters.combinationalOperators.${operator}`)}
    </Paragraph>
  );
};
