import { all } from 'redux-saga/effects';
import * as Account from './account';
import * as Billing from './billing';
import * as FirstPromoter from './first-promoter';
import * as Register from './register';
import * as Weebly from './weebly';

export function* watchers() {
  yield all([Account.watchers(), Register.watchers(), Billing.watchers(), FirstPromoter.watchers(), Weebly.watchers()]);
}
