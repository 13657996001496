export const BackIconRounded: React.FC<{ color?: string; className?: string }> = ({ color = 'black', className }) => {
  return (
    <svg
      className={className}
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 16.75C10.3661 16.75 11.6468 16.3848 12.75 15.7466V2.75337C11.6468 2.11523 10.3661 1.75 9 1.75C4.85786 1.75 1.5 5.10786 1.5 9.25C1.5 13.3921 4.85786 16.75 9 16.75ZM16.5 9.25C16.5 11.348 15.6385 13.2449 14.25 14.6061V3.89393C15.6385 5.25512 16.5 7.15195 16.5 9.25ZM9 18.25C13.9706 18.25 18 14.2206 18 9.25C18 4.27944 13.9706 0.25 9 0.25C4.02944 0.25 0 4.27944 0 9.25C0 14.2206 4.02944 18.25 9 18.25ZM8.03032 11.7197L6.31065 10H10.5V8.5H6.31065L8.03032 6.78033L6.96966 5.71967L3.43933 9.25L6.96966 12.7803L8.03032 11.7197Z'
        fill={color}
      />
    </svg>
  );
};
