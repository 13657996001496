import { openModal } from '@va/dashboard/actions/ui';
import { getSupportCenterDomain } from '@va/dashboard/selectors/customization';
import QuestionTooltip from '@va/deprecated/components/tooltips/QuestionTooltip';
import { Clock } from '@va/icons';
import { useTranslate } from '@va/localization';
import { START_CREATE_AGENCY_FLOW } from '@va/standalone/shared/actions';
import { modalName } from '@va/standalone/shared/constants';
import { AddPage, CheckMarkWithCircle } from '@va/svg-visa-icons';
import { Button } from '@va/ui/design-system';
import { useDispatch, useSelector } from 'react-redux';
import './WelcomePageAgencyCommission.scss';

const WelcomePageAgencyCommission = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const supportCenterDomain = useSelector(getSupportCenterDomain);

  function handleSelectedWhiteLabelWebsite(websiteId) {
    dispatch({ type: START_CREATE_AGENCY_FLOW, websiteId: websiteId });
  }

  function handleAgencyFreePlanClick() {
    dispatch(
      openModal(modalName.WHITE_LABEL_WEBSITE, {
        onSubmit: handleSelectedWhiteLabelWebsite,
      }),
    );
  }

  const steps = [
    {
      icon: <AddPage />,
      title: 'agencyCommission.welcomePage.step1',
      description: 'agencyCommission.welcomePage.description1',
    },
    {
      icon: <CheckMarkWithCircle />,
      title: 'agencyCommission.welcomePage.step2',
      description: 'agencyCommission.welcomePage.description2',
    },
    {
      icon: <Clock strokeColor='#04B5A6' />,
      title: 'agencyCommission.welcomePage.step3',
      description: `agencyCommission.welcomePage.description3`,
    },
  ];

  return (
    <div className='welcome-page-agency-commission-wrapper flex justify-center flex-column items-center'>
      <div className='flex flex-column'>
        <div className='flex justify-center text-center font-semibold welcome-page-title text-mine-shaft'>
          <div className=''>{translate('agencyCommission.welcomePage.title')}</div>
        </div>
        <div className='sm:text-base text-lg leading-24 tracking-default flex justify-center text-center mt-2'>
          <div className='sm:w-full sm:px-2 w-9/12'>{translate('agencyCommission.welcomePage.subTitle')}</div>
        </div>
      </div>
      <div className='welcome-page-agency-commission-steps-content'>
        {steps.map((item, index) => {
          return (
            <div className='flex items-center welcome-page-agency-commission-step' key={`step-${index}`}>
              <div className='welcome-page-agency-commission-step-icon bg-green-aquaSpring'>{item.icon}</div>
              <div className='flex flex-column ml-4'>
                <div className='font-semibold text-21 leading-30 tracking-normalTight'>{translate(item.title)}</div>
                <div className='text-15 leading-24 tracking-tightestDefault'>{translate(item.description)}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className='flex justify-center mt-8'>
        <Button text={translate('button.agencyWhiteLabel.tryFreePlan')} onClick={handleAgencyFreePlanClick} />
      </div>
      <div className='flex justify-center mt-3'>
        <div className='flex items-center bg-gray-alabaster p-6 rounded-12'>
          <QuestionTooltip>
            {translate('agencyCommission.welcomePage.tooltip.helpDesk', { supportCenterDomain })}
          </QuestionTooltip>
          <div className='ml-2'>{translate('agencyCommission.welcomePage.helpDesk', { supportCenterDomain })}</div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePageAgencyCommission;
