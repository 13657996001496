import { useTranslate } from '@va/localization';
import PropTypes from 'prop-types';
import './PlanPrice.scss';

const PlanPrice = (props) => {
  const { planName, price } = props;
  const translate = useTranslate();

  return (
    <div className='plan-price-wrapper flex flex-column align-center justify-center'>
      <div className='font-inter font-semibold text-3xl leading-9 text-mine-shaft'>{planName}</div>
      <div className='plan-price font-inter font-semibold text-6xl leading-60 text-mine-shaft'>{price} </div>
      <div className='font-inter font-medium text-21 leading-9 text-gray-devil'>
        {translate('upgradeModal.price.period.per.monthly')}
      </div>
    </div>
  );
};

PlanPrice.propTypes = {
  price: PropTypes.string.isRequired,
  planName: PropTypes.string.isRequired,
};

export default PlanPrice;
