import {
  CREATE_SSR_SETTINGS,
  CREATE_SSR_SETTINGS_FAILED,
  CREATE_SSR_SETTINGS_SUCCEEDED,
  GET_RECORDINGS_COUNT,
  GET_RECORDINGS_COUNT_FAILED,
  GET_RECORDINGS_COUNT_SUCCEEDED,
  GET_SSR_SETTINGS,
  GET_SSR_SETTINGS_FAILED,
  GET_SSR_SETTINGS_SUCCEEDED,
  GET_VIDEO_TRACKING_CODE_FAILED,
  GET_VIDEO_TRACKING_CODE_REQUEST,
  GET_VIDEO_TRACKING_CODE_SUCCEEDED,
  PROMPT_SSR_FEATURE,
  PROMPT_SSR_FEATURE_FAILED,
  PROMPT_SSR_FEATURE_SUCCEEDED,
  UPDATE_SSR_SETTINGS,
  UPDATE_SSR_SETTINGS_FAILED,
  UPDATE_SSR_SETTINGS_SUCCEEDED,
} from '@va/dashboard/actions/api';
import { newApiRequest } from './core/util';

export const promptSsrFeature = newApiRequest(
  PROMPT_SSR_FEATURE,
  PROMPT_SSR_FEATURE_SUCCEEDED,
  PROMPT_SSR_FEATURE_FAILED,
);

export const getVideoTrackingCode = newApiRequest(
  GET_VIDEO_TRACKING_CODE_REQUEST,
  GET_VIDEO_TRACKING_CODE_SUCCEEDED,
  GET_VIDEO_TRACKING_CODE_FAILED,
);

export const getRecordingsCount = newApiRequest(
  GET_RECORDINGS_COUNT,
  GET_RECORDINGS_COUNT_SUCCEEDED,
  GET_RECORDINGS_COUNT_FAILED,
);

export const createSsrSettings = newApiRequest(
  CREATE_SSR_SETTINGS,
  CREATE_SSR_SETTINGS_SUCCEEDED,
  CREATE_SSR_SETTINGS_FAILED,
);

export const getSsrSettings = newApiRequest(GET_SSR_SETTINGS, GET_SSR_SETTINGS_SUCCEEDED, GET_SSR_SETTINGS_FAILED);

export const updateSsrSettings = newApiRequest(
  UPDATE_SSR_SETTINGS,
  UPDATE_SSR_SETTINGS_SUCCEEDED,
  UPDATE_SSR_SETTINGS_FAILED,
);
