import { GRAPH_LEVEL } from '@va/constants';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';

export const getFunnelDetailGraphLevel = (percent) => {
  if (!percent && isNaN(percent)) return '';
  if (percent >= 80) {
    return GRAPH_LEVEL.HIGH;
  }
  if (percent < 80 && percent >= 40) {
    return GRAPH_LEVEL.MEDIUM;
  }
  if (percent < 40) {
    return GRAPH_LEVEL.LOW;
  }
  return '';
};

const FunnelLevel = ({ percentage, value, isFirst, isLast, text }) => {
  const graphLevel = useMemo(() => getFunnelDetailGraphLevel(percentage), [percentage]);

  const levelbgColor = useMemo(() => {
    if (graphLevel === GRAPH_LEVEL.MEDIUM) {
      return 'bg-fuel-yellow';
    }
    if (graphLevel === GRAPH_LEVEL.LOW) {
      return 'bg-pomegranate';
    }
    return 'bg-salem w-100';
  }, [graphLevel]);
  return (
    <div
      className={`mx-auto p-6 ${levelbgColor} ${isFirst ? 'rounded-t-30' : isLast ? 'rounded-b-30' : ''}`}
      style={{ width: Math.max(percentage, 30) + '%' }}
    >
      <div className='flex flex-row justify-center content-center h-full '>
        <div className='flex flex-column leading-6'>
          <span className='text-2xl md:text-3xl leading-9 text-center text-white font-semibold'>{percentage} %</span>
          <span className='text-sm text-center leading-6 text-white font-semibold'>
            {value} {text}
          </span>
        </div>
      </div>
    </div>
  );
};

FunnelLevel.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  percentage: PropTypes.number,
  value: PropTypes.number,
  text: PropTypes.string,
};

export default memo(FunnelLevel);
