import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { scrollToActiveElement } from '../helpers';
import './style.css';

export default class EventsList extends PureComponent {
  constructor(props) {
    super(props);

    this.eventsWrapperRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lastPlayedEventIndex !== this.props.lastPlayedEventIndex) {
      //todo check whether this is required
      //currently, it prevents user from scrolling through events when the replay is on
      // this.scrollTo();
    }
  }

  scrollTo = () => {
    const wrapperRef = this.eventsWrapperRef.current;

    if (!wrapperRef) return;

    //check for event
    let activeElement = wrapperRef.querySelector('div[data-event-active="true"]');
    if (!activeElement) return;

    scrollToActiveElement(activeElement, wrapperRef);
  };

  render() {
    const { events, lastPlayedEventIndex } = this.props;

    return (
      <div className='events-list-wrapper' ref={this.eventsWrapperRef}>
        <h1>Events log</h1>
        {events.map((event, index) => {
          return (
            event.color &&
            event.details && (
              <div
                key={event.id}
                onClick={() => this.props.onEventClick(event)}
                data-event-active={index === lastPlayedEventIndex}
                style={{ color: event.color }}
                className={`events-list__item ${index === lastPlayedEventIndex ? 'events-list__item--active' : ''}`}
              >
                {event.details}
              </div>
            )
          );
        })}
      </div>
    );
  }
}

EventsList.propTypes = {
  events: PropTypes.array.isRequired,
  lastPlayedEventIndex: PropTypes.number.isRequired,
  onEventClick: PropTypes.func.isRequired,
};
