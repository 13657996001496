import Switch from 'react-switch';

type ToggleSwitchProps = {
  onChange: (checked: boolean) => void;
  checked: boolean;
  className?: string;
  disabled?: boolean;
  'data-testid'?: string;
};

export const ToggleSwitch = ({
  onChange,
  checked = false,
  className = '',
  disabled,
  'data-testid': dataTestId,
}: ToggleSwitchProps) => {
  return (
    <Switch
      className={className}
      handleDiameter={24}
      offHandleColor='#FFFFFF'
      onHandleColor={'#FFFFFF'}
      offColor='#C3C3C3'
      onColor={window.POSITIVE_COLOR}
      onChange={onChange}
      checked={checked}
      checkedIcon={false}
      uncheckedIcon={false}
      width={48}
      height={30}
      disabled={disabled}
      data-testid={dataTestId}
    />
  );
};
