import { useTranslate } from '@va/localization';
import { AddWebsiteForm } from '@va/standalone/core/shared/onboarding';
import { useCallback } from 'react';

type AddWebsiteUrlStepProps = { onContinue: (url: string) => void; initialValue?: string };

const AddWebsiteUrlStep = ({ onContinue, initialValue }: AddWebsiteUrlStepProps) => {
  const translate = useTranslate();

  const onSubmit = useCallback(
    (values: { url: string }) => {
      onContinue(values.url);
    },
    [onContinue],
  );

  return (
    <AddWebsiteForm
      subtitle={translate('standalone.websiteCreation.websiteUrl.subtitle')}
      title={translate('standalone.websiteCreation.websiteUrl.title')}
      onSubmit={onSubmit}
      initialValues={{ url: initialValue ?? '' }}
    />
  );
};

export default AddWebsiteUrlStep;
