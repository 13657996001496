export const DuplicateIcon = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='7' y='7' width='10' height='10' rx='3' stroke='#666666' strokeWidth='2' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 2H8C9.10457 2 10 2.89543 10 4H12C12 1.79086 10.2091 0 8 0H4C1.79086 0 0 1.79086 0 4V8C0 10.2091 1.79086 12 4 12V10C2.89543 10 2 9.10457 2 8V4C2 2.89543 2.89543 2 4 2Z'
        fill='#666666'
      />
    </svg>
  );
};
