import { toArray } from './toArray';

export function findClosest(node, selector) {
  if (node.closest !== undefined) {
    return node.closest(selector);
  }

  const matches = toArray(document.querySelectorAll(selector));
  return findClosestInArray(node, matches);
}

export function findClosestInArray(node, elements) {
  if (elements.length === 0) {
    return null;
  }

  let element = node;
  let index;
  do {
    index = elements.length;
    // when an element is found 'index' remains greater than or equal to zero
    while (--index >= 0 && elements[index] !== element) {
      /* eslint-disable no-empty */
    }
    // 'parentElement' is not set for svg elements on IE 11 so we check parentNode as well
  } while (index < 0 && (element = element.parentElement || element.parentNode));
  return element;
}
