import L from 'leaflet';
import PropTypes from 'prop-types';
import { Component } from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';

const checkCommaPosForCluster = (str, count) => {
  if (count > 9999) {
    const commaPos = str.indexOf(',');
    return commaPos !== -1 ? str.substring(0, commaPos) : str;
  }

  return str.substring(0, 3);
};

const processBigNumber = (count, checkCommaPosFn) => {
  let labelStr = count.toString();
  const labelLength = labelStr.length;
  labelStr = labelStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const labelSubstr = labelStr.substr(0, 4);

  // Substr up to 3 digits + k/M/B if needed
  if (labelLength < 4) {
    return count;
  }

  if (labelLength > 3 && labelLength <= 6) {
    return checkCommaPosFn(labelSubstr, count) + 'k';
  }

  if (labelLength > 6 && labelLength <= 9) {
    return checkCommaPosFn(labelSubstr, count) + 'M';
  }

  if (labelLength > 9 && labelLength <= 12) {
    return checkCommaPosFn(labelSubstr, count) + 'B';
  }
  return count;
};

const createClusterCustomIcon = (cluster) => {
  const clusterCount = cluster.getChildCount();

  return L.divIcon({
    html: `<div><span>${processBigNumber(clusterCount, checkCommaPosForCluster)}</span></div>`,
    className: 'marker-cluster marker-cluster-small',
    iconSize: L.point(40, 40, true),
  });
};

export default class CustomMarkerClusterGroup extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <MarkerClusterGroup
        maxClusterRadius={50}
        removeOutsideVisibleBounds={true}
        iconCreateFunction={createClusterCustomIcon}
      >
        {this.props.markers}
      </MarkerClusterGroup>
    );
  }
}

CustomMarkerClusterGroup.propTypes = {
  markers: PropTypes.array.isRequired,
};
