import { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';

type RefreshContextType = {
  isManualRefreshing: boolean;
  isRefreshing: boolean;
  manualRefresh: () => void;
  autoRefresh: () => void;
  isAutoRefreshing: boolean;
};

const RefreshContext = createContext<RefreshContextType>({} as RefreshContextType);

const RefreshContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [isManualRefreshing, setIsManualRefreshing] = useState(false);
  const [isAutoRefreshing, setIsAutoRefreshing] = useState(false);

  const manualRefresh = useCallback(() => {
    setIsManualRefreshing(true);
    setTimeout(() => {
      setIsManualRefreshing(false);
    }, 500);
  }, []);

  const autoRefresh = useCallback(() => {
    setIsAutoRefreshing(true);
    setTimeout(() => {
      setIsAutoRefreshing(false);
    }, 500);
  }, []);

  return (
    <RefreshContext.Provider
      value={{
        manualRefresh,
        autoRefresh,
        isRefreshing: isManualRefreshing || isAutoRefreshing,
        isManualRefreshing,
        isAutoRefreshing,
      }}
    >
      {children}
    </RefreshContext.Provider>
  );
};

const useRefreshContext = () => {
  return useContext(RefreshContext);
};

export { RefreshContextProvider, useRefreshContext };
