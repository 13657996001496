import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

/** @deprecated */
export default class PopUpCard extends PureComponent {
  renderHeaderIcon() {
    return this.props.icon ? (
      <div className='popup-header-icon'>{React.cloneElement(this.props.icon, { className: 'icon' })}</div>
    ) : null;
  }

  render() {
    const {
      type,
      colorTheme,
      invalid,
      title,
      preTitle,
      subtitle,
      noPadding,
      children,
      footerButtons,
      smallerBottomSpace,
      noFooterMargin,
      centeredTitle,
      withBackBtn,
      hasBackButton,
    } = this.props;
    const validityClass = invalid ? 'invalid' : 'valid';

    return (
      <div
        className={classNames(`box popup-box overflow-x-hidden ${type} ${colorTheme} ${validityClass}`, {
          'with-back-btn': withBackBtn,
        })}
      >
        <div className='scrollable-content scrollbar scrollbar-thumb'>
          {this.renderHeaderIcon()}
          {preTitle}

          <div
            className={classNames('popup-title', {
              'sm:ml-10 sm:mr-10 h-8 items-center': hasBackButton,
              'text-center': centeredTitle,
            })}
          >
            {title}
          </div>
          <h3 className='popup-subtitle'>{subtitle}</h3>
          <div className={`popup-content ${noPadding ? 'no-padding' : ''}`}>{children}</div>

          {!!footerButtons && (
            <div
              className={`popup-footer ${smallerBottomSpace ? 'smaller-bottom-space' : ''} ${
                noFooterMargin ? 'no-margin-top' : ''
              }`}
            >
              {footerButtons}
            </div>
          )}
        </div>
      </div>
    );
  }
}

PopUpCard.propTypes = {
  title: PropTypes.any,
  preTitle: PropTypes.element,
  subtitle: PropTypes.any,
  type: PropTypes.oneOf(['card-grey', 'card-white']).isRequired,
  colorTheme: PropTypes.oneOf(['theme-green', 'theme-red', 'theme-purple', 'theme-blue', 'theme-orange', 'no-theme']),
  footerButtons: PropTypes.element,
  icon: PropTypes.any,
  noPadding: PropTypes.bool,
  smallerBottomSpace: PropTypes.bool,
  noFooterMargin: PropTypes.bool,
  centeredTitle: PropTypes.bool,
  invalid: PropTypes.bool,
  withBackBtn: PropTypes.bool,
  children: PropTypes.element,
  hasBackButton: PropTypes.bool,
};

PopUpCard.defaultTypes = {
  type: 'card-white',
  colorTheme: 'theme-green',
};
