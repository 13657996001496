type RadioButtonProps = {
  isSelected?: boolean;
};

export const RadioButton = (props: RadioButtonProps) => {
  const { isSelected = false } = props;
  return (
    <svg
      className={isSelected ? 'stroke-primary' : 'stroke-gray'}
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='6' cy='6' r='5' strokeWidth='2' />
      {isSelected && <circle cx='6' cy='6' r='1.5' strokeWidth='2.5' />}
    </svg>
  );
};
