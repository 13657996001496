export const TermsIcon = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='#3c3c3c' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 4.5V13.5C1.5 14.3284 2.17157 15 3 15H9C9.82843 15 10.5 14.3284 10.5 13.5V9V4.5C10.5 3.67157 9.82843 3 9 3H6H3C2.17157 3 1.5 3.67157 1.5 4.5ZM3 1.5C1.34315 1.5 0 2.84315 0 4.5V13.5C0 15.1569 1.34315 16.5 3 16.5H9C10.6569 16.5 12 15.1569 12 13.5V9V4.5C12 2.84315 10.6569 1.5 9 1.5H6H3ZM13.5 3.75C13.5 3.33579 13.8358 3 14.25 3C14.6642 3 15 3.33579 15 3.75V14.25C15 14.6642 14.6642 15 14.25 15C13.8358 15 13.5 14.6642 13.5 14.25V3.75ZM17.25 4.5C16.8358 4.5 16.5 4.83579 16.5 5.25V12.75C16.5 13.1642 16.8358 13.5 17.25 13.5C17.6642 13.5 18 13.1642 18 12.75V5.25C18 4.83579 17.6642 4.5 17.25 4.5Z'
      />
    </svg>
  );
};
