import { CommonFilterParams, Filter, FilterOperators } from '@va/types/filters';
import { FunnelFilterInput } from './FunnelFilterInput';
import { FunnelFilterInputApplied } from './FunnelFilterInputApplied';

export type FunnelFilterValue = { id: string | undefined; lvl: number | undefined };

export type FunnelFilterInputProps = {};

type FunnelFilterParams = CommonFilterParams<FunnelFilterValue, FunnelFilterInputProps>;

export class FunnelFilter extends Filter<FunnelFilterValue, FunnelFilterInputProps> {
  constructor(params: FunnelFilterParams) {
    super({
      ...params,
      operator: params.operator || FilterOperators.is,
      input: FunnelFilterInput,
      appliedInput: FunnelFilterInputApplied,
    });
  }
}
