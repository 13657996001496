import { validationTranslationKeys } from '@va/constants';
import { CloseIconWithCircle, SortAscArrow } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, fontWeights, GrayGradientDivider, Heading5, TextInput } from '@va/ui/design-system';
import { ModalWrapper } from '@va/util/components';
import { isValidCSSColor } from '@va/util/helpers';
import { useFormik } from 'formik';
import { isEqual } from 'lodash';
import { ChangeEvent, useCallback, useRef } from 'react';
import * as yup from 'yup';
import { ThemeColorInputItem } from './ThemeColorInputItem';
import { ThemeColorsType, ThemeColorVariantsEnum } from './types';

export type EditThemeModalComponentProps = {
  isOpen: boolean;
  closeModal: () => void;
  onSave: (values: ThemeColorsType & { name: string }) => void;
  initialColors: ThemeColorsType;
  themeName: string;
  title: string;
  isSaveButtonDisabled?: boolean;
};

export const EditThemeModalComponent = ({
  isOpen,
  closeModal,
  onSave,
  initialColors,
  themeName,
  title,
  isSaveButtonDisabled,
}: EditThemeModalComponentProps) => {
  const modalContentRef = useRef<HTMLDivElement>(null);
  const translate = useTranslate();

  const { values, errors, handleChange, setFieldValue, handleSubmit, setFieldTouched, handleBlur, touched } = useFormik(
    {
      enableReinitialize: true,
      initialValues: { ...initialColors, name: themeName },
      validationSchema: getValidationSchema(initialColors, translate),
      onSubmit: (values) => {
        onSave(values);
      },
    },
  );

  const hasMadeChanges = !isEqual(values, {
    ...initialColors,
    name: themeName,
  });

  const clearField = useCallback(
    (fieldName: string) => {
      setFieldValue(fieldName, '');
    },
    [setFieldValue],
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
      setFieldTouched(fieldName);
      handleChange(e);
    },
    [handleChange, setFieldTouched],
  );

  const updateFieldValue = useCallback(
    (fieldName: string, value: string) => {
      setFieldValue(fieldName, value, true);
    },
    [setFieldValue],
  );

  return (
    <ModalWrapper isModalOpen={isOpen} closeModal={closeModal}>
      <div ref={modalContentRef} className='p-2 sm-initial:p-5 max-w-720px'>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          <div className='flex justify-between items-center pt-2 pb-5'>
            <Heading5 weight={fontWeights.semibold} colorClassName='text-gray-devil'>
              {title}
              <SortAscArrow color='#696969' className='inline rotate-90 w-4 h-4 mx-2' />
              <span className='text-gray-charcoal'>{themeName}</span>
            </Heading5>
            <Button color='quinary' onClick={closeModal} icon={() => <CloseIconWithCircle />} />
          </div>
          <GrayGradientDivider className='-translate-x-5' />
          <TextInput
            disabled={true}
            wrapperClassName='py-4 px-1 sm-initial:py-7'
            name='name'
            clearField={() => {
              clearField('name');
            }}
            error={touched.name ? errors.name : undefined}
            onChange={(e) => {
              onChange(e, 'name');
            }}
            label={translate('whiteLabel.theme.label.name')}
            value={values.name}
          />
          <GrayGradientDivider className='-translate-x-5' />
          <div className='p-2 sm-initial:py-7 sm-initial:px-2 space-y-5 sm-initial:max-h-500 sm-initial:overflow-auto scrollbar scrollbar-thumb'>
            {Object.keys(values).map((key, index) => {
              if (key === 'name') return null;

              const fieldName = key as ThemeColorVariantsEnum;
              const label = translate(`whiteLabel.colors.${key}.label`);
              const info = translate(`whiteLabel.colors.${key}.info`);

              return (
                <ThemeColorInputItem
                  onSelectHexColor={(color) => {
                    updateFieldValue(fieldName, color);
                  }}
                  error={touched[fieldName] ? errors[fieldName] : undefined}
                  key={index}
                  name={key}
                  info={info}
                  color={values[fieldName]!}
                  onChange={(e) => {
                    onChange(e, key);
                  }}
                  clearField={() => {
                    clearField(fieldName);
                  }}
                  onBlur={handleBlur}
                  label={label}
                />
              );
            })}
          </div>
          <div className='bg-white'>
            <GrayGradientDivider className='-translate-x-5' />
            <div className='flex flex-col sm-initial:flex-row gap-3 pt-5 pb-3 sm-initial:pb-0'>
              <Button className='flex-1' onClick={closeModal} color='tertiary' text={translate('button.cancel')} />
              <Button
                disabled={isSaveButtonDisabled || !hasMadeChanges}
                type={isSaveButtonDisabled ? 'button' : 'submit'}
                className='flex-1'
                color='primary'
                text={translate('button.saveThemeChanges')}
              />
            </div>
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};

function getValidationSchema(themeColors: ThemeColorsType, translate: Function) {
  return yup.object().shape({
    name: yup.string().required(translate(validationTranslationKeys.required)),
    ...Object.keys(themeColors).reduce((acc, key) => {
      return {
        ...acc,
        [key]: yup
          .string()
          .required(translate(validationTranslationKeys.required))
          .test('isValidColor', translate('whiteLabel.invalidColorCode'), (value) => isValidCSSColor(value ?? '')),
      };
    }, {}),
  });
}
