import { apiStatus } from '@va/constants';
import LoadingCard from '@va/deprecated/components/PopUpCard/card-types/LoadingCard';
import { useTranslate } from '@va/localization';
import { renderIf } from '@va/util/helpers';
import React, { memo, useEffect, useState } from 'react';

import MessageCard from 'standalone/components/cards/PopUpCard/card-types/MessageCard';

const ConfirmUpdateEmailComponent: React.FC<{ closeModal: Function; changeEmailStatus: string }> = ({
  closeModal,
  changeEmailStatus,
}) => {
  const translate = useTranslate();
  const [showLoading, setShowLoading] = useState(true);
  const [showFailedView, setShowFailedView] = useState(false);
  const renderConfirmationMessage = renderIf(!showLoading);
  const renderLoading = renderIf(showLoading);

  useEffect(() => {
    setShowLoading(changeEmailStatus === apiStatus.IN_PROGRESS);
    setShowFailedView(changeEmailStatus === apiStatus.FAILED);
  }, [changeEmailStatus]);

  return (
    <div className='modal-content'>
      {renderConfirmationMessage(
        <MessageCard
          modalName='changeEmail'
          confirmationView={true}
          translate={translate}
          onClose={closeModal}
          isConfirmEmailError={showFailedView}
        />,
      )}

      {renderLoading(<LoadingCard />)}
    </div>
  );
};

export default memo(ConfirmUpdateEmailComponent);
