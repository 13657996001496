import { IconProps } from '.';

export const ModuleIcon = ({ className, color = '#000000' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.75 1.5C2.50736 1.5 1.5 2.50736 1.5 3.75C1.5 4.99264 2.50736 6 3.75 6H6V3.75C6 2.50736 4.99264 1.5 3.75 1.5ZM7.5 6V3.75C7.5 1.67893 5.82107 0 3.75 0C1.67893 0 0 1.67893 0 3.75C0 5.82107 1.67893 7.5 3.75 7.5H6V10.5H3.75C1.67893 10.5 0 12.1789 0 14.25C0 16.3211 1.67893 18 3.75 18C5.82107 18 7.5 16.3211 7.5 14.25V12H10.5V14.25C10.5 16.3211 12.1789 18 14.25 18C16.3211 18 18 16.3211 18 14.25C18 12.1789 16.3211 10.5 14.25 10.5H12V7.5H14.25C16.3211 7.5 18 5.82107 18 3.75C18 1.67893 16.3211 0 14.25 0C12.1789 0 10.5 1.67893 10.5 3.75V6H7.5ZM7.5 7.5V10.5H10.5V7.5H7.5ZM12 6H14.25C15.4926 6 16.5 4.99264 16.5 3.75C16.5 2.50736 15.4926 1.5 14.25 1.5C13.0074 1.5 12 2.50736 12 3.75V6ZM12 12V14.25C12 15.4926 13.0074 16.5 14.25 16.5C15.4926 16.5 16.5 15.4926 16.5 14.25C16.5 13.0074 15.4926 12 14.25 12H12ZM6 12H3.75C2.50736 12 1.5 13.0074 1.5 14.25C1.5 15.4926 2.50736 16.5 3.75 16.5C4.99264 16.5 6 15.4926 6 14.25V12Z'
        fill={color}
      />
    </svg>
  );
};
