import { EventType, MappedEventType, RegularEventTypeEnum } from '@va/types/recordings';
import { USER_ABORTED_REQUEST_MSG } from '@va/util/hooks';
import { createContext, PropsWithChildren, RefObject, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { KeyedMutator } from 'swr';
import { SessionInfoType, useGetSessionInfo } from './apiClient';

type RecordingsDetailsContextType = {
  events: MappedEventType[];
  onParseEvents: (parsedEvents: EventType[]) => void;
  playerRef: RefObject<HTMLElement>;
  onEventClick: (event: MappedEventType) => void;
  currentEventIndex: number;
  onCurrentEventChange: (index: number) => void;
  mutateSessionInfo: KeyedMutator<SessionInfoType>;
  sessionInfo: SessionInfoType | undefined;
  isLoadingSessionInfo: boolean;
  hasError: boolean;
  parentEventIndex: number;
};

const RecordingsDetailsContext = createContext<RecordingsDetailsContextType>({} as RecordingsDetailsContextType);

export const RecordingsDetailsContextProvider: React.FC<PropsWithChildren<{ sessionId?: string | null }>> = ({
  children,
  sessionId: sessionIdProp,
}) => {
  const [events, setEvents] = useState<MappedEventType[]>([]);
  const [currentEventIndex, setCurrentEventIndex] = useState(0);

  const parentEventIndex = useRef(-1);
  const playerRef = useRef<any>(null);
  const { sessionId } = useParams<{ sessionId: string }>();
  const {
    data: sessionInfo,
    isLoading: isLoadingSessionInfo,
    mutate: mutateSessionInfo,
    error,
  } = useGetSessionInfo(sessionIdProp ?? sessionId);

  const onParseEvents = useCallback((parsedEvents: EventType[]) => {
    setEvents(eventsMapper(parsedEvents));
  }, []);

  const onCurrentEventChange = useCallback(
    (eventIndex: number) => {
      setCurrentEventIndex(eventIndex);
      parentEventIndex.current = events.findIndex((e) => e.eventIndexes.includes(eventIndex));
    },
    [events],
  );

  const onEventClick = useCallback((event: MappedEventType) => {
    if (!playerRef.current) return;
    setCurrentEventIndex(event.eventIndexes[0]);
    playerRef.current?.playFromEvent(event);
  }, []);

  const hasError = useMemo(() => {
    if (error?.message?.includes(USER_ABORTED_REQUEST_MSG)) return false;
    if (error) return true;
    if (!Array.isArray(sessionInfo)) return false;
    // @ts-ignore
    if (sessionInfo.length === 0) return true; // The api call is not failing with 404 if the session is not found, empty array is returned instead
    return false;
  }, [error, sessionInfo]);

  return (
    <RecordingsDetailsContext.Provider
      value={{
        events,
        onParseEvents,
        playerRef,
        onEventClick,
        currentEventIndex,
        onCurrentEventChange,
        sessionInfo,
        isLoadingSessionInfo,
        mutateSessionInfo,
        hasError,
        parentEventIndex: parentEventIndex.current,
      }}
    >
      {children}
    </RecordingsDetailsContext.Provider>
  );
};

const useRecordingsDetailsContext = () => useContext(RecordingsDetailsContext);

export default useRecordingsDetailsContext;

function eventsMapper(events: EventType[]) {
  const mappedEvents: MappedEventType[] = [];

  events.forEach((event, index) => {
    const lastMappedEvent = mappedEvents[mappedEvents.length - 1];
    if (
      !lastMappedEvent ||
      lastMappedEvent.name !== RegularEventTypeEnum.scroll ||
      event.name !== RegularEventTypeEnum.scroll
    ) {
      mappedEvents.push({ ...event, eventIndexes: [index] });
      return;
    }
    mappedEvents[mappedEvents.length - 1] = {
      ...lastMappedEvent,
      to: event.delay,
      eventIndexes: [...lastMappedEvent.eventIndexes, index],
    };
  });
  return mappedEvents;
}
