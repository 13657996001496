import { makeAction } from '@va/store';

export const SET_HIGHLIGHTED_RECORDING_ID = 'SET_HIGHLIGHTED_RECORDING_ID';
export const REMOVE_HIGHLIGHTED_RECORDING_ID = 'REMOVE_HIGHLIGHTED_RECORDING_ID';

export const SHOW_ALL_TIME_UNSEEN_RECORDINGS = 'SHOW_ALL_TIME_UNSEEN_RECORDINGS';

export const setHighlightedRecordingId = makeAction(SET_HIGHLIGHTED_RECORDING_ID, 'payload');
export const removeHighlightedRecordingId = makeAction(REMOVE_HIGHLIGHTED_RECORDING_ID);

export const setShowAllTimeUnseenRecordings = makeAction(SHOW_ALL_TIME_UNSEEN_RECORDINGS, 'payload');
