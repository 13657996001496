import { get, patch, post, remove } from '@va/http-client';

export const getDynamicPages = (websiteId) => {
  const url = `/websites/${websiteId}/dynamic-pages`;
  return get(url, {});
};

export const addDynamicPage = (websiteId, data) => {
  const url = `/websites/${websiteId}/dynamic-pages`;
  return post(url, {}, data);
};

export const editDynamicPage = (websiteId, pageId, data) => {
  const url = `/websites/${websiteId}/dynamic-pages/${pageId}`;
  return patch(url, {}, data);
};

export const removeDynamicPage = (websiteId, pageId) => {
  const url = `/websites/${websiteId}/dynamic-pages/${pageId}`;
  return remove(url, {});
};
