import { IconProps } from '.';

export const CheckmarkIconFilled = ({ className, color = '#06A25A' }: IconProps) => {
  return (
    <svg
      className={className}
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12.5' cy='12' r='11.25' stroke={color} strokeWidth='1.5' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5 19.5C16.6421 19.5 20 16.1421 20 12C20 7.85786 16.6421 4.5 12.5 4.5C8.35786 4.5 5 7.85786 5 12C5 16.1421 8.35786 19.5 12.5 19.5ZM12.2803 14.7803L16.7803 10.2803L15.7197 9.21967L11.75 13.1893L9.28033 10.7197L8.21967 11.7803L11.2197 14.7803L11.75 15.3107L12.2803 14.7803Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5 19.5C16.6421 19.5 20 16.1421 20 12C20 7.85786 16.6421 4.5 12.5 4.5C8.35786 4.5 5 7.85786 5 12C5 16.1421 8.35786 19.5 12.5 19.5ZM12.5 21C17.4706 21 21.5 16.9706 21.5 12C21.5 7.02944 17.4706 3 12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21Z'
        fill={color}
      />
    </svg>
  );
};


