import { useLocale } from '@va/localization';
import { addTransparencyToHexColor } from '@va/util/helpers';
import { Chart, ChartConfiguration, ChartData } from 'chart.js';
import classNames from 'classnames';
import { assign, cloneDeep } from 'lodash';
import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

export type RadarChartProps = {
  id: string;
  chartData: ChartData<'radar'>;
  wrapperStyle?: CSSProperties;
  tooltipFn?: (tooltipCtx: any) => ReactNode;
  className?: string;
  isLoading?: boolean;
};

export const RadarChart = ({ isLoading, className, wrapperStyle, chartData, tooltipFn }: RadarChartProps) => {
  const { locale } = useLocale();
  const canvasRef = useRef<HTMLCanvasElement | null>();
  const containerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<Chart<'radar'>>();

  const [tooltipContext, setTooltipContext] = useState<any>();
  const [clonedChartData, setClonedChartData] = useState<ChartData<'radar'>>({
    datasets: [],
    labels: [],
  });

  useEffect(() => {
    // cloning is required because we're using the same references when updating
    setClonedChartData(cloneDeep(chartData));
  }, [chartData]);

  useEffect(() => {
    if (!canvasRef.current || !containerRef.current) return;
    const canvasContext = canvasRef.current.getContext('2d');
    if (!canvasContext) return;
    const hasSingleLabel = clonedChartData?.datasets[0]?.data?.length === 1;

    const config: ChartConfiguration<'radar'> = {
      type: 'radar',
      data: {
        labels: chartData.labels,
        datasets: chartData.datasets.map((dataset) => {
          return {
            ...dataset,
            backgroundColor: addTransparencyToHexColor(dataset.borderColor as string, 0.1),
            baseColor: dataset.borderColor as string,
            borderWidth: 2,
          };
        }),
      },
      options: {
        locale: locale,
        maintainAspectRatio: false,
        animation: false,
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            mode: 'index',
            position: 'nearest',
            enabled: !tooltipFn,
            external: (context) => setTooltipContext(context),
          },
        },
        scales: {
          r: {
            pointLabels: {
              ...buildTicks(),
              callback: (label, index) => {
                const length = chartData.labels?.length ?? 0;
                const maxLabels = 20;

                if (length > maxLabels) {
                  const step = Math.ceil(length / maxLabels);
                  if (index % step === 0) {
                    return label;
                  }

                  return '';
                }
                return label;
              },
            },
            ticks: {
              ...buildTicks(),
            },
          },
        },

        elements: {
          point: {
            radius: hasSingleLabel ? 2 : 0,
            hoverRadius: 4,
            hoverBorderWidth: 3,
            hoverBorderColor: '#fff',
          },
        },
      },
    };

    if (!chartRef.current) {
      chartRef.current = new Chart<'radar'>(canvasContext, config);
      // Initial chart creation
      return;
    }

    // If chart exists, update it
    updateChart(chartRef.current, config);
  }, [chartData, clonedChartData?.datasets, locale, tooltipFn]);

  return (
    <div ref={containerRef} className={classNames('relative', className)} style={wrapperStyle}>
      {isLoading && <Skeleton className='h-full rounded-24' />}
      <canvas
        className={classNames({ 'invisible absolute': isLoading })}
        ref={(ref) => {
          canvasRef.current = ref;
        }}
        width='400'
        height='400'
      />
      {tooltipContext && !isLoading && tooltipFn && tooltipFn(tooltipContext)}
    </div>
  );
};

function updateChart(chart: Chart<'radar'>, config: ChartConfiguration<'radar'>) {
  // Update chart options
  if (config.options) {
    chart.options = config.options;
  }

  // Update chart datasets
  if (chart.data.datasets.length !== config.data.datasets.length) {
    // If the nr of datasets is different, reassign the object
    chart.data.datasets = config.data.datasets;
  } else {
    for (let i = 0; i < config.data.datasets.length; i++) {
      const dataset = config.data.datasets[i];
      assign(chart.data.datasets[i], dataset);
    }
  }

  // Update chart labels
  if (chart.data.labels) {
    chart.data.labels = config.data.labels;
  }

  // Re-draw chart
  chart.update();
}

function buildTicks(color = '#696969', size = 12) {
  return {
    font: {
      family: window.fontFamily,
      size,
      weight: 500,
      lineHeight: '150%',
    },
    color,
  };
}
