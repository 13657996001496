import { createColumnHelper } from '@tanstack/react-table';
import { ClockIcon } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { BaseTimeseriesResponse } from '@va/types/time-series';
import { HeaderCellV8 } from '@va/ui/components/data-table';
import { useReportBlockCtx } from '@va/ui/components/report-block';
import { Paragraph, paragraphSizes } from '@va/ui/design-system';
import { addNumberSeparator, getPercentageChange, getPercentageColors } from '@va/util/helpers';
import classNames from 'classnames';
import { useMemo } from 'react';
import { SWRResponse } from 'swr';
import { useTableContext } from './TableContext';
import { TableViewComponentProps } from './TableViewComponent';

const columnHelper = createColumnHelper<Record<string, any>>();

export const useTableViewComponentColumns = ({
  datasetKeys,
  getTableHeaderTextFunc,
  currentData,
}: { currentData: SWRResponse<BaseTimeseriesResponse<any>, Error>['data'] } & Pick<
  TableViewComponentProps,
  'datasetKeys' | 'getTableHeaderTextFunc'
>) => {
  const translate = useTranslate();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor('date', {
        header: (context) => {
          return <HeaderCellV8 context={context} text={translate('all.visitsSessionsVisitors.tableHeaders.date')} />;
        },
        cell: ({ getValue }) => {
          return (
            <div className='flex gap-3 items-center'>
              <ClockIcon className='shrink-0' color='#3C3C3C' />
              <Paragraph>{getValue()}</Paragraph>
            </div>
          );
        },
        enableSorting: true,
        enableColumnFilter: true,
      }),
      ...datasetKeys.map((key) =>
        columnHelper.accessor(key, {
          header: (context) => {
            return <HeaderCellV8 context={context} text={getTableHeaderTextFunc(key, currentData?.total[key] ?? 0)} />;
          },
          cell: ({ getValue, row }) => {
            return <DataCell value={getValue()} kpi={key} index={row.index} />;
          },
          enableSorting: true,
          enableColumnFilter: true,
        }),
      ),
    ];
  }, [currentData?.total, datasetKeys, getTableHeaderTextFunc, translate]);

  return columns;
};

const DataCell = ({ value, kpi, index }: { value: number; kpi: string; index: number }) => {
  const { showPrevious } = useReportBlockCtx();
  const { getPreviousValue } = useTableContext();
  const prevVal = useMemo(() => getPreviousValue(index, kpi), [getPreviousValue, index, kpi]);
  const percentage = useMemo(() => getPercentageChange(prevVal, value), [prevVal, value]);
  const { locale } = useLocale();
  return (
    <div>
      <Paragraph>{addNumberSeparator(value, locale)}</Paragraph>
      <Paragraph
        className={classNames({ 'opacity-0': !showPrevious })}
        colorClassName={getPercentageColors(percentage)}
        size={paragraphSizes.tiny}
      >
        {addNumberSeparator(percentage, locale, { style: 'percent' })}
      </Paragraph>
    </div>
  );
};
