import { ReportBlockProps } from '@va/types/report-block';
import { IntersectionObserverProvider } from '@va/util/misc';
import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useState } from 'react';

// TODO Move and fix duplicate
export enum DateComparisonPeriod {
  PREVIOUS = 'previous',
  YOY = 'yoy',
}

export type ReportBlockCtx = ReportBlockProps & {
  showPrevious: boolean;
  setShowPrevious: Dispatch<SetStateAction<boolean>>;
  activeComparisonOption: DateComparisonPeriod;
  setActiveComparisonOption: Dispatch<SetStateAction<DateComparisonPeriod>>;
};

const ReportBlockCtx = createContext<ReportBlockCtx>({} as ReportBlockCtx);

export const ReportBlockProvider = ({ children, ...props }: PropsWithChildren<ReportBlockProps>) => {
  const [showPrevious, setShowPrevious] = useState<boolean>(props.showPrevious ?? false);
  const [activeComparisonOption, setActiveComparisonOption] = useState(DateComparisonPeriod.PREVIOUS);

  return (
    <ReportBlockCtx.Provider
      value={{
        ...props,
        showPrevious,
        setShowPrevious,
        activeComparisonOption,
        setActiveComparisonOption,
      }}
    >
      <IntersectionObserverProvider>{children}</IntersectionObserverProvider>
    </ReportBlockCtx.Provider>
  );
};

export const useReportBlockCtx = () => useContext(ReportBlockCtx);
