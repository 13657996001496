import { SHOULD_REFRESH_DATEPICKER, TOGGLE_DATEPICKER_VISIBILITY } from '@va/dashboard/actions/ui';
import Immutable from 'immutable';

const filterReducer = (
  state = Immutable.Map({
    isDatePickerVisible: false,
    shouldRefreshDatePicker: false,
  }),
  action,
) => {
  switch (action.type) {
    case SHOULD_REFRESH_DATEPICKER:
      return state.update('shouldRefreshDatePicker', (value) => !value);
    case TOGGLE_DATEPICKER_VISIBILITY:
      return state.update('isDatePickerVisible', (val) => !val);
    default:
      return state;
  }
};
export default filterReducer;
