import { setStepFilter } from '@va/dashboard/actions/ui';
import { getStepFilter } from '@va/dashboard/selectors/ui';
import { TimePeriod } from '@va/types/time';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VisaTimePeriodFilter } from './VisaTimePeriodFilter';

export function GlobalTimePeriodFilter() {
  const storeTimePeriodFilter = useSelector(getStepFilter);
  const dispatch = useDispatch();

  const onChange = useCallback(
    (timePeriod: TimePeriod) => {
      dispatch(setStepFilter({ period: timePeriod }));
    },
    [dispatch],
  );

  return <VisaTimePeriodFilter selectedTimePeriod={storeTimePeriodFilter.period} onChange={onChange} />;
}
