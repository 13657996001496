import { createReducer } from '@reduxjs/toolkit';
import { CustomizationState } from '@va/aaas/shared/types';
import {
  setChatwootToken,
  setCustomizationOptions,
  setProviderName,
  setSupportCenterDomain,
} from '@va/dashboard/actions/ui';
import { getWellFormedUrl } from '@va/util/helpers';

export const customizationReducer = createReducer<CustomizationState>(
  {
    providerName: '',
    supportCenterDomain: '',
    chatwootToken: '',
  },
  (builder) => {
    builder.addCase(setCustomizationOptions, (state, action) => {
      const { supportCenterDomain } = action.payload;
      return {
        ...state,
        ...action.payload,
        supportCenterDomain: supportCenterDomain ? getWellFormedUrl(supportCenterDomain) : state.supportCenterDomain,
      };
    });
    builder.addCase(setProviderName, (state, action) => {
      state.providerName = action.payload;
    });
    builder.addCase(setSupportCenterDomain, (state, action) => {
      state.supportCenterDomain = getWellFormedUrl(action.payload);
    });
    builder.addCase(setChatwootToken, (state, action) => {
      state.chatwootToken = action.payload;
    });
  },
);
