import * as Types from '@va/standalone/shared/actions';
import { SET_ONBOARDING_STEP, SET_WEBSITE_CREATION_STEP } from '@va/standalone/shared/actions';
import Immutable from 'immutable';

const appReducer = (
  state = Immutable.fromJS({
    appsumoUserId: null,
    brainTreeInstanceLoaded: false,
    listOfVouchers: [],
    onboardingStep: 0,
    websiteCreationStep: 0,
  }),
  action,
) => {
  switch (action.type) {
    case Types.standalone.App.INITIALIZE:
      return state.set('initializing', true);
    case Types.standalone.App.INITIALIZE_SUCCEEDED:
      return state.set('initializing', false);
    case Types.standalone.App.SET_ACTIVE_WEBSITE:
      return state.setIn(['setActiveWebsite', 'inProgress'], true).set('activeWebsiteId', action.websiteId);
    case Types.standalone.App.SET_ACTIVE_WEBSITE_SUCCEEDED:
      return state.setIn(['setActiveWebsite', 'inProgress'], false).setIn(['setActiveWebsite', 'data'], action.data);
    case Types.standalone.App.START_UPGRADE_NOW_FLOW:
      return state.set('websiteIdForUpgrade', action.websiteId);
    case Types.standalone.Api.REMOVE_WEBSITE_SUCCEEDED:
      return state.set('websiteIdToBeRemoved', null);
    case Types.standalone.App.SET_APPSUMO_STEP:
      return state.set('appsumoStep', action.step);
    case Types.standalone.App.SET_BRAINTREE_INSTANCE_LOADED:
      return state.set('brainTreeInstanceLoaded', action.value);
    case Types.standalone.App.SET_APPSUMO_USER_ID:
      return state.set('appsumoUserId', action.userId);
    case Types.standalone.App.UPDATE_VOUCHERS_LIST: {
      const voucherCode = action.voucher;
      return state.updateIn(['listOfVouchers'], (arr) => arr.push(voucherCode));
    }
    case Types.standalone.App.SET_VOUCHERS_LIST:
      return state.set('listOfVouchers', action.vouchers);
    case Types.standalone.App.SET_PADDLE_CALLBACK_EVENT:
      return state.set('paddleCheckoutEvents', action.data);
    case Types.standalone.App.ADD_AGENCY_CUSTOM_THEME:
      return state.set('agencyCustomTheme', action.data);
    case SET_ONBOARDING_STEP:
      return state.set('onboardingStep', action.data);
    case SET_WEBSITE_CREATION_STEP:
      return state.set('websiteCreationStep', action.data);
    case Types.standalone.App.SET_UPGRADE_PLAN_STEP:
      return state.set('upgradePlanStep', action.data);
    case Types.standalone.App.SET_UPGRADE_PLAN_INDEX:
      return state.set('upgradePlanIndex', action.data);
    case Types.standalone.App.RESET_UPGRADE_PLAN_INDEX:
      return state.set('upgradePlanIndex', undefined);
    default:
      return state;
  }
};

export default appReducer;
