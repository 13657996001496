import { RequireAppReinstall } from '@va/dashboard/components';
import { hasSsrCodeInstalled, isFreeWixWebsite } from '@va/dashboard/selectors/core';
import { useTranslate } from '@va/localization';
import { isWixClaimed } from '@va/standalone/shared/selectors';
import { MultiStepWizard } from '@va/ui/design-system';
import { isWixApp } from '@va/util/helpers';
import { isNil } from 'lodash';
import { PropsWithChildren, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import SsrOnboardingStep1 from './SsrOnboardingStep1';
import SsrOnboardingStep2 from './SsrOnboardingStep2';

type SsrOnboardingProps = {};

const useSsrOnboardingSteps = ({ redirectToDashboard }: { redirectToDashboard: boolean }) => {
  const translate = useTranslate();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);

  return {
    activeStep,
    steps: [
      {
        label: translate('modal.addWixSsrCode.firstPageTitle'),
        component: (
          <SsrOnboardingStep1
            onSuccess={() => {
              setActiveStep(1);
            }}
          />
        ),
      },
      {
        label: translate('modal.addWixSsrCode.secPageTitle'),
        component: (
          <SsrOnboardingStep2
            onSuccess={() => {
              if (!redirectToDashboard) return;
              history.push('/');
            }}
          />
        ),
      },
    ],
  };
};

export function SsrOnboarding({ children }: PropsWithChildren<SsrOnboardingProps>) {
  const ssrCodeInstalled = useSelector(hasSsrCodeInstalled);
  const isClaimedWebsite = useSelector(isWixClaimed);
  const { activeStep, steps } = useSsrOnboardingSteps({ redirectToDashboard: isNil(children) });
  const isFreeWix = useSelector(isFreeWixWebsite);

  const shouldShowSsrOnboarding = useMemo(() => {
    if (ssrCodeInstalled) return false;
    if (isWixApp()) return true;
    return isClaimedWebsite;
  }, [isClaimedWebsite, ssrCodeInstalled]);

  if (shouldShowSsrOnboarding && isFreeWix) {
    return (
      <div className='mt-6 mx-auto max-w-600 px-2'>
        <RequireAppReinstall />
      </div>
    );
  }

  if (shouldShowSsrOnboarding && !isFreeWix) {
    return (
      <div className='mt-6'>
        <MultiStepWizard steps={steps} activeStep={activeStep} />
      </div>
    );
  }

  return children;
}
