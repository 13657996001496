import { buttons } from '@va/constants';
import { Button } from '@va/ui/design-system';
import { useTranslate } from '@va/localization';
import PropTypes from 'prop-types';
import './ModalFooter.scss';

const ModalFooter = (props) => {
  const translate = useTranslate();
  const {
    onSubmitClick,
    onCancelClick,
    isSubmitDisabled,
    disabledTooltip,
    isAdditionalBtnDisabled,
    submitBtnText,
    cancelBtnText,
    additionalBtnText,
    onAdditionalButtonClick,
  } = props;

  return (
    <div className='modal-footer-wrapper'>
      <div className='modal-footer-content m-1 flex justify-end bg-gray-concrete items-center rounded-br-3xl rounded-bl-3xl '>
        {cancelBtnText && (
          <div className='mr-6px modal-footer-cancel-button' onClick={() => onCancelClick()}>
            {cancelBtnText || translate('button.cancel')}
          </div>
        )}
        {onAdditionalButtonClick && (
          <Button
            type='button'
            color='secondary'
            onClick={onAdditionalButtonClick}
            disabled={isAdditionalBtnDisabled}
            className='mr-6px'
            text={additionalBtnText}
          />
        )}
        <Button
          tooltip={isSubmitDisabled && disabledTooltip ? disabledTooltip : undefined}
          type={isSubmitDisabled ? 'button' : 'submit'}
          color='primary'
          onClick={() => onSubmitClick()}
          disabled={isSubmitDisabled}
          text={submitBtnText || translate('button.continue')}
        />
      </div>
    </div>
  );
};

ModalFooter.defaultProps = {
  button: buttons.PRIMARY,
  additionalButton: buttons.PRIMARY,
};

ModalFooter.propTypes = {
  onSubmitClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
  disabledTooltip: PropTypes.string,
  isAdditionalBtnDisabled: PropTypes.bool,
  submitBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  additionalBtnText: PropTypes.string,
  onAdditionalButtonClick: PropTypes.func,
};

export default ModalFooter;
