import { schemeCategory10 } from 'd3';

const colorPalette = schemeCategory10.concat([
  '#393b79',
  '#990099',
  '#6063C4',
  '#8C8ED2',
  '#637939',
  '#00069F',
  '#299B80',
  '#9CECCF',
  '#8c6d31',
  '#FE3600',
  '#e7ba52',
  '#E7E442',
  '#652926',
  '#ad494a',
  '#E7717B',
  '#FF5B68',
  '#7b4173',
  '#6801B8',
  '#B85401',
  '#DE00C1',
]);

let index = 0;
const hashCodes = {} as Record<string, any>;

export function getAdsChartBarColor(adName: string) {
  if (index >= colorPalette.length - 1) {
    index = 0;
  }

  const color = colorPalette[index];
  index++;

  if (typeof adName !== 'string') {
    return color;
  }

  switch (adName.toLowerCase()) {
    case 'campaign':
      return '#4A90E2';
    case 'organic':
      return '#F2B44F';
    case 'facebook':
      return '#3a559f';
    case 'google':
      return '#34a853';
    case 'yandex':
      return '#f04440';
    case 'reddit':
      return '#ff4500';
    case 'twitter':
      return '#5ea9dd';
    case 'linkedin':
      return '#0077b7';
    case 'snapchat':
      return '#fffc01';
    case 'instagram':
      return '#a12eb1';
    case 'stumbleupon':
      return '#e17842';
  }

  hashCodes[adName] = color;
  return color;
}
