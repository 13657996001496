export const ResetIcon2 = ({ color = 'currentColor', ...props }: { color?: string } & JSX.IntrinsicElements['svg']) => (
  <svg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 6L12.5 3L8 0V2C6.94943 2 5.90914 2.20693 4.93853 2.60896C3.96793 3.011 3.08601 3.60028 2.34315 4.34315C1.60028 5.08601 1.011 5.96793 0.608963 6.93853C0.206926 7.90914 0 8.94942 0 10H1.5C1.5 9.14641 1.66813 8.30117 1.99478 7.51256C2.32144 6.72394 2.80022 6.00739 3.40381 5.40381C4.00739 4.80023 4.72394 4.32144 5.51256 3.99478C6.30117 3.66813 7.14641 3.5 8 3.5L8 6ZM10.4874 14.0052C9.69883 14.3319 8.85359 14.5 8 14.5L8 12L3.5 15L8 18V16C9.05058 16 10.0909 15.7931 11.0615 15.391C12.0321 14.989 12.914 14.3997 13.6569 13.6569C14.3997 12.914 14.989 12.0321 15.391 11.0615C15.7931 10.0909 16 9.05058 16 8H14.5C14.5 8.85359 14.3319 9.69883 14.0052 10.4874C13.6786 11.2761 13.1998 11.9926 12.5962 12.5962C11.9926 13.1998 11.2761 13.6786 10.4874 14.0052Z'
      fill={color}
    />
  </svg>
);
