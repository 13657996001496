import { UNTRANSLATED_LOCALE } from '@va/constants';
import { T3_URL_PARAMS } from '@va/standalone/shared/constants';
import { extractLanguage, getParameterByName, updateUrlParameter } from '@va/util/helpers';
import { MDText } from 'i18n-react';
import moment from 'moment';
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { handleCustomTags } from '../helpers/helpers';

export const LocalizationContext = createContext({
  locale: 'en',
  setLocale: (locale: string) => Promise.resolve(),
  translate: (key: string, options?: Record<string, unknown>) => '' as any,
  isLoading: false,
  translationsError: false,
});

export const useLocalizationContext = () => useContext(LocalizationContext);

type TranslationsProviderProps = {
  fetchTranslations: (locale: string) => Promise<Record<string, unknown>>;
  onLocaleChange?: (l: string) => void;
};

export const TranslationsProvider = ({
  children,
  fetchTranslations,
  onLocaleChange,
}: PropsWithChildren<TranslationsProviderProps>) => {
  const [{ locale, values }, setLocale] = useState({
    locale: extractLanguage(),
    values: {},
  });
  const translationsRequestFinished = useRef(false);
  const translationsRequestError = useRef(false);

  const updateLocale = useCallback(
    async (newLocale: string) => {
      if (translationsRequestFinished.current && newLocale === locale) return;
      updateUrlParameter(T3_URL_PARAMS.locale, newLocale);
      if (newLocale === UNTRANSLATED_LOCALE) {
        translationsRequestFinished.current = true;
        setLocale({
          locale: newLocale,
          values: {},
        });
        return;
      }
      try {
        const newValues = await fetchTranslations(newLocale);
        moment.locale(newLocale);

        setLocale({
          locale: newLocale,
          values: newValues,
        });
        onLocaleChange?.(newLocale);
      } catch (e) {
        translationsRequestError.current = true;
        setLocale((p) => ({
          locale: p.locale,
          values: {},
        }));
      } finally {
        translationsRequestFinished.current = true;
      }
    },
    [locale, fetchTranslations, onLocaleChange],
  );

  const translator = useMemo(() => {
    return new MDText(values);
  }, [values]);

  const translateCallback = useCallback(
    (key: string, options?: Record<string, any>) => {
      const translatedText = translator.translate(key, options);
      if (locale === UNTRANSLATED_LOCALE) {
        return key;
      }
      if (translatedText && typeof translatedText === 'object') {
        return (
          <>
            {(translatedText as Array<unknown>).map((item) => {
              if (typeof item === 'string') return handleCustomTags(item);
              return item;
            })}
          </>
        );
      }

      return translatedText ? handleCustomTags(translatedText as string) : translatedText;
    },
    [locale, translator],
  );

  useEffect(() => {
    updateLocale(locale);
  }, [locale, updateLocale]);

  const context = {
    locale,
    setLocale: updateLocale,
    translate: translateCallback,
    isLoading: !translationsRequestFinished.current,
    translationsError: translationsRequestError.current,
  };

  return <LocalizationContext.Provider value={context}>{children}</LocalizationContext.Provider>;
};
