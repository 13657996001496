import { PreviousToggle } from '@va/dashboard/ui/components';
import { useReportBlockCtx } from '@va/ui/components/report-block';
import { memo } from 'react';

export const ReportBlockComparison = memo(() => {
  const { showPrevious, setShowPrevious, activeComparisonOption, setActiveComparisonOption } = useReportBlockCtx();

  return (
    <PreviousToggle
      selected={showPrevious}
      onToggle={() => setShowPrevious(!showPrevious)}
      activeComparisonOption={activeComparisonOption}
      setActiveComparisonOption={setActiveComparisonOption}
    />
  );
});
