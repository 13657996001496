import { lastDateModalShown, modalName, notificationTransKeys } from '@va/constants';
import { markModalAsViewed } from '@va/dashboard/actions/api';
import { addNotification, closeModal } from '@va/dashboard/actions/ui';
import { getApiRequest } from '@va/dashboard/selectors/api';
import { getCurrentPlanName } from '@va/dashboard/selectors/app';
import { getWebsite, isPremium } from '@va/dashboard/selectors/core';
import { isModalOpen } from '@va/dashboard/selectors/ui';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import {
  getLastCreatedSubscription,
  getPlanDetailsById,
  hasFreePlan,
  hasPermission,
  isSumoUser,
} from '@va/standalone/shared/selectors';
import { VisitsLimitReachedIcon } from '@va/svg-visa-icons';
import NotificationLimit from 'common/components/misc/NotificationLimit';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ModalWrapper from '../ModalWrapper';
import VisitsLimitReached from './VisitsLimitReached';

class VisitsLimitReachedContainer extends PureComponent {
  onCloseModal = () => {
    const markVisitorCurrentDate = moment().format('YYYY-MM');
    this.props.markModalAsViewed(modalName.visitsLimitReached + `-${lastDateModalShown}`, markVisitorCurrentDate);
    this.props.closeModal();
  };

  closeAndAddNotification = () => {
    this.onCloseModal();

    const sumoLimit = this.props.isSumoUser ? this.props.website.sumoLimit : null;

    const limit = this.props.visitLimitCount || this.props.limitFromSubscription || sumoLimit;
    const content = {
      level: 'warning',
      autoDismiss: 0,
      dismissible: 'button',
      children: (
        <NotificationLimit
          limit={limit}
          subTitleText={this.props.translate(notificationTransKeys.visitsLimitReachedUpgrade)}
        />
      ),
    };
    this.props.addNotification(content);
  };

  render() {
    return (
      <div className='visits-limit-reached-container'>
        <ModalWrapper
          isOpen={this.props.isModalOpen}
          closeModal={this.closeAndAddNotification}
          showCloseIconWithBorder
          bigger
        >
          <React.Fragment>
            <VisitsLimitReachedIcon className='visits-limit-reached-icon' />
            <VisitsLimitReached
              translate={this.props.translate}
              closeModal={this.onCloseModal}
              isFree={this.props.isFree}
              planName={this.props.planName}
              visitLimitCount={this.props.visitLimitCount}
              hasChangeSubscriptionPermission={this.props.hasChangeSubscriptionPermission}
            />
          </React.Fragment>
        </ModalWrapper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const featurePermissions = getApiRequest(state, 'getFeaturePermissions');

  const lastSubscription = getLastCreatedSubscription(state);

  return {
    isModalOpen: isModalOpen(state, modalName.visitsLimitReached),
    lastSubscription: lastSubscription,
    isFree: hasFreePlan(state) || !isPremium(state),
    planName: getCurrentPlanName(state),
    visitLimitCount: featurePermissions && featurePermissions.visits && featurePermissions.visits.limit,
    isSumoUser: isSumoUser(state),
    website: getWebsite(state),
    hasChangeSubscriptionPermission: hasPermission(state),
    limitFromSubscription: lastSubscription && getPlanDetailsById(state, lastSubscription.planId)?.visits,
  };
};

const mapDispatchToProps = {
  closeModal: () => closeModal(modalName.visitsLimitReached),
  markModalAsViewed: markModalAsViewed,
  addNotification: addNotification,
};

VisitsLimitReachedContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  markModalAsViewed: PropTypes.func.isRequired,
  isFree: PropTypes.bool.isRequired,
  addNotification: PropTypes.func.isRequired,
  isSumoUser: PropTypes.bool.isRequired,
  lastSubscription: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  website: PropTypes.object,
  planName: PropTypes.string.isRequired,
  visitLimitCount: PropTypes.number,
  hasChangeSubscriptionPermission: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(VisitsLimitReachedContainer));
