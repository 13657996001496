import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const VerticalDivider = ({ className }) => {
  const cssClasses = useMemo(() => classNames('h-full border-r-2 border-gray-gallery', className), [className]);
  return <div className={cssClasses} />;
};

VerticalDivider.defaultProps = {
  className: null,
};

VerticalDivider.propTypes = {
  className: PropTypes.string,
};

export default VerticalDivider;
