import React from 'react';
import { IconProps } from '.';

export const GoBackIcon = ({ className = '', color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 16.5C10.3661 16.5 11.6468 16.1348 12.75 15.4966V2.50337C11.6468 1.86523 10.3661 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM16.5 9C16.5 11.098 15.6385 12.9949 14.25 14.3561V3.64393C15.6385 5.00512 16.5 6.90195 16.5 9ZM9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM8.03032 11.4697L6.31065 9.75H10.5V8.25H6.31065L8.03032 6.53033L6.96966 5.46967L3.43933 9L6.96966 12.5303L8.03032 11.4697Z'
        fill={color ?? 'var(--color-primary)'}
      />
    </svg>
  );
};
