import { Cell, flexRender } from '@tanstack/react-table';
import { SegmentIcon } from '@va/icons';
import { IconWithBackground, ParagraphWithTooltip, fontWeights, paragraphSizes } from '@va/ui/design-system';
import classNames from 'classnames';
import { useCallback } from 'react';

export const SegmentCellV8 = <T,>({ cell, segmentData }: { cell: Cell<T, unknown>; segmentData: T }) => {
  const segmentCell = cell.column.columnDef.meta?.segmentCell;
  // @ts-ignore - The columnDef actually has the accessorKey property, but it can be undefined
  const accessorKey = cell.column.columnDef.accessorKey;

  const getValue = useCallback(() => (segmentData as any)[accessorKey] ?? undefined, [accessorKey, segmentData]);

  if (segmentCell) {
    return (
      <td className={classNames(cell.column?.columnDef.meta?.cellClassName, '!min-h-[60px]')}>
        {typeof segmentCell === 'string'
          ? segmentCell
          : segmentCell({
              ...cell.getContext(),
              getValue,
              segmentData,
            })}
      </td>
    );
  }

  return (
    <td
      className={classNames(
        {
          'w-full flex items-center justify-center text-center':
            cell.column.columnDef.meta?.useDefaultCellStyle !== false,
        },
        cell.column.columnDef.meta?.cellClassName,
      )}
      key={cell.id}
    >
      {flexRender(cell.column.columnDef.cell, {
        ...cell.getContext(),
        getValue,
        row: {
          ...cell.row,
          original: segmentData,
        },
      })}
    </td>
  );
};

export const SegmentNameCellV8 = ({ name }: { name: string }) => {
  return (
    <td className='flex items-center gap-3 ml-3'>
      <IconWithBackground
        className='bg-white-snow group-hover:bg-white ml-3'
        icon={() => <SegmentIcon color='#696969' />}
      />
      <ParagraphWithTooltip weight={fontWeights.medium} size={paragraphSizes.tiny}>
        {name}
      </ParagraphWithTooltip>
    </td>
  );
};
