import { getInstanceId } from '@va/dashboard/selectors/app';
import { isMobileDevice } from '@va/dashboard/selectors/ui';
import { GoogleTagManagerIcon, RightArrowIcon, WordPressIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { CopyActionCard } from '@va/ui/cards';
import { Button, IconWithBackground, ParagraphWithTooltip, paragraphSizes } from '@va/ui/design-system';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PluginInstallationSteps from './PluginInstallationSteps';

const AutomaticTrackingCodeInstallation = () => {
  const translate = useTranslate();
  const instanceId = useSelector(getInstanceId);
  return (
    <div className='flex flex-col items-center w-full'>
      <CopyActionCard
        title={translate('onboarding.trackingCodeInstallation.websiteId')}
        subTitle={instanceId}
        className='max-w-[540px] mt-[21px] mb-36px w-full'
      />
      <AutoInstallationPlugins />
    </div>
  );
};

export default AutomaticTrackingCodeInstallation;

enum PluginNames {
  wordpress = 'wordpress',
  googleTagManager = 'googleTagManager',
}

const AutoInstallationPlugins = () => {
  const translate = useTranslate();
  const isMobile = useSelector(isMobileDevice);
  const [selectedPlugin, setSelectedPlugin] = useState<PluginNames | null>(isMobile ? null : PluginNames.wordpress);

  const platformsList = useMemo(
    () => [
      {
        title: translate('register.installPlugin'),
        subTitle: translate('trackingCode.installation.wordpress'),
        icon: WordPressIcon,
        steps: 5,
        value: PluginNames.wordpress,
      },
      {
        title: translate('register.installPlugin'),
        subTitle: translate('trackingCode.installation.googleTagManager'),
        icon: GoogleTagManagerIcon,
        steps: 10,
        value: PluginNames.googleTagManager,
      },
    ],
    [translate],
  );

  const isFirstPluginSelected = useMemo(
    () => selectedPlugin === platformsList[0].value,
    [selectedPlugin, platformsList],
  );

  const pluginListEventHanlder = useCallback(
    (value: PluginNames) => {
      if (value !== selectedPlugin) {
        setSelectedPlugin(value);
      }
    },
    [selectedPlugin],
  );

  return (
    <div className='flex flex-col sm-initial:flex-row w-full '>
      <div
        className={classNames('flex flex-col gap-3 w-full sm-initial:max-w-[300px]', {
          hidden: isMobile && selectedPlugin,
        })}
      >
        {platformsList.map(({ title, icon: Icon, subTitle, value }) => {
          const isActive = value === selectedPlugin;
          return (
            <div
              className={classNames(
                'flex group hover:bg-white-snow min-w-300px p-3 rounded-18px sm-initial:rounded-l-18px sm-initial:rounded-r-none gap-3 justify-between cursor-default',
                {
                  'bg-white-snow': isActive || isMobile,
                },
              )}
              onMouseEnter={() => !isMobile && pluginListEventHanlder(value)}
              onClick={() => pluginListEventHanlder(value)}
              key={value}
            >
              <div className='flex items-center gap-3'>
                <IconWithBackground
                  icon={() => <Icon />}
                  className={classNames('bg-white-snow shrink-0 group-hover:bg-white', {
                    'bg-white': isActive || isMobile,
                  })}
                />
                <div className='flex justify-center flex-col overflow-hidden'>
                  <ParagraphWithTooltip size={paragraphSizes.tiny} className={'text-devil-gray font-525'}>
                    {title}
                  </ParagraphWithTooltip>
                  <ParagraphWithTooltip className={'text-gray-charcoal font-525 truncate'}>
                    {subTitle}
                  </ParagraphWithTooltip>
                </div>
              </div>
              <Button icon={() => <RightArrowIcon color='#696969' />} className='bg-transparent sm-initial:hidden' />
            </div>
          );
        })}
      </div>
      {selectedPlugin && (
        <PluginInstallationSteps
          pluginName={selectedPlugin}
          stepsCount={platformsList.find((platform) => platform.value === selectedPlugin)?.steps ?? 0}
          className={classNames({
            'rounded-tl-none': isFirstPluginSelected && !isMobile,
          })}
        />
      )}
    </div>
  );
};
