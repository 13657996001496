import { resetRequestStatus } from '@va/dashboard/actions/api';
import { CLOSE_MODAL, openModal } from '@va/dashboard/actions/ui';
import { getInstanceId } from '@va/dashboard/selectors/app';
import * as Actions from '@va/standalone/shared/actions';
import * as Types from '@va/standalone/shared/actions';
import * as Api from '@va/standalone/shared/api-client';
import { firstPromoterCookie, modalName } from '@va/standalone/shared/constants';
import { getUserId } from '@va/standalone/shared/helpers';
import * as Selectors from '@va/standalone/shared/selectors';
import { getCookie, isValidWebsiteId } from '@va/util/helpers';
import { all, call, delay, put, select, spawn, take, takeLatest } from 'redux-saga/effects';

export function* watchers() {
  yield all([
    takeLatest(Types.standalone.Api.GET_UPDATE_PAYMENT_METHOD_URL, getUpdatePaymentMethodUrl),
    takeLatest(Types.standalone.Api.UPDATE_SUBSCRIPTION_REQUEST, updateSubscription),
    takeLatest(Types.standalone.Api.GET_CUSTOMER_AUTH_TOKEN_REQUEST, getPaymentAuthToken),
    takeLatest(Types.standalone.Api.GET_BRAINTREE_AUTH_TOKEN_REQUEST, getBrainTreeAuthToken),
    takeLatest(Types.standalone.Api.GET_CUSTOMER_REQUEST, getCustomer),
    takeLatest(Types.standalone.Api.GET_AGENCY_CUSTOMER_REQUEST, getAgencyCustomer),
    takeLatest(Types.standalone.Api.GET_PAYMENT_METHOD_REQUEST, getPaymentMethod),
    takeLatest(Types.standalone.Api.GET_PAYMENT_PLANS_REQUEST, getPaymentPlans),
    takeLatest(Types.standalone.Api.GET_ALL_SUBSCRIPTIONS_REQUEST, getAllSubscriptions),
    takeLatest(Types.standalone.Api.GET_COMPUTED_SUBSCRIPTION_PRICE_REQUEST, getComputedSubscriptionPrice),
    takeLatest(Types.standalone.Api.VALIDATE_VAT_REQUEST, validateVat),
    takeLatest(Types.standalone.Api.SUBMIT_PAYMENT_REQUEST, submitPayment),
    takeLatest(Types.standalone.Api.CREATE_CUSTOMER_REQUEST, createCustomer),
    takeLatest(Types.standalone.Api.CREATE_PAYMENT_METHOD_REQUEST, createPaymentMethod),
    takeLatest(Types.standalone.Api.CREATE_SUBSCRIPTION_REQUEST, createSubscription),
    takeLatest(Types.standalone.Api.CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription),
    takeLatest(Types.standalone.Api.CANCEL_SUBSCRIPTION_IMMEDIATE_REQUEST, cancelSubscriptionImmediate),
    takeLatest(Types.standalone.Api.REACTIVATE_SUBSCRIPTION_REQUEST, reactivateSubscription),
    takeLatest(Types.standalone.Api.UPDATE_CUSTOMER_REQUEST, updateCustomer),
    takeLatest(Types.standalone.Api.GENERATE_INVOICE, generateInvoice),
    takeLatest(Types.standalone.Api.VALIDATE_PROMO_CODE, validatePromoCode),
    takeLatest(Types.standalone.Api.GET_AGENCY_CUSTOMER_REQUEST, getAgencyCustomer),
  ]);
}

// === Paddle ===

function* getUpdatePaymentMethodUrl() {
  try {
    const websiteId = yield select(getInstanceId);
    const subscription = yield select(Selectors.standalone.App.getLastCreatedSubscription);
    const response = yield call(Api.getUpdatePaymentMethodUrl, websiteId, subscription.id);
    yield put(Actions.standalone.Api.getUpdatePaymentMethodUrlSucceeded(response));
  } catch (error) {
    yield put(Actions.standalone.Api.getUpdatePaymentMethodUrlFailed(error));
  }
}

function* updateSubscription() {
  try {
    const websiteId = yield select(getInstanceId);
    const currentSubscription = yield select(Selectors.standalone.App.getLastCreatedSubscription);
    const selectedPlanId = yield select(Selectors.standalone.Ui.getSelectedPlanId);
    const selectedSubscriptionPlanType = yield select(Selectors.standalone.Ui.getSubscriptionType);
    const requestData = {
      planId: selectedPlanId,
      planType: selectedSubscriptionPlanType,
    };

    const response = yield call(Api.updateSubscription, requestData, websiteId, currentSubscription.id);
    yield put(Actions.standalone.Api.updateSubscriptionSucceeded(response));
    yield take(CLOSE_MODAL);
    yield put(Actions.standalone.App.setActiveWebsite(websiteId));
  } catch (error) {
    yield put(Actions.standalone.Api.updateSubscriptionFailed(error));
  }
}

function* getAgencyCustomer() {
  try {
    const userId = getUserId();
    const response = yield call(Api.getAgencyCustomer, userId);
    yield put(Actions.standalone.Api.getAgencyCustomerSucceeded(response));
  } catch (error) {
    yield put(Actions.standalone.Api.getAgencyCustomerFailed(error));
  }
}

// === Common ===

function* generateInvoice(action) {
  try {
    const data = yield call(Api.generateInvoice, action.data);
    yield put(Actions.standalone.Api.generateInvoiceSucceeded(data));
  } catch (error) {
    yield put(Actions.standalone.Api.generateInvoiceFailed(error));
  }
}

function* getPaymentPlans(action) {
  try {
    const websiteId = yield select(getInstanceId);
    let queryData = {};
    if (action.planType) {
      queryData.planType = action.planType;
    }
    if (!isValidWebsiteId(websiteId)) throw new Error('No active website');
    const response = yield call(Api.getPaymentPlans, websiteId, queryData);
    yield put(Actions.standalone.Api.getPaymentPlansSucceeded(response));
  } catch (error) {
    yield put(Actions.standalone.Api.getPaymentPlansFailed(error));
  }
}

function* getAllSubscriptions() {
  try {
    const websiteId = yield select(getInstanceId);
    const response = yield call(Api.getAllSubscriptions, websiteId);
    yield put(Actions.standalone.Api.getAllSubscriptionsSucceeded(response));
  } catch (error) {
    yield put(Actions.standalone.Api.getAllSubscriptionsFailed(error));
  }
}

function* cancelSubscription(action) {
  try {
    const websiteId = yield select(getInstanceId);
    const subscription = yield select(Selectors.standalone.App.getLastCreatedSubscription);
    const requestData = {
      reason: action.data.get('reason', null),
    };
    const response = yield call(Api.cancelSubscription, requestData, websiteId, subscription.id);
    yield put(Actions.standalone.Api.cancelSubscriptionSucceeded(response));
    yield put(Actions.standalone.Api.getAllSubscriptions());
  } catch (error) {
    yield put(Actions.standalone.Api.cancelSubscriptionFailed(error));
  }
}

function* getPaymentMethod() {
  try {
    const websiteId = yield select(getInstanceId);
    const subscription = yield select(Selectors.standalone.App.getLastCreatedSubscription);
    const response = yield call(Api.getPaymentMethod, websiteId, subscription.id);
    yield put(Actions.standalone.Api.getPaymentMethodSucceeded(response));
  } catch (error) {
    yield put(Actions.standalone.Api.getPaymentMethodFailed(error));
  }
}

// === Braintree ====

function* getPaymentAuthToken() {
  try {
    const customer = yield select(Selectors.standalone.Api.getApiRequest, 'getCustomer');
    const customerCreated = yield select(Selectors.standalone.Api.getApiRequest, 'createCustomer');
    const websiteId = yield select(getInstanceId);
    const customerId = customer.get('id', customerCreated.get('id', null));
    const apiCall = customerId ? Api.getCustomerPaymentAuthToken : Api.getPaymentAuthToken;
    const response = yield call(apiCall, websiteId, customerId);

    yield put(Actions.standalone.Api.getPaymentAuthTokenSucceeded(response));
  } catch (error) {
    yield put(Actions.standalone.Api.getPaymentAuthTokenFailed(error));
  }
}

function* getBrainTreeAuthToken() {
  try {
    const websiteId = yield select(getInstanceId);
    const response = yield call(Api.getPaymentAuthToken, websiteId);
    yield put(Actions.standalone.Api.getBraintreeAuthTokenSucceeded(response));
  } catch (error) {
    yield put(Actions.standalone.Api.getBraintreeAuthTokenFailed(error));
  }
}

function* getCustomer() {
  try {
    const websiteId = yield select(getInstanceId);
    const response = yield call(Api.getCustomer, websiteId);
    yield put(Actions.standalone.Api.getCustomerSucceeded(response));
  } catch (error) {
    yield put(Actions.standalone.Api.getCustomerFailed(error));
  }
}

function* getComputedSubscriptionPrice(action) {
  try {
    const websiteId = yield select(getInstanceId);
    const customer = yield select(Selectors.standalone.Api.getApiRequest, 'getCustomer');
    const planId = yield select(Selectors.standalone.Ui.getSubscriptionPlanId);
    const planType = yield select(Selectors.standalone.Ui.getSubscriptionType);
    const selectedCountry = yield select(Selectors.standalone.getFormValue, action.formName, 'country');
    const selectedVat = yield select(Selectors.standalone.getFormValue, action.formName, 'vat');
    const validatePromoCodeRequest = yield select(Selectors.standalone.Api.getApiRequest, 'validatePromoCode');

    const queryData = {
      planId: planId,
      planType: planType,
      country: selectedCountry || customer.get('country', ''),
      vat: selectedVat || customer.get('vat', ''),
      voucherCode: validatePromoCodeRequest.get('voucherCode', ''),
    };
    const response = yield call(Api.getComputedSubscriptionPrice, websiteId, queryData);
    yield put(Actions.standalone.Api.getComputedSubscriptionPriceSucceeded(response));
  } catch (error) {
    yield put(Actions.standalone.Api.getComputedSubscriptionPriceFailed(error));
  }
}

function* validateVat(action) {
  try {
    const customer = yield select(Selectors.standalone.Api.getApiRequest, 'getCustomer');
    const selectedCountry = yield select(Selectors.standalone.getFormValue, action.formName, 'country');
    const selectedVat = yield select(Selectors.standalone.getFormValue, action.formName, 'vat');

    const queryData = {
      country: selectedCountry || customer.get('country'),
      vat: selectedVat || customer.get('vat'),
    };
    const response = yield call(Api.validateVat, queryData);
    yield put(Actions.standalone.Api.validateVatSucceeded(response));
  } catch (error) {
    yield put(Actions.standalone.Api.validateVatFailed(error));
  }
}

function* submitPayment(action) {
  try {
    const apiTypes = Types.standalone.Api;
    const customer = yield select(Selectors.standalone.Api.getApiRequest, 'getCustomer');
    const hasAllRequiredCustomerDetails = yield select(Selectors.standalone.App.hasAllRequiredCustomerDetails);
    if (!customer.get('id')) {
      yield put(Actions.standalone.Api.createCustomer(action.data));
      yield take([apiTypes.CREATE_CUSTOMER_SUCCEEDED, apiTypes.CREATE_CUSTOMER_FAILED]);
    } else if (!hasAllRequiredCustomerDetails) {
      yield put(Actions.standalone.Api.updateCustomer(action.data));
      yield take([Types.standalone.Api.UPDATE_CUSTOMER_SUCCEEDED, Types.standalone.Api.UPDATE_CUSTOMER_FAILED]);
    }
    yield spawn(createPaymentWithSubscription, action);
  } catch (error) {
    yield put(Actions.standalone.Api.submitPaymentFailed(error));
  }
}

function* createPaymentWithSubscription(action) {
  try {
    const customer = yield select(Selectors.standalone.Api.getApiRequest, 'getCustomer');
    if (!customer.get('id')) return;

    const apiTypes = Types.standalone.Api;
    const paymentMethod = yield select(Selectors.standalone.Api.getApiRequest, 'getPaymentMethod');
    if (action.newCard || !paymentMethod.get('id')) {
      yield put(Actions.standalone.Api.createPaymentMethod(action.token, action.nonce));
      const createPaymentAction = yield take([
        apiTypes.CREATE_PAYMENT_METHOD_SUCCEEDED,
        apiTypes.CREATE_PAYMENT_METHOD_FAILED,
      ]);
      if (createPaymentAction.type === apiTypes.CREATE_PAYMENT_METHOD_FAILED) return;
    }

    yield put(Actions.standalone.Api.createSubscription());
    const createSubscriptionAction = yield take([
      apiTypes.CREATE_SUBSCRIPTION_SUCCEEDED,
      apiTypes.CREATE_SUBSCRIPTION_FAILED,
    ]);
    if (createSubscriptionAction.type === apiTypes.CREATE_SUBSCRIPTION_SUCCEEDED) {
      yield put(Actions.standalone.Api.submitPaymentSucceeded());
      yield put(Actions.standalone.Api.getAllSubscriptions());
      yield put(Actions.standalone.Api.getWebsites());
    }
  } catch (error) {
    yield put(Actions.standalone.Api.submitPaymentFailed(error));
  }
}

function* createCustomer(action) {
  try {
    const websiteId = yield select(getInstanceId);
    const requestData = {
      companyName: action.data.get('companyName') && action.data.get('companyName').trim(),
      country: action.data.get('country'),
      firstName: action.data.get('firstName') && action.data.get('firstName').trim(),
      lastName: action.data.get('lastName') && action.data.get('lastName').trim(),
      street: action.data.get('street') && action.data.get('street').trim(),
      city: action.data.get('city') && action.data.get('city').trim(),
      state: action.data.get('state') && action.data.get('state').trim(),
      zipcode: action.data.get('zipcode') && action.data.get('zipcode').trim(),
      vat: action.data.get('vat', ''),
    };

    const response = yield call(Api.createCustomer, requestData, websiteId);
    yield put(Actions.standalone.Api.getCustomer());
    yield take([Types.standalone.Api.GET_CUSTOMER_SUCCEEDED, Types.standalone.Api.GET_CUSTOMER_FAILED]);
    yield put(Actions.standalone.Api.createCustomerSucceeded(response));
    if (getCookie(firstPromoterCookie.CODE)) {
      yield put(Actions.standalone.Api.signUpPromoter());
    }
  } catch (error) {
    yield put(Actions.standalone.Api.createCustomerFailed(error));
    yield put(Actions.standalone.Api.submitPaymentFailed(error));
  } finally {
    yield delay(3000);
    yield put(resetRequestStatus('createCustomer'));
  }
}

function* createPaymentMethod(action) {
  try {
    const requestData = {
      paymentMethodNonce: action.nonce,
      token: action.token,
    };

    let customerCreated = yield select(Selectors.standalone.Api.getApiRequest, 'createCustomer');
    let customerId = customerCreated.get('id', null);
    if (!customerId) {
      const apiTypes = Types.standalone.Api;
      yield put(Actions.standalone.Api.getCustomer());
      const getStatus = yield take([apiTypes.GET_CUSTOMER_SUCCEEDED, apiTypes.GET_CUSTOMER_FAILED]);
      if (getStatus.type === apiTypes.GET_CUSTOMER_SUCCEEDED) {
        const customer = yield select(Selectors.standalone.Api.getApiRequest, 'getCustomer');
        customerId = customer.get('id', null);
      } else if (getStatus.type === apiTypes.GET_CUSTOMER_FAILED) {
        yield put(Actions.standalone.Api.createCustomer(action.data));
        const createStatus = yield take([apiTypes.CREATE_CUSTOMER_SUCCEEDED, apiTypes.CREATE_CUSTOMER_FAILED]);
        if (createStatus.type === apiTypes.CREATE_CUSTOMER_SUCCEEDED) {
          customerCreated = yield select(Selectors.standalone.Api.getApiRequest, 'createCustomer');
          customerId = customerCreated.get('id', null);
        }
      }
    }
    const websiteId = yield select(getInstanceId);
    const response = yield call(Api.createPaymentMethod, requestData, websiteId, customerId);
    yield put(Actions.standalone.Api.createPaymentMethodSucceeded(response));
    yield put(Actions.standalone.Api.getPaymentAuthToken());
    yield put(Actions.standalone.Api.getPaymentMethodSucceeded(response));
  } catch (error) {
    yield put(Actions.standalone.Api.createPaymentMethodFailed(error));
    yield put(Actions.standalone.Api.submitPaymentFailed(error));
  }
}

function* createSubscription() {
  try {
    const websiteId = yield select(getInstanceId);
    const uiSubscriptionPlan = yield select(Selectors.standalone.Ui.getSubscriptionPlan);
    const uiSubscriptionType = yield select(Selectors.standalone.Ui.getSubscriptionType);
    const paymentPlans = yield select(Selectors.standalone.App.getPaymentPlans);
    const planId = paymentPlans[uiSubscriptionType][uiSubscriptionPlan].planId;
    const computedPrice = yield select(Selectors.standalone.Api.getApiRequest, 'getComputedSubscriptionPrice');
    const validatePromoCodeRequest = yield select(Selectors.standalone.Api.getApiRequest, 'validatePromoCode');

    const requestData = {
      planId: planId,
      planType: uiSubscriptionType,
      price: computedPrice.get('finalPrice'),
      voucherCode: validatePromoCodeRequest.get('voucherCode', ''),
    };

    const response = yield call(Api.createSubscription, requestData, websiteId);
    yield put(Actions.standalone.Api.createSubscriptionSucceeded(response));

    yield take(CLOSE_MODAL);
    yield put(Actions.standalone.App.setActiveWebsite(websiteId));
  } catch (error) {
    yield put(Actions.standalone.Api.createSubscriptionFailed(error));
    yield put(Actions.standalone.Api.submitPaymentFailed(error));
  }
}

function* cancelSubscriptionImmediate() {
  try {
    const websiteId = yield select(getInstanceId);
    const response = yield call(Api.cancelSubscriptionImmediate, websiteId);
    yield put(Actions.standalone.Api.cancelSubscriptionImmediateSucceeded(response));
  } catch (error) {
    yield put(Actions.standalone.Api.cancelSubscriptionImmediateFailed(error));
  }
}

function* reactivateSubscription(action) {
  try {
    const websiteId = action.data && action.data.websiteId ? action.data.websiteId : yield select(getInstanceId);
    const lastSubscription = yield select(Selectors.standalone.App.getLastCreatedSubscription);
    let subscriptionId = action.data && action.data.subscriptionId ? action.data.subscriptionId : lastSubscription.id;
    yield put(openModal(modalName.REACTIVATE_SUBSCRIPTION));
    const response = yield call(Api.reactivateSubscription, {}, websiteId, subscriptionId);
    yield put(Actions.standalone.Api.reactivateSubscriptionSucceeded(response));
    yield put(Actions.standalone.Api.getWebsites());
    yield put(Actions.standalone.Api.getAllSubscriptions());
  } catch (error) {
    yield put(Actions.standalone.Api.reactivateSubscriptionFailed(error));
  }
}

function* updateCustomer(action) {
  try {
    const websiteId = yield select(getInstanceId);
    const customer = yield select(Selectors.standalone.Api.getApiRequest, 'getCustomer');
    const customerId = customer.get('id', null);
    const requestData = {
      companyName: action.data.get('companyName').trim(),
      firstName: action.data.get('firstName').trim(),
      lastName: action.data.get('lastName').trim(),
      country: action.data.get('country'),
      street: action.data.get('street') && action.data.get('street').trim(),
      city: action.data.get('city') && action.data.get('city').trim(),
      state: action.data.get('state') && action.data.get('state').trim(),
      zipcode: action.data.get('zipcode') && action.data.get('zipcode').trim(),
      vat: action.data.get('vat', ''),
    };
    const response = yield call(Api.updateCustomer, requestData, websiteId, customerId);
    yield put(Actions.standalone.Api.updateCustomerSucceeded(response));
    yield put(Actions.standalone.Api.getCustomer());
  } catch (error) {
    yield put(Actions.standalone.Api.updateCustomerFailed(error));
  } finally {
    yield delay(3000);
    yield put(resetRequestStatus('updateCustomer'));
  }
}

function* validatePromoCode(action) {
  try {
    const websiteId = yield select(getInstanceId);
    const voucherCodeFromAction = typeof action.voucherCode === 'string' ? action.voucherCode : null;
    const voucherCodeFromForm = yield select(Selectors.standalone.getFormValue, 'checkoutForm', 'voucherCode');
    const requestData = {
      voucherCode: voucherCodeFromAction || (voucherCodeFromForm && voucherCodeFromForm.trim()),
    };
    const data = yield call(Api.validatePromoCode, requestData, websiteId);
    yield put(Actions.standalone.Api.validatePromoCodeSucceeded(data));
    yield put(Actions.standalone.Api.getComputedSubscriptionPrice('checkoutForm'));
  } catch (error) {
    yield put(Actions.standalone.Api.validatePromoCodeFailed(error));
    const computedPrice = yield select(Selectors.standalone.Api.getApiRequest, 'getComputedSubscriptionPrice');
    if (computedPrice.get('voucherDiscount') && parseFloat(computedPrice.get('voucherDiscount')) > 0) {
      yield put(Actions.standalone.Api.getComputedSubscriptionPrice('checkoutForm'));
    }
  } finally {
    yield delay(3000);
    yield put(resetRequestStatus('validatePromoCode'));
  }
}
