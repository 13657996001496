import { currentActivePrivacyLevel, getPrivacySettingsData } from '@va/dashboard/selectors/api';
import { PRIVACY_FEATURES, PrivacyLevelType } from '@va/standalone/shared/types';
import { privacyLevelFeatures } from '@va/util/helpers';
import { isArray } from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useCheckPrivacySetting = () => {
  const privacyLevelData = useSelector(getPrivacySettingsData) as PrivacyLevelType[];

  const activeLevel = useSelector(currentActivePrivacyLevel);

  const isTrackingEnabled = useCallback(
    (featureName: PRIVACY_FEATURES, countryCode: string) => {
      const lockedOnLevel = privacyLevelData?.find((level) => level.exceptions.includes(countryCode))?.levelKey;
      const response = privacyLevelFeatures(lockedOnLevel ?? activeLevel);

      if (isArray(response)) {
        return response.includes(featureName);
      }

      return response;
    },
    [privacyLevelData, activeLevel],
  );

  return { activeLevel, isTrackingEnabled };
};
