import { setGlobalFilter } from '@va/dashboard/actions/ui';
import { getWebsite } from '@va/dashboard/selectors/core';
import {
  getGlobalFilter,
  shouldRefreshDatePicker as shouldRefreshDatePickerSelector,
} from '@va/dashboard/selectors/ui';
import { useRefreshDateFilter } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { DatePicker } from '@va/shared/feature-litepie-datepicker';
import dayjs from 'dayjs';
import moment, { Moment } from 'moment';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type DatePickerContainerProps = {
  featureReleaseDate?: number;
  installDate?: number;
  placement?: string;
  size?: 'small' | 'medium';
};

function DatePickerContainer({
  featureReleaseDate,
  installDate: installDateProp,
  placement,
  size = 'medium',
}: DatePickerContainerProps) {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const filter = useSelector(getGlobalFilter);
  const website = useSelector(getWebsite);
  const installDate = installDateProp ?? website.installDate;
  const shouldRefreshDatePicker = useSelector(shouldRefreshDatePickerSelector);
  const onPeriodChange = useCallback(
    (date: [Moment, Moment]) => {
      const [from, until] = date;

      dispatch(setGlobalFilter({ from, until }));
    },
    [dispatch],
  );

  const minimumDate = useMemo(() => {
    if (featureReleaseDate && featureReleaseDate > installDate)
      return dayjs(featureReleaseDate * 1000).tz(window.timezone);

    return dayjs(installDate * 1000).tz(window.timezone);
  }, [featureReleaseDate, installDate]);

  const maximumDate = dayjs().tz(window.timezone).endOf('day');
  const getDatePickerTooltipTranslationKey = () => {
    const availableDataFrom = website.lastResetDate ? Math.max(website.lastResetDate, installDate) : installDate;
    return featureReleaseDate && featureReleaseDate > availableDataFrom
      ? translate('filter.featureReleaseDate', {
          releaseDate: moment(featureReleaseDate * 1000).format('L'),
        })
      : translate('filter.installedToday');
  };

  useRefreshDateFilter();
  return (
    <DatePicker
      tooltipContent={getDatePickerTooltipTranslationKey()}
      onPeriodChange={onPeriodChange}
      startDate={filter.from}
      endDate={filter.until}
      minDate={minimumDate}
      maxDate={maximumDate}
      shouldRefreshDatePicker={shouldRefreshDatePicker}
      placement={placement}
      size={size}
    />
  );
}

export default DatePickerContainer;
