import { TEST_IDS } from '@va/constants';
import { useLocale, useTranslate } from '@va/localization';
import { ChartTile } from '@va/types/charts';
import { TooltipWrapper } from '@va/ui/tooltips';
import { addNumberSeparator, getPercentageColors } from '@va/util/helpers';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { memo, useMemo } from 'react';
import { Heading3, paragraphSizes, useChartTilesContext } from '../..';
import { Paragraph, fontWeights } from '../../typography';

export const ChartTileComponent = memo(
  ({
    colorHEX,
    label,
    headingValue,
    selected = true,
    percentageChange,
    onClick,
    valueDetails,
    reversePercentageColors,
    renderContextMenu,
    id,
  }: ChartTile) => {
    const translate = useTranslate();

    const { locale } = useLocale();

    const percentageDisplayValue = useMemo(() => {
      if (isNil(percentageChange)) return '-';
      return addNumberSeparator(percentageChange, locale, {
        style: 'percent',
        signDisplay: 'exceptZero',
      });
    }, [percentageChange, locale]);

    const parsedHeadingValue = useMemo(() => {
      if (typeof headingValue === 'string') return headingValue;
      return addNumberSeparator(headingValue, locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        // @ts-ignore
        trailingZeroDisplay: 'stripIfInteger',
      });
    }, [headingValue, locale]);

    const { displayContextMenu } = useChartTilesContext();

    return (
      <div
        data-testid={TEST_IDS.generic.chart.getChartTileId(id)}
        onClick={onClick}
        className={classNames(
          'text-center shrink-0 grow min-w-150 rounded-24 hover:bg-white-snow py-2 px-2 group z-10 relative',
          {
            'bg-white text-gray-silver': !selected,
            'cursor-pointer': onClick,
          },
        )}
      >
        <div
          className={classNames('flex items-center', {
            'justify-between': displayContextMenu,
            'justify-center': !displayContextMenu,
          })}
        >
          {displayContextMenu && <div className='w-9 opacity-0'></div>}
          <Paragraph
            weight={fontWeights.medium}
            size={paragraphSizes.large}
            style={{ color: selected ? colorHEX : '#c3c3c3' }}
            className='text-center'
          >
            {label}
          </Paragraph>
          {displayContextMenu && renderContextMenu?.()}
        </div>
        <Heading3
          className='mt-6px'
          colorClassName={!selected ? 'text-gray-silver' : 'text-gray-code'}
          weight={fontWeights.medium}
        >
          {parsedHeadingValue}
        </Heading3>
        {valueDetails && <Paragraph weight={fontWeights.medium}>{valueDetails}</Paragraph>}
        <TooltipWrapper interactive content={translate('percentComparedToPrevious')}>
          <Paragraph
            size={paragraphSizes.normal}
            colorClassName={
              selected ? getPercentageColors(percentageChange ?? 0, reversePercentageColors) : 'text-gray-silver'
            }
          >
            {percentageDisplayValue}
          </Paragraph>
        </TooltipWrapper>
      </div>
    );
  },
);
