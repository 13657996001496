import { IconWithBackground, ParagraphWithTooltip, fontWeights } from '@va/ui/design-system';
import classNames from 'classnames';
import { ReactNode } from 'react';

export const LocationRowDetails = ({ icon, label }: { icon: ReactNode; label: string }) => {
  return (
    <div className='flex gap-3 items-center'>
      <IconWithBackground className={classNames('bg-white-snow group-hover:bg-white shrink-0')} icon={() => icon} />
      <ParagraphWithTooltip weight={fontWeights.medium}>{label}</ParagraphWithTooltip>
    </div>
  );
};
