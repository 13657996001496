import { AaasFeaturePermission, GroupedPackages, StpCounterResponse } from '@va/aaas/shared/types';
import { apiStatus } from '@va/constants';
import { Website } from '@visitor-analytics/3as-sdk';
import { Map } from 'immutable';
import { ThemeType } from './theme.types';

export interface RootState extends Map<string, any> {
  core: any;
  api: any;
  ui: any;
  customization: CustomizationState;
  app: WixMiniAppState;
}

export interface WixMiniAppState {
  instanceId: string;
  initialized: boolean;
  activeWebsite: string;
  theme: ThemeType | undefined;
  featurePermissions: {
    data: AaasFeaturePermission[];
    status: apiStatus;
  };
  allowUpgrade: boolean;
  intpWebsite?: Website & { intpName: string };
  stpRates: StpCounterResponse;
  packages: GroupedPackages;
  predefinedFilters: PredefinedFilters;
}

export interface CustomizationState {
  providerName: string;
  supportCenterDomain: string;
  chatwootToken: string;
}

export enum PREDEFINED_PERIOD {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
}

export interface PredefinedFilters {
  timezone: string;
  timePeriod?: string;
  deviceType?: string;
}
