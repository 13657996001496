import { IconProps } from '.';

export const NewCampaignIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='21'
      height='18'
      viewBox='0 0 21 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.3899 15.4136C12.2558 16.103 10.9242 16.5 9.5 16.5C5.35786 16.5 2 13.1421 2 9C2 4.85786 5.35786 1.5 9.5 1.5C10.9242 1.5 12.2558 1.89699 13.3899 2.58636C14.0398 2.3727 14.7331 2.25504 15.4533 2.25016C13.8667 0.849747 11.7826 0 9.5 0C4.52944 0 0.5 4.02944 0.5 9C0.5 13.9706 4.52944 18 9.5 18C11.7826 18 13.8667 17.1503 15.4533 15.7498C14.7331 15.745 14.0398 15.6273 13.3899 15.4136ZM10.3912 13.4118C10.7755 13.8564 11.2169 14.2504 11.704 14.5823C11.0217 14.8519 10.2781 15 9.5 15C6.18629 15 3.5 12.3137 3.5 9C3.5 5.68629 6.18629 3 9.5 3C10.2781 3 11.0217 3.14813 11.704 3.41774C11.2169 3.74961 10.7755 4.14357 10.3912 4.58823C10.1031 4.53036 9.8051 4.5 9.5 4.5C7.01472 4.5 5 6.51472 5 9C5 11.4853 7.01472 13.5 9.5 13.5C9.8051 13.5 10.1031 13.4696 10.3912 13.4118ZM8.8892 10.3704C9.00736 10.9434 9.19812 11.4898 9.45145 11.9996C7.81699 11.9737 6.5 10.6406 6.5 9C6.5 7.35936 7.81699 6.02632 9.45145 6.00038C9.19812 6.51023 9.00736 7.05664 8.8892 7.62958C8.3652 7.86348 8 8.38909 8 9C8 9.61091 8.3652 10.1365 8.8892 10.3704ZM15.5 12.75C17.5711 12.75 19.25 11.0711 19.25 9C19.25 6.92893 17.5711 5.25 15.5 5.25C13.4289 5.25 11.75 6.92893 11.75 9C11.75 11.0711 13.4289 12.75 15.5 12.75ZM15.5 14.25C18.3995 14.25 20.75 11.8995 20.75 9C20.75 6.1005 18.3995 3.75 15.5 3.75C12.6005 3.75 10.25 6.1005 10.25 9C10.25 11.8995 12.6005 14.25 15.5 14.25ZM16.25 8.25H17.75V9.75H16.25V11.25H14.75V9.75H13.25V8.25H14.75V6.75H16.25V8.25Z'
        fill={color}
      />
    </svg>
  );
};


