import { isMobileDevice } from '@va/dashboard/selectors/ui';
import { onContactSupport } from '@va/dashboard/util-helpers/misc';
import { CustomerSupport, UserIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, Paragraph, paragraphSizes } from '@va/ui/design-system';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import VerifyTrackingCodeInstallation from './VerifyTrackingCodeInstallation';
import { useInstallTrackingCodeContext } from './useTrackingCodeInstallationView';
import { getProviderName } from '@va/dashboard/selectors/customization';

const PluginInstallationSteps = ({
  pluginName,
  className,
  stepsCount,
}: {
  pluginName: string;
  className?: string;
  stepsCount: number;
}) => {
  const translate = useTranslate();
  const { onSuccess } = useInstallTrackingCodeContext();
  const isMobile = useSelector(isMobileDevice);
  const providerName = useSelector(getProviderName);

  return (
    <div
      className={classNames(
        'w-full sm-initial:bg-white-snow rounded-30px px-0 sm-initial:p-9 cursor-default',
        className,
      )}
    >
      <Paragraph size={paragraphSizes.large} className='font-550 text-gray-code text-center sm-initial:text-left'>
        {isMobile
          ? translate(`onboarding.trackingCodeInstallation.${pluginName}.instructionTitle`)
          : translate(`onboarding.trackingCodeInstallation.${pluginName}.title`, { providerName })}
      </Paragraph>
      <Paragraph size={paragraphSizes.normal} className='font-525 text-gray-charcoal hidden sm-initial:block'>
        {translate(`onboarding.trackingCodeInstallation.followInstructions`)}
      </Paragraph>
      <div className='flex flex-col mt-18px mb-4 gap-3'>
        {Array.from(Array(stepsCount).keys()).map((_, index) => {
          const stepNumber = index + 1;

          return (
            <div className='flex gap-[10.5px] items-center' key={index}>
              <Paragraph className='border-2 !font-525 rounded-full border-solid h-6 w-6 text-xs flex justify-center items-center shrink-0 text-gray-devilSolid'>
                {stepNumber}
              </Paragraph>
              <Paragraph className={'text-gray-charcoal !font-525'}>
                {translate(`onboarding.trackingCodeInstallation.${pluginName}.step${stepNumber}`, { providerName })}
              </Paragraph>
            </div>
          );
        })}
      </div>
      <VerifyTrackingCodeInstallation />
      <Button
        className='w-full justify-center mt-3'
        text={translate('button.skipTrackingCodeInstallation')}
        color={isMobile ? 'tertiary' : 'quinary'}
        onClick={onSuccess}
      />
      <div className='flex flex-col sm-initial:flex-row gap-3 mt-6'>
        <a
          href={translate(`onboarding.trackingCodeInstallation.supportLink.${pluginName}`)}
          target={'_blank'}
          rel='noreferrer'
          className='flex-1'
        >
          <Button
            className='w-full justify-center'
            text={translate('button.supportCenter')}
            color={isMobile ? 'tertiary' : 'quinary'}
            icon={() => <CustomerSupport color={'#696969'} />}
          />
        </a>
        <Button
          onClick={onContactSupport}
          className='flex-1 w-full justify-center'
          text={translate('button.contactSupport')}
          color={isMobile ? 'tertiary' : 'quinary'}
          icon={() => <UserIcon color={'#696969'} />}
        />
      </div>
    </div>
  );
};

export default PluginInstallationSteps;
