import { IconProps } from '.';

export const SplitTablePieDiagram = ({ className, color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.25 12.75L2.25 5.25C2.25 3.59315 3.59315 2.25 5.25 2.25H8.25V15.75H5.25C3.59315 15.75 2.25 14.4069 2.25 12.75ZM9.75 15.75H12.75C14.4069 15.75 15.75 14.4069 15.75 12.75V5.25C15.75 3.59315 14.4069 2.25 12.75 2.25H9.75L9.75 15.75ZM5.25 17.25C2.76472 17.25 0.75 15.2353 0.75 12.75L0.75 5.25C0.75 2.76472 2.76472 0.75 5.25 0.75H12.75C15.2353 0.75 17.25 2.76472 17.25 5.25V12.75C17.25 15.2353 15.2353 17.25 12.75 17.25H5.25Z'
        fill={color}
      />
      <rect x='1.5' y='6' width='7.5' height='1.5' fill={color} />
      <rect x='1.5' y='10.5' width='7.5' height='1.5' fill={color} />
      <mask id='pieChartMask' maskUnits='userSpaceOnUse' x='9' y='2' width='7' height='14'>
        <path
          d='M9.75 2.25H12.75C14.4069 2.25 15.75 3.59315 15.75 5.25V12.75C15.75 14.4069 14.4069 15.75 12.75 15.75H9.75V2.25Z'
          fill='#D9D9D9'
        />
      </mask>
      <g mask='url(#pieChartMask)'>
        <path
          opacity='0.75'
          d='M17 3C16.2121 3 15.4319 3.15519 14.7039 3.45672C13.9759 3.75825 13.3145 4.20021 12.7574 4.75736L17 9V3Z'
          fill={color}
        />
        <path
          opacity='0.5'
          d='M11 9C11 8.21207 11.1552 7.43185 11.4567 6.7039C11.7583 5.97595 12.2002 5.31451 12.7574 4.75736L17 9L11 9Z'
          fill={color}
        />
        <path
          opacity='0.25'
          d='M17 3C18.1867 3 19.3467 3.35189 20.3334 4.01118C21.3201 4.67047 22.0892 5.60754 22.5433 6.7039C22.9974 7.80026 23.1162 9.00666 22.8847 10.1705C22.6532 11.3344 22.0818 12.4035 21.2426 13.2426C20.4035 14.0818 19.3344 14.6532 18.1705 14.8847C17.0067 15.1162 15.8003 14.9974 14.7039 14.5433C13.6075 14.0892 12.6705 13.3201 12.0112 12.3334C11.3519 11.3467 11 10.1867 11 9H17V3Z'
          fill={color}
        />
      </g>
    </svg>
  );
};
