import classNames from 'classnames';
import React from 'react';
import { IconProps } from '.';

export const LoginIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='20'
      height='17'
      viewBox='0 0 20 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.005 3.23926L13.0178 1.91021C12.5322 1.74833 12.0306 2.1098 12.0306 2.62172V14.3783C12.0306 14.8902 12.5322 15.2517 13.0178 15.0898L17.005 13.7608C17.6175 13.5566 18.0306 12.9834 18.0306 12.3377V4.66229C18.0306 4.01664 17.6175 3.44344 17.005 3.23926ZM19.5306 4.66229C19.5306 3.371 18.7043 2.22458 17.4793 1.81624L13.4922 0.487183C12.074 0.0144669 10.6107 1.02932 10.5338 2.50001H9.78064C8.538 2.50001 7.53064 3.50737 7.53064 4.75001V5.50001H9.03064V4.75001C9.03064 4.3358 9.36643 4.00001 9.78064 4.00001H10.5306V13H9.78064C9.36643 13 9.03064 12.6642 9.03064 12.25V11.5H7.53064V12.25C7.53064 13.4927 8.538 14.5 9.78064 14.5H10.5338C10.6107 15.9707 12.074 16.9856 13.4922 16.5128L17.4793 15.1838C18.7044 14.7754 19.5306 13.629 19.5306 12.3377V4.66229ZM13.5306 8.50001C13.5306 8.91423 13.8664 9.25001 14.2806 9.25001C14.6949 9.25001 15.0306 8.91423 15.0306 8.50001C15.0306 8.0858 14.6949 7.75001 14.2806 7.75001C13.8664 7.75001 13.5306 8.0858 13.5306 8.50001ZM5.50026 4.96967L8.50026 7.96967L9.03059 8.5L8.50026 9.03032L5.50026 12.0303L4.4396 10.9697L6.15927 9.25H0.469971V7.75H6.15927L4.4396 6.03033L5.50026 4.96967Z'
        fill={color}
      />
    </svg>
  );
};


