import { makeAction } from '@va/store';
import * as Types from './types';

export * from './types';

export const setSelectedWebsiteInfo = makeAction(Types.SET_SELECTED_WEBSITE_INFO, 'data');
export const rememberEmailForLogin = makeAction(Types.REMEMBER_EMAIL_FOR_LOGIN, 'email');
export const setSubscriptionType = makeAction(Types.SET_SUBSCRIPTION_TYPE, 'subscriptionType');
export const setSubscriptionPlan = makeAction(Types.SET_SUBSCRIPTION_PLAN, 'packageNo', 'sliderVal');
export const setInitialUpgradePlanSliderValue = makeAction(Types.SET_INITIAL_UPGRADE_PLAN_SLIDER_VALUE, 'payload');
export const resetInitialUpgradePlanSliderValue = makeAction(Types.RESET_INITIAL_UPGRADE_PLAN_SLIDER_VALUE);
