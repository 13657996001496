import PropTypes from 'prop-types';

/** @deprecated */
const Setting = ({ children, description, field }) => {
  return (
    <label className='flex justify-between items-center px-3 py-2 cursor-pointer'>
      <div className='mr-4 leading-normal'>
        <div className='font-medium text-mine-shaft text-sm'>{children}</div>
        {description && <div className='text-xs text-devil-gray'>{description}</div>}
      </div>
      <div className='grow text-right'>{field}</div>
    </label>
  );
};

Setting.propTypes = {
  children: PropTypes.string,
  description: PropTypes.string,
  field: PropTypes.node,
};

export default Setting;
