import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  addDynamicPageFailed,
  addDynamicPageSucceeded,
  ADD_DYNAMIC_PAGE,
  editDynamicPageFailed,
  editDynamicPageSucceeded,
  EDIT_DYNAMIC_PAGE,
  getDynamicPages,
  getDynamicPagesFailed,
  getDynamicPagesSucceeded,
  GET_DYNAMIC_PAGES,
  removeDynamicPageFailed,
  removeDynamicPageSucceeded,
  REMOVE_DYNAMIC_PAGE,
  resetRequestStatus,
} from '@va/dashboard/actions/api';
import { popRemovingDynamicPage, pushRemovingDynamicPage } from '@va/dashboard/actions/app';
import Api from '@va/dashboard/api-client/index';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { isValidWebsiteId } from '@va/util/helpers';

export function* watchers() {
  yield all([
    takeLatest(GET_DYNAMIC_PAGES, getDynamicPagesSaga),
    takeLatest(ADD_DYNAMIC_PAGE, addDynamicPageSaga),
    takeLatest(EDIT_DYNAMIC_PAGE, editDynamicPageSaga),
    takeEvery(REMOVE_DYNAMIC_PAGE, removeDynamicPageSaga),
  ]);
}

export function* getDynamicPagesSaga() {
  try {
    const websiteId = yield select(getInstanceId);

    if (!isValidWebsiteId(websiteId)) throw new Error('No active website');
    const response = yield call(Api.getDynamicPages, websiteId);
    yield put(getDynamicPagesSucceeded({ data: response }));
  } catch (error) {
    yield put(getDynamicPagesFailed(error));
  }
}

export function* addDynamicPageSaga(action) {
  try {
    const websiteId = yield select(getInstanceId);
    yield call(Api.addDynamicPage, websiteId, action.data);
    yield put(addDynamicPageSucceeded());
    yield put(getDynamicPages());
  } catch (error) {
    yield put(addDynamicPageFailed(error));
  } finally {
    yield delay(3000);
    yield put(resetRequestStatus('addDynamicPage'));
  }
}

export function* editDynamicPageSaga(action) {
  try {
    const websiteId = yield select(getInstanceId);
    yield call(Api.editDynamicPage, websiteId, action.id, action.data);
    yield put(editDynamicPageSucceeded());
    yield put(getDynamicPages());
  } catch (error) {
    yield put(editDynamicPageFailed(error));
  } finally {
    yield delay(3000);
    yield put(resetRequestStatus('editDynamicPage'));
  }
}

export function* removeDynamicPageSaga(action) {
  yield put(pushRemovingDynamicPage(action.id));
  try {
    const websiteId = yield select(getInstanceId);
    yield call(Api.removeDynamicPage, websiteId, action.id);
    yield put(removeDynamicPageSucceeded());
    yield put(popRemovingDynamicPage(action.id));
    yield put(getDynamicPages());
  } catch (error) {
    yield put(removeDynamicPageFailed(error));
    yield put(popRemovingDynamicPage(action.id));
  } finally {
    yield delay(3000);
    yield put(resetRequestStatus('removeDynamicPage'));
  }
}
