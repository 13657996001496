import { TEST_IDS } from '@va/constants';
import { SearchIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { CloseIconWithBorder } from '@va/svg-visa-icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './style.scss';

/** @deprecated TODO Update or delete*/
export const SearchInput = ({ value, onChange, id, className = '', placeholder, disabled }) => {
  const translate = useTranslate();

  const onInputChanged = (value) => {
    onChange(value);
  };

  return (
    <div
      className={classNames(
        className,
        `search-input flex p-6px w-full h-[48px] rounded-xl cursor-default bg-white-snow hover:bg-gray-concrete active:bg-gray-concrete`,
        { 'cursor-not-allowed': disabled },
      )}
      id={id}
    >
      <SearchIcon width={36} className='flex-shrink-0' color='#696969' />
      <input
        data-testid={TEST_IDS.generic.inputs.search}
        className='bg-transparent text-primary text-sm'
        type='text'
        value={value}
        onChange={(e) => onInputChanged(e.target.value)}
        placeholder={placeholder ? placeholder : `${translate('filter.search')}...`}
        disabled={disabled}
      />
      <CloseIconWithBorder
        className={classNames({ hidden: !value, 'cursor-pointer': value })}
        color={'#C3C3C3'}
        radius={10}
        onClick={() => {
          onInputChanged('');
        }}
      />
    </div>
  );
};

SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};
