import { countryCodes, isoCountries } from '@va/assets';
import { businessSector, businessSize, businessType, howDidYouFindUs } from '@va/constants';
import { sortBy } from 'lodash';

export function getBusinessSectorOptions(translate: (k: string) => string) {
  return businessSector.map((item: any) => {
    return {
      label: translate(item.transKey),
      value: item.value,
    };
  });
}

export function getBusinessTypeOptions(translate: (k: string) => string) {
  return businessType.map((item: any) => {
    return {
      label: translate(item.transKey),
      value: item.value,
    };
  });
}

export function getBusinessSizeOptions(translate: (k: string) => string) {
  return businessSize.map((item: any) => {
    return {
      label: translate(item.transKey),
      value: item.value,
    };
  });
}

export function getHowDidYouFindUsOptions(translate: (k: string) => string) {
  return howDidYouFindUs.map((item: { transKey: string; value: number | string }) => {
    return {
      label: translate(item.transKey),
      value: item.value,
    };
  });
}

export function getBusinessSectorName(value: string) {
  return businessSector.filter((item: any) => item.value === parseInt(value)).map((sector) => sector.transKey);
}

export function getCountryOptions(translate: (k: string) => string) {
  return sortBy(
    Object.entries(isoCountries).map((item) => {
      return {
        value: item[0],
        label: translate(`country.name.${item[0]}`),
      };
    }),
    (country) => country.label,
  );
}

export const getAlpha2CountryCodes = (): {
  [countryCodeAlpha2: string]: string;
} => {
  return countryCodes.reduce((acc, item) => Object.assign(acc, { [item.alpha2]: item.alpha3 }), {});
};

export const getAlpha3CountryCodes = (): {
  [countryCodeAlpha3: string]: string;
} => {
  return countryCodes.reduce((acc, item) => Object.assign(acc, { [item.alpha3]: item.alpha2 }), {});
};

export const alpha3CountryCodes = getAlpha3CountryCodes();
export const alpha2CountryCodes = getAlpha2CountryCodes();

export const findAlpha2CodeByAlpha3 = (alpha3Code: string) => {
  return alpha3CountryCodes[alpha3Code];
};

export const findAlpha3CodeByAlpha2 = (alpha2Code: string) => {
  return alpha2CountryCodes[alpha2Code];
};

export const isCountryCode = (code: string) => {
  return !!countryCodes.find((countryCode) => countryCode.alpha2 === code || countryCode.alpha3 === code);
};
