import { validationTranslationKeys } from '@va/constants';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { getAgencyUiSucceeded } from '@va/standalone/shared/actions';
import { getAgencyUi } from '@va/standalone/shared/selectors';
import { useFormik } from 'formik';
import { isNil } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useUpdateAgencyUi } from '../apiClient';

export const fieldName = 'replyToEmailAddress';

const useReplyToEmailAddress = () => {
  const {
    execute: updateAgencyUi,
    isLoading,
    error: apiError,
    clearError: clearApiError,
    isSucceeded,
    data,
  } = useUpdateAgencyUi();

  const translate = useTranslate();
  const dispatch = useDispatch();
  const agencyUi = useSelector(getAgencyUi) as undefined | { id: string; replyToEmailAddress: string | null };

  const { showErrorNotification, showSuccessNotification } = useAddNotification();

  const {
    handleChange,
    values,
    errors: formErrors,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: { [fieldName]: agencyUi?.replyToEmailAddress ?? null },
    validationSchema: yup.object().shape({
      replyToEmailAddress: yup
        .string()
        .required(translate(validationTranslationKeys.required))
        .email(translate(validationTranslationKeys.invalidEmail)),
    }),
    onSubmit: (values) => {
      if (!agencyUi?.id) return;
      updateAgencyUi({ replyToEmailAddress: values.replyToEmailAddress });
    },
  });

  const hasMadeChanges = agencyUi?.replyToEmailAddress !== values.replyToEmailAddress;

  // Populating the initial state
  useEffect(() => {
    if (!isNil(values.replyToEmailAddress) || !agencyUi?.replyToEmailAddress) return;
    setFieldValue(fieldName, agencyUi?.replyToEmailAddress);
  }, [agencyUi?.replyToEmailAddress, setFieldValue, values.replyToEmailAddress]);

  useEffect(() => {
    if (!apiError) return;
    showErrorNotification();
    clearApiError();
  }, [apiError, clearApiError, showErrorNotification]);

  useEffect(() => {
    if (!isSucceeded) return;
    showSuccessNotification();
    dispatch(getAgencyUiSucceeded({ data }));
  }, [data, dispatch, isSucceeded, showSuccessNotification]);

  const clearField = useCallback(() => {
    setFieldValue(fieldName, '');
  }, [setFieldValue]);

  return {
    handleChange,
    clearField,
    isLoading,
    formErrors,
    values,
    handleSubmit,
    agencyUiId: agencyUi?.id,
    hasMadeChanges,
  };
};

export default useReplyToEmailAddress;
