import { getHourlyTimeDurationFromMs } from '@va/util/helpers';
import { useTranslate } from '@va/localization';
import PropTypes from 'prop-types';

export const TimeCell = ({ value }) => {
  const translate = useTranslate();
  return <span>{!value ? translate('feedback.stats.notCompleted') : getHourlyTimeDurationFromMs(value * 1000)}</span>;
};

TimeCell.propTypes = {
  value: PropTypes.number, //seconds
};
