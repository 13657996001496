export function VideoCameraV2(props) {
  const { color, ...restProps } = props;
  const colorValue = color ? color : '#666666';

  return (
    <svg {...restProps} width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17 11V12.2C17 13.8802 17 14.7202 16.673 15.362C16.3854 15.9265 15.9265 16.3854 15.362 16.673C14.7202 17 13.8802 17 12.2 17H11'
        stroke={colorValue}
        strokeWidth='2'
      />
      <path
        d='M1 11V12.2C1 13.8802 1 14.7202 1.32698 15.362C1.6146 15.9265 2.07354 16.3854 2.63803 16.673C3.27976 17 4.11984 17 5.8 17H7'
        stroke={colorValue}
        strokeWidth='2'
      />
      <circle cx='9' cy='9' r='3' stroke={colorValue} strokeWidth='2' />
      <path
        d='M17 7V5.8C17 4.11984 17 3.27976 16.673 2.63803C16.3854 2.07354 15.9265 1.6146 15.362 1.32698C14.7202 1 13.8802 1 12.2 1H11'
        stroke={colorValue}
        strokeWidth='2'
      />
      <path
        d='M1 7V5.8C1 4.11984 1 3.27976 1.32698 2.63803C1.6146 2.07354 2.07354 1.6146 2.63803 1.32698C3.27976 1 4.11984 1 5.8 1H7'
        stroke={colorValue}
        strokeWidth='2'
      />
    </svg>
  );
}
