import classNames from 'classnames';
import QuickNavigatorListItem from './QuickNavigatorListItem';
import useQuickNavigator from './useQuickNavigator';

export type ListType = {
  id: string;
  title: string;
  subTitle?: string;
};

export type QuickNavigatorTypes = {
  navigatorClass?: string;
  list: ListType[];
  offset: number;
  parentId?: string;
};
export const QuickNavigator = ({ list, navigatorClass, offset, parentId }: QuickNavigatorTypes) => {
  const { activeElementId } = useQuickNavigator({
    elementsId: list.map((item) => item.id),
    offset: offset ?? 0,
    scrollContainerId: parentId ?? 'root',
  });

  return (
    <div
      className={classNames(
        'bg-white-snow rounded-15 p-[3px] hidden sm-initial:block scrollbar scrollbar-thumb overflow-auto min-w-[300px] h-[fit-content] sticky',
        navigatorClass,
      )}
    >
      {list.map((item, index) => (
        <QuickNavigatorListItem key={index} item={item} activeElementId={activeElementId} />
      ))}
    </div>
  );
};
