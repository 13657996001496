import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import EventsList from '../Events/EventsList';
import { isFullscreen } from '../helpers';
import CustomControls from './CustomControls';
import UserEvent from './ProgressBarEvent';
import { ProgressBarTooltip } from './ProgressBarTooltip';
import './styles.css';

class Controls extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentTime: 0,
      totalTime: null,
      isPlaying: false,
      isSkipping: false,
      isFinished: false,
      skipInactive: false,
      speed: 1,
      lastPlayedEventIndex: 0,
      currentRecordingIndex: 0,
      eventsAsMap: {},
    };

    this.progressWrapper = React.createRef();
  }

  componentDidMount() {
    this.computeMeta();
    this.addReplayerListeners();

    this.setState({
      eventsAsMap: this.props.progressBarEvents.reduce((map, obj, index) => {
        map[obj.id] = { ...obj, index };
        return map;
      }, {}),
    });

    if (this.props.autoPlay) {
      this.props.replayer.play(this.props.autoPlayStart);
    } else {
      this.props.replayer.pause();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isPlaying !== prevState.isPlaying) {
      if (this.state.isPlaying) {
        this.loopTimer();
      } else {
        cancelAnimationFrame(this.time);
        this.timer = null;
      }
    }

    if (this.state.lastPlayedEventIndex !== prevState.lastPlayedEventIndex) {
      this.props.onCurrentEventChange(this.state.lastPlayedEventIndex);
    }
  }

  componentWillUnmount() {
    const { isPlaying } = this.state;

    if (this.timer) {
      cancelAnimationFrame(this.timer);
      this.timer = null;
    }

    if (isPlaying) {
      this.pause();
    }
  }

  loopTimer = () => {
    const update = () => {
      const { replayer } = this.props;

      if (!this.timer) return;

      const currentTime = this.computeCurrentTime(replayer);

      const lastPlayedEventId = replayer?.service?.state?.context?.lastPlayedEvent?.id;

      const lastPlayedEventIndex = this.state.eventsAsMap[lastPlayedEventId]?.index || -1;

      if (lastPlayedEventIndex !== -1) {
        this.setState({
          lastPlayedEventIndex,
          currentTime,
        });
      } else {
        this.setState({ currentTime });
      }

      if (currentTime < this.state.totalTime) {
        this.timer = requestAnimationFrame(update);
      }
    };

    this.timer = requestAnimationFrame(update);
  };

  computeCurrentTime() {
    let currentTime = this.props.replayer.getCurrentTime();
    if (currentTime < 0) currentTime = 0;
    return currentTime;
  }

  addReplayerListeners = () => {
    const { replayer } = this.props;
    replayer.on('start', () => {
      this.setState({ isPlaying: true });
    });
    replayer.on('pause', () => {
      this.setState({ isPlaying: false });
    });
    replayer.on('finish', this.onFinishCallback);
    replayer.on('skip-start', ({ speed }) => this.setState({ speed: speed, isSkipping: true }));
    replayer.on('skip-end', ({ speed }) => this.setState({ speed: speed, isSkipping: false }));
  };

  onFinishCallback = () => {
    const { replayer, allowReplay } = this.props;
    if (allowReplay) {
      this.setState({ isPlaying: false, isFinished: true });
    } else {
      replayer.pause();
      this.playFromTimeOffset(0);
    }
  };

  /**
   * Computes totalTime based on the duration of each recording
   */
  computeMeta = () => {
    const totalTime = this.props.recordings.reduce((a, b) => a + (b.duration || 0), 0);
    // added a gap of 1 millisecond after each recording
    const gaps = this.props.recordings.length - 1;
    this.setState({ totalTime: totalTime + gaps });
  };

  play = (time) => {
    this.props.replayer.play(time ?? this.state.currentTime);
  };

  replay = () => {
    this.setState({ isFinished: false });
    this.play(0);
  };

  pause = () => {
    this.props.replayer.pause();
  };

  toggle = () => {
    const { isPlaying, isFinished } = this.state;
    isPlaying ? this.pause() : isFinished ? this.replay() : this.play();
  };

  setSpeed = (speed) => {
    const { replayer } = this.props;

    const previousPlayValue = this.state.isPlaying;

    replayer.pause();
    replayer.setConfig({ speed });
    this.setState({ speed });

    if (previousPlayValue) {
      replayer.play(this.state.currentTime);
    }
  };

  toggleSkipInactive = () => {
    const { skipInactive } = this.state;
    const { replayer } = this.props;
    const newValue = !skipInactive;
    replayer.setConfig({ skipInactive: newValue });
    this.setState({ skipInactive: newValue });
  };

  getRecordingIndexByTimeOffset = (timeOffset) => {
    const { recordings } = this.props;

    let duration = 0;
    for (let i = 0; i < recordings.length; i++) {
      // one extra millisecond for gap
      duration += recordings[i].duration + 1;
      if (timeOffset <= duration) return i;
    }
    return -1;
  };

  getRecordingTimeOffset = (recordingIndex) => {
    const { recordings } = this.props;

    let duration = 0;
    for (let i = 0; i < recordingIndex; i++) {
      // one extra millisecond for gap
      duration += recordings[i].duration + 1;
    }

    return duration;
  };

  handleProgressClick = (clickEvent) => {
    const timeOffset = this.calculateTimeOffset(clickEvent);
    this.playFromTimeOffset(timeOffset);
  };

  playFromTimeOffset(timeOffset) {
    const { isPlaying: previousPlayValue, isFinished } = this.state;

    if (isFinished) {
      this.setState({ isFinished: false });
    }

    this.pause();

    //index of the new recording
    const newRecordingIndex = this.getRecordingIndexByTimeOffset(timeOffset);

    this.setState(
      {
        currentRecordingIndex: newRecordingIndex,
        currentTime: timeOffset,
      },
      () => {
        if (previousPlayValue) {
          this.play();
        } else {
          this.play();
          this.pause();
        }
      },
    );
  }

  calculateTimeOffset(event) {
    const progressBarRect = this.progressWrapper.current.getBoundingClientRect();
    const x = event.clientX - progressBarRect.left;
    let percent = x / progressBarRect.width;
    if (percent < 0) {
      percent = 0;
    } else if (percent > 1) {
      percent = 1;
    }

    return this.state.totalTime * percent;
  }

  formatTime(timestamp) {
    const utcMoment = moment.utc(timestamp);

    if (utcMoment.hour()) {
      return utcMoment.format('HH:mm:ss');
    } else {
      return utcMoment.format('mm:ss');
    }
  }

  render() {
    const percentage = `${100 * Math.min(1, this.state.currentTime / this.state.totalTime)}%`;
    const {
      playButton,
      skipInactiveSwitch,
      toggleFullscreenButton,
      onFullscreen,
      speedValues,
      speedItem,
      controls,
      progressBarEvents,
      isPlayerFullscreen,
    } = this.props;
    const { isPlaying, skipInactive, isFinished } = this.state;

    const controlsStyle = {
      borderRadius: isPlayerFullscreen ? 0 : '0 0 24px 24px',
    };

    return (
      <div>
        <div style={controlsStyle} className={'ssr-controls'}>
          <div ref={this.progressWrapper} className={'ssr-progress-bar-wrapper'} onClick={this.handleProgressClick}>
            <div className={'ssr-progress-bar-passed'} style={{ width: percentage }} />
            <div className={'ssr-progress-bar-handler'} style={{ left: percentage }} />
            <ProgressBarTooltip totalTime={this.state.totalTime}>
              <div className='absolute left-0 top-0 w-full h-full' />
            </ProgressBarTooltip>
            {progressBarEvents &&
              this.state.totalTime &&
              progressBarEvents.map((userEvent) => {
                return <UserEvent key={userEvent.id} eventData={userEvent} totalTime={this.state.totalTime} />;
              })}
          </div>

          <div className={'ssr-control-buttons ssr-row'}>
            {controls ? (
              <CustomControls
                isPlaying={isPlaying}
                isFinished={isFinished}
                togglePlay={this.toggle}
                currentTime={this.formatTime(this.state.currentTime)}
                totalTime={this.formatTime(this.state.totalTime)}
                speedValues={speedValues}
                setSpeed={this.setSpeed}
                currentSpeed={this.state.speed}
                toggleSkipInactive={this.toggleSkipInactive}
                isSkipping={skipInactive}
                isFullscreen={isFullscreen()}
                toggleFullScreen={onFullscreen}
                controls={controls}
              />
            ) : (
              <Fragment>
                <div className={'ssr-row'}>
                  <div onClick={this.toggle} className={'ssr-control-button ssr-control-button__play'}>
                    {playButton({ isPlaying })}
                  </div>

                  <div className={'ssr-time ssr-row'}>
                    <div className={'ssr-time__current'}>{this.formatTime(this.state.currentTime)}</div>
                    <div>/</div>
                    <div className={'ssr-time__total'}>{this.formatTime(this.state.totalTime)}</div>
                  </div>
                </div>
                <div className={'ssr-speed-buttons-wrapper ssr-row'}>
                  {speedValues.map((speed) => (
                    <div
                      key={speed}
                      className={'ssr-control-button ssr-control-button__speed'}
                      onClick={() => this.setSpeed(speed)}
                    >
                      {speedItem({
                        value: speed,
                        isActive: this.state.speed === speed,
                      })}
                    </div>
                  ))}
                </div>

                <div className={'ssr-control-button ssr-control-button__switch'} onClick={this.toggleSkipInactive}>
                  {skipInactiveSwitch({ isSkipping: skipInactive })}
                </div>
                <div className={'ssr-control-button'} onClick={onFullscreen}>
                  {toggleFullscreenButton({ isFullscreen: isFullscreen() })}
                </div>
              </Fragment>
            )}
          </div>
        </div>
        {this.props.showEventsLog && this.props.progressBarEvents && (
          <EventsList
            events={this.props.progressBarEvents}
            lastPlayedEventIndex={this.state.lastPlayedEventIndex}
            onEventClick={this.playFromEvent}
          />
        )}
      </div>
    );
  }
}

Controls.propTypes = {
  replayer: PropTypes.any.isRequired,
  autoPlay: PropTypes.bool.isRequired,
  autoPlayStart: PropTypes.number,
  playButton: PropTypes.func.isRequired,
  skipInactiveSwitch: PropTypes.func.isRequired,
  toggleFullscreenButton: PropTypes.func.isRequired,
  speedValues: PropTypes.array.isRequired,
  speedItem: PropTypes.func.isRequired,
  onFullscreen: PropTypes.func.isRequired,
  showEventsLog: PropTypes.bool.isRequired,
  controls: PropTypes.func,
  progressBarEvents: PropTypes.array.isRequired,
  recordings: PropTypes.array.isRequired,
  onCurrentEventChange: PropTypes.func.isRequired,
  isPlayerFullscreen: PropTypes.bool.isRequired,
};

export default Controls;

const defaultSpeedItem = ({ value, isActive }) => (
  <div className={`${isActive ? 'ssr-control-button__speed--active' : ''}`}>{value}x</div>
);

const defaultPlayButton = ({ isPlaying }) => <div>{isPlaying ? 'Pause' : 'Play'}</div>;

const defaultSkipInactiveSwitch = ({ isSkipping }) => <div>{isSkipping ? 'Skipping' : 'Not skipping'}</div>;

const defaultToggleFullscreenButton = ({ isFullscreen }) => <div>{isFullscreen ? 'Minimize' : 'Maximize'}</div>;

Controls.defaultProps = {
  speedValues: [1, 2, 4, 8],
  speedItem: defaultSpeedItem,
  playButton: defaultPlayButton,
  skipInactiveSwitch: defaultSkipInactiveSwitch,
  toggleFullscreenButton: defaultToggleFullscreenButton,
};
