import { useLocale } from '@va/localization';
import { Paragraph } from '@va/ui/design-system';
import { addNumberSeparator } from '@va/util/helpers';

export function TooltipSubtitle({ subtitle = '', elementsCount = 0 }: { subtitle?: string; elementsCount?: number }) {
  const { locale } = useLocale();
  if (!subtitle) return <div />;
  return (
    <div className='flex flex-row flex-nowrap gap-2 self-end sm-initial:self-auto'>
      <Paragraph className='truncate max-w-xs'>{subtitle}</Paragraph>
      <Paragraph>({addNumberSeparator(elementsCount, locale)})</Paragraph>
    </div>
  );
}
