import { UserMenu } from '@va/dashboard/shared/ui-layout';
import { ResetRequestedPage as ResetRequestedPageComponent } from '@va/dashboard/ui/pages';
import { WebsiteSelectorV2 } from '@va/standalone/shared/feature-website-selector';

const topBarClassNames =
  'z-999 h-[70px] px-2 lg:pl-18px lg:pr-6px backdrop-filter backdrop-blur-md flex flex-row justify-between items-center flex-nowrap border-b border-gray-mercury fixed top-0 w-full';

const ResetRequestedPage = () => {
  return (
    <>
      <div id='top-bar' className={topBarClassNames}>
        <div className={'w-4/5 sm-initial:w-auto'}>
          <WebsiteSelectorV2 />
        </div>
        <UserMenu />
      </div>
      <ResetRequestedPageComponent />
    </>
  );
};

export default ResetRequestedPage;
