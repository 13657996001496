import { IconProps } from '.';

export const ChatIcon = ({ className, color = '#3C3C3C' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 18.5L7.5 17.75L12 15.5H13.5C15.9853 15.5 18 13.4853 18 11V5C18 2.51472 15.9853 0.5 13.5 0.5H4.5C2.01472 0.5 0 2.51472 0 5V11C0 13.4853 2.01472 15.5 4.5 15.5H6V16.8229V17V18.5ZM11.3292 14.1584L7.5 16.0729V15.5V14H6H4.5C2.84315 14 1.5 12.6569 1.5 11V5C1.5 3.34315 2.84315 2 4.5 2H13.5C15.1569 2 16.5 3.34315 16.5 5V11C16.5 12.6569 15.1569 14 13.5 14H12H11.6459L11.3292 14.1584ZM4.875 9.125C5.49632 9.125 6 8.62132 6 8C6 7.37868 5.49632 6.875 4.875 6.875C4.25368 6.875 3.75 7.37868 3.75 8C3.75 8.62132 4.25368 9.125 4.875 9.125ZM10.5 8C10.5 8.62132 9.99632 9.125 9.375 9.125C8.75368 9.125 8.25 8.62132 8.25 8C8.25 7.37868 8.75368 6.875 9.375 6.875C9.99632 6.875 10.5 7.37868 10.5 8ZM13.5 4.25C13.0858 4.25 12.75 4.58579 12.75 5V11C12.75 11.4142 13.0858 11.75 13.5 11.75C13.9142 11.75 14.25 11.4142 14.25 11V5C14.25 4.58579 13.9142 4.25 13.5 4.25Z'
        fill={color}
      />
    </svg>
  );
};


