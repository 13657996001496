import { IconProps } from '..';
export const WindowsIcon = ({ className }: IconProps) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path d='M8.55 0H0V8.55H8.55V0Z' fill='#F25022' />
      <path d='M18 0H9.44995V8.55H18V0Z' fill='#7FBA00' />
      <path d='M8.55 9.44995H0V18H8.55V9.44995Z' fill='#00A4EF' />
      <path d='M18 9.44995H9.44995V18H18V9.44995Z' fill='#FFB900' />
    </svg>
  );
};
