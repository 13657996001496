import React from 'react';
import PropTypes from 'prop-types';

export function WebsiteEntry(props) {
  const fillColor = !props.customColor ? '#13232A' : '';

  return (
    <svg className={props.className}
      width="25" height="25" viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <polygon id="path-1" points="0 24 24 24 24 0 0 0"/>
      </defs>
      <g fillRule="evenodd">
        <g transform="translate(-400 -505)">
          <g transform="translate(390 505)">
            <g transform="translate(10)" fill={fillColor}>
              <path d="M0,24 L24,24 L24,0 L0,0 L0,24 Z M1,23.001 L23.001,23.001 L23.001,1 L1,1 L1,23.001 Z"/>
              <path d="M3,2.75 C2.862,2.75 2.75,2.862 2.75,3 C2.75,3.275 3.25,3.275 3.25,3 C3.25,2.862 3.138,2.75 3,2.75 Z M3,3.75 C2.587,3.75 2.25,3.413 2.25,3 C2.25,2.586 2.587,2.25 3,2.25 C3.413,2.25 3.75,2.586 3.75,3 C3.75,3.413 3.413,3.75 3,3.75 Z"/>
              <path d="M5.5,2.75 C5.362,2.75 5.25,2.862 5.25,3 C5.25,3.275 5.75,3.275 5.75,3 C5.75,2.862 5.638,2.75 5.5,2.75 M5.5,3.75 C5.087,3.75 4.75,3.413 4.75,3 C4.75,2.586 5.087,2.25 5.5,2.25 C5.913,2.25 6.25,2.586 6.25,3 C6.25,3.413 5.913,3.75 5.5,3.75"/>
              <path d="M8,2.75 C7.862,2.75 7.75,2.862 7.75,3 C7.75,3.275 8.25,3.275 8.25,3 C8.25,2.862 8.138,2.75 8,2.75 M8,3.75 C7.587,3.75 7.25,3.413 7.25,3 C7.25,2.586 7.587,2.25 8,2.25 C8.413,2.25 8.75,2.586 8.75,3 C8.75,3.413 8.413,3.75 8,3.75"/>
              <mask id="mask-2" fill="#fff">
                <use xlinkHref="#path-1"/>
              </mask>
              <polygon mask="url(mask-2)" points="0 6 24 6 24 5 0 5"/>
              <polygon mask="url(mask-2)" points="9 23.5 10 23.5 10 5.5 9 5.5"/>
              <polygon mask="url(mask-2)" points="3 9 7 9 7 8 3 8"/>
              <polygon mask="url(mask-2)" points="3 12 7 12 7 11 3 11"/>
              <polygon mask="url(mask-2)" points="3 15 7 15 7 14 3 14"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

WebsiteEntry.propTypes = {
  className: PropTypes.string,
  customColor: PropTypes.bool
};
