export const nonTranslatableText = {
  appName: 'TWIPLA',
  dpa: 'Data Processing Agreement',
  terms: 'Terms Of Use',
  gdprCommitment: 'Safeguarding Your Data',
  yourPrivacyPolicy: 'What to include in your Privacy Policy',
  cookieInfo: 'Cookie Information',
  aboutUs: 'About Us',
  blog: 'Blog',
  facebookGroup: 'TWIPLA | Facebook Group',
  seoServices: 'Seo Services',
  helpCenter: 'Help & Support',
};
