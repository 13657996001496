import { TEST_IDS } from '@va/constants';
import { VisitDetails } from '@va/dashboard/components';
import { OperatingSystemIcon } from '@va/icons';
import { useTranslate } from '@va/localization';

export function OperatingSystemCell({ value }: { value: string }) {
  const translate = useTranslate();
  return (
    <VisitDetails
      data-testid={TEST_IDS.generic.visitInfo.os}
      tooltipText={translate(`panels.latestVisitors.operatingSystem`)}
      tooltipSubtext={value}
      icon={<OperatingSystemIcon osName={value} className={'w-18 h-18'} />}
    />
  );
}
