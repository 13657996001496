import { Clock, NavStatistic } from '@va/icons';
import { useTranslate } from '@va/localization';
import { contributorRole } from '@va/standalone/shared/selectors';
import {
  IconWithBackground,
  ListCardItemWithIcon,
  paragraphSizes,
  ParagraphWithTooltip,
  SelectableButtonsGroup,
  ToggleActionCard,
} from '@va/ui/design-system';
import { useSelector } from 'react-redux';
import useNotificationPreferences, { ReportTypeEnum } from './useNotificationPreferences';

export const EmailReportsToggle = () => {
  const translate = useTranslate();
  const isContributor = useSelector(contributorRole);
  const { formValues, onFrequencyChange, getFrequency, selectedFrequency, onReportsChange } =
    useNotificationPreferences();

  return (
    <ToggleActionCard
      title={translate('form.emailReports.statisticsReports')}
      subtitle={translate('form.emailReports.reportsSubtitle')}
      name={'sendEmailReports'}
      subSectionClass={'p-3 pl-1.5'}
      value={formValues.reportType !== ReportTypeEnum.never}
      handleChange={onReportsChange}
      disabled={isContributor}
      infoTooltip={isContributor ? translate('tooltips.contributorRole') : undefined}
      icon={() => <IconWithBackground className='bg-white' icon={() => <NavStatistic />} />}
    >
      <div className={'flex w-full justify-between rounded-12 sm:flex-col bg-white-snow flex-nowrap'}>
        <ListCardItemWithIcon
          className='!max-w-full'
          icon={<IconWithBackground className='bg-white' icon={() => <Clock strokeColor='#696969' />} />}
          topText={translate('unsubscribePage.frequencyReports')}
          bottomNode={
            <ParagraphWithTooltip
              size={paragraphSizes.tiny}
              colorClassName='text-primary'
              className={'!text-devil-gray'}
            >
              {translate('form.emailReports.frequencyReportsSubtitle')}
            </ParagraphWithTooltip>
          }
        />
        <div className='overflow-x-auto scrollbar scrollbar-thumb flex self-center'>
          <SelectableButtonsGroup
            buttons={getFrequency()}
            selectedValue={selectedFrequency}
            onChange={(value) => onFrequencyChange({ name: 'reportType', value: Number(value) })}
            className={'!bg-white-smoke !justify-center'}
            activeButtonColor='secondary'
            inactiveButtonColor='tertiary'
          />
        </div>
      </div>
    </ToggleActionCard>
  );
};
