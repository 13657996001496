import { IconProps } from '..';

export const AppleIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      width='16'
      height='18'
      viewBox='0 0 16 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M14.8606 6.13611C14.7555 6.21777 12.9124 7.25602 12.9124 9.56578C12.9124 12.2372 15.2571 13.1822 15.3272 13.2054C15.3155 13.2637 14.9539 14.5003 14.0906 15.7602C13.3207 16.8685 12.5157 17.9766 11.2908 17.9766C10.066 17.9766 9.75095 17.265 8.33939 17.265C6.96285 17.265 6.47287 18 5.35295 18C4.23303 18 3.4514 16.9734 2.55321 15.7135C1.51496 14.2319 0.675049 11.9338 0.675049 9.75237C0.675049 6.25267 2.9499 4.39778 5.18962 4.39778C6.37957 4.39778 7.37106 5.17941 8.11767 5.17941C8.82927 5.17941 9.93755 4.35113 11.2907 4.35113C11.8041 4.35124 13.6473 4.39801 14.8606 6.13611ZM10.6493 2.86977C11.2092 2.20482 11.6058 1.28325 11.6058 0.361672C11.6058 0.233358 11.5942 0.105045 11.5708 0C10.6609 0.0350151 9.57599 0.606665 8.92268 1.36491C8.40942 1.94819 7.93107 2.86977 7.93107 3.80309C7.93107 3.94304 7.95445 4.0831 7.96609 4.12974C8.02437 4.14138 8.11778 4.15313 8.21108 4.15313C9.02772 4.15301 10.0542 3.60474 10.6493 2.86977Z'
        fill={color}
      />
    </svg>
  );
};
