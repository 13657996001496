import { MATCHING_TYPES } from '@va/constants';
import { CampaignsIcon } from '@va/icons';
import { UrlPatternTypeEnum } from '@va/types/website';
import { IconWithBackground } from '@va/ui/design-system';
import { SelectDropdownInputOption } from './SelectDropdownInput';

export function formatUrlPatternOptionForDropdown(option: { value: string; labelKey: string }, translate: any) {
  return {
    value: option.value,
    label: translate(option.labelKey),
    labelTooltip: getUrlPatternTooltipInfo(option.value as UrlPatternTypeEnum, translate),
    icon: (
      <IconWithBackground
        className='!bg-white-snow group-hover:!bg-white'
        icon={() => <CampaignsIcon color='#696969' />}
      />
    ),
  };
}

export const getUrlPatternDropdownOptions = (translate: Function): SelectDropdownInputOption[] =>
  MATCHING_TYPES.map((item) => formatUrlPatternOptionForDropdown(item, translate));

export function getUrlPatternTooltipInfo(type: UrlPatternTypeEnum, translate: Function) {
  return translate(`tooltips.urlPattern.${type.toLowerCase()}`);
}
