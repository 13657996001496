export function WebsiteNotPublished(props) {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 60 60'
      xmlSpace='preserve'
      {...props}
    >
      <path
        fill='#009688'
        stroke='#009688'
        strokeWidth='0.4'
        strokeMiterlimit='10'
        d='M7.4,19.9c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6S9,17.5,9,18.3C9,19.2,8.3,19.9,7.4,19.9z M7.4,17
	c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.2,1.3,1.2s1.3-0.5,1.3-1.2C8.7,17.6,8.1,17,7.4,17z'
      />
      <path
        fill='#009688'
        stroke='#009688'
        strokeWidth='0.4'
        strokeMiterlimit='10'
        d='M12.7,19.9c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6C14.3,19.2,13.6,19.9,12.7,19.9z
	M12.7,17c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.2,1.3,1.2c0.8,0,1.3-0.5,1.3-1.2C14.1,17.6,13.5,17,12.7,17z'
      />
      <path
        fill='#009688'
        stroke='#009688'
        strokeWidth='0.4'
        strokeMiterlimit='10'
        d='M18,19.9c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6C19.6,19.2,18.9,19.9,18,19.9z
	M18,17c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.2,1.3,1.2c0.8,0,1.3-0.5,1.3-1.2C19.4,17.6,18.8,17,18,17z'
      />
      <rect x='5.9' y='28.5' width='7.8' height='1.4' />
      <g>
        <path
          fill='#009688'
          d='M55.5,2.7l3.2,3.2l-24,24h-3.2v-3.2L55.5,2.7 M55.5,0.9L30.2,26.2v4.9h4.9L60.4,5.9L55.5,0.9L55.5,0.9z'
        />
        <g>
          <rect
            x='53'
            y='5.1'
            transform='matrix(0.7071 -0.7071 0.7071 0.7071 10.2555 40.1636)'
            fill='#009688'
            width='1.2'
            height='5.2'
          />
        </g>
      </g>
      <path d='M0.4,59.6V13.4h40.3l-1.2,1.2h-38v8.6h29.4l-1.2,1.2H19.2v34.1h26.3V24.3h-0.9l2-2v37.3H0.4z M1.6,58.4H18V24.3H1.6V58.4z' />
      <rect x='5.9' y='32.4' width='7.8' height='1.4' />
      <rect x='5.9' y='36.5' width='7.8' height='1.4' />
    </svg>
  );
}
