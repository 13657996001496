import { getInstanceId } from '@va/dashboard/selectors/app';
import { getPreviousGlobalFilter, getStepFilter } from '@va/dashboard/selectors/ui';
import { useSelector } from 'react-redux';

export const usePreviousPeriodFilter = (keepOffset = true) => {
  // TODO Important handle install date
  const { period: unit } = useSelector(getStepFilter);
  const { from, until } = useSelector(getPreviousGlobalFilter);
  const websiteId = useSelector(getInstanceId);

  return {
    from: from.toISOString(keepOffset),
    until: until.toISOString(keepOffset),
    unit,
    websiteId,
  };
};
