import { CheckboxChecked, CheckboxUnchecked } from '@va/svg-visa-icons';
import PropTypes from 'prop-types';
import { Component } from 'react';

export default class Checkbox extends Component {
  render() {
    const { label, id, isChecked, onCheckboxChange } = this.props;

    return (
      <div className='checkbox' key={id}>
        <label htmlFor={id} className='checkbox-label'>
          {isChecked ? <CheckboxChecked fill={window.PRIMARY_COLOR} /> : <CheckboxUnchecked />}
          <input
            id={id}
            type='checkbox'
            className='checkbox-input'
            value={label}
            checked={isChecked}
            onChange={() => onCheckboxChange(id)}
          />
          {label}
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
};
