import { IconProps } from '.';

export const BlockIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.7465 14.8072C12.4536 15.8652 10.801 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 7.19905 2.13477 5.54635 3.19279 4.25345L13.7465 14.8072ZM14.8072 13.7465L4.25345 3.19279C5.54635 2.13477 7.19905 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 10.801 15.8652 12.4536 14.8072 13.7465ZM18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z'
        fill={color}
      />
    </svg>
  );
};


