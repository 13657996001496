import classNames from 'classnames';
import { ReactNode } from 'react';
import { fontWeights, Paragraph, paragraphSizes } from '../typography';

type IllustrationButtonProps = {
  icon: (classes: string) => ReactNode;
  title: string;
  subtitle: string;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
};

export const IllustrationButton = ({
  icon,
  title,
  subtitle,
  disabled,
  className,
  ...props
}: IllustrationButtonProps) => {
  const classes = classNames(
    'rounded-21 p-6 group flex justify-start items-center cursor-pointer text-left divide-transparent divide-x-18',
    {
      'bg-primary-lighter-06 hover:bg-primary-lighter-13 text-primary active:bg-blue-sail focus:bg-blue-link-water focus:ring-2 focus:ring-primary focus:ring-inset':
        !disabled,
      'bg-white-snow hover:bg-white-snow focus:bg-white-snow active:bg-white-snow text-gray-silver': disabled,
    },
    className,
  );

  const iconClasses = classNames({
    'fill-primary hover:fill-primary': !disabled,
    'fill-gray-silver': disabled,
  });

  return (
    <button type='button' className={classes} {...props}>
      <div>{icon(iconClasses)}</div>
      <div className='transition-translate duration-200 transform translate-y-1/4 group-hover:translate-y-0 group-active:translate-y-0'>
        <Paragraph size={paragraphSizes.large} weight={fontWeights.semibold}>
          {title}
        </Paragraph>
        <Paragraph
          size={paragraphSizes.normal}
          weight={fontWeights.medium}
          className='transition-opacity ease-linear delay-100 duration-200 opacity-0 group-hover:opacity-100 group-active:opacity-100'
        >
          {subtitle}
        </Paragraph>
      </div>
    </button>
  );
};
