import { getWindowWidth } from '@va/dashboard/selectors/ui';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UpgradeToHigherPackageBadge from '../UpgradeToHigherPackageBadgeContent';
import './UpgradeToHigherPackageBadgePreHeader.scss';

function UpgradeToHigherPackageBadgePreHeader(props) {
  return (
    <div className={'upgrade-to-advanced-badge-header'}>
      <UpgradeToHigherPackageBadge />
    </div>
  );
}

const mapStateToProps = (state) => ({
  windowWidth: getWindowWidth(state),
});

UpgradeToHigherPackageBadgePreHeader.propTypes = {
  windowWidth: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(UpgradeToHigherPackageBadgePreHeader);
