import { Formik } from 'formik';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { number, object, string } from 'yup';

import { modalName } from '@va/constants';
import { updateSettings } from '@va/dashboard/actions/api';
import { addNotification, setCardView } from '@va/dashboard/actions/ui';
import { getAccountInformation } from '@va/dashboard/selectors/api';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { getWebsite } from '@va/dashboard/selectors/core';
import { isModalOpen as modalOpen } from '@va/dashboard/selectors/ui';
import PopUpCard from '@va/deprecated/components/PopUpCard';
import LoadingPopUpCard from '@va/deprecated/components/PopUpCard/card-types/LoadingCard';
import { useTranslate } from '@va/localization';
import {
  createCustomerSucceeded,
  getCustomer,
  signUpPromoter,
  updateCustomerSucceeded,
} from '@va/standalone/shared/actions';
import { createCustomer, updateCustomer } from '@va/standalone/shared/api-client/billing';
import { firstPromoterCookie, upgradeModalView } from '@va/standalone/shared/constants';
import { getApiRequest } from '@va/standalone/shared/selectors';
import { getCookie, isCountryCode } from '@va/util/helpers';
import ModalFooter from 'standalone/components/modals/common/components/modal-footer';
import CustomerInfoFields from './CustomerInfoFields';

const CustomerInfoForm = ({ hasBackButton, backToInitialScreen }) => {
  const translate = useTranslate();
  const instanceId = useSelector(getInstanceId);
  const website = useSelector(getWebsite);
  const accountInformation = useSelector(getAccountInformation);

  const isUpgradeModalOpen = useSelector((state) => modalOpen(state, modalName.upgrade));
  const customer = useSelector((state) => getApiRequest(state, 'getCustomer'));
  const customerId = customer.get('id');
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (values) => {
      const flow = customerId
        ? updateCustomerFlow(instanceId, customerId, values)
        : createCustomerFlow(instanceId, values);
      dispatch(updateSettings(Immutable.Map({ businessSector: values.businessSector }))); //BusinessSector value is stored on website.

      return flow
        .then(() => {
          if (customerId) {
            dispatch(updateCustomerSucceeded());
          } else {
            dispatch(createCustomerSucceeded());
            if (getCookie(firstPromoterCookie.CODE)) {
              dispatch(signUpPromoter());
            }
          }
          dispatch(setCardView(modalName.upgrade, upgradeModalView.CHECKOUT_FORM, upgradeModalView.CUSTOMER_INFO_FORM));
          dispatch(getCustomer());
        })
        .catch(() => {
          dispatch(
            addNotification({
              title: 'notifications.generalError',
              level: 'error',
              autoDismiss: 10,
            }),
          );
        });
    },
    [instanceId],
  );

  const formInitialValues = useMemo(() => {
    if (customer.get('id'))
      return {
        businessSector: isUpgradeModalOpen ? website.businessSector : parseInt(customer.get('businessSector')),
        companyName: customer.get('companyName') || website.companyName,
        country: customer.get('country'),
        address: customer.get('address'),
        firstName: customer.get('firstName'),
        lastName: customer.get('lastName'),
      };
    return {
      businessSector: isUpgradeModalOpen ? website.businessSector : null,
      companyName: website.companyName,
      firstName: accountInformation.firstName,
      lastName: accountInformation.lastName,
      country: '',
      address: '',
    };
  }, [customer]);

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      validationSchema={schema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({
        handleSubmit,
        submitForm,
        isSubmitting,
        isValid,
        setFieldTouched,
        errors,
        touched,
        values,
        handleChange,
        setFieldValue,
      }) =>
        isSubmitting ? (
          <LoadingPopUpCard />
        ) : (
          <form
            name='customer-info'
            onSubmit={handleSubmit}
            className='custom-plan-form flex flex-col h-full cancel-parent-padding'
          >
            <PopUpCard
              type={'card-grey'}
              title={translate('upgradeModal.standalone.title.checkout')}
              colorTheme={'no-theme'}
              footerButtons={
                <ModalFooter
                  submitBtnText={translate('button.continue')}
                  cancelBtnText={translate('button.back')}
                  onCancelClick={backToInitialScreen}
                  isSubmitDisabled={!isValid}
                  setFieldTouched={setFieldTouched}
                  onSubmitClick={submitForm}
                />
              }
              hasBackButton={hasBackButton}
            >
              <CustomerInfoFields
                errors={errors}
                values={values}
                handleChange={handleChange}
                touched={touched}
                setFieldValue={setFieldValue}
              />
            </PopUpCard>
          </form>
        )
      }
    </Formik>
  );
};

const schema = object().shape({
  companyName: string().required('form.requiredField'),
  businessSector: number().typeError('form.requiredField').required('form.requiredField'),
  country: string()
    .test('isValidCountryCode', 'form.requiredField', (value) => {
      return isCountryCode(value);
    })
    .required('form.requiredField'),
  address: string().required('form.requiredField'),
  firstName: string().required('form.requiredField'),
  lastName: string().required('form.requiredField'),
});

CustomerInfoForm.propTypes = {
  backToInitialScreen: PropTypes.func.isRequired,
  hasBackButton: PropTypes.bool,
};

function createCustomerFlow(instanceId, values) {
  return Promise.resolve({}).then(() => {
    return createCustomer(values, instanceId);
  });
}

function updateCustomerFlow(instanceId, customerId, values) {
  return Promise.resolve({}).then(() => {
    return updateCustomer(values, instanceId, customerId);
  });
}

export default CustomerInfoForm;
