import { SessionStorage } from '@va/util/helpers';
import { useCallback } from 'react';

export enum FeaturePaginationKey {
  'recordings' = 'recordings',
  'latestVisitors' = 'latestVisitors',
  'funnels' = 'funnels',
  'autoEventTracking' = 'autoEventTracking',
  'manualEventTracking' = 'manualEventTracking',
  'alarmingBehaviorEvent' = 'alarmingBehaviorEvent',
  'heatmaps' = 'heatmaps',
  'clientsListTable' = 'clientsListTable',
}

const paginationStorageKeys = {
  pageSize(featureKey: FeaturePaginationKey) {
    return `visa-page-size-feature-${featureKey}`;
  },
};

export const useSessionStoragePagination = (featureKey: FeaturePaginationKey) => {
  const getStoredPageSize = useCallback(() => {
    const storageItem = SessionStorage.getItem(paginationStorageKeys.pageSize(featureKey));
    try {
      if (storageItem && typeof JSON.parse(storageItem) === 'number') {
        return JSON.parse(storageItem);
      }
    } catch (error) {
      console.error(error);
    }
    return undefined;
  }, [featureKey]);

  const storePageSize = useCallback(
    (pageSize: number) => {
      SessionStorage.setItem(paginationStorageKeys.pageSize(featureKey), JSON.stringify(pageSize));
    },
    [featureKey],
  );
  return { getStoredPageSize, storePageSize };
};
