import classNames from 'classnames';
import React from 'react';
import { IconProps } from '.';

export const LogoutIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='20'
      height='17'
      viewBox='0 0 20 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.9828 1.91021L2.99563 3.23926C2.38312 3.44344 1.96997 4.01664 1.96997 4.66229V12.3377C1.96997 12.9834 2.38312 13.5566 2.99563 13.7608L6.9828 15.0898C7.46845 15.2517 7.96997 14.8902 7.96997 14.3783V2.62172C7.96997 2.1098 7.46845 1.74833 6.9828 1.91021ZM2.52129 1.81624C1.29626 2.22458 0.469971 3.371 0.469971 4.66229V12.3377C0.469971 13.629 1.29626 14.7754 2.52129 15.1838L6.50846 16.5128C7.92661 16.9856 9.38987 15.9707 9.4668 14.5H10.22C11.4626 14.5 12.47 13.4927 12.47 12.25V11.5H10.97V12.25C10.97 12.6642 10.6342 13 10.22 13H9.46997V4.00001H10.22C10.6342 4.00001 10.97 4.3358 10.97 4.75001V5.50001H12.47V4.75001C12.47 3.50737 11.4626 2.50001 10.22 2.50001H9.4668C9.38987 1.02932 7.92661 0.0144669 6.50846 0.487183L2.52129 1.81624ZM19.0003 7.96968L16.0003 4.96968L14.9396 6.03034L16.6593 7.75001H10.97V9.25001H16.6593L14.9396 10.9697L16.0003 12.0303L19.0003 9.03034L19.5306 8.50001L19.0003 7.96968ZM5.71997 9.25001C6.13418 9.25001 6.46997 8.91423 6.46997 8.50001C6.46997 8.0858 6.13418 7.75001 5.71997 7.75001C5.30576 7.75001 4.96997 8.0858 4.96997 8.50001C4.96997 8.91423 5.30576 9.25001 5.71997 9.25001Z'
        fill={color}
      />
    </svg>
  );
};


