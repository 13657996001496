import { tabNames } from '@va/constants';
import { PageContent } from '@va/dashboard/shared/ui-layout';
import { useActiveTab } from '@va/dashboard/util-hooks';
import AccountDetails from './account-details';
import './style.scss';

const AccountSettings = () => {
  useActiveTab(tabNames.accountSettings);

  return (
    <PageContent className='account-tab'>
      <AccountDetails />
    </PageContent>
  );
};

export default AccountSettings;
