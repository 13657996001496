import classNames from 'classnames';
import { IconProps } from '.';

export const RefreshIcon = ({ className, color }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 2.75C7.56608 2.75 6.16437 3.17521 4.97212 3.97185C3.77986 4.76849 2.85061 5.90078 2.30187 7.22555C1.75314 8.55031 1.60956 10.008 1.88931 11.4144C2.16905 12.8208 2.85955 14.1126 3.87348 15.1265C4.88741 16.1405 6.17923 16.831 7.5856 17.1107C8.99196 17.3904 10.4497 17.2469 11.7745 16.6981C13.0992 16.1494 14.2315 15.2201 15.0282 14.0279C15.8248 12.8356 16.25 11.4339 16.25 10'
        stroke={color ?? 'var(--color-primary)'}
        strokeWidth='1.5'
      />
      <path d='M13.5 3L9 6L9 -1.96701e-07L13.5 3Z' fill={color ?? 'var(--color-primary)'} />
    </svg>
  );
};


