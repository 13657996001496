import { LeftCircleArrowIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { setActiveWebsite } from '@va/standalone/shared/actions';
import { getLastWebsiteId } from '@va/standalone/shared/helpers';
import { Button } from '@va/ui/design-system';
import { Link } from '@va/util/router';
import { useDispatch } from 'react-redux';

export const GoToLastWebsiteButton = () => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  return (
    <Link
      to={'/'}
      onClick={() => {
        dispatch(setActiveWebsite(getLastWebsiteId(), false, false));
      }}
    >
      <Button
        color='tertiary'
        icon={(iconClassName) => <LeftCircleArrowIcon className={iconClassName} color={'#696969'} />}
        text={translate('topBar.goBackBtn')}
        className='shrink-0'
      />
    </Link>
  );
};
