import { IconProps } from '.';

export const TrackingCodeIcon = ({ className = '', color = '#000000' }: IconProps) => {
  return (
    <svg
      width='18'
      height='13'
      viewBox='0 0 18 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2734 0.309998L13.9014 5.75196H18V7.25196H13.0986L10.7266 3.69391L7.72658 12.6939L4.09861 7.25196H0V5.75196H4.90139L7.27342 9.31L10.2734 0.309998Z'
        fill={color}
      />
    </svg>
  );
};
