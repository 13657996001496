import * as Api from '@va/standalone/shared/api-client';
import { signUpPromoterFailed, signUpPromoterSucceeded } from '@va/standalone/shared/actions';

import { getInstanceId } from '@va/dashboard/selectors/app';
import { FIRST_PROMOTER_SIGN_UP } from '@va/standalone/shared/actions';
import { firstPromoterCookie } from '@va/standalone/shared/constants';
import { getCookie } from '@va/util/helpers';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

export function* watchers() {
  yield all([takeLatest(FIRST_PROMOTER_SIGN_UP, signUpPromoter)]);
}

function* signUpPromoter() {
  try {
    const websiteId = yield select(getInstanceId);
    let refId = getCookie(firstPromoterCookie.CODE);
    //Take only the refIf value from cookie.
    refId = refId.replace('_r_', '');
    const data = yield call(Api.signUpPromoter, websiteId, refId);
    yield put(signUpPromoterSucceeded(data));
  } catch (error) {
    yield put(signUpPromoterFailed(error));
  }
}
