import classNames from 'classnames';
import { IconProps } from '.';

export const FilterIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={classNames(className)}
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.75 5V1.25C9.75 0.835786 9.41421 0.5 9 0.5C8.58579 0.5 8.25 0.835786 8.25 1.25V5H7.5C7.08579 5 6.75 5.33579 6.75 5.75C6.75 6.16421 7.08579 6.5 7.5 6.5H9H10.5C10.9142 6.5 11.25 6.16421 11.25 5.75C11.25 5.33579 10.9142 5 10.5 5H9.75ZM1.5 2.75C1.5 2.33579 1.83579 2 2.25 2C2.66421 2 3 2.33579 3 2.75V8.75C3 9.16421 2.66421 9.5 2.25 9.5C1.83579 9.5 1.5 9.16421 1.5 8.75V2.75ZM3 12.5H3.75C4.16421 12.5 4.5 12.1642 4.5 11.75C4.5 11.3358 4.16421 11 3.75 11H2.25H0.75C0.335786 11 0 11.3358 0 11.75C0 12.1642 0.335787 12.5 0.75 12.5H1.5V16.25C1.5 16.6642 1.83579 17 2.25 17C2.66421 17 3 16.6642 3 16.25V12.5ZM15 2.75C15 2.33579 15.3358 2 15.75 2C16.1642 2 16.5 2.33579 16.5 2.75V8.75C16.5 9.16421 16.1642 9.5 15.75 9.5C15.3358 9.5 15 9.16421 15 8.75V2.75ZM17.25 12.5H16.5V16.25C16.5 16.6642 16.1642 17 15.75 17C15.3358 17 15 16.6642 15 16.25V12.5H14.25C13.8358 12.5 13.5 12.1642 13.5 11.75C13.5 11.3358 13.8358 11 14.25 11H15.75H17.25C17.6642 11 18 11.3358 18 11.75C18 12.1642 17.6642 12.5 17.25 12.5ZM9 18.5C9.41421 18.5 9.75 18.1642 9.75 17.75V8.75C9.75 8.33579 9.41421 8 9 8C8.58579 8 8.25 8.33579 8.25 8.75V17.75C8.25 18.1642 8.58579 18.5 9 18.5Z'
        fill={color}
        fillOpacity='0.8333'
      />
    </svg>
  );
};


