import { LogLevel } from '@twipla/worker';
import { isStandaloneApp, isWixApp, isWixMiniApp } from '@va/util/helpers';
import Config from 'Config';
import BaseWorker from '../base-worker/BaseWorker';
import { rewriteUrl, rewriteWixMiniUrl } from '../helpers';

/**
 * Worker implementation for tracking app.visitor-analytics.io / app.twipla.com
 */
class DashboardAppWorker extends BaseWorker {
  private static instance: DashboardAppWorker;

  private constructor() {
    super({
      websiteId: Config.dashboardWebsiteId,
      logLevel: LogLevel.ERROR,
      trackUrlChanges: true,
      rewriteUrl: isWixMiniApp() ? rewriteWixMiniUrl : rewriteUrl,
      enabled: isStandaloneApp() || isWixApp() || isWixMiniApp(),
      usePolls: true,
    });
  }

  public static getInstance() {
    if (!DashboardAppWorker.instance) {
      DashboardAppWorker.instance = new DashboardAppWorker();
    }
    return DashboardAppWorker.instance;
  }
}

export default DashboardAppWorker.getInstance();
