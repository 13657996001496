import {
  ColumnSort,
  GroupingState,
  InitialTableState,
  PaginationState,
  RowSelectionState,
  SortingState,
} from '@tanstack/react-table';
import { deepEquals } from 'nx/src/utils/json-diff';
import { useCallback, useMemo, useState } from 'react';

export type ControlledTableState = {
  rowSelection: RowSelectionState;
  setPagination: (value: ((prevState: PaginationState) => PaginationState) | PaginationState) => void;
  pagination: { pageNumber: number; pageIndex: number; pageSize: number };
  setSorting: (value: ((prevState: ColumnSort[]) => ColumnSort[]) | ColumnSort[]) => void;
  setGrouping: (value: ((prevState: string[]) => string[]) | string[]) => void;
  sorting: ColumnSort[];
  setRowSelection: (value: ((prevState: RowSelectionState) => RowSelectionState) | RowSelectionState) => void;
  grouping: string[];
};

export const useControlledTableState = (initialState: InitialTableState = {}): ControlledTableState => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: initialState.pagination?.pageIndex ?? 0,
    pageSize: initialState.pagination?.pageSize ?? 12,
  });

  const pageNumber = useMemo(() => pagination.pageIndex + 1, [pagination.pageIndex]);

  const [grouping, setGrouping] = useState<GroupingState>(initialState.grouping ?? []);
  const [sorting, setSorting] = useState<SortingState>(initialState.sorting ?? []);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(initialState.rowSelection ?? {});

  const setPaginationMemoized = useCallback(
    (value: PaginationState | ((prevState: PaginationState) => PaginationState)) => {
      const val = typeof value === 'function' ? value(pagination) : value;

      if (deepEquals(val, pagination)) {
        // Prevent unnecessary setPagination calls which in turn cause unnecessary re-renders
        return;
      }
      setPagination(val);
    },
    [pagination],
  );

  return {
    pagination: { ...pagination, pageNumber },
    setPagination: setPaginationMemoized,
    grouping,
    setGrouping,
    sorting,
    setSorting,
    rowSelection,
    setRowSelection,
  };
};
