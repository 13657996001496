import { countryCodes } from '@va/assets';
import { useTranslate } from '@va/localization';
import { SelectDropdown, TextInput } from '@va/ui/design-system';
import { getBusinessSectorOptions } from '@va/util/helpers';
import { isNil } from 'lodash';
import React, { useCallback, useMemo } from 'react';

const formFieldNames = {
  companyName: 'companyName',
  country: 'country',
  lastName: 'lastName',
  firstName: 'firstName',
  address: 'address',
  businessSector: 'businessSector',
};

type CustomerInfoValuesType = {
  companyName: string;
  country: string;
  lastName: string;
  firstName: string;
  address: string;
  businessSector: string | number;
};

type CustomerInfoFieldsProps = {
  errors: Record<string, string>;
  touched: Record<string, string>;
  values: CustomerInfoValuesType;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

const CustomerInfoFields: React.FC<CustomerInfoFieldsProps> = ({
  errors,
  touched,
  values,
  handleChange,
  setFieldValue,
}) => {
  const translate = useTranslate();

  const countryOptions = countryCodes.map((item) => ({
    label: item.name,
    value: item.alpha2,
  }));

  const businessSectorOptions = useMemo(() => getBusinessSectorOptions(translate), [translate]);

  const selectedBussinessSector = useMemo(() => {
    if (isNil(values.businessSector)) return undefined;
    return businessSectorOptions.find((option) => Number(option.value) === Number(values.businessSector));
  }, [businessSectorOptions, values.businessSector]);

  const selectedCountry = useMemo(
    () => countryOptions.find((option) => option.value === values.country),
    [countryOptions, values.country],
  );

  const clearField = useCallback(
    (fieldName: string) => {
      setFieldValue(fieldName, '');
    },
    [setFieldValue],
  );

  return (
    <div className='grid grid-cols-1 sm-initial:grid-cols-2 gap-5'>
      <TextInput
        required
        clearField={() => {
          clearField(formFieldNames.companyName);
        }}
        value={values.companyName}
        onChange={handleChange}
        label={translate('form.placeholders.businessName')}
        name={formFieldNames.companyName}
        error={touched.companyName ? translate(errors.companyName) : undefined}
        placeholder={translate('form.placeholders.businessName')}
        autoFocus
      />

      <SelectDropdown
        required
        buttonClassName='h-60'
        dropdownPlacement='bottom'
        onChange={(option) => {
          setFieldValue(formFieldNames.businessSector, option.value);
        }}
        selectedOption={selectedBussinessSector}
        label={translate('dropdown.competition.businessSector.label')}
        placeholderText={translate('dropdown.competition.businessSector.placeholder')}
        dropdownOptions={businessSectorOptions}
        error={touched.businessSector ? translate(errors.businessSector) : undefined}
      />

      <SelectDropdown
        required
        buttonClassName='h-60'
        dropdownPlacement='bottom'
        onChange={(option) => {
          setFieldValue(formFieldNames.country, option.value);
        }}
        selectedOption={selectedCountry}
        label={translate('form.placeholders.country')}
        placeholderText={translate('dropdown.competition.country.placeholder')}
        dropdownOptions={countryOptions}
        error={touched.country ? translate(errors.country) : undefined}
        comparisonType='match'
      />
      <TextInput
        required
        clearField={() => {
          clearField(formFieldNames.address);
        }}
        value={values.address}
        onChange={handleChange}
        label={translate('form.placeholders.address')}
        name={formFieldNames.address}
        error={touched.address ? translate(errors.address) : undefined}
        placeholder={translate('form.placeholders.address')}
      />

      <TextInput
        required
        clearField={() => {
          clearField(formFieldNames.firstName);
        }}
        value={values.firstName}
        onChange={handleChange}
        label={translate('form.placeholders.firstName')}
        name={formFieldNames.firstName}
        error={touched.firstName ? translate(errors.firstName) : undefined}
        placeholder={translate('form.placeholders.firstName')}
      />
      <TextInput
        required
        clearField={() => {
          clearField(formFieldNames.lastName);
        }}
        value={values.lastName}
        onChange={handleChange}
        label={translate('form.placeholders.lastName')}
        name={formFieldNames.lastName}
        error={touched.lastName ? translate(errors.lastName) : undefined}
        placeholder={translate('form.placeholders.lastName')}
      />
    </div>
  );
};

export default CustomerInfoFields;
