import { NegativeTagIcon } from '@va/icons';
import { useLocale } from '@va/localization';
import { addNumberSeparator } from '@va/util/helpers';

export function VisitorAlarmingActionCount({ count }: { count: number }) {
  const { locale } = useLocale();
  return (
    <span className='flex items-center gap-1 px-3px'>
      <NegativeTagIcon color='#f06651' />
      <span className='text-red-sienna'>{addNumberSeparator(count, locale)}</span>
    </span>
  );
}
