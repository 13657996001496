import {
  useBrowserOptions,
  useCompanyOrgType,
  useCountryOptions,
  useDeviceOptions,
  useNewDirectChannelFilter,
  useNewEmailChannelFilter,
  useNewOrganicChannelFilter,
  useNewPaidChannelFilter,
  useNewReferrerChannelFilter,
  useNewSocialChannelFilter,
  useOssOptions,
} from '@va/dashboard/shared/filters';
import { useTranslate } from '@va/localization';
import { MultiSelectFilter, TextFilter } from '@va/shared/feature-filters';
import { ApiOperators, FilterLogicalOperators, FilterOperators, buildNestedFiltersPage } from '@va/types/filters';
import { useMemo } from 'react';

export const useSharedDimensionsFilters = () => {
  const translate = useTranslate();

  const countryOptions = useCountryOptions();
  const deviceOptions = useDeviceOptions();
  const companyOrgTypeOptions = useCompanyOrgType();

  const browserNameFilter = useMemo(
    () =>
      new MultiSelectFilter({
        id: 'browserName',
        name: 'browserName',
        label: translate('filter.browserName'),
        inputProps: {
          showSearchInput: true,
          searchPlaceholder: translate('filters.placeholder.browserName'),
          useOptions: useBrowserOptions,
        },
        operator: FilterOperators.is,
        operatorOptions: [FilterOperators.is, FilterOperators.isNot],
      }),
    [translate],
  );
  const osFilter = useMemo(
    () =>
      new MultiSelectFilter({
        id: 'platform',
        name: 'platform',
        label: translate('filter.platform'),
        inputProps: {
          searchPlaceholder: translate('filters.placeholder.platform'),
          showSearchInput: true,
          useOptions: useOssOptions,
        },
        operator: FilterOperators.is,
        operatorOptions: [FilterOperators.is, FilterOperators.isNot],
      }),
    [translate],
  );

  const filters = useMemo(
    () => [
      new MultiSelectFilter({
        id: 'countryCode',
        name: 'countryCode',
        label: translate('filter.countryName'),
        inputProps: {
          useOptions: () => ({ data: countryOptions }),
          showSearchInput: true,
          searchPlaceholder: translate('filters.placeholder.countryName'),
        },
        operator: FilterOperators.is,
        operatorOptions: [FilterOperators.is, FilterOperators.isNot],
      }),
      browserNameFilter,
      new TextFilter({
        id: 'browserVersion',
        name: 'browserVersion',
        label: translate('filter.browserVersion'),
        inputProps: { placeholder: translate('filters.placeholder.browserVersion') },
        operator: FilterOperators.is,
        operatorOptions: [FilterOperators.is, FilterOperators.isNot],
      }),
      new TextFilter({
        id: 'ip',
        name: 'ip',
        label: translate('filter.ip'),
        inputProps: { placeholder: translate('filters.placeholder.ip') },
        operator: FilterOperators.contains,
        operatorOptions: [FilterOperators.contains, FilterOperators.doesNotContain],
      }),
      new MultiSelectFilter({
        id: 'deviceType',
        name: 'deviceType',
        label: translate('filter.deviceType'),
        inputProps: { useOptions: () => ({ data: deviceOptions }) },
        operator: FilterOperators.is,
        operatorOptions: [FilterOperators.is, FilterOperators.isNot],
      }),
      new TextFilter({
        id: 'cityName',
        name: 'cityName',
        label: translate('filter.cityName'),
        inputProps: { placeholder: translate('filters.placeholder.cityName') },
        operator: FilterOperators.contains,
        operatorOptions: [FilterOperators.contains, FilterOperators.doesNotContain],
      }),
      osFilter,
      new TextFilter({
        id: 'display',
        name: 'display',
        label: translate('filter.display'),
        inputProps: { placeholder: translate('filters.placeholder.display') },
        operator: FilterOperators.is,
        operatorOptions: [FilterOperators.is, FilterOperators.isNot],
      }),
      new TextFilter({
        id: 'companyOrgName',
        name: 'companyOrgName',
        label: translate('filter.companyOrgName'),
        inputProps: { placeholder: translate('filters.placeholder.companyOrgName') },
        operator: FilterOperators.contains,
        operatorOptions: [FilterOperators.contains, FilterOperators.doesNotContain],
      }),
      new MultiSelectFilter({
        id: 'companyOrgType',
        name: 'companyOrgType',
        label: translate('filter.companyOrgType'),
        inputProps: { useOptions: () => ({ data: companyOrgTypeOptions }) },
      }),
    ],
    [browserNameFilter, companyOrgTypeOptions, countryOptions, deviceOptions, osFilter, translate],
  );

  return filters;
};

export const usePageUrlFilter = ({ allowMultiSelect = false }: { allowMultiSelect?: boolean } = {}) => {
  const translate = useTranslate();
  const filter = useMemo(
    () =>
      new TextFilter({
        id: 'pageUrl',
        name: 'pageUrl',
        label: translate('filter.pageUrl'),
        inputProps: {
          placeholder: (operator) => {
            if (operator === FilterOperators.is) {
              return translate('filters.placeholder.completePageUrl');
            }
            return translate('filters.placeholder.pageUrl');
          },
        },
        allowMultiSelect: allowMultiSelect,
        operator: FilterOperators.contains,
        operatorOptions: [FilterOperators.contains, FilterOperators.doesNotContain, FilterOperators.is],
        operatorMapper: (operator) => {
          switch (operator) {
            case FilterOperators.contains:
              return ApiOperators.in;
            case FilterOperators.doesNotContain:
              return ApiOperators.notIn;
            case FilterOperators.is:
              return ApiOperators.eq;
            default:
              return ApiOperators.in;
          }
        },
      }),
    [allowMultiSelect, translate],
  );

  return filter;
};

export const useTrafficChannelFilters = () => {
  const translate = useTranslate();
  const direct = useNewDirectChannelFilter();
  const social = useNewSocialChannelFilter();
  const organic = useNewOrganicChannelFilter();
  const paid = useNewPaidChannelFilter();
  const referrals = useNewReferrerChannelFilter();
  const email = useNewEmailChannelFilter();

  return buildNestedFiltersPage({
    label: translate('filters.labels.trafficChannel'),
    filters: [direct, email, paid, organic, social, referrals],
    id: 'traffic-channel-filters',
    childrenLogicalOperator: FilterLogicalOperators.or,
  });
};
