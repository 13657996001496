import { SILVER } from '@va/constants';
import { CLOSE_MODAL } from '@va/dashboard/actions/ui';
import { isModalOpen as modalOpen } from '@va/dashboard/selectors/ui';
import ModalWrapper from '@va/dashboard/shared/modals/ModalWrapper';
import PopUpCard from '@va/deprecated/components/PopUpCard';
import { LocationIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { modalName } from '@va/standalone/shared/constants';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommissionPayoutsDetails from './commission-payouts-details';
import './CommissionPayouts.scss';

const CommissionPayouts = () => {
  const isModalOpen = useSelector((state) => modalOpen(state, modalName.COMMISSION_PAYOUTS));
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [annualCommissionPayout, setAnnualCommissionPayout] = useState(0);

  function handleClose() {
    dispatch({ type: CLOSE_MODAL, modalName: modalName.COMMISSION_PAYOUTS });
  }

  return (
    <ModalWrapper
      isOpen={isModalOpen}
      closeModal={handleClose}
      showCloseButton={false}
      className={'commission-payouts-container'}
      showCloseIconWithBorder
    >
      <PopUpCard
        type='card-grey'
        title={translate('modal.agency.commissionPayouts.title')}
        colorTheme={'no-theme'}
        smallerBottomSpace
        noPadding
        noFooterMargin
      >
        <div className='commission-payouts-content'>
          <div className='frequency-and-total-payouts-wrapper flex flex-column md:flex-row'>
            <FrequencyAndTotalPayoutSection
              titleTransKey={'agency.commissionPayouts.frequency.label'}
              value={translate('option.btn.yearly')}
              className='w-full md:w-2/5'
            />
            <FrequencyAndTotalPayoutSection
              titleTransKey={'agency.commissionPayouts.annualCommissionPayouts.label'}
              value={`${annualCommissionPayout}$`}
              className='w-full md:w-3/5 border-t-2 md:border-t-0 md:border-l-2 border-solid border-gray-concrete'
            />
          </div>
          <CommissionPayoutsDetails setAnnualCommissionPayout={setAnnualCommissionPayout} />
          <Address />
        </div>
      </PopUpCard>
    </ModalWrapper>
  );
};

export default CommissionPayouts;

const FrequencyAndTotalPayoutSection = (props) => {
  const translate = useTranslate();
  const { className, titleTransKey, value } = props;
  return (
    <div className={classNames('frequency-and-total-payouts ', className)}>
      <div className='flex flex-column bg-gray-concrete h-full py-4 md:py-8 px-12'>
        <div className='font-normal text-lg leading-normal text-gray-devil flex justify-center md:justify-start'>
          {translate(titleTransKey)}
        </div>
        <div className='font-semibold text-21 leading-30 text-mine-shaft flex justify-center md:justify-start'>
          {value}
        </div>
      </div>
    </div>
  );
};

FrequencyAndTotalPayoutSection.propTypes = {
  className: PropTypes.string,
  titleTransKey: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const Address = memo(() => {
  const translate = useTranslate();

  return (
    <div className='flex flex-column p-10'>
      <div className='font-semibold text-lg leading-normal text-gray-devil'>
        {translate('agency.commissionPayouts.accessRevenue.title')}
      </div>
      <div className='font-normal text-15 leading-normal text-gray-devil mt-1'>
        {translate('agency.commissionPayouts.accessRevenue.info')}
      </div>
      <div className='flex mt-4 items-center'>
        <div>
          <LocationIcon color={SILVER} />
        </div>
        <div className='ml-4 font-medium text-lg leading-normal text-mine-shaft'>
          {translate('agency.commissionPayouts.accessRevenue.address')}
        </div>
      </div>
    </div>
  );
});
