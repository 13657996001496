import { VideoCameraIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Banner, fontWeights, Paragraph } from '@va/ui/design-system';
import { useUpdateSsrSettings } from '@va/dashboard/modules/recordings/settings';
import { useAddNotification, useToggleRecordings } from '@va/dashboard/util-hooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSsrSettingsSucceeded } from '@va/dashboard/actions/api';
import { getSupportCenterDomain } from '@va/dashboard/selectors/customization';

export default function EnableSessionRecordingsBanner() {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const { isToggleRecordingsPaused, ssrSettingsResponse } = useToggleRecordings();
  const { execute, isSucceeded } = useUpdateSsrSettings();
  const { showSuccessNotification, showErrorNotification } = useAddNotification();
  const supportCenterDomain = useSelector(getSupportCenterDomain);

  const activateRecordings = async () => {
    try {
      const newSettings = { ...ssrSettingsResponse, paused: false };
      await execute(newSettings);
      dispatch(getSsrSettingsSucceeded(newSettings));
      showSuccessNotification(5000, translate('notifications.recordings.activated'));
    } catch (e) {
      showErrorNotification();
    }
  };

  useEffect(() => {
    if (!isSucceeded) return;
  }, [isSucceeded]);

  if (!isToggleRecordingsPaused) return null;

  return (
    <Banner wrapperClassName='mb-7' icon={<VideoCameraIcon color='var(--color-gray-charcoal)' />}>
      <Paragraph weight={fontWeights.medium}>
        {translate('all.eventTracking.alarmingBehaviorEvents.enableSessionRecordingsBannerTitle')}
      </Paragraph>
      <div className='mt-6px flex items-center gap-3'>
        <button onClick={activateRecordings} className='text-12 font-medium'>
          {translate('all.eventTracking.alarmingBehaviorEvents.enableSessionRecordings')}
        </button>
        <a
          href={translate('all.eventTracking.alarmingBehaviorEvents.linkTo.howItWorks.link', { supportCenterDomain })}
          className='text-12 font-medium'
          target='_blank'
          rel='noopener noreferrer'
        >
          {translate('all.eventTracking.alarmingBehaviorEvents.howItWorks.label')}
        </a>
      </div>
    </Banner>
  );
}
