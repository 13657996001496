import PropTypes from 'prop-types';
import { BLACK_COLOR, GREEN_COLOR, WHITE_COLOR } from '../../constants';

export function ScrollActivityRule(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 128 86' className={props.className}>
      <defs>
        <filter id='a' width='190.9%' height='115.6%' x='-45.5%' y='-7.8%' filterUnits='objectBoundingBox'>
          <feOffset dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
          <feColorMatrix
            in='shadowOffsetOuter1'
            result='shadowMatrixOuter1'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.179960664 0'
          />
          <feMerge>
            <feMergeNode in='shadowMatrixOuter1' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>
      <g fill='none' fillRule='evenodd' opacity={props.active ? '1' : '.6'}>
        <path fill={WHITE_COLOR} d='M.855 85.926H127.34V.859H.855z' />
        <path fill={BLACK_COLOR} d='M0 85.926h127.341V0H0v85.926zm1.785-1.791h123.771V1.789H1.786v82.346z' />
        <path fill='#000' d='M23 23h89V10H23v13zm2-2h85v-9H25v9z' />
        <path fill={WHITE_COLOR} d='M10.722 9h5v64h-5z' />
        <path fill={WHITE_COLOR} d='M13.5 7.722l7.778 7.778-7.778 7.778L5.722 15.5z' />
        <g
          stroke={props.active ? GREEN_COLOR : '#1E2E35'}
          strokeWidth='2'
          filter='url(#a)'
          transform='translate(8 10.722)'
        >
          <path d='M5.474.237V63.9M10.5 5.5L5.42.5.5 5.5M10.5 58.5l-5.08 5-4.92-5' />
        </g>
        <path fill='#000' d='M23 30.074h16v-2.578H23zM23 36.948h23V34.37H23z' />
        <path
          fill={BLACK_COLOR}
          d='M42.677 57.57l-7.214 10.34-4.543-3.265-8.7 9.251h23.93V61.38l-3.473-3.81zm.19 2.964l1.443 1.582v9.906H26.533l4.62-4.915 3.248 2.334 1.5 1.078 1.063-1.524 5.903-8.46z'
        />
        <path
          fill={props.active ? '#00796B' : '#4A4A4A'}
          d='M45.903 62.111l4.104-6.032 9.02 11.381 7.104-10.47 9.584.228 11.162-18.551 9.697 12.974v21.396h-58.97z'
        />
        <g fill={props.active ? GREEN_COLOR : '#9B9B9B'}>
          <path d='M35.49 73.758l10.361-12.234 14.765 10.638 15.541-12.5 6.735 2.927L97.14 45.834l13.987 27.924z' />
        </g>
      </g>
    </svg>
  );
}

ScrollActivityRule.propTypes = {
  className: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
};
