import { CommonProps } from '@va/types/component';
import { fontWeightClassName, fontWeights, paragraphSizeClassNames, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import { ReactElement, ReactNode, useMemo } from 'react';

type VisitDetailsProps = CommonProps & {
  icon: ReactNode;
  tooltipText?: ReactElement | string;
  tooltipSubtext?: ReactElement | string;
  size?: 'small' | 'medium';
  squareIcon?: boolean;
};

export function VisitDetails({
  icon,
  tooltipText,
  tooltipSubtext,
  size = 'medium',
  squareIcon,
  className,
  ...rest
}: VisitDetailsProps) {
  const isTooltipDisabled = useMemo(() => {
    if (tooltipText || tooltipSubtext) return false;
    return true;
  }, [tooltipSubtext, tooltipText]);

  const tooltipContent = () => {
    return (
      <div className='flex flex-col '>
        {tooltipText && <div className='text-10.5 flex justify-center font-medium leading-15'>{tooltipText}</div>}
        {tooltipSubtext && (
          <div
            className={classNames(
              'flex justify-center',
              fontWeightClassName(fontWeights.medium),
              paragraphSizeClassNames(paragraphSizes.normal),
            )}
          >
            {tooltipSubtext}
          </div>
        )}
      </div>
    );
  };

  return (
    // TODO maybe hide tooltips on mobile?
    <TooltipWrapper
      disabled={isTooltipDisabled}
      tooltipClassNames='!rounded-10.5 px-18.5 py-10.5'
      content={tooltipContent()}
    >
      <div
        data-testid={rest['data-testid']}
        className={classNames(
          'shrink-0 bg-white-snow group-hover:bg-white hover:bg-white flex justify-center items-center',
          {
            'w-9 h-9': size === 'small',
            'w-12 h-12': size === 'medium',
            'rounded-9px': squareIcon,
            'rounded-full': !squareIcon,
          },
          className,
        )}
      >
        <div className='shrink-0'>{icon}</div>
      </div>
    </TooltipWrapper>
  );
}
