import { tabNames } from '@va/constants';
import { startUpgradeProcess } from '@va/dashboard/actions/app';
import { getApiRequest } from '@va/dashboard/selectors/api';
import { getCurrentPlanName } from '@va/dashboard/selectors/app';
import { getActiveTab } from '@va/dashboard/selectors/ui';
import { useFeatureCounterContext } from '@va/dashboard/shared/feature-counter';
import GradientSplitBarChart from '@va/deprecated/components/charts/gradientSplitBarChart';
import { useLocale, useTranslate } from '@va/localization';
import { subscriptionStatus } from '@va/standalone/shared/constants';
import { hasPermission, hasUserRole } from '@va/standalone/shared/selectors';
import { UserRole } from '@va/types/website';
import { Button } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { addNumberSeparator } from '@va/util/helpers';
import * as d3 from 'd3';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './UpgradeAccountNav.scss';

const tabsToShowUpgradeInNavBar = [
  tabNames.recordings,
  tabNames.heatmaps,
  tabNames.funnels,
  tabNames.polls,
  tabNames.surveys,
  tabNames.eventTracking,
];

const UpgradeAccountNav: React.FC<{}> = (props) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const activeTab = useSelector(getActiveTab);
  const hasUpgradePermission = useSelector(hasPermission);
  const featuresLimits: any = useFeatureCounterContext();
  const isDashboardContributor = useSelector(hasUserRole)(UserRole.DASHBOARD);

  const featurePermissions: any = useSelector((state: {}) => getApiRequest(state, 'getFeaturePermissions'));

  const unlimitedVoucher = featurePermissions?.unlimitedVoucher;
  const planName = useSelector((state) => getCurrentPlanName(state, subscriptionStatus.ACTIVE));

  const isActiveTabForShowUpgradeNav: boolean = tabsToShowUpgradeInNavBar.includes(activeTab);

  const currentTabValues = featuresLimits?.[activeTab];
  const featureLimit = currentTabValues?.limit ?? 0;
  const remainingFeatureLimitValue = featureLimit - currentTabValues?.count;

  function handleUpgradeModal() {
    if (!hasUpgradePermission) return;
    dispatch(startUpgradeProcess());
  }

  if (isDashboardContributor) return null;

  if (isActiveTabForShowUpgradeNav) {
    return (
      <NavFeaturePlanDetails
        planName={
          unlimitedVoucher
            ? translate('plan.voucherWithCode', {
                voucherCode: unlimitedVoucher.voucherCode,
              })
            : planName
        }
        remainingFeatureLimit={remainingFeatureLimitValue}
        activeTab={activeTab}
        limit={featureLimit}
        openUpgradeModal={handleUpgradeModal}
      />
    );
  }

  return null;

  // Removed due to VISA-11253; to be re-added later
  // return <NavUpgradeAccount openUpgradeModal={handleUpgradeModal} />;
};

export default memo(UpgradeAccountNav);

//Feature details
const NavFeaturePlanDetails: React.FC<{
  planName: string;
  remainingFeatureLimit: number;
  activeTab: string;
  limit: number;
  openUpgradeModal: (event: React.MouseEvent<HTMLElement>) => void;
}> = (props) => {
  const translate = useTranslate();
  const { planName, remainingFeatureLimit, activeTab, limit, openUpgradeModal } = props;
  const hasUpgradePermission = useSelector(hasPermission);
  const { locale } = useLocale();

  return (
    <div className='upgrade-account-nav-container current-plan'>
      <div className='nav-upgrade-content'>
        <div className='upgrade-account-plan-title'>{translate('newPlanDetails.yourPlan')} :</div>
        <div className='upgrade-account-plan-name'>{planName}</div>
        <div className='upgrade-account-remaining-limit'>
          {translate(`remaining.${activeTab}.limit`, {
            count: addNumberSeparator(remainingFeatureLimit, locale),
          })}
        </div>
        <GradientSplitBarChart
          chartId='splitChart'
          data={{
            limit: limit,
            remainingLimit: remainingFeatureLimit,
          }}
          width={216}
          height={12}
          colors={{
            chartColor: [window.PRIMARY_COLOR, d3.rgb(window.PRIMARY_COLOR).brighter(4)],
            backgroundColor: '#FFFFFF',
          }}
        />
        <Button
          className='bg-white w-full mt-18px'
          color='tertiary'
          text={translate('nav.upgrade.button')}
          onClick={openUpgradeModal}
          disabled={!hasUpgradePermission}
          tooltip={!hasUpgradePermission && translate('tooltips.contributorRole')}
        />
      </div>
    </div>
  );
};

const NavUpgradeAccount: React.FC<{ openUpgradeModal: (event: React.MouseEvent<HTMLElement>) => void }> = (props) => {
  const translate = useTranslate();
  const hasUpgradePermission = useSelector(hasPermission);
  const { openUpgradeModal } = props;

  return (
    <div className='upgrade-account-nav-container'>
      <div className='nav-upgrade-content'>
        <div className='upgrade-account-title'>{translate('nav.upgrade.title')}</div>
        <div className='upgrade-account-description'>{translate('nav.upgrade.description')}</div>
        <TooltipWrapper content={translate('tooltips.contributorRole')} disabled={hasUpgradePermission}>
          <div className='upgrade-account-btn' onClick={openUpgradeModal}>
            {translate('nav.upgrade.button')}
          </div>
        </TooltipWrapper>
      </div>
    </div>
  );
};
