import { IconProps } from '.';

export const ReelVideoIcon = ({ className, color = '#000000' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.25 1.5H15C15.7778 1.5 16.4173 2.092 16.4926 2.85L14.25 2.85V1.5ZM12.75 0H14.25H15C16.6569 0 18 1.34315 18 3V15C18 16.6569 16.6569 18 15 18H14.25H12.75H5.25H3.75H3C1.34315 18 0 16.6569 0 15V3C0 1.34315 1.34315 0 3 0H3.75H5.25H12.75ZM14.25 16.5H15C15.7778 16.5 16.4173 15.908 16.4926 15.15H14.25V16.5ZM14.25 13.65H16.5V11.55H14.25V13.65ZM14.25 10.05H16.5V7.95L14.25 7.95V10.05ZM14.25 6.45L16.5 6.45V4.35L14.25 4.35V6.45ZM12.75 16.5H5.25V9.75L12.75 9.75V16.5ZM12.75 8.25L5.25 8.25V1.5H12.75V8.25ZM3.75 16.5H3C2.2222 16.5 1.58266 15.908 1.50741 15.15H3.75V16.5ZM3.75 13.65H1.5V11.55H3.75V13.65ZM3.75 10.05H1.5V7.95L3.75 7.95V10.05ZM3.75 6.45L1.5 6.45V4.35L3.75 4.35V6.45ZM3.75 2.85L1.50741 2.85C1.58267 2.092 2.2222 1.5 3 1.5H3.75V2.85Z'
        fill={color}
      />
    </svg>
  );
};
