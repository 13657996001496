import { getProviderName } from '@va/dashboard/selectors/customization';
import { useTranslate } from '@va/localization';
import { subscriptionStatus } from '@va/standalone/shared/constants';
import { getActiveUntilDate } from '@va/standalone/shared/helpers';
import { useSelector } from 'react-redux';

const SubscriptionTooltip = (props) => {
  const { activeSubscription, timezone } = props;
  const translate = useTranslate();
  const providerName = useSelector(getProviderName);

  let date = getActiveUntilDate(activeSubscription, timezone);
  switch (activeSubscription && (activeSubscription.status || activeSubscription.get('status'))) {
    case subscriptionStatus.EXPIRED:
    case subscriptionStatus.CANCELED:
      return (
        <div className='tooltip card-tooltip plan-tooltip-container'>
          {translate('plan.expiredOrCanceled.tooltip', { providerName })}
        </div>
      );
    case subscriptionStatus.PAUSED:
      return (
        <div className='tooltip card-tooltip plan-tooltip-container'>
          {translate('plan.subscriptionPaused.tooltip')}
        </div>
      );
    case subscriptionStatus.DELETED:
      return (
        <div className='tooltip card-tooltip plan-tooltip-container'>
          {translate('plan.subscriptionDeleted.tooltip')}
        </div>
      );
    case subscriptionStatus.PAST_DUE:
      return <div className='tooltip card-tooltip plan-tooltip-container'>{translate('plan.pastDue.tooltip')}</div>;
    case subscriptionStatus.ACTIVE:
      return (
        <div className='tooltip card-tooltip plan-tooltip-container'>
          {translate('plan.activeUntil.tooltip', { date, providerName })}
        </div>
      );
    default:
      return null;
  }
};

export default SubscriptionTooltip;
