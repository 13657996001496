import { userActions } from '@va/constants';
import { CheckmarkIcon, EmptyCircleIcon, EmptyStarIcon, MinusIcon, MinusStarIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { useFiltersContext } from '@va/shared/feature-filters';
import { useConfirmationDialogContext } from '@va/shared/util-confirmation';
import { hasPermission as hasPermissionSelector } from '@va/standalone/shared/selectors';
import { BulkOperations, SelectActionDropdownOption } from '@va/ui/design-system';

import { isNil } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMarkSeenRecording, useStarRecordings, useUpdateBulkRecordings } from './apiClient';
import { useRecordingsContext } from './context';

import { DeleteSingleRecordingConfirmationDialog } from './DeleteSingleRecordingConfirmationDialog';
import { recordingsSelectOptionsEnum } from './types';
import { isWixMiniApp } from '@va/util/helpers';

type RecordingsBulkOperationsProps = {
  size?: 'small' | 'large';
};

export const RecordingsBulkOperations = ({ size = 'large' }: RecordingsBulkOperationsProps) => {
  const {
    selectedRecordingsIds,
    clearSelectedRecordingsIds,
    selectAllRecordings,
    selectedBulkOption,
    setSelectedBulkOption,
    totalCount,
  } = useRecordingsContext();
  const recordingsBulkActions = useRecordingsBulkActions();
  const { appliedFilterValues } = useFiltersContext<{ watched: number; starred: number }>();

  const translate = useTranslate();

  const selectOptions: SelectActionDropdownOption[] = useMemo(
    () => [
      {
        onClick: () => {
          selectAllRecordings();
          setSelectedBulkOption(recordingsSelectOptionsEnum.all);
        },
        text: translate('behaviourStatus.All', { count: totalCount }),
      },
    ],
    [totalCount, selectAllRecordings, setSelectedBulkOption, translate],
  );

  const actionOptions: SelectActionDropdownOption[] = useMemo(() => {
    const { star, unstar, markSeen, markUnseen, bulkDelete } = recordingsBulkActions;
    const { watched, starred } = appliedFilterValues;

    const possibleActions: Record<string, SelectActionDropdownOption | undefined> = {
      star: isNil(starred) || starred === 0 ? star : undefined,
      unstar: isNil(starred) || starred === 1 ? unstar : undefined,
      markSeen: isNil(watched) || watched === 0 ? markSeen : undefined,
      markUnseen: isNil(watched) || watched === 1 ? markUnseen : undefined,
    };

    // VISA-11435
    if (!isWixMiniApp()) {
      possibleActions.bulkDelete = bulkDelete;
    }

    return Object.values(possibleActions).filter((v) => !isNil(v)) as SelectActionDropdownOption[];
  }, [recordingsBulkActions, appliedFilterValues]);

  return (
    <BulkOperations
      allSelected={selectedBulkOption === recordingsSelectOptionsEnum.all}
      selectedItemsCount={
        selectedBulkOption === recordingsSelectOptionsEnum.all ? totalCount : selectedRecordingsIds.length
      }
      selectOptions={selectOptions}
      actionsOptions={actionOptions}
      clearSelectedItems={() => {
        clearSelectedRecordingsIds();
        setSelectedBulkOption(null);
      }}
      selectedOption={selectedBulkOption}
      size={size}
    />
  );
};

const useRecordingsBulkActions = () => {
  const { selectedRecordingsIds, selectedBulkOption, deleteBulk, deleteIndividual } = useRecordingsContext();

  const hasPermissionToEdit = useSelector((state) => hasPermissionSelector(state, userActions.Delete));
  const hasPermissionToDelete = useSelector((state) => hasPermissionSelector(state, userActions.Edit));

  const { confirm } = useConfirmationDialogContext();

  const { execute: updateBulkRecordings } = useUpdateBulkRecordings();

  const { execute: starRecordings } = useStarRecordings(selectedRecordingsIds);
  const { execute: markSeenRecordings } = useMarkSeenRecording(selectedRecordingsIds);

  const translate = useTranslate();
  const iconColor = useMemo(() => (hasPermissionToEdit ? '#999999' : '#696969'), [hasPermissionToEdit]);

  return {
    star: {
      onClick: () => {
        if (selectedBulkOption === recordingsSelectOptionsEnum.all) {
          updateBulkRecordings({ star: true });
        } else {
          starRecordings(true);
        }
      },
      text: translate('recordings.actions.multiple.star'),
      icon: () => <EmptyStarIcon color={iconColor} />,
      tooltip: !hasPermissionToEdit ? translate('tooltips.contributorRole') : undefined,
      disabled: !hasPermissionToEdit,
    },
    unstar: {
      onClick: () => {
        if (selectedBulkOption === recordingsSelectOptionsEnum.all) {
          updateBulkRecordings({ star: false });
        } else {
          starRecordings(false);
        }
      },
      text: translate('recordings.actions.multiple.unstar'),
      icon: () => <MinusStarIcon color={iconColor} />,
      tooltip: !hasPermissionToEdit ? translate('tooltips.contributorRole') : undefined,
      disabled: !hasPermissionToEdit,
    },
    markSeen: {
      onClick: () => {
        if (selectedBulkOption === recordingsSelectOptionsEnum.all) {
          updateBulkRecordings({ seen: true });
        } else {
          markSeenRecordings(true);
        }
      },
      text: translate('recordings.actions.markSeen'),
      icon: () => <CheckmarkIcon color={iconColor} />,
      tooltip: !hasPermissionToEdit ? translate('tooltips.contributorRole') : undefined,
      disabled: !hasPermissionToEdit,
    },
    markUnseen: {
      onClick: () => {
        if (selectedBulkOption === recordingsSelectOptionsEnum.all) {
          updateBulkRecordings({ seen: false });
        } else {
          markSeenRecordings(false);
        }
      },
      text: translate('recordings.actions.markUnseen'),
      icon: () => <EmptyCircleIcon color={iconColor} />,
      tooltip: !hasPermissionToEdit ? translate('tooltips.contributorRole') : undefined,
      disabled: !hasPermissionToEdit,
    },
    bulkDelete: {
      onClick: () => {
        confirm(() => {
          if (selectedBulkOption === recordingsSelectOptionsEnum.all) {
            deleteBulk();
          } else {
            deleteIndividual(selectedRecordingsIds);
          }
        }, DeleteSingleRecordingConfirmationDialog);
      },
      text: translate('recordings.actions.multiple.delete'),
      icon: () => <MinusIcon color={hasPermissionToDelete ? '#EA2A0C' : '#999999'} />,
      textColorClass: 'text-red-pure',
      tooltip: !hasPermissionToDelete ? translate('tooltips.contributorRole') : undefined,
      disabled: !hasPermissionToDelete,
    },
  };
};
