import { getEventIcon } from '@va/shared/ssr-player';
import {
  AlarmingEvents,
  alarmingEventsTranslations,
  AlarmingEventTrigger,
  EventListItem,
  Events,
  EventsData,
} from '@va/types/events';
import { EventTypeEnum, MappedEventType, RecordingType } from '@va/types/recordings';

export function subtractEventsFromData(eventsData: EventsData): Events {
  return {
    clickInteractions: eventsData.clickInteractions,
    moveInteractions: eventsData.moveInteractions,
    scrollInteractions: eventsData.scrollInteractions,
  };
}

export function sumObjectValues(obj: Record<string, number>): number {
  return Object.values(obj).reduce((prev, current) => prev + current, 0);
}

export function calculateTotalAlarmingEvents(data: RecordingType[]): number {
  return data.reduce((sum, row) => sum + sumObjectValues(row.alarmingEvents), 0);
}

export function calculateTotalEvents(data: RecordingType[]): number {
  return data.reduce((sum, row) => sum + sumObjectValues(subtractEventsFromData(row.events)), 0);
}

export function transformAlarmingEvents(abes: AlarmingEvents): EventListItem[] {
  return Object.entries(abes)
    .filter(([, value]) => value && value > 0)
    .map(([name, count]) => ({
      eventType: name as AlarmingEventTrigger,
      count,
      translationKey: alarmingEventsTranslations[name],
    }));
}

export function transformRegularEvents(nbes: MappedEventType[]): EventListItem[] {
  return Object.values(
    nbes.reduce((acc, event) => {
      const name = event.name;
      const eventIcon = getEventIcon({ eventDetails: name });
      acc[name] = {
        eventType: name,
        count: (acc[name]?.count || 0) + 1,
        icon: eventIcon.icon(),
        translationKey: `recordings.actionsFilterOption.name.${name}`,
      };
      return acc;
    }, {} as Record<EventTypeEnum, EventListItem>),
  );
}
