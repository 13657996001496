import { BLACK_COLOR, WHITE_COLOR } from './colors.constants';

/** @deprecated */
export const gridLayoutPredefinedSize = {
  overviewNormalCard: {
    xllg: { width: 1, height: 2 },
    llg: { width: 1, height: 2 },
    lg: { width: 1, height: 2 },
    md: { width: 1, height: 2 },
    sm: { width: 1, height: 2 },
    xs: { width: 1, height: 2 },
    xxs: { width: 1, height: 2 },
  },
  overviewWiderCard: {
    xllg: { width: 2, height: 2 },
    llg: { width: 2, height: 2 },
    lg: { width: 2, height: 2 },
    md: { width: 2, height: 2 },
    sm: { width: 2, height: 2 },
    xs: { width: 1, height: 2 },
    xxs: { width: 1, height: 2 },
  },
  settingsNormalCard: {
    xllg: { width: 2, height: 1 },
    llg: { width: 2, height: 1 },
    lg: { width: 2, height: 1 },
    md: { width: 3, height: 1 },
    sm: { width: 2, height: 1 },
    xs: { width: 1, height: 1 },
    xxs: { width: 1, height: 1 },
  },
  settingsMobileDiffCard: {
    xllg: { width: 2, height: 1 },
    llg: { width: 2, height: 1 },
    lg: { width: 2, height: 1 },
    md: { width: 3, height: 1 },
    sm: { width: 2, height: 1 },
    xs: { width: 1, height: 2 },
    xxs: { width: 1, height: 2 },
  },
  settingsDoubleCard: {
    xllg: { width: 2, height: 2 },
    llg: { width: 2, height: 2 },
    lg: { width: 2, height: 2 },
    md: { width: 3, height: 2 },
    sm: { width: 2, height: 2 },
    xs: { width: 1, height: 2 },
    xxs: { width: 1, height: 2 },
  },
  settingsTripleCard: {
    xllg: { width: 2, height: 3 },
    llg: { width: 2, height: 3 },
    lg: { width: 2, height: 3 },
    md: { width: 3, height: 3 },
    sm: { width: 2, height: 3 },
    xs: { width: 1, height: 2 },
    xxs: { width: 1, height: 2 },
  },
};
/** @deprecated */
export const gridLayout = {
  //Ads overview
  AdsSourcesCard: gridLayoutPredefinedSize.overviewNormalCard,
  AdsMediumCard: gridLayoutPredefinedSize.overviewNormalCard,
  AdsCampaignContentCard: gridLayoutPredefinedSize.overviewNormalCard,
  AdsPageVisitsCard: gridLayoutPredefinedSize.overviewNormalCard,
  AdsVisitorsCard: gridLayoutPredefinedSize.overviewNormalCard,
  AdsTopStartingPagesCard: gridLayoutPredefinedSize.overviewNormalCard,
  AdsTopCampaignTermsCard: gridLayoutPredefinedSize.overviewNormalCard,
  AdsTopCampaignNamesCard: gridLayoutPredefinedSize.overviewNormalCard,
  AdsLatestVisitorsCard: gridLayoutPredefinedSize.overviewWiderCard,
  AdsMapCard: gridLayoutPredefinedSize.overviewWiderCard,
  //PageSettings
  ConversionsTypeCard: gridLayoutPredefinedSize.settingsTripleCard,
  DynamicPagesCard: gridLayoutPredefinedSize.settingsTripleCard,
};

/** @deprecated */
export const defaultReactSelectStyles = {
  menu: (provided: any) => ({
    ...provided,
    border: 0,
    borderTop: '0',
    borderBottom: '0',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.06)',
    margin: 0,
    zIndex: 1002,
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: 0,
    zIndex: 1002,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: 0,
    fontSize: '14px',
    maxWidth: 'calc(100% - 35px)',
    flexWrap: 'no-wrap',
    cursor: 'pointer',
  }),
  control: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
    border: 'none',
    borderRadius: 0,
    borderBottom: `1px solid ${BLACK_COLOR}`,
    background: 'transparent',
    boxShadow: 'none',
    WebkitAppearance: 'none',
    WebkitBorderRadius: 0,
    '&:hover': {
      borderColor: BLACK_COLOR,
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    cursor: state.isDisabled ? 'default' : 'pointer',
    backgroundColor: state.isSelected ? window.PRIMARY_COLOR : WHITE_COLOR,
    '&:hover': {
      backgroundColor: state.isSelected ? window.PRIMARY_COLOR : '#F0FBFA', // aqua-spring-default
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: '14px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    display: 'block',
    lineHeight: '20px',
  }),
  container: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
  }),
};

export const customReactSelectStyles = {
  menu: (provided: any) => ({
    ...provided,
    borderTop: '0',
    borderBottom: '0',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.06)',
    marginTop: '5px',
    zIndex: 2,
    borderRadius: '12px',
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: 0,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: 0,
    flexWrap: 'no-wrap',
  }),
  control: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
    border: '2px solid #EEEEEE',
    borderRadius: '12px',
    boxShadow: 'none',
    height: '48px',
    paddingLeft: '15px',
    '&:hover': {
      border: '2px solid #EEEEEE',
    },
  }),
  indicatorSeparator: defaultReactSelectStyles.indicatorSeparator,
  option: (provided: any, { isSelected }: any) => ({
    ...provided,
    cursor: 'pointer',
    padding: '12px 15px 12px 15px',
    color: isSelected ? window.PRIMARY_COLOR : 'black',
    borderRadius: '12px',
    backgroundColor: 'white',
    '&:hover': {
      color: window.PRIMARY_COLOR,
      backgroundColor: 'transparent',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    lineHeight: '28px',
  }),
};

/** @deprecated */
export const defaultReactSelectStylesV2 = {
  ...defaultReactSelectStyles,
  control: (provided: any) => ({
    ...provided,
    ...defaultReactSelectStyles.control,
    border: 'none',
    fontSize: 18,
    boxShadow: 'none',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    lineHeight: 24,
  }),
};
