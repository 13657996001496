import { EVENTS } from '@va/constants';
import { DataViewProps } from '@va/types/report-block';
import { TooltipOptions } from '@va/types/tooltip';
import { TooltipWrapper } from '@va/ui/tooltips';
import { useCustomizationContext, useEventsContext, usePermissionsProvider } from '@va/util/misc';
import classNames from 'classnames';
import { PropsWithChildren, forwardRef, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { DataViewButton } from '../data-visualization';
import { TitleInput } from '../inputs';
import { Paragraph, fontWeights, paragraphSizes } from '../typography';

/** @deprecated */
type ReportBlockProps = {
  title: React.ReactNode;
  titleTooltip?: React.ReactNode;
  titleTooltipOptions?: TooltipOptions;
  className?: string;
  border?: boolean;
  nodes?: {
    filterButton?: React.ReactNode;
    segmentsButton?: React.ReactNode;
    appliedFilters?: React.ReactNode;
    segments?: React.ReactNode;
    bulkActions?: React.ReactNode;
    compareTo?: React.ReactNode;
    pagination?: React.ReactNode;
    dataViewButton?: React.ReactNode;
    rightSide?: React.ReactNode;
    productSelector?: React.ReactNode;
    downloadButton?: React.ReactNode;
  };
  visualization?: DataViewProps;
  visualizationComponents?: Record<string, () => JSX.Element>;
  isLoading?: boolean;
};

/** @deprecated use import from @va/ui/components */
export const ReportBlock = forwardRef<HTMLDivElement, PropsWithChildren<ReportBlockProps>>(
  (
    {
      title,
      titleTooltip,
      className,
      children,
      nodes,
      border = false,
      titleTooltipOptions,
      visualization,
      visualizationComponents,
      isLoading,
    },
    ref,
  ) => {
    const {
      filterButton,
      segmentsButton,
      appliedFilters,
      segments,
      bulkActions,
      compareTo,
      pagination,
      dataViewButton,
      rightSide,
      productSelector,
      downloadButton,
    } = nodes ?? {};

    const renderRightSideDiv = useMemo(() => {
      if (!nodes) return false;
      return Object.keys(nodes).length !== 0;
    }, [nodes]);
    const { canEditTitle } = usePermissionsProvider();
    const { localEventBus } = useEventsContext();
    const { getCustomValue } = useCustomizationContext();

    const customRBTitle = getCustomValue('initialTitle', title);

    const Component = useMemo(() => {
      if (!visualization?.selectedView || !visualizationComponents) return undefined;
      return visualizationComponents[visualization.selectedView];
    }, [visualization?.selectedView, visualizationComponents]);

    return (
      <div
        ref={ref}
        className={classNames(
          'report-block bg-white rounded-24 p-6 relative overflow-hidden space-y-2',
          { 'border border-gray-mercury': border },
          className,
        )}
      >
        <div
          className={classNames('flex gap-3 justify-between flex-wrap', {
            'flex-row items-center': !bulkActions,
            'flex-col': bulkActions,
          })}
        >
          <div className='flex gap-12px items-center overflow-hidden'>
            {visualization && <DataViewButton {...visualization} />}

            {canEditTitle && typeof customRBTitle === 'string' ? (
              <TitleInput
                name={'title'}
                initialValue={customRBTitle}
                onSave={(title: string) => {
                  localEventBus?.dispatch(EVENTS.editRBName, title);
                }}
              />
            ) : (
              <TooltipWrapper
                trigger='click'
                interactive
                disabled={!titleTooltip}
                content={titleTooltip}
                {...titleTooltipOptions}
              >
                <Paragraph
                  className={classNames('inline truncate', { 'underline-dashed cursor-default': titleTooltip })}
                  weight={fontWeights.medium}
                  size={paragraphSizes.large}
                >
                  {customRBTitle}
                </Paragraph>
              </TooltipWrapper>
            )}
          </div>
          {renderRightSideDiv && (
            <div className={classNames('flex items-center gap-18px flex-wrap', { 'justify-end': bulkActions })}>
              {bulkActions && <div className='mr-auto'>{bulkActions}</div>}
              {rightSide}
              {downloadButton}
              {compareTo}
              {segmentsButton}
              {filterButton}
              {pagination}
              {dataViewButton}
              {productSelector}
            </div>
          )}
        </div>
        {(segments || appliedFilters) && (
          <div className='mb-18px'>
            {segments}
            {appliedFilters}
          </div>
        )}
        {isLoading ? (
          <div className='h-full w-full'>
            <Skeleton className='h-full w-full' />
          </div>
        ) : (
          <>
            {Component && <Component />}
            {children}
          </>
        )}
      </div>
    );
  },
);
