import { DataViewOption, DataViewProps, VisualizationOption } from '@va/types/report-block';
import { LocalStorage } from '@va/util/helpers';
import { useCallback, useState } from 'react';

function createKey(identifier: string) {
  return `data-view-for-${identifier}`;
}

function getStoredValue(identifier: string) {
  const value = LocalStorage.getItem(createKey(identifier));
  if (!value) return undefined;
  if (!Object.values(DataViewOption).includes(value as DataViewOption)) {
    return undefined;
  }

  return value as DataViewOption;
}

export const useDataView = ({
  defaultView,
  identifier,
  options,
}: {
  defaultView: DataViewOption;
  options?: VisualizationOption[];
  identifier: string;
}): DataViewProps => {
  const [view, setView] = useState<DataViewOption>(getStoredValue(identifier) || defaultView);

  const onViewChange = useCallback(
    (view: DataViewOption) => {
      setView(view);
      LocalStorage.setItem(createKey(identifier), view);
    },
    [identifier],
  );

  return { viewOptions: options || [], onViewChange, selectedView: view };
};
