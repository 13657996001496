import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';
import { IconWithBackground } from '../icon-with-background';

export const Banner = ({
  children,
  wrapperClassName,
  icon,
}: PropsWithChildren<{ wrapperClassName?: string; icon: ReactNode }>) => {
  return (
    <div className='space-x-7'>
      <div className={classNames('bg-primary p-3 px-4 rounded-24 flex gap-3 items-center', wrapperClassName)}>
        <div className='relative'>
          <IconWithBackground className='bg-white-snow' icon={() => icon} />
        </div>
        <div className='text-white'>{children}</div>
      </div>
    </div>
  );
};
