import { CloseIconWithCircle } from '@va/icons';
import { TooltipOptions } from '@va/types/tooltip';
import { appHasDarkTheme } from '@va/util/helpers';
import classNames from 'classnames';
import React, { forwardRef, useMemo } from 'react';
import { Tooltip, TooltipContent, TooltipTrigger } from './Tooltip';

export type TooltipWrapperProps = {
  content: React.ReactNode;
  zIndex?: number | string;
} & TooltipOptions;

export const TooltipWrapper = forwardRef<HTMLDivElement, React.PropsWithChildren<TooltipWrapperProps>>(
  ({ children, content, ...rest }, ref) => {
    return (
      <Tooltip {...rest}>
        <TooltipTrigger ref={ref}>{children}</TooltipTrigger>
        <TooltipContent {...rest}>{content}</TooltipContent>
      </Tooltip>
    );
  },
);

export const CloseButton = ({
  onClose,
  iconColor,
  className,
}: {
  onClose: () => void;
  iconColor?: string;
  className?: string;
}) => {
  const isDarkTheme = appHasDarkTheme();

  const closeIconColor = useMemo(() => {
    if (iconColor) {
      return iconColor;
    }
    return isDarkTheme ? '#000000' : '#FFFFFF';
  }, [iconColor, isDarkTheme]);

  return (
    <div onClick={onClose}>
      <CloseIconWithCircle color={closeIconColor} className={classNames('md:hidden cursor-pointer', className)} />
    </div>
  );
};
