import { IconProps } from '.';

export const SortAscArrow = ({ color = '#3C3C3c', className }: IconProps) => {
  return (
    <svg className={className} width='8' height='9' viewBox='0 0 8 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.50191 2.20711L4.50192 9L3.50192 9L3.50191 2.20711L1.35547 4.35355L0.648362 3.64645L4.00191 0.292894L7.35547 3.64645L6.64836 4.35355L4.50191 2.20711Z'
        fill={color}
      ></path>
    </svg>
  );
};
