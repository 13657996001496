/* eslint-disable jsx-a11y/anchor-has-content */
import { flagKeys, modalName } from '@va/constants';
import { setUserFlag, updateWebsiteFlag } from '@va/dashboard/actions/api';
import { closeModal, openModal, setAnnouncementData } from '@va/dashboard/actions/ui';
import { getAnnouncementData, isModalOpen as isModalOpenSelector } from '@va/dashboard/selectors/ui';
import { AnnouncementType } from '@va/types/announcements';
import { ModalWrapper } from '@va/util/components';
import CustomFocusTrap from 'common/components/misc/CustomFocusTrap';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnnouncementModalV2 from './AnnouncementModalV2';

type AnnouncementModalContainerV2Props = {
  markForWebsite?: boolean;
};

const AnnouncementModalContainerV2: React.FC<AnnouncementModalContainerV2Props> = ({ markForWebsite }) => {
  const announcements = useSelector(getAnnouncementData) as AnnouncementType[];

  const [announcement, setAnnouncement] = useState<AnnouncementType | undefined | null>(undefined);
  const [isChecked, setIsChecked] = useState(false);

  const dispatch = useDispatch();
  const isModalOpen = useSelector((state) => isModalOpenSelector(state, modalName.announcement));

  const showAnnouncement = useCallback(
    (announcement: AnnouncementType) => {
      dispatch(openModal(modalName.announcement));
      setAnnouncement(announcement);
    },
    [dispatch],
  );

  useEffect(() => {
    if (announcement || announcements.length === 0) return;
    showAnnouncement(announcements[0]);
  }, [announcements, announcement, showAnnouncement]);

  const updateTimestamps = useCallback(() => {
    if (announcement?.forceDisplay) return;
    if (announcement?.isAccountBased) {
      if (markForWebsite) {
        dispatch(updateWebsiteFlag(flagKeys.LAST_ACCOUNT_BASED_ANNOUNCEMENT_VIEWED_AT, moment().unix()));
        return;
      }
      dispatch(setUserFlag(flagKeys.LAST_ACCOUNT_BASED_ANNOUNCEMENT_VIEWED_AT, moment().unix()));
    } else {
      if (markForWebsite) {
        dispatch(updateWebsiteFlag(flagKeys.LAST_WEBSITE_BASED_ANNOUNCEMENT_VIEWED_AT, moment().unix()));
        return;
      }
      dispatch(setUserFlag(flagKeys.LAST_WEBSITE_BASED_ANNOUNCEMENT_VIEWED_AT, moment().unix()));
    }
  }, [announcement, dispatch, markForWebsite]);

  const onCloseModal = useCallback(() => {
    const announcementsLeftInLine = announcements.filter((item) => item.id !== announcement?.id);
    dispatch(setAnnouncementData(announcementsLeftInLine));

    setIsChecked(false);
    dispatch(closeModal(modalName.announcement));
    setAnnouncement(undefined);

    updateTimestamps();

    if (announcement?.forceDisplay) return;

    if (markForWebsite) {
      dispatch(updateWebsiteFlag(announcement?.id, true));
    } else {
      dispatch(setUserFlag(announcement?.id, true));
    }
  }, [announcement, announcements, dispatch, markForWebsite, updateTimestamps]);

  if (!announcement) return null;

  const confirmation_needed = announcement?.content?.confirmation_needed;

  const modalContent = (
    <ModalWrapper
      isModalOpen={isModalOpen}
      closeModal={() => (!confirmation_needed ? onCloseModal() : null)}
      contentClassName='rounded-ee-48px max-w-[calc(100%-20px)] md:max-w-unset'
    >
      <AnnouncementModalV2
        onClose={onCloseModal}
        content={announcement?.content}
        isChecked={true}
        onCheckboxChange={() => {
          setIsChecked(!isChecked);
        }}
        modalId={announcement?.id ?? ''}
      />
    </ModalWrapper>
  );
  if (confirmation_needed) {
    return isModalOpen ? (
      <CustomFocusTrap>
        <a href={'###'} />
        {modalContent}
      </CustomFocusTrap>
    ) : null;
  } else {
    return modalContent;
  }
};

export default AnnouncementModalContainerV2;
