import {
  IGNORE_VISITS,
  INITIALIZE,
  INITIALIZE_SUCCEEDED,
  POP_REMOVING_CONVERSION_TYPE,
  POP_REMOVING_DYNAMIC_PAGE,
  PUSH_REMOVING_CONVERSION_TYPE,
  PUSH_REMOVING_DYNAMIC_PAGE,
  REQUEST_IGNORE_VISITS,
  SET,
  SET_ALL_RECORDING_EVENTS_LOADED,
  SET_FORM_FIELD,
  SET_IGNORE_VISITS,
  SET_IGNORE_VISITS_IFRAME_LOADED,
  SET_IGNORE_VISITS_LINK,
  SET_INSTANCE_ID,
  SET_SSR_SESSION_FOR_RECORDINGS,
  SET_WEBSITE_PROPERTY,
} from '@va/dashboard/actions/app';
import { applyReducers } from '@va/dashboard/reducers';
import Immutable from 'immutable';
import { recordingsReducer } from './recordingsReducer';

const appReducer = (
  state = Immutable.Map({
    visitedSsrSessions: Immutable.List(),
  }),
  action,
) => {
  switch (action.type) {
    case SET_INSTANCE_ID:
      return state.set('instanceId', action.instanceId);
    case INITIALIZE:
      return state.set('initializing', true);
    case INITIALIZE_SUCCEEDED:
      return state.set('initializing', false);
    case SET_ALL_RECORDING_EVENTS_LOADED:
      return state.set('getAllRecordingEventsSucceeded', action.value);
    case SET_SSR_SESSION_FOR_RECORDINGS:
      return state
        .set('ssrSessionForRecordings', action.sessionId)
        .update('visitedSsrSessions', (arr) => arr.push(action.sessionId));
    default:
      return applyReducers(state, action, {
        ignoreVisits: ignoreVisits,
        website: website,
        user: user,
        form: form,
        pendingRemovingDynamicPages: handleRemoveDynamicPage,
        pendingRemovingConversionTypes: handleRemoveConversionTypes,
        recordings: recordingsReducer,
      });
  }
};
export default appReducer;

const user = (
  state = Immutable.Map({
    instance: null,
  }),
  action,
) => {
  switch (action.type) {
    case SET:
      return state.merge(action.state);
    default:
      return state;
  }
};

const website = (state = Immutable.Map(), action) => {
  switch (action.type) {
    case SET_WEBSITE_PROPERTY:
      return state.set(action.propertyName, action.propertyValue);
    default:
      return state;
  }
};

const ignoreVisits = (
  state = Immutable.Map({
    isLoaded: false,
    inProgress: false,
    isIgnored: undefined,
    ignore: undefined,
    link: undefined,
  }),
  action,
) => {
  switch (action.type) {
    case SET_IGNORE_VISITS_IFRAME_LOADED:
      return state.merge({
        isLoaded: true,
        inProgress: true,
      });
    case REQUEST_IGNORE_VISITS:
      return state.set('inProgress', true);
    case SET_IGNORE_VISITS_LINK:
      return state.set('link', action.link);
    case SET_IGNORE_VISITS:
      return state.merge({
        inProgress: false,
        isIgnored: action.value,
        isAllIgnored: action.isAllIgnored,
        ignore: action.value,
      });
    case IGNORE_VISITS:
      return state.set('ignore', action.value);
    default:
      return state;
  }
};

const form = (state = Immutable.Map(), action) => {
  switch (action.type) {
    case SET_FORM_FIELD:
      return state.setIn([action.formName, action.field], action.value);
    default:
      return state;
  }
};

const handleRemoveDynamicPage = (state = Immutable.List(), action) => {
  switch (action.type) {
    case PUSH_REMOVING_DYNAMIC_PAGE:
      return state.push(action.id);
    case POP_REMOVING_DYNAMIC_PAGE:
      return state.filter((o) => o !== action.id);
    default:
      return state;
  }
};

const handleRemoveConversionTypes = (state = Immutable.List(), action) => {
  switch (action.type) {
    case PUSH_REMOVING_CONVERSION_TYPE:
      return state.push(action.id);
    case POP_REMOVING_CONVERSION_TYPE:
      return state.filter((o) => o !== action.id);
    default:
      return state;
  }
};
