import { DesktopDeviceIcon, MobileIcon } from '..';
import { UnknownOS } from '../operating-systems-icons/OS';

export function Device(props: JSX.IntrinsicElements['svg']) {
  const device = props.type ? props.type : '';
  switch (device.toLowerCase()) {
    case 'desktop':
      return <DesktopDeviceIcon {...props} />;
    case 'mobile':
      return <Tablet {...props} />;
    case 'phone':
      return <MobileIcon {...props} />;
    case 'unknown':
      return <UnknownOS {...props} />;
    default:
      return <span>{device}</span>;
  }
}

function Tablet(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg width='31' height='20' viewBox='0 0 31 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fillRule='evenodd' clipRule='evenodd' d='M31 0H0V20H31V0ZM30 1H1V19H30V1Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27 12C28.1055 12 29 11.1046 29 10C29 8.89539 28.1055 8 27 8C25.8945 8 25 8.89539 25 10C25 11.1046 25.8945 12 27 12ZM27 11C27.5527 11 28 10.5522 28 10C28 9.60181 27.7676 9.25793 27.4316 9.09705C27.3008 9.03479 27.1543 9 27 9C26.4473 9 26 9.44775 26 10C26 10.5522 26.4473 11 27 11Z'
      />
    </svg>
  );
}
