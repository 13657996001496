import { EventBus } from '@va/util/misc';

export const InternalChatwootEvents = {
  OPEN_CHATWOOT: 'OPEN_CHATWOOT',
  SET_ATTRIBUTES: 'SET_ATTRIBUTES',
};

export const openChatwootWidget = () => {
  EventBus.dispatch(InternalChatwootEvents.OPEN_CHATWOOT);
};
