import { modalName, reportType } from '@va/constants';
import { startUpgradeProcess } from '@va/dashboard/actions/app';
import { Checkbox as CheckboxIcon, CheckboxPremium } from '@va/svg-visa-icons';
import { validateEmail } from '@va/util/helpers';
import PropTypes from 'prop-types';
import React from 'react';

/** @deprecated */
export class renderField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      autoFilled: false,
      hasChanged: false,
    };

    this.onValueChange = this.onValueChange.bind(this);
  }

  componentDidMount() {
    if (this.props.shouldAutoFocus) {
      setTimeout(() => this.inputRef && this.inputRef.focus(), 100);
    }

    if (this.props.hasAutoFill) {
      ['animationstart', 'webkitAnimationStart'].map((eventName) =>
        this.inputRef.addEventListener(eventName, (e) => {
          if (e.animationName === 'onAutoFillStart') {
            this.setState({ autoFilled: true });
          }
        }),
      );
    }

    // avoid validation when tab are switched, but value has not changed
    if (this.props.untouch) {
      window.addEventListener(
        'focus',
        () => {
          if (!this.state.hasChanged) {
            this.props.untouch(this.props.input.name);
          }
        },
        false,
      );
    }
  }

  onValueChange(event) {
    if (!this.state.hasChanged) {
      this.setState({ hasChanged: true });
    }

    this.props.input.onChange(event);
  }

  render() {
    const {
      input,
      type,
      icon,
      rightInputIcon,
      meta: { touched, error, active },
      placeholder,
      errorTransParams,
      disabled,
      translate,
      autoCompleteType,
      initialValue,
      required,
      showErrorWithoutTouching,
      inputClassName,
      hideLabel,
    } = this.props;

    const loweredClass = active || input.value || initialValue || this.state.autoFilled ? '' : 'lowered';
    const requiredClass = required ? 'required' : '';
    const labelClass = placeholder ? '' : 'no-label';
    const noIconClass = icon ? '' : 'no-icon';
    const hasError = showErrorWithoutTouching ? error : touched && error;
    const disabledClass = disabled ? 'disabled' : '';

    return (
      <div className={`custom-input ${noIconClass} ${hasError ? 'invalid' : ''} ${disabledClass}`}>
        {icon && React.cloneElement(icon, { className: 'icon' })}
        {!hideLabel && <label className={`${loweredClass} ${labelClass} ${requiredClass}`}>{placeholder}</label>}
        <input
          ref={(ref) => (this.inputRef = ref)}
          name={input.name}
          value={initialValue || input.value}
          onChange={this.onValueChange}
          onBlur={input.onBlur}
          onFocus={input.onFocus}
          autoComplete={autoCompleteType}
          className={`notification-input ${inputClassName}`}
          type={type}
          min={this.props.min}
          max={this.props.max}
          disabled={disabled}
          placeholder={hideLabel ? placeholder : ''}
        />
        {rightInputIcon
          ? React.cloneElement(rightInputIcon, {
              className: 'icon right-input-icon',
            })
          : null}
        {hasError && <p className='error-text'>{translate(error, errorTransParams)}</p>}
      </div>
    );
  }
}

renderField.defaultProps = {
  inputClassName: '',
};

/** @deprecated */
export const renderCheckbox = ({ input, id, className, type, meta: { touched, error } }) => {
  return (
    <div>
      <div className='checkbox-icon' onClick={() => input.onChange(!input.value)}>
        <CheckboxIcon
          color={window.PRIMARY_COLOR}
          active={input.value || false}
          className={touched && error ? 'required' : ''}
        />
      </div>
      <input {...input} id={id} className={className} type={type} />
    </div>
  );
};

const getOnNotificationTypeClickFn = (input, emailIsValid, openModal, hasPermission) => {
  if (!hasPermission) return null;
  if (!emailIsValid && input.value !== reportType.NEVER) return () => openModal(modalName.accountSettingsRequired);

  return () => input.onChange(input.value);
};

/** @deprecated */
export const renderRadioCheckbox = (props) => {
  const {
    input,
    id,
    className,
    label,
    labelClass,
    type,
    reportType,
    email,
    isPremium,
    wixClaimed,
    standaloneCard,
    premiumOption,
    openModal,
    hasPermission,
  } = props;

  const isOptionChecked = input.value === reportType;
  const emailIsValid = validateEmail(email);

  const isPremiumOptionAndFreeUser = !isPremium && premiumOption;

  const premiumClass = isPremiumOptionAndFreeUser ? `premium ${standaloneCard ? 'standalone-premium' : ''}` : '';
  const activeClass = isOptionChecked ? 'active' : '';
  const disabledClass = !hasPermission ? 'disabled' : '';
  const premiumNotificationIconColors = standaloneCard ? { color1: '#2F90AE', color2: '#50A2BC' } : null;

  return (
    <div
      onClick={
        isPremiumOptionAndFreeUser
          ? standaloneCard && wixClaimed
            ? null
            : () => startUpgradeProcess()
          : getOnNotificationTypeClickFn(input, emailIsValid, openModal, hasPermission)
      }
    >
      <div className={`checkbox-icon ${disabledClass}`}>
        {isPremiumOptionAndFreeUser ? (
          <CheckboxPremium colors={premiumNotificationIconColors} />
        ) : (
          <CheckboxIcon disabled={!hasPermission} active={isOptionChecked} />
        )}
      </div>
      <input
        {...input}
        id={id}
        disabled={!emailIsValid || isPremiumOptionAndFreeUser || !hasPermission}
        className={className + ' radio-input'}
        type={type}
        onBlur={getOnNotificationTypeClickFn(input, emailIsValid)}
      />
      <label className={`notification-btn ${labelClass} ${premiumClass} ${activeClass} ${disabledClass}`} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

renderRadioCheckbox.propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  reportType: PropTypes.number,
  email: PropTypes.string,
  className: PropTypes.string,
  labelClass: PropTypes.string,
  isPremium: PropTypes.bool,
  premiumOption: PropTypes.bool,
  openModal: PropTypes.func,
  hasPermission: PropTypes.bool,
  standaloneCard: PropTypes.bool,
  wixClaimed: PropTypes.bool,
};
