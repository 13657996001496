import { useLocalizationContext } from '@va/localization';
import { fontWeights, Paragraph, ParagraphWithTooltip } from '@va/ui/design-system';
import { addNumberSeparator, appHasDarkTheme } from '@va/util/helpers';
import { ReactNode } from 'react';

type VisitorActionListItemProps = {
  translationKey: string;
  count: number;
  icon: ReactNode;
};

export function VisitorActionListItem({ translationKey, count, icon }: VisitorActionListItemProps) {
  const isDarkThemeApplied = appHasDarkTheme();
  const { locale, translate } = useLocalizationContext();

  return (
    <li className='flex items-center gap-10 justify-between'>
      <div className='flex items-center gap-3 truncate'>
        <div className='h-12 w-12 shrink-0 rounded-full flex items-center justify-center bg-transparent'>{icon}</div>
        <div className='flex flex-col text-left truncate'>
          <ParagraphWithTooltip weight={fontWeights.medium} className='truncate w-full block'>
            {translate(translationKey)}
          </ParagraphWithTooltip>
        </div>
      </div>
      <div className='shrink-0 text-left'>
        <Paragraph
          colorClassName={isDarkThemeApplied ? 'text-gray-charcoal/83' : 'text-white-83'}
          weight={fontWeights.medium}
        >
          {addNumberSeparator(count, locale)}
        </Paragraph>
      </div>
    </li>
  );
}
