import { CheckmarkIconFilled, EmptyCheckmarkIcon, FilterIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { FilterOperators } from '@va/types/filters';
import { Button, Paragraph, fontWeights } from '@va/ui/design-system';
import { ModalWrapper } from '@va/util/components';
import { FILTER_SELECTION_MODAL_CLASS_IDENTIFIER } from '../constants';

export const PickOperatorModal = ({
  isOpen,
  closeModal,
  operators,
  onChange,
  selectedOperator,
}: {
  isOpen: boolean;
  closeModal: () => void;
  onChange: (operator: FilterOperators) => void;
  operators: FilterOperators[];
  selectedOperator: FilterOperators;
}) => {
  const translate = useTranslate();

  return (
    <ModalWrapper className={FILTER_SELECTION_MODAL_CLASS_IDENTIFIER} closeModal={closeModal} isModalOpen={isOpen}>
      <div className='p-3 min-w-[330px]'>
        <ul className='space-y-1'>
          {operators.map((operator, index) => {
            const isSelected = operator === selectedOperator;

            return (
              <li
                key={index}
                onClick={() => {
                  onChange(operator);
                  closeModal();
                }}
                className='cursor-pointer h-12 hover:bg-white-snow rounded-12 px-3 flex items-center gap-3'
              >
                <FilterIcon color='#696969' />
                <Paragraph weight={fontWeights.medium}>{translate(`filters.operators.${operator}`)}</Paragraph>

                <div className='ml-auto'>{isSelected ? <CheckmarkIconFilled /> : <EmptyCheckmarkIcon />}</div>
              </li>
            );
          })}
        </ul>
        <Button
          onClick={closeModal}
          className='text-center w-full mt-3'
          color='tertiary'
          text={translate('button.close')}
        />
      </div>
    </ModalWrapper>
  );
};
