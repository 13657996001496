import { BLACK_COLOR } from '@va/constants';
import { withTranslate } from '@va/deprecated/components/withTranslate';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import Switch from 'react-switch';
import './ToggleSwitch.scss';

/** @deprecated */
class ToggleSwitch extends Component {
  switchContainerClassName = 'toggle-switch';
  handleClassName = 'react-switch-handle';

  constructor(props) {
    super(props);
    this.state = { containerWidth: 0 };

    this.diameterHeightDifference = this.props.height - this.props.diameter;
    this.handleOffsetSize = this.props.diameter + this.diameterHeightDifference;
    this.textContainerWidth = this.props.width - this.handleOffsetSize + this.diameterHeightDifference;

    this.changeHandleImage = this.changeHandleImage.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const parentSize = document.querySelector(`.${this.switchContainerClassName} .left-text`).parentElement.offsetWidth;
    this.setState({ containerWidth: parentSize });

    this.changeHandleImage();

    document.querySelector(`.${this.switchContainerClassName} .${this.handleClassName}`).style.backgroundSize = 'cover';
    document.querySelector(`.${this.switchContainerClassName} .${this.handleClassName}`).style.backgroundPosition =
      'cover center';
  }

  componentDidUpdate() {
    this.changeHandleImage();
  }

  changeHandleImage() {
    if (this.props.offHandleImg && this.props.onHandleImg) {
      if (this.props.checked) {
        document.querySelector(
          `.${this.switchContainerClassName} .${this.handleClassName}`,
        ).style.background = `url(${this.props.onHandleImg}) center / cover`;
      } else {
        document.querySelector(
          `.${this.switchContainerClassName} .${this.handleClassName}`,
        ).style.background = `url(${this.props.offHandleImg}) center / cover`;
      }
    }
  }

  onChange(...args) {
    if (this.props.handleChange) {
      this.props.handleChange(...args);
    }
  }

  render() {
    const commonTextStyle = {
      width: this.textContainerWidth,
      height: this.props.height,
      textAlign: 'center',
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      color: BLACK_COLOR,
    };

    const rightTextStyle = { ...commonTextStyle, right: 0 };
    const leftTextStyle = { ...commonTextStyle };

    const leftText = (
      <div className='left-text' style={leftTextStyle}>
        {this.props.leftText}
      </div>
    );
    const rightText = (
      <div className='right-text' style={rightTextStyle}>
        {this.props.rightText}
      </div>
    );

    const containerClassName = [this.switchContainerClassName, this.props.containerClassName || ''].join(' ');
    return (
      <div
        className={classNames(`toggle-switch-container ${this.props.checked ? 'checked' : 'unchecked'}`, {
          'without-border': this.props.withoutBorder,
        })}
      >
        <TooltipWrapper
          interactive
          disabled={!this.props.tooltipId}
          content={this.props.translate(this.props.translateKey)}
        >
          <div>
            <Switch
              uncheckedIcon={rightText}
              checkedIcon={leftText}
              className={containerClassName}
              onChange={this.onChange}
              checked={this.props.checked}
              disabled={this.props.disabled}
              width={this.props.width}
              height={this.props.height}
              handleDiameter={this.props.diameter}
              offColor={this.props.offColor}
              onColor={this.props.onColor}
              offHandleColor={this.props.offHandleColor}
              onHandleColor={this.props.onHandleColor}
            />
          </div>
        </TooltipWrapper>
      </div>
    );
  }
}

ToggleSwitch.propTypes = {
  tooltipId: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  diameter: PropTypes.number,
  leftText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  rightText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  offColor: PropTypes.string,
  onColor: PropTypes.string,
  offHandleColor: PropTypes.string,
  onHandleColor: PropTypes.string,
  offHandleImg: PropTypes.string,
  onHandleImg: PropTypes.string,
  disabled: PropTypes.bool,
  containerClassName: PropTypes.string,
  withoutBorder: PropTypes.bool,
};

ToggleSwitch.defaultProps = {
  height: 18,
  width: 44,
  checked: false,
  diameter: 16,
  leftText: '',
  rightText: '',
  disabled: false,
};

export default withTranslate(ToggleSwitch);
