import { TEST_IDS } from '@va/constants';
import { DashedLineGraphIcon, FilledLineGraphIcon, UnfilledLineGraphIcon, VerticalThreeDotIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { LineStyling } from '@va/types/charts';
import { TooltipWrapper } from '@va/ui/tooltips';
import { Checkmark } from '@va/util/components';
import { useMemo, useState } from 'react';
import { Button } from '../../button';
import { Paragraph, fontWeights } from '../../typography';
import { useChartTilesContext } from './ChartTilesContext';

export const LineDataViewOptions = ({ tileId }: { tileId: string }) => {
  const { onKpiDataViewChange, options, displayContextMenu } = useChartTilesContext();

  if (!displayContextMenu) {
    return null;
  }
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <TooltipWrapper
        content={
          <LineDataViewOptionsDropdown
            onChange={(value: string) => onKpiDataViewChange(tileId, value)}
            value={options[tileId] as LineStyling}
          />
        }
        trigger='click'
        useDefaultStyle={false}
        arrow={false}
      >
        <div>
          <Button
            data-testid={TEST_IDS.generic.chart.toggleTileContextMenuBtn}
            icon={() => <VerticalThreeDotIcon />}
            color={'tertiary'}
            className='invisible group-hover:visible z-20 h-9 w-9'
          />
        </div>
      </TooltipWrapper>
    </div>
  );
};

const LineDataViewOptionsDropdown = ({
  value,
  onChange,
}: {
  value: LineStyling;
  onChange: (value: string) => void;
}) => {
  const translate = useTranslate();
  const [selectedView, setSelectedView] = useState<LineStyling | undefined>(value);

  const viewOptions = useMemo(() => {
    return [LineStyling.UNFILLED, LineStyling.FILLED, LineStyling.DASHED];
  }, []);

  return (
    <div className='bg-white p-6px rounded-15 shadow-md min-w-300px'>
      <ul>
        {viewOptions.map((view) => {
          const Icon = getLineViewIcon(view);
          return (
            <li
              data-testid={TEST_IDS.helpers.createListItemId(view)}
              onClick={() => [onChange(view), setSelectedView(view)]}
              className='flex items-center gap-3 p-3 rounded-12 hover:bg-white-snow active:bg-gray-concrete cursor-pointer'
              key={view}
            >
              {<Icon color='#696969' />}
              <Paragraph weight={fontWeights.medium}>{translate(`all.lineViewOptions.${view}`)}</Paragraph>
              <div className='ml-auto'>
                <Checkmark selected={view === selectedView} />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export function getLineViewIcon(view: LineStyling) {
  switch (view) {
    case LineStyling.FILLED:
      return FilledLineGraphIcon;
    case LineStyling.DASHED:
      return DashedLineGraphIcon;
    default:
      return UnfilledLineGraphIcon;
  }
}
