import { IconProps } from '../misc';

export const LockIconV2 = ({ className, color = '#000000' }: IconProps) => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.5 3.59998V3C4.5 2.17157 5.17157 1.5 6 1.5C6.82843 1.5 7.5 2.17157 7.5 3V3.59998H4.5ZM3 3.59998V3C3 1.34314 4.34314 0 6 0C7.65686 0 9 1.34314 9 3V3.59998H9.75C10.5784 3.59998 11.25 4.27155 11.25 5.09998V10.5C11.25 11.3284 10.5784 12 9.75 12H2.25C1.42157 12 0.75 11.3284 0.75 10.5V5.09998C0.75 4.27155 1.42157 3.59998 2.25 3.59998H3ZM7.5 7.80005C7.5 8.62848 6.82843 9.30005 6 9.30005C5.17157 9.30005 4.5 8.62848 4.5 7.80005C4.5 6.97162 5.17157 6.30005 6 6.30005C6.82843 6.30005 7.5 6.97162 7.5 7.80005Z'
        fill={color}
      />
    </svg>
  );
};
