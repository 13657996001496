import { dataViewerRoutes } from '@va/standalone/shared/constants';
import { getActiveWebsiteId } from '@va/standalone/shared/selectors';
import { getQueryStringProcessed } from '@va/util/router';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

const RouterLink = (props) => {
  const {
    keepQueryParams,
    activeWebsiteId,
    queryParams,
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    match,
    location,
    children,
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    history,
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    staticContext,
    ...others
  } = props;
  let to = props.to;

  // add route prefix for dataViewerRoutes
  if (isString(to) && dataViewerRoutes.includes(to)) {
    to = `/website/${activeWebsiteId}`.concat(to);
  }

  const qs = keepQueryParams ? location.search : '';
  return (
    <Link
      {...others}
      to={{
        pathname: to,
        search: getQueryStringProcessed(qs, queryParams),
      }}
    >
      {children}
    </Link>
  );
};

const mapStateToProps = (state) => {
  return {
    activeWebsiteId: getActiveWebsiteId(state),
  };
};

RouterLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  queryParams: PropTypes.object,
  staticContext: PropTypes.any,
  keepQueryParams: PropTypes.bool,
  className: PropTypes.string,
  activeWebsiteId: PropTypes.string,
};

export default withRouter(connect(mapStateToProps, {})(RouterLink));
