import * as Api from './api';
import * as App from './app';
import * as Ui from './ui';

// TODO Remove and replace all occurances
const standalone = {
  Api: Api,
  Ui: Ui,
  App: App,
};

export * from './api';
export * from './app';
export * from './ui';
export { standalone };
