import { useMutateOnRefresh } from '@va/dashboard/util-hooks';
import { GlobeIcon, LocationIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { ProgressBarListItem, SelectDropdown, SelectDropdownOption } from '@va/ui/design-system';
import { Flag } from '@va/util/components';
import { sumBy } from 'lodash';
import { memo, useCallback, useMemo, useState } from 'react';
import { LocationRowDetails } from './LocationRowDetails';
import LocationsCard from './LocationsCard';
import { getDataToShow, isVisitorUnknown } from './VisitorsByCountryCard';
import { VisitorByCity, noOfItemsToShow, useVisitorsByCity } from './apiClient';

function getAllCountriesOption(translate: (k: string) => string) {
  return { value: 'allCountries', label: translate(`country.name.allCountries`) };
}

const allCountries = 'allCountries';

const VisitorsByCityCard = () => {
  const translate = useTranslate();
  const { data, isLoading, mutate } = useVisitorsByCity();

  const [showExtendedData, setShowExtendedData] = useState(false);
  const { isManualRefreshing } = useMutateOnRefresh(mutate);

  const visitorsByCityData = useMemo(() => data?.sortedData || [], [data]);
  const totalCount = useMemo(() => data?.totalCount || 0, [data]);
  const maxCount = useMemo(() => data?.maxCount ?? 0, [data]);
  const filteredCountryCodes = useMemo(() => data?.filteredCountryCodes || [], [data]);

  const [selectedCountry, setSelectedCountry] = useState(getAllCountriesOption(translate));

  const filteredVisitors = useMemo(() => {
    if (selectedCountry.value === allCountries) return visitorsByCityData;
    return visitorsByCityData.filter((v) => v.countryCode === selectedCountry.value);
  }, [selectedCountry, visitorsByCityData]);

  const totalCountByCountry = useMemo(() => {
    if (selectedCountry.value === allCountries) return totalCount;
    return sumBy(filteredVisitors, (v) => v.count);
  }, [filteredVisitors, selectedCountry, totalCount]);

  const dataToShow: VisitorByCity[] = useMemo(
    () => getDataToShow(showExtendedData, filteredVisitors),
    [filteredVisitors, showExtendedData],
  );

  const othersCount = useMemo(() => {
    return totalCountByCountry ? totalCountByCountry - sumBy(dataToShow, (v) => v.count) : 0;
  }, [dataToShow, totalCountByCountry]);

  const showChangeLoadedDataButton = useMemo(
    () => filteredVisitors.length > noOfItemsToShow.DEFAULT,
    [filteredVisitors.length],
  );

  const isCityUnknown = useCallback((city: string) => {
    return isVisitorUnknown(city);
  }, []);

  const dropdownOptions: SelectDropdownOption<string>[] = useMemo(() => {
    return [getAllCountriesOption(translate), ...filteredCountryCodes];
  }, [filteredCountryCodes, translate]);

  return (
    <LocationsCard
      title={translate('all.latestVisitors.locations.byCity.title')}
      isLoading={isLoading || isManualRefreshing}
      onChangeLoadedData={() => setShowExtendedData((prev) => !prev)}
      showExtendedData={showExtendedData}
      showChangeLoadedDataButton={showChangeLoadedDataButton}
      hasNoData={!dataToShow.length}
      rightSideElement={
        <SelectDropdown
          icon={<LocationIcon color='#696969' />}
          selectedOption={selectedCountry}
          dropdownOptions={dropdownOptions}
          onChange={(option: SelectDropdownOption<string>) => {
            setShowExtendedData(false);
            setSelectedCountry(option);
          }}
          dropdownPlacement='bottom'
          comparisonType='match'
        />
      }
    >
      <>
        {dataToShow.map((visit, index) => (
          <ProgressBarListItem
            key={index}
            datasets={[{ color: 'var(--color-primary)', count: visit.count }]}
            totalCount={totalCountByCountry}
            maxCount={maxCount}
            percentPrecision={2}
            countLabel={translate('label.visitors')}
            tooltip={isCityUnknown(visit.name) ? translate('panels.referringSites.tooltipUnknown') : undefined}
            rowDetails={
              <LocationRowDetails
                label={visit.name}
                icon={<Flag countryCode={visit.countryCode} size='1x1' className='w-18 h-18 rounded-full' />}
              />
            }
          />
        ))}
        {showExtendedData && othersCount > 0 && (
          <ProgressBarListItem
            datasets={[{ color: 'var(--color-primary)', count: othersCount }]}
            totalCount={totalCountByCountry}
            maxCount={maxCount}
            percentPrecision={2}
            countLabel={translate('label.visitors')}
            rowDetails={<LocationRowDetails label={translate('city.name.others')} icon={<GlobeIcon />} />}
          />
        )}
      </>
    </LocationsCard>
  );
};

export default memo(VisitorsByCityCard);
