import { useInnerOnChange } from '@va/util/hooks';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const DebouncedInput = ({ value = '', onChange, type = 'text', delay = 300, ...props }, ref) => {
  const { innerOnChange, innerValue } = useInnerOnChange(onChange, delay, value);

  return <input value={innerValue} ref={ref} type={type} onChange={innerOnChange} {...props} />;
};

DebouncedInput.propTypes = {
  value: PropTypes.string,
  delay: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default forwardRef(DebouncedInput);
