import { debounce } from 'lodash';
import { RefObject, useEffect, useState } from 'react';

/**
 * Checks if the ref passed as an argument has overflow
 * @param ref
 * @returns
 */
export const useCheckOverflow = (ref: RefObject<HTMLElement>) => {
  const [isOverflow, setIsOverflow] = useState(false);

  const onResize = debounce(() => {
    if (!ref.current) return;
    const { scrollWidth, offsetWidth, scrollHeight, offsetHeight } = ref.current;
    setIsOverflow(scrollWidth > offsetWidth || scrollHeight > offsetHeight);
  }, 300);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return isOverflow;
};
