import { HorizontalArrowsIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { ReportBlockBody } from '@va/ui/components/report-block';
import { GrayGradientDivider } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { useIsHovering, useWindowDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';

type SplitPanelProps = {
  left: React.ReactNode;
  right: React.ReactNode;
  initialLeftWidth?: number;
};

export default function SplitPanel({ left, right, initialLeftWidth = 50 }: SplitPanelProps) {
  const translate = useTranslate();
  const { isTablet } = useWindowDimensions();
  const [leftWidth, setLeftWidth] = useState(initialLeftWidth);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const dividerRef = useRef<HTMLDivElement>(null);
  const isHoveringOndivider = useIsHovering(dividerRef);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
    const startX = e.pageX;
    const containerWidth = containerRef.current?.offsetWidth || 0;
    const dividerWidth = dividerRef.current?.offsetWidth || 0;
    const startLeftWidth = leftWidth;

    const handleMouseMove = (e: MouseEvent) => {
      const offsetX = e.pageX - startX;
      const newLeftWidth = Math.max(
        0,
        Math.min(100, startLeftWidth + (offsetX / (containerWidth - dividerWidth)) * 100),
      );
      setLeftWidth(newLeftWidth);
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    setLeftWidth(isTablet ? 100 : initialLeftWidth);
  }, [isTablet, initialLeftWidth]);

  const isSeparatorUsed = useMemo(() => isHoveringOndivider || isDragging, [isHoveringOndivider, isDragging]);
  return (
    <ReportBlockBody className='!p-0'>
      {isTablet ? (
        <div className='flex flex-col'>
          <div className='w-full overflow-hidden'>{left}</div>
          <GrayGradientDivider />
          <div className='overflow-hidden w-full'>{right}</div>
        </div>
      ) : (
        <div ref={containerRef} className='flex items-stretch relative'>
          <div style={{ width: `${leftWidth}%` }} className='overflow-hidden'>
            {left}
          </div>
          <div
            ref={dividerRef}
            className={classNames('z-20 w-1.5px cursor-ew-resize relative', {
              'bg-[radial-gradient(50%_50%_at_50%_50%,_var(--color-primary)_0%,_#FFFFFF_100%)]': isSeparatorUsed,
              'bg-gray-mercury': !isSeparatorUsed,
            })}
            onMouseDown={handleMouseDown}
          >
            <TooltipWrapper interactive placement='right' content={translate('all.splitPanel.tooltip')}>
              <div
                className={classNames(
                  'absolute inset-1/2 w-12 transform -translate-x-1/2 -translate-y-1/2 rounded-full flex items-center justify-center',
                  {
                    'h-9 bg-white border-[1.5px] border-primary': isSeparatorUsed,
                    'h-12 bg-white-snow': !isSeparatorUsed,
                  },
                )}
              >
                <HorizontalArrowsIcon color={isSeparatorUsed ? 'var(--color-primary)' : '#696969'} />
              </div>
            </TooltipWrapper>
          </div>
          <div style={{ width: `${100 - leftWidth}%` }} className='overflow-hidden'>
            {right}
          </div>
        </div>
      )}
    </ReportBlockBody>
  );
}
