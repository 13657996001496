import { useIntersectionObserverCtx } from '@va/util/misc';
import { useRef } from 'react';
import { Key, SWRResponse } from 'swr';
import { useFetchData, useFetchDataParams } from './useFetchData';

/**
 * @description This hook depends on IntersectionObserver context, in
 * order for the lazy loading to take effect the IntersectionObserver context must be setup correctly around the component
 * @description Lazy loading scenarios:
 * 1. On initial page load, data is not fetched until first intersection
 * 2. If the key changes after the initial fetch ( due to a change in a global filter for example ),
 *  the new request will not be triggered until the component is intersecting the user's view again.
 */
export const useLazyDataFetch = <T, D = Error>(
  key: useFetchDataParams<T, D>['key'],
  config?: useFetchDataParams<T, D>['config'],
  mapper?: useFetchDataParams<T, D>['mapper'],
  fetchFunc?: useFetchDataParams<T, D>['fetchFunc'],
): SWRResponse<T, D> => {
  const { isIntersecting } = useIntersectionObserverCtx();

  const keyRef = useRef<Key | null>(null);

  if (isIntersecting || isIntersecting === undefined) {
    keyRef.current = key;
  }

  return useFetchData(keyRef.current, config, mapper, fetchFunc);
};
