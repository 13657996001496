import { ArrowLeft, ArrowRight } from '@va/svg-visa-icons';
import classNames from 'classnames';
import React from 'react';
import { SCROLL_DIRECTION } from './enums';

export const SideArrowScroll: React.FC<{
  scrollDirection: SCROLL_DIRECTION;
  canScroll: boolean;
  updateScrollValues: () => void;
  scrollFn: () => void;
}> = ({ scrollDirection, canScroll = false, updateScrollValues, scrollFn }) => {
  const rotationDeg = scrollDirection === SCROLL_DIRECTION.RIGHT ? 270 : 90;
  const ArrowIconComponent = scrollDirection === SCROLL_DIRECTION.RIGHT ? ArrowRight : ArrowLeft;
  return (
    <div
      onMouseEnter={() => updateScrollValues && updateScrollValues()}
      className={classNames(
        'absolute top-0 w-10 h-full flex align-center cursor-pointer',
        'transition-opacity duration-150',
        { 'opacity-10 hover:opacity-25 z-10': canScroll },
        { 'opacity-0 -z-1': !canScroll },
        { 'right-0': scrollDirection === SCROLL_DIRECTION.RIGHT },
        { 'left-0': scrollDirection === SCROLL_DIRECTION.LEFT },
      )}
      style={{
        background: `linear-gradient(${rotationDeg}deg, rgba(2,0,36,1) 0%, rgba(246,246,246,0) 100%, rgba(0,212,255,1) 100%)`,
      }}
      onClick={() => scrollFn && scrollFn()}
    >
      <ArrowIconComponent className={'w-10'} />
    </div>
  );
};
