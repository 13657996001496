import { useCallback, useEffect, useState } from 'react';

export const useIsHovering = (elementRef: React.RefObject<HTMLElement | null | undefined>) => {
  const [isHovering, setIsHovering] = useState(false);

  const onMouseEnter = useCallback(() => {
    setIsHovering(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsHovering(false);
  }, []);

  useEffect(() => {
    if (!elementRef.current) return;
    const element = elementRef.current;
    element.addEventListener('mouseenter', onMouseEnter);
    element.addEventListener('mouseleave', onMouseLeave);
    return () => {
      element.removeEventListener('mouseenter', onMouseEnter);
      element.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [elementRef, onMouseEnter, onMouseLeave]);

  return isHovering;
};
