import { Component } from 'react';

import { modalName } from '@va/standalone/shared/constants';
import ManagePaymentMethod from '../components/ManagePaymentMethod';

export default class UpdateCardDetails extends Component {
  render() {
    return <ManagePaymentMethod modalName={modalName.UPDATE_CARD_DETAILS} />;
  }
}
