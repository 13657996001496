import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';
import { MappedWebsiteResponse, useWebsites } from '../agency-white-label-v2/agency-white-label-apiClient';

type WebsiteFilterQueryType = {
  start: number;
  length: number;
  withContributorRequests: boolean;
  withSubscription: boolean;
  withSharedWebsites?: boolean;
};

type ManageWebsitesTypes = {
  offset: number;
  setOffset: (value: number) => void;
  websiteFilterQuery: WebsiteFilterQueryType;
  setWebsiteFilterQuery: React.Dispatch<React.SetStateAction<WebsiteFilterQueryType>>;
  websitesData: MappedWebsiteResponse | undefined;
  isLoading: boolean;
  getWebsites: Function;
  totalWebsites: number;
  totalWhiteLabeled: number;
};

const ManageWebsitesContext = React.createContext({} as ManageWebsitesTypes);

const ManageWebsitesContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [offset, setOffset] = useState(0);
  const [websiteFilterQuery, setWebsiteFilterQuery] = useState<WebsiteFilterQueryType>({
    start: 0,
    length: 12,
    withContributorRequests: true,
    withSharedWebsites: true,
    withSubscription: true,
  });

  const { data, isLoading, mutate: getWebsites } = useWebsites(websiteFilterQuery);

  const totalWebsites = useMemo(() => data?.total ?? 0, [data]);

  return (
    <ManageWebsitesContext.Provider
      value={{
        offset,
        setOffset,
        websiteFilterQuery,
        setWebsiteFilterQuery,
        websitesData: data,
        isLoading,
        getWebsites,
        totalWebsites,
        totalWhiteLabeled: data?.totalWhiteLabeled ?? 0,
      }}
    >
      {children}
    </ManageWebsitesContext.Provider>
  );
};

export default ManageWebsitesContextProvider;

export const useManageWebsitesContext = () => useContext(ManageWebsitesContext);
