import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

export const getQueryStringProcessed = (initialQueryString, otherQueryParams = {}) => {
  const paramsArr = (initialQueryString && initialQueryString.substring(1).split('&')) || [];
  let params = {};

  paramsArr.forEach((item) => {
    const itemArr = item.split('=');
    const key = itemArr[0];
    itemArr.shift();
    params[key] = itemArr.join('=');
  });

  params = Object.assign(params, otherQueryParams);

  let processedQueryString = '?';
  for (let item in params) {
    if (
      otherQueryParams.visitorKey ||
      item !== 'visitorKey' ||
      otherQueryParams.campaignKey ||
      item !== 'campaignKey'
    ) {
      processedQueryString = processedQueryString.concat(item, '=', params[item], '&');
    }
  }

  return processedQueryString.slice(0, -1);
};

const RouterLink = (props) => {
  // extract 'to' property so we don't override the one with the query string
  // eslint-disable-next-line no-unused-vars
  const { to, location, children, queryParams, staticContext, ...others } = props;

  return (
    <Link
      to={
        queryParams
          ? `${to}${getQueryStringProcessed(location.search, queryParams)}`
          : {
              pathname: to,
              search: getQueryStringProcessed(location.search, queryParams),
            }
      }
      {...others}
    >
      {children}
    </Link>
  );
};

RouterLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  queryParams: PropTypes.object,
  staticContext: PropTypes.any,
};

export default withRouter(RouterLink);
