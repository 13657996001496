import { useMutateOnRefresh } from '@va/dashboard/util-hooks';
import { useLocale, useTranslate } from '@va/localization';
import { ChartContext, ChartTile, ChartVisualizationComponentProps } from '@va/types/charts';
import { BaseTimeseriesDataset } from '@va/types/time-series';
import { useReportBlockCtx } from '@va/ui/components/report-block';
import { externalChartTooltip } from '@va/ui/design-system';
import { chartHelpers, formatLineDiagramDateLabels } from '@va/util/helpers';
import { useHiddenKPIs } from '@va/util/hooks';
import { ChartData, ChartTypeRegistry } from 'chart.js';
import React, { useCallback, useMemo } from 'react';

export const useCommonChartViewData = <T extends keyof ChartTypeRegistry>({
  useCurrentData,
  usePreviousData,
  getHeadingValueFunc,
  getValueDetailsFunc,
  tooltipTitle,
  tooltipSubtitle,
  getTooltipValueFunc,
  reversePercentageColors,
  renderTileContextMenu,
  getExtraDatasetConfig,
  tooltipMode,
  renderErrorComponent,
}: ChartVisualizationComponentProps & {
  renderTileContextMenu?: (key: string) => React.ReactNode;
  getExtraDatasetConfig?: (dataset: BaseTimeseriesDataset<any>) => Record<string, any>;
}) => {
  const current = useCurrentData();
  const previous = usePreviousData();

  const { isManualRefreshing } = useMutateOnRefresh(() => {
    current.mutate();
    previous.mutate();
  });
  const currentData = useMemo(() => current.data, [current.data]);
  const previousData = useMemo(() => previous.data, [previous.data]);

  const isLoading = current.isLoading || previous.isLoading || isManualRefreshing;

  const { id, showPrevious } = useReportBlockCtx();
  const { locale } = useLocale();

  const { hiddenKPIs, toggleKpiVisibility } = useHiddenKPIs({ reportBlockId: id });

  const translate = useTranslate();

  const tiles: ChartTile[] = useMemo(() => {
    if (!currentData || !previousData) return [];

    return chartHelpers.buildChartTiles({
      currentDatasets: currentData.items,
      currentTotal: currentData.total,
      hiddenDatasets: hiddenKPIs,
      previousTotal: previousData.total,
      locale: locale,
      getHeadingValueFunc: getHeadingValueFunc,
      getValueDetailsFunc,
      renderContextMenu: renderTileContextMenu,
    });
  }, [currentData, getHeadingValueFunc, getValueDetailsFunc, hiddenKPIs, locale, previousData, renderTileContextMenu]);

  const chartData: ChartData<T> = useMemo(() => {
    if (!currentData || !previousData) return { datasets: [], labels: [] };
    return {
      labels: currentData.chartLabels ? formatLineDiagramDateLabels(currentData.chartLabels, locale) : [],
      datasets: chartHelpers.buildChartDatasets({
        currentDatasets: currentData.items,
        previousDatasets: previousData.items,
        hiddenDatasets: hiddenKPIs,
        isPreviousEnabled: showPrevious,
        getExtraConfig: getExtraDatasetConfig,
      }),
    } as ChartData<T>;
  }, [currentData, getExtraDatasetConfig, hiddenKPIs, locale, previousData, showPrevious]);

  const tooltipFn = useCallback(
    (ctx: ChartContext) => {
      return externalChartTooltip(ctx, {
        title: tooltipTitle ?? translate('dashboard.lineChart.tooltip.title'),
        subtitle: tooltipSubtitle ?? translate('dashboard.lineChart.tooltip.subtitle'),
        showPrevious: showPrevious,
        currentTimeIntervals: currentData?.timeIntervals ?? [],
        previousTimeIntervals: previousData?.timeIntervals ?? [],
        valueTransformer: getTooltipValueFunc,
        reversePercentageColors: reversePercentageColors,
        tooltipMode: tooltipMode,
      });
    },
    [
      currentData?.timeIntervals,
      getTooltipValueFunc,
      previousData?.timeIntervals,
      reversePercentageColors,
      showPrevious,
      tooltipMode,
      tooltipSubtitle,
      tooltipTitle,
      translate,
    ],
  );

  const errorComponent = useMemo(() => {
    return renderErrorComponent
      ? renderErrorComponent(current.error ?? previous.error ?? undefined, isLoading)
      : undefined;
  }, [current.error, isLoading, previous.error, renderErrorComponent]);

  return { tooltipFn, chartData, tiles, toggleKpiVisibility, isLoading, errorComponent };
};
