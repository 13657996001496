import { getBusinessSectorOptions } from '@va/util/helpers';

const EmailTemplateCustomOffer = (values, translate) => {
  const businessSectorOption = getBusinessSectorOptions(translate).find((item) => item.value === values['industry']);
  const traffic = values['traffic'];
  const email = values['email'].trim();
  const companyName = values['companyName'];
  const jobRole = values['jobRole'];
  const additionalNotes = values['additionalNotes'];

  let emailBody = [];

  const intro = translate('customOffer.email.body.greeting');
  const withJobRole =
    jobRole &&
    companyName &&
    translate('customOffer.email.body.withJobRole', { jobRole: jobRole, companyName: companyName });
  const requirement = translate('customOffer.email.body.requirement', {
    traffic: traffic,
    industry: businessSectorOption.label,
  });
  const notes = additionalNotes && translate('customOffer.email.body.notes', { additionalNotes: additionalNotes });
  const emailInfo = translate('customOffer.email.body.sendOffer', { email: email });
  const closeEmail = translate('customOffer.email.body.thankYou');

  emailBody.push(intro, withJobRole, requirement, notes, emailInfo, closeEmail);

  return encodeURIComponent(emailBody.join('\n'));
};

export default EmailTemplateCustomOffer;
