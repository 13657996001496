import { EmailReportsToggle } from '@va/dashboard/feature/general-settings';
import { NewsletterToggle } from '@va/dashboard/feature/newsletter';
import { FooterButtons } from '@va/dashboard/shared/modals/AnnouncementModal/AnnouncementModalFooterButtons';
import { useTranslate } from '@va/localization';
import { AnnouncementContentType } from '@va/types/announcements';
import { fontWeights, Heading3 } from '@va/ui/design-system';
import { useWindowDimensions } from '@va/util/hooks';
import { Link } from '@va/util/router';
import classNames from 'classnames';
import Checkbox from 'common/components/misc/Checkbox';
import CopyableBox from 'common/components/misc/CopyableBox';
import parse, { DOMNode, domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';
import { useMemo } from 'react';
import './AnnouncementModal.scss';

type AnnouncementModalProps = {
  onClose: () => void;
  content: AnnouncementContentType;
  isChecked: boolean;
  onCheckboxChange: () => void;
  modalId: string;
};

const AnnouncementModalV2 = ({ onClose, content, isChecked, onCheckboxChange, modalId }: AnnouncementModalProps) => {
  const translate = useTranslate();
  const { height, width } = useWindowDimensions();

  const shouldSplitInTwoParts = useMemo(() => {
    return (height <= 640 && width > 1100) || width > height;
  }, [height, width]);

  const htmlParseOptions: HTMLReactParserOptions = {
    replace: (domNode) => {
      const elementDomNode = domNode as Element;
      if (elementDomNode.type === 'tag' && elementDomNode.name === 'internal-link') {
        return <Link to={elementDomNode.attribs.to}>{domToReact(elementDomNode.children as DOMNode[])}</Link>;
      }
    },
  };

  const showVideo = useMemo(() => content.video?.url, [content.video?.url]);

  const videoOrImageContent = useMemo(() => {
    return (
      <>
        {showVideo && (
          <div className='flex h-full aspect-video'>
            <video controls src={content.video?.url} poster={content.video?.poster} className='min-w-full bg-black'>
              Sorry, your browser doesn't support embedded videos, but don't worry, you can
              <a href={content.video?.url}>download it</a>
              and watch it with your favorite video player!
            </video>
          </div>
        )}
        {!showVideo && content.image && (
          <ModalImage
            imageUrl={content.image}
            targetUrl={content.target_url}
            className={
              !shouldSplitInTwoParts
                ? 'h-full aspect-square rounded-12 overflow-hidden max-w-[350px] mx-auto min-h-200'
                : 'h-full aspect-square min-h-200'
            }
          />
        )}
      </>
    );
  }, [content.image, content.target_url, content.video?.poster, content.video?.url, shouldSplitInTwoParts, showVideo]);

  const showNewsletterToggle = useMemo(() => content.show_newsletter_toggle, [content.show_newsletter_toggle]);
  const showEmailReportsToggle = useMemo(() => content.show_email_reports_toggle, [content.show_email_reports_toggle]);

  return (
    <div
      className={classNames('flex pr-6 rounded-48px', {
        'flex-row h-[500px] gap-6': shouldSplitInTwoParts,
        'max-w-[1350px]': shouldSplitInTwoParts && showVideo,
        'max-w-[1045px]': shouldSplitInTwoParts && !showVideo,
        'flex pl-6 flex-col max-w-[540px]': !shouldSplitInTwoParts,
      })}
    >
      {shouldSplitInTwoParts && <div className='flex h-full justify-center items-center'>{videoOrImageContent}</div>}

      <div className='flex flex-col'>
        <div className={classNames('flex flex-col flex-1', { 'overflow-hidden': shouldSplitInTwoParts })}>
          <div
            className={classNames('sticky top-0 bg-white z-40 w-full mb-3 pt-6 min-h-60px flex', {
              'min-h-fit': content.headline?.trim()?.length,
            })}
          >
            <Heading3 weight={fontWeights.semibold} colorClassName={'text-gray-charcoal'}>
              {content.headline && parse(content.headline, htmlParseOptions)}
            </Heading3>
          </div>
          {!shouldSplitInTwoParts && videoOrImageContent}
          <div className={classNames('flex flex-col gap-1.5px mb-3', { 'mt-6': !shouldSplitInTwoParts })}>
            {showEmailReportsToggle && <EmailReportsToggle />}
            {showNewsletterToggle && <NewsletterToggle />}
          </div>
          <div
            className={classNames('flex flex-col gap-6', {
              'overflow-y-scroll scrollbar scrollbar-thumb': shouldSplitInTwoParts,
            })}
          >
            <div className='announcement-modal-text'>{parse(content.text, htmlParseOptions)}</div>
            {content.voucher_code && (
              <div className='w-4/5 xs:w-full mx-auto my-0 mt-2'>
                <CopyableBox text={content.voucher_code} />
              </div>
            )}
            {content.confirmation_needed && (
              <Checkbox
                id='read-id'
                label={content.confirmation_text || translate('modal.announcement.understood')}
                isChecked={isChecked}
                onCheckboxChange={onCheckboxChange}
              />
            )}
          </div>
        </div>
        <FooterButtons content={content} onClose={onClose} isChecked={isChecked} modalId={modalId} />
      </div>
    </div>
  );
};

export default AnnouncementModalV2;

const ModalImage = ({
  imageUrl,
  targetUrl,
  className,
}: {
  imageUrl: string;
  targetUrl: string;
  className?: string;
}) => {
  if (!imageUrl) return null;
  const imgContent = <img src={imageUrl} alt={'announcement-img'} className='h-full object-cover' />;
  if (targetUrl) {
    return (
      <a target='_blank' rel='noreferrer' href={targetUrl} className={className}>
        {imgContent}
      </a>
    );
  } else {
    return <div className={className}>{imgContent}</div>;
  }
};
