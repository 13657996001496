import { LatestVisitorsTable } from '@va/dashboard/shared/visitors';
import { useTranslate } from '@va/localization';
import { AddFilterButton, AppliedFilters, FiltersContextProvider } from '@va/shared/feature-filters';
import { DataViewOption } from '@va/types/report-block';
import { ReportBlock, getReportBlockPaginationId } from '@va/ui/components/report-block';
import { memo, useMemo } from 'react';
import { ExportButtonContainer } from './ExportButtonContainer';
import { useLatestVisitorsFilterOptions } from './useLatestVisitorsFilterOptions';

export const LatestVisitorsTableReport = memo(({ id }: { id: string }) => {
  const filters = useLatestVisitorsFilterOptions();
  const translate = useTranslate();

  const nodes = useMemo(
    () => ({
      filterButton: <AddFilterButton />,
      appliedFilters: <AppliedFilters />,
      downloadButton: <ExportButtonContainer />,
      pagination: <div id={getReportBlockPaginationId(id)}></div>,
    }),
    [id],
  );

  const visualizationComponents = useMemo(() => {
    return {
      [DataViewOption.table]: () => <LatestVisitorsTable paginationContainerId={getReportBlockPaginationId(id)} />,
    };
  }, [id]);

  return (
    <FiltersContextProvider reportBlockId={id} allFilterOptions={filters}>
      <ReportBlock
        id={id}
        title={translate('card.latestVisitors.title')}
        titleTooltip={translate('card.latestVisitors.info.tooltip')}
        nodes={nodes}
        visualization={{ selectedView: DataViewOption.table }}
        visualizationComponents={visualizationComponents}
      />
    </FiltersContextProvider>
  );
});
