import { IconProps } from '.';

export const CloseIconFilled = ({ className = '', color = '#EA2A0C' }: IconProps) => {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12' cy='12' r='11.25' stroke='#EA2A0C' stroke-width='1.5' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.5 12C19.5 16.1421 16.1421 19.5 12 19.5C7.85786 19.5 4.5 16.1421 4.5 12C4.5 7.85786 7.85786 4.5 12 4.5C16.1421 4.5 19.5 7.85786 19.5 12ZM10.9393 12L8.46967 9.53033L9.53033 8.46967L12 10.9393L14.4697 8.46967L15.5303 9.53033L13.0607 12L15.5303 14.4697L14.4697 15.5303L12 13.0607L9.53033 15.5303L8.46967 14.4697L10.9393 12Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.5 12C19.5 16.1421 16.1421 19.5 12 19.5C7.85786 19.5 4.5 16.1421 4.5 12C4.5 7.85786 7.85786 4.5 12 4.5C16.1421 4.5 19.5 7.85786 19.5 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z'
        fill={color}
      />
    </svg>
  );
};
