import { modalName, passwordRegex, validationTranslationKeys } from '@va/constants';
import { closeModal } from '@va/dashboard/actions/ui';
import { isModalOpen } from '@va/dashboard/selectors/ui';
import { useAddNotification } from '@va/dashboard/util-hooks';
import { useTranslate } from '@va/localization';
import { useUpdateAccountSettings } from '@va/standalone/shared/api-client/Account';
import { ModalFormWrapper } from '@va/ui/components/modals';
import { Paragraph, ProgressBar, TextInput, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { getPasswordStrength, getPasswordStrengthKey } from '@va/util/helpers';
import { useFormik } from 'formik';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, ref, string } from 'yup';

enum FormFieldsNames {
  password = 'password',
  repeatPassword = 'repeatPassword',
}

const ChangePasswordModal = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const { showSuccessNotification } = useAddNotification();
  const { isLoading, execute, isSucceeded, error, reset } = useUpdateAccountSettings();
  const isOpen = useSelector((state) => isModalOpen(state, modalName.changePassword));

  const { values, errors, handleChange, setFieldValue, touched, handleSubmit, resetForm } = useFormik({
    initialValues: {
      [FormFieldsNames.password]: '',
      [FormFieldsNames.repeatPassword]: '',
    },
    validationSchema: object().shape({
      [FormFieldsNames.password]: string()
        .nullable()
        .matches(passwordRegex, validationTranslationKeys.passwordStrength)
        .required(translate('form.requiredField')),
      [FormFieldsNames.repeatPassword]: string()
        .nullable()
        .oneOf([ref('password'), null], translate(validationTranslationKeys.passwordDoNotMatch))
        .required(translate('form.requiredField')),
    }),
    onSubmit: (values) => {
      execute({ password: values.password });
    },
  });

  const handleClose = useCallback(() => {
    resetForm();
    reset();
    dispatch(closeModal(modalName.changePassword));
  }, [dispatch, reset, resetForm]);

  useEffect(() => {
    if (isSucceeded) {
      showSuccessNotification();
      handleClose();
    }
  }, [isSucceeded, handleClose, showSuccessNotification]);

  return (
    <ModalFormWrapper
      title={translate(`modal.changePassword.newPassword.title`)}
      isOpen={isOpen}
      onClose={() => handleClose()}
      onSubmit={handleSubmit}
      saveButtonProps={{
        text: isLoading ? translate('button.isLoading') : translate(`button.changePassword`),
      }}
      isSaveButtonDisabled={isLoading}
    >
      <div className='flex flex-col p-3 gap-3'>
        <TextInput
          id='password'
          label={translate('resetPassword.form.password.placeholder')}
          name={FormFieldsNames.password}
          type='password'
          required
          onChange={handleChange}
          clearField={() => {
            setFieldValue(FormFieldsNames.password, '');
          }}
          value={values.password}
          autoComplete='new-password'
          error={errors.password && touched.password ? translate(errors.password) : undefined}
          showTogglePasswordVisibilityButton
          autoFocus
        />

        <TextInput
          id='password'
          label={translate('resetPassword.form.repeatPassword.placeholder')}
          name={FormFieldsNames.repeatPassword}
          type='password'
          required
          onChange={handleChange}
          clearField={() => {
            setFieldValue(FormFieldsNames.repeatPassword, '');
          }}
          value={values.repeatPassword}
          autoComplete='new-password'
          error={errors.repeatPassword && touched.repeatPassword ? translate(errors.repeatPassword) : undefined}
          showTogglePasswordVisibilityButton
        />

        <div className='mt-6px flex flex-column'>
          <div className='flex justify-between items-center mb-4'>
            <Paragraph size={paragraphSizes.large} weight={fontWeights.semibold} colorClassName='text-gray-devil'>
              {translate('changePassword.password.strength')}
            </Paragraph>
            <Paragraph weight={fontWeights.semibold} colorClassName='text-gray-dusty'>
              {translate(getPasswordStrengthKey(values[FormFieldsNames.password]))}
            </Paragraph>
          </div>
          <ProgressBar
            progress={[
              {
                percentage: getPasswordStrength(values[FormFieldsNames.password]) * 25,
                color: window.PRIMARY_COLOR,
              },
            ]}
          />
        </div>

        {error && (
          <Paragraph weight={fontWeights.medium} className='ml-2 text-negative'>
            {translate('form.generalError')}
          </Paragraph>
        )}
      </div>
    </ModalFormWrapper>
  );
};

export default ChangePasswordModal;
