import { TrackingCodeIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Heading3, IconWithBackground, Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';

export const tKeys = {
  title: 'wix.appReinstallRequired.title',
  steps: ['wix.appReinstallRequired.step1', 'wix.appReinstallRequired.step2'],
  info1: 'wix.appReinstallRequired.info1',
  info2: 'wix.appReinstallRequired.info2',
  info3: 'wix.appReinstallRequired.info3',
};

export const RequireAppReinstall = () => {
  const translate = useTranslate();
  return (
    <div>
      <div className='mb-3 mx-auto w-screen max-w-[300px] aspect-square rounded-full bg-red-pure flex items-center justify-center'>
        <TrackingCodeIcon color='white' className='w-1/2 h-1/2' />
      </div>
      <Heading3 className='mb-3 text-gray-cod text-center' weight={fontWeights.semibold}>
        {translate(tKeys.title)}
      </Heading3>
      <div>
        <Paragraph size={paragraphSizes.large} className='inline' weight={fontWeights.bold}>
          {translate(tKeys.info1)}
        </Paragraph>
        <Paragraph size={paragraphSizes.large} className='inline ml-1'>
          {translate(tKeys.info2)}
        </Paragraph>
      </div>
      <div className='space-y-2 my-3'>
        {tKeys.steps.map((key, index) => {
          return (
            <div key={index} className='flex items-center gap-3'>
              <IconWithBackground className='bg-primary' icon={() => <div className='text-white'>{index + 1}</div>} />
              <Paragraph weight={fontWeights.medium}>{translate(key)}</Paragraph>
            </div>
          );
        })}
      </div>
      <Paragraph size={paragraphSizes.large} weight={fontWeights.medium}>
        {translate(tKeys.info3)}
      </Paragraph>
    </div>
  );
};
