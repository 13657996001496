import { TEST_IDS } from '@va/constants';
import { CommentsIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, fontWeights, paragraphSizes, ParagraphWithTooltip, RadioInputButton } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { DropdownArrow } from '@va/util/components';
import classNames from 'classnames';
import { useMemo, useState } from 'react';

type SelectItemsPerPageDropdownProps = {
  pageSize: number;
  setPageSize: (newPageSize: number) => void;
  total: number;
  options?: Array<number>;
  disabled?: boolean;
  size?: 'small' | 'large';
};

export const SelectItemsPerPageDropdown = ({
  pageSize,
  setPageSize,
  total,
  options = [6, 12, 18, 24, 36, 48],
  disabled = false,
  size = 'large',
}: SelectItemsPerPageDropdownProps) => {
  const translate = useTranslate();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const selectedPageSize = useMemo(() => {
    const pageSizeIndex = options.indexOf(pageSize);
    const closestPageSizeValue = options[pageSizeIndex - 1];
    if (total < pageSize && pageSizeIndex > 0 && total < closestPageSizeValue) {
      const smallerOptions = options.filter((option) => option <= total);
      return smallerOptions.length ? Math.max(...smallerOptions) : options[0];
    }
    return pageSize;
  }, [pageSize, total, options]);

  const pageSizeOptions = useMemo(
    () =>
      options.map((option) => {
        return { label: translate(`pagination.pageSize.itemsCount`, { items: option }), value: option };
      }),
    [translate, options],
  );

  return (
    <TooltipWrapper content={translate('tooltip.label.selectPageSize')}>
      <div>
        <Button
          text={selectedPageSize}
          bottomIcon={
            <DropdownArrow
              iconClassName={classNames({
                'w-1.5': size === 'small',
                'w-2.5': size === 'large',
              })}
              open={isDropdownVisible}
              color='#696969'
            />
          }
          color='tertiary'
          tooltip={
            <DropdownOptions
              selectedOption={selectedPageSize}
              options={pageSizeOptions}
              onChange={(value: number) => {
                setPageSize(value);
                setDropdownVisible(false);
              }}
              total={total}
            />
          }
          tooltipProps={{
            trigger: 'click',
            tooltipClassNames: '!rounded-18 w-[240px] border-2 border-gray-concrete',
            arrow: false,
            open: isDropdownVisible,
            onOpenChange: (open) => setDropdownVisible(open),
            useDefaultStyle: false,
            disabled: disabled,
          }}
          className={classNames('cursor-pointer text-xs', {
            'w-9 h-9 px-6px': size === 'small',
          })}
          disabled={disabled}
          data-testid={TEST_IDS.generic.table.rowsPerPageDropdown}
          size={size}
        />
      </div>
    </TooltipWrapper>
  );
};

const DropdownOptions = ({
  selectedOption,
  options,
  onChange,
  total = 0,
}: {
  selectedOption: number;
  options: { label: string | number; value: number }[];
  onChange: (value: number) => void;
  total?: number;
}) => {
  return (
    <div className='flex flex-col bg-white p-2' data-testid={TEST_IDS.generic.table.rowsPerPageTooltip}>
      {options.map(({ label, value }, index, arr) => {
        const isSelected = selectedOption === value;
        const isDisabled = total < value && index > 0 && total < arr[index - 1]?.value;
        return (
          <div
            key={value}
            className={classNames('flex items-center justify-between p-3 rounded-xl truncate', {
              'opacity-50 cursor-not-allowed': isDisabled,
              'hover:bg-gray-concrete active:bg-gray-mercury cursor-pointer': !isDisabled,
            })}
            onClick={() => !isDisabled && onChange(value)}
            data-testid={`${TEST_IDS.generic.table.rowsPerPageOption}-${value}`}
          >
            <div className='flex items-center truncate'>
              <CommentsIcon className='shrink-0' color='#969696' />
              <ParagraphWithTooltip
                colorClassName='text-gray-charcoal'
                className='ml-9px truncate'
                size={paragraphSizes.tiny}
                weight={fontWeights.medium}
              >
                {label}
              </ParagraphWithTooltip>
            </div>
            <RadioInputButton selected={isSelected} className='w-6 h-6 ml-3 shrink-0' />
          </div>
        );
      })}
    </div>
  );
};
