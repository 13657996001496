import { LineChartDataset, LineChartTimestamp, LineChartTooltipDataset } from '@va/types/charts';
import { TimePeriod } from '@va/types/time';
import { ChartCategoryFormat } from '@va/ui/design-system';
import { getPercentageChange } from '@va/util/helpers';

export type DatasetItem = {
  total: number;
  previousTotal: number;
  dataset: LineChartDataset;
  previousDataset: LineChartDataset;
  label?: string;
};

export const buildChartDatasets = (
  items: Record<string, DatasetItem>,
  visibleItems: string[],
  showPrevious: boolean,
): LineChartDataset[] => {
  return Object.keys(items)
    .map((key) => {
      const item = items[key];
      if (showPrevious) {
        return [
          { ...item.dataset, hidden: !visibleItems.includes(key), isCurrent: true },
          { ...item.previousDataset, hidden: !visibleItems.includes(key), isCurrent: false },
        ];
      }
      return [{ ...item.dataset, hidden: !visibleItems.includes(key), isCurrent: true }];
    })
    .flat();
};

export const buildTooltipDatasets = (items: Record<string, DatasetItem>, visibleItems: string[]) => {
  return Object.keys(items).reduce(
    (acc, key) => {
      const item = items[key];

      if (!visibleItems.includes(key)) return acc;
      acc.current.push({ ...item.dataset, isCurrent: true });
      acc.previous.push({ ...item.previousDataset, isCurrent: false });

      return acc;
    },
    { current: [], previous: [] } as { current: LineChartTooltipDataset[]; previous: LineChartTooltipDataset[] },
  );
};

export const buildChartCategories = (
  items: Record<string, DatasetItem & { format?: string }>,
  colorMap: Record<string, string>,
) => {
  return Object.keys(items).map((key) => {
    const { total, previousTotal, label, format } = items[key];

    return {
      translationKey: label ?? key,
      value: total,
      percentage: getPercentageChange(previousTotal, total),
      color: colorMap[key],
      key: key,
      format: format as ChartCategoryFormat,
      id: key,
    };
  });
};

export const buildTooltipDates = (current: LineChartTimestamp[], previous: LineChartTimestamp[], unit: TimePeriod) => {
  return {
    current: mapTooltipLabels(current || [], unit),
    previous: mapTooltipLabels(previous || [], unit),
  };
};

export function mapTooltipLabels(labels: Array<LineChartTimestamp>, unit: TimePeriod) {
  if (unit === TimePeriod.day) {
    return labels.map(({ from }) => ({
      tooltip: from,
      axis: from,
    }));
  }

  return labels.map(({ from, until }) => ({
    tooltip: `${from} - ${until}`,
    axis: `${from} - ${until}`,
  }));
}

const DefaultDataset: LineChartDataset = {
  data: [],
  label: 'default',
};

export function mapChartLabels(labels: Array<LineChartTimestamp>) {
  return labels.map(({ from }) => from);
}

export const checkDataset = (dataset: LineChartDataset | undefined | null) => {
  if (!dataset) return DefaultDataset;
  return dataset;
};
