import React from 'react';
import { IconProps } from '.';

export const SuccessIcon = (props: IconProps) => {
  return (
    <svg
      width='60'
      height='61'
      viewBox='0 0 60 61'
      fill={props.color || 'black'}
      xmlns='http://www.w3.org/2000/svg'
      className={props.className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M57 30.5C57 45.4117 44.9117 57.5 30 57.5C15.0883 57.5 3 45.4117 3 30.5C3 15.5883 15.0883 3.5 30 3.5C44.9117 3.5 57 15.5883 57 30.5ZM60 30.5C60 47.0685 46.5685 60.5 30 60.5C13.4315 60.5 0 47.0685 0 30.5C0 13.9315 13.4315 0.5 30 0.5C46.5685 0.5 60 13.9315 60 30.5ZM40.0607 25.5607L37.9393 23.4393L27 34.3787L22.0607 29.4393L19.9393 31.5607L25.9393 37.5607L27 38.6213L28.0607 37.5607L40.0607 25.5607Z'
      />
    </svg>
  );
};
