import { TEST_IDS } from '@va/constants';
import { EraseIcon, SearchIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { CommonProps } from '@va/types/component';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import './SearchFilterV2.scss';

export const SearchFilterV2 = ({
  onChange,
  placeholder,
  shouldClearField = false,
  ...rest
}: {
  onChange: (value: string) => void;
  placeholder?: string;
  shouldClearField?: boolean;
} & CommonProps) => {
  const [searchValue, setSearchValue] = useState('');
  const translate = useTranslate();

  const debouncedOnChange = useDebouncedCallback((value: string) => {
    onChange(value);
  }, 250);

  useEffect(() => {
    if (!shouldClearField) return;
    setSearchValue('');
  }, [shouldClearField]);

  return (
    <div
      id='searchInput'
      className='hover:bg-gray-concrete px-3 rounded-lg flex items-center gap-3 h-9 text-gray-charcoal bg-white-snow'
    >
      <SearchIcon className='shrink-0 w-4 h-4' color={'#696969'} />
      <input
        data-testid={rest['data-testid'] ?? TEST_IDS.generic.inputs.search}
        value={searchValue}
        onChange={(event) => {
          const value = event.target.value;
          setSearchValue(value);
          debouncedOnChange(value);
        }}
        type='text'
        className='bg-transparent text-gray-charcoal text-xs font-medium flex-grow'
        placeholder={placeholder ? placeholder : translate('filter.search')}
      />
      <button
        className='ml-auto w-4 h-4'
        onClick={() => {
          setSearchValue('');
          onChange('');
        }}
        data-testid={TEST_IDS.generic.buttons.erase}
      >
        <EraseIcon className='w-full h-full' color={'#696969'} />
      </button>
    </div>
  );
};
