import React from 'react';
import { IconProps } from '.';

export const DownloadIcon = ({ className = '', color }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.25 0V10.6293L5.03033 7.40967L3.96967 8.47033L8.46967 12.9703L9 13.5007L9.53033 12.9703L14.0303 8.47033L12.9697 7.40967L9.75 10.6293V0H8.25ZM1.5 10.5V13.5C1.5 15.1569 2.84315 16.5 4.5 16.5H13.5C15.1569 16.5 16.5 15.1569 16.5 13.5V10.5H18V13.5C18 15.9853 15.9853 18 13.5 18H4.5C2.01472 18 0 15.9853 0 13.5V10.5H1.5Z'
        fill={color ?? 'var(--color-primary)'}
      />
    </svg>
  );
};


