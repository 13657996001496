import { useTranslate } from '@va/localization';
import { Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import classNames from 'classnames';
import { forwardRef } from 'react';
import { CompanyType } from './types';

type CompanyRevealLabelProps = {
  companyName: string | null;
  companyType: CompanyType | null;
  className?: string;
};

export const CompanyRevealLabel = forwardRef<HTMLParagraphElement, CompanyRevealLabelProps>(
  ({ companyName, companyType, className }, ref) => {
    const translate = useTranslate();

    const isISP = companyType === CompanyType.ISP;
    const label = isISP
      ? translate('all.latestVisitors.ipCell.viaIsp', { companyName })
      : translate('all.latestVisitors.ipCell.fromCompanyName', { companyName });

    return (
      <Paragraph
        ref={ref}
        size={paragraphSizes.tiny}
        weight={isISP ? fontWeights.medium : fontWeights.bold}
        className={classNames('text-primary text-left', className)}
      >
        {label}
      </Paragraph>
    );
  },
);
