import { TEST_IDS } from '@va/constants';
import { useLocale } from '@va/localization';
import { addNumberSeparator } from '@va/util/helpers';

type CountCircleProps = {
  count: number;
  className?: string;
};

export default function CountCircle({ count, className }: CountCircleProps) {
  const { locale } = useLocale();
  return (
    <div
      data-testid={TEST_IDS.generic.count}
      className={`h-12 w-12 rounded-full flex items-center shrink-0 justify-center text-15 font-medium text-white ${className}`}
    >
      +{addNumberSeparator(count, locale)}
    </div>
  );
}
