import { ColorOptionIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { fontWeights, Paragraph, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';

type ThemeColorOptionsProps = {
  colors: string[];
  className?: string;
  showTooltip?: boolean;
};

const colorTypes = ['primary', 'secondary', 'tertiary', 'positive', 'negative', 'neutral'];

export const ThemeColorOptions: React.FC<ThemeColorOptionsProps> = ({ colors, className, showTooltip = false }) => {
  const translate = useTranslate();
  return (
    <div className={classNames('flex gap-2 items-center', className)}>
      {colors.map((color, index) => {
        const colorName = colorTypes[index];
        return (
          <TooltipWrapper
            key={index}
            content={
              <div className='flex gap-6px flex-col'>
                <Paragraph size={paragraphSizes.large} weight={fontWeights.semibold}>
                  {translate(`theme.${colorName}.color.title`)}
                </Paragraph>
                <Paragraph weight={fontWeights.medium}>{translate(`theme.${colorName}.color.info`)}</Paragraph>
              </div>
            }
            disabled={!showTooltip}
            tooltipClassNames='px-[9px] py-[5px] !rounded-xl'
          >
            <div>
              <ColorOptionIcon color={color} />
            </div>
          </TooltipWrapper>
        );
      })}
    </div>
  );
};
