import { fontWeights, Heading3, Paragraph } from '@va/ui/design-system';

export const SectionHeader = ({ title, description }: { title: string; description?: string }) => {
  return (
    <div>
      <Heading3 weight={fontWeights.semibold}>{title}</Heading3>
      {description && (
        <Paragraph colorClassName='text-gray-charcoal' className='mt-6px'>
          {description}
        </Paragraph>
      )}
    </div>
  );
};
