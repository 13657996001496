import { BackIconRounded } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, ButtonColors, fontWeights, Paragraph, paragraphSizes } from '@va/ui/design-system';
import { isCurrentUserAllowed } from '@va/util/helpers';
import classNames from 'classnames';
import { createContext, FC, PropsWithChildren, useContext } from 'react';
import Joyride, { Props, Step, TooltipRenderProps } from 'react-joyride';

type ProgressProps = {
  show?: boolean;
  label?: string;
};

type FuncEvent = React.MouseEvent<HTMLElement, MouseEvent>;
type NextFunc = (e: FuncEvent) => void;

export type TourStep = Step & {
  onNextMiddleware?: (e: React.MouseEvent<HTMLElement, MouseEvent>, nextFunc: NextFunc) => void;
  onBackMiddleware?: (e: React.MouseEvent<HTMLElement, MouseEvent>, nextFunc: NextFunc) => void;
};

type TourProps = {
  steps: TourStep[];
  progress?: ProgressProps;
} & Props;

export function buildTourStep(step: TourStep) {
  return step;
}

export const Tour: FC<TourProps> = ({ steps, progress: _progress, ...rest }) => {
  const translate = useTranslate();

  return (
    <Joyride
      tooltipComponent={(props: TooltipRenderProps) => <Tooltip {...props} />}
      continuous
      debug={isCurrentUserAllowed()}
      steps={steps}
      locale={{
        back: '',
        last: translate('all.featureTour.labels.last'),
        next: translate('all.featureTour.labels.next'),
      }}
      {...rest}
    />
  );
};

export const Tooltip: FC<TooltipRenderProps & { progress?: ProgressProps; step: TourStep }> = (props) => {
  const { continuous, index, step, backProps, closeProps, primaryProps, tooltipProps, size, progress, isLastStep } =
    props;

  return (
    <div ref={tooltipProps.ref} className='p-3 bg-white min-w-[300px] max-w-[400px] rounded-12 break-words'>
      {progress?.show !== false && <TourProgress index={index + 1} size={size} label={progress?.label} />}
      <TourTooltipContextProvider {...props}>{step.content}</TourTooltipContextProvider>
      <div className='flex items-center gap-3 mt-18px'>
        {index > 0 && (
          <BackButton
            {...backProps}
            onClick={(e) => {
              if (step.onBackMiddleware) {
                step.onBackMiddleware(e, backProps.onClick);
              } else {
                backProps.onClick(e);
              }
            }}
          />
        )}
        {continuous && !isLastStep && (
          <CloseButton className={classNames({ 'flex-1': index === 0 })} color='tertiary' {...closeProps} />
        )}
        {continuous && (
          <NextButton
            {...primaryProps}
            onClick={(e) => {
              if (step.onNextMiddleware) {
                step.onNextMiddleware(e, primaryProps.onClick);
              } else {
                primaryProps.onClick(e);
              }
            }}
          />
        )}
        {!continuous && <CloseButton {...closeProps} />}
      </div>
    </div>
  );
};

export const StepContent = ({ title, description }: { title: string; description: string }) => {
  return (
    <div className='space-y-2 mt-2'>
      <Paragraph className='text-center' size={paragraphSizes.large} weight={fontWeights.medium}>
        {title}
      </Paragraph>
      <Paragraph>{description}</Paragraph>
    </div>
  );
};

const TourTooltipContext = createContext<TooltipRenderProps>({} as TooltipRenderProps);

const TourTooltipContextProvider: React.FC<PropsWithChildren<TooltipRenderProps>> = ({ children, ...rest }) => {
  return <TourTooltipContext.Provider value={rest}>{children}</TourTooltipContext.Provider>;
};

export const useTourTooltipContext = () => useContext(TourTooltipContext);

const NextButton = ({ onClick, title }: TooltipRenderProps['primaryProps']) => {
  return <Button onClick={onClick} className='flex-1' text={title} />;
};

const CloseButton = ({
  onClick,
  title,
  color,
  className,
}: TooltipRenderProps['closeProps'] & { color?: ButtonColors; className?: string }) => {
  return <Button color={color} onClick={onClick} className={className} text={title} />;
};
const BackButton = ({ onClick, title }: TooltipRenderProps['backProps']) => {
  return (
    <Button color='tertiary' onClick={onClick} icon={(_, color) => <BackIconRounded color={color} />} text={title} />
  );
};

const TourProgress = ({ index, size, label }: { index: number; size: number; label?: string }) => {
  const translate = useTranslate();
  return (
    <Paragraph weight={fontWeights.medium} className='text-center text-gray-devil'>
      {label && <span className='mr-1'>{label}</span>}
      <span>{translate('all.featureTour.progress', { index, size })}</span>
    </Paragraph>
  );
};

export const selectTarget = (dataAtr: string) => `[data-tour="${dataAtr}"]`;
