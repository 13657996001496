import { getInstanceId } from '@va/dashboard/selectors/app';
import { useCurrentPeriodFilter } from '@va/dashboard/util-hooks';
import { patch, post, remove } from '@va/http-client';
import { useFiltersContext } from '@va/shared/feature-filters';
import { RecordingsDataType, RecordingsFiltersType } from '@va/types/recordings';
import { isStandaloneApp, isWixMiniApp } from '@va/util/helpers';
import { getSwrError, isSWRLoading, useAsyncFunction } from '@va/util/hooks';
import { RequestCanceler, useCancelOnUnmount } from '@va/util/misc';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import { useRecordingsContext } from './context';

export const useVisitorRecordings = ({
  pageNumber,
  pageSize = 10,
  initialFilters,
}: {
  pageNumber: number;
  pageSize?: number;
  initialFilters?: Partial<RecordingsFiltersType>;
}) => {
  const websiteId = useSelector(getInstanceId);
  const { appliedFilterValues } = useFiltersContext<Partial<RecordingsFiltersType>>();

  const filters = { ...initialFilters, ...appliedFilterValues };

  if (appliedFilterValues.duration) {
    filters.duration = {
      min: appliedFilterValues.duration.min * 1000,
      max: appliedFilterValues.duration.max * 1000,
    };
  }

  if (appliedFilterValues.adCampaignLabels && typeof appliedFilterValues.adCampaignLabels === 'string') {
    filters.adCampaignLabels = [appliedFilterValues.adCampaignLabels];
  }

  const { from, unit, until } = useCurrentPeriodFilter();
  const queryData = useMemo(
    () => ({
      from,
      unit,
      until,
      length: pageSize,
      search: '',
      start: pageNumber * pageSize,
      // TODO Remove this when sorting is implemented on the table
      order: isWixMiniApp() ? 'duration' : 'createdAt',
    }),
    [from, pageSize, pageNumber, unit, until],
  );

  const reqId = useMemo(() => RequestCanceler.generateId(), []);
  useCancelOnUnmount(reqId);

  const fetcher = useCallback(
    async (url: string, queryData: Record<string, any>, filters: Object) => {
      const signal = RequestCanceler.onRequestStart(reqId);
      const res = await post<RecordingsDataType>(url, queryData, filters, { signal });
      RequestCanceler.onRequestEnd(reqId);
      return res;
    },
    [reqId],
  );

  const swr = useSWR([`/v2/websites/${websiteId}/sessions/recordings`, queryData, filters], fetcher, {
    revalidateOnFocus: true,
    revalidateIfStale: true,
    onError: () => {
      RequestCanceler.onRequestEnd(reqId);
    },
  });
  return { ...swr, isLoading: isSWRLoading(swr), error: getSwrError(swr) };
};

export const useUpdateBulkRecordings = () => {
  const { websiteId, from, until } = useCurrentPeriodFilter();
  const { appliedFilterValues } = useFiltersContext();

  const asyncFunc = (body: { seen: boolean } | { star: boolean }) => {
    const payload = {
      filter: {
        from,
        until,
        ...appliedFilterValues,
      },
      update: {
        ...body,
      },
    };
    return patch(`/websites/${websiteId}/session-extras/all`, {}, payload);
  };

  const response = useAsyncFunction<typeof asyncFunc>(asyncFunc);
  useMutateRecordings(response.isSucceeded);
  return response;
};

export const useGetRecordingLink = (recordingId: string) => {
  const [success, setSuccess] = useState(false);
  const websiteId = useSelector(getInstanceId);
  const origin = window.location.origin;
  const query = window.location.search;
  const basePath = isStandaloneApp() ? `/website/${websiteId}` : '';
  const link = `${origin}${basePath}/behaviour/recordings/${recordingId}${query}`;
  const copyLink = useCallback(() => {
    navigator.clipboard.writeText(link);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
  }, [link]);
  return { copyLink, success, link };
};

export const useStarRecordings = (sessionIds: string[]) => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = (star: boolean) => patch(`/websites/${websiteId}/session-extras`, {}, { sessionIds, star });
  const res = useAsyncFunction<typeof asyncFunc>(asyncFunc, { throwErrorBack: true });
  useMutateRecordings(res.isSucceeded);
  return res;
};

export const useMarkSeenRecording = (sessionIds: string[]) => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = (seen: boolean) => patch(`/websites/${websiteId}/session-extras`, {}, { sessionIds, seen });
  const res = useAsyncFunction<typeof asyncFunc>(asyncFunc, { throwErrorBack: true });
  useMutateRecordings(res.isSucceeded);
  return res;
};

export const useDeleteBulkRecordings = () => {
  const { websiteId, from, until } = useCurrentPeriodFilter();
  const { appliedFilterValues } = useFiltersContext();

  const asyncFunc = () => {
    const payload = {
      filter: {
        from,
        until,
        ...appliedFilterValues,
      },
    };
    return remove(`/v2/websites/${websiteId}/recordings/bulk`, {}, payload);
  };

  const response = useAsyncFunction<typeof asyncFunc>(asyncFunc);
  useMutateRecordings(response.isSucceeded);
  return response;
};

export const useDeleteRecordingsApi = () => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = (sessionIds: string[]) => remove(`/websites/${websiteId}/recordings/delete`, {}, { sessionIds });
  const res = useAsyncFunction<typeof asyncFunc>(asyncFunc);
  useMutateRecordings(res.isSucceeded);
  return res;
};

export const useMutateRecordings = (shouldMutate: boolean) => {
  const { mutateRecordings } = useRecordingsContext();
  useEffect(() => {
    if (!shouldMutate) return;
    setTimeout(() => {
      if (!mutateRecordings) return;
      mutateRecordings();
    }, 10);
  }, [mutateRecordings, shouldMutate]);
};
