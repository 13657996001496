import { getApiRequest } from '@va/dashboard/selectors/api';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { patch, post } from '@va/http-client';
import { SsrSettingsType } from '@va/types/website';
import { useAsyncFunction } from '@va/util/hooks';
import { useSelector } from 'react-redux';
import { SsrSettingsPayloadType } from './types';

export const useUpdateSsrSettings = () => {
  const websiteId = useSelector(getInstanceId);
  const ssrSettings = useSelector((state) => getApiRequest(state, 'getSsrSettings')) as any;
  const asyncFunc = async (data: any) => {
    return await patch<SsrSettingsType>(`/websites/${websiteId}/ssr-settings/${ssrSettings?.key}`, {}, data);
  };
  return useAsyncFunction(asyncFunc);
};

export const useCreateSsrSettings = () => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = async (data: SsrSettingsPayloadType) => {
    return await post<SsrSettingsType>(`/websites/${websiteId}/ssr-settings`, {}, data);
  };
  return useAsyncFunction(asyncFunc);
};
