import { WHITE_COLOR } from '@va/constants';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import { Component } from 'react';
import BaseChart from '../BaseChart';
import chart from './GradientChart';
import './GradientSplitBarChart.scss';

export default class GradientSplitBarChart extends Component {
  constructor(props) {
    super(props);
    this.renderChart = this.renderChart.bind(this);
    this.colors = {
      text: '#aaa',
      label: '#000000',
      chartColor: [window.PRIMARY_COLOR, d3.rgb(window.PRIMARY_COLOR).brighter(4)],
      backgroundColor: WHITE_COLOR,
    };
  }

  renderChart(width) {
    if (!this.props.data || this.props.data.length === 0) {
      return;
    }
    chart(
      d3,
      this.props.chartId,
      this.props.width ? this.props.width : width,
      this.props.height,
      this.props.data,
      this.props.colors || this.colors,
      this.props.margin,
    );
  }

  render() {
    return (
      <div className='relative'>
        <BaseChart
          id={this.props.chartId}
          className='nav-feature-split-chart'
          createChart={this.renderChart}
          data={[this.props.data, this.props.colors || this.colors]}
          width={this.props.width}
        />
      </div>
    );
  }
}

GradientSplitBarChart.defaultProps = {
  margin: { top: 0, right: 0, bottom: 0, left: 0 },
  width: 250,
  height: 15,
};

GradientSplitBarChart.propTypes = {
  chartId: PropTypes.string.isRequired,
  data: PropTypes.shape({
    limit: PropTypes.number.isRequired,
    remainingLimit: PropTypes.number.isRequired,
  }).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  colors: PropTypes.shape({
    text: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    chartColor: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  margin: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
};
