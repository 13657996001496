import invariant from 'invariant';

/**
 * @see https://github.com/facebook/fbjs/blob/e66ba20ad5be433eb54423f2b097d829324d9de6/packages/fbjs/src/core/createArrayFromMixed.js#L24
 */
export function toArray(obj) {
  const length = obj.length;

  // Some browsers builtin objects can report typeof 'function' (e.g. NodeList
  // in old versions of Safari).
  invariant(
    !Array.isArray(obj) && (typeof obj === 'object' || typeof obj === 'function'),
    'toArray: Array-like object expected',
  );

  invariant(typeof length === 'number', 'toArray: Object needs a length property');

  invariant(length === 0 || length - 1 in obj, 'toArray: Object should have keys for indices');

  invariant(
    typeof obj.callee !== 'function',
    "toArray: Object can't be `arguments`. Use rest params (function(...args) {}) or Array.from() instead.",
  );

  // Old IE doesn't give collections access to hasOwnProperty. Assume inputs
  // without method will throw during the slice call and skip straight to the
  // fallback.
  if (obj.hasOwnProperty) {
    try {
      return Array.prototype.slice.call(obj);
    } catch (e) {
      // IE < 9 does not support Array#slice on collections objects
    }
  }

  // Fall back to copying key by key. This assumes all keys have a value,
  // so will not preserve sparsely populated inputs.
  const ret = Array(length);
  for (let ii = 0; ii < length; ii++) {
    ret[ii] = obj[ii];
  }
  return ret;
}
