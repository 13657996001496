import { IconProps } from '.';

export function UserIcon({ className, color }: IconProps) {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.3 13.1C14.4 12.2 13.4 11.6 12.2 11.1C13.9 10.1 15 8.2 15 6C15 5.4 14.9 4.7 14.7 4.1C13.9 1.7 11.7 0 9.00001 0C6.30001 0 4.10001 1.7 3.30001 4.1C3.10001 4.7 3.00001 5.4 3.00001 6C3.00001 8.1 4.10001 10 5.80001 11.1C4.60001 11.5 3.60001 12.2 2.70001 13.1C2.30001 13.5 2.00001 13.9 1.70001 14.3C2.00001 14.7 2.30001 15.1 2.70001 15.5C4.30001 17 6.60001 18 9.00001 18C11.4 18 13.7 17 15.3 15.4C15.7 15 16 14.6 16.3 14.2C16 13.8 15.7 13.4 15.3 13.1ZM4.50001 6C4.50001 3.7 6.30001 1.8 8.50001 1.5C8.70001 1.5 8.80001 1.5 9.00001 1.5C9.20001 1.5 9.30001 1.5 9.50001 1.5C11.7 1.7 13.5 3.7 13.5 6C13.5 8.5 11.5 10.5 9.00001 10.5C6.50001 10.5 4.50001 8.5 4.50001 6ZM9.00001 16.5C6.90001 16.5 5.00001 15.6 3.60001 14.3C5.00001 12.9 6.90001 12 9.00001 12C11.1 12 13 12.9 14.3 14.2C13 15.6 11.1 16.5 9.00001 16.5Z'
        fill={color ?? 'var(--color-primary)'}
      />
    </svg>
  );
}
