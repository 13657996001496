export const BinIcon = () => {
  return (
    <svg width='16' height='20' viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.17071 4C5.58254 2.83481 6.69378 2 8 2C9.30622 2 10.4175 2.83481 10.8293 4H5.17071ZM3.10002 4C3.56329 1.71776 5.58104 0 8 0C10.419 0 12.4367 1.71776 12.9 4H16V6H15V15C15 17.7614 12.7614 20 10 20H6C3.23858 20 1 17.7614 1 15V6H0V4H3.10002ZM3 15V6H13V15C13 16.6569 11.6569 18 10 18H6C4.34315 18 3 16.6569 3 15ZM7 9C7 8.44771 6.55228 8 6 8C5.44772 8 5 8.44771 5 9V15C5 15.5523 5.44772 16 6 16C6.55228 16 7 15.5523 7 15V9ZM10 8C10.5523 8 11 8.44771 11 9V15C11 15.5523 10.5523 16 10 16C9.44771 16 9 15.5523 9 15V9C9 8.44771 9.44771 8 10 8Z'
        fill='#666666'
      />
    </svg>
  );
};
