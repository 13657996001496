import { CheckMarkWithCircle, CopyIcon } from '@va/icons';
import { Button } from '@va/ui/design-system';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

type CopyToClipboardBtnProps = {
  containerClassName?: string;
  content: string;
  btnText?: string;
  btnClass?: string;
  onCopyText?: string;
  icon?: (iconClasses: string, color?: string) => ReactNode;
  onCopyIcon?: (iconClasses: string, color?: string) => ReactNode;
};

export const CopyToClipboardBtn = ({
  containerClassName,
  content,
  btnText,
  btnClass,
  onCopyText,
  icon = (_, color = '#FFF') => <CopyIcon color={color} />,
  onCopyIcon = (_, color = '#FFF') => <CheckMarkWithCircle color={color} />,
}: CopyToClipboardBtnProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const shownIcon = useMemo(() => (isCopied ? onCopyIcon : icon), [icon, isCopied, onCopyIcon]);

  const copyToClipboard = useCallback(() => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  }, []);

  return (
    <div className={containerClassName}>
      <CopyToClipboard text={content} onCopy={copyToClipboard}>
        <Button className={btnClass} icon={shownIcon} text={isCopied && onCopyText ? onCopyText : btnText} />
      </CopyToClipboard>
    </div>
  );
};

export default CopyToClipboardBtn;
