import { post } from '@va/http-client';

export const sendFeedback = (websiteId, data) => {
  const url = `/websites/${websiteId}/feedback`;

  return post(url, {}, data);
};

export const sendReview = (websiteId, data) => {
  const url = `/websites/${websiteId}/review`;

  return post(url, {}, data);
};
