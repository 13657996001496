import Immutable from 'immutable';

import {
  REQUEST_TRANSLATIONS,
  REQUEST_TRANSLATIONS_FAILED,
  REQUEST_TRANSLATIONS_SUCCEEDED,
} from '@va/dashboard/actions/ui';

export function replaceNewLinesInTranslationFile(data) {
  let processedData = JSON.stringify(data).replace(/\\n/g, '<br/>');

  return JSON.parse(processedData);
}

/**
 * @deprecated
 */
const translationsReducer = (state = Immutable.Map(), action) => {
  switch (action.type) {
    case REQUEST_TRANSLATIONS:
      return state.merge({
        inProgress: true,
        succeeded: false,
        isFirstLoad: !!action.isFirstLoad,
      });
    case REQUEST_TRANSLATIONS_SUCCEEDED:
      state = state.mergeIn([action.locale], replaceNewLinesInTranslationFile(action.data));
      return state.merge({
        inProgress: false,
        succeeded: true,
      });
    case REQUEST_TRANSLATIONS_FAILED:
      return state.merge({
        inProgress: false,
        succeeded: false,
      });
    default:
      return state;
  }
};
export default translationsReducer;
