export const OperaIcon: React.FC<JSX.IntrinsicElements['svg'] & { monochrome?: boolean }> = ({
  monochrome,
  ...props
}) => {
  if (monochrome)
    return (
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 24 24'
        {...props}
      >
        <path
          d='M11.9,1.5c-3.3,0-5,3.4-5,10.1c0,2.6,0,10.5,5.1,10.5c5,0,5-7.6,5-10.4C16.9,4.9,15.2,1.5,11.9,1.5z M12,21.3
c-3.8,0-4.3-5.6-4.3-9.7c0-4.4,0.7-9.3,4.2-9.3s4.2,4.9,4.2,9.3C16.1,15.8,15.7,21.3,12,21.3z'
        />
        <path
          d='M13.9,0.4c-0.6-0.1-1.3-0.2-2-0.2C5.5,0.3,1.1,4.9,1.1,11.8c0,1.5,0.3,3,0.7,4.3C3.3,20.4,7,23.7,12,23.7
c6.5,0,10.9-5.6,10.9-11.9C22.9,5.6,19.3,1.2,13.9,0.4z M12,22.9c-4.3,0-7.9-2.7-9.4-7.1c-0.5-1.2-0.7-2.6-0.7-4
c0-6.4,4-10.7,10-10.7c0.6,0,1.2,0,1.8,0.1c5.1,0.8,8.4,5,8.4,10.7C22.1,17.3,18.3,22.9,12,22.9z'
        />
      </svg>
    );

  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M6.03622 14.0724C5.04082 12.897 4.39642 11.1618 4.35322 9.2124C4.35322 9.207 4.35322 8.793 4.35322 8.7876C4.39642 6.8382 5.04082 5.103 6.03622 3.9276C7.32862 2.2518 9.24742 1.188 11.3894 1.188C12.707 1.188 13.9418 1.5912 14.9966 2.2914C13.4126 0.873 11.3246 0.009 9.03322 0C9.02242 0 9.00982 0 8.99902 0C4.02922 0 -0.000976562 4.0302 -0.000976562 9C-0.000976562 13.8276 3.79882 17.766 8.57062 17.9892C8.71282 17.9964 8.85502 18 8.99902 18C11.303 18 13.4054 17.1342 14.9966 15.7104C13.9418 16.4088 12.7088 16.812 11.3894 16.812C9.24742 16.812 7.32862 15.7482 6.03622 14.0724Z'
        fill='url(#paint0_linear_1120_52945)'
      />
      <path
        d='M6.03619 3.92759C6.86239 2.95199 7.92799 2.36519 9.09439 2.36519C11.7152 2.36519 13.8374 5.33519 13.8374 8.99999C13.8374 12.6648 11.7134 15.6348 9.09439 15.6348C7.92979 15.6348 6.86239 15.0462 6.03619 14.0724C7.32859 15.7482 9.24739 16.812 11.3894 16.812C12.707 16.812 13.9418 16.4088 14.9966 15.7104C16.8398 14.0616 17.999 11.6658 17.999 8.99999C17.999 6.33419 16.8398 3.93839 14.9966 2.29139C13.9418 1.59119 12.7088 1.18799 11.3894 1.18799C9.24739 1.18799 7.32859 2.25179 6.03619 3.92759Z'
        fill='url(#paint1_linear_1120_52945)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1120_52945'
          x1='7.49824'
          y1='0.293472'
          x2='7.49824'
          y2='17.738'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.3' stopColor='#FF1B2D' />
          <stop offset='0.4381' stopColor='#FA1A2C' />
          <stop offset='0.5939' stopColor='#ED1528' />
          <stop offset='0.7581' stopColor='#D60E21' />
          <stop offset='0.9272' stopColor='#B70519' />
          <stop offset='1' stopColor='#A70014' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_1120_52945'
          x1='12.0178'
          y1='1.32165'
          x2='12.0178'
          y2='16.7505'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#9C0000' />
          <stop offset='0.7' stopColor='#FF4B4B' />
        </linearGradient>
      </defs>
    </svg>
  );
};
