import { LevelIcon, PrivacyLockIcon, RadioButtonThick } from '@va/icons';
import { useTranslate } from '@va/localization';
import { PrivacyLevelsEnum } from '@va/standalone/shared/types';
import {
  GrayGradientDivider,
  Paragraph,
  ParagraphWithTooltip,
  fontWeights,
  paragraphSizes,
} from '@va/ui/design-system';
import classNames from 'classnames';
import { useCallback } from 'react';

type DataPrivacyCardProps = {
  onClick: (value: PrivacyLevelsEnum) => void;
  selected: boolean;
  value: PrivacyLevelsEnum;
  baseKey: string;
  variant?: 'default' | 'wix-mini';
};

const styles = {
  default: {
    card: {
      base: 'bg-white-snow hover:bg-gray-concrete p-3 rounded-30',
      selected: 'bg-gray-concrete',
    },
    iconContainer: {
      base: 'bg-white',
      selected: 'bg-blue-pure',
    },
    levelIconColor: {
      base: '#2478F0',
      selected: '#FFFFFF',
      inactive: '#F0F0F0',
      selectedInactive: '#6DA8FC',
    },
    iconColor: {
      base: '#969696',
      selected: '#FFFFFF',
    },
    detailsContainer: {
      base: 'bg-white',
      selected: 'bg-white',
    },
  },
  'wix-mini': {
    card: {
      base: 'bg-blue-solitude p-1.5 rounded-[24px]',
      selected: 'bg-white hover:bg-white',
    },
    iconContainer: {
      base: 'bg-white',
      selected: 'bg-primary',
    },
    levelIconColor: {
      base: 'var(--color-primary)',
      selected: 'var(--color-primary)',
      inactive: '#F0F0F0',
      selectedInactive: 'var(--color-primary)',
    },
    iconColor: {
      base: '#969696',
      selected: '#FFFFFF',
    },
    detailsContainer: {
      base: 'bg-white-83',
      selected: 'bg-gray-athens',
    },
  },
};

export const DataPrivacyCard = ({ onClick, selected, value, baseKey, variant = 'default' }: DataPrivacyCardProps) => {
  const translate = useTranslate();

  const levelIconColor = useCallback(
    (level: PrivacyLevelsEnum) => {
      if (selected) {
        if (value >= level) return styles[variant].levelIconColor.selected;
        return styles[variant].levelIconColor.selectedInactive;
      }
      if (value >= level) return styles[variant].levelIconColor.base;
      return styles[variant].levelIconColor.inactive;
    },
    [selected, value, variant],
  );

  return (
    <div
      onClick={() => {
        onClick(value);
      }}
      className={classNames(
        'w-[300px] relative flex flex-col items-center cursor-pointer text-center break-words shrink-0 gap-1',
        styles[variant].card.base,
        { [styles[variant].card.selected]: selected },
      )}
    >
      <RadioButtonThick
        selected={selected}
        className='absolute top-18px right-18px'
        innerCircleColor={!selected ? 'transparent' : undefined}
      />
      <div
        className={classNames('relative h-60px w-60px rounded-full p-1 flex justify-center items-center my-2', {
          [styles[variant].iconContainer.base]: !selected,
          [styles[variant].iconContainer.selected]: selected,
        })}
      >
        <div className='grid grid-cols-2 gap-0.5'>
          <LevelIcon color={levelIconColor(PrivacyLevelsEnum.completePrivacy)} />
          <LevelIcon className='rotate-90' color={levelIconColor(PrivacyLevelsEnum.defaultPrivacy)} />
          <LevelIcon className='-rotate-90' color={levelIconColor(PrivacyLevelsEnum.GDPRMode)} />
          <LevelIcon className='rotate-180' color={levelIconColor(PrivacyLevelsEnum.basicPrivacy)} />
        </div>
        <PrivacyLockIcon
          color={selected ? styles[variant].iconColor.selected : styles[variant].iconColor.base}
          className='absolute'
          level={value}
        />
      </div>
      <Paragraph size={paragraphSizes.large} colorClassName='text-text-dark' weight={fontWeights.semibold}>
        {translate(`${baseKey}.title`)}
      </Paragraph>
      <Paragraph size={paragraphSizes.tiny} weight={fontWeights.medium}>
        {translate(`${baseKey}.subtitle`)}
      </Paragraph>
      <div
        className={classNames(
          'rounded-18 p-4 mt-1 h-3/5 text-text-primary w-full break-words',
          { [styles[variant].detailsContainer.base]: !selected },
          { [styles[variant].detailsContainer.selected]: selected },
        )}
      >
        <Paragraph className='mb-4' weight={fontWeights.medium}>
          {translate(`${baseKey}.info1`)}
        </Paragraph>
        <GrayGradientDivider className='-ml-4 my-3' />

        <Paragraph size={paragraphSizes.tiny} weight={fontWeights.semibold}>
          {translate(`${baseKey}.info2`)}
        </Paragraph>
        <GrayGradientDivider className='-ml-4 my-3' />

        <ParagraphWithTooltip size={paragraphSizes.tiny} colorClassName='text-gray-devil' weight={fontWeights.medium}>
          {translate('onboarding.privacyLevels.modeComplies')}
        </ParagraphWithTooltip>
        <ParagraphWithTooltip size={paragraphSizes.tiny} weight={fontWeights.medium}>
          {translate(`${baseKey}.info3`)}
        </ParagraphWithTooltip>
        <GrayGradientDivider className='-ml-4 my-3' />

        <ParagraphWithTooltip size={paragraphSizes.tiny} colorClassName='text-gray-devil' weight={fontWeights.medium}>
          {translate('onboarding.privacyLevels.dataTracking')}
        </ParagraphWithTooltip>
        <ParagraphWithTooltip size={paragraphSizes.tiny} weight={fontWeights.semibold} className='mb-4'>
          {translate(`${baseKey}.info4`)}
        </ParagraphWithTooltip>
      </div>
    </div>
  );
};
