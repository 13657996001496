export const MobileDevice = () => {
  return (
    <svg width='60' height='60' viewBox='0 0 60 60' fill='black' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30 57C35.5504 57 40.7097 55.3252 45 52.4534V23.4C45 20.4177 42.5823 18 39.6 18H39V19.5C39 21.9853 36.9853 24 34.5 24H25.5C23.0147 24 21 21.9853 21 19.5V18H20.4C17.4177 18 15 20.4177 15 23.4V52.4534C19.2903 55.3252 24.4496 57 30 57ZM24 18V19.5C24 20.3284 24.6716 21 25.5 21H34.5C35.3284 21 36 20.3284 36 19.5V18H24ZM48 23.4V50.125C53.5237 45.1811 57 37.9965 57 30C57 15.0883 44.9117 3 30 3C15.0883 3 3 15.0883 3 30C3 37.9965 6.47626 45.1811 12 50.125V23.4C12 18.7608 15.7608 15 20.4 15H39.6C44.2392 15 48 18.7608 48 23.4ZM60 30C60 39.8149 55.2867 48.529 48 54.0022L47.9495 54.0401C47.0076 54.7445 46.0229 55.3948 45 55.9865C44.3779 56.3464 43.7417 56.6846 43.0924 57L43.0062 57.0417C39.0717 58.9376 34.6599 60 30 60C25.3061 60 20.8639 58.922 16.9076 57C16.2583 56.6846 15.6221 56.3464 15 55.9865C14.9179 55.939 14.836 55.8912 14.7544 55.8429C13.8011 55.2793 12.8817 54.6645 12 54.0022C4.71332 48.529 0 39.8149 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30Z'
      />
    </svg>
  );
};
