import { IconProps } from '.';

export const EditorIcon = ({ color = 'black', className }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 14.6213V17H3.87868L14.159 6.71967L11.7803 4.34099L1.5 14.6213ZM16.0607 4.81802L15.2197 5.65901L12.841 3.28033L13.682 2.43934C13.9633 2.15804 14.3448 2 14.7426 2H15C15.8284 2 16.5 2.67157 16.5 3.5V3.75736C16.5 4.15518 16.342 4.53672 16.0607 4.81802ZM4.5 18.5L17.1213 5.87868C17.6839 5.31607 18 4.55301 18 3.75736V3.5C18 1.84315 16.6569 0.5 15 0.5H14.7426C13.947 0.5 13.1839 0.816071 12.6213 1.37868L0 14V17V18.5H1.5H4.5ZM17.25 18.5H7.5L9 17H17.25C17.6642 17 18 17.3358 18 17.75C18 18.1642 17.6642 18.5 17.25 18.5Z'
        fill={color}
      />
    </svg>
  );
};
