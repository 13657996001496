class ExtendableError extends Error {
  code: number | null;
  constructor(message: string, code: number | null) {
    super(message);
    this.code = code;
  }
}

export const PREMIUM_ONLY = 100;
export const NOT_IMPLEMENTED = 101;
export const INVALID_DATA = 102;
export const PERMISSION_DENIED = 103;
export const WEBSITE_NOT_FOUND = 104;
export const NO_IDENTITY = 105;
export const BAD_SIGNED_INSTANCE = 106;
export const EXPIRED_TOKEN = 107;

export class PremiumOnlyException extends ExtendableError {
  constructor(message: string) {
    super(message, PREMIUM_ONLY);
  }
}

export class NotImplementedException extends ExtendableError {
  constructor(message: string) {
    super(message, NOT_IMPLEMENTED);
  }
}

export class InvalidDataException extends ExtendableError {
  constructor(message: string) {
    super(message, INVALID_DATA);
  }
}

export class PermissionDeniedException extends ExtendableError {
  constructor(message: string) {
    super(message, PERMISSION_DENIED);
  }
}

export class WebsiteNotFoundException extends ExtendableError {
  constructor(message: string) {
    super(message, WEBSITE_NOT_FOUND);
  }
}

export class NoIdentityException extends ExtendableError {
  constructor(message: string) {
    super(message, NO_IDENTITY);
  }
}

export class BadSignedInstanceException extends ExtendableError {
  constructor(message: string) {
    super(message, BAD_SIGNED_INSTANCE);
  }
}

export class ExpiredTokenException extends ExtendableError {
  constructor(message: string) {
    super(message, EXPIRED_TOKEN);
  }
}

export class MethodNotImplemented extends ExtendableError {
  constructor() {
    super('Method is not implemented!', null);
  }
}

export const createException = (error: {
  message: string;
  code: number | null;
}) => {
  switch (error.code) {
    case 100:
      return new PremiumOnlyException(error.message);
    case 101:
      return new NotImplementedException(error.message);
    case 102:
      return new InvalidDataException(error.message);
    case 103:
      return new PermissionDeniedException(error.message);
    case 104:
      return new WebsiteNotFoundException(error.message);
    case 105:
      return new NoIdentityException(error.message);
    case 106:
      return new BadSignedInstanceException(error.message);
    case 107:
      return new ExpiredTokenException(error.message);
    case undefined:
      return new Error(error.toString());
    default:
      return new Error(JSON.stringify(error));
  }
};
