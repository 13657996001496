import { FC } from 'react';

import { ProgressBarTooltip } from './ProgressBarTooltip';

type ProgressBarEvent = {
  delay: number;
  details: string;
  color: string;
  name: string;
  to?: number;
};

type ProgressBarEventProps = {
  eventData: ProgressBarEvent;
  totalTime: number;
};

const ProgressBarEvent: FC<ProgressBarEventProps> = ({ eventData, totalTime }) => {
  const left = `${(100 * eventData.delay) / totalTime}%`;
  const right = eventData.to ? `${100 - (100 * eventData.to) / totalTime}%` : 'auto';

  return (
    <ProgressBarTooltip eventData={eventData}>
      <div className={'ssr-user-event'} style={{ left, right, backgroundColor: eventData.color }} />
    </ProgressBarTooltip>
  );
};

export default ProgressBarEvent;
