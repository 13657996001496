import { IconProps } from '.';

export const StatsIcon = ({ className, color = '#000000' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='6' y='7' width='4' height='10' rx='2' stroke={color} strokeWidth='2' />
      <rect x='13' y='1' width='4' height='16' rx='2' stroke={color} strokeWidth='2' />
      <circle cx='2' cy='16' r='2' fill={color} />
    </svg>
  );
};
