import {
  ADD_TOASTER_TOOLTIP,
  ADD_TOOLTIP,
  REMOVE_TOOLTIP,
  UPDATE_ALL_TOOLTIPS,
  UPDATE_TOOLTIP,
  UPDATE_TOOLTIPS,
} from '@va/dashboard/actions/ui';
import Immutable from 'immutable';

const tooltipsReducer = (
  state = Immutable.Map({
    requestedRebind: Immutable.List(),
    items: Immutable.Map(),
  }),
  action,
) => {
  switch (action.type) {
    case ADD_TOOLTIP:
      return state.setIn(
        ['items', action.id],
        Immutable.fromJS({
          id: action.id,
          render: action.render,
          ...action.options,
        }),
      );

    case ADD_TOASTER_TOOLTIP:
      return state.setIn(
        ['items', action.id],
        Immutable.fromJS({
          id: action.id,
          place: action.place,
          expirationTime: action.expirationTime,
          render: action.render,
          ...action.options,
        }),
      );

    case UPDATE_TOOLTIP:
      return state.hasIn(['items', action.id])
        ? state.mergeDeepIn(['items', action.id], Immutable.fromJS(action.options))
        : state;

    case UPDATE_TOOLTIPS:
      return state.withMutations((temporaryState) => {
        const tooltips = action.tooltips;
        Object.keys(action.tooltips)
          .filter((id) => temporaryState.hasIn(['items', id]))
          .forEach((id) => temporaryState.mergeDeepIn(['items', id], Immutable.fromJS(tooltips[id])));
      });

    case UPDATE_ALL_TOOLTIPS:
      return state.update('items', (items) =>
        items.withMutations((mutTooltips) => {
          mutTooltips.forEach((item, key) => {
            mutTooltips.mergeIn([key], Immutable.fromJS(action.options));
          });
        }),
      );

    case REMOVE_TOOLTIP:
      return state.deleteIn(['items', action.id]);

    default:
      return state;
  }
};
export default tooltipsReducer;
