import { POLLS_AND_SURVEYS_QUESTION_LIMIT } from '@va/constants';
import { addNotificationV2 } from '@va/dashboard/actions/ui';
import {
  QUESTION_TYPE,
  RATING_SHAPE,
  TEXT_TYPE_QUESTION,
  feedbackTransKeys,
  positionChangeAction,
} from '@va/dashboard/modules/feedback/shared';
import { useTranslate } from '@va/localization';
import { Button } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { generateId } from '@va/util/helpers';
import classNames from 'classnames';
import { FieldArray, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import EditableQuestion from './EditableQuestion';

export const QuestionsCreator = ({ name, errors, touched, formHasErrors, isDisabled, tooltipTextTranslationKey }) => {
  const translate = useTranslate();
  const { getFieldProps } = useFormikContext();
  const { value: questions = [] } = getFieldProps(name);

  const questionCreateLimitReached = useMemo(() => questions.length >= POLLS_AND_SURVEYS_QUESTION_LIMIT, [questions]);

  return (
    <TooltipWrapper content={translate(tooltipTextTranslationKey)} followCursorAxis='both' disabled={!isDisabled}>
      <div className={classNames({ 'opacity-50': isDisabled })}>
        <FieldArray name={name}>
          {({ push, insert, remove, swap, replace }) => (
            <>
              {questions.map((question, index) => (
                <>
                  <Question
                    key={question.id}
                    // question={question}
                    index={index}
                    insert={insert}
                    remove={remove}
                    name={name}
                    swap={swap}
                    push={push}
                    replace={replace}
                    totalQuestions={questions.length}
                  />
                  {errors &&
                    (touched || formHasErrors) &&
                    errors[index] &&
                    (TEXT_TYPE_QUESTION.includes(questions && questions[index] && questions[index].type) ? (
                      <div className='mb-2 ml-20 text-sm text-red-negative'>{translate(errors[index].body)}</div>
                    ) : (
                      <div className='mb-2 ml-20 text-sm text-red-negative'>
                        {translate(feedbackTransKeys.FEEDBACK_POLLS_QUESTION_WITH_MULTICHOICES_REQUIRED_ERROR)}
                      </div>
                    ))}
                </>
              ))}

              <div className='flex space-x-4 pt-2'>
                {/* TODO will be added along with the question bank */}
                {/* <AddItemButton
                className='flex-1'
                onClick={() => {
                  console.log(questions);
                }}>
                <SimpleTranslation value='feedbackCreator.addFromQuestionBank' />
              </AddItemButton> */}
                {!isDisabled && (
                  <Button
                    className='flex-1'
                    onClick={() => push(createNewQuestion())}
                    text={translate('feedback.question.addNew')}
                    color='secondary'
                    disabled={questionCreateLimitReached}
                    tooltip={questionCreateLimitReached && translate('feedback.tooltip.questionCreateLimitReached')}
                  />
                )}
              </div>
            </>
          )}
        </FieldArray>
      </div>
    </TooltipWrapper>
  );
};

QuestionsCreator.propTypes = {
  name: PropTypes.string.isRequired,
};

const Question = memo(function Question({ index, insert, remove, name, totalQuestions, swap, push, replace }) {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const onDuplicate = useCallback(
    (question) => {
      if (totalQuestions < POLLS_AND_SURVEYS_QUESTION_LIMIT) {
        return insert(index + 1, {
          ...question,
          id: generateId(),
          isNew: true,
        });
      }
      return dispatch(
        addNotificationV2({
          text: translate('feedback.errors.canNotDuplicate.questionCreateLimitReached'),
          autoDismiss: 2500,
          type: 'error',
        }),
      );
    },
    [totalQuestions, dispatch, translate, insert, index],
  );
  const onDelete = useCallback(() => remove(index), [remove, index]);

  const onPositionChange = useCallback(
    (action) => {
      if (action === positionChangeAction.moveUp) {
        swap(index, index - 1);
      } else {
        swap(index, index + 1);
      }
    },
    [index, swap],
  );

  return (
    <div className='pb-4'>
      <EditableQuestion
        name={`${name}.${index}`}
        number={index + 1}
        onDuplicate={onDuplicate}
        onDelete={onDelete}
        onPositionChange={onPositionChange}
        totalQuestions={totalQuestions}
        remove={remove}
        push={push}
        index={index}
        replace={replace}
      />
    </div>
  );
});

export function createNewQuestion(questionType = QUESTION_TYPE.SHORT_TEXT) {
  return {
    id: generateId(),
    isNew: true,
    body: '',
    type: questionType,
    required: false,
    ...computeOptionalFields(questionType),
  };
}

function computeOptionalFields(questionType) {
  const fields = {
    hasDescription: false,
  };

  if (questionType === QUESTION_TYPE.MULTIPLE_CHOICE) {
    fields.multipleChoice = true;
  }

  if ([QUESTION_TYPE.RATING_SCALE, QUESTION_TYPE.SLIDER, QUESTION_TYPE.RATING].includes(questionType)) {
    fields.labels = false;
  }

  if (questionType === QUESTION_TYPE.RATING) {
    fields.steps = 3;
    fields.shape = RATING_SHAPE.STAR;
  }

  if (questionType === QUESTION_TYPE.RATING_SCALE) {
    fields.steps = 3;
  }

  return fields;
}
