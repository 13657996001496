import { setActiveTab } from '@va/dashboard/actions/ui';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useActiveTab = (tabName: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveTab(tabName));
  }, [tabName, dispatch]);
};
