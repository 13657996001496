import React from 'react';
import { IconProps } from '.';

export const LeftCircleArrowIcon = ({ className, color = 'black' }: IconProps) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 9C1.5 13.1421 4.85786 16.5 9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9ZM-3.93402e-07 9C-6.10673e-07 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 -1.76132e-07 9 -3.93402e-07C4.02944 -6.10673e-07 -1.76132e-07 4.02944 -3.93402e-07 9ZM12.9 8.25L7.21068 8.25L8.93035 6.53033L7.86969 5.46967L4.86969 8.46967L4.33936 9L4.86969 9.53033L7.86969 12.5303L8.93035 11.4697L7.21068 9.75L12.9 9.75L12.9 8.25Z'
        fill={color}
      />
    </svg>
  );
};


