import { NestedRoute, NestedTab } from '@va/types/navigation';

export function buildNestedRoute(route: NestedRoute): NestedRoute {
  return route;
}

export function buildNestedTab(tab: NestedTab): NestedTab {
  return tab;
}

export function buildNestedNavigation(routes: Array<NestedRoute>): Array<NestedRoute>;
export function buildNestedNavigation(tabs: Array<NestedTab>): Array<NestedTab>;
export function buildNestedNavigation(tabs: Array<NestedRoute | NestedTab>): Array<NestedRoute | NestedTab>;

export function buildNestedNavigation(routes: Array<NestedRoute | NestedTab>): Array<NestedRoute | NestedTab> {
  return routes;
}
