import { userActions } from '@va/constants';
import { FilterIcon, PencilEditIcon } from '@va/icons';
import { useLocale, useTranslate } from '@va/localization';
import { hasPermission } from '@va/standalone/shared/selectors';
import { FilterTemplate } from '@va/types/filter-templates';
import {
  Button,
  IconWithBackground,
  ParagraphWithTooltip,
  RadioInputButton,
  fontWeights,
  paragraphSizes,
} from '@va/ui/design-system';
import moment from 'moment';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

export const TemplateListItem = ({
  template: { name, createdAt, id },
  selectedTemplateId,
  onClickEdit,
}: {
  template: FilterTemplate;
  selectedTemplateId: string | undefined;
  onClickEdit: () => void;
}) => {
  const isSelected = useMemo(() => id === selectedTemplateId, [id, selectedTemplateId]);
  const ref = useRef<HTMLDivElement>(null);
  const translate = useTranslate();
  const { locale } = useLocale();
  const hasEditPermission = useSelector((state) => hasPermission(state, userActions.Edit));

  return (
    <div ref={ref} className='flex items-center gap-3 py-1 rounded-15 bg-white hover:bg-white-snow px-18px group'>
      <RadioInputButton className='w-4 h-4 shrink-0' selected={isSelected} />
      <div className='flex gap-3 items-center overflow-hidden'>
        <IconWithBackground
          className='!bg-white-snow group-hover:!bg-white shrink-0 w-9 h-9'
          icon={() => <FilterIcon className='w-3 h-3' color='#3C3C3C ' />}
        />
        <div className='overflow-hidden'>
          <ParagraphWithTooltip
            size={paragraphSizes.tiny}
            colorClassName='text-gray-charchoal'
            weight={fontWeights.medium}
          >
            {name}
          </ParagraphWithTooltip>
          <ParagraphWithTooltip
            size={paragraphSizes.extraTiny}
            colorClassName='text-gray-devil'
            weight={fontWeights.medium}
          >
            {translate('all.filterTemplates.createdOn', {
              date: moment(createdAt).locale(locale).format('DD MMM YYYY'),
            })}
          </ParagraphWithTooltip>
        </div>
      </div>
      <Button
        className='ml-auto invisible group-hover:visible shrink-0 scale-75'
        color='quaternary'
        onClick={onClickEdit}
        icon={(_, color) => <PencilEditIcon color={color} />}
        disabled={!hasEditPermission}
        tooltip={!hasEditPermission && translate('tooltips.contributorRole')}
      />
    </div>
  );
};
