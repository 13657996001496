import { IconProps } from '.';

export const MiddleChainIcon = ({ color = 'black', className }: IconProps) => {
  return (
    <svg
      className={className}
      width='12'
      height='18'
      viewBox='0 0 12 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.75 0V3.04642C9.70973 3.41549 12 5.94029 12 9C12 12.0597 9.70973 14.5845 6.75 14.9536V18H5.25V14.9536C2.29027 14.5845 0 12.0597 0 9C0 5.94029 2.29027 3.41549 5.25 3.04642V0H6.75ZM6 13.5C8.48528 13.5 10.5 11.4853 10.5 9C10.5 6.51472 8.48528 4.5 6 4.5C3.51472 4.5 1.5 6.51472 1.5 9C1.5 11.4853 3.51472 13.5 6 13.5ZM6 11.25C7.24264 11.25 8.25 10.2426 8.25 9C8.25 7.75736 7.24264 6.75 6 6.75C4.75736 6.75 3.75 7.75736 3.75 9C3.75 10.2426 4.75736 11.25 6 11.25Z'
        fill={color}
      />
    </svg>
  );
};


