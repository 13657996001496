import { IconProps } from '.';

export const ExportIcon = ({ color = 'black', className }: IconProps) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.25 13.5V2.87132L5.03033 6.09099L3.96967 5.03033L8.46967 0.530331L9 0L9.53033 0.530331L14.0303 5.03033L12.9697 6.09099L9.75 2.87132V13.5H8.25ZM1.5 10.5V13.5C1.5 15.1569 2.84315 16.5 4.5 16.5H13.5C15.1569 16.5 16.5 15.1569 16.5 13.5V10.5H18V13.5C18 15.9853 15.9853 18 13.5 18H4.5C2.01472 18 0 15.9853 0 13.5V10.5H1.5Z'
        fill={color}
      />
    </svg>
  );
};
