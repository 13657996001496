import { GlobalTimePeriodFilter } from '@va/dashboard/components';
import { ChartVisualizationComponentProps } from '@va/types/charts';
import { BaseTimeseriesDataset } from '@va/types/time-series';
import { useReportBlockCtx } from '@va/ui/components/report-block';
import { ChartTiles, StackedBarChart, StackedBarChartDataType } from '@va/ui/design-system';
import { memo, useCallback } from 'react';
import { useCommonChartViewData } from './shared/useCommonChartViewData';

export const BarChartViewComponent = memo((props: ChartVisualizationComponentProps) => {
  const { reversePercentageColors, loadingPlaceholdersCount } = props;

  const getExtraDatasetConfig = useCallback(
    (dataset: BaseTimeseriesDataset) => ({ minBarLength: 5, stack: dataset.key }),
    [],
  );

  const { chartData, tiles, toggleKpiVisibility, tooltipFn, isLoading, errorComponent } = useCommonChartViewData<'bar'>(
    {
      ...props,
      getExtraDatasetConfig: getExtraDatasetConfig,
    },
  );
  const { id } = useReportBlockCtx();

  if (errorComponent) return errorComponent;

  return (
    <div className='space-y-3'>
      <ChartTiles
        reversePercentageColors={reversePercentageColors}
        isLoading={isLoading}
        loadingPlaceholdersCount={loadingPlaceholdersCount}
        tiles={tiles}
        onClick={(id) => {
          toggleKpiVisibility(id);
        }}
      />
      <div className='w-full flex min-h-[48px] justify-end'>
        <GlobalTimePeriodFilter />
      </div>
      <StackedBarChart
        isLoading={isLoading}
        tooltipFn={tooltipFn}
        canvasId={`${id}-bar-chart-canvas`}
        wrapperClasses='h-[400px]'
        chartData={chartData as StackedBarChartDataType}
        configuration={{
          maintainAspectRatio: false,
          aspectRatio: 1,
          xAxisMaxRotation: 0,
        }}
      />
    </div>
  );
});
